export const googleFormatter = (item) => {
  return {
    multipolygon: multipolygonFormatter(item['multiPolygon'].coordinates),
    multipoint: item['multiPoint'] ? multipointFormatter(item['multiPoint'].coordinates) : null,
  }
}

export const multipointFormatter = (multipoint) => {
  let googleCoords = []
  for (let c = 0; c < multipoint.length; c++) {
    let lat = parseFloat(multipoint[c][1])
    if (lat > 85) lat = 85
    if (lat < -85) lat = -85
    let lng = parseFloat(multipoint[c][0])
    googleCoords[c] = { lat, lng }
  }
  return googleCoords
}

export const multipolygonFormatter = (multipolygon) => {
  let googlePolygons = [[[]]] //multi
  for (let p = 0; p < multipolygon.length; p++) {
    let googleRings = [[]] //poly
    for (let r = 0; r < multipolygon[p].length; r++) {
      let googleCoords = [] // ring
      for (let c = 0; c < multipolygon[p][r].length; c++) {
        let lat = parseFloat(multipolygon[p][r][c][1])
        // Because we only have -85 to 85 lat instead of -90 to 90
        if (lat > 85) lat = 85
        if (lat < -85) lat = -85
        let lng = parseFloat(multipolygon[p][r][c][0])
        googleCoords[c] = { lat, lng } // coordinate
      }
      googleRings[r] = googleCoords
    }
    googlePolygons[p] = googleRings
  }
  return googlePolygons
}
