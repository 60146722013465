import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { UserConfig } from 'modules/auth/Auth.types'

// state

interface State {
  loading: boolean
  error: string | null
}

const initialState: State = {
  loading: false,
  error: null,
}

export interface SetUserConfigAction {
  type: actionTypes.SET_USERCONFIG_ACTION_TYPE
  // REQUEST
  collectionId: string
  configId: string
  value: UserConfig['value']
  // SUCCESS
  userConfigs: UserConfig[]
}

// reducers

const loading = createReducer<State['loading'], SetUserConfigAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.SET_USERCONFIG_REQUEST:
      return true
    case actionTypes.SET_USERCONFIG_SUCCESS:
    case actionTypes.SET_USERCONFIG_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], SetUserConfigAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.SET_USERCONFIG_SUCCESS:
      case actionTypes.SET_USERCONFIG_DISMISS:
        return null
      case actionTypes.SET_USERCONFIG_FAILURE:
        return error
    }
    return state
  },
)

export const setUserConfigReducer = combineReducers({
  loading,
  error,
})

// selectors

export const setUserConfigLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.setUserConfig.loading,
  (loading) => loading,
)
export const setUserConfigErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.setUserConfig.error,
  (error) => error,
)
