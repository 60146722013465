import React from 'react'
import { Checkbox } from 'final-form-material-ui'
import { Field } from 'react-final-form'
import { CheckboxControl } from 'ui/form/authForm.style'
import { Box } from '@material-ui/core'

interface ReTableCheckboxCellProps {
  value?: boolean
  isEdit?: boolean
  name?: string
  label?: string
  fieldAutoFocus?: boolean
}

const ReTableCheckboxCell: React.FC<ReTableCheckboxCellProps> = ({ value, isEdit, name = '', label = '' }) => {
  return (
    <Box mt={1}>
      <CheckboxControl
        label={label}
        control={<Field defaultValue={value} disabled={!isEdit} name={name} type="checkbox" component={Checkbox} />}
      />
    </Box>
  )
}

export default React.memo(ReTableCheckboxCell)
