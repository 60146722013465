import React, { useEffect, useMemo, useState } from 'react'
import ApiDocumentation from 'modules/apiDocumentation/ApiDocumentation'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { toolbarHeight } from 'themes/theme-light'
import { c } from 'ttag'
import LayoutTitle from 'ui/LayoutTitle'
import MasterDataStandardFormat from 'modules/masterData/MasterDataStandardFormat'
import HistoricalMeterDataStandardFormat from 'modules/data/fileManager/HistoricalMeterDataStandardFormat'
import MasterDataCustomFormats from 'modules/masterData/masterDataCustomFormats/MasterDataCustomFormats'
import { QUERY_MASTER_DATA_CUSTOM_FORMAT, useQueryMatch, useQueryParams, useQueryString } from 'utils/query-string'
import MasterDataIframe from 'modules/masterData/MasterDataIframe'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isSubscribedTo, useUserHasGlobalAccess } from 'utils/user'
import { SubscribedProduct } from 'modules/auth/Auth.types'

const Section = styled(Box)`
  margin-bottom: 2em;
  line-height: 1.5;
`

const DataFlowContainer = styled(Box)`
  height: calc(100vh - ${toolbarHeight});
`

const DataFlow: React.FC = () => {
  const user = useSelector(getUserResultSelector)

  const [oldUiIframeUrl, setOldUiIframeUrl] = useState<string>('')
  const { onDeleteQueryStrings } = useQueryString()
  const { queryParams } = useQueryParams()
  const hasGlobalAccess = useUserHasGlobalAccess()

  const hasAccessToMasterDataCustomFormat = useMemo(
    () => hasGlobalAccess || isSubscribedTo(user, SubscribedProduct.MASTER_DATA_CUSTOM),
    [user, hasGlobalAccess],
  )

  const isMasterDataCustomFormatDetails = useQueryMatch(QUERY_MASTER_DATA_CUSTOM_FORMAT)

  useEffect(() => {
    if (!isMasterDataCustomFormatDetails) {
      setOldUiIframeUrl('')
    } else {
      const id = queryParams[QUERY_MASTER_DATA_CUSTOM_FORMAT]
      const iframeUrl = `/iframe/#/masterdatabulk/${id === 'new' ? '' : `${id}`}?IFRAME`

      setOldUiIframeUrl(iframeUrl)
    }
  }, [isMasterDataCustomFormatDetails, queryParams[QUERY_MASTER_DATA_CUSTOM_FORMAT]])

  useEffect(() => {
    if (!hasAccessToMasterDataCustomFormat && isMasterDataCustomFormatDetails) {
      onDeleteQueryStrings([QUERY_MASTER_DATA_CUSTOM_FORMAT])
    }
  }, [hasAccessToMasterDataCustomFormat, onDeleteQueryStrings, isMasterDataCustomFormatDetails])

  return (
    <>
      {isMasterDataCustomFormatDetails ? (
        <MasterDataIframe url={oldUiIframeUrl} />
      ) : (
        <DataFlowContainer ml={1} mr={1}>
          <LayoutTitle icon="retweet">{c('Data flow').t`Data flow`}</LayoutTitle>
          <Section>
            <ApiDocumentation />
          </Section>

          {/*{!isFree(user) && (*/}
          {/*  <Section>*/}
          {/*    <IpWhiteList />*/}
          {/*  </Section>*/}
          {/*)}*/}

          <Section>
            <MasterDataStandardFormat />
          </Section>
          {hasAccessToMasterDataCustomFormat && (
            <Box>
              <MasterDataCustomFormats />
            </Box>
          )}
          <Section>
            <HistoricalMeterDataStandardFormat />
          </Section>
        </DataFlowContainer>
      )}
    </>
  )
}

export default React.memo(DataFlow)
