import { SubscribedProduct, User } from 'modules/auth/Auth.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'ttag'
import { Roles } from 'utils/userManagement'

export const hasAnyAuthority = (user: User | null, authorities: string[]) => {
  return Boolean(user && authorities.some((authority) => user.authorities.includes(authority)))
}

export const hasAllRoles = (user: User | null, roles: string[]) => {
  return Boolean(user && roles.every((role) => user.authorities.includes(role)))
}

// Since isAdmin is related admin functionality and toggle admin functionality ,
// second param (stayOnDisplay) , is used when we want to keep some component highlighted even if this toggle is off
export const isAdmin = (user: User | null, stayOnDisplay?: boolean) => {
  if (sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT) && !stayOnDisplay) {
    const showAdminFunctionality = JSON.parse(sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT))
    return Boolean(user && showAdminFunctionality && hasAnyAuthority(user, ['ROLE_ADMIN']))
  }
  return Boolean(user && hasAnyAuthority(user, ['ROLE_ADMIN']))
}

export const isPartner = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_PARTNER']))
}

export const isCompanyAccount = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, [Roles.COMPANY_ACCOUNT]))
}

export const isDemo = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, [Roles.DEMO]))
}

export const isFree = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_FREE']))
}

export const isDeveloper = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_DEVELOPER']))
}

// Since isImpersonatedAdmin is related admin functionality and toggle admin functionality (add or remove admin components) ,
// second param (stayOnDisplay) , is used when we want to keep some component highlighted even if this toggle is off
export const isImpersonatedAdmin = (user: User | null, stayOnDisplay?: boolean) => {
  if (sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT) && !stayOnDisplay) {
    const showAdminFunctionality = JSON.parse(sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT))
    return Boolean(user && showAdminFunctionality && hasAnyAuthority(user, ['ROLE_PREVIOUS_ADMINISTRATOR']))
  }
  return Boolean(user && hasAnyAuthority(user, ['ROLE_PREVIOUS_ADMINISTRATOR']))
}

export const isImpersonatedPartner = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_PREVIOUS_PARTNER']))
}

export const isEnercast = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_ENERCAST']))
}

export const isIndianCustomer = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_INDIA']))
}

// we are calling isChpRole , because isChp is already reserved by handling asset types.
export const isChpRole = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_CHP']))
}

export const hasPermissionForPvMeterDataCleansing = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, [Roles.METER_DATA_CLEANSING_PV]))
}
export const hasPermissionForWindMeterDataCleansing = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, [Roles.METER_DATA_CLEANSING_WIND]))
}
export const hasPermissionForPvBulkMeterDataCleansing = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, [Roles.METER_DATA_CLEANSING_BULK_PV]))
}
export const hasPermissionForWindBulkMeterDataCleansing = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, [Roles.METER_DATA_CLEANSING_BULK_WIND]))
}

export const isSubscribedTo = (user: User | null, subscription: SubscribedProduct) => {
  return isAdmin(user) || isImpersonatedAdmin(user) || Boolean(user && user.subscribedProducts.includes(subscription))
}

// This function checks if user (doesn't matter if is impersonated admin or not) , has a certain subscription.
export const isCurrentUserSubscribedTo = (user: User | null, subscription: SubscribedProduct) => {
  return Boolean(user && user.subscribedProducts.includes(subscription))
}

export const isAuthorizedToPenalties = (user: User | null) => {
  return (
    isAdmin(user) ||
    isImpersonatedAdmin(user) ||
    (isIndianCustomer(user) && isSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
  )
}

export const userHasGlobalAccessWithoutDemo = (user: User | null) => {
  return (isAdmin(user) || isImpersonatedAdmin(user)) && !isDemo(user)
}

export const useUserHasGlobalAccess = () => {
  const user = useSelector(getUserResultSelector)
  const hasGlobalAccess = useMemo(() => isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user), [user])
  return hasGlobalAccess
}

export const isCompanySubAccount = (user: User | null) => {
  return Boolean(user && hasAnyAuthority(user, ['ROLE_IMPERSONATED_INTO_COMPANY_ACCOUNT']))
}

export const useIsReadOnlyUser = () => {
  const user = useSelector(getUserResultSelector)
  const [isReadOnlyUser, setIsReadOnlyUser] = useState(false)

  useEffect(() => {
    if (user) {
      if (isImpersonatedAdmin(user)) {
        // Here we are handling value from session storage
        const toggleAdminComponentsSessionValue = sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT)
        const sessionForToggleAdmin = toggleAdminComponentsSessionValue
          ? JSON.parse(toggleAdminComponentsSessionValue)
          : true
        if (sessionForToggleAdmin) {
          setIsReadOnlyUser(false)
        }
      } else {
        setIsReadOnlyUser(hasAnyAuthority(user, ['ROLE_READ_ONLY']))
      }
    }
  }, [user])

  return isReadOnlyUser
}

// authentication and permissions for datastreams

export const hasPermissionForSiteForecast = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_SITE_FORECAST'])
}

// Meta forecast widget and dataStream should be added only if user has access to the e3 Weather models or 3rd party along with META_FORECAST_ROLE
export const hasPermissionForMetaForecast = (user: User | null) => {
  const hasAccessToE3WeatherModels = hasPermissionForE3ModelLevelForecast(user)
  const hasAccessTo3rdPartyModels = hasPermissionTo3rdPartyForecasts(user)
  const hasPermissionForMetaForecast =
    hasAnyAuthority(user, ['ROLE_E3_METAFORECAST']) || isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user)

  return hasPermissionForMetaForecast && (hasAccessToE3WeatherModels || hasAccessTo3rdPartyModels)
}

export const hasPermissionForE3ModelLevelForecast = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_E3_FORECAST']) || isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user)
}

export const hasPermissionTo3rdPartyForecasts = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_E3_3RD_PARTY_FORECAST'])
}

export const hasPermissionForMeterData = (user: User | null) => {
  return Boolean(user)
}

export const hasPermissionForCapacityData = (user: User | null) => {
  return Boolean(user)
}

export const hasPermissionForWeatherData = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_WDA'])
}

export const hasPermissionForAreaForecast = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_AREA_FORECAST'])
}

export const hasPermissionForSiteAssessment = (user: User | null) => {
  return hasAnyAuthority(user, [
    'ROLE_SITE_ASSESSMENT_PV',
    'ROLE_SITE_ASSESSMENT_WIND',
    'ROLE_SITE_ASSESSMENT_BACKCAST',
  ])
}

export const hasPermissionForLongRangeForecast = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_SEASONAL_FORECAST'])
}

export const hasPermissionForSiteAssessmentBackcast = (user: User | null, withoutAdmin?: boolean) => {
  if (withoutAdmin) {
    return hasAnyAuthority(user, ['ROLE_SITE_ASSESSMENT_BACKCAST'])
  }
  return hasAnyAuthority(user, ['ROLE_SITE_ASSESSMENT_BACKCAST']) || isAdmin(user) || isImpersonatedAdmin(user)
}

export const hasPermissionToClimatology = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_CLIMATOLOGY'])
}

export const hasPermissionToCreateSchedule = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_CREATE_SCHEDULE'])
}

export const hasPermissionForMaintenance = (user: User | null) => {
  return hasAnyAuthority(user, ['ROLE_MAINTENANCE'])
}

export const hasPermissionForEpexDataStreams = (user: User | null) => {
  const hasAccessToSiteAssessment = hasPermissionForSiteAssessment(user)
  const hasCalculateRevenueSubscription = isCurrentUserSubscribedTo(
    user,
    SubscribedProduct.CALCULATION_OF_REVENUE_SPREAD,
  )

  const hasAccess = hasAccessToSiteAssessment && hasCalculateRevenueSubscription
  return hasAccess
}

export enum UserMenuItemKeys {
  USER_SETTINGS = 'USER_SETTINGS',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  DEMO_RESOURCES = 'DEMO_RESOURCES',
  SHOW_CLASSIC_UI = 'SHOW_CLASSIC_UI',
  TOGGLE_ADMIN_FUNCTIONALITY = 'TOGGLE_ADMIN_FUNCTIONALITY',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  METRICS = 'METRICS',
  HEALTH = 'HEALTH',
  CONFIGURATION = 'CONFIGURATION',
  AUDITS = 'AUDITS',
  LOGS = 'LOGS',
  TRAINING = 'TRAINING',
  GIPS = 'GIPS',
  RUNNING_BACKCASTS = 'RUNNING_BACKCASTS',
  RELEASE_NOTES = 'RELEASE_NOTES',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  IMPRINT = 'IMPRINT',
  CONTACT_US = 'CONTACT_US',
  AUTHORIZED_ACCOUNTS = 'AUTHORIZED_ACCOUNTS',
  LEAVE_IMPERSONATE = 'LEAVE_IMPERSONATE',
  SIGN_OUT = 'SIGN_OUT',
}

// This const is used to handle refresh page when print view is open
export const PRINT_VIEW_RELOAD = 'printViewReload'

// Since we are exposing this const in session storage , assigned name it's a bit different
export const TOGGLE_ADMIN_COMPONENT = 'a270d254536mc294o637m17p03'

export const getUserMenuItemData = (key: UserMenuItemKeys): { label: string; icon: string; custom?: boolean } => {
  switch (key) {
    case UserMenuItemKeys.USER_SETTINGS:
      return { label: t`User settings`, icon: 'user-cog' }
    case UserMenuItemKeys.CHANGE_PASSWORD:
      return { label: t`Change password`, icon: 'lock' }
    case UserMenuItemKeys.DEMO_RESOURCES:
      return { label: t`Demo resources`, icon: 'download' }
    case UserMenuItemKeys.SHOW_CLASSIC_UI:
      return { label: t`Show classic UI`, icon: 'external-link-alt' }
    case UserMenuItemKeys.TOGGLE_ADMIN_FUNCTIONALITY:
      return { label: t`Admin functionality`, icon: 'user', custom: true }
    case UserMenuItemKeys.USER_MANAGEMENT:
      return { label: t`User management`, icon: 'user' }
    case UserMenuItemKeys.METRICS:
      return { label: t`Metrics`, icon: 'tachometer-alt' }
    case UserMenuItemKeys.HEALTH:
      return { label: t`Health`, icon: 'heartbeat' }
    case UserMenuItemKeys.CONFIGURATION:
      return { label: t`Configuration`, icon: 'sliders-h' }
    case UserMenuItemKeys.AUDITS:
      return { label: t`Audits`, icon: 'bell' }
    case UserMenuItemKeys.LOGS:
      return { label: t`Logs`, icon: 'list-alt' }
    case UserMenuItemKeys.TRAINING:
      return { label: t`Training`, icon: 'road' }
    case UserMenuItemKeys.GIPS:
      return { label: t`GIPS`, icon: 'draw-polygon' }
    case UserMenuItemKeys.RUNNING_BACKCASTS:
      return { label: t`Running backcasts`, icon: 'chart-line' }
    case UserMenuItemKeys.RELEASE_NOTES:
      return { label: t`Release Notes`, icon: 'rocket' }
    case UserMenuItemKeys.TERMS_AND_CONDITIONS:
      return { label: t`Terms and conditions`, icon: 'clipboard-list' }
    case UserMenuItemKeys.PRIVACY_POLICY:
      return { label: t`Privacy policy`, icon: 'clipboard-check' }
    case UserMenuItemKeys.IMPRINT:
      return { label: t`Imprint`, icon: 'clipboard' }
    case UserMenuItemKeys.CONTACT_US:
      return { label: t`Contact us`, icon: 'envelope' }
    case UserMenuItemKeys.AUTHORIZED_ACCOUNTS:
      return { label: t`Authorized accounts`, icon: 'user-check' }
    case UserMenuItemKeys.LEAVE_IMPERSONATE:
      return { label: t`Leave impersonate`, icon: 'caret-square-left' }
    case UserMenuItemKeys.SIGN_OUT:
      return { label: t`Sign out`, icon: 'sign-out-alt' }
    default:
      return { label: '', icon: '' }
  }
}

// const checkIfAdminComponent = (dataStreamType: TimeSeriesType, user: User) => {
//   const value = dataStreamType
//   switch (value) {
//     case TimeSeriesType.MARKET_PRICE_DATA:
//       return false
//     case TimeSeriesType.E3_META_FORECAST:
//       return !hasAnyAuthority(user, ['ROLE_E3_METAFORECAST']) && !hasAnyAuthority(user, ['ROLE_E3_FORECAST'])
//   }
//
//   return true
// }

/**
 * This function returns saved language for a certain user.
 * If a user is sub-account , it returns from Original user key
 */
export const getSavedLanguage = (user: User) => {
  if (isCompanySubAccount(user) && user?.originalUser?.langKey) {
    return user?.originalUser?.langKey
  }
  return user?.langKey
}
