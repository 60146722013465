import React from 'react'
import Flex from 'ui/styles/Flex'
import { Typography } from '@material-ui/core'
import { c } from 'ttag'

const MasterDataStandardFormat: React.FC = () => {
  return (
    <>
      <Flex alignItems="center">
        <Typography variant="h5">{c('Master data').t`Master data standard format`}</Typography>
      </Flex>

      <Typography>{c('Master data')
        .t`For uploading asset data in bulk, please use the file format as shown in the following sample file:`}</Typography>
      <a href="api/v1/download/get/samples/1" download>
        {c('Master data').t`masterdata-sample.csv`}
      </a>
      <ul>
        <li>
          {c('Master data').t`Please use international CSV format (decimal separator: point; field separator: comma).`}
        </li>
      </ul>

      <Typography>
        {c('Master data').t`This file format can be submitted by e-mail to `}
        <a href="mailto: support@enercast.de">support@enercast.de</a>
        {c('Master data').t`. Please contact the same e-mail address in case of any questions.`}
      </Typography>
    </>
  )
}

export default React.memo(MasterDataStandardFormat)
