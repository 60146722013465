import React, { useEffect, useState } from 'react'
import LayoutTitle from 'ui/LayoutTitle'
import { c } from 'ttag'
import LoadingButton from 'ui/form/LoadingButton'
import { addDemoData, deleteDemoData, getDemoData, MASTER_DEMO_ACCOUNT } from 'modules/auth/api/userSettings.api'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'
import { QUERY_USER_SETTINGS, useQueryString } from 'utils/query-string'
import { useIsReadOnlyUser } from 'utils/user'

const DEMO_ACCOUNT_IS = {
  COPIED: 'COPIED',
  NOT_COPIED: 'NOT_COPIED',
  UPDATING: 'UPDATING',
}

const DemoUserSettings = () => {
  const { onDeleteQueryStrings } = useQueryString()
  const isReadOnlyUser = useIsReadOnlyUser()
  const [buttonState, setButtonState] = useState<string>(DEMO_ACCOUNT_IS.UPDATING)
  const [reqIsMade, setReqIsMade] = useState(false)

  // Used to check if we are using demo account setup for logged-in user
  useEffect(() => {
    getDemoData(MASTER_DEMO_ACCOUNT)
      .then((res) => {
        setButtonState(res as string)
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (reqIsMade) {
      const intervalId = setInterval(() => {
        getDemoData(MASTER_DEMO_ACCOUNT)
          .then((res) => {
            if (res !== DEMO_ACCOUNT_IS.UPDATING) {
              setButtonState(res as string)
              setReqIsMade(false)
              onDeleteQueryStrings([QUERY_USER_SETTINGS])
              location.reload()
            }
          })
          .catch((err) => console.log(err))
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [reqIsMade, onDeleteQueryStrings])

  const clickButtonHandler = () => {
    if (buttonState === DEMO_ACCOUNT_IS.COPIED) {
      return removeDemoDataHandler()
    } else if (buttonState === DEMO_ACCOUNT_IS.NOT_COPIED) {
      return addDemoDataHandler()
    } else {
      return null
    }
  }

  const addDemoDataHandler = () => {
    setButtonState(DEMO_ACCOUNT_IS.UPDATING)

    addDemoData(MASTER_DEMO_ACCOUNT)
      .then(() => {
        setReqIsMade(true)
      })
      .catch((err) => console.log(err))
  }

  const removeDemoDataHandler = () => {
    setButtonState(DEMO_ACCOUNT_IS.UPDATING)
    deleteDemoData(MASTER_DEMO_ACCOUNT)
      .then(() => {
        setReqIsMade(true)
      })
      .catch((err) => console.log(err))
  }

  return (
    <Flex direction="column" style={{ marginBottom: '1em' }}>
      <LayoutTitle>{c('User Settings').t`Demo settings`}</LayoutTitle>
      <Box display="flex">
        <LoadingButton
          color="primary"
          size="small"
          variant="contained"
          loading={buttonState === DEMO_ACCOUNT_IS.UPDATING}
          onClick={clickButtonHandler}
          disabled={isReadOnlyUser}
        >
          {buttonState === DEMO_ACCOUNT_IS.COPIED
            ? c('User Settings').t`Remove demo data`
            : buttonState === DEMO_ACCOUNT_IS.NOT_COPIED
            ? c('User Settings').t`Add demo data`
            : c('User Settings').t`Updating`}
        </LoadingButton>
      </Box>
    </Flex>
  )
}

export default DemoUserSettings
