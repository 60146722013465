import React, { useMemo } from 'react'
import { getAreaIds } from 'fixtures/areaLabels'
import { Area, TYPE_AREA } from 'modules/asset/store/asset.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useActiveAreaConfigs } from 'modules/dataStreams/api/areaForecastConfigs.api'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useActiveWeatherConfigs, useWeatherCatalog } from 'modules/dataStreams/api/weatherData.api'
import {
  AreaConfig,
  DataStream,
  DataStreamItem,
  DataStreamOrder,
  DataStreamRowItem,
  DataStreamSelectionItem,
  DAY_AHEAD_DATA_STREAM_SEARCH_KEY,
  E3ForecastModel,
  ForecastConfig,
  ForecastConfigurationCategory,
  GERMANY_REVENUE_SEARCH_KEY,
  INTRA_DAY_DATA_STREAM_SEARCH_KEY,
  MetaDataHierarchy,
  ParamDto,
  TimeSeriesClassifier,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { CellRenderType, Column, ColumnSortType, ReTableItem } from 'modules/reTable/reTable.types'
import { WeatherConfig } from 'modules/weather/store/weather.types'
import { useSelector } from 'react-redux'
import { c, jt, t } from 'ttag'
import { getDefaultE3ModelLevelForecastConfigs, getE3ModelLabel } from 'utils/e3'
import { MenuItem } from 'utils/menu'
import { AppUnits, DEFAULT_POWER_UNIT } from 'utils/units'
import {
  hasPermissionForAreaForecast,
  hasPermissionForCapacityData,
  hasPermissionForE3ModelLevelForecast,
  hasPermissionForEpexDataStreams,
  hasPermissionForLongRangeForecast,
  hasPermissionForMetaForecast,
  hasPermissionForMeterData,
  hasPermissionForSiteForecast,
  hasPermissionForWeatherData,
  hasPermissionTo3rdPartyForecasts,
  hasPermissionToClimatology,
  hasPermissionToCreateSchedule,
  isAdmin,
  isDemo,
  isImpersonatedAdmin,
} from 'utils/user'
import { capitalizeString } from 'utils/dataFormatting'
import { User } from 'modules/auth/Auth.types'

export const getDataStreamColumns: () => Column[] = () => [
  {
    name: 'name',
    label: c('Data Stream').t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: '14.7em',
    fixed: true,
  },
]

// data stream definitions

export const DEFAULT_FORECAST_CONFIG_ID = 'default'

export const dataStreamTypeQuery = {
  [TimeSeriesType.SITE_FORECAST]: 'site-forecast',
  [TimeSeriesType.E3_META_FORECAST]: 'e3-forecast',
  [TimeSeriesType.METER_DATA]: 'meter-data',
  [TimeSeriesType.MARKET_PRICE_DATA]: 'price-data',
  [TimeSeriesType.CAPACITY_DATA]: 'capacity-data',
  [TimeSeriesType.WEATHER_DATA]: 'weather-data',
  [TimeSeriesType.AREA_FORECAST]: 'area-forecast',
  [TimeSeriesType.CLIMATOLOGY]: 'climatology',
  [TimeSeriesType.SEASONAL_FORECAST]: 'seasonal-forecast',
  [TimeSeriesType.SCHEDULE]: 'schedule',
  [TimeSeriesType.THIRD_PARTY]: 'third-party',
}

export const dataStreamTypesThatAreConfigurable = [
  TimeSeriesType.SITE_FORECAST,
  TimeSeriesType.WEATHER_DATA,
  TimeSeriesType.AREA_FORECAST,
  TimeSeriesType.SCHEDULE,
]

export const getSiteForecastLabel = (forecastId = '', forecastConfigs: ForecastConfig[] = []) => {
  const forecastConfig = forecastConfigs.find((forecastConfig) => forecastId.startsWith(forecastConfig.id || ''))
  return forecastConfig ? forecastConfig.name : forecastId
}

export const getWeatherDataLabel = (weatherId?: string, weatherConfigs: WeatherConfig[] = []) => {
  return weatherConfigs.find((w) => w.id === weatherId)?.label || weatherId
}

export const getAreaForecastLabel = (areaForecastId?: string, areas: Area[] = []) => {
  return areas.find((a) => a.productCode === areaForecastId)?.name || areaForecastId
}

interface GetDataStreamId {
  id?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
}
export const getDataStreamId = ({ id, type, subType, classifier }: GetDataStreamId) => {
  return id || `${type}${subType ? `_${subType}` : ''}${classifier ? `_${classifier}` : ''}`
}

interface GetDataStreamName {
  // data stream data
  id?: string
  name?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
  // additional data needed to label data streams
  forecastConfigId?: string
  forecastConfigs?: ForecastConfig[]
  weatherConfigs?: WeatherConfig[]
  areas?: Area[]
}
export const getDataStreamName = ({
  id,
  name,
  type,
  subType,
  classifier,
  forecastConfigs = [],
  weatherConfigs = [],
  areas,
}: GetDataStreamName) => {
  if (name) return name

  switch (type) {
    case TimeSeriesType.SITE_FORECAST:
    case TimeSeriesType.SCHEDULE:
    case TimeSeriesType.BACK_CAST:
      return getSiteForecastLabel(id, forecastConfigs)
    case TimeSeriesType.META_FORECAST:
      if (subType === TimeSeriesSubType.META_ENSEMBLE) {
        return c('Workbench:TimeSeries').t`Interactive meta forecast`
      }
    case TimeSeriesType.E3_META_FORECAST:
      if (subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST) {
        return getSiteForecastLabel(id, forecastConfigs)
      }
      return getE3ModelLabel((id as E3ForecastModel) || 'E3_META_FORECAST')
    case TimeSeriesType.METER_DATA:
      switch (classifier) {
        case TimeSeriesClassifier.SCADA:
          return c('Workbench:TimeSeries').t`Meter data (live)`
        case TimeSeriesClassifier.METERING_FINAL:
          return c('Workbench:TimeSeries').t`Meter data (finalized)`
        default:
          return c('Workbench:TimeSeries').t`Meter data (latest)`
      }
    case TimeSeriesType.CAPACITY_DATA:
      switch (subType) {
        case TimeSeriesSubType.INSTALLED_CAPACITY:
          return c('Workbench:TimeSeries').t`Nameplate capacity`
        case TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY:
          return c('Workbench:TimeSeries').t`Available capacity (planned)`
        case TimeSeriesSubType.MAX_AVAILABLE_CAPACITY:
          switch (classifier) {
            case TimeSeriesClassifier.SCADA:
              return c('Workbench:TimeSeries').t`Available capacity (live)`
            case TimeSeriesClassifier.METERING_FINAL:
              return c('Workbench:TimeSeries').t`Available capacity (finalized)`
            default:
              return c('Workbench:TimeSeries').t`Available capacity (latest)`
          }
        default:
          return type
      }
    case TimeSeriesType.WEATHER_DATA:
      return getWeatherDataLabel(id, weatherConfigs)
    case TimeSeriesType.AREA_FORECAST:
      return getAreaForecastLabel(id, areas)
    case TimeSeriesType.SEASONAL_FORECAST:
    case TimeSeriesType.CLIMATOLOGY:
      switch (classifier) {
        case TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION:
        case TimeSeriesClassifier.CLIMATOLOGY_GENERATION:
          return c('Workbench:TimeSeries').t`Generation`
        case TimeSeriesClassifier.SEASONAL_FORECAST_WIND_SPEED:
        case TimeSeriesClassifier.CLIMATOLOGY_WIND_SPEED:
          return c('Workbench:TimeSeries').t`Wind speed`
        case TimeSeriesClassifier.SEASONAL_FORECAST_SOLAR_IRRADIATION:
        case TimeSeriesClassifier.CLIMATOLOGY_SOLAR_IRRADIATION:
          return c('Workbench:TimeSeries').t`Solar irradiation`
        default:
          return classifier
      }
    case TimeSeriesType.MARKET_PRICE_DATA:
      // Names for Indian Market price are same as classifiers, here classifiers are States
      if (subType === TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA && classifier) {
        return capitalizeString(classifier.replace('_', ' '))
      }
      // Names for Market price classifiers
      if (subType && classifier) {
        switch (classifier) {
          case TimeSeriesClassifier.GERMANY_EPEX_DAY_AHEAD_AUCTION:
            return c('Workbench:TimeSeries').t`EPEX SPOT Day-Ahead Auction`
          case TimeSeriesClassifier.FRANCE_EPEX_DAY_AHEAD_AUCTION:
            return c('Workbench:TimeSeries').t`EPEX SPOT Day-Ahead Auction`
          case TimeSeriesClassifier.FRANCE_M0_SOLAR:
            return c('Workbench:TimeSeries').t`M0 Solar`
          case TimeSeriesClassifier.FRANCE_M0_WIND:
            return c('Workbench:TimeSeries').t`M0 Wind`
          case TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_AUCTION:
            return c('Workbench:TimeSeries').t`EPEX SPOT Intraday Auction`
          case TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_HIGH:
            return c('Workbench:TimeSeries').t`EPEX SPOT Intraday continuous high`
          case TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LOW:
            return c('Workbench:TimeSeries').t`EPEX SPOT Intraday continuous low`
          case TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE:
            return c('Workbench:TimeSeries').t`EPEX SPOT Intraday continuous average`
          case TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LAST:
            return c('Workbench:TimeSeries').t`EPEX SPOT Intraday continuous last`
          case TimeSeriesClassifier.GERMANY_REBAP:
            return c('Workbench:TimeSeries').t`reBAP`
          case TimeSeriesClassifier.GERMANY_ESTIMATED_REBAP:
            return c('Workbench:TimeSeries').t`Estimated reBAP`
          case TimeSeriesClassifier.GERMANY_MARKTWERT_SOLAR:
            return c('Workbench:Timeseries').t`Marktwert Solar`
          case TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_OFF_SHORE:
            return c('Workbench:TimeSeries').t`Marktwert Wind Offshore`
          case TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_ON_SHORE:
            return c('Workbench:TimeSeries').t`Marktwert Wind Onshore`

          case TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST:
            return c('Workbench:TimeSeries').t`Day-Ahead balancing cost`
          case TimeSeriesClassifier.GERMANY_REVENUE_INTRA_DAY_BALANCING_COST:
            return c('Workbench:TimeSeries').t`Intraday balancing cost`
          case TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_REVENUE:
            return c('Workbench:TimeSeries').t`Day-Ahead revenue`
          case TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE:
            return c('Workbench:TimeSeries').t`Day-Ahead & Intraday revenue`
          case TimeSeriesClassifier.GERMANY_REVENUE_SPREAD:
            return c('Workbench:TimeSeries').t`Revenue spread`
          case TimeSeriesClassifier.GERMANY_REVENUE:
            return c('Workbench:TimeSeries').t`Day-Ahead revenue`

          case TimeSeriesClassifier.TURKEY_SYSTEM_MARGINAL_PRICE:
            return c('Workbench:TimeSeries').t`SMF`
          case TimeSeriesClassifier.TURKEY_MARKET_CLEARANCE_PRICE:
            return c('Workbench:TimeSeries').t`PTF`
          case TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_SOLAR:
            return c('Workbench:TimeSeries').t`Referenzmarktwert Solar`
          case TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_WIND:
            return c('Workbench:TimeSeries').t`Referenzmarktwert Wind`
          default:
            return classifier
        }
      } else {
        // Names for market price sub-types (countries)
        switch (subType) {
          case TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA:
            return c('Workbench:TimeSeries').t`Germany`
          case TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA:
            return c('Workbench:TimeSeries').t`France`
          case TimeSeriesSubType.AUSTRIA_MARKET_PRICE_DATA:
            return c('Workbench:TimeSeries').t`Austria`
          case TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA:
            return c('Workbench:TimeSeries').t`India Normal Rates`
          case TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA:
            return c('Workbench:TimeSeries').t`Turkey `
          default:
            return subType
        }
      }

    default:
      return type
  }
}

interface GetDataStreamUnit {
  id?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
  paramData?: ParamDto
}
export const getDataStreamUnit = ({ type, subType, paramData, classifier }: GetDataStreamUnit) => {
  switch (type) {
    case TimeSeriesType.SITE_FORECAST:
    case TimeSeriesType.SCHEDULE:
    case TimeSeriesType.E3_META_FORECAST:
    case TimeSeriesType.META_FORECAST:
    case TimeSeriesType.METER_DATA:
    case TimeSeriesType.CAPACITY_DATA:
    case TimeSeriesType.BACK_CAST:
      return DEFAULT_POWER_UNIT
    case TimeSeriesType.WEATHER_DATA:
      return paramData?.unit || '?'
    case TimeSeriesType.AREA_FORECAST:
      return AppUnits.MEGA_WATT
    case TimeSeriesType.MARKET_PRICE_DATA:
      if (classifier?.includes(GERMANY_REVENUE_SEARCH_KEY)) {
        return AppUnits.EURO
      } else {
        switch (subType) {
          case TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA:
            return AppUnits.RUPEE_PER_KILO_WATT_HOUR
          case TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA:
            return AppUnits.LIRA_PER_MEGA_WATT_HOUR
          default:
            return AppUnits.EURO_PER_MEGA_WATT_HOUR
        }
      }
  }
}

// data stream sidenav menu items
export const getDataStreamMenuItems = (): MenuItem[] => {
  return [
    {
      name: dataStreamTypeQuery[TimeSeriesType.SITE_FORECAST],
      label: c('Data streams').t`Add Site Forecast`,
      userHasPermission: hasPermissionForSiteForecast,
    },
    {
      name: dataStreamTypeQuery[TimeSeriesType.SCHEDULE],
      label: c('Data streams').t`Add Schedule`,
      userHasPermission: hasPermissionToCreateSchedule,
    },
    {
      name: dataStreamTypeQuery[TimeSeriesType.WEATHER_DATA],
      label: c('Data streams').t`Add Weather Data`,
      userHasPermission: hasPermissionForWeatherData,
      isAdminComponent: true,
    },
    {
      name: dataStreamTypeQuery[TimeSeriesType.AREA_FORECAST],
      label: c('Data streams').t`Add Area Forecast`,
      userHasPermission: hasPermissionForAreaForecast,
    },
  ]
}

export const getDataStreamTypeQueryLabel = (type: TimeSeriesType | TimeSeriesSubType) => {
  switch (type) {
    case TimeSeriesType.SITE_FORECAST:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Site Forecast`,
      }
    case TimeSeriesType.SCHEDULE:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Schedule`,
      }
    case TimeSeriesSubType.SEASONAL_FORECAST:
      return {
        query: '',
        label: c('Data Stream').t`Seasonal Forecast`,
      }
    case TimeSeriesSubType.CLIMATOLOGY:
      return {
        query: '',
        label: c('Data Stream').t`Climatology`,
      }
    case TimeSeriesType.E3_META_FORECAST:
    case TimeSeriesSubType.E3_WEATHER_TRACK:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`e³ Forecast`,
      }
    case TimeSeriesType.THIRD_PARTY:
    case TimeSeriesSubType.E3_THIRD_PARTY_FORECAST:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Meta Forecast`, // *** Attention *** Behind the scene we are still using third party , 'Meta forecast' is only label in UI
      }
    case TimeSeriesType.METER_DATA:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Actual Generation`,
      }
    case TimeSeriesType.MARKET_PRICE_DATA:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Market and Price Data`,
      }
    case TimeSeriesType.CAPACITY_DATA:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Capacity`,
      }
    case TimeSeriesType.WEATHER_DATA:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Weather Forecast`,
      }
    case TimeSeriesType.AREA_FORECAST:
      return {
        query: dataStreamTypeQuery[type],
        label: c('Data Stream').t`Area Forecast`,
      }
    case TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA:
      return {
        query: '',
        label: c('Data Stream').t`Germany`,
      }
    case TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA:
      return {
        query: '',
        label: c('Data Stream').t`France`,
      }
    case TimeSeriesSubType.AUSTRIA_MARKET_PRICE_DATA:
      return {
        query: '',
        label: c('Data Stream').t`Austria`,
      }
    case TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA:
      return {
        query: '',
        label: c('Data Stream').t`Turkey`,
      }
    case TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA:
      return {
        query: '',
        label: c('Data Stream').t`India Normal Rates`,
      }
  }
}

export const isMetaForecastDataStream = (dataStream: Partial<DataStream>) => {
  return dataStream.type === TimeSeriesType.META_FORECAST && dataStream.subType === TimeSeriesSubType.META_ENSEMBLE
}
export const getMetaForecastConfigDataStream = () => {
  return [
    {
      name: getDataStreamName({ type: TimeSeriesType.META_FORECAST, subType: TimeSeriesSubType.META_ENSEMBLE }),
      type: TimeSeriesType.META_FORECAST,
      subType: TimeSeriesSubType.META_ENSEMBLE,
    },
  ]
}

// const useIndianMarketPriceDataStreams = (): DataStream[] => {
//   const indianStates = useIndianStates()
//
//   const indianMarketDataStreams: DataStream[] = useMemo(() => {
//     if (indianStates.data) {
//       return indianStates?.data?.map((state) => {
//         return {
//           type: TimeSeriesType.MARKET_PRICE_DATA,
//           subType: TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA,
//           classifier: state,
//         }
//       })
//     }
//     return []
//   }, [indianStates.data])
//   return indianMarketDataStreams
// }

const getTurkeyMarketPriceDataStreams = (): DataStream[] => {
  return [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.TURKEY_MARKET_CLEARANCE_PRICE,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.TURKEY_SYSTEM_MARGINAL_PRICE,
    },
  ]
}

const getAustriaMarketPriceDataStreams = (): DataStream[] => {
  return [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.AUSTRIA_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_SOLAR,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.AUSTRIA_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_WIND,
    },
  ]
}

const getFranceMarketPriceDataStreams = (user: User | null): DataStream[] => {
  const freeMarketDataStreams = [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.FRANCE_M0_SOLAR,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.FRANCE_M0_WIND,
    },
  ]

  let marketDataStreamsToDisplay = [...freeMarketDataStreams]

  if (user && (isDemo(user) || isAdmin(user) || isImpersonatedAdmin(user))) {
    const franceEpexDataStream = [
      {
        type: TimeSeriesType.MARKET_PRICE_DATA,
        subType: TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA,
        classifier: TimeSeriesClassifier.FRANCE_EPEX_DAY_AHEAD_AUCTION,
      },
    ]
    marketDataStreamsToDisplay = marketDataStreamsToDisplay.concat(franceEpexDataStream)
  }

  return marketDataStreamsToDisplay
}

export const getGermanyMarketPriceDataStreams = (user: User): DataStream[] => {
  const showEpexDataStreams = hasPermissionForEpexDataStreams(user)

  const epexDataStreams = [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_EPEX_DAY_AHEAD_AUCTION,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_AUCTION,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_HIGH,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LOW,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LAST,
    },
  ]
  const marktwertDataStreams = [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_MARKTWERT_SOLAR,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_ON_SHORE,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_OFF_SHORE,
    },
  ]

  const reBapDataStreams = [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_REBAP,
    },
  ]

  const estimatedReBapDataStreams = [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_ESTIMATED_REBAP,
    },
  ]

  const revenueDataStreams = [
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
    },
    // {
    //   type: TimeSeriesType.MARKET_PRICE_DATA,
    //   subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
    //   classifier: TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    // },
    {
      type: TimeSeriesType.MARKET_PRICE_DATA,
      subType: TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA,
      classifier: TimeSeriesClassifier.GERMANY_REVENUE,
    },
  ]
  const freeMarketDataStreams = [...reBapDataStreams, ...estimatedReBapDataStreams, ...marktwertDataStreams]
  let marketDataStreamsToDisplay = [...freeMarketDataStreams]

  if (isAdmin(user) || isImpersonatedAdmin(user)) {
    marketDataStreamsToDisplay = [...epexDataStreams, ...freeMarketDataStreams, ...revenueDataStreams] // Show all
  } else {
    if (isDemo(user)) {
      marketDataStreamsToDisplay = marketDataStreamsToDisplay.concat(revenueDataStreams)
    }
    if (showEpexDataStreams) {
      marketDataStreamsToDisplay = marketDataStreamsToDisplay.concat(epexDataStreams)
    }
  }

  return marketDataStreamsToDisplay
}

const getLongRangeForecastDataStreamItems = (user: User): DataStream[] => {
  const seasonalDataStreams = [
    {
      type: TimeSeriesType.SEASONAL_FORECAST,
      subType: TimeSeriesSubType.SEASONAL_FORECAST,
      classifier: TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION,
    },
    {
      type: TimeSeriesType.SEASONAL_FORECAST,
      subType: TimeSeriesSubType.SEASONAL_FORECAST,
      classifier: TimeSeriesClassifier.SEASONAL_FORECAST_SOLAR_IRRADIATION,
    },
    {
      type: TimeSeriesType.SEASONAL_FORECAST,
      subType: TimeSeriesSubType.SEASONAL_FORECAST,
      classifier: TimeSeriesClassifier.SEASONAL_FORECAST_WIND_SPEED,
    },
  ]

  const climatologyDataStreams = [
    {
      type: TimeSeriesType.CLIMATOLOGY,
      subType: TimeSeriesSubType.CLIMATOLOGY,
      classifier: TimeSeriesClassifier.CLIMATOLOGY_GENERATION,
    },
    {
      type: TimeSeriesType.CLIMATOLOGY,
      subType: TimeSeriesSubType.CLIMATOLOGY,
      classifier: TimeSeriesClassifier.CLIMATOLOGY_SOLAR_IRRADIATION,
    },
    {
      type: TimeSeriesType.CLIMATOLOGY,
      subType: TimeSeriesSubType.CLIMATOLOGY,
      classifier: TimeSeriesClassifier.CLIMATOLOGY_WIND_SPEED,
    },
  ]

  let longRangeDataStreamsToDisplay = [...seasonalDataStreams]

  if (hasPermissionToClimatology(user)) {
    longRangeDataStreamsToDisplay = longRangeDataStreamsToDisplay.concat(climatologyDataStreams)
  }

  return longRangeDataStreamsToDisplay
}

const getCapacityDataStreamItems = (): DataStream[] => {
  return [
    {
      type: TimeSeriesType.CAPACITY_DATA,
      subType: TimeSeriesSubType.INSTALLED_CAPACITY,
    },
    {
      type: TimeSeriesType.CAPACITY_DATA,
      subType: TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY,
    },
    {
      type: TimeSeriesType.CAPACITY_DATA,
      subType: TimeSeriesSubType.MAX_AVAILABLE_CAPACITY,
    },
    {
      type: TimeSeriesType.CAPACITY_DATA,
      subType: TimeSeriesSubType.MAX_AVAILABLE_CAPACITY,
      classifier: TimeSeriesClassifier.SCADA,
    },
    {
      type: TimeSeriesType.CAPACITY_DATA,
      subType: TimeSeriesSubType.MAX_AVAILABLE_CAPACITY,
      classifier: TimeSeriesClassifier.METERING_FINAL,
    },
  ]
}

const getMeterDataStreamItems = (): DataStream[] => {
  return [
    {
      type: TimeSeriesType.METER_DATA,
    },
    {
      type: TimeSeriesType.METER_DATA,
      classifier: TimeSeriesClassifier.SCADA,
    },
    {
      type: TimeSeriesType.METER_DATA,
      classifier: TimeSeriesClassifier.METERING_FINAL,
    },
  ]
}

// hooks for getting data streams
export const useDataStreams = () => {
  const user = useSelector(getUserResultSelector)
  const areaForecastConfigs = useActiveAreaConfigs()
  const forecastConfigs = useSiteForecastConfigs()

  // Maybe used later
  // const indiaMarketPriceDataStreams = useIndianMarketPriceDataStreams()
  const metaForecastConfig = useMemo(() => getMetaForecastConfigDataStream(), [])

  const e3WeatherModelsForecastConfigs = useMemo(() => {
    const defaultE3ModelConfigs = getDefaultE3ModelLevelForecastConfigs()
    return (Object.keys(defaultE3ModelConfigs) as E3ForecastModel[]).map<DataStream>((model) => ({
      id: model,
      name: getE3ModelLabel(model),
      type: TimeSeriesType.E3_META_FORECAST,
      subType: TimeSeriesSubType.E3_WEATHER_TRACK,
    }))
  }, [])

  // Third party forecast configs
  const e3ThirdPartyForecastConfigs = useMemo(() => {
    const thirdPartyForecastConfigs = (forecastConfigs.data || [])
      .filter((config) => config.category === ForecastConfigurationCategory.EXTERNAL_SITE_FORECAST)
      .map((config) => {
        return {
          id: config.id || undefined,
          name: config.name,
          type: TimeSeriesType.E3_META_FORECAST,
          subType: TimeSeriesSubType.E3_THIRD_PARTY_FORECAST,
          customUICategory: TimeSeriesType.THIRD_PARTY,
        }
      })

    return (thirdPartyForecastConfigs || []).sort((a, b) => {
      const valueA = a.name.toUpperCase()
      const valueB = b.name.toUpperCase()
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    })
  }, [forecastConfigs.data])

  // Schedule
  const scheduleItems: DataStream[] = useMemo(() => {
    const scheduleConfigs = (forecastConfigs.data || [])
      .filter((config) => config.category === ForecastConfigurationCategory.SITE_SCHEDULE)
      .map((config) => {
        return {
          id: config.id || undefined,
          name: config.name,
          type: TimeSeriesType.SCHEDULE,
          // TODO single delivery times: https://gitlab.enercast.de/enercast/reui/issues/164
        }
      })

    return (scheduleConfigs || []).sort((a, b) => {
      const valueA = a.name.toUpperCase()
      const valueB = b.name.toUpperCase()
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    })
  }, [forecastConfigs.data])

  // Site forecast
  const siteForecastItems: DataStream[] = useMemo(() => {
    const siteForecastConfigs = (forecastConfigs.data || [])
      .filter((config) => config.category === ForecastConfigurationCategory.ENERCAST_SITE_FORECAST)
      .map((forecastConfig) => {
        return {
          id: forecastConfig.id || undefined,
          name: forecastConfig.name,
          type: TimeSeriesType.SITE_FORECAST,
          // TODO single delivery times: https://gitlab.enercast.de/enercast/reui/issues/164
        }
      })

    const isMaximumHorizonSiteForecast = (forecastConfigs.data || []).find((config) => config.maximumHorizon)
    let maximumHorizonSiteForecastObject = undefined

    if (isMaximumHorizonSiteForecast) {
      maximumHorizonSiteForecastObject = {
        id: isMaximumHorizonSiteForecast?.id || undefined,
        name: isMaximumHorizonSiteForecast?.name,
        type: TimeSeriesType.SITE_FORECAST,
      }
    }

    const defaultConfig = (siteForecastConfigs || []).find((config) => config.name === 'Default')
    const configsWithoutSpecialSiteForecasts = (siteForecastConfigs || []).filter((config) => {
      if (isMaximumHorizonSiteForecast) {
        return config.name !== 'Default' && config.id !== isMaximumHorizonSiteForecast.id
      }
      return config.name !== 'Default'
    })

    const sortedConfigsWithoutSpecialSiteForecasts = (configsWithoutSpecialSiteForecasts || []).sort((a, b) => {
      const valueA = a.name.toUpperCase()
      const valueB = b.name.toUpperCase()
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    })

    if (defaultConfig && maximumHorizonSiteForecastObject) {
      return [defaultConfig, ...maximumHorizonSiteForecastObject, ...sortedConfigsWithoutSpecialSiteForecasts]
    } else if (defaultConfig && !maximumHorizonSiteForecastObject) {
      return [defaultConfig, ...sortedConfigsWithoutSpecialSiteForecasts]
    } else if (!defaultConfig && maximumHorizonSiteForecastObject) {
      return [maximumHorizonSiteForecastObject, ...sortedConfigsWithoutSpecialSiteForecasts]
    }

    return [...sortedConfigsWithoutSpecialSiteForecasts]
  }, [forecastConfigs.data])

  const weatherConfigs = useActiveWeatherConfigs()
  const weatherCatalog = useWeatherCatalog()
  const weatherConfigItems: DataStream[] = useMemo(
    () =>
      (weatherConfigs.data || []).map((weatherConfig) => ({
        id: weatherConfig.id,
        name: weatherCatalog.data?.UniqueCatalogLabel?.[weatherConfig.id] || weatherConfig.id,
        type: TimeSeriesType.WEATHER_DATA,
      })),
    [weatherConfigs.data, weatherCatalog.data],
  )

  const areaForecastItems: DataStream[] = useMemo(
    () =>
      (areaForecastConfigs.data || []).map((areaConfig) => ({
        id: areaConfig.productCode,
        name: areaConfig.name,
        type: TimeSeriesType.AREA_FORECAST,
      })),
    [areaForecastConfigs.data],
  )

  const meterDataItems: DataStream[] = useMemo(() => getMeterDataStreamItems(), [])
  const capacityDataItems: DataStream[] = useMemo(() => getCapacityDataStreamItems(), [])
  const longRangeForecastItems: DataStream[] = useMemo(() => getLongRangeForecastDataStreamItems(user), [user])

  const marketPriceItems: DataStream[] = useMemo(() => {
    // Maybe used later
    // const indianItems = indiaMarketPriceDataStreams
    const germanItems = getGermanyMarketPriceDataStreams(user)
    const turkeyItems = getTurkeyMarketPriceDataStreams()
    const austriaItems = getAustriaMarketPriceDataStreams()
    const franceItems = getFranceMarketPriceDataStreams(user)

    const marketDataStreamsToDisplay: DataStreamItem[] = [
      ...germanItems,
      ...franceItems,
      ...austriaItems,
      ...turkeyItems,
    ]

    return marketDataStreamsToDisplay
  }, [user])

  return useMemo(() => {
    const dataStreams = enrichDataStreamsAfterLoad([
      ...(hasPermissionForSiteForecast(user) ? siteForecastItems : []),
      ...(hasPermissionForLongRangeForecast(user) ? longRangeForecastItems : []),
      ...(hasPermissionForMetaForecast(user) ? metaForecastConfig : []),
      ...(hasPermissionForE3ModelLevelForecast(user) ? e3WeatherModelsForecastConfigs : []),
      ...(hasPermissionTo3rdPartyForecasts(user) ? e3ThirdPartyForecastConfigs : []),
      ...(hasPermissionForMeterData(user) ? meterDataItems : []),
      // All market price data streams are free except EPOX SPOT price, and all of them are displayed based on role
      ...marketPriceItems,
      ...scheduleItems,
      ...(hasPermissionForCapacityData(user) ? capacityDataItems : []),
      ...(hasPermissionForWeatherData(user) || isAdmin(user) || isImpersonatedAdmin(user) ? weatherConfigItems : []),
      ...(hasPermissionForAreaForecast(user) ? areaForecastItems : []),
    ])

    return dataStreams
  }, [
    siteForecastItems,
    metaForecastConfig,
    e3WeatherModelsForecastConfigs,
    e3ThirdPartyForecastConfigs,
    meterDataItems,
    capacityDataItems,
    weatherConfigItems,
    areaForecastItems,
    longRangeForecastItems,
    marketPriceItems,
  ])
}

// data stream model handling

export const enrichDataStreamsAfterLoad = (dataStreams: DataStream[]): DataStream[] => {
  return dataStreams.map((dataStream) => {
    const id = getDataStreamId(dataStream)
    const name = getDataStreamName({ id, ...dataStream })
    return {
      id,
      name,
      ...dataStream,
      uiAncestors: [dataStream.customUICategory ? dataStream.customUICategory : dataStream.type],
      uiParents: [dataStream.customUICategory ? dataStream.customUICategory : dataStream.type],
      uiDescendants: [],
      uiChildren: [],
      uiLevel: 1,
    }
  })
}

// Area Data streams

export interface AreaProductMeta {
  EXPORT_NAME: string
}
export interface AreaProduct {
  productCode: string
  id: string
  name?: string
  permit: boolean
  tsURL: string
  type: string
  shape: string
  args: any
  meta?: AreaProductMeta
}

type GetAreaProductsData = () => Record<string, AreaProduct>
export const getAreaProductsData: GetAreaProductsData = () => {
  return {
    '1-1022': {
      productCode: '1-1022',
      id: 'area_germany_pv',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_pv_pnode',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyPvRealtimeWeb' },
    },
    '1-1136': {
      productCode: '1-1136',
      id: 'area_germany_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_aggregated',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyAggregatedRealtimeWeb' },
    },
    'DEMO-1-1337': {
      productCode: 'DEMO-1-1337',
      id: 'area_germany_aggregated_einsman',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_aggregated',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyAggregatedRealtimeWeb' },
    },
    '1-1025': {
      productCode: '1-1025',
      id: 'area_germany_pv_amprion_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_pv_amprion_aggregated',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyPvRealtimeWeb' },
    },
    '1-1023': {
      productCode: '1-1023',
      id: 'area_germany_pv_hz50_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_pv_hz50_aggregated',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyPvRealtimeWeb' },
    },
    '1-1027': {
      productCode: '1-1027',
      id: 'area_germany_pv_tennet_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_pv_tennet_aggregated',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyPvRealtimeWeb' },
    },
    '1-1029': {
      productCode: '1-1029',
      id: 'area_germany_pv_transnetbw_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_pv_transnetbw_aggregated',
      type: 'solar',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyPvRealtimeWeb' },
    },
    '1-1019': {
      productCode: '1-1019',
      id: 'area_germany_wind',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind',
      type: 'wind',
      shape: 'DE',
      args: null,
    },
    '1-1020': {
      productCode: '1-1020',
      id: 'area_germany_wind_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind_aggregated',
      type: 'wind',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyWindRealtimeWeb' },
    },

    '1-1021': {
      productCode: '1-1021',
      id: 'area_germany_wind_offshore',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind_offshore',
      type: 'wind',
      shape: 'DE',
      args: null,
    },
    '1-1062': {
      productCode: '1-1062',
      id: 'area_germany_wind_50hertz_onshore_offshore',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind_50hertz_onshore_offshore',
      type: 'wind',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyTSOWindWeb' },
    },
    '1-1063': {
      productCode: '1-1063',
      id: 'area_germany_wind_amprion_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind_amprion_aggregated',
      type: 'wind',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyTSOWindWeb' },
    },
    '1-1064': {
      productCode: '1-1064',
      id: 'area_germany_wind_tennet_onshore_offshore',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind_tennet_onshore_offshore',
      type: 'wind',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyTSOWindWeb' },
    },
    '1-1065': {
      productCode: '1-1065',
      id: 'area_germany_wind_transnetbw_aggregated',
      permit: false,
      tsURL: 'pfa/area_germany/area_germany_wind_transnetbw_aggregated',
      type: 'wind',
      shape: 'DE',
      args: null,
      meta: { EXPORT_NAME: 'AreaGermanyTSOWindWeb' },
    },
  }
}

export const prepareAreaConfigBeforeSaving = (areaConfigs: AreaConfig[] | AreaProduct[]) => {
  return areaConfigs.map((config: AreaConfig | AreaProduct) => {
    return {
      id: config.id,
      productCode: config.productCode,
      args: config['args'] ? config.args.map((a) => a.value) : null,
    }
  })
}

export const transformAreaConfigs = (areaConfigs: AreaConfig[]) => {
  const areaIds = getAreaIds()
  return (areaConfigs || []).map<Area>((areaConfig) => ({
    type: TYPE_AREA,
    id: areaConfig.id,
    name: areaIds[areaConfig.id] || 'unknown area',
    productCode: areaConfig.productCode,
  }))
}

export const transformMetaDataHierarchy = (metaDataHierarchy: MetaDataHierarchy) => {
  // make structure unique to distinguish history and recent forecasts

  metaDataHierarchy.forEach((data) => {
    if (
      Array.isArray(data.structure) &&
      data.structure.length > 0 &&
      data.structure[0].value === 'weatherforecast rec'
    ) {
      data.structure[1].value += ' rec'
      data.structure[2].value += ' rec'
    }
  })
  return metaDataHierarchy
}

// Data streams ReTable columns
export const ConfigureDataStreamTableCellWidth = '25em'

export const getAllAreaForecastsColumns: () => Column[] = () => [
  {
    name: 'name',
    label: t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: ConfigureDataStreamTableCellWidth,
    fixed: true,
  },
]

export const getAllWeatherDataStreamsColumns: () => Column[] = () => [
  {
    name: 'name',
    label: t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: ConfigureDataStreamTableCellWidth,
    fixed: true,
  },
]

export const getAvailableE3ForecastColumns: () => Column[] = () => [
  {
    name: 'name',
    label: t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: ConfigureDataStreamTableCellWidth,
    fixed: true,
  },
]

export const getSiteForecastTemplatesColumns: () => Column[] = () => [
  {
    name: 'name',
    label: t`Template name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: ConfigureDataStreamTableCellWidth,
    fixed: true,
  },
]

const findDataStreamItemIndex = (subType: TimeSeriesSubType, dataStreams: DataStreamItem[]) => {
  return (dataStreams || []).findIndex((dsot: DataStream) => dsot.subType === subType)
}

export const addDataStreamsToTheirCategories = (dataStreams: DataStream[]) => {
  const {
    INDIAN_MARKET_PRICE_DATA,
    GERMANY_MARKET_PRICE_DATA,
    FRANCE_MARKET_PRICE_DATA,
    AUSTRIA_MARKET_PRICE_DATA,
    TURKEY_MARKET_PRICE_DATA,
    SEASONAL_FORECAST,
    CLIMATOLOGY,
  } = TimeSeriesSubType

  const dataStreamSubTypes = [
    INDIAN_MARKET_PRICE_DATA,
    GERMANY_MARKET_PRICE_DATA,
    FRANCE_MARKET_PRICE_DATA,
    AUSTRIA_MARKET_PRICE_DATA,
    TURKEY_MARKET_PRICE_DATA,
    SEASONAL_FORECAST,
    CLIMATOLOGY,
  ]

  let rowItems: DataStreamRowItem[] = []
  DataStreamOrder.forEach((type) => {
    const dataStreamType = { id: type }

    const dataStreamsOfType = dataStreams
      .filter((item) => {
        if (type === TimeSeriesType.SITE_FORECAST) {
          // We need to put the meta forecast datastream in site forecast category
          const isMetaForecastDataStream = item.type === TimeSeriesType.META_FORECAST
          return item.type === type || isMetaForecastDataStream
          // We are adding to third party category only data stream with E3_THIRD_PARTY_FORECAST as subtype
        } else if (type === TimeSeriesType.THIRD_PARTY) {
          return item.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST
          // Since we added above , we need to remove from this type
        } else if (type === TimeSeriesType.E3_META_FORECAST) {
          return item.type === type && item.subType !== TimeSeriesSubType.E3_THIRD_PARTY_FORECAST
        } else {
          return item.type === type
        }
      })
      .map((item) => {
        return {
          ...item,
          uiAncestors: [item.customUICategory ? item.customUICategory : dataStreamType.id],
          uiParents: [item.customUICategory ? item.customUICategory : dataStreamType.id],
        }
      })

    dataStreamType.uiChildren = dataStreamsOfType.map((dsot) => dsot.id)
    dataStreamType.uiDescendants = dataStreamsOfType.map((dsot) => dsot.id)

    // Data streams which has two level hierarchy we need to add a new sub parents (like Market and seasonal)
    // to all matching datastreams with  subtype
    if (type === TimeSeriesType.MARKET_PRICE_DATA) {
      dataStreamSubTypes.forEach((dataStreamSubType: TimeSeriesSubType) => {
        const dataStreamsOfMarketSubType = dataStreamsOfType.filter((dsot) => dsot.subType === dataStreamSubType)

        const dataStreamItemsIndex = findDataStreamItemIndex(dataStreamSubType, dataStreamsOfType)
        if (dataStreamItemsIndex >= 0) {
          const dataStreamWithParentAndChild = {
            id: dataStreamSubType,
            uiAncestors: [dataStreamType.id],
            uiParents: [dataStreamType.id],
            uiChildren: (dataStreamsOfMarketSubType || []).map((item) => item.id),
            uiLevel: 1,
          }

          // Add a subtype as parents to Datastreams of matching subtypes
          dataStreamsOfType.forEach((dsot) => {
            if (dsot.subType === dataStreamSubType) {
              dsot['uiAncestors'] = [...dsot['uiAncestors'], dataStreamSubType]
              dsot['uiParents'] = [...dsot['uiParents'], dataStreamSubType]
              dsot['uiLevel'] = 2
            }
          })

          // Add a subtype as children to Datastream type Market
          dataStreamType.uiChildren = [...dataStreamType.uiChildren, dataStreamSubType]
          dataStreamType.uiDescendants = [...dataStreamType.uiDescendants, dataStreamSubType]

          // Add the subtype (sub parent) to the datastreams
          dataStreamsOfType.splice(dataStreamItemsIndex, 0, dataStreamWithParentAndChild)
        }
      })
    }

    if (dataStreamsOfType.length) {
      rowItems.push(dataStreamType)
      rowItems = rowItems.concat(dataStreamsOfType)
    } else {
      // Add another if condition , if you want to show a type by default even though there is no data stream inside this category
      if (dataStreamType.id === TimeSeriesType.AREA_FORECAST) {
        rowItems.push(dataStreamType)
        rowItems = rowItems.concat(dataStreamsOfType)
      }
    }
  })
  return rowItems
}

export const getMarketPriceDataBaseUrl = (dataStream: DataStreamSelectionItem) => {
  const { subType, classifier } = dataStream
  if (subType === TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA && classifier) {
    return `/api/v1/ts/normal_rates/${capitalizeString(classifier.replace('_', ' '))}/`
  }
  const {
    GERMANY_EPEX_DAY_AHEAD_AUCTION,
    GERMANY_EPEX_INTRA_DAY_AUCTION,
    GERMANY_EPEX_INTRA_DAY_CONTINUOUS_HIGH,
    GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LOW,
    GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
    GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LAST,
    GERMANY_REBAP,
    GERMANY_ESTIMATED_REBAP,
    GERMANY_MARKTWERT_WIND_OFF_SHORE,
    GERMANY_MARKTWERT_WIND_ON_SHORE,
    TURKEY_MARKET_CLEARANCE_PRICE,
    TURKEY_SYSTEM_MARGINAL_PRICE,
    GERMANY_MARKTWERT_SOLAR,
    FRANCE_EPEX_DAY_AHEAD_AUCTION,
    FRANCE_M0_SOLAR,
    FRANCE_M0_WIND,
    AUSTRIA_REFERENZ_MARKTWERT_SOLAR,
    AUSTRIA_REFERENZ_MARKTWERT_WIND,
  } = TimeSeriesClassifier
  const dataStreamClassifier = dataStream.classifier
  switch (dataStreamClassifier) {
    case GERMANY_EPEX_DAY_AHEAD_AUCTION:
      return '/api/v1/ts/eex/germany/spotmarket/price/'
    case GERMANY_EPEX_INTRA_DAY_AUCTION:
      return '/api/v1/ts/eex/germany/intradayauction/price/'
    case GERMANY_EPEX_INTRA_DAY_CONTINUOUS_HIGH:
      return '/api/v1/ts/eex/germany/intradaycontinuous/highprice/'
    case GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LOW:
      return '/api/v1/ts/eex/germany/intradaycontinuous/lowprice/'
    case GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE:
      return '/api/v1/ts/eex/germany/intradaycontinuous/avgprice/'
    case GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LAST:
      return '/api/v1/ts/eex/germany/intradaycontinuous/lastprice/'
    case GERMANY_MARKTWERT_SOLAR:
      return '/api/v1/ts/netztransparenz/marktwert_1/mw_solar/eurocent_kwh/'
    case GERMANY_MARKTWERT_WIND_OFF_SHORE:
      return '/api/v1/ts/netztransparenz/marktwert_1/mw_wind_offshore/eurocent_kwh/'
    case GERMANY_MARKTWERT_WIND_ON_SHORE:
      return '/api/v1/ts/netztransparenz/marktwert_1/mw_wind_onshore/eurocent_kwh/'
    case GERMANY_REBAP:
      return '/api/v1/ts/netztransparenz/marktwert/rebap/euro_mwh/'
    case GERMANY_ESTIMATED_REBAP:
      return '/api/v1/ts/netztransparenz/AepSchaetzer/price/euro_mwh/'
    case FRANCE_EPEX_DAY_AHEAD_AUCTION:
      return '/api/v1/ts/eex/france/spotmarket/price/'
    case FRANCE_M0_SOLAR:
      return '/api/v2/ts/france/marktwert/mw_solar/euro_mwh/'
    case FRANCE_M0_WIND:
      return '/api/v2/ts/france/marktwert/mw_wind/euro_mwh/'
    case TURKEY_MARKET_CLEARANCE_PRICE:
      return '/api/v1/ts/epias/transparency/mcp/lira_mwh/'
    case TURKEY_SYSTEM_MARGINAL_PRICE:
      return '/api/v1/ts/epias/transparency/smp/lira_mwh/'
    case AUSTRIA_REFERENZ_MARKTWERT_SOLAR:
      return '/api/v2/ts/austria/referenzmarktwert/solar/eurocent_kwh/'
    case AUSTRIA_REFERENZ_MARKTWERT_WIND:
      return '/api/v2/ts/austria/referenzmarktwert/wind/eurocent_kwh/'
  }
}

/**
 * Description: This function is used in Data Stream table , to show a tooltip for different data stream type.
 * @param: classifier
 * @param: subType
 */
export const getTooltipContentForDataStreamRow = (
  classifier: TimeSeriesClassifier | undefined,
  subType: TimeSeriesSubType | undefined,
  revenueRelatedSiteForecast?: any,
) => {
  const {
    GERMANY_MARKTWERT_WIND_ON_SHORE,
    GERMANY_MARKTWERT_WIND_OFF_SHORE,
    GERMANY_MARKTWERT_SOLAR,
    GERMANY_REBAP,
    GERMANY_ESTIMATED_REBAP,
    TURKEY_SYSTEM_MARGINAL_PRICE,
    TURKEY_MARKET_CLEARANCE_PRICE,
    GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
    GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE,
    FRANCE_M0_SOLAR,
    FRANCE_M0_WIND,
    AUSTRIA_REFERENZ_MARKTWERT_WIND,
    AUSTRIA_REFERENZ_MARKTWERT_SOLAR,
  } = TimeSeriesClassifier

  const dataSourceClickHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation()
  }

  enum getSourcesTypes {
    href = 'href',
    text = 'text',
  }

  const getSources = (type: getSourcesTypes) => {
    if (subType == TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA) {
      if (type === getSourcesTypes.href) {
        if (classifier === GERMANY_REBAP) {
          return 'https://www.netztransparenz.de/WebAPI'
        }
        if (classifier === GERMANY_ESTIMATED_REBAP) {
          return 'https://www.netztransparenz.de/Daten-zur-Regelenergie/AEP-Schaetzer'
        }
        if (
          classifier === GERMANY_MARKTWERT_SOLAR ||
          classifier === GERMANY_MARKTWERT_WIND_ON_SHORE ||
          classifier === GERMANY_MARKTWERT_WIND_OFF_SHORE
        ) {
          return 'https://www.netztransparenz.de/de-de/Erneuerbare-Energien-und-Umlagen/EEG/Transparenzanforderungen/Marktpr%C3%A4mie/Marktwert%C3%BCbersicht'
        }
        // This is just a fallback
        return 'https://www.netztransparenz.de'
      }

      return 'netztransparenz.de'
    } else if (subType == TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA) {
      if (type === getSourcesTypes.href) {
        if (classifier === FRANCE_M0_SOLAR || classifier === FRANCE_M0_WIND) {
          return 'https://www.centrales-next.fr/glossaire-energies-renouvelables/m0-mzero'
        }
        // This is just a fallback
        return 'https://www.centrales-next.fr'
      }

      return 'centrales-next.fr'
    } else if (subType == TimeSeriesSubType.AUSTRIA_MARKET_PRICE_DATA) {
      return 'https://www.e-control.at/referenzmarktwert'
    } else if (subType == TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA) {
      if (type === getSourcesTypes.text) {
        return 'EPİAŞ Şeffaflık Platformu'
      } else {
        if (classifier == TURKEY_SYSTEM_MARGINAL_PRICE) {
          return 'https://seffaflik.epias.com.tr/transparency/piyasalar/dgp/smf.xhtml'
        } else if (classifier == TURKEY_MARKET_CLEARANCE_PRICE) {
          return 'https://seffaflik.epias.com.tr/transparency/piyasalar/gop/ptf.xhtml'
        }
        //This is just a fallback
        return 'https://seffaflik.epias.com.tr'
      }
    }
  }

  const possibleEquationExplanationType = {
    E_SIMULATION_DA: 'E_SIMULATION_DA',
    E_SIMULATION_ID: 'E_SIMULATION_ID',
  }

  const getEquationExplanation = {
    [possibleEquationExplanationType.E_SIMULATION_DA]: (
      <>{revenueRelatedSiteForecast ? revenueRelatedSiteForecast?.name : t`[DA]`}</>
    ),
    [possibleEquationExplanationType.E_SIMULATION_ID]: (
      <>{revenueRelatedSiteForecast ? revenueRelatedSiteForecast?.name : t`[ID]`}</>
    ),
  }

  const getEquation = () => {
    if (classifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST) {
      return (
        <>
          <span style={{ marginBottom: '4px' }}>
            (<>{getEquationExplanation[possibleEquationExplanationType.E_SIMULATION_DA]}</> -{' '}
            <>{c('Workbench:TimeSeries').t`Meter data (latest)`}</>) * {t`reBAP`}
          </span>
        </>
      )
    } else if (classifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST) {
      return (
        <>
          <span style={{ marginBottom: '4px' }}>
            (<>{getEquationExplanation[possibleEquationExplanationType.E_SIMULATION_ID]}</> -{' '}
            <>{c('Workbench:TimeSeries').t`Meter data (latest)`}</>) * {t`reBAP`}
          </span>
        </>
      )
    } else if (classifier === GERMANY_REVENUE_DAY_AHEAD_REVENUE) {
      return (
        <>
          <span style={{ marginBottom: '4px' }}>
            <>{getEquationExplanation[possibleEquationExplanationType.E_SIMULATION_DA]}</> *{' '}
            {c('Workbench:TimeSeries').t`EPEX SPOT Day-Ahead Auction`}
          </span>
        </>
      )
    } else if (classifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE) {
      return (
        <>
          <span style={{ marginBottom: '6px' }}>
            <>{getEquationExplanation[possibleEquationExplanationType.E_SIMULATION_DA]}</> *{' '}
            {c('Workbench:TimeSeries').t`EPEX SPOT Day-Ahead Auction`} + ({' '}
            <>{getEquationExplanation[possibleEquationExplanationType.E_SIMULATION_DA]}</> -{' '}
            <>{getEquationExplanation[possibleEquationExplanationType.E_SIMULATION_ID]}</> ) *{' '}
            {c('Workbench:TimeSeries').t`EPEX SPOT Intraday continuous average`}
          </span>
        </>
      )
    }
    return null
  }

  const linkToDataSource = (
    <a
      key="dataSource"
      href={getSources(getSourcesTypes.href)}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => dataSourceClickHandler(e)}
    >
      {getSources(getSourcesTypes.text)}
    </a>
  )

  const tooltipContent: any = {
    [GERMANY_MARKTWERT_WIND_ON_SHORE]: (
      <>
        {t`Energy source-specific monthly market value for onshore wind energy.`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [GERMANY_MARKTWERT_WIND_OFF_SHORE]: (
      <>
        {t`Energy source-specific monthly market value for offshore wind energy.`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [GERMANY_MARKTWERT_SOLAR]: (
      <>
        {t`Energy source-specific monthly market value for solar energy.`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [GERMANY_REBAP]: (
      <>
        {t`Uniform imbalance price across control areas.`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [GERMANY_ESTIMATED_REBAP]: (
      <>
        {t`Estimated balancing energy price (AEP-Schätzer).`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [TURKEY_SYSTEM_MARGINAL_PRICE]: (
      <>
        {t`System marginal price.`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [TURKEY_MARKET_CLEARANCE_PRICE]: (
      <>
        {t`Market clearing price.`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [FRANCE_M0_SOLAR]: (
      <>
        {t`M zero Solar.`}
        {/*<br />*/}
        {/*<span>{jt`Data source: ${linkToDataSource}`}</span>*/}
      </>
    ),

    [FRANCE_M0_WIND]: (
      <>
        {t`M zero Wind.`}
        {/*<br />*/}
        {/*<span>{jt`Data source: ${linkToDataSource}`}</span>*/}
      </>
    ),

    [AUSTRIA_REFERENZ_MARKTWERT_SOLAR]: (
      <>
        {t`Reference market value according to § 13 Renewable Energy Expansion Act (EAG)`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [AUSTRIA_REFERENZ_MARKTWERT_WIND]: (
      <>
        {t`Reference market value according to § 13 Renewable Energy Expansion Act (EAG)`}
        <br />
        <span>{jt`Data source: ${linkToDataSource}`}</span>
      </>
    ),

    [GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST]: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>{getEquation()}</div>
    ),
    [GERMANY_REVENUE_INTRA_DAY_BALANCING_COST]: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>{getEquation()}</div>
    ),
    [GERMANY_REVENUE_DAY_AHEAD_REVENUE]: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>{getEquation()}</div>
    ),
    [GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE]: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>{getEquation()}</div>
    ),
  }

  if (classifier && tooltipContent[classifier]) {
    return tooltipContent[classifier]
  }

  return false
}

interface getDataStreamsRequiredForEquationsProps {
  equationDataStream: DataStreamSelectionItem
  allDataStreams: ReTableItem[]
  prevSelection: DataStreamSelectionItem[]
}

// Check if the required datastreams are present in the previous selection
// If yes just return empty
// If no create an array of datastreams from allDatastreams

export const getDataStreamsRequiredForEquations = ({
  equationDataStream,
  allDataStreams,
  prevSelection,
}: getDataStreamsRequiredForEquationsProps): DataStreamSelectionItem[] => {
  const {
    GERMANY_REBAP,
    GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
    GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE,
    GERMANY_REVENUE,
    GERMANY_EPEX_DAY_AHEAD_AUCTION,
    GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
  } = TimeSeriesClassifier
  let colourCount = prevSelection.length
  const equationClassifier = equationDataStream.classifier
  // console.log({ allDataStreams, prevSelection, equationDataStream })
  const requiredDataStreams = []
  const atLeastOneRevenueSelected = prevSelection.some((sel) => sel.classifier?.includes(GERMANY_REVENUE_SEARCH_KEY))
  if (!atLeastOneRevenueSelected) {
    return []
  }

  const isBalancingCostDayAheadSelected = prevSelection.some(
    (sel) => sel.classifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
  )

  const isBalancingCostIntraDaySelected = prevSelection.some(
    (sel) => sel.classifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
  )

  // ALL BUT SECOND EQUATION: We need Day ahead for all cost equations except for intra day balancing cost equation , and germany revenue
  if (atLeastOneRevenueSelected && equationClassifier !== GERMANY_REVENUE_INTRA_DAY_BALANCING_COST) {
    const isDayAheadForecastSelected = prevSelection.some(
      (sel) =>
        (sel?.name?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY) ||
          sel?.label?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY)) &&
        sel.type === TimeSeriesType.SITE_FORECAST,
    )

    if (!isDayAheadForecastSelected) {
      // Dayahead: find out Dayahead datastreams from all datastreams
      const dayAheadDataStream = allDataStreams.find(
        (d) =>
          d?.name?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY) && d.type === TimeSeriesType.SITE_FORECAST,
      )
      colourCount = colourCount + 1
      requiredDataStreams.push({ ...dayAheadDataStream, color: colourCount })
    }
  }

  // FIRST AND SECOND EQUATIONS: We need meter data and rebap for Intra day and day ahead equations
  if (
    (equationClassifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST && isBalancingCostDayAheadSelected) ||
    (equationClassifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST && isBalancingCostIntraDaySelected)
  ) {
    const isMeterDataLatestSelected = prevSelection.some(
      (sel) => sel.type === TimeSeriesType.METER_DATA && !sel.classifier,
    )

    if (!isMeterDataLatestSelected) {
      // Meterdatastreams: find out Meterdatastreams from all datastreams
      const meterDataLatestDataStream = allDataStreams.find(
        (d) => d.type === TimeSeriesType.METER_DATA && !d.classifier,
      )
      colourCount = colourCount + 1
      requiredDataStreams.push({ ...meterDataLatestDataStream, color: colourCount })
    }

    const isReBapSelected = prevSelection.some((sel) => sel?.classifier === TimeSeriesClassifier.GERMANY_REBAP)

    if (!isReBapSelected) {
      // ReBap: find out rebap datastreams from all datastreams
      const reBapDataStream = allDataStreams.find((d) => d?.classifier === GERMANY_REBAP)
      colourCount = colourCount + 1
      requiredDataStreams.push({ ...reBapDataStream, color: colourCount })
    }
  }

  // SECOND and FOURTH EQUATION: We need Intra day datastream for Intra day balancing cost equation
  if (
    atLeastOneRevenueSelected &&
    (equationClassifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST ||
      equationClassifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE)
  ) {
    // IntraDay: find out Intraday datastreams from all datastreams
    const intraDayDataStream = allDataStreams.find(
      (d) =>
        d?.name?.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY) && d.type === TimeSeriesType.SITE_FORECAST,
    )

    const isIntraDayForecastSelected = prevSelection.some(
      (sel) =>
        (sel?.name?.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY) ||
          sel?.label?.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY)) &&
        sel.type === TimeSeriesType.SITE_FORECAST,
    )
    if (!isIntraDayForecastSelected) {
      colourCount = colourCount + 1
      requiredDataStreams.push({ ...intraDayDataStream, color: colourCount })
    }
  }

  // THIRD and FOURTH EQUATION and Germany Revenue: We need Epex spot day ahead datastream for Day ahead revenue cost equation
  if (
    atLeastOneRevenueSelected &&
    (equationClassifier === GERMANY_REVENUE_DAY_AHEAD_REVENUE ||
      equationClassifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE ||
      equationClassifier === GERMANY_REVENUE)
  ) {
    const isEpexSpotDayAheadSelected = prevSelection.some((sel) => sel?.classifier === GERMANY_EPEX_DAY_AHEAD_AUCTION)

    if (!isEpexSpotDayAheadSelected) {
      const epexSpotDayAheadDataStream = allDataStreams.find((d) => d?.classifier === GERMANY_EPEX_DAY_AHEAD_AUCTION)
      colourCount = colourCount + 1
      requiredDataStreams.push({ ...epexSpotDayAheadDataStream, color: colourCount })
    }
  }

  // FOURTH EQUATION: We need Epex spot intra day continuous average datastream for Day-Ahead & Intraday revenue
  if (atLeastOneRevenueSelected && equationClassifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE) {
    const isEpexSpotIntraDayContinuousAverageSelected = prevSelection.some(
      (sel) => sel?.classifier === GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
    )

    if (!isEpexSpotIntraDayContinuousAverageSelected) {
      const epexSpotIntraDayContinuousAverageDataStream = allDataStreams.find(
        (d) => d?.classifier === GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
      )
      colourCount = colourCount + 1
      requiredDataStreams.push({ ...epexSpotIntraDayContinuousAverageDataStream, color: colourCount })
    }
  }

  return requiredDataStreams
}
