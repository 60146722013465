import React from 'react'
import styled from 'styled-components'

import logo from 'media/enercast-logo.svg'

interface LogoStyleAttributes {
  height?: string
}

interface LogoAttributes {
  height?: string
}

const Image = styled.img<LogoStyleAttributes>`
  height: ${(props) => (props.height ? props.height : '1.8em')};
`

const Logo: React.FC<LogoAttributes> = ({ height }) => <Image height={height} src={logo} alt="enercast Logo" />

export default Logo
