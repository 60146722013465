import React, { useContext, useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import { t } from 'ttag'
import { BulkMdcRowObject } from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.types'
import { useAllAssets } from 'modules/asset/api/assets.api'
import { Box, Button } from '@material-ui/core'
import { theme } from 'themes/theme-light'
import { Link } from 'react-router-dom'
import { getAssetQueryObj, stringifyQueryParams } from 'utils/query-string'
import { ROUTE_METER_DATA_BULK_CLEANSING } from 'modules/app/Routes'
import { assetTabNames } from 'fixtures/assetForm'
import {
  restartMdcForAsset,
  useDefaultMDCFilterSettings,
  useEnercastMDCFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { FilterSettingsContext } from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMdc'
import {
  MDCAssetType,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import {
  BULK_FILTER_SETTINGS_SOLAR,
  BULK_FILTER_SETTINGS_WIND,
  getDefaultValuesForBulkMdcFilterSettings,
  isValidAssetForMDC,
} from 'utils/meterDataCleansing'
import { getDifference } from 'utils/array'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'
import BulkMDCRowStatistics from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingBody/BulkMDCRowStatistics'
import BulkMDCBeforeAfterFilteringChart from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingBody/BulkMDCBeforeAfterFilteringChart'
import { isSolarAsset, isWindAsset } from 'utils/asset'

// Main div
const BulkMdcRowDiv = styled(Flex)`
  margin-bottom: 2em;
`

// Information div
export const StatisticsContainer = styled.div`
  flex: 1 1 0;
  min-width: 17vw;
  max-width: 30vw;
  p {
    margin: auto 0 auto 7px;
  }
  @media (max-width: 1440px) {
    min-width: 100% !important;
  }
`

// Image div
export const BeforeAfterChartContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 1 1 0;
  height: 35vh;
  width: 43vw;
  @media (max-width: 1440px) {
    justify-content: center;
  }
`

//Individual filter button
const IndividualFilterButton = styled(Flex)`
  min-width: 13em;
  max-width: 13em;
  & .details-link {
    text-decoration: none;
  }
`

const TooltipDiv = styled.div`
  margin-left: 5px;
`

interface BulkMdcRowProps {
  rowData: BulkMdcRowObject
}

const BulkMdcRow: React.FC<BulkMdcRowProps> = ({ rowData }) => {
  const allAssets = useAllAssets()
  const { assetId, cleansingStatus, failureReason, meterDataRaw } = rowData

  // Getting filters
  const { triggerFilterSettings, setTriggerFilterSettings } = useContext(FilterSettingsContext)
  const { refresh, setRefresh } = useContext(FilterSettingsContext)

  const defaultWindFilterSettings = useDefaultMDCFilterSettings(MDCAssetType.WIND)
  const enercastWindFilterSettings = useEnercastMDCFilterSettings(MDCAssetType.WIND)

  // Managing result from filters
  const {
    isLoading: defaultWindFilterSettingsIsLoading,
    isSuccess: defaultWindFilterSettingsIsSuccess,
    data: defaultWindFilterSettingsData,
  } = defaultWindFilterSettings

  const {
    isLoading: enercastWindFilterSettingsIsLoading,
    isSuccess: enercastWindFilterSettingsIsSuccess,
    data: enercastWindFilterSettingsData,
  } = enercastWindFilterSettings

  const defaultSolarFilterSettings = useDefaultMDCFilterSettings(MDCAssetType.SOLAR)
  const enercastSolarFilterSettings = useEnercastMDCFilterSettings(MDCAssetType.SOLAR)

  // Managing result from filters
  const {
    isLoading: defaultSolarFilterSettingsIsLoading,
    isSuccess: defaultSolarFilterSettingsIsSuccess,
    data: defaultSolarFilterSettingsData,
  } = defaultSolarFilterSettings

  const {
    isLoading: enercastSolarFilterSettingsIsLoading,
    isSuccess: enercastSolarFilterSettingsIsSuccess,
    data: enercastSolarFilterSettingsData,
  } = enercastSolarFilterSettings

  //  Singe we don't have an api for single asset , we have to find asset name in allAssets
  const asset = allAssets?.data?.find((singleAsset) => singleAsset.id === assetId)

  // Prepare asset details url
  const queryString = stringifyQueryParams(
    getAssetQueryObj(
      {
        id: assetId,
      },
      { activeTab: assetTabNames.meterDataCleansing },
    ),
  )
  const assetDetailsURL = `${ROUTE_METER_DATA_BULK_CLEANSING}?${queryString}`

  // 1. Prepare filters comparison
  // 1.1 Get bulk filter settings
  const windBulkFilters: MeterDataCleansingFilterSettings = useMemo(() => {
    let windBulkFiltersConfig
    if (triggerFilterSettings) {
      windBulkFiltersConfig = triggerFilterSettings?.wind || {}
    } else if (isWindAsset(asset) && sessionStorage.getItem(BULK_FILTER_SETTINGS_WIND)) {
      windBulkFiltersConfig = JSON.parse(sessionStorage.getItem(BULK_FILTER_SETTINGS_WIND))
    } else {
      if (!defaultWindFilterSettingsIsLoading && defaultWindFilterSettingsIsSuccess && defaultWindFilterSettingsData) {
        windBulkFiltersConfig = defaultWindFilterSettingsData
      } else if (
        !enercastWindFilterSettingsIsLoading &&
        enercastWindFilterSettingsIsSuccess &&
        enercastWindFilterSettingsData
      ) {
        windBulkFiltersConfig = enercastWindFilterSettingsData
      } else {
        windBulkFiltersConfig = getDefaultValuesForBulkMdcFilterSettings()
      }
    }

    if (windBulkFiltersConfig && windBulkFiltersConfig?.timePeriodForTraining) {
      delete windBulkFiltersConfig.timePeriodForTraining
    }

    return windBulkFiltersConfig
  }, [defaultWindFilterSettings, enercastWindFilterSettings, triggerFilterSettings])

  // 1.2 Get bulk filter settings
  const solarBulkFilters: MeterDataCleansingFilterSettings = useMemo(() => {
    let solarBulkFiltersConfig
    if (triggerFilterSettings) {
      solarBulkFiltersConfig = triggerFilterSettings?.solar || {}
    } else if (isWindAsset(asset) && sessionStorage.getItem(BULK_FILTER_SETTINGS_SOLAR)) {
      solarBulkFiltersConfig = JSON.parse(sessionStorage.getItem(BULK_FILTER_SETTINGS_SOLAR))
    } else {
      if (
        !defaultSolarFilterSettingsIsLoading &&
        defaultSolarFilterSettingsIsSuccess &&
        defaultSolarFilterSettingsData
      ) {
        solarBulkFiltersConfig = defaultSolarFilterSettingsData
      } else if (
        !enercastSolarFilterSettingsIsLoading &&
        enercastSolarFilterSettingsIsSuccess &&
        enercastSolarFilterSettingsData
      ) {
        solarBulkFiltersConfig = enercastSolarFilterSettingsData
      } else {
        solarBulkFiltersConfig = getDefaultValuesForBulkMdcFilterSettings()
      }
    }

    if (solarBulkFiltersConfig && solarBulkFiltersConfig?.timePeriodForTraining) {
      delete solarBulkFiltersConfig.timePeriodForTraining
    }

    return solarBulkFiltersConfig
  }, [defaultSolarFilterSettings, enercastSolarFilterSettings, triggerFilterSettings])

  // 2. Get individual filters from props
  const getIndividualFilters: MeterDataCleansingFilterSettings = useMemo(() => {
    if (rowData && rowData?.cleansingParams && rowData?.cleansingParams?.timePeriodForTraining) {
      delete rowData.cleansingParams.timePeriodForTraining
    }
    return rowData.cleansingParams
  }, [rowData])

  // 3. Find if there is a difference between two filters
  const findDifferenceBetweenFilters = useMemo(() => {
    if (!asset) return null
    if (windBulkFilters && getIndividualFilters && isWindAsset(asset)) {
      const difference = getDifference(windBulkFilters, getIndividualFilters)
      return Object.keys(difference).length > 0
    } else if (solarBulkFilters && getIndividualFilters && isSolarAsset(asset)) {
      const difference = getDifference(solarBulkFilters, getIndividualFilters)
      return Object.keys(difference).length > 0
    } else {
      return null
    }
  }, [rowData, defaultWindFilterSettings, enercastWindFilterSettings, triggerFilterSettings, asset])

  // handle refresh
  // we don't care what is the value of refresh. we are using only to trigger a useEffect in BulkMdcBody , which is responsible for rendering.
  const handleRefreshBulkMdc = () => {
    restartMdcForAsset(asset).then(() => {
      setRefresh((prev: any) => !prev)
    })
  }

  return (
    <BulkMdcRowDiv justifyContent="center" alignItems="center" flexWrap="wrap">
      <StatisticsContainer>
        <BulkMDCRowStatistics asset={asset} meterDataRaw={meterDataRaw} />
      </StatisticsContainer>

      <BeforeAfterChartContainer>
        <BulkMDCBeforeAfterFilteringChart
          asset={asset}
          meterDataRaw={meterDataRaw}
          cleansingStatus={cleansingStatus}
          failureReason={failureReason}
        />
      </BeforeAfterChartContainer>

      <IndividualFilterButton alignItems="center" direction="column">
        {asset && isValidAssetForMDC(asset) && (
          <>
            <Flex alignItems="center">
              <Link className="details-link" to={assetDetailsURL}>
                <Button
                  color={findDifferenceBetweenFilters ? 'primary' : 'default'}
                  size="small"
                  variant={findDifferenceBetweenFilters ? 'contained' : 'outlined'}
                  startIcon={findDifferenceBetweenFilters && <FontAwesomeIcon icon={'check'} />}
                >
                  {t`Unique filters`}
                </Button>
              </Link>

              <TooltipDiv>
                <PopperTooltip
                  popperLabel={
                    <Box ml={0.3}>
                      <FontAwesomeIcon color={theme.palette.primary.main} icon="info" size="sm" />
                    </Box>
                  }
                  popperContent={
                    <div>
                      {findDifferenceBetweenFilters
                        ? t`This asset uses unique meter data cleansing filters that are different than the rest of the selected assets. Use the button at the top of the page to re-apply the same filters to all selected assets and hence removing the unique filters, or click here to modify the unique filters.`
                        : t`Click here to configure individual meter data cleansing filters for this specific asset, rather than using the filter settings configured at the top of this page for all selected assets.`}
                    </div>
                  }
                />
              </TooltipDiv>
            </Flex>

            <Button
              variant="contained"
              size="small"
              startIcon={<FontAwesomeIcon icon={'sync-alt'} size="sm" />}
              onClick={() => handleRefreshBulkMdc()}
              style={{ marginTop: '1em' }}
            >
              {t`Restart`}
            </Button>
          </>
        )}
      </IndividualFilterButton>
    </BulkMdcRowDiv>
  )
}

export default BulkMdcRow
