import { TextField as MaterialTextField } from '@material-ui/core'
import { RenderInputParams } from '@material-ui/lab'
import { FormApi } from 'final-form'
import { Checkbox } from 'final-form-material-ui'
import { useManufacturerModels, useManufacturers } from 'modules/asset/api/metadata.api'
import { ManufacturerModel } from 'modules/asset/assetCrud/asset.crud.types'

import { WindGenerator } from 'modules/asset/store/asset.types'
import React, { useCallback, useEffect, useState } from 'react'
import { Field, FormSpy, useField } from 'react-final-form'
import { t } from 'ttag'
import {
  AssetFormLabel,
  AutoCompleteWithLabel,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  NumberFieldWithLabel,
  NumberFieldWithoutLabel,
  SubField,
} from 'ui/form/assetForm.style'
import Flex from 'ui/styles/Flex'
import { isPark } from 'utils/asset'

// styled components

// exported component

interface ConfigurationWindPlantProps {
  asset: WindGenerator
  form: FormApi<WindGenerator>
  onFormChangeExternally: () => void
}

const ConfigurationWindPlant: React.FC<ConfigurationWindPlantProps> = ({ asset, form, onFormChangeExternally }) => {
  const [formState, setFormState] = useState(asset)

  const manufacturers = useManufacturers()
  const models = useManufacturerModels()
  const [filteredManufacturers, setFilteredManufacturers] = useState(manufacturers.data || [])
  const [filteredModels, setFilteredModels] = useState(models.data || [])
  const [manufacturerModels, setManufacturerModels] = useState<ManufacturerModel[]>([])
  const selectedModel = useField('model').input.value
  const selectedManufacturer = useField('manufacturer').input.value

  const handleModelSelection = useCallback(
    (event: React.ChangeEvent, model: string) => {
      const modelValue = event.type === 'change' ? event.target.value : model
      setFilteredModels(
        manufacturerModels.filter((data) => data.model.toLowerCase().includes(modelValue.toLowerCase())),
      )
      if (selectedModel !== modelValue) {
        form.mutators.setValue('model', modelValue)
        onFormChangeExternally()
      }
    },
    [manufacturerModels, onFormChangeExternally, selectedModel],
  )

  const handleManufacturerSelection = useCallback(
    (event: React.ChangeEvent, manufacturer: string) => {
      const manufacturerValue = event.type === 'change' ? event.target.value : manufacturer
      setFilteredManufacturers(
        (manufacturers.data || []).filter((manufacturer) =>
          manufacturer.toLowerCase().includes(manufacturerValue.toLowerCase()),
        ),
      )
      if (selectedManufacturer !== manufacturerValue) {
        form.mutators.setValue('manufacturer', manufacturerValue)
        onFormChangeExternally()
      }
    },
    [manufacturers.data, onFormChangeExternally, selectedManufacturer],
  )

  useEffect(() => {
    const manufacturerModels = (models.data || []).filter((model) => model.manufacturer === formState?.manufacturer)
    setManufacturerModels(manufacturerModels)
    setFilteredModels(manufacturerModels)
  }, [manufacturers.data, models.data, formState?.manufacturer])

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as WindGenerator)
        }}
      />

      {/*Extra info*/}
      {isPark(formState) && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Number of plants in park`}: </AssetFormLabel>
          </FormKey>

          <FormValue>
            <Flex direction="row">
              <Flex flexBasis="50%">
                <Field name="numParts">
                  {(props) => {
                    return <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />
                  }}
                </Field>
              </Flex>
              <SubField flexBasis="50%" />
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Turbine model`}: </AssetFormLabel>
        </FormKey>

        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="50%">
              <AutoCompleteWithLabel
                freeSolo
                value={formState?.manufacturer || ''}
                options={filteredManufacturers.map((el) => el)}
                onChange={handleManufacturerSelection}
                disableClearable
                renderInput={(params: RenderInputParams) => (
                  <MaterialTextField
                    onChange={handleManufacturerSelection}
                    label={t`Manufacturer`}
                    {...params}
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </Flex>
            <SubField flexBasis="50%">
              <AutoCompleteWithLabel
                freeSolo
                value={formState?.model || ''}
                options={filteredModels.map((el) => el.model)}
                onChange={handleModelSelection}
                disableClearable
                renderInput={(params: RenderInputParams) => (
                  <MaterialTextField
                    onChange={handleModelSelection}
                    label={t`Model`}
                    {...params}
                    margin="normal"
                    fullWidth
                  />
                )}
              />
            </SubField>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Technical data`}: </AssetFormLabel>
        </FormKey>

        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="50%" direction="row">
              <Field name="typeSpecificAttributes.hubHeight">
                {(props) => {
                  return (
                    <NumberFieldWithLabel
                      // TODO this seems to break `form.getState().active` which is undefined
                      InputProps={{ inputProps: { min: 0 } }}
                      label={t`Hub height`}
                      type="number"
                      input={props.input}
                      meta={props.meta}
                    />
                  )
                }}
              </Field>
              <FieldUnit>m</FieldUnit>
            </Flex>

            <SubField flexBasis="50%" direction="row">
              <Field fullWidth name="typeSpecificAttributes.diameter">
                {(props) => {
                  return (
                    <NumberFieldWithLabel
                      // TODO this seems to break `form.getState().active` which is undefined
                      InputProps={{ inputProps: { min: 0 } }}
                      label={t`Rotor diameter`}
                      type="number"
                      input={props.input}
                      meta={props.meta}
                    />
                  )
                }}
              </Field>
              <FieldUnit>m</FieldUnit>
            </SubField>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Offshore plant`}: </AssetFormLabel>
        </FormKey>

        <FormValue>
          <div>
            <Field component={Checkbox} type="checkbox" name="typeSpecificAttributes.offshore" />
          </div>
        </FormValue>
      </FormKeyValueGrid>
    </>
  )
}

export default React.memo(ConfigurationWindPlant)
