import React, { useCallback, useEffect, useState } from 'react'
import NumberFieldWithArrows from 'ui/form/NumberFieldWithArrows'
import Flex from 'ui/styles/Flex'
import { IndividualTime } from 'modules/dataStreams/dataStreams.types'

interface TimePickerProps {
  onChange: (time: IndividualTime) => void
  initialMinutes: string
  initialHours: string
}

const TimePicker: React.FC<TimePickerProps> = ({ onChange, initialMinutes, initialHours }) => {
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const maxMinutes = 59
  const minMinutesAndHours = 0
  const maxHours = 23

  const handleHoursChange = useCallback((hours) => {
    setHours(hours)
  }, [])

  const updateHour = useCallback(
    (prevMinutes, currMinutes, changedWithArrow) => {
      const minutes = currMinutes
      let newHours = hours
      if (changedWithArrow) {
        if (currMinutes === 0 && prevMinutes === 59) {
          newHours = hours + 1
        } else if (currMinutes === 59 && prevMinutes === 0) {
          newHours = hours - 1
        }
        handleHoursChange(newHours)
      }
      return minutes
    },
    [hours],
  )

  const handleMinutesChange = useCallback(
    (currMinutes, changedWithArrow) => {
      setMinutes((prevMinutes) => updateHour(prevMinutes, currMinutes, changedWithArrow))
    },
    [updateHour],
  )

  useEffect(() => {
    if (initialHours !== hours || initialMinutes !== minutes) {
      const time = {
        hours: hours,
        minutes: minutes,
      }
      onChange(time)
    }
  }, [hours, minutes, initialHours, initialMinutes])

  useEffect(() => {
    setMinutes(initialMinutes)
    setHours(initialHours)
  }, [initialHours, initialMinutes])

  return (
    <Flex alignItems="center">
      <div>
        <NumberFieldWithArrows
          onChange={handleHoursChange}
          min={minMinutesAndHours}
          max={maxHours}
          value={hours}
          timeInput={true}
        />
      </div>
      <div>:</div>
      <div>
        <NumberFieldWithArrows
          onChange={handleMinutesChange}
          min={minMinutesAndHours}
          max={maxMinutes}
          value={minutes}
          timeInput={true}
        />
      </div>
    </Flex>
  )
}

export default React.memo(TimePicker)
