import { AnyAction, combineReducers } from 'redux'

import { assetReducer } from 'modules/asset/store/asset.store'
import { authReducer } from 'modules/auth/redux_store/auth.store'
import { dataReducer } from 'modules/data/store/data.store'
import { productReducer } from 'modules/product/store/product.store'
import { qualityReducer } from 'modules/quality/quality.store'
import { reTableReducer } from 'modules/reTable/reTable.store'
import { weatherReducer } from 'modules/weather/store/weather.store'
import { workspaceReducer } from 'modules/workspace/store/workspace.store'
import { workbenchReducer } from 'pages/workbench/store/workbench.store'
import { sessionReducer } from 'modules/app/checkConnectivity.state'
import { dataStreamsMgmntReducer } from 'modules/dataStreams/redux_store/dataStreams.store'
import gipsReducer from 'modules/gips/reducer/gipsReducer'

export const RESET_APP = '@APP/RESET'

const appReducer = combineReducers({
  asset: assetReducer,
  auth: authReducer,
  data: dataReducer,
  gips: gipsReducer,
  product: productReducer,
  quality: qualityReducer,
  reTable: reTableReducer,
  weather: weatherReducer,
  workspace: workspaceReducer,
  workbench: workbenchReducer,
  session: sessionReducer,
  dataStreamsMgmnt: dataStreamsMgmntReducer,
})

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === RESET_APP) {
    state = {
      session: state.session,
    }
  }

  return appReducer(state, action)
}

export default rootReducer
