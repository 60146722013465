import React, { useMemo } from 'react'
import { AppTours } from 'modules/app/tour/appTour.types'
import { c } from 'ttag'
import {
  activateDraftSteps,
  addDeliveryTargetToSiteForecastSteps,
  createAssetSteps,
  createDeliveryTargetSteps,
  createSiteForecastSteps,
} from 'modules/app/tour/appTourSteps'
import { Box } from '@material-ui/core'
import { TourListItem } from 'modules/app/tour/AppTour'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useDeliveryTargetsTableItems } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'

export const AddTourStepsTime = 300

export const getTourName = (tourContext: AppTours) => {
  const { ADD_ASSET, ADD_SITE_FORECAST, ADD_DELIVERY_TARGET } = AppTours
  return tourContext === ADD_ASSET
    ? c('AppTour').t`Asset`
    : tourContext === ADD_SITE_FORECAST
    ? c('AppTour').t`Site Forecast`
    : tourContext === ADD_DELIVERY_TARGET
    ? c('AppTour').t`Delivery target`
    : ''
}

export const getAppTourSteps = (tourContext: AppTours | undefined) => {
  const {
    ADD_ASSET,
    ADD_SITE_FORECAST,
    ADD_DELIVERY_TARGET,
    ACTIVATE_DRAFT,
    ADD_DELIVERY_TARGET_TO_SITE_FORECAST,
  } = AppTours
  if (tourContext === undefined) return ''
  const steps =
    tourContext === ADD_ASSET
      ? createAssetSteps()
      : tourContext === ACTIVATE_DRAFT
      ? activateDraftSteps()
      : tourContext === ADD_SITE_FORECAST
      ? createSiteForecastSteps()
      : tourContext === ADD_DELIVERY_TARGET
      ? createDeliveryTargetSteps()
      : tourContext === ADD_DELIVERY_TARGET_TO_SITE_FORECAST
      ? addDeliveryTargetToSiteForecastSteps()
      : []
  return steps
}
export const getOnBoardingStartTourDialogBodyContent = () => {
  return (
    <Box mb={1}>
      {c('AppTour')
        .t`Follow our guided tour of the enercast Portal to set up your first forecast for a wind or solar asset.`}
      <Box mt={2}>{c('AppTour').t`These are the steps to get your power forecast in a few minutes:`}</Box>

      <ul>
        <TourListItem>
          <b>{c('AppTour').t`Add an Asset`}</b>
          <>
            {': '}
            {c('AppTour')
              .t`Tell us a few things about the wind or solar site for which you would like to obtain a forecast.`}
          </>
        </TourListItem>
        <TourListItem>
          <b>{c('AppTour').t`Add a Site Forecast`}</b>
          <>
            {': '}
            {c('AppTour')
              .t`Let us know how you want your forecast calculated -- how often, which horizon, what quality parameters, ... `}
          </>
        </TourListItem>
        <TourListItem>
          <b>{c('AppTour').t`Add a Delivery Target`}</b>
          <>
            {': '}
            {c('AppTour').t`Where shall we send the forecast every time it has been calculated, e. g. by e-mail?`}
          </>
        </TourListItem>
      </ul>
    </Box>
  )
}

export const getEndTourDialogBodyContent = () => {
  return (
    <Box mb={1}>
      <Box mt={2}>{c('AppTour')
        .t`Your first forecast is now continually calculated by our backend.  Depending on the parameters you chose, it will take a few minutes before you will see the first forecast.`}</Box>
      <Box mt={2}>{c('AppTour')
        .t`In order to display the forecast here in the enercast Portal, select an asset from the asset list on the left and the desired forecast from the data stream list on the right.  Just click on the respective list entry.  Later, you can use Ctrl-Click or Shift-Click to select multiple assets or forecasts and shown them together in a single chart. `}</Box>
      <Box mt={2}>{c('AppTour').t`Enjoy!`}</Box>
    </Box>
  )
}

export enum DeliveryUsed {
  YES = 'YES',
  NO = 'NO',
}

export const useAtLeastOneDeliveryIsUsed = () => {
  const siteForecastsResult = useSiteForecastConfigs()
  const deliveryTargetsResult = useDeliveryTargetsTableItems()
  const atLeastOneDeliveryIsAssignedToSiteForecast = useMemo(() => {
    if (deliveryTargetsResult?.isSuccess && siteForecastsResult?.isSuccess) {
      if (siteForecastsResult?.data.length === 1 || deliveryTargetsResult?.data.length === 1) return
      const deliveryAddedToSiteForecast = deliveryTargetsResult?.data?.some((delivery) =>
        siteForecastsResult?.data?.some((config) => config.deliveryEndpoints.includes(delivery.id)),
      )
      return deliveryAddedToSiteForecast
    }
  }, [
    deliveryTargetsResult?.isSuccess,
    deliveryTargetsResult?.data,
    siteForecastsResult?.isSuccess,
    siteForecastsResult?.data,
  ])
  return atLeastOneDeliveryIsAssignedToSiteForecast ? DeliveryUsed.YES : DeliveryUsed.NO
}
