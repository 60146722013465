import React from 'react'
import { AssetIconProps } from 'ui/icons/ClusterPoolIcon'

const EnercastIconSmall: React.FC<AssetIconProps> = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="173 1 46 46" width={size} height={size}>
      <g stroke="none" strokeOpacity="1" strokeDasharray="none" fill="none" fillOpacity="1">
        <title>Arbeitsfläche 1</title>
        <g>
          <title>Ebene 1</title>
          <path
            d="M 196.1129 1.40062 C 190.4519 1.40062 185.0399 3.51463 180.8739 7.35262 C 171.7469 15.76163 171.1619
        30.02962 179.5709 39.158625 C 183.8199 43.770625 189.8559 46.417625 196.1329 46.417625 C 201.7949 46.417625 207.2089
        44.301625 211.3769 40.462625 C 216.5199 35.72362 219.1539 28.85862 218.5429 21.91763 L 196.2729 24.98262 C 196.2229
        24.98862 196.1749 24.99162 196.1259 24.99162 C 196.0949 24.99162 196.0669 24.98362 196.0389 24.98163 C 195.9999
        24.97863 195.9619 24.97462 195.9239 24.96762 C 195.8709 24.95763 195.8179 24.94163 195.7659 24.92262 C 195.7299
        24.90962 195.6939 24.89963 195.6589 24.88262 C 195.6069 24.85762 195.5589 24.82462 195.5089 24.79062 C 195.4819
        24.77163 195.4539 24.75563 195.4269 24.73362 C 195.3549 24.67462 195.2909 24.60663 195.2359 24.52763 C 195.0029
        24.19362 194.9759 23.75762 195.1679 23.39862 L 205.7179 3.54262 C 202.7299 2.13862 199.4319 1.40062 196.1129 1.40062
        Z"
            fill="#379036"
          />
          <path
            d="M 207.6334 4.56032 L 198.0794 22.54332 L 218.2484 19.76732 C 217.4764 15.60632 215.5634
        11.78332 212.6814 8.65633 C 211.1974 7.04633 209.5054 5.67232 207.6334 4.56032 Z"
            fill="#cb1f27"
          />
        </g>
      </g>
    </svg>
  )
}

export default EnercastIconSmall
