import React, { useCallback, useEffect, useState } from 'react'
import { c } from 'ttag'
import Flex from 'ui/styles/Flex'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from '@material-ui/core/Radio/Radio'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import { FormApi } from 'final-form'
import { ForecastConfig, SitesSelectionType } from 'modules/dataStreams/dataStreams.types'
import AssetAutocomplete from 'ui/elements/AssetAutocomplete'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { Field, useField } from 'react-final-form'
import { useUniqueAllAssets } from 'utils/asset'

interface SiteForecastSectionAssetsProps {
  form: FormApi<ForecastConfig>
  onFormChange: () => void
}

const SectionForecastSites: React.FC<SiteForecastSectionAssetsProps> = ({ form, onFormChange }) => {
  const initialSites = useField<string[]>('sites').input.value || []
  const sitesSelectionType = useField<SitesSelectionType>('ui.sitesSelectionType').input.value

  const { ALL_SITES, SELECTED_SITES } = SitesSelectionType
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>()
  const allAssets = useUniqueAllAssets()

  const handleAssetSelection = useCallback(
    (ids: string[]) => {
      const assetIds = allAssets.filter((a) => ids.includes(a.id)).map((obj) => obj.id)
      form.mutators.setValue('sites', assetIds)
      setSelectedAssetIds(assetIds)
      onFormChange()
    },

    [allAssets],
  )

  useEffect(() => {
    if (initialSites && JSON.stringify(initialSites) != JSON.stringify(selectedAssetIds)) {
      setSelectedAssetIds(initialSites || [])
    }
  }, [initialSites])

  return (
    <>
      <Flex direction="column">
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`Forecast sites:`}</FormKey>
          <FormValue>
            <Field name="ui.sitesSelectionType" type="radio">
              {({ input }) => {
                return (
                  <RadioGroup name={input.name} value={sitesSelectionType} onChange={input.onChange}>
                    <Flex direction="row">
                      <FormControlLabel
                        value={ALL_SITES}
                        control={<Radio size="small" />}
                        label={c('Delivery').t`All sites`}
                        title={c('Delivery').t`All sites`}
                      />

                      <FormControlLabel
                        value={SELECTED_SITES}
                        control={<Radio size="small" />}
                        label={c('Delivery').t`Selected sites`}
                        title={c('Delivery').t`Selected sites`}
                      />
                    </Flex>
                  </RadioGroup>
                )
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>

        {sitesSelectionType === SELECTED_SITES && (
          <FormKeyValueGrid>
            <FormKey>{c('Delivery').t`Select sites:`}</FormKey>
            <FormValue>
              <AssetAutocomplete
                onAssetSelect={handleAssetSelection}
                selectedIds={selectedAssetIds || []}
                autocompleteAssetList={allAssets}
                multiple={true}
                unitspace={false}
              />
            </FormValue>
          </FormKeyValueGrid>
        )}
      </Flex>
    </>
  )
}

export default React.memo(SectionForecastSites)
