import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import styled from 'styled-components'
import useResizeAware from 'react-resize-aware'
import { HighchartsStockChart, Navigator, PlotBand, Series, XAxis, YAxis } from 'react-jsx-highstock'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import TimeOptions from 'modules/workspace/highstock/TimeOptions'
import { Timezone } from 'fixtures/timezones'
import TooltipOptions from 'modules/workspace/highstock/TooltipOptions'
import { XAxisOptions } from 'highcharts/highstock'
import { AppUnits } from 'utils/units'
import { XAxisPlotBandsOptions, YAxisOptions } from 'highcharts'
import { thousandSeparator } from 'utils/dataFormatting'
import { c } from 'ttag'
import { createTimeSeriesWithNullValues } from 'utils/timeseries'
import { useMeterDataCleansingChartSeriesSet } from 'utils/hooks/useMeterDataCleansingChartSeries'
import { addSeconds, differenceInMinutes, getTime } from 'date-fns'
import { DateRange, EnercastDate } from 'utils/date'
import { darken, fade, lighten } from '@material-ui/core'
import { miscellaneousColors, theme } from 'themes/theme-light'
import { createYAxis, CreateYAxis } from 'modules/workspace/chart/axis'
import { removeDuplicates } from 'utils/array'
import { CHART_BOOST_THRESHOLD_VALUE, getYAxisData } from 'utils/chart'
import { useDebounce } from 'use-debounce'

const ChartContainer = styled.div`
  position: relative;
  height: calc((100vh - 16em) / 2);
  min-height: 21em;
  max-height: inherit;
`

const plotOptions = {
  area: {
    animation: false,
    lineWidth: 2,
    marker: { enabled: false },
    shadow: false,
  },
  series: {
    animation: {
      duration: 100,
    },
    turboThreshold: 0,
    boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
    connectNulls: false,
    states: {
      inactive: {
        enabled: false, // do not fade out other series if one is hovered
      },
    },
  },
}

const chartOptions = { zoomType: 'x' }

const xAxis: XAxisOptions = {
  crosshair: true,
  labels: {
    rotation: -45,
  },
  minRange: 1000 * 60 * 60 * 2, // 2 hours
  minTickInterval: 1000 * 60 * 15, // 15 minutes
  ordinal: false,
  tickPixelInterval: 50,
  type: 'datetime',
}

type CreateHighLightArea = (timeperiods: TimePeriodToHighlightInterface[], color: string) => XAxisPlotBandsOptions[]

export interface MDCTimePeriod {
  start: EnercastDate
  end: EnercastDate
}

export interface TimePeriodToHighlightInterface extends MDCTimePeriod {
  highlight?: boolean
  name?: string
  id?: string
}

interface MeterDataCleansingChartProps {
  totalTimePeriod: DateRange
  timezone?: Timezone
  asset: Asset
  timePeriodsToExcludeFromChart: TimePeriodToHighlightInterface[]
  timePeriodExcludedFromTraining: TimePeriodToHighlightInterface[]
  onClickExcludedTimePeriodToEdit: (timePeriod: TimePeriodToHighlightInterface) => void
  onSelectTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  showMDCChartTooltip: boolean
}

const MeterDataCleansingChart: React.FC<MeterDataCleansingChartProps> = ({
  totalTimePeriod,
  timezone = 'UTC',
  asset,
  timePeriodsToExcludeFromChart,
  timePeriodExcludedFromTraining,
  onClickExcludedTimePeriodToEdit,
  onSelectTimePeriod,
  showMDCChartTooltip,
}) => {
  const [resizeListener, { width, height }] = useResizeAware()
  const chartDateRangeInUTC = useMemo(() => totalTimePeriod, [totalTimePeriod])
  const chartSeriesSet = useMeterDataCleansingChartSeriesSet(asset, totalTimePeriod)
  const [debouncedSeriesSet] = useDebounce(chartSeriesSet, 30, { leading: true })
  // console.log({ chartSeriesSet })
  // console.log({totalTimePeriod, chartSeriesSet})
  const [chart, setChart] = useState<HighchartsStockChart | undefined>()

  const createHighLightArea: CreateHighLightArea = useCallback(
    (timeperiods: TimePeriodToHighlightInterface[], color: string) => {
      let highLightPlotbands: XAxisPlotBandsOptions[] = []
      if (timeperiods.length) {
        for (const period of timeperiods) {
          const data: XAxisPlotBandsOptions = {
            id: period.id,
            from: getTime(period.start.date),
            to: getTime(period.end.date),
            zIndex: 5,
            events: {
              click: () => {
                onClickExcludedTimePeriodToEdit(period)
              },
            },
            color: period.highlight
              ? fade(lighten(theme.palette.primary.main, 0.4), 0.8)
              : fade(lighten(color, 0.4), 0.8),
            label: {
              text: '',
              rotation: 270,
              width: 2,
              y: 8,
              style: {
                color: darken(color, 0.3),
              },
              textAlign: 'right',
            },
            borderColor: fade(lighten(color, 0.4), 0.8), //to prevent a small space between two selection , or when you extend a selected one. (borderColor should be = color)
            borderWidth: 0.07, //to prevent a small space between two selection , or when you extend a selected one.
          }
          highLightPlotbands.push(data)
        }
      } else {
        highLightPlotbands = []
      }
      return highLightPlotbands
    },
    [onClickExcludedTimePeriodToEdit],
  )

  /**
   * @param event
   * @description This function handle zone selection inside a chart.
   */
  const handleTimePeriodSelection = (event) => {
    if (event.xAxis) {
      const startDate = new Date(event.xAxis[0].min)
      const endDate = new Date(event.xAxis[0].max)
      // console.log({xAxis:event.xAxis, startDate, endDate, timezone})
      const data = {
        start: { date: startDate, timezone },
        end: { date: endDate, timezone },
      }
      onSelectTimePeriod(data)
    }
    return false
  }

  const highlightBands = useMemo(() => {
    const excludedFromChartBands = timePeriodsToExcludeFromChart?.length
      ? createHighLightArea(timePeriodsToExcludeFromChart, miscellaneousColors.meterDataCleansingExcludedBand)
      : []
    const excludedFromTrainingPeriod = timePeriodExcludedFromTraining?.length
      ? createHighLightArea(timePeriodExcludedFromTraining, '#b3b2af')
      : []
    // console.log({ timePeriodExcludedFromTraining, excludedFromTrainingPeriod })
    return [...excludedFromChartBands, ...excludedFromTrainingPeriod]
  }, [timePeriodsToExcludeFromChart, timePeriodExcludedFromTraining, timezone])

  // chart and plot options
  const initialChartOptions = useMemo(
    () => ({
      height: '100%',
      events: {
        selection: handleTimePeriodSelection,
      },
    }),
    [handleTimePeriodSelection],
  )

  const unit = AppUnits.KILO_WATT
  const emptyChartYAxis: YAxisOptions = useMemo(() => {
    return {
      alignTicks: true,
      crosshair: true,
      gridLineWidth: 1,
      labels: {
        align: 'left',
        formatter: function () {
          return thousandSeparator(this.value)
        },
        reserveSpace: true,
      },
      lineWidth: 1,
      minPadding: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      endOnTick: false,
      title: {
        // offset: 48,
        text: `${c('Workbench:Quality').t`Power`} (${unit})`,
      },
    }
  }, [unit])

  // yAxis definition
  const yAxis: YAxisOptions[] = useMemo(() => {
    const createYAxisParams: CreateYAxis[] = []
    const units = removeDuplicates(debouncedSeriesSet?.filter((fs) => fs.custom?.unit).map((ms) => ms.custom?.unit))

    const seriesForEachUnit = (units || []).reduce((prevVal, currentVal) => {
      const currentUnitSeries = debouncedSeriesSet
        ?.filter((fs) => fs?.custom?.unit === currentVal)
        .map((ms) => ms?.data)
      return {
        ...prevVal,
        [currentVal]: currentUnitSeries.reduce((a, b) => a.concat(b), []),
      }
    }, {})

    const yAxisPartialDataForEachUnit = {}
    for (const [key, value] of Object.entries(seriesForEachUnit)) {
      yAxisPartialDataForEachUnit[key] = getYAxisData(value)
    }

    debouncedSeriesSet.forEach((series) => {
      const paramData = series.custom?.paramData
      const unit = series.custom?.unit
      if (unit && !createYAxisParams.map((params) => params.unit).includes(unit)) {
        createYAxisParams.push({
          paramData,
          unit,
          yAxisPartialData: yAxisPartialDataForEachUnit[unit],
        })
      }
    })
    const yAxes = createYAxisParams.map((params) => createYAxis(params))
    return yAxes
  }, [debouncedSeriesSet])

  const handleChartMounted = (chartInstance: HighchartsStockChart) => {
    setChart(chartInstance)
  }

  const emptySeries = createTimeSeriesWithNullValues(chartDateRangeInUTC)
  const hasData =
    chartSeriesSet.length > 0 && chartSeriesSet.some((series) => Array.isArray(series.data) && series.data.length > 0)

  /**
   * @description This useEffect is used to show all the selected values on x Axis.
   */
  useEffect(() => {
    if (!chart?.xAxis || chart.xAxis.length === 0 || !totalTimePeriod.length) return

    const timeoutExtremes = setTimeout(() => {
      const nextExtremes = [getTime(new Date(totalTimePeriod[0])), getTime(addSeconds(new Date(totalTimePeriod[1]), 1))]
      if (
        !nextExtremes ||
        (Math.abs(differenceInMinutes(chart.xAxis[0]?.min, nextExtremes[0])) <= 1 &&
          Math.abs(differenceInMinutes(chart.xAxis[0]?.max, nextExtremes[1])) <= 1)
      ) {
        return
      }

      // we need the timeout so that setExtremes is overriding any automatic extremes adjustments by highcharts when new data is loaded
      // see this from 2012 (!!): https://www.highcharts.com/forum/viewtopic.php?t=15091
      if (chart.xAxis[0]) {
        // reset selection when chart date range changes
        chart.xAxis[0].setExtremes(nextExtremes[0], nextExtremes[1], true, false)
      }
    }, 0)

    return () => {
      if (timeoutExtremes) {
        clearTimeout(timeoutExtremes)
      }
    }
  }, [chart, totalTimePeriod])

  /**
   * @description This useEffect is used to handle size of the chart.
   */
  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }
  }, [chart, width, height])

  return (
    <>
      <ChartContainer>
        {resizeListener}
        <HighchartsStockChart chart={initialChartOptions} plotOptions={plotOptions} callback={handleChartMounted}>
          <GenericOptions />
          <ChartOptions options={chartOptions} />
          <LoadingOptions isLoading={false} />
          <TimeOptions timezone={timezone} />

          {showMDCChartTooltip && <TooltipOptions timezone={timezone} />}

          <XAxis {...xAxis}>
            {hasData &&
              (highlightBands || []).map((band) => (
                <PlotBand onClick={() => band.events?.click} key={`plotband-${band.id}`} {...band} />
              ))}
          </XAxis>

          {hasData ? (
            yAxis.map((y) => {
              return (
                <YAxis key={y.id} {...y}>
                  <YAxis.Title>{y.title?.text}</YAxis.Title>
                  {debouncedSeriesSet
                    .filter((series) => series.yAxis === y.id)
                    .map((series) => {
                      return <Series key={series.name} id={series.name} {...series} />
                    })}
                </YAxis>
              )
            })
          ) : (
            <YAxis {...emptyChartYAxis}>
              <YAxis.Title>{emptyChartYAxis?.title?.text}</YAxis.Title>
              {emptySeries.map((series) => (
                <Series key={series.name} id={series.name} {...series} />
              ))}
            </YAxis>
          )}

          <Navigator>
            {chartSeriesSet.map((series) => (
              <Navigator.Series key={series.name} seriesId={series.name} />
            ))}
          </Navigator>
        </HighchartsStockChart>
      </ChartContainer>
    </>
  )
}

export default React.memo(MeterDataCleansingChart)
