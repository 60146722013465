import axios from 'axios'
import { FormApi } from 'final-form'
import {
  createDraft,
  getHasDraft,
  QUERY_KEY_ASSETS_DRAFT,
  QUERY_KEY_ASSETS_SHAPES,
  updateAssetShape,
  useShapes,
  useStage,
} from 'modules/asset/api/metadata.api'
import {
  Asset,
  Cluster,
  Coordinate,
  Generator,
  Park,
  TYPE_CHP,
  TYPE_SOLARPARK,
  TYPE_SOLARPLANT,
  TYPE_WINDPARK,
  TYPE_WINDPLANT,
} from 'modules/asset/store/asset.types'
import { User } from 'modules/auth/Auth.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { ShapeInfoWrapper } from 'modules/gips/helper/Gips.types'
import { prepareShapeUpdate } from 'modules/maps/maps'
import { REVISION_SET_STAGE } from 'pages/workbench/store/workbench.action.types'
import { STAGE, STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { enrichAssetDataAfterLoad, enrichAssetDataAfterLoadWithShapes } from 'utils/asset'
import { getAssetQueryObj, getAssetQueryStrings, useQueryParams, useQueryString } from 'utils/query-string'

import { apiRequest, Error, useOptimisticMutation } from 'utils/request'
import { useAssetSaveMainItemKey } from 'modules/asset/api/assetsUserSettings.api'
import { FormSaveOptions } from 'utils/form'
import { CoordinateCachedData } from 'modules/asset/assetCrud/assetDetails/LocationCoordinates'
import { RETABLE_TOGGLE_SUBTREE } from 'modules/reTable/redux_store/reTable.action.types'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { useMemo } from 'react'

// Query keys for caching data

export const QUERY_KEY_ASSETS = 'assets'
export const QUERY_KEY_ASSETS_COORDINATES_CACHED = 'assets:CoordinatesCached'
export const QUERY_KEY_CHP_PROFILES = 'ChpProfiles'

// types

export enum AssetMutationType {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
}

// API: fetch assets

export const getGenerators = async (stages: STAGE) => {
  return await apiRequest<Generator[]>(() =>
    axios.get('/api/masterdata/generator', {
      params: {
        stages,
        showDeleted: stages === STAGE_DRAFT,
      },
    }),
  )
}

export const getParks = async (stages: STAGE) => {
  return await apiRequest<Park[]>(() =>
    axios.get('/api/masterdata/park', {
      params: {
        stages,
        showDeleted: stages === STAGE_DRAFT,
      },
    }),
  )
}

export const getClusters = async (stages: STAGE) => {
  return await apiRequest<Cluster[]>(() =>
    axios.get('/api/masterdata/cluster', {
      params: {
        stages,
        showDeleted: stages === STAGE_DRAFT,
      },
    }),
  )
}

export const getIsDataCachedByCoordinates = async (latitude: string, longitude: string) => {
  return await apiRequest<CoordinateCachedData[]>(() =>
    axios.get(`/api/catalog/v1/operational/${latitude}/${longitude}`),
  )
}

export const getIsDataCachedByListOfCoordinates = async (coordinates: Coordinate[]) => {
  return await apiRequest<CoordinateCachedData[]>(() => axios.post(`/api/catalog/v1/operational`, coordinates))
}

export const cacheAssetCoordinates = async (coordinates: Coordinate[]) => {
  return await apiRequest<CoordinateCachedData[]>(() =>
    axios.post(`/api/catalog/v1/operational?addIfNotFound=true`, coordinates),
  )
}

// Info about backast status of forecast-model per asset and forecast model
export const getBackcastStatusOfForecastModel = async () => {
  return await apiRequest<any>(() => axios.get(`/api/backcast/v1/info`))
}

// API: wrapper for merging asset data

export const getAllAssets = async (stageToFetch: STAGE, shapes: ShapeInfoWrapper[]) => {
  const [generators, parks, clusters] = await Promise.all([
    getGenerators(stageToFetch),
    getParks(stageToFetch),
    getClusters(stageToFetch),
  ])

  return new Promise<Asset[]>((resolve) => {
    const mergedAssets = enrichAssetDataAfterLoad([...(clusters || []), ...(parks || []), ...(generators || [])])
    const mergedAssetsWithShapes = enrichAssetDataAfterLoadWithShapes(mergedAssets, shapes)
    resolve(mergedAssetsWithShapes)
    // TODO reject in case of errors
  })
}

// API: modify assets

const urlPointsTo = (asset: Asset) => {
  if (asset.type === TYPE_SOLARPLANT || asset.type === TYPE_WINDPLANT || asset.type === TYPE_CHP) {
    return 'generator'
  } else if (asset.type === TYPE_SOLARPARK || asset.type === TYPE_WINDPARK) {
    return 'park'
  } else {
    return 'cluster'
  }
}

export const modifyAsset = (allAssets: Asset[], user: User | null) => async (asset: Asset) => {
  // TODO draft creation should be done by backend
  const hasDraft = await getHasDraft()
  if (!hasDraft) await createDraft()

  const assetWithoutShape = { ...asset }
  delete assetWithoutShape.shape
  delete assetWithoutShape.shapeUUID
  const [assetResult] = await Promise.all([
    apiRequest<Asset>(() => axios.post(`/api/masterdata/${urlPointsTo(asset)}`, assetWithoutShape)),
    updateAssetShape(prepareShapeUpdate(allAssets, asset, user?.login)),
  ])

  return assetResult
}

export const deleteAssets = async (assetIds: string[]) => {
  // TODO draft creation should be done by backend
  const hasDraft = await getHasDraft()
  if (!hasDraft) await createDraft()
  return await apiRequest<Asset>(() => axios.delete('/api/masterdata/delete/', { data: assetIds }))
}

// Hooks to fetch and update via react-query

export const useAllAssetsOfStage = (stage: STAGE) => {
  // get all assets either for modified (= DRAFT) or active (=PRODUCTIVE) stage
  const shapes = useShapes()
  return useQuery<Asset[]>([QUERY_KEY_ASSETS, stage], () => getAllAssets(stage, shapes.data || []))
}

export const useAllAssets = () => {
  // get all assets for currently shown stage
  const stage = useStage()
  const draftAssets = useAllAssetsOfStage(STAGE_DRAFT)
  const productiveAssets = useAllAssetsOfStage(STAGE_PRODUCTIVE)

  const uniqueDraftAssets = useMemo(() => {
    return (draftAssets?.data || []).filter((asset, index, assetList) => {
      const valid = assetList.findIndex((a) => a.id === asset.id) === index
      return valid
    })
  }, [draftAssets?.data])

  const uniqueProductiveAssets = useMemo(() => {
    return (productiveAssets?.data || []).filter((asset, index, assetList) => {
      const valid = assetList.findIndex((a) => a.id === asset.id) === index
      return valid
    })
  }, [productiveAssets?.data])

  const hasDraftAssets = draftAssets.data && draftAssets.data.length > 0

  const hasMissingDraftAssets =
    draftAssets.data && productiveAssets.data && uniqueDraftAssets.length < uniqueProductiveAssets.length

  const stageToUse =
    stage === STAGE_PRODUCTIVE || !hasDraftAssets || hasMissingDraftAssets ? STAGE_PRODUCTIVE : STAGE_DRAFT

  return stageToUse === STAGE_PRODUCTIVE ? productiveAssets : draftAssets
}

const assetCacheUpdater = (modifiedAsset: Asset, assetCache: Asset[] | undefined) => {
  let optimisticallyUpdatedAssets: Asset[]

  modifiedAsset.changed = true
  const existsAlready = assetCache?.some((asset) => asset.id === modifiedAsset.id)
  if (existsAlready) {
    optimisticallyUpdatedAssets = (assetCache || []).map((asset) =>
      asset.id === modifiedAsset.id ? modifiedAsset : asset,
    )
  } else {
    optimisticallyUpdatedAssets = [...(assetCache || []), modifiedAsset]
  }

  // we need to enrich again to make sure that order and hierarchy information is correct
  return enrichAssetDataAfterLoad(optimisticallyUpdatedAssets)
}

export interface UseAssetModifyMutationProps {
  isNew: boolean
  currentAssetIndex: number
  // REACT FINAL FORM REF
  formReference: FormApi
}
export const useAssetModifyMutation = (assetMutationType: AssetMutationType, options: UseAssetModifyMutationProps) => {
  // create a new or update an existing asset
  const dispatch = useDispatch()
  const { queryParams } = useQueryParams()
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const allAssets = useAllAssets()
  const user = useSelector(getUserResultSelector)

  const { isNew, currentAssetIndex, formReference } = options
  const { SAVE_AND_NEW, SAVE, SAVE_AND_NEXT, CREATE_COPY, SAVE_AND_CLOSE } = FormSaveOptions

  const saveMainItemKey = useAssetSaveMainItemKey()

  const queryClient = useQueryClient()
  return useOptimisticMutation<Asset, Asset, Asset[]>({
    queryCacheKey: [QUERY_KEY_ASSETS, 'NOTHING'],
    apiMutator: modifyAsset(allAssets.data || [], user),
    onUpdate: (newValue) => {
      dispatch({ type: REVISION_SET_STAGE, stage: STAGE_DRAFT })
      queryClient.setQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT], (oldData) =>
        assetCacheUpdater(newValue, oldData),
      )
      queryClient.setQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE], (oldData) =>
        assetCacheUpdater(newValue, oldData),
      )
    },
    onSuccess: (asset) => {
      // we clean up the form after successful saving and might navigate away according to selected save option
      formReference.reset()
      let saveOption = isNew ? saveMainItemKey?.data?.createAsset : saveMainItemKey?.data?.updateAsset
      if (!saveOption) {
        saveOption = SAVE_AND_CLOSE
      }
      // collapse the asset after creating or copying a Asset used for collapsing the models in asset tree initially
      if (isNew || saveOption === CREATE_COPY) {
        dispatch({ type: RETABLE_TOGGLE_SUBTREE, table: RETABLE_ID_ASSETS, ids: [asset.id] })
      }
      if (saveOption === SAVE_AND_CLOSE) {
        onDeleteQueryStrings(getAssetQueryStrings())
      } else if (saveOption === SAVE || saveOption === CREATE_COPY) {
        onUpdateQueryString(getAssetQueryObj(asset, queryParams))
      } else if (saveOption === SAVE_AND_NEW) {
        formReference.restart()
      } else if (saveOption === SAVE_AND_NEXT) {
        const assetCount = (allAssets.data || []).length
        let nextAssetIndex = 0
        if (currentAssetIndex + 1 !== assetCount) {
          nextAssetIndex = currentAssetIndex + 1
        }
        onUpdateQueryString(getAssetQueryObj((allAssets.data || [])[nextAssetIndex], queryParams))
      } else {
        // if there is no saveOption set close the form which is equal to 'saveAndClose'
        onDeleteQueryStrings(getAssetQueryStrings())
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_DRAFT, { refetchInactive: true })
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_SHAPES, { refetchInactive: true })
      queryClient.invalidateQueries(QUERY_KEY_ASSETS, { refetchInactive: true })
    },
  })
}

const assetDeleteCacheUpdater = (assetIds: string[], oldData: Asset[]) => {
  return oldData.map((asset) => {
    if (assetIds.includes(asset.id)) {
      return {
        ...asset,
        deleted: true,
      }
    } else {
      return asset
    }
  })
}

export const useAssetDeleteMutation = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  return useMutation<Asset, Error, string[]>(deleteAssets, {
    onMutate: (assetIds) => {
      dispatch({ type: REVISION_SET_STAGE, stage: STAGE_DRAFT })
      queryClient.setQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT], (oldData) =>
        assetDeleteCacheUpdater(assetIds, oldData || []),
      )
      queryClient.setQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE], (oldData) =>
        assetDeleteCacheUpdater(assetIds, oldData || []),
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_DRAFT, { refetchInactive: true })
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_SHAPES, { refetchInactive: true })
      queryClient.invalidateQueries(QUERY_KEY_ASSETS, { refetchInactive: true })
    },
  })
}

//Get Chp Profiles
const getChpProfiles = async (selectedLanguage: string) => {
  return await apiRequest<any>(() => axios.get(`/api/chp/v1/profiles?language=${selectedLanguage}`))
}
export const useChpProfiles = (lang: string) => {
  return useQuery<any>([QUERY_KEY_CHP_PROFILES, lang], () => getChpProfiles(lang))
}
