import { createStory } from 'test/utils'

// stories
export const storyCategory = 'App/MainPage'
export const storyDefault = createStory(storyCategory, 'default')

// tests
const testIdPrefix = 'mainPage'
export const testIdMainPage = `${testIdPrefix}-root`
export const testIdMainHeader = `${testIdPrefix}-header`
