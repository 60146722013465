import React, { useCallback, useEffect, useState } from 'react'
import { isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import LayoutTitle from 'ui/LayoutTitle'
import { c, t } from 'ttag'
import { TextField as TextFieldInput } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import LoadingButton from 'ui/form/LoadingButton'
import { FormRow } from 'modules/user/userSettings/UserSettings'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { AdminSettings } from 'modules/auth/Auth.types'
import { KEY_ADMIN_SETTINGS, useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { QUERY_USER_SETTINGS } from 'utils/query-string'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import styled from 'styled-components'
import { useCustomMuiStyles } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

const InfoDiv = styled(Flex)`
  min-width: 2em;
  justify-content: space-around;
`

const AdminUserSettings: React.FC = () => {
  //Style
  const classes = useCustomMuiStyles()

  const isReadOnlyUser = useIsReadOnlyUser()
  const user = useSelector(getUserResultSelector)
  const [formChanged, setFormChanged] = useState<boolean>(false)

  // admin settings
  const defaultAdminSettings: AdminSettings = {
    orderNumber: '',
  }
  const [formAdminSettings, setFormAdminSettings] = useState<AdminSettings>(defaultAdminSettings)
  const adminSettings = useUserSetting<AdminSettings>(KEY_ADMIN_SETTINGS)
  const { mutate: mutateAdminSettings } = useUserSettingSaveMutation<AdminSettings>(KEY_ADMIN_SETTINGS)

  useEffect(() => {
    if (!adminSettings.data) return
    setFormAdminSettings(adminSettings.data)
  }, [adminSettings.data])

  const handleChangeAdminSetting = useCallback((event) => {
    const field = event.target
    setFormAdminSettings((state) => ({
      ...state,
      [field.name]: field.value,
    }))
    setFormChanged(true)
  }, [])

  const handleSaveAdminSettings = useCallback(
    (e) => {
      e.preventDefault()
      mutateAdminSettings(formAdminSettings)
      setFormChanged(false)
    },
    [formAdminSettings],
  )

  const handleResetFormChange = useCallback(() => {
    setFormChanged(false)
  }, [])

  return (
    <>
      {<LayoutTitle>{c('User Settings').t`Additional settings`}</LayoutTitle>}
      <form onSubmit={handleSaveAdminSettings}>
        <FormBlockNavigation
          blockWithExternalChanges={formChanged}
          navigationDialogText={t`Admin settings`}
          currentPageQueries={[QUERY_USER_SETTINGS]}
          navigationDialogKey={'adminSettings'}
          onResetExternalChanges={handleResetFormChange}
        />
        {(isAdmin(user) || isImpersonatedAdmin(user)) && (
          <FormRow className={classes.adminComponent}>
            <Flex direction="row" alignItems="center">
              <TextFieldInput
                fullWidth
                name="orderNumber"
                label={t`Order number`}
                value={formAdminSettings.orderNumber}
                onChange={handleChangeAdminSetting}
              />
              <InfoDiv>
                <PopperTooltip
                  popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
                  popperContent={
                    <>
                      {t`The order number entered here will be used per default for all assets in this account for which no individual order number has been assigned in the Asset Details.`}
                    </>
                  }
                />
              </InfoDiv>
            </Flex>
          </FormRow>
        )}

        <Flex direction="row" justifyContent="flex-end">
          <LoadingButton
            disabled={isReadOnlyUser ? true : !formChanged}
            type="submit"
            color="primary"
            size="small"
            variant="contained"
          >
            {c('User Settings').t`Save`}
          </LoadingButton>
        </Flex>
      </form>
    </>
  )
}

export default React.memo(AdminUserSettings)
