import React, { useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import { HelperFormControl } from 'modules/auth/RegistrationForm'
import { Field } from 'react-final-form'
import { Select, TextField } from 'final-form-material-ui'
import { c, t } from 'ttag'
import { MenuItem } from '@material-ui/core'
import { CountrySelectorWrapper } from 'ui/elements/CountrySelector'
import { TimezoneSelectorWrapper } from 'ui/datetime/TimezoneSelector'
import { FormRow } from 'modules/user/userSettings/UserSettings'
import { getSalutations } from 'fixtures/auth'
import { User } from 'modules/auth/Auth.types'

interface UserInfoSectionFieldsProps {
  user: User
}

const SectionUserInfo: React.FC<UserInfoSectionFieldsProps> = ({ user }) => {
  const salutations = useMemo(() => (user?.langKey ? getSalutations(user.langKey) : []), [user?.langKey])

  return (
    <>
      <FormRow>
        <Flex direction="row" justifyContent="space-between" flexWrap="wrap">
          <Flex flexBasis="19%">
            <HelperFormControl>
              <Field name="salutation" component={Select} fullWidth label={t`Salutation`}>
                {salutations.map((salute) => (
                  <MenuItem key={salute.label} value={salute.id}>
                    {salute.label}
                  </MenuItem>
                ))}
              </Field>
            </HelperFormControl>
          </Flex>
          <Flex flexBasis="26%">
            <Field fullWidth name="firstName" component={TextField} label={t`First name`} />
          </Flex>
          <Flex flexBasis="51%">
            <Field fullWidth name="lastName" component={TextField} label={t`Last name`} />
          </Flex>
        </Flex>
      </FormRow>

      <FormRow>
        <Field fullWidth name="company" component={TextField} type="text" label={t`Company`} />
      </FormRow>

      <FormRow>
        <Field name="country" label={t`Country`} component={CountrySelectorWrapper} />
      </FormRow>

      <FormRow>
        <Field fullWidth name="phoneNumber" component={TextField} type="text" label={t`Phone number`} />
      </FormRow>

      <FormRow>
        <Field
          name="email"
          label={c('User Settings').t`E-mail`}
          component={TextField}
          fullWidth
          // data-testid={testIdUserEmail}
        />
      </FormRow>

      <FormRow>
        <Field
          name="langKey"
          label={c('User Settings').t`Language`}
          component={Select}
          required
          fullWidth
          // data-testid={testIdUserLanguage}
        >
          <MenuItem value="en">{c('User Settings').t`English`}</MenuItem>
          <MenuItem value="de">{c('User Settings').t`German`}</MenuItem>
        </Field>
      </FormRow>

      <FormRow>
        <Field
          name="timezone"
          label={c('User Settings').t`Time zone`}
          component={TimezoneSelectorWrapper}
          required
          // data-testid={testIdUserTimezone}
        />
      </FormRow>
    </>
  )
}

export default SectionUserInfo
