// Query keys for caching data
import { apiRequest } from 'utils/request'
import axios from 'axios'
import { useQuery } from 'react-query'
import { ResultAllFromBulkMdc } from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.types'
import { BulkMeterDataCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { isParkWithNoPlants, isSolarPlant, isWindPlant } from 'utils/asset'
import { Asset, TYPE_SOLARPLANT, TYPE_WINDPLANT } from 'modules/asset/store/asset.types'
import { User } from 'modules/auth/Auth.types'
import { hasPermissionForPvBulkMeterDataCleansing, hasPermissionForWindBulkMeterDataCleansing } from 'utils/user'

export const QUERY_KEY_BULK_METER_DATA_CLEANSING = 'bulkMeterDataCleansing'

interface GetAllFromBulkMdcProps {
  page: number
  size: number
  includeImage: boolean
  solarAssets: Asset[]
  windAssets: Asset[]
  filterSettings: BulkMeterDataCleansingFilterSettings | null
}

const getAllFromBulkMdc = async ({
  page,
  size,
  includeImage,
  solarAssets,
  windAssets,
  filterSettings,
}: GetAllFromBulkMdcProps) => {
  const solarAssetIds = solarAssets.map((a) => a.id)
  const windAssetIds = windAssets.map((a) => a.id)

  return await apiRequest<ResultAllFromBulkMdc>(() =>
    axios.post(
      `api/meterData-cleansing/v1/bulk-cleansing?page=${page}&size=${size}&includeImage=${includeImage}`,
      filterSettings
        ? {
            solar: {
              assetIds: solarAssetIds || [],
              cleansingParams: filterSettings?.solar || {},
            },
            wind: {
              assetIds: windAssetIds || [],
              cleansingParams: filterSettings?.wind || {},
            },
          }
        : {
            solar: {
              assetIds: solarAssetIds || [],
            },
            wind: {
              assetIds: windAssetIds || [],
            },
          },
    ),
  )
}

interface GetBulkMdcProps {
  page: number
  size: number
  includeImage: boolean
  assets: Asset[]
  filterSettings: BulkMeterDataCleansingFilterSettings | null
  user: User
}

export const useGetAllFromBulkMdc = ({ page, size, includeImage, assets, filterSettings, user }: GetBulkMdcProps) => {
  const solarAssets = hasPermissionForPvBulkMeterDataCleansing(user)
    ? assets.filter((asset) => isSolarPlant(asset) || isParkWithNoPlants(asset, TYPE_SOLARPLANT))
    : []
  const windAssets = hasPermissionForWindBulkMeterDataCleansing(user)
    ? assets.filter((asset) => isWindPlant(asset) || isParkWithNoPlants(asset, TYPE_WINDPLANT))
    : []

  return useQuery(
    [QUERY_KEY_BULK_METER_DATA_CLEANSING, page, size],
    () => getAllFromBulkMdc({ page, size, includeImage, solarAssets, windAssets, filterSettings }),
    { keepPreviousData: true },
  )
}
