import React, { useEffect, useState } from 'react'
import { timeAgo } from 'utils/date'

interface TimeAgoProps {
  date: Date
}

const TimeAgo: React.FC<TimeAgoProps> = ({ date }) => {
  const [timeAgoValue, setTimeAgoValue] = useState(timeAgo(date))

  useEffect(() => {
    const calculateTimeAgoValue = setInterval(() => {
      setTimeAgoValue(timeAgo(date))
    }, 1 * 1000)

    return () => {
      clearInterval(calculateTimeAgoValue)
    }
  }, [date])
  return <>{timeAgoValue}</>
}

export default React.memo(TimeAgo)
