import React, { useEffect, useState } from 'react'
import { DisableContainer } from 'modules/dataStreams/siteForecast/SiteForecastForm'
import { FormKey, FormKeyValueGrid, FormValue, SiteForecastSelectField } from 'ui/form/assetForm.style'
import { c, jt, t } from 'ttag'
import { Field } from 'react-final-form'
import { Select } from 'final-form-material-ui'
import { MenuItem } from '@material-ui/core'
import { labelsForForecastModelCategory, staticForecastModelCategory } from 'utils/siteForecastConfiguration'
import PopperTooltip from 'ui/PopperTooltip'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import Flex from 'ui/styles/Flex'
import { getForecastModelCategoryForUser } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { LinkToContactSupport } from 'ui/elements/MailLink'
import styled from 'styled-components'

// 46.2 is sum of width between FormKey and FormValue
const Info = styled.div`
  position: absolute;
  right: -1em;
  @media (min-width: 1900px) {
    right: calc(100% - 46.2em);
  }
`
interface SectionModelTypeProps {
  disableFecs: boolean
}
const SectionModelType: React.FC<SectionModelTypeProps> = ({ disableFecs }) => {
  // State for handling forecast model category options
  const [forecastModelCategoryOptions, setForecastModelCategoryOptions] = useState<string[]>(
    staticForecastModelCategory(),
  )

  useEffect(() => {
    getForecastModelCategoryForUser()
      .then((res) => {
        if (res && res.length > 0) {
          setForecastModelCategoryOptions(res)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const forecastModelCategoryTooltipContent = () => {
    const e3Text = <i>{t`e³ Forecast`}</i>
    const metaText = <i>{t`Meta Forecast`}</i>
    return (
      <>
        <p>{t`Select the model type to be used for calculating the power generation forecast:`}</p>
        <ul>
          <li>
            {jt`${e3Text} – A weighted combination of models based on multiple numerical weather prediction models and machine learning. This is the standard method which enercast uses to calculate forecasts from scratch.`}
          </li>
          <li>
            {jt`${metaText} – A weighted combination of multiple power generation forecasts from enercast or third parties. This helps you integrate forecasts from multiple sources using quality-optimized weights.`}
          </li>
        </ul>
        <p>
          {t`If the respective asset does not have a model of the selected type, no forecast will be generated for this forecast configuration.`}
        </p>
        <p>
          {jt`Model type selection is available only for accounts that meet certain technical and commercial requirements. If the selection is disabled, please contact ${LinkToContactSupport}.`}
        </p>
      </>
    )
  }
  return (
    <Flex direction="row" alignItems="center" style={{ position: 'relative', width: '100%' }}>
      <DisableContainer disable={disableFecs} style={{ width: 'inherit' }}>
        <FormKeyValueGrid>
          <FormKey>{c('Site Forecast:Config').t`Model type:`}</FormKey>
          <FormValue>
            <SiteForecastSelectField>
              <Field name="forecastModelCategory" component={Select} fullWidth>
                {forecastModelCategoryOptions.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {labelsForForecastModelCategory(category)}
                  </MenuItem>
                ))}
              </Field>
            </SiteForecastSelectField>
          </FormValue>
        </FormKeyValueGrid>
      </DisableContainer>
      <Info>
        <PopperTooltip
          contentWidth="48em"
          popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
          popperContent={forecastModelCategoryTooltipContent()}
        />
      </Info>
    </Flex>
  )
}

export default React.memo(SectionModelType)
