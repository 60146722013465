import React, { useCallback } from 'react'
import styled from 'styled-components'

import { SelectAsListItem } from 'ui/form/SelectAsList'
import { Box, MenuItem } from '@material-ui/core'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { useCustomMuiStyles } from 'themes/theme-light'

const IconPlaceholder = styled.div`
  width: 17.5px;
`

interface CheckmarkRadioProps {
  options: SelectAsListItem[]
  selected: string | string[] | null
  disabled?: boolean
  onChange: (option: SelectAsListItem) => void
  disableItem?: string
}

const CheckmarkRadio: React.FC<CheckmarkRadioProps> = ({ options, selected, onChange, disableItem }) => {
  const classes = useCustomMuiStyles()
  const handleChange = useCallback(
    (option: SelectAsListItem) => (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      onChange(option)
    },
    [onChange],
  )

  return (
    <>
      {options.map((option) => {
        const checked = Array.isArray(selected) ? selected.includes(option.key) : option.key === selected
        return (
          <MenuItem
            key={option.key}
            disabled={disableItem === option.key}
            onClick={handleChange(option)}
            className={option.isAdminComponent ? classes.adminComponent : 'inherit'}
          >
            {checked ? <FontAwesomeActionIcon icon="check" fixedWidth /> : <IconPlaceholder />}
            <Box width={'100%'} ml={1} display="flex" justifyContent="space-between">
              {option.label} <Box ml={1}>{option?.itemMetaData} </Box>
            </Box>
          </MenuItem>
        )
      })}
    </>
  )
}

export default React.memo(CheckmarkRadio)
