import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { FormApi } from 'final-form'
import { PARK_DATA_MODE_CALCULATE, PARK_DATA_MODE_MANUAL } from 'fixtures/assetForm'
import { Asset } from 'modules/asset/store/asset.types'
import React, { useCallback, useEffect, useState } from 'react'
import { FormSpy } from 'react-final-form'
import { t } from 'ttag'

import Flex from 'ui/styles/Flex'
import { isPark } from 'utils/asset'

interface ManageTechnicalDataProps {
  asset: Asset
  form: FormApi<Asset>
  enterManually: boolean
  onChange: (val: string) => void
}

const ManageTechnicalData: React.FC<ManageTechnicalDataProps> = ({ asset, form, enterManually, onChange }) => {
  const [formState, setFormState] = useState(asset)

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value)
  }, [])

  useEffect(() => {
    if (isPark(formState)) {
      form.mutators.setValue('enterManually', enterManually)
    }
  }, [formState?.type, enterManually])

  // const numPartsText = <strong key="numParts">{numParts}</strong>

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as Asset)
        }}
      />
      {/*{!enterManually ? (*/}
      {/*  <div>*/}
      {/*    <Box mb={1}>*/}
      {/*      <Typography>*/}
      {/*        {t`Overall capacity of this park is calculated from the sum of all child plants assigned to it. Each plant is*/}
      {/*        configured individually.`}*/}
      {/*      </Typography>*/}
      {/*    </Box>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  <Box mb={1}>{jt`This park consists of ${numPartsText} plants. All share the following configuration:`}</Box>*/}
      {/*)}*/}

      <RadioGroup
        name="mode"
        value={enterManually ? PARK_DATA_MODE_MANUAL : PARK_DATA_MODE_CALCULATE}
        onChange={handleChange}
      >
        <Flex direction="row">
          <FormControlLabel
            value={PARK_DATA_MODE_CALCULATE}
            control={<Radio size="small" />}
            label={t`Calculate technical data from individual plants`}
            title={t`Overall capacity of this park is calculated from the sum of all child plants assigned to it. Each plant is
             configured individually.`}
          />

          <FormControlLabel
            value={PARK_DATA_MODE_MANUAL}
            control={<Radio size="small" />}
            label={t`Enter technical data manually`}
            title={t`This park consists of multiple plants. All share the configuration specified below.`}
          />
        </Flex>
      </RadioGroup>
    </>
  )
}

export default React.memo(ManageTechnicalData)
