import React from 'react'
import Flex from 'ui/styles/Flex'
import DurationWithArrows from 'ui/datetime/DurationWithArrows'
import { Field } from 'react-final-form'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from '@material-ui/core/Radio/Radio'
import { c } from 'ttag'
import { Box } from '@material-ui/core'
import { OffsetType, SiteForecastHorizonType } from 'modules/dataStreams/dataStreams.types'

interface ForecastAndEvaluationStartProps {
  daysInputName: string
  maxDays?: number
  hoursInputName: string
  minutesInputName: string
  maxMinutes?: number
  radioButtonName: string
  radioButtonValue: SiteForecastHorizonType | OffsetType
  firstRadioButtonOptionValue: SiteForecastHorizonType | OffsetType
  secondRadioButtonOptionValue: SiteForecastHorizonType | OffsetType
}

const ForecastAndEvaluationStart: React.FC<ForecastAndEvaluationStartProps> = ({
  daysInputName,
  maxDays,
  hoursInputName,
  minutesInputName,
  maxMinutes,
  radioButtonName,
  radioButtonValue,
  firstRadioButtonOptionValue,
  secondRadioButtonOptionValue,
}) => {
  return (
    <Flex direction="row" flexWrap="wrap">
      <DurationWithArrows
        daysInputName={daysInputName}
        maxDays={maxDays}
        maxMinutes={maxMinutes}
        repeatDays={false}
        hoursInputName={hoursInputName}
        minutesInputName={minutesInputName}
      />

      <Box mt={0} ml={0.5}>
        <Field name={radioButtonName} type="radio">
          {({ input }) => {
            return (
              <RadioGroup name={input.name} value={radioButtonValue} onChange={input.onChange}>
                <Flex direction="column">
                  <FormControlLabel
                    value={firstRadioButtonOptionValue}
                    control={<Radio size="small" />}
                    label={c('Site Forecast:Config').t`from delivery time`}
                    title={c('Site Forecast:Config').t`from delivery time`}
                  />

                  <FormControlLabel
                    value={secondRadioButtonOptionValue}
                    control={<Radio size="small" />}
                    label={c('Site Forecast:Config').t`from start of day of delivery`}
                    title={c('Site Forecast:Config').t`from start of day of delivery`}
                  />
                </Flex>
              </RadioGroup>
            )
          }}
        </Field>
      </Box>
    </Flex>
  )
}

export default React.memo(ForecastAndEvaluationStart)
