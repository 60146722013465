import { combineReducers } from 'redux'
import { all, call, put } from 'redux-saga/effects'
import axios from 'axios'

import createReducer from 'utils/createReducer'
import { request } from 'utils/request'
import { WeatherCatalog, WeatherConfig } from './weather.types'
import { createSelector } from 'reselect'

// state
interface State {
  result: WeatherConfig[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
}

// types

export const GET_WEATHERCONFIGS_REQUEST = 'GET_WEATHERCONFIGS_REQUEST'
export const GET_WEATHERCONFIGS_SUCCESS = 'GET_WEATHERCONFIGS_SUCCESS'
export const GET_WEATHERCONFIGS_FAILURE = 'GET_WEATHERCONFIGS_FAILURE'
export const GET_WEATHERCONFIGS_DISMISS = 'GET_WEATHERCONFIGS_DISMISS'
export type GET_WEATHERCONFIGS_REQUEST = typeof GET_WEATHERCONFIGS_REQUEST
export type GET_WEATHERCONFIGS_SUCCESS = typeof GET_WEATHERCONFIGS_SUCCESS
export type GET_WEATHERCONFIGS_FAILURE = typeof GET_WEATHERCONFIGS_FAILURE
export type GET_WEATHERCONFIGS_DISMISS = typeof GET_WEATHERCONFIGS_DISMISS
export type GET_WEATHERCONFIGS_ACTION_TYPE =
  | GET_WEATHERCONFIGS_REQUEST
  | GET_WEATHERCONFIGS_SUCCESS
  | GET_WEATHERCONFIGS_FAILURE
  | GET_WEATHERCONFIGS_DISMISS

interface GetWeatherConfigsAction {
  type: GET_WEATHERCONFIGS_ACTION_TYPE
  weatherConfigs: State['result']
  error: State['error']
}

// reducers

const result = createReducer<State['result'] | undefined, GetWeatherConfigsAction>(
  (state = initialState.result, { type, weatherConfigs }) => {
    if (type === GET_WEATHERCONFIGS_SUCCESS) {
      state = weatherConfigs
    }
    return state
  },
)

const loading = createReducer<State['loading'] | undefined, GetWeatherConfigsAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case GET_WEATHERCONFIGS_REQUEST:
        return true
      case GET_WEATHERCONFIGS_SUCCESS:
      case GET_WEATHERCONFIGS_FAILURE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'] | undefined, GetWeatherConfigsAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case GET_WEATHERCONFIGS_SUCCESS:
      case GET_WEATHERCONFIGS_DISMISS:
        return null
      case GET_WEATHERCONFIGS_FAILURE:
        return error
    }
    return state
  },
)

export const getWeatherConfigsReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors

export const getWeatherConfigsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.weather.getWeatherConfigs.result,
  (result) => result,
)
export const getWeatherConfigsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.weather.getWeatherConfigs.loading,
  (loading) => loading,
)
export const getWeatherConfigsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.weather.getWeatherConfigs.error,
  (error) => error,
)

// api
export const getWeatherConfigs = () => {
  return request(() => axios.get<WeatherConfig[]>('/api/usersettings/weatherconfig'))
}

export const getWeatherLabels = () => {
  return request(() => axios.get<WeatherCatalog>('/api/weather-data-catalog/v1/all'))
}

// sagas
export function* getWeatherConfigsSaga() {
  const [weatherConfigsResult, weatherLabelsResult] = yield all([call(getWeatherConfigs), call(getWeatherLabels)])
  if (weatherConfigsResult.isSuccessful && weatherLabelsResult.isSuccessful) {
    const weatherLabels: WeatherCatalog = weatherLabelsResult.getData()
    const weatherConfigs: WeatherConfig[] = (weatherConfigsResult.getData() || []).map(
      (weatherConfig: WeatherConfig) => {
        return {
          id: weatherConfig.id,
          label: (weatherLabels.UniqueCatalogLabel as any)[weatherConfig.id],
        }
      },
    )
    yield put({ type: GET_WEATHERCONFIGS_SUCCESS, weatherConfigs, weatherLabels })
  } else {
    const errors = [weatherConfigsResult.getError(), weatherLabelsResult.getError()]
    const error = errors[0] // we take only first error, to avoid confusion
    yield put({ type: GET_WEATHERCONFIGS_FAILURE, error })
  }
}
