import { combineReducers } from 'redux'
import { takeEvery } from 'redux-saga/effects'

import { GET_PRODUCTS_REQUEST, getProductsReducer, getProductsSaga } from './getProducts.state'

export const productReducer = combineReducers({
  getProducts: getProductsReducer,
})

export function* productSaga() {
  yield takeEvery(GET_PRODUCTS_REQUEST, getProductsSaga)
}
