import React, { useMemo } from 'react'
import { Tooltip } from 'react-jsx-highstock'

import { Timezone } from 'fixtures/timezones'
import { getTooltipFormatter } from 'utils/chart'

interface TooltipOptionsProps {
  timezone?: Timezone
  options?: Record<string, unknown>
  seriesCreatedWithDateZero?: boolean
  displayOnlyTime?: boolean
}

const TooltipOptions: React.FC<TooltipOptionsProps> = ({
  timezone,
  options,
  seriesCreatedWithDateZero = false,
  displayOnlyTime = false,
}) => {
  const tooltipOptions = useMemo(() => {
    return {
      backgroundColor: 'rgba(245, 245, 245, 0.85)',
      borderColor: 'rgba(205, 205, 205, 0.5)',
      borderRadius: 3,
      borderWidth: 1,
      formatter: timezone ? getTooltipFormatter(timezone, seriesCreatedWithDateZero, displayOnlyTime) : undefined,
      // headerFormat: '<span>{point.x:%Y-%m-%d %H:%M}</span>',
      shared: true,
      split: false,
      style: {
        color: '#111',
      },
      useHTML: true,
      valueDecimals: 1,
      ...(options || {}),
    }
  }, [timezone])

  return <Tooltip {...tooltipOptions} />
}

export default React.memo(TooltipOptions)
