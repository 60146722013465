import { CellRenderType, Column, ColumnSortType, ReTableItem } from 'modules/reTable/reTable.types'
import { c, t } from 'ttag'
import moment from 'moment'
import { removeDuplicates } from 'utils/array'
import {
  DataStreamSelection,
  DataStreamSelectionItem,
  EPEX,
  germanyRevenueDatastreams,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { getUnitForDataStreamCategory } from 'utils/units'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useEvaluationData } from 'modules/workspace/advancedChartWidgets/evaluation/api/evaluation.api'
import { getDataStreamName } from 'utils/dataStream'
import { User } from 'modules/auth/Auth.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForEpexDataStreams, isAdmin, isImpersonatedAdmin } from 'utils/user'

export const getEvaluationTableColumns: (
  dataStreams: DataStreamSelectionItem[],
  isBackcastActive: boolean,
  user: User,
) => Column[] = (dataStreams, isBackcastActive, user) => {
  const dynamicColumn: Column[] = []

  if (dataStreams.length > 0) {
    dataStreams.forEach((dataStream) => {
      const getColumnLabel = () => {
        const { type, subType, classifier, label, name } = dataStream
        if (type === TimeSeriesType.MARKET_PRICE_DATA || type === TimeSeriesType.METER_DATA) {
          return getDataStreamName({ type, subType, classifier })
        }
        return label || name
      }

      const handleIsAdminComponent = (): boolean => {
        if (dataStream.classifier?.includes(EPEX)) {
          return (isAdmin(user) || isImpersonatedAdmin(user)) && !hasPermissionForEpexDataStreams(user)
        }
        return false
      }

      dynamicColumn.push({
        name: dataStream.id || '',
        label: getColumnLabel(),
        cellRenderType: CellRenderType.NUMERIC,
        columnSortType: ColumnSortType.FIELD,
        searchable: false,
        sortable: false,
        width: '12em',
        fieldName: dataStream.id,
        isEditable: false,
        fixed: true,
        unit: getUnitForDataStreamCategory(dataStream.type, dataStream.subType, dataStream.classifier),
        isAdminComponent: handleIsAdminComponent(),
      })
      if (isBackcastActive && dataStream.type === TimeSeriesType.SITE_FORECAST) {
        dynamicColumn.push({
          name: `${dataStream.id}-${BackcastRecognition}` || '',
          label: `${getColumnLabel()} ${BackcastRecognition}`,
          cellRenderType: CellRenderType.NUMERIC,
          columnSortType: ColumnSortType.FIELD,
          searchable: false,
          sortable: false,
          width: '12em',
          fieldName: `${dataStream.id}-${BackcastRecognition}`,
          isEditable: false,
          fixed: true,
          unit: getUnitForDataStreamCategory(dataStream.type, dataStream.subType, dataStream.classifier),
        })
      }
    })
  }

  const staticColumns = [
    {
      name: 'period',
      label: c('Evaluation').t`Period`,
      cellRenderType: CellRenderType.TEXT,
      columnSortType: ColumnSortType.FIELD,
      searchable: false,
      sortable: false,
      width: '10em',
      fieldName: 'period',
      isEditable: false,
      fixed: true,
    },
    {
      name: 'from',
      label: c('Evaluation').t`From`,
      cellRenderType: CellRenderType.TEXT,
      columnSortType: ColumnSortType.FIELD,
      searchable: false,
      sortable: false,
      width: '10em',
      fieldName: 'from',
      isEditable: false,
      fixed: false,
    },
    {
      name: 'to',
      label: c('Evaluation').t`To`,
      cellRenderType: CellRenderType.TEXT,
      columnSortType: ColumnSortType.FIELD,
      searchable: false,
      sortable: false,
      width: '10em',
      fieldName: 'to',
      isEditable: false,
      fixed: false,
    },
  ]

  return [...staticColumns, ...dynamicColumn]
}

export const evaluationHierarchy = {
  PARENT: 'parent',
  FIRST_LEVEL_CHILD: 'firstLevelChild',
  SECOND_LEVEL_CHILD: 'secondLevelChild',
}

export const BackcastRecognition = '[Backcast]'

// We need the following const because we are showing market price data (and area forecast) as asset in evaluation table
export const dataStreamWithoutAssetIdCombination = 'dataStreamWithoutAssetIdCombination'

export const useEvaluationTableItems = () => {
  const finalData = useEvaluationData()
  const user = useSelector(getUserResultSelector)
  const { data, isSuccess, isError, isLoading } = finalData

  const evaluationTableItemsForReTable: any = []
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)

  if (isSuccess && !isLoading) {
    const filteredSelectedDataStream = filteredSelectedDataStreamForEvaluationTable(selectedDataStreams, user)

    const evaluationTransformedData = data.transformedData
    const evaluationOriginalData = data.originalData

    // We are setting value for each selected data stream id , example default: 1
    const processDataStream = (singleTransformedDataObj: any, level: number, yearKey?: string, monthKey?: string) => {
      filteredSelectedDataStream.forEach((dataStream) => {
        if (dataStream.id) {
          const findDataStream = (backcast?: boolean) =>
            evaluationOriginalData?.find(
              (dataStreamObj) =>
                dataStreamObj.identifier === singleTransformedDataObj.identifier &&
                dataStreamObj.streamIdentifier === dataStream.id &&
                (!backcast || dataStreamObj.backcast),
            )

          const relatedDataStream = findDataStream()
          const relatedBackcast = findDataStream(true)

          const getKey = (backcastKey?: boolean) =>
            backcastKey ? `${dataStream.id}-${BackcastRecognition}` : dataStream.id

          if (relatedDataStream) {
            const key = getKey()

            if (level === 0 && key) {
              singleTransformedDataObj.wholePeriod[key] = relatedDataStream.wholePeriod.calculatedValue
            } else if (key && level === 1 && yearKey) {
              singleTransformedDataObj.years[yearKey][key] = relatedDataStream.years[yearKey].calculatedValue
            } else if (key && level === 2 && yearKey && monthKey) {
              singleTransformedDataObj.months[yearKey][monthKey][key] =
                relatedDataStream.months[yearKey][monthKey].calculatedValue
            }
          }

          if (dataStream.type === TimeSeriesType.SITE_FORECAST && relatedBackcast) {
            const backcastKey = getKey(true)

            if (level === 0 && backcastKey) {
              singleTransformedDataObj.wholePeriod[backcastKey] = relatedBackcast.wholePeriod.calculatedValue
            } else if (backcastKey && level === 1 && yearKey) {
              singleTransformedDataObj.years[yearKey][backcastKey] = relatedBackcast.years[yearKey].calculatedValue
            } else if (backcastKey && level === 2 && yearKey && monthKey) {
              singleTransformedDataObj.months[yearKey][monthKey][backcastKey] =
                relatedBackcast.months[yearKey][monthKey].calculatedValue
            }
          }
        }
      })
    }

    evaluationTransformedData.forEach((singleTransformedDataObj, index) => {
      // because we don't need show asset row for dataStreamWithoutAssetIdCombination
      // we also need to handle levels
      if (singleTransformedDataObj.identifier !== dataStreamWithoutAssetIdCombination) {
        processDataStream(singleTransformedDataObj, 0)

        // 1. Create parent , which is whole period (level 0)
        const parentPerAsset = transformEvaluationDataForReTable(
          singleTransformedDataObj.identifier,
          singleTransformedDataObj.wholePeriod,
          0,
          index,
          '',
          '',
          Object.keys(singleTransformedDataObj.years), // we need to pass also next children , in this case we are passing years, for example: [2020, 2021, 2022 , 2023]
        )
        evaluationTableItemsForReTable.push(parentPerAsset)
      }

      // 2. Create first level child , and direct descendants of first level child (which is second level child)
      // Creating descendants directly first level is a MUST , because this is how reTable works.
      Object.keys(singleTransformedDataObj.years).map((yearKey) => {
        processDataStream(singleTransformedDataObj, 1, yearKey)

        // 1. Create first level child
        const firstLevelChild = transformEvaluationDataForReTable(
          singleTransformedDataObj.identifier,
          singleTransformedDataObj.years[yearKey],
          1,
          index,
          yearKey,
          '',
          Object.keys(singleTransformedDataObj.months[yearKey]), // we need to pass also next children , in this case we are passing months , for example: [7, 8 ,9 ,10] (each number represents , the number of the month in the calendar)
        )
        evaluationTableItemsForReTable.push(firstLevelChild)

        // 2. Direct descendants of first level child (which is second level child)
        const monthsOfGiveYear = singleTransformedDataObj.months[yearKey]
        Object.keys(monthsOfGiveYear).map((monthKey) => {
          processDataStream(singleTransformedDataObj, 2, yearKey, monthKey)

          const secondLevelChild = transformEvaluationDataForReTable(
            singleTransformedDataObj.identifier,
            monthsOfGiveYear[monthKey],
            2,
            index,
            monthKey,
            yearKey,
          )
          evaluationTableItemsForReTable.push(secondLevelChild)
        })
      })
    })
  }

  return { evaluationTableItemsForReTable, isSuccess, isError, isLoading }
}

export const transformEvaluationDataForReTable = (
  identifier: string,
  pureDataForTransformation: any,
  level: number,
  index: number,
  currentChildName?: string,
  prevChildName?: string,
  nextChildName?: string[],
) => {
  const { PARENT, FIRST_LEVEL_CHILD, SECOND_LEVEL_CHILD } = evaluationHierarchy
  const transformedData: ReTableItem = pureDataForTransformation

  if (level === 0) {
    transformedData.identifier = identifier
    transformedData.id = `${PARENT}-${index}`
    transformedData.name = t`Full Period`
    transformedData.highlight = false
    transformedData.uiAncestors = []
    transformedData.uiParents = []
    transformedData.uiChildren = nextChildName?.map((name) => {
      return `${FIRST_LEVEL_CHILD}-${name}-${index}`
    })

    transformedData.uiDescendants = nextChildName?.map((name) => {
      return `${FIRST_LEVEL_CHILD}-${name}-${index}`
    })
    transformedData.uiHasMatchingDescendants = true
    transformedData.uiHasMatchingParents = false
    transformedData.uiIsMatching = true
    transformedData.uiLevel = level
  } else if (level === 1) {
    transformedData.identifier = identifier
    transformedData.id = `${FIRST_LEVEL_CHILD}-${currentChildName}-${index}`
    transformedData.name = currentChildName
    transformedData.year = currentChildName
    transformedData.highlight = false
    transformedData.uiAncestors = [`${PARENT}-${index}`]
    transformedData.uiParents = [`${PARENT}-${index}`]
    transformedData.uiChildren = nextChildName?.map((name) => {
      return `${SECOND_LEVEL_CHILD}-${name}-${currentChildName}-${index}`
    })
    transformedData.uiDescendants = nextChildName?.map((name) => {
      return `${SECOND_LEVEL_CHILD}-${name}-${currentChildName}-${index}`
    })
    transformedData.uiHasMatchingDescendants = true
    transformedData.uiHasMatchingParents = true
    transformedData.uiIsMatching = true
    transformedData.uiLevel = level
  } else if (level === 2) {
    transformedData.identifier = identifier
    transformedData.id = `${SECOND_LEVEL_CHILD}-${currentChildName}-${prevChildName}-${index}`
    transformedData.name = currentChildName
    transformedData.yearAndMonth = prepareYearAndMonth(prevChildName, currentChildName)
    transformedData.highlight = false
    transformedData.uiAncestors = [`${PARENT}-${index}`, `${FIRST_LEVEL_CHILD}-${prevChildName}-${index}`]
    transformedData.uiParents = [`${PARENT}-${index}`, `${FIRST_LEVEL_CHILD}-${prevChildName}-${index}`]
    transformedData.uiChildren = []
    transformedData.uiDescendants = []
    transformedData.uiHasMatchingDescendants = false
    transformedData.uiHasMatchingParents = true
    transformedData.uiIsMatching = true
    transformedData.uiLevel = level
  }

  return transformedData
}

const prepareYearAndMonth = (year: string, month: string) => {
  if (month.length === 1) {
    return `${year}-0${month}`
  }
  return `${year}-${month}`
}

export const getYearAndMonths = (formattedSelectedDatesWithTimezone: string[]) => {
  const startDate = moment(formattedSelectedDatesWithTimezone[0])
  const endDate = moment(formattedSelectedDatesWithTimezone[1])

  const yearAndMonth: string[] = []
  const years = []

  const month = moment(startDate) //clone the startDate

  while (month <= endDate) {
    yearAndMonth.push(month.format('YYYY-MM'))
    years.push(month.format('YYYY'))
    month.add(1, 'month')
  }

  const cleanYears = removeDuplicates(years)

  return { yearAndMonth, cleanYears }
}

export const getEvaluationTimePeriodOptions = () => {
  return [
    { id: 'custom', label: t`Custom` },
    { id: 'last3years', label: t`Last 3 years` },
  ]
}
// For evaluation table , currently we are supporting MARKET_PRICE_DATA, SITE_FORECAST, METER_DATA
export const filteredSelectedDataStreamForEvaluationTable = (dataStreams: DataStreamSelection, user: User) => {
  const filteredDataStreams = dataStreams.filter((dataStream) => {
    const { type, classifier } = dataStream
    const isMarketPriceDataStream =
      type === TimeSeriesType.MARKET_PRICE_DATA && !germanyRevenueDatastreams.includes(classifier)
    const isEPEXDataStream = classifier?.includes(EPEX)
    const isAllowedDataStreamType =
      isMarketPriceDataStream || type === TimeSeriesType.SITE_FORECAST || type === TimeSeriesType.METER_DATA

    const isAllowedByUserPermissions = !(
      isEPEXDataStream &&
      !hasPermissionForEpexDataStreams(user) &&
      !isAdmin(user) &&
      !isImpersonatedAdmin(user)
    )

    return isAllowedDataStreamType && isAllowedByUserPermissions
  })

  return filteredDataStreams
}
