import { combineReducers } from 'redux'
import * as actionTypes from '../auth.action.types'
import { LOGOUT_SUCCESS } from '../auth.action.types'
import createReducer from 'utils/createReducer'
import { LoginAction } from '../../Auth.types'
import { createSelector } from 'reselect'

// state
interface State {
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  initialized: false,
}

const loading = createReducer<State['loading'], LoginAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.LOGIN_REQUEST:
      return true
    case actionTypes.LOGIN_SUCCESS:
    case actionTypes.LOGIN_FAILURE:
    case actionTypes.CLEAR_LOGIN_STATE:
      return false
  }
  return state
})

const error = createReducer<State['error'], LoginAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.LOGIN_SUCCESS:
      case actionTypes.LOGIN_DISMISS:
      case actionTypes.CLEAR_LOGIN_STATE:
        return null
      case actionTypes.LOGIN_FAILURE:
        return error
    }
    return state
  },
)

const initialized = createReducer<State['initialized'], LoginAction>((state = initialState.initialized, { type }) => {
  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return true
    case LOGOUT_SUCCESS:
      return false
  }
  return state
})

// reducer
export const loginReducer = combineReducers({
  loading,
  error,
  initialized,
})

// selectors
export const loginLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.login.loading,
  (loading) => loading,
)
export const loginErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.login.error,
  (error) => error,
)
export const loginInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.auth.login.initialized,
  (initialized) => initialized,
)
