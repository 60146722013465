export function getSelected(state) {
  return state.gips.selected.shapes
}

export function getSelectedRow(state) {
  return state.gips.selected.row
}

export function getSelectedPoints(state) {
  return state.gips.selected.points
}

export function getSelectedPoint(state) {
  return state.gips.selected.point
}
