import React from 'react'
import styled from 'styled-components'

import FullHeight from './FullHeight'

interface CenteredProps {
  fullHeight?: boolean
}

const centerStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  ${centerStyles}
`

const FullContainer = styled(FullHeight)`
  ${centerStyles}
`

const Centered: React.FC<CenteredProps> = ({ fullHeight = false, children }) => (
  <>{fullHeight ? <FullContainer>{children}</FullContainer> : <Container>{children}</Container>}</>
)

export default React.memo(Centered)
