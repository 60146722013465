import { AREA_ID } from 'fixtures/areaLabels'
import { Timezone } from 'fixtures/timezones'
import { STAGE } from 'pages/workbench/store/workbench.types'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { ClusterTypesEnum } from 'fixtures/assetForm'

// status
export const STATUS_ACTIVE = 'ACTIVE'
export type STATUS_ACTIVE = typeof STATUS_ACTIVE
export type STATUS = STATUS_ACTIVE
export const FORECAST_MODEL_FOR_BACK_CAST_ID = '-a-id-f-id-'

// type
export enum GenericAssetType {
  CLUSTER = 'CLUSTER',
  PARK = 'PARK',
  GENERATOR = 'GENERATOR',
  METERDATA = 'METERDATA', // for sake of completeness, not used anywhere
}
export enum UiClusterTypes {
  CLUSTER = 'cluster',
  CLUSTER_POOL = 'clusterPool',
  CLUSTER_HYBRID = 'clusterHybrid',
}

export const TYPE_AREA = 'area'
export const TYPE_CLUSTER = 'cluster'
export const TYPE_WINDPARK = 'windpark'
export const TYPE_SOLARPARK = 'pvpark'
export const TYPE_WINDPLANT = 'wind'
export const TYPE_SOLARPLANT = 'pv'
export const TYPE_CHP = 'chp'
export type TYPE_AREA = typeof TYPE_AREA
export type TYPE_CLUSTER = typeof TYPE_CLUSTER
export type TYPE_WINDPARK = typeof TYPE_WINDPARK
export type TYPE_SOLARPARK = typeof TYPE_SOLARPARK
export type TYPE_WINDPLANT = typeof TYPE_WINDPLANT
export type TYPE_SOLARPLANT = typeof TYPE_SOLARPLANT
export type TYPE_CHP = typeof TYPE_CHP
export type TYPE_LOCATION_ASSET = TYPE_WINDPARK | TYPE_SOLARPARK | TYPE_WINDPLANT | TYPE_SOLARPLANT | TYPE_CHP
export type TYPE_ASSET = TYPE_CLUSTER | TYPE_LOCATION_ASSET

export const RELATION_TYPE_CLUSTER = 'cluster'
export const RELATION_TYPE_PARK = 'park'
export const RELATION_TYPE_PLANT = 'plant'
export const RELATION_TYPE_NONE = 'none'
export type RELATION_TYPE_CLUSTER = typeof RELATION_TYPE_CLUSTER
export type RELATION_TYPE_PARK = typeof RELATION_TYPE_PARK
export type RELATION_TYPE_PLANT = typeof RELATION_TYPE_PLANT
export type RELATION_TYPE_NONE = typeof RELATION_TYPE_NONE
export type RELATION_TYPE = RELATION_TYPE_CLUSTER | RELATION_TYPE_PARK | RELATION_TYPE_PLANT | RELATION_TYPE_NONE
export type UiAssetType = UiClusterTypes | TYPE_ASSET

export interface Area {
  id: AREA_ID
  name: string
  type: TYPE_AREA
  productCode: string
  args?: Record<string, string>
}

interface BaseAsset {
  id: string
  name: string
  type: TYPE_ASSET
  annotations: Record<string, unknown>
  assets: Asset[]
  changed: boolean
  currentNomCap: number
  deleted: boolean
  description?: string
  generatorIds: string[]
  shape?: string
  shapeUUID?: string
  productConfigIds: string[]
  forecastEnabled: boolean
  licensed: boolean
  orderNumber?: string
  revision: {
    id: number
    created: number
    activeFrom: number
    stage: STAGE
  }
  ppaRate: number
  penaltyRegulationId: string | null
  // added in UI for displaying hierarchy
  uiAncestors: string[]
  uiParents: string[]
  uiChildren: string[]
  uiDescendants: string[]
  uiLevel: number
  uiPpaChanged: boolean
  uiRegulationIdChanged: boolean
  uiNotOperational?: boolean
  uiAssetType?: UiAssetType
}

export interface Coordinate {
  latitude: number | undefined
  longitude: number | undefined
}

export type Nomcap = {
  since: string
  nomcap: number
  sinceTimeZone: Timezone
}

export interface Nomcaps {
  nomcaps: Nomcap[]
}

export type NomcapsByAsset = Record<string, number>

export interface AssetLocation {
  coordinate: Coordinate
  address?: string
  city?: string
  country?: string
  state?: string
  zip?: string
}

interface AssetWithLocation {
  autoDeploymentEnabled: boolean
  clusterIds: string[]
  key?: string
  eegId?: string
  location: AssetLocation
  interval?: {
    from?: Date
    to?: Date
  }
  status: STATUS
  ppaRate?: number
}

export interface BaseGenerator extends BaseAsset, AssetWithLocation {
  parkId: string
  nomCap: number
  nomCapTimeZone?: Timezone | null
  uiParkIds?: string[]
}

export interface Cluster extends BaseAsset, Nomcaps {
  clusterType: ClusterTypesEnum
  parkIds: string[]
}

export interface PeriodOfOperation {
  since: number
  sinceTimeZone?: Timezone | null
  to: number
  toTimeZone?: Timezone | null
}

export interface PlantProduction {
  manufacturer: string
  model: string
}

export interface Park extends BaseAsset, Nomcaps, AssetWithLocation, PeriodOfOperation {
  numParts: number
  __manualData?: boolean
}

export interface WindAttributes {
  hubHeight: number
  diameter: number
  offshore: boolean
}

export interface WindPark extends Park, PeriodOfOperation, PlantProduction {
  typeSpecificAttributes: WindAttributes
  enterManually?: boolean
}

export interface WindGenerator extends BaseGenerator, PeriodOfOperation, PlantProduction {
  typeSpecificAttributes: WindAttributes
}

export interface SolarAttributes {
  azimuth?: number
  tilt?: number
  trackerAzimuthFrom?: number
  trackerAzimuthTo?: number
  trackerTiltFrom?: number
  trackerTiltTo?: number
  tracker: number
  useCMV: boolean
  trackerName?: string
  acCapacity?: number
}

export interface SolarPark extends Park {
  typeSpecificAttributes: SolarAttributes
  enterManually?: boolean
}

export interface SolarGenerator extends BaseGenerator, PeriodOfOperation {
  numParts?: number
  typeSpecificAttributes: SolarAttributes
}

// CHP Interface , and its typeSpecificAttributes
export interface chpAttributes {
  namePlateConsumption: number
  absoluteCapacityHeat: number
  periodEnergySum: number
  periodStartDay: Date
  periodEndDay: Date
  periodStartDayTimeZone?: Timezone | null
  periodEndDayTimeZone?: Timezone | null
  region: string
  loadProfile: string
  expressionClass: string
}

export interface chpGenerator extends BaseGenerator {
  typeSpecificAttributes: chpAttributes
}

export type Generator = WindGenerator | SolarGenerator | chpGenerator
export type LocationAsset = Park | Generator
export type Asset = Cluster | LocationAsset

export type NewLocationAsset = Partial<LocationAsset>

// meterdata

export interface MeterdataSummary {
  availabilityDays: number
  lastImport: Date
  lastTimeseriesTimestamp: Date
  firstTimeseriesTimestamp: Date
  amountOfTimeseriesElements: number
}

export type MeterdataSummaries = Record<string, MeterdataSummary>

// correlations
export interface CorrelationItem {
  name: string
  value: number
}
export interface Correlation {
  value: number
  correlations: CorrelationItem[]
}
// correlations are grouped by asset and timestamp
interface CorrelationObject {
  data: Record<string, Record<string, Correlation> | undefined>
  meterDataStreamId: string
  correlationSetType?: TimeSeriesType
}

export type CorrelationSet = Record<string, CorrelationObject>

// asset specific types

export interface SolarPlantTrackerType {
  id: number
  name: string
  label: string
}

export interface TreeDataStreamsSelectionOptions {
  ctrlKey: boolean
  shiftKey: boolean
}
