import React from 'react'
import { ForecastModel } from 'modules/quality/quality.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import { isMetaForecastModel } from 'utils/forecastModel'

interface ForecastModelIconProps {
  forecastModel: ForecastModel
  activeModel: string
  selected?: boolean
  lightMode?: boolean
}

const ForecastModelIcon: React.FC<ForecastModelIconProps> = ({ forecastModel, activeModel, selected, lightMode }) => {
  let icon = 'code-branch'
  const metaModel = isMetaForecastModel(forecastModel)

  if (metaModel) {
    icon = 'sliders-h'
  }

  return (
    <FontAwesomeIcon
      style={{ width: '14px', paddingRight: '2px' }}
      color={
        selected && !lightMode ? 'white' : forecastModel.uuid === activeModel ? theme.palette.primary.main : 'inherit'
      }
      icon={icon}
    />
  )
}

export default ForecastModelIcon
