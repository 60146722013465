// array

export const sortObjectByKeys = (unordered: Record<string, any>) => {
  const ordered: Record<string, any> = {}
  Object.keys(unordered)
    .sort()
    .forEach(function (key) {
      ordered[key] = unordered[key]
    })
  return ordered
}

export const includesCaseInsensitive = (source: Array<string> | string, value: string) => {
  if (Array.isArray(source)) {
    const lowerCaseArray = source.map((item) => item.toLowerCase())
    return lowerCaseArray.includes(value.toLowerCase())
  } else {
    return source.toLowerCase().includes(value.toLowerCase())
  }
}

export const arrayIncludesSameValues = (array: string[]) => {
  if (!array.length) return false
  return array.every((item) => array.indexOf(item) === 0)
}

// deep merge strategies
export const overwriteMerge = (destination: Array<any>, sourceArray: Array<any>) => sourceArray

// object
export const isObject = (value: any) => {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export const deleteUiKeysFromObject = (object: any) => {
  if (isObject(object)) {
    Object.keys(object).forEach((key) => {
      if (key.startsWith('ui')) delete object[key]
    })
  }

  return object
}

export const removeDuplicates = (arrayItems: any[]) => {
  const uniqueItems = (arrayItems || []).filter((item, index, arrayItems) => {
    const valid = arrayItems.findIndex((a) => JSON.stringify(a) === JSON.stringify(item)) === index
    return valid
  })
  return uniqueItems
}

export const objectToDropdownValuesHandler = (
  objectToConvert: any,
  other?: boolean,
  otherName?: any,
  otherValue?: any,
) => {
  const finalArray: any[] = []
  const keys = Object.keys(objectToConvert)
  keys.forEach((key) => {
    other
      ? finalArray.push({ id: key, name: objectToConvert[key][otherName], other: objectToConvert[key][otherValue] })
      : finalArray.push({ id: key, name: objectToConvert[key] })
  })

  return finalArray
}

export const checkIfTwoArraysHaveSameValues = (array1: any[], array2: any[]) => {
  return array1.every((r) => array2.includes(r))
}

/**
 *
 * @param obj
 * @param propString
 * @description You can use a deep access function based on a string for the path
 * Example: var obj = {
 *   foo: {
 *     bar: {
 *       baz: 'test'
 *     }
 *   }
 * };
 * @usage: getPropByString(obj, 'foo.bar.baz')); // RETURNS 'test'
 */

export const getPropByString = (obj: any, propString: any) => {
  if (!propString) return obj
  let i
  let lengthOfArray
  let prop
  const props = propString.split('.')

  for (i = 0, lengthOfArray = props.length - 1; i < lengthOfArray; i++) {
    prop = props[i]

    const candidate = obj[prop]
    if (candidate !== undefined) {
      obj = candidate
    } else {
      break
    }
  }
  return obj[props[i]]
}

export const switchElementsInArray = (array: any[], fromIndex: number, toIndex: number) => {
  const element = array[fromIndex]
  array.splice(fromIndex, 1)
  array.splice(toIndex, 0, element)
  return array
}

/**
 *
 * @param a
 * @param b
 * @description Find differences between two objects. Doesn't work with nested obj.
 * Example:
 * const a = {a:'a',b:'b',c:'c', d:'d'};
 * const b = {a:'a',b: '1', c:'c', d:'2', f:'!!!'}
 * @usage: const c = getDifference(a,b); // RETURNS {b:'1', d:'2'}
 */
export const getDifference = (a: any, b: any) =>
  Object.entries(b)
    .filter(([key, val]) => a[key] !== val && key in a)
    .reduce((a, [key, v]) => ({ ...a, [key]: v }), {})

export const checkIfObjectHasValuesForAllKeys = (data: Record<string, any>): boolean => {
  return Object.keys(data).every((key) => data[key] !== null)
}

/**
 *
 * @param arrayToSort
 * @param refArray
 * @param key
 * @description This function sort an array of object based on another array of object , by specifying with which key and without mutating the original array.
 */

export const sortArrayBasedOnAnotherArray = (arrayToSort: any[], refArray: any[], key: string) => {
  const keysFromRefArray = refArray.map((value) => value[key])
  const sortedArray = arrayToSort.slice().sort((a, b) => {
    return keysFromRefArray.indexOf(a[key]) - keysFromRefArray.indexOf(b[key])
  })

  return sortedArray
}
