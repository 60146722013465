import React, { useCallback } from 'react'

import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

interface CustomLinkProps {
  route: string
  onLinkClick?: () => void
  children: React.ReactNode
}

const CustomLink: React.FC<CustomLinkProps> = ({ route, onLinkClick, children }) => {
  const handleLinkClick = useCallback(() => {
    if (onLinkClick) {
      onLinkClick()
    } else {
      return
    }
  }, [onLinkClick])

  return (
    <StyledLink onClick={handleLinkClick} to={route}>
      {children}
    </StyledLink>
  )
}

export default CustomLink
