import { LoginType, RegistrationType } from 'modules/auth/Auth.types'
import { AuthContextDataType } from 'modules/auth/AuthContainer'
import { DeepPartial } from 'ts-essentials'
import { ForgotPasswordType } from 'modules/auth/redux_store/state/forgotPassword'

export const transformLoginDataBeforeSubmit = (data: AuthContextDataType) => {
  const transformedObj: DeepPartial<LoginType> = {}
  const loginKeys: (keyof LoginType)[] = ['username', 'password', 'rememberMe']
  loginKeys.forEach((key) => {
    transformedObj[key] = data[key]
  })
  return transformedObj
}

export const transformUserDetailsBeforeSave = (data: RegistrationType) => {
  const removeSpaces: (keyof RegistrationType)[] = ['login', 'email', 'firstName', 'lastName', 'company', 'phoneNumber']
  removeSpaces.forEach((key) => {
    if (data && data[key]) {
      data[key] = data[key].trim()
    }
  })
  return data
}

export const transformForgotPasswordDataBeforeSubmit = (data: ForgotPasswordType) => {
  const transformedObj: DeepPartial<ForgotPasswordType> = {}
  const forgotPasswordKeys: (keyof ForgotPasswordType)[] = ['email']
  forgotPasswordKeys.forEach((key) => {
    transformedObj[key] = data[key].trim()
  })
  return transformedObj
}
