import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { testIdWorkbench } from 'pages/workbench/Workbench.ids'
import { sideNavWidth } from 'themes/theme-light'
import Workspace from 'modules/workspace/Workspace'
import DataPortfolio from 'modules/dataStreams/portfolio/DataPortfolio'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { math } from 'polished'
import { QUERY_ASSET_MAP, useQueryMatch } from 'utils/query-string'
import AssetMap from 'modules/asset/assetMap/AssetMap'
import AssetPortfolio from 'modules/asset/portfolio/AssetPortfolio'

// styled components
const Container = styled.div`
  height: 100%;
`

const AreaWorkspace = styled.div`
  position: absolute;
  margin-left: ${sideNavWidth};
  width: calc(100vw - ${math(`2*${sideNavWidth}`)});
  background-color: #fff;
  overflow: hidden;
  height: 100%;
`

const MapArea = styled.div`
  position: absolute;
  margin-left: ${sideNavWidth};
  width: calc(100vw - ${sideNavWidth});
  overflow: hidden;
  height: 100%;
`

// exported component
const Workbench: React.FC = () => {
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const isAssetMapDisplayed = useQueryMatch(QUERY_ASSET_MAP)

  return (
    <Container className="workbench-page not-printable" data-testid={testIdWorkbench}>
      <AssetPortfolio />
      {isAssetMapDisplayed ? (
        <MapArea>
          <AssetMap />
        </MapArea>
      ) : (
        <>
          <AreaWorkspace>
            <Workspace anyAssetSelected={assetSelection.length > 0} />
          </AreaWorkspace>

          <DataPortfolio />
        </>
      )}
    </Container>
  )
}

// Workbench.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(Workbench)
