import { combineReducers } from 'redux'
import selectedReducer from './selectedReducer'
import metaReducer from './metaReducer'
import coordsReducer from './coordsReducer'
import pointsReducer from './pointsReducer'
import bboxReducer from './bboxReducer'
import urlParamsReducer from './urlParamsReducer'

export default combineReducers({
  selected: selectedReducer,
  meta: metaReducer,
  coords: coordsReducer,
  points: pointsReducer,
  bbox: bboxReducer,
  urlParams: urlParamsReducer,
})
