import React from 'react'
import { FormApi } from 'final-form'

import ConfigurationWindPlant from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationWindPlant'
import Flex from 'ui/styles/Flex'

interface ConfigurationWindParkProps {
  enterManually: boolean
  form: FormApi<Asset>
  onFormChangeExternally: () => void
}

const ConfigurationWindPark: React.FC<ConfigurationWindParkProps> = ({
  enterManually,
  form,
  onFormChangeExternally,
}) => {
  return (
    <Flex direction="column">
      {enterManually && <ConfigurationWindPlant onFormChangeExternally={onFormChangeExternally} form={form} />}
    </Flex>
  )
}

export default React.memo(ConfigurationWindPark)
