import {
  CellRenderType,
  Column,
  ColumnSortType,
  RETABLE_ID_LONG_RANGE_FORECAST,
  ReTableItem,
} from 'modules/reTable/reTable.types'
import { addMonths, getYear, isAfter, isBefore, startOfMonth, subMonths } from 'date-fns'
import { SeasonalForecastData } from 'modules/workspace/advancedChartWidgets/seasonalForecast/seasonalForecast.types'
import { formatNumber, isNumeric } from 'utils/dataFormatting'
import { Asset, TYPE_SOLARPARK, TYPE_SOLARPLANT, TYPE_WINDPARK, TYPE_WINDPLANT } from 'modules/asset/store/asset.types'
import {
  DataStreamSelection,
  TimeSeriesClassifier,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { AppUnits } from 'utils/units'
import { isSolarPark, isSolarPlant, isWindPark, isWindPlant } from 'utils/asset'
import { getDataStreamName, getDataStreamTypeQueryLabel } from 'utils/dataStream'
import { PRINT_VIEW_ROW_TYPE } from 'modules/printView/PrintViewTable'
import { c, t } from 'ttag'
import { formatDate } from 'utils/date'
import { Timezone } from 'fixtures/timezones'
import { User } from 'modules/auth/Auth.types'
import { hasPermissionToClimatology } from 'utils/user'

export enum SeasonalForecastDataColors {
  METER_DATA_COLOR = `#efefef`,
  FORECAST_DATA_COLOR = '#def7de',
  INTRA_MONTH_FORECAST_COLOR = '#abddab',
  CLIMATOLOGY_COLOR = '#baddffc2',
}

export enum SeasonalForecastChartColor {
  SEASONAL_GENERATION = '#868484',
  CLIMATOLOGY_GENERATION = '#baddffc2',
}

export enum MonthlyViewModeIds {
  HALF_FUTURE_VIEW = 'halfFutureView',
  FULL_FUTURE_VIEW = 'fullFutureView',
}

export const getYearAndMonthForSeasonalForecast = (date: Date) => {
  const year = getYear(date)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  return `${year}-${month}`
}

export const getSeasonalForecastYearAndMonths = (mode?: MonthlyViewModeIds) => {
  const currentDate = new Date()
  const startDate = subMonths(currentDate, 6)
  const noOfMonths = 12

  const months: string[] = []

  for (let i = 0; i < noOfMonths; i++) {
    const nextDate = addMonths(mode === MonthlyViewModeIds.FULL_FUTURE_VIEW ? currentDate : startDate, i)
    const label = getYearAndMonthForSeasonalForecast(nextDate)
    months.push(label)
  }

  return months
}

export enum SeasonalForecastTableMonthType {
  PAST_MONTH = 'PAST_MONTH',
  INTRA_MONTH = 'INTRA_MONTH',
  FUTURE_MONTH = 'FUTURE_MONTH',
}

export const createSeasonalForecastColumns: (monthlyViewMode: MonthlyViewModeIds) => Column[] = (monthlyViewMode) => {
  const seasonalForecastYearAndMonths = getSeasonalForecastYearAndMonths(monthlyViewMode)
  const { PAST_MONTH, INTRA_MONTH, FUTURE_MONTH } = SeasonalForecastTableMonthType
  const currentMonth = startOfMonth(new Date())

  const columns = seasonalForecastYearAndMonths.map((yearAndMonth) => {
    const columnMonth = startOfMonth(new Date(yearAndMonth))

    return {
      name: `${yearAndMonth}`,
      label: `${yearAndMonth}`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      searchable: false,
      sortable: false,
      width: '5em',
      customDynamicWidth: '100%',
      fieldName: `${yearAndMonth}`,
      fixed: true,
      tableId: RETABLE_ID_LONG_RANGE_FORECAST,
      helperValue: isAfter(columnMonth, currentMonth)
        ? FUTURE_MONTH
        : isBefore(columnMonth, currentMonth)
        ? PAST_MONTH
        : INTRA_MONTH,
    }
  })

  const otherColumns = [
    {
      name: `type`,
      label: `Type`,
      cellRenderType: CellRenderType.TEXT,
      columnSortType: ColumnSortType.FIELD,
      searchable: false,
      sortable: false,
      width: '12.5em',
      fieldName: `type`,
      fixed: true,
      helperValue: '',
      firstColumn: true,
      tableId: RETABLE_ID_LONG_RANGE_FORECAST,
    },
  ]

  return [...otherColumns, ...columns]
}

export const transformSeasonalForecastDataForReTable = (data: Record<string, string>, type: string) => {
  const transformedReTableItem: ReTableItem = {
    id: `${type}-${data.assetId}`,
    type: type,
    ...data,
  }

  transformedReTableItem.uiAncestors = [data.assetId]
  transformedReTableItem.uiParents = [data.assetId]
  transformedReTableItem.uiChildren = []
  transformedReTableItem.uiDescendants = []
  transformedReTableItem.uiLevel = 1
  return transformedReTableItem
}

/**
 * enrichSeasonalForecastMonthlyData
 * We receive "forecastData" in this format and then loop over the object keys,
 * these keys are nothing but data in the coming months from "2023-01".
 *
 * "2023-01": {
 *       "0": 4.322,
 *       "1": 4.467,
 *       "2": 4.334,
 *       "3": 4.09,
 *       "4": 5.032,
 *       "5": 7.333
 *     }
 */

export const getForecastValuesForFutureMonthsFromDate = (
  date: Date,
  forecastData: Record<string, number>,
  category: keyof SeasonalForecastData,
): Record<string, any> => {
  const forecastDataKeys = Object.keys(forecastData)

  const enrichedData: Record<string, number> = {}
  forecastDataKeys.forEach((key) => {
    const monthAndYear = getYearAndMonthForSeasonalForecast(addMonths(date, parseInt(key)))
    // Divide by 1000 to convert MWh values in to MUs (GIGA_WATT_HOUR)
    const forecastValue =
      category === 'energyYield' && isNumeric(forecastData[key]) ? forecastData[key] / 1000 : forecastData[key]
    enrichedData[monthAndYear] = forecastValue
  })
  return enrichedData
}

/** *
 * We get data in this structure, need to check the current year and month object
 * If present exit the loop and use that object to display the values for next six months
 * If not check the previous month and so on until we find data
 *
 * "energyYield": {
 *   "2022-04": {
 *     "0": 8915.049,
 *         "1": 9062.567,
 *         "2": 8237.415,
 *         "3": 7968.508,
 *         "4": 8114.189,
 *         "5": 8644.86
 *   },
 *   "2022-05": {
 *     "0": 8651.719,
 *         "1": 8373.406,
 *         "2": 8022.423,
 *         "3": 8275.171,
 *         "4": 8765.119,
 *         "5": 7378.104
 *   },
 */

export const extractSeasonalForecastMonthlyData = (
  forecastMonthlyData: SeasonalForecastData,
  dataCategories: (keyof SeasonalForecastData)[],
  currentPastYearAndMonths: string[],
): SeasonalForecastData => {
  const extractedData: Record<string, any> = {}

  extractedData['assetId'] = forecastMonthlyData?.assetUuid
  dataCategories.forEach((category) => {
    if (forecastMonthlyData[category]) {
      const unitKey = `${category}Unit`
      extractedData[unitKey] = forecastMonthlyData[unitKey]
      // The every() function stops iterating through the array whenever the callback function returns a falsy value.
      currentPastYearAndMonths.every((yearAndMonth) => {
        const date = new Date(yearAndMonth)
        if (forecastMonthlyData.energyYield[yearAndMonth]) {
          const forecastData = forecastMonthlyData[category][yearAndMonth]
          extractedData[category] = getForecastValuesForFutureMonthsFromDate(date, forecastData, category)

          return false // exit the loop
        } else return true // continue loop
      })
    }
  })

  return extractedData
}

/**
 * We get data in this format and need to extract this data for the past 6months from current data
 * "energyYield": {
 *     "2022-04": 16.79,
 *     "2022-05": 42.11,
 *     "2022-06": 42.93,
 *     "2022-07": 23.58,
 *     "2022-08": 23.42,
 *     "2022-09": 19.75,
 *     "2022-10": 8.73,
 *     "2022-11": 10.31,
 *     "2022-12": 7.03
 *   },
 *    "energyYieldUnit": "MWh",
 *   "poaIrradiation": {
 *     "2022-04": "",
 *     "2022-05": "",
 *     "2022-06": "",
 *     "2022-07": "",
 *     "2022-08": "",
 *     "2022-09": "",
 *     "2022-10": "",
 *     "2022-11": "",
 *     "2022-12": ""
 *   },
 *   poaIrradiationUnit:"kWh/m2"
 * */

export const extractSeasonalMeterDataMonthly = (
  meterDataMonthly: SeasonalForecastData,
  dataCategories: (keyof SeasonalForecastData)[],
  pastYearAndMonths: string[],
): SeasonalForecastData => {
  const extractedData: Record<string, any> = {}
  extractedData['assetId'] = meterDataMonthly?.assetUuid
  dataCategories.forEach((category) => {
    extractedData[category] = {}
    if (meterDataMonthly[category]) {
      const unitKey = `${category}Unit`
      extractedData[unitKey] = meterDataMonthly[unitKey]
      pastYearAndMonths.forEach((yearAndMonth) => {
        if (meterDataMonthly?.[category]?.[yearAndMonth]) {
          const meterDataValue = meterDataMonthly?.[category]?.[yearAndMonth]
          // Divide the energyYield value by 1000 to convert to MUs (GIGA_WATT_HOUR)
          extractedData[category][yearAndMonth] =
            category === 'energyYield' && isNumeric(meterDataValue) ? meterDataValue / 1000 : meterDataValue
        } else extractedData[category][yearAndMonth] = ''
      })
    }
  })

  return extractedData
}

/**
 *
 * @param seasonalForecastItems
 * @param monthlyViewMode
 * @description This function returns an Energy summary for Wind Assets , Solar Assets and a climatology summary for all assets (we don't make any differentiation between asset type).
 * We are using NULL , because this function is mainly used in seasonal forecast chart (in chart we need null to support gaps)
 */
export const getSeasonalForecastSummaryResults = (
  seasonalForecastItems: ReTableItem[],
  monthlyViewMode: MonthlyViewModeIds,
) => {
  // Getting array of years and months
  const seasonalForecastMonths = getSeasonalForecastYearAndMonths(monthlyViewMode)

  // Prepare empty object
  let solarEnergyYieldSummary = {}
  let windEnergyYieldSummary = {}
  let climatologySummary = {}

  if (seasonalForecastItems.length > 0 && seasonalForecastMonths.length > 0) {
    seasonalForecastMonths.forEach((month) => {
      let solarSum = 0
      let windSum = 0
      let climatologySum = 0

      seasonalForecastItems.forEach((item) => {
        if (item.type === TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION) {
          if (item.assetType === TYPE_SOLARPLANT || item.assetType === TYPE_SOLARPARK) {
            if (item?.[month]) {
              solarSum += item?.[month]
            }
            solarEnergyYieldSummary = { ...solarEnergyYieldSummary, [month]: solarSum === 0 ? null : solarSum }
          } else if (item.assetType === TYPE_WINDPLANT || item.assetType === TYPE_WINDPARK) {
            if (item?.[month]) {
              windSum += item?.[month]
            }
            windEnergyYieldSummary = { ...windEnergyYieldSummary, [month]: windSum === 0 ? null : windSum }
          }
        } else if (item.type === TimeSeriesClassifier.CLIMATOLOGY_GENERATION) {
          if (item?.[month]) {
            climatologySum += item?.[month]
          }
          climatologySummary = { ...climatologySummary, [month]: climatologySum === 0 ? null : climatologySum }
        }
      })
    })
  }

  // if object contains only null then return {}
  return {
    solarEnergyYieldSummary: Object.values(solarEnergyYieldSummary).every((o) => o === null)
      ? {}
      : solarEnergyYieldSummary,
    windEnergyYieldSummary: Object.values(windEnergyYieldSummary).every((o) => o === null)
      ? {}
      : windEnergyYieldSummary,
    climatologySummary: Object.values(climatologySummary).every((o) => o === null) ? {} : climatologySummary,
  }
}

/************************
  SEASONAL FORECAST CHART
 ***********************/

// In X-axis we have only one value for example '2022-01' , but highcharts as 'from' and 'to' accepts only number , that's why we are using number.
export const getSeasonalForecastChartPlotBand = (monthlyViewMode: MonthlyViewModeIds) => {
  const BANDS_LIMIT = {
    START_FIRST_MONTH: -1,
    END_FIRST_MONTH: 0.5,
    START_CURRENT_MONTH: 5.5,
    END_CURRENT_MONTH: 6.5,
    END_LAST_MONTH: 11.5,
  }

  const meterDataPlotBand = {
    from: BANDS_LIMIT.START_FIRST_MONTH,
    to: BANDS_LIMIT.START_CURRENT_MONTH,
    color: SeasonalForecastDataColors.METER_DATA_COLOR,
  }

  const forecastWithIntraMonthPlotBand = [
    {
      from:
        monthlyViewMode === MonthlyViewModeIds.FULL_FUTURE_VIEW
          ? BANDS_LIMIT.START_FIRST_MONTH
          : BANDS_LIMIT.START_CURRENT_MONTH,
      to:
        monthlyViewMode === MonthlyViewModeIds.FULL_FUTURE_VIEW
          ? BANDS_LIMIT.END_FIRST_MONTH
          : BANDS_LIMIT.END_CURRENT_MONTH,
      color: SeasonalForecastDataColors.INTRA_MONTH_FORECAST_COLOR,
    },
    {
      from:
        monthlyViewMode === MonthlyViewModeIds.FULL_FUTURE_VIEW
          ? BANDS_LIMIT.END_FIRST_MONTH
          : BANDS_LIMIT.END_CURRENT_MONTH,
      to: BANDS_LIMIT.END_LAST_MONTH,
      color: SeasonalForecastDataColors.FORECAST_DATA_COLOR,
    },
  ]

  if (monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW) {
    forecastWithIntraMonthPlotBand.push(meterDataPlotBand)
  }

  return forecastWithIntraMonthPlotBand
}

export const getEmptySeasonalForecastChartColumnSeries = () => {
  const numberOfMonths = 12
  const emptyNulls = []

  for (let i = 0; i < numberOfMonths; i++) {
    emptyNulls.push(null)
  }
  return emptyNulls
}

/**
 * @param climatologySummaryObj
 * @description An array of arrays with 3 or 2 values. In this case, the values correspond to x,low,high. If the first value is a string, it is applied as the name of the point, and the x value is inferred.
 * The x value can also be omitted, in which case the inner arrays should be of length 2.
 * Then the x value is automatically calculated, either starting at 0 and incremented by 1, or from pointStart and pointInterval given in the series options.
 */

export const prepareDataForClimatologyChart = (climatologySummaryObj: { [x: string]: any }) => {
  const seriesDataForClimatologyChart = []
  for (const key in climatologySummaryObj) {
    seriesDataForClimatologyChart.push([climatologySummaryObj[key], climatologySummaryObj[key]])
  }
  return seriesDataForClimatologyChart
}

export const getClimatologyTypes = (category: keyof SeasonalForecastData) => {
  if (category === 'energyYield') {
    return TimeSeriesClassifier.CLIMATOLOGY_GENERATION
  } else if (category === 'poaIrradiation') {
    return TimeSeriesClassifier.CLIMATOLOGY_SOLAR_IRRADIATION
  } else return TimeSeriesClassifier.CLIMATOLOGY_WIND_SPEED
}

export const getSeasonalTypes = (category: keyof SeasonalForecastData) => {
  if (category === 'energyYield') {
    return TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION
  } else if (category === 'poaIrradiation') {
    return TimeSeriesClassifier.SEASONAL_FORECAST_SOLAR_IRRADIATION
  } else return TimeSeriesClassifier.SEASONAL_FORECAST_WIND_SPEED
}

export const getSeasonalDataStaticUnits = (
  category?: keyof SeasonalForecastData,
  classifier?: TimeSeriesClassifier,
) => {
  const {
    CLIMATOLOGY_GENERATION,
    CLIMATOLOGY_SOLAR_IRRADIATION,
    CLIMATOLOGY_WIND_SPEED,
    SEASONAL_FORECAST_GENERATION,
    SEASONAL_FORECAST_SOLAR_IRRADIATION,
    SEASONAL_FORECAST_WIND_SPEED,
  } = TimeSeriesClassifier

  if (
    category === 'energyYield' ||
    classifier === CLIMATOLOGY_GENERATION ||
    classifier === SEASONAL_FORECAST_GENERATION
  ) {
    return AppUnits.GIGA_WATT_HOUR
  } else if (
    category === 'poaIrradiation' ||
    classifier === CLIMATOLOGY_SOLAR_IRRADIATION ||
    classifier === SEASONAL_FORECAST_SOLAR_IRRADIATION
  ) {
    return AppUnits.KILO_WATT_HOUR_PER_SQUARE_METER
  } else if (
    category === 'windSpeed' ||
    classifier === CLIMATOLOGY_WIND_SPEED ||
    classifier === SEASONAL_FORECAST_WIND_SPEED
  ) {
    return AppUnits.METER_PER_SECOND
  }
}

export const getSeasonalCategoriesBasedOnAsset = (asset: Asset | null) => {
  let dataCategories: (keyof SeasonalForecastData)[] = ['energyYield', 'poaIrradiation', 'windSpeed']
  if ((asset && isSolarPark(asset)) || isSolarPlant(asset)) {
    dataCategories = dataCategories.filter((category) => category !== 'windSpeed')
  } else if ((asset && isWindPark(asset)) || isWindPlant(asset)) {
    dataCategories = dataCategories.filter((category) => category !== 'poaIrradiation')
  }
  return dataCategories
}

export const getClimatologyDataStreams = () => {
  const { CLIMATOLOGY_GENERATION, CLIMATOLOGY_SOLAR_IRRADIATION, CLIMATOLOGY_WIND_SPEED } = TimeSeriesClassifier
  return [CLIMATOLOGY_GENERATION, CLIMATOLOGY_SOLAR_IRRADIATION, CLIMATOLOGY_WIND_SPEED]
}

export const getSeasonalForecastDataStreams = () => {
  const {
    SEASONAL_FORECAST_GENERATION,
    SEASONAL_FORECAST_SOLAR_IRRADIATION,
    SEASONAL_FORECAST_WIND_SPEED,
  } = TimeSeriesClassifier
  return [SEASONAL_FORECAST_GENERATION, SEASONAL_FORECAST_SOLAR_IRRADIATION, SEASONAL_FORECAST_WIND_SPEED]
}
// This function merges seasonal forecast data stream depending on climatology role
export const mergedSeasonalForecastDataStreamsClassifiers = (user: User) => {
  let mergedDataStreams = [...getSeasonalForecastDataStreams()]
  if (hasPermissionToClimatology(user)) {
    mergedDataStreams = mergedDataStreams.concat(getClimatologyDataStreams())
  }
  return mergedDataStreams
}

export const getSeasonalForecastTypeName = (seasonalForecastData: ReTableItem) => {
  const name = getDataStreamName({
    type: TimeSeriesType.CLIMATOLOGY,
    classifier: seasonalForecastData['type'] as TimeSeriesClassifier,
  })

  const unit = seasonalForecastData.unit
  return `${name} (${unit})`
}

interface GetSeasonalTableCellStylesProps {
  column: Column
  seasonalData: ReTableItem
}
export const getSeasonalTableCellBackgroundColor = ({ column, seasonalData }: GetSeasonalTableCellStylesProps) => {
  const { FUTURE_MONTH, INTRA_MONTH, PAST_MONTH } = SeasonalForecastTableMonthType
  const {
    CLIMATOLOGY_COLOR,
    INTRA_MONTH_FORECAST_COLOR,
    FORECAST_DATA_COLOR,
    METER_DATA_COLOR,
  } = SeasonalForecastDataColors

  const climatologyItems = getClimatologyDataStreams()
  const monthType = column.helperValue as string
  const isClimatology = seasonalData?.type && climatologyItems.includes(seasonalData?.type)

  if (isClimatology) {
    return CLIMATOLOGY_COLOR
  } else {
    switch (monthType) {
      case FUTURE_MONTH:
        return FORECAST_DATA_COLOR
      case INTRA_MONTH:
        return INTRA_MONTH_FORECAST_COLOR
      case PAST_MONTH:
        return METER_DATA_COLOR
      default:
        return 'inherit'
    }
  }
}

export const getSeasonalTableCellBorderColor = ({ column, seasonalData }: GetSeasonalTableCellStylesProps) => {
  const { FUTURE_MONTH, INTRA_MONTH, PAST_MONTH } = SeasonalForecastTableMonthType

  const climatologyItems = getClimatologyDataStreams()
  const monthType = column.helperValue as string
  const isClimatology = seasonalData?.type && climatologyItems.includes(seasonalData?.type)

  if (isClimatology) {
    return 'white'
  } else {
    switch (monthType) {
      case FUTURE_MONTH:
      case INTRA_MONTH:
      case PAST_MONTH:
        return 'white'

      default:
        return ''
    }
  }
}

interface PrepareSeasonalItemsForPrintViewTableProps {
  assets: Asset[]
  seasonalForecastItems: ReTableItem[]
  forecastSelected: boolean
  climatologySelected: boolean
}

export const prepareSeasonalItemsForPrintViewTable = ({
  assets,
  seasonalForecastItems,
  forecastSelected,
  climatologySelected,
}: PrepareSeasonalItemsForPrintViewTableProps) => {
  const printViewItems: Record<string, any>[] = []
  const { SEASONAL_FORECAST } = TimeSeriesSubType

  const climatologyDataStreams = getClimatologyDataStreams()
  const seasonalForecastDataStreams = getSeasonalForecastDataStreams()

  assets.forEach((asset) => {
    // Push asset
    printViewItems.push(asset as ReTableItem)

    const assetSpecificItems = seasonalForecastItems?.filter((item) => asset.id === item?.assetId)

    if (forecastSelected) {
      const categoryName = getDataStreamTypeQueryLabel(SEASONAL_FORECAST)?.label || ''
      // Push Seasonal Category
      printViewItems.push({
        rowType: PRINT_VIEW_ROW_TYPE.ONLY_VALUE,
        id: `${asset?.id || ''}-${categoryName}`,
        value: categoryName,
      })
      // Filter seasonal items
      const assetSeasonalItems = (assetSpecificItems || []).filter((item) =>
        seasonalForecastDataStreams.includes(item.type),
      )
      assetSeasonalItems.forEach((seasonalItem) => {
        // Push the seasonal items after adding type label
        printViewItems.push({ ...seasonalItem, name: getSeasonalForecastTypeName(seasonalItem) })
      })
    }
    if (climatologySelected) {
      const categoryName = getDataStreamTypeQueryLabel(TimeSeriesSubType.CLIMATOLOGY)?.label || ''
      // Push Climatology Category
      printViewItems.push({
        rowType: PRINT_VIEW_ROW_TYPE.ONLY_VALUE,
        id: `${asset?.id || ''}-${categoryName}`,
        value: categoryName,
      })
      // Filter seasonal items
      const assetClimatologyItems = (assetSpecificItems || []).filter((item) =>
        climatologyDataStreams.includes(item.type),
      )
      assetClimatologyItems.forEach((climatologyItem) => {
        // Push the climatology items after adding type label
        printViewItems.push({ ...climatologyItem, name: getSeasonalForecastTypeName(climatologyItem) })
      })
    }
  })

  return printViewItems
}

// This function creates a csv column (for monthly view) depending on classifier
export const createLabelForCsvColumn = (classifier: TimeSeriesClassifier) => {
  const isClimatology = classifier.includes(TimeSeriesType.CLIMATOLOGY)
  const labelPrefix = isClimatology ? c('Data Stream').t`Climatology` : c('Data Stream').t`Seasonal Forecast`
  const dataStreamType = isClimatology ? TimeSeriesType.CLIMATOLOGY : TimeSeriesType.SEASONAL_FORECAST

  const dataStreamName = getDataStreamName({
    type: dataStreamType,
    classifier: classifier as TimeSeriesClassifier,
  })

  const unit = getSeasonalDataStaticUnits(undefined, classifier)

  return `${labelPrefix} - ${dataStreamName} (${unit})`
}

/**
 ** Download CSV for monthly view
 * We already have a function for table csv downloading , used in user management , but this one has totally a different data structure , and totally a different logic
 */
export const exportMonthlyViewCsv = (
  assets: Asset[],
  columnsSelected: Column[],
  seasonalForecastItems: ReTableItem[],
  dataSelection: DataStreamSelection,
  timezone: Timezone,
  user: User | null,
) => {
  if (
    assets.length > 0 &&
    seasonalForecastItems.length > 0 &&
    dataSelection.length > 0 &&
    columnsSelected.length > 0 &&
    user
  ) {
    /**
     ** 1.Prepare column array for CSV
     */
    // we need to show them always in this order
    const mergedSeasonalAndClimatology = [...mergedSeasonalForecastDataStreamsClassifiers(user)]

    // We are doing things ready , to display them as column , not here yet
    const filteredSeasonalAndClimatology = (mergedSeasonalAndClimatology || []).filter((item) => {
      return dataSelection.findIndex((selection) => selection.classifier === item) > -1
    })

    // csv columns
    const csvColumns = [
      { type: 'yearMonth', label: t`Year - Month (${timezone})` },
      { type: 'assetName', label: t`Asset Name` },
    ]

    filteredSeasonalAndClimatology.forEach((el) => {
      csvColumns.push({
        type: el,
        label: createLabelForCsvColumn(el),
      })
    })

    /**
     ** 2.Prepare structure with asset and seasonal values
     */
    // For each asset we have created a new array
    const separatedSeasonalArrayForEachAsset: any[][] = assets.map((asset) => {
      const arrayForEachAsset: any[] = [...asset]

      // we are getting matching seasonal items
      const matchingSeasonalItems = seasonalForecastItems.filter((el) => el.id.includes(asset.id))

      arrayForEachAsset.push(...matchingSeasonalItems)
      return arrayForEachAsset
    })

    /**
     ** 3. Prepare body array for csv
     */
    let preparedArrayForCsv: any[] = []
    for (let i = 0; i < separatedSeasonalArrayForEachAsset.length; i++) {
      const preparedArray: any[] = []
      for (let j = 1; j < columnsSelected.length; j++) {
        // create an array with all values undefined
        // array length should be the same as csvColumn length
        // undefined because we are not populating every column , some of them are seasonal others are climatology
        const singleRowForEachSeasonalItem = csvColumns.map(() => undefined)
        for (let k = 0; k < separatedSeasonalArrayForEachAsset[i].length; k++) {
          const year = columnsSelected[j].label

          if (k === 0) {
            const assetName = separatedSeasonalArrayForEachAsset[i][k].name
            // adding year and asset name at first two positions
            singleRowForEachSeasonalItem.splice(
              0,
              2, // we are deleting because initially all values are undefined
              year,
              assetName,
            )
          } else {
            // find index of column in csv array
            const indexOfTypeInColumnArray = csvColumns.findIndex((column) => {
              return column.type === separatedSeasonalArrayForEachAsset[i][k].type
            })

            const valueForEachMonth = separatedSeasonalArrayForEachAsset[i][k][year]

            // Formatted number as we have it in UI
            // since csv wants undefined for empty values , we are handling that here
            const valueToBeShown = isNumeric(valueForEachMonth)
              ? formatNumber({
                  data: valueForEachMonth,
                  showFractionalPart: true,
                  forceFractionalPart: true,
                  separator: false,
                  limit: 3,
                })
              : undefined

            // add element at exactly index that we found from above step
            singleRowForEachSeasonalItem.splice(
              indexOfTypeInColumnArray,
              1, // we are deleting because initially all values are undefined
              valueToBeShown,
            )
          }
        }
        preparedArray.push(singleRowForEachSeasonalItem)
      }
      preparedArrayForCsv = [...preparedArrayForCsv, ...preparedArray]
    }

    /**
     ** 4. Prepare csv file
     */
    // Transform csv row item by add quotes around the string so that comma inside them is not treated as separator
    const separator = ','
    const transformRowItem = (rowItems: any[]) => {
      return rowItems.map((item) => `"${item.label}"`).join(separator)
    }

    const csvString = [transformRowItem(csvColumns), ...preparedArrayForCsv].join('\n')

    const csvFile = new Blob([csvString], { type: 'text/csv' })
    const downloadLink = document.createElement('a')
    const filename = `enercast--${c('Workbench:Widget').t`Monthly View`}--${formatDate(new Date())}`
    downloadLink.download = `${filename}.csv`
    downloadLink.href = window.URL.createObjectURL(csvFile)
    downloadLink.style.display = 'none'
    document.body.appendChild(downloadLink)
    downloadLink.click()
  }
}

export const monthlyViewModeOptions = () => {
  return [
    {
      id: MonthlyViewModeIds.HALF_FUTURE_VIEW,
      name: t`+/- 6 months`,
    },
    {
      id: MonthlyViewModeIds.FULL_FUTURE_VIEW,
      name: t`+ 12 months`,
    },
  ]
}
