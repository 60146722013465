import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { t } from 'ttag'

import ButtonMenu, { SelectOption } from 'ui/form/ButtonMenu'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import {
  selectedConfigSelector,
  workspaceConfigsResultSelector,
} from 'modules/workspace/store/getWorkspaceConfigs.state'
import {
  workspaceDraftChartNameSelector,
  workspaceDraftIdSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { testIdWorkspaceHeaderMenuContent } from 'modules/workspace/header/WorkspaceHeader.ids'
import { seriesSelector } from 'modules/workspace/store/series.state'
import CSVExportDialog from 'ui/elements/CSVExportDialog'
import { useIsReadOnlyUser } from 'utils/user'

const StyledButtonMenu = styled(ButtonMenu)`
  margin-right: 0.5em;
`

export interface WorkspaceMenuProps {
  unsavedChanges: boolean
  onAdd: () => void
  onRename: () => void
  onDuplicate: () => void
  onSave: (config: undefined) => void
  onDiscard: () => void
  onDelete: () => void
  onSelect: (key: string) => void
}

const WorkspaceMenu: React.FC<WorkspaceMenuProps> = ({
  unsavedChanges,
  onAdd,
  onRename,
  onDuplicate,
  onSave,
  onDiscard,
  onDelete,
  onSelect,
  ...rest
}) => {
  const draftId = useSelector(workspaceDraftIdSelector)
  const chartName = useSelector(workspaceDraftChartNameSelector)
  const selectedConfig = useSelector(selectedConfigSelector)
  const configs = useSelector(workspaceConfigsResultSelector)
  const series = useSelector(seriesSelector)
  const isReadOnlyUser = useIsReadOnlyUser()

  // console.log({ draftId, isReadOnlyUser, unsavedChanges })

  const loadOptions: SelectOption[] = useMemo(() => {
    const sortedConfig = ([...configs] || []).sort((a, b) => {
      const valueA = a.chart?.name.toUpperCase()
      const valueB = b.chart?.name.toUpperCase()
      if (valueA < valueB) {
        return -1
      }
      if (valueA > valueB) {
        return 1
      }
      return 0
    })

    return (sortedConfig || []).map((config) => ({
      key: config.id as string,
      label: config.chart?.name,
    }))
  }, [configs])

  const handleAdd = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onAdd()
      handleClose()
    },
    [onAdd],
  )

  const handleRename = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onRename()
      handleClose()
    },
    [onDuplicate],
  )

  const handleDuplicate = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onDuplicate()
      handleClose()
    },
    [onDuplicate],
  )

  const handleSave = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onSave(undefined)
      handleClose()
    },
    [onSave],
  )

  const handleDiscard = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onDiscard()
      handleClose()
    },
    [onDiscard],
  )

  const handleDelete = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onDelete()
      handleClose()
    },
    [onDelete],
  )

  const handleExportCSV = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      setShowCSVExportDialog(true)
      handleClose()
    },
    [],
  )

  const [showCSVExportDialog, setShowCSVExportDialog] = useState(false)
  const handleCloseCSVExportDialog = useCallback(() => {
    setShowCSVExportDialog(false)
  }, [])

  const handleSelect = useCallback(
    (option: SelectOption, handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onSelect(option.key)
      handleClose()
    },
    [onSelect],
  )

  const menuContent = useCallback(
    (handleClose) => (
      <div data-testid={testIdWorkspaceHeaderMenuContent}>
        <MenuItem disabled={isReadOnlyUser} onClick={handleAdd(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="plus" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`New chart`} />
        </MenuItem>

        <MenuItem disabled={isReadOnlyUser || !draftId || !unsavedChanges} onClick={handleSave(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="save" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Save chart`} />
        </MenuItem>

        <MenuItem disabled={isReadOnlyUser} onClick={handleDuplicate(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="save" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Save as new chart`} />
        </MenuItem>
        <MenuItem disabled={isReadOnlyUser || !draftId} onClick={handleRename(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="i-cursor" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Rename chart`} />
        </MenuItem>
        <MenuItem disabled={isReadOnlyUser || !draftId || !unsavedChanges} onClick={handleDiscard(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="times" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Discard changes`} />
        </MenuItem>
        <MenuItem disabled={isReadOnlyUser || !selectedConfig} onClick={handleDelete(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="trash-alt" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Delete chart`} />
        </MenuItem>
        <Divider />
        <MenuItem disabled={series.length === 0} onClick={handleExportCSV(handleClose)}>
          <ListItemIcon>
            <FontAwesomeActionIcon icon="download" fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Export as CSV`} />
        </MenuItem>
        <Divider />
        <Box mx={2} my={1}>
          <Typography variant="subtitle2">{t`Load Chart`}</Typography>
        </Box>
        <>
          {loadOptions.length > 0 ? (
            loadOptions.map((option) => {
              const isSelected = selectedConfig && option.key === selectedConfig.id
              return (
                <MenuItem key={option.key} selected={isSelected} onClick={handleSelect(option, handleClose)}>
                  <Typography noWrap>{isSelected ? chartName : option.label}</Typography>
                </MenuItem>
              )
            })
          ) : (
            <MenuItem onClick={handleClose}>
              <em>{t`No saved charts`}</em>
            </MenuItem>
          )}
        </>
      </div>
    ),
    [loadOptions, selectedConfig, series, handleAdd, handleSave, handleExportCSV, handleDuplicate, handleDelete],
  )

  return (
    <>
      <StyledButtonMenu icon="bars" iconOnly renderMenu={menuContent} {...rest} />
      {showCSVExportDialog && <CSVExportDialog onClose={handleCloseCSVExportDialog} />}
    </>
  )
}

export default React.memo(WorkspaceMenu)
