import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ReTable from 'modules/reTable/ReTable'
import Flex from 'ui/styles/Flex'
import { useDispatch } from 'react-redux'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_RUNNING_BACKCASTS, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { getRunningBackcastsTableColumns, prepareRunningBackcastObj } from 'utils/backcasts'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { table } from 'themes/theme-light'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import RunningBackcastTableBody from 'modules/workspace/header/backCast/RunningBackcasts/RunningBackcastTableBody'
import { singleRunningBackcastType } from 'modules/workspace/header/backCast/backcast.types'
import RunningBackcastsTableToolbar from 'modules/workspace/header/backCast/RunningBackcasts/RunningBackcastsTableToolbar'
import { useRunningBackcastsDeleteMutation } from 'modules/workspace/header/backCast/backCast.api'

const Table = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

const TableContainer = styled(Flex)`
  height: calc(100vh - 165px);
`

interface RunningBackcastsTableProps {
  tableItems: singleRunningBackcastType[]
}
const RunningBackcastsTable: React.FC<RunningBackcastsTableProps> = ({ tableItems }) => {
  const dispatch = useDispatch()
  const tableHeaderHasActions = true
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const [itemsToRender, setItemsToRender] = useState<singleRunningBackcastType[]>([])

  const { mutate: deleteRunningBackcast } = useRunningBackcastsDeleteMutation()

  // Columns
  const columnsAvailable = useMemo(() => getRunningBackcastsTableColumns(), [])
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_RUNNING_BACKCASTS)

  // Default sort
  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'status',
    }),
    [],
  )

  const handleItemsToRenderChange = useCallback((items: singleRunningBackcastType[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_RUNNING_BACKCASTS,
      columnsAvailable,
    })
  }, [columnsAvailable])

  const handleDeleteItem = useCallback((item: singleRunningBackcastType) => {
    const cleanedItem = prepareRunningBackcastObj(item)
    deleteRunningBackcast(cleanedItem)
  }, [])

  return (
    <Flex direction="column" fullHeight>
      <RunningBackcastsTableToolbar
        columns={columnsSelected}
        tableHeaderHasActions={tableHeaderHasActions}
        tableId={RETABLE_ID_RUNNING_BACKCASTS}
        totalItems={itemsToRender}
        visibleItems={itemsToRender}
      />
      <TableContainer direction="column">
        <Table
          id={RETABLE_ID_RUNNING_BACKCASTS}
          items={tableItems as ReTableItem[]}
          itemHeight={table.rowHeight}
          itemsPaddingHeader={ITEMS_PADDING_HEADER}
          itemsPaddingFooter={ITEMS_PADDING_FOOTER}
          renderAheadCount={RENDER_AHEAD_COUNT}
          hasExtendedHeader={false}
          defaultSort={defaultSort}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ReTableGenericHeader columns={columnsSelected} />
          <RunningBackcastTableBody
            columns={columnsSelected}
            tableHeaderHasActions={tableHeaderHasActions}
            visibleItems={itemsToRender}
            onDeleteItem={handleDeleteItem}
          />
        </Table>
      </TableContainer>
    </Flex>
  )
}

export default React.memo(RunningBackcastsTable)
