import { FormApi, FormSubscription } from 'final-form'
import { t } from 'ttag'
import { getPropByString } from 'utils/array'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import arrayMutators from 'final-form-arrays'
import setFieldTouched from 'final-form-set-field-touched'

export interface Field {
  name: string
}

export interface ValidationError {
  field: string
  message: string
}

export type TriState = boolean | null

export type FormValidate<T> = ({ data, fields }: { data: T; fields: Field[] }) => ValidationError[]

export const getError = (field: string, errors: ValidationError[]) => {
  return errors.find((error) => error.field === field)
}

export const hasError = (field: string, errors: ValidationError[]) => {
  return getError(field, errors) !== undefined
}

export const genericFormSubscription = (): FormSubscription => {
  return {
    // make sure to have everything listed that is needed in `formRender()`
    // it will enable much faster form rendering
    dirty: true,
    errors: true,
    submitFailed: true, // (2)
    dirtyFields: true,
    // WARNING: do NOT add "active" or "values" here because it would rerender all the time
    // instead use <FormSpy> to subscribe to them where it is needed
  }
}

export enum FormSaveOptions {
  SAVE = 'SAVE',
  SAVE_AND_NEXT = 'SAVE_AND_NEXT',
  SAVE_AND_CLOSE = 'SAVE_AND_CLOSE',
  SAVE_AND_NEW = 'SAVE_AND_NEW',
  EDIT_AND_SAVE = 'EDIT_AND_SAVE',
  CREATE_COPY = 'CREATE_COPY',
}

export const getFormSaveMenuItems = () => {
  return [
    {
      key: FormSaveOptions.SAVE_AND_CLOSE,
      label: t`Save and close`,
    },
    {
      key: FormSaveOptions.SAVE,
      label: t`Save`,
    },
    {
      key: FormSaveOptions.SAVE_AND_NEXT,
      label: t`Save and next`,
    },
    {
      key: FormSaveOptions.SAVE_AND_NEW,
      label: t`Save and new`,
    },
    {
      key: FormSaveOptions.CREATE_COPY,
      label: t`Save a copy`,
    },
  ]
}

export enum ScheduleFormSaveOptions {
  SUBMIT = 'SUBMIT',
  SUBMIT_WITH_TEMPLATE = 'SUBMIT_WITH_TEMPLATE',
}

export const getScheduleFormSaveMenuItems = () => {
  return [
    {
      key: ScheduleFormSaveOptions.SUBMIT,
      label: t`Submit`,
    },
    {
      key: ScheduleFormSaveOptions.SUBMIT_WITH_TEMPLATE,
      label: t`Submit with template`,
    },
  ]
}

export const getCopyName = (name: string, existingNames: string[]) => {
  let newName = name
  while (existingNames.indexOf(newName) != -1) {
    if (isNaN(parseInt(newName.charAt(newName.length - 2), 10))) {
      newName = newName + ' (1)'
      continue
    } else if (!newName.match(/\((\d+)\)/)) {
      newName = newName + ' (1)'
      continue
    }
    newName = newName.replace(/\((\d+)\)/, function (fullMatch, n) {
      return '(' + (Number(n) + 1) + ')'
    })
  }
  return newName
}

export interface RequiredFieldsOfForm {
  fieldName: string | undefined
  labelName?: string | undefined
  reason?: string | undefined
  required?: boolean
}
const QualityConfigsRequiredKey = 'qualityConfigs' // this is definition of a field , and its array

export const getInvalidFieldsErrorMessagesForCertainForm = (form: FormApi, fields: RequiredFieldsOfForm[]) => {
  const requiredFields: RequiredFieldsOfForm[] = []
  const formErrors = form?.getState().errors
  // Create the object
  fields?.forEach((field) => {
    // In order to handle dynamic quality evaluations , we decided to show a general error message , and not a detailed one for each of them
    if (field.fieldName === QualityConfigsRequiredKey && formErrors?.[QualityConfigsRequiredKey]) {
      const qualityConfigError = formErrors?.[QualityConfigsRequiredKey]
      const qualityConfigsHasErrorMessages = qualityConfigError.some(
        (configError: Record<string, string>) => configError && Object.keys(configError).length > 0,
      )
      if (qualityConfigsHasErrorMessages) {
        requiredFields.push({
          fieldName: field.labelName,
          reason: t`Please check quality evaluation`,
        })
      }
    } else if (
      getPropByString(formErrors, field.fieldName) !== undefined &&
      getPropByString(formErrors, field.fieldName).length > 0
    ) {
      requiredFields.push({
        fieldName: field.labelName,
        reason: getPropByString(formErrors, field.fieldName),
      })
    }
  })
  return requiredFields
}

export const scheduleFormRequiredFields = () => {
  return [
    {
      fieldName: 'start',
      labelName: t`Start`,
    },
    {
      fieldName: 'end',
      labelName: t`End`,
    },
    {
      fieldName: 'sourceDataStream',
      labelName: t`Source Data Stream`,
    },
    {
      fieldName: 'targetScheduleDataStream',
      labelName: t`Destination Schedule`,
    },
  ]
}

export const assetFormRequiredFields = () => {
  return [
    {
      fieldName: 'name',
      labelName: t`Name`,
    },
    {
      fieldName: 'nomCap',
      labelName: t`Nameplate capacity`,
    },
    {
      fieldName: 'location.coordinate.latitude',
      labelName: t`Latitude`,
    },
    {
      fieldName: 'location.coordinate.longitude',
      labelName: t`Longitude`,
    },
    {
      fieldName: 'typeSpecificAttributes.azimuth',
      labelName: t`Orientation angle`,
    },
    {
      fieldName: 'typeSpecificAttributes.tilt',
      labelName: t`Tilt [0° = horizontal]`,
    },
    {
      fieldName: 'parentRelation',
      labelName: t`Parent relation`,
    },
    {
      fieldName: 'since',
      labelName: t`From`,
      required: false,
    },
    {
      fieldName: 'to',
      labelName: t`To`,
      required: false,
    },
  ]
}

export const deliveryTargetFormRequiredFields = () => {
  return [
    {
      fieldName: 'name',
      labelName: t`Name`,
    },
    {
      fieldName: 'ftpConfiguration.host',
      labelName: t`Server address`,
    },
    {
      fieldName: 'ftpConfiguration.port',
      labelName: t`Port`,
    },
    {
      fieldName: 'ftpConfiguration.username',
      labelName: t`Username`,
    },
    {
      fieldName: 'ftpConfiguration.password',
      labelName: t`Password`,
    },
    {
      fieldName: 'ftpConfiguration.ftpParameter.connectTimeout',
      labelName: t`FTP connection timeout`,
    },
    {
      fieldName: 'mailConfiguration.toAddresses',
      labelName: t`Recipients`,
    },
  ]
}

export const userManagementFormRequiredFields = () => {
  return [
    {
      fieldName: 'login',
      labelName: t`enercast ID`,
    },
    {
      fieldName: 'email',
      labelName: t`E-mail`,
    },
  ]
}

export const siteForecastFormRequiredFields = () => {
  return [
    {
      fieldName: 'name',
      labelName: t`Forecast name`,
    },
    {
      fieldName: 'ui.forecastRange',
      labelName: t`Forecast range`,
    },
    {
      fieldName: 'ui.forecastLength.hours',
      labelName: t`Forecast range length`,
    },
    {
      fieldName: 'horizon.offsetMinutes',
      labelName: t`Delivery frequency offset`,
    },
    {
      fieldName: 'updateTimes.individualTimes',
      labelName: t`Delivery times`,
    },
    {
      fieldName: 'updateTimes.earliestAttemptOfDelivery',
      labelName: t`Delivery window start`,
    },
    {
      fieldName: 'updateTimes.latestAttemptOfDelivery',
      labelName: t`Delivery window end`,
    },
    {
      fieldName: 'qualityConfigs',
      labelName: t`Quality Evaluation`,
    },
  ]
}

export const penaltyFormRequiredFields = (typeOfForm: PenaltyTableTypeOfRow) => {
  if (typeOfForm === PenaltyTableTypeOfRow.REGULATION) {
    return [
      {
        fieldName: 'regulationName',
        labelName: t`Regulation name`,
      },
      {
        fieldName: 'gridType',
        labelName: t`Grid type`,
      },
      {
        fieldName: 'country',
        labelName: t`Country`,
      },
      {
        fieldName: 'state',
        labelName: t`State`,
      },
    ]
  } else if (typeOfForm === PenaltyTableTypeOfRow.VERSION) {
    return [
      {
        fieldName: 'regulationVersionName',
        labelName: t`Version name`,
      },
      {
        fieldName: 'activeSince',
        labelName: t`Active since`,
      },
    ]
  } else if (typeOfForm === PenaltyTableTypeOfRow.RULESET) {
    return [
      {
        fieldName: 'generatorTypes',
        labelName: t`Generator type`,
      },
    ]
  }
}

export const formMutators = () => {
  return {
    ...arrayMutators,
    setFieldTouched,
    setValue: ([field, value], state, { changeValue }) => {
      changeValue(state, field, () => value)
    },
    setValues: ([changes], state, { changeValue }) => {
      changes.forEach((change) => {
        changeValue(state, change.field, () => change.value)
      })
    },
  }
}
