import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { c } from 'ttag'
import { useDeliveryFormatsTableItems } from 'modules/delivery/deliveryFormats/api/deliveryFormats.api'
import InlineLoading from 'ui/InlineLoading'
import Flex from 'ui/styles/Flex'
import DeliveryFormatsTable from 'modules/delivery/deliveryFormats/DeliveryFormatsTable'
import { useDeliveryFormatTemplatesTableItems } from 'modules/delivery/deliveryFormats/api/deliveryFormatTemplates.api'
import styled from 'styled-components'

const Info = styled(Box)`
  max-width: 68em;
`

interface DeliveryFormatsProps {
  isTemplateTable: boolean
}

const DeliveryFormats: React.FC<DeliveryFormatsProps> = ({ isTemplateTable }) => {
  const deliveryFormatsResult = useDeliveryFormatsTableItems()
  const deliveryFormatsLoading = useMemo(() => deliveryFormatsResult.isLoading, [deliveryFormatsResult])

  const deliveryFormatsTemplateResult = useDeliveryFormatTemplatesTableItems()
  const deliveryFormatsTemplateLoading = useMemo(() => deliveryFormatsTemplateResult.isLoading, [
    deliveryFormatsTemplateResult,
  ])

  return (
    <>
      <Box mb={1}>
        <Flex alignItems="center">
          <Typography variant="h5">
            {isTemplateTable ? c('Delivery').t`File Format Templates` : c('Delivery').t`File Formats`}
          </Typography>
          {(deliveryFormatsLoading || deliveryFormatsTemplateLoading) && <InlineLoading />}
        </Flex>

        <Info>
          <Typography>
            {isTemplateTable
              ? c('Delivery')
                  .t`In order to allow users to easily use configure their own file formats, administrators can define file format templates below that will be visible to all users.`
              : c('Delivery')
                  .t`How do you want any delivered files to be named and formatted? You may add new file formats from scratch or by customizing a file format template. You can later assign these file formats to your individual forecasts.`}
          </Typography>
        </Info>
      </Box>
      {deliveryFormatsResult.data && (
        <DeliveryFormatsTable
          isTemplateTable={isTemplateTable}
          deliveryFormats={isTemplateTable ? deliveryFormatsTemplateResult.data : deliveryFormatsResult.data}
        />
      )}
    </>
  )
}

export default React.memo(DeliveryFormats)
