import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { sessionLoadingSelector, sessionStatusChangedDateSelector } from 'modules/app/checkConnectivity.state'
import Alert from '@material-ui/lab/Alert'
import { tConnectivityAndBanners } from 'fixtures/commonTranslations'
import TimeAgo from 'ui/datetime/TimeAgo'
import LoadingButton from 'ui/form/LoadingButton'

interface SessionOfflineBannerProps {
  onCheckAppSessionStatus: () => void
}

const SessionOfflineBanner: React.FC<SessionOfflineBannerProps> = ({ onCheckAppSessionStatus }) => {
  const sessionStatusChangedDate = useSelector(sessionStatusChangedDateSelector)
  const sessionLoading = useSelector(sessionLoadingSelector)

  const sessionTimeAgo = <TimeAgo key="timeago" date={sessionStatusChangedDate} />
  const translations = useMemo(() => tConnectivityAndBanners(sessionTimeAgo), [sessionStatusChangedDate])

  const handleRetryConnecting = useCallback(() => {
    onCheckAppSessionStatus()
  }, [onCheckAppSessionStatus])

  return (
    <Alert
      action={
        <LoadingButton onClick={handleRetryConnecting} loading={sessionLoading} size="small">
          {translations.actionRetry}
        </LoadingButton>
      }
      severity="warning"
      variant="filled"
    >
      <div>{translations.textOffline}</div>
    </Alert>
  )
}

export default React.memo(SessionOfflineBanner)
