import { ADD_POINTS, REM_POINTS, SET_POINTS } from '../actions/pointsActions'

const initialState = []

export default function pointsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_POINTS:
      return action.points
    case ADD_POINTS:
      return {
        ...state,
        [action.uuid]: [action.lat, action.lng],
      }
    case REM_POINTS:
      return state.filter((point) => {
        return point === action.point
      })
    default:
      return state
  }
}
