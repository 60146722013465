import React, { useCallback, useEffect, useMemo } from 'react'
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { t } from 'ttag'
import { formatDateShort } from 'utils/date'
import { DataStreamSelectionItem } from 'modules/dataStreams/dataStreams.types'
import { BackCastCreationData, useCreateBackCastSaveMutation } from 'modules/workspace/header/backCast/backCast.api'
import BackCastCalculationForm from 'modules/workspace/header/backCast/BackCastCalculationForm'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useLineChartSettings, useLineChartSettingsSaveMutation } from 'modules/workspace/api/lineChart.api'
import { LineChartSettings } from 'modules/workspace/store/workspace.types'

export interface BackCastTimePeriod {
  start: Date
  end: Date
}

interface BackCastCalculationDialogProps {
  onCloseDialog: () => void
  selectedSiteForecastDataStreams: DataStreamSelectionItem[]
  onCheckNoOfBackCastsRunning: () => void
  setTriggeredBackcastCreation: (value: boolean) => void
}

export const sessionForTriggeredBackcastCreation = 'sessionForTriggeredBackcastCreation'

const BackCastCalculationDialog: React.FC<BackCastCalculationDialogProps> = ({
  onCloseDialog,
  selectedSiteForecastDataStreams,
  onCheckNoOfBackCastsRunning,
  setTriggeredBackcastCreation,
}) => {
  const assetsAndModelsSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const settingsResult = useLineChartSettings()
  const saveLineChartSettingsResult = useLineChartSettingsSaveMutation()

  const [showBackCast]: (keyof LineChartSettings)[] = ['showBackCast']

  const {
    mutate: createBackCastMutation,
    data: createBackCastResponse,
    isError: error,
    isLoading: loading,
    isSuccess,
  } = useCreateBackCastSaveMutation()

  const saveLineChartSettingsMutation = saveLineChartSettingsResult.mutate
  const chartSettings = useMemo(() => settingsResult.data as LineChartSettings, [settingsResult])

  const handleStartBackCastCreation = useCallback(
    (timePeriod: BackCastTimePeriod) => {
      if (timePeriod?.start && timePeriod?.end) {
        const dataToCreateBackCast: BackCastCreationData[] = []
        const filteredAssetsAndModelsSelection = assetsAndModelsSelection.filter((id) => Boolean(id))

        selectedSiteForecastDataStreams?.forEach((forecast) => {
          filteredAssetsAndModelsSelection.forEach((id) => {
            const isModel = id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)
            const data = {
              forecastConfigId: forecast.id || '',
              start: formatDateShort(timePeriod?.start),
              end: formatDateShort(timePeriod?.end),
              ...(isModel ? { forecastModelId: getBackCastModelDataFromId(id).modelId } : { assetId: id }),
            }
            dataToCreateBackCast.push(data)
          })
        })
        createBackCastMutation(dataToCreateBackCast)
      }
    },
    [selectedSiteForecastDataStreams, assetsAndModelsSelection],
  )

  const handleCancelAction = useCallback(() => {
    if (!loading) {
      onCloseDialog()
    }
  }, [loading])

  // Get the count of backcasts running as soon as creation is successfull from dialog
  useEffect(() => {
    if (isSuccess || createBackCastResponse) {
      onCheckNoOfBackCastsRunning()
      const atLeastOneBackCastCreationFailed = createBackCastResponse?.some((d) => d.status == 'ERROR')
      if (!atLeastOneBackCastCreationFailed) {
        // check Show backcast checkbox , whether is clicked or not , and if it's not then we need to activate.
        if (chartSettings && !chartSettings.showBackCast) {
          const data: LineChartSettings = {
            ...chartSettings,
            [showBackCast]: Boolean(!chartSettings[showBackCast]),
          }

          // save value in line chart settings and trigger this action
          saveLineChartSettingsMutation(data)

          // trigger action
          if (!sessionStorage.getItem(sessionForTriggeredBackcastCreation)) {
            setTriggeredBackcastCreation(true)
            sessionStorage.setItem(sessionForTriggeredBackcastCreation, 'true')
          }
        }
        // close dialog
        onCloseDialog()
      }
    }
  }, [isSuccess, createBackCastResponse, chartSettings])

  return (
    <Dialog
      open={true}
      onClose={handleCancelAction}
      onEscapeKeyDown={handleCancelAction}
      onBackdropClick={handleCancelAction}
    >
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">{t`Create backcast`}</Typography>
      </DialogTitle>
      <DialogContent>
        <BackCastCalculationForm
          assetsAndModelsSelection={assetsAndModelsSelection}
          loading={loading}
          onCloseDialog={onCloseDialog}
          onStartBackCastCreation={handleStartBackCastCreation}
          error={error}
          selectedSiteForecastDataStreams={selectedSiteForecastDataStreams}
          creationResponse={createBackCastResponse}
        />
      </DialogContent>
    </Dialog>
  )
}

export default React.memo(BackCastCalculationDialog)
