import styled from 'styled-components'
import { TextField } from 'final-form-material-ui'
import { FormControl } from '@material-ui/core'

const UserDetailsUnitWidth = '1em'

export const UserDetailsTextFieldWithoutLabel = styled(TextField)`
  *.MuiInputBase-root {
    width: calc(100% - ${UserDetailsUnitWidth});
  }
`

export const UserDetailsFieldUnit = styled.div`
  margin-left: 0.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${UserDetailsUnitWidth};
`

export const UserDetailsNumberFieldWithoutLabel = styled(TextField)`
  *.MuiInputBase-root {
    width: calc(100% - ${UserDetailsUnitWidth});
  }
`

export const UserDetailsSelectFieldWithoutLabel = styled(FormControl)`
  & .MuiInput-root {
    margin-top: 0;
  }
  width: calc(100% - ${UserDetailsUnitWidth});
`
