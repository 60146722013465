import { formatDate } from 'utils/date'
import { Timezone } from 'fixtures/timezones'

export const BackcastCsvExport = (backcastCSV: string, timezone: Timezone, csvName: string) => {
  const csvFile = new Blob([backcastCSV], { type: 'text/csv' })
  const downloadLink = document.createElement('a')
  const filename = `${csvName}--${formatDate(new Date(), timezone)}`
  downloadLink.download = `${filename}.csv`
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}
