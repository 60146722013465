import React from 'react'
import ForecastModelsTableRow from 'modules/forecastModels/ForecastModelsTableRow'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { Column, ReTableContextMenuItemName, Sort } from 'modules/reTable/reTable.types'
import { EnrichedForecastModelItem, EnrichedQualityEvaluationItem, ForecastModelTableItem } from 'utils/forecastModel'
import { Asset } from 'modules/asset/store/asset.types'
import { ForecastModel } from 'modules/quality/quality.types'

interface ForecastModelsTableBodyProps {
  asset: Asset
  activeModels: ForecastModel[]
  columnsSelected: Column[]
  evaluationRunning: Record<string, boolean>
  onClickModelRowMenuAction: (menuItem: ReTableContextMenuItemName, forecastModel: EnrichedForecastModelItem) => void
  onSelect: (item: EnrichedQualityEvaluationItem) => void
  query: string
  visibleItems: ForecastModelTableItem[]
  selectedItem: EnrichedQualityEvaluationItem
  sort: Sort
}

const ForecastModelsTableBody: React.FC<ForecastModelsTableBodyProps> = ({
  asset,
  activeModels,
  columnsSelected,
  evaluationRunning,
  onClickModelRowMenuAction,
  onSelect,
  query,
  visibleItems,
  selectedItem,
  sort,
}) => {
  return (
    <ReTableBody>
      {(visibleItems || []).map((item) => {
        const isSelected = selectedItem?.id === item.id
        const key = `${item.id || item.uuid}`
        return (
          <ForecastModelsTableRow
            key={key}
            asset={asset}
            activeModels={activeModels}
            evaluationRunning={evaluationRunning}
            item={item}
            isSelected={isSelected}
            onSelect={onSelect}
            columnsSelected={columnsSelected}
            query={query}
            onClickModelRowMenuAction={onClickModelRowMenuAction}
            sort={sort}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(ForecastModelsTableBody)
