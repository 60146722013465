import React from 'react'

import { Box } from '@material-ui/core'
import LayoutTitle from 'ui/LayoutTitle'
import { t } from 'ttag'
import Spacer from 'ui/Spacer'
import styled from 'styled-components'
import { usePenaltyRegulationTableItems } from 'utils/penaltyRegulations'
import PenaltyRegulationsTable from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationsTable'
import {
  KEY_SHOW_IN_ACTIVE_PENALTIES,
  useUserSetting,
  useUserSettingSaveMutation,
} from 'modules/auth/api/userSettings.api'

const TableWrapper = styled(Box)`
  width: ${(props) => props.width};
  height: calc(100% - 5.2em);
`

const PenaltyRegulations: React.FC = () => {
  const { mutate: mutateShowDeletedPenalties } = useUserSettingSaveMutation<boolean>(KEY_SHOW_IN_ACTIVE_PENALTIES)

  const penalties = usePenaltyRegulationTableItems()

  const userShowInActivePenaltiesResults = useUserSetting<Record<string, boolean>>(KEY_SHOW_IN_ACTIVE_PENALTIES)
  const showInActive = userShowInActivePenaltiesResults?.data?.value || false

  const handleToggleShowInActive = () => {
    mutateShowDeletedPenalties({ value: !showInActive })
  }

  return (
    <>
      <Box ml={1}>
        <LayoutTitle icon="table">
          {t`Penalty regulations`}
          <Spacer />
        </LayoutTitle>
      </Box>
      <TableWrapper ml={1} mr={1}>
        <PenaltyRegulationsTable
          showInActive={showInActive}
          onToggleShowInActive={handleToggleShowInActive}
          tableItems={penalties}
        />
      </TableWrapper>
    </>
  )
}

export default PenaltyRegulations
