import styled from 'styled-components'
import { Slider, SliderProps } from '@material-ui/core'
import React, { useCallback } from 'react'

const StyledSlider = styled(Slider)<SliderProps>`
  &.MuiSlider-root.MuiSlider-vertical {
    height: 14em;
    margin-bottom: 1em;
  }
`

interface ModelSliderProps {
  value: number
  unit?: string
  loading: boolean
  onChange: (value: number) => void
}
const ModelSlider: React.FC<ModelSliderProps> = ({ value, unit = '', loading, onChange }) => {
  // TODO slider color depending on series color

  const getValueText = useCallback(
    (newValue: number) => {
      return `${newValue}${unit}`
    },
    [unit],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent, newValue: number | number[]) => {
      event.stopPropagation()
      if (onChange) {
        onChange(newValue as number)
      }
    },
    [onChange],
  )

  return (
    <StyledSlider
      orientation="horizontal"
      getAriaValueText={getValueText}
      value={value}
      disabled={loading}
      onChange={handleChange}
    />
  )
}

export default React.memo(ModelSlider)
