import React, { useMemo } from 'react'
import { useRouter } from 'utils/route'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

export enum MailAddresses {
  support = 'support@enercast.de',
  sales = 'sales@enercast.de',
}

export enum MailSubjects {
  support = 'Support request regarding page',
  sales = 'Free trial has expired',
  readyOnlySupport = 'Write access request for enercast ID',
}

interface MailLinkProps {
  mailingAddress?: MailAddresses
  supportReason?: string
  children: React.ReactNode
}

const MailLink: React.FC<MailLinkProps> = ({ mailingAddress, supportReason, children }) => {
  const user = useSelector(getUserResultSelector)
  const { location } = useRouter()

  const mailLink = useMemo<string>(() => {
    const mailToAddress = mailingAddress
    const url = `${location.pathname}${location.search}${location.hash}`
    let subject = ''
    let body = ''

    // Handle email subject
    if (mailingAddress === MailAddresses.sales) {
      subject = `${MailSubjects.sales} `
    } else {
      if (supportReason) {
        subject = `${MailSubjects.readyOnlySupport}: "${user?.login}"`
      } else {
        subject = `${MailSubjects.support}: "${url}"`
      }
    }

    //Handle email body
    if (mailingAddress === MailAddresses.support && !supportReason) {
      body = `${navigator.userAgent}\n\n`
    } else {
      body = ''
    }

    return `mailto:${mailToAddress}?subject=${subject}&body=${body}`
  }, [location, user])

  return (
    <a target="_blank" rel="noopener noreferrer" href={mailLink}>
      {children}
    </a>
  )
}

export default MailLink

export const LinkToContactSales = <MailLink mailingAddress={MailAddresses.sales}>{MailAddresses.sales}</MailLink>
export const LinkToContactSupport = <MailLink mailingAddress={MailAddresses.support}>{MailAddresses.support}</MailLink>
export const LinkToContactSupportForReadOnly = (
  <MailLink mailingAddress={MailAddresses.support} supportReason="readOnly">
    {MailAddresses.support}
  </MailLink>
)
