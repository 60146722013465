import { TimeSeriesTime } from 'modules/workspace/store/workspace.types'
import {
  PenaltyBandNew,
  PenaltyGeneratorType,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'

export enum PenaltyBlockIssue {
  FORECAST_DATA_NOT_AVAILABLE = 'FORECAST_DATA_NOT_AVAILABLE',
  METER_DATA_NOT_AVAILABLE = 'METER_DATA_NOT_AVAILABLE',
  CAPACITY_DATA_NOT_AVAILABLE = 'CAPACITY_DATA_NOT_AVAILABLE',
  PENALTIES_TIMESERIES_NOT_AVAILABLE = 'PENALTIES_TIMESERIES_NOT_AVAILABLE',
}

export enum PenaltyResultStatus {
  OK = 'OK',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  PARAM_MISSING = 'PARAM_MISSING',
  ASSET_INCONSISTENCY = 'ASSET_INCONSISTENCY',
  ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
  ASSET_TYPE_NOT_SUPPORTED = 'ASSET_TYPE_NOT_SUPPORTED',
  FORECAST_NOT_FOUND = 'FORECAST_NOT_FOUND',
  FORECAST_TIMESERIES_EMPTY = 'FORECAST_TIMESERIES_EMPTY',
  BACKCAST_TIMESERIES_EMPTY = 'BACKCAST_TIMESERIES_EMPTY',
  ACTUAL_DATA_TIMESERIES_EMPTY = 'ACTUAL_DATA_TIMESERIES_EMPTY',
  CAPACITY_TIMESERIES_EMPTY = 'CAPACITY_TIMESERIES_EMPTY',
  SYNC_FAILED = 'SYNC_FAILED',
  NO_PENALTY_REGULATION_FOUND = 'NO_PENALTY_REGULATION_FOUND',
  PPA_RATE_MISSING = 'PPA_RATE_MISSING',
  PENALTY_TIMESERIES_VALUE_MISSING = 'PENALTY_TIMESERIES_VALUE_MISSING',
  NO_PENALTY_REGULATION_VERSION_FOUND = 'NO_PENALTY_REGULATION_VERSION_FOUND',
  NO_PENALTY_REGULATION_RULE_SET_FOUND = 'NO_PENALTY_REGULATION_RULE_SET_FOUND',
  ASSET_GENERATOR_TYPE_INCONSISTENCY = 'ASSET_GENERATOR_TYPE_INCONSISTENCY',
  MISSING_DATA_FOR_CHILD_ASSET = 'MISSING_DATA_FOR_CHILD_ASSET',
  PENALTY_TIMESERIES_ID_MISSING = 'PENALTY_TIMESERIES_ID_MISSING',
  NO_PENALTY_REGULATION_FOUND_FOR_CHILD_ASSET = 'NO_PENALTY_REGULATION_FOUND_FOR_CHILD_ASSET',
  DATA_RESOLUTION_DOES_NOT_MATCH_REGULATION = 'DATA_RESOLUTION_DOES_NOT_MATCH_REGULATION',
}

export interface PenaltyBlockResult {
  time: TimeSeriesTime
  penaltyBand: PenaltyBandNew
  finalBand: boolean
  actualGeneration: number
  penaltyCost: number
  overInjectionPenaltyCost: number
  overInjectionPenaltyCostNormalized: number
  underInjectionPenaltyCost: number
  underInjectionPenaltyCostNormalized: number
  revenue: number
  revenueImpact: number
  forecastValue: number
  actualValue: number
  availableCapacityValue: number
  absoluteError: number
  normalizedError: number
  penaltyBlockIssues: PenaltyBlockIssue[]
}

export interface PenaltyNotification {
  customer: string
  assetId: string
  forecastConfigId: string
  qualityConfigId: string
  primary: boolean
  start: Date | string
  end: Date | string
  accuracy: number
  accuracyThreshold: number
  accuracyBlockCount: number
  totalBlockCount: number
  blockCountWithIssues: number
  updated: Date | string
}

export interface PenaltyCalculationSummary {
  id: string
  status: PenaltyResultStatus
  statusMessage?: string
  start: Date
  end: Date
  isBackcastRequest: true
  assetId: string
  forecastModelId: null
  penaltyRegulationId: string
  penaltyRegulationVersionId: string
  siteForecastId: string
  actualDataClassifier: string
  availableCapacityClassifier: string
  nameplateCapacity: number
  actualGeneration: number
  overInjectionPenaltyCost: number
  overInjectionPenaltyCostNormalized: number
  underInjectionPenaltyCost: number
  underInjectionPenaltyCostNormalized: number
  revenue: number
  revenueImpact: number
  bandBlockCounts: Record<string, any>
  bandBlockPercentages: Record<string, any>
  bandBlockPercentage: Record<string, any>
  totalBandCount: number
  totalBlockCount: number
  totalBlockCountWithIssues: number
  totalErrorUnits: number
  totalOverInjectionError: number
  totalUnderInjectionError: number
  totalOverInjectionNormalizedError: number
  totalUnderInjectionNormalizedError: number
  averageOverInjectionError: number
  averageUnderInjectionError: number
  averageOverInjectionNormalizedError: number
  averageUnderInjectionNormalizedError: number
  generatorTypes: PenaltyGeneratorType[]
  overInjectionPenaltyBands: PenaltyBandNew[]
  underInjectionPenaltyBands: PenaltyBandNew[]
  penaltyBlockResults?: PenaltyBlockResult[]
  hasUnderInjection: boolean
  blockIssueSet: PenaltyBlockIssue[]
  blockIssuesCount: Record<PenaltyBlockIssue, number>
  summary: boolean
  realtimeDataCompleteness: number
}

export interface PenaltyCalculationResult {
  summaryResult: PenaltyCalculationSummary
  detailResult?: Record<string, any>
}
