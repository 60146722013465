import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Flex from 'ui/styles/Flex'
import ReTable from 'modules/reTable/ReTable'
import { RETABLE_ID_PENALTY_REGULATIONS_NEW, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { table } from 'themes/theme-light'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import { ReTableBody } from 'modules/reTable/ReTableBody'

import { useDispatch } from 'react-redux'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector, reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import { getPenaltyCreationErrorMessage, getPenaltyRegulationColumnsNew } from 'utils/penaltyRegulations'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import styled from 'styled-components'

import {
  PenaltyTableTypeOfRow,
  usePenaltyRegulationDeleteMutation,
} from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'

import PenaltyRegulationTableRow from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationTableRow'
import { PenaltyRegulationTableItemNew } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { QUERY_PENALTY_DETAILS, useQueryParams } from 'utils/query-string'
import PenaltyRegulationsTableToolbarNew from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationsToolbar'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { c } from 'ttag'

const RegulationTable = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

interface PenaltyRegulationsTableNewProps {
  tableItems: PenaltyRegulationTableItemNew[]
  onToggleShowInActive: () => void
  showInActive: boolean
}

const PenaltyRegulationsTable: React.FC<PenaltyRegulationsTableNewProps> = ({
  tableItems,
  onToggleShowInActive,
  showInActive,
}) => {
  const dispatch = useDispatch()
  const { queryParams } = useQueryParams()
  const [showDialog, setShowDialog] = useState(false)

  const [itemsToRender, setItemsToRender] = useState<PenaltyRegulationTableItemNew[]>([])

  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const { mutate: deletePenaltyTableItemMutation } = usePenaltyRegulationDeleteMutation()

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_PENALTY_REGULATIONS_NEW)
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_PENALTY_REGULATIONS_NEW)

  const defaultCollapsed = useMemo(() => [], [])
  const collapsibleIds = useMemo(
    () =>
      (tableItems || [])
        .filter((item) => item.typeOfRow !== PenaltyTableTypeOfRow.RULESET)
        .map((filteredItem) => filteredItem.id),
    [tableItems],
  )

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'name',
    }),
    [],
  )

  const columnsAvailable = useMemo(() => {
    return getPenaltyRegulationColumnsNew()
  }, [])

  const handleItemsToRenderChange = useCallback((items: PenaltyRegulationTableItemNew[]) => {
    const rowItems = items
    requestAnimationFrame(() => {
      setItemsToRender(rowItems)
    })
  }, [])

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_PENALTY_REGULATIONS_NEW, columnsAvailable })
  }, [columnsAvailable])

  const currentlySelectedId: string = useMemo(() => {
    return queryParams[QUERY_PENALTY_DETAILS]
  }, [queryParams[QUERY_PENALTY_DETAILS]])

  const handleDeletePenaltyTableItem = (item: PenaltyRegulationTableItemNew) => {
    deletePenaltyTableItemMutation(item)
  }

  const handleOpenDialog = () => {
    setShowDialog(true)
  }
  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  return (
    <Flex direction="column" fullHeight>
      <ConfirmationDialog
        heading={c('Penalties:').t`Penalty Regulation`}
        text={getPenaltyCreationErrorMessage()}
        confirmAction={'Ok'}
        onConfirm={handleCloseDialog}
        openDialog={showDialog}
      />
      <PenaltyRegulationsTableToolbarNew
        columns={columnsSelected}
        tableHeaderHasActions={true}
        onToggleShowInActive={onToggleShowInActive}
        showInActive={showInActive}
      />

      <RegulationTable
        id={RETABLE_ID_PENALTY_REGULATIONS_NEW}
        itemHeight={table.rowHeight}
        items={tableItems as ReTableItem[]}
        hasExtendedHeader={false}
        itemsPaddingHeader={ITEMS_PADDING_HEADER}
        itemsPaddingFooter={ITEMS_PADDING_FOOTER}
        renderAheadCount={RENDER_AHEAD_COUNT}
        collapsibleIds={collapsibleIds}
        defaultCollapsed={defaultCollapsed}
        defaultSort={defaultSort}
        onItemsToRenderChange={handleItemsToRenderChange}
      >
        <ReTableGenericHeader isHierarchical={true} columns={columnsSelected} />
        <ReTableBody>
          {itemsToRender.map((item) => {
            const selected = item.id === currentlySelectedId
            return (
              <PenaltyRegulationTableRow
                onDeleteRowItem={handleDeletePenaltyTableItem}
                key={JSON.stringify(item)}
                selected={selected}
                sort={sort}
                item={item}
                onOpenDialog={handleOpenDialog}
              />
            )
          })}
        </ReTableBody>
      </RegulationTable>
    </Flex>
  )
}

export default PenaltyRegulationsTable
