import React from 'react'
import Flex from 'ui/styles/Flex'
import { t } from 'ttag'
import styled from 'styled-components'

//Styled Component
const HeaderHelper = styled(Flex)`
  @media (max-width: 1440px) {
    display: none;
  }
`

const HelperDiv = styled.div`
  flex: 1 1 0;
  min-width: 17vw;
  max-width: 30vw;
`
const SecondHelperDiv = styled.div`
  min-width: 13em;
  max-width: 13em;
`

const HeaderTextDiv = styled.div`
  flex: 1 1 0;
`

const StatusDiv = styled.div`
  text-align: center;
  width: 43vw;
  display: flex;
  justify-content: space-around;
`

const OriginalDiv = styled.div`
  width: 21.5vw;
  padding-left: 4em;
`

const FilteredDiv = styled.div`
  width: 21.5vw;
  padding-right: 4em;
`

const BulkMdcHeaderHelper: React.FC = () => {
  return (
    <HeaderHelper justifyContent="center" alignItems="center" flexWrap="wrap">
      <HelperDiv></HelperDiv>
      <HeaderTextDiv>
        <StatusDiv>
          <OriginalDiv>
            <b>{t`Original`}</b>
          </OriginalDiv>
          <FilteredDiv>
            <b>{t`Filtered`}</b>
          </FilteredDiv>
        </StatusDiv>
      </HeaderTextDiv>
      <SecondHelperDiv></SecondHelperDiv>
    </HeaderHelper>
  )
}

export default BulkMdcHeaderHelper
