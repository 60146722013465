import {
  PenaltyFormSaveMainItem,
  PenaltyRegulationNew,
  PenaltyRegulationRuleSet,
  PenaltyRegulationTableItemNew,
  PenaltyRegulationVersionNew,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { apiRequest, useOptimisticMutation } from 'utils/request'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'
import { FormApi } from 'final-form'

export const QUERY_KEY_INDIAN_STATES = 'data:indianStates'
export const QUERY_KEY_PENALTY_FORM_SAVEMAINITEMKEY = 'data:penaltyRegulationForm:saveMainItemKey'
export const QUERY_KEY_PENALTY_REGULATIONS = 'data:penaltyRegulations'
export const QUERY_KEY_INDIAN_TIMESERIES_LIST = 'data:indianTimeseriesList'
const PENALTY_REGULATIONS_BASE_URL = '/api/qecs/penalty/v1/regulations'

const getIndianStates = async () => {
  return await apiRequest<string[]>(() => {
    return axios.get('api/qecs/v1/indianStates')
  })
}

// Hooks to fetch and update via react-query

export const useIndianStates = () => {
  return useQuery<string[]>(QUERY_KEY_INDIAN_STATES, getIndianStates)
}

const getPenaltyRegulations = async () => {
  return await apiRequest<PenaltyRegulationNew[]>(() => {
    return axios.get('/api/qecs/penalty/v1/regulations')
  })
}

export const usePenaltyRegulations = () => {
  // fetch all
  return useQuery<PenaltyRegulationNew[]>(QUERY_KEY_PENALTY_REGULATIONS, getPenaltyRegulations)
}

const getIndianTimeseries = async () => {
  return await apiRequest<string[]>(() => {
    return axios.get('/api/qecs/penalty/v1/timeseries_list')
  })
}

export const useIndianTimeseriesList = () => {
  return useQuery<string[]>(QUERY_KEY_INDIAN_TIMESERIES_LIST, getIndianTimeseries)
}

export enum PenaltyTableTypeOfRow {
  REGULATION = 'REGULATION',
  VERSION = 'VERSION',
  RULESET = 'RULESET',
}

export type PenaltyFormData = PenaltyRegulationNew | PenaltyRegulationVersionNew | PenaltyRegulationRuleSet

const savePenaltyRegulations = async (data: PenaltyFormData) => {
  const { REGULATION, VERSION, RULESET } = PenaltyTableTypeOfRow

  let url = ''
  if (data.typeOfRow === REGULATION) {
    url = `${PENALTY_REGULATIONS_BASE_URL}`
  } else if (data.typeOfRow === VERSION) {
    url = `${PENALTY_REGULATIONS_BASE_URL}/${data.regulationId}/version`
  } else if (data.typeOfRow === RULESET) {
    url = `${PENALTY_REGULATIONS_BASE_URL}/${data.regulationId}/version/${data.versionId}/ruleSet`
  }

  const isNew = data?.id === 'new'

  if (isNew) {
    delete data.id
    data['regulationVersions'] = []
    return await apiRequest<PenaltyRegulationNew>(() => {
      return axios.post(url, data)
    })
  } else {
    return await apiRequest<PenaltyRegulationNew>(() => {
      return axios.put(`${url}/${data.id}`, data)
    })
  }
}

export const usePenaltyRegulationsSaveMutation = (formReference: FormApi<PenaltyRegulationTableItemNew> | null) => {
  return useOptimisticMutation<PenaltyFormData, PenaltyFormData, PenaltyRegulationNew[]>({
    queryCacheKey: QUERY_KEY_PENALTY_REGULATIONS,
    apiMutator: (data) => savePenaltyRegulations(data),
    cacheUpdater: (updatedPenalty, penalties) => {
      return penalties
    },
    onSuccess: (penaltyData) => {
      formReference?.reset(penaltyData)
      formReference?.restart(penaltyData)
    },
  })
}

// user settings for save button in penalty detail page
export const usePenaltyFormSaveMainItemKey = () => {
  return useUserSetting<PenaltyFormSaveMainItem>(QUERY_KEY_PENALTY_FORM_SAVEMAINITEMKEY)
}

export const usePenaltyFormSaveMainItemKeySaveMutation = () => {
  return useUserSettingSaveMutation<PenaltyFormSaveMainItem>(QUERY_KEY_PENALTY_FORM_SAVEMAINITEMKEY)
}

const deletePenaltyRegulation = async (data: PenaltyRegulationTableItemNew) => {
  const { REGULATION, VERSION, RULESET } = PenaltyTableTypeOfRow

  let url = ''
  if (data.typeOfRow === REGULATION) {
    url = `${PENALTY_REGULATIONS_BASE_URL}/${data.id}`
  } else if (data.typeOfRow === VERSION) {
    url = `${PENALTY_REGULATIONS_BASE_URL}/${data.regulationId}/version/${data.id}`
  } else if (data.typeOfRow === RULESET) {
    url = `${PENALTY_REGULATIONS_BASE_URL}/${data.regulationId}/version/${data.versionId}/ruleSet/${data.id}`
  }

  return await apiRequest<PenaltyRegulationNew>(() => {
    return axios.delete(url)
  })
}

export const usePenaltyRegulationDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deletePenaltyRegulation, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_PENALTY_REGULATIONS)
    },
  })
}

/**
 * getScheduleTiming
 * Used in schedule timing (schedule feature)
 * @param assetId
 */
export const getScheduleTiming = async (assetId: string) => {
  return await apiRequest<any>(() => {
    const url = `${PENALTY_REGULATIONS_BASE_URL}/version/assetId/${assetId}`
    return axios.get(url)
  })
}
