import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'

import { MeterdataSummary } from 'modules/asset/store/asset.types'
import React from 'react'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import AssetMeterdataDescription from 'ui/meterdata/AssetMeterdataDescription'
import PopperTooltip from 'ui/PopperTooltip'

const getMeterdataAvailabilityText = (summary: MeterdataSummary) => {
  const days = summary.availabilityDays

  let text
  if (days < 90) {
    text = '< 3 months'
  } else if (days < 180) {
    text = '< 6 months'
  } else if (days < 360) {
    text = '> 6 months'
  } else if (days < 720) {
    text = '> 1 year'
  } else {
    text = '> 2 year'
  }

  return text
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 0.25em;
`

interface MeterdataSummaryProps {
  summary: MeterdataSummary
  selected?: boolean
}

const AssetMeterdata: React.FC<MeterdataSummaryProps> = ({ summary, selected = false, ...rest }) => {
  const isSufficientData = summary.availabilityDays > 180
  const stateColor = isSufficientData ? 'primary' : 'error'
  return (
    <PopperTooltip
      popperLabel={
        <Typography color={selected ? 'inherit' : stateColor} {...rest}>
          {getMeterdataAvailabilityText(summary)}{' '}
          <StyledFontAwesomeIcon color={selected ? 'white' : theme.palette[stateColor].main} icon="info" size="sm" />
        </Typography>
      }
      popperContent={<AssetMeterdataDescription summary={summary} toolTip />}
    />
  )
}

export default React.memo(AssetMeterdata)
