import React from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import { FormContainer } from 'ui/form/form.style'
import AssetCrudCommonHeader from 'modules/asset/assetCrud/AssetCrudCommonHeader'
import { assetTabNames } from 'fixtures/assetForm'
import OldUiIframe from 'ui/elements/OldUiIframe'

interface AssetQualityProps {
  asset: Asset
}

const AssetQuality: React.FC<AssetQualityProps> = ({ asset }) => {
  return (
    <FormContainer>
      <AssetCrudCommonHeader asset={asset} activeTab={assetTabNames.quality} />
      <OldUiIframe url={`/iframe/#/sites?IFRAME&view=quality&site=${asset.id}`} />
    </FormContainer>
  )
}

export default AssetQuality
