import { GetQualityOverviewAction, QualityOverview } from 'modules/quality/quality.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  result: QualityOverview | null
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: null,
  loading: false,
  error: null,
}

const result = createReducer<State['result'], GetQualityOverviewAction>(
  (state = initialState.result, { type, qualityOverview }) => {
    if (type === actionTypes.GET_QUALITY_OVERVIEW_SUCCESS) {
      return qualityOverview
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetQualityOverviewAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.GET_QUALITY_OVERVIEW_SUCCESS:
    case actionTypes.GET_QUALITY_OVERVIEW_FAILURE:
      return false
    case actionTypes.GET_QUALITY_OVERVIEW_REQUEST:
      return true
  }
  return state
})

const error = createReducer<State['error'], GetQualityOverviewAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.GET_QUALITY_OVERVIEW_FAILURE:
      return error
    case actionTypes.GET_QUALITY_OVERVIEW_REQUEST:
    case actionTypes.GET_QUALITY_OVERVIEW_SUCCESS:
      return null
  }
  return state
})

export const getQualityOverviewReducer = combineReducers({ result, loading, error })

// selectors

export const getQualityOverviewResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.quality.getQualityOverview.result,
  (result) => result,
)
export const getQualityOverviewLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.quality.getQualityOverview.loading,
  (loading) => loading,
)
export const getQualityOverviewErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.quality.getQualityOverview.error,
  (error) => error,
)
