import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import AreaForecasts from 'modules/dataStreams/areaForecast/AreaForecasts'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import SiteForecasts from 'modules/dataStreams/siteForecast/SiteForecasts'
import WeatherDataStreams from 'modules/dataStreams/weatherData/WeatherDataStreams'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import FullHeight from 'ui/FullHeight'
import Flex from 'ui/styles/Flex'
import {
  hasPermissionForAreaForecast,
  hasPermissionForCapacityData,
  hasPermissionForE3ModelLevelForecast,
  hasPermissionForMeterData,
  hasPermissionForSiteForecast,
  hasPermissionForWeatherData,
} from 'utils/user'

import { dataStreamTypeQuery } from 'utils/dataStream'

import { QUERY_DATA_STREAM_ID, QUERY_DATA_STREAM_TYPE, useQueryParams, useQueryString } from 'utils/query-string'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'

export const DataStreamListContainer = styled(Flex)`
  margin-right: 1em;
`

const Container = styled(FullHeight)`
  margin: 0em 1em 0 1em;
`

const ManageDataStreams: React.FC = () => {
  const { onDeleteQueryStrings } = useQueryString()

  const user = useSelector(getUserResultSelector)
  const { queryParams } = useQueryParams()
  const activeDataStream = useMemo(() => queryParams[QUERY_DATA_STREAM_TYPE] || TimeSeriesType.SITE_FORECAST, [
    queryParams,
  ])

  const handleCloseDataStreamSlider = useCallback(() => {
    const queryStrings = [QUERY_DATA_STREAM_TYPE, QUERY_DATA_STREAM_ID]
    onDeleteQueryStrings(queryStrings)
  }, [onDeleteQueryStrings])

  useEffect(() => {
    if (activeDataStream) {
      let permissionCheckHandler
      switch (activeDataStream) {
        case TimeSeriesType.SITE_FORECAST:
          permissionCheckHandler = hasPermissionForSiteForecast
          break
        case TimeSeriesType.E3_META_FORECAST:
          permissionCheckHandler = hasPermissionForE3ModelLevelForecast
          break
        case TimeSeriesType.METER_DATA:
          permissionCheckHandler = hasPermissionForMeterData
          break
        case TimeSeriesType.CAPACITY_DATA:
          permissionCheckHandler = hasPermissionForCapacityData
          break
        case TimeSeriesType.WEATHER_DATA:
          permissionCheckHandler = hasPermissionForWeatherData || isAdmin || isImpersonatedAdmin
          break
        case TimeSeriesType.AREA_FORECAST:
          permissionCheckHandler = hasPermissionForAreaForecast
          break
      }
      if (permissionCheckHandler) {
        if (!permissionCheckHandler(user)) {
          handleCloseDataStreamSlider()
        }
      }
    }
  }, [activeDataStream, user, handleCloseDataStreamSlider])

  return (
    <Container>
      <>{activeDataStream === dataStreamTypeQuery[TimeSeriesType.SITE_FORECAST] && <SiteForecasts />}</>
      {/*<>{activeDataStream === dataStreamTypeQuery[TimeSeriesType.E3_META_FORECAST] && <E3Forecast />}</>*/}
      <>{activeDataStream === dataStreamTypeQuery[TimeSeriesType.WEATHER_DATA] && <WeatherDataStreams />}</>
      <>{activeDataStream === dataStreamTypeQuery[TimeSeriesType.AREA_FORECAST] && <AreaForecasts />}</>
    </Container>
  )
}

export default React.memo(ManageDataStreams)
