import React, { useEffect, useState } from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { isDateValid } from 'utils/date'

interface DateTimePickerProps {
  value: Date | null
  onDateChange: (date: Date) => void
  label: string
  onlyDate?: boolean
  name?: string
  errorMsg?: string
  minDate?: Date | null
  maxDate?: Date | null
  minDateMessage?: string
  disabled: boolean
}

const DateAndTimePicker: React.FC<DateTimePickerProps> = ({
  value,
  onDateChange,
  label,
  onlyDate = true,
  name = '',
  errorMsg = '',
  minDate = null,
  maxDate = null,
  minDateMessage = '',
  disabled = false,
}) => {
  const [date, setDate] = useState<Date>()
  const [error, setError] = useState<string>('')
  const handleDateChange = (value: Date) => {
    onDateChange(value)
  }

  useEffect(() => {
    if (value) {
      setDate(value)
    }
  }, [value])

  useEffect(() => {
    if (!isDateValid(value)) {
      setError('Invalid date')
    } else if (errorMsg) {
      setError(errorMsg)
    } else setError('')
  }, [errorMsg, value])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {onlyDate ? (
        <KeyboardDatePicker
          variant="inline"
          label={label}
          // helperText={errorMsg ? errorMsg : undefined}
          // error={Boolean(errorMsg)}
          onChange={handleDateChange}
          value={date ? date : null}
          format={'yyyy-MM-dd'}
          autoOk={true}
          minDate={minDate}
          maxDate={maxDate}
          minDateMessage={minDateMessage}
          disabled={disabled}
        />
      ) : (
        <KeyboardDateTimePicker
          ampm={false}
          name={name}
          label={label}
          helperText={error ? error : undefined}
          error={Boolean(error)}
          onChange={handleDateChange}
          value={date ? date : null}
          minDate={minDate}
          // maxDate={maxDate}
          // minDateMessage={minDateMessage}
          format={'yyyy-MM-dd HH:mm'}
          autoOk={true}
          disabled={disabled}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}

export default DateAndTimePicker
