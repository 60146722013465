import React, { useCallback, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors, theme, useCustomMuiStyles } from 'themes/theme-light'
import { CellRenderType, Column, ColumnItem, Sort } from 'modules/reTable/reTable.types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { Box, IconButton } from '@material-ui/core'
import { useReTableId, useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_SET_SORT } from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch, useSelector } from 'react-redux'
import PopperTooltip from 'ui/PopperTooltip'
import { getSortIcon } from 'modules/reTable/reTable.functionality'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'

interface HeaderLabelProps {
  subItem?: ColumnItem
  autoWidthForHeaderWithSort?: boolean
  widthInheritanceForHeaderWithSort?: string
}
// TODO width hack for quality subitems

const HeaderLabel = styled(Flex)<HeaderLabelProps>`
  width: ${(props) => {
    if (props.autoWidthForHeaderWithSort) {
      return 'auto'
    }
    return props.subItem ? '6.25em' : 'inherit'
  }};
  max-width: ${(props) => {
    if (props.widthInheritanceForHeaderWithSort) {
      return props.widthInheritanceForHeaderWithSort
    }
    return undefined
  }};
  padding-right: 0.4em;
`
const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    width: 1.2em;
  }
`
const sortIcon: (column: Column, sort: Sort, subItem: ColumnItem | undefined) => IconProp = (column, sort, subItem) => {
  const sortId = subItem ? `${column.name}.${subItem.name}` : column.name
  if (sort.column === sortId) {
    return getSortIcon(column.cellRenderType, sort)
  }
  return 'sort'
}

interface ReTableHeaderWithSortProps {
  column: Column
  disableSort?: boolean
  subItem?: ColumnItem
  autoWidthForHeaderWithSort?: boolean
  widthInheritanceForHeaderWithSort?: string | undefined
}

export const ReTableHeaderWithSort: React.FC<ReTableHeaderWithSortProps> = ({
  column,
  disableSort,
  subItem,
  autoWidthForHeaderWithSort,
  widthInheritanceForHeaderWithSort,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserResultSelector)
  const { NUMERIC, CUSTOM_NUMERIC } = CellRenderType
  const [ref, tableId] = useReTableId()
  const sort = useReTableSelectorWithId(reTableSortSelector, tableId)
  const label = useMemo(() => (subItem ? subItem.label : column.label), [column, subItem])

  const classes = useCustomMuiStyles()

  const handleSort = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      const sortId = subItem ? `${column.name}.${subItem.name}` : column.name
      dispatch({
        type: RETABLE_SET_SORT,
        table: tableId,
        sort: {
          active: true,
          column: sortId,
          ascending: sort.column === sortId ? !sort.ascending : true,
          cellRenderType: column?.cellRenderType,
        },
      })
    },
    [column, sort, subItem, tableId],
  )
  const icon = sortIcon(column, sort, subItem)
  return (
    // TODO alignment hack for quality subitems
    <HeaderLabel
      justifyContent={
        subItem
          ? 'center'
          : column.cellRenderType === NUMERIC || column.cellRenderType === CUSTOM_NUMERIC
          ? 'flex-end'
          : 'inherit'
      }
      alignItems="center"
      autoWidthForHeaderWithSort={autoWidthForHeaderWithSort}
      widthInheritanceForHeaderWithSort={widthInheritanceForHeaderWithSort}
    >
      {column.sortable && (
        <StyledIconButton
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleSort(event)}
          disabled={disableSort}
          size="small"
          aria-label={`sort column ${label}`}
        >
          <FontAwesomeIcon size="sm" icon={icon} color={disableSort ? colors.marked : theme.palette.primary.main} />
        </StyledIconButton>
      )}
      <span
        style={{ marginLeft: !column.sortable ? '0.3em' : '0', overflow: 'hidden', textOverflow: 'ellipsis' }}
        ref={ref}
        className={
          column.isAdminComponent
            ? (isAdmin(user) || isImpersonatedAdmin(user)) && column.isAdminComponent
              ? classes.adminComponent
              : 'inherit'
            : 'inherit'
        }
      >
        <FixedWidthTextContainer tableToolbarHasActions={true} text={label} width={'inherit'}>
          {label}
        </FixedWidthTextContainer>
      </span>
      {column.toolTip && (
        <PopperTooltip
          popperLabel={
            <Box ml={0.5}>
              <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
            </Box>
          }
          popperContent={<div>{column.toolTip}</div>}
        />
      )}
    </HeaderLabel>
  )
}
