import React, { useMemo } from 'react'
import { ReTableGenericToolbarContainer } from 'ui/styles/table'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Column, ReTableId, ReTableItem } from 'modules/reTable/reTable.types'
import { math } from 'polished'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import { TableHeaderActionCellWidth } from 'modules/reTable/ReTableHeaderActionsCell'

interface RunningBackcastsTableToolbarProps {
  columns: Column[]
  tableHeaderHasActions: boolean
  tableId: ReTableId
  totalItems: ReTableItem[]
  visibleItems: ReTableItem[]
}

const RunningBackcastsTableToolbar: React.FC<RunningBackcastsTableToolbarProps> = ({
  columns,
  tableHeaderHasActions,
  tableId,
  visibleItems,
}) => {
  const columnsWidth = useMemo(() => {
    const width = (columns || []).reduce((sum, column) => {
      return math(`${sum} + ${column.width}`)
    }, '0')
    return math(
      `${visibleItems.length ? columns.length * 0.58 : 0} em + ${width} + ${editableRowActionsCellWidth} + ${
        tableHeaderHasActions ? TableHeaderActionCellWidth : 0
      }`,
    )
  }, [columns, visibleItems])

  return (
    <ReTableGenericToolbarContainer width={columnsWidth}>
      <ReTableToolbar showSearchField={true} id={tableId} showClearSorting={true} />
    </ReTableGenericToolbarContainer>
  )
}

export default React.memo(RunningBackcastsTableToolbar)
