import React from 'react'
import AssetTreeSummary from 'modules/asset/tree/AssetTreeSummary'
import TableFooter from '@material-ui/core/TableFooter'
import { Column } from 'modules/reTable/reTable.types'
import { QualityOverview } from 'modules/quality/quality.types'

interface AssetTreeFooterProps {
  isWide: boolean
  columns: Column[]
  onCapacitySummaryValuesHasFractions: (value: boolean) => void
  qualityOverview: QualityOverview | null
}

const AssetTreeFooter: React.FC<AssetTreeFooterProps> = ({
  isWide,
  columns,
  onCapacitySummaryValuesHasFractions,
  qualityOverview,
}) => {
  return (
    <TableFooter>
      <AssetTreeSummary
        qualityOverview={qualityOverview}
        isWide={isWide}
        columns={columns}
        onCapacitySummaryValuesHasFractions={onCapacitySummaryValuesHasFractions}
      />
    </TableFooter>
  )
}

export default React.memo(AssetTreeFooter)
