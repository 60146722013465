import React from 'react'
import Logo from 'modules/app/header/Logo'
import Flex from 'ui/styles/Flex'
import { Box, Typography } from '@material-ui/core'
import { DATE_FORMAT_DEFAULT, formatDate } from 'utils/date'
import { t } from 'ttag'
import { QUERY_PRINT_VIEW, useQueryString } from 'utils/query-string'
import { A4Dimensions, PrintViewHeadingsFontSize } from 'modules/printView/PrintView'
import UserProfilePic from 'ui/elements/UserProfilePic'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

const PrintViewHeader: React.FC = () => {
  const { onDeleteQueryStrings } = useQueryString()
  const date = new Date()

  const handleClosePrintPage = () => {
    onDeleteQueryStrings([QUERY_PRINT_VIEW])
  }

  return (
    <>
      <Flex style={{ width: A4Dimensions.width }} justifyContent="space-between">
        <Box mb={1} mt={1}>
          <Logo height={'2.3em'} />
        </Box>
        <Box display="flex" flexDirection="row" mb={1}>
          <UserProfilePic showUserNameIfNoProfilePic={false} />
        </Box>
      </Flex>
      <Flex style={{ height: '26px' }} justifyContent="space-between" alignItems="center">
        <Flex justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">
            <div style={{ fontSize: PrintViewHeadingsFontSize }}>{t`Report Date:`}</div>
          </Typography>

          <span>&nbsp;{formatDate(date, null, DATE_FORMAT_DEFAULT)}</span>
        </Flex>

        <div className="print-page-close-button">
          <FontAwesomeActionIcon icon="times" size="lg" onClick={handleClosePrintPage} />
        </div>
      </Flex>
    </>
  )
}

export default PrintViewHeader
