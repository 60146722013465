import React, { useEffect, useMemo } from 'react'
import { Loading, useChart } from 'react-jsx-highstock'

interface LoadingOptionsProps {
  isLoading: boolean
  textForLoading?: string
  minHeight?: number | undefined
  hideDefaultLoadingText?: boolean
}

const LoadingOptions: React.FC<LoadingOptionsProps> = ({
  isLoading,
  textForLoading = '',
  minHeight = 0,
  hideDefaultLoadingText = false,
}) => {
  const chart = useChart()

  useEffect(() => {
    if (!chart) return

    if (isLoading) {
      textForLoading?.length > 0 ? chart.showLoading(textForLoading) : chart.showLoading()
    } else {
      chart.hideLoading()
    }
  }, [chart, isLoading, textForLoading])

  const loadingOptions = useMemo(() => {
    return {
      labelStyle: {
        color: hideDefaultLoadingText ? 'transparent' : '#333',
      },
      style: {
        backgroundColor: '#f4f4f4',
        opacity: textForLoading?.length > 0 ? 1 : 0.35,
        fontFamily: 'Lato',
        minHeight: minHeight > 0 ? `${(minHeight + 4).toString()}px` : 'auto', // this is used mainly in scatter chart , so number 4 is some more space in the bottom
      },
    }
  }, [textForLoading, minHeight, hideDefaultLoadingText])

  return <Loading isLoading={isLoading} {...loadingOptions} />
}

export default React.memo(LoadingOptions)
