import React, { useMemo } from 'react'
import { SummaryType } from 'modules/asset/tree/AssetTreeSummary'
import { useSelector } from 'react-redux'
import { getMeterdataSummariesResultSelector } from 'modules/asset/store/getMeterdataSummaries.state'
import { Asset } from 'modules/asset/store/asset.types'
import Flex from 'ui/styles/Flex'

interface MeterDataSummaryCellProps {
  activeSummary: SummaryType
  allAssets: Asset[]
  selectedAssets: Asset[]
}

const MeterDataSummaryCell: React.FC<MeterDataSummaryCellProps> = ({ activeSummary, allAssets, selectedAssets }) => {
  const allAssetsMeterDataSummaries = useSelector(getMeterdataSummariesResultSelector)

  const allAssetsSummaryCount = allAssetsMeterDataSummaries ? Object.keys(allAssetsMeterDataSummaries).length : 0

  const selectedAssetsSummariesCount = useMemo(() => {
    if (allAssetsSummaryCount && selectedAssets) {
      return selectedAssets.filter((asset) => allAssetsMeterDataSummaries[asset.id]).length
    } else return 0
  }, [selectedAssets, allAssetsSummaryCount])

  return (
    <Flex justifyContent={'flex-end'} flexGrow={1}>
      {activeSummary === SummaryType.total ? allAssetsSummaryCount : selectedAssetsSummariesCount}/
      {activeSummary === SummaryType.total ? allAssets.length : selectedAssets.length}
    </Flex>
  )
}

export default MeterDataSummaryCell
