import React, { useCallback } from 'react'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'
import Number from 'ui/Number'
import Flex from 'ui/styles/Flex'
import { EnrichedForecastModelItem, EnrichedQualityEvaluationItem } from 'utils/forecastModel'
import { Asset } from 'modules/asset/store/asset.types'
import { useSelector } from 'react-redux'
import { getQualityEvaluationsResultSelector } from 'modules/quality/redux_store/state/getQualityEvaluation'
import { getTrainingJobsResultSelector } from 'modules/quality/redux_store/state/getTrainingJobs'
import { Column, Sort } from 'modules/reTable/reTable.types'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import StatusIcon from 'ui/elements/StatusIcon'
import { colors } from 'themes/theme-light'
import { getPrimaryMeasurement } from 'utils/quality'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import WeatherModelEnsembleBar from 'modules/forecastModels/WeatherModelEnsembleBar'
import ActivationState from 'modules/forecastModels/ActivationState'
import { DATE_FORMAT_DEFAULT, DATE_FORMAT_DEFAULT_SHORT, formatDate } from 'utils/date'
import { ForecastModel } from 'modules/quality/quality.types'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import Highlight from 'ui/Highlight'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ForecastModelAndEvaluationReason from 'modules/forecastModels/ForecastModelAndEvaluationReason'
import { math } from 'polished'

interface StyledReTableRowProps {
  hidden: boolean
}

const StyledReTableRow = styled(ReTableRow)<StyledReTableRowProps>`
  &.MuiTableRow-root.Mui-selected {
    background-color: ${colors.marked};

    &:hover {
      background-color: ${colors.markedLight};
    }
  }
  *.MuiTableCell-body {
    color: ${(props) => (props.hidden ? 'grey' : 'inherit')};
  }
`

const ModelTreeControlCell = styled(ReTableCell)`
  &.MuiTableCell-body:first-child > * {
    padding-right: 0;
    padding-left: 1em;
  }
`

interface ForecastModelsTableRowProps {
  asset: Partial<Asset>
  columnsSelected: Column[]
  item: EnrichedQualityEvaluationItem | ForecastModel
  isSelected: boolean
  onSelect: (item: EnrichedQualityEvaluationItem) => void
  query: string
  sort: Sort
}
const QualityEvaluationRow: React.FC<ForecastModelsTableRowProps> = ({
  asset,
  columnsSelected,
  item,
  isSelected,
  onSelect,
  query,
  sort,
}) => {
  const qualityEvaluations = useSelector(getQualityEvaluationsResultSelector)
  const trainingJobs = useSelector(getTrainingJobsResultSelector)

  // handlers
  const handleSelect = useCallback(() => {
    onSelect(item)
  }, [item])

  // cell renderers
  const getEvaluationCell = useCallback(
    (evaluation: EnrichedQualityEvaluationItem, column) => {
      const forecastModel = evaluation.forecastModel
      const trainingJob = trainingJobs.find((t) => t.uuid === evaluation.meta)
      let correction = ''
      if (evaluation?.forecastModel?.parameter.windCorrections) {
        const cs =
          typeof evaluation?.forecastModel.parameter.windCorrections === 'string'
            ? JSON.parse(evaluation?.forecastModel.parameter.windCorrections)
            : evaluation?.forecastModel.parameter.windCorrections
        correction = cs?.name || ''
      }

      switch (column.name) {
        case 'forecastModelName':
          return (
            <Flex alignItems={'center'}>
              <Box mr={0.5}>
                <FontAwesomeIcon color={'inherit'} icon={'code-branch'} />
              </Box>

              <FixedWidthTextContainer text={evaluation.forecastModelName} width={'10em'}>
                <Highlight query={query} text={evaluation.forecastModelName} />
              </FixedWidthTextContainer>
            </Flex>
          )

        case 'forecastModelId':
          return (
            <>
              <FixedWidthTextContainer text={evaluation.forecastModelId} width={math(`${column.width} - 0.5em`)}>
                <Highlight text={evaluation.forecastModelId} query={query} />
              </FixedWidthTextContainer>
            </>
          )

        case 'forecastConfigName':
          return (
            <>
              <FixedWidthTextContainer text={evaluation.forecastConfigName} width={column.width}>
                <Highlight text={evaluation.forecastConfigName} query={query} />
              </FixedWidthTextContainer>
            </>
          )
        case 'qualityConfigName':
          return (
            <FixedWidthTextContainer text={evaluation.qualityConfigName} width={column.width}>
              <Highlight text={evaluation.qualityConfigName} query={query} />
            </FixedWidthTextContainer>
          )
        case 'type':
          return <>{evaluation.qualityConfig.primary && <Typography color="primary">primary</Typography>}</>
        case 'deliveryTime':
          return <>{evaluation.deliveryTime && <Typography>{evaluation.deliveryTime}</Typography>}</>
        case 'reason':
          return <ForecastModelAndEvaluationReason forecastModel={forecastModel} qualityEvaluation={evaluation} />
        case 'evaluationPeriodStart':
          return (
            <>
              <Highlight
                text={formatDate(evaluation?.evaluationTimeRangeStart, null, DATE_FORMAT_DEFAULT_SHORT) || ''}
                query={query}
              />
            </>
          )

        case 'evaluationPeriodEnd':
          return (
            <>
              <Highlight
                text={formatDate(evaluation?.evaluationTimeRangeEnd, null, DATE_FORMAT_DEFAULT_SHORT) || ''}
                query={query}
              />
            </>
          )
        case 'trainingPeriodStart':
          const from = trainingJob?.trainingStart ? new Date(trainingJob.trainingStart) : null
          return <>{from && <Highlight query={query} text={formatDate(from, null, DATE_FORMAT_DEFAULT_SHORT)} />}</>
        case 'trainingPeriodEnd':
          const to = trainingJob?.trainingEnd ? new Date(trainingJob.trainingEnd) : null
          return <>{to && <Highlight query={query} text={formatDate(to, null, DATE_FORMAT_DEFAULT_SHORT)} />}</>
        case 'nRmse':
        case 'nMae':
        case 'absoluteDeviation':
        case 'nbias':
        case 'correlationCoefficient':
          const isFinished = evaluation.status === 'FINISHED' || !evaluation.status
          if (isFinished) {
            const value = evaluation[column.name] as number
            const isPrimary = getPrimaryMeasurement(evaluation) === column.name
            // const changePercentage = evaluation[`${column.name}ChangePercentage`]
            // const hasImproved = evaluation[`${column.name}HasImproved`]
            // const color =
            //   hasImproved === EvaluationImprovement.POSITIVE
            //     ? 'green'
            //     : hasImproved === EvaluationImprovement.NEGATIVE
            //     ? 'red'
            //     : undefined
            return (
              <Flex justifyContent="flex-end">
                {value && (
                  <Flex alignItems="flex-end" direction="column">
                    <Flex justifyContent="flex-end">
                      <ConditionalWrapper condition={isPrimary} wrapper={(children) => <strong>{children}</strong>}>
                        <Number highlight={query} data={value} showFractionalPart={true} limit={3} unit="%" />
                      </ConditionalWrapper>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            )
          } else {
            // return <InlineLoading title={evaluation.status} />
            return <>-</>
          }
        case 'evaluationStatus':
          return <StatusIcon status={evaluation.evaluationStatus} />
        // case 'comparisonType':
        //   const isCurrent = evaluation.label === 'CURRENT'
        //   return <Typography color={isCurrent ? 'primary' : 'secondary'}>{evaluation.label}</Typography>
        case 'creationDate':
          return evaluation?.created ? (
            <Highlight query={query} text={formatDate(evaluation?.created, null, DATE_FORMAT_DEFAULT)} />
          ) : (
            <></>
          )
        case 'activeFrom':
          return forecastModel?.activeFrom ? (
            <Highlight query={query} text={formatDate(forecastModel.activeFrom, null, DATE_FORMAT_DEFAULT_SHORT)} />
          ) : (
            <></>
          )
        case 'activationStatus':
          return forecastModel && <ActivationState asset={asset as Asset} forecastModel={forecastModel} />
        case 'weatherModel':
          return (
            <>
              <WeatherModelEnsembleBar weatherModels={evaluation.weatherModels} />
            </>
          )
        case 'correctionModel':
          return <>{correction}</>

        default:
          return <>{evaluation[column.name]}</>
      }
    },
    [qualityEvaluations, trainingJobs, query],
  )

  const showIcon = !sort.active && item?.uiChildren?.length > 0

  return (
    <StyledReTableRow hidden={item?.forecastModel?.hide} selected={isSelected} onClick={handleSelect}>
      <ModelTreeControlCell no_border={true}>
        {showIcon && (
          <Box ml={1}>
            <ReTableExpandToggle item={item as EnrichedForecastModelItem} />
          </Box>
        )}
      </ModelTreeControlCell>

      {columnsSelected.map((column) => {
        return <ReTableCell key={column.name}>{getEvaluationCell(item, column)}</ReTableCell>
      })}
    </StyledReTableRow>
  )
}

export default React.memo(QualityEvaluationRow)
