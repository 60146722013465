import React, { useEffect, useState } from 'react'
import { Box, Slider } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import PopperTooltip from 'ui/PopperTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import styled from 'styled-components'

const SliderName = styled.div`
  padding-right: 1em;
  min-width: 6em;
`

const SliderValue = styled.div`
  width: 7.4em;
  margin-left: 1em;
`

interface SliderElementProps {
  sliderName: string
  inputName: string
  sliderUnit?: string
  sliderInfo?: string
  sliderStep?: number
  minValue?: number
  maxValue?: number
  initialValue: number
  disabled?: boolean
  onChangeValue: (inputName: string, value: number) => void
}

const SliderElement: React.FC<SliderElementProps> = ({
  sliderName,
  inputName,
  sliderUnit,
  sliderInfo,
  initialValue = 0,
  sliderStep = 1,
  minValue = 0,
  maxValue = 100,
  disabled = false,
  onChangeValue,
}) => {
  const [value, setValue] = useState<number>(0)

  const handleChange = (value: Event, newValue: number) => {
    setValue(newValue as number)
    onChangeValue(inputName, newValue)
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <Flex direction="row" alignItems="center">
      <SliderName>{sliderName}</SliderName>
      <Slider
        name={inputName}
        aria-label={sliderName}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        step={sliderStep}
        min={minValue}
        max={maxValue}
      />
      <SliderValue>
        {value} {sliderUnit}
      </SliderValue>
      {sliderInfo && (
        <Box>
          <PopperTooltip
            popperLabel={
              <Box ml={0.3}>
                <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
              </Box>
            }
            popperContent={<div>{sliderInfo}</div>}
          />
        </Box>
      )}
    </Flex>
  )
}

export default SliderElement
