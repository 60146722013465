import React, { useCallback, useEffect } from 'react'
import { ReTableId, ReTableItem } from 'modules/reTable/reTable.types'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector, reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import { useDebounce } from 'use-debounce'

export const PAGINATION_ITEMS = 50

export interface PaginationIndex {
  start: number
  end: number
}

interface TablePaginationProps {
  totalItems: ReTableItem[]
  visibleItems: ReTableItem[]
  onPageChange: (paginationObj: PaginationIndex) => void
  tableId: ReTableId
}

const ReTablePagination: React.FC<TablePaginationProps> = ({ totalItems, visibleItems, onPageChange, tableId }) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, tableId)
  const sort = useReTableSelectorWithId(reTableSortSelector, tableId)
  const [debouncedQuery] = useDebounce(query, 200)

  const firstVisibleItem = visibleItems.length ? visibleItems[0] : undefined
  const lastVisibleItem = visibleItems.length ? visibleItems[visibleItems.length - 1] : undefined
  let firstVisibleItemIndexInTotalItems = 0
  let lastVisibleItemIndexInTotalItems = 0
  if (visibleItems?.length && totalItems.length) {
    firstVisibleItemIndexInTotalItems = totalItems.findIndex((item) => item.login === firstVisibleItem?.login)
    lastVisibleItemIndexInTotalItems = totalItems.findIndex((item) => item.login === lastVisibleItem?.login) + 1
  }

  const handleNextPrevItems = useCallback(
    (nextItems: boolean) => {
      const currentDisplayedItems = lastVisibleItemIndexInTotalItems - firstVisibleItemIndexInTotalItems

      const start =
        currentDisplayedItems !== PAGINATION_ITEMS
          ? totalItems.length - currentDisplayedItems - PAGINATION_ITEMS
          : nextItems
          ? lastVisibleItemIndexInTotalItems
          : firstVisibleItemIndexInTotalItems - PAGINATION_ITEMS

      const end = start + PAGINATION_ITEMS
      const paginationObject = {
        start: start < 0 ? 0 : start,
        end: end > totalItems.length ? totalItems.length : end,
      }

      onPageChange(paginationObject)
    },
    [lastVisibleItemIndexInTotalItems, firstVisibleItemIndexInTotalItems, totalItems],
  )

  useEffect(() => {
    if (sort || query) {
      onPageChange({ start: 0, end: PAGINATION_ITEMS })
    }
  }, [sort, debouncedQuery])

  return (
    <Box display="flex" alignItems="center" mr={1}>
      {firstVisibleItemIndexInTotalItems + 1}-{lastVisibleItemIndexInTotalItems} of {totalItems?.length}
      <Box>
        <IconButton
          onClick={() => handleNextPrevItems(false)}
          color="primary"
          disabled={firstVisibleItemIndexInTotalItems === 0}
        >
          <FontAwesomeIcon icon="chevron-left" />
        </IconButton>
        <IconButton
          onClick={() => handleNextPrevItems(true)}
          color="primary"
          disabled={lastVisibleItemIndexInTotalItems >= totalItems.length}
        >
          <FontAwesomeIcon icon="chevron-right" />
        </IconButton>
      </Box>
    </Box>
  )
}

export default React.memo(ReTablePagination)
