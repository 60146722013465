import React, { useCallback, useEffect, useMemo } from 'react'
import { t } from 'ttag'

import ButtonMenu from 'ui/form/ButtonMenu'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { ChartWidget } from 'modules/workspace/store/workspace.types'
import { useSelector } from 'react-redux'
import { workspaceDraftChartWidgetsSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import CheckmarkRadio from 'ui/form/CheckmarkRadio'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasAnyAuthority, isAdmin, isCurrentUserSubscribedTo, isImpersonatedAdmin, isIndianCustomer } from 'utils/user'
import { SubscribedProduct } from 'modules/auth/Auth.types'
import { getChartWidgetMenuItems } from 'utils/workspace'

export interface WidgetMenuProps {
  onChangeWidgets: (widgets: ChartWidget[]) => void
  hideLabel: boolean
}

const WidgetMenu: React.FC<WidgetMenuProps> = ({ onChangeWidgets, hideLabel, ...rest }) => {
  const user = useSelector(getUserResultSelector)
  const selectedChartWidgets = useSelector(workspaceDraftChartWidgetsSelector)
  const { WIDGET_META_FORECAST, WIDGET_QUALITY_TABLE, WIDGET_LINE_CHART } = ChartWidget

  const widgetOptions = useMemo(() => getChartWidgetMenuItems(user), [user])

  const handleChange = useCallback(
    () => (widgetItem: SelectAsListItem) => {
      if (widgetItem.key !== WIDGET_LINE_CHART) {
        const widgetKey = widgetItem.key as ChartWidget
        const chartWidgetAlreadySelected = selectedChartWidgets.includes(widgetKey)
        if (chartWidgetAlreadySelected) {
          if (widgetItem.key === WIDGET_QUALITY_TABLE && selectedChartWidgets.includes(WIDGET_META_FORECAST)) {
            onChangeWidgets(
              selectedChartWidgets.filter((widget) => widget !== widgetKey && widget !== WIDGET_META_FORECAST),
            )
          } else {
            // Un-select chart widget
            onChangeWidgets(selectedChartWidgets.filter((widget) => widget !== widgetKey))
          }
        } else {
          // Select chart widget
          const widgetsToSelect = [widgetKey]
          if (widgetKey === WIDGET_META_FORECAST) {
            const isQualityWidgetSelected = selectedChartWidgets.includes(WIDGET_QUALITY_TABLE)
            if (!isQualityWidgetSelected) {
              // Select quality widget if Meta forecast widget is selected
              widgetsToSelect.push(WIDGET_QUALITY_TABLE)
            }
          }
          onChangeWidgets(selectedChartWidgets.concat(widgetsToSelect))
        }
      }

      // Do not close the menu because its a multiselection menu
      // handleClose()
    },
    [selectedChartWidgets, onChangeWidgets],
  )

  const menuContent = useCallback(() => {
    const transformedWidgetOptions = widgetOptions.map((option) => {
      const transformedOption = { ...option }
      if (option.key === ChartWidget.WIDGET_META_FORECAST) {
        return {
          ...transformedOption,
          isAdminComponent:
            (isAdmin(user) || isImpersonatedAdmin(user)) && !hasAnyAuthority(user, ['ROLE_E3_METAFORECAST']),
        }
      }
      if (option.key === ChartWidget.WIDGET_PENALTY_TABLE) {
        return {
          ...transformedOption,
          isAdminComponent:
            (isAdmin(user) || isImpersonatedAdmin(user)) &&
            (!isIndianCustomer(user) ||
              !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING)),
        }
      }

      return transformedOption
    })

    return (
      <>
        <CheckmarkRadio
          options={transformedWidgetOptions}
          selected={selectedChartWidgets}
          onChange={handleChange()}
          disableItem={ChartWidget.WIDGET_LINE_CHART}
        />
      </>
    )
  }, [widgetOptions, selectedChartWidgets, handleChange])

  useEffect(() => {
    if (!selectedChartWidgets.includes(ChartWidget.WIDGET_LINE_CHART)) {
      onChangeWidgets([...selectedChartWidgets, ChartWidget.WIDGET_LINE_CHART])
    }
  }, [selectedChartWidgets, onChangeWidgets])

  return (
    <ButtonMenu
      // error={widgetMenuHasErrorItems ? 1 : 0}
      icon="chart-pie"
      title={t`Chart Widgets`}
      text={hideLabel ? '' : t`Chart Widgets`}
      color={'primary'}
      size="small"
      renderMenu={menuContent}
      {...rest}
    />
  )
}

export default React.memo(WidgetMenu)
