import { createStory } from 'test/utils'

// stories
export const storyCategory = 'Modules/Asset/Tree'
export const storyNarrow = createStory(storyCategory, 'narrow')
export const storyWide = createStory(storyCategory, 'wide')

// tests
const testIdPrefix = 'asset-tree'
export const testIdAssetTree = `${testIdPrefix}-root`
