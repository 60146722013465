import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Prompt } from 'react-router'
import { Location } from 'history'
import { useRouter } from 'utils/route'
import { useLocale } from 'ttag'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import {
  getReloadWindowQueryObj,
  QUERY_AUTH_FORM,
  QUERY_LEAVE_IMPERSONATE,
  QUERY_LOGOUT,
  QUERY_RELOAD_WINDOW,
  QUERY_STRING,
  QUERY_SWITCH_TO_CLASSIC,
  QUERY_USER_SETTINGS,
  stringifyQueryParams,
  useQueryMatch,
  useQueryString,
} from 'utils/query-string'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInitializedSelector, getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { GET_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'
import { tUnsavedChangesDialog } from 'fixtures/commonTranslations'
import { SessionActions, SessionStatus } from 'modules/app/app.types'
import { sessionStatusSelector } from 'modules/app/checkConnectivity.state'
import { getSavedLanguage } from 'utils/user'

export const blockCommonQueries: QUERY_STRING[] = [
  QUERY_LOGOUT,
  QUERY_LEAVE_IMPERSONATE,
  QUERY_SWITCH_TO_CLASSIC,
  QUERY_RELOAD_WINDOW,
]

export type AddNavigationBlockHandlerType = Record<
  string,
  {
    dialogText: React.ReactNode
    checkNavigationWillDestroyChanges: (
      currentLoc: Location,
      nextLoc: Location,
    ) => { isBlocking: boolean; isDestroying: boolean }
    abortNavigation: () => void
    proceedNavigation: () => void
  }
>

export interface NavigationBlockerHandlerTypes {
  addNavigationBlockHandler: (handler: AddNavigationBlockHandlerType) => void
  removeNavigationBlockHandler: (key: string) => void
}

export const UnsavedChangesContext = React.createContext<NavigationBlockerHandlerTypes>({})

interface BlockNavigationDialogProps {
  children: React.ReactNode
}

const BlockNavigationDialog: React.FC<BlockNavigationDialogProps> = ({ children }) => {
  const [navigationBlockHandlers, setNavigationBlockHandlers] = useState<Record<string, any>>({})
  const [blockingComponents, setBlockingComponents] = useState<string[]>([])
  const [newLocation, setNewLocation] = useState<Location>({} as Location)
  const sessionStatus = useSelector(sessionStatusSelector)

  const { onDeleteQueryStrings } = useQueryString()

  const isReloadWindow = useQueryMatch(QUERY_RELOAD_WINDOW)
  const user = useSelector(getUserResultSelector)

  const initialized = useSelector(getUserInitializedSelector)

  const dispatch = useDispatch()
  const unsavedDialogTranslations = tUnsavedChangesDialog()

  const [open, setOpen] = React.useState<boolean>()
  const { history, location } = useRouter()
  const [needsRefresh, setNeedsRefresh] = useState(false)

  const changeLocale = useCallback(() => {
    // set language
    if (user?.langKey && !blockingComponents.length) {
      const language = getSavedLanguage(user)
      useLocale(language)
      window.__localeId__ = language
      localStorage.setItem('language', language)
    }
  }, [user?.langKey, user?.originalUser?.langKey, blockingComponents])

  useEffect(() => {
    if (needsRefresh) {
      if (isReloadWindow) {
        onDeleteQueryStrings([QUERY_RELOAD_WINDOW])
      } else {
        dispatch({ type: SessionActions.INITIATE_RELOAD, initiateReload: true })
        window.location.reload()
      }
    }
  }, [needsRefresh, isReloadWindow, onDeleteQueryStrings])

  useEffect(() => {
    // If the query param has reload then we should reload irrespective of blocking components
    if (isReloadWindow) {
      setNeedsRefresh(true)
    }
  }, [isReloadWindow])

  const handleProceed = useCallback(() => {
    blockingComponents.forEach((view) => {
      const { proceedNavigation } = navigationBlockHandlers[view]
      proceedNavigation()
    })

    setTimeout(() => {
      history.push(`${newLocation.pathname}${newLocation.search}`)
      if (newLocation.search.includes(`${QUERY_RELOAD_WINDOW}=true`)) {
        setNeedsRefresh(true)
      }
    }, 0)

    setOpen(false)
  }, [blockingComponents, navigationBlockHandlers, newLocation])

  const handleCancel = useCallback(() => {
    blockingComponents.forEach((view) => {
      const { abortNavigation } = navigationBlockHandlers[view]
      abortNavigation()
    })
    setOpen(false)

    const language = getSavedLanguage(user)

    if (window.__localeId__ !== language) {
      onDeleteQueryStrings([QUERY_USER_SETTINGS])
    }

    // in case of an expired session we need to set the mode to covering so that we show the banner
    // (nothing happens if user is still authenticated)
    if (sessionStatus === SessionStatus.EXPIRED) {
      dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: true })
    }
  }, [blockingComponents, navigationBlockHandlers, sessionStatus, user, onDeleteQueryStrings])

  const blockNavigationHandlers: NavigationBlockerHandlerTypes = useMemo(() => {
    return {
      addNavigationBlockHandler: (blockHandler) => {
        setNavigationBlockHandlers((navigationBlockHandlers) => ({ ...navigationBlockHandlers, ...blockHandler }))
      },
      removeNavigationBlockHandler: (key) => {
        setNavigationBlockHandlers((navigationBlockHandlers) => {
          const handlers = { ...navigationBlockHandlers }
          delete handlers[key]
          return handlers
        })
      },
    }
  }, [])

  const handleBlockingNavigation = useCallback(
    (nextLocation: Location) => {
      const isReloadLocation = nextLocation.search.includes(stringifyQueryParams(getReloadWindowQueryObj()))
      // Should not block auth related urls when user session is expired
      const isAuthLocation = nextLocation.search.includes(QUERY_AUTH_FORM)
      if (!isAuthLocation && !isReloadWindow && !isReloadLocation && sessionStatus !== SessionStatus.AUTHED) {
        dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: false })
        return false
      } else {
        setNewLocation(nextLocation)
        const blockingComponents = Object.keys(navigationBlockHandlers).filter((handler) => {
          const { checkNavigationWillDestroyChanges } = navigationBlockHandlers[handler]
          const { isBlocking, isDestroying } = checkNavigationWillDestroyChanges(location, nextLocation)
          if (isBlocking) {
            return isDestroying
          } else {
            return false
          }
        })
        setBlockingComponents(blockingComponents)
        const openDialog = Boolean(blockingComponents.length)
        setOpen(openDialog)
        return Boolean(!blockingComponents.length)
      }
    },
    [location, navigationBlockHandlers, sessionStatus, isReloadWindow],
  )

  useEffect(() => {
    // initial language setter
    if (initialized) {
      changeLocale()
    }
  }, [initialized])

  useEffect(() => {
    dispatch({ type: GET_USER_REQUEST })
  }, [user?.login])

  const hasBlockingComponent = useMemo(
    () => blockingComponents.every((component) => Object.keys(navigationBlockHandlers).includes(component)),
    [navigationBlockHandlers, blockingComponents],
  )

  const getComponentText = useCallback(() => {
    return hasBlockingComponent ? (
      <span>
        {unsavedDialogTranslations.text1}
        <ul>
          {blockingComponents.map((component) => {
            return <li key={component}>{navigationBlockHandlers[component].dialogText}</li>
          })}
        </ul>
        {unsavedDialogTranslations.text2}
      </span>
    ) : (
      <></>
    )
  }, [navigationBlockHandlers, blockingComponents, hasBlockingComponent])

  return (
    <UnsavedChangesContext.Provider value={blockNavigationHandlers}>
      <Prompt when={true} message={handleBlockingNavigation} />
      {hasBlockingComponent && open && (
        <ConfirmationDialog
          heading={unsavedDialogTranslations.heading}
          text={getComponentText()}
          confirmAction={unsavedDialogTranslations.confirmAction}
          cancelAction={unsavedDialogTranslations.cancelAction}
          onConfirm={handleProceed}
          onCancel={handleCancel}
          openDialog={open}
        />
      )}
      <>{children}</>
    </UnsavedChangesContext.Provider>
  )
}

export default React.memo(BlockNavigationDialog)
