import { createStory } from 'test/utils'

// stories
export const storyCategory = 'Modules/LoginForm'
export const storyEmpty = createStory(storyCategory, 'empty')
export const storyPrefilled = createStory(storyCategory, 'prefilled')

// tests
const testIdPrefix = 'login-form'
export const testIdLoginForm = `${testIdPrefix}-root`
export const testIdInputUsername = `${testIdPrefix}-input-username`
export const testIdInputPassword = `${testIdPrefix}-input-password`
export const testIdButtonSubmit = `${testIdPrefix}-button-submit`
export const testIdError = `${testIdPrefix}-error`
export const testUsername = 'john'
