import React, { useCallback, useContext, useEffect } from 'react'
import Flex from 'ui/styles/Flex'
import { Box, Button } from '@material-ui/core'
import { c, t } from 'ttag'
import { Form } from 'react-final-form'
import PasswordStrengthBar from 'ui/form/PasswordStrengthBar'
import { FormRow } from 'modules/user/userSettings/UserSettings'
import { validatePasswordForm } from 'utils/formValidations'
import LoadingButton from 'ui/form/LoadingButton'
import { FormApi } from 'final-form'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGE_PASSWORD_REQUEST, CLEAR_CHANGE_PASSWORD_STATE } from 'modules/auth/redux_store/auth.action.types'
import {
  changePasswordErrorSelector,
  changePasswordIsSuccessSelector,
  changePasswordLoadingSelector,
} from 'modules/auth/redux_store/state/changePassword'
import PasswordField from 'ui/form/PasswordField'
import ErrorMessage from 'ui/form/ErrorMessage'
import LayoutTitle from 'ui/LayoutTitle'
import styled from 'styled-components'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import AuthHelpText from 'ui/elements/AuthHelpText'
import qs from 'query-string'
import { blockCommonQueries, UnsavedChangesContext } from 'ui/elements/BlockNavigationDialog'
import { QUERY_CHANGE_PASSWORD, QueryParamType, useQueryParams, useQueryString } from 'utils/query-string'
import { isCompanySubAccount, useIsReadOnlyUser } from 'utils/user'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.3rem;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
  }
`

const PasswordHelpText = styled.div`
  height: 3.4em;
`

let formReference: FormApi<ChangePasswordType>

export interface ChangePasswordType {
  newPassword: string
  confirmPassword: string
}

const ChangePasswordForm: React.FC = () => {
  const { addNavigationBlockHandler, removeNavigationBlockHandler } = useContext(UnsavedChangesContext)
  const user = useSelector(getUserResultSelector)

  const changePwdData: Partial<ChangePasswordType> = {}
  const dispatch = useDispatch()
  const { onDeleteQueryStrings } = useQueryString()
  const { queryParams } = useQueryParams()
  const isReadOnlyUser = useIsReadOnlyUser()

  const isLoading = useSelector(changePasswordLoadingSelector)
  const error = useSelector(changePasswordErrorSelector)
  const isPasswordChanged = useSelector(changePasswordIsSuccessSelector)
  const keys: (keyof ChangePasswordType)[] = ['newPassword', 'confirmPassword']

  const handleResetForm = useCallback(() => {
    keys.forEach((key) => {
      formReference.change(key, undefined)
      formReference.resetFieldState(key)
    })
  }, [Boolean(formReference)])

  const handleFormSubmit = useCallback((data: ChangePasswordType) => {
    dispatch({ type: CHANGE_PASSWORD_REQUEST, data: data })
  }, [])

  const handleCloseForm = useCallback(() => {
    onDeleteQueryStrings([QUERY_CHANGE_PASSWORD])
  }, [onDeleteQueryStrings])

  const proceedNavigation = useCallback(() => {
    handleResetForm()
    handleCloseForm()
  }, [handleResetForm, handleCloseForm])

  const abortNavigation = useCallback(() => {
    // console.log('handle cancel changes')
  }, [])

  // Block the navigation by checking next location's query params
  // If the next location has this component's query params do nothing else block navigation
  const checkNavigationWillDestroyChanges = useCallback(
    (currentLocation: Location, nextLocation: Location) => {
      const isBlocking = formReference?.getState().dirty && !isLoading
      let isDestroying = false
      if (isBlocking) {
        const nextParams = qs.parse(nextLocation.search) as QueryParamType
        isDestroying = Boolean(
          !nextParams[QUERY_CHANGE_PASSWORD] || blockCommonQueries.some((query) => nextParams[query]),
        )
      } else {
        return false
      }

      return {
        isBlocking,
        isDestroying,
      }
    },
    [formReference?.getState().dirty, isLoading],
  )

  useEffect(() => {
    if (isPasswordChanged) {
      handleCloseForm()
      onDeleteQueryStrings([QUERY_CHANGE_PASSWORD])
    }
  }, [isPasswordChanged, handleCloseForm, onDeleteQueryStrings])

  useEffect(() => {
    if (!queryParams[QUERY_CHANGE_PASSWORD]) {
      dispatch({ type: CLEAR_CHANGE_PASSWORD_STATE })
      handleResetForm()
    }
  }, [queryParams[QUERY_CHANGE_PASSWORD], handleResetForm])

  useEffect(() => {
    if (addNavigationBlockHandler && removeNavigationBlockHandler) {
      if (queryParams[QUERY_CHANGE_PASSWORD]) {
        addNavigationBlockHandler({
          changePassword: {
            dialogText: <>{t`Change password`}</>,
            checkNavigationWillDestroyChanges: checkNavigationWillDestroyChanges,
            abortNavigation: abortNavigation,
            proceedNavigation: proceedNavigation,
          },
        })
      } else {
        removeNavigationBlockHandler('changePassword')
      }
    }
  }, [
    location,
    queryParams[QUERY_CHANGE_PASSWORD],
    checkNavigationWillDestroyChanges,
    abortNavigation,
    proceedNavigation,
    addNavigationBlockHandler,
    removeNavigationBlockHandler,
  ])

  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between">
        <LayoutTitle>{t`Change password`}</LayoutTitle>
        <SliderCloseButton onCloseSlider={handleCloseForm} />
      </Flex>

      <Form
        onSubmit={handleFormSubmit}
        initialValues={changePwdData}
        validate={validatePasswordForm}
        render={({ handleSubmit, values, form, pristine, submitting }) => {
          formReference = form
          const activeField = form.getState().active

          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {/*<SuccessMessage successMsg={isPasswordChanged && !error ? t`Password changed successfully` : ''} />*/}

              <ErrorMessage errorMsg={!isLoading && !form.getState().modifiedSinceLastSubmit && error ? error : ''} />

              <Flex direction="column">
                <FormRow>
                  <PasswordField name="newPassword" label={t`New password`} />
                  <PasswordStrengthBar value={values.newPassword || ''} />
                </FormRow>

                <FormRow>
                  <PasswordField name="confirmPassword" label={t`Confirm new password`} />
                </FormRow>
                <PasswordHelpText>{activeField && <AuthHelpText activeField={activeField} />}</PasswordHelpText>
                <Flex direction="row" justifyContent="flex-end">
                  <Box mr={1}>
                    <Button
                      color="default"
                      size="small"
                      variant="contained"
                      onClick={handleCloseForm}
                      disabled={isReadOnlyUser && !isCompanySubAccount(user)}
                    >
                      {t`Cancel`}
                    </Button>
                  </Box>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    size="small"
                    variant="contained"
                    loading={isLoading}
                    disabled={isReadOnlyUser && !isCompanySubAccount(user) ? true : pristine || submitting}
                  >
                    {c('User Settings').t`Save`}
                  </LoadingButton>
                </Flex>
              </Flex>
            </form>
          )
        }}
      />
    </Container>
  )
}

export default React.memo(ChangePasswordForm)
