import {
  DeliveryTime,
  DeliveryTimesType,
  EvaluationDurationType,
  ExportFormat,
  ForecastConfig,
  IndiaExportFormat,
  OffsetType,
  QualityMeasurementTypes,
  SiteForecastFecsProcessing,
  SiteForecastHorizon,
  SiteForecastHorizonTemplateName,
  SiteForecastHorizonType,
  SiteForecastQualityConfig,
  SiteForecastUpdateTimes,
  SiteForecastUpdateTimesFrequency,
  SitesSelectionType,
} from 'modules/dataStreams/dataStreams.types'
import { User } from 'modules/auth/Auth.types'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import { isNumeric } from 'utils/dataFormatting'
import { getDefaultDeliveryTarget } from 'utils/delivery'
import { getFormattedTime, pad } from 'utils/date'
import { c, t } from 'ttag'
import { StartTime } from 'modules/dataStreams/siteForecast/SectionTimingPreview'
import { addDays, addMinutes, differenceInMilliseconds, isAfter, isBefore } from 'date-fns'
import { addMilliseconds } from 'date-fns/fp'
import { FormSaveOptions } from 'utils/form'
import { ReTableContextMenuItem, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { isAdmin } from 'utils/user'
import { ForecastType } from 'modules/reTable/ReTableForecastsManagement'
import { ForecastModelCategoryEnum } from 'modules/forecastModels/forecastModels.types'

export enum TemplateIds {
  NEW = 'newTemplate',
}
export const deliveryTemplateExtension = ' (Template)'

export const calcConfigTimeDuration = (
  config: SiteForecastQualityConfig,
  forecastLengthInMilliSeconds: number,
  configOffset: number,
) => {
  const { lengthDays, lengthMinutes, lengthHours } = config.horizon
  if (config.durationType === EvaluationDurationType.AUTOMATIC) {
    return forecastLengthInMilliSeconds - configOffset
  } else {
    return 1000 * 60 * ((lengthMinutes || 0) + 60 * ((lengthHours || 0) + 24 * (lengthDays || 0)))
  }
}

export const getTimeLabel = (time: DeliveryTime) => {
  return `${time.name ? time.name + ' - ' : ''}${time.hours}:${time.minutes}`
}

export const createTimeObj = (hrs: string, minutes: string, name = '') => {
  return {
    hours: ('00' + (hrs || '00')).slice(-2),
    minutes: ('00' + (minutes || '00')).slice(-2),
    name: name,
  }
}

export const getTimes = (qualityConfig: SiteForecastQualityConfig) => {
  const deliveryTimes = qualityConfig.deliveryTimes.filter((deliveryTime) => {
    return qualityConfig.deliveryTimesType === 'AUTOMATIC' || deliveryTime
  })
  const prettyTimes = deliveryTimes.map(function (time) {
    return getFormattedTime(time)
  })
  return prettyTimes
}

export const getQualityConfigName = (config: SiteForecastQualityConfig, index: number) => {
  const { AUTOMATIC } = DeliveryTimesType
  const q = 'Q' + (index + 1)
  const qualityConfig = { ...config }
  const times = getTimes(qualityConfig)
  const padOffsetHours = pad(qualityConfig.horizon.offsetHours)
  const padOffsetMinutes = pad(qualityConfig.horizon.offsetMinutes)

  const primaryMeasurement = qualityConfig.primaryMeasurement
    ? `${qualityConfig.primaryMeasurement} ${qualityConfig.errorMeasurements.length > 1 ? '+' : ''}`
    : '-'

  const offset =
    (qualityConfig.horizon.offsetDays > 0 ? `${qualityConfig.horizon.offsetDays}d ` : '') +
    `${padOffsetHours}:${padOffsetMinutes}`

  const deliveryTime =
    qualityConfig.deliveryTimesType === AUTOMATIC
      ? 'all'
      : times.length > 0
      ? `${times[0]} ${times.length > 1 ? '+' : ''}`
      : '-'

  qualityConfig.autoName = `${q} ${primaryMeasurement} ${offset} ${deliveryTime}`
  return qualityConfig.name ? qualityConfig.name : qualityConfig.autoName
}

export const getExportFormatConfigurationText = (key: string) => {
  const formatConfigText = {
    wind: c('Site Forecast').t`Wind`,
    pv: c('Site Forecast').t`Solar`,
    all: c('Site Forecast').t`Choose all types`,
    CSV_COMMA_CLUSTER_LEVEL: c('Site Forecast').t`CSV forecast per cluster (decimal separator .)`,
    CSV_SEMICOLON_CLUSTER_LEVEL: c('Site Forecast').t`CSV forecast per cluster (decimal separator ,)`,
    CSV_COMMA_SITE_LEVEL: c('Site Forecast').t`CSV forecast per site (decimal separator .)`,
    CSV_SEMICOLON_SITE_LEVEL: c('Site Forecast').t`CSV forecast per site (decimal separator ,)`,
    CSV_INDIA_STANDARD_KW: c('Site Forecast').t`CSV India Standard, power unit kW`,
    CSV_INDIA_STANDARD_MW: c('Site Forecast').t`CSV India Standard, power unit MW`,
    CSV_INDIA_STANDARD_V2_KW: c('Site Forecast').t`CSV India Standard with availabilities, power unit kW`,
    CSV_INDIA_STANDARD_V2_MW: c('Site Forecast').t`CSV India Standard with availabilities, power unit MW`,
    NONE: c('Site Forecast').t`Remove format`,
    enercast_Default: c('Site Forecast').t`Default format defined by enercast.`,
    ALL: c('Site Forecast').t`all`,
  }

  return key ? formatConfigText[key] : ''
}

export const getSiteForecastHorizons: () => SiteForecastHorizon[] = () => {
  const { INTRADAY, DAYAHEAD, CUSTOM } = SiteForecastHorizonTemplateName
  const { TIME_RELATIVE, DAY_RELATIVE } = SiteForecastHorizonType
  return [
    {
      templateName: INTRADAY,
      type: TIME_RELATIVE,
      offsetDays: 0,
      offsetHours: 0,
      offsetMinutes: 0,
      lengthHours: 24,
      modifiable: false,
      timeZone: 'Europe/Berlin',
      untilEndOfDay: false,
    },
    {
      templateName: DAYAHEAD,
      type: DAY_RELATIVE,
      offsetDays: 1,
      offsetHours: 0,
      offsetMinutes: 0,
      lengthHours: 24,
      modifiable: false,
      timeZone: 'Europe/Berlin',
      untilEndOfDay: false,
    },
    {
      templateName: CUSTOM,
      type: TIME_RELATIVE,
      offsetDays: 1,
      offsetHours: 0,
      offsetMinutes: 0,
      lengthHours: 168,
      modifiable: true,
      timeZone: 'Europe/Berlin',
      untilEndOfDay: false,
    },
  ]
}

export const getSiteForecastFrequencies: () => SiteForecastUpdateTimes[] = () => {
  const { QUARTER_HOURLY, HOURLY, INDIVIDUAL } = SiteForecastUpdateTimesFrequency
  return [
    {
      name: 'ecui.product_frequenz.quarter_hourly',
      frequency: QUARTER_HOURLY,
      individualTimes: [],
      redeliveryMinutes: 0,
      offsetMinutes: 0,
      earliestAttemptOfDelivery: -15,
      latestAttemptOfDelivery: 0,
      timeZone: 'Europe/Berlin',
    },
    {
      name: 'ecui.product_frequenz.hourly',
      frequency: HOURLY,
      individualTimes: [],
      redeliveryMinutes: 0,
      offsetMinutes: 0,
      earliestAttemptOfDelivery: -15,
      latestAttemptOfDelivery: 0,
      timeZone: 'Europe/Berlin',
    },
    {
      name: 'ecui.product_frequenz.individual',
      frequency: INDIVIDUAL,
      individualTimes: [],
      redeliveryMinutes: 0,
      offsetMinutes: 0,
      earliestAttemptOfDelivery: -15,
      latestAttemptOfDelivery: 0,
      timeZone: 'Europe/Berlin',
    },
  ]
}

export const getDeprecatedExportFormats = () => {
  const {
    NONE,
    CSV_COMMA_CLUSTER_LEVEL,
    CSV_COMMA_SITE_LEVEL,
    CSV_SEMICOLON_CLUSTER_LEVEL,
    CSV_SEMICOLON_SITE_LEVEL,
  } = ExportFormat
  return [
    {
      name: NONE,
      label: getExportFormatConfigurationText(NONE),
    },
    {
      name: CSV_COMMA_CLUSTER_LEVEL,
      label: getExportFormatConfigurationText(CSV_COMMA_CLUSTER_LEVEL),
    },
    {
      name: CSV_SEMICOLON_CLUSTER_LEVEL,
      label: getExportFormatConfigurationText(CSV_SEMICOLON_CLUSTER_LEVEL),
    },
    {
      name: CSV_COMMA_SITE_LEVEL,
      label: getExportFormatConfigurationText(CSV_COMMA_SITE_LEVEL),
    },
    {
      name: CSV_SEMICOLON_SITE_LEVEL,
      label: getExportFormatConfigurationText(CSV_SEMICOLON_SITE_LEVEL),
    },
  ]
}

export const getIndiaExportFormats = () => {
  const {
    CSV_INDIA_STANDARD_KW,
    CSV_INDIA_STANDARD_MW,
    CSV_INDIA_STANDARD_V2_KW,
    CSV_INDIA_STANDARD_V2_MW,
  } = IndiaExportFormat
  return [
    {
      name: CSV_INDIA_STANDARD_KW,
      label: getExportFormatConfigurationText(CSV_INDIA_STANDARD_KW),
    },
    {
      name: CSV_INDIA_STANDARD_MW,
      label: getExportFormatConfigurationText(CSV_INDIA_STANDARD_MW),
    },
    {
      name: CSV_INDIA_STANDARD_V2_KW,
      label: getExportFormatConfigurationText(CSV_INDIA_STANDARD_V2_KW),
    },
    {
      name: CSV_INDIA_STANDARD_V2_MW,
      label: getExportFormatConfigurationText(CSV_INDIA_STANDARD_V2_MW),
    },
  ]
}

export const siteForecastMasterData = (): Partial<ForecastConfig> => {
  const horizons = getSiteForecastHorizons()
  const frequencies = getSiteForecastFrequencies()
  return {
    horizon: horizons[0],
    products: [], // TODO can we remove this? check if template has this field
    features: [],
    aggregationLevel: [],
    sites: [],
    deliveryEndpoints: [],
    qualityConfigs: [],
    updateTimes: frequencies[0],
  }
}

export const getNewQualityConfigData = () => {
  const { NRMSE, NMAE, ABSOLUTE_DEVIATION, NBIAS, RMSE, MAE } = QualityMeasurementTypes
  const { AUTOMATIC } = EvaluationDurationType
  return {
    errorMeasurements: [NRMSE, NMAE, ABSOLUTE_DEVIATION, NBIAS, RMSE, MAE],
    primaryMeasurement: NRMSE,
    deliveryTimesType: 'AUTOMATIC',
    offsetType: 'START_OF_DELIVERY',
    deliveryTimes: [],
    durationType: AUTOMATIC,
    horizon: {
      offsetDays: 0,
      offsetHours: 0,
      offsetMinutes: 0,
      lengthDays: null,
      lengthHours: null,
      lengthMinutes: null,
    },
  }
}

export const getSiteForecastQualityMeasurements = () => {
  const { NRMSE, NMAE, ABSOLUTE_DEVIATION, NBIAS, RMSE, MAE } = QualityMeasurementTypes
  return [
    {
      name: NRMSE,
      label: 'NRMSE',
    },
    {
      name: NMAE,
      label: 'NMAE',
    },
    {
      name: ABSOLUTE_DEVIATION,
      label: 'ABSOLUTE DEVIATION',
    },
    {
      name: NBIAS,
      label: 'NBIAS',
    },
    {
      name: RMSE,
      label: 'RMSE',
    },
    {
      name: MAE,
      label: 'MAE',
    },
  ]
}

export const transformSiteForecastDataForForm = (
  forecastData: ForecastConfig,
  newSiteForecast = false,
  isTemplate = false,
  user: User,
  isCopy?: boolean,
  deliveryFormats?: DeliveryFormat[],
): ForecastConfig => {
  let transformedData = { ...forecastData }
  const { AUTOMATIC, CUSTOM } = DeliveryTimesType
  const { ALL_SITES, SELECTED_SITES } = SitesSelectionType
  const measurements = getSiteForecastQualityMeasurements()
  const exportFormats = getDeprecatedExportFormats()

  if (newSiteForecast) {
    const masterData = siteForecastMasterData()
    transformedData = { ...masterData, ...transformedData }
    transformedData.id = null
    transformedData.horizon.templateName = transformedData.name
  }

  if (isTemplate && !transformedData.exportFormatConfiguration) {
    transformedData.exportFormatConfiguration = exportFormats[0].name
  }

  if (isTemplate && isCopy) {
    if (transformedData.id) delete transformedData.id
    delete transformedData.deleted
    transformedData.name = transformedData.name + ' (Copy)'
    transformedData.horizon.templateName = transformedData.horizon.templateName + ' (Copy)'
  }

  if (Array.isArray(transformedData.sites) && transformedData.sites?.length > 0) {
    transformedData.sites = transformedData.sites.filter(
      (selectedId, index, list) => list.findIndex((id) => id === selectedId) === index,
    )
  }

  if (!transformedData.exportFormatConfigId && deliveryFormats && deliveryFormats.length) {
    if (isTemplate && !transformedData.id) {
      // 'enercast standard' is one of the delivery templates coming from backend and its a standard one
      // We update Delivery templates labels by adding an extension in the UI
      // while assigning a config we need to remove the extension as we might have duplicates
      const exportFormatConfig = (deliveryFormats || []).find(
        (config) => config.name.replace(deliveryTemplateExtension, '') === 'enercast Standard',
      )
      if (exportFormatConfig) {
        transformedData.exportFormatConfigId = exportFormatConfig.id
      }
    } else {
      transformedData.exportFormatConfigId = deliveryFormats[0].id
    }
  }

  // split hours into days and hours for proper form handling
  const totalForecastLengthHours = transformedData.horizon?.lengthHours || 0
  const days = totalForecastLengthHours ? Math.floor(totalForecastLengthHours / 24) : 0
  const hours = totalForecastLengthHours ? totalForecastLengthHours - days * 24 : 0

  // We should add this for evaluation section
  if (transformedData.qualityConfigs) {
    const hasPrimaryConfig = transformedData.qualityConfigs.some((config) => config.primary)

    transformedData.qualityConfigs = transformedData?.qualityConfigs.map((config, index) => {
      const copiedConfig = { ...config }

      // Generate auto name
      if (!copiedConfig.autoName) {
        copiedConfig.autoName = getQualityConfigName(copiedConfig, index)
      }

      // set primary quality config if it's not set
      if (!hasPrimaryConfig && index === 0) {
        copiedConfig.primary = true
      }

      // error measurements
      copiedConfig.errorMeasurements = measurements.map((m) => m.name)
      // set primary error measurement
      if (!copiedConfig.primaryMeasurement && copiedConfig.errorMeasurements.length) {
        copiedConfig.primaryMeasurement = copiedConfig.errorMeasurements[0]
      }

      const isAutomatic =
        copiedConfig.horizon.lengthDays == null &&
        copiedConfig.horizon.lengthHours == null &&
        copiedConfig.horizon.lengthMinutes == null
      copiedConfig.durationType = isAutomatic ? AUTOMATIC : CUSTOM

      copiedConfig.horizon = {
        ...copiedConfig.horizon,
        lengthDays: typeof copiedConfig.horizon.lengthDays === 'number' ? copiedConfig.horizon.lengthDays : 1,
        lengthHours: typeof copiedConfig.horizon.lengthHours === 'number' ? copiedConfig.horizon.lengthHours : 0,
        lengthMinutes: typeof copiedConfig.horizon.lengthMinutes === 'number' ? copiedConfig.horizon.lengthMinutes : 0,
      }
      return copiedConfig
    })
  }

  if (transformedData.updateTimes) {
    const copiedUpdateTimes = { ...transformedData.updateTimes }
    copiedUpdateTimes.latestAttemptOfDelivery = isNumeric(copiedUpdateTimes.latestAttemptOfDelivery)
      ? copiedUpdateTimes.latestAttemptOfDelivery
      : 0
    copiedUpdateTimes.latestAttemptOfDeliveryOffsetMinutes =
      transformedData.updateTimes.latestAttemptOfDeliveryOffsetMinutes || null

    copiedUpdateTimes.earliestAttemptOfDelivery = isNumeric(copiedUpdateTimes.earliestAttemptOfDelivery)
      ? copiedUpdateTimes.earliestAttemptOfDelivery
      : -15
    copiedUpdateTimes.earliestAttemptOfDeliveryOffsetMinutes =
      transformedData.updateTimes.earliestAttemptOfDeliveryOffsetMinutes || null

    transformedData.updateTimes = copiedUpdateTimes
  }

  // add ui related data
  const uiData = {
    // // if saved as custom config, it cannot be switched back to normal and this should be set when details are saved and loaded from the backend
    // // this transformer is called when the custom config is uploaded but not yet saved, custom config files does not have id
    // customUnRemovable: transformedData.id ? transformedData.customConfig : false,
    forecastLength: {
      days: days,
      hours: hours,
    },
    sitesSelectionType: transformedData?.sites?.length ? SELECTED_SITES : ALL_SITES,
    timeZone: transformedData?.horizon?.timeZone || transformedData?.updateTimes?.timeZone || user?.timezone,
    isTemplate: isTemplate,
    deliveryFrequencyChanged: false,
    disableFecsRealTime: transformedData?.fecsForecastInstructions
      ? transformedData.fecsForecastInstructions.includes(SiteForecastFecsProcessing.DISABLE_REALTIME_PROCESSING)
      : false,
    disableFecsPostProcessing: transformedData?.fecsForecastInstructions
      ? transformedData.fecsForecastInstructions.includes(SiteForecastFecsProcessing.DISABLE_POST_PROCESSING)
      : false,
  }

  return { ...transformedData, ui: uiData }
}

export const transformSiteForecastDataBeforeSubmit = (forecastData: ForecastConfig, isTemplate = false) => {
  const transformedData = { ...forecastData }
  const defaultDeliveryTarget = getDefaultDeliveryTarget()
  const { DISABLE_REALTIME_PROCESSING, DISABLE_POST_PROCESSING } = SiteForecastFecsProcessing

  // although horizon.lengthHours is already handled in <SectionForecast>
  // we calculate it here again, just to be safe
  const forecastLengthDays = forecastData?.ui?.forecastLength?.days
  const forecastLengthHours = forecastData?.ui?.forecastLength?.hours
  const lengthHours = (forecastLengthDays || 0) * 24 + (forecastLengthHours || 0)
  transformedData.horizon = { ...transformedData.horizon, lengthHours }

  if (transformedData?.deliveryEndpoints?.length) {
    transformedData.deliveryEndpoints = transformedData.deliveryEndpoints.filter(
      (endPointId) => endPointId != defaultDeliveryTarget.id,
    )
  }

  if (isTemplate && forecastData?.exportFormatConfiguration === ExportFormat.NONE) {
    transformedData.exportFormatConfiguration = null
  }

  if (transformedData.id === 'default') {
    transformedData.exportFormatConfigId = null
  }

  if (!transformedData?.customConfig && transformedData?.ui?.timeZone) {
    transformedData.horizon.timeZone = transformedData.ui.timeZone
    transformedData.updateTimes.timeZone = transformedData.ui.timeZone
  }

  // fecs forecast instructions
  let fecsForecastInstructions = [DISABLE_REALTIME_PROCESSING, DISABLE_POST_PROCESSING]
  if (!transformedData?.ui?.disableFecsRealTime) {
    fecsForecastInstructions = fecsForecastInstructions.filter((i) => i !== DISABLE_REALTIME_PROCESSING)
  }
  if (!transformedData?.ui?.disableFecsPostProcessing) {
    fecsForecastInstructions = fecsForecastInstructions.filter((i) => i !== DISABLE_POST_PROCESSING)
  }
  transformedData.fecsForecastInstructions = fecsForecastInstructions.join(',')

  if (
    transformedData?.ui?.disableFecsPostProcessing &&
    !transformedData?.fecsForecastInstructions.includes(SiteForecastFecsProcessing.DISABLE_REALTIME_PROCESSING)
  ) {
    transformedData.fecsForecastInstructions = SiteForecastFecsProcessing.DISABLE_POST_PROCESSING
  }

  if (transformedData.ui?.sitesSelectionType === SitesSelectionType.ALL_SITES) {
    // make sure that no assets are saved when "All sites" is chosen
    // this is because the radio value is derived implicitly and not by a separate boolean
    transformedData.sites = []
  }

  if (transformedData.qualityConfigs) {
    transformedData.qualityConfigs = transformedData?.qualityConfigs.map((config) => {
      const copiedConfig = { ...config }
      if (copiedConfig.durationType === EvaluationDurationType.AUTOMATIC) {
        copiedConfig.horizon.lengthDays = null
        copiedConfig.horizon.lengthHours = null
        copiedConfig.horizon.lengthMinutes = null
      }
      if (!copiedConfig.penaltyNotificationEnabled) {
        copiedConfig.penaltyNotificationThreshold = null
      }

      return copiedConfig
    })
  }

  // remove ui related data
  delete transformedData.ui

  return transformedData
}

interface EvaluationTimesWithoutOverlapProps {
  config: SiteForecastQualityConfig
  configIndex: number
  forecastLengthInMilliSeconds: number
  forecastDeliveries: StartTime[]
  forecastHorizonUntilEndOfDay: boolean | undefined
  limitEvaluationTimes?: number
}
export const getEvaluationTimesWithoutOverlapForChart = ({
  config,
  configIndex,
  forecastLengthInMilliSeconds,
  forecastDeliveries,
  forecastHorizonUntilEndOfDay,
  limitEvaluationTimes,
}: EvaluationTimesWithoutOverlapProps) => {
  const { START_OF_DAY } = OffsetType
  const configOffset =
    1000 * 60 * (config.horizon.offsetMinutes + 60 * (config.horizon.offsetHours + 24 * config.horizon.offsetDays))

  const configStartTimes: StartTime[] = []

  config.deliveryTimes.forEach((dt) => {
    const t = addMinutes(new Date(0), parseInt(dt.hours) * 60 + parseInt(dt.minutes))
    const s = addMilliseconds(config.offsetType === START_OF_DAY ? new Date(0) : t, configOffset)
    const d = calcConfigTimeDuration(config, forecastLengthInMilliSeconds, configOffset)
    configStartTimes.push({ delivery: addMinutes(t, (configIndex + 1) * 0), start: s, duration: d })
  })

  // When quality config does not use fixed length and forecast deliveries are extended until of end of the day
  if (config.durationType === EvaluationDurationType.AUTOMATIC && forecastHorizonUntilEndOfDay) {
    configStartTimes.forEach((cst) => {
      const matchFD = forecastDeliveries.find((fd) => {
        return fd.delivery.getTime() === cst?.delivery.getTime()
      })

      if (matchFD) {
        const matchFDEndDate = addMilliseconds(matchFD.start, matchFD?.duration)
        cst['duration'] = differenceInMilliseconds(matchFDEndDate, cst?.start)
      }
    })
  }

  const sortedConfigTimes = configStartTimes.sort((a, b) => {
    const timeA = new Date(a.delivery)
    const timeB = new Date(b.delivery)
    return isBefore(timeA, timeB) ? -1 : 1
  })

  const configTimes = sortedConfigTimes.slice(0, limitEvaluationTimes ? limitEvaluationTimes : sortedConfigTimes.length)
  // Avoid overlapping of all evaluation times except the last one in the timing overview chart
  let timesWithoutOverlap: StartTime[] = []
  if (configTimes.length) {
    const firstConfig = configTimes[0]
    timesWithoutOverlap = configTimes.map((currentConfigTime, index) => {
      const lastConfigTimeIndex = configTimes.length - 1
      const currentConfigStartDate = currentConfigTime.start
      const currentConfigEndDate = addMilliseconds(currentConfigStartDate, currentConfigTime.duration)
      const nextConfig = configTimes[index + 1]
        ? configTimes[index + 1]
        : // This option is to remove overlapping the last delivery of the day with the next day first delivery
          {
            ...firstConfig,
            delivery: addDays(firstConfig.delivery, 1),
            start: addDays(firstConfig.start, 1),
          }
      let transformedConfigTime = { ...currentConfigTime }

      if (limitEvaluationTimes && lastConfigTimeIndex === index) {
        // Do not remove overlapping time for the last delivery when we have limit
        // because we want to show the full length in the recent deliveries section of the chart
      } else if (nextConfig?.start && isAfter(currentConfigEndDate, nextConfig.start)) {
        const duration = differenceInMilliseconds(nextConfig.start, currentConfigStartDate)
        transformedConfigTime = { ...transformedConfigTime, duration }
      }
      return transformedConfigTime
    })
  }

  return timesWithoutOverlap
}

export const getAddSiteForecastFromTableSaveMenuItems = () => {
  return [
    {
      key: FormSaveOptions.SAVE,
      label: t`Use template`,
    },
    {
      key: FormSaveOptions.EDIT_AND_SAVE,
      label: t`Customize`,
    },
  ]
}

export const createSeriesForTimingOverviewChart = (
  st: StartTime,
  lineWidth: number,
  shadowWidth: number,
  color: string,
  name = '',
  qualityConfigIndex?: number,
) => {
  return {
    data: [
      [st.start.getTime(), st.delivery.getTime()],
      [addMilliseconds(st.start, st.duration).getTime(), st.delivery.getTime()],
    ],
    color: color,
    lineWidth: lineWidth,
    name: name,
    shadow: {
      color: color,
      width: shadowWidth,
      offsetX: 0,
      offsetY: 0,
    },
    qualityConfigIndex: qualityConfigIndex,
  }
}

export const getSiteForecastListContextMenuItems = (): ReTableContextMenuItem[] => {
  const { EDIT_ROW, COPY_ROW, DELETE_ROW, SAVE_TEMPLATE_AS_FILE_MENU_ITEM } = ReTableRowContextActions
  return [
    {
      itemName: COPY_ROW,
      itemLabel: c('Site Forecast').t`Copy`,
      icon: 'copy',
      userHasPermission: (user: User | null) => {
        return isAdmin(user)
      },
    },
    {
      itemName: EDIT_ROW,
      itemLabel: c('Site Forecast').t`Edit`,
      icon: 'pen',
      userHasPermission: (user: User | null) => {
        return isAdmin(user)
      },
    },
    {
      itemName: SAVE_TEMPLATE_AS_FILE_MENU_ITEM,
      itemLabel: c('Site Forecast').t`Save as file`,
      icon: 'file-download',
      userHasPermission: (user: User | null) => {
        return isAdmin(user)
      },
    },
    {
      itemName: DELETE_ROW,
      itemLabel: c('Site Forecast').t`Delete`,
      icon: 'trash-alt',
      userHasPermission: (user: User | null) => {
        return isAdmin(user)
      },
    },
  ]
}

export const getSiteForecastFrequencyTypeText = (frequency: SiteForecastUpdateTimesFrequency) => {
  const { QUARTER_HOURLY, HOURLY, INDIVIDUAL } = SiteForecastUpdateTimesFrequency
  const frequencyTypeText = {
    [QUARTER_HOURLY]: c('Site Forecast').t`Quarter-hourly`,
    [HOURLY]: c('Site Forecast').t`Hourly`,
    [INDIVIDUAL]: c('Site Forecast').t`Specific times`,
  }
  return frequencyTypeText[frequency]
}

export const getSiteForecastHorizonTypeText = (type: SiteForecastHorizonType) => {
  const { TIME_RELATIVE, DAY_RELATIVE } = SiteForecastHorizonType
  const horizonTypeText = {
    [TIME_RELATIVE]: c('Site Forecast').t`Time of delivery`,
    [DAY_RELATIVE]: c('Site Forecast').t`Start of the day of delivery`,
  }
  return horizonTypeText[type]
}

export const createNameSuggestion = (config: ForecastConfig, configNames: string[]) => {
  let name = config.horizon.templateName
  while (configNames.indexOf(name) != -1) {
    if (isNaN(parseInt(name.charAt(name.length - 1), 10))) {
      name = name + '_1'
      continue
    }
    name = name.replace(/\d+$/, (n) => ++n)
  }
  return name
}

export const getSiteForecastUpdateTimesFrequency = () => {
  const { QUARTER_HOURLY, HOURLY, INDIVIDUAL } = SiteForecastUpdateTimesFrequency
  return [
    {
      name: QUARTER_HOURLY,
      label: t`Quarter-hourly`,
    },
    {
      name: HOURLY,
      label: t`Hourly`,
    },
    {
      name: INDIVIDUAL,
      label: t`Custom settings`,
    },
  ]
}

export const getIndividualTimes = (updateTimesObj: SiteForecastUpdateTimes) => {
  const { QUARTER_HOURLY, HOURLY, INDIVIDUAL } = SiteForecastUpdateTimesFrequency
  const frequency = updateTimesObj.frequency

  const times = []
  if (frequency === INDIVIDUAL) {
    updateTimesObj.individualTimes.forEach((time) => {
      times.push(createTimeObj(time.hours, time.minutes, time.name))
    })
  } else if (frequency === HOURLY) {
    for (let i = 0; i < 24; i++) {
      times.push(createTimeObj(i, 0 + updateTimesObj.offsetMinutes))
    }
  } else if (frequency === QUARTER_HOURLY) {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        times.push(createTimeObj(i, j + updateTimesObj.offsetMinutes))
      }
    }
  }

  const sortedTimes = times.sort((a, b) => (`${a.hours}${a.minutes}` < `${b.hours}${b.minutes}` ? -1 : 1))

  return sortedTimes
}

export const saveSiteForecastAsJSON = (item: ForecastType) => {
  const config = { ...item }

  if (config.id) delete config.id
  delete config.deleted
  delete config.customConfig
  const fileName = `${config.name || 'enercastProductConfig'}.productConfigTemplate`
  const data = JSON.stringify(config, undefined, 2)

  const blob = new Blob([data], { type: 'text/json' })
  const e = document.createEvent('MouseEvents')
  const a = document.createElement('a')

  a.download = fileName
  a.href = window.URL.createObjectURL(blob)
  a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
  e.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
  a.dispatchEvent(e)
}

export const staticForecastModelCategory = () => {
  return ['E3', 'META']
}

// Currently we are supporting only e3 and meta as selection
export const labelsForForecastModelCategory = (key: string) => {
  switch (key) {
    case ForecastModelCategoryEnum.E3:
      return t`e³ Forecast`
    case ForecastModelCategoryEnum.META:
      return t`Meta Forecast`
    default:
      return ''
  }
}
