import React, { useCallback, useEffect, useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import { t } from 'ttag'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { convertZonedTimeToUtc, isDateValid, isSameDate } from 'utils/date'
import {
  BackCastRangeType,
  ChartDataRangeType,
  MeterDataCleansingRangeType,
} from 'modules/workspace/store/workspace.types'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import DateAndTimePicker from 'ui/elements/DateAndTimePicker'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'
import { Timezone } from 'fixtures/timezones'
import { MDCTimePeriod } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { Box } from '@material-ui/core'
import { isBefore } from 'date-fns'

const DatesContainer = styled(Flex)`
  *.MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
    line-height: 1;
  }
  width: 100%;
`

const InputFieldContainer = styled(Flex)`
  & .MuiFormControl-root {
    width: 90%;
  }

  & .MuiInput-formControl {
    width: 100%;
  }

  & .MuiIconButton-root {
    padding: 0;
  }

  flex: 1 1 0;
`

const SelectFieldContainer = styled(Flex)`
  & .MuiFormControl-root {
    width: 90%;
  }

  & .MuiInput-formControl {
    width: 100%;
  }

  flex: 1 1 0;
`

export interface StaticRangeOptions {
  key: BackCastRangeType | ChartDataRangeType | MeterDataCleansingRangeType
  label: string
}

interface TimePeriodWithPreSetsProps {
  staticRangeOptions: StaticRangeOptions[]
  initialTimePeriod: MDCTimePeriod
  fullTimePeriod: Date[]
  onChangeTimePeriod: (period: MDCTimePeriod) => void
  userTimezone: Timezone
  disabled: boolean
}

const TimePeriodWithPreSet: React.FC<TimePeriodWithPreSetsProps> = ({
  staticRangeOptions,
  initialTimePeriod,
  fullTimePeriod,
  onChangeTimePeriod,
  userTimezone,
  disabled,
}) => {
  const [timePeriod, setTimePeriod] = useState<MDCTimePeriod>(initialTimePeriod)
  const [staticPeriod, setStaticPeriod] = useState<MeterDataCleansingRangeType>(
    MeterDataCleansingRangeType.CHART_DATA_RANGE_CUSTOM,
  )

  const startDate = timePeriod?.start.date || initialTimePeriod?.start.date || null
  const startDateTimezone = timePeriod?.start.timezone || initialTimePeriod?.start.timezone || null

  const endDate = timePeriod?.end.date || initialTimePeriod?.end.date || null
  const endDateTimezone = timePeriod?.end.timezone || initialTimePeriod?.end.timezone || null

  const startDateInUTC = isDateValid(startDate) ? convertZonedTimeToUtc(startDate, userTimezone) : null
  const endDateInUTC = isDateValid(endDate) ? convertZonedTimeToUtc(endDate, userTimezone) : null

  useEffect(() => {
    setTimePeriod(initialTimePeriod)
  }, [initialTimePeriod])

  const handleChangeStartDate = useCallback(
    (value: Date) => {
      const data = { ...timePeriod, start: { date: value, timezone: userTimezone } }
      setTimePeriod(data)
      onChangeTimePeriod(data)
      setStaticPeriod(MeterDataCleansingRangeType.CHART_DATA_RANGE_CUSTOM)
    },
    [timePeriod, onChangeTimePeriod, userTimezone],
  )

  const handleChangeEndDate = useCallback(
    (value: Date) => {
      const data = { ...timePeriod, end: { date: value, timezone: userTimezone } }
      setTimePeriod(data)
      onChangeTimePeriod(data)
      setStaticPeriod(MeterDataCleansingRangeType.CHART_DATA_RANGE_CUSTOM)
    },
    [timePeriod, onChangeTimePeriod, userTimezone],
  )

  useEffect(() => {
    if (timePeriod) {
      if (
        isSameDate(new Date(fullTimePeriod[0]), new Date(timePeriod?.start.date)) &&
        isSameDate(new Date(fullTimePeriod[1]), new Date(timePeriod?.end.date))
      ) {
        setStaticPeriod(MeterDataCleansingRangeType.AVAILABLE_PERIOD)
      } else {
        setStaticPeriod(MeterDataCleansingRangeType.CHART_DATA_RANGE_CUSTOM)
      }
    }
  }, [timePeriod, staticRangeOptions, fullTimePeriod])

  const handleRangeChange = useCallback(
    (event: React.ChangeEvent<{ value: MeterDataCleansingRangeType }>) => {
      const rangeType = event.target.value
      setStaticPeriod(rangeType)
      if (rangeType !== MeterDataCleansingRangeType.CHART_DATA_RANGE_CUSTOM) {
        const data = {
          start: { date: fullTimePeriod[0], timezone: userTimezone },
          end: { date: fullTimePeriod[1], timezone: userTimezone },
        }
        setTimePeriod(data)
        onChangeTimePeriod(data)
      }
    },
    [timePeriod, onChangeTimePeriod, fullTimePeriod],
  )

  return (
    <DatesContainer direction="row" alignItems="center" justifyContent="space-between">
      <SelectFieldContainer>
        <FormControl>
          <InputLabel id="demo-simple-select-label">{t`Time range`}</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={staticPeriod}
            onChange={handleRangeChange}
            disabled={disabled}
          >
            {staticRangeOptions?.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SelectFieldContainer>

      <InputFieldContainer alignItems="center">
        <DateAndTimePicker
          onDateChange={handleChangeStartDate}
          label={t`Start`}
          value={startDate}
          minDate={fullTimePeriod[0]}
          maxDate={endDate}
          minDateMessage={t`Should be greater than start date of available date`}
          onlyDate={false}
          disabled={disabled}
        />
        <Box mt={1.5} mr={1.5}>
          {startDateInUTC && startDateTimezone && startDateTimezone !== userTimezone && (
            <DateTimezonePopover dateInUTC={startDateInUTC} originalTimezone={startDateTimezone} />
          )}
        </Box>
      </InputFieldContainer>

      <InputFieldContainer alignItems="center" justifyContent="center">
        <DateAndTimePicker
          onDateChange={handleChangeEndDate}
          label={t`End`}
          value={endDate}
          minDate={startDate}
          maxDate={fullTimePeriod[1]}
          errorMsg={endDate && startDate && isBefore(endDate, startDate) ? t`Should be after start date` : ''}
          onlyDate={false}
          disabled={disabled}
        />
        <Box mt={1.5}>
          {endDateInUTC && endDateTimezone && endDateTimezone !== userTimezone && (
            <DateTimezonePopover dateInUTC={endDateInUTC} originalTimezone={endDateTimezone} />
          )}
        </Box>
      </InputFieldContainer>
    </DatesContainer>
  )
}

export default React.memo(TimePeriodWithPreSet)
