import React from 'react'

import { NumberFieldWithoutLabel } from 'ui/form/assetForm.style'
import { TextField } from 'final-form-material-ui'
import Number from 'ui/Number'

interface ReTableNumberCellProps {
  value: number
  isEdit?: boolean
  label?: string
  name?: string
  query?: string
  width?: string | number
}

const ReTableNumberCell: React.FC<ReTableNumberCellProps> = ({ value, isEdit, label, name = '', query = '' }) => {
  // return <NumberFieldWithoutLabel type="number" input={input} meta={meta} />
  return (
    <>
      {isEdit ? (
        <NumberFieldWithoutLabel
          type="number"
          fullWidth
          component={TextField}
          name={name}
          defaultValue={value}
          label={label}
        />
      ) : (
        <Number showFractionalPart={false} highlight={query} data={value} />
      )}
    </>
  )
}

export default ReTableNumberCell
