export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE'
export const DOWNLOAD_FILE_DISMISS = 'DOWNLOAD_FILE_DISMISS'
export type DOWNLOAD_FILE_REQUEST = typeof DOWNLOAD_FILE_REQUEST
export type DOWNLOAD_FILE_SUCCESS = typeof DOWNLOAD_FILE_SUCCESS
export type DOWNLOAD_FILE_FAILURE = typeof DOWNLOAD_FILE_FAILURE
export type DOWNLOAD_FILE_DISMISS = typeof DOWNLOAD_FILE_DISMISS
export type DOWNLOAD_FILE_ACTION_TYPE =
  | DOWNLOAD_FILE_REQUEST
  | DOWNLOAD_FILE_SUCCESS
  | DOWNLOAD_FILE_FAILURE
  | DOWNLOAD_FILE_DISMISS

export const DEACTIVATE_FILE_REQUEST = 'DEACTIVATE_FILE_REQUEST'
export const DEACTIVATE_FILE_SUCCESS = 'DEACTIVATE_FILE_SUCCESS'
export const DEACTIVATE_FILE_FAILURE = 'DEACTIVATE_FILE_FAILURE'
export const DEACTIVATE_FILE_DISMISS = 'DEACTIVATE_FILE_DISMISS'
export type DEACTIVATE_FILE_REQUEST = typeof DEACTIVATE_FILE_REQUEST
export type DEACTIVATE_FILE_SUCCESS = typeof DEACTIVATE_FILE_SUCCESS
export type DEACTIVATE_FILE_FAILURE = typeof DEACTIVATE_FILE_FAILURE
export type DEACTIVATE_FILE_DISMISS = typeof DEACTIVATE_FILE_DISMISS
export type DEACTIVATE_FILE_ACTION_TYPE =
  | DEACTIVATE_FILE_REQUEST
  | DEACTIVATE_FILE_SUCCESS
  | DEACTIVATE_FILE_FAILURE
  | DEACTIVATE_FILE_DISMISS

export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE'
export const DELETE_FILE_DISMISS = 'DELETE_FILE_DISMISS'
export type DELETE_FILE_REQUEST = typeof DELETE_FILE_REQUEST
export type DELETE_FILE_SUCCESS = typeof DELETE_FILE_SUCCESS
export type DELETE_FILE_FAILURE = typeof DELETE_FILE_FAILURE
export type DELETE_FILE_DISMISS = typeof DELETE_FILE_DISMISS
export type DELETE_FILE_ACTION_TYPE =
  | DELETE_FILE_REQUEST
  | DELETE_FILE_SUCCESS
  | DELETE_FILE_FAILURE
  | DELETE_FILE_DISMISS

export const GET_METERDATAFILES_REQUEST = 'GET_METERDATAFILES_REQUEST'
export const GET_METERDATAFILES_SUCCESS = 'GET_METERDATAFILES_SUCCESS'
export const GET_METERDATAFILES_FAILURE = 'GET_METERDATAFILES_FAILURE'
export const GET_METERDATAFILES_DISMISS = 'GET_METERDATAFILES_DISMISS'
export type GET_METERDATAFILES_REQUEST = typeof GET_METERDATAFILES_REQUEST
export type GET_METERDATAFILES_SUCCESS = typeof GET_METERDATAFILES_SUCCESS
export type GET_METERDATAFILES_FAILURE = typeof GET_METERDATAFILES_FAILURE
export type GET_METERDATAFILES_DISMISS = typeof GET_METERDATAFILES_DISMISS
export type GET_METERDATAFILES_ACTION_TYPE =
  | GET_METERDATAFILES_REQUEST
  | GET_METERDATAFILES_SUCCESS
  | GET_METERDATAFILES_FAILURE
  | GET_METERDATAFILES_DISMISS

export const UPLOAD_METERDATAFILES_REQUEST = 'UPLOAD_METERDATAFILES_REQUEST'
export const UPLOAD_METERDATAFILES_SUCCESS = 'UPLOAD_METERDATAFILES_SUCCESS'
export const UPLOAD_METERDATAFILES_FAILURE = 'UPLOAD_METERDATAFILES_FAILURE'
export const UPLOAD_METERDATAFILES_DISMISS = 'UPLOAD_METERDATAFILES_DISMISS'
export type UPLOAD_METERDATAFILES_REQUEST = typeof UPLOAD_METERDATAFILES_REQUEST
export type UPLOAD_METERDATAFILES_SUCCESS = typeof UPLOAD_METERDATAFILES_SUCCESS
export type UPLOAD_METERDATAFILES_FAILURE = typeof UPLOAD_METERDATAFILES_FAILURE
export type UPLOAD_METERDATAFILES_DISMISS = typeof UPLOAD_METERDATAFILES_DISMISS
export type UPLOAD_METERDATAFILES_ACTION_TYPE =
  | UPLOAD_METERDATAFILES_REQUEST
  | UPLOAD_METERDATAFILES_SUCCESS
  | UPLOAD_METERDATAFILES_FAILURE
  | UPLOAD_METERDATAFILES_DISMISS
