import { Box, IconButton, Typography } from '@material-ui/core'
import { DataStreamTypeItem, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

import { ReTableCell } from 'modules/reTable/ReTableCell'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import React, { useCallback, useMemo } from 'react'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import Flex from 'ui/styles/Flex'
import { dataStreamTypeQuery, dataStreamTypesThatAreConfigurable, getDataStreamTypeQueryLabel } from 'utils/dataStream'
import { QUERY_DATA_STREAM_TYPE, QUERY_NEW_SCHEDULE } from 'utils/query-string'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_DATASTREAMS } from 'modules/reTable/reTable.types'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import { useCustomMuiStyles } from 'themes/theme-light'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import {
  hasAnyAuthority,
  hasPermissionToCreateSchedule,
  isAdmin,
  isImpersonatedAdmin,
  useIsReadOnlyUser,
} from 'utils/user'
import { TDIndented } from 'ui/styles/table'

interface DataStreamRowProps {
  typeItem: DataStreamTypeItem
  selected?: boolean
}

const DataStreamTypeRow: React.FC<DataStreamRowProps> = ({ typeItem, selected = false }) => {
  //Style
  const classes = useCustomMuiStyles()
  const user = useSelector(getUserResultSelector)
  const data = getDataStreamTypeQueryLabel(typeItem.id)
  const { onAddQueryString } = useUpdateQueryString()
  const showAddIcon = useMemo(() => {
    if (typeItem.id === TimeSeriesType.SCHEDULE) {
      return hasPermissionToCreateSchedule(user) && dataStreamTypesThatAreConfigurable.includes(typeItem.id)
    }
    return dataStreamTypesThatAreConfigurable.includes(typeItem.id)
  }, [typeItem, user])
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_DATASTREAMS)
  const isReadOnlyUser = useIsReadOnlyUser()

  const handleAddDataStream = useCallback(() => {
    onAddQueryString({
      [QUERY_DATA_STREAM_TYPE]:
        typeItem.id === TimeSeriesType.SCHEDULE ? dataStreamTypeQuery[TimeSeriesType.SITE_FORECAST] : data.query,
    })

    if (typeItem.id === TimeSeriesType.SCHEDULE) {
      onAddQueryString({ [QUERY_NEW_SCHEDULE]: true })
    }
  }, [onAddQueryString])

  return (
    <ReTableRow
      className={
        (isAdmin(user) || isImpersonatedAdmin(user)) &&
        !hasAnyAuthority(user, ['ROLE_E3_METAFORECAST']) &&
        !hasAnyAuthority(user, ['ROLE_E3_FORECAST']) &&
        typeItem.id === TimeSeriesType.E3_META_FORECAST
          ? classes.adminComponent
          : (isAdmin(user) || isImpersonatedAdmin(user)) &&
            !hasAnyAuthority(user, ['ROLE_WDA']) &&
            typeItem.id === TimeSeriesType.WEATHER_DATA
          ? classes.adminComponent
          : 'inherit'
      }
    >
      <ReTableCell no_border={true} colSpan={2}>
        <TDIndented level={typeItem?.uiLevel || 0}>
          <Flex direction="row" justifyContent="space-between" alignItems="center">
            <Flex>
              {sort.active ? (
                <Box width="1.4em"></Box>
              ) : (
                <ReTableExpandToggle item={typeItem} color={selected ? 'white' : 'inherit'} />
              )}

              <Typography variant="subtitle2">{data.label}</Typography>
            </Flex>
            {showAddIcon && (
              <IconButton disabled={isReadOnlyUser} onClick={handleAddDataStream} size="small">
                <FontAwesomeActionIcon disabled={isReadOnlyUser} size="xs" icon="plus" />
              </IconButton>
            )}
          </Flex>
        </TDIndented>
      </ReTableCell>
    </ReTableRow>
  )
}

export default React.memo(DataStreamTypeRow)
