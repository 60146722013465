import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Column, ReTableContextMenuItemName, ReTableRowContextActions, Sort } from 'modules/reTable/reTable.types'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { colors } from 'themes/theme-light'
import { t } from 'ttag'
import { formatDate } from 'utils/date'
import ForecastModelName from 'modules/quality/ForecastModelName'
import { Asset } from 'modules/asset/store/asset.types'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'
import ReTableContextMenu from 'modules/reTable/ReTableContextMenu'
import {
  EnrichedForecastModelItem,
  forecastModelsTableActionMenuItems,
  hasPermissionForForecastModelTableActions,
} from 'utils/forecastModel'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import { ForecastModel } from 'modules/quality/quality.types'
import { getQualityEvaluationsResultSelector } from 'modules/quality/redux_store/state/getQualityEvaluation'
import HideModelCheckBox from 'modules/forecastModels/HideModelCheckBox'
import { isSolarPark, isSolarPlant, isWindPark, isWindPlant } from 'utils/asset'
import ForecastModelRowMetaData from 'modules/forecastModels/ForecastModelRowMetaData'
import ForecastModelAndEvaluationReason from 'modules/forecastModels/ForecastModelAndEvaluationReason'
import ForecastModelIcon from 'ui/elements/ForecastModelIcon'

interface StyledReTableRowProps {
  hidden: boolean
}

const StyledReTableRow = styled(ReTableRow)<StyledReTableRowProps>`
  &.MuiTableRow-root.Mui-selected {
    background-color: ${colors.marked};

    &:hover {
      background-color: green !important;
    }
  }
  *.MuiTableCell-body {
    color: ${(props) => (props.hidden ? 'grey' : 'inherit')};
  }
  * .MuiInput-root {
    color: ${(props) => (props.hidden ? 'grey' : 'inherit')};
  }
`

interface ForecastModelsTableRowProps {
  columnsSelected: Column[]
  activeModels: ForecastModel[]
  evaluationRunning: Record<string, boolean>
  forecastModel: EnrichedForecastModelItem
  asset: Partial<Asset>
  onClickModelRowMenuAction: (menuItem: ReTableContextMenuItemName, forecastModel: EnrichedForecastModelItem) => void
  sort: Sort
}
const ModelRow: React.FC<ForecastModelsTableRowProps> = ({
  columnsSelected,
  activeModels,
  evaluationRunning,
  forecastModel,
  asset,
  onClickModelRowMenuAction,
  sort,
}) => {
  const user = useSelector(getUserResultSelector)

  const qualityEvaluations = useSelector(getQualityEvaluationsResultSelector)
  const modelEvaluations = useMemo(() => {
    return (qualityEvaluations || []).filter(
      (evaluation) => evaluation.forecastModelId === forecastModel.uuid && evaluation.assetId === asset.id,
    )
  }, [qualityEvaluations, forecastModel])
  const evaluationInProgress = modelEvaluations.some((evaluation) => evaluation.status !== 'FINISHED')
  const evaluationCount = modelEvaluations.length
  const running = evaluationRunning[forecastModel.uuid] || evaluationInProgress
  const disableEvaluateAll = evaluationCount === 0 || !forecastModel.trainingId || running
  const activeModelForThisForecastModelCategory = activeModels.find((am) => am.category === forecastModel.category)
  const isForecastModelActive = activeModelForThisForecastModelCategory?.uuid === forecastModel.id

  const actionMenuItems = useMemo(() => {
    let menuItems = forecastModelsTableActionMenuItems(user).filter((item) =>
      item?.userHasPermission ? item.userHasPermission(user) : true,
    )

    // Update disabled value
    menuItems = menuItems.map((item) => {
      let updatedItem = { ...item }
      if (item.itemName === ReTableRowContextActions.ACTIVATE) {
        updatedItem = { ...item, disabled: isForecastModelActive || false }
      } else if (item.itemName === ReTableRowContextActions.EVALUATE_ALL) {
        updatedItem = { ...item, disabled: disableEvaluateAll || false }
      }
      return updatedItem
    })

    if ((isAdmin(user) || isImpersonatedAdmin(user)) && forecastModel?.version === 'V1' && isForecastModelActive) {
      const migrateToV2MenuItem = {
        itemName: ReTableRowContextActions.MIGRATE_TO_V2,
        itemLabel: t`Migrate to V2`,
        icon: '',
        disabled: false,
        userHasPermission: hasPermissionForForecastModelTableActions,
      }
      menuItems = [...menuItems, migrateToV2MenuItem]
    }

    if ((isAdmin(user) || isImpersonatedAdmin(user)) && forecastModel?.version === 'V2') {
      const enableDisableRealTime = {
        itemName: ReTableRowContextActions.ENABLE_DISABLE_REALTIME,
        itemLabel: forecastModel?.parameter?.realtime ? t`Disable realtime` : `Enable realtime`,
        icon: '',
        disabled: false,
        userHasPermission: hasPermissionForForecastModelTableActions,
      }
      menuItems = [...menuItems, enableDisableRealTime]
    }

    if (isWindPark(asset) || isWindPlant(asset)) {
      menuItems = menuItems.filter((item) => item.itemName !== ReTableRowContextActions.START_SOLAR_CALIBRATION)
    }

    if (isSolarPark(asset) || isSolarPlant(asset)) {
      menuItems = menuItems.filter((item) => item.itemName !== ReTableRowContextActions.START_TRAINING_NOW)
    }

    return menuItems
  }, [user, activeModelForThisForecastModelCategory, disableEvaluateAll, forecastModel, isForecastModelActive])

  const [modelRowHovered, setModelRowHovered] = useState(false)
  const nameOrDate =
    forecastModel?.name ||
    `[${forecastModel?.creationDate ? formatDate(forecastModel.creationDate) : formatDate(forecastModel?.activeFrom)}]`

  const handleMouseOver = () => {
    setModelRowHovered(true)
  }

  const handleMouseLeave = () => {
    setModelRowHovered(false)
  }

  const handleMenuClick = (menuItem: ReTableContextMenuItemName) => {
    onClickModelRowMenuAction(menuItem, forecastModel)
  }

  const reasonColumnDisplayed = columnsSelected.find((col) => col.name === 'reason')

  const showIcon = !sort.active && forecastModel && forecastModel?.uiChildren?.length > 0

  // *** NOTE: The direction of column values is maintained using colSpan ***
  return (
    <StyledReTableRow hidden={forecastModel?.hide} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <ReTableCell colSpan={2}>
        <Flex alignItems={'center'}>
          <Box width={'1.3em'}>
            {showIcon && <ReTableExpandToggle item={forecastModel as EnrichedForecastModelItem} />}
          </Box>

          <ForecastModelIcon
            forecastModel={forecastModel}
            activeModel={activeModelForThisForecastModelCategory?.uuid}
          />

          <Box dispaly="flex" alignItems={'center'}>
            <ForecastModelName
              user={user}
              asset={asset}
              initialValue={nameOrDate || ''}
              forecastModel={forecastModel}
            />
          </Box>
        </Flex>
      </ReTableCell>

      {reasonColumnDisplayed && (
        <ReTableCell>
          <ForecastModelAndEvaluationReason forecastModel={forecastModel} qualityEvaluation={null} />
        </ReTableCell>
      )}

      <ReTableCell colSpan={columnsSelected.length - (reasonColumnDisplayed ? 2 : 1)}>
        <Flex alignItems={'center'}>
          <ForecastModelRowMetaData forecastModel={forecastModel} asset={asset} user={user} />

          <Flex alignItems="center">
            <ReTableContextMenu menuItems={actionMenuItems} showContextMenu={true} onClickMenuItem={handleMenuClick} />
            {modelRowHovered && <HideModelCheckBox asset={asset} forecastModel={forecastModel} />}
          </Flex>
        </Flex>
      </ReTableCell>
    </StyledReTableRow>
  )
}

export default React.memo(ModelRow)
