import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { t } from 'ttag'
import Help from 'ui/elements/Help'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'

const FlexRow = styled.div`
  display: flex;
`

const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Example = styled.div`
  font-family: monospace;
  white-space: pre;
`

interface FileUploadHelpPopoverProps {
  showHelpTextBtn: boolean
}

const FileUploadHelpPopover: React.FC<FileUploadHelpPopoverProps> = ({ showHelpTextBtn }) => {
  return (
    <Flex>
      <Help showHelpTextBtn={showHelpTextBtn} data-testid="upload-modal-button-help">
        <Box mb={1}>
          <Typography variant="body2" gutterBottom={true}>
            {t`Supported upload types`}
          </Typography>
        </Box>
        <FlexRow>
          <Box mr={3}>
            <div>
              <strong>{t`Meter data`}</strong>
            </div>
            <Typography variant="body1">
              {t`Files have to be in CSV format, consisting of two columns: 1. Timestamp, 2. Power in watt. The file name must be in the format <name>.csv where <name> exactly matches the name of the asset which the power data shall be assigned to.`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" component="div">
              <Box mb={0.5}>
                <FlexBetween>
                  <strong>{t`Example`}:</strong>
                  <a href="/public/meterdata/example-meterdata-two-weeks.csv" download>
                    <small>{t`Download`}</small>
                  </a>
                </FlexBetween>
              </Box>
              <Example>
                <div>DateTime, PowerWatt</div>
                <div>2018-12-05 00:00+0000,1518909</div>
                <div>2018-12-05 01:00+0000,1531128</div>
                <div>2018-12-05 02:00+0000,1569822</div>
                <div>2018-12-05 03:00+0000,1586560</div>
                <div>2018-12-05 04:00+0000,1507138</div>
                <div>[...]</div>
              </Example>
            </Typography>
          </Box>
        </FlexRow>
      </Help>
    </Flex>
  )
}

export default React.memo(FileUploadHelpPopover)
