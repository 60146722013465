// places types

import {
  TYPE_CLUSTER,
  TYPE_SOLARPARK,
  TYPE_SOLARPLANT,
  TYPE_WINDPARK,
  TYPE_WINDPLANT,
} from 'modules/asset/store/asset.types'
import { Marker, Polygon, Rectangle } from '@react-google-maps/api'
import React from 'react'

export type GeocoderAddressComponentType =
  | 'street_address'
  | 'street_number'
  | 'route'
  | 'intersection'
  | 'political'
  | 'country'
  | 'administrative_area_level_1'
  | 'administrative_area_level_2'
  | 'administrative_area_level_3'
  | 'administrative_area_level_4'
  | 'administrative_area_level_5'
  | 'colloquial_area'
  | 'locality'
  | 'sublocality'
  | 'neighborhood'
  | 'premise'
  | 'subpremise'
  | 'postal_code'
  | 'natural_feature'
  | 'airport'
  | 'park'
  | 'point_of_interest'
  | 'establishment'
  | 'parking'
  | 'post_box'
  | 'postal_town'
  | 'floor'
  | 'room'
  | 'bus_station'
  | 'train_station'
  | 'transit_station'

export const getMarkerIcons = () => {
  const iconBase = '/public/map/'
  const iconProps = {
    size: new google.maps.Size(36, 48),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(18, 48),
  }

  const icons: Record<string, google.maps.Icon> = {
    unknown: {
      ...iconProps,
      url: iconBase + 'marker.png',
    },
    [TYPE_CLUSTER]: {
      ...iconProps,
      url: iconBase + 'marker-cluster.png',
    },
    [TYPE_SOLARPARK]: {
      ...iconProps,
      url: iconBase + 'marker-solarpark.png',
    },
    [TYPE_WINDPARK]: {
      ...iconProps,
      url: iconBase + 'marker-windpark.png',
    },
    [TYPE_SOLARPLANT]: {
      ...iconProps,
      url: iconBase + 'marker-solarplant.png',
    },
    [TYPE_WINDPLANT]: {
      ...iconProps,
      url: iconBase + 'marker-windplant.png',
    },
  }

  return icons
}

// shapes

export const getPoly = (poly: any[][], itCount: number, name: string, selection: string) => {
  const rings = []
  for (let i = 0; i < poly[itCount].length; i++) {
    rings.push(poly[itCount][i])
  }
  return <Polygon key={name + '_' + selection + '_' + itCount} paths={rings} />
}

export const getPoint = (point: google.maps.LatLng | google.maps.LatLngLiteral, name: string, selection: string) => {
  return <Marker key={name + '-' + selection} position={point} />
}

export const getBBox = (poly: any[][][]) => {
  const north = poly[0][0][2].lat
  const south = poly[0][0][0].lat
  const east = poly[0][0][2].lng
  const west = poly[0][0][0].lng

  // console.log("north: " + north)
  // console.log("south: " + south)
  // console.log("east: " + east)
  // console.log("west: " + west)

  return (
    <Rectangle
      key="bbox"
      bounds={{
        north: north,
        south: south,
        east: east,
        west: west,
      }}
    />
  )
}

export const plotShape = (coordinates: { multipoint: any; multipolygon: any }, selection: string) => {
  const returnValue = []
  const multiPolygon = coordinates.multipolygon
  const multiPoint = coordinates.multipoint
  for (let i = 0; i < multiPolygon.length; i++) {
    returnValue.push(getPoly(multiPolygon, i, 'shape', selection))
  }
  if (multiPoint !== null) {
    for (let i = 0; i < multiPoint.length; i++) {
      returnValue.push(getPoint(multiPoint, i + '', selection))
    }
  }
  // console.log(returnValue);
  return returnValue
}
