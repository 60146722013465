import React, { useCallback, useEffect, useMemo } from 'react'
import { Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import { t } from 'ttag'

import styled from 'styled-components'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import NestedMenuItem from 'ui/elements/NestedMenuItem'
import { Column, RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { useReTableId, useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import {
  reTableColumnsAvailableSelector,
  reTableColumnsSelectedSelector,
} from 'modules/reTable/redux_store/state/view.state'
import { KEY_SHOW_MODELS_IN_ASSET_TREE, useUserSetting } from 'modules/auth/api/userSettings.api'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { SAVE_WORKSPACE_DRAFT_REQUEST } from 'modules/workspace/store/workspace.types'
import { useDispatch, useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { useCustomMuiStyles } from 'themes/theme-light'
import { isAdmin, isImpersonatedAdmin, hasPermissionForSiteAssessmentBackcast } from 'utils/user'

const MenuIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 0;
    margin-right: 7px;
    font-size: 1em;
  }
`

interface ReTableHeaderContextMenuProps {
  disabled?: boolean
  isHierarchical: boolean
  onSelectAll?: () => void
  onSelectAllLeafs?: () => void
  onSelectEntireBranch?: () => void
  onExpandRows: () => void
  onCollapseRows: () => void
  onColumnSelection: (col: Column) => void
  onToggleModelRows: () => void
}

const ReTableHeaderContextMenu: React.FC<ReTableHeaderContextMenuProps> = ({
  disabled,
  isHierarchical,
  onSelectAll,
  onSelectAllLeafs,
  onSelectEntireBranch,
  onExpandRows,
  onCollapseRows,
  onColumnSelection,
  onToggleModelRows,
}) => {
  // style
  const classes = useCustomMuiStyles()

  const dispatch = useDispatch()
  const [menuPosition, setMenuPosition] = React.useState<any>(null)
  const userShowModelRowsResult = useUserSetting<any>(KEY_SHOW_MODELS_IN_ASSET_TREE)
  const user = useSelector(getUserResultSelector)
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const [ref, tableId] = useReTableId()
  const availableColumns = useReTableSelectorWithId(reTableColumnsAvailableSelector, tableId)

  const selectedColumns = useReTableSelectorWithId(reTableColumnsSelectedSelector, tableId)
  const selectedColumnNames = selectedColumns.map((c) => c.name)

  const showModels = useMemo(() => userShowModelRowsResult?.data?.value || false, [userShowModelRowsResult?.data])

  const hasAccessToSiteAssessmentBackcast = useMemo(() => hasPermissionForSiteAssessmentBackcast(user), [user])

  const handleMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (menuPosition) {
        return
      }
      event.preventDefault()
      setMenuPosition({ top: event.pageY, left: event.pageX })
    },
    [menuPosition],
  )

  const handleClose = useCallback(() => {
    setMenuPosition(null)
  }, [])

  // Remove models selectionId from assetSelection if models are hidden
  useEffect(() => {
    if (assetSelection.length && !showModels && userShowModelRowsResult?.data) {
      const assetSelections = assetSelection.filter((id) => !id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID))
      const oldModelSelectionsWithVersions = assetSelection.filter((id) => id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID))
      if (oldModelSelectionsWithVersions) {
        const draft = {
          asset: {
            selection: assetSelections,
          },
        }
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
    }
  }, [assetSelection, showModels, userShowModelRowsResult?.data?.value])

  return (
    <div ref={ref}>
      <MenuIconButton size="small" aria-haspopup="true" onClick={handleMenuClick}>
        <FontAwesomeActionIcon aria-controls="simple-menu" aria-haspopup="true" icon="ellipsis-v" />
      </MenuIconButton>
      <Menu
        id="simple-menu"
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
        open={Boolean(menuPosition)}
        onClose={handleClose}
      >
        {isHierarchical && (
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onExpandRows()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeActionIcon icon="plus-square" />
            </ListItemIcon>
            <ListItemText primary={t`Expand all`} />
          </MenuItem>
        )}

        {isHierarchical && (
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onCollapseRows()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeActionIcon icon="minus-square" />
            </ListItemIcon>
            <ListItemText primary={t`Collapse all`} />
          </MenuItem>
        )}

        {isHierarchical && onSelectAllLeafs && (
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onSelectAllLeafs()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeActionIcon icon="check" />
            </ListItemIcon>
            <ListItemText primary={t`Select all children`} />
          </MenuItem>
        )}

        {isHierarchical && onSelectEntireBranch && (
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onSelectEntireBranch()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeActionIcon icon="check-double" />
            </ListItemIcon>
            <ListItemText primary={t`Select entire branch`} />
          </MenuItem>
        )}

        {isHierarchical && onSelectAll && (
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onSelectAll()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeActionIcon icon="check-double" />
            </ListItemIcon>
            <ListItemText primary={t`Select all`} />
          </MenuItem>
        )}

        {tableId === RETABLE_ID_ASSETS && hasAccessToSiteAssessmentBackcast && (
          <MenuItem
            disabled={disabled}
            onClick={() => {
              onToggleModelRows()
              handleClose()
            }}
          >
            <ListItemIcon>
              <FontAwesomeActionIcon icon={'code-branch'} />
            </ListItemIcon>
            <ListItemText primary={showModels ? t`Hide models` : t`Show models`} />
          </MenuItem>
        )}

        {availableColumns.length > 0 && (
          <NestedMenuItem label={t`Columns`} icon="table" parentMenuOpen={!!menuPosition}>
            <>
              {availableColumns.map((col: Column) => {
                return (
                  <MenuItem
                    disabled={col.fixed}
                    key={col.name}
                    onClick={() => onColumnSelection(col)}
                    className={
                      col.isAdminComponent
                        ? (isAdmin(user) || isImpersonatedAdmin(user)) && col.isAdminComponent
                          ? classes.adminComponent
                          : 'inherit'
                        : 'inherit'
                    }
                  >
                    <ListItemIcon>
                      <Checkbox checked={selectedColumnNames.includes(col.name)} color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={col.label} />
                  </MenuItem>
                )
              })}
            </>
          </NestedMenuItem>
        )}
      </Menu>
    </div>
  )
}

export default React.memo(ReTableHeaderContextMenu)
