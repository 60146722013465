// Important
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { UserManagementType } from 'modules/userManagement/userManagement.types'
import {
  postSiteAssessmentCoordinateLimit,
  useDefaultAuthorities,
  useSiteAssessmentCoordinateLimitById,
  useSiteAssessmentCoordinateLimitSaveMutation,
  useUserManagementAllUsers,
  useUserManagementSaveMainItemKey,
  useUserManagementUserById,
  useUserManagementUserSaveMutation,
} from 'modules/userManagement/api/userManagement.api'
import {
  getUserManagementUserQueryObj,
  QUERY_USER_MANAGEMENT_DETAILS,
  useGetFullPath,
  useQueryString,
} from 'utils/query-string'
// UI
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { FormApi, Mutator } from 'final-form'
import UserManagementForm from 'modules/userManagement/UserManagementForm'
import { Link } from 'react-router-dom'
import { c } from 'ttag'
import { FormSaveOptions, genericFormSubscription } from 'utils/form'
import { Form } from 'react-final-form'
import { validateUserManagementUserForm } from 'utils/formValidations'
import { Box } from '@material-ui/core'
import CenteredLoading from 'ui/CenteredLoading'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isPartner } from 'utils/user'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_USER_MANAGEMENT } from 'modules/reTable/reTable.types'

//Style Component
const FormContainer = styled(Flex)`
  height: 100%;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
  }
`
// Form
let formReference: FormApi<UserManagementType>

// Interface
interface UserManagementDetailsProps {
  userId: string
}

// Other
const NEW = 'new'

const UserManagementDetails: React.FC<UserManagementDetailsProps> = ({ userId }) => {
  const isNew = userId === NEW
  const user = useSelector(getUserResultSelector)
  const siteAssessmentCoordinateFieldValueRef = useRef(0)
  // Get default authorities (or profiles or roles)
  const defaultAuthorities = useDefaultAuthorities()
  const allUserManagementUsers = useUserManagementAllUsers()
  const saveMainItemKey = useUserManagementSaveMainItemKey()

  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const [selectedUserDetails, setSelectedUserDetails] = useState<UserManagementType | null>(null)

  const saveUserManagementUserResult = useUserManagementUserSaveMutation(isNew)
  const { reset: resetSaveResult } = saveUserManagementUserResult
  const saveUserManagementUserMutation = saveUserManagementUserResult.mutate

  const selectedUserDetailsResult = useUserManagementUserById(userId)
  const siteAssessmentCoordinateLimitByIdResult = useSiteAssessmentCoordinateLimitById(userId)

  const saveSiteAssessmentCoordinateLimit = useSiteAssessmentCoordinateLimitSaveMutation(userId)

  const allUserManagementUsersResult = useUserManagementAllUsers()
  const filteredIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_USER_MANAGEMENT)

  const filteredUserManagementUsers = useMemo(() => {
    return filteredIds.map((id) => (allUserManagementUsersResult.data || []).find((user) => user.id === id))
  }, [allUserManagementUsersResult.data, filteredIds])

  const handleFormSubmit = useCallback(
    (formData: UserManagementType) => {
      // Site Assessment Coordinate Value
      siteAssessmentCoordinateFieldValueRef.current = formData.siteAssessmentCoordinateLimit || 0
      if (!isNew) {
        saveSiteAssessmentCoordinateLimit.mutate(formData.siteAssessmentCoordinateLimit)
      }
      // This condition is added because for admin user , in partnerName field we are using FinalFormAutocompleteField (requires array as value)
      if (isAdmin(user) && formData.partnerName) {
        if (formData.partnerName.length > 0) {
          formData.partnerName = Array.isArray(formData.partnerName) ? formData?.partnerName[0] : formData.partnerName
        } else {
          formData.partnerName = ''
        }
      }
      saveUserManagementUserMutation(formData)
    },
    [isNew],
  )

  const handleCloseSlider = useCallback(() => {
    onDeleteQueryStrings([QUERY_USER_MANAGEMENT_DETAILS])
  }, [onDeleteQueryStrings])

  const formMutators = useMemo<{ [key: string]: Mutator<UserManagementType, Partial<UserManagementType>> }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
    }
  }, [])

  // Form To Render
  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <UserManagementForm
          isNew={isNew}
          form={form}
          onFormSubmit={handleSubmit}
          onCloseSlider={handleCloseSlider}
          saveResult={saveUserManagementUserResult}
          allUsers={allUserManagementUsersResult.data || []}
          selectedUserDetails={selectedUserDetails}
        />
      )
    },
    [
      isNew,
      saveUserManagementUserResult.isLoading,
      saveUserManagementUserResult?.isError,
      allUserManagementUsersResult.data,
      selectedUserDetails,
    ],
  )

  // Details of a certain user not found & create new user link
  const routeToNewUserManagementUser = useGetFullPath(getUserManagementUserQueryObj())
  const detailsNotFoundMessage = useMemo(() => {
    const createNewLink = <Link to={routeToNewUserManagementUser}>{c('UserManagement').t`new user`}</Link>
    return c('UserManagement').jt`User not found. Please select from the list or create a ${createNewLink}.`
  }, [routeToNewUserManagementUser])

  const formSubscription = useMemo(() => genericFormSubscription(), [])

  useEffect(() => {
    if (selectedUserDetailsResult?.data) {
      const prepareInitValue = { ...selectedUserDetailsResult?.data }
      if (isNew) {
        if (isPartner(user)) prepareInitValue.partnerName = user?.login
        prepareInitValue.authorities = defaultAuthorities.data
      }

      //Site Assessment Coordinate Limit
      prepareInitValue.siteAssessmentCoordinateLimit = siteAssessmentCoordinateLimitByIdResult?.data
        ? siteAssessmentCoordinateLimitByIdResult?.data
        : 0

      setSelectedUserDetails(selectedUserDetailsResult?.isSuccess ? prepareInitValue : null)
      resetSaveResult()
    }
  }, [
    selectedUserDetailsResult?.data,
    isNew,
    userId,
    user,
    defaultAuthorities?.data,
    siteAssessmentCoordinateLimitByIdResult?.data,
  ])

  // Actions need to be performed after saving the user
  useEffect(() => {
    if (saveUserManagementUserResult?.isSuccess && saveUserManagementUserResult?.data) {
      const data = saveUserManagementUserResult?.data
      // saving the coordinate limit for new users after they are saved
      if (isNew) {
        postSiteAssessmentCoordinateLimit(data.login, siteAssessmentCoordinateFieldValueRef.current)
      }
      const { SAVE, SAVE_AND_CLOSE, SAVE_AND_NEW, SAVE_AND_NEXT } = FormSaveOptions
      const saveOption =
        (isNew ? saveMainItemKey?.data?.createUserManagement : saveMainItemKey?.data?.updateUserManagement) ||
        SAVE_AND_CLOSE

      if (isNew && saveOption === SAVE_AND_NEW) {
        formReference.reset()
        formReference.restart()
      } else {
        formReference.reset(data)
      }
      resetSaveResult()

      setTimeout(() => {
        if (saveOption === SAVE) {
          onUpdateQueryString(getUserManagementUserQueryObj(data))
        } else if (saveOption === SAVE_AND_NEW) {
          formReference.restart()
        } else if (saveOption === SAVE_AND_NEXT) {
          const currentUserManagementUserIndex = filteredUserManagementUsers?.findIndex((user) => user?.id === data.id)
          const userManagementUserCount = (filteredUserManagementUsers || []).length

          let nextUserManagementUserIndex = 0

          if (
            currentUserManagementUserIndex !== undefined &&
            currentUserManagementUserIndex + 1 !== userManagementUserCount
          ) {
            nextUserManagementUserIndex = currentUserManagementUserIndex + 1
          }
          onUpdateQueryString(
            getUserManagementUserQueryObj((filteredUserManagementUsers || [])[nextUserManagementUserIndex]),
          )
        } else {
          onDeleteQueryStrings([QUERY_USER_MANAGEMENT_DETAILS])
        }
      }, 400)
    }
  }, [
    filteredUserManagementUsers,
    onUpdateQueryString,
    isNew,
    JSON.stringify(allUserManagementUsers),
    saveUserManagementUserResult?.isSuccess,
    JSON.stringify(saveUserManagementUserResult?.data),
  ])

  return (
    <div>
      {selectedUserDetails ? (
        <FormContainer direction="column">
          <Form
            mutators={formMutators}
            onSubmit={handleFormSubmit}
            initialValues={selectedUserDetails}
            validate={validateUserManagementUserForm}
            subscription={formSubscription}
            render={formRender}
          />
        </FormContainer>
      ) : selectedUserDetailsResult?.isLoading ? (
        <Box mt={3}>
          <CenteredLoading size="2em" />
        </Box>
      ) : (
        <DetailsNotFound key="details-not-found" message={detailsNotFoundMessage} onClose={handleCloseSlider} />
      )}
    </div>
  )
}

export default React.memo(UserManagementDetails)
