import { Box, ButtonGroup, FormControlLabel, IconButton, Radio, RadioGroup } from '@material-ui/core'
import { orange } from '@material-ui/core/colors'
import {
  DraftRevisionAction,
  useActivateDraftMutation,
  useDeleteDraftMutation,
  useDraftChangeCounts,
  useHasDraft,
  useStage,
} from 'modules/asset/api/metadata.api'
import { testIdAssetRevisionChooser, testIdError } from 'modules/asset/portfolio/AssetRevisionChooser.ids'
import { REVISION_SET_STAGE } from 'pages/workbench/store/workbench.action.types'
import { STAGE, STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { sideNavWidth, theme } from 'themes/theme-light'
import { c, msgid, ngettext, t } from 'ttag'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import LoadingButton from 'ui/form/LoadingButton'
import Message from 'ui/Message'
import Flex from 'ui/styles/Flex'
import { RelatedObject } from 'utils/request'
import { AppTours } from 'modules/app/tour/appTour.types'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { activateDraftSteps } from 'modules/app/tour/appTourSteps'
import { useRouter, useRouterMatch } from 'utils/route'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import { AddTourStepsTime } from 'modules/app/tour/appTourUtils'
import { getAssetQueryObj, QUERY_ASSET, useQueryMatch, useQueryString } from 'utils/query-string'
import PopperTooltip from 'ui/PopperTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Asset } from 'modules/asset/store/asset.types'
import AssetLink from 'modules/asset/AssetLink'
import { cacheAssetCoordinates, useAllAssetsOfStage } from 'modules/asset/api/assets.api'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { SAVE_WORKSPACE_DRAFT_SELECT_ASSET } from 'modules/workspace/store/workspace.types'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { isCluster } from 'utils/asset'
import { useIsReadOnlyUser } from 'utils/user'

const FlexContainer = styled(Flex)`
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 18em;
  margin: 0 2em 0 1em;
  padding: 0.5em;
  background: ${orange[100]};
  box-shadow: ${theme.shadows[4]};
  text-align: center;
`

const SmallRadioGroup = styled(RadioGroup)`
  & .MuiRadio-root {
    padding: 4px;
  }
`

const AssetRevisionChooser: React.FC = () => {
  const { addTourSteps, removeTourSteps, isTourActive } = useContext(AppTourContext)
  const { onUpdateQueryString } = useQueryString()
  const isReadOnlyUser = useIsReadOnlyUser()

  const productiveAssets = useAllAssetsOfStage(STAGE_PRODUCTIVE)
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const { history } = useRouter()
  const dispatch = useDispatch()
  const hasDraft = useHasDraft()
  const stage = useStage()
  const draftAssets = useAllAssetsOfStage(STAGE_DRAFT)
  const modifiedAssets = (draftAssets.data || []).filter((asset) => asset.changed || asset.deleted)

  const { pendingCount } = useDraftChangeCounts()
  const { isMatch: isWorkbench } = useRouterMatch(ROUTE_WORKBENCH)
  const isAssetDetails = useQueryMatch(QUERY_ASSET)

  const showChooser = hasDraft.data && pendingCount > 0

  const activateDraftResult = useActivateDraftMutation()
  const activateDraftMutation = activateDraftResult.mutate
  const deleteDraftResult = useDeleteDraftMutation()
  const deleteDraftMutation = deleteDraftResult.mutate

  const activateDraftErrorData = activateDraftResult.error
  const deleteDraftError = deleteDraftResult.error

  const activationErrorTooltip = useMemo(() => {
    if (!activateDraftErrorData) return

    // const { code, context } = activateDraftErrorData
    // TODO why do we need to parse json here? that should be done at higher level
    //  seems to do something with react query and exceptions that are thrown
    const { code, context } = JSON.parse(activateDraftErrorData.message)
    const relatedObjects: Record<string, RelatedObject> = (context || {}).relatedObjects || {}
    const assetNames = Object.values(relatedObjects)
      .map((relatedObject) => relatedObject.assetName)
      .filter((a) => a) // filter everything which is undefined

    const failedDueToAvailabilities = ['R101A003', 'R101A004', 'R101A005'].includes(code)
    const errorMessage = failedDueToAvailabilities
      ? c('Asset:Activation')
          .t`Activation was not performed because nameplate capacity was changed for the following assets with active availability limitations:`
      : code === 'R001B009'
      ? c('Asset:Activation').t`Park would be empty, please add at least one generator`
      : ''

    const helpMessage = failedDueToAvailabilities
      ? c('Asset:Activation')
          .t`You can still activate the changes, the availability limitations will then be applied on the new nameplate capacity.`
      : ''

    return (
      <>
        <strong>{errorMessage}</strong> <span>(Error Code: {code})</span>
        <ul>
          {assetNames.map((assetName) => (
            <li key={assetName}>{assetName}</li>
          ))}
        </ul>
        <div>
          {helpMessage}
          {failedDueToAvailabilities && (
            <Flex justifyContent="flex-end">
              <LoadingButton
                loading={activateDraftResult.isLoading}
                color="primary"
                variant="contained"
                onClick={() => handleSaveDiscardDraft(true)}
              >
                {t`Activate anyways`}
              </LoadingButton>
            </Flex>
          )}
        </div>
      </>
    )
  }, [activateDraftErrorData])

  useEffect(() => {
    // when hidden we want to clear error message
    if (!showChooser) {
      activateDraftResult.reset()
      deleteDraftResult.reset()
    }
  }, [showChooser])

  /**
   * This function is used to disable Delete Button after a successfully deletion of one/multiple assets.
   **/
  const disableDeleteAssetButtonHandler = useCallback(() => {
    const newProductiveAsset = (productiveAssets.data || []).filter((asset) => assetSelection.includes(asset.id))

    newProductiveAsset.forEach((singleAsset) => {
      dispatch({
        type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
        asset: singleAsset,
        selectionOptions: { ctrlKey: true },
      })
    })
  }, [productiveAssets, assetSelection])

  const [openDialog, setOpenDialog] = useState(false)
  const [activeAction, setActiveAction] = useState<DraftRevisionAction | null>(null)

  const handleSelectStage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStage = (event.target as HTMLInputElement).value as STAGE
    dispatch({ type: REVISION_SET_STAGE, stage: newStage })
  }

  const handleSaveDiscardDraft = useCallback(
    (forceActivation = false) => {
      if (isTourActive) {
        history.push(ROUTE_WORKBENCH)
      }
      switch (activeAction) {
        case DraftRevisionAction.ACTIVATE:
          activateDraftMutation(forceActivation)
          // Cache the asset coordinates
          const coordinates = (modifiedAssets || [])
            .filter((asset) => !isCluster(asset) && asset?.location?.coordinate?.longitude)
            .map((assetWithLocation) => {
              return assetWithLocation?.location?.coordinate
            })
          if (coordinates?.length) {
            cacheAssetCoordinates(coordinates).catch((error) => console.log(error))
          }

          disableDeleteAssetButtonHandler()
          break
        case DraftRevisionAction.DISCARD:
          deleteDraftMutation()
          break
      }
      setOpenDialog(false)
    },
    [activeAction, isTourActive, disableDeleteAssetButtonHandler, modifiedAssets],
  )

  const closeSaveDiscardDraft = useCallback(() => {
    setOpenDialog(false)
  }, [])

  const handleConfirmDialog = useCallback((actionType: DraftRevisionAction) => {
    setActiveAction(actionType)
    setOpenDialog(true)
  }, [])

  const handleConfirmDiscard = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    handleConfirmDialog(DraftRevisionAction.DISCARD)
  }

  const handleConfirmActivate = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    handleConfirmDialog(DraftRevisionAction.ACTIVATE)
  }

  const handleOpenAssetDetails = useCallback(
    (asset: Asset) => {
      onUpdateQueryString(getAssetQueryObj(asset))
    },
    [onUpdateQueryString],
  )

  useEffect(() => {
    const addSteps = showChooser && isTourActive && typeof addTourSteps === 'function'
    if (!addSteps) return
    const steps = activateDraftSteps()

    const addStepsTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (addStepsTimer) clearTimeout(addStepsTimer)
    }
  }, [addTourSteps, isTourActive, showChooser, isWorkbench, isAssetDetails])

  useEffect(() => {
    if (!showChooser) {
      if (typeof removeTourSteps === 'function') removeTourSteps(AppTours.ACTIVATE_DRAFT)
    }
  }, [showChooser])

  return (
    <>
      {openDialog && (
        <ConfirmationDialog
          heading={activeAction === DraftRevisionAction.DISCARD ? t`Discard draft` : t`Activate draft`}
          text={
            activeAction === DraftRevisionAction.DISCARD
              ? t`Do you want to discard this draft?`
              : t`Do you want to activate this draft?`
          }
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={handleSaveDiscardDraft}
          onCancel={closeSaveDiscardDraft}
          openDialog={openDialog}
        />
      )}

      {showChooser && (
        <FlexContainer className="appTour-activateDraft" data-testid={testIdAssetRevisionChooser} direction="column">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" mb={1}>
            {activateDraftErrorData && (
              <Message type="error" tooltip={activationErrorTooltip} data-testid={testIdError}>
                {t`Activation failed`}
              </Message>
            )}
            {deleteDraftError && (
              <Message type="error" data-testid={testIdError}>
                {t`Discard failed`}
              </Message>
            )}
            <Flex justifyContent="center">
              {!activateDraftErrorData &&
                !deleteDraftError &&
                ngettext(msgid`${pendingCount} change pending`, `${pendingCount} changes pending`, pendingCount)}
              {modifiedAssets.length > 0 && (
                <PopperTooltip
                  popperLabel={
                    <Box ml={0.7}>
                      <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
                    </Box>
                  }
                  popperContent={
                    <Box width={sideNavWidth}>
                      {modifiedAssets.map((asset) => (
                        <Box mb={0.2} display="flex" key={asset.id} justifyContent="space-between" alignItems="center">
                          <FixedWidthTextContainer text={asset.name} width={'18em' || 'inherit'}>
                            <AssetLink asset={asset} />
                          </FixedWidthTextContainer>
                          <IconButton onClick={() => handleOpenAssetDetails(asset)} size="small">
                            <FontAwesomeIcon icon="eye" color={theme.palette.primary.main} />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  }
                />
              )}
            </Flex>
          </Box>
          <Flex direction="row" justifyContent="space-evenly" alignItems="center">
            <SmallRadioGroup value={stage} onChange={handleSelectStage}>
              <FormControlLabel value={STAGE_PRODUCTIVE} control={<Radio size="small" />} label={t`Active`} />
              <FormControlLabel value={STAGE_DRAFT} control={<Radio size="small" />} label={t`Modified`} />
            </SmallRadioGroup>
            <ButtonGroup orientation="vertical" size="small">
              <LoadingButton
                variant="contained"
                loading={deleteDraftResult.isLoading}
                color="default"
                onClick={handleConfirmDiscard}
                disabled={isReadOnlyUser}
              >
                {t`Discard`}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={activateDraftResult.isLoading}
                color="primary"
                onClick={handleConfirmActivate}
                disabled={isReadOnlyUser}
              >
                {t`Activate`}
              </LoadingButton>
            </ButtonGroup>
          </Flex>
        </FlexContainer>
      )}
    </>
  )
}

export default React.memo(AssetRevisionChooser)
