import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { HighchartsChart, XAxis, YAxis, ScatterSeries } from 'react-jsx-highcharts'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import TimeOptions from 'modules/workspace/highstock/TimeOptions'
import { Timezone } from 'fixtures/timezones'
import { c, t } from 'ttag'
import { AppUnits } from 'utils/units'
import { extractDataFromCleansingService } from 'utils/meterDataCleansing'
import useResizeAware from 'react-resize-aware'
import { CHART_BOOST_THRESHOLD_VALUE } from 'utils/chart'
import { MDC_REQUEST_FAILED } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingDetails'
import { Asset } from 'modules/asset/store/asset.types'
import { isSolarAsset } from 'utils/asset'

const ChartContainer = styled.div`
  position: relative;
  min-height: calc((((100vh - 16em) / 2) * 100) / 100);
  width: 100%;
  flex: 1 1 0;
`

interface RefreshLinkProps {
  disable_link: number
}

const RefreshLink = styled.div<RefreshLinkProps>`
  position: absolute;
  z-index: 10;
  left: 41.5%;
  top: 41.7%;
  cursor: ${(props) => (props.disable_link ? 'inherit' : 'pointer')};
  pointer-events: ${(props) => (props.disable_link ? 'none' : 'inherit')};
  color: ${(props) => (props.disable_link ? 'grey' : 'inherit')};
  font-weight: bold;
`

/**
 * @name chartOptions
 */
const chartOptions = { zoomType: undefined }

interface WeatherGuidedCleansingChartProps {
  timezone?: Timezone
  mdcRequestFailed: string
  refreshData: boolean
  onRefresh: () => void
  scatterChartValues: any
  loadingCleansingData: boolean
  formInvalid: boolean
  asset: Asset
}

const WeatherGuidedCleansingChart: React.FC<WeatherGuidedCleansingChartProps> = ({
  timezone = 'UTC',
  mdcRequestFailed,
  refreshData,
  onRefresh,
  scatterChartValues,
  loadingCleansingData,
  formInvalid,
  asset,
}) => {
  const [resizeListener, { width, height }] = useResizeAware()
  const [chart, setChart] = useState<any>()
  const [originalData, setOriginalData] = useState<number[]>([])
  const [afterCleansing, setAfterCleansing] = useState<number[]>([])
  const getCleansedDataFailed = mdcRequestFailed === MDC_REQUEST_FAILED.GET_CLEANSED_DATA
  const isSolar = isSolarAsset(asset)

  /**
   * @name plotOptions
   */
  const plotOptions = {
    series: {
      animation: false,
      turboThreshold: 0,
      boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
      connectNulls: false,
      states: {
        inactive: {
          enabled: true, // do not fade out other series if one is hovered
        },
      },
    },
    scatter: {
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormat: `{point.x} ${isSolar ? AppUnits.WATT_PER_SQUARE_METER : AppUnits.METER_PER_SECOND}, {point.y} ${
          AppUnits.KILO_WATT
        }`,
      },
      marker: {
        radius: 1, // dot size
      },
    },
  }

  useEffect(() => {
    setOriginalData(extractDataFromCleansingService(scatterChartValues, asset).originalData)
    setAfterCleansing(extractDataFromCleansingService(scatterChartValues, asset).afterCleansingData)
  }, [scatterChartValues, asset])

  const handleChartMounted = (chartInstance: any) => {
    setChart(chartInstance)
  }

  /**
   * @description This useEffect is used to handle size of the chart.
   */
  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }
  }, [chart, width, height])

  return (
    <ChartContainer>
      {!loadingCleansingData && refreshData && (
        <RefreshLink disable_link={formInvalid ? 1 : 0} onClick={onRefresh}>{t`Click here to refresh`}</RefreshLink>
      )}

      <ChartContainer>
        {resizeListener}
        <HighchartsChart plotOptions={plotOptions} callback={handleChartMounted}>
          <GenericOptions showTooltip={!refreshData} />
          <ChartOptions options={chartOptions} />
          <LoadingOptions
            isLoading={getCleansedDataFailed || loadingCleansingData || refreshData}
            textForLoading={
              getCleansedDataFailed
                ? c('Meter data cleansing').t`Could not start cleansing service.`
                : loadingCleansingData
                ? t`Loading...`
                : 'refresh' // this label will be transparent when 'Click here to refresh link' is shown
            }
            minHeight={chart?.plotHeight}
            hideDefaultLoadingText={!loadingCleansingData && refreshData}
          />

          <TimeOptions timezone={timezone} />
          <XAxis>
            <XAxis.Title>
              {isSolarAsset(asset)
                ? `GHI ${AppUnits.WATT_PER_SQUARE_METER}`
                : c('Meter data cleansing').t`Wind speed (m/s)`}
            </XAxis.Title>
          </XAxis>

          <YAxis>
            <YAxis.Title>{c('Meter data cleansing').t`Power (kW)`}</YAxis.Title>
            <ScatterSeries
              name={c('Meter data cleansing').t`Qualified data`}
              color="rgb(50 131 206)"
              data={originalData}
            />
            <ScatterSeries
              name={c('Meter data cleansing').t`Disqualified data`}
              color="#a29e9e"
              data={afterCleansing}
            />
          </YAxis>
        </HighchartsChart>
      </ChartContainer>
    </ChartContainer>
  )
}

export default React.memo(WeatherGuidedCleansingChart)
