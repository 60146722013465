import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'

import Flex from 'ui/styles/Flex'
import DetailsLink from 'ui/DetailsLink'

const RowItemContainer = styled(Flex)`
  height: 100%;
  position: relative;
`
const ContentContainer = styled(Flex)`
  max-width: 100%;
`

interface DetailsLinkContainerProps {
  isDataStreamRow?: boolean
}

const DetailsLinkContainer = styled.div<DetailsLinkContainerProps>`
  position: ${(props) => (props.isDataStreamRow ? 'relative' : 'absolute')};
  right: 0;
  backdrop-filter: blur(1.5px);
`

interface SidebarRowItemProps {
  children: React.ReactNode
  lightMode: boolean
  isVisible: boolean
  showIcon?: boolean
  isDataStreamRow?: boolean

  onToggleDetails: () => void
}

const SidebarRowItem: React.FC<SidebarRowItemProps> = ({
  children,
  lightMode,
  isVisible,
  showIcon = false,
  isDataStreamRow = false,
  onToggleDetails,
}) => {
  return (
    <RowItemContainer direction="row" alignItems="center">
      <ContentContainer direction="row" alignItems="center" flexGrow={1}>
        {children}
      </ContentContainer>
      {showIcon && isVisible && (
        <DetailsLinkContainer isDataStreamRow={isDataStreamRow}>
          <DetailsLink lightMode={lightMode} title={t`Show details`} onToggle={onToggleDetails} />
        </DetailsLinkContainer>
      )}
    </RowItemContainer>
  )
}

export default React.memo(SidebarRowItem)
