import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import { RETABLE_ID_EVALUATION, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import styled from 'styled-components'
import ReTable from 'modules/reTable/ReTable'
import { table } from 'themes/theme-light'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { t } from 'ttag'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch, useSelector } from 'react-redux'
import {
  evaluationHierarchy,
  filteredSelectedDataStreamForEvaluationTable,
  getEvaluationTableColumns,
} from 'utils/evaluation'
import EvaluationTableBody from 'modules/workspace/advancedChartWidgets/evaluation/table/EvaluationTableBody'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { appTourColors } from 'modules/app/tour/OnBoardingTourDialog'
import Flex from 'ui/styles/Flex'
import InlineLoading from 'ui/InlineLoading'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'
import { useDataStreams } from 'utils/dataStream'
import { sortArrayBasedOnAnotherArray } from 'utils/array'

export interface EvaluationTableProps {
  tableItems: ReTableItem[]
  showTableData: boolean
  isLoading: boolean
}

const StyledEvaluationTable = styled(ReTable)`
  & .MuiTable-root {
    width: 100%;
  }
`

const ErrorMessage = styled.div`
  color: ${appTourColors.darkBlue};
  margin-left: 42px;
`

const EvaluationTable: React.FC<EvaluationTableProps> = ({ tableItems, showTableData, isLoading }) => {
  const dispatch = useDispatch()

  const dataStreams = useDataStreams()
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)

  const user = useSelector(getUserResultSelector)
  // Are needed for backcast
  const lineChartSettings = useLineChartSettings()
  const hasAccessToBackCast = hasPermissionForSiteAssessmentBackcast(user)

  const isBackcastActive = lineChartSettings?.data?.showBackCast && hasAccessToBackCast

  const [itemsToRender, setItemsToRender] = useState<ReTableItem[]>([])

  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes

  const sortedSelectedDataStreams = useMemo(() => {
    return sortArrayBasedOnAnotherArray(selectedDataStreams, dataStreams, 'id')
  }, [dataStreams, selectedDataStreams])

  const handleItemsToRenderChange = useCallback((items: ReTableItem[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  const collapsibleIds = useMemo(() => {
    const availableIds: string[] = []
    tableItems.forEach((item) => {
      if (item.id.startsWith(evaluationHierarchy.PARENT) || item.id.startsWith(evaluationHierarchy.FIRST_LEVEL_CHILD)) {
        availableIds.push(item.id)
      }
    })

    return availableIds
  }, [tableItems])

  const filteredColumns = useMemo(() => {
    const filteredSelectedDataStream = filteredSelectedDataStreamForEvaluationTable(sortedSelectedDataStreams, user)
    return getEvaluationTableColumns(filteredSelectedDataStream, isBackcastActive, user)
  }, [sortedSelectedDataStreams, isBackcastActive, user])

  const columnsAvailable = useMemo(() => {
    filteredColumns.forEach((column, index) => {
      if (index === filteredColumns.length - 1) {
        column.customDynamicWidth = `auto`
      }
    })

    return filteredColumns
  }, [filteredColumns])

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_EVALUATION)

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_EVALUATION, columnsAvailable })
  }, [columnsAvailable])

  useEffect(() => {
    const prevSelectedColumns = columnsSelected.map((c) => c.name)
    const columns = columnsAvailable.filter((c) => c.fixed || prevSelectedColumns.includes(c.name)).map((c) => c.name)

    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_EVALUATION,
      columnsSelected: columns,
    })
  }, [columnsAvailable, columnsSelected])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'period',
    }),
    [],
  )

  return (
    <>
      <Flex direction="row" alignItems="center">
        <Box width="2em">{isLoading && <InlineLoading />}</Box>

        {/*<EvaluationTablePeriodInput />*/}

        {/*<PopperTooltip*/}
        {/*  popperLabel={*/}
        {/*    <Box ml={1} mt={0.3}>*/}
        {/*      <FontAwesomeActionIcon icon="info" size="sm" />*/}
        {/*    </Box>*/}
        {/*  }*/}
        {/*  popperContent={t`Additional information`}*/}
        {/*/>*/}
      </Flex>

      <DynamicTableHeightContainer numberOfRows={itemsToRender.length > 0 ? itemsToRender.length : 1}>
        <StyledEvaluationTable
          id={RETABLE_ID_EVALUATION}
          items={tableItems}
          itemHeight={table.rowHeight}
          hasExtendedHeader={false}
          itemsPaddingHeader={ITEMS_PADDING_HEADER}
          itemsPaddingFooter={ITEMS_PADDING_FOOTER}
          renderAheadCount={RENDER_AHEAD_COUNT}
          collapsibleIds={collapsibleIds}
          defaultCollapsed={[]}
          defaultSort={defaultSort}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ReTableGenericHeader
            tableHeaderHasActions={true}
            isHierarchical={false}
            columns={columnsSelected}
            tableRowsHasCrudActions={false}
            inheritWidthForHeaderWithSort={true}
          />
          {showTableData ? (
            <EvaluationTableBody items={itemsToRender} columns={columnsSelected} tableHeaderHasActions={true} />
          ) : (
            <ReTableBody>
              <ReTableRow>
                <ReTableCell colSpan={columnsSelected.length + 1}>
                  {!isLoading && <ErrorMessage>{t`No data within the selected time range.`}</ErrorMessage>}
                </ReTableCell>
              </ReTableRow>
            </ReTableBody>
          )}
        </StyledEvaluationTable>
      </DynamicTableHeightContainer>
    </>
  )
}

export default EvaluationTable
