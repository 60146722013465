import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import { request } from 'utils/request'
import createReducer from 'utils/createReducer'
import { AreaConfig } from '../../dataStreams/dataStreams.types'
import { Coordinate } from 'modules/asset/store/asset.types'
import { createSelector } from 'reselect'

// state

interface State {
  result: Record<string, Coordinate[] | []>
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: {},
  loading: false,
  error: null,
}

// types

export const GET_WEATHER_COORDINATES_REQUEST = 'GET_WEATHER_COORDINATES_REQUEST'
export const GET_WEATHER_COORDINATES_SUCCESS = 'GET_WEATHER_COORDINATES_SUCCESS'
export const GET_WEATHER_COORDINATES_FAILURE = 'GET_WEATHER_COORDINATES_FAILURE'
export const GET_WEATHER_COORDINATES_DISMISS = 'GET_AREA_FORECAST_BY_ID_DISMISS'
export type GET_WEATHER_COORDINATES_REQUEST = typeof GET_WEATHER_COORDINATES_REQUEST
export type GET_WEATHER_COORDINATES_SUCCESS = typeof GET_WEATHER_COORDINATES_SUCCESS
export type GET_WEATHER_COORDINATES_FAILURE = typeof GET_WEATHER_COORDINATES_FAILURE
export type GET_WEATHER_COORDINATES_DISMISS = typeof GET_WEATHER_COORDINATES_DISMISS
export type GET_WEATHER_COORDINATES_ACTION_TYPE =
  | GET_WEATHER_COORDINATES_REQUEST
  | GET_WEATHER_COORDINATES_SUCCESS
  | GET_WEATHER_COORDINATES_FAILURE
  | GET_WEATHER_COORDINATES_DISMISS

interface GetWeatherCoordinatesAction {
  type: GET_WEATHER_COORDINATES_ACTION_TYPE
  // REQUEST
  location: Coordinate
  // SUCCESS
  weatherCoordinates: Record<string, Coordinate[] | []>
  // ERROR
  error: string | null
}

// reducers

const result = createReducer<State['result'], GetWeatherCoordinatesAction>(
  (state = initialState.result, { type, weatherCoordinates }) => {
    if (type === GET_WEATHER_COORDINATES_SUCCESS) {
      return weatherCoordinates
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetWeatherCoordinatesAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case GET_WEATHER_COORDINATES_REQUEST:
        return true
      case GET_WEATHER_COORDINATES_SUCCESS:
      case GET_WEATHER_COORDINATES_FAILURE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'], GetWeatherCoordinatesAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case GET_WEATHER_COORDINATES_SUCCESS:
      case GET_WEATHER_COORDINATES_DISMISS:
        return null
      case GET_WEATHER_COORDINATES_FAILURE:
        return error
    }
    return state
  },
)

export const getWeatherCoordinatesReducer = combineReducers({
  result,
  loading,
  error,
})

export const getWeatherCoordinatesResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.data.weatherCoordinates.result,
  (result) => result,
)
export const getWeatherCoordinatesLoadingSelector = createSelector<any, boolean, boolean>(
  (state) => state.data.weatherCoordinates.loading,
  (loading) => loading,
)
export const getWeatherCoordinatesErrorSelector = createSelector<any, string | null, string | null>(
  (state) => state.data.weatherCoordinates.error,
  (error) => error,
)

// api
export const getWeatherCoordinates = (location: Coordinate) => {
  return request(() =>
    axios.get<AreaConfig[]>(
      `api/weather-data-catalog/v1/utils/NWPGridPoints/${location.latitude}/${location.longitude}`,
    ),
  )
}

// sagas
export function* getWeatherCoordinatesSaga({ location }: GetWeatherCoordinatesAction): SagaIterator {
  const result = yield call(getWeatherCoordinates, location)
  if (result.isSuccessful) {
    // result may be empty
    const weatherCoordinates = result.getData() || {}
    yield put({ type: GET_WEATHER_COORDINATES_SUCCESS, weatherCoordinates })
  } else {
    const error = result.getError()
    yield put({ type: GET_WEATHER_COORDINATES_FAILURE, error })
  }
}
