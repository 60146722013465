import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps, Menu, MenuItem, MenuList } from '@material-ui/core'
import { MenuProps } from '@material-ui/core/Menu/Menu'
import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { t } from 'ttag'
import LoadingButton from 'ui/form/LoadingButton'

import { SelectAsListItem } from 'ui/form/SelectAsList'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

const CaretButton = styled(Button)<ButtonProps>`
  &.MuiButton-containedSizeSmall {
    padding: 4px 0;
  }
  &.MuiButtonGroup-grouped {
    min-width: 24px;
  }
`

const StyledMenu = styled(Menu)<MenuProps>`
  & > .MuiPaper-root {
    max-width: 32em;
  }

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`

interface StyledButtonProps {
  isError: boolean
}
const buttonProps = (props: StyledButtonProps) => `
  ${
    props.isError
      ? `
    &:hover {
      background-color: ${theme.palette.error.main};
    }
  `
      : ''
  }

`

const StyledButtonGroup = styled(({ ...rest }) => <ButtonGroup {...rest} />)<StyledButtonProps>`
  &.MuiButtonGroup-root .MuiButton-root {
    ${(props) => buttonProps(props)}
  }

  & .MuiButtonGroup-contained {
    box-shadow: ${(props) =>
      props.disabled
        ? 'none'
        : '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'};
  }
`

const Error = styled.div`
  color: ${theme.palette.error.main};
`

const TooltipWrapper = styled.div`
  max-width: 50rem;
`

interface SplitButtonProps {
  items: SelectAsListItem[]
  loading: boolean
  startIcon?: React.ReactNode
  onSelectItem: (item: SelectAsListItem) => void
  error?: string | null
  isInvalid?: boolean
  mainItemKey?: string
}
const SplitButton: React.FC<SplitButtonProps & ButtonGroupProps> = ({
  items,
  loading,
  onSelectItem,
  error,
  isInvalid,
  mainItemKey,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const firstItem = mainItemKey ? items.find((item) => item.key === mainItemKey) : items[0]
  const restMenuItems = items.filter((item) => item.key !== firstItem.key)
  // const [firstItem, ...menuItems] = items
  const { variant = 'contained', ...buttonGroupProps } = rest

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>,
    item: SelectAsListItem,
  ) => {
    event.preventDefault()
    onSelectItem(item)
    setOpen(false)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  // error handling
  const anyError = Boolean(error || isInvalid)
  const TooltipContent = useMemo(() => {
    return anyError ? (
      <TooltipWrapper>
        {isInvalid && (
          <>
            <Error>{t`Validation failed. Please correct the issues below.`}</Error>
          </>
        )}
        {error && (
          <>
            <Error>{error}</Error>
          </>
        )}
      </TooltipWrapper>
    ) : (
      <></>
    )
  }, [anyError, isInvalid, error])

  const btnIcon = useMemo(() => {
    let fontAwesomeIcon: IconProp = 'save'
    if (anyError) {
      fontAwesomeIcon = 'exclamation-triangle'
    }
    return <FontAwesomeIcon color={anyError ? theme.palette.error.dark : 'inherit'} icon={fontAwesomeIcon} fixedWidth />
  }, [anyError])

  return (
    <>
      <ConditionalWrapper
        condition={anyError}
        wrapper={(children) => (
          <PopperTooltip
            popperLabel={children}
            popperContent={TooltipContent}
            position={PopperTooltipPosition.BOTTOM_START}
          />
        )}
      >
        <StyledButtonGroup isError={!loading && anyError ? 1 : 0} {...buttonGroupProps}>
          <LoadingButton group={true} loading={loading} variant={variant} color="primary">
            <Button
              disabled={anyError}
              ref={anchorRef}
              startIcon={btnIcon}
              onClick={(event) => handleMenuItemClick(event, firstItem)}
            >
              {firstItem?.label || ''}
            </Button>
            <CaretButton
              color="primary"
              size="small"
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="menu"
              onClick={handleToggle}
              disabled={false}
            >
              <FontAwesomeIcon icon="caret-down" />
            </CaretButton>
          </LoadingButton>

          <StyledMenu
            anchorEl={anchorRef.current}
            getContentAnchorEl={null}
            variant="menu"
            open={open}
            onClose={handleClose}
          >
            <MenuList>
              {restMenuItems.map((item) => (
                <MenuItem disabled={anyError} key={item.key} onClick={(event) => handleMenuItemClick(event, item)}>
                  {item.label}
                </MenuItem>
              ))}
            </MenuList>
          </StyledMenu>
        </StyledButtonGroup>
      </ConditionalWrapper>
    </>
  )
}

export default React.memo(SplitButton)
