import React, { useEffect, useState } from 'react'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { Box, Chip, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { c, t } from 'ttag'

import AssetItem from 'modules/asset/AssetItem'
import { Asset } from 'modules/asset/store/asset.types'
import AssetIcon from 'modules/asset/AssetIcon'
import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'

const StyledAutocomplete = styled(Autocomplete)<AutocompleteProps<Asset>>`
  & .MuiFormControl-marginNormal {
    margin-top: 0;
  }
  min-width: 5em;

  width: ${(props) => (props.unitspace ? `calc(100% - 2.28em)` : '100%')};
`
const AssetChip = styled(Chip)`
  margin: 5px;
`

interface AssetAutocompleteProps {
  onAssetSelect: (ids: string[]) => void
  onValidate?: (selectedAssets: string[], selectChild: boolean) => void
  selectedIds: string[]
  autocompleteAssetList: Asset[]
  multiple?: boolean
  disabled?: boolean
  selectChild?: boolean
  unitspace?: boolean
  compact?: boolean
}

// TODO use <FinalFormAutocompleteField>? but only if this is always embedded in a form
const AssetAutocomplete: React.FC<AssetAutocompleteProps> = ({
  onAssetSelect,
  onValidate,
  selectedIds = [],
  autocompleteAssetList,
  multiple,
  disabled = false,
  selectChild = false,
  unitspace = true,
  compact = false,
}) => {
  const emptyValue = multiple ? [] : null
  const [selectedAssets, setSelectedAssets] = useState<Asset[] | Asset | null | undefined>(undefined)
  // console.log('selectedIds =', selectedIds)
  // console.log('autocompleteAssetList =', autocompleteAssetList)
  // console.log('selected assets =', selectedAssets)
  // console.log('multiple assets =', multiple)

  const handleAssetsChange = (event: React.ChangeEvent, newAssets: Asset[] | Asset) => {
    let assetIds: string[] = []
    if (Array.isArray(newAssets)) {
      assetIds = newAssets.map((asset: Asset) => asset.id)
      setSelectedAssets(newAssets)
    } else if (newAssets?.id) {
      assetIds = [newAssets.id]
      setSelectedAssets(newAssets)
    } else {
      setSelectedAssets(emptyValue)
    }
    onAssetSelect(assetIds)
  }

  useEffect(() => {
    let selectedAssetData: Asset[] = []
    if (autocompleteAssetList.length) {
      selectedAssetData = (selectedIds || [])
        .map((id) => autocompleteAssetList.find((a) => a.id === id))
        .filter((a) => typeof a !== 'undefined')
      if (multiple) {
        setSelectedAssets(selectedAssetData || emptyValue)
      } else {
        setSelectedAssets(selectedAssetData.length > 0 ? selectedAssetData[0] : emptyValue)
      }
    }
    if (onValidate) {
      onValidate(
        selectedAssetData.map((a) => a.id),
        selectChild,
      )
    }
  }, [selectedIds.join(','), multiple, JSON.stringify(autocompleteAssetList)])

  // hooks make autocompleteAssetList and selectedAssets out of sync
  // therefore we need to check if the selection is valid
  // note: this can't be memoized
  const selectionValid =
    typeof selectedAssets !== 'undefined' &&
    !((Array.isArray(selectedAssets || []) ? selectedAssets || [] : [selectedAssets]) as Asset[]).some(
      (asset: Asset) => !autocompleteAssetList.map((a) => a.id).includes(asset.id),
    ) &&
    ((multiple && Array.isArray(selectedAssets)) || (!multiple && !Array.isArray(selectedAssets)))

  return (
    <Box>
      {autocompleteAssetList.length === 0 ? (
        <em>{c('Asset:Autocomplete').t`No assets found`}</em>
      ) : (
        selectionValid && (
          <StyledAutocomplete
            options={autocompleteAssetList}
            value={selectedAssets}
            multiple={multiple}
            getOptionLabel={(option) => option?.name || ''}
            getOptionSelected={(option, value) => Boolean(option?.id && value?.id && option.id === value.id)}
            onChange={handleAssetsChange}
            disabled={disabled}
            disableCloseOnSelect
            unitspace={unitspace ? 1 : 0}
            renderInput={(params) => (
              <TextField {...params} placeholder={t`Select assets...`} margin="normal" fullWidth />
            )}
            renderOption={(asset, { inputValue }) => (
              <AssetItem asset={asset} highlight={inputValue} compact={compact} />
            )}
            renderTags={(value, getTagProps) =>
              value.map((asset: Asset, index) => (
                <React.Fragment key={index}>
                  {asset !== undefined && (
                    <AssetChip
                      icon={
                        <Box>
                          <AssetIcon asset={asset} size={AssetLinkIconSize} selected={true} />
                        </Box>
                      }
                      color="primary"
                      label={asset.name}
                      {...getTagProps({ index })}
                    />
                  )}
                </React.Fragment>
              ))
            }
          />
        )
      )}
    </Box>
  )
}

export default React.memo(AssetAutocomplete)
