import React, { useMemo } from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

import { Column } from 'modules/reTable/reTable.types'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import Flex from 'ui/styles/Flex'
import Spacer from 'ui/Spacer'
import { colors, table } from 'themes/theme-light'
import { QUERY_ASSET, useQueryMatch } from 'utils/query-string'
import AssetTreeHeadActions from 'modules/asset/tree/AssetTreeHeadActions'

// FIXME hack for multiline headers
// & .MuiTableCell-root {
//   padding: 0;
// }
const TableHeader = styled(ReTableHeader)`
  & .MuiTableCell-root:last-child {
    padding-right: 0;
  }
`

const HeaderWrapper = styled(Flex)`
  width: 100%;
`

export const HeaderTitle = styled.div`
  position: absolute;
  top: -${table.rowHeight}px;
  padding-top: 6px !important; /* FIXME hack with !important to deal with padding override in <TableHeader /> */
  width: 100%;
  height: ${table.rowHeight}px;
  color: dimgrey;
  background-color: ${colors.markedLight};
  text-align: center;
  font-size: 85%;
`

export const TreeToggleButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 24px;
    padding: 1px 1px;
  }
`

// types
interface TreeRootProps {
  isWide: boolean
  columnsNarrow: Column[]
  columnsWide: Column[]
}

// exported component
const AssetTreeHead: React.FC<TreeRootProps> = ({ isWide, columnsNarrow, columnsWide }) => {
  const isAssetDetails = useQueryMatch(QUERY_ASSET)
  const widthPrimaryColumn = useMemo(() => {
    return isAssetDetails && isWide ? '16em' : '100%'
  }, [isAssetDetails, isWide])

  return (
    <TableHeader>
      <ReTableRow>
        <AssetTreeHeadActions />

        <ReTableCell width={widthPrimaryColumn} no_border={!isWide}>
          <Flex direction="column">
            <HeaderTitle />
            <Flex direction="row" alignItems="center">
              {columnsNarrow.map((column) => (
                <ReTableHeaderWithSort key={column.label} column={column} />
              ))}
              <Spacer />
            </Flex>
          </Flex>
        </ReTableCell>
        {isWide &&
          columnsWide.map((column, index) => (
            <ReTableCell
              key={column.label}
              width={isAssetDetails && index === columnsWide.length - 1 ? '100%' : column.width}
            >
              <Flex direction="column" alignItems="center">
                <HeaderTitle key={column.label} title={column.label}>
                  {column.subItems ? column.label : ''}
                </HeaderTitle>
                <HeaderWrapper direction="row">
                  {column.subItems ? (
                    column.subItems.map((subItem) => (
                      <ReTableHeaderWithSort key={subItem.name} column={column} subItem={subItem} />
                    ))
                  ) : (
                    <ReTableHeaderWithSort column={column} />
                  )}
                </HeaderWrapper>
              </Flex>
            </ReTableCell>
          ))}
      </ReTableRow>
    </TableHeader>
  )
}

export default React.memo(AssetTreeHead)
