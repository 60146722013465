import React, { useCallback, useMemo } from 'react'
import { Checkbox, Select } from 'final-form-material-ui'
import { Field, useField } from 'react-final-form'
import { Box, MenuItem } from '@material-ui/core'
import { c } from 'ttag'
import FinalFormAutocompleteField from 'ui/elements/FinalFormAutocompleteField'
import { FormKey, FormKeyValueGrid, FormValue, SiteForecastSelectField } from 'ui/form/assetForm.style'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isIndianCustomer } from 'utils/user'
import { getIndiaExportFormats } from 'utils/siteForecastConfiguration'
import { getDeprecatedExportFormats } from 'utils/siteForecastConfiguration'
import {
  getDeliveryTargetQueryObj,
  QUERY_SITE_FORECAST_TEMPLATE,
  useQueryMatch,
  useQueryString,
} from 'utils/query-string'
import { useDeliveryTargets } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import Flex from 'ui/styles/Flex'
import { CheckboxControl } from 'ui/form/authForm.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import { FormApi } from 'final-form'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'

interface SectionGeneralProps {
  deliveryFormatsToDisplay: DeliveryFormat[]
  form: FormApi<ForecastConfig>
}

const SectionDeliveryTargetAndFormat: React.FC<SectionGeneralProps> = ({ deliveryFormatsToDisplay }) => {
  const selectedDeliveryEndPoints = useField<string[]>('deliveryEndpoints').input.value
  const user = useSelector(getUserResultSelector)
  const isTemplate = useQueryMatch(QUERY_SITE_FORECAST_TEMPLATE)

  const { onUpdateQueryString } = useQueryString()
  const deliveryTargets = useDeliveryTargets()

  const exportFormats = useMemo(() => {
    if (isIndianCustomer(user)) {
      return [...getDeprecatedExportFormats(), ...getIndiaExportFormats()]
    } else {
      return [...getDeprecatedExportFormats()]
    }
  }, [user])

  const redirectHandler = useCallback(
    (item: any) => {
      onUpdateQueryString(getDeliveryTargetQueryObj(item))
    },
    [onUpdateQueryString],
  )

  return (
    <>
      {!isTemplate && (
        <FormKeyValueGrid>
          <FormKey>{c('Site Forecast:Details').t`Not operational:`}</FormKey>
          <FormValue>
            <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
              <CheckboxControl
                label={''}
                control={<Field name="notOperational" type="checkbox" component={Checkbox} />}
              />
              <Box>
                <PopperTooltip
                  popperLabel={
                    <Box ml={0.7} mt={0.3}>
                      <FontAwesomeIcon color={theme.palette.primary.main} icon="info" size="sm" />
                    </Box>
                  }
                  popperContent={
                    <div>{c('Site Forecast:Details')
                      .t`When checked this configuration will not be used for operational forecast calculation and delivery. It can only be used to calculate backcasts with the timings configured here.`}</div>
                  }
                />
              </Box>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {!isTemplate && (
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`Delivery target:`}</FormKey>
          <FormValue className="appTour-addDeliveryTarget-add-and-save">
            {deliveryTargets.isSuccess && (
              <Field fullWidth name="deliveryEndpoints">
                {(props) => {
                  return (
                    <FinalFormAutocompleteField
                      availableItems={deliveryTargets.data || []}
                      selectedIds={selectedDeliveryEndPoints || []}
                      multiple={true}
                      input={props.input}
                      meta={props.meta}
                      onRedirect={redirectHandler}
                    />
                  )
                }}
              </Field>
            )}
          </FormValue>
        </FormKeyValueGrid>
      )}

      {isTemplate && (
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`File Format (DEPRECATED):`}</FormKey>
          <FormValue>
            <SiteForecastSelectField>
              {deliveryFormatsToDisplay.length > 0 && (
                <Field name="exportFormatConfiguration" disabled component={Select} fullWidth>
                  {exportFormats.map((exportFormat) => (
                    <MenuItem key={exportFormat.name} value={exportFormat.name}>
                      {exportFormat.label}
                    </MenuItem>
                  ))}
                </Field>
              )}
            </SiteForecastSelectField>
          </FormValue>
        </FormKeyValueGrid>
      )}

      <FormKeyValueGrid>
        <FormKey>{c('Delivery').t`File Format:`}</FormKey>
        <FormValue>
          <SiteForecastSelectField>
            {deliveryFormatsToDisplay.length > 0 && (
              <Field name="exportFormatConfigId" component={Select} fullWidth>
                {deliveryFormatsToDisplay.map((format) => (
                  <MenuItem key={format.id} value={format.id}>
                    {format.name}
                  </MenuItem>
                ))}
              </Field>
            )}
          </SiteForecastSelectField>
        </FormValue>
      </FormKeyValueGrid>
    </>
  )
}

export default React.memo(SectionDeliveryTargetAndFormat)
