import React from 'react'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

import Highlight from 'ui/Highlight'
import PopperTooltip from 'ui/PopperTooltip'
import { formatDate, timeAgo } from 'utils/date'
import Flex from 'ui/styles/Flex'

const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 5px;
`

const Container = styled.div`
  display: inline-block;
`

interface DateSingleProps {
  date: Date
  selected?: boolean
  highlight?: string
}

const DateSingle: React.FC<DateSingleProps> = ({ date, selected = false, highlight = '', ...rest }) => {
  if (!date) return <></>

  const formattedDate = formatDate(date)
  const daysAgoDate = timeAgo(date)
  return (
    <Container {...rest}>
      <PopperTooltip
        popperLabel={
          <Flex>
            <Highlight text={daysAgoDate} query={highlight} />
            <TooltipIcon icon="info" color={selected ? 'white' : theme.palette.primary.main} size="sm" />
          </Flex>
        }
        popperContent={formattedDate}
      />
    </Container>
  )
}

export default React.memo(DateSingle)
