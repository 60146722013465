import React, { useState } from 'react'

import { Asset } from 'modules/asset/store/asset.types'
import AssetLink from 'modules/asset/AssetLink'
import { Box, Card, CardContent, Chip } from '@material-ui/core'
import { jt, t } from 'ttag'
import AssetIcon from 'modules/asset/AssetIcon'
import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'
import { getTypeLabel, isAsset, isCHP, isCluster, isPark, isSolarAsset, isWindAsset } from 'utils/asset'
import { SAVE_WORKSPACE_DRAFT_SELECT_ASSET } from 'modules/workspace/store/workspace.types'
import { useDispatch } from 'react-redux'
import { ShowMoreLessLink } from 'ui/styles/ShowLessMoreLink'
import { FORBIDDEN_ERROR_MSG } from 'utils/request'

const assetChipsDisplayLimit = 20

interface BulkMdcRowProps {
  asset: Asset
  allAssets: Asset[]
  assetSelection: string[]
  hasAccessToBulkWindMdc: boolean
  hasAccessToBulkPvMdc: boolean
}

const BulkMdcUnSupportedAssetRow: React.FC<BulkMdcRowProps> = ({
  asset,
  allAssets,
  assetSelection,
  hasAccessToBulkWindMdc,
  hasAccessToBulkPvMdc,
}) => {
  const dispatch = useDispatch()
  const assetHasChildren = asset?.generatorIds?.length > 0 || asset?.parkIds?.length > 0
  const childAssetIds = assetHasChildren ? [...asset?.generatorIds, ...asset?.parkIds] : []
  const childAssets = childAssetIds.map((id) => allAssets.find((a) => a && a?.id === id))
  const sortedChildAssets = (childAssets || [])
    .filter((a) => a && isAsset(a))
    .sort((a, b) => (a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : 1))
  const allChildrenSelected = childAssets.every((a) => a && a?.id && assetSelection.includes(a.id))
  const assetType = getTypeLabel(asset?.type)

  const [childAssetsToDisplayLimit, setChildAssetsToDisplayLimit] = useState(assetChipsDisplayLimit)

  const handleSelectAsset = (assetToSelect: Asset) => {
    dispatch({
      type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
      asset: assetToSelect,
      selectionOptions: { ctrlKey: true },
    })
  }

  const moreCount = sortedChildAssets.length - childAssetsToDisplayLimit
  const userHasAccessToThisTypeOfAsset = isWindAsset(asset)
    ? hasAccessToBulkWindMdc
    : isSolarAsset(asset)
    ? hasAccessToBulkPvMdc
    : true
  const assetTypeLabel = isSolarAsset(asset) ? t`solar assets` : t`wind assets`

  return (
    <Card style={{ marginBottom: '1em', marginTop: '1em' }}>
      <CardContent>
        <b>
          <AssetLink asset={asset} />
        </b>
        {userHasAccessToThisTypeOfAsset ? (
          <>
            <Box mt={0.8}>
              {isCluster(asset)
                ? t`You cannot perform bulk meter data cleansing for Clusters.`
                : isPark(asset)
                ? t`You cannot perform bulk meter data cleansing for Parks with individual plants.`
                : isCHP(asset)
                ? t`You cannot perform bulk meter data cleansing for CHP plant.`
                : ''}
              {'  '}
              {childAssetIds.length > 0 && <>{allChildrenSelected ? '' : t`Please select the individual plants.`}</>}
            </Box>

            {childAssetIds.length > 0 && (
              <Box mt={0.8}>
                <>{jt`Assets in this ${assetType}`}:</>
                {sortedChildAssets
                  .filter((a) => a && isAsset(a))
                  .slice(0, childAssetsToDisplayLimit)
                  .map((childAsset) => {
                    const assetSelected = childAsset && assetSelection.includes(childAsset.id)
                    return (
                      <React.Fragment key={childAsset.id}>
                        {assetSelected ? (
                          <Chip
                            style={{ margin: '5px' }}
                            color="primary"
                            icon={
                              <Box>
                                <AssetIcon asset={childAsset} size={AssetLinkIconSize} selected={assetSelected} />
                              </Box>
                            }
                            label={childAsset.name}
                          />
                        ) : (
                          <Chip
                            style={{ margin: '5px', cursor: 'pointer' }}
                            onClick={() => handleSelectAsset(childAsset)}
                            icon={
                              <Box>
                                <AssetIcon asset={childAsset} size={AssetLinkIconSize} />
                              </Box>
                            }
                            label={childAsset.name}
                          />
                        )}
                      </React.Fragment>
                    )
                  })}
                {sortedChildAssets.length === childAssetsToDisplayLimit ? (
                  <ShowMoreLessLink onClick={() => setChildAssetsToDisplayLimit(assetChipsDisplayLimit)}>
                    {t`Show less...`}
                  </ShowMoreLessLink>
                ) : (
                  <>
                    {moreCount > 0 && (
                      <ShowMoreLessLink
                        onClick={() => setChildAssetsToDisplayLimit(sortedChildAssets.length)}
                      >{t`Show ${moreCount} more...`}</ShowMoreLessLink>
                    )}
                  </>
                )}
              </Box>
            )}
          </>
        ) : (
          <Box mt={0.8}>
            {jt`You cannot perform bulk meter data cleansing for ${assetTypeLabel}.`}
            {'  '} {FORBIDDEN_ERROR_MSG}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

export default BulkMdcUnSupportedAssetRow
