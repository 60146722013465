import { Asset } from 'modules/asset/store/asset.types'
import { assetTabNames, getAssetTabs } from 'fixtures/assetForm'
import { useAllAssets } from 'modules/asset/api/assets.api'
import { useMemo } from 'react'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'
import { useAvailabilitiesByAssetsTableItems } from 'modules/asset/availability/api/availability.api'
import { useSelector } from 'react-redux'
import { getMeterdataFilesLoadingSelector } from 'modules/data/fileManager/redux_store/state/getMeterdataFiles.state'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { Availability } from 'modules/asset/availability/Availability.types'
import { getDescendants, isAsset, isGenerator } from 'utils/asset'

export const useAssetTabs = (asset: Asset) => {
  const { meterData, availability, forecastModels } = assetTabNames
  const allAssets = useAllAssets()

  const assetDescendants = useMemo(() => {
    if (!isAsset(asset)) return []
    return isGenerator(asset) ? [asset] : [asset, ...getDescendants(asset, allAssets.data || [])]
  }, [asset, allAssets.data])

  const forecastModelsByAssetResult = useForecastModelsByAsset(asset)
  // We are filtering because in the count we only need to show the number of unhidden models.
  const forecastModelsData = useMemo(() => forecastModelsByAssetResult?.data?.filter((model) => model.hide !== true), [
    forecastModelsByAssetResult.data,
  ])
  const forecastModelsLoading = useMemo(() => forecastModelsByAssetResult.isLoading, [
    forecastModelsByAssetResult.isLoading,
  ])

  const availabilityResults = useAvailabilitiesByAssetsTableItems(assetDescendants)
  const availabilityLoading = useMemo(() => availabilityResults.some((res) => res.isLoading), [availabilityResults])
  const meterDataLoading = useSelector(getMeterdataFilesLoadingSelector)
  const user = useSelector(getUserResultSelector)

  const availabilities = useMemo(() => {
    let result = [] as Availability[]
    availabilityResults.forEach((res) => {
      if (res.isFetched && res.isSuccess && res.data) {
        result = [...result, ...res?.data]
      }
    })
    return result
  }, [availabilityResults])

  const assetTabs = useMemo(() => {
    const tabsWithCount = getAssetTabs(asset, user).map((tab) => {
      if (tab.name === availability) {
        if (availabilityLoading) {
          tab.loading = true
        } else {
          tab.count = availabilities.length
        }
      }
      if (tab.name === forecastModels) {
        if (forecastModelsLoading) {
          tab.loading = true
        } else {
          tab.count = forecastModelsData?.length
        }
      }
      if (tab.name === meterData) {
        if (meterDataLoading) {
          tab.loading = true
        }
      }
      return tab
    })

    return tabsWithCount
  }, [forecastModelsData, forecastModelsLoading, asset, availabilities, meterDataLoading, user])

  return assetTabs
}
