import React, { useCallback, useEffect, useState } from 'react'
import LayoutTitle from 'ui/LayoutTitle'
import { c, t } from 'ttag'

import { Box, FormControl, Input, InputLabel, MenuItem, Select, Typography } from '@material-ui/core'
import LoadingButton from 'ui/form/LoadingButton'
import Flex from 'ui/styles/Flex'
import {
  connectFiltersToDefault,
  QUERY_KEY_MDC_DEFAULT_FILTER_SETTINGS,
  useMdcTrainingValues,
  useUserMdcTrainingValue,
  useUserMdcTrainingValueSaveMutation,
} from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import {
  BULK_FILTER_SETTINGS_WIND,
  BULK_FILTER_SETTINGS_SOLAR,
  getMeterDataCleansingTrainingLabel,
} from 'utils/meterDataCleansing'
import { useIsReadOnlyUser } from 'utils/user'
import { useQueryClient } from 'react-query'

import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { MDCAssetType, UserMdcTrainingData } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'

const SelectFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    margin-bottom: 1rem;
  }
`

const MeterDataCleansingSettings: React.FC = () => {
  const queryClient = useQueryClient()
  const allTrainingValues = useMdcTrainingValues()?.data
  const userMdcTrainingValue = useUserMdcTrainingValue()?.data
  const user = useSelector(getUserResultSelector)

  const {
    mutate: saveUserMdcTrainingValue,
    isSuccess: saveUserMdcTrainingValueSuccess,
    isLoading: saveUserMdcTrainingValueLoading,
  } = useUserMdcTrainingValueSaveMutation()

  // MDC training value
  const [mdcTrainingInputValue, setMdcTrainingInputValue] = useState('')
  const [mdcTrainingInputValueChanged, setMdcTrainingInputValueChanged] = useState(false)

  const handleChangeMdcTrainingValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const field = event.target
    setMdcTrainingInputValueChanged(true)
    setMdcTrainingInputValue(field.value)
  }

  const handleSaveUserMdcTrainingValue = () => {
    const data: UserMdcTrainingData = {
      user: user?.login || '',
      setting: mdcTrainingInputValue,
    }

    saveUserMdcTrainingValue(data)
  }

  useEffect(() => {
    if (userMdcTrainingValue && userMdcTrainingValue !== mdcTrainingInputValue) {
      setMdcTrainingInputValue(userMdcTrainingValue)
    }
  }, [userMdcTrainingValue])

  useEffect(() => {
    if (saveUserMdcTrainingValueSuccess) {
      setMdcTrainingInputValueChanged(false)
    }
  }, [saveUserMdcTrainingValueSuccess])

  //Factory default
  const [loadFactoryDefault, setLoadFactoryDefault] = useState(false)
  const isReadOnlyUser = useIsReadOnlyUser()

  const resetToFactoryDefault = useCallback(() => {
    setLoadFactoryDefault(true)
    connectFiltersToDefault(['default']).then(() => {
      // Clear cache for default mdc
      queryClient.removeQueries([QUERY_KEY_MDC_DEFAULT_FILTER_SETTINGS, MDCAssetType.WIND])
      queryClient.removeQueries([QUERY_KEY_MDC_DEFAULT_FILTER_SETTINGS, MDCAssetType.SOLAR])

      // remove default filters from session (used in bulk) if user default is set to factory default
      if (sessionStorage.getItem(BULK_FILTER_SETTINGS_WIND)) {
        sessionStorage.removeItem(BULK_FILTER_SETTINGS_WIND)
      }
      if (sessionStorage.getItem(BULK_FILTER_SETTINGS_SOLAR)) {
        sessionStorage.removeItem(BULK_FILTER_SETTINGS_SOLAR)
      }

      // loading false
      setLoadFactoryDefault(false)
    })
  }, [])

  return (
    <Flex direction="column">
      <LayoutTitle>{c('User Settings').t`Meter data cleansing settings`}</LayoutTitle>
      {/*Training settings*/}
      <>
        <Typography variant={'subtitle1'}>{t`Training`}</Typography>
        <SelectFormControl fullWidth>
          <InputLabel id="cleansing-select-label">{t`Meter Data Cleansing for Training`}</InputLabel>
          <Select
            labelId="cleansing-select-label"
            id="cleansing"
            label={t`Meter Data Cleansing for Training`}
            disabled={isReadOnlyUser}
            input={
              <Input
                name="meterDataCleansingForTraining"
                value={mdcTrainingInputValue}
                onChange={handleChangeMdcTrainingValue}
              />
            }
          >
            {(allTrainingValues || []).map((trainingOption) => (
              <MenuItem key={trainingOption} value={trainingOption}>
                {getMeterDataCleansingTrainingLabel(trainingOption)}
              </MenuItem>
            ))}
          </Select>
        </SelectFormControl>
        <Flex direction="row" justifyContent="flex-end">
          <LoadingButton
            disabled={isReadOnlyUser || !mdcTrainingInputValueChanged}
            color="primary"
            size="small"
            variant="contained"
            onClick={handleSaveUserMdcTrainingValue}
            loading={saveUserMdcTrainingValueLoading}
          >
            {c('User Settings').t`Save`}
          </LoadingButton>
        </Flex>
      </>

      {/*Filter settings*/}
      <>
        <Typography variant={'subtitle1'}>{t`Filters`}</Typography>
        <Box mb={2} mt={1} display="flex">
          <LoadingButton
            color="primary"
            size="small"
            variant="contained"
            loading={loadFactoryDefault}
            onClick={resetToFactoryDefault}
            disabled={isReadOnlyUser}
          >{c('User Settings').t`Reset user default to factory default`}</LoadingButton>
        </Box>
      </>
    </Flex>
  )
}

export default MeterDataCleansingSettings
