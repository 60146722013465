import React, { useEffect, useState } from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import styled from 'styled-components'

const min = 0
const max = 100

// Disabling arrows from input type number
const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
})

const StyledTextField = styled(TextField)`
  width: 4em;
  padding-left: 13px !important;

  & .MuiInputBase-input {
    padding: 0 !important;
    text-align: center;
  }
`

interface NumberFieldProps {
  inputValue: number
  onChangeValue: (value: number) => void
}

const NumberField: React.FC<NumberFieldProps> = ({ inputValue, onChangeValue }) => {
  const classes = useStyles()

  const [value, setValue] = useState<number>()

  const handleOnChange = (e: React.ChangeEvent) => {
    // Prevent repeating 0 while typing
    if (e.target.value === '00') {
      e.target.value = '0'
    }

    let value = parseInt(e.target.value, 10)

    if (value > max) value = max
    if (value < min) value = min

    onChangeValue(value)
  }

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  return (
    <StyledTextField
      className={classes.input}
      size="small"
      type="number"
      inputProps={{ min, max }}
      value={value}
      onChange={(e) => handleOnChange(e)}
      variant="outlined"
    />
  )
}

export default NumberField
