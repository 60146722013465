import React, { useEffect, useMemo, useState } from 'react'
import {
  hasAllRoles,
  hasPermissionForE3ModelLevelForecast,
  hasPermissionTo3rdPartyForecasts,
  isAdmin,
  isImpersonatedAdmin,
} from 'utils/user'
import { Box, Paper, Typography } from '@material-ui/core'
import { c } from 'ttag'
import { useDispatch, useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useCustomMuiStyles } from 'themes/theme-light'
import { Asset } from 'modules/asset/store/asset.types'
import { useMetaForecastData } from 'utils/hooks/useMetaForecastData'
import styled from 'styled-components'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { usePrevious } from 'utils/state'
import { GET_FORECAST_MODELS_REQUEST } from 'modules/quality/redux_store/quality.action.types'
import DynamicSliders from 'modules/workspace/advancedChartWidgets/metaForecast/DynamicSliders'
import { useSortedSelectedDataStreams } from 'utils/hooks/datastreams/useSortedSelectedDataStreams'
import { filterQualityTableDataStreams } from 'utils/quality'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'

import { SAVE_WORKSPACE_DRAFT_REQUEST } from 'modules/workspace/store/workspace.types'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { getMixerWidgetMenuItems } from 'utils/forecastModel'
import { ForecastModel } from 'modules/quality/quality.types'
import { ForecastModelCategoryEnum } from 'modules/forecastModels/forecastModels.types'

export const MetaForecastWidgetWidth = '20em'
export const MetaForecastSliderContainer = styled(Paper)`
  width: ${MetaForecastWidgetWidth};
  min-height: 90px;
  margin-right: 0.5em;
  padding: 0 0.5em;
  &.MuiPaper-root {
    border: none;
    background: none;
  }
`

const InputSelector = styled(FormControl)`
  &.MuiFormControl-root {
    margin-top: -1em;
    width: 13em;
  }
`

interface MetaForecastConfigurationProps {
  selectedAssets: Asset[]
  selectedModels: ForecastModel[]
  selectedAssetsAndModelIds: string[]
  loadSavedWeights: boolean
  hasAccessToThirdPartyForecast: boolean
  hasAccessToE3WeatherModelForecast: boolean
  meterDataWithValue: boolean
  onSetLoadSavedWeightsToFalse: () => void
}

const MetaForecastConfiguration: React.FC<MetaForecastConfigurationProps> = ({
  selectedAssetsAndModelIds,
  selectedModels,
  selectedAssets,
  loadSavedWeights,
  meterDataWithValue,
  onSetLoadSavedWeightsToFalse,
}) => {
  const dispatch = useDispatch()
  const classes = useCustomMuiStyles()
  const chartSettingsResult = useLineChartSettings()
  const isBackCastEnabled = useMemo(() => chartSettingsResult?.data?.showBackCast, [chartSettingsResult?.data])
  const [activeWidget, setActiveWidget] = useState<TimeSeriesSubType>()
  const { E3_WEATHER_TRACK: e3ModelsWidget, E3_THIRD_PARTY_FORECAST: thirdPartyWidget } = TimeSeriesSubType

  const user = useSelector(getUserResultSelector)
  const metaForecastWidgetData = useMetaForecastData()
  const activeMetaForecastWidgetFromDraft = metaForecastWidgetData.activeWidget

  const selectedDataStreams = useSortedSelectedDataStreams()
  const qualityTableDataStreams = filterQualityTableDataStreams(selectedDataStreams)

  const handleChangeActiveWidget = (value: TimeSeriesSubType) => {
    setActiveWidget(value)
    // Update the draft to save the active metaForecast widget
    const draft = {
      e3WidgetSettings: {
        thirdPartySliders: metaForecastWidgetData.thirdPartySliders,
        weatherModelSliders: metaForecastWidgetData.weatherModelSliders,
        activeWidget: value,
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }

  const invalidNumberOfSelection = selectedAssetsAndModelIds.length > 1 || !selectedAssetsAndModelIds.length

  // Fetch the forecast models for the selected assets
  const previousAssets = usePrevious(selectedAssets) || []
  useEffect(() => {
    const previousAssetIds = previousAssets.map((asset) => asset.id)
    selectedAssets.forEach((asset) => {
      if (!previousAssetIds.includes(asset.id)) {
        dispatch({ type: GET_FORECAST_MODELS_REQUEST, asset })
      }
    })
  }, [selectedAssets, previousAssets])

  // Set the value initially to local state
  useEffect(() => {
    if (!activeMetaForecastWidgetFromDraft && !activeWidget) {
      // Set the E3_WEATHER_TRACK initially if nothing is selected
      handleChangeActiveWidget(e3ModelsWidget)
    }
    if (!activeWidget && activeMetaForecastWidgetFromDraft) {
      handleChangeActiveWidget(activeMetaForecastWidgetFromDraft)
    }
  }, [activeWidget])

  // If there is no permission to use the active widget change it and update the draft
  useEffect(() => {
    const hasAccessTo3rdParty = hasPermissionTo3rdPartyForecasts(user)
    const hasAccessToWeatherModels = hasPermissionForE3ModelLevelForecast(user)
    if (!activeWidget) return
    if (activeWidget && (hasAccessTo3rdParty || hasAccessToWeatherModels)) {
      let changeActiveWidget: TimeSeriesSubType | null = null
      if (activeWidget === e3ModelsWidget && !hasAccessToWeatherModels) {
        changeActiveWidget = thirdPartyWidget
      } else if (activeWidget === thirdPartyWidget && !hasAccessTo3rdParty) {
        changeActiveWidget = e3ModelsWidget
      }
      if (changeActiveWidget) {
        handleChangeActiveWidget(changeActiveWidget)
      }
    }
  }, [user, activeWidget])

  // Select the active widget to match the selected model
  useEffect(() => {
    const hasAccessTo3rdParty = hasPermissionTo3rdPartyForecasts(user)
    const hasAccessToWeatherModels = hasPermissionForE3ModelLevelForecast(user)
    if (selectedModels && selectedModels.length) {
      if (
        selectedModels[0].category === ForecastModelCategoryEnum.E3 &&
        activeWidget !== e3ModelsWidget &&
        hasAccessToWeatherModels
      ) {
        handleChangeActiveWidget(e3ModelsWidget)
      }

      if (
        selectedModels[0].category === ForecastModelCategoryEnum.META &&
        activeWidget !== thirdPartyWidget &&
        hasAccessTo3rdParty
      ) {
        handleChangeActiveWidget(thirdPartyWidget)
      }
    }
  }, [selectedModels, activeWidget, user])

  const typeOfModel = activeWidget || activeMetaForecastWidgetFromDraft
  const widgetTypes = getMixerWidgetMenuItems(user)

  return (
    <Box
      className={
        (isAdmin(user) || isImpersonatedAdmin(user)) && !hasAllRoles(user, ['ROLE_E3_METAFORECAST', 'ROLE_E3_FORECAST'])
          ? classes.adminComponent
          : 'inherit'
      }
      width={MetaForecastWidgetWidth}
    >
      <Box mb={0.5} mr={0.1} display="flex" flexDirection="row" alignItems="center">
        <Typography variant="subtitle1">{c('e³').t`Mixer`}</Typography>
        <Box width={'2em'}></Box>
        {widgetTypes?.some((wt) => wt.value === activeWidget) ? (
          <InputSelector variant="standard" fullWidth>
            <InputLabel id="meter-data-label">{c('Data Stream').t`Model type`}</InputLabel>
            <Select
              labelId="meter-data-label"
              id="meter-data"
              value={activeWidget || ''}
              onChange={(event) => handleChangeActiveWidget(event.target.value)}
              label={c('e³').t`Model Type`}
            >
              {(widgetTypes || []).map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </InputSelector>
        ) : (
          <></>
        )}
      </Box>

      {/*Show the empty space to align the rows*/}
      <div style={{ height: '30px' }}></div>

      {selectedAssets && typeOfModel ? (
        <DynamicSliders
          meterDataWithValue={meterDataWithValue}
          onSetLoadSavedWeightsToFalse={onSetLoadSavedWeightsToFalse}
          qualityTableDataStreams={qualityTableDataStreams}
          typeOfModel={typeOfModel}
          isBackCastEnabled={isBackCastEnabled}
          loadSavedWeights={loadSavedWeights}
          invalidNumberOfSelection={invalidNumberOfSelection}
        />
      ) : null}
    </Box>
  )
}

export default MetaForecastConfiguration
