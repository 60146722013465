import React from 'react'
import { Column } from 'modules/reTable/reTable.types'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import Flex from 'ui/styles/Flex'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { Typography } from '@material-ui/core'

interface TrainingRowProps {
  error: string | null
  tableHeaderHasActions: boolean
  columns: Column[]
  itemObject: any
}

const TrainingRow: React.FC<TrainingRowProps> = ({ columns, tableHeaderHasActions, itemObject }) => {
  return (
    <ReTableCell colSpan={columns.length + (tableHeaderHasActions ? 1 : 0)}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex>
          <ReTableExpandToggle item={itemObject} />
          <Typography variant="subtitle2">{itemObject.jobName}</Typography>
        </Flex>
      </Flex>
    </ReTableCell>
  )
}

export default TrainingRow
