import React from 'react'
import { FormApi } from 'final-form'

import ConfigurationSolarPlant from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationSolarPlant'
import { Asset, SolarPark } from 'modules/asset/store/asset.types'
import Flex from 'ui/styles/Flex'

// styled components

// exported component

interface ConfigurationSolarParkProps {
  asset: SolarPark
  enterManually: boolean
  form: FormApi<Asset>
}

const ConfigurationSolarPark: React.FC<ConfigurationSolarParkProps> = ({ form, enterManually, asset }) => {
  return (
    <Flex direction="column">
      {enterManually && <ConfigurationSolarPlant enterManually={enterManually} form={form} asset={asset} />}
    </Flex>
  )
}

export default React.memo(ConfigurationSolarPark)
