import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { DateRange, getInclusiveDateRangeFromChartDataRange } from 'utils/date'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { isSolarAsset, useUniqueAllAssets } from 'utils/asset'

import { QueryObserverOptions, QueryObserverResult, useQueries, useQuery, UseQueryOptions } from 'react-query'
import { DataStreamSelectionItem, ForecastConfig, TimeSeriesResult } from 'modules/dataStreams/dataStreams.types'
import { Asset } from 'modules/asset/store/asset.types'
import { getApiQueryMethod } from 'modules/dataStreams/api/timeseries.api'
import { meterDataCleansingDataSelection } from 'utils/meterDataCleansing'
import { useMemo } from 'react'
import { ChartDataRangeType } from 'modules/workspace/store/workspace.types'
import { apiRequest, useOptimisticMutation } from 'utils/request'
import axios from 'axios'
import { useAvailabilitiesByAssets } from 'modules/asset/availability/api/availability.api'
import {
  FindAllCleansingFilterSettings,
  MDCAssetType,
  MeterDataCleansingConfiguration,
  MeterDataCleansingFilterSettings,
  UserMdcTrainingData,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { getAssetQueryObj, getAssetQueryStrings, useQueryParams, useQueryString } from 'utils/query-string'
import { useAssetSaveMainItemKey } from 'modules/asset/api/assetsUserSettings.api'
import { FormSaveOptions } from 'utils/form'
import { useAllAssets } from 'modules/asset/api/assets.api'

export const QUERY_KEY_METER_DATA_CLEANSING_TIMESERIES = 'meterDataCleansing:timeSeries'
export const QUERY_KEY_PERSIST_ALL_CONFIGURATIONS = 'meterDataCleansing:persistConfigurations'
export const QUERY_KEY_MDC_DEFAULT_FILTER_SETTINGS = 'meterDataCleansing:DefaultFilterSettings'
export const QUERY_KEY_MDC_ENERCAST_FILTER_SETTINGS = 'meterDataCleansing:EnercastFilterSettings'
export const QUERY_KEY_MDC_TRAINING_VALUES = 'meterDataCleansing:trainingValues'
export const QUERY_KEY_USER_MDC_TRAINING_VALUE = 'meterDataCleansing:userTrainingValue'

/**
 * default options for querying data streams
 * we don't want time series to be re-fetched too often
 */
const timeSeriesDefaultQueryOptions: UseQueryOptions<TimeSeriesResult, TimeSeriesResult> = {
  cacheTime: 1000 * 60 * 30,
  staleTime: 1000 * 60,
  retry: (failureCount, error: TimeSeriesResult) => {
    const retryNecessary = Boolean(error.cacheIsStillBeingBuilt)
    return retryNecessary
  },
}

/**
 * query options for react-query to fetch one specific time series
 */
interface GetQueryOptions {
  dataStream: DataStreamSelectionItem
  asset?: Asset
  allAssets: Asset[]
  range: DateRange
  forecastConfigs: ForecastConfig[]
  availabilityResult: any
}
const getTimeSeriesQueryOptions = ({
  dataStream,
  asset,
  allAssets,
  range,
  forecastConfigs,
  availabilityResult,
}: GetQueryOptions) => {
  const apiQueryMethod = getApiQueryMethod({ dataStream })
  const queryFn = () => apiQueryMethod({ dataStream, asset, allAssets, range, forecastConfigs, availabilityResult })

  const queryOptions: UseQueryOptions<TimeSeriesResult, TimeSeriesResult> = {
    queryKey: [
      QUERY_KEY_METER_DATA_CLEANSING_TIMESERIES,
      availabilityResult,
      asset?.id,
      dataStream.type,
      dataStream.subType,
      dataStream.classifier,
      dataStream.id,
      range,
      null,
    ],
    queryFn,
    ...timeSeriesDefaultQueryOptions,
  }

  return queryOptions
}

export const useMeterDataForCleansingQueryResult = (asset: Asset, timePeriod: DateRange) => {
  const availabilityResult = useAvailabilitiesByAssets({ assets: [asset] })

  const timePeriodToFetchMeterData = useMemo(() => {
    return {
      rangeType: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM,
      customRange: timePeriod,
    }
  }, [timePeriod])
  const timezone = useSelector(getUserTimezoneSelector)
  const range = getInclusiveDateRangeFromChartDataRange(timePeriodToFetchMeterData, timezone)
  const forecastConfigs = useSiteForecastConfigs().data || []
  const allAssets = useUniqueAllAssets()
  return useQueries(
    meterDataCleansingDataSelection.flatMap((dataStream) => {
      {
        // Fetch meterdata
        const queryOptionsForAssets = [asset].map((a) => {
          return getTimeSeriesQueryOptions({
            dataStream,
            asset: a,
            allAssets,
            range,
            forecastConfigs,
            availabilityResult,
          })
        })

        return queryOptionsForAssets.filter(
          (queryOption) => Boolean(queryOption) && Object.keys(queryOption).length > 0,
        ) as QueryObserverOptions<unknown>
      }
    }),
  ) as QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[]
}

interface getCleansingResultProps {
  assetId: string
  cleansingData: MeterDataCleansingConfiguration
}
// We are getting the result from cleansing tool , to populate scatter chart.
export const getCleansingResult = async ({ assetId, cleansingData }: getCleansingResultProps) => {
  return await apiRequest(() => axios.post(`api/meterData-cleansing/v1/assetId/${assetId}`, cleansingData))
}

export const startTrainingFromCleansing = async (asset: Asset, requestData: any) => {
  const assetType = isSolarAsset(asset) ? MDCAssetType.SOLAR : MDCAssetType.WIND
  const assetId = asset.id
  return await apiRequest(() =>
    axios.post(`api/meterData-cleansing/v1/startTraining/assetType/${assetType}/assetId/${assetId}`, requestData),
  )
}

interface SaveDefaultFilterSettingProps {
  data: MeterDataCleansingFilterSettings
  mdcAssetType: MDCAssetType
}

export const saveDefaultFilterSettings = async ({ data, mdcAssetType }: SaveDefaultFilterSettingProps) => {
  const url =
    mdcAssetType === MDCAssetType.SOLAR
      ? `api/meterData-cleansing/v1/saveDefaultSolarToMongoDb`
      : `api/meterData-cleansing/v1/saveDefaultWindToMongoDb`
  return await apiRequest(() => axios.post(url, data))
}

const getDefaultFilterSettings = async (mdcAssetType: MDCAssetType) => {
  const url =
    mdcAssetType === MDCAssetType.SOLAR
      ? `/api/meterData-cleansing/v1/findDefaultSolarCleansingParams`
      : `/api/meterData-cleansing/v1/findDefaultWindCleansingParams`
  return await apiRequest<MeterDataCleansingFilterSettings>(() => axios.get(url))
}

export const useDefaultMDCFilterSettings = (mdcAssetType: MDCAssetType) => {
  return useQuery<MeterDataCleansingFilterSettings>([QUERY_KEY_MDC_DEFAULT_FILTER_SETTINGS, mdcAssetType], () =>
    getDefaultFilterSettings(mdcAssetType),
  )
}

const getEnercastFilterSettings = async (mdcAssetType: MDCAssetType) => {
  const url =
    mdcAssetType === MDCAssetType.SOLAR
      ? `/api/meterData-cleansing/v1/returnEnercastSolarDefault`
      : `/api/meterData-cleansing/v1/returnEnercastWindDefault`
  return await apiRequest<MeterDataCleansingFilterSettings>(() => axios.get(url))
}

export const useEnercastMDCFilterSettings = (mdcAssetType: MDCAssetType) => {
  return useQuery<MeterDataCleansingFilterSettings>([QUERY_KEY_MDC_ENERCAST_FILTER_SETTINGS, mdcAssetType], () =>
    getEnercastFilterSettings(mdcAssetType),
  )
}

export const useDefaultMDCFilterSettingsSaveMutation = (mdcAssetType: MDCAssetType) => {
  return useOptimisticMutation({
    queryCacheKey: [QUERY_KEY_MDC_DEFAULT_FILTER_SETTINGS, mdcAssetType],
    apiMutator: saveDefaultFilterSettings,
  })
}

export const saveCleansingConfiguration = async (assetId: string, data: MeterDataCleansingConfiguration) => {
  if (data?.linkToDefault) {
    delete data.linkToDefault
    return connectFiltersToDefault([assetId])
  }
  delete data?.linkToDefault
  return await apiRequest(() => axios.post(`api/meterData-cleansing/v1/saveToMongoDb/assetId/${assetId}`, data))
}

export const usePersistConfigurationSaveMutation = (asset: Asset, trainingStarted: boolean) => {
  const { queryParams } = useQueryParams()
  const saveMainItemKey = useAssetSaveMainItemKey()
  const { SAVE, SAVE_AND_NEXT, CREATE_COPY, SAVE_AND_CLOSE } = FormSaveOptions
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const allAssets = useAllAssets()

  const currentAssetIndex = useMemo(() => {
    const index = (allAssets.data || []).findIndex((a) => a.id === asset.id)
    return index
  }, [asset])

  return useOptimisticMutation<any, MeterDataCleansingConfiguration, MeterDataCleansingConfiguration>({
    queryCacheKey: [QUERY_KEY_PERSIST_ALL_CONFIGURATIONS, asset.id],
    apiMutator: (value) => saveCleansingConfiguration(asset.id, value),
    cacheUpdater: (updatedConfigurations) => {
      return updatedConfigurations
    },
    onSuccess: () => {
      // If training started do not redirect page, because it should go to forecast models page and its done in the component
      if (!trainingStarted) {
        const saveOption = saveMainItemKey?.data?.updateAsset || SAVE_AND_CLOSE
        if (saveOption === SAVE_AND_CLOSE) {
          onDeleteQueryStrings(getAssetQueryStrings())
        } else if (saveOption === SAVE || saveOption === CREATE_COPY) {
          onUpdateQueryString(getAssetQueryObj(asset, queryParams))
        } else if (saveOption === SAVE_AND_NEXT) {
          const assetCount = (allAssets.data || []).length
          let nextAssetIndex = 0
          if (currentAssetIndex + 1 !== assetCount) {
            nextAssetIndex = currentAssetIndex + 1
          }
          onUpdateQueryString(getAssetQueryObj((allAssets.data || [])[nextAssetIndex], queryParams))
        } else {
          // if there is no saveOption set close the form which is equal to 'saveAndClose'
          onDeleteQueryStrings(getAssetQueryStrings())
        }
      }
    },
  })
}

export const getCleansingConfiguration = async (assetId: string) => {
  // Do not delete this need to test transformResponse with react query
  // return await apiRequest(() =>
  //   axios.get<MeterDataCleansingConfiguration>(`api/meterData-cleansing/v1/findCleansingParams/assetId/${assetId}`, {
  //     transformResponse: convertMDCConfigDatesToUserTimezone(userTimezone),
  //   }),
  // )

  return await apiRequest(() =>
    axios.get<MeterDataCleansingConfiguration>(`api/meterData-cleansing/v1/findCleansingParams/assetId/${assetId}`),
  )
}
export const usePersistConfigurationsByAssetId = (assetId: string) => {
  return useQuery<MeterDataCleansingConfiguration>([QUERY_KEY_PERSIST_ALL_CONFIGURATIONS, assetId], () => {
    return getCleansingConfiguration(assetId)
  })
}

export const getAllCleansingParams = async (assetIds: string[]) => {
  return await apiRequest<Record<string, FindAllCleansingFilterSettings>>(() =>
    axios.post(`/api/meterData-cleansing/v1/findCleansingParams`, assetIds),
  )
}

// Is used to link asset filter settings to default
export const connectFiltersToDefault = async (assetIds: string[]) => {
  return await apiRequest(() => axios.put(`api/meterData-cleansing/v1/deactivateParameters`, assetIds))
}

// Is used to unlink asset filter settings From default
export const disconnectsFiltersFromDefault = async (assetIds: string[]) => {
  return await apiRequest(() => axios.put(`api/meterData-cleansing/v1/activateParameters`, assetIds))
}

export const restartMdcForAsset = async (asset: Asset) => {
  const assetType = isSolarAsset(asset) ? MDCAssetType.SOLAR : MDCAssetType.WIND
  const assetId = asset.id
  return await apiRequest(() =>
    axios.post(`api/meterData-cleansing/v1/assetType/${assetType}/assetId/${assetId}/restart`),
  )
}

// Meter data cleansing filters for training
const getMdcTrainingValues = async () => {
  return await apiRequest(() => axios.get<string[]>(`api/meterData-cleansing/v1/settings/values`))
}

export const useMdcTrainingValues = () => {
  return useQuery<string[]>(QUERY_KEY_MDC_TRAINING_VALUES, getMdcTrainingValues)
}

const saveMeterDataCleansingTrainingValue = async (data: Record<string, string>) => {
  return await apiRequest(() => axios.post(`api/meterData-cleansing/v1/settings`, data))
}

export const useUserMdcTrainingValueSaveMutation = () => {
  return useOptimisticMutation({
    queryCacheKey: QUERY_KEY_USER_MDC_TRAINING_VALUE,
    apiMutator: (data: UserMdcTrainingData) => saveMeterDataCleansingTrainingValue(data),
    cacheUpdater: (updatedValue, oldValue) => {
      return oldValue
    },
  })
}

const getUserMdcTrainingValues = async () => {
  return await apiRequest(() => axios.get<string>(`api/meterData-cleansing/v1/settings`))
}

export const useUserMdcTrainingValue = () => {
  return useQuery<string>(QUERY_KEY_USER_MDC_TRAINING_VALUE, getUserMdcTrainingValues)
}
