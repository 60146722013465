import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Button, Divider, FormControlLabel, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'ttag'
import {
  getSavedLanguage,
  isAdmin,
  isCompanySubAccount,
  isDemo,
  isImpersonatedAdmin,
  isImpersonatedPartner,
  isPartner,
  TOGGLE_ADMIN_COMPONENT,
  UserMenuItemKeys,
} from 'utils/user'
import CustomLink from 'ui/elements/CustomLink'
import MailLink, { MailAddresses } from 'ui/elements/MailLink'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import {
  getChangePasswordQueryObj,
  getUserSettingsQueryObj,
  QUERY_LEAVE_IMPERSONATE,
  QUERY_LOGOUT,
  QUERY_SWITCH_TO_CLASSIC,
} from 'utils/query-string'
import { AppSliders } from 'fixtures/mainPage'
import { getDemoUserMenuItems, getDocumentMenuItems, LanguageKeys } from 'fixtures/header'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { useAppTourSettings, useAppTourSettingsSaveMutation } from 'modules/app/tour/appTour.api'
import NestedMenuItem from 'ui/elements/NestedMenuItem'
import { impersonateUser, useAuthorizedAccounts } from 'modules/user/api/user.api'
import { User } from 'modules/auth/Auth.types'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import UserMenuItem from 'modules/user/userMenu/UserMenuItem'
import BooleanSwitch from 'ui/form/BooleanSwitch'
import { useCustomMuiStyles } from 'themes/theme-light'
import { getCompaniesForSubUser } from 'modules/userManagement/api/userManagement.api'
import { leaveImpersonate } from 'modules/auth/redux_store/auth.api'

const SwitchControl = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    padding-left: 3px;
  }
`

const StyledMenu = styled(Menu)`
  & a {
    color: initial;
    text-decoration: initial;
  }
`

const Username = styled(Box)`
  text-transform: none;
`

const demoUserMenuItems = getDemoUserMenuItems()
const documentMenuItemsInEnglish = getDocumentMenuItems(LanguageKeys.english)
const documentMenuItemsInGerman = getDocumentMenuItems(LanguageKeys.german)
const nestedMenuItemIconColor = '#707070'

const {
  USER_SETTINGS,
  CHANGE_PASSWORD,
  SIGN_OUT,
  LEAVE_IMPERSONATE,
  CONTACT_US,
  SHOW_CLASSIC_UI,
  TOGGLE_ADMIN_FUNCTIONALITY,
} = UserMenuItemKeys

const UserMenu: React.FC = () => {
  const user = useSelector(getUserResultSelector)
  const appTourSettings = useAppTourSettings()
  const { userSettings, changePassword } = AppSliders
  const { onAddQueryString } = useUpdateQueryString()
  const isTourEndByUser = useMemo(() => appTourSettings?.data?.endOnBoardingTour, [appTourSettings?.data])

  const classes = useCustomMuiStyles()

  const saveAppTourSettingsResult = useAppTourSettingsSaveMutation()
  const saveAppTourSettingsMutation = saveAppTourSettingsResult.mutate
  const authorizedAccountsResult = useAuthorizedAccounts()
  const authorizedAccounts = useMemo(() => authorizedAccountsResult?.data, [authorizedAccountsResult?.data])
  const [logoutInit, setLogoutInit] = useState(false)
  const [addAdminFunctionality, setAddAdminFunctionality] = useState(
    sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT) ? JSON.parse(sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT)) : true,
  )

  const [companiesForSubUser, setCompaniesForSubUser] = useState<string[] | []>([])

  const [userMenuAnchor, setUserMenuAnchor] = React.useState<null | HTMLElement>(null)
  const userMenuOpen = Boolean(userMenuAnchor)

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = (key: UserMenuItemKeys) => {
    // For better UX , we don't want to close user menu if we are clicking toggle admin functionality
    if (key !== TOGGLE_ADMIN_FUNCTIONALITY) setUserMenuAnchor(null)
  }

  const handleSwitchToClassicUI = () => {
    if (user) {
      onAddQueryString({ [QUERY_SWITCH_TO_CLASSIC]: true })
    }
  }

  const handleImpersonateUser = useCallback((account: User) => {
    impersonateUser(account.login).finally(() => {
      window.location.assign('/')
    })
  }, [])

  const handleLeaveImpersonate = useCallback(() => {
    saveAppTourSettingsMutation({ startOnBoardingTour: false, endOnBoardingTour: isTourEndByUser, enableTour: false })

    // Clear session storage
    if (sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT)) {
      sessionStorage.removeItem(TOGGLE_ADMIN_COMPONENT)
    }

    onAddQueryString({ [QUERY_LEAVE_IMPERSONATE]: true })
  }, [handleCloseUserMenu, onAddQueryString, isTourEndByUser])

  const handleLogout = useCallback(() => {
    saveAppTourSettingsMutation({ startOnBoardingTour: false, endOnBoardingTour: isTourEndByUser, enableTour: false })
    setLogoutInit(true)
  }, [handleCloseUserMenu, isTourEndByUser])

  const handleOpenSlider = useCallback(
    (slider: AppSliders) => {
      if (!user) return
      if (slider === userSettings) {
        onAddQueryString(getUserSettingsQueryObj())
      } else if (slider === changePassword) {
        onAddQueryString(getChangePasswordQueryObj())
      }
    },
    [user, onAddQueryString, handleCloseUserMenu],
  )

  const handleToggleAdminFunctionality = (checked: boolean) => {
    sessionStorage.setItem(TOGGLE_ADMIN_COMPONENT, JSON.stringify(checked))

    setAddAdminFunctionality(checked)

    // We are adding this timeout for better UX.
    // It's not necessary to clear this timeout , because we are refreshing the location and it's automatically removed
    setTimeout(() => {
      window.location.reload()
    }, 700)
  }

  const handleClickMenuItem = (key: UserMenuItemKeys) => {
    handleCloseUserMenu(key)
    switch (key) {
      case USER_SETTINGS:
        handleOpenSlider(AppSliders.userSettings)
        break
      case CHANGE_PASSWORD:
        handleOpenSlider(AppSliders.changePassword)
        break
      case SHOW_CLASSIC_UI:
        handleSwitchToClassicUI()
        break
      case LEAVE_IMPERSONATE:
        handleLeaveImpersonate()
        break
      case SIGN_OUT:
        handleLogout()
      default:
        handleCloseUserMenu(key)
    }
  }

  useEffect(() => {
    if (logoutInit && saveAppTourSettingsResult.isSuccess) {
      // Clear session storage
      if (sessionStorage.getItem(TOGGLE_ADMIN_COMPONENT)) {
        sessionStorage.removeItem(TOGGLE_ADMIN_COMPONENT)
      }

      onAddQueryString({ [QUERY_LOGOUT]: true })
    }
  }, [logoutInit, saveAppTourSettingsResult.isSuccess, onAddQueryString])

  const getUserName = () => {
    if (user) {
      if (isCompanySubAccount(user)) {
        return `${user?.login} [${user?.originalUser?.login}]` || ''
      }
      return user.login
    }
    return ''
  }

  const getLanguage = () => {
    if (user) {
      return getSavedLanguage(user)
    }
    return LanguageKeys.english
  }

  useEffect(() => {
    getCompaniesForSubUser(isCompanySubAccount(user)).then((res) => {
      if (res?.length > 0) {
        if (isCompanySubAccount(user)) {
          res.push(user?.originalUser?.login)
        } else {
          res.push(user?.login)
        }
        setCompaniesForSubUser(res)
      }
    })
  }, [user])

  const handleImpersonationForSubUserToDifferentCompany = (login: string) => {
    leaveImpersonate().then(() => {
      impersonateUser(login).finally(() => {
        window.location.assign('/')
      })
    })
  }

  const disableSwitchAccountSelection = (account: string) => {
    return account === user?.login
  }

  return (
    <>
      <Button color="default" onClick={handleUserMenu}>
        <FontAwesomeActionIcon size="lg" icon="user-circle" />
        <Username ml={0.5} mr={0.4}>
          {getUserName()}
        </Username>
        <FontAwesomeIcon icon="caret-down" />
      </Button>
      <StyledMenu
        id="header-user-menu"
        keepMounted
        open={userMenuOpen}
        onClose={handleCloseUserMenu}
        anchorEl={userMenuAnchor}
      >
        <MenuItem onClick={() => handleClickMenuItem(USER_SETTINGS)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={'user-cog'} fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`User settings`} />
        </MenuItem>

        <MenuItem onClick={() => handleClickMenuItem(CHANGE_PASSWORD)}>
          <ListItemIcon>
            <FontAwesomeIcon icon={'lock'} fixedWidth />
          </ListItemIcon>
          <ListItemText primary={t`Change password`} />
        </MenuItem>

        {/*Demo user MenuItems */}
        {isDemo(user) &&
          demoUserMenuItems.map((item) => {
            return (
              <CustomLink route={item.link} key={item.link} onLinkClick={handleCloseUserMenu}>
                <UserMenuItem onMenuItemClick={handleClickMenuItem} itemKey={item.key} />
              </CustomLink>
            )
          })}

        {(isAdmin(user) || isImpersonatedAdmin(user)) && (
          <UserMenuItem
            onMenuItemClick={handleClickMenuItem}
            itemKey={SHOW_CLASSIC_UI}
            isAdminComponent={isImpersonatedAdmin(user) || isAdmin(user)}
          />
        )}

        {(isImpersonatedAdmin(user, true) || isAdmin(user, true)) && <Divider />}
        {(isImpersonatedAdmin(user, true) || isAdmin(user, true)) && (
          <MenuItem
            onClick={() => handleClickMenuItem(TOGGLE_ADMIN_FUNCTIONALITY)}
            className={isImpersonatedAdmin(user, true) || isAdmin(user, true) ? classes.adminComponent : 'inherit'}
          >
            <SwitchControl
              value="end"
              control={<BooleanSwitch enabled={addAdminFunctionality} onToggle={handleToggleAdminFunctionality} />}
              label={t`Admin functionality`}
              labelPlacement="end"
            />
          </MenuItem>
        )}

        <Divider />
        {/*Document links*/}
        {(getLanguage() === LanguageKeys.english ? documentMenuItemsInEnglish : documentMenuItemsInGerman).map(
          (item) => {
            return (
              <a onClick={handleCloseUserMenu} key={item.link} href={item.link} target="_blank" rel="noreferrer">
                <UserMenuItem onMenuItemClick={handleClickMenuItem} itemKey={item.key} />
              </a>
            )
          },
        )}

        <MailLink mailingAddress={MailAddresses.support} target="_blank" rel="noopener noreferrer">
          <UserMenuItem onMenuItemClick={handleClickMenuItem} itemKey={CONTACT_US} />
        </MailLink>

        {companiesForSubUser.length > 0 && !isImpersonatedAdmin(user, true) && (
          <NestedMenuItem
            iconColor={nestedMenuItemIconColor}
            label={t`Switch account`}
            icon="user-check"
            parentMenuOpen={!!userMenuOpen}
          >
            <>
              {(companiesForSubUser || []).map((account: string, index: number) => {
                return (
                  <MenuItem
                    key={account}
                    onClick={() => handleImpersonationForSubUserToDifferentCompany(account)}
                    style={{
                      borderTop: index === companiesForSubUser.length - 1 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
                    }}
                    disabled={disableSwitchAccountSelection(account)}
                  >
                    <ListItemText primary={account} />
                  </MenuItem>
                )
              })}
            </>
          </NestedMenuItem>
        )}

        {isPartner(user) && (
          <NestedMenuItem
            iconColor={nestedMenuItemIconColor}
            label={t`Authorized accounts`}
            icon="user-check"
            parentMenuOpen={!!userMenuOpen}
          >
            <>
              {(authorizedAccounts || []).map((account: User) => {
                return (
                  <MenuItem key={account.login} onClick={() => handleImpersonateUser(account)}>
                    <ListItemText primary={account.login} />
                  </MenuItem>
                )
              })}
            </>
          </NestedMenuItem>
        )}

        <Divider />

        {(isImpersonatedAdmin(user, true) || isImpersonatedPartner(user)) && (
          <UserMenuItem
            onMenuItemClick={handleClickMenuItem}
            itemKey={LEAVE_IMPERSONATE}
            isAdminComponent={!isImpersonatedPartner(user) && isImpersonatedAdmin(user, true)}
          />
        )}

        <UserMenuItem onMenuItemClick={handleClickMenuItem} itemKey={SIGN_OUT} />
      </StyledMenu>
    </>
  )
}

// Header.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default React.memo(UserMenu)
