import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Flex from 'ui/styles/Flex'
import ReTable from 'modules/reTable/ReTable'
import { RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { table } from 'themes/theme-light'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch } from 'react-redux'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import styled from 'styled-components'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import { getMasterDataCustomFormatQueryObj, useQueryString } from 'utils/query-string'
import DeliveryTablesGenericToolbar from 'modules/delivery/DeliveryTablesGenericToolbar'
import { c } from 'ttag'
import { MasterDataConfig } from 'modules/masterData/masterData.types'
import { useMasterDataCustomFormatDeleteMutation } from 'modules/masterData/api/masterDataCustomFormats.api'
import { getMasterDataCustomFormatTableColumns } from 'utils/masterData'
import MasterDataCustomFormatsTableBody from 'modules/masterData/masterDataCustomFormats/MasterDataCustomFormatsTableBody'

const MasterDataTable = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

interface MasterDataCustomFormatsTableProps {
  masterDataCustomFormats: MasterDataConfig[]
}

const MasterDataCustomFormatsTable: React.FC<MasterDataCustomFormatsTableProps> = ({ masterDataCustomFormats }) => {
  const dispatch = useDispatch()
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const columnsSelected = useReTableSelectorWithId(
    reTableColumnsSelectedSelector,
    RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS,
  )
  const tableHeaderHasAction = false
  const [itemsToRender, setItemsToRender] = useState<MasterDataConfig[]>([])
  const { onUpdateQueryString } = useQueryString()

  const { mutate: deleteMasterDataCustomFormat } = useMasterDataCustomFormatDeleteMutation()

  const handleItemsToRenderChange = useCallback((items: MasterDataConfig[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  const handleAddNewItem = useCallback(() => {
    onUpdateQueryString(getMasterDataCustomFormatQueryObj())
  }, [onUpdateQueryString])

  const handleCopyItem = useCallback(() => {
    console.log('handle copy item')
  }, [])

  const handleEditItem = useCallback(
    (data: MasterDataConfig) => {
      onUpdateQueryString(getMasterDataCustomFormatQueryObj(data))
    },
    [onUpdateQueryString],
  )

  const handleDeleteItem = useCallback((data: MasterDataConfig) => {
    deleteMasterDataCustomFormat(data)
  }, [])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'name',
    }),
    [],
  )

  // Manage columns
  const columnsAvailable = useMemo(() => getMasterDataCustomFormatTableColumns(), [])
  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS, columnsAvailable })
  }, [columnsAvailable])

  return (
    <Flex direction="column" fullHeight>
      <DeliveryTablesGenericToolbar
        tableHeaderHasActions={tableHeaderHasAction}
        columns={columnsSelected}
        onAddNewItem={handleAddNewItem}
        tableId={RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS}
        addItemButtonLabel={c('Master data').t`Configure new master data format`}
      />
      <DynamicTableHeightContainer numberOfRows={itemsToRender.length}>
        <MasterDataTable
          id={RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS}
          itemHeight={table.rowHeight}
          items={masterDataCustomFormats as ReTableItem[]}
          hasExtendedHeader={false}
          itemsPaddingHeader={ITEMS_PADDING_HEADER}
          itemsPaddingFooter={ITEMS_PADDING_FOOTER}
          renderAheadCount={RENDER_AHEAD_COUNT}
          defaultSort={defaultSort}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ReTableGenericHeader tableHeaderHasActions={tableHeaderHasAction} columns={columnsSelected} />
          <MasterDataCustomFormatsTableBody
            onCopyItem={handleCopyItem}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            columns={columnsSelected}
            visibleItems={itemsToRender}
            tableHeaderHasActions={tableHeaderHasAction}
          />
        </MasterDataTable>
      </DynamicTableHeightContainer>
    </Flex>
  )
}

export default React.memo(MasterDataCustomFormatsTable)
