import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import FileUploadHelpPopover from 'modules/data/fileManager/FileUploadHelpPopover'
import React, { useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { theme, transitionFor } from 'themes/theme-light'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'

import { convertFileRejectionsToErrors, FILE_SIZE, FileData, FileError, readFile } from 'utils/file'
import { useIsReadOnlyUser } from 'utils/user'

interface PaperProps {
  active: number
  invalid: number
}

const UploadContainer = styled(Paper)<PaperProps>`
  &.MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.active ? '#e6e6e6' : '#fafafa')};
    color: ${(props) =>
      props.active ? theme.palette[props.invalid ? 'error' : 'primary'].main : theme.app.color.contrast};
    border: 3px dashed
      ${(props) => (props.active ? theme.palette[props.invalid ? 'error' : 'primary'].main : theme.app.color.border)};
    border-radius: 0;
    outline: 0;
    cursor: crosshair;
    transition: ${transitionFor(['color', 'border-color', 'background-color', 'box-shadow'])};
  }
`

const UploadArea = styled.div`
  min-height: 10em;
  text-align: center;
  padding: ${theme.spacing(3)}px;
`

const IconWrapper = styled.span`
  display: inline-block;
  width: 1.3em;
`

const Label = styled.span`
  margin-left: ${theme.spacing(1)}px;
`

const CompactUploadArea = styled(Box)`
  padding-left: 0.5em;
  min-height: 1.35em;
`

// // type guard to check drop result type
// // more info: https://medium.com/ovrsea/checking-the-type-of-an-object-in-typescript-the-type-guards-24d98d9119b0
// export const isDropData = (dropResult: DropResult): dropResult is FileData => {
//   return Boolean((dropResult as FileData).file)
// }

export type FileUploadHandler = ({
  validFiles,
  invalidFiles,
}: {
  validFiles: FileData[]
  invalidFiles: FileError[]
}) => void

export interface FileUploadZoneProps {
  label?: string
  accept?: string
  compact?: boolean
  onFileSelect: FileUploadHandler
  onResetErrors?: () => void
  buttonText?: string
  buttonIcon?: string
  multiple?: boolean
  showHelpIcon?: boolean
  maxSize?: number
  ignoreReadOnlyRole?: boolean
}

const FileUploadZone: React.FC<
  FileUploadZoneProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = ({
  label,
  accept,
  compact,
  onFileSelect,
  onResetErrors,
  buttonText,
  buttonIcon,
  multiple = true,
  showHelpIcon = true,
  maxSize,
  ignoreReadOnlyRole,
}) => {
  // const [hover, setHover] = useState<boolean>(false)
  const isReadOnlyUser = useIsReadOnlyUser()
  const disableActionDueToReadOnly = ignoreReadOnlyRole ? false : isReadOnlyUser
  // const [validFiles, setValidFiles] = useState<FileData[]>([])
  // const [fileErrors, setFileErrors] = useState<FileError[]>([])

  const handleDragEnter = useCallback(() => {
    if (onResetErrors) {
      onResetErrors()
    }
  }, [onResetErrors])

  const handleMouseOver = useCallback(() => {
    // setHover(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    // setHover(false)
  }, [])

  const handleDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      const fileHandlers = acceptedFiles.map((file: File) => readFile(file))

      const newValidFiles: FileData[] = []
      const newFileErrors: FileError[] = convertFileRejectionsToErrors(fileRejections)

      await Promise.all(
        fileHandlers.map(async (fileHandler) => {
          await fileHandler
            .then((validFile: FileData) => {
              newValidFiles.push(validFile)
            })
            .catch((fileError: FileError) => {
              newFileErrors.push(fileError)
            })
        }),
      )

      // setValidFiles(newValidFiles)
      // setFileErrors(newFileErrors)
      onFileSelect({ validFiles: newValidFiles, invalidFiles: newFileErrors })
    },
    [onFileSelect],
  )

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    accept,
    minSize: FILE_SIZE.MIN,
    maxSize: maxSize || FILE_SIZE.MAX,
    multiple: multiple,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    onDrop: handleDrop,
    preventDropOnDocument: true,
    disabled: disableActionDueToReadOnly,
  })

  const { ...rootProps } = getRootProps()

  return (
    <div {...rootProps}>
      <UploadContainer
        elevation={isDragActive ? 4 : 0}
        active={isDragActive ? 1 : 0}
        invalid={0}
        onPointerEnter={handleMouseOver}
        onPointerLeave={handleMouseLeave}
        onDragEnter={handleDragEnter}
      >
        <input {...getInputProps()} />
        {compact ? (
          <CompactUploadArea m={1}>
            <Flex flexWrap="wrap" justifyContent="center" alignItems="center">
              <Typography variant="subtitle1">
                <Flex>
                  <Box mr={1}>
                    <FontAwesomeIcon
                      style={{ color: disableActionDueToReadOnly ? 'grey' : 'inherit' }}
                      icon={isDragActive ? 'folder-open' : 'folder'}
                      fixedWidth
                    />
                  </Box>
                  <Box style={{ color: disableActionDueToReadOnly ? 'grey' : 'inherit' }}>{label}</Box>
                </Flex>
              </Typography>
              <Typography variant="subtitle1">
                <Box ml={1}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<FontAwesomeIcon icon={buttonIcon || 'file-csv'} />}
                    onClick={open}
                    disabled={disableActionDueToReadOnly}
                  >
                    {buttonText || multiple ? t`Select Files...` : t`Select File`}
                  </Button>
                </Box>
              </Typography>

              {showHelpIcon && <FileUploadHelpPopover showHelpTextBtn={false} />}
            </Flex>
          </CompactUploadArea>
        ) : (
          <UploadArea>
            <Typography variant="subtitle1" align="center">
              <IconWrapper>
                <FontAwesomeIcon
                  style={{ color: disableActionDueToReadOnly ? 'grey' : 'inherit' }}
                  icon={isDragActive ? 'folder-open' : 'folder'}
                  fixedWidth
                />
              </IconWrapper>
              <Label style={{ color: disableActionDueToReadOnly ? 'grey' : 'inherit' }}>{label}</Label>
            </Typography>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<FontAwesomeIcon icon={buttonIcon || 'file-csv'} />}
                onClick={open}
                disabled={disableActionDueToReadOnly}
              >
                {buttonText || t`Select Files...`}
              </Button>
            </Box>
          </UploadArea>
        )}
      </UploadContainer>
    </div>
  )
}

export default React.memo(FileUploadZone)
