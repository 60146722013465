import React from 'react'
import TextFieldDialog from 'ui/elements/TextFieldDialog'
import { msgid, ngettext, t } from 'ttag'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { ConfirmWorkspaceHeaderMenuBarActionsProps } from 'modules/workspace/header/WorkspaceHeader'

interface WorkspaceHeaderDialogsProps {
  confirmMenuBarActions: ConfirmWorkspaceHeaderMenuBarActionsProps
  renameDialogOpen: boolean
  saveAsDialogOpen: boolean
  discardDialogOpen: boolean
  chartNameText: string
  chartName: string
  onConfirmUnsavedChanges: () => void
  onCloseConfirmUnsavedChanges: () => void
  onConfirmDeleteChart: () => void
  onCloseConfirmDeleteChart: () => void
  onDiscardChanges: () => void
  onCloseDiscardChanges: () => void
  onRenameChart: (name: string) => void
  onValidateRename: (name: string) => string | undefined
  onCloseRenameDialog: () => void
  onValidateSaveAs: (name: string) => string | undefined
  onCloseSaveAsDialog: () => string
  onSaveDuplicatedChart: (name: string) => void
}

const WorkspaceHeaderDialogs: React.FC<WorkspaceHeaderDialogsProps> = ({
  confirmMenuBarActions,
  renameDialogOpen,
  saveAsDialogOpen,
  discardDialogOpen,
  chartNameText,
  chartName,
  onConfirmUnsavedChanges,
  onCloseConfirmUnsavedChanges,
  onConfirmDeleteChart,
  onCloseConfirmDeleteChart,
  onDiscardChanges,
  onCloseDiscardChanges,
  onRenameChart,
  onValidateRename,
  onCloseRenameDialog,
  onValidateSaveAs,
  onCloseSaveAsDialog,
  onSaveDuplicatedChart,
}) => {
  return (
    <>
      {confirmMenuBarActions.isUnsavedDialogOpen && (
        <ConfirmationDialog
          heading={t`Unsaved changes`}
          text={t`There are unsaved changes in "${chartNameText}" chart. They will be discarded if you proceed.`}
          confirmAction={t`Discard and proceed`}
          cancelAction={t`Cancel`}
          onConfirm={onConfirmUnsavedChanges}
          onCancel={onCloseConfirmUnsavedChanges}
          openDialog={confirmMenuBarActions.isUnsavedDialogOpen}
        />
      )}
      {confirmMenuBarActions.isDeleteDialogOpen && (
        <ConfirmationDialog
          heading={ngettext(msgid`Delete chart`, `Delete charts`, 1)}
          text={ngettext(msgid`Do you want to delete this chart?`, `Do you want to delete these charts`, 1)}
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={onConfirmDeleteChart}
          onCancel={onCloseConfirmDeleteChart}
          openDialog={confirmMenuBarActions.isDeleteDialogOpen}
        />
      )}
      {renameDialogOpen && (
        <TextFieldDialog
          heading={t`Rename chart`}
          text={t`Please enter name for this charts:`}
          label={t`Chart name`}
          defaultValue={chartName}
          loading={false}
          submitLabel={t`Rename`}
          onSubmit={onRenameChart}
          onValidate={onValidateRename}
          onCancel={onCloseRenameDialog}
        />
      )}
      {saveAsDialogOpen && (
        <TextFieldDialog
          heading={t`Save chart`}
          text={t`Please enter name for a copy of this chart:`}
          label={t`Chart name`}
          loading={false}
          onSubmit={onSaveDuplicatedChart}
          onValidate={onValidateSaveAs}
          onCancel={onCloseSaveAsDialog}
        />
      )}
      {discardDialogOpen && (
        <ConfirmationDialog
          heading={t`Discard changes`}
          text={t`Chart will be reset to last saved state. Are you sure that you want to discard your changes?`}
          confirmAction={t`Discard and proceed`}
          cancelAction={t`Cancel`}
          onConfirm={onDiscardChanges}
          onCancel={onCloseDiscardChanges}
          openDialog={discardDialogOpen}
        />
      )}
    </>
  )
}

export default WorkspaceHeaderDialogs
