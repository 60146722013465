import React, { useEffect, useState, createContext } from 'react'
import MetaForecastConfiguration from 'modules/workspace/advancedChartWidgets/metaForecast/MetaForecastConfiguration'
import QualityPreview from 'modules/workspace/advancedChartWidgets/quality/QualityPreview'
import { ChartWidget } from 'modules/workspace/store/workspace.types'
import { Box } from '@material-ui/core'
import { useMetaForecastData } from 'utils/hooks/useMetaForecastData'
import { Asset, FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { getChartWidgetNames } from 'utils/workspace'
import MetaForecastActions from 'modules/workspace/advancedChartWidgets/metaForecast/MetaForecastActions'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { workspaceDraftSelectedModelsSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useSelector } from 'react-redux'

interface QualityTableAndMetaForecastWidgetsProps {
  selectedAssets: Asset[]
  selectedAssetsAndModelIds: string[]
  loading: boolean
}

export const MeterDataWithValueContext = createContext({
  meterDataWithValue: false,
  setMeterDataWithValue: (b: boolean) => b,
})

const QualityTableAndMetaForecastWidgets: React.FC<QualityTableAndMetaForecastWidgetsProps> = ({
  selectedAssets,
  selectedAssetsAndModelIds,
  loading,
}) => {
  const metaForecastWidgetData = useMetaForecastData()
  const showMetaForecastWidget = metaForecastWidgetData.showMetaForecastWidget
  const activeWidget = metaForecastWidgetData.activeWidget
  const hasAccessToThirdPartyForecast = metaForecastWidgetData.hasAccessToThirdPartyForecast
  const selectedModels = useSelector(workspaceDraftSelectedModelsSelector)

  // console.log({ metaForecastWidgetData })

  const [meterDataWithValue, setMeterDataWithValue] = useState(false)

  const value = { meterDataWithValue, setMeterDataWithValue }

  const [loadSavedWeights, setLoadSavedWeights] = useState(false)
  //
  const chartWidgetNames = getChartWidgetNames()

  const selectedModelIds = selectedAssetsAndModelIds.filter((id) => id && id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID))

  useEffect(() => {
    setLoadSavedWeights(false)
  }, [selectedAssetsAndModelIds, activeWidget])

  // console.log('main component =', activeWidget)

  return (
    <Box mt={1.5}>
      <div>
        <MeterDataWithValueContext.Provider value={value}>
          <Box display="flex" flexDirection="row">
            {showMetaForecastWidget && (
              <MetaForecastConfiguration
                meterDataWithValue={meterDataWithValue}
                selectedModels={selectedModels}
                selectedAssets={selectedAssets}
                selectedAssetsAndModelIds={selectedAssetsAndModelIds}
                loadSavedWeights={loadSavedWeights}
                onSetLoadSavedWeightsToFalse={() => setLoadSavedWeights(false)}
                hasAccessToThirdPartyForecast={metaForecastWidgetData.hasAccessToThirdPartyForecast}
                hasAccessToE3WeatherModelForecast={metaForecastWidgetData.hasAccessToE3WeatherModelForecast}
              />
            )}
            <QualityPreview loading={loading} widgetName={chartWidgetNames[ChartWidget.WIDGET_QUALITY_TABLE]} />
          </Box>
        </MeterDataWithValueContext.Provider>

        {showMetaForecastWidget && (
          <MetaForecastActions
            // ensembleWeights={ensembleWeightsToSave}
            selectedAssets={selectedAssets}
            selectedModelIds={selectedModelIds}
            hasAccessToThirdPartyForecast={hasAccessToThirdPartyForecast}
            handleSetLoadSavedWeights={() => setLoadSavedWeights(true)}
            selectedAssetsAndModelIds={selectedAssetsAndModelIds}
            activeWidgetType={activeWidget || TimeSeriesSubType.E3_WEATHER_TRACK}
          />
        )}
      </div>
    </Box>
  )
}

export default QualityTableAndMetaForecastWidgets
