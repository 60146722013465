import React from 'react'

import {
  Asset,
  Cluster,
  TYPE_CHP,
  TYPE_CLUSTER,
  TYPE_SOLARPARK,
  TYPE_SOLARPLANT,
  TYPE_WINDPARK,
  TYPE_WINDPLANT,
} from 'modules/asset/store/asset.types'
import ClusterPoolIcon from 'ui/icons/ClusterPoolIcon'
import SolarPlantIcon from 'ui/icons/SolarPlantIcon'
import SolarParkIcon from 'ui/icons/SolarParkIcon'
import WindPlantIcon from 'ui/icons/WindPlantIcon'
import WindParkIcon from 'ui/icons/WindParkIcon'
import CHPIcon from 'ui/icons/CHPIcon'
import { ClusterTypesEnum } from 'fixtures/assetForm'
import ClusterHybridIcon from 'ui/icons/HybridClusterIcon'
import ClusterIcon from 'ui/icons/ClusterIcon'

interface AssetIconProps {
  asset: Asset
  size: string
  selected?: boolean
}

const AssetIcon: React.FC<AssetIconProps> = ({ asset, size, selected = false }) => {
  let svgIcon
  switch (asset.type) {
    case TYPE_CLUSTER:
      const cluster = asset as Cluster
      if (cluster?.clusterType && cluster.clusterType === ClusterTypesEnum.POOL) {
        svgIcon = <ClusterPoolIcon size={size} iconSelected={selected} />
      } else if (cluster?.clusterType && cluster?.clusterType === ClusterTypesEnum.HYBRID) {
        svgIcon = <ClusterHybridIcon size={size} iconSelected={selected} />
      } else {
        svgIcon = <ClusterIcon size={size} iconSelected={selected} />
      }
      break
    case TYPE_SOLARPLANT:
      svgIcon = <SolarPlantIcon size={size} iconSelected={selected} />
      break
    case TYPE_SOLARPARK:
      svgIcon = <SolarParkIcon size={size} iconSelected={selected} />
      break
    case TYPE_WINDPLANT:
      svgIcon = <WindPlantIcon size={size} iconSelected={selected} />
      break
    case TYPE_WINDPARK:
      svgIcon = <WindParkIcon size={size} iconSelected={selected} />
      break
    case TYPE_CHP:
      svgIcon = <CHPIcon size={size} iconSelected={selected} />
      break
    default:
      break
  }

  return <>{svgIcon}</>
}

export default React.memo(AssetIcon)
