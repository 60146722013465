import React from 'react'
import { useSelector } from 'react-redux'
import { AppBanner } from 'modules/app/app.types'
import { appBannersSelector } from 'modules/app/checkConnectivity.state'
import SessionOfflineBanner from 'modules/app/banners/SessionOfflineBanner'
import SessionExpiredBanner from 'modules/app/banners/SessionExpiredBanner'
import TimezoneBanner from 'modules/app/banners/TimezoneBanner'
import ActionFailedBanner from 'modules/app/banners/ActionFailedBanner'
import { authedSelector } from 'modules/auth/redux_store/state/getUser'

interface AppBannersProps {
  onCheckAppSessionStatus: () => void
}

const AppBanners: React.FC<AppBannersProps> = ({ onCheckAppSessionStatus }) => {
  const appBanners = useSelector(appBannersSelector)
  const authed = useSelector(authedSelector)

  return (
    <>
      {appBanners.map((banner) => {
        switch (banner) {
          case AppBanner.ACTION_FAILED:
            return authed && <ActionFailedBanner key="ActionFailed" />
          case AppBanner.SESSION_OFFLINE:
            return <SessionOfflineBanner key="SessionOffline" onCheckAppSessionStatus={onCheckAppSessionStatus} />
          case AppBanner.SESSION_EXPIRED:
            return <SessionExpiredBanner key="SessionExpired" />
          case AppBanner.TIMEZONE:
            return <TimezoneBanner key="Timezone" />
          default:
            return <React.Fragment key="None" />
        }
      })}
    </>
  )
}

export default React.memo(AppBanners)
