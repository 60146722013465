import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  createSeasonalForecastColumns,
  getClimatologyDataStreams,
  getSeasonalForecastDataStreams,
  MonthlyViewModeIds,
  prepareSeasonalItemsForPrintViewTable,
} from 'utils/seasonalForecast'
import { RETABLE_ID_LONG_RANGE_FORECAST, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import ReTable from 'modules/reTable/ReTable'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import styled from 'styled-components'
import { Asset } from 'modules/asset/store/asset.types'
import { table } from 'themes/theme-light'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import {
  reTableCollapsedSelector,
  reTableColumnsSelectedSelector,
  reTableSortSelector,
} from 'modules/reTable/redux_store/state/view.state'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import SeasonalForecastTableBody from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastTableBody'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { appTourColors } from 'modules/app/tour/OnBoardingTourDialog'
import { t } from 'ttag'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import PrintViewTable from 'modules/printView/PrintViewTable'

const ErrorMessage = styled.div`
  color: ${appTourColors.darkBlue};
  margin-left: 42px;
`

const SeasonalTable = styled(ReTable)`
  & .MuiTable-root {
    width: 100%;
  }
`

interface SeasonalForecastTableProps {
  seasonalForecastItems: ReTableItem[]
  assets: Asset[]
  showTableData: boolean
  setAllResultsLoading: (value: boolean) => void
  climatologySelected: boolean
  forecastSelected: boolean
  isPrintView?: boolean
  monthlyViewMode: MonthlyViewModeIds
}

const SeasonalForecastTable: React.FC<SeasonalForecastTableProps> = ({
  seasonalForecastItems,
  assets,
  showTableData,
  setAllResultsLoading,
  climatologySelected,
  forecastSelected,
  isPrintView = false,
  monthlyViewMode,
}) => {
  const dispatch = useDispatch()

  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes

  const [itemsToRender, setItemsToRender] = useState<ReTableItem[]>([])

  const collapsibleIds = useMemo(() => assets.map((asset) => asset.id), [assets])
  const defaultCollapsed = useMemo(() => [], [])
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_LONG_RANGE_FORECAST)

  const columnsAvailable = useMemo(() => createSeasonalForecastColumns(monthlyViewMode), [monthlyViewMode])
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_LONG_RANGE_FORECAST)

  const addAssetsAsParentsToSeasonalItems = (items: ReTableItem[]) => {
    let itemsWithAssets: typeof itemsToRender = []
    assets.forEach((asset) => {
      const assetSeasonalForecastItems = items.filter((item) => asset.id === item?.assetId)

      itemsWithAssets.push(asset)
      if (assetSeasonalForecastItems.length > 0) {
        itemsWithAssets = itemsWithAssets.concat(assetSeasonalForecastItems)
      }
    })
    return itemsWithAssets
  }

  const handleItemsToRenderChange = useCallback(
    (items: ReTableItem[]) => {
      let rowItems: typeof itemsToRender = []
      if (sort.active || true) {
        rowItems = items
      } else {
        rowItems = addAssetsAsParentsToSeasonalItems(items)
      }

      requestAnimationFrame(() => {
        setItemsToRender(rowItems)
      })
    },
    [assets, sort],
  )

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'type',
    }),
    [],
  )

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_LONG_RANGE_FORECAST, columnsAvailable })
  }, [JSON.stringify(columnsAvailable)])

  useEffect(() => {
    const columnsSelectedNames = columnsSelected.map((c) => c.name)
    const updatedColumnsSelected = columnsAvailable
      .filter((c) => {
        return c.fixed || columnsSelectedNames.includes(c.name)
      })
      .map((c) => c.name)
    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_LONG_RANGE_FORECAST,
      columnsSelected: updatedColumnsSelected,
    })
  }, [columnsAvailable, columnsSelected])

  // These are dataStream rows which are added dynamically
  const seasonalForecastDataStreams = getSeasonalForecastDataStreams()
  const climatologyDataStreams = getClimatologyDataStreams()

  const extraRowsCount = useMemo(() => {
    let count = 0
    assets.forEach((asset) => {
      const assetSpecificItems = itemsToRender?.filter((item) => asset.id === item?.assetId)
      const hasAssetSeasonalItems = assetSpecificItems.some((item) => seasonalForecastDataStreams.includes(item.type))
      if (hasAssetSeasonalItems) count = count + 1

      const hasAssetClimatologyItems = assetSpecificItems.some((item) => climatologyDataStreams.includes(item.type))
      if (hasAssetClimatologyItems) count = count + 1
    })

    return count
  }, [assets, itemsToRender])

  const collapsedIds = useReTableSelectorWithId(reTableCollapsedSelector, RETABLE_ID_LONG_RANGE_FORECAST)

  useEffect(() => {
    if (isPrintView) {
      setAllResultsLoading(false)
    }
  }, [isPrintView])

  /**
   * Prepare seasonal forecast items for print view
   * Need to add asset and its corresponding
   * **/
  const seasonalItemsForPrintView = useMemo(() => {
    return prepareSeasonalItemsForPrintViewTable({
      assets,
      seasonalForecastItems,
      forecastSelected,
      climatologySelected,
    })
  }, [seasonalForecastItems, assets, forecastSelected, climatologySelected])

  /**
   * We need to prepare columns for print view
   * Need to modify the name of the type column
   * Because we set the table cell with help of 'name'
   * **/
  const columnsForPrintView = columnsSelected.map((column) => {
    if (column.name === 'type') {
      return { ...column, name: 'name' }
    } else return column
  })

  return (
    <>
      {isPrintView ? (
        <PrintViewTable columns={columnsForPrintView} itemsToRender={seasonalItemsForPrintView} />
      ) : (
        <DynamicTableHeightContainer numberOfRows={assets.length + itemsToRender?.length} narrowRows={extraRowsCount}>
          <SeasonalTable
            id={RETABLE_ID_LONG_RANGE_FORECAST}
            itemHeight={table.rowHeight}
            items={seasonalForecastItems}
            hasExtendedHeader={false}
            itemsPaddingHeader={ITEMS_PADDING_HEADER}
            itemsPaddingFooter={ITEMS_PADDING_FOOTER}
            renderAheadCount={RENDER_AHEAD_COUNT}
            collapsibleIds={collapsibleIds}
            defaultCollapsed={defaultCollapsed}
            defaultSort={defaultSort}
            onItemsToRenderChange={handleItemsToRenderChange}
          >
            <ReTableGenericHeader
              tableHeaderHasActions={false}
              isHierarchical={false}
              columns={columnsSelected}
              tableRowsHasCrudActions={false}
              autoWidthForHeaderWithSort={true}
            />
            {showTableData ? (
              <SeasonalForecastTableBody
                columns={columnsSelected}
                itemsToRender={itemsToRender}
                tableHeaderHasActions={true}
                assets={assets}
                setAllResultsLoading={setAllResultsLoading}
                climatologySelected={climatologySelected}
                forecastSelected={forecastSelected}
                collapsedIds={collapsedIds}
                monthlyViewMode={monthlyViewMode}
              />
            ) : (
              <ReTableBody>
                <ReTableRow>
                  <ReTableCell colSpan={columnsSelected.length}>
                    <ErrorMessage>{t`Please select both an Asset and a Seasonal Forecast data stream.`}</ErrorMessage>
                  </ReTableCell>
                </ReTableRow>
              </ReTableBody>
            )}
          </SeasonalTable>
        </DynamicTableHeightContainer>
      )}
    </>
  )
}

export default SeasonalForecastTable
