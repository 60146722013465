import styled from 'styled-components'
import { TableCell, TableCellProps } from '@material-ui/core'

interface ReTableProps {
  width?: string | number
  no_border?: boolean
}
export const ReTableCell = styled(TableCell)<ReTableProps & TableCellProps>`
  &.MuiTableCell-root {
    width: ${(props) => (props.width ? props.width : 'inherit')};
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-right: ${(props) => (props.no_border ? 'none' : '2px solid white')};
  }
`
