import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { ForecastModel } from 'modules/quality/quality.types'
import { Asset } from 'modules/asset/store/asset.types'
import { t } from 'ttag'

import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, TextField } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { User } from 'modules/auth/Auth.types'

// Styled Element
const ButtonWithIcon = styled(IconButton)`
  &.MuiIconButton-root {
    margin-left: 0.3em;
  }
`

// Interface
interface ForecastModelNameProps {
  initialValue: string
  forecastModel: ForecastModel
  asset: Partial<Asset>
  user: User
}

const ForecastModelName: React.FC<ForecastModelNameProps> = ({ initialValue, forecastModel, asset }) => {
  const dispatch = useDispatch()

  // State
  const [inputValue, setInputValue] = useState(initialValue)

  let cs = undefined
  if (forecastModel?.parameter?.windCorrections) {
    cs =
      typeof forecastModel?.parameter?.windCorrections === 'string'
        ? JSON.parse(forecastModel?.parameter?.windCorrections)
        : forecastModel?.parameter?.windCorrections
  }

  const correction = cs?.name || ''

  // 1. Change input value handler
  const handleChangeName = useCallback((event) => {
    setInputValue(event.target.value)
  }, [])

  // 2. Clear input value
  const clearInputHandler = useCallback(
    (event) => {
      event.stopPropagation()
      setInputValue(initialValue)
    },
    [initialValue],
  )

  // 3. Save input value
  const saveInputHandler = useCallback(
    (event) => {
      event.stopPropagation()
      // Make Request
      dispatch({ type: actionTypes.SAVE_FORECAST_MODEL_NAME_REQUEST, asset, forecastModel, inputValue })
    },
    [inputValue],
  )

  // 4. Handle single click of input
  const handleSingleClickOfInput = useCallback((event) => {
    event.stopPropagation()
  }, [])

  return (
    <Flex direction="row" justifyContent="space-between" alignItems="center">
      <TextField
        type="text"
        placeholder={t`Model name`}
        // label={t`Model name`}
        value={inputValue}
        onClick={(event) => handleSingleClickOfInput(event)}
        onChange={(event) => handleChangeName(event)}
        InputProps={{
          style: {
            width: '9em',
          },
        }}
      />
      {initialValue === inputValue ? (
        <Box width={'2.2em'} ml={0.8}>
          {correction}
        </Box>
      ) : (
        <>
          <ButtonWithIcon size="small" onClick={(event) => clearInputHandler(event)}>
            <FontAwesomeIcon icon="times" />
          </ButtonWithIcon>
          <ButtonWithIcon color="primary" size="small" onClick={(event) => saveInputHandler(event)}>
            <FontAwesomeIcon icon="check" />
          </ButtonWithIcon>
        </>
      )}
    </Flex>
  )
}

export default React.memo(ForecastModelName)
