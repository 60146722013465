import { request } from 'utils/request'
import axios from 'axios'
import { LoginType, RegistrationType, ResetPasswordType, User, UserConfig } from 'modules/auth/Auth.types'
import { ForgotPasswordType } from 'modules/auth/redux_store/state/forgotPassword'
import { ResendConfirmationType } from 'modules/auth/redux_store/state/resendConfirmation'

export const register = (regData: RegistrationType) => {
  return request(() => axios.post('/api/register', regData))
}

export const activateUser = (activationKey: string) => {
  return request(() => axios.get(`/api/activate?key=${activationKey}`))
}

export const changePassword = (password: string) => {
  return request(() =>
    axios.post('/api/account/change_password', password, { headers: { 'Content-Type': 'text/html' } }),
  )
}

export const login = ({ username, password, rememberMe = true }: LoginType) => {
  const formData = new FormData()
  formData.append('j_username', username)
  formData.append('j_password', password)
  formData.append('remember-me', rememberMe.toString())
  return request(() => axios.post('/api/authentication', formData))
}

export const getCompanyAccounts = () => {
  return request(() => axios.get('/api/account/company-accounts'))
}

export const loginJWT = ({ username, password }: LoginType) => {
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  // }
  const headers = {
    Authorization: `Basic ${username} ${password}`,
  }
  return request(() => axios.get('/api/auth/v1/token', { headers }))
}

export const getUser = () => {
  return request(() => axios.get<User>('/api/account'))
}

export const saveUser = (user: User) => {
  return request(() => axios.post('/api/account', user))
}

export const getUserConfig = (collectionId: string, configId?: string) => {
  const path = configId ? `${collectionId}/${configId}` : collectionId
  return request(() => axios.get<UserConfig | UserConfig[]>(`/api/externaluserconfig/v1/${path}`))
}

export const setUserConfig = (collectionId: string, configId: string, value: UserConfig['value']) => {
  return request(() =>
    axios.post<UserConfig>(`/api/externaluserconfig/v1/${collectionId}/${configId}`, value, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  )
}

export const deleteUserConfig = (collectionId: string, configId?: string) => {
  const path = configId ? `${collectionId}/${configId}` : collectionId
  return request(() => axios.delete(`/api/externaluserconfig/v1/${path}`))
}

export const forgotPassword = ({ email }: ForgotPasswordType) => {
  return request(() =>
    axios.post('/api/account/reset_password/init', email, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  )
}

export const resetPassword = (data: ResetPasswordType) => {
  return request(() => axios.post('/api/account/reset_password/finish', data))
}

export const resendConfirmation = ({ email }: ResendConfirmationType) => {
  return request(() =>
    axios.post('/api/account/resend_activation_mail', email, {
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  )
}

export const logout = () => {
  return request(() => axios.post('/api/logout'))
}

export const leaveImpersonate = () => {
  return request(() => axios.get('/api/logout/impersonate'))
}
