import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getAllWeatherDataStreamsColumns } from 'utils/dataStream'
import Flex from 'ui/styles/Flex'
import { RETABLE_ID_ALL_WEATHER_DATASTREAMS } from 'modules/reTable/reTable.types'
import { table } from 'themes/theme-light'
import ReTableForecastsManagement from 'modules/reTable/ReTableForecastsManagement'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { c } from 'ttag'
import {
  useActiveWeatherConfigs,
  useWeatherCatalogItem,
  useWeatherConfigSaveMutation,
  useWeatherDataTreeItems,
} from 'modules/dataStreams/api/weatherData.api'
import { DetailsKey, DetailsKeyValueGrid, DetailsValue } from 'ui/form/assetForm.style'
import DataStreamPreviewContainer from 'modules/dataStreams/DataStreamPreviewContainer'
import LayoutTitle from 'ui/LayoutTitle'
import { WeatherConfig } from 'modules/weather/store/weather.types'

import { DataStreamListContainer } from 'modules/dataStreams/ManageDataStreams'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

const WeatherDataStreams: React.FC = () => {
  const dispatch = useDispatch()

  const [selectedItemId, setSelectedItemId] = useState<string | undefined>()

  // const weatherDataStreams = useMemo(() => getWeatherDataStreams(), [])
  const activeWeatherConfigs = useActiveWeatherConfigs()
  const activeWeatherConfigIds = useMemo(() => {
    return (activeWeatherConfigs.data || []).map((config) => config.id)
  }, [activeWeatherConfigs])
  const weatherDataStreams = useWeatherDataTreeItems()
  const dataStreamDetails = useWeatherCatalogItem(selectedItemId)

  const selectedItem = useMemo(() => {
    return (weatherDataStreams || []).find((config) => config.id === selectedItemId)
  }, [selectedItemId, weatherDataStreams])

  // map shape
  // const defaultOptions = useMemo(
  //   () => ({
  //     center: {
  //       lat: 50,
  //       lng: 10,
  //     },
  //     zoom: 1,
  //   }),
  //   [],
  // )

  // const shape = useMemo(() => {
  //   if (!dataStreamDetails.data?.spatialCoverageShape) return
  //   if (dataStreamDetails.data.geoWholeEarth) {
  //     // TODO correct bounding box for whole world
  //     return
  //     // return {
  //     //   multipoint: null,
  //     //   multipolygon: multipolygonFormatter([
  //     //     [
  //     //       [
  //     //         [-90.0, -180.0],
  //     //         [90.0, -180.0],
  //     //         [90.0, 179.76],
  //     //         [-90.0, 179.76],
  //     //         [-90.0, -180.0],
  //     //       ],
  //     //     ],
  //     //   ]),
  //     // }
  //   } else {
  //     return {
  //       multipoint: null,
  //       multipolygon: [
  //         JSON.parse(dataStreamDetails.data.spatialCoverageShape).coordinates[0].map((coords) => ({
  //           lat: coords[1],
  //           lng: coords[0],
  //         })),
  //       ],
  //     }
  //   }
  // }, [dataStreamDetails.data])

  const allWeatherDataStreamsColumns = useMemo(getAllWeatherDataStreamsColumns, [])
  const [allItemsToRender, setAllItemsToRender] = useState([])

  const collapsibleIds = useMemo(() => weatherDataStreams.map((item) => item.id), [weatherDataStreams])
  const defaultCollapsed = useMemo(() => [], [])

  const handleAllItemsToRenderChange = useCallback((items) => {
    requestAnimationFrame(() => {
      setAllItemsToRender(items)
    })
  }, [])

  const handleClickDataStream = useCallback((item) => {
    setSelectedItemId(item.id)
  }, [])

  const { mutate: saveMutation } = useWeatherConfigSaveMutation()
  const handleAddWeatherForecast = useCallback(() => {
    const activeConfigs = activeWeatherConfigs.data || []
    const newData = [...activeConfigs, { id: selectedItemId } as WeatherConfig]
    saveMutation(newData)
  }, [activeWeatherConfigs.data, selectedItemId])

  const disableAddBtn = useMemo(() => activeWeatherConfigIds.some((id) => selectedItemId === id), [
    activeWeatherConfigIds,
    selectedItemId,
  ])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_ALL_WEATHER_DATASTREAMS,
      columnsAvailable: allWeatherDataStreamsColumns,
    })
  }, [allWeatherDataStreamsColumns])

  return (
    <Flex direction="row">
      <DataStreamListContainer direction="column">
        <LayoutTitle>
          <Box>{c('Weather Forecast').t`Add Weather Data`}</Box>
        </LayoutTitle>
        <ReTableForecastsManagement
          tableId={RETABLE_ID_ALL_WEATHER_DATASTREAMS}
          itemHeight={table.rowHeight}
          collapsibleIds={collapsibleIds}
          defaultCollapsed={defaultCollapsed}
          items={weatherDataStreams}
          itemsToRender={allItemsToRender}
          selectedItem={selectedItem}
          disableSelectedItems={true}
          onItemsToRenderChange={handleAllItemsToRenderChange}
          columns={allWeatherDataStreamsColumns}
          onSelectItem={handleClickDataStream}
          hasAuthority={true}
          activeForecastIds={activeWeatherConfigIds}
          icon="plus"
        />
      </DataStreamListContainer>

      <DataStreamPreviewContainer
        isDataStreamSelected={Boolean(selectedItemId) && selectedItem?.uiChildren?.length === 0}
        previewHeader={c('Weather Forecast:Details').t`Preview`}
        emptyBoxText={c('Weather Forecast:Details')
          .t`Select weather forecast on the left to obtain more information about it.`}
        useBtnText={c('Weather Forecast:Details').t`Add`}
        onUseDataStream={handleAddWeatherForecast}
        disableUseBtn={disableAddBtn}
        usedFrom={TimeSeriesType.WEATHER_DATA}
      >
        {/*What is commented might be USED LATER*/}
        {dataStreamDetails.data && Boolean(selectedItemId) && selectedItem?.uiChildren?.length === 0 && (
          <>
            {/*<MapImage src={areaForecastMapImg} />*/}
            {/*<GoogleMap*/}
            {/*  id="preview-map"*/}
            {/*  mapContainerStyle={{ height: '450px', width: '500px' }}*/}
            {/*  zoom={defaultOptions.zoom}*/}
            {/*  center={defaultOptions.center}*/}
            {/*>*/}
            {/*  {shape ? plotShape(shape, -1) : null}*/}
            {/*</GoogleMap>*/}
            <Box mt={1}>
              <Typography variant="subtitle2">{c('Site Forecast:Preview').t`Details`}</Typography>
              {dataStreamDetails.data.text && <p>{dataStreamDetails.data.text}</p>}
              {dataStreamDetails.data.fullName && (
                <DetailsKeyValueGrid>
                  <DetailsKey>{c('Weather Forecast:Details').t`Name:`}</DetailsKey>
                  <DetailsValue>{dataStreamDetails.data.fullName}</DetailsValue>
                </DetailsKeyValueGrid>
              )}
              {/*{dataStreamDetails.data.deliveryDelay && (*/}
              {/*  <DetailsKeyValueGrid>*/}
              {/*    <DetailsKey>{c('Weather Forecast:Details').t`Delivery delay:`}</DetailsKey>*/}
              {/*    <DetailsValue>*/}
              {/*      {dataStreamDetails.data.deliveryDelay / 60} {t`hours`}*/}
              {/*    </DetailsValue>*/}
              {/*  </DetailsKeyValueGrid>*/}
              {/*)}*/}
              {/*{dataStreamDetails.data.minSourceTemporalResolution && (*/}
              {/*  <DetailsKeyValueGrid>*/}
              {/*    <DetailsKey>{c('Weather Forecast:Details').t`Min. temp. resolution:`}</DetailsKey>*/}
              {/*    <DetailsValue>*/}
              {/*      {dataStreamDetails.data.minSourceTemporalResolution / 60} {t`hours`}*/}
              {/*    </DetailsValue>*/}
              {/*  </DetailsKeyValueGrid>*/}
              {/*)}*/}
              {/*{dataStreamDetails.data.maxSourceTemporalResolution && (*/}
              {/*  <DetailsKeyValueGrid>*/}
              {/*    <DetailsKey>{c('Weather Forecast:Details').t`Max. temp. resolution:`}</DetailsKey>*/}
              {/*    <DetailsValue>*/}
              {/*      {dataStreamDetails.data.maxSourceTemporalResolution / 60} {t`hours`}*/}
              {/*    </DetailsValue>*/}
              {/*  </DetailsKeyValueGrid>*/}
              {/*)}*/}
              {dataStreamDetails.data.minSpatialResolution && dataStreamDetails.data.minSpatialResolution !== 'NaN' && (
                <DetailsKeyValueGrid>
                  <DetailsKey>{c('Weather Forecast:Details').t`Min. spatial resolution:`}</DetailsKey>
                  <DetailsValue>{dataStreamDetails.data.minSpatialResolution}°</DetailsValue>
                </DetailsKeyValueGrid>
              )}
              {dataStreamDetails.data.maxSpatialResolution && dataStreamDetails.data.maxSpatialResolution !== 'NaN' && (
                <DetailsKeyValueGrid>
                  <DetailsKey>{c('Weather Forecast:Details').t`Max. spatial resolution:`}</DetailsKey>
                  <DetailsValue>{dataStreamDetails.data.maxSpatialResolution}°</DetailsValue>
                </DetailsKeyValueGrid>
              )}
              {/*{dataStreamDetails.data.minForecastHorizon && (*/}
              {/*  <DetailsKeyValueGrid>*/}
              {/*    <DetailsKey>{c('Weather Forecast:Details').t`Min. forecast horizon:`}</DetailsKey>*/}
              {/*    <DetailsValue>*/}
              {/*      {dataStreamDetails.data.minForecastHorizon / 60 / 24} {t`days`}*/}
              {/*    </DetailsValue>*/}
              {/*  </DetailsKeyValueGrid>*/}
              {/*)}*/}
              {/*{dataStreamDetails.data.maxForecastHorizon && (*/}
              {/*  <DetailsKeyValueGrid>*/}
              {/*    <DetailsKey>{c('Weather Forecast:Details').t`Max. forecast horizon:`}</DetailsKey>*/}
              {/*    <DetailsValue>*/}
              {/*      {dataStreamDetails.data.maxForecastHorizon / 60 / 24} {t`days`}*/}
              {/*    </DetailsValue>*/}
              {/*  </DetailsKeyValueGrid>*/}
              {/*)}*/}
              {/*{dataStreamDetails.data.geoWholeEarth && (*/}
              {/*  <DetailsKeyValueGrid>*/}
              {/*    <DetailsKey>{c('Weather Forecast:Details').t`Availability:`}</DetailsKey>*/}
              {/*    <DetailsValue>{c('Weather Forecast:Details').t`This forecast is available world wide`}</DetailsValue>*/}
              {/*  </DetailsKeyValueGrid>*/}
              {/*)}*/}
            </Box>
          </>
        )}
      </DataStreamPreviewContainer>
    </Flex>
  )
}

export default React.memo(WeatherDataStreams)
