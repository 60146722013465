import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Chip, Tab, Tabs } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import { TabItem } from 'utils/tabs'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import { isNumeric } from 'utils/dataFormatting'
import { assetTabNames } from 'fixtures/assetForm'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import { useCustomMuiStyles } from 'themes/theme-light'

const CompactTabs = styled(Tabs)`
  .MuiTab-root {
    min-width: auto !important;
  }

  .MuiTab-root:first-child {
    padding-left: 2px;
  }
`

const TabSpacer = styled.div`
  display: inline-block;
  min-width: 3em;
  height: 19px;
`

const Count = styled(Chip)`
  margin-left: 0.5em;

  &.MuiChip-root {
    height: 19px;
    vertical-align: inherit;
  }

  .MuiChip-label {
    line-height: 17px;
  }
`

const Loading = styled(CircularProgress)`
  margin-top: 6px;
  margin-left: 4px;
`

interface TabBarProps {
  isNew?: boolean
  tabs: TabItem[]
  activeTab: string
  onChange: (tab: TabItem) => void
  changed?: boolean
}

const TabBar: React.FC<TabBarProps> = ({ isNew, tabs, activeTab, onChange, changed }) => {
  const user = useSelector(getUserResultSelector)
  const classes = useCustomMuiStyles()
  const isReadOnlyUser = useIsReadOnlyUser()

  // TODO remove all asset related logic from this generic component
  const { details, forecastModels } = assetTabNames

  const handleChange = useCallback(
    (event: React.ChangeEvent<Record<string, unknown>>, newActive: string) => {
      onChange(tabs.find((tab) => tab.name === newActive) || tabs[0])
    },
    [tabs, onChange],
  )

  const getLabel = useCallback(
    (tab: TabItem) => {
      return (
        <Flex>
          <>{tab.label}</>
          {tab.showCount && !isNew && (
            <TabSpacer>
              {isNumeric(tab?.count) && <Count size="small" color="primary" label={tab.count} />}
              {tab.loading && <Loading size={12} />}
            </TabSpacer>
          )}
        </Flex>
      )
    },
    [isNew],
  )

  const visibleTabs = useMemo(() => {
    return user ? tabs.filter((tab) => !tab.userHasPermission || tab.userHasPermission(user)) : tabs
  }, [user, tabs])

  return (
    <>
      <CompactTabs
        value={activeTab}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
      >
        {visibleTabs.map((tab) => (
          <Tab
            disabled={tab.name !== details && ((isReadOnlyUser && tab.name === forecastModels) || changed || isNew)}
            key={tab.name}
            value={tab.name}
            label={getLabel(tab)}
            className={
              tab.isAdminComponent
                ? (isAdmin(user) || isImpersonatedAdmin(user)) && tab.isAdminComponent
                  ? classes.adminComponent
                  : 'inherit'
                : 'inherit'
            }
          />
        ))}
      </CompactTabs>
    </>
  )
}

export default React.memo(TabBar)
