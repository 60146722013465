import { Box } from '@material-ui/core'

import {
  DataStreamSelectionItem,
  E3Ensemble,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'

import ModelSlider from 'modules/workspace/advancedChartWidgets/metaForecast/ModelSlider'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { useDebouncedCallback } from 'use-debounce'
import { isNumeric } from 'utils/dataFormatting'
import { MetaForecastWidgetType, SAVE_WORKSPACE_DRAFT_REQUEST } from 'modules/workspace/store/workspace.types'

import { MetaForecastSliderContainer } from 'modules/workspace/advancedChartWidgets/metaForecast/MetaForecastConfiguration'
import { useSavedForecastWeights } from 'utils/hooks/useSavedForecastWeights'
import { workspaceDraftE3WidgetSettingsSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { createSlidersInASequence, defaultEnsembleWeightsByDataStreams } from 'utils/e3'
import NumberField from 'ui/elements/NumberField'
import { checkIfObjectHasValuesForAllKeys } from 'utils/array'
import MetaForecastWidgetMessage from 'modules/workspace/advancedChartWidgets/metaForecast/MetaForecastWidgetMessage'

interface SliderContainerProps {
  show_border: number
  first_child_with_slider?: number
}
const SliderContainer = styled(Box)<SliderContainerProps>`
  height: 30px;
  border-bottom: ${(props) => props.show_border && '1px solid rgba(224, 224, 224, 1)'};
`

interface MetaForecastSlidersProps {
  qualityTableDataStreams: DataStreamSelectionItem[]
  typeOfModel: MetaForecastWidgetType
  isBackCastEnabled: boolean
  loadSavedWeights: boolean
  invalidNumberOfSelection: boolean
  meterDataWithValue: boolean
  onSetLoadSavedWeightsToFalse: () => void
}

const MetaForecastSliders: React.FC<MetaForecastSlidersProps> = ({
  qualityTableDataStreams,
  typeOfModel,
  isBackCastEnabled,
  loadSavedWeights,
  invalidNumberOfSelection,
  meterDataWithValue,
  onSetLoadSavedWeightsToFalse,
}) => {
  const dispatch = useDispatch()
  const [e3Ensemble, setE3Ensemble] = useState<Record<string, any> | null>(null)
  const [dataStreamSelectionChanged, setDataStreamSelectionChanged] = useState(true)

  const savedE3Weights = useSavedForecastWeights(typeOfModel) || {}
  const metaForecastWidgetDataInDraft = useSelector(workspaceDraftE3WidgetSettingsSelector)

  const weightsInDraft = useMemo(() => {
    if (typeOfModel === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST) {
      return metaForecastWidgetDataInDraft.thirdPartySliders || {}
    } else {
      return metaForecastWidgetDataInDraft.weatherModelSliders || {}
    }
  }, [metaForecastWidgetDataInDraft])

  const dataStreamsOfType = qualityTableDataStreams.filter(
    (dataStream) =>
      dataStream.subType === typeOfModel &&
      dataStream.type !== TimeSeriesType.METER_DATA &&
      dataStream.type !== TimeSeriesType.META_FORECAST,
  )

  const updateStore = useDebouncedCallback((updatedE3Ensemble: E3Ensemble) => {
    const draft = {
      e3WidgetSettings: {
        ...metaForecastWidgetDataInDraft,
        [typeOfModel === TimeSeriesSubType.E3_WEATHER_TRACK
          ? 'weatherModelSliders'
          : 'thirdPartySliders']: updatedE3Ensemble,
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }, 500)

  // console.log({ metaForecastWidgetDataInDraft, metaForecastData })

  const handleChange = useCallback(
    (dataStreamId: string) => (value: number) => {
      const updatedE3Ensemble = { ...e3Ensemble, [dataStreamId]: value }
      setE3Ensemble(updatedE3Ensemble)
      updateStore(updatedE3Ensemble)
      if (loadSavedWeights) {
        onSetLoadSavedWeightsToFalse()
      }
    },
    [e3Ensemble, loadSavedWeights],
  )

  /**
   * Delete the weights from draft if the slider is not shown
   * */
  useEffect(() => {
    if (weightsInDraft) {
      const keysOfWeightsInDraft = Object.keys(weightsInDraft)
      const dataStreamSlidersToDisplayIds = dataStreamsOfType.map((d) => d.id)
      const draftHasKeysOtherThanSelectedDataStreams = keysOfWeightsInDraft.filter(
        (key) => !dataStreamSlidersToDisplayIds.includes(key),
      )
      if (draftHasKeysOtherThanSelectedDataStreams) {
        const modifiedDraftWeights = { ...weightsInDraft }
        keysOfWeightsInDraft.forEach((keyInDraft) => {
          if (!dataStreamSlidersToDisplayIds.includes(keyInDraft)) {
            delete modifiedDraftWeights[keyInDraft]
          }
        })
        updateStore(modifiedDraftWeights)
      }
    }
  }, [JSON.stringify(dataStreamsOfType), JSON.stringify(weightsInDraft)])

  /**
   * Set the e3Ensemble weights from Asset/Model
   * */
  useEffect(() => {
    if (loadSavedWeights && dataStreamsOfType.length && savedE3Weights && Object.keys(savedE3Weights).length) {
      // Saved Asset/Model weights
      setE3Ensemble(savedE3Weights)
    }
  }, [JSON.stringify(dataStreamsOfType), loadSavedWeights, JSON.stringify(savedE3Weights)])

  // console.log({ weightsInDraft, e3Ensemble, savedE3Weights })

  /**
   * On reload or a new data stream selected
   * Set the e3Ensemble weights from Draft or default
   * */
  useEffect(() => {
    if (!dataStreamsOfType.length) {
      setE3Ensemble({})
      return
    }

    if (!loadSavedWeights && dataStreamSelectionChanged) {
      // Default Weights
      let sliderValues = defaultEnsembleWeightsByDataStreams(dataStreamsOfType)
      // Draft weights
      if (Object.keys(weightsInDraft).length && checkIfObjectHasValuesForAllKeys(weightsInDraft)) {
        // If new data stream selected check if there is a value (0 - 100) in draft if not set it to 50
        const modifiedDraftWeights = dataStreamsOfType.reduce((ensemble, dataStream) => {
          const weight =
            dataStream?.id && weightsInDraft?.hasOwnProperty(dataStream?.id) ? weightsInDraft[dataStream.id] : 50
          return {
            ...ensemble,
            [`${dataStream.id}`]: weight,
          }
        }, {})

        sliderValues = modifiedDraftWeights
      }
      setDataStreamSelectionChanged(false)
      updateStore(sliderValues)
      setE3Ensemble(sliderValues)
    }
  }, [JSON.stringify(dataStreamsOfType), dataStreamSelectionChanged, JSON.stringify(weightsInDraft), loadSavedWeights])

  /**
   * Set dataStreamSelected to true when a data stream is selected
   */
  useEffect(() => {
    setDataStreamSelectionChanged(true)
  }, [JSON.stringify(dataStreamsOfType)])

  /**
   * Set the e3 ensemble on loading the weights for the first time in Draft
   * */
  const setEnsembleInitiallyInDraftRef = useRef(true)
  useEffect(() => {
    if (!Object.keys(weightsInDraft).length && Object.keys(e3Ensemble || {}).length) {
      if (setEnsembleInitiallyInDraftRef.current) {
        setEnsembleInitiallyInDraftRef.current = false
        updateStore(e3Ensemble)
      }
    }
  }, [e3Ensemble, JSON.stringify(weightsInDraft)])

  const sequenceOfSlidersToRender = useMemo(() => {
    return createSlidersInASequence({ dataStreamsOfType, qualityTableDataStreams, isBackCastEnabled })
  }, [qualityTableDataStreams, dataStreamsOfType, isBackCastEnabled])

  const filteredSequenceOfSlidersToRender = useMemo(() => {
    // if (!meterDataWithValue) {
    // return sequenceOfSlidersToRender.filter((sequence) => sequence.showSlider)
    // }
    return sequenceOfSlidersToRender
  }, [meterDataWithValue, sequenceOfSlidersToRender])

  // console.log('filteredSequenceOfSlidersToRender =', filteredSequenceOfSlidersToRender)

  const showMessage = !dataStreamsOfType?.length || invalidNumberOfSelection

  return (
    <MetaForecastSliderContainer elevation={0}>
      {showMessage ? (
        <MetaForecastWidgetMessage
          dataStreamNotSelected={!dataStreamsOfType?.length}
          invalidNumberOfSelection={invalidNumberOfSelection}
          typeOfModel={typeOfModel}
        />
      ) : (
        <>
          <Box height={'30px'}></Box>

          {filteredSequenceOfSlidersToRender?.map((data, index) => {
            // Need to add border-bottom as we do for re-table cells
            const isNextFirstChildWithSlider =
              filteredSequenceOfSlidersToRender[index] &&
              !filteredSequenceOfSlidersToRender[index]?.showSlider &&
              filteredSequenceOfSlidersToRender[index + 1] &&
              filteredSequenceOfSlidersToRender[index + 1]?.showSlider
                ? 1
                : 0
            return (
              <SliderContainer key={data.id} show_border={data.showSlider || isNextFirstChildWithSlider ? 1 : 0}>
                {data.showSlider ? (
                  <Flex title={data.name} justifyContent="center" alignItems="center">
                    <ModelSlider
                      value={isNumeric(e3Ensemble?.[data.id]) ? (e3Ensemble[data.id] as number) : 0}
                      onChange={handleChange(data.id)}
                      loading={false}
                    />
                    <NumberField
                      inputValue={Math.round(e3Ensemble?.[data.id] || 0)}
                      onChangeValue={handleChange(data.id)}
                    />
                  </Flex>
                ) : (
                  <></>
                )}
              </SliderContainer>
            )
          })}
        </>
      )}
    </MetaForecastSliderContainer>
  )
}

export default React.memo(MetaForecastSliders)
