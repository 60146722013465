import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import createReducer from 'utils/createReducer'

// state

interface State {
  selectedConfig: string | null
  workspaceConfig: Partial<WorkspaceConfig>
}

const initialState: State = {
  selectedConfig: null,
  workspaceConfig: {},
}

// types

export const SET_SELECTED_CONFIG = 'SET_SELECTED_CONFIG'
export type SET_SELECTED_CONFIG = typeof SET_SELECTED_CONFIG
export type VIEW_ACTION_TYPE = SET_SELECTED_CONFIG

interface ViewAction {
  type: VIEW_ACTION_TYPE
  selectedConfig: State['selectedConfig']
  workspaceConfig: State['workspaceConfig']
  draft: State['workspaceConfig']
}

// reducers

const selectedConfig = createReducer<State['selectedConfig'], ViewAction>(
  (state = initialState.selectedConfig, { type, selectedConfig }) => {
    if (type === SET_SELECTED_CONFIG) {
      return selectedConfig
    }
    return state
  },
)

export const viewReducer = combineReducers({
  selectedConfig,
})

// selectors
export const selectedWorkspaceConfigIdSelector = createSelector<any, State['selectedConfig'], State['selectedConfig']>(
  (state) => state.workspace.view.selectedConfig,
  (selectedConfig) => selectedConfig,
)

// api

// sagas
