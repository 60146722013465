import { RefObject, useCallback, useEffect, useRef, useState } from 'react'

// update scroll event only once per animation frame (ideal for 60fps rendering)
// inspired by: https://dev.to/adamklein/build-your-own-virtual-scroll-part-ii-3j86
export const useScroll = (existingRef?: RefObject<HTMLElement>) => {
  const [scrollTop, setScrollTop] = useState<number>(0)
  const ref = existingRef || useRef<HTMLElement>()
  const animationFrame = useRef<number>()

  const onScroll = useCallback((e) => {
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current)
    }
    animationFrame.current = requestAnimationFrame(() => {
      setScrollTop(e.target.scrollTop)
    })
  }, [])

  useEffect(() => {
    const scrollContainer = ref.current
    if (scrollContainer) {
      setScrollTop(scrollContainer.scrollTop)
      scrollContainer.addEventListener('scroll', onScroll)
      return () => scrollContainer.removeEventListener('scroll', onScroll)
    } else {
      console.error('ref for scroll container is not applied')
    }
  }, [])

  return { scrollTop, ref }
}
