import React from 'react'
import { Field } from 'react-final-form'
import SiteForecastDurationField from 'ui/elements/SiteForecastDurationField'
import { t } from 'ttag'
import FieldError from 'ui/form/FieldError'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'

export const InputContainer = styled.div`
  margin-right: 0.5em;
`

interface DurationWithArrowsProps {
  daysInputName?: string
  minDays?: number
  maxDays?: number
  repeatDays?: boolean
  hoursInputName?: string
  minHours?: number
  maxHours?: number
  minutesInputName?: string
  minMinutes?: number
  maxMinutes?: number
  disableInputs?: boolean
}

const DurationWithArrows: React.FC<DurationWithArrowsProps> = ({
  daysInputName,
  minDays,
  maxDays,
  repeatDays,
  hoursInputName,
  minHours,
  maxHours,
  minutesInputName,
  minMinutes,
  maxMinutes,
  disableInputs,
}) => {
  return (
    <>
      {daysInputName && (
        <InputContainer>
          <Field name={daysInputName}>
            {({ input, meta }) => (
              <Flex direction="row" alignItems="center">
                <SiteForecastDurationField
                  disabled={disableInputs}
                  repeatNumbers={repeatDays}
                  min={minDays || 0}
                  max={maxDays || 365}
                  durationText={t`days`}
                  input={input}
                />
                {meta.error && <FieldError error={meta.error} />}
              </Flex>
            )}
          </Field>
        </InputContainer>
      )}
      {hoursInputName && (
        <InputContainer>
          <Field name={hoursInputName}>
            {({ input, meta }) => (
              <Flex direction="row" alignItems="center">
                <SiteForecastDurationField
                  disabled={disableInputs}
                  min={minHours || 0}
                  max={maxHours || 23}
                  durationText={t`hours`}
                  input={input}
                  timeInput={true}
                />
                {meta.error && <FieldError error={meta.error} />}
              </Flex>
            )}
          </Field>
        </InputContainer>
      )}
      {minutesInputName && (
        <InputContainer>
          <Field name={minutesInputName}>
            {({ input, meta }) => (
              <Flex direction="row" alignItems="center">
                <SiteForecastDurationField
                  disabled={disableInputs}
                  min={minMinutes || 0}
                  max={maxMinutes || 59}
                  durationText={t`minutes`}
                  input={input}
                  timeInput={true}
                />
                {meta.error && <FieldError error={meta.error} />}
              </Flex>
            )}
          </Field>
        </InputContainer>
      )}
    </>
  )
}

export default React.memo(DurationWithArrows)
