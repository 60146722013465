export function getCoords(state) {
  return state.gips.coords
}

export function getMultipolygon(state) {
  return state.gips.coords.multipolygon
}

export function getMultiPoint(state) {
  return state.gips.coords.multipoint
}
