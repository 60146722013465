import React from 'react'
import { useSelector } from 'react-redux'
import { lighten, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { t } from 'ttag'

import FullHeight from 'ui/FullHeight'
import { colors, sideNavWidth, theme, toolbarHeight, useCustomMuiStyles } from 'themes/theme-light'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import {
  isAdmin,
  isAuthorizedToPenalties,
  isCurrentUserSubscribedTo,
  isImpersonatedAdmin,
  isIndianCustomer,
} from 'utils/user'
import OldUiIframe from 'ui/elements/OldUiIframe'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
  ROUTE_DATA,
  ROUTE_DATA_DELIVERY,
  ROUTE_DATA_FLOW,
  ROUTE_FILE_MANAGER,
  ROUTE_PENALTY_REGULATION,
  ROUTE_TRAINING,
  ROUTE_WORKBENCH,
} from 'modules/app/Routes'
import { useRouterMatch } from 'utils/route'
import CustomLink from 'ui/elements/CustomLink'
import Delivery from 'modules/delivery/Delivery'
import DataFlow from 'modules/data/dataFlow/DataFlow'
import { SideNavContainer } from 'modules/app/app.styles'
import FileManager from 'modules/data/fileManager/FileManager'
import { SubscribedProduct } from 'modules/auth/Auth.types'
import PenaltyRegulations from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulations'

const Container = styled(FullHeight)`
  display: grid;
  grid-template-columns: minmax(${sideNavWidth}, 0.1fr) auto 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'list divider content';
`

const DataSideNav = styled(SideNavContainer)`
  grid-area: list;
  padding: 1em;
  padding-right: 0;
`

const ContentLayout = styled.div`
  grid-area: content;
  display: inline-flex;
  flex-direction: column;
  padding: 0.5em;
  background-color: #fff;
  overflow: auto;
  height: calc(100vh - ${toolbarHeight});
`

interface StyledListItemProps {
  withAdminStyle?: boolean
}

const StyledListItem = styled(ListItem)<StyledListItemProps>`
  &.MuiListItem-root.Mui-selected {
    background-color: ${(props) =>
      props.withAdminStyle ? `${colors.adminFunctionalityColor.dark}` : `${lighten(theme.palette.primary.main, 0.8)}`};
    box-shadow: ${(props) => props.withAdminStyle && `inset 0 0 0 8px ${lighten(theme.palette.primary.main, 0.8)}`};
  }
`

const Data: React.FC = () => {
  //Style
  const classes = useCustomMuiStyles({ dark: true })

  const user = useSelector(getUserResultSelector)
  const { isMatch: isDataFlow } = useRouterMatch(ROUTE_DATA_FLOW)
  const { isMatch: isDataDelivery } = useRouterMatch(ROUTE_DATA_DELIVERY)
  const { isMatch: isFileManager } = useRouterMatch(ROUTE_FILE_MANAGER)

  // const { isMatch: isMasterdataImport } = useRouterMatch(ROUTE_MASTERDATA_IMPORT)
  const { isMatch: isTraining } = useRouterMatch(ROUTE_TRAINING)
  const { isMatch: isPenaltyRegulation } = useRouterMatch(ROUTE_PENALTY_REGULATION)

  const hasAccessToTraining = isAdmin(user) || isImpersonatedAdmin(user)

  return (
    <Container data-testid="page-tools">
      <DataSideNav>
        <List component="nav">
          <CustomLink route={ROUTE_DATA_DELIVERY}>
            <StyledListItem button selected={isDataDelivery}>
              <ListItemIcon>
                <FontAwesomeIcon icon="truck" fixedWidth />
              </ListItemIcon>
              <ListItemText primary={t`Delivery`} />
            </StyledListItem>
          </CustomLink>

          <CustomLink route={ROUTE_DATA_FLOW}>
            <StyledListItem button selected={isDataFlow}>
              <ListItemIcon>
                <FontAwesomeIcon icon="retweet" fixedWidth />
              </ListItemIcon>
              <ListItemText primary={t`Data flow`} />
            </StyledListItem>
          </CustomLink>

          <CustomLink route={ROUTE_FILE_MANAGER}>
            <StyledListItem button selected={isFileManager}>
              <ListItemIcon>
                <FontAwesomeIcon icon="folder" fixedWidth />
              </ListItemIcon>
              <ListItemText primary={t`File manager`} />
            </StyledListItem>
          </CustomLink>

          {/*{(isAdmin(user) || isImpersonated(user)) && (*/}
          {/*    <StyledListItem onClick={() => navigateToRoute(ROUTE_MASTERDATA_IMPORT)} button selected={isMasterdataImport}>*/}
          {/*      <ListItemIcon>*/}
          {/*        <FontAwesomeIcon icon="database" fixedWidth />*/}
          {/*      </ListItemIcon>*/}
          {/*      <ListItemText primary={t`Master Data Import`} />*/}
          {/*    </StyledListItem>*/}
          {/*)}*/}
          {hasAccessToTraining && (
            <CustomLink route={ROUTE_TRAINING}>
              <StyledListItem button selected={isTraining} className={classes.adminComponent} withAdminStyle={true}>
                <ListItemIcon>
                  <FontAwesomeIcon icon="road" fixedWidth />
                </ListItemIcon>
                <ListItemText primary={t`Training`} />
              </StyledListItem>
            </CustomLink>
          )}
          {isAuthorizedToPenalties(user) && (
            <CustomLink route={ROUTE_PENALTY_REGULATION}>
              <StyledListItem
                button
                selected={isPenaltyRegulation}
                withAdminStyle={
                  (isAdmin(user) || isImpersonatedAdmin(user)) &&
                  (!isIndianCustomer(user) ||
                    !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
                }
                className={
                  (isAdmin(user) || isImpersonatedAdmin(user)) &&
                  (!isIndianCustomer(user) ||
                    !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
                    ? classes.adminComponent
                    : 'inherit'
                }
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon="table" fixedWidth />
                </ListItemIcon>
                <ListItemText primary={t`Penalty regulations`} />
              </StyledListItem>
            </CustomLink>
          )}
        </List>
      </DataSideNav>

      <ContentLayout>
        <Switch>
          <Route exact path={ROUTE_DATA}>
            <Redirect to={ROUTE_DATA_DELIVERY} />
          </Route>
          <Route path={ROUTE_DATA_DELIVERY} exact component={Delivery} />

          <Route path={ROUTE_DATA_FLOW} exact component={() => <DataFlow />} />
          <Route path={ROUTE_FILE_MANAGER} exact component={FileManager} />

          {hasAccessToTraining && (
            <Route path={ROUTE_TRAINING} exact component={() => <OldUiIframe url="/iframe/#/training?IFRAME" />} />
          )}

          {isAuthorizedToPenalties(user) && (
            <Route path={ROUTE_PENALTY_REGULATION} exact component={PenaltyRegulations} />
          )}
          <Route path="/data/*">
            <Redirect to={ROUTE_WORKBENCH} />
          </Route>
        </Switch>
      </ContentLayout>
    </Container>
  )
}

export default React.memo(Data)
