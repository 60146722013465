import { darken, fade, lighten } from '@material-ui/core/styles'
import { getTime } from 'date-fns'
import { XAxisPlotBandsOptions } from 'highcharts'
import { AssetMaintenanceDataAttributes, Availability } from 'modules/asset/availability/Availability.types'
import { miscellaneousColors } from 'themes/theme-light'
import { c } from 'ttag'
import { getAvailabilityTypeLabel } from 'utils/availabilities'

import { getUnit } from 'utils/dataFormatting'

type CreateAvailabilityPlotbands = (availabilities: AssetMaintenanceDataAttributes[]) => XAxisPlotBandsOptions[]

export const createAvailabilityPlotbands: CreateAvailabilityPlotbands = (availabilities) => {
  let availabilityPlotbands: XAxisPlotBandsOptions[] = []
  if (availabilities.length) {
    for (const i in availabilities) {
      availabilities[i].maintenanceList.map((availability: Availability) => {
        const ids = availabilityPlotbands.map((plotband) => plotband.id)
        if (ids.includes(availability.id)) return

        const unit = getUnit(availability.unit)
        const availabilityTypeLabel = getAvailabilityTypeLabel(availability.type)
        const data: XAxisPlotBandsOptions = {
          // availability dates are already converted to user timezone
          //   see: Availabilities.tsx::transformAvailabilityDataForForm()
          id: availability.id,
          from: getTime(new Date(availability.from)),
          to: getTime(new Date(availability.to)),
          color: fade(lighten(miscellaneousColors.availabilityPlotBand, 0.1), 0.2),
          label: {
            text: c('Availability')
              .t`${availabilityTypeLabel} to ${availability.value} ${unit} ${availability.description}`,
            rotation: 270,
            width: 2,
            y: 8,
            style: {
              color: darken(miscellaneousColors.availabilityPlotBand, 0.3),
            },
            textAlign: 'right',
          },
          borderColor: darken(miscellaneousColors.availabilityPlotBand, 0.3),
          borderWidth: 0,
        }
        availabilityPlotbands.push(data)
      })
    }
  } else {
    availabilityPlotbands = []
  }
  return availabilityPlotbands
}
