import { User } from 'modules/auth/Auth.types'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'

import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { c, t } from 'ttag'
import {
  isAdmin,
  isDemo,
  isImpersonatedAdmin,
  isIndianCustomer,
  hasPermissionForSiteAssessmentBackcast,
  userHasGlobalAccessWithoutDemo,
} from 'utils/user'
import { AppUnits, COORDINATE_LATITUDE } from 'utils/units'

export const getDefaultSelectedColumnNames = (): string[] => {
  return [
    'type',
    'name',
    'plausibility',
    'nomcap',
    'latitude',
    'longitude',
    'meterdata',
    'lastTraining',
    'autoDeploymentEnabled',
  ]
}

export const SITE_FORECAST_COLUMN_WIDTH = '13em'
export const PLANT_KEY_AND_EEG_ID_WIDTH = '20em'
export const getColumnsNarrow: () => Column[] = () => [
  {
    name: 'type',
    label: t`Type`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '4em',
    fixed: true,
  },
  {
    name: 'name',
    label: t`Asset`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: '18.5em',
    fixed: true,
  },
]

export const getColumnsWide: (loginUser: User) => Column[] = (loginUser: User) => [
  // {
  //   name: 'plausibility',
  //   label: t`Plausibility`,
  //   cellRenderType: CellRenderType.TUPLE,
  //   columnSortType: ColumnSortType.PLAUSIBILITY,
  //   searchable: false,
  //   sortable: true,
  //   width: '9em',
  // },
  {
    name: 'backcast',
    label: `${t`Backcast`}`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '13em',
    isAuthorized: (user: User) => {
      return hasPermissionForSiteAssessmentBackcast(user)
    },
    isAdminComponent: false,
  },
  {
    name: 'nomcap',
    label: `${t`Nameplate capacity`} (${AppUnits.KILO_WATT})`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.NOMCAP,
    searchable: true,
    sortable: true,
    width: '13em',
  },
  // {
  //   name: 'availableCapacity',
  //   label: `${t`Available capacity`}`,
  //   type: ColumnType.NUMERIC,
  //   data: ColumnData.AVAILABLECAP,
  //   searchable: true,
  //   sortable: true,
  //   width: '13em',
  // },
  {
    name: 'eegId',
    label: `${t`EEG-ID`}`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: PLANT_KEY_AND_EEG_ID_WIDTH,
  },
  {
    name: 'key',
    label: `${t`Plant key`}`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: PLANT_KEY_AND_EEG_ID_WIDTH,
  },
  {
    name: 'siteForecast',
    label: `${t`Site forecast`}`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.SITE_FORECAST,
    searchable: true,
    sortable: true,
    width: SITE_FORECAST_COLUMN_WIDTH,
  },
  // {
  //   name: 'creationDate',
  //   label: `${t`Created`}`,
  //   type: ColumnType.DATE,
  //   data: ColumnData.FIELD,
  //   searchable: true,
  //   sortable: true,
  //   width: '8em',
  // },
  // {
  //   name: 'lastModified',
  //   label: `${t`Last Modified`}`,
  //   type: ColumnType.DATE,
  //   data: ColumnData.FIELD,
  //   searchable: true,
  //   sortable: true,
  //   width: '8em',
  // },

  {
    name: 'country',
    label: `${t`Country`}`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.COUNTRY_CODE,
    searchable: true,
    sortable: true,
    width: '8em',
  },
  {
    name: 'latitude',
    label: `${t`Latitude`} (${COORDINATE_LATITUDE})`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.COORDS,
    searchable: false,
    sortable: true,
    width: '9em',
  },
  {
    name: 'longitude',
    label: `${t`Longitude`} (${c('Units').t`°E`})`, //refers to COORDINATE_LONGITUDE
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.COORDS,
    searchable: false,
    sortable: true,
    width: '9em',
  },
  {
    name: 'meterdata',
    label: t`Meter data`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.METERDATA,
    searchable: false,
    sortable: true,
    width: '9.5em',
  },
  {
    name: 'assetSpecificFilterSetup',
    label: t`Meter data filter`, // Label is different but this column is totally related to mdc asset specific (individual) filter setup, also from BE we are receiving such information.
    cellRenderType: CellRenderType.BOOLEAN,
    columnSortType: ColumnSortType.ASSET_SPECIFIC_FILTER_SETUP,
    searchable: false,
    sortable: true,
    width: '13em',
    isAuthorized: (user: User) => {
      return isAdmin(user) || isImpersonatedAdmin(user)
    },
    isAdminComponent: true,
  },
  {
    name: 'penaltyAccuracy',
    label: t`Penalty accuracy`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.PENALTY_ACCURACY,
    searchable: false,
    sortable: true,
    width: '11em',
    toolTip: t`Penalty accuracy represents the ratio of time blocks (15 min.) in a given period for which the penalty is zero.`,
    isAuthorized: (user: User) => {
      return isIndianCustomer(user)
    },
  },
  {
    name: 'lastTraining',
    label: t`Last model update`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.LAST_SET_NETWORK,
    searchable: false,
    sortable: true,
    width: '12em',
    isAuthorized: (user: User) => {
      return isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user)
    },
    isAdminComponent: userHasGlobalAccessWithoutDemo(loginUser),
  },
  {
    name: 'orderNumber',
    label: `${t`Order Number`}`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '13em',
    isAuthorized: (user: User) => {
      return isAdmin(user) || isImpersonatedAdmin(user)
    },
    isAdminComponent: true,
  },

  {
    name: 'autoDeploymentEnabled',
    label: t`Auto model update`,
    cellRenderType: CellRenderType.BOOLEAN,
    columnSortType: ColumnSortType.FIELD_GENERATOR,
    searchable: false,
    sortable: true,
    width: '11.6em',
    isAuthorized: (user: User) => {
      return isAdmin(user) || isImpersonatedAdmin(user)
    },
    isAdminComponent: true,
  },
  {
    name: 'operational',
    label: t`Operational`,
    cellRenderType: CellRenderType.BOOLEAN,
    columnSortType: ColumnSortType.ASSET_OPERATIONAL,
    searchable: false,
    sortable: true,
    width: '11.6em',
  },
  // {
  //   name: 'trainingScheduled',
  //   label: t`Training`,
  //   cellRenderType: CellRenderType.BOOLEAN,
  //   columnSortType: ColumnSortType.BOOLEAN,
  //   searchable: false,
  //   sortable: true,
  //   width: '11.6em',
  // },
]

export const createForecastConfigColumn = (forecastConfig: ForecastConfig) => {
  return {
    name: `quality.${forecastConfig.id}`,
    label: t`Quality: ${forecastConfig.name}`,
    subItems: [
      {
        name: 'last',
        label: t`Last`,
        type: CellRenderType.NUMERIC,
      },
      {
        name: 'active',
        label: t`Active`,
        type: CellRenderType.NUMERIC,
      },
      {
        name: 'candidate',
        label: t`Candidate`,
        type: CellRenderType.NUMERIC,
      },
    ],
    type: CellRenderType.NUMERIC,
    data: ColumnSortType.QUALITY_EVALUATION,
    searchable: false,
    sortable: true,
    width: '20em',
  }
}

// Backcast of forecast model
export const BACKCAST = 'backcast'

export const backcastStatusValue = {
  NOTHING: 'NOTHING',
  READY: 'READY',
  RUNNING: 'RUNNING',
}
