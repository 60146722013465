import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { TriggerWeightOptimizationAction, TriggerWeightOptimizationState } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import createReducer from 'utils/createReducer'

const initialState: TriggerWeightOptimizationState = {
  loading: false,
  error: null,
}

const loading = createReducer<TriggerWeightOptimizationState['loading'], TriggerWeightOptimizationAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS:
      case actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_FAILURE:
        return false
      case actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<TriggerWeightOptimizationState['error'], TriggerWeightOptimizationAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_FAILURE:
        return error
      case actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_REQUEST:
      case actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS:
        return null
    }
    return state
  },
)

export const triggerWeightOptimizationReducer = combineReducers({ loading, error })

// selectors
export const triggerWeightOptimizationLoadingSelector = createSelector<
  any,
  TriggerWeightOptimizationState['loading'],
  TriggerWeightOptimizationState['loading']
>(
  (state) => state.quality.triggerWeightOptimization.loading,
  (loading) => loading,
)
export const triggerWeightOptimizationErrorSelector = createSelector<
  any,
  TriggerWeightOptimizationState['error'],
  TriggerWeightOptimizationState['error']
>(
  (state) => state.quality.triggerWeightOptimization.error,
  (error) => error,
)
