import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom'
import * as H from 'history'
import { useCallback, useEffect, useState } from 'react'
import qs from 'query-string'
import {
  getChangePasswordQueryObj,
  getUserSettingsQueryObj,
  QUERY_CHANGE_PASSWORD,
  QUERY_USER_SETTINGS,
  useQueryParams,
} from 'utils/query-string'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { Asset } from 'modules/asset/store/asset.types'
import { DeliveryTarget } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { UserManagementType } from 'modules/userManagement/userManagement.types'
import { EnrichedQualityEvaluationItem } from 'utils/forecastModel'
import { ReTableItem } from 'modules/reTable/reTable.types'

interface UseRouterState {
  history: H.History
  location: H.Location
  params: any
  pathname: string
}

type UseRouter = () => UseRouterState
export const useRouter: UseRouter = () => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  const getRouterState = (): UseRouterState => {
    const pathname = location.pathname
    return {
      history,
      location,
      params,
      pathname,
    }
  }

  const [router, setRouter] = useState<UseRouterState>(getRouterState())
  useEffect(() => {
    setRouter(getRouterState())
  }, [location])

  return router
}

interface UseRouterMatchState {
  routeMatch: any
  isMatch: boolean
}

type UseRouterMatch = (match?: string) => UseRouterMatchState
export const useRouterMatch: UseRouterMatch = (match = ROUTE_WORKBENCH) => {
  const location = useLocation()
  const routeMatch = useRouteMatch(match)

  const getRouterMatchState = (): UseRouterMatchState => {
    const isMatch = Boolean(routeMatch)
    return {
      routeMatch,
      isMatch,
    }
  }

  const [routerMatch, setRouterMatch] = useState<UseRouterMatchState>(getRouterMatchState())
  useEffect(() => {
    setRouterMatch(getRouterMatchState())
  }, [location.pathname])

  return routerMatch
}

export const useRouteNavigation = () => {
  const { queryParams } = useQueryParams()
  const { history } = useRouter()
  const routeNavigation = useCallback(
    (route: string) => {
      const queryObj = queryParams[QUERY_USER_SETTINGS]
        ? getUserSettingsQueryObj()
        : queryParams[QUERY_CHANGE_PASSWORD]
        ? getChangePasswordQueryObj()
        : {}
      const queryString = qs.stringify(queryObj, { sort: false })
      history.push(`${route}?${queryString}`)
    },
    [queryParams],
  )

  return { routeNavigation }
}

type NavigationItem =
  | Asset
  | ForecastConfig
  | DeliveryTarget
  | UserManagementType
  | EnrichedQualityEvaluationItem
  | ReTableItem

export const getNextPrevNavigationIndex = (
  visibleItems: NavigationItem[],
  currentDisplayedId: string,
  nextItem: boolean,
) => {
  const totalNumOfItems = visibleItems.length
  if (totalNumOfItems < 2) {
    return
  }
  const currentItem = visibleItems.find((item) => item.id === currentDisplayedId)
  let newIndex
  const currentIndex = visibleItems.indexOf(currentItem)
  if (currentIndex === -1) {
    newIndex = 0
  } else if (nextItem) {
    newIndex = totalNumOfItems <= currentIndex + 1 ? 0 : currentIndex + 1
  } else {
    newIndex = currentIndex === 0 ? totalNumOfItems - 1 : currentIndex - 1
  }
  return newIndex
}
