import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SAVE_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'
import axios from 'axios'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import Alert from '@material-ui/lab/Alert'
import { Button } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { jt, t } from 'ttag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { QUERY_USER_SETTINGS, useQueryString } from 'utils/query-string'
import { AppBanner, SessionActions } from 'modules/app/app.types'

const CloseIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin-left: 0.3em;
  }
`
const StyledButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 0.3em;
  }
`
const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    background-color: rgb(187, 209, 255) !important;
  }
`

export interface TimezoneBannerValueType {
  value: boolean
}

const TimezoneBanner: React.FC = () => {
  const dispatch = useDispatch()
  const { onUpdateQueryString } = useQueryString()
  const user = useSelector(getUserResultSelector)
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const handleShowTimezoneBannerRequest = useCallback((showBanner: boolean) => {
    axios.post('api/usersettings/save/showTimezoneBanner', { value: showBanner })
  }, [])

  // Set the browser timezone as default if user timezone is not set
  const setDefaultUserTimezone = useCallback(() => {
    const settings = {
      ...user,
      timezone: browserTimezone,
    }
    dispatch({ type: SAVE_USER_REQUEST, user: settings })
    handleShowTimezoneBannerRequest(true)
  }, [user, handleShowTimezoneBannerRequest])

  const ackAutomaticTimezone = useCallback(() => {
    dispatch({ type: SessionActions.REMOVE_APP_BANNER, appBanner: AppBanner.TIMEZONE })
    handleShowTimezoneBannerRequest(false)
  }, [handleShowTimezoneBannerRequest])

  const closeBanner = useCallback(() => {
    dispatch({ type: SessionActions.REMOVE_APP_BANNER, appBanner: AppBanner.TIMEZONE })
  }, [])

  const handleGoToSettings = useCallback(() => {
    onUpdateQueryString({ [QUERY_USER_SETTINGS]: true })
  }, [onUpdateQueryString])

  useEffect(() => {
    if (user && !user.timezone) {
      setDefaultUserTimezone()
    }
  }, [user, setDefaultUserTimezone])

  return (
    <StyledAlert
      action={
        <>
          <StyledButton onClick={ackAutomaticTimezone} size="small">{t`Confirm`}</StyledButton>
          <StyledButton onClick={handleGoToSettings} size="small">{t`Change`}</StyledButton>
          <CloseIconButton onClick={closeBanner} size="small">
            <FontAwesomeIcon icon="times" />
          </CloseIconButton>
        </>
      }
      severity="info"
      variant="filled"
    >
      <div>
        {jt`We detected "${browserTimezone}" as your local time zone and saved it in your profile. You can confirm or change this setting.`}
      </div>
    </StyledAlert>
  )
}

export default React.memo(TimezoneBanner)
