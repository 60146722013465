import { SeriesArearangeOptions, SeriesLineOptions } from 'highcharts'
import { c } from 'ttag'

import { Timezone } from 'fixtures/timezones'
import { limitDecimals } from 'utils/dataFormatting'
import { DateRange, formatDate } from 'utils/date'
import {
  cropSeries,
  CropSeriesTriggeredFrom,
  isAustriaMarketTimeSeries,
  isFranceM0TimeSeries,
  isMarktwertTimeSeries,
  normalizeSeriesToInterval,
} from 'utils/timeseries'
import { AppUnits } from 'utils/units'
import { TimeIntervalsFileTitles } from 'ui/elements/CSVExportDialog'
import { addMonths, endOfMonth, isAfter, subMonths } from 'date-fns'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

export const convertSeriesToCSVAndStartDownload = (
  series: (SeriesArearangeOptions | SeriesLineOptions)[],
  timezone: Timezone,
  range: DateRange,
  normalizeToInterval?: number,
  partialTitle?: string,
  backastFilename?: string,
) => {
  const TRIGGERED_FROM = CropSeriesTriggeredFrom.convertSeriesToCSVAndStartDownload
  const csvColumnCount = series.length
  const header = [c('Workbench:CSV Export').t`Start (${timezone})`, c('Workbench:CSV Export').t`End (${timezone})`]

  // fill and align series if required by user
  const intervalMinutes = normalizeToInterval ? normalizeToInterval / 1000 / 60 : null
  const transformedSeries = normalizeToInterval
    ? normalizeSeriesToInterval(series, [...range], normalizeToInterval)
    : series

  const croppedSeries = cropSeries(transformedSeries, [...range], TRIGGERED_FROM)

  // merge all series to one
  const mergedSeries = croppedSeries.reduce<Record<string, [number, number, ...number[]]>>((previous, s, index) => {
    if (header.length <= index + 2) {
      const unit = s.custom?.unit || AppUnits.KILO_WATT
      const lastColHeaderVal = `${s.name || (index + 2).toString()} (${unit})`.replace(/["]/g, '')
      header.push(lastColHeaderVal)
    }

    const data = (s.data || []) as [[number, number]]

    if (
      isMarktwertTimeSeries(s?.custom?.datastreamClassifier) ||
      isAustriaMarketTimeSeries(s?.custom?.datastreamClassifier) ||
      isFranceM0TimeSeries(s?.custom?.datastreamClassifier)
    ) {
      const dateNow = new Date()
      const lastTimeStamp = new Date(data[data.length - 1][0])
      const previousMonthEndDate = endOfMonth(subMonths(dateNow, 1))
      /**
       *  Remove the last timestamp which was added manually for Marktwert/M0
       *  Only if the last timestamp is greater than previous month end date
       * */

      if (isAfter(lastTimeStamp, previousMonthEndDate)) {
        data.pop()
      }
    }
    // console.log({ data })
    data.forEach((item, dataIndex) => {
      const x = item[0]
      const y = item[1]

      const rightAligned = s.custom?.alignment === 'right'
      let start
      let end
      if (
        isMarktwertTimeSeries(s?.custom?.datastreamClassifier) ||
        isFranceM0TimeSeries(s?.custom?.datastreamClassifier) ||
        isAustriaMarketTimeSeries(s?.custom?.datastreamClassifier)
      ) {
        const startDate = item[0]
        const endDate = data[dataIndex + 1]?.[0] ? data[dataIndex + 1]?.[0] : addMonths(startDate, 1)
        const isEndDateGreaterThanRangeEndDate = isAfter(endDate, new Date(range[1]))
        start = startDate
        end = isEndDateGreaterThanRangeEndDate ? new Date(range[1]).getTime() + 60000 : endDate
      } else {
        start = rightAligned ? x - (normalizeToInterval || s?.custom?.detectedInterval || 0) : x
        end = rightAligned ? x : x + (normalizeToInterval || s?.custom?.detectedInterval || 0)
      }

      const key = `${start}-${end}`
      if (!previous[key]) {
        previous[key] = [start, end, ...new Array(csvColumnCount)]
      }
      previous[key][index + 2] =
        s?.custom?.datastreamType === TimeSeriesType.MARKET_PRICE_DATA ? limitDecimals(y, 2) : limitDecimals(y)
    })
    return previous
  }, {})

  // console.log({ mergedSeries })

  const separator = ','

  // Transform csv row item by add quotes around the string so that comma inside them is not treated as separator
  const transformRowItem = (rowItems: (string | number)[]) => {
    return rowItems.map((item) => `"${item}"`).join(separator)
  }

  // generate csv file
  const csvData = Object.keys(mergedSeries)
    .sort((a, b) => {
      const startA = a.split('-')[0]
      const startB = b.split('-')[0]
      return startA < startB ? -1 : 1
    })
    .map((x) => {
      const row = mergedSeries[x]
      row[0] = formatDate(row[0], timezone)
      row[1] = formatDate(row[1], timezone)
      return row
    })

  // This condition is used to remove first element and last one , if there is more than one chart also type is interpolation/aggregation.
  if (
    partialTitle === TimeIntervalsFileTitles.MERGE_INTERPOLATION ||
    partialTitle === TimeIntervalsFileTitles.MERGE_AGGREGATION
  ) {
    csvData.pop()
    if (new Date(csvData[0][0]).getDate() < new Date(csvData[0][1]).getDate()) {
      csvData.shift()
    }
  }

  const csvString = [transformRowItem(header), ...csvData].join('\n')

  const csvFile = new Blob([csvString], { type: 'text/csv' })
  const downloadLink = document.createElement('a')
  // TODO this filename is too long, we use something more generic for now
  // const filename = `${series.map(s => s.name).join(' -- ')} (from ${formatDate(range[0])} to ${formatDate(range[1])})`
  // TODO this filename is containing the range, but we export whole range always
  // const filename = `enercast-${formatDate(new Date(), timezone)} (from ${formatDate(
  //   range[0],
  //   timezone,
  // )} to ${formatDate(range[1], timezone)})`
  const description =
    series.length === 1
      ? series[0].name
      : normalizeToInterval
      ? `${partialTitle}-${intervalMinutes}-minutes`
      : `merged-raw`
  const filename = `enercast--${backastFilename ? backastFilename : description}--${formatDate(new Date(), timezone)}`
  downloadLink.download = `${filename}.csv`
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}
