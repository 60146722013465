import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'

export interface EmailAddress {
  address: string | undefined
  valid?: boolean | null
}

export interface MailConfiguration {
  bccAddresses: EmailAddress[]
  ccAddresses: EmailAddress[]
  multiFile: boolean
  toAddresses: EmailAddress[]
}

export interface FtpParameter {
  connectTimeout: string | number
  disconnect: boolean | string
  fileExist: string
  stepwise: boolean | string
}

export interface FtpConfiguration {
  directory: string
  ftpParameter: FtpParameter
  host: string
  password: string
  port: number
  protocol: string
  username: string
}

export interface DeliveryTarget {
  customer: string
  id: string
  mailConfiguration?: MailConfiguration | null
  ftpConfiguration?: FtpConfiguration | null
  name: string
  type: DELIVERY_TARGET_TYPE
  version: string
  usedIn?: ForecastConfig[]
  usedInLength?: number
  ui?: {
    fixed?: boolean
  }
  isNewCopy?: boolean
}

export interface DeliveryTargetSaveMainItem {
  updateDeliveryTarget: string
  createDeliveryTarget: string
}

export const DELIVERY_TARGET_DEFAULT = 'DELIVERY_TARGET_DEFAULT'
export const DELIVERY_TARGET_TYPE_MAIL = 'MAIL'
export const DELIVERY_TARGET_TYPE_CUSTOMER_FTP = 'CUSTOMER_FTP'
export const DELIVERY_TARGET_TYPE_ENERCAST_FTP = 'EC_FTP'
export type DELIVERY_TARGET_DEFAULT = typeof DELIVERY_TARGET_DEFAULT
export type DELIVERY_TARGET_TYPE_MAIL = typeof DELIVERY_TARGET_TYPE_MAIL
export type DELIVERY_TARGET_TYPE_CUSTOMER_FTP = typeof DELIVERY_TARGET_TYPE_CUSTOMER_FTP
export type DELIVERY_TARGET_TYPE_ENERCAST_FTP = typeof DELIVERY_TARGET_TYPE_ENERCAST_FTP
export type DELIVERY_TARGET_TYPE =
  | DELIVERY_TARGET_DEFAULT
  | DELIVERY_TARGET_TYPE_MAIL
  | DELIVERY_TARGET_TYPE_CUSTOMER_FTP
  | DELIVERY_TARGET_TYPE_ENERCAST_FTP

export enum LocalFtpProtocols {
  FTP = 'FTP',
  SFTP = 'SFTP',
  FTPS = 'FTPS',
}

export enum LocalFtpIfFileExist {
  OVERRIDE = 'Override',
  IGNORE = 'Ignore',
}
