import { useEffect, useRef } from 'react'

export const usePrevious = <T extends unknown>(state: T): T | undefined => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = state
  })

  return ref.current
}

// source: https://gist.github.com/jed/982883
export const getUuid = (a = ''): string =>
  a
    ? ((Number(a) ^ (Math.random() * 16)) >> (Number(a) / 4)).toString(16)
    : `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, getUuid)
