import { createStory } from 'test/utils'

// stories
export const storyCategory = 'Modules/Asset/RevisionChooser'
export const storyNoChanges = createStory(storyCategory, 'no changes')
export const storyWithChanges = createStory(storyCategory, 'with changes')
export const storyErrorActivate = createStory(storyCategory, 'error activate')
export const storyErrorDiscard = createStory(storyCategory, 'error discard')

// tests
const testIdPrefix = 'asset-revision-chooser'
export const testIdAssetRevisionChooser = `${testIdPrefix}-root`
export const testIdError = `${testIdPrefix}-error`
