import axios from 'axios'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'

import { Asset, TYPE_SOLARPLANT } from 'modules/asset/store/asset.types'
import { STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { queryClient } from 'queryClient'
import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { flattenAndUniquifyAssetIds } from 'utils/asset'
import createReducer from 'utils/createReducer'
import { request } from 'utils/request'

// State
interface State {
  loading: boolean
  error: string | null
  success: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  success: false,
}

// Types
export const START_TRAINING_REQUEST_FOR_SOLAR = 'START_TRAINING_REQUEST_FOR_SOLAR'
export const START_TRAINING_SUCCESS_FOR_SOLAR = 'START_TRAINING_SUCCESS_FOR_SOLAR'
export const START_TRAINING_FAILURE_FOR_SOLAR = 'START_TRAINING_FAILURE_FOR_SOLAR'
export const START_TRAINING_DISMISS_FOR_SOLAR = 'START_TRAINING_DISMISS_FOR_SOLAR'
export const START_TRAINING_RESET_FOR_SOLAR = 'START_TRAINING_RESET_FOR_SOLAR'
export type START_TRAINING_REQUEST_FOR_SOLAR = typeof START_TRAINING_REQUEST_FOR_SOLAR
export type START_TRAINING_SUCCESS_FOR_SOLAR = typeof START_TRAINING_SUCCESS_FOR_SOLAR
export type START_TRAINING_FAILURE_FOR_SOLAR = typeof START_TRAINING_FAILURE_FOR_SOLAR
export type START_TRAINING_DISMISS_FOR_SOLAR = typeof START_TRAINING_DISMISS_FOR_SOLAR
export type START_TRAINING_RESET_FOR_SOLAR = typeof START_TRAINING_RESET_FOR_SOLAR
export type START_TRAINING_ACTION_FOR_SOLAR_TYPE =
  | START_TRAINING_REQUEST_FOR_SOLAR
  | START_TRAINING_SUCCESS_FOR_SOLAR
  | START_TRAINING_FAILURE_FOR_SOLAR
  | START_TRAINING_DISMISS_FOR_SOLAR
  | START_TRAINING_RESET_FOR_SOLAR

interface StartTrainingActionForSolar {
  type: START_TRAINING_ACTION_FOR_SOLAR_TYPE
  // ERROR
  error: State['error']
  // REQUEST
  assets: Asset[]
}

// Reducers
const loading = createReducer<State['loading'], StartTrainingActionForSolar>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case START_TRAINING_REQUEST_FOR_SOLAR:
        return true
      case START_TRAINING_SUCCESS_FOR_SOLAR:
      case START_TRAINING_FAILURE_FOR_SOLAR:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'], StartTrainingActionForSolar>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case START_TRAINING_SUCCESS_FOR_SOLAR:
      case START_TRAINING_DISMISS_FOR_SOLAR:
      case START_TRAINING_RESET_FOR_SOLAR:
        return null
      case START_TRAINING_FAILURE_FOR_SOLAR:
        return error
    }
    return state
  },
)

const success = createReducer<State['success'], StartTrainingActionForSolar>(
  (state = initialState.success, { type }) => {
    switch (type) {
      case START_TRAINING_SUCCESS_FOR_SOLAR:
        return true
      case START_TRAINING_REQUEST_FOR_SOLAR:
      case START_TRAINING_FAILURE_FOR_SOLAR:
      case START_TRAINING_RESET_FOR_SOLAR:
        return false
    }
    return state
  },
)

export const startTrainingForSolarReducer = combineReducers({
  loading,
  error,
  success,
})

export const setStartTrainingForSolarLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.asset.startTrainingForSolar.loading,
  (loading) => loading,
)

export const setStartTrainingForSolarErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.asset.startTrainingForSolar.error,
  (error) => error,
)

export const setStartTrainingForSolarSuccessSelector = createSelector<any, State['success'], State['success']>(
  (state) => state.asset.startTrainingForSolar.success,
  (success) => success,
)

// api
export const startTrainingForSolar = (assetIds: string[]) => {
  return request(() => {
    return axios.post(`/api/qecs/solarcalibration/v0/start`, assetIds)
  })
}

// sagas
export function* startTrainingForSolarSaga({ assets }: StartTrainingActionForSolar): SagaIterator {
  // TODO to get all assets we can't use hooks in sagas, so we need to find a better way than the following:
  const allAssetsProductive = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE])
  const allAssetsDraft = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT])
  const allAssets = ((allAssetsDraft || []).length > 0 ? allAssetsDraft : allAssetsProductive) || []

  // we have passed only TYPE_SOLARPLANT but its just for Reference because in flattenAndUniquifyAssetIds its inlcuded also solar park
  const assetIdsToSet = flattenAndUniquifyAssetIds(assets, allAssets, TYPE_SOLARPLANT)

  const result = yield call(startTrainingForSolar, assetIdsToSet)

  if (result.isSuccessful) {
    yield put({ type: START_TRAINING_SUCCESS_FOR_SOLAR })
    queryClient.invalidateQueries(QUERY_KEY_ASSETS)
  } else {
    const error = result.getError()
    yield put({ type: START_TRAINING_FAILURE_FOR_SOLAR, error })
  }
}
