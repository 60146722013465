import { combineReducers } from 'redux'
import createReducer from 'utils/createReducer'
import { FileData } from 'utils/file'
import { createSelector } from 'reselect'
import * as actionTypes from 'modules/data/fileManager/redux_store/fileManager.action.types'

// state

export interface UploadResult {
  statusMap: Record<string, string>
  uploadId: string
}

interface State {
  result: UploadResult | null
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: null,
  loading: false,
  error: null,
}

export interface UploadMeterdataFilesAction {
  type: actionTypes.UPLOAD_METERDATAFILES_ACTION_TYPE
  // SUCCESS
  result: State['result']
  // ERROR
  error: State['error']
  // REQUEST
  fileDatas: FileData[]
  selectedAssetName?: string
}

// reducers

const result = createReducer<State['result'], UploadMeterdataFilesAction>(
  (state = initialState.result, { type, result }) => {
    if (type === actionTypes.UPLOAD_METERDATAFILES_SUCCESS) {
      return result
    } else if (type == actionTypes.UPLOAD_METERDATAFILES_REQUEST) {
      return null
    }
    return state
  },
)

const loading = createReducer<State['loading'], UploadMeterdataFilesAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.UPLOAD_METERDATAFILES_REQUEST:
        return true
      case actionTypes.UPLOAD_METERDATAFILES_SUCCESS:
      case actionTypes.UPLOAD_METERDATAFILES_FAILURE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'], UploadMeterdataFilesAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.UPLOAD_METERDATAFILES_SUCCESS:
      case actionTypes.UPLOAD_METERDATAFILES_DISMISS:
        return null
      case actionTypes.UPLOAD_METERDATAFILES_FAILURE:
        return error
    }
    return state
  },
)

export const uploadMeterdataFilesReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors

export const uploadMeterdataFilesResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.data.fileManager.uploadMeterdataFiles.result,
  (result) => result,
)
export const uploadMeterdataFilesLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.data.fileManager.uploadMeterdataFiles.loading,
  (loading) => loading,
)
export const uploadMeterdataFilesErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.data.fileManager.uploadMeterdataFiles.error,
  (error) => error,
)
