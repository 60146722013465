import React, { useMemo } from 'react'
import * as Highcharts from 'highcharts'
import { c } from 'ttag'

import { ForecastConfig, SiteForecastHorizonType } from 'modules/dataStreams/dataStreams.types'
import { addMinutes, addSeconds, differenceInMilliseconds, endOfDay, isBefore, subMinutes } from 'date-fns'
import { addMilliseconds } from 'date-fns/fp'
import { Box, Typography } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { getColor, paletteTimingPreview } from 'themes/theme-light'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getQualityConfigName } from 'utils/siteForecastConfiguration'
import styled from 'styled-components'
import { TimingPreview } from 'modules/dataStreams/siteForecast/SiteForecastDetails'
import SectionTimingPreviewOldDeliveries from 'modules/dataStreams/siteForecast/SectionTimingPreviewOldDeliveries'

const LegendIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
`

export interface StartTime {
  delivery: Date
  start: Date
  duration: number
}

interface SectionTimingPreviewProps {
  forecastDetails: ForecastConfig
  onUpdateTimingPreview?: (data: TimingPreview) => void
}
const SectionTimingPreview: React.FC<SectionTimingPreviewProps> = ({ forecastDetails, onUpdateTimingPreview }) => {
  // TODO make this individual for this chart only
  Highcharts.SVGRenderer.prototype.symbols.lineLimit = function (x, y, w, h) {
    return ['M', x + w / 2, y, 'L', x + w / 2, y + h, 'z']
  }
  const forecastTimingSeriesColor = getColor(0, 0, 0, paletteTimingPreview)

  // Forecast deliveries
  const forecastDeliveries = useMemo(() => {
    let endOfLastForecast: Date
    const startTimes: StartTime[] = []
    let intervalMinutes = 0
    const frequency = forecastDetails.updateTimes?.frequency
    const forecastLengthHours = forecastDetails.horizon?.lengthHours
    const forecastLengthInMilliSeconds = 1000 * 60 * 60 * forecastLengthHours
    const forecastOffset =
      1000 *
      60 *
      (forecastDetails.horizon.offsetMinutes +
        60 * (forecastDetails.horizon.offsetHours + 24 * forecastDetails.horizon.offsetDays))

    function calcDuration(start: Date) {
      let duration

      if (forecastDetails.horizon.untilEndOfDay) {
        // we need to subtract timezone offset from start because when we create
        // date with new Date(0) it returns date with browser timezone
        const offset = start?.getTimezoneOffset()
        const startDate =
          offset > 0 ? addMinutes(start, offset) : offset < 0 ? subMinutes(start, Math.abs(offset)) : start
        let endOfStartDate = endOfDay(startDate)
        if (forecastLengthInMilliSeconds) {
          // We need to remove 1 second with forecastLength and untilEndOfDay option
          endOfLastForecast = addMilliseconds(startDate, forecastLengthInMilliSeconds - 1)
          endOfStartDate = endOfDay(endOfLastForecast)
        }
        duration = differenceInMilliseconds(endOfStartDate, startDate)
      } else {
        duration = forecastLengthInMilliSeconds
      }
      return duration
    }

    switch (frequency) {
      case 'INDIVIDUAL':
        forecastDetails.updateTimes.individualTimes.forEach(function (it) {
          const t = addMinutes(new Date(0), parseInt(it.hours) * 60 + parseInt(it.minutes))
          const s = addMilliseconds(
            forecastDetails.horizon.type == SiteForecastHorizonType.DAY_RELATIVE ? new Date(0) : t,
            forecastOffset,
          )
          const d = calcDuration(s)
          startTimes.push({ delivery: t, start: s, duration: d })
        })
        break
      case 'QUARTER_HOURLY':
      case 'HOURLY':
        const startDate = new Date(0)
        const endDate = addMinutes(addSeconds(endOfDay(startDate), 2), forecastDetails.updateTimes.offsetMinutes)
        intervalMinutes = frequency === 'QUARTER_HOURLY' ? 15 : 60
        let deliveryOffset = addMinutes(startDate, forecastDetails.updateTimes.offsetMinutes)

        // Need to subtract 59 minutes to get the last three deliveries as the time is GMT+1
        while (isBefore(subMinutes(deliveryOffset, 59), endDate)) {
          const s = addMilliseconds(
            forecastDetails.horizon.type == SiteForecastHorizonType.DAY_RELATIVE ? startDate : deliveryOffset,
            forecastOffset,
          )
          const d = calcDuration(s)
          startTimes.push({ delivery: deliveryOffset, start: s, duration: d })
          deliveryOffset = addMinutes(deliveryOffset, intervalMinutes)
        }
        break
    }

    return startTimes
  }, [forecastDetails])

  return (
    <Flex direction="column">
      <Typography variant="subtitle2">{c('Site Forecast:Config').t`Timing overview`}</Typography>

      {/*---------------Recent deliveries chart--------------*/}
      {/*<SectionTimingPreviewRecentDeliveries forecastDeliveries={forecastDeliveries} forecastDetails={forecastDetails} />*/}

      {/*-------------History deliveries---------------*/}
      <SectionTimingPreviewOldDeliveries
        forecastDeliveries={forecastDeliveries}
        forecastDetails={forecastDetails}
        onUpdateTimingPreview={onUpdateTimingPreview}
      />

      <Box display="flex" flexDirection="row" ml={4}>
        <Box mb={1} mr={2}>
          <LegendIcon color={forecastTimingSeriesColor} size="sm" icon="circle" />{' '}
          <>{c('Site Forecast:Preview').t`Forecast delivery`}</>
        </Box>

        {forecastDetails?.qualityConfigs && (
          <>
            {forecastDetails.qualityConfigs.map((config, index) => (
              <Box key={index} mb={1} mr={2}>
                <LegendIcon color={getColor(index + 1, 0, 0, paletteTimingPreview)} size="sm" icon="circle" />{' '}
                {getQualityConfigName(config, index)}
              </Box>
            ))}
          </>
        )}
      </Box>
    </Flex>
  )
}

export default React.memo(SectionTimingPreview)
