import React from 'react'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { c } from 'ttag'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'

interface SectionConfigInfoProps {
  fieldArrayKey: string
}

const SectionConfigInfo: React.FC<SectionConfigInfoProps> = ({ fieldArrayKey }) => {
  return (
    <FormKeyValueGrid>
      <FormKey>{c('Site Forecast:Custom Config').t`Config name:`}</FormKey>
      <FormValue>
        <Field fullWidth name={`${fieldArrayKey}.name`}>
          {(props) => {
            return <TextField fullWidth input={props.input} meta={props.meta} />
          }}
        </Field>
      </FormValue>
    </FormKeyValueGrid>
  )
}

export default React.memo(SectionConfigInfo)
