import { assetTabNames } from 'fixtures/assetForm'
import { Asset } from 'modules/asset/store/asset.types'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import qs from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { dataStreamTypeQuery, DEFAULT_FORECAST_CONFIG_ID } from 'utils/dataStream'
import { useRouter } from 'utils/route'
import { DeliveryTarget } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import { MasterDataConfig } from 'modules/masterData/masterData.types'
import { UserManagementType } from 'modules/userManagement/userManagement.types'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'

// auth query strings
export const QUERY_AUTH_FORM = 'authForm'
export const QUERY_KEY = 'key'
export type QUERY_KEY = typeof QUERY_KEY
export type QUERY_AUTH_FORM = typeof QUERY_AUTH_FORM

// special handling for location changes to work with confirmation dialog on unsaved changes
export const QUERY_LANG_CHANGED = 'langChanged'
export const QUERY_LEAVE_IMPERSONATE = 'leaveImpersonate'
export const QUERY_LOGOUT = 'logout'
export const QUERY_SWITCH_TO_CLASSIC = 'switchToClassic'
export type QUERY_LANG_CHANGED = typeof QUERY_LANG_CHANGED
export type QUERY_LEAVE_IMPERSONATE = typeof QUERY_LEAVE_IMPERSONATE
export type QUERY_LOGOUT = typeof QUERY_LOGOUT
export type QUERY_SWITCH_TO_CLASSIC = typeof QUERY_SWITCH_TO_CLASSIC

// regular overlays
export const QUERY_ASSET = 'asset'
export const QUERY_ACTIVE_TAB = 'activeTab'
export const QUERY_DATA_STREAM = 'datastream'
export const QUERY_CATEGORY = 'category'
export const QUERY_DATA_STREAM_TYPE = 'dataStreamType'
export const QUERY_DATA_STREAM_ID = 'dataStreamId'
export const QUERY_NEW_SITE_FORECAST = 'newSiteForecast'
export const QUERY_NEW_SCHEDULE = 'newSchedule'
export const QUERY_NEW_SITE_FORECAST_TEMPLATE = 'newSiteForecastTemplate'
export const QUERY_SITE_FORECAST_TEMPLATE = 'siteForecastTemplate'
export const QUERY_COPY_SITE_FORECAST_TEMPLATE = 'copyForecastTemplate'
export const QUERY_USER_SETTINGS = 'userSettings'
export const QUERY_CHANGE_PASSWORD = 'changePassword'
export const QUERY_RELOAD_WINDOW = 'reload'
export const QUERY_DELIVERY_TARGET = 'deliveryTarget'
export const QUERY_DELIVERY_FORMAT = 'deliveryFormat'
export const QUERY_DELIVERY_FORMAT_TEMPLATE = 'isFormatTemplate'
export const QUERY_ASSET_MAP = 'assetMap'
export const QUERY_MASTER_DATA_CUSTOM_FORMAT = 'masterDataCustomFormat'
export const QUERY_USER_MANAGEMENT_DETAILS = 'userManagementDetails'
export const QUERY_PENALTY_DETAILS = 'penaltyDetails'
export const QUERY_PENALTY_NEW_ENTITY_TYPE = 'penaltyNewEntityType'
export const QUERY_PRINT_VIEW = 'printView'
export const QUERY_SCHEDULE = 'schedule'
export type QUERY_ASSET = typeof QUERY_ASSET
export type QUERY_ACTIVE_TAB = typeof QUERY_ACTIVE_TAB
export type QUERY_DATA_STREAM = typeof QUERY_DATA_STREAM
export type QUERY_CATEGORY = typeof QUERY_CATEGORY
export type QUERY_DATA_STREAM_TYPE = typeof QUERY_DATA_STREAM_TYPE
export type QUERY_DATA_STREAM_ID = typeof QUERY_DATA_STREAM_ID
export type QUERY_NEW_SITE_FORECAST = typeof QUERY_NEW_SITE_FORECAST
export type QUERY_NEW_SCHEDULE = typeof QUERY_NEW_SCHEDULE
export type QUERY_NEW_SITE_FORECAST_TEMPLATE = typeof QUERY_NEW_SITE_FORECAST_TEMPLATE
export type QUERY_SITE_FORECAST_TEMPLATE = typeof QUERY_SITE_FORECAST_TEMPLATE
export type QUERY_COPY_SITE_FORECAST_TEMPLATE = typeof QUERY_COPY_SITE_FORECAST_TEMPLATE
export type QUERY_USER_SETTINGS = typeof QUERY_USER_SETTINGS
export type QUERY_CHANGE_PASSWORD = typeof QUERY_CHANGE_PASSWORD
export type QUERY_RELOAD_WINDOW = typeof QUERY_RELOAD_WINDOW
export type QUERY_DELIVERY_TARGET = typeof QUERY_DELIVERY_TARGET
export type QUERY_DELIVERY_FORMAT = typeof QUERY_DELIVERY_FORMAT
export type QUERY_DELIVERY_FORMAT_TEMPLATE = typeof QUERY_DELIVERY_FORMAT_TEMPLATE
export type QUERY_ASSET_MAP = typeof QUERY_ASSET_MAP
export type QUERY_MASTER_DATA_CUSTOM_FORMAT = typeof QUERY_MASTER_DATA_CUSTOM_FORMAT
export type QUERY_USER_MANAGEMENT_DETAILS = typeof QUERY_USER_MANAGEMENT_DETAILS
export type QUERY_PENALTY_DETAILS = typeof QUERY_PENALTY_DETAILS
export type QUERY_PENALTY_NEW_ENTITY_TYPE = typeof QUERY_PENALTY_NEW_ENTITY_TYPE
export type QUERY_PRINT_VIEW = typeof QUERY_PRINT_VIEW
export type QUERY_SCHEDULE = typeof QUERY_SCHEDULE

// normal pages
export const QUERY_PENALTY_REGULATIONS = 'penaltyRegulations'
export type QUERY_PENALTY_REGULATIONS = typeof QUERY_PENALTY_REGULATIONS

// all possible query strings
export type QUERY_STRING =
  | QUERY_LANG_CHANGED
  | QUERY_LEAVE_IMPERSONATE
  | QUERY_LOGOUT
  | QUERY_SWITCH_TO_CLASSIC
  | QUERY_ASSET
  | QUERY_ACTIVE_TAB
  | QUERY_DATA_STREAM
  | QUERY_CATEGORY
  | QUERY_DATA_STREAM_TYPE
  | QUERY_DATA_STREAM_ID
  | QUERY_NEW_SITE_FORECAST
  | QUERY_NEW_SCHEDULE
  | QUERY_NEW_SITE_FORECAST_TEMPLATE
  | QUERY_SITE_FORECAST_TEMPLATE
  | QUERY_COPY_SITE_FORECAST_TEMPLATE
  | QUERY_USER_SETTINGS
  | QUERY_AUTH_FORM
  | QUERY_KEY
  | QUERY_CHANGE_PASSWORD
  | QUERY_RELOAD_WINDOW
  | QUERY_PENALTY_REGULATIONS
  | QUERY_DELIVERY_FORMAT
  | QUERY_DELIVERY_FORMAT_TEMPLATE
  | QUERY_DELIVERY_TARGET
  | QUERY_ASSET_MAP
  | QUERY_MASTER_DATA_CUSTOM_FORMAT
  | QUERY_USER_MANAGEMENT_DETAILS
  | QUERY_PENALTY_DETAILS
  | QUERY_PENALTY_NEW_ENTITY_TYPE
  | QUERY_SCHEDULE

export type QueryParamType = Record<QUERY_STRING, string>

export const getAssetQueryStrings = () => {
  return [QUERY_ASSET, QUERY_ACTIVE_TAB]
}

export const getAssetMapQueryStrings = () => {
  return {
    [QUERY_ASSET_MAP]: true,
  }
}

export const getUserManagementUserQueryObj = (user?: UserManagementType) => {
  return {
    [QUERY_USER_MANAGEMENT_DETAILS]: user ? user.login : 'new',
  }
}

export const getDeliveryTargetQueryObj = (target?: DeliveryTarget) => {
  return {
    [QUERY_DELIVERY_TARGET]: target ? target.id : 'new',
  }
}
export const getDeliveryFormatQueryObj = (format?: DeliveryFormat) => {
  return {
    [QUERY_DELIVERY_FORMAT]: format ? format.id : 'new',
  }
}
export const getDeliveryFormatTemplateQueryObj = (format?: DeliveryFormat) => {
  return {
    [QUERY_DELIVERY_FORMAT]: format ? format.id : 'new',
    [QUERY_DELIVERY_FORMAT_TEMPLATE]: true,
  }
}
export const getMasterDataCustomFormatQueryObj = (config?: MasterDataConfig) => {
  return {
    [QUERY_MASTER_DATA_CUSTOM_FORMAT]: config ? config.id : 'new',
  }
}

export const getAssetQueryObj = (asset: Partial<Asset> = {}, queryParams: any = {}) => {
  const { details } = assetTabNames
  return {
    [QUERY_ASSET]: asset?.id || 'new',
    [QUERY_ACTIVE_TAB]: asset?.id ? queryParams[QUERY_ACTIVE_TAB] || details : details,
  }
}

export const getDataStreamStrings = () => {
  return [QUERY_DATA_STREAM_TYPE, QUERY_DATA_STREAM_ID, QUERY_NEW_SITE_FORECAST, QUERY_NEW_SCHEDULE]
}

export const getManageDataStreamQueryObj = (dataStreamType?: string) => {
  const { [TimeSeriesType.SITE_FORECAST]: siteForecast } = dataStreamTypeQuery
  return {
    [QUERY_DATA_STREAM_TYPE]: dataStreamType || siteForecast,
  }
}

export const getDataStreamDetailsQueryObj = (dataStreamId?: string | null) => {
  return {
    [QUERY_DATA_STREAM_ID]: dataStreamId || DEFAULT_FORECAST_CONFIG_ID,
  }
}

export const getSiteForecastTemplateQueryObj = (templateId: string) => {
  return {
    [QUERY_DATA_STREAM_ID]: templateId,
    [QUERY_SITE_FORECAST_TEMPLATE]: true,
  }
}

export const getNewSiteForecastQueryObj = (siteForecastId: string) => {
  return {
    [QUERY_NEW_SITE_FORECAST]: true,
    [QUERY_DATA_STREAM_ID]: siteForecastId,
  }
}

export const getReloadWindowQueryObj = () => {
  return {
    [QUERY_RELOAD_WINDOW]: true,
  }
}

export const getUserSettingsQueryObj = () => {
  return {
    [QUERY_USER_SETTINGS]: true,
    [QUERY_CHANGE_PASSWORD]: undefined,
  }
}

export const getChangePasswordQueryObj = () => {
  return {
    [QUERY_CHANGE_PASSWORD]: true,
    [QUERY_USER_SETTINGS]: undefined,
  }
}

export const getPenaltyDetailsNewQueryObj = (penaltyRegulationId: string, newEntityType: PenaltyTableTypeOfRow) => {
  let queryObj = { [QUERY_PENALTY_DETAILS]: penaltyRegulationId }
  if (newEntityType || newEntityType === undefined) {
    queryObj = { ...queryObj, [`${QUERY_PENALTY_NEW_ENTITY_TYPE}`]: newEntityType }
  }
  return queryObj
}

export const getPenaltyDetailsQueryObj = (penaltyRegulationId: string) => {
  return { [QUERY_PENALTY_DETAILS]: penaltyRegulationId, [QUERY_PENALTY_NEW_ENTITY_TYPE]: undefined }
}

export const getPenaltyDetailsQueryStrings = () => {
  return [QUERY_PENALTY_DETAILS, QUERY_PENALTY_NEW_ENTITY_TYPE]
}

export const stringifyQueryParams = (queryParams = {}): string => {
  return qs.stringify(queryParams, { sort: false })
}

const setQueryStringValue = (value: Record<string, unknown>, queryString: string) => {
  const values = qs.parse(queryString, { sort: false })
  const newQsValue = qs.stringify({ ...values, ...value }, { sort: false })
  return `?${newQsValue}`
}

const deleteQueryStringValue = (keys: string[], queryString: string) => {
  const values = qs.parse(queryString, { sort: false })
  keys.map((key) => {
    delete values[key]
  })
  const newQsValue = qs.stringify(values)
  return `?${newQsValue}`
}

export const useQueryString = () => {
  const { history, location } = useRouter()

  const onUpdateQueryString = useCallback(
    (newQueryObj) => {
      const newQueryParamString = setQueryStringValue(newQueryObj, location.search)
      history.push(`${location.pathname}${newQueryParamString}`)
    },
    [location.search, location.pathname],
  )

  const onDeleteQueryStrings = useCallback(
    (keys) => {
      const newQueryParamString = deleteQueryStringValue(keys, location.search)
      history.push(`${location.pathname}${newQueryParamString}`)
    },
    [location.search, location.pathname],
  )

  const updateQueryString = useCallback(
    // set value to something to set it
    // set value to undefined to delete it
    // keys that are not present are kept
    //
    // example:
    // {
    //    changePassword: true,
    //    userSettings: undefined,
    // }
    (queryObj) => {
      const updateObj = Object.keys(queryObj).reduce((result, key) => {
        const value = queryObj[key]
        return typeof value === 'undefined'
          ? result
          : {
              ...result,
              [key]: value,
            }
      }, {})
      const paramsToDelete = Object.keys(queryObj).filter((key) => typeof queryObj[key] === 'undefined')

      let newQueryParamString = setQueryStringValue(updateObj, location.search)
      newQueryParamString = deleteQueryStringValue(paramsToDelete, newQueryParamString)
      history.push(`${location.pathname}${newQueryParamString}`)
    },
    [location.search, location.pathname],
  )

  return {
    onUpdateQueryString,
    onDeleteQueryStrings,
    updateQueryString,
  }
}

export const useQueryMatch = (query: QUERY_STRING, value?: string) => {
  const { location } = useRouter()
  const [queryMatch, setQueryMatch] = useState(false)

  const isQueryPresent = useCallback(() => {
    const queryParams = qs.parse(location.search)
    if (value) {
      return queryParams[query] === value
    } else {
      return typeof queryParams[query] !== 'undefined'
    }
  }, [query, location.search])

  useEffect(() => {
    setQueryMatch(isQueryPresent())
  }, [location.search])

  return queryMatch
}

export const useQueryParams = () => {
  const { location } = useRouter()
  const [queryParams, setQueryParams] = useState<QueryParamType>({})
  const [initialized, setInitialized] = useState<boolean>(false)

  const getQueryParams = useCallback(() => {
    return qs.parse(location.search) as QueryParamType
  }, [location.search])

  useEffect(() => {
    setInitialized(true)
    setQueryParams(getQueryParams())
  }, [location.search])
  return { queryParams: queryParams, queryParamsInitialized: initialized }
}

export const useGetFullPath = (newQueryParams: any) => {
  const { location } = useRouter()
  const queryString = setQueryStringValue(newQueryParams, location.search)
  return `${location.pathname}${queryString}`
}

export const isQueryPresentInUrl = (
  query: QUERY_STRING,
  value?: string,
): { queryPresent: boolean; hasPassedValue: boolean; queryValue: string; queryParams: Record<string, string> } => {
  const location = window.location
  const existingQueryParamString = location.hash.split('?')[1] || ''
  const queryPresent = existingQueryParamString.includes(query)
  const queryParams = qs.parse(existingQueryParamString, { sort: false })
  let hasPassedValue = false
  if (value) {
    hasPassedValue = queryParams[query] === value
  }
  const queryValue = (queryParams[query] || '') as string
  return { queryPresent, hasPassedValue, queryValue, queryParams }
}
