import { combineReducers } from 'redux'
import { downloadFileReducer } from 'modules/data/fileManager/redux_store/state/fileManager.download'
import { deleteFileReducer } from 'modules/data/fileManager/redux_store/state/fileManager.delete'
import { getMeterdataFilesReducer } from 'modules/data/fileManager/redux_store/state/getMeterdataFiles.state'
import { uploadMeterdataFilesReducer } from 'modules/data/fileManager/redux_store/state/uploadMeterdataFiles.state'

export const fileManagerReducer = combineReducers({
  download: downloadFileReducer,
  delete: deleteFileReducer,
  deactivate: deleteFileReducer,
  getMeterdataFiles: getMeterdataFilesReducer,
  uploadMeterdataFiles: uploadMeterdataFilesReducer,
})
