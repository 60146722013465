import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { AppTourContext } from 'modules/app/tour/AppTour'
import { useUniqueAllAssets } from 'utils/asset'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useDeliveryTargetsTableItems } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import { AppTours, TourStepCategory } from 'modules/app/tour/appTour.types'
import { useRouter, useRouterMatch } from 'utils/route'
import { ROUTE_DATA_DELIVERY, ROUTE_WORKBENCH } from 'modules/app/Routes'
import { useDraftChangeCounts, useHasDraft } from 'modules/asset/api/metadata.api'
import { DeliveryUsed, getAppTourSteps, useAtLeastOneDeliveryIsUsed } from 'modules/app/tour/appTourUtils'
import { OnBoardingTourDialog } from 'modules/app/tour/OnBoardingTourDialog'
import {
  QUERY_ASSET,
  QUERY_DATA_STREAM_ID,
  QUERY_DATA_STREAM_TYPE,
  QUERY_DELIVERY_TARGET,
  useQueryMatch,
  useQueryParams,
} from 'utils/query-string'

const OnBoardingTour: React.FC = () => {
  const { addTourSteps, isTourActive } = useContext(AppTourContext)
  const { history } = useRouter()
  const { queryParams } = useQueryParams()

  const {
    ADD_ASSET,
    ADD_SITE_FORECAST,
    ADD_DELIVERY_TARGET,
    ACTIVATE_DRAFT,
    ADD_DELIVERY_TARGET_TO_SITE_FORECAST,
  } = AppTours

  const siteForecastsResults = useSiteForecastConfigs()
  const deliveryTargetsResult = useDeliveryTargetsTableItems()
  const hasDraft = useHasDraft()
  const { pendingCount } = useDraftChangeCounts()

  const assetChangesPending = hasDraft.data && pendingCount > 0
  const assets = useUniqueAllAssets()
  const siteForecasts = useMemo(() => siteForecastsResults?.data || [], [siteForecastsResults?.data])
  const deliveryTargets = useMemo(() => deliveryTargetsResult?.data || [], [deliveryTargetsResult?.data])

  const isAssetDetails = useQueryMatch(QUERY_ASSET)
  const { isMatch: isWorkbench } = useRouterMatch(ROUTE_WORKBENCH)
  const { isMatch: isDelivery } = useRouterMatch(ROUTE_DATA_DELIVERY)
  const isDeliveryTarget = useQueryMatch(QUERY_DELIVERY_TARGET)
  const isDataStreams = useQueryMatch(QUERY_DATA_STREAM_TYPE)
  const isDataStreamsDetails = useMemo(() => isDataStreams && Boolean(queryParams[QUERY_DATA_STREAM_ID]), [
    queryParams,
    isDataStreams,
  ])

  const atLeastOneDeliveryIsAssignedToSiteForecast = useAtLeastOneDeliveryIsUsed()

  const tourContext = useMemo(() => {
    if (!assets.length) {
      return ADD_ASSET
    } else if ((assets.length && assetChangesPending) || pendingCount) {
      return ACTIVATE_DRAFT
    } else if (siteForecasts && siteForecasts?.length == 1) {
      return ADD_SITE_FORECAST
    } else if (deliveryTargets && deliveryTargets?.length == 1) {
      return ADD_DELIVERY_TARGET
    } else if (atLeastOneDeliveryIsAssignedToSiteForecast === DeliveryUsed.NO) {
      return ADD_DELIVERY_TARGET_TO_SITE_FORECAST
    }
  }, [
    assets,
    siteForecasts,
    deliveryTargets,
    assetChangesPending,
    pendingCount,
    atLeastOneDeliveryIsAssignedToSiteForecast,
  ])

  useEffect(() => {
    if (tourContext === ADD_DELIVERY_TARGET_TO_SITE_FORECAST) {
      const NavigateToWorkbenchTimer = setTimeout(() => {
        history.push(ROUTE_WORKBENCH)
      }, 500)
      return () => {
        if (NavigateToWorkbenchTimer) clearTimeout(NavigateToWorkbenchTimer)
      }
    }
  }, [tourContext])

  // Add initial step for creating asset, forecast and delivery based on context
  // Should not add steps if user is viewing its corresponding details page to get rid of flickering popups
  // For activating draft add it from the component itself
  const handleAddTourSteps = useCallback(() => {
    if (
      !tourContext ||
      tourContext === ACTIVATE_DRAFT ||
      (tourContext === ADD_ASSET && isAssetDetails) ||
      (tourContext === ADD_SITE_FORECAST && isDataStreams) ||
      (tourContext === ADD_DELIVERY_TARGET && (isDeliveryTarget || isDelivery)) ||
      (tourContext === ADD_DELIVERY_TARGET_TO_SITE_FORECAST && isDataStreamsDetails)
    )
      return
    const steps = getAppTourSteps(tourContext)
    if (typeof addTourSteps === 'function' && steps.length) {
      setTimeout(() => {
        addTourSteps(steps.filter((item) => item.stepCategory === TourStepCategory.WORKBENCH))
      }, 500)
    }
  }, [tourContext, isAssetDetails, isDataStreams, isDataStreamsDetails, isDeliveryTarget, isDelivery])

  // Navigate to workbench if tour started in a different page
  const handleNavigateToWorkbench = useCallback(() => {
    if (tourContext === ADD_ASSET) {
      if (!isWorkbench && !isAssetDetails) {
        history.push(ROUTE_WORKBENCH)
      }
    } else if (tourContext === ACTIVATE_DRAFT) {
      if (!isWorkbench) {
        history.push(ROUTE_WORKBENCH)
      }
    } else if (tourContext === ADD_SITE_FORECAST) {
      if (!isWorkbench && !isDataStreams && !isDataStreamsDetails) {
        history.push(ROUTE_WORKBENCH)
      }
    } else if (tourContext === ADD_DELIVERY_TARGET) {
      if (!isWorkbench && !isDelivery && !isDeliveryTarget) {
        history.push(ROUTE_WORKBENCH)
      }
    }
    handleAddTourSteps()
  }, [
    handleAddTourSteps,
    tourContext,
    isWorkbench,
    isAssetDetails,
    isDataStreams,
    isDataStreamsDetails,
    isDelivery,
    isDeliveryTarget,
  ])

  useEffect(() => {
    if (tourContext && isTourActive) {
      handleAddTourSteps()
    }
  }, [tourContext, handleAddTourSteps, isTourActive])

  return (
    <>
      <OnBoardingTourDialog
        assets={assets}
        siteForecasts={siteForecasts}
        deliveryTargets={deliveryTargets}
        tourContext={tourContext}
        onTourStart={handleNavigateToWorkbench}
      />
    </>
  )
}

export default React.memo(OnBoardingTour)
