import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { HideForecastModelAction, HideForecastModelState } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import createReducer from 'utils/createReducer'

const initialState: HideForecastModelState = {
  loading: false,
  error: null,
}

const loading = createReducer<HideForecastModelState['loading'], HideForecastModelAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.HIDE_FORECAST_MODEL_SUCCESS:
      case actionTypes.HIDE_FORECAST_MODEL_FAILURE:
        return false
      case actionTypes.HIDE_FORECAST_MODEL_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<HideForecastModelState['error'], HideForecastModelAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.HIDE_FORECAST_MODEL_FAILURE:
        return error
      case actionTypes.HIDE_FORECAST_MODEL_REQUEST:
      case actionTypes.HIDE_FORECAST_MODEL_SUCCESS:
        return null
    }
    return state
  },
)

export const hideForecastModelReducer = combineReducers({ loading, error })

// selectors
export const hideForecastModelLoadingSelector = createSelector<
  any,
  HideForecastModelState['loading'],
  HideForecastModelState['loading']
>(
  (state) => state.quality.hideForecastModel.loading,
  (loading) => loading,
)
export const hideForecastModelErrorSelector = createSelector<
  any,
  HideForecastModelState['error'],
  HideForecastModelState['error']
>(
  (state) => state.quality.hideForecastModel.error,
  (error) => error,
)
