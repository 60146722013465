import React, { useEffect } from 'react'

import { Box } from '@material-ui/core'

import { t } from 'ttag'
import { Checkbox } from 'final-form-material-ui'
import { Field, useField } from 'react-final-form'
import { FormApi } from 'final-form'
import { getNewPenaltyBandData, PenaltyBandTypes, UI_DIFFERENT_BANDS_KEYS } from 'utils/penaltyRegulations'
import { PenaltyRegulationTableItemNew } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import PenaltyBandTypeSection from 'modules/data/penalties/PenaltyRegulationNew/PenaltyBandTypeSection'

interface RuleSetFormProps {
  form: FormApi<PenaltyRegulationTableItemNew>
  isNew: boolean
}

const RuleSetForm: React.FC<RuleSetFormProps> = ({ form }) => {
  const { OVER_INJECTION_BANDS, UNDER_INJECTION_BANDS } = PenaltyBandTypes
  const overInjectionBands = useField(OVER_INJECTION_BANDS).input.value
  const underInjectionBands = useField(UNDER_INJECTION_BANDS).input.value
  const hasDifferentOverAndUnderInjectionBands = useField(UI_DIFFERENT_BANDS_KEYS).input.value

  const handleAddBand = (event: React.MouseEvent<HTMLElement>, bandType: string) => {
    event.preventDefault()
    let currentLastBandIndex = 0
    let currentLastBandData = {}
    if (bandType === OVER_INJECTION_BANDS) {
      currentLastBandIndex = overInjectionBands.length - 1
      currentLastBandData = overInjectionBands[currentLastBandIndex]
    } else {
      currentLastBandIndex = underInjectionBands.length - 1
      currentLastBandData = underInjectionBands[currentLastBandIndex]
    }

    const newBandConfig = getNewPenaltyBandData(currentLastBandIndex, currentLastBandData)

    form.mutators.push(bandType, newBandConfig)
  }

  const handleDeleteBand = (event: React.MouseEvent<HTMLElement>, index: number, bandType: PenaltyBandTypes) => {
    form.mutators.remove(bandType, index)
  }

  useEffect(() => {
    if (hasDifferentOverAndUnderInjectionBands && !underInjectionBands.length) {
      const newBandConfig = getNewPenaltyBandData(-1, null, true)
      form.mutators.push(UNDER_INJECTION_BANDS, newBandConfig)
    }
  }, [hasDifferentOverAndUnderInjectionBands, underInjectionBands])

  return (
    <>
      <Box style={{ marginLeft: '-6px' }} display="flex" flexDirection="row" alignItems="center">
        <Field component={Checkbox} type="checkbox" name={UI_DIFFERENT_BANDS_KEYS} />
        <div>{t`Rule set has different Under and Over Injection band values`}</div>
      </Box>
      {/*Over Injection*/}
      {overInjectionBands.length > 0 && (
        <PenaltyBandTypeSection
          form={form}
          injectionBands={overInjectionBands}
          fieldArrayKey={OVER_INJECTION_BANDS}
          penaltyBandType={OVER_INJECTION_BANDS}
          onAddBand={handleAddBand}
          onDeleteBand={handleDeleteBand}
          sameBands={!hasDifferentOverAndUnderInjectionBands}
        />
      )}
      {/*Under Injection*/}
      <div style={{ display: hasDifferentOverAndUnderInjectionBands ? 'block' : 'none' }}>
        {hasDifferentOverAndUnderInjectionBands && (
          <PenaltyBandTypeSection
            form={form}
            injectionBands={underInjectionBands}
            fieldArrayKey={UNDER_INJECTION_BANDS}
            penaltyBandType={UNDER_INJECTION_BANDS}
            onAddBand={handleAddBand}
            onDeleteBand={handleDeleteBand}
            sameBands={false}
          />
        )}
      </div>
    </>
  )
}

export default RuleSetForm
