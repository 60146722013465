export const RETABLE_SET_COLUMNS_AVAILABLE = 'RETABLE_SET_COLUMNS_AVAILABLE'
export type RETABLE_SET_COLUMNS_AVAILABLE = typeof RETABLE_SET_COLUMNS_AVAILABLE
export const RETABLE_INIT_COLUMNS_SELECTED = 'RETABLE_INIT_COLUMNS_SELECTED'
export type RETABLE_INIT_COLUMNS_SELECTED = typeof RETABLE_INIT_COLUMNS_SELECTED
export const RETABLE_SET_COLUMNS_SELECTED = 'RETABLE_SET_COLUMNS_SELECTED'
export type RETABLE_SET_COLUMNS_SELECTED = typeof RETABLE_SET_COLUMNS_SELECTED
export type COLUMNS_ACTION_TYPE =
  | RETABLE_SET_COLUMNS_AVAILABLE
  | RETABLE_INIT_COLUMNS_SELECTED
  | RETABLE_SET_COLUMNS_SELECTED

export const RETABLE_INIT_TREE = 'RETABLE_INIT_TREE'
export const RETABLE_TOGGLE_SUBTREE = 'RETABLE_TOGGLE_SUBTREE'
export const RETABLE_EXPAND_SUBTREES = 'RETABLE_EXPAND_SUBTREES'
export const RETABLE_COLLAPSE_SUBTREES = 'RETABLE_COLLAPSE_SUBTREES'
export type RETABLE_INIT_TREE = typeof RETABLE_INIT_TREE
export type RETABLE_TOGGLE_SUBTREE = typeof RETABLE_TOGGLE_SUBTREE
export type RETABLE_EXPAND_SUBTREES = typeof RETABLE_EXPAND_SUBTREES
export type RETABLE_COLLAPSE_SUBTREES = typeof RETABLE_COLLAPSE_SUBTREES
export type COLLAPSE_ACTION_TYPE =
  | RETABLE_INIT_TREE
  | RETABLE_TOGGLE_SUBTREE
  | RETABLE_EXPAND_SUBTREES
  | RETABLE_COLLAPSE_SUBTREES

export const RETABLE_TOGGLE_SUBTREE_SEARCH = 'RETABLE_TOGGLE_SUBTREE_SEARCH'
export const RETABLE_EXPAND_SUBTREES_SEARCH = 'RETABLE_EXPAND_SUBTREES_SEARCH'
export const RETABLE_COLLAPSE_SUBTREES_SEARCH = 'RETABLE_COLLAPSE_SUBTREES_SEARCH'
export type RETABLE_TOGGLE_SUBTREE_SEARCH = typeof RETABLE_TOGGLE_SUBTREE_SEARCH
export type RETABLE_EXPAND_SUBTREES_SEARCH = typeof RETABLE_EXPAND_SUBTREES_SEARCH
export type RETABLE_COLLAPSE_SUBTREES_SEARCH = typeof RETABLE_COLLAPSE_SUBTREES_SEARCH
export type COLLAPSE_SEARCH_ACTION_TYPE =
  | RETABLE_TOGGLE_SUBTREE_SEARCH
  | RETABLE_EXPAND_SUBTREES_SEARCH
  | RETABLE_COLLAPSE_SUBTREES_SEARCH

export const RETABLE_SET_COLLAPSIBLE_IDS = 'RETABLE_SET_COLLAPSIBLE_IDS'
export type RETABLE_SET_COLLAPSIBLE_IDS = typeof RETABLE_SET_COLLAPSIBLE_IDS
export type COLLAPSIBLE_IDS_ACTION_TYPE = RETABLE_SET_COLLAPSIBLE_IDS

export const RETABLE_SET_FILTERED_ITEMS = 'RETABLE_SET_FILTERED_ITEMS'
export type RETABLE_SET_FILTERED_ITEMS = typeof RETABLE_SET_FILTERED_ITEMS
export type FILTERED_ITEMS_ACTION_TYPE = RETABLE_SET_FILTERED_ITEMS

export const RETABLE_SET_FILTER = 'RETABLE_SET_FILTER'
export const RETABLE_REMOVE_FILTER = 'RETABLE_REMOVE_FILTER'
export type RETABLE_SET_FILTER = typeof RETABLE_SET_FILTER
export type RETABLE_REMOVE_FILTER = typeof RETABLE_REMOVE_FILTER
export type FILTER_ACTION_TYPE = RETABLE_SET_FILTER | RETABLE_REMOVE_FILTER

export const RETABLE_SEARCH = 'RETABLE_SEARCH'
export type RETABLE_SEARCH = typeof RETABLE_SEARCH
export type SEARCH_ACTION_TYPE = RETABLE_SEARCH

export const RETABLE_SET_SELECTED_IDS = 'RETABLE_SET_SELECTED_IDS'
export type RETABLE_SET_SELECTED_IDS = typeof RETABLE_SET_SELECTED_IDS
export type SELECTED_IDS_ACTION_TYPE = RETABLE_SET_SELECTED_IDS

export const RETABLE_SET_SORT = 'RETABLE_SET_SORT'
export type RETABLE_SET_SORT = typeof RETABLE_SET_SORT
export const RETABLE_SET_DEFAULT_SORT = 'RETABLE_SET_DEFAULT_SORT'
export type RETABLE_SET_DEFAULT_SORT = typeof RETABLE_SET_DEFAULT_SORT
export type SORT_ACTION_TYPE = RETABLE_SET_SORT | RETABLE_SET_DEFAULT_SORT

export const RETABLE_SET_VIRTUAL_RANGE = 'RETABLE_SET_VIRTUAL_RANGE'
export type RETABLE_SET_VIRTUAL_RANGE = typeof RETABLE_SET_VIRTUAL_RANGE
export type VIRTUAL_RANGE_ACTION_TYPE = RETABLE_SET_VIRTUAL_RANGE

// actions for saving data in user config
export type RETABLE_SAVE_ACTION = RETABLE_SET_COLUMNS_SELECTED | COLLAPSE_ACTION_TYPE | VIRTUAL_RANGE_ACTION_TYPE
