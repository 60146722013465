import { SET_SELECTED, SET_SELECTED_POINT, SET_SELECTED_POINTS, SET_SELECTED_ROW } from '../actions/selectedActions'

const initialState = { row: '-1', point: '-1', shapes: [], points: [] }

export default function selectedReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED:
      return { ...state, shapes: action.shapes }
    case SET_SELECTED_ROW:
      return { ...state, row: action.row }
    case SET_SELECTED_POINT:
      return { ...state, point: action.point }
    case SET_SELECTED_POINTS:
      return { ...state, points: action.points }
    default:
      return state
  }
}
