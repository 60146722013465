import { TableCellProps } from '@material-ui/core'
import { TransformedPlausibility } from 'modules/asset/plausability/Plausability.types'
import { MeterdataSummary } from 'modules/asset/store/asset.types'
import { User } from 'modules/auth/Auth.types'
import { QualityOverview } from 'modules/quality/quality.types'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FindAllCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'

// retable ids
// TODO enum would be better
export const RETABLE_ID_ASSETS = 'RETABLE_ID_ASSETS'
export const RETABLE_ID_ASSET_SELECTOR = 'RETABLE_ID_ASSET_SELECTOR'
export const RETABLE_ID_AVAILABILITIES = 'RETABLE_ID_AVAILABILITIES'
export const RETABLE_ID_DATASTREAMS = 'RETABLE_ID_DATASTREAMS'
export const RETABLE_ID_FILEMANAGER = 'RETABLE_ID_FILEMANAGER'
export const RETABLE_ID_QUALITY_PREVIEW = 'RETABLE_ID_QUALITY_PREVIEW'
export const RETABLE_ID_QUALITY_PENALTY = 'RETABLE_ID_QUALITY_PENALTY'
export const RETABLE_ID_SELECTED_AREA_FORECASTS = 'RETABLE_ID_SELECTED_AREA_FORECASTS'
export const RETABLE_ID_AVAILABLE_AREA_FORECASTS = 'RETABLE_ID_AVAILABLE_AREA_FORECASTS'
export const RETABLE_ID_ALL_WEATHER_DATASTREAMS = 'RETABLE_ID_ALL_WEATHER_DATASTREAMS'
export const RETABLE_ID_SELECTED_WEATHER_DATASTREAMS = 'RETABLE_ID_SELECTED_WEATHER_DATASTREAMS'
export const RETABLE_ID_AVAILABLE_FORECAST_TEMPLATES = 'RETABLE_ID_AVAILABLE_FORECAST_TEMPLATES'
export const RETABLE_ID_SELECTED_FORECASTS = 'RETABLE_ID_SELECTED_FORECASTS'
export const RETABLE_ID_AVAILABLE_E3_FORECASTS = 'RETABLE_ID_AVAILABLE_E3_FORECASTS'
export const RETABLE_ID_PENALTY_REGULATIONS = 'RETABLE_ID_PENALTY_REGULATIONS'
export const RETABLE_ID_PENALTY_REGULATIONS_NEW = 'RETABLE_ID_PENALTY_REGULATIONS_NEW'
export const RETABLE_ID_DELIVERY_TARGETS = 'RETABLE_ID_DELIVERY_TARGETS'
export const RETABLE_ID_DELIVERY_FORMATS = 'RETABLE_ID_DELIVERY_FORMATS'
export const RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS = 'RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS'
export const RETABLE_ID_USER_MANAGEMENT = 'RETABLE_ID_USER_MANAGEMENT'
export const RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS = 'RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS'
export const RETABLE_ID_METER_DATA_STATISTICS = 'RETABLE_ID_METER_DATA_STATISTICS'
export const RETABLE_ID_TRAINING = 'RETABLE_ID_TRAINING'
export const RETABLE_ID_RUNNING_BACKCASTS = 'RETABLE_ID_RUNNING_BACKCASTS'
export const RETABLE_ID_FORECAST_MODELS = 'RETABLE_ID_FORECAST_MODELS'
export const RETABLE_ID_LONG_RANGE_FORECAST = 'RETABLE_ID_LONG_RANGE_FORECAST'
export const RETABLE_ID_EVALUATION = 'RETABLE_ID_EVALUATION'
export const RETABLE_ID_SCHEDULE_TEMPLATE_DATA = 'RETABLE_ID_SCHEDULE_TEMPLATE_DATA'

export type ReTableId =
  | typeof RETABLE_ID_ASSETS
  | typeof RETABLE_ID_ASSET_SELECTOR
  | typeof RETABLE_ID_AVAILABILITIES
  | typeof RETABLE_ID_DATASTREAMS
  | typeof RETABLE_ID_FILEMANAGER
  | typeof RETABLE_ID_QUALITY_PREVIEW
  | typeof RETABLE_ID_QUALITY_PENALTY
  | typeof RETABLE_ID_SELECTED_AREA_FORECASTS
  | typeof RETABLE_ID_AVAILABLE_AREA_FORECASTS
  | typeof RETABLE_ID_ALL_WEATHER_DATASTREAMS
  | typeof RETABLE_ID_SELECTED_WEATHER_DATASTREAMS
  | typeof RETABLE_ID_AVAILABLE_FORECAST_TEMPLATES
  | typeof RETABLE_ID_SELECTED_FORECASTS
  | typeof RETABLE_ID_AVAILABLE_E3_FORECASTS
  | typeof RETABLE_ID_PENALTY_REGULATIONS
  | typeof RETABLE_ID_DELIVERY_TARGETS
  | typeof RETABLE_ID_DELIVERY_FORMATS
  | typeof RETABLE_ID_MASTER_DATA_CUSTOM_FORMATS
  | typeof RETABLE_ID_USER_MANAGEMENT
  | typeof RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS
  | typeof RETABLE_ID_METER_DATA_STATISTICS
  | typeof RETABLE_ID_TRAINING
  | typeof RETABLE_ID_RUNNING_BACKCASTS
  | typeof RETABLE_ID_FORECAST_MODELS
  | typeof RETABLE_ID_LONG_RANGE_FORECAST
  | typeof RETABLE_ID_PENALTY_REGULATIONS_NEW
  | typeof RETABLE_ID_EVALUATION
  | typeof RETABLE_ID_SCHEDULE_TEMPLATE_DATA

// table items

export interface ReTableItem {
  id: string
  name?: string
  uiAncestors?: string[]
  uiDescendants?: string[]
  uiParents?: string[]
  uiChildren?: string[]
  uiLevel?: number
  uiIsMatching?: boolean
  uiHasMatchingDescendants?: boolean
  uiHasMatchingParents?: boolean
  [key: string]: unknown
}

// meta data for sorting

export interface ReTableSortData {
  // asset specific sort data
  meterdataSummaries?: Record<string, MeterdataSummary>
  lastSetNetwork?: Record<string, Date>
  qualityOverview?: QualityOverview | null
  plausibilityData?: TransformedPlausibility[]
  siteForecastNames?: Record<string, string>
  countryCodes?: Record<string, string>
  assetPenaltyAccuracy?: Record<string, number>
  assetOperationalData?: Record<string, string>
  assetFilterSetup?: Record<string, FindAllCleansingFilterSettings>
}

// collapsed

export type Collapsed = string[]

// columns

export enum CellRenderType {
  TEXT, // alphanumeric
  NUMERIC, // only numbers, possibly with units
  TUPLE, // data with primary and secondary levels, e.g. errors -> warnings
  DATE, // dates and times
  BOOLEAN, // switches and flags
  SELECT,
  CHECKBOX,
  CUSTOM,
  CUSTOM_NUMERIC,
}
export enum ColumnSortType {
  FIELD, // just a common field of the item
  FIELD_GENERATOR, // again just the field, but only for generators or manual parks without plants
  PLAUSIBILITY,
  NOMCAP, // nominal capacity
  AVAILABLECAP, // available capacity
  COORDS, // geographic locations
  METERDATA, // meterdata information
  LAST_SET_NETWORK, // last set network for training
  QUALITY_EVALUATION, // quality evaluation results
  VALUE_WITH_UNIT, // availability value
  FILE_UPLOAD_STATUS, // file upload
  SITE_FORECAST, // Asset siteforecasts
  COUNTRY_CODE,
  REGULATION_ERROR_BAND,
  BOOLEAN,
  DELIVERY_TARGET_TYPE,
  PENALTY_ACCURACY,
  ASSET_OPERATIONAL,
  ASSET_SPECIFIC_FILTER_SETUP,
}
export type ColumnWidth = string | number

export interface ColumnItem {
  name: string
  label: string | string[]
  cellRenderType: CellRenderType
  columnSortType: ColumnSortType
  primaryColumn?: boolean // only one column can be primary. it will be fluid and take as much space as possible
  searchable: boolean
  sortable: boolean
  fixed?: boolean
  width?: ColumnWidth
  customDynamicWidth?: string //overwrite width
  align?: TableCellProps['align']
  isAuthorized?: (user: User) => boolean
  dynamicLabel?: boolean
  fieldName?: string
  isEditable?: boolean
  selectItems?: ReTableColumnSelectItem[]
  helperValue?: number | string | boolean
  // TODO fix this item type when we have generic column type
  fieldLookup?: (item: any, helper: any) => any
  minDate?: Date
  toolTip?: React.ReactNode
  isAdminComponent?: boolean // this is used for highlighting admin component
  firstColumn?: boolean
  [key: string]: unknown
}

export interface Column extends ColumnItem {
  subItems?: ColumnItem[]
  tableId?: ReTableId
}

export type Columns = Column[]

// filters

export interface Filter {
  value: string | string[] | undefined
}

export type Filters = Record<string, Filter>

// search

export type Search = string

// sort

export interface Sort {
  active: boolean
  column?: string
  ascending?: boolean
  cellRenderType?: CellRenderType
}

export enum ReTableRowContextActions {
  SAVE_ROW = 'SAVE_ROW',
  ADD_ROW = 'ADD_ROW',
  EDIT_ROW = 'EDIT_ROW',
  DELETE_ROW = 'DELETE_ROW',
  COPY_ROW = 'COPY_ROW',
  CLOSE_FORM = 'CLOSE_FORM',
  SAVE_TEMPLATE_AS_FILE_MENU_ITEM = 'SAVE_TEMPLATE_AS_FILE_MENU_ITEM',
  EVALUATE_ALL = 'EVALUATE_ALL',
  ACTIVATE = 'ACTIVATE',
  ENABLE_DISABLE_REALTIME = 'ENABLE_DISABLE_REALTIME',
  MIGRATE_TO_V2 = 'MIGRATE_TO_V2',
  START_WEIGHT_OPTIMIZATION = 'START_WEIGHT_OPTIMIZATION',
  START_SOLAR_CALIBRATION = 'START_SOLAR_CALIBRATION',
  HIDE_ROW = 'HIDE_ROW',
  START_TRAINING_NOW = 'START_TRAINING_NOW',
}

export type ReTableContextMenuItemName = ReTableRowContextActions

export interface ReTableContextMenuItem {
  itemName: ReTableContextMenuItemName
  itemLabel: string
  icon: string
  disabled?: boolean
  userHasPermission?: (user: User | null) => boolean
}

export interface ReTableColumnSelectItem {
  label: string
  id: string
}

export enum StatusType {
  'success' = 'success',
  'progress' = 'progress',
  'error' = 'error',
}
export interface Status {
  label: string
  description: string
  icon: IconProp
  color: string
  type: StatusType
  messages: string[]
}
