import {
  ACTIVATE_FORECAST_MODEL_ACTION_TYPE,
  GET_FORECAST_MODELS_ACTION_TYPE,
  GET_QUALITY_EVALUATION_ACTION_TYPE,
  GET_QUALITY_OVERVIEW_ACTION_TYPE,
  GET_TRAINING_INFO_ACTION_TYPE,
  GET_TRAINING_JOBS_ACTION_TYPE,
  HIDE_FORECAST_MODEL_ACTION_TYPE,
  REEVALUATE_FORECAST_MODEL_ACTION_TYPE,
  SAVE_FORECAST_MODEL_ACTION_TYPE,
  SAVE_FORECAST_MODEL_NAME_ACTION_TYPE,
  TRIGGER_WEIGHT_OPTIMIZATION_ACTION_TYPE,
} from 'modules/quality/redux_store/quality.action.types'
import { Asset } from 'modules/asset/store/asset.types'
import { ForecastConfig, SiteForecastQualityConfig } from 'modules/dataStreams/dataStreams.types'

// get forecast model

export interface WeatherModelConfiguration {
  model: string
  weight: number
  run: number
  fallbackCount: number
}

export interface Parameter {
  weatherModelConfiguration: WeatherModelConfiguration[]
  StaticWeightsMetaForecastAlgorithm: any
  weatherPoints?: Record<string, string>[]
  capacitiesSources: string[]
  anns: any
  windCorrections: CorrectionSettings
  forcePhysical: boolean
  algorithmChain?: string | string[]
  realtime?: boolean
}

export const FORECAST_MODEL_TYPE_ANN = 'ANN'
export const FORECAST_MODEL_TYPE_POWERCURVE = 'Power Curve'
export type FORECAST_MODEL_TYPE_ANN = typeof FORECAST_MODEL_TYPE_ANN
export type FORECAST_MODEL_TYPE_POWERCURVE = typeof FORECAST_MODEL_TYPE_POWERCURVE
export type FORECAST_MODEL_TYPE = FORECAST_MODEL_TYPE_ANN | FORECAST_MODEL_TYPE_POWERCURVE

export interface ForecastModel {
  uuid: string
  creationDate: Date
  activeFrom?: Date
  assetId: string
  assetType: string
  customer: string
  parameter: Parameter
  latitude: number
  longitude: number
  reason: string
  trainingId: string
  precursors: any[]
  version?: string
  deleted?: any
  hide?: boolean
  name?: string
  modelInstanceVersion?: number
  id?: string
  category?: string
}

export interface GetForecastModelState {
  forecastModels: ForecastModel[]
  loading: boolean
  error: string | null
}

export interface GetForecastModelsAction {
  type: GET_FORECAST_MODELS_ACTION_TYPE
  // REQUEST
  asset: Asset
  // SUCCESS
  forecastModels: GetForecastModelState['forecastModels']
  // ERROR
  error: GetForecastModelState['error']
}

// trigger weight optimization
export type WeightOptimizationJobStatus = 'QUEUED' | 'IN_PROGRESS' | 'VALIDATION_STARTED' | 'DONE' | 'ERROR'

export interface WeightOptimizationJob {
  uuid: string
  jobStatus: WeightOptimizationJobStatus
  assetId: string
  customer: string
  creationDate: Date
  start: Date
  end: Date
  message: string
  qecrs: QualityEvaluation[]
}

export type WeightOptimizationJobMap = Record<string, WeightOptimizationJob[]>

export interface TriggerWeightOptimizationState {
  loading: boolean
  error: string | null
}

export interface TriggerWeightOptimizationAction {
  type: TRIGGER_WEIGHT_OPTIMIZATION_ACTION_TYPE
  // REQUEST
  assets: Asset[]
  // ERROR
  error: GetForecastModelState['error']
}

// save forecast model

export interface SaveForecastModelState {
  loading: boolean
  error: string | null
  success: boolean
}

export interface SaveForecastModelAction {
  type: SAVE_FORECAST_MODEL_ACTION_TYPE
  // REQUEST
  assets: Asset[]
  data: ForecastModel
  activate: boolean
  // ERROR
  error: SaveForecastModelState['error']
}

// hide forecast model
export interface HideForecastModelState {
  loading: boolean
  error: string | null
}

export interface HideForecastModelAction {
  type: HIDE_FORECAST_MODEL_ACTION_TYPE
  // REQUEST
  asset: Asset
  forecastModel: ForecastModel
  hide: boolean
  // ERROR
  error: HideForecastModelState['error']
}

// save forecast model name
export interface SaveForecastModelNameState {
  result: any
  loading: boolean
  error: string | null
}

export interface SaveForecastModelNameAction {
  type: SAVE_FORECAST_MODEL_NAME_ACTION_TYPE
  // REQUEST
  asset: Asset
  forecastModel: ForecastModel
  inputValue: string
  // ERROR
  error: SaveForecastModelNameState['error']
}

// reevaluate forecast model

export interface ReevaluateForecastModelState {
  isReEvaluated: boolean
  loading: boolean
  error: string | null
}

export interface ReevaluateForecastModelAction {
  type: REEVALUATE_FORECAST_MODEL_ACTION_TYPE
  // REQUEST
  asset: Asset
  forecastModel: ForecastModel
  // ERROR
  error: ReevaluateForecastModelState['error']
}

// activate forecast model

export interface ActivateForecastModelState {
  isActivated: boolean
  loading: boolean
  error: string | null
}

export interface ActivateForecastModelAction {
  type: ACTIVATE_FORECAST_MODEL_ACTION_TYPE
  // REQUEST
  asset: Asset
  forecastModel: ForecastModel
  // ERROR
  error: ActivateForecastModelState['error']
}

// training job

export interface TrainingJob {
  uuid: string
  jobName: string
  customer: string
  assetIds: any[]
  weatherModels: any[]
  trainingStart: number[]
  trainingEnd: number[]
  validationStart: number[]
  validationEnd: number[]
  creationDate: number
  lastModified: number
  ignoreErrorCodes?: boolean
  autoRange?: any
  jiraLink?: any
  jobStatus?: any
  trainings: any[]
  additionalMessages: any
}

export interface TrainingJobsState {
  trainingJobs: TrainingJob[]
  loading: boolean
  error: string | null
}

export interface GetTrainingJobsAction {
  type: GET_TRAINING_JOBS_ACTION_TYPE
  trainingJobs: TrainingJob[]
  error: TrainingJobsState['error']
}

// training info

export interface CorrectionSettings {
  ncUuid?: any
  useCorrection: boolean
  name: string
  vIdle: number
  vIdleTransit: number
  vLoad: number
  vLoadTransit: number
  highWindScale: number
  useHighWindCorrection: boolean
}

export interface TrainingQuality {
  trainingRmse: number
  validationRmse: number
  testRmse: number
}

export interface Training {
  uuid?: string
  assetId: string
  weatherModel: string
  status?: any
  trainingStart: number[]
  trainingEnd: number[]
  progress: number
  correctionSettings: CorrectionSettings
  trainingQuality: TrainingQuality
  deploymentStatus?: any
}

export interface TrainingInfo {
  creationDate: number
  lastModified: number
  weatherModel: string
  training: Training
}

export type TrainingInfoPerAsset = Record<string, TrainingInfo[]>

export interface TrainingInfoState {
  trainingInfo: TrainingInfoPerAsset
  lastSetNetwork: Record<string, Date>
  loading: boolean
  error: string | null
}

export interface GetTrainingInfoAction {
  type: GET_TRAINING_INFO_ACTION_TYPE
  assets: Asset[]
  trainingInfo: TrainingInfoPerAsset
  error: TrainingInfoState['error']
}

// training evaluation

export type ErrorMeasurementType =
  | 'nRmse'
  | 'nMae'
  | 'nbias'
  | 'rmse'
  | 'mae'
  | 'bias'
  | 'absoluteDeviation'
  | 'correlationCoefficient'

export interface ErrorMeasurements {
  errorTag: string
  errorMessage: string
  nRmse: number
  nMae: number
  absoluteDeviation: number
  nbias: number
  correlationCoefficient: number
  rmse: number
  mae: number
  bias: number
}

export type QualityEvaluationStatus = 'QUEUED' | 'IN_PROGRESS' | 'FINISHED'

export interface QualityEvaluation {
  created: Date
  customer: string
  assetId: string
  status: QualityEvaluationStatus
  reason: string
  meta: string
  errorMeasurements: ErrorMeasurements
  productConfigId: string
  qualityConfig: SiteForecastQualityConfig
  evaluationTimeRangeStart: Date
  evaluationTimeRangeEnd: Date
  timeZone?: any
  deliveryTime: string
  label: string
  id: string
  forecastModelId: string
}

export interface QualityEvaluationState {
  qualityEvaluations: QualityEvaluation[]
  loading: boolean
  error: string | null
}

export interface GetQualityEvaluationAction {
  type: GET_QUALITY_EVALUATION_ACTION_TYPE | GET_TRAINING_INFO_ACTION_TYPE
  assets: Asset[]
  qualityEvaluations: QualityEvaluation[]
  trainingInfo: TrainingInfoPerAsset
  error: QualityEvaluationState['error']
}

// quality overview

export interface QualityOverviewItem {
  active: QualityEvaluation
  last: QualityEvaluation
  candidate: QualityEvaluation
}

export type QualityOverview = Record<string, Record<string, QualityOverviewItem>>

export interface GetQualityOverviewState {
  qualityOverview: QualityOverview | null
  loading: boolean
  error: string | null
}

export interface GetQualityOverviewAction {
  type: GET_QUALITY_OVERVIEW_ACTION_TYPE
  assets: Asset[]
  forecastConfigs: ForecastConfig[]
  qualityOverview: QualityOverview
  error: GetQualityOverviewState['error']
}
