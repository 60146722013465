import { isAfter, isWithinInterval } from 'date-fns'

import { CorrelationSet, FORECAST_MODEL_FOR_BACK_CAST_ID, NomcapsByAsset } from 'modules/asset/store/asset.types'
import {
  DataStreamSelectionItem,
  ForecastConfig,
  TimeSeriesResult,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { ErrorMeasurementType, QualityEvaluation } from 'modules/quality/quality.types'
import { QueryObserverResult } from 'react-query'
import { isNumeric } from 'utils/dataFormatting'
import { getSiteForecastLabel } from 'utils/dataStream'
import { getQualityConfigName } from 'utils/siteForecastConfiguration'
import { DateRange } from 'utils/date'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { c, t } from 'ttag'

type GetPrimaryMeasurement = (evaluation: QualityEvaluation) => ErrorMeasurementType
export const getPrimaryMeasurement: GetPrimaryMeasurement = (evaluation: QualityEvaluation) => {
  let measurementName: ErrorMeasurementType = 'nRmse'

  if (evaluation.qualityConfig.primaryMeasurement || !evaluation.qualityConfig.errorMeasurements.includes('NRMSE')) {
    const qualityconfigMeasurementName = evaluation.qualityConfig.primaryMeasurement
      ? evaluation.qualityConfig.primaryMeasurement
      : evaluation.qualityConfig.errorMeasurements[0]
    switch (qualityconfigMeasurementName) {
      case 'NRMSE':
        measurementName = 'nRmse'
        break
      case 'NMAE':
        measurementName = 'nMae'
        break
      case 'NBIAS':
        measurementName = 'nbias'
        break
      case 'RMSE':
        measurementName = 'rmse'
        break
      case 'MAE':
        measurementName = 'mae'
        break
      case 'BIAS':
        measurementName = 'bias'
        break
      case 'ABSOLUTE_DEVIATION':
        measurementName = 'absoluteDeviation'
        break
    }
  }

  return measurementName
}

export const measurementsInPercentage = ['nRmse', 'nMae', 'absoluteDeviation', 'nbias', 'correlationCoefficient']

export const formatErrorMeasurementValues = (evaluation: QualityEvaluation) => {
  const measurementValues = Object.assign({}, evaluation.errorMeasurements)
  measurementsInPercentage.forEach((key) => {
    measurementValues[key] = measurementValues[key] * 100
  })
  return measurementValues
}

type GetErrorMeasurements = (evaluation: QualityEvaluation) => ErrorMeasurementType[]
export const getErrorMeasurements: GetErrorMeasurements = (evaluation) => {
  return Object.keys(evaluation.errorMeasurements || {}).filter(
    (name) => !['errorTag', 'errorMessage'].includes(name),
  ) as ErrorMeasurementType[]
}

type GetLatestEvaluation = (evaluations: QualityEvaluation[], evaluationLabel: string) => QualityEvaluation | null
export const getLatestEvaluation: GetLatestEvaluation = (evaluations, evaluationLabel) => {
  const filteredEvaluations = evaluations.filter(
    (evaluation) => evaluation.errorMeasurements.errorTag === 'OK' && evaluation.label === evaluationLabel,
  )
  return filteredEvaluations.length > 0
    ? filteredEvaluations.reduce<QualityEvaluation>((previous, evaluation) => {
        return isAfter(new Date(previous.created), new Date(evaluation.created)) ? evaluation : previous
      }, filteredEvaluations[0])
    : null
}

type GetPrimaryQualityValue = (evaluation: QualityEvaluation | null) => string
export const getPrimaryQualityValue: GetPrimaryQualityValue = (evaluation) => {
  if (evaluation && evaluation.errorMeasurements) {
    const measurement = getPrimaryMeasurement(evaluation)
    const initialValue = evaluation.errorMeasurements[measurement]
    const formattedValue = measurementsInPercentage.includes(measurement) ? initialValue * 100 : initialValue
    return formattedValue.toString()
  }
  return ''
}

export const getPrimaryQualityLabel = (forecastId: string, forecastConfigs: ForecastConfig[], showQuality: boolean) => {
  const forecastConfig = forecastConfigs.find((forecastConfig) => forecastId.startsWith(forecastConfig.id))
  const qualityConfigs = forecastConfig?.qualityConfigs || []
  const primaryQualityConfigIndex = qualityConfigs.findIndex((qualityConfig) => qualityConfig.primary)

  const forecastName = getSiteForecastLabel(forecastId, forecastConfigs)
  const qualityName =
    primaryQualityConfigIndex !== -1
      ? getQualityConfigName(qualityConfigs[primaryQualityConfigIndex], primaryQualityConfigIndex)
      : null

  return qualityName && showQuality ? `${forecastName} (${qualityName})` : forecastName
}

// Quality for comparing data, e.g. for e³ Forecasts

export const createCorrelations = (
  meterDataQueryResults: QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[],
  forecastDataQueryResults: QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[],
): CorrelationSet => {
  const correlationSet: CorrelationSet = {}
  // console.log({ forecastDataQueryResults, meterDataQueryResults })
  // Go through each meterdata query result and create an empty correlation set
  meterDataQueryResults.forEach((meterDataQueryResult) => {
    const id = meterDataQueryResult.data?.id
    if (id && !correlationSet[id]) {
      correlationSet[id] = {
        data: {},
        meterDataStreamId: '',
        correlationSetType: meterDataQueryResult?.data?.meterDataFor,
      }
    }
  })

  Object.keys(correlationSet).forEach((id) => {
    const meterDataQueryResultsForAsset = meterDataQueryResults.filter((meterDataQueryResult) => {
      return id === meterDataQueryResult.data?.id
    })

    if (meterDataQueryResultsForAsset.length === 0) return
    // console.log('iteration for each set =', id, meterDataQueryResultsForAsset)

    const meterDataForCorrelationSet = meterDataQueryResultsForAsset[0]
    const data = meterDataForCorrelationSet.data?.data || {}
    correlationSet[id]['meterDataStreamId'] = meterDataForCorrelationSet?.data?.dataStreamId || ''

    Object.keys(data).forEach((meterDataTimestamp) => {
      if (isNumeric(data[meterDataTimestamp])) {
        correlationSet[id]['data'][meterDataTimestamp] = {
          value: data[meterDataTimestamp] as number,
          correlations: [],
        }
      }
    })

    forecastDataQueryResults.forEach((forecastDataQueryResult) => {
      const dataStreamId = forecastDataQueryResult.data?.dataStreamId
      const id = forecastDataQueryResult.data?.id
      const data = forecastDataQueryResult.data?.data || {}
      if (!id || !dataStreamId || !correlationSet[id] || !correlationSet[id]['data']) return
      Object.keys(data).forEach((forecastTimestamp) => {
        if (
          correlationSet[id]['data'] &&
          correlationSet[id]['data']?.[forecastTimestamp] &&
          isNumeric(data[forecastTimestamp])
        ) {
          // to avoid duplicates
          const correlationDataForThisTimestamp = correlationSet[id]['data'][forecastTimestamp]?.correlations
          const isAlreadyPresent =
            correlationDataForThisTimestamp &&
            (correlationDataForThisTimestamp || [])?.some((cr) => cr.name === dataStreamId)

          if (!isAlreadyPresent) {
            correlationSet[id]['data'][forecastTimestamp].correlations.push({
              name: dataStreamId,
              value: data[forecastTimestamp] as number,
            })
          }
        }
      })
    })
  })

  return correlationSet
}

type QualityPreviewCalculationMap = Record<string, number[]>

export interface QualityPreviewErrors {
  assetId: string
  meterDataStreamId: string
  model: string
  nRMSE: number | null
  nMAE: number | null
  absoluteDeviation: number | null
  nBIAS: number | null
  RMSE: number | null
  MAE: number | null
  correlationSetType: TimeSeriesType
}
// Group Quality per weather model
export type QualityPreviewPerModel = Record<string, QualityPreviewErrors>
// Group Quality per asset
export type QualityPreviewResult = Record<string, QualityPreviewPerModel>

const calculateRMSE = (values: number[]): number | null => {
  let rmse = null

  if (values.length > 0) {
    const sum = values.reduce((result, value) => {
      return result + value
    }, 0)

    rmse = Math.sqrt(sum / values.length)
  }

  return rmse
}

const calculateMAE = (values: number[]): number | null => {
  let mae = null

  if (values.length > 0) {
    const sum = values.reduce((result, value) => {
      return result + value
    }, 0)

    mae = sum / values.length
  }

  return mae
}

const calculateBIAS = (values: number[]): number | null => {
  let bias = null

  if (values.length > 0) {
    const sum = values.reduce((result, value) => {
      return result + value
    }, 0)

    bias = sum / values.length
  }

  return bias
}

const calculateNRMSE = (values: number[], nomcap: number): number | null => {
  let nrmse = null
  const rmse = calculateRMSE(values)

  if (rmse) {
    nrmse = rmse / (nomcap || 1)
  }

  return nrmse
}

const calculateNMAE = (values: number[], nomcap: number): number | null => {
  let nmae = null
  const mae = calculateMAE(values)

  if (mae) {
    nmae = mae / (nomcap || 1)
  }

  return nmae
}

const calculateAbsDev = (values: number[], measurementValues: number[]): number | null => {
  let absDev = null

  if (values.length > 0) {
    const sum = values.reduce((result, value) => {
      return result + value
    }, 0)

    const sumMeasurement = measurementValues.reduce((result, value) => {
      return result + value
    }, 0)

    absDev = sum / sumMeasurement
  }

  return absDev
}

const calculateNBIAS = (values: number[], nomcap: number): number | null => {
  let nbias = null
  const bias = calculateBIAS(values)

  if (bias) {
    nbias = bias / (nomcap || 1)
  }

  return nbias
}

export const calculateQualityForPreview = (
  correlationSet: CorrelationSet,
  nomcaps: NomcapsByAsset,
  range: DateRange,
  assetSelection: string[],
): QualityPreviewResult => {
  const result: QualityPreviewResult = {}

  Object.keys(correlationSet).forEach((assetId) => {
    const id = assetId
    if (!assetSelection.includes(id.replace('BACK_CAST', ''))) return

    const correlationsForAsset = correlationSet[assetId].data
    let nomcap = nomcaps[assetId]
    // Nom cap for backcast data from forecast models and asset
    if (assetId.includes('BACK_CAST')) {
      let backCastAssetId = assetId.replace('BACK_CAST', '')
      if (backCastAssetId.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)) {
        backCastAssetId = getBackCastModelDataFromId(assetId).assetId || ''
      }
      nomcap = nomcaps[backCastAssetId]
    }
    const meterDataMap: QualityPreviewCalculationMap = {}
    const diffInMeterAndForecast: QualityPreviewCalculationMap = {}
    const diffSquaredInMeterAndForecast: QualityPreviewCalculationMap = {}
    const diffAbsInMeterAndForecast: QualityPreviewCalculationMap = {}
    // const correlationSetType: QualityPreviewCalculationMap = {}
    const models: string[] = []

    Object.keys(correlationsForAsset).forEach((timestamp) => {
      if (!isWithinInterval(new Date(timestamp), { start: range[0], end: range[1] })) return

      const correlation = correlationsForAsset[timestamp]
      const meterDataValue = correlation.value

      correlation.correlations.forEach((correlationItem) => {
        // console.log({ correlationItem })
        const forecastValue = correlationItem?.value
        const name = correlationItem?.name
        if (!models.includes(name)) models.push(name)
        if (!meterDataMap[name]) meterDataMap[name] = []
        if (!diffSquaredInMeterAndForecast[name]) diffSquaredInMeterAndForecast[name] = []
        if (!diffAbsInMeterAndForecast[name]) diffAbsInMeterAndForecast[name] = []
        if (!diffInMeterAndForecast[name]) diffInMeterAndForecast[name] = []

        meterDataMap[name].push(meterDataValue)
        diffSquaredInMeterAndForecast[name].push(Math.pow(meterDataValue - forecastValue, 2))
        diffAbsInMeterAndForecast[name].push(Math.abs(meterDataValue - forecastValue))
        diffInMeterAndForecast[name].push(meterDataValue - forecastValue)
      })
    })

    result[assetId] = {}

    models.forEach((model) => {
      result[assetId][model] = {
        assetId,
        meterDataStreamId: correlationSet[assetId].meterDataStreamId,
        correlationSetType: correlationSet[assetId].correlationSetType || '',
        model,
        nRMSE: calculateNRMSE(diffSquaredInMeterAndForecast[model], nomcap),
        nMAE: calculateNMAE(diffAbsInMeterAndForecast[model], nomcap),
        absoluteDeviation: calculateAbsDev(diffAbsInMeterAndForecast[model], meterDataMap[model]),
        nBIAS: calculateNBIAS(diffInMeterAndForecast[model], nomcap),
        RMSE: calculateRMSE(diffSquaredInMeterAndForecast[model]),
        MAE: calculateMAE(diffAbsInMeterAndForecast[model]),
      }
    })
  })
  // if (Object.keys(result).length) {
  //   console.log({ result })
  // }
  return result
}

export const getQualityTableColumns: () => Column[] = () => {
  return [
    {
      name: 'siteForecast',
      label: t`Data Streams`,
      cellRenderType: CellRenderType.TEXT,
      columnSortType: ColumnSortType.FIELD,
      width: '20em',
      sortable: false,
      fixed: true,
      primaryColumn: true,
    },
    {
      name: 'nRMSE',
      label: c('e³').t`nRMSE`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      width: '10em',
      sortable: false,
      fixed: true,
    },
    {
      name: 'nMAE',
      label: c('e³').t`nMAE`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      width: '10em',
      sortable: false,
      fixed: true,
    },
    {
      name: 'absoluteDeviation',
      label: c('e³').t`AbsDev`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      width: '10em',
      sortable: false,
      fixed: true,
    },
    {
      name: 'nBIAS',
      label: c('e³').t`nBIAS`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      width: '10em',
      sortable: false,
      fixed: true,
    },
    {
      name: 'RMSE',
      label: c('e³').t`RMSE`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      width: '10em',
      sortable: false,
      fixed: true,
    },
    {
      name: 'MAE',
      label: c('e³').t`MAE`,
      cellRenderType: CellRenderType.NUMERIC,
      columnSortType: ColumnSortType.FIELD,
      width: '10em',
      sortable: false,
      fixed: true,
    },
  ]
}

/**
 *  Quality table shows only data related following dataStreams
 *  Site forecast
 *  BackCast
 *  Meta forecast
 *  Third party
 *  Weather models
 *  Schedule
 */
export const QualityTableDataStreamsTypes = [
  TimeSeriesType.SITE_FORECAST,
  TimeSeriesType.BACK_CAST,
  TimeSeriesType.META_FORECAST,
  TimeSeriesType.E3_META_FORECAST,
  TimeSeriesType.SCHEDULE,
]

export const filterQualityTableDataStreams = (dataStreams: DataStreamSelectionItem[]) => {
  const qualityDataStreams = dataStreams?.filter((dataStream) => {
    return QualityTableDataStreamsTypes.includes(dataStream.type)
  })

  return qualityDataStreams
}
