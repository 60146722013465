import React from 'react'
import { useSelector } from 'react-redux'
import { convertUtcToZonedTime, formatDate } from 'utils/date'
import Highlight from 'ui/Highlight'
import Flex from 'ui/styles/Flex'
import { Timezone } from 'fixtures/timezones'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'

interface DateWithTimezoneProps {
  dateInUTC: Date | string
  originalTimezone: Timezone | null
  query: string
}

const DateWithTimezone: React.FC<DateWithTimezoneProps> = ({ dateInUTC, originalTimezone, query }) => {
  const userTimezone = useSelector(getUserTimezoneSelector)
  const userTimezoneDate = formatDate(convertUtcToZonedTime(dateInUTC, userTimezone))
  const originalTimezoneIsDifferent = originalTimezone && userTimezone !== originalTimezone

  return (
    <Flex direction="row">
      <Highlight text={userTimezoneDate} query={query} />
      {originalTimezoneIsDifferent && <DateTimezonePopover dateInUTC={dateInUTC} originalTimezone={originalTimezone} />}
    </Flex>
  )
}

export default React.memo(DateWithTimezone)
