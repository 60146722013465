import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { t } from 'ttag'
import {
  RunningBackcastsStatus,
  RunningBackcastsType,
  singleRunningBackcastType,
} from 'modules/workspace/header/backCast/backcast.types'

export const getRunningBackcastsTableColumns: () => Column[] = () => [
  {
    name: 'status',
    label: t`Status`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'status',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'customer',
    label: t`Customer`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'customer',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'assetId',
    label: t`Asset Id`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '10em',
    fieldName: 'assetId',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'forecastModelId',
    label: t`Forecast model ID`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '12em',
    fieldName: 'forecastModelId',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'forecastConfigId',
    label: t`Forecast config Id`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '12em',
    fieldName: 'forecastConfigId',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'qualityConfigurationId',
    label: t`Quality configuration Id`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '12em',
    fieldName: 'qualityConfigurationId',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'forecastConfigVersion',
    label: t`Forecast config version`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '12em',
    fieldName: 'forecastConfigVersion',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'start',
    label: t`Start`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '7em',
    fieldName: 'start',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'end',
    label: t`End`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '7em',
    fieldName: 'end',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'timeSeriesCompaction',
    label: t`Meter data source`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '10em',
    fieldName: 'timeSeriesCompaction',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'ignorePeriodOfOperation',
    label: t`Period of operation`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.BOOLEAN,
    searchable: false,
    sortable: false,
    width: '12em',
    fieldName: 'ignorePeriodOfOperation',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'callbackUrl',
    label: t`Callback URL`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '7em',
    fieldName: 'callbackUrl',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'reason',
    label: t`Reason`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '10em',
    fieldName: 'reason',
    isEditable: false,
    fixed: false,
  },
]

export const transformRunningBackcastsItemsForTable = (items: RunningBackcastsType) => {
  const tableItems: singleRunningBackcastType[] = []

  if (items[RunningBackcastsStatus.IN_PROGRESS]) {
    items[RunningBackcastsStatus.IN_PROGRESS].forEach((singleEl, index) => {
      const obj = {
        ...singleEl,
        status: RunningBackcastsStatus.IN_PROGRESS,
        id: RunningBackcastsStatus.IN_PROGRESS + index, // this is used only in UI , for handling reTable properly, because reTable required ID for each row.
      }
      tableItems.push(obj)
    })
  }

  if (items[RunningBackcastsStatus.QUEUED]) {
    items[RunningBackcastsStatus.QUEUED].forEach((singleEl, index) => {
      const obj = { ...singleEl, status: RunningBackcastsStatus.QUEUED, id: RunningBackcastsStatus.QUEUED + index } // this is used only in UI , for handling reTable properly, because reTable required ID for each row.
      tableItems.push(obj)
    })
  }

  return tableItems
}

export const prepareRunningBackcastObj = (item: singleRunningBackcastType) => {
  const copyItem = { ...item }
  delete copyItem.id
  delete copyItem.status
  delete copyItem.uiHasMatchingDescendants
  delete copyItem.uiHasMatchingParents
  delete copyItem.uiIsMatching

  return copyItem
}
