import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  AutocompleteGetTagProps,
  AutocompleteProps,
  AutocompleteRenderInputParams,
} from '@material-ui/lab'
import { Box, Chip, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { FieldInputProps, FieldMetaState } from 'react-final-form'
import { rgba } from 'polished'
import { colors } from 'themes/theme-light'
import { ErrorMsg } from 'ui/form/assetForm.style'

const StyledAutocomplete = styled(Autocomplete)<AutocompleteProps<AutocompleteItem>>`
  & .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0px;
  }
`

interface StyledTextFieldProps {
  highlight_required_field: number
}

const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  &.MuiTextField-root {
    background-color: ${(props) => (props.highlight_required_field ? rgba(colors.primaryMain, 0.07) : 'inherit')};
  }
`

const ItemChip = styled(Chip)`
  margin: 5px;
`

const ErrorMsgContainer = styled.div`
  margin-bottom: -15px;
`

export interface AutocompleteItem {
  id: string
  name?: string
  label?: string
  ui?: {
    fixed?: boolean
    [x: string]: any
  }
  [x: string]: any
}

interface FinalFormAutocompleteFieldProps {
  input: FieldInputProps<any, HTMLElement>
  meta: FieldMetaState<any>
  selectedIds: string[]
  availableItems: AutocompleteItem[]
  multiple?: boolean
  disabled?: boolean
  onRedirect?: (item: any) => void
  placeHolder?: string
  required?: boolean
  formDirty?: boolean
  inputFieldWidth?: string
}

const FinalFormAutocompleteField: React.FC<FinalFormAutocompleteFieldProps> = ({
  selectedIds = [],
  availableItems,
  multiple,
  disabled = false,
  input,
  meta,
  onRedirect,
  placeHolder,
  required,
  formDirty,
  inputFieldWidth,
}) => {
  const [fieldTouched, setFieldTouched] = useState(false)
  const emptyValue = multiple ? [] : null
  const [selectedItems, setSelectedItems] = useState<AutocompleteItem[] | AutocompleteItem | null | undefined>(
    undefined,
  )

  const fixedItems = useMemo(() => availableItems.filter((item) => item?.ui?.fixed), [availableItems])
  const fixedItemIds = useMemo(() => fixedItems.map((item) => item.id), [fixedItems])

  const handleItemsChange = useCallback(
    (event: React.ChangeEvent, newItems: AutocompleteItem[] | AutocompleteItem) => {
      let ids: string[] = []
      if (Array.isArray(newItems)) {
        ids = newItems.map((item) => item.id)
        setSelectedItems([...fixedItems, ...newItems.filter((item) => !fixedItemIds.includes(item.id))])
      } else if (newItems?.id) {
        ids = [newItems.id]
        setSelectedItems(newItems)
      } else {
        setSelectedItems(emptyValue)
      }
      input.onChange(ids)
    },
    [fixedItems, fixedItemIds],
  )

  useEffect(() => {
    let selectedItemData: AutocompleteItem[] = []
    if (availableItems.length) {
      selectedItemData = (selectedIds || [])
        .map((id) => availableItems.find((a) => a.id === id))
        .filter((a) => typeof a !== 'undefined' && !a?.ui?.fixed)
      if (multiple) {
        setSelectedItems([...fixedItems, ...(selectedItemData || emptyValue)])
      } else {
        setSelectedItems(selectedItemData.length > 0 ? selectedItemData[0] : emptyValue)
      }
    }
  }, [selectedIds.join(','), multiple, JSON.stringify(availableItems), fixedItems])

  // hooks make availableItems and selectedItems out of sync
  // therefore we need to check if the selection is valid
  // note: this can't be memoized
  const selectionValid =
    typeof selectedItems !== 'undefined' &&
    !(Array.isArray(selectedItems || []) ? selectedItems || [] : [selectedItems]).some(
      (item: AutocompleteItem) => !availableItems.map((a) => a.id).includes(item.id),
    ) &&
    ((multiple && Array.isArray(selectedItems)) || (!multiple && !Array.isArray(selectedItems)))

  return (
    <Box width={inputFieldWidth ? inputFieldWidth : 'inherit'}>
      {selectionValid && (
        <StyledAutocomplete
          options={availableItems}
          value={selectedItems}
          multiple={multiple}
          disabled={disabled}
          onChange={handleItemsChange}
          // filterSelectedOptions={true}
          // filterOptions={(options: AutocompleteItem[]) => options.filter((option) => !option?.ui?.fixed)}
          getOptionLabel={(option: AutocompleteItem) => option?.name || option?.label || ''}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <StyledTextField
              highlight_required_field={required ? 1 : 0}
              placeholder={placeHolder}
              {...params}
              margin="normal"
              fullWidth
              onClick={() => setFieldTouched(true)}
            />
          )}
          renderTags={(value: AutocompleteItem[], getTagProps: AutocompleteGetTagProps) =>
            value.map((item, index) => (
              <ItemChip
                key={item.id}
                {...getTagProps({ index })}
                label={item.name || item.label}
                disabled={fixedItemIds.includes(item.id)}
                onClick={() => (onRedirect ? onRedirect(item) : null)}
              />
            ))
          }
        />
      )}

      {meta?.error && (formDirty || fieldTouched) && (
        <ErrorMsgContainer>
          <ErrorMsg>{meta.error || ''}</ErrorMsg>
        </ErrorMsgContainer>
      )}
    </Box>
  )
}

export default React.memo(FinalFormAutocompleteField)
