import React from 'react'
import { c } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import PopperTooltip from 'ui/PopperTooltip'

interface PenaltyRegulationSharedIconProps {
  shared: boolean
}

const PenaltyRegulationSharedIcon: React.FC<PenaltyRegulationSharedIconProps> = ({ shared }) => {
  return (
    <PopperTooltip
      popperLabel={<FontAwesomeActionIcon size="sm" icon={shared ? 'globe' : 'user'} />}
      popperContent={
        shared ? (
          <>{c('PenaltyRegulation')
            .t`Global regulation which is visible to everyone. Can only be modified by admins.`}</>
        ) : (
          <>{c('PenaltyRegulation').t`This regulation is yours and not shared with other users.`}</>
        )
      }
    />
  )
}

export default React.memo(PenaltyRegulationSharedIcon)
