import { useQuery } from 'react-query'
import axios from 'axios'

import { MetaData, MetaDataHierarchy } from 'modules/dataStreams/dataStreams.types'
import { apiRequest, queryOptionsForStaticData } from 'utils/request'
import { transformMetaDataHierarchy } from 'utils/dataStream'

// Query keys for caching data

export const QUERY_KEY_META_DATA_PARAMS = 'dataStreams:metaData:params'
export const QUERY_KEY_META_DATA_HIERARCHY = 'dataStreams:metaData:hierarchy'

// Async API requests to fetch and update data

const getMetaDataParams = async () => {
  return await apiRequest<MetaData>(() => axios.get('/api/availdecisionparams'))
}

const getMetaDataHierarchy = async () => {
  const hierarchyData = await apiRequest<MetaDataHierarchy>(() => axios.get('/api/signalmenu'))
  const transformedData = transformMetaDataHierarchy(hierarchyData)
  return transformedData
}

// Hooks to fetch and update via react-query

export const useMetaDataParams = () => {
  return useQuery<MetaData>(QUERY_KEY_META_DATA_PARAMS, getMetaDataParams, queryOptionsForStaticData)
}

export const useMetaDataHierarchy = () => {
  return useQuery<MetaDataHierarchy>(QUERY_KEY_META_DATA_HIERARCHY, getMetaDataHierarchy, queryOptionsForStaticData)
}
