import React from 'react'
import { EnrichedForecastModelItem, EnrichedQualityEvaluationItem } from 'utils/forecastModel'
import { ForecastModel, QualityEvaluation } from 'modules/quality/quality.types'
import { t } from 'ttag'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'

interface ForecastModelAndEvaluationReasonProps {
  forecastModel: EnrichedForecastModelItem | ForecastModel
  qualityEvaluation: EnrichedQualityEvaluationItem | QualityEvaluation | null
}

const ForecastModelAndEvaluationReason: React.FC<ForecastModelAndEvaluationReasonProps> = ({
  forecastModel,
  qualityEvaluation,
}) => {
  const evaluationReason = qualityEvaluation ? qualityEvaluation?.reason : ''
  const modelReason = forecastModel ? forecastModel?.reason : ''

  const evaluationReasonLabels = {
    MANUAL_EVALUATION: t`Manual`,
    TRAINING_EVALUATION: t`Training performed`,
    PERIODIC_EVALUATION: t`Periodic`,
    ENSEMBLE_WEIGHTS_EVALUATION: t`Ensemble weights`,
    WEIGHT_OPTIMIZATION_EVALUATION: 'Training performed',
    WEIGHT_OPTIMIZATION_REQUEST: t`Training performed`,
  }

  const modelReasonLabels = {
    ASSET_CREATED: t`Asset created`,
    ASSET_UPDATED: t`Asset updated`,
    TRAINING_FINISHED: t`Training performed`,
    E3_REQUESTED: t`Backcast requested`,
    MASTER_DATA_UPDATE: t`Asset created`,
    SOLAR_CALIBRATION_RAW_BACKCAST: t`Raw forecast`,
    SOLAR_CALIBRATION_RESULT: t`Training performed`,
    CREATED_BY_MIGRATION: t`Migration from V1`,
    WEIGHT_OPTIMIZATION_REQUEST: t`Training performed`,
  }

  let reasonToDisplay = ''
  if (qualityEvaluation && evaluationReason) {
    reasonToDisplay = evaluationReasonLabels[evaluationReason]
  } else if (modelReason) {
    reasonToDisplay = modelReasonLabels[modelReason]
  }

  // display reason from model if evaluation reason and model reasons belongs to TRAINING
  // if (evaluationReason === 'TRAINING_EVALUATION' && modelReason === 'TRAINING_FINISHED') {
  //   reasonToDisplay = modelReasonLabels[modelReason]
  // }

  return (
    <FixedWidthTextContainer width={'10em'} text={reasonToDisplay}>
      {reasonToDisplay || ''}
    </FixedWidthTextContainer>
  )
  return <></>
}

export default ForecastModelAndEvaluationReason
