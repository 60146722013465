import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Asset,
  BaseGenerator,
  RELATION_TYPE_CLUSTER,
  RELATION_TYPE_NONE,
  RELATION_TYPE_PARK,
  UiClusterTypes,
} from 'modules/asset/store/asset.types'
import { FormApi } from 'final-form'
import { getTypeLabel, isCHP, isCluster, isGenerator, isPark, isSolarPark, isWindPark, isWindPlant } from 'utils/asset'
import { Box } from '@material-ui/core'
import { AssetAutocompleteContainer, ErrorMsg, FormValue } from 'ui/form/assetForm.style'
import { jt } from 'ttag'
import FullWidth from 'ui/FullWidth'
import AssetAutocomplete from 'ui/elements/AssetAutocomplete'

interface ParentRelationProps {
  allAssets: Asset[]
  asset: Asset
  formState: Asset
  form: FormApi<Asset>
  enterManually: boolean
  formErrors: any
  onSetFormState?: (asset: Asset) => void
  onRelationChange: () => void
}

const ParentRelation: React.FC<ParentRelationProps> = ({
  asset,
  allAssets,
  formState,
  form,
  enterManually,
  formErrors,
  onRelationChange,
}) => {
  const [plantBelongsTo, setPlantBelongsTo] = useState('')
  const assetType = getTypeLabel(formState?.type)
  const [parentAssetList, setParentAssetList] = useState<Asset[]>([])

  // console.log({ formErrors })

  const allClusters = allAssets.filter((a) => isCluster(a) && !a.deleted)
  const allClusterIds = allClusters?.map((c) => c.id)
  const allParkIds = allAssets.filter((a) => isPark(a) && !a.deleted)?.map((p) => p.id)

  const parentSelectedIds = useMemo(() => {
    const plant = asset as BaseGenerator
    let selectedIds: string[] = []
    if (asset.id) {
      const clusterIds = plant.clusterIds || []
      const parkIds = plant.uiParkIds || []
      selectedIds = [...clusterIds, ...parkIds]
    }
    return selectedIds
  }, [asset, enterManually])

  const setAssetParents = useCallback(() => {
    if (isGenerator(formState) && !isCHP(formState)) {
      const windParks = allAssets.filter((a) => isWindPark(a) && !a.deleted)
      const solarParks = allAssets.filter((a) => isSolarPark(a) && !a.deleted)
      const parks = isWindPlant(asset) ? windParks : solarParks
      const assets = [...allClusters, ...parks]
      setParentAssetList(assets)
    } else if (isCHP(formState)) {
      const clusters = allClusters.filter((c) => c.uiAssetType == UiClusterTypes.CLUSTER)
      const pools = allClusters.filter((c) => c.uiAssetType == UiClusterTypes.CLUSTER_POOL)

      const assets = [...clusters, ...pools]
      setParentAssetList(assets)
    } else if (isPark(formState)) {
      setParentAssetList(allClusters)
    }
  }, [allAssets, formState, JSON.stringify(allClusters)])

  const initPlantBelongsTo = (value: string) => {
    setPlantBelongsTo(value)
    form.mutators.setValue('plantBelongsTo', value)
    if (value === RELATION_TYPE_NONE) {
      form.mutators.setValue('clusterIds', [])
      form.mutators.setValue('uiParkIds', [])
    }
    setAssetParents()
  }

  const updateParentSelection = useCallback(
    (ids: string[]) => {
      const selectedParkIds = ids.filter((id) => allParkIds.includes(id))
      const selectedClusterIds = ids.filter((id) => allClusterIds.includes(id))
      form.mutators.setValue('uiParkIds', selectedParkIds.length ? selectedParkIds : [])
      form.mutators.setValue('clusterIds', selectedClusterIds.length ? selectedClusterIds : [])
    },
    [form, plantBelongsTo, allParkIds, allClusterIds],
  )

  const handleParentSelection = useCallback(
    (ids: string[]) => {
      updateParentSelection(ids)
      onRelationChange()
    },
    [updateParentSelection],
  )

  const handleValidate = (selectedAssets: string[], selectChild: boolean) => {
    if (!selectChild) {
      updateParentSelection(selectedAssets)
    }
  }

  useEffect(() => {
    if (formState?.id) {
      initPlantBelongsTo(
        (isPark(formState) || isGenerator(formState)) && (formState.clusterIds || []).length > 0
          ? RELATION_TYPE_CLUSTER
          : isGenerator(formState) && formState?.uiParkIds?.length
          ? RELATION_TYPE_PARK
          : RELATION_TYPE_NONE,
      )
    } else {
      initPlantBelongsTo(RELATION_TYPE_NONE)
    }
  }, [formState?.id])

  useEffect(() => {
    if (isPark(formState)) {
      setAssetParents()
    } else if (isGenerator(formState)) {
      setAssetParents()
    } else if (isCHP(formState)) {
      setAssetParents()
    }
  }, [formState, formState?.type, setAssetParents])

  return (
    <>
      {(isPark(formState) || isGenerator(formState)) && (
        <Box>
          <Box mr={1} mb={1}>{jt`This ${assetType} belongs to:`}</Box>
          <FormValue style={{ maxWidth: 'none' }} highlightRequired>
            <AssetAutocompleteContainer direction="row" alignItems="flex-end">
              {!isCluster(formState) && (
                <FullWidth>
                  <AssetAutocomplete
                    onAssetSelect={handleParentSelection}
                    onValidate={handleValidate}
                    selectedIds={parentSelectedIds || []}
                    autocompleteAssetList={parentAssetList || []}
                    selectChild={false}
                    multiple={true}
                  />
                  {formErrors.parentRelation && <ErrorMsg>{formErrors.parentRelation}</ErrorMsg>}
                </FullWidth>
              )}
            </AssetAutocompleteContainer>
          </FormValue>
        </Box>
      )}
    </>
  )
}

export default ParentRelation
