import React from 'react'
import { getDataStreamTypeQueryLabel } from 'utils/dataStream'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { c } from 'ttag'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { appTourColors } from 'modules/app/tour/OnBoardingTourDialog'
import { MetaForecastWidgetType } from 'modules/workspace/store/workspace.types'

const InfoContainer = styled(Flex)`
  color: ${appTourColors.darkBlue};
  padding: 0.5em 0;
`

interface MetaForecastWidgetMessageProps {
  invalidNumberOfSelection: boolean
  typeOfModel: MetaForecastWidgetType
  dataStreamNotSelected: boolean
}

const MetaForecastWidgetMessage: React.FC<MetaForecastWidgetMessageProps> = ({
  invalidNumberOfSelection,
  typeOfModel,
  dataStreamNotSelected,
}) => {
  const dataStreamCategoryName = getDataStreamTypeQueryLabel(typeOfModel)?.label || ''
  const modelName = typeOfModel === TimeSeriesSubType.E3_WEATHER_TRACK ? c('e³').t`e³` : c('e³').t`Meta forecast`
  const e3WeatherTrackMessage = c('e³')
    .jt`Select at least one ${dataStreamCategoryName} data stream to create an ${modelName} model.`
  const metaForecastMessage = c('e³')
    .jt`Select at least one ${dataStreamCategoryName} data stream to create a ${modelName} model.`

  const selectedDataStreamErrorMessage = dataStreamNotSelected
    ? typeOfModel === TimeSeriesSubType.E3_WEATHER_TRACK
      ? e3WeatherTrackMessage
      : metaForecastMessage
    : ''

  const e3ForecastSelectedAssetsErrorMessage = c('e³')
    .jt`To create an ${modelName} model, please select a single asset.`
  const metaForecastSelectedAssetsErrorMessage = c('e³')
    .jt`To create a ${modelName} model, please select a single asset.`
  const selectedAssetsErrorMessage = invalidNumberOfSelection
    ? typeOfModel === TimeSeriesSubType.E3_WEATHER_TRACK
      ? e3ForecastSelectedAssetsErrorMessage
      : metaForecastSelectedAssetsErrorMessage
    : ''

  const displayMessage = selectedAssetsErrorMessage || selectedDataStreamErrorMessage

  return (
    <InfoContainer direction="column" justifyContent="center" alignItems="center">
      {displayMessage}
    </InfoContainer>
  )
}

export default MetaForecastWidgetMessage
