import styled from 'styled-components'

export interface LabelWithoutWrapProps {
  color?: string | null
}

const LabelWithoutWrap = styled.div<LabelWithoutWrapProps>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${(props) => props.color};
  width: 100%;
`

export default LabelWithoutWrap
