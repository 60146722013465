import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Joyride from 'react-joyride'
import { useAppTourSettings, useAppTourSettingsSaveMutation } from 'modules/app/tour/appTour.api'
import { isNumeric } from 'utils/dataFormatting'
import { AppTours, AppTourStep } from 'modules/app/tour/appTour.types'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useDeliveryTargetsTableItems } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import { useAllAssets } from 'modules/asset/api/assets.api'
import styled from 'styled-components'
import { useRouter } from 'utils/route'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import { useDraftChangeCounts } from 'modules/asset/api/metadata.api'
import { DeliveryUsed, useAtLeastOneDeliveryIsUsed } from 'modules/app/tour/appTourUtils'

export const AppTourColour = '#BBD1FF'

export const TourStepContent = styled.div`
  font-size: 14px;
  text-align: left;
`

export const TourUnOrderedList = styled.ul`
  padding: 0;
  padding-left: 16px;
  margin: 0;
`

export const TourListItem = styled.li`
  margin: 6px 0;
  text-align: left;
  &:last-child {
    margin-bottom: 0;
  }
`

interface AppTourProps {
  children: React.ReactNode
}

export interface AppTourContextProps {
  addTourSteps: (steps: AppTourStep[], stepIndex?: number) => void
  removeTourSteps: (appTourContext: AppTours) => void
  resumeTour: () => void
  isTourActive: boolean
  currentTour?: AppTours | null
  currentSteps?: AppTourStep[]
  isTourPaused?: boolean
}

export const AppTourContext = React.createContext<AppTourContextProps>({})

const AppTour: React.FC<AppTourProps> = ({ children }) => {
  const appTourSettings = useAppTourSettings()
  const saveAppTourSettingsResult = useAppTourSettingsSaveMutation()
  const saveAppTourSettingsMutation = saveAppTourSettingsResult.mutate
  const [currentTour, setCurrentTour] = useState<AppTours | null>(null)
  const [pauseTour, setPauseTour] = useState<boolean>(false)
  const { pendingCount } = useDraftChangeCounts()

  const { history } = useRouter()

  const assetsResult = useAllAssets()
  const siteForecastsResults = useSiteForecastConfigs()
  const deliveryTargetResult = useDeliveryTargetsTableItems()

  const [tourData, setTourData] = useState({ run: false, steps: [], stepIndex: null })

  const isTourEnabled = useMemo(() => appTourSettings?.data?.enableTour, [appTourSettings?.data])
  const showOnBoardingDialog = useMemo(() => appTourSettings?.data?.startOnBoardingTour, [appTourSettings?.data])
  const isTourEndByUser = useMemo(() => appTourSettings?.data?.endOnBoardingTour, [appTourSettings?.data])

  const hideTour = useCallback(() => {
    saveAppTourSettingsMutation({
      startOnBoardingTour: showOnBoardingDialog,
      enableTour: false,
    })
    setPauseTour(true)
    setTourData((tourData) => {
      return { ...tourData, run: false }
    })
  }, [showOnBoardingDialog])

  const handleJoyrideCallback = useCallback(
    (data) => {
      // const { action, index, status, type } = data
      const { action } = data
      if (data.step) {
        setCurrentTour(data.step.tourContext)
      }
      //[STATUS.FINISHED].includes(status)

      if (action === 'stop' || action === 'close') {
        hideTour()
      }
      // else if (([EVENTS.STEP_AFTER] as string[]).includes(type)) {
      //   const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1)
      //   console.log('step index in joyride =', stepIndex)
      //   setTourData((tourData) => {
      //     return { ...tourData, run: true, stepIndex: stepIndex }
      //   })
      // }

      // console.log({ action, index, status, type, controlled: data.controlled })
    },
    [hideTour],
  )

  const appTourHandler: AppTourContextProps = useMemo(() => {
    return {
      addTourSteps: (appSpecificSteps) => {
        setTimeout(() => {
          setTourData((prevState) => {
            const stepsAlreadyExist = (prevState?.steps || []).some((prevStep) =>
              appSpecificSteps.some((newStep) => prevStep?.target === newStep.target),
            )
            if (stepsAlreadyExist) {
              const stepIndex = prevState?.steps.findIndex(
                (step: AppTourStep) => step.target == appSpecificSteps[0].target,
              )
              if (prevState.stepIndex !== stepIndex) {
                return { ...prevState, stepIndex: stepIndex }
              } else return prevState
            } else {
              const newIndex =
                prevState.stepIndex !== null && isNumeric(prevState.stepIndex) ? prevState?.stepIndex + 1 : 0
              const newSteps = [...(prevState.steps || []), ...appSpecificSteps]
              return {
                ...prevState,
                run: true,
                steps: newSteps,
                stepIndex: newSteps?.length === 1 ? 0 : newIndex,
              }
            }
          })
        }, 500)
      },
      removeTourSteps: () => {
        setTourData({})
      },
      resumeTour: () => {
        setPauseTour(false)
      },
      isTourPaused: pauseTour,
      isTourActive: isTourEnabled,
      currentTour: currentTour,
    }
  }, [isTourEnabled, currentTour, pauseTour])

  useEffect(() => {
    if (isTourEnabled) {
      setTourData({ ...tourData, run: true })
    }
  }, [isTourEnabled])

  const atAtLeastOneDeliveryAddedToSiteForecast = useAtLeastOneDeliveryIsUsed()
  const startOnBoardingTour = useMemo(() => {
    if (appTourSettings?.isSuccess) {
      if (isTourEndByUser) return false
      if (siteForecastsResults?.isSuccess && deliveryTargetResult?.isSuccess && assetsResult?.isSuccess) {
        return (
          !assetsResult?.data.length ||
          (assetsResult?.data.length == 1 && pendingCount > 0) ||
          siteForecastsResults?.data?.length == 1 ||
          deliveryTargetResult?.data?.length == 1 ||
          atAtLeastOneDeliveryAddedToSiteForecast === DeliveryUsed.NO
        )
      }
    }
  }, [
    assetsResult?.isSuccess,
    JSON.stringify(assetsResult?.data),
    siteForecastsResults?.isSuccess,
    JSON.stringify(siteForecastsResults?.data),
    deliveryTargetResult?.isSuccess,
    JSON.stringify(deliveryTargetResult?.data),
    isTourEndByUser,
    pendingCount,
    atAtLeastOneDeliveryAddedToSiteForecast,
    appTourSettings?.isSuccess,
  ])

  // Set on boarding tour based on the user data in the portal
  // Check whether user has created asset, site forecast and delivery target and assigned to a site forecast
  useEffect(() => {
    if (startOnBoardingTour && !isTourEndByUser) {
      history.push(ROUTE_WORKBENCH)
      saveAppTourSettingsMutation({ startOnBoardingTour: true, enableTour: false })
    }
  }, [startOnBoardingTour, isTourEndByUser])

  // console.log('tour data =', tourData )

  return (
    <AppTourContext.Provider value={{ ...appTourHandler, currentSteps: tourData?.steps }}>
      {tourData && tourData?.steps && isTourEnabled ? (
        <Joyride
          disableCloseOnEsc={true}
          disableOverlay={true}
          callback={handleJoyrideCallback}
          run={tourData?.run}
          steps={tourData?.steps}
          stepIndex={tourData?.stepIndex}
          continuous={true}
          showProgress={false}
          disableScrolling={currentTour ? currentTour !== AppTours.ADD_DELIVERY_TARGET_TO_SITE_FORECAST : true}
          styles={{
            options: {
              arrowColor: AppTourColour,
              backgroundColor: AppTourColour,
              textColor: 'black',
            },
          }}
        />
      ) : (
        <></>
      )}
      {children}
    </AppTourContext.Provider>
  )
}

export default React.memo(AppTour)
