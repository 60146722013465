import { t } from 'ttag'

export const getAreaIds = () => ({
  area_germany_aggregated: t`Germany - Wind and Solar`,
  area_germany_aggregated_einsman: t`Germany - Wind and Solar with Einsman`,
  area_germany_pv: t`Germany - Solar`,
  area_germany_pv_amprion_aggregated: t`Germany, TSO Amprion - Solar`,
  area_germany_pv_hz50_aggregated: t`Germany, TSO 50Hertz - Solar`,
  area_germany_pv_tennet_aggregated: t`Germany, TSO TenneT - Solar`,
  area_germany_pv_transnetbw_aggregated: t`Germany, TSO TransnetBW - Solar`,
  area_germany_wind: t`Germany - Wind`,
  area_germany_wind_aggregated: t`Germany - Wind, Onshore`,
  area_germany_wind_amprion_aggregated: t`Germany, TSO Amprion - Wind`,
  area_germany_wind_50hertz_onshore_offshore: t`Germany, TSO 50Hertz - Wind`,
  area_germany_wind_hz50_aggregated: t`Germany, TSO 50Hertz - Wind, Onshore`,
  area_germany_wind_offshore: t`Germany - Wind, Offshore`,
  area_germany_wind_offshore_50hertz: t`Germany, TSO 50Hertz - Wind, Offshore`,
  area_germany_wind_offshore_tennet: t`Germany, TSO TenneT - Wind, Offshore`,
  area_germany_wind_tennet_onshore_offshore: t`Germany, TSO TenneT - Wind`,
  area_germany_wind_tennet_aggregated: t`Germany, TSO TenneT - Wind, Onshore`,
  area_germany_wind_transnetbw_aggregated: t`Germany, TSO TransnetBW - Wind`,
  area_netherlands_pv: t`Netherland - Solar`,
  area_netherlands_wind: t`Netherland - Wind`,
  area_austria_wind: t`Austria - Wind`,
  area_belgium_wind: t`Belgium - Wind`,
  area_belgium_pv: t`Belgium - Solar`,
  area_germany_zip3_pv: t`Germany (ZIP3-Areas) - Solar`,
})

export type AREA_ID =
  | 'area_germany_aggregated'
  | 'area_germany_aggregated_einsman'
  | 'area_germany_pv'
  | 'area_germany_pv_amprion_aggregated'
  | 'area_germany_pv_hz50_aggregated'
  | 'area_germany_pv_tennet_aggregated'
  | 'area_germany_pv_transnetbw_aggregated'
  | 'area_germany_wind'
  | 'area_germany_wind_aggregated'
  | 'area_germany_wind_amprion_aggregated'
  | 'area_germany_wind_hz50_aggregated'
  | 'area_germany_wind_50hertz_onshore_offshore'
  | 'area_germany_wind_offshore'
  | 'area_germany_wind_offshore_50hertz'
  | 'area_germany_wind_offshore_tennet'
  | 'area_germany_wind_tennet_aggregated'
  | 'area_germany_wind_tennet_onshore_offshore'
  | 'area_germany_wind_transnetbw_aggregated'
  | 'area_netherlands_pv'
  | 'area_netherlands_wind'
  | 'area_austria_wind'
  | 'area_belgium_wind'
  | 'area_belgium_pv'
  | 'area_germany_zip3_pv'
