import React, { useMemo } from 'react'
import Flex from 'ui/styles/Flex'

import styled from 'styled-components'

import { Asset } from 'modules/asset/store/asset.types'
import { hasAllRoles, isAdmin, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useCustomMuiStyles } from 'themes/theme-light'

import { MetaForecastWidgetType } from 'modules/workspace/store/workspace.types'
import { TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

import { useTimeSeriesQueryResults } from 'modules/dataStreams/api/timeseries.api'
import SaveModelAction from 'modules/workspace/advancedChartWidgets/metaForecast/SaveModelAction'
import RetrieveModelWeightsAction from 'modules/workspace/advancedChartWidgets/metaForecast/RetrieveModelWeightsAction'
import { useMetaForecastData } from 'utils/hooks/useMetaForecastData'

const Actions = styled(Flex)`
  padding: 0.5em 0.5em 0.5em;
  margin-bottom: 0.5em;
  margin-top: 1.8em;
  width: fit-content;
`

interface E3OptimzationThirdPartyActionsProps {
  selectedAssets: Asset[]
  selectedModelIds: string[]
  hasAccessToThirdPartyForecast: boolean
  handleSetLoadSavedWeights: () => void
  selectedAssetsAndModelIds: string[]
  activeWidgetType: MetaForecastWidgetType
}

const MetaForecastActions: React.FC<E3OptimzationThirdPartyActionsProps> = ({
  selectedAssets,
  selectedModelIds,
  hasAccessToThirdPartyForecast,
  handleSetLoadSavedWeights,
  selectedAssetsAndModelIds,
  activeWidgetType,
}) => {
  const user = useSelector(getUserResultSelector)
  const classes = useCustomMuiStyles()
  const metaForecastWidgetData = useMetaForecastData()

  const ensembleWeights =
    metaForecastWidgetData.activeWidget === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST
      ? metaForecastWidgetData.thirdPartySliders
      : metaForecastWidgetData.weatherModelSliders

  // console.log(ensembleWeights)

  const timeSeriesQueryResults = useTimeSeriesQueryResults()

  const hasMetaForecastTimeSeries = useMemo(() => {
    if (timeSeriesQueryResults) {
      return timeSeriesQueryResults
        .filter((queryResult) => queryResult.data?.type === TimeSeriesType.META_FORECAST)
        .some((filteredResult) => Object.keys(filteredResult?.data?.data || {}).length > 0)
    } else return false
  }, [timeSeriesQueryResults])

  // console.log({ savedE3Weights, activeWidgetType })

  const invalidNumberOfSelection = selectedAssetsAndModelIds.length > 1 || !selectedAssetsAndModelIds.length

  return (
    <Actions
      className={
        (isAdmin(user) || isImpersonatedAdmin(user)) && !hasAllRoles(user, ['ROLE_E3_METAFORECAST', 'ROLE_E3_FORECAST'])
          ? classes.adminComponent
          : 'inherit'
      }
    >
      <SaveModelAction
        modelWeights={ensembleWeights}
        selectedAssets={selectedAssets}
        selectedModelIds={selectedModelIds}
        activeWidgetType={activeWidgetType}
        hasAccessToThirdPartyForecast={hasAccessToThirdPartyForecast}
        invalidNumberOfSelection={invalidNumberOfSelection}
        hasMetaForecastTimeSeries={hasMetaForecastTimeSeries}
      />

      <RetrieveModelWeightsAction
        handleSetLoadSavedWeights={handleSetLoadSavedWeights}
        activeWidgetType={activeWidgetType}
        modelWeights={ensembleWeights}
        invalidNumberOfSelection={invalidNumberOfSelection}
        selectedAssets={selectedAssets}
        selectedModelIds={selectedModelIds}
      />
    </Actions>
  )
}

export default MetaForecastActions
