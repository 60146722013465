import React, { useCallback, useState } from 'react'
import {
  DataStream,
  DataStreamSelectionItem,
  EPEX,
  GERMANY_REVENUE_SEARCH_KEY,
  TimeSeriesClassifier,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import styled from 'styled-components'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { Box, TableRowProps } from '@material-ui/core'
import { colors, getColor, theme } from 'themes/theme-light'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import Highlight from 'ui/Highlight'
import SidebarRowItem from 'ui/elements/SidebarRowItem'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_DATASTREAMS } from 'modules/reTable/reTable.types'
import Flex from 'ui/styles/Flex'
import { TreeDataStreamsSelectionOptions } from 'modules/asset/store/asset.types'
import DataStreamDataIndicator from 'modules/dataStreams/tree/DataStreamDataIndicator'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import {
  hasAnyAuthority,
  hasPermissionForEpexDataStreams,
  hasPermissionToCreateSchedule,
  isAdmin,
  isDemo,
  isImpersonatedAdmin,
  isIndianCustomer,
} from 'utils/user'
import { TDIndented } from 'ui/styles/table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'
import { getTooltipContentForDataStreamRow } from 'utils/dataStream'

interface DataStreamTableRowProps {
  colorindex?: number
  lightMode: number
  isAdminComponent?: boolean
  item: DataStream
}

const DataStreamTableRow = styled(ReTableRow)<DataStreamTableRowProps & TableRowProps>`
  &.MuiTableRow-root {
    background: ${(props) => (props.isAdminComponent ? colors.adminFunctionalityColor.light : 'inherit')};
  }

  &.MuiTableRow-root.Mui-selected .MuiTableCell-body {
    color: ${(props) => {
      if (props.item.subType === TimeSeriesSubType.META_ENSEMBLE && props.lightMode) {
        return 'white'
      }
      return !props.lightMode ? 'white' : 'inherit'
    }};
  }

  &.MuiTableRow-root.Mui-selected {
    background: ${(props) => getColor(props.colorindex, props.lightMode ? 0.8 : undefined)};
  }

  &.MuiTableRow-root.Mui-selected:hover {
    background: ${(props) => getColor(props.colorindex, props.lightMode ? 0.9 : 0.1)};
  }
`

interface SelectableFlexProps {
  detailsvisible: 'true' | 'false'
}
const SelectableFlex = styled(Flex)<SelectableFlexProps>`
  max-width: 100%;
  /*cursor: pointer;*/

  & .details-link {
    visibility: ${(props) => (props.detailsvisible === 'true' ? 'visible' : 'hidden')};
  }

  &:hover .details-link {
    visibility: visible;
  }
`

const DataStreamTableCell = styled(ReTableCell)`
  &.MuiTableCell-body:first-child {
    padding-left: 0 !important;
  }
  padding: 0 !important;
`

interface DataStreamRowProps {
  item: DataStream
  onSelect: (item: DataStreamSelectionItem, selectionOptions: TreeDataStreamsSelectionOptions) => void
  onDetails?: (item: DataStreamSelectionItem) => void
  detailsVisible: boolean
  lightMode: boolean
  isSelected: boolean
  index: number
  mouseDown: (index: number, event: any) => void
  mouseEnter: (index: number, event: any) => void
  mouseUp: (index: number, event: any) => void
  color?: number | string
  revenueRelatedDataStream?: any
}

const DataStreamNameWidth = '14em'

const DatastreamRow: React.FC<DataStreamRowProps> = ({
  item,
  onSelect,
  onDetails,
  detailsVisible,
  lightMode,
  isSelected,
  index,
  mouseDown,
  mouseUp,
  mouseEnter,
  color,
  revenueRelatedDataStream,
}) => {
  const user = useSelector(getUserResultSelector)
  const search = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_DATASTREAMS)
  // hover handling
  const [hovered, setHovered] = useState<boolean>(false)

  const handleMouseOver = useCallback(
    (event) => {
      mouseEnter(index, event)
      event.stopPropagation()
      setHovered(true)
    },
    [index],
  )

  const handleMouseDown = (event) => {
    mouseDown(index, event)
    event.stopPropagation()
  }

  const handleMouseUp = (event) => {
    mouseUp(index, event)
    event.stopPropagation()
  }

  const handleMouseLeave = useCallback((event) => {
    event.stopPropagation()
    setHovered(false)
  }, [])

  const handleDetails = useCallback(() => {
    if (onDetails) {
      onDetails(item)
    }
  }, [item, onDetails])

  // select item
  const handleSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      if (item.subType === TimeSeriesSubType.META_ENSEMBLE) {
        // Do nothing
        return
      }
      onSelect(
        {
          ...item,
          label: item.name,
          name: item.id as string, // TODO would be better to directly map name to name
        },
        { ctrlKey: event.ctrlKey || event.metaKey, shiftKey: event.shiftKey },
      )
    },
    [item, onSelect],
  )

  // const [lightModeDebounced] = useDebounce(lightMode, 200)

  const rowChildIsAdmin = useCallback(
    (item: DataStream) => {
      // console.log(item)
      if (item.type === TimeSeriesType.MARKET_PRICE_DATA) {
        if (item.subType === TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA) {
          if (item.id?.includes(EPEX)) {
            return (isAdmin(user) || isImpersonatedAdmin(user)) && !hasPermissionForEpexDataStreams(user)
          } else if (item.id?.includes('REVENUE')) {
            return (isAdmin(user) || isImpersonatedAdmin(user)) && !isDemo(user)
          }
        } else if (item.subType === TimeSeriesSubType.FRANCE_MARKET_PRICE_DATA) {
          if (item.classifier === TimeSeriesClassifier.FRANCE_EPEX_DAY_AHEAD_AUCTION) {
            return (isAdmin(user) || isImpersonatedAdmin(user)) && !isDemo(user)
          }
        } else if (item.subType === TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA) {
          return (isAdmin(user) || isImpersonatedAdmin(user)) && !isIndianCustomer(user)
        }
      } else if (item.type === TimeSeriesType.E3_META_FORECAST) {
        if (item.subType === TimeSeriesSubType.E3_WEATHER_TRACK) {
          return (isAdmin(user) || isImpersonatedAdmin(user)) && !hasAnyAuthority(user, ['ROLE_E3_FORECAST'])
        } else if (item.subType === TimeSeriesSubType.META_ENSEMBLE) {
          return (isAdmin(user) || isImpersonatedAdmin(user)) && !hasAnyAuthority(user, ['ROLE_E3_METAFORECAST'])
        }
      } else if (item.type === TimeSeriesType.WEATHER_DATA) {
        return (isAdmin(user) || isImpersonatedAdmin(user)) && !hasAnyAuthority(user, ['ROLE_WDA'])
      }
      return false
    },
    [item, user],
  )

  return (
    <DataStreamTableRow
      selected={isSelected}
      colorindex={color}
      lightMode={lightMode ? 1 : 0}
      item={item}
      isAdminComponent={rowChildIsAdmin(item)}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <DataStreamTableCell no_border={true} colSpan={2}>
        <SelectableFlex direction="row" detailsvisible={detailsVisible ? 'true' : 'false'} onClick={handleSelect}>
          <DataStreamDataIndicator dataStream={item} />
          <TDIndented level={item.uiLevel || 0}>
            <SidebarRowItem
              lightMode={isSelected && !detailsVisible}
              isVisible={hovered || detailsVisible}
              showIcon={
                item.type === TimeSeriesType.SITE_FORECAST ||
                (item.type === TimeSeriesType.SCHEDULE && hasPermissionToCreateSchedule(user))
              }
              onToggleDetails={handleDetails}
              isDataStreamRow={true}
            >
              <div style={{ display: 'flex' }}>
                <FixedWidthTextContainer
                  text={item.name}
                  width={
                    getTooltipContentForDataStreamRow(item.classifier, item.subType, revenueRelatedDataStream) &&
                    hovered
                      ? '13.5em'
                      : DataStreamNameWidth
                  }
                >
                  <Highlight
                    disableUserSelect={true}
                    text={item.name}
                    query={search}
                    hasFontStyle={item.classifier?.includes(GERMANY_REVENUE_SEARCH_KEY)}
                  />
                </FixedWidthTextContainer>
                {getTooltipContentForDataStreamRow(item.classifier, item.subType, revenueRelatedDataStream) && hovered && (
                  <div style={{ width: '1.5em', textAlign: 'center' }}>
                    <PopperTooltip
                      popperLabel={
                        <Box>
                          <FontAwesomeIcon
                            color={isSelected && !detailsVisible ? 'white' : theme.palette.primary.main}
                            icon="info"
                            size="sm"
                          />
                        </Box>
                      }
                      popperContent={
                        <div>
                          {getTooltipContentForDataStreamRow(item.classifier, item.subType, revenueRelatedDataStream)}
                        </div>
                      }
                    />
                  </div>
                )}
              </div>
            </SidebarRowItem>
          </TDIndented>
        </SelectableFlex>
      </DataStreamTableCell>
    </DataStreamTableRow>
  )
}

export default React.memo(DatastreamRow)
