import * as React from 'react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { Box, Button, FormControl, FormHelperText, MenuItem } from '@material-ui/core'
import styled from 'styled-components'
import { Checkbox, Select, TextField } from 'final-form-material-ui'
import { jt, t } from 'ttag'

import { testIdError } from 'modules/auth/AuthenticationForm.ids'
import { RegistrationType } from 'modules/auth/Auth.types'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import {
  registrationErrorSelector,
  registrationLoadingSelector,
  registrationSuccessSelector,
} from 'modules/auth/redux_store/state/registration'
import LoadingButton from 'ui/form/LoadingButton'
import Flex from 'ui/styles/Flex'
import {
  AuthActionButtonContainer,
  AuthFormContainer,
  AuthFormField,
  AuthFormTitle,
  CheckboxControl,
  CheckboxLabel,
} from 'ui/form/authForm.style'
import { LanguageKeys } from 'fixtures/header'
import { validateRegistrationForm } from 'utils/formValidations'
import { AuthForms, getLeadSource, getSalutations, LeadSourceIds } from 'fixtures/auth'
import ErrorMessage from 'ui/form/ErrorMessage'
import SuccessMessage from 'ui/form/SuccessMessage'
import PasswordField from 'ui/form/PasswordField'
import PasswordStrengthBar from 'ui/form/PasswordStrengthBar'
import AuthHelpText, { HelpTextContainer } from 'ui/elements/AuthHelpText'
import { transformUserDetailsBeforeSave } from 'utils/auth'
import { AuthContext } from 'modules/auth/AuthContainer'
import {
  resendConfirmationErrorSelector,
  resendConfirmationLoadingSelector,
  resendConfirmationResultSelector,
} from 'modules/auth/redux_store/state/resendConfirmation'
import { OnChange } from 'react-final-form-listeners'

export const HelperFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
  }
`

const TermsAndConditionErrorMsg = styled(FormHelperText)`
  &.MuiFormHelperText-root {
    color: red;
    position: inherit;
    margin-left: -4px;
    margin-top: -4px;
    height: 10px;
  }
`

interface RegistrationFormProps {
  onChangeActiveForm: (e: any, formType: AuthForms) => void
  onUpdateContextData: (data: RegistrationType) => void
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ onChangeActiveForm, onUpdateContextData }) => {
  const { english } = LanguageKeys
  const { login } = AuthForms

  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const dispatch = useDispatch()
  const loading = useSelector(registrationLoadingSelector)
  const error = useSelector(registrationErrorSelector)
  // TODO login already in use
  const success = useSelector(registrationSuccessSelector)
  const { authData } = useContext(AuthContext)

  //Resend Confirmation
  const [resendConfirmationSuccessState, setResendConfirmationSuccessState] = useState(false)
  const [resendConfirmationErrorState, setResendConfirmationErrorState] = useState('')
  const resendConfirmationSuccess = useSelector(resendConfirmationResultSelector)
  const resendConfirmationLoading = useSelector(resendConfirmationLoadingSelector)
  const resendConfirmationError = useSelector(resendConfirmationErrorSelector)

  // const regData: DeepPartial<RegistrationType> = {
  //   ackTermsAndConditions: false,
  //   langKey: english,
  // }
  const [regData, setRegData] = useState<RegistrationType>(authData as RegistrationType)

  const langKey = (localStorage.getItem('language') as keyof typeof LanguageKeys) || english

  // const dismissError = useCallback(() => dispatch({ type: actionTypes.REGISTRATION_DISMISS }), [])

  const handleFormSubmit = useCallback((regData: RegistrationType) => {
    regData = transformUserDetailsBeforeSave(regData)
    dispatch({ type: actionTypes.REGISTRATION_REQUEST, regData })
  }, [])

  const handleChangeActiveForm = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      onChangeActiveForm(event, login)
    },
    [onChangeActiveForm],
  )

  const resendConfirmationEmailHandler = () => {
    const data = {
      email: regData.email,
    }
    dispatch({ type: actionTypes.RESEND_CONFIRMATION_REQUEST, resendConfirmationData: data })
  }

  const salutations = useMemo(() => getSalutations(langKey), [langKey])

  const listOfLeadSources = useMemo(() => getLeadSource(), [])

  useEffect(() => {
    if (success) {
      setRegistrationSuccess(true)
    }
    dispatch({ type: actionTypes.CLEAR_REGISTRATION_STATE })
  }, [success])

  // 1.Handle Resend Confirmation success
  useEffect(() => {
    if (resendConfirmationSuccess) {
      setResendConfirmationSuccessState(resendConfirmationSuccess)
    }
    const timerSuccess = setTimeout(() => setResendConfirmationSuccessState(false), 3000)
    return () => {
      clearTimeout(timerSuccess)
      dispatch({ type: actionTypes.CLEAR_RESEND_CONFIRMATION_STATE })
    }
  }, [resendConfirmationSuccess])

  // 2.Handle Resend Confirmation error
  useEffect(() => {
    if (resendConfirmationError && !resendConfirmationLoading) {
      setResendConfirmationErrorState(resendConfirmationError)
    }
    const timerError = setTimeout(() => setResendConfirmationErrorState(''), 3000)
    return () => {
      clearTimeout(timerError)
      dispatch({ type: actionTypes.CLEAR_RESEND_CONFIRMATION_STATE })
    }
  }, [resendConfirmationError])

  const linkTerms = (
    <a
      key="termsAndConditionsLink"
      href="https://www.enercast.de/terms-and-conditions/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {t`Terms and conditions`}
    </a>
  )

  const linkPrivacy = (
    <a key="privacyPolicyLink" href="https://www.enercast.de/privacy-policy/" target="_blank" rel="noopener noreferrer">
      {t`Privacy policy`}
    </a>
  )

  const handleFormValuesChange = useCallback(
    (values) => {
      if (JSON.stringify(values) !== JSON.stringify(authData)) {
        onUpdateContextData(values)
      }
    },
    [authData, onUpdateContextData],
  )

  const linkLogin = <a key="signInLink" onClick={handleChangeActiveForm}>{t`Sign in`}</a>
  const resendConfirmationEmail = (
    <a
      key="resendConfirmationEmail"
      style={{ color: '#419e45' }}
      onClick={resendConfirmationEmailHandler}
    >{t`Send confirmation e-mail again`}</a>
  )
  const registeredEmail = <b>{regData.email}</b>

  useEffect(() => {
    setRegData(authData)
  }, [authData])

  return (
    <AuthFormContainer>
      <AuthFormTitle variant="h1">{t`Register a new enercast account`}</AuthFormTitle>
      {registrationSuccess && (
        <Flex direction="column">
          <Box mb={2}>
            <SuccessMessage
              successMsg={jt`Almost done! We sent a confirmation e-mail to ${registeredEmail}. Please check your e-mail and follow the instructions therein.`}
            />
          </Box>
          <Box>
            <AuthFormField>{resendConfirmationEmail}</AuthFormField>
          </Box>
          <Box>
            <Button color="primary" onClick={(e) => onChangeActiveForm(e, login)} variant="contained">
              {t`Back to login`}
            </Button>
          </Box>
          <Box mt={2}>
            {resendConfirmationSuccessState && (
              <SuccessMessage successMsg={t`Successfully re-sent a confirmation e-mail`} />
            )}
            {resendConfirmationErrorState.length > 0 && (
              <ErrorMessage color="black" errorMsg={resendConfirmationErrorState} />
            )}
          </Box>
        </Flex>
      )}
      {!registrationSuccess && (
        <Form
          onSubmit={handleFormSubmit}
          initialValues={regData}
          validate={validateRegistrationForm}
          render={({ handleSubmit, form, values, ...rest }) => {
            const activeField = form.getState().active

            return (
              <form
                onKeyUp={() => handleFormValuesChange(values)}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <HelpTextContainer>{activeField && <AuthHelpText activeField={activeField} />}</HelpTextContainer>

                <Flex direction="column">
                  <ErrorMessage
                    errorMsg={!loading && !form.getState().modifiedSinceLastSubmit && error ? error : ''}
                    data-testid={testIdError}
                  />
                  <Flex direction="column">
                    <AuthFormField>
                      <Field fullWidth name="login" component={TextField} label={t`enercast ID`} />
                    </AuthFormField>
                    <AuthFormField>
                      <PasswordField name="password" label={t`Password`} />
                      <PasswordStrengthBar value={values.password || ''} />
                    </AuthFormField>
                    <Flex direction="row" justifyContent="space-between" flexWrap="wrap">
                      <Flex flexBasis="19%">
                        <AuthFormField>
                          <HelperFormControl>
                            <Field name="salutation" component={Select} required fullWidth label={t`Salutation`}>
                              {salutations.map((salute) => (
                                <MenuItem key={salute.label} value={salute.id}>
                                  {salute.label}
                                </MenuItem>
                              ))}
                            </Field>
                            <OnChange name={`salutation`}>
                              {() => {
                                handleFormValuesChange(values)
                              }}
                            </OnChange>
                          </HelperFormControl>
                        </AuthFormField>
                      </Flex>
                      <Flex flexBasis="39%">
                        <AuthFormField>
                          <Field fullWidth name="firstName" component={TextField} label={t`First name`} />
                        </AuthFormField>
                      </Flex>
                      <Flex flexBasis="40%">
                        <AuthFormField>
                          <Field fullWidth name="lastName" component={TextField} label={t`Last name`} />
                        </AuthFormField>
                      </Flex>
                    </Flex>

                    <AuthFormField>
                      <Field fullWidth name="company" component={TextField} type="text" label={t`Company`} />
                    </AuthFormField>

                    <AuthFormField>
                      <Field fullWidth name="email" component={TextField} type="email" label={t`Email`} />
                    </AuthFormField>

                    <AuthFormField>
                      <HelperFormControl>
                        <Field
                          fullWidth
                          name="leadSource"
                          component={Select}
                          required
                          label={t`How did you hear about enercast?`}
                        >
                          {listOfLeadSources.map((source) => (
                            <MenuItem key={source.label} value={source.id}>
                              {source.label}
                            </MenuItem>
                          ))}
                        </Field>
                        <OnChange name={`leadSource`}>
                          {() => {
                            handleFormValuesChange(values)
                          }}
                        </OnChange>
                      </HelperFormControl>
                    </AuthFormField>
                    {authData?.leadSource && authData.leadSource === LeadSourceIds.OTHER && (
                      <AuthFormField>
                        <Field
                          fullWidth
                          name="leadSourceOther"
                          component={TextField}
                          type="text"
                          label={t`Please specify`}
                        />
                      </AuthFormField>
                    )}

                    <AuthFormField>
                      <Box ml={0.5} mt={1}>
                        <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
                          <CheckboxControl
                            label={''}
                            control={<Field name="ackTermsAndConditions" type="checkbox" component={Checkbox} />}
                          />
                          <CheckboxLabel>{jt`Yes, I accept ${linkTerms} and ${linkPrivacy}`}</CheckboxLabel>
                        </Flex>
                        <TermsAndConditionErrorMsg>
                          {rest.errors.ackTermsAndConditions && rest.submitFailed && <> {t`Required`}</>}
                        </TermsAndConditionErrorMsg>
                      </Box>
                    </AuthFormField>
                    <Flex direction="column">
                      <AuthFormField>
                        <AuthActionButtonContainer mb={1}>
                          <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
                            {t`Register`}
                          </LoadingButton>
                        </AuthActionButtonContainer>
                      </AuthFormField>
                      <AuthFormField>{jt`Already have an account? ${linkLogin}`}</AuthFormField>
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            )
          }}
        />
      )}
    </AuthFormContainer>
  )
}

export default React.memo(RegistrationForm)
