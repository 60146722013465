import * as actionTypes from 'modules/reTable/redux_store/reTable.action.types'
import {
  COLLAPSE_ACTION_TYPE,
  COLLAPSE_SEARCH_ACTION_TYPE,
  COLLAPSIBLE_IDS_ACTION_TYPE,
  FILTER_ACTION_TYPE,
  SEARCH_ACTION_TYPE,
  SELECTED_IDS_ACTION_TYPE,
  SORT_ACTION_TYPE,
  VIRTUAL_RANGE_ACTION_TYPE,
} from 'modules/reTable/redux_store/reTable.action.types'
import { Collapsed, Column, Columns, Filter, Filters, ReTableId, Search, Sort } from 'modules/reTable/reTable.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { VirtualRange } from 'ui/scroll/VirtualScroll'
import createReducer from 'utils/createReducer'
import { isJson } from 'utils/dataFormatting'

// state
export interface ViewState {
  collapsed: Record<string, Collapsed>
  collapsedSearch: Record<string, Collapsed>
  collapsibleIds: Record<string, string[]>
  columnsAvailable: Record<string, Columns>
  columnsSelected: Record<string, string[]>
  filteredItems: Record<string, string[]>
  filters: Record<string, Filters>
  search: Record<string, Search>
  selectedIds: Record<string, string[]>
  sort: Record<string, Sort>
  sortDefault: Record<string, Sort>
  virtualRange: Record<string, VirtualRange>
}

const initialState: ViewState = {
  collapsed: {},
  collapsedSearch: {},
  collapsibleIds: {},
  columnsAvailable: {},
  columnsSelected: {},
  filteredItems: {},
  filters: {},
  search: {},
  selectedIds: {},
  sort: {},
  sortDefault: {},
  virtualRange: {},
}

export const reTableInitialState = {
  collapsed: [],
  collapsedSearch: [],
  collapsibleIds: [],
  columnsAvailable: [],
  columnsSelected: [],
  filteredItems: [],
  filters: {},
  search: '',
  selectedIds: [],
  sort: { active: false },
  sortDefault: { active: false },
  virtualRange: {
    renderedItemCount: 0,
    start: 0,
    end: 0,
    startGap: 0,
    endGap: 0,
  },
}

// reducers

export interface CollapseAction {
  type: COLLAPSE_ACTION_TYPE
  table: ReTableId
  ids: string[]
}
const collapsed = createReducer<ViewState['collapsed'], CollapseAction>(
  (state = initialState.collapsed, { type, table, ids }) => {
    let s = state[table] || []
    if (type === actionTypes.RETABLE_INIT_TREE) {
      // init is the only collapse/expand action that does not trigger a backend call to save the current settings
      state[table] = ids
    } else if (type === actionTypes.RETABLE_TOGGLE_SUBTREE) {
      ids.forEach((id) => {
        s = s.includes(id) ? s.filter((collapsedId) => collapsedId !== id) : [...s, id]
      })
      state[table] = s
    } else if (type === actionTypes.RETABLE_EXPAND_SUBTREES || type === actionTypes.RETABLE_COLLAPSE_SUBTREES) {
      s = s.filter((id) => !ids.includes(id))
      if (type === actionTypes.RETABLE_COLLAPSE_SUBTREES) {
        s = [...s, ...ids]
      }
      state[table] = s
    }

    return state
  },
)

export interface CollapseSearchAction {
  type: COLLAPSE_SEARCH_ACTION_TYPE
  table: ReTableId
  ids: string[]
  search: string
}
const collapsedSearch = createReducer<ViewState['collapsedSearch'], CollapseSearchAction>(
  (state = initialState.collapsed, { type, table, ids, search }) => {
    let s = state[table] || []
    if (type === actionTypes.RETABLE_TOGGLE_SUBTREE_SEARCH) {
      ids.forEach((id) => {
        s = s.includes(id) ? s.filter((collapsedId) => collapsedId !== id) : [...s, id]
      })
      state[table] = s
    } else if (
      type === actionTypes.RETABLE_EXPAND_SUBTREES_SEARCH ||
      type === actionTypes.RETABLE_COLLAPSE_SUBTREES_SEARCH
    ) {
      s = s.filter((id) => !ids.includes(id))
      if (type === actionTypes.RETABLE_COLLAPSE_SUBTREES_SEARCH) {
        s = [...s, ...ids]
      }
      state[table] = s
    } else if (type === actionTypes.RETABLE_SEARCH && !search && s.length > 0) {
      // reset to expand everything when search is cleared
      state[table] = []
    }

    return state
  },
)

export interface CollapsibleIdsAction {
  type: COLLAPSIBLE_IDS_ACTION_TYPE
  table: ReTableId
  collapsibleIds: string[]
}
const collapsibleIds = createReducer<ViewState['collapsibleIds'], CollapsibleIdsAction>(
  (state = initialState.collapsibleIds, { type, table, collapsibleIds }) => {
    if (type === actionTypes.RETABLE_SET_COLLAPSIBLE_IDS) {
      state[table] = collapsibleIds
    }
    return state
  },
)

export interface ColumnsAvailableAction {
  type: actionTypes.COLUMNS_ACTION_TYPE
  table: ReTableId
  columnsAvailable: Column[]
}
const columnsAvailable = createReducer<ViewState['columnsAvailable'], ColumnsAvailableAction>(
  (state = initialState.columnsAvailable, { type, table, columnsAvailable }) => {
    if (type === actionTypes.RETABLE_SET_COLUMNS_AVAILABLE) {
      state[table] = columnsAvailable
    }
    return state
  },
)

export interface ColumnsSelectedAction {
  type: actionTypes.COLUMNS_ACTION_TYPE
  table: ReTableId
  columnsSelected: string[]
}
const columnsSelected = createReducer<ViewState['columnsSelected'], ColumnsSelectedAction>(
  (state = initialState.columnsSelected, { type, table, columnsSelected }) => {
    // TODO: added difference check because otherwise it stuck in infinite loop in Availabilities
    const isDifferent = isJson(state[table]) !== isJson(columnsSelected)
    const isDifferentJson =
      isJson(state[table]) &&
      isJson(columnsSelected) &&
      JSON.stringify(state[table]) !== JSON.stringify(columnsSelected)
    const needsUpdate = isDifferent || isDifferentJson
    if (
      type === actionTypes.RETABLE_INIT_COLUMNS_SELECTED ||
      (needsUpdate && type === actionTypes.RETABLE_SET_COLUMNS_SELECTED)
    ) {
      state[table] = columnsSelected
    }
    return state
  },
)

export interface FilteredItemsAction {
  type: actionTypes.FILTERED_ITEMS_ACTION_TYPE
  table: ReTableId
  itemIds: string[]
}
const filteredItems = createReducer<ViewState['filteredItems'], FilteredItemsAction>(
  (state = initialState.filteredItems, { type, table, itemIds }) => {
    if (type === actionTypes.RETABLE_SET_FILTERED_ITEMS) {
      state[table] = itemIds
    }
    return state
  },
)

export interface FilterAction {
  type: FILTER_ACTION_TYPE
  table: ReTableId
  key: string
  filter: Filter
}
const filters = createReducer<ViewState['filters'], FilterAction>(
  (state = initialState.filters, { type, table, key, filter }) => {
    if (type === actionTypes.RETABLE_SET_FILTER) {
      const s = state[table] || []
      state[table] = {
        ...s,
        [key]: filter,
      }
    }
    if (type === actionTypes.RETABLE_REMOVE_FILTER && state[table]) {
      delete state[table][key]
    }
    return state
  },
)

export interface SearchAction {
  type: SEARCH_ACTION_TYPE
  table: ReTableId
  search: string
}
const search = createReducer<ViewState['search'], SearchAction>(
  (state = initialState.search, { type, table, search }) => {
    if (type === actionTypes.RETABLE_SEARCH) {
      state[table] = search
    }
    return state
  },
)

export interface SelectedIdsAction {
  type: SELECTED_IDS_ACTION_TYPE
  table: ReTableId
  selectedIds: string
}
const selectedIds = createReducer<ViewState['search'], SelectedIdsAction>(
  (state = initialState.search, { type, table, selectedIds }) => {
    if (type === actionTypes.RETABLE_SET_SELECTED_IDS) {
      state[table] = selectedIds
    }
    return state
  },
)

export interface SortAction {
  type: SORT_ACTION_TYPE
  table: ReTableId
  sort: Sort
}
const sort = createReducer<ViewState['sort'], SortAction>((state = initialState.sort, { type, table, sort }) => {
  if (type === actionTypes.RETABLE_SET_SORT) {
    state[table] = { ...sort }
  }
  return state
})
const sortDefault = createReducer<ViewState['sortDefault'], SortAction>(
  (state = initialState.sortDefault, { type, table, sort }) => {
    if (type === actionTypes.RETABLE_SET_DEFAULT_SORT) {
      state[table] = { ...sort }
    }
    return state
  },
)

export interface VirtualRangeAction {
  type: VIRTUAL_RANGE_ACTION_TYPE
  table: ReTableId
  virtualRange: VirtualRange
}
const virtualRange = createReducer<ViewState['virtualRange'], VirtualRangeAction>(
  (state = initialState.virtualRange, { type, table, virtualRange }) => {
    if (type === actionTypes.RETABLE_SET_VIRTUAL_RANGE) {
      state[table] = virtualRange
    }
    return state
  },
)

export const viewReducer = combineReducers({
  collapsed,
  collapsedSearch,
  collapsibleIds,
  columnsAvailable,
  columnsSelected,
  filteredItems,
  filters,
  search,
  selectedIds,
  sort,
  sortDefault,
  virtualRange,
})

// selectors

export const reTableCollapsedSelector = createSelector<any, ViewState['collapsed'], ViewState['collapsed']>(
  (state) => state.reTable.view.collapsed,
  (collapsed) => collapsed,
)

export const reTableCollapsedSearchSelector = createSelector<
  any,
  ViewState['collapsedSearch'],
  ViewState['collapsedSearch']
>(
  (state) => state.reTable.view.collapsedSearch,
  (collapsedSearch) => collapsedSearch,
)

export const reTableCollapsibleIdsSelector = createSelector<
  any,
  ViewState['collapsibleIds'],
  ViewState['collapsibleIds']
>(
  (state) => state.reTable.view.collapsibleIds,
  (collapsibleIds) => collapsibleIds,
)

export const reTableColumnsAvailableSelector = createSelector<
  any,
  ViewState['columnsAvailable'],
  ViewState['columnsAvailable']
>(
  (state) => state.reTable.view.columnsAvailable,
  (columnsAvailable) => columnsAvailable,
)

// selected columns are just stored as list of strings
// therefore we need to filter available columns to get full column information
export const reTableColumnsSelectedSelector = createSelector<
  any,
  ViewState['columnsAvailable'],
  ViewState['columnsSelected'],
  ViewState['columnsAvailable']
>(
  reTableColumnsAvailableSelector,
  (state) => state.reTable.view.columnsSelected,
  (columnsAvailable, columnsSelected) => {
    return Object.keys(columnsAvailable).reduce((result, table) => {
      const ca = columnsAvailable[table] || []
      const cs = columnsSelected[table] || []
      const selectedColumnsForTable = ca.filter((column) => cs.includes(column.name))
      return {
        ...result,
        [table]: selectedColumnsForTable,
      }
    }, {})
  },
)

export const reTableFilteredItemsSelector = createSelector<any, ViewState['filteredItems'], ViewState['filteredItems']>(
  (state) => state.reTable.view.filteredItems,
  (filteredItems) => filteredItems,
)

export const reTableFiltersSelector = createSelector<any, ViewState['filters'], ViewState['filters']>(
  (state) => state.reTable.view.filters,
  (filters) => filters,
)

export const reTableSearchSelector = createSelector<any, ViewState['search'], ViewState['search']>(
  (state) => state.reTable.view.search,
  (search) => search,
)

export const reTableSelectedIdsSelector = createSelector<any, ViewState['selectedIds'], ViewState['selectedIds']>(
  (state) => state.reTable.view.selectedIds,
  (selectedIds) => selectedIds,
)

export const reTableSortSelector = createSelector<any, ViewState['sort'], ViewState['sort']>(
  (state) => state.reTable.view.sort,
  (sort) => sort,
)

export const reTableSortDefaultSelector = createSelector<any, ViewState['sort'], ViewState['sort']>(
  (state) => state.reTable.view.sortDefault,
  (sortDefault) => sortDefault,
)

export const reTableVirtualRangeSelector = createSelector<any, ViewState['virtualRange'], ViewState['virtualRange']>(
  (state) => state.reTable.view.virtualRange,
  (virtualRange) => virtualRange,
)
