import { useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { apiRequest, useOptimisticMutation } from 'utils/request'
import {
  transformSiteForecastDataBeforeSubmit,
  transformSiteForecastDataForForm,
} from 'utils/siteForecastConfiguration'
import { FormApi } from 'final-form'
import {
  QUERY_COPY_SITE_FORECAST_TEMPLATE,
  QUERY_DATA_STREAM_ID,
  QUERY_NEW_SITE_FORECAST_TEMPLATE,
  QUERY_SITE_FORECAST_TEMPLATE,
  useQueryString,
} from 'utils/query-string'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

// Query keys for caching data

export const QUERY_KEY_SITE_FORECAST_CONFIG_TEMPLATES = 'dataStreams:siteForecastConfigTemplates'

// Async API requests to fetch and update and delete data
const getSiteForecastConfigTemplates = async () => {
  return await apiRequest<ForecastConfig[]>(() => axios.get('/api/productconfig/v2/templates'))
}

const saveSiteForecastConfigTemplate = async (forecastConfig: ForecastConfig) => {
  let response
  const forecastConfigTemplate = transformSiteForecastDataBeforeSubmit(forecastConfig, true)
  if (forecastConfig.id) {
    response = await axios.put(`/api/productconfig/v2/templates/${forecastConfigTemplate.id}`, forecastConfigTemplate)
  } else {
    response = await axios.post('/api/productconfig/v2/templates', forecastConfigTemplate)
  }
  return response.data
}

const deleteSiteForecastConfigTemplate = async (templateId: string) => {
  return await apiRequest(() => axios.delete(`/api/productconfig/v2/templates/${templateId}`))
}

// Hooks to fetch and update via react-query

export const useSiteForecastConfigTemplates = () => {
  // fetch all
  return useQuery<ForecastConfig[]>(QUERY_KEY_SITE_FORECAST_CONFIG_TEMPLATES, getSiteForecastConfigTemplates)
}

export const useSiteForecastConfigTemplateSaveMutation = (formReference: FormApi<ForecastConfig>) => {
  // create or update one
  const forecastTemplates = useSiteForecastConfigTemplates()
  const { onDeleteQueryStrings } = useQueryString()
  const user = useSelector(getUserResultSelector)

  return useOptimisticMutation<ForecastConfig, ForecastConfig, ForecastConfig[] | undefined>({
    queryCacheKey: QUERY_KEY_SITE_FORECAST_CONFIG_TEMPLATES,
    apiMutator: saveSiteForecastConfigTemplate,
    cacheUpdater: (newValue, oldData) => {
      if (newValue?.id) {
        return (oldData || []).map((template) => (template.id === newValue.id ? newValue : template))
      } else {
        return oldData
      }
    },
    onSuccess: (data) => {
      if (forecastTemplates.isSuccess) {
        const forecastTemplateFormData = transformSiteForecastDataForForm(data, false, false, user)
        formReference.reset(forecastTemplateFormData)
        onDeleteQueryStrings([
          QUERY_SITE_FORECAST_TEMPLATE,
          QUERY_DATA_STREAM_ID,
          QUERY_COPY_SITE_FORECAST_TEMPLATE,
          QUERY_NEW_SITE_FORECAST_TEMPLATE,
        ])
      }
    },
  })
}

export const useSiteForecastConfigTemplateDeleteMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteSiteForecastConfigTemplate, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_SITE_FORECAST_CONFIG_TEMPLATES)
    },
  })
}
