import { c, t } from 'ttag'

export const tDeleteDialog = (contextHeading: string, contextText: string) => ({
  heading: c('Delete dialog').t`Delete ${contextHeading}`,
  text: c('Delete dialog').t`Do you want to delete this ${contextText}?`,
  confirmAction: c('Delete dialog').t`Yes`,
  cancelAction: c('Delete dialog').t`No`,
})

export const tUnsavedChangesDialog = () => ({
  heading: c('Unsaved changes dialog').t`Unsaved changes`,
  text1: c('Unsaved changes dialog').t`There are unsaved changes in: `,
  text2: c('Unsaved changes dialog').t`They will be discarded if you proceed.`,
  confirmAction: c('Unsaved changes dialog').t`Discard and proceed`,
  cancelAction: c('Unsaved changes dialog').t`Cancel`,
})

export const tConnectivityAndBanners = (sessionChangeTimeago: React.ReactNode) => ({
  actionOk: c('App:Connectivity').t`Ok`,
  actionRetry: c('App:Connectivity').t`Retry now`,
  actionReload: c('App:Connectivity').t`Reload`,
  actionCancel: c('App:Connectivity').t`Cancel`,
  textActionFailedOffline: c('App:Connectivity')
    .t`An action could not be completed because the connection was lost. Data shown on the current page may not be correct. Please check your internet connectivity, then reload.`,
  textActionFailedOnline: c('App:Connectivity')
    .t`An action could not be completed because the connection was lost. Data shown on the current page may not be correct. Please reload this page.`,
  textActionFailedExpired: c('App:Connectivity')
    .t`An action could not be completed because your session has expired. Data shown on the current page may not be correct. Please reload the page and sign in again.`,
  textExpired: c('App:Connectivity')
    .jt`Your session has expired ${sessionChangeTimeago}. Please reload the page and sign in again. If you have unsaved changes you may want to copy those before reloading.`,
  textOffline: c('App:Connectivity')
    .jt`Connection lost ${sessionChangeTimeago}. Please check your internet connectivity. We are trying to reconnect.`,
})

export enum AppEntityNamesForTranslation {
  PREPARE_SCHEDULE = 'PREPARE_SCHEDULE',
}
export const tGetEntityName = (entityName: AppEntityNamesForTranslation) => {
  const { PREPARE_SCHEDULE } = AppEntityNamesForTranslation
  switch (entityName) {
    case PREPARE_SCHEDULE:
      return t`Prepare schedule`
    default:
      return ''
  }
}
