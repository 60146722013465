import axios from 'axios'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'

import { Asset } from 'modules/asset/store/asset.types'
import { STAGE, STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { queryClient } from 'queryClient'
import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { flattenAndUniquifyAssetIds } from 'utils/asset'
import createReducer from 'utils/createReducer'
import { request } from 'utils/request'

// state

interface State {
  loading: boolean
  error: string | null
}

const initialState: State = {
  loading: false,
  error: null,
}

// types

export const SET_AUTODEPLOY_REQUEST = 'SET_AUTODEPLOY_REQUEST'
export const SET_AUTODEPLOY_SUCCESS = 'SET_AUTODEPLOY_SUCCESS'
export const SET_AUTODEPLOY_FAILURE = 'SET_AUTODEPLOY_FAILURE'
export const SET_AUTODEPLOY_DISMISS = 'SET_AUTODEPLOY_DISMISS'
export type SET_AUTODEPLOY_REQUEST = typeof SET_AUTODEPLOY_REQUEST
export type SET_AUTODEPLOY_SUCCESS = typeof SET_AUTODEPLOY_SUCCESS
export type SET_AUTODEPLOY_FAILURE = typeof SET_AUTODEPLOY_FAILURE
export type SET_AUTODEPLOY_DISMISS = typeof SET_AUTODEPLOY_DISMISS
export type SET_AUTODEPLOY_ACTION_TYPE =
  | SET_AUTODEPLOY_REQUEST
  | SET_AUTODEPLOY_SUCCESS
  | SET_AUTODEPLOY_FAILURE
  | SET_AUTODEPLOY_DISMISS

interface SetAutoDeployAction {
  type: SET_AUTODEPLOY_ACTION_TYPE
  // ERROR
  error: State['error']
  // REQUEST
  assets: Asset[]
  enabled: boolean
}

// reducers

const loading = createReducer<State['loading'], SetAutoDeployAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case SET_AUTODEPLOY_REQUEST:
      return true
    case SET_AUTODEPLOY_SUCCESS:
    case SET_AUTODEPLOY_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], SetAutoDeployAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case SET_AUTODEPLOY_SUCCESS:
    case SET_AUTODEPLOY_DISMISS:
      return null
    case SET_AUTODEPLOY_FAILURE:
      return error
  }
  return state
})

export const setAutoDeployReducer = combineReducers({
  loading,
  error,
})

export const setAutoDeployLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.asset.setAutoDeploy.loading,
  (loading) => loading,
)
export const setAutoDeployErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.asset.setAutoDeploy.error,
  (error) => error,
)

// api
export const setAutoDeploy = (assetIds: string[], enabled: boolean) => {
  const mode = enabled ? 'enable' : 'disable'
  return request(() => {
    return axios.post(`api/masterdata/autodeploy/${mode}`, assetIds)
  })
}

// sagas
const optimisticallyUpdateAutoDeploymentEnabled = (
  assetIds: string[],
  enabled: boolean,
  allAssets: Asset[],
  stage: STAGE,
) => {
  const updatedAssets = allAssets.map((asset) =>
    assetIds.includes(asset.id) ? { ...asset, autoDeploymentEnabled: enabled } : asset,
  )
  queryClient.setQueryData([QUERY_KEY_ASSETS, stage], updatedAssets)
}

export function* setAutoDeploySaga({ assets, enabled }: SetAutoDeployAction): SagaIterator {
  // TODO to get all assets we can't use hooks in sagas, so we need to find a better way than the following:
  const allAssetsProductive = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE])
  const allAssetsDraft = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT])
  const allAssets = ((allAssetsDraft || []).length > 0 ? allAssetsDraft : allAssetsProductive) || []

  const assetIdsToSet = flattenAndUniquifyAssetIds(assets, allAssets)
  optimisticallyUpdateAutoDeploymentEnabled(assetIdsToSet, enabled, allAssetsProductive || [], STAGE_PRODUCTIVE)
  optimisticallyUpdateAutoDeploymentEnabled(assetIdsToSet, enabled, allAssetsDraft || [], STAGE_DRAFT)

  const result = yield call(setAutoDeploy, assetIdsToSet, enabled)
  if (result.isSuccessful) {
    yield put({ type: SET_AUTODEPLOY_SUCCESS })
    queryClient.invalidateQueries(QUERY_KEY_ASSETS)
  } else {
    const error = result.getError()
    yield put({ type: SET_AUTODEPLOY_FAILURE, error })
  }
}
