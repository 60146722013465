export interface UserManagementType {
  id: number | string | undefined
  login: string | undefined
  email: string | undefined
  firstName?: string | undefined
  lastName?: string | undefined
  salutation?: string | undefined
  country?: string | undefined
  timezone?: string | undefined
  partnerName?: string | undefined
  companyAccounts?: string
  activated: boolean | undefined
  langKey: string | undefined
  ackTermsAndConditions: boolean | undefined
  authorities: string[] | undefined
  subUsers: string[]
  createdDate: string | Date | undefined
  createdBy?: string | undefined
  lastModifiedDate: string | Date | undefined
  lastModifiedBy: string | undefined
  uiUserStatus?: string
  uiUserStatusLabel?: string
  siteAssessmentCoordinateLimit?: number | undefined
  orderNumber?: string | undefined
  leadSource?: string | undefined
}

export interface UserManagementSaveMainItem {
  updateUserManagement: string
  createUserManagement: string
}

export enum UserStatus {
  ACTIVATED = 'Active',
  DEACTIVATED = 'Deactivated',
  EXPIRED = 'Expired',
}

export enum UserManagementTableActions {
  EDIT_USER = 'EDIT_USER',
  SYNCHRONIZE = 'SYNCHRONIZE',
  RESET_REVISION_TO_BASE = 'RESET_REVISION_TO_BASE',
  CURRENT_REVISION_TO_BASE = 'CURRENT_REVISION_TO_BASE',
}

export interface UserManagementTableContextMenuItem {
  itemName: UserManagementTableActions
  itemLabel: string
  icon: string
}
