import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from '@material-ui/core/Box'
import { blue, green, yellow } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import PopperTooltip from 'ui/PopperTooltip'

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600],
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: yellow[900],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const iconSize: SizeProp = 'lg'
const variantIcon = {
  success: <FontAwesomeIcon size={iconSize} icon="check" />,
  info: <FontAwesomeIcon size={iconSize} icon="info" />,
  warning: <FontAwesomeIcon size={iconSize} icon="exclamation-triangle" />,
  error: <FontAwesomeIcon size={iconSize} icon="exclamation-circle" />,
}

type MessageType = 'success' | 'info' | 'warning' | 'error'

interface MessageProps {
  type: MessageType
  tooltip?: React.ReactElement
  onClose?: () => void
}

const Message: React.FC<MessageProps> = ({ type, tooltip, onClose, children, ...props }) => {
  const classes = useStyles()
  const icon = variantIcon[type]
  const actions = []

  if (onClose) {
    actions.push(
      <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
        <FontAwesomeActionIcon size="xs" color="white" icon="times" />
      </IconButton>,
    )
  }

  return (
    <PopperTooltip
      popperLabel={
        <SnackbarContent
          className={classes[type]}
          aria-describedby={`message-${type}`}
          message={
            <div className={classes.message}>
              <Box mr={1}>{icon}</Box>
              {children}
            </div>
          }
          action={actions}
          {...props}
        />
      }
      popperContent={tooltip ? <>{tooltip}</> : ''}
    />
  )
}

export default React.memo(Message)
