import { Box, Button, Chip, lighten, TextField as MaterialTextField } from '@material-ui/core'
import axios from 'axios'
import { FormApi } from 'final-form'
import { User } from 'modules/auth/Auth.types'
import { CLEAR_SAVE_USER_STATE, SAVE_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'

import { getUserInitializedSelector, getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import {
  saveUserErrorSelector,
  saveUserIsSuccessSelector,
  saveUserLoadingSelector,
} from 'modules/auth/redux_store/state/saveUser'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { c, jt, t } from 'ttag'
import ErrorMessage from 'ui/form/ErrorMessage'
import LoadingButton from 'ui/form/LoadingButton'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import LayoutTitle from 'ui/LayoutTitle'
import Flex from 'ui/styles/Flex'
import { transformUserDetailsBeforeSave } from 'utils/auth'
import { validateUserSettings } from 'utils/formValidations'
import { QUERY_LANG_CHANGED, QUERY_USER_SETTINGS, useQueryParams, useQueryString } from 'utils/query-string'
import {
  hasPermissionForPvBulkMeterDataCleansing,
  hasPermissionForPvMeterDataCleansing,
  hasPermissionForWindBulkMeterDataCleansing,
  hasPermissionForWindMeterDataCleansing,
  isAdmin,
  isCompanyAccount,
  isCompanySubAccount,
  isDemo,
  isImpersonatedAdmin,
} from 'utils/user'
import AdminUserSettings from 'modules/user/userSettings/AdminUserSettings'
import {
  KEY_USER_COMPANY_LOGO,
  MASTER_DEMO_ACCOUNT,
  useUserSettingSaveMutation,
} from 'modules/auth/api/userSettings.api'
import SectionUploadCompanyLogo, { CompanyLogo } from 'modules/user/userSettings/SectionUploadCompanyLogo'
import DemoUserSettings from 'modules/user/userSettings/DemoUserSettings'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import MeterDataCleansingSettings from 'modules/user/userSettings/MeterDataCleansingSettings'
import SectionUserInfo from 'modules/user/userSettings/SectionUserInfo'
import { saveUser } from 'modules/auth/redux_store/auth.api'
import { DisabledFormContainer } from 'ui/form/form.style'
import { LinkToContactSupport } from 'ui/elements/MailLink'
import { theme } from 'themes/theme-light'
import { getSubUsersForCompany } from 'modules/userManagement/api/userManagement.api'
import { AutoCompleteWithLabel } from 'ui/form/assetForm.style'
import { RenderInputParams } from '@material-ui/lab'

let formReference: FormApi<User>

const UserSettingsForm = styled.form`
  width: 100%;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 1.3rem;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
  }
`

export const FormRow = styled.div`
  margin-bottom: 1rem;
  & .MuiFormControl-root {
    width: 100%;
  }
`

const HeadingBar = styled(Flex)`
  width: 100%;
`

const CustomChip = styled(Chip)`
  &.MuiChip-root.MuiChip-sizeSmall > .MuiChip-deleteIcon {
    display: none;
  }
`

const UserSettings: React.FC = () => {
  const dispatch = useDispatch()
  const [userDataAfterLanguageChanged, setUserDataAfterLanguageChanged] = useState<User | null>()
  // if this value is greater than 0 , it means that the logged-in user is a company account with sub-users
  const [linkedUsersToLoggedInUser, setLinkedUsersToLoggedInUser] = useState<string[] | []>([])

  const { onDeleteQueryStrings, onUpdateQueryString } = useQueryString()
  const { queryParams } = useQueryParams()
  const [companyLogoData, setCompanyLogoData] = useState<Record<string, any> | null>(null)

  const user = useSelector(getUserResultSelector)
  const userInitialized = useSelector(getUserInitializedSelector)
  const isUserSaved = useSelector(saveUserIsSuccessSelector)
  const saveLoading = useSelector(saveUserLoadingSelector)
  const saveError = useSelector(saveUserErrorSelector)

  const {
    mutate: mutateUserCompanyLogo,
    isSuccess: isSaveCompanyLogoSuccess,
  } = useUserSettingSaveMutation<CompanyLogo>(KEY_USER_COMPANY_LOGO)

  const fileName = useMemo(() => companyLogoData?.file?.name, [companyLogoData])

  const saveUserSettings = useCallback(
    (data: User) => {
      if (companyLogoData) {
        setCompanyLogoData(null)
        mutateUserCompanyLogo({ fileName: companyLogoData?.file?.name, imgSrc: companyLogoData.base64ImgSrc })
      }

      const dataToPost = () => {
        if (isCompanySubAccount(user)) {
          const copyUser = { ...user }
          copyUser.originalUser = data
          return copyUser
        }
        return data
      }

      dispatch({ type: SAVE_USER_REQUEST, user: dataToPost() })
      axios.post('api/usersettings/save/showTimezoneBanner', false)
    },
    [window.__localeId__, companyLogoData, user],
  )

  const handleResetForm = useCallback(
    (userData?: User) => {
      if (userData) {
        formReference?.reset(userData)
      } else {
        formReference?.reset()
      }

      setCompanyLogoData(null)
    },
    [Boolean(formReference)],
  )

  const handleFormSubmit = useCallback(
    (data: User) => {
      if (isCompanySubAccount(user)) {
        // here we are getting subscribed product from company
        data.subscribedProducts = user?.subscribedProducts
      }

      const userSettings = Object.assign({}, data)

      userSettings.country = data && data?.country ? data.country?.code || data.country : ''

      const preparedData = transformUserDetailsBeforeSave(userSettings)

      if (window.__localeId__ !== data.langKey) {
        onUpdateQueryString({ [QUERY_LANG_CHANGED]: 'true' })
        setUserDataAfterLanguageChanged(preparedData)
      } else {
        saveUserSettings(preparedData)
      }
    },
    [saveUserSettings, onUpdateQueryString, window.__localeId__, handleResetForm],
  )

  const handleCloseForm = useCallback(() => {
    onDeleteQueryStrings([QUERY_USER_SETTINGS, QUERY_LANG_CHANGED])
  }, [onDeleteQueryStrings])

  const handleCancelUnsavedDialog = () => {
    setUserDataAfterLanguageChanged(null)
  }

  /**
   * If language query is added in url update save the user settings with new lanugage information
   */
  useEffect(() => {
    if (queryParams[QUERY_LANG_CHANGED] && userDataAfterLanguageChanged) {
      handleResetForm(userDataAfterLanguageChanged)
      saveUser(userDataAfterLanguageChanged).finally(() => {
        setTimeout(() => {
          onDeleteQueryStrings([QUERY_LANG_CHANGED, QUERY_USER_SETTINGS])
          window.location.reload()
        }, 400)
      })
    }
  }, [
    queryParams[QUERY_LANG_CHANGED],
    handleResetForm,
    userDataAfterLanguageChanged,
    onDeleteQueryStrings,
    onUpdateQueryString,
  ])

  useEffect(() => {
    if (isUserSaved) {
      handleResetForm()
      // Need to close the form after saving
      // Used timeout because the form will be dirty, so we close the form in timeout
      setTimeout(() => {
        handleCloseForm()
      }, 400)
    }
  }, [isUserSaved, handleCloseForm, isSaveCompanyLogoSuccess, handleResetForm, onUpdateQueryString])

  useEffect(() => {
    if (!queryParams[QUERY_USER_SETTINGS]) {
      dispatch({ type: CLEAR_SAVE_USER_STATE })
      handleResetForm()
    }
  }, [queryParams[QUERY_USER_SETTINGS], handleResetForm])

  useEffect(() => {
    let useOriginalUser = false
    if (user?.originalUser?.login) {
      useOriginalUser = true
    }
    getSubUsersForCompany(useOriginalUser).then((res) => {
      setLinkedUsersToLoggedInUser(res)
    })
  }, [user])

  const disableForm = false

  const getRelatedCompanyNameAsJSX = useMemo(() => {
    return <b>{user?.login}</b>
  }, [user])

  const hasPermissionForMeterDataCleansing =
    hasPermissionForWindMeterDataCleansing(user) ||
    hasPermissionForPvMeterDataCleansing(user) ||
    hasPermissionForWindBulkMeterDataCleansing(user) ||
    hasPermissionForPvBulkMeterDataCleansing(user)

  return (
    <Container>
      {userInitialized && user && (
        <>
          <HeadingBar justifyContent="space-between" alignItems="center">
            <LayoutTitle>{c('User Settings').t`User settings`}</LayoutTitle>
            <SliderCloseButton onCloseSlider={handleCloseForm} />
          </HeadingBar>
          <DisabledFormContainer disable={disableForm}>
            <Form
              initialValues={isCompanySubAccount(user) ? user?.originalUser : user}
              validate={validateUserSettings}
              onSubmit={handleFormSubmit}
              render={({ handleSubmit, form, pristine, submitting }) => {
                formReference = form
                return (
                  <UserSettingsForm onSubmit={handleSubmit} noValidate autoComplete="off">
                    <FormBlockNavigation
                      blockWithExternalChanges={Boolean(fileName)}
                      navigationDialogText={t`User settings`}
                      form={form as FormApi}
                      currentPageQueries={[QUERY_USER_SETTINGS]}
                      navigationDialogKey={'userSettings'}
                      onResetExternalChanges={handleResetForm}
                      onCancelUnsavedDialog={handleCancelUnsavedDialog}
                    />
                    <ErrorMessage
                      errorMsg={
                        !saveLoading && !form.getState().modifiedSinceLastSubmit && saveError
                          ? saveError
                          : companyLogoData?.errorMsg
                          ? companyLogoData.errorMsg
                          : ''
                      }
                    />

                    <SectionUserInfo user={isCompanySubAccount(user) ? user?.originalUser : user} />

                    {isCompanySubAccount(user) ? (
                      <FormRow
                        style={{ backgroundColor: lighten(theme.palette.secondary.light, 0.9), padding: '10px' }}
                      >{jt`You are currently logged in to the company account ${getRelatedCompanyNameAsJSX}. Please note that any modifications you make to assets, data streams or other saved data will impact all users linked to the same company account while user settings like language or time zone affect only your personal view. To unlink your account, please contact ${LinkToContactSupport}.`}</FormRow>
                    ) : (
                      <FormRow>
                        <SectionUploadCompanyLogo
                          errorFile={companyLogoData?.errorMsg}
                          fileName={fileName}
                          onSetCompanyLogoData={setCompanyLogoData}
                        />
                      </FormRow>
                    )}

                    {isCompanyAccount(user) && (
                      <FormRow>
                        <AutoCompleteWithLabel
                          size="medium"
                          fullWidth
                          freeSolo
                          multiple
                          options={linkedUsersToLoggedInUser}
                          value={linkedUsersToLoggedInUser}
                          disableClearable
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <CustomChip key={index} label={option} size="small" {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params: RenderInputParams) => (
                            <MaterialTextField
                              label={t`Authorized users`}
                              {...params}
                              fullWidth
                              style={{ paddingTop: '5px' }}
                            />
                          )}
                        />
                      </FormRow>
                    )}

                    <Flex direction="row" justifyContent="flex-end">
                      <Box mr={1}>
                        <Button
                          color="default"
                          size="small"
                          variant="contained"
                          onClick={handleCloseForm}
                          disabled={disableForm}
                        >
                          {c('User Settings').t`Cancel`}
                        </Button>
                      </Box>
                      <LoadingButton
                        type="submit"
                        color="primary"
                        size="small"
                        variant="contained"
                        loading={saveLoading}
                        disabled={
                          disableForm ? true : companyLogoData ? companyLogoData?.errorMsg : pristine || submitting
                        }
                      >
                        {c('User Settings').t`Save`}
                      </LoadingButton>
                    </Flex>
                  </UserSettingsForm>
                )
              }}
            />
          </DisabledFormContainer>
          {isDemo(user) && user?.login !== MASTER_DEMO_ACCOUNT && <DemoUserSettings />}

          {/*We are adding isMeterDataCleansing just to show a selection for training in user settings*/}
          {(isAdmin(user) || isImpersonatedAdmin(user)) && <AdminUserSettings />}

          {hasPermissionForMeterDataCleansing && <MeterDataCleansingSettings />}
        </>
      )}
    </Container>
  )
}

export default React.memo(UserSettings)
