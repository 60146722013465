import React from 'react'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { Column } from 'modules/reTable/reTable.types'

interface ForecastModelsTableHeaderProps {
  columnsSelected: Column[]
}

const ForecastModelsTableHeader: React.FC<ForecastModelsTableHeaderProps> = ({ columnsSelected }) => {
  return (
    <ReTableHeader>
      <ReTableRow>
        <ReTableHeaderActionsCell isHierarchical={true} />
        {columnsSelected.map((column) => (
          <ReTableCell key={column.name} width={column.width}>
            <ReTableHeaderWithSort column={column} />
          </ReTableCell>
        ))}
      </ReTableRow>
    </ReTableHeader>
  )
}

export default React.memo(ForecastModelsTableHeader)
