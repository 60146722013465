import React, { useCallback, useMemo } from 'react'
import { useField } from 'react-final-form'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { Typography } from '@material-ui/core'
import TimePeriodWithPreSet from 'ui/elements/TimePeriodWithPreSets'
import { DateRange, meterDataCleansingRangeOptions } from 'utils/date'
import styled from 'styled-components'
import { FormApi } from 'final-form'
import { Asset } from 'modules/asset/store/asset.types'
import { MDCTimePeriod } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { Timezone } from 'fixtures/timezones'

const TimePeriodAndAvailabilityAnalysisContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

interface TimePeriodAndAvailabilityAnalysisProps {
  asset: Asset
  totalTimePeriod: MDCTimePeriod
  form: FormApi
  userTimezone: Timezone
  linkToDefault: boolean
}

const TimePeriodAnaAvailabilityAnalysis: React.FC<TimePeriodAndAvailabilityAnalysisProps> = ({
  asset,
  totalTimePeriod,
  form,
  userTimezone,
  linkToDefault,
}) => {
  const rangeOptions = meterDataCleansingRangeOptions()

  const handleChangeTrainingTimePeriod = useCallback(
    (newTimePeriod: MDCTimePeriod) => {
      form.mutators.setValue('timePeriodForTraining', newTimePeriod)
    },
    [userTimezone],
  )

  const timePeriodForTraining = useField<MDCTimePeriod>('timePeriodForTraining').input.value

  const initialTrainingTimePeriod: MDCTimePeriod = useMemo(() => {
    if (Object.keys(timePeriodForTraining).length > 0) {
      return timePeriodForTraining
    }
    return totalTimePeriod
  }, [totalTimePeriod, timePeriodForTraining])

  const fullTimePeriod: DateRange = useMemo(() => {
    return [totalTimePeriod.start.date, totalTimePeriod.end.date]
  }, [totalTimePeriod, asset])

  return (
    <TimePeriodAndAvailabilityAnalysisContainer>
      <Typography variant="subtitle1">{t`Select time period for training and evaluation`}</Typography>

      <Flex direction="row" alignItems="flex-start" justifyContent="flex-start" style={{ width: '100%' }}>
        <TimePeriodWithPreSet
          onChangeTimePeriod={handleChangeTrainingTimePeriod}
          staticRangeOptions={rangeOptions}
          initialTimePeriod={initialTrainingTimePeriod}
          fullTimePeriod={fullTimePeriod}
          userTimezone={userTimezone}
          disabled={linkToDefault}
        />
      </Flex>
    </TimePeriodAndAvailabilityAnalysisContainer>
  )
}

export default React.memo(TimePeriodAnaAvailabilityAnalysis)
