import React from 'react'
import { Box } from '@material-ui/core'
import SectionConfigInfo from 'modules/dataStreams/siteForecast/SectionConfigInfo'
import SectionQualityMeasurement from 'modules/dataStreams/siteForecast/SectionQualityMeasurement'
import SectionDeliveryTimes from 'modules/dataStreams/siteForecast/SectionDeliveryTimes'
import SectionEvaluation from 'modules/dataStreams/siteForecast/SectionEvaluation'
import { FormApi } from 'final-form'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'

interface SectionForecastQualityEvaluationBodyProps {
  onFormChange: () => void
  form: FormApi<ForecastConfig>
  fieldArrayKey: string
  index: number
}

const SectionForecastQualityEvaluationBody: React.FC<SectionForecastQualityEvaluationBodyProps> = ({
  onFormChange,
  form,
  fieldArrayKey,
  index,
}) => {
  return (
    <>
      <Box mb={1.5}>
        <SectionConfigInfo fieldArrayKey={fieldArrayKey} />
      </Box>
      <Box mb={1.5}>
        <SectionQualityMeasurement form={form} fieldArrayKey={fieldArrayKey} />
      </Box>
      <Box mb={1.5}>
        <SectionDeliveryTimes index={index} form={form} onFormChange={onFormChange} fieldArrayKey={fieldArrayKey} />
      </Box>
      <Box mb={1.5}>
        <SectionEvaluation form={form} index={index} fieldArrayKey={fieldArrayKey} />
      </Box>
    </>
  )
}

export default React.memo(SectionForecastQualityEvaluationBody)
