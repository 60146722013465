import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FileRejection } from 'react-dropzone'
import { FILE_INVALID_TYPE, FILE_TOO_LARGE, FILE_TOO_SMALL, TOO_MANY_FILES } from 'react-dropzone/src/utils'
import { c, t } from 'ttag'

import { theme } from 'themes/theme-light'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'
import { AppUnits } from 'utils/units'

export enum FILE_SIZE {
  MIN = 1, // in Byte
  MAX = 31457280, // in MB
  COMPANY_LOGO_MAX = 205000, // in kB
}

const fileSizeUnits = [
  AppUnits.BYTES,
  AppUnits.KILO_BYTE,
  AppUnits.MEGA_BYTE,
  AppUnits.GIGA_BYTE,
  AppUnits.TERA_BYTE,
  AppUnits.PETA_BYTE,
  AppUnits.EXA_BYTE,
  AppUnits.ZETTA_BYTE,
  AppUnits.YOTTA_BYTE,
]

export const formatBytes = (size: number) => {
  let l = 0,
    n = parseInt(size.toString(), 10) || 0
  while (n >= 1024 && ++l) {
    n = n / 1024
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + fileSizeUnits[l]
}

export const enrichFilesAfterLoad = (files: MeterdataFile[]): MeterdataFile[] => {
  return files.map((file) => {
    return {
      ...file,
      id: file.uuid,
      uiAncestors: [],
      uiParents: [],
      uiDescendants: [],
      uiChildren: [],
      uiLevel: 0,
    }
  })
}

export const FILE_RENAMED_OR_DELETED = 'file-renamed-or-deleted'
export type FILE_RENAMED_OR_DELETED = typeof FILE_RENAMED_OR_DELETED
export type FILE_ERROR_CODE =
  | FILE_INVALID_TYPE
  | FILE_TOO_LARGE
  | FILE_TOO_SMALL
  | TOO_MANY_FILES
  | FILE_RENAMED_OR_DELETED

export interface FileData {
  file: File
  text: string
}

export interface FileError {
  file: File
  errors: Record<FILE_ERROR_CODE, { errorMessage: string; helpMessage: string }[]>
}

export const readFile = (file: File) => {
  let fileError: FileError
  return new Promise<FileData>((resolve, reject) => {
    const reader = new FileReader()
    reader.onabort = () => {
      fileError = { file, error: t`File reading was aborted` }
      reject(fileError)
    }
    reader.onerror = () => {
      fileError = { file, error: t`File reading has failed` }
      reject(fileError)
    }
    reader.onload = () => {
      const text = reader.result as string
      if (text) {
        resolve({ text, file })
      } else {
        fileError = { file, error: t`file is empty` }
        reject(fileError)
      }
    }
    reader.readAsText(file)
  })
}

type FileStatusType = 'success' | 'progress' | 'error'
export interface ParseStatus {
  (file: MeterdataFile): {
    label: string
    description: string
    icon: IconProp
    color: string
    type: FileStatusType
    messages: string[]
  }
}
export const parseFileUploadStatus: ParseStatus = (file) => {
  const { status, messages } = file
  let label: string
  let description: string
  let icon: IconProp
  let color: string
  let type: FileStatusType

  switch (status) {
    case 'ASSET_NOT_FOUND':
      label = t`Asset not found`
      description = t`Name of the uploaded file needs to match exactly to an asset's name. Please rename your file accordingly`
      icon = 'exclamation-circle'
      type = 'error'
      break
    case 'DELETED':
      label = t`File deleted`
      description = t`This file was deleted and is not accessible anymore`
      icon = 'ban'
      type = 'error'
      break
    case 'ENQUEUED_FOR_IMPORT':
      label = t`File is currently being imported...`
      description = t`File is currently checked for correct format and content`
      icon = 'file-import'
      type = 'progress'
      break
    case 'IMPORTED_AND_ASSIGNED':
      label = t`Successfully imported`
      description = t`Meter data was successfully transferred to associated asset`
      icon = 'check'
      type = 'success'
      break
    case 'INVALID_FORMAT':
      label = t`Invalid Format`
      description = t`Uploaded file is not a proper CSV file or has a wrong format. Please use two comma-separated columns for datetime and power in Watt`
      icon = 'exclamation-circle'
      type = 'error'
      break
    case 'PARSE_ERROR':
      label = t`File could not be parsed`
      description = t`Uploaded CSV file could not be parsed, please check for structural or encoding issues`
      icon = 'exclamation-circle'
      type = 'error'
      break
    case 'ERROR':
    default:
      label = t`General error`
      description = t`File could not be processed`
      icon = 'exclamation-circle'
      type = 'error'
      break
  }

  switch (type) {
    case 'success':
      color = theme.palette.primary.main
      break
    case 'error':
      color = theme.palette.error.main
      break
    default:
      color = theme.palette.secondary.main
      break
  }

  return {
    label,
    description,
    icon,
    color,
    type,
    messages,
  }
}

export const convertRejectionErrors = (fileRejection: FileRejection): FileError['errors'] => {
  const actualFileSize = formatBytes(fileRejection.file.size)
  const maxFileSize = formatBytes(FILE_SIZE.MAX)
  return fileRejection.errors.reduce((result, error) => {
    let errorMessage: string
    let helpMessage: string
    switch (error.code) {
      case FILE_INVALID_TYPE:
        errorMessage = c('File Manager:Upload').t`Invalid file extension.`
        helpMessage = c('File Manager:Upload').t`Please make sure to upload a CSV file.`
        break
      case FILE_TOO_LARGE:
        errorMessage = c('File Manager:Upload').t`File is too large (${actualFileSize}).`
        helpMessage = c('File Manager:Upload')
          .t`Maximum file size is ${maxFileSize}. Select another file or try to split it into multiple files.`
        break
      case FILE_TOO_SMALL:
        errorMessage = c('File Manager:Upload').t`File is empty.`
        helpMessage = c('File Manager:Upload').t`Please make sure to choose a file with content.`
        break
      case TOO_MANY_FILES:
        errorMessage = c('File Manager:Upload').t`Too many files uploaded.`
        helpMessage = c('File Manager:Upload').t`You selected too many files. Please choose fewer files to upload.`
        break
      case FILE_RENAMED_OR_DELETED:
        errorMessage = c('File Manager:Upload').t`File not found.`
        helpMessage = c('File Manager:Upload').t`This file may have been renamed or deleted after selecting it.`
        break
      default:
        errorMessage = c('File Manager:Upload').t`Upload error.`
        helpMessage = c('File Manager:Upload').t`File could not be uploaded. Please try again or select another file.`
        break
    }
    return {
      ...result,
      [error.code]: {
        errorMessage,
        helpMessage,
      },
    }
  }, {})
}

export const convertFileRejectionsToErrors = (fileRejections: FileRejection[]): FileError[] => {
  return fileRejections.map((rejection) => ({
    file: rejection.file,
    errors: convertRejectionErrors(rejection),
  }))
}
