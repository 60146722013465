import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'ttag'

import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { miscellaneousColors } from 'themes/theme-light'
import Flex from 'ui/styles/Flex'
import { DATE_FORMAT_DEFAULT, formatDate } from 'utils/date'

const Wrapper = styled(Flex)``

const Note = styled.span`
  color: ${miscellaneousColors.mutedText};
  margin-right: 2px;
`

interface TimezoneInfoProps {
  showHeading?: boolean
  showTime?: boolean
}

const TimezoneInfo: React.FC<TimezoneInfoProps> = ({ showHeading = true, showTime = false }) => {
  const timezone = useSelector(getUserTimezoneSelector)
  const nowDate = new Date()

  return (
    <Wrapper direction="column" alignItems="flex-end">
      <Flex flexWrap="wrap" justifyContent="flex-end">
        <div>{showHeading && <Note>{t`Time zone: `}</Note>}</div>
        <div>
          {timezone} {showTime && <>({formatDate(nowDate, timezone, DATE_FORMAT_DEFAULT)})</>}
        </div>
      </Flex>
      {showTime && timezone !== 'UTC' && <Flex>UTC ({formatDate(nowDate, 'UTC', DATE_FORMAT_DEFAULT)})</Flex>}
    </Wrapper>
  )
}

export default React.memo(TimezoneInfo)
