import styled from 'styled-components'

export const AssetLinkIconSize = '17'
export const IconSelectedColor = '#ffffff'

interface SvgIconPathProps {
  iconColor?: string
}

export const SvgIconPath = styled.path<SvgIconPathProps>`
  fill: ${(props) => (props.iconColor ? props.iconColor : '#388e3c')};
  stroke: ${(props) => (props.iconColor ? props.iconColor : '#388e3c')};
`
