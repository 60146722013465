import React from 'react'
import Button from '@material-ui/core/Button'

import { useRouterMatch } from 'utils/route'
import CustomLink from 'ui/elements/CustomLink'
import { useCustomMuiStyles } from 'themes/theme-light'
import styled from 'styled-components'

const CustomHeaderButton = styled(Button)`
  margin-right: 2px !important;
`

interface HeaderLinkProps {
  isAdminComponent?: boolean
  to: string
  children: React.ReactNode
}

const HeaderLink: React.FC<HeaderLinkProps> = ({ to, children, isAdminComponent = false }) => {
  const { isMatch } = useRouterMatch(to)
  const classes = useCustomMuiStyles({ dark: true })

  return (
    <CustomLink route={to}>
      <CustomHeaderButton
        className={isAdminComponent ? classes.adminComponent : 'inherit'}
        color={isMatch ? 'primary' : 'default'}
      >
        {children}
      </CustomHeaderButton>
    </CustomLink>
  )
}

// HeaderLink.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default HeaderLink
