import React, { useEffect } from 'react'
import { useChart } from 'react-jsx-highstock'
import { appTourColors } from 'modules/app/tour/OnBoardingTourDialog'
import { theme } from 'themes/theme-light'

interface GenericOptionsProps {
  message?: string
  title?: string
  showTooltip?: boolean
}

const GenericOptions: React.FC<GenericOptionsProps> = ({ message = '', title = '', showTooltip = true }) => {
  const chart = useChart()

  useEffect(() => {
    if (!chart) return

    chart.update(
      {
        boost: {
          enabled: true,
          useGPUTranslations: true,
          seriesThreshold: 1, // Minimum number of series(plots) the chart must have for the chart-wide boost to kick in
        },
        credits: {
          enabled: false,
        },
        // navigator: {
        //   // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
        //   // BEWARE: THIS SEEMS TO DESTROY THE CHART SOMETIMES
        //   series: {
        //     type: 'line',
        //   },
        //   // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
        // },
        // rangeSelector: {
        //   enabled: false,
        //   inputEnabled: false,
        //   inputDateFormat: '%Y-%m-%d',
        //   // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
        //   // BEWARE:
        //   // don't add 'selected' attribute, otherwise Highcharts will completely massacre date range events
        //   // i'll repeat just to be sure and to avoid an epic rain of fire:
        //   //   =>  DO NOT ADD SELECTED
        //   // selected: 1,
        //   // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
        // },
        scrollbar: {
          enabled: false,
          liveRedraw: true,
        },
        tooltip: {
          enabled: showTooltip,
        },
        title: {
          text: title,
          style: {
            color: theme.palette.primary.main,
            fontSize: '16px',
          },
          floating: true,
          align: 'left',
          x: 80,
          y: 15,
        },
        subtitle: {
          text: message,
          style: {
            color: appTourColors.darkBlue,
            fontSize: '14px',
          },
          floating: true,
          align: 'left',
          x: 80,
          y: 50,
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          floating: true,
          x: 40,
          y: -20,
        },
      },
      false,
    )
  }, [chart, message, title, showTooltip])

  return null
}

export default React.memo(GenericOptions)
