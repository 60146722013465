import React from 'react'
import Flex from 'ui/styles/Flex'
import { Typography } from '@material-ui/core'
import { c } from 'ttag'

const HistoricalMeterDataStandardFormat: React.FC = () => {
  return (
    <>
      <Flex alignItems="center">
        <Typography variant="h5">{c('Meter data').t`Historical meter data standard format`}</Typography>
      </Flex>

      <Typography>{c('Meter data')
        .t`For uploading historical meter and available data in bulk, please use the file format as shown in the following sample file:`}</Typography>
      <a href="api/v1/download/get/samples/3" download>
        {c('Meter data').t`historical-meterdata-sample.csv`}
      </a>
      <ul>
        <li>
          {c('Meter data')
            .t`The name of the file must match the name of the asset for which the meter data is provided.`}
        </li>
        <li>
          {c('Meter data')
            .t`The temporal resolution (granularity or steps between the time stamps) should be 15 minutes.`}
        </li>
        <li>
          {c('Meter data').t`Please use international CSV format (decimal separator: point; field separator: comma).`}
        </li>
      </ul>

      <Typography>
        {c('Meter data').t`This file format can be submitted by e-mail to `}
        <a href="mailto: support@enercast.de">support@enercast.de</a>
        {c('Master data').t`. Please contact the same e-mail address in case of any questions.`}
      </Typography>
    </>
  )
}

export default HistoricalMeterDataStandardFormat
