import React from 'react'
import AssetLink from 'modules/asset/AssetLink'
import Number from 'ui/Number'
import { SeasonalForecastTableCell } from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastTableBody'
import { Column, ReTableItem } from 'modules/reTable/reTable.types'
import { useSeasonalIntraMonthForecastByAssets } from 'modules/workspace/advancedChartWidgets/seasonalForecast/seasonalForecast.api'
import { Asset } from 'modules/asset/store/asset.types'
import {
  getSeasonalForecastTypeName,
  getSeasonalTableCellBackgroundColor,
  getSeasonalTableCellBorderColor,
  getYearAndMonthForSeasonalForecast,
} from 'utils/seasonalForecast'
import { addMonths } from 'date-fns'
import InlineLoading from 'ui/InlineLoading'
import { TimeSeriesClassifier } from 'modules/dataStreams/dataStreams.types'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { math } from 'polished'
import { IndentationValueForSeasonalTableRow } from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastCategoryAndDataRows'

interface SeasonalForecastDataRowProps {
  asset: Asset
  columns: Column[]
  seasonalForecastData: ReTableItem // seasonalForecastData is just collection of record
}

const SeasonalForecastDataRow: React.FC<SeasonalForecastDataRowProps> = ({ asset, columns, seasonalForecastData }) => {
  const intraMonthForecastResult = useSeasonalIntraMonthForecastByAssets({ assets: [asset] })
  const assetIntraMonthForecastResultLoading = intraMonthForecastResult[0]?.isLoading

  const intraMonthAndYearLabel = getYearAndMonthForSeasonalForecast(new Date())
  const intraPlusMonthAndYearLabel = getYearAndMonthForSeasonalForecast(addMonths(new Date(), 1))
  const isEnergyRow = seasonalForecastData?.type === TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION

  return (
    <>
      {columns.map((column) =>
        column.name === 'assetName' ? (
          <SeasonalForecastTableCell key={column.name}>
            <AssetLink asset={asset} />
          </SeasonalForecastTableCell>
        ) : column.name === 'type' ? (
          <SeasonalForecastTableCell key={column.name}>
            <div style={{ marginLeft: `${IndentationValueForSeasonalTableRow}` }}>
              <FixedWidthTextContainer
                text={getSeasonalForecastTypeName(seasonalForecastData)}
                width={math(`${column.width} - ${IndentationValueForSeasonalTableRow}`)}
              >
                {getSeasonalForecastTypeName(seasonalForecastData)}
              </FixedWidthTextContainer>
            </div>
          </SeasonalForecastTableCell>
        ) : (
          <SeasonalForecastTableCell
            width="auto"
            bg_color={getSeasonalTableCellBackgroundColor({ column, seasonalData: seasonalForecastData })}
            border_bottom_color={getSeasonalTableCellBorderColor({ column, seasonalData: seasonalForecastData })}
            key={column.name}
            align={'right'}
          >
            {(column.name === intraMonthAndYearLabel || column.name === intraPlusMonthAndYearLabel) &&
            assetIntraMonthForecastResultLoading &&
            isEnergyRow ? (
              <div>
                <InlineLoading />
              </div>
            ) : seasonalForecastData[column.name] ? (
              <Number
                showFractionalPart={true}
                separator={false}
                data={seasonalForecastData[column.name]}
                limit={3}
                forceFractionalPart={true}
              />
            ) : (
              <div style={{ paddingRight: '0.4em' }}>-</div>
            )}
          </SeasonalForecastTableCell>
        ),
      )}
    </>
  )
}

export default SeasonalForecastDataRow
