import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonProps, IconButton, lighten } from '@material-ui/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { theme } from 'themes/theme-light'
import { t } from 'ttag'
import PopperTooltip from 'ui/PopperTooltip'

const HelpButton = styled(Button)`
  &.MuiButton-root {
    max-height: 3em;
    padding: 0 1em;
    cursor: pointer;
    margin-bottom: -10px;
  }
`

interface HelpProps extends ButtonProps {
  showHelpTextBtn?: boolean
  primaryColors?: boolean
}

const Help: React.FC<HelpProps> = ({ showHelpTextBtn = true, primaryColors = false, children, ...rest }) => {
  const getPopperLabel = useCallback(() => {
    return showHelpTextBtn ? (
      <HelpButton
        variant="text"
        color={primaryColors ? 'primary' : 'secondary'}
        size="small"
        startIcon={<FontAwesomeIcon size="xs" icon="question-circle" />}
        {...rest}
      >
        {t`Help`}
      </HelpButton>
    ) : (
      <IconButton color={primaryColors ? 'primary' : 'secondary'} {...rest}>
        <FontAwesomeIcon size="sm" icon="question-circle" />
      </IconButton>
    )
  }, [showHelpTextBtn])

  return (
    <>
      {/*only Popper works here instead of Popover because else the backdrop interferes with mouse over handling*/}
      <PopperTooltip
        contentBackgroundColor={
          primaryColors ? lighten(theme.palette.primary.light, 0.9) : lighten(theme.palette.secondary.light, 0.9)
        }
        popperContent={children}
        popperLabel={getPopperLabel()}
      />
    </>
  )
}

export default React.memo(Help)
