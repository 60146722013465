import React from 'react'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import AssetLink from 'modules/asset/AssetLink'
import { theme } from 'themes/theme-light'
import { Column, ReTableItem } from 'modules/reTable/reTable.types'
import { useUniqueSelectedAssets } from 'utils/asset'
import { Asset } from 'modules/asset/store/asset.types'
import Flex from 'ui/styles/Flex'

interface EvaluationTableAssetCellProps {
  item: ReTableItem
  columns: Column[]
  tableHeaderHasActions: boolean
}

const EvaluationTableAssetCell: React.FC<EvaluationTableAssetCellProps> = ({
  item,
  columns,
  tableHeaderHasActions,
}) => {
  const selectedAssets = useUniqueSelectedAssets()

  const asset = selectedAssets.find((asset) => asset.id === item.identifier) as Asset

  return asset ? (
    <ReTableCell colSpan={columns.length + (tableHeaderHasActions ? 1 : 0)}>
      <Flex alignItems="center">
        <ReTableExpandToggle item={item} />
        <AssetLink color={theme.palette.primary.main} query={''} asset={asset} />
      </Flex>
    </ReTableCell>
  ) : null
}

export default EvaluationTableAssetCell
