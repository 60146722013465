import React from 'react'
import { c, t } from 'ttag'
import { AppTours, AppTourStep, TourStepCategory } from 'modules/app/tour/appTour.types'
import { TourListItem, TourStepContent, TourUnOrderedList } from 'modules/app/tour/AppTour'
import { Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const createAssetSteps: () => AppTourStep[] = () => {
  const addAssetText = <b>{t`Add Asset`}</b>
  const saveAndCloseText = <b>{t`SAVE AND CLOSE`}</b>

  return [
    {
      target: '.appTour-createAsset-contextMenu',
      title: c('AppTour:Asset').t`Add Asset`,
      content: (
        <TourStepContent>
          <TourUnOrderedList>
            <TourListItem> {c('AppTour:Asset').t`Open the menu.`}</TourListItem>
            <TourListItem>{c('AppTour:Asset').jt`Select ${addAssetText} to create a wind or solar site.`}</TourListItem>
          </TourUnOrderedList>
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'auto',
      stepCategory: TourStepCategory.WORKBENCH,
      tourContext: AppTours.ADD_ASSET,
    },
    {
      target: '.appTour-createAsset-save',
      title: c('AppTour:Asset').t`Add Asset`,
      content: (
        <TourStepContent>
          <TourUnOrderedList>
            <TourListItem>{c('AppTour:Asset')
              .t`First use the dropdown to choose the type of asset you want to create.`}</TourListItem>
            <TourListItem>{c('AppTour:Asset').t`Enter the required asset details.`}</TourListItem>
            <TourListItem>
              {c('AppTour:Asset')
                .t`To enter your asset location, simply use the location search and the interactive map below or enter the geocoordinates directly.`}
            </TourListItem>
            <TourListItem>{c('AppTour:Asset').jt`Click ${saveAndCloseText}.`}</TourListItem>
          </TourUnOrderedList>
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'right',
      stepCategory: TourStepCategory.FORM,
      tourContext: AppTours.ADD_ASSET,
    },
  ]
}

export const activateDraftSteps: () => AppTourStep[] = () => {
  const activateDraftText = <b>{c('AppTour:Activate draft').t`ACTIVATE`}</b>
  return [
    {
      target: '.appTour-activateDraft',
      title: c('AppTour:Activate draft').t`Activate draft`,
      content: (
        <TourStepContent>
          {c('AppTour:Activate draft')
            .jt`All your changes are saved in a draft so you can finalize your setup before it impacts the forecast production. Click ${activateDraftText} to start forecasting.`}
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'auto',
      stepCategory: TourStepCategory.WORKBENCH,
      tourContext: AppTours.ACTIVATE_DRAFT,
    },
  ]
}

export const createSiteForecastSteps: () => AppTourStep[] = () => {
  const addSiteForecastText = <b>{t`Add Site Forecast`}</b>
  const useTemplateText = <b>{t`USE TEMPLATE`}</b>
  const customize = <b>{t`Customize`}</b>
  const saveAndCloseText = <b>{t`SAVE AND CLOSE`}</b>
  const menuIcon = <FontAwesomeIcon icon="sort-down" color="black" />
  return [
    {
      target: '.appTour-createSiteForecast-contextMenu',
      title: c('AppTour:Site Forecast').t`Add Site Forecast`,
      content: (
        <TourStepContent>
          <TourUnOrderedList>
            <TourListItem> {c('AppTour:Site Forecast').t`Open the menu.`}</TourListItem>
            <TourListItem> {c('AppTour:Site Forecast').jt`Select ${addSiteForecastText}`}</TourListItem>
          </TourUnOrderedList>
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'auto',
      stepCategory: TourStepCategory.WORKBENCH,
      tourContext: AppTours.ADD_SITE_FORECAST,
    },
    {
      target: '.appTour-createSiteForecast-saveOrEdit',
      title: c('AppTour:Site Forecast').t`Add Site Forecast`,
      content: (
        <TourStepContent>
          <TourUnOrderedList>
            <TourListItem>
              {c('AppTour:Site Forecast')
                .t`From the list on the left, choose the template that best matches your business needs.`}
            </TourListItem>
            <TourListItem>
              {c('AppTour:Site Forecast')
                .jt`Click ${useTemplateText} to use it as it is or click ${menuIcon} and select “${customize}” to set up individual details like update times and horizon of your forecast.`}
            </TourListItem>
          </TourUnOrderedList>
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true,
      hideCloseButton: false,
      hideFooter: true,
      placement: 'left-start',
      stepCategory: TourStepCategory.TABLE_OR_PAGE,
      tourContext: AppTours.ADD_SITE_FORECAST,
    },
    {
      target: '.appTour-createSiteForecast-save',
      title: c('AppTour:Site Forecast').t`Add Site Forecast`,
      content: (
        <TourStepContent>{c('AppTour:Site Forecast')
          .jt`Customize your Site Forecast, then click ${saveAndCloseText}.`}</TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true,
      hideCloseButton: false,
      hideFooter: true,
      placement: 'bottom',
      stepCategory: TourStepCategory.FORM,
      tourContext: AppTours.ADD_SITE_FORECAST,
    },
  ]
}

export const addDeliveryTargetToSiteForecastSteps: () => AppTourStep[] = () => {
  const eyeIcon = <FontAwesomeIcon icon="eye" color="black" />
  const saveAndCloseText = <b>{t`SAVE AND CLOSE`}</b>
  return [
    {
      target: '.appTour-addDeliveryTarget-select-forecast',
      title: c('AppTour:Site Forecast').t`Add Delivery target to Site Forecast`,
      content: (
        <TourStepContent>
          {c('AppTour:Site Forecast')
            .jt`Click the ${eyeIcon} button next to the Site Forecast that you created to open its details.`}
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'auto',
      stepCategory: TourStepCategory.WORKBENCH,
      tourContext: AppTours.ADD_DELIVERY_TARGET_TO_SITE_FORECAST,
    },
    {
      target: '.appTour-addDeliveryTarget-add-and-save',
      title: c('AppTour:Site Forecast').t`Add Delivery target to Site Forecast`,
      content: (
        <TourStepContent>
          {c('AppTour:Site Forecast')
            .jt`Add the new delivery target to the Site Forecast and click ${saveAndCloseText}.`}
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true,
      hideCloseButton: false,
      hideFooter: true,
      placement: 'left',
      stepCategory: TourStepCategory.FORM,
      tourContext: AppTours.ADD_DELIVERY_TARGET_TO_SITE_FORECAST,
    },
  ]
}

export const createDeliveryTargetSteps: () => AppTourStep[] = () => {
  const selectDataTabText = <b>{t`DATA`}</b>
  const addNewDeliveryTargetText = <b>{t`ADD NEW DELIVERY TARGET`}</b>
  const saveAndCloseText = <b>{t`SAVE AND CLOSE`}</b>

  return [
    {
      target: '.appTour-createDeliveryTarget-headerBarButton',
      title: c('AppTour:Delivery Target').t`Set up automated delivery`,
      content: (
        <TourStepContent>{c('AppTour:Delivery Target')
          .jt`enercast IPL can automatically deliver your forecasts to targets like FTP servers or your e-mail address. To set up a delivery target, click on ${selectDataTabText}.`}</TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      stepCategory: TourStepCategory.WORKBENCH,
      tourContext: AppTours.ADD_DELIVERY_TARGET,
    },
    {
      target: '.appTour-createDeliveryTarget-addBtn',
      title: c('AppTour:Delivery Target').t`Add Delivery Target`,
      content: (
        <TourStepContent>{c('AppTour:Delivery Target').jt`Click on ${addNewDeliveryTargetText}.`}</TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // If true it open's the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'bottom',
      stepCategory: TourStepCategory.TABLE_OR_PAGE,
      tourContext: AppTours.ADD_DELIVERY_TARGET,
    },
    {
      target: '.appTour-createDeliveryTarget-save',
      title: c('AppTour:Delivery Target').t`Add Delivery Target`,
      content: (
        <TourStepContent>
          <Box mb={1.2}>{c('AppTour:Delivery Target')
            .t`Choose either e-Mail or FTP and give your new target a name.`}</Box>
          <b>{c('AppTour:Delivery Target').t`For FTP delivery:`}</b>
          <TourUnOrderedList>
            <TourListItem>
              {c('AppTour:Delivery Target').t`Fill in the required fields to access your FTP server.`}
            </TourListItem>
          </TourUnOrderedList>

          <Box mt={1.2}>
            <b>{c('AppTour:Delivery Target').t`For e-mail delivery:`}</b>
          </Box>
          <TourUnOrderedList>
            <TourListItem>{c('AppTour:Delivery Target').t`Please check your e-mail address.`}</TourListItem>
            {/*<TourListItem>*/}
            {/*  {c('AppTour:Delivery Target')*/}
            {/*    .jt`Check the checkbox ${deliveryTargetEmailCheckbox} to have all files delivered in a single mail, rather than receiving one mail per file.`}*/}
            {/*</TourListItem>*/}
          </TourUnOrderedList>
          <Box mt={0.5}>{c('AppTour: Delivery Target')
            .t`Sorry, you may have to close this pop-up to see the input fields. Don't worry, you can resume the tour afterwards.`}</Box>
          <Box mt={0.5}>{c('AppTour:Delivery Target').jt`Click ${saveAndCloseText}.`}</Box>
        </TourStepContent>
      ),
      spotlightClicks: true,
      disableBeacon: true, // If true it open's the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'auto',
      stepCategory: TourStepCategory.FORM,
      tourContext: AppTours.ADD_DELIVERY_TARGET,
    },
  ]
}
