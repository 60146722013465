import React, { useMemo } from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { t } from 'ttag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getAdminHeaderMenuItems } from 'fixtures/header'
import { Box, ListItemIcon, ListItemText } from '@material-ui/core'
import styled from 'styled-components'
import { useRouter } from 'utils/route'
import { useCustomMuiStyles } from 'themes/theme-light'

const StyledMenu = styled(Menu)`
  & a {
    color: initial;
    text-decoration: initial;
  }
`

const HeaderAdminFunctionality: React.FC = () => {
  //Style
  const classesWithDark = useCustomMuiStyles({ dark: true })
  const classes = useCustomMuiStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { history } = useRouter()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (route: string) => {
    history.push(route)
    setAnchorEl(null)
  }

  const adminMenuItems = useMemo(() => getAdminHeaderMenuItems(), [])

  return (
    <>
      <Button
        aria-controls="header-admin-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classesWithDark.adminComponent}
      >
        {t`Admin`}
        <Box ml={0.4}>
          <FontAwesomeIcon icon="caret-down" />
        </Box>
      </Button>
      <StyledMenu id="header-admin-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {adminMenuItems.map((item) => {
          return (
            <MenuItem key={item.link} onClick={() => handleClose(item.link)} className={classes.adminComponent}>
              <ListItemIcon>
                <FontAwesomeIcon icon={item.icon} fixedWidth />
              </ListItemIcon>
              <ListItemText>
                {/*<CustomLink key={item.link} onLinkClick={handleClose} route={item.link}>*/}
                {/* */}
                {/*</CustomLink>*/}
                {item.label}
              </ListItemText>
            </MenuItem>
          )
        })}
      </StyledMenu>
    </>
  )
}

export default HeaderAdminFunctionality
