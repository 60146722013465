import { combineReducers } from 'redux'
import * as actionTypes from '../auth.action.types'
import createReducer from 'utils/createReducer'
import { createSelector } from 'reselect'
import { ChangePasswordType } from 'modules/auth/ChangePasswordForm'

// state
interface State {
  isSuccess: boolean
  loading: boolean
  error: string | null
}

const initialState: State = {
  isSuccess: false,
  loading: false,
  error: null,
}

export interface ChangePasswordAction {
  type: actionTypes.CHANGE_PASSWORD_TYPE
  // REQUEST
  data: ChangePasswordType
  // ERROR
  error: string | null
}

const isSuccess = createReducer<State['isSuccess'], ChangePasswordAction>(
  (state = initialState.isSuccess, { type }) => {
    switch (type) {
      case actionTypes.CHANGE_PASSWORD_SUCCESS:
        return true
      case actionTypes.CHANGE_PASSWORD_REQUEST:
      case actionTypes.CHANGE_PASSWORD_FAILURE:
      case actionTypes.CHANGE_PASSWORD_DISMISS:
      case actionTypes.CLEAR_CHANGE_PASSWORD_STATE:
        return false
    }
    return state
  },
)

const loading = createReducer<State['loading'], ChangePasswordAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.CHANGE_PASSWORD_REQUEST:
      return true
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
    case actionTypes.CHANGE_PASSWORD_FAILURE:
    case actionTypes.CHANGE_PASSWORD_DISMISS:
    case actionTypes.CLEAR_CHANGE_PASSWORD_STATE:
      return false
  }
  return state
})

const error = createReducer<State['error'], ChangePasswordAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
    case actionTypes.CHANGE_PASSWORD_DISMISS:
    case actionTypes.CLEAR_CHANGE_PASSWORD_STATE:
      return null
    case actionTypes.CHANGE_PASSWORD_FAILURE:
      return error
  }
  return state
})

export const changePasswordReducer = combineReducers({
  isSuccess,
  loading,
  error,
})

// Selectors
export const changePasswordIsSuccessSelector = createSelector<any, State['isSuccess'], State['isSuccess']>(
  (state) => state.auth.changePassword.isSuccess,
  (isSuccess) => isSuccess,
)

export const changePasswordLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.changePassword.loading,
  (loading) => loading,
)
export const changePasswordErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.changePassword.error,
  (error) => error,
)
