import React, { useMemo } from 'react'
import { LanguageKey, LanguageKeys } from 'fixtures/header'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'
import LoadingButton from 'ui/form/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'ttag'
import Box from '@material-ui/core/Box'
import { ScheduleFormActionsButtonsContainer } from 'modules/workspace/schedule/ScheduleForm'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { FormApi } from 'final-form'
import { CreateScheduleInputData, SubmitScheduleSeriesProps } from 'modules/workspace/schedule/schedule.types'

import {
  getInvalidFieldsErrorMessagesForCertainForm,
  getScheduleFormSaveMenuItems,
  scheduleFormRequiredFields,
  ScheduleFormSaveOptions,
} from 'utils/form'
import SplitButton from 'ui/form/SplitButton'
import { AssetSaveMainItem } from 'utils/asset'
import {
  useScheduleSaveMainItemKey,
  useScheduleSaveMainItemKeySaveMutation,
} from 'modules/workspace/schedule/schedule.api'
import { convertZonedTimeToUtc } from 'utils/date'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { ScheduleLocalStorageKeys } from 'utils/schedule'

const TooltipWrapper = styled.div`
  max-width: 50rem;
`

const ErrorMessage = styled.div`
  color: ${theme.palette.error.main};
`

interface ScheduleFormActionsProps {
  form: FormApi<CreateScheduleInputData>
  onSubmitScheduleSeries: (data: SubmitScheduleSeriesProps) => void
  disableSubmitButton: boolean
  langKey: LanguageKey
  submitInProgress: boolean
  formValues: CreateScheduleInputData
  seriesChanged: boolean
}

const ScheduleFormActions: React.FC<ScheduleFormActionsProps> = ({
  form,
  onSubmitScheduleSeries,
  disableSubmitButton,
  langKey,
  submitInProgress,
  formValues,
  seriesChanged,
}) => {
  const formDirty = form.getState().dirty
  const formInvalid = form.getState().invalid

  // Check if there is a changed version in localstorage
  const dataInLocal = localStorage.getItem(ScheduleLocalStorageKeys.outPutSeries as string)
  const validDataInLocalStorage = dataInLocal && Object.keys(JSON.parse(dataInLocal) || {}).length > 0

  const saveMenuItems = getScheduleFormSaveMenuItems()
  const saveMainItemKey = useScheduleSaveMainItemKey()
  const savedMainItem = saveMainItemKey.data?.value
  const { mutate: saveMainMutation } = useScheduleSaveMainItemKeySaveMutation()
  const userTimezone = useSelector(getUserTimezoneSelector)

  const handleSaveMenuSelect = (item: Record<string, ScheduleFormSaveOptions | string>) => {
    if (formValues?.start?.date && formValues?.end?.date) {
      formValues.start.timezone = userTimezone
      formValues.end.timezone = userTimezone
    }

    if (item.key === ScheduleFormSaveOptions.SUBMIT_WITH_TEMPLATE) {
      onSubmitScheduleSeries({ scheduleInput: formValues, withTemplate: true })
    } else {
      onSubmitScheduleSeries({ scheduleInput: formValues, withTemplate: false })
    }

    // if (savedMainItem !== item.key) {
    const selectedMenuItemData: AssetSaveMainItem = {
      value: item.key as ScheduleFormSaveOptions,
    }
    saveMainMutation(selectedMenuItemData)
    // }
  }

  // console.log({ formErrors, formInvalid })

  const requiredFieldsForScheduleForm = scheduleFormRequiredFields()

  const anyError = formInvalid

  const isInvalid = formInvalid

  const invalidFieldsErrorMessages = getInvalidFieldsErrorMessagesForCertainForm(
    form as FormApi,
    requiredFieldsForScheduleForm,
  )

  const TooltipContent = useMemo(() => {
    if (anyError) {
      return (
        <TooltipWrapper>
          {isInvalid && (
            <>
              <ErrorMessage>{t`Validation failed. Please correct the issues below.`}</ErrorMessage>
              {invalidFieldsErrorMessages && invalidFieldsErrorMessages.length > 0 && (
                <ul>
                  {invalidFieldsErrorMessages?.map((field, index) => {
                    return (
                      <li key={index}>
                        <ErrorMessage>
                          <span style={{ paddingRight: '5px', color: 'black' }}>{field.fieldName}:</span> {field.reason}
                        </ErrorMessage>
                      </li>
                    )
                  })}
                </ul>
              )}
            </>
          )}
        </TooltipWrapper>
      )
    } else return <></>
  }, [anyError, isInvalid, invalidFieldsErrorMessages])

  const disableSaveButton = (formInvalid || !formDirty) && !seriesChanged && !validDataInLocalStorage
  const disableSubmit = disableSubmitButton || formDirty || validDataInLocalStorage

  return (
    <ScheduleFormActionsButtonsContainer is_lang_eng={langKey === LanguageKeys.english ? 1 : 0} alignItems="flex-end">
      <ConditionalWrapper
        condition={anyError}
        wrapper={(children) => (
          <PopperTooltip
            popperLabel={children}
            popperContent={TooltipContent}
            position={PopperTooltipPosition.BOTTOM_START}
          />
        )}
      >
        <LoadingButton
          loading={false}
          variant="contained"
          size="small"
          type={'submit'}
          disabled={disableSaveButton}
          startIcon={
            <FontAwesomeIcon
              color={formInvalid ? theme.palette.error.dark : 'inherit'}
              icon={formInvalid ? 'exclamation-triangle' : 'save'}
              fixedWidth
            />
          }
        >{t`Save`}</LoadingButton>
      </ConditionalWrapper>

      <Box ml={2}>
        <SplitButton
          items={saveMenuItems}
          mainItemKey={savedMainItem}
          loading={submitInProgress}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FontAwesomeIcon icon="check" fixedWidth />}
          onSelectItem={handleSaveMenuSelect}
          disabled={disableSubmit}
        />
      </Box>
    </ScheduleFormActionsButtonsContainer>
  )
}

export default ScheduleFormActions
