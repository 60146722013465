import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DeliveryTarget } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import Flex from 'ui/styles/Flex'
import ReTable from 'modules/reTable/ReTable'
import { RETABLE_ID_DELIVERY_TARGETS, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { table } from 'themes/theme-light'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { getDeliveryTargetsColumns } from 'utils/delivery'
import { useDispatch } from 'react-redux'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import styled from 'styled-components'
import DeliveryTargetsTableBody from 'modules/delivery/deliveryTargets/DeliveryTargetsTableBody'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import { getDeliveryTargetQueryObj, QUERY_DELIVERY_TARGET, useQueryMatch, useQueryString } from 'utils/query-string'
import { useDeliveryTargetsDeleteMutation } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import DeliveryTablesGenericToolbar from 'modules/delivery/DeliveryTablesGenericToolbar'
import { c } from 'ttag'
import { createDeliveryTargetSteps } from 'modules/app/tour/appTourSteps'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { AppTours, TourStepCategory } from 'modules/app/tour/appTour.types'
import { AddTourStepsTime } from 'modules/app/tour/appTourUtils'
import { useRouter } from 'utils/route'
import { ROUTE_DATA_DELIVERY } from 'modules/app/Routes'

const TargetsTable = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

interface DeliveryTargetsTableProps {
  deliveryTargets: DeliveryTarget[]
}

const DeliveryTargetsTable: React.FC<DeliveryTargetsTableProps> = ({ deliveryTargets }) => {
  const { history } = useRouter()
  const { addTourSteps, removeTourSteps, isTourActive } = useContext(AppTourContext)
  const isDeliveryTarget = useQueryMatch(QUERY_DELIVERY_TARGET)
  const dispatch = useDispatch()
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_DELIVERY_TARGETS)
  const tableHeaderHasAction = false
  const [itemsToRender, setItemsToRender] = useState<DeliveryTarget[]>([])
  const { onUpdateQueryString } = useQueryString()

  const { mutate: deleteDeliveryTarget, isSuccess: deleteDeliveryTargetSuccess } = useDeliveryTargetsDeleteMutation()

  const handleItemsToRenderChange = useCallback((items: DeliveryTarget[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  const handleAddNewItem = useCallback(() => {
    onUpdateQueryString(getDeliveryTargetQueryObj())
  }, [onUpdateQueryString])

  const handleCopyItem = useCallback(() => {
    console.log('handle copy item')
  }, [])

  const handleEditItem = useCallback(
    (data: DeliveryTarget) => {
      onUpdateQueryString(getDeliveryTargetQueryObj(data))
    },
    [onUpdateQueryString],
  )

  const handleDeleteItem = useCallback((data: DeliveryTarget) => {
    deleteDeliveryTarget(data)
  }, [])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'name',
    }),
    [],
  )

  // Manage columns
  const columnsAvailable = useMemo(() => getDeliveryTargetsColumns(), [])
  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_DELIVERY_TARGETS, columnsAvailable })
  }, [columnsAvailable])

  useEffect(() => {
    const addSteps =
      !isDeliveryTarget && deliveryTargets?.length == 1 && typeof addTourSteps === 'function' && isTourActive
    if (!addSteps) return
    const steps = createDeliveryTargetSteps().filter((item) => item.stepCategory === TourStepCategory.TABLE_OR_PAGE)
    const addStepsTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (addStepsTimer) clearTimeout(addStepsTimer)
    }
  }, [JSON.stringify(deliveryTargets), addTourSteps, isTourActive, isDeliveryTarget])

  useEffect(() => {
    return () => {
      if (typeof removeTourSteps === 'function' && isTourActive) removeTourSteps(AppTours.ADD_DELIVERY_TARGET)
    }
  }, [isTourActive])

  // Redirection after each successful deletion of a delivery target
  useEffect(() => {
    if (deleteDeliveryTargetSuccess) {
      history.push(ROUTE_DATA_DELIVERY)
    }
  }, [deleteDeliveryTargetSuccess])

  // Navigate to workbench if at least one delivery is not assigned to siteforecast
  // useEffect(() => {
  //   if (deliveryTargets?.length === 1 || siteForecastsResults?.data?.length === 1) return
  //   if (isTourActive && atLeastOneDeliveryIsAssignedToSiteForecast === DeliveryUsed.YES) return
  //   if (isTourActive || isTourPaused) {
  //     history.push(ROUTE_WORKBENCH)
  //   }
  // }, [
  //   deliveryTargets,
  //   siteForecastsResults?.data,
  //   atLeastOneDeliveryIsAssignedToSiteForecast,
  //   isTourActive,
  //   isTourPaused,
  // ])

  return (
    <Flex direction="column" fullHeight>
      <DeliveryTablesGenericToolbar
        tableHeaderHasActions={tableHeaderHasAction}
        columns={columnsSelected}
        onAddNewItem={handleAddNewItem}
        tableId={RETABLE_ID_DELIVERY_TARGETS}
        addItemButtonLabel={c('Delivery:Target').t`Add new delivery target`}
        addBtnClassName="appTour-createDeliveryTarget-addBtn"
        isTargetTable={true}
      />
      <DynamicTableHeightContainer numberOfRows={itemsToRender.length}>
        <TargetsTable
          id={RETABLE_ID_DELIVERY_TARGETS}
          itemHeight={table.rowHeight}
          items={deliveryTargets as ReTableItem[]}
          hasExtendedHeader={false}
          itemsPaddingHeader={ITEMS_PADDING_HEADER}
          itemsPaddingFooter={ITEMS_PADDING_FOOTER}
          renderAheadCount={RENDER_AHEAD_COUNT}
          defaultSort={defaultSort}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ReTableGenericHeader tableHeaderHasActions={tableHeaderHasAction} columns={columnsSelected} />
          <DeliveryTargetsTableBody
            onCopyItem={handleCopyItem}
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            columns={columnsSelected}
            visibleItems={itemsToRender}
            tableHeaderHasActions={tableHeaderHasAction}
          />
        </TargetsTable>
      </DynamicTableHeightContainer>
    </Flex>
  )
}

export default React.memo(DeliveryTargetsTable)
