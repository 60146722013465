import React, { useEffect, useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import Number from 'ui/Number'
import { AssetsCapacitySummary, SummaryAggregation, SummaryType } from 'modules/asset/tree/AssetTreeSummary'
import { Asset } from 'modules/asset/store/asset.types'
import { getNomcap, isCluster, isGenerator, isPark } from 'utils/asset'

interface CapacitySummaryCellProps {
  activeAggregation: SummaryAggregation
  activeSummary: SummaryType
  allAssets: Asset[]
  selectedAssets: Asset[]
  onCapacitySummaryValuesHasFractions: (val: boolean) => void
}

const CapacitySummaryCell: React.FC<CapacitySummaryCellProps> = ({
  activeSummary,
  allAssets,
  selectedAssets,
  activeAggregation,
  onCapacitySummaryValuesHasFractions,
}) => {
  const calculateAssetsCapacitySummary = (assets: Asset[]): AssetsCapacitySummary => {
    const clusters = assets.filter((asset) => isCluster(asset))
    const parks = assets.filter((asset) => isPark(asset))
    const generators = assets.filter((asset) => isGenerator(asset))

    let alreadyAddedIds: string[] = []
    let sumOfCapacities = 0

    clusters?.forEach((c) => {
      const parkIds = c?.parkIds || []
      const generatorIds = c?.generatorIds || []
      alreadyAddedIds = [...alreadyAddedIds, ...[c.id], ...parkIds, ...generatorIds]
      sumOfCapacities += c.currentNomCap
    })

    parks?.forEach((p) => {
      if (!alreadyAddedIds.includes(p.id)) {
        const generatorIds = p?.generatorIds || []
        alreadyAddedIds = [...alreadyAddedIds, ...[p.id], ...generatorIds]
        sumOfCapacities += p.currentNomCap
      }
    })

    generators?.forEach((g) => {
      if (!alreadyAddedIds.includes(g.id)) {
        alreadyAddedIds = [...alreadyAddedIds, ...[g.id]]
        sumOfCapacities += g?.nomCap || 0
      }
    })

    const capacities = assets.map((asset) => (isCluster(asset) || isPark(asset) ? asset.currentNomCap : asset.nomCap))

    return {
      sum: sumOfCapacities,
      max: Math.max(...capacities) || 0,
      min: Math.min(...capacities) || 0,
      avg: sumOfCapacities ? sumOfCapacities / assets.length : 0,
    }
  }

  const totalAssetsCapacitySummary = useMemo(() => {
    return calculateAssetsCapacitySummary(allAssets)
  }, [allAssets])

  const selectedAssetsCapacitySummary = useMemo(() => {
    return calculateAssetsCapacitySummary(selectedAssets)
  }, [selectedAssets])

  const capacitiesHasDecimals = useMemo(() => {
    return allAssets.some((asset) => getNomcap(asset).toString().split('.').length > 1)
  }, [allAssets])

  const getCapacityInfoComponent = (capacityData: AssetsCapacitySummary) => {
    const value = capacityData[activeAggregation]
    const summaryHasDecimals = value.toString().split('.').length > 1
    const hasDecimal = capacitiesHasDecimals || summaryHasDecimals
    onCapacitySummaryValuesHasFractions(hasDecimal)
    return (
      <Flex flexGrow={1} justifyContent="flex-end">
        <Number
          data={capacityData[activeAggregation] ? capacityData[activeAggregation] : 0}
          limit={hasDecimal ? 3 : 0}
          showFractionalPart={hasDecimal}
        />
      </Flex>
    )
  }

  const capacityHasFractionalNumbers = useMemo(() => {
    const values: (keyof AssetsCapacitySummary)[] = ['sum', 'min', 'max', 'avg']
    const totalAssetsCapacitySummaryHasFractional = values.some(
      (v) => totalAssetsCapacitySummary[v].toString().split('.').length > 1,
    )
    const selectedAssetsCapacitySummaryHasFractional = values.some(
      (v) => selectedAssetsCapacitySummary[v].toString().split('.').length > 1,
    )

    return totalAssetsCapacitySummaryHasFractional || selectedAssetsCapacitySummaryHasFractional
  }, [totalAssetsCapacitySummary, selectedAssetsCapacitySummary])

  useEffect(() => {
    onCapacitySummaryValuesHasFractions(capacityHasFractionalNumbers)
  }, [capacityHasFractionalNumbers])

  return (
    <>
      {getCapacityInfoComponent(
        activeSummary === SummaryType.total ? totalAssetsCapacitySummary : selectedAssetsCapacitySummary,
      )}
    </>
  )
}

export default CapacitySummaryCell
