import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { CellRenderType, Column, RETABLE_ID_FILEMANAGER } from 'modules/reTable/reTable.types'

import FileManagerTableCell from 'modules/data/fileManager/FileManagerTableCell'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'

export const TableCellIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

// const Success = styled.div`
//   color: ${theme.palette.primary.dark};
// `
//
// const Info = styled.div`
//   color: ${theme.palette.secondary.dark};
// `
//
// const Error = styled.div`
//   color: ${theme.palette.error.dark};
// `

interface MeterDataTableRowProps {
  file: MeterdataFile
  columns: Column[]
}

const FileManagerTableRow: React.FC<MeterDataTableRowProps> = ({ file, columns }) => {
  const search = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_FILEMANAGER)
  const [hovered, setHovered] = useState<boolean>(false)

  const handleMouseLeave = () => {
    setHovered(false)
  }

  const handleMouseOver = () => {
    setHovered(true)
  }

  return (
    <ReTableRow onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {columns.map((column) => (
        <ReTableCell
          width={column.width}
          align={column.cellRenderType === CellRenderType.NUMERIC ? 'right' : 'left'}
          key={column.name}
        >
          <FileManagerTableCell hovered={hovered} search={search} column={column} file={file} />
        </ReTableCell>
      ))}
    </ReTableRow>
  )
}

export default React.memo(FileManagerTableRow)
