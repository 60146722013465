import styled from 'styled-components'

import { theme, transitionFor } from 'themes/theme-light'
import { isNumeric } from 'utils/dataFormatting'

interface SliderProps {
  width: string
  right: string
  top?: string
  marginRight?: string
  duration?: number
  zIndex: number
  overflow?: string
}

export const SliderComponent = styled.div<SliderProps>`
  position: absolute;
  top: ${(props) => (props.top ? props.top : '0')};
  right: ${(props) => props.right};
  margin-right: ${(props) => props.marginRight};
  width: ${(props) => props.width};
  height: 100%;
  overflow: ${(props) => props.overflow || 'auto !important'};
  background: white;
  box-shadow: ${theme.shadows[5]};
  z-index: ${(props) => props.zIndex};
  transition: ${(props) => transitionFor(['margin-right', 'right'], isNumeric(props.duration) ? props.duration : 800)};
  @media (max-width: 87em) {
    width: ${(props) => `calc(100vw - ${props.right})`};
  }
`
