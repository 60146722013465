import React from 'react'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'

import { TableCellIcon } from 'modules/data/fileManager/FileManagerTableRow'
import Highlight from 'ui/Highlight'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { Column } from 'modules/reTable/reTable.types'
import { math } from 'polished'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'

interface MeterDataTitleProps {
  file: MeterdataFile
  hovered: boolean
  query: string
  column: Column
}

const FileName = styled.div`
  margin-left: 0.45em;
`

const FileTitle: React.FC<MeterDataTitleProps> = ({ file, query, column }) => {
  // const status = parseFileUploadStatus(file)
  // const dispatch = useDispatch()
  // const user = useSelector(getUserResultSelector)
  // const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)
  //
  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setMenuEl(event.currentTarget)
  // }
  //
  // const handleClose = () => {
  //   setMenuEl(null)
  // }
  //
  // const handleDownloadFile = useCallback(() => {
  //   dispatch({ type: DOWNLOAD_FILE_REQUEST, fileId: file.uuid })
  //   handleClose()
  // }, [])
  //
  // const handleDeactivateFile = useCallback(() => {
  //   dispatch({ type: DEACTIVATE_FILE_REQUEST, fileId: file.uuid })
  //   handleClose()
  // }, [])
  //
  // const handleDeleteFile = useCallback(() => {
  //   dispatch({ type: DELETE_FILE_REQUEST, fileId: file.uuid })
  //   handleClose()
  // }, [])

  return (
    // eslint-disable-next-line react/jsx-no-undef
    <Flex direction="row" alignItems="center" justifyContent="space-between">
      <Flex direction="row" alignItems="center">
        <TableCellIcon icon="file-csv" />
        <FileName>
          <FixedWidthTextContainer width={math(`${column.width} - 3em`)} text={file.originalFileName}>
            <Highlight text={file.originalFileName} query={query} />
          </FixedWidthTextContainer>
        </FileName>
      </Flex>

      {/*Enable this context menu when API's are ready*/}
      {/*{(isAdmin(user) || isImpersonated(user)) && (*/}
      {/*  <>*/}
      {/*    <Flex direction="column" alignItems="center">*/}
      {/*      <MenuIconButton hovered={hovered} size="small" aria-haspopup="true" onClick={handleClick}>*/}
      {/*        <FontAwesomeIcon aria-controls="simple-menu" aria-haspopup="true" icon="ellipsis-v" />*/}
      {/*      </MenuIconButton>*/}
      {/*      <Menu id="simple-menu" anchorEl={menuEl} open={Boolean(menuEl)} onClose={handleClose}>*/}
      {/*        <MenuItem disabled onClick={handleDownloadFile}>*/}
      {/*          <ListItemIcon>*/}
      {/*            <FontAwesomeIcon icon="download" />*/}
      {/*          </ListItemIcon>*/}
      {/*          <ListItemText>{t`Download`}</ListItemText>*/}
      {/*        </MenuItem>*/}
      {/*        <MenuItem disabled onClick={handleDeactivateFile}>*/}
      {/*          <ListItemIcon>*/}
      {/*            <FontAwesomeIcon icon="times-circle" />*/}
      {/*          </ListItemIcon>*/}
      {/*          <ListItemText>{t`Deactivate`}</ListItemText>*/}
      {/*        </MenuItem>*/}
      {/*        <MenuItem disabled onClick={handleDeleteFile}>*/}
      {/*          <ListItemIcon>*/}
      {/*            <FontAwesomeIcon icon="trash-alt" />*/}
      {/*          </ListItemIcon>*/}
      {/*          <ListItemText>{t`Delete`}</ListItemText>*/}
      {/*        </MenuItem>*/}
      {/*      </Menu>*/}
      {/*    </Flex>*/}
      {/*  </>*/}
      {/*)}*/}
    </Flex>
  )
}

export default React.memo(FileTitle)
