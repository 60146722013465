import React, { useEffect, useMemo } from 'react'
import { useChart } from 'react-jsx-highstock'

import { Timezone } from 'fixtures/timezones'
import { getTimezoneOffset } from 'utils/date'

interface TimeOptionsProps {
  timezone: Timezone
  seriesStartDate?: Date | number | string
}

const TimeOptions: React.FC<TimeOptionsProps> = ({ timezone, seriesStartDate }) => {
  const chart = useChart()

  const timezoneOffset = useMemo(() => {
    return getTimezoneOffset(timezone, seriesStartDate ? new Date(seriesStartDate) : new Date())
  }, [timezone, seriesStartDate])

  useEffect(() => {
    if (!chart) return

    chart.update({
      time: { timezoneOffset },
    })
  }, [chart, timezoneOffset])

  return null
}

export default React.memo(TimeOptions)
