import styled from 'styled-components'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { lighten } from '@material-ui/core'
import { theme } from 'themes/theme-light'
import React from 'react'

interface RowProps {
  selected: boolean
  light_mode: boolean // Written in snake case because of warning from styled components 'suppressClassNameWarnings'
  is_shaded?: number
}

// styled components
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const StyledReTableRow = styled(({ detailsVisible, light_mode, is_shaded, ...rest }) => (
  <ReTableRow {...rest} />
))<RowProps>`
  &.MuiTableRow-root.Mui-selected {
    background-color: ${(props) => lighten(theme.palette.primary.main, props.light_mode ? 0.8 : 0)};
  }
  &.MuiTableRow-root.Mui-selected:hover {
    background-color: ${(props) => lighten(theme.palette.primary.main, props.light_mode ? 0.85 : 0.1)};
  }
  &.MuiTableRow-root.Mui-selected .MuiTableCell-body {
    color: ${(props) => (props.light_mode ? 'inherit' : 'white')};
  }
  *.MuiTableCell-body {
    color: ${(props) => (props.is_shaded ? 'grey' : 'inherit')};
  }
`
