import React from 'react'
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'

import { theme } from 'themes/theme-light'

interface FontAwesomeActionIconProps {
  disabled?: boolean
}

const FontAwesomeActionIcon: React.FC<FontAwesomeActionIconProps & FontAwesomeIconProps> = ({
  disabled = false,
  ...rest
}) => <FontAwesomeIcon color={disabled ? theme.palette.text.disabled : theme.palette.primary.main} {...rest} />

export default React.memo(FontAwesomeActionIcon)
