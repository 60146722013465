import React, { useCallback, useEffect, useState } from 'react'
import { EmailAddress } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import Flex from 'ui/styles/Flex'
import { Box, Chip, TextField } from '@material-ui/core'
import { t } from 'ttag'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@material-ui/core/IconButton'
import { authCommonErrorMsgs, validate } from 'utils/formValidations'

const EmailChip = styled(Chip)`
  margin-right: 0.4em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
`

const Container = styled(Flex)`
  & .MuiFormControl-root {
    width: 100%;
  }
  width: 100%;
`

const FieldError = styled.div`
  color: red;
  margin-left: 5px;
`

interface MultipleEmailsInputProps {
  onChange: (emails: EmailAddress[]) => void
  initialEmails: EmailAddress[]
  fieldErrorMsg: string
  addMultiple?: boolean
}

const MultipleEmailsInput: React.FC<MultipleEmailsInputProps> = ({
  onChange,
  initialEmails,
  fieldErrorMsg,
  addMultiple = true,
}) => {
  const [activeEmails, setActiveEmails] = useState<EmailAddress[] | null>(initialEmails)
  const [value, setValue] = useState<string>('')
  const [showInputField, setShowInputField] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>('')

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    const commonErrorMsgs = authCommonErrorMsgs()
    const error = !validate.email(event.target.value) ? commonErrorMsgs.email : ''
    setErrorMsg(error)
  }, [])

  const handleAddEmail = useCallback(
    (emails) => {
      const emailList = [
        ...(emails || []),
        {
          address: value,
          valid: null,
        },
      ]

      setActiveEmails([emailList])
      setValue('')
      setErrorMsg('')
      onChange(emailList)
      if (!addMultiple && emailList.length) {
        setShowInputField(false)
      }
    },
    [value, addMultiple],
  )

  const handleDeleteEmail = useCallback((item: EmailAddress, emails) => {
    const data = emails?.filter((e) => e.address !== item.address)
    setActiveEmails(data)
    onChange(data)
  }, [])

  const handleShowInputField = () => {
    setShowInputField(true)
  }

  const handleHideInputField = () => {
    setValue('')
    setErrorMsg('')
    setShowInputField(false)
  }

  useEffect(() => {
    setActiveEmails(initialEmails)
  }, [initialEmails])

  return (
    <Container direction="column">
      <Flex alignItems="center">
        <Box>
          {(activeEmails || []).map((e) => (
            <EmailChip
              color="primary"
              key={e.address}
              label={e.address}
              onDelete={() => handleDeleteEmail(e, activeEmails)}
            />
          ))}
          {!showInputField && (addMultiple || initialEmails.length === 0) && (
            <IconButton color="primary" title={t`Add email`} size="small" onClick={handleShowInputField}>
              <FontAwesomeIcon icon="plus" />
            </IconButton>
          )}
        </Box>
        {!showInputField && <FieldError>{fieldErrorMsg}</FieldError>}
      </Flex>

      {showInputField && (
        <Flex alignItems="center">
          <TextField error={Boolean(errorMsg)} helperText={errorMsg} onChange={handleChange} value={value} />
          <Box ml={0.4}>
            <IconButton size="small" onClick={handleHideInputField}>
              <FontAwesomeIcon icon="times" />
            </IconButton>
          </Box>
          <Box ml={0.4}>
            <IconButton
              disabled={Boolean(errorMsg) || !value}
              color="primary"
              size="small"
              onClick={() => handleAddEmail(activeEmails)}
            >
              <FontAwesomeIcon icon="check" />
            </IconButton>
          </Box>
        </Flex>
      )}
    </Container>
  )
}

export default React.memo(MultipleEmailsInput)
