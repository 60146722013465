import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import PopperTooltip from 'ui/PopperTooltip'
import { math } from 'polished'

interface TextContainerProps {
  width?: string | number
}

const TextContainer = styled.div<TextContainerProps>`
  width: ${(props) => props.width || 'inherit'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
`

const TooltipContainer = styled.div<TextContainerProps>`
  max-width: ${(props) => props.width || 'inherit'};
  word-break: break-word;
`

interface FixedWidthTextContainerProps {
  children: React.ReactNode
  width: string | number
  text: string
  toolTipContent?: React.ReactNode
  showTooltipByDefault?: boolean
  tableToolbarHasActions?: boolean
}

const FixedWidthTextContainer: React.FC<FixedWidthTextContainerProps> = ({
  width,
  text,
  children,
  toolTipContent,
  showTooltipByDefault = false,
  tableToolbarHasActions = false,
}) => {
  const containerRef = useRef(null)
  const [textOverFlowed, setTextOverFlowed] = useState<boolean>()
  const containerWidth = useMemo(
    () => (width !== 'inherit' && tableToolbarHasActions ? math(`${width}- 0.4em`) : width),
    [width, tableToolbarHasActions],
  )

  const handleContentClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const getTooltipContent = useCallback(() => {
    return (
      <TooltipContainer onClick={handleContentClick} width={containerWidth}>
        {text}
      </TooltipContainer>
    )
  }, [width, text])

  useEffect(() => {
    if (containerRef?.current) {
      // This will tell whether the div is overflowed or not
      setTextOverFlowed(containerRef.current?.offsetWidth < containerRef.current?.scrollWidth)
    }
  }, [text])

  return (
    <PopperTooltip
      popperLabel={
        <TextContainer ref={containerRef} width={containerWidth}>
          {children}
        </TextContainer>
      }
      popperContent={textOverFlowed || showTooltipByDefault ? toolTipContent || getTooltipContent() : ''}
    />
  )
}

export default React.memo(FixedWidthTextContainer)
