import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { c } from 'ttag'

export const getMasterDataCustomFormatTableColumns: () => Column[] = () => [
  {
    name: 'displayName',
    label: c('Master data').t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '30em',
    fieldName: 'displayName',
    isEditable: true,
    fixed: true,
  },
  {
    name: 'lastModified',
    label: c('Delivery:Target').t`Last modified`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '14em',
    fieldName: 'lastModified',
    isEditable: false,
    minDate: new Date(1995, 1, 1),
    fixed: true,
  },
]
