import React from 'react'
import styled from 'styled-components'
import { UserManagementType, UserStatus } from 'modules/userManagement/userManagement.types'
import Flex from 'ui/styles/Flex'
import { getAuthoritiesLabelHandler, preDefinedAllAuthorities } from 'utils/userManagement'
import { Column } from 'modules/reTable/reTable.types'
import { Box } from '@material-ui/core'
import PopperTooltip from 'ui/PopperTooltip'
import Highlight from 'ui/Highlight'

interface RoleContainerProps {
  container_width: string
}
const RoleContainer = styled(Box)<RoleContainerProps>`
  width: ${(props) => props.container_width};
`

interface RoleChipProps {
  danger: number
  nonActive?: string
}

export const RoleChip = styled.div<RoleChipProps>`
  background-color: ${(props) => {
    if (props.nonActive === UserStatus.DEACTIVATED || props.nonActive === UserStatus.EXPIRED) {
      return 'rgba(128, 128, 128, 0.5);'
    }
    return props.danger ? 'red' : '#0cb0f2'
  }};
  color: white;
  margin-right: 0.5em;
  font-size: 11px;
  padding: 0 5px;
  border-radius: 2px;
  width: fit-content;
  font-weight: bold;
`

const ToolTipContent = styled(Flex)`
  max-width: 21em;
`

interface RoleCellProps {
  user: UserManagementType
  column: Column
  query: string
}

const IMPORTANT_ROLES = {
  ADMIN: 'ADMIN',
  PARTNER: 'PARTNER',
  DEMO: 'DEMO',
  DEV: 'DEV',
  ENERCAST: 'ENERCAST',
  VPP: 'VPP',
}

const RoleCell: React.FC<RoleCellProps> = ({ user, column, query }) => {
  // Sorting roles from a list that can be found in Confluence.
  const roles = preDefinedAllAuthorities()
  user?.authorities?.sort((a, b) => {
    return roles.indexOf(a) - roles.indexOf(b)
  })

  // Removing the world 'ROLE', to keep it clean.
  const transformedRoles = (user?.authorities || []).map((authority) => getAuthoritiesLabelHandler(authority))

  const commaSeparated = transformedRoles.join(',')
  const subString = commaSeparated.length > 40 ? commaSeparated.substring(0, 40) : commaSeparated
  const subStringArr = subString.split(',')
  const lastOne = subStringArr[subStringArr.length - 1]
  const endIndex =
    transformedRoles.indexOf(lastOne) === -1 ? subStringArr.length - 2 : transformedRoles.indexOf(lastOne)
  const rolesToDisplay = (transformedRoles || []).slice(0, endIndex + 1)
  const hiddenRoles = (transformedRoles || []).slice(endIndex + 1)

  return (
    <RoleContainer container_width={column.width}>
      <Flex alignItems="center">
        {rolesToDisplay.map((authority) => {
          const isDangerRole =
            authority === IMPORTANT_ROLES.ADMIN ||
            authority === IMPORTANT_ROLES.PARTNER ||
            authority === IMPORTANT_ROLES.DEMO ||
            authority === IMPORTANT_ROLES.DEV ||
            authority === IMPORTANT_ROLES.ENERCAST ||
            authority === IMPORTANT_ROLES.VPP
          return (
            <RoleChip nonActive={user?.uiUserStatus} danger={isDangerRole ? 1 : 0} key={authority}>
              <Highlight text={authority} query={query} />
            </RoleChip>
          )
        })}
        {hiddenRoles.length > 0 && (
          <PopperTooltip
            popperLabel={`+${hiddenRoles.length}`}
            popperContent={
              <ToolTipContent flexWrap="wrap">
                {(hiddenRoles || []).map((authority) => {
                  const isDangerRole =
                    authority === IMPORTANT_ROLES.ADMIN ||
                    authority === IMPORTANT_ROLES.PARTNER ||
                    authority === IMPORTANT_ROLES.DEMO ||
                    authority === IMPORTANT_ROLES.DEV ||
                    authority === IMPORTANT_ROLES.ENERCAST ||
                    authority === IMPORTANT_ROLES.VPP
                  return (
                    <Box key={authority} mt={0.5} mb={0.5}>
                      <RoleChip nonActive={user?.uiUserStatus} danger={isDangerRole ? 1 : 0}>
                        <Highlight text={authority} query={query} />
                      </RoleChip>
                    </Box>
                  )
                })}
              </ToolTipContent>
            }
          />
        )}
      </Flex>
    </RoleContainer>
  )
}

export default React.memo(RoleCell)
