import styled from 'styled-components'
import { theme } from 'themes/theme-light'

interface ShowMoreLessLinkProps {
  link_cursor?: 'string'
}

export const ShowMoreLessLink = styled.span<ShowMoreLessLinkProps>`
  &:hover {
    text-decoration: underline;
  }
  color: ${theme.palette.primary.main};
  cursor: ${(props) => (props.link_cursor ? props.link_cursor : 'pointer')};
`
