import { useHistory } from 'react-router-dom'
import qs from 'query-string'

const addQueryToLocation = (value: Record<string, unknown>): string => {
  const location = window.location
  const existingQueryParamString = location.hash.split('?')[1] || ''
  const values = qs.parse(existingQueryParamString, { sort: false })
  const newQsValue = qs.stringify({ ...values, ...value }, { sort: false })
  return `?${newQsValue}`
}

const removeQueriesFromLocation = (keys: string[]): string => {
  const location = window.location
  const existingQueryParamString = location.hash.split('?')[1] || ''
  const values = qs.parse(existingQueryParamString, { sort: false })
  keys.map((key) => {
    delete values[key]
  })
  const newQsValue = qs.stringify(values)
  return `?${newQsValue}`
}

export const useUpdateQueryString = () => {
  const history = useHistory()

  const onAddQueryString = (newQueryObj: Record<string, unknown>) => {
    const newLocationWithQueryParams = addQueryToLocation(newQueryObj)
    history.push(`${newLocationWithQueryParams}`)
  }

  const onDeleteQueryStrings = (queryKeys: string[]) => {
    const newLocationWithoutQueryParams = removeQueriesFromLocation(queryKeys)
    history.push(`${newLocationWithoutQueryParams}`)
  }

  return {
    onAddQueryString,
    onDeleteQueryStrings,
  }
}
