// Important
import React, { useMemo } from 'react'
import { FormApi } from 'final-form'
import { UseMutationResult } from 'react-query'
import { AnyObject, useField } from 'react-final-form'
import { UserManagementType } from 'modules/userManagement/userManagement.types'
import { useDebounce } from 'use-debounce'
import { FormContainer } from 'ui/form/form.style'
import { c } from 'ttag'
import { QUERY_USER_MANAGEMENT_DETAILS } from 'utils/query-string'
import { ROUTE_USER_MANAGEMENT } from 'modules/app/Routes'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import Flex from 'ui/styles/Flex'
import UserManagementSectionHeader from 'modules/userManagement/UserManagementSectionHeader'
import { KeyValuePair } from 'ui/form/assetForm.style'
import { Box } from '@material-ui/core'
import UserManagementDetailsFields from 'modules/userManagement/UserManagementDetailsFields'

// Interface
interface UserManagementFormProps {
  isNew: boolean
  form: FormApi
  saveResult: UseMutationResult<UserManagementType, Error>
  selectedUserDetails: UserManagementType
  onCloseSlider: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  allUsers: UserManagementType[]
}

const UserManagementForm: React.FC<UserManagementFormProps> = ({
  isNew,
  form,
  onFormSubmit,
  onCloseSlider,
  saveResult,
  allUsers,
  selectedUserDetails,
}) => {
  const formDirty = useMemo(() => form.getState().dirty, [form.getState().dirty])
  const userLogin = useField<string>('login').input.value
  const loading = saveResult.isLoading

  const formInvalid = useMemo(() => {
    return isNew ? form.getState().submitFailed && form.getState().invalid : form.getState().invalid
  }, [form.getState().invalid, form.getState().submitFailed, isNew])
  const [formInvalidDebounced] = useDebounce(formInvalid, 200)

  return (
    <FormContainer>
      <form onSubmit={onFormSubmit} noValidate>
        <FormBlockNavigation
          blockWithExternalChanges={false}
          navigationDialogText={userLogin ? userLogin : c('UserManagement').t`New user`}
          form={form}
          currentPageQueries={[QUERY_USER_MANAGEMENT_DETAILS]}
          currentPageRoute={ROUTE_USER_MANAGEMENT}
          navigationDialogKey={'userManagement'}
        />
        <Flex direction="column" fullHeight>
          <UserManagementSectionHeader
            form={form}
            isNewUserManagementUser={isNew}
            enableSaveBtn={formDirty}
            onCloseSlider={onCloseSlider}
            onFormSubmit={onFormSubmit}
            saveResult={saveResult}
            loading={loading}
            formInvalid={formInvalidDebounced}
          />
          <KeyValuePair>
            <Box mt={0.5}>
              <UserManagementDetailsFields
                allUsers={allUsers}
                isNew={isNew}
                form={form}
                selectedUserDetails={selectedUserDetails}
              />
            </Box>
          </KeyValuePair>
        </Flex>
      </form>
    </FormContainer>
  )
}

export default UserManagementForm
