import React from 'react'
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

const DateTimePickerFinalForm = (props) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    ...rest
  } = props
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {props.onlyDate ? (
        <KeyboardDatePicker
          {...rest}
          name={name}
          helperText={showError ? meta.error || meta.submitError : undefined}
          error={showError}
          inputProps={restInput}
          onChange={onChange}
          value={value === '' ? null : value}
          format={'yyyy-MM-dd'}
        />
      ) : (
        <KeyboardDateTimePicker
          {...rest}
          ampm={false}
          name={name}
          helperText={showError ? meta.error || meta.submitError : undefined}
          error={showError}
          inputProps={restInput}
          onChange={onChange}
          value={value === '' ? null : value}
          format={'yyyy-MM-dd HH:mm'}
        />
      )}
    </MuiPickersUtilsProvider>
  )
}

export default React.memo(DateTimePickerFinalForm)
