import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'

import AssetIcon from 'modules/asset/AssetIcon'
import { Asset } from 'modules/asset/store/asset.types'
import { getNomcap } from 'utils/asset'
import Number from 'ui/Number'
import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'
import Highlight from 'ui/Highlight'
import { AppUnits } from 'utils/units'

interface CompactProps {
  compact: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Icon = styled.div`
  margin-right: 1em;
`

const Info = styled.div<CompactProps>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.compact ? 'margin-top: -0.4em;' : '')}
`

const Label = styled.div<CompactProps>`
  ${(props) => (props.compact ? '' : 'font-weight: bold;')}
`

const NomCap = styled.div`
  font-size: 0.8em;
`

interface AssetItemProps {
  asset: Asset
  highlight?: string
  compact?: boolean
}

const AssetItem: React.FC<AssetItemProps> = ({ asset, highlight = '', compact = true }) => {
  return (
    <Container>
      {asset && (
        <Icon>
          <AssetIcon asset={asset} size={AssetLinkIconSize} />
        </Icon>
      )}
      <Info compact={compact}>
        <Label compact={compact}>
          <Highlight text={asset.name} query={highlight} />
        </Label>
        {!compact && (
          <NomCap>
            {t`Nameplate capacity`}: <Number unit={AppUnits.KILO_WATT} data={getNomcap(asset)} limit={4} />
          </NomCap>
        )}
      </Info>
    </Container>
  )
}

export default React.memo(AssetItem)
