import React from 'react'
import { QUERY_PRINT_VIEW, useQueryString } from 'utils/query-string'
import { ChartWidget } from 'modules/workspace/store/workspace.types'
import { colors } from 'themes/theme-light'
import styled from 'styled-components'
import { t } from 'ttag'
import { PRINT_VIEW_RELOAD } from 'utils/user'

interface PrintButtonProps {
  disabled: boolean
}

export const StyledMonthlyViewButton = styled.div<PrintButtonProps>`
  color: ${(props) => (props.disabled ? 'grey' : colors.primaryMain)};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  margin-left: 0.5em;
`

interface PrintViewButtonProps {
  widgetName: ChartWidget
  disabled: boolean
}

const PrintViewButton: React.FC<PrintViewButtonProps> = ({ widgetName, disabled }) => {
  const { onUpdateQueryString } = useQueryString()

  const handleGoToPrintView = () => {
    if (disabled) {
      return
    }
    // Save a redirection helper in session storage
    localStorage.setItem(PRINT_VIEW_RELOAD, JSON.stringify(true))

    onUpdateQueryString({ [QUERY_PRINT_VIEW]: widgetName })
  }

  return (
    <StyledMonthlyViewButton disabled={disabled} onClick={handleGoToPrintView}>
      {t`Print view`}
    </StyledMonthlyViewButton>
  )
}

export default PrintViewButton
