import { Asset } from 'modules/asset/store/asset.types'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTable from 'modules/reTable/ReTable'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { RETABLE_ID_QUALITY_PENALTY, ReTableItem } from 'modules/reTable/reTable.types'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'

import React, { useEffect, useMemo, useState } from 'react'
import { QueryObserverResult } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { table } from 'themes/theme-light'
import PenaltyCalculationTableBody from 'modules/data/penalties/penaltyCalculations/PenaltyCalculationTableBody'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { PenaltyCalculationResult } from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import {
  defaultSelectedColumnForPenaltyCalculationTable,
  getPenaltyCalculationsTableColumns,
  PenaltyCalculationColumnType,
} from 'utils/penaltyCalculations'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import { getNomcap } from 'utils/asset'

interface PenaltyTableProps {
  penaltyResults: QueryObserverResult<PenaltyCalculationResult>[]
  assets: Asset[]
  selectedAssetsAndModelIds: string[]
}

const PenaltyCalculationTable: React.FC<PenaltyTableProps> = ({ penaltyResults, assets }) => {
  const dispatch = useDispatch()
  const items = useMemo<ReTableItem[]>(() => {
    return assets.map((asset) => {
      return {
        ...asset,
        id: asset.id,
        name: asset.name,
      }
    })
  }, [assets])

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_QUALITY_PENALTY)

  const [columnsSelectedWithDynamicWidth, setColumnsSelectedWithDynamicWidth] = useState<any>()

  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const columnsAvailable = useMemo(() => getPenaltyCalculationsTableColumns(), [])

  const atLeastOneResultHasUnderInjectionBands = useMemo(() => {
    const resultWithUnderInjection = penaltyResults.filter(
      (result) => result?.data?.summaryResult?.underInjectionPenaltyBands?.length > 0 && !result.isLoading,
    )
    return resultWithUnderInjection.length > 0
  }, [penaltyResults])

  const filteredAvailableColumns = useMemo(() => {
    const { OVER_INJECTION, UNDER_INJECTION } = PenaltyCalculationColumnType

    const filteredColumns = atLeastOneResultHasUnderInjectionBands
      ? [...columnsAvailable]
      : [...columnsAvailable].filter((col) => col.helperValue !== UNDER_INJECTION && col.helperValue !== OVER_INJECTION)
    return filteredColumns
  }, [assetSelection, columnsAvailable, atLeastOneResultHasUnderInjectionBands])

  const defaultColumnsSelected = useMemo(() => defaultSelectedColumnForPenaltyCalculationTable(), [])

  const showNumCapFraction = useMemo(() => items.some((asset) => getNomcap(asset).toString().split('.').length > 1), [
    items,
  ])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_QUALITY_PENALTY,
      columnsAvailable: filteredAvailableColumns,
    })
  }, [filteredAvailableColumns])

  useEffect(() => {
    if (columnsSelected.length > 0) {
      const columns = columnsSelected.map((column) => column.name)

      dispatch({
        type: RETABLE_SET_COLUMNS_SELECTED,
        table: RETABLE_ID_QUALITY_PENALTY,
        columnsSelected: columns,
      })
    }
  }, [columnsSelected])

  useEffect(() => {
    if (columnsSelected.length > 0) {
      const columnsSelectedCopy = [...columnsSelected]
      const lastItemIndex = columnsSelectedCopy.length - 1
      const copyOfLastItem = { ...columnsSelectedCopy[lastItemIndex] }
      copyOfLastItem.customDynamicWidth = 'auto'
      columnsSelectedCopy[lastItemIndex] = copyOfLastItem
      setColumnsSelectedWithDynamicWidth(columnsSelectedCopy)
    }
  }, [columnsSelected])

  return (
    <>
      <ReTable
        id={RETABLE_ID_QUALITY_PENALTY}
        itemHeight={table.rowHeight}
        items={items}
        itemCount={1}
        defaultColumnsSelected={defaultColumnsSelected}
        hasExtendedHeader={false}
        itemsPaddingHeader={ReTableGenericAttributes.ITEMS_PADDING_HEADER}
        itemsPaddingFooter={ReTableGenericAttributes.ITEMS_PADDING_FOOTER}
        renderAheadCount={30}
      >
        <ReTableGenericHeader
          tableRowsHasCrudActions={false}
          isHierarchical={false}
          columns={columnsSelectedWithDynamicWidth?.length > 0 ? columnsSelectedWithDynamicWidth : columnsSelected}
          inheritWidthForHeaderWithSort={true}
        />

        <PenaltyCalculationTableBody
          items={penaltyResults}
          columns={columnsSelectedWithDynamicWidth?.length > 0 ? columnsSelectedWithDynamicWidth : columnsSelected}
          showNumCapFraction={showNumCapFraction}
        />
      </ReTable>
    </>
  )
}

export default React.memo(PenaltyCalculationTable)
