import React from 'react'
import { IconSelectedColor, SvgIconPath } from 'ui/styles/SvgIconPath'
import { AssetIconProps } from 'ui/icons/ClusterPoolIcon'
import { getTypeLabel } from 'utils/asset'
import { TYPE_CHP } from 'modules/asset/store/asset.types'

const CHPIcon: React.FC<AssetIconProps> = ({ iconSelected, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="0 100 900 475"
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid meet"
    >
      <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />

      <g className="currentLayer">
        <title>{getTypeLabel(TYPE_CHP)}</title>
        <g transform="translate(0.000000,675.000000) scale(0.100000,-0.100000)">
          <SvgIconPath
            d="M7991 6115 c-22 -30 -41 -58 -41 -64 0 -5 -7 -11 -15 -15 -8 -3 -15
              -12 -15 -21 0 -8 -4 -15 -10 -15 -5 0 -10 -6 -10 -12 0 -7 -15 -28 -32 -47
              -18 -19 -39 -47 -48 -62 -8 -16 -26 -41 -40 -56 -14 -15 -36 -45 -49 -66 -13
              -21 -37 -53 -52 -70 -16 -17 -29 -36 -29 -43 0 -6 -13 -24 -30 -40 -16 -16
              -30 -35 -30 -41 0 -7 -4 -13 -9 -13 -5 0 -12 -8 -16 -19 -3 -10 -19 -31 -35
              -47 -16 -16 -30 -33 -30 -38 0 -7 -54 -81 -70 -96 -14 -14 -50 -70 -50 -80 0
              -5 -4 -10 -10 -10 -5 0 -16 -15 -25 -33 -9 -19 -31 -49 -50 -68 -19 -19 -35
              -38 -35 -42 0 -5 -20 -35 -45 -67 -25 -32 -45 -61 -45 -65 0 -6 -14 -24 -41
              -53 -9 -9 -25 -30 -35 -47 -25 -39 -35 -53 -56 -73 -10 -9 -18 -22 -18 -29 0
              -7 -4 -13 -10 -13 -5 0 -10 -7 -10 -15 0 -8 -9 -20 -20 -27 -11 -7 -20 -17
              -20 -22 0 -5 -8 -15 -17 -22 -43 -31 -2 -35 421 -37 447 -2 446 -2 430 -52 -3
              -11 -12 -42 -18 -70 -7 -27 -16 -57 -19 -65 -4 -8 -11 -35 -16 -60 -22 -100
              -34 -146 -43 -157 -5 -6 -13 -33 -18 -60 -4 -26 -16 -75 -25 -108 -9 -33 -20
              -80 -25 -104 -6 -24 -14 -49 -19 -55 -5 -6 -12 -33 -16 -61 -4 -27 -10 -54
              -15 -60 -4 -5 -11 -31 -14 -57 -4 -26 -11 -50 -17 -53 -5 -4 -9 -17 -9 -30 0
              -13 -7 -45 -16 -70 -8 -26 -14 -51 -12 -56 2 -6 29 25 61 68 31 43 64 85 72
              94 8 9 15 19 15 23 0 8 52 78 77 104 7 7 13 18 13 23 0 10 10 24 41 57 8 9 30
              40 48 67 19 28 40 54 47 58 8 4 14 15 14 22 0 8 4 15 9 15 5 0 11 6 13 14 2 7
              16 28 31 47 27 33 106 142 137 189 8 13 23 30 33 39 9 8 17 19 17 23 0 8 106
              157 133 188 7 8 28 38 46 65 19 28 40 54 47 58 8 4 14 12 14 18 0 5 5 15 10
              22 6 7 33 44 60 82 27 39 55 77 63 85 7 8 19 27 26 42 8 15 29 42 47 60 18 18
              34 39 34 45 0 10 -93 13 -417 15 l-418 3 -3 33 c-2 18 1 39 7 46 6 7 14 35 18
              62 3 26 12 59 20 74 7 14 13 39 13 56 0 17 3 34 7 38 8 7 24 73 45 176 6 30
              14 60 18 65 5 6 11 28 15 50 4 22 9 47 13 55 6 17 28 110 42 183 5 27 13 54
              18 60 5 7 13 35 17 62 4 28 10 55 15 60 10 13 18 95 9 95 -4 0 -25 -25 -48
              -55z"
            id="svg_2"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M360 5673 c0 -5 0 -1106 0 -2448 l0 -2440 2133 0 2132 0 2 912 3 913
              -163 2 -162 3 -5 620 -5 620 -1560 0 -1560 0 -5 -620 -5 -620 -156 -3 c-125
              -2 -159 0 -168 12 -9 10 -12 391 -14 1532 l-2 1519 -232 3 c-128 1 -233 -1
              -233 -5z m2431 -2125 c4 -15 3 -44 -3 -65 -5 -21 -14 -65 -19 -98 -5 -33 -14
              -89 -20 -123 -7 -35 -15 -112 -20 -170 -4 -59 -11 -127 -16 -152 l-8 -45 -70
              -6 c-38 -3 -83 -13 -100 -22 -45 -25 -80 -22 -101 11 -26 39 -54 94 -54 108 0
              6 -9 19 -20 29 -11 10 -20 26 -20 36 0 10 -11 39 -25 65 -14 26 -25 54 -25 63
              0 9 -6 26 -14 37 -26 36 -30 89 -10 123 10 17 24 31 30 31 7 0 25 13 41 28 15
              15 45 36 66 45 20 10 37 21 37 26 0 4 19 15 43 25 23 10 56 25 72 32 17 7 50
              18 75 24 25 6 59 15 75 20 52 15 78 8 86 -22z m384 -108 c3 -5 27 -10 52 -10
              25 0 64 -8 87 -19 23 -10 55 -23 71 -27 21 -7 31 -17 33 -33 4 -23 -11 -43
              -72 -99 -27 -25 -166 -209 -166 -220 0 -6 -7 -12 -15 -16 -8 -3 -15 -12 -15
              -21 0 -8 -5 -15 -10 -15 -6 0 -14 -10 -19 -23 -5 -13 -20 -36 -34 -53 -13 -16
              -26 -38 -28 -49 -3 -12 -17 -23 -37 -29 -27 -8 -38 -5 -62 12 -16 12 -40 22
              -53 22 -12 0 -28 5 -35 12 -6 6 -31 14 -54 17 l-43 6 1 130 c1 118 21 303 40
              365 10 34 21 40 74 40 25 0 52 5 60 10 20 13 217 13 225 0z m-1010 -55 c5 -22
              16 -50 23 -62 6 -12 12 -28 12 -36 0 -8 7 -28 15 -43 8 -16 15 -37 15 -46 0
              -10 5 -18 10 -18 6 0 10 -8 10 -18 0 -10 7 -27 15 -38 8 -10 15 -29 15 -41 0
              -12 6 -28 13 -35 13 -14 57 -100 57 -112 0 -4 11 -24 25 -44 40 -59 34 -82
              -32 -125 -32 -20 -73 -55 -92 -77 -35 -42 -74 -53 -85 -25 -3 8 -12 15 -20 15
              -8 0 -16 3 -18 8 -4 8 -65 52 -73 52 -3 0 -11 5 -18 10 -35 30 -107 80 -114
              80 -4 0 -25 15 -45 34 -21 19 -47 37 -58 41 -17 5 -20 15 -20 56 0 30 8 65 19
              87 11 20 28 55 38 76 33 72 100 162 174 235 57 57 77 71 98 69 20 -2 27 -11
              36 -43z m1301 -215 c73 -32 118 -54 124 -61 3 -3 21 -14 41 -23 20 -9 75 -55
              123 -102 78 -76 87 -89 84 -117 -3 -28 -8 -33 -43 -43 -47 -12 -70 -20 -110
              -39 -16 -8 -42 -17 -57 -21 -16 -3 -28 -10 -28 -15 0 -5 -8 -9 -18 -9 -10 0
              -27 -7 -38 -15 -10 -8 -26 -15 -35 -15 -9 0 -22 -7 -29 -15 -7 -8 -20 -15 -30
              -15 -10 0 -23 -7 -30 -15 -7 -8 -20 -15 -30 -15 -10 0 -23 -7 -30 -15 -7 -8
              -21 -15 -32 -15 -10 0 -21 -7 -24 -15 -4 -9 -19 -15 -40 -15 -27 0 -37 5 -46
              25 -6 14 -15 25 -20 25 -4 0 -8 5 -8 11 0 13 -74 89 -86 89 -5 0 -15 13 -22
              30 -12 27 -11 32 12 63 14 19 26 39 26 45 0 6 8 17 18 24 10 7 26 30 36 51 10
              21 24 41 32 44 8 3 14 11 14 19 0 8 5 14 10 14 6 0 13 8 17 18 7 20 64 95 76
              101 5 2 13 14 18 28 8 22 14 25 43 20 18 -3 55 -15 82 -27z m-1749 -225 c29
              -29 58 -54 128 -108 63 -49 148 -107 155 -107 6 0 10 -4 10 -9 0 -9 107 -81
              120 -81 5 0 17 -11 28 -25 17 -22 18 -30 9 -60 -6 -19 -14 -35 -18 -35 -5 0
              -11 -17 -14 -37 -3 -21 -10 -45 -14 -53 -5 -9 -12 -30 -15 -47 -6 -24 -14 -33
              -34 -37 -27 -6 -242 7 -386 22 -93 11 -111 27 -122 110 -9 76 0 348 13 364 6
              7 14 30 17 51 12 67 26 87 59 87 20 0 40 -11 64 -35z m1107 -104 c3 -4 21 -11
              40 -14 39 -6 149 -59 172 -82 9 -8 18 -15 22 -15 10 0 92 -76 92 -85 0 -4 14
              -24 30 -45 17 -21 30 -42 30 -47 0 -6 9 -20 20 -33 11 -13 20 -35 20 -50 0
              -14 5 -31 11 -37 6 -6 11 -62 12 -134 2 -101 -2 -135 -18 -184 -11 -33 -22
              -62 -25 -65 -3 -3 -11 -16 -18 -30 -12 -25 -29 -53 -47 -77 -19 -26 -97 -103
              -105 -103 -6 0 -22 -12 -67 -50 -7 -5 -16 -10 -20 -10 -4 -1 -26 -9 -48 -20
              -22 -11 -49 -19 -61 -20 -11 0 -25 -5 -30 -10 -16 -16 -202 -17 -254 -2 -153
              46 -318 178 -378 304 -112 231 -71 465 111 648 74 74 140 120 172 120 8 0 15
              5 15 10 0 6 10 10 23 10 12 0 33 7 47 14 28 16 245 22 254 7z m960 -139 c4
              -10 19 -33 34 -50 46 -54 62 -76 62 -88 0 -7 3 -14 8 -16 15 -6 82 -157 82
              -185 0 -10 5 -24 11 -30 14 -14 22 -115 10 -132 -8 -13 -19 -13 -73 -2 -125
              26 -396 58 -510 60 -41 1 -50 5 -73 35 -20 27 -27 49 -30 97 -2 34 -9 67 -14
              72 -6 6 -11 26 -11 45 0 36 30 71 61 72 9 0 22 7 29 15 7 8 20 15 30 15 10 0
              23 7 30 15 7 8 19 15 26 15 8 0 40 14 71 30 31 17 60 30 65 30 6 0 24 6 41 14
              67 30 91 34 119 20 15 -8 29 -22 32 -32z m-2286 -342 c32 -17 310 -47 462 -49
              124 -2 142 -15 146 -102 1 -33 10 -76 19 -95 19 -41 16 -104 -5 -114 -94 -46
              -151 -70 -163 -70 -8 0 -20 -7 -27 -15 -7 -8 -17 -15 -22 -15 -9 0 -42 -13
              -113 -45 -16 -7 -47 -21 -69 -31 -46 -21 -57 -16 -104 47 -54 73 -68 95 -81
              126 -6 11 -15 24 -20 28 -6 3 -11 14 -11 24 0 10 -7 24 -15 31 -8 7 -15 19
              -15 27 0 8 -6 29 -14 46 -36 85 -57 193 -39 210 9 10 51 8 71 -3z m2046 -149
              c3 -4 63 -11 133 -15 88 -5 133 -12 144 -21 39 -36 72 -329 42 -367 -6 -7 -14
              -35 -18 -63 -9 -69 -35 -148 -52 -161 -21 -17 -46 -7 -96 39 -79 74 -101 93
              -132 113 -36 22 -38 25 -90 69 -22 19 -43 35 -46 35 -8 0 -111 69 -119 79 -3
              3 -17 12 -32 20 -23 12 -28 21 -28 51 0 21 6 43 14 49 8 6 16 24 19 39 7 34
              26 113 32 130 5 14 220 18 229 3z m-1346 -261 c12 -16 22 -32 22 -36 0 -3 29
              -35 65 -71 36 -35 65 -72 65 -81 0 -9 -16 -34 -35 -56 -19 -21 -35 -44 -35
              -50 0 -7 -12 -25 -28 -42 -15 -16 -34 -41 -41 -56 -8 -15 -36 -49 -62 -77 -27
              -27 -49 -53 -49 -58 0 -5 -11 -20 -24 -32 -23 -22 -27 -22 -81 -10 -31 7 -58
              16 -61 21 -3 4 -14 8 -25 8 -10 0 -27 9 -36 20 -10 11 -23 20 -31 20 -13 0
              -58 26 -80 47 -7 7 -17 13 -21 13 -4 0 -44 36 -89 80 -64 63 -82 86 -82 108 0
              30 30 52 70 52 10 0 22 5 25 10 3 6 13 10 22 10 15 0 46 12 129 51 23 10 45
              19 50 19 5 0 34 12 64 26 146 70 177 83 198 89 12 4 22 11 22 16 0 19 27 8 48
              -21z m1060 -50 c23 -11 42 -25 42 -30 0 -6 7 -10 15 -10 8 0 15 -4 15 -9 0 -5
              12 -13 28 -19 15 -5 39 -21 52 -35 14 -15 29 -27 34 -27 7 0 81 -53 96 -70 3
              -3 24 -18 48 -34 23 -16 42 -36 42 -45 0 -9 -12 -38 -25 -66 -14 -27 -25 -58
              -25 -67 0 -9 -6 -23 -13 -30 -7 -7 -18 -24 -25 -38 -30 -62 -72 -116 -159
              -207 -88 -93 -124 -97 -138 -16 -4 24 -11 45 -16 49 -5 3 -9 13 -9 22 0 10 -9
              34 -20 55 -11 20 -20 44 -20 52 0 8 -4 15 -10 15 -5 0 -10 9 -10 21 0 11 -9
              37 -19 57 -29 56 -69 144 -76 170 -3 12 -10 22 -15 22 -13 0 -32 67 -25 90 3
              11 12 20 18 20 7 0 46 34 87 75 41 41 78 75 81 75 3 0 24 -9 47 -20z m-801
              -175 c8 -8 27 -15 43 -15 16 0 31 -4 34 -9 3 -4 28 -11 56 -15 43 -6 51 -10
              56 -33 9 -35 -5 -333 -17 -354 -5 -10 -9 -39 -9 -64 0 -70 -10 -80 -92 -100
              -55 -12 -102 -15 -188 -12 -111 4 -240 23 -250 37 -3 3 -21 11 -40 16 -28 8
              -36 15 -38 38 -3 22 4 35 32 60 20 17 36 36 36 43 0 6 20 33 45 59 25 27 45
              55 45 62 0 6 4 12 9 12 5 0 12 8 15 18 3 9 16 28 28 42 33 37 68 88 68 99 0 5
              7 11 15 15 8 3 15 13 15 21 0 9 6 22 13 30 6 7 23 27 37 44 27 34 57 36 87 6z
              m533 -35 c4 -8 16 -35 28 -60 11 -25 31 -70 45 -100 14 -30 28 -65 32 -77 3
              -13 11 -23 16 -23 5 0 9 -8 9 -17 0 -10 14 -45 30 -77 17 -32 30 -64 30 -71 0
              -16 -68 -83 -91 -90 -11 -4 -19 -11 -19 -16 0 -5 -7 -9 -15 -9 -7 0 -18 -7
              -22 -14 -11 -21 -179 -103 -228 -112 -22 -4 -55 -13 -73 -21 -39 -16 -101 -8
              -109 15 -4 8 0 30 7 49 6 20 14 68 17 107 3 39 9 76 13 82 5 5 12 77 15 160
              10 238 20 256 144 267 35 3 68 10 75 17 15 15 87 8 96 -10z"
            id="svg_3"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M5709 3908 c0 -2 -2 -82 -4 -178 -1 -96 -3 -185 -4 -197 l-1 -23
              -490 0 -490 0 0 -280 0 -280 489 0 489 0 5 -42 c3 -24 5 -110 4 -191 -2 -153
              3 -185 26 -156 7 9 32 26 57 38 62 30 90 47 103 60 6 6 19 11 29 11 9 0 21 6
              25 13 4 6 29 23 56 37 26 13 47 28 47 32 0 5 6 8 13 8 8 0 24 9 37 20 13 11
              31 20 42 20 10 0 18 4 18 8 0 5 34 26 76 48 41 21 83 44 92 49 48 29 100 61
              117 73 11 6 31 18 45 24 14 7 27 16 30 19 6 9 146 85 183 100 15 6 27 15 27
              20 0 5 6 9 14 9 8 0 21 6 28 13 7 7 20 16 28 21 8 4 30 15 49 25 l33 18 -38
              23 c-80 49 -120 71 -146 81 -14 6 -29 17 -32 25 -3 8 -14 14 -24 14 -11 0 -25
              7 -32 15 -7 8 -18 15 -25 15 -8 0 -15 3 -17 8 -1 4 -25 18 -53 32 -27 14 -53
              29 -56 33 -6 8 -74 48 -119 70 -14 6 -27 15 -30 19 -3 4 -17 12 -30 18 -14 5
              -47 24 -75 41 -27 16 -62 35 -77 40 -16 6 -28 15 -28 19 0 4 -12 13 -27 19
              -16 6 -41 20 -58 31 -16 11 -39 24 -50 30 -11 6 -33 19 -50 29 -16 11 -61 36
              -100 56 -38 20 -74 43 -78 51 -7 12 -25 20 -28 12z"
            id="svg_4"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M7340 2569 c-19 -10 -43 -19 -52 -19 -10 0 -18 -4 -18 -10 0 -5 -16
              -12 -35 -16 -19 -3 -35 -10 -35 -15 0 -5 -8 -9 -17 -9 -10 -1 -36 -9 -58 -20
              -22 -11 -47 -19 -56 -20 -9 0 -22 -6 -28 -14 -9 -11 -2 -22 35 -55 56 -49 55
              -66 -10 -131 -25 -25 -45 -51 -46 -57 0 -7 -4 -13 -10 -13 -5 0 -10 -7 -10
              -15 0 -8 -9 -23 -20 -32 -11 -10 -20 -23 -20 -30 0 -7 -4 -21 -9 -31 -34 -68
              -41 -98 -41 -186 0 -64 5 -103 15 -122 8 -16 15 -36 15 -45 0 -32 137 -267
              162 -277 4 -2 8 -9 8 -16 0 -7 9 -21 20 -31 11 -10 20 -26 20 -37 0 -10 4 -18
              8 -18 11 0 39 -67 47 -113 4 -20 11 -40 16 -43 14 -8 11 -59 -6 -90 -8 -16
              -15 -36 -15 -45 0 -29 -59 -134 -102 -181 -55 -59 -78 -90 -78 -101 0 -5 18
              -27 40 -50 l40 -40 36 37 c44 46 104 123 104 135 0 5 7 11 15 15 8 3 15 14 15
              24 0 11 7 25 15 32 8 7 15 20 15 29 0 9 4 21 9 26 4 6 16 44 26 85 15 64 16
              84 6 145 -7 38 -21 87 -32 107 -10 20 -19 43 -19 51 0 8 -7 20 -15 27 -8 7
              -15 21 -15 31 0 11 -3 19 -8 19 -4 0 -16 17 -27 38 -17 32 -65 103 -95 139 -6
              7 -10 14 -10 17 0 3 -13 25 -30 49 -16 25 -30 51 -30 58 0 8 -7 27 -16 44 -17
              33 -21 152 -6 182 6 10 21 42 33 71 13 28 27 52 31 52 3 0 17 17 29 38 13 20
              35 50 51 66 15 17 28 33 28 38 0 4 13 8 30 8 20 0 41 -11 66 -35 20 -20 39
              -34 41 -31 13 13 44 196 60 359 4 41 3 47 -14 46 -10 0 -34 -9 -53 -20z"
            id="svg_5"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M7890 2569 c-25 -12 -53 -25 -62 -30 -10 -5 -23 -9 -29 -9 -7 0 -30
              -9 -53 -19 -80 -37 -114 -51 -128 -51 -7 0 -21 -6 -30 -13 -15 -12 -11 -17 33
              -47 60 -40 63 -66 16 -113 -18 -17 -44 -50 -57 -72 -13 -22 -29 -45 -35 -51
              -11 -11 -33 -55 -59 -115 -23 -54 -30 -164 -16 -247 6 -40 16 -72 21 -72 5 0
              9 -7 9 -16 0 -22 41 -104 52 -104 4 0 8 -9 8 -20 0 -10 6 -23 14 -27 8 -4 24
              -27 36 -50 12 -23 28 -47 36 -54 8 -6 14 -16 14 -22 0 -5 11 -24 24 -41 13
              -17 26 -41 30 -53 4 -11 13 -27 21 -33 8 -7 15 -20 15 -30 0 -9 7 -32 16 -50
              25 -50 14 -140 -28 -216 -49 -90 -106 -172 -126 -183 -10 -5 -24 -21 -31 -34
              -13 -24 -11 -28 30 -66 l44 -40 23 27 c13 15 40 45 60 67 20 22 45 55 56 75
              10 19 22 37 26 40 11 7 50 96 50 114 0 8 5 18 10 21 18 11 12 219 -7 262 -32
              74 -74 158 -94 188 -10 17 -24 40 -30 53 -7 12 -16 22 -21 22 -4 0 -8 5 -8 11
              0 6 -13 28 -28 48 -15 20 -33 47 -40 61 -7 14 -20 39 -31 55 -31 52 -46 155
              -32 219 11 51 36 114 50 126 3 3 17 21 30 40 14 19 30 39 38 43 7 4 13 13 13
              19 0 21 53 68 77 68 14 0 36 -13 52 -31 17 -19 36 -29 47 -27 16 3 21 19 31
              108 6 58 16 125 21 150 6 25 12 65 13 90 4 54 -7 59 -71 29z"
            id="svg_6"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M8435 2568 c-57 -31 -67 -35 -103 -43 -18 -3 -32 -11 -32 -16 0 -5
              -8 -9 -18 -9 -9 0 -34 -9 -55 -20 -20 -11 -43 -20 -51 -20 -7 0 -19 -6 -25
              -14 -14 -16 4 -39 53 -71 14 -10 26 -23 26 -31 0 -8 -23 -40 -50 -71 -28 -32
              -56 -65 -63 -73 -11 -13 -51 -91 -91 -178 -15 -34 -14 -238 2 -254 7 -7 12
              -19 12 -28 0 -8 9 -32 20 -53 11 -20 20 -44 20 -52 0 -8 3 -15 8 -15 4 0 16
              -17 27 -37 11 -21 33 -57 50 -79 61 -84 96 -139 116 -184 6 -14 18 -39 26 -55
              18 -36 15 -221 -3 -233 -6 -4 -15 -18 -19 -32 -4 -14 -10 -27 -14 -30 -3 -3
              -19 -24 -35 -47 -15 -23 -42 -57 -60 -75 -62 -66 -62 -61 -12 -114 l44 -48 24
              24 c13 14 40 47 59 74 19 28 42 58 52 69 10 10 23 32 29 48 5 16 14 29 18 29
              4 0 11 12 15 27 3 15 12 31 18 35 8 5 12 52 12 150 0 158 -1 161 -79 302 -6
              11 -42 67 -81 125 -111 168 -136 227 -137 319 -1 83 12 124 63 200 16 24 29
              46 29 50 1 16 101 112 117 112 10 0 30 -11 44 -24 14 -13 35 -28 46 -34 26
              -14 33 0 33 63 0 24 4 56 10 70 5 14 13 64 16 111 4 47 11 87 16 90 4 3 8 18
              8 35 0 24 -4 29 -22 29 -13 -1 -41 -10 -63 -22z"
            id="svg_7"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default CHPIcon
