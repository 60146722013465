import React, { useMemo } from 'react'
import PopperTooltip from 'ui/PopperTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import { Box, Typography } from '@material-ui/core'
import { jt, msgid, ngettext, t } from 'ttag'
import {
  PenaltyCalculationSummary,
  PenaltyResultStatus,
} from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import { getPenaltyBlockIssueErrorMsg, getPenaltyBlockSingleIssueErrorMsg } from 'utils/penaltyCalculations'
import { formatDate } from 'utils/date'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import styled from 'styled-components'
import { thousandSeparator } from 'utils/dataFormatting'

const TooltipContainer = styled.div`
  max-width: 32em;
`

const MAX_BLOCK_COUNT_TO_DISPLAY = 20
const PENALTY_COMPLETENESS_ERROR_THRESHOLD = 80
const PENALTY_COMPLETENESS_WARNING_THRESHOLD = 99
const PENALTY_COMPLETENESS_ZERO = 0

interface PenaltyCalculationsStatusCellProps {
  penalty: PenaltyCalculationSummary
  resultErrorMsg: string | null
}

const PenaltyCalculationsStatusCell: React.FC<PenaltyCalculationsStatusCellProps> = ({ penalty, resultErrorMsg }) => {
  const userTimezone = useSelector(getUserTimezoneSelector)
  const completeness = penalty.realtimeDataCompleteness
  const { totalBlockCountWithIssues, totalBlockCount, blockIssuesCount, realtimeDataCompleteness } = penalty

  const blockIssueKeys = Object.keys(blockIssuesCount)

  const blockResultsWithIssues = useMemo(() => {
    if (penalty?.penaltyBlockResults?.length) {
      return (penalty?.penaltyBlockResults || []).filter((blockResult) => blockResult?.penaltyBlockIssues?.length > 0)
    } else {
      return []
    }
  }, [penalty?.penaltyBlockResults])

  const getBlockCountWithIssuesContent = useMemo(() => {
    const totalBlockCountWithIssuesFormatted = thousandSeparator(totalBlockCountWithIssues)
    const totalBlockCountFormatted = thousandSeparator(totalBlockCount)
    return (
      <TooltipContainer>
        {totalBlockCountWithIssues && (
          <>
            {/* ------------- Message on top -------------*/}
            {jt`Penalty could not be calculated for ${totalBlockCountWithIssuesFormatted} out of ${totalBlockCountFormatted} time blocks`}{' '}
            <>
              ({t`completeness`}&nbsp;{realtimeDataCompleteness.toFixed(2)}%).
            </>{' '}
            {realtimeDataCompleteness < PENALTY_COMPLETENESS_ERROR_THRESHOLD &&
            realtimeDataCompleteness != PENALTY_COMPLETENESS_ZERO
              ? t`Therefore, the penalty preview should not be considered reliable.`
              : ''}
            {/* ------------- Encountered issues -------------*/}
            {blockIssueKeys && blockIssueKeys.length ? (
              <Box mt={0.5}>
                <Typography color="inherit">{t`Encountered issues:`}</Typography>
                {blockIssueKeys.length == 1 ? (
                  <>{getPenaltyBlockSingleIssueErrorMsg(blockIssueKeys[0])}</>
                ) : (
                  <>
                    {blockIssueKeys.map((issue) => {
                      const count = blockIssuesCount[issue]
                      return <div key={issue}>- {getPenaltyBlockIssueErrorMsg(issue, count)}</div>
                    })}
                  </>
                )}
              </Box>
            ) : (
              <></>
            )}
            {/* ------------- Display time blocks -------------*/}
            {realtimeDataCompleteness !== PENALTY_COMPLETENESS_ZERO ? (
              <Box mt={1}>
                {blockResultsWithIssues.length > MAX_BLOCK_COUNT_TO_DISPLAY ? (
                  <Typography color="inherit">{t`Showing only first ${MAX_BLOCK_COUNT_TO_DISPLAY} affected time blocks:`}</Typography>
                ) : (
                  <Typography color="inherit">
                    {ngettext(msgid`Affected time block:`, `Affected time blocks:`, blockResultsWithIssues.length)}
                  </Typography>
                )}
                {blockResultsWithIssues.slice(0, MAX_BLOCK_COUNT_TO_DISPLAY).map((blockResult, index) => (
                  <Typography key={`${index}.${blockResult.time.start}.${blockResult.time.end}`} color="inherit">
                    {formatDate(blockResult.time.start, userTimezone)} -{' '}
                    {formatDate(blockResult.time.end, userTimezone)}
                  </Typography>
                ))}
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
      </TooltipContainer>
    )
  }, [blockResultsWithIssues, penalty, userTimezone])

  const isError = completeness < PENALTY_COMPLETENESS_ERROR_THRESHOLD
  const isWarning =
    (penalty.status === PenaltyResultStatus.DATA_RESOLUTION_DOES_NOT_MATCH_REGULATION && completeness == 100) ||
    (completeness < PENALTY_COMPLETENESS_WARNING_THRESHOLD && completeness > PENALTY_COMPLETENESS_ERROR_THRESHOLD)

  const isSuccess = !isError && !isWarning

  return (
    <Box ml={0.5}>
      {/* ------------- Status icon -------------*/}
      <PopperTooltip
        popperLabel={
          <FontAwesomeIcon
            icon={isError ? 'exclamation-circle' : isWarning ? 'exclamation-triangle' : 'check'}
            color={isWarning ? '#f8bd71' : isError ? theme.palette.error.main : theme.palette.primary.main}
            size={isError || isWarning ? 'lg' : '1x'}
          />
        }
        popperContent={
          <div>
            <Typography color={isSuccess ? 'primary' : 'error'} variant="subtitle1">
              {isSuccess ? (completeness == 100 ? t`Success` : t`Information`) : t`Warning`}
            </Typography>

            <>
              {totalBlockCountWithIssues ? (
                getBlockCountWithIssuesContent
              ) : resultErrorMsg ? (
                <>{resultErrorMsg}</>
              ) : (
                <Typography color="inherit">{t`Calculated successfully`} </Typography>
              )}
            </>
          </div>
        }
      />
    </Box>
  )
}

export default PenaltyCalculationsStatusCell
