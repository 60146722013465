import React, { useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import { Box, Typography } from '@material-ui/core'
import { c } from 'ttag'
import InlineLoading from 'ui/InlineLoading'
import { useMasterDataCustomFormats } from 'modules/masterData/api/masterDataCustomFormats.api'
import MasterDataCustomFormatsTable from 'modules/masterData/masterDataCustomFormats/MasterDataCustomFormatsTable'
import { useCustomMuiStyles } from 'themes/theme-light'
import { isCurrentUserSubscribedTo, userHasGlobalAccessWithoutDemo } from 'utils/user'
import { SubscribedProduct } from 'modules/auth/Auth.types'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const MasterDataCustomFormats: React.FC = () => {
  //Style
  const classes = useCustomMuiStyles()

  const user = useSelector(getUserResultSelector)

  const masterDataCustomerFormatsResult = useMasterDataCustomFormats()

  const customFormatsLoading = useMemo(() => masterDataCustomerFormatsResult.isLoading, [
    masterDataCustomerFormatsResult,
  ])

  return (
    <div
      className={
        isCurrentUserSubscribedTo(user, SubscribedProduct.MASTER_DATA_CUSTOM)
          ? 'inherit'
          : userHasGlobalAccessWithoutDemo(user)
          ? classes.adminComponent
          : 'inherit'
      }
    >
      <Box mb={1}>
        <Flex alignItems="center">
          <Typography variant="h5">{c('Master data').t`Master data custom format`}</Typography>
          {customFormatsLoading && <InlineLoading />}
        </Flex>

        <Typography>{c('Master data')
          .t`How do you want to map your master data to the enercast data structure? Add configurations to modify the enercast standard format as needed`}</Typography>
      </Box>
      {masterDataCustomerFormatsResult.data && (
        <MasterDataCustomFormatsTable masterDataCustomFormats={masterDataCustomerFormatsResult.data} />
      )}
    </div>
  )
}

export default React.memo(MasterDataCustomFormats)
