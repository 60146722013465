import React, { useCallback, useEffect, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { Select } from 'final-form-material-ui'
import { Field, useField } from 'react-final-form'
import { t } from 'ttag'

import { calculateAvailabilityValue } from 'utils/availabilities'
import { Asset } from 'modules/asset/store/asset.types'
import { Availability } from 'modules/asset/availability/Availability.types'
import { getUnit, isNumeric } from 'utils/dataFormatting'
import { AvailabilityValue, UnitFormControl, ValueField, ValueFormControl } from 'ui/form/maintenanceForm.style'
import Flex from 'ui/styles/Flex'
import Number from 'ui/Number'
import styled from 'styled-components'
import { availabilityUnits } from 'utils/units'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_AVAILABILITIES } from 'modules/reTable/reTable.types'

const ValueUnitContainer = styled(Flex)`
  width: 4em;
`
const ValueUnitWrapper = styled(Flex)`
  position: relative;
`

const AVAILABILITY_UNIT_WIDTH = 23

interface AvailabilityTableValueUnitCellProps {
  parentAsset: Asset
  isEdit: boolean
  availability: Availability
  activeField: string
  fieldAutoFocus: boolean
}

const AvailabilityTableValueUnitCell: React.FC<AvailabilityTableValueUnitCellProps> = ({
  parentAsset,
  isEdit,
  availability,
  activeField,
  fieldAutoFocus,
}) => {
  const [calculatedVal, setCalculatedVal] = useState({ unit: '', data: '0' })
  const unit = useField('unit').input.value
  const value = useField('value').input.value
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_AVAILABILITIES)

  const calculateValue = useCallback(() => {
    const result = calculateAvailabilityValue(value, unit, parentAsset)
    setCalculatedVal(result)
  }, [parentAsset, unit, value])

  useEffect(() => {
    if (parentAsset && parentAsset.id) {
      calculateValue()
    }
  }, [parentAsset, unit, value])

  return (
    <>
      {!isEdit && availability?.unit && isNumeric(availability?.value) && (
        <Flex justifyContent="flex-end">
          <Number
            showFractionalPart={true}
            forceFractionalPart={true}
            limit={3}
            highlight={query}
            unit={getUnit(availability.unit)}
            data={availability.value}
            unitWidth={AVAILABILITY_UNIT_WIDTH}
          />
        </Flex>
      )}
      {isEdit && (
        <ValueUnitWrapper direction="column" justifyContent="space-between" flexWrap="wrap">
          <Flex>
            <ValueUnitContainer flexGrow={1}>
              <ValueFormControl>
                <Field
                  fullWidth
                  defaultValue={availability.value}
                  name="value"
                  component={ValueField}
                  type="number"
                  label={t`Value`}
                  autoFocus={fieldAutoFocus}
                />
              </ValueFormControl>
            </ValueUnitContainer>
            <ValueUnitContainer flexGrow={1}>
              <UnitFormControl>
                <Field fullWidth defaultValue={availability.unit} name="unit" component={Select} label={t`Unit`}>
                  {availabilityUnits.map((el) => (
                    <MenuItem key={el.value} value={el.name}>
                      {el.value}
                    </MenuItem>
                  ))}
                </Field>
              </UnitFormControl>
            </ValueUnitContainer>
          </Flex>
          {(activeField === 'unit' || activeField === 'value' || fieldAutoFocus) && (
            <Flex justifyContent="center">
              <AvailabilityValue>
                {calculatedVal.data}
                {calculatedVal.unit}
              </AvailabilityValue>
            </Flex>
          )}
        </ValueUnitWrapper>
      )}
    </>
  )
}

export default React.memo(AvailabilityTableValueUnitCell)
