import React from 'react'
import { Field } from 'react-final-form'
import { Select } from 'final-form-material-ui'
import Highlight from 'ui/Highlight'
import { FormControlFullWidth } from 'ui/form/reactFinalFormFields.style'
import { MenuItem } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { ReTableColumnSelectItem } from 'modules/reTable/reTable.types'
import styled from 'styled-components'

interface ContainerProps {
  width: string
}

const Container = styled(Flex)<ContainerProps>`
  width: ${(props) => props.width || 'inherit'};
`

interface ReTableTextCellProps {
  value: string
  isEdit?: boolean
  label?: string | string[]
  name?: string
  query?: string
  selectItems: ReTableColumnSelectItem[] | undefined
  width: string
}

const ReTableSelectCell: React.FC<ReTableTextCellProps> = ({
  value,
  isEdit,
  label = '',
  name = '',
  query = '',
  selectItems,
  width,
}) => {
  const selectedItem = (selectItems || []).find((item) => item.id === value)
  return (
    <Container width={width}>
      {!isEdit && selectedItem && <Highlight text={selectedItem?.label || ''} query={query} />}
      {isEdit && (
        <FormControlFullWidth>
          <Field name={name} component={Select} fullWidth label={label}>
            {(selectItems || []).map((type) => (
              <MenuItem key={type.label} value={type.id}>
                {type.label}
              </MenuItem>
            ))}
          </Field>
        </FormControlFullWidth>
      )}
    </Container>
  )
}

export default React.memo(ReTableSelectCell)
