import React, { useCallback, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { Field, Form } from 'react-final-form'
import { Select } from 'final-form-material-ui'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import styled from 'styled-components'
import LoadingButton from 'ui/form/LoadingButton'
import {
  backCastRangeOptions,
  convertUTCToLocalDate,
  DATE_FORMAT_DEFAULT_SHORT,
  DateRange,
  formatDate,
  getDateRangeFromChartDataRange,
} from 'utils/date'
import { isSameDay } from 'date-fns'
import { BackCastRangeType } from 'modules/workspace/store/workspace.types'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { DataStreamSelectionItem } from 'modules/dataStreams/dataStreams.types'

import { BackCastCreationResponse } from 'modules/workspace/header/backCast/backCast.api'

import { useSelector } from 'react-redux'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import BackCastCalculationErrorMsg from 'modules/workspace/header/backCast/BackCastCalculationErrorMsg'
import Box from '@material-ui/core/Box'
import BackCastCoordinatesInfo from 'modules/workspace/header/backCast/BackCastCoordinatesInfo'
import { validateCreateBackcastForm } from 'utils/formValidations'
import DateTimePickerFinalForm from 'ui/elements/DateTimePickerFinalForm'
import { FormApi, Mutator } from 'final-form'

const DatesContainer = styled(Flex)`
  height: 6em;
  *.MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
  }
`

const InputFieldContainer = styled(Flex)`
  & .MuiInput-formControl {
    width: 10.5em;
  }
`

export interface BackCastTimePeriod {
  start: Date
  end: Date
}

export interface TimePeriodWithRange {
  start: Date
  end: Date
  range: BackCastRangeType
}

interface BackCastCalculationDialogProps {
  onCloseDialog: () => void
  onStartBackCastCreation: (timePeriod: BackCastTimePeriod) => void
  error: any
  loading: boolean
  selectedSiteForecastDataStreams: DataStreamSelectionItem[]
  creationResponse: BackCastCreationResponse[] | undefined
  assetsAndModelsSelection: string[]
}

let formReference: FormApi<TimePeriodWithRange>

const BackCastCalculationForm: React.FC<BackCastCalculationDialogProps> = ({
  onCloseDialog,
  onStartBackCastCreation,
  loading,
  selectedSiteForecastDataStreams,
  creationResponse,
  assetsAndModelsSelection,
}) => {
  const chartSelectedDateRangeInUTC = useWorkspaceChartSelectedDateRange()
  const timezone = useSelector(getUserTimezoneSelector)

  const chartSelectedDateRange: DateRange = useMemo(() => {
    return [
      formatDate(convertUTCToLocalDate(chartSelectedDateRangeInUTC[0]), timezone, DATE_FORMAT_DEFAULT_SHORT),
      formatDate(convertUTCToLocalDate(chartSelectedDateRangeInUTC[1]), timezone, DATE_FORMAT_DEFAULT_SHORT),
    ]
  }, [chartSelectedDateRangeInUTC, timezone])

  const siteForecastConfigs = useSiteForecastConfigs()?.data
  const rangeOptions = useMemo(() => backCastRangeOptions(), [])

  const selectedForecastsWithNoQualityConfig = useMemo(() => {
    if (!selectedSiteForecastDataStreams?.length || !siteForecastConfigs?.length) return []
    const forecastWithNoQuality = siteForecastConfigs.filter((sfc) => {
      const config = selectedSiteForecastDataStreams?.find((selectedConfig) => sfc.id === selectedConfig.id)
      return config && !sfc?.qualityConfigs?.length
    })
    return forecastWithNoQuality.filter((fwnq) => fwnq.id !== 'default')
  }, [selectedSiteForecastDataStreams, siteForecastConfigs])

  const selectionInvalid = useMemo(() => {
    return selectedForecastsWithNoQualityConfig.length === selectedSiteForecastDataStreams.length
  }, [selectedForecastsWithNoQualityConfig, selectedSiteForecastDataStreams])

  const hideBackcastSubscriptionInfo = useMemo(() => {
    if (creationResponse && creationResponse?.length) {
      return creationResponse?.filter((res: BackCastCreationResponse) => res.status == 'ERROR')
    } else {
      return selectionInvalid
    }
  }, [creationResponse, selectionInvalid])

  const handleCancelAction = useCallback(() => {
    if (!loading) {
      onCloseDialog()
    }
  }, [loading])

  const handleRangeChange = (rangeType: BackCastRangeType) => {
    if (rangeType !== BackCastRangeType.CHART_DATA_RANGE_CUSTOM) {
      const period = getDateRangeFromChartDataRange({ rangeType, customRange: null })
      formReference.mutators.setValue('start', new Date(period[0]))
      formReference.mutators.setValue('end', new Date(period[1]))
    }
  }

  const handleFormSubmit = useCallback((data: TimePeriodWithRange) => {
    onStartBackCastCreation({ start: data.start, end: data.end })
  }, [])

  const getRangeFromTimePeriod = (timePeriod: Partial<TimePeriodWithRange>) => {
    let range = BackCastRangeType.CHART_DATA_RANGE_CUSTOM
    if (timePeriod && rangeOptions) {
      const isTimePeriodMatchingAnyRange = (rangeOptions || []).find((option) => {
        const period = getDateRangeFromChartDataRange({ rangeType: option?.key, customRange: null })
        return (
          isSameDay(new Date(period[0]), new Date(timePeriod?.start)) &&
          isSameDay(new Date(period[1]), new Date(timePeriod?.end))
        )
      })
      if (isTimePeriodMatchingAnyRange) {
        range = isTimePeriodMatchingAnyRange.key
      }
    }
    return range
  }

  const initialValues = useMemo(() => {
    const startDate = new Date(chartSelectedDateRange[0])
    const endDate = new Date(chartSelectedDateRange[1])
    if (chartSelectedDateRange.length) {
      return {
        start: startDate,
        end: endDate,
        range: getRangeFromTimePeriod({ start: startDate, end: endDate }),
      }
    } else return {}
  }, [chartSelectedDateRange])

  // setValue is for setting one value and setValues is use set multiple values at same time
  const mutators = useMemo<{ [key: string]: Mutator<TimePeriodWithRange, Partial<TimePeriodWithRange>> }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
    }
  }, [])

  return (
    <>
      {t`Please select the time period to create the backcast.`}
      <Form
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validate={validateCreateBackcastForm}
        mutators={mutators}
        render={({ handleSubmit, form }) => {
          formReference = form
          const startDate = form.getState()?.values?.start
          return (
            <form onSubmit={handleSubmit}>
              <DatesContainer direction="row" alignItems="center" justifyContent="space-between">
                <InputFieldContainer>
                  <Field name="range">
                    {(props) => {
                      return (
                        <FormControl>
                          <Select label={t`Time range`} input={props.input} meta={props.meta}>
                            {rangeOptions?.map((item) => (
                              <MenuItem onClick={() => handleRangeChange(item.key)} key={item.key} value={item.key}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )
                    }}
                  </Field>
                </InputFieldContainer>

                <InputFieldContainer alignItems="center">
                  <Field name="start" fullWidth>
                    {({ input, meta }) => {
                      return <DateTimePickerFinalForm label={t`Start`} input={input} meta={meta} onlyDate={true} />
                    }}
                  </Field>
                </InputFieldContainer>

                <InputFieldContainer alignItems="center">
                  <Field name="end" fullWidth>
                    {({ input, meta }) => {
                      return (
                        <DateTimePickerFinalForm
                          label={t`End`}
                          input={input}
                          minDate={startDate ? startDate : null}
                          meta={meta}
                          onlyDate={true}
                        />
                      )
                    }}
                  </Field>
                </InputFieldContainer>
              </DatesContainer>

              <BackCastCalculationErrorMsg
                selectedForecastsWithNoQualityConfig={selectedForecastsWithNoQualityConfig}
                creationResponse={creationResponse}
                selectedSiteForecastDataStreams={selectedSiteForecastDataStreams}
              />

              <Box
                mb={2}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={!hideBackcastSubscriptionInfo ? 'space-between' : 'end'}
              >
                {!hideBackcastSubscriptionInfo && (
                  <BackCastCoordinatesInfo assetsAndModelsSelection={assetsAndModelsSelection} />
                )}

                <Box display="flex" flexDirection="row">
                  <Button onClick={handleCancelAction} color="primary" size="small">
                    {creationResponse ? t`Close` : t`Cancel`}
                  </Button>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    size="small"
                    color="primary"
                    disabled={selectionInvalid}
                    type={'submit'}
                  >{t`Submit`}</LoadingButton>
                </Box>
              </Box>
            </form>
          )
        }}
      />
    </>
  )
}

export default React.memo(BackCastCalculationForm)
