import { REVISION_SET_STAGE } from 'pages/workbench/store/workbench.action.types'
import { STAGE } from 'pages/workbench/store/workbench.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import createReducer from 'utils/createReducer'

// state

export type REVISION_ACTION_TYPE = REVISION_SET_STAGE

export interface RevisionAction {
  type: REVISION_ACTION_TYPE
  // SUCCESS
  selectedStage: State['selectedStage']
  stage: STAGE
}

export interface State {
  selectedStage: STAGE | null
}

const initialState: State = {
  selectedStage: null,
}

// reducers

export const selectedStage = createReducer<State['selectedStage'], RevisionAction>(
  (state = initialState.selectedStage, { type, stage }) => {
    if (type === REVISION_SET_STAGE) {
      state = stage
    }
    return state
  },
)

export const revisionReducer = combineReducers({
  selectedStage,
})

// selectors

export const selectedStageSelector = createSelector<any, State['selectedStage'], State['selectedStage']>(
  (state) => state.workbench.revision.selectedStage,
  (selectedStage) => selectedStage,
)

// api
