import React from 'react'
import { EnrichedForecastModelItem, EnrichedQualityEvaluationItem, ForecastModelTableItem } from 'utils/forecastModel'
import { Asset } from 'modules/asset/store/asset.types'
import { Column, ReTableContextMenuItemName, Sort } from 'modules/reTable/reTable.types'
import { ForecastModel } from 'modules/quality/quality.types'
import QualityEvaluationRow from 'modules/forecastModels/QualityEvaluationRow'
import ModelRow from 'modules/forecastModels/ModelRow'

interface ForecastModelsTableRowProps {
  asset: Partial<Asset>
  activeModels: ForecastModel[]
  evaluationRunning: Record<string, boolean>
  columnsSelected: Column[]
  item: ForecastModelTableItem
  isSelected: boolean
  query: string
  onSelect: (item: EnrichedQualityEvaluationItem) => void
  onClickModelRowMenuAction: (menuItem: ReTableContextMenuItemName, forecastModel: EnrichedForecastModelItem) => void
  sort: Sort
}

const ForecastModelsTableRow: React.FC<ForecastModelsTableRowProps> = ({
  asset,
  activeModels,
  evaluationRunning,
  columnsSelected,
  item,
  isSelected,
  onSelect,
  query,
  onClickModelRowMenuAction,
  sort,
}) => {
  return (
    <>
      {item?.isQualityEvaluation ? (
        <QualityEvaluationRow
          item={item}
          onSelect={onSelect}
          isSelected={isSelected}
          asset={asset}
          columnsSelected={columnsSelected}
          query={query}
          sort={sort}
          activeModels={activeModels}
        />
      ) : (
        <ModelRow
          forecastModel={item as EnrichedForecastModelItem}
          asset={asset}
          activeModels={activeModels}
          columnsSelected={columnsSelected}
          evaluationRunning={evaluationRunning}
          onClickModelRowMenuAction={onClickModelRowMenuAction}
          sort={sort}
        />
      )}
    </>
  )
}

export default React.memo(ForecastModelsTableRow)
