import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { c } from 'ttag'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import Flex from 'ui/styles/Flex'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from '@material-ui/core/Radio/Radio'
import { Box, MenuItem } from '@material-ui/core'
import ButtonMenu from 'ui/form/ButtonMenu'
import { getIndividualTimes, getTimeLabel } from 'utils/siteForecastConfiguration'
import {
  DeliveryTime,
  DeliveryTimesType,
  ForecastConfig,
  SiteForecastUpdateTimes,
} from 'modules/dataStreams/dataStreams.types'
import { FormApi } from 'final-form'
import { Field, useField } from 'react-final-form'
import SiteForecastTimeChips from 'ui/SiteForecastTimeChips'
import ErrorMessage from 'ui/form/ErrorMessage'

interface SectionDeliveryTimesProps {
  form: FormApi<ForecastConfig>
  onFormChange: () => void
  fieldArrayKey: string
  index: number
}

const SectionDeliveryTimes: React.FC<SectionDeliveryTimesProps> = ({ form, onFormChange, fieldArrayKey, index }) => {
  const initialForecastDetails = form.getState().initialValues
  const { AUTOMATIC, CUSTOM } = DeliveryTimesType
  const [availableTimes, setAvailableTimes] = useState<DeliveryTime[]>([])

  const deliveryTimesType = useField<DeliveryTimesType>(`${fieldArrayKey}.deliveryTimesType`).input.value
  const currentUpdateTimes = useField<SiteForecastUpdateTimes>('updateTimes').input.value
  const selectedTimes = useField<DeliveryTime[]>(`${fieldArrayKey}.deliveryTimes`).input.value
  const deliveryFrequencyChanged = useField<boolean>(`ui.deliveryFrequencyChanged`).input.value
  const [customSelectedTimes, setCustomSelectedTimes] = useState<DeliveryTime[]>([])

  const selectableTimes = useMemo(() => {
    const selectedPairs = selectedTimes.map((time) => `${parseInt(time.hours)}:${parseInt(time.minutes)}`)
    return availableTimes.filter((time) => {
      const availablePair = `${parseInt(time.hours)}:${parseInt(time.minutes)}`
      return !selectedPairs.includes(availablePair)
    })
  }, [availableTimes, selectedTimes])

  const handleUpdateDeliveryTimes = useCallback(
    (times: DeliveryTime[], deliveryType: DeliveryTimesType) => {
      const availablePairs = availableTimes.map((time) => `${parseInt(time.hours)}:${parseInt(time.minutes)}`)
      const deliveryTimes = times.filter((time) => {
        const pair = `${parseInt(time.hours)}:${parseInt(time.minutes)}`
        return availablePairs.includes(pair)
      })

      if (deliveryType === CUSTOM) {
        setCustomSelectedTimes(deliveryTimes)
      }
      form.mutators.setValue(`${fieldArrayKey}.deliveryTimes`, deliveryTimes)
    },
    [availableTimes],
  )

  const handleDeleteDeliveryLength = useCallback(
    (time: DeliveryTime) => {
      const times = (selectedTimes || []).filter((t) => t !== time)
      handleUpdateDeliveryTimes(times, deliveryTimesType)
      onFormChange()
    },
    [selectedTimes, deliveryTimesType, handleUpdateDeliveryTimes],
  )

  const handleSelectTime = useCallback(
    (handleClose: any, time: DeliveryTime) => {
      const times = [...selectedTimes, time]
      handleUpdateDeliveryTimes(times, deliveryTimesType)
      handleClose()
      onFormChange()
    },
    [selectedTimes, handleUpdateDeliveryTimes, deliveryTimesType],
  )

  const handleDeliveryTimesTypeChange = useCallback(
    (event) => {
      const type = event.target.value
      form.mutators.setValue(`${fieldArrayKey}.deliveryTimesType`, type)
      handleUpdateDeliveryTimes(type === CUSTOM ? customSelectedTimes : availableTimes, type)
    },
    [customSelectedTimes, handleUpdateDeliveryTimes, availableTimes],
  )

  const menuContent = useCallback(
    (handleClose) => (
      <>
        {selectableTimes.map((time) => (
          <MenuItem key={getTimeLabel(time)} onClick={() => handleSelectTime(handleClose, time)}>
            {getTimeLabel(time)}
          </MenuItem>
        ))}
      </>
    ),
    [selectableTimes, handleSelectTime],
  )

  useEffect(() => {
    const forecastChanged =
      JSON.stringify(initialForecastDetails.updateTimes) !== JSON.stringify(currentUpdateTimes) ||
      deliveryFrequencyChanged

    const availableTimes: DeliveryTime[] = forecastChanged
      ? getIndividualTimes(currentUpdateTimes)
      : initialForecastDetails?.updateTimes
      ? getIndividualTimes(initialForecastDetails.updateTimes)
      : []

    setAvailableTimes(availableTimes)
  }, [initialForecastDetails, index, currentUpdateTimes, deliveryFrequencyChanged])

  useEffect(() => {
    if (initialForecastDetails?.updateTimes) {
      if (
        initialForecastDetails?.qualityConfigs?.length &&
        initialForecastDetails.qualityConfigs[index] &&
        initialForecastDetails.qualityConfigs[index].deliveryTimesType === CUSTOM
      ) {
        setCustomSelectedTimes(initialForecastDetails.qualityConfigs[index].deliveryTimes)
      }
    }
  }, [initialForecastDetails])

  return (
    <>
      <FormKeyValueGrid>
        <FormKey>{c('Delivery').t`Delivery time:`}</FormKey>
        <FormValue>
          <Field name={`${fieldArrayKey}.deliveryTimesType`} type="radio">
            {({ input }) => {
              return (
                <RadioGroup name={input.name} value={deliveryTimesType} onChange={handleDeliveryTimesTypeChange}>
                  <Flex direction="row">
                    <FormControlLabel
                      value={AUTOMATIC}
                      control={<Radio size="small" />}
                      label={c('Delivery').t`Consider all delivery times`}
                      title={c('Delivery').t`Consider all delivery times`}
                    />

                    <FormControlLabel
                      value={CUSTOM}
                      control={<Radio size="small" />}
                      label={c('Delivery').t`Choose specific delivery times`}
                      title={c('Delivery').t`Choose specific delivery times`}
                    />
                  </Flex>
                </RadioGroup>
              )
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>{c('Delivery').t`Delivery times:`}</FormKey>
        <FormValue>
          <Flex alignItems="center">
            <Box>
              {selectedTimes.length > 0 && (
                <>
                  {deliveryTimesType === AUTOMATIC ? (
                    <SiteForecastTimeChips times={selectedTimes} initialCount={25} />
                  ) : (
                    <SiteForecastTimeChips
                      times={selectedTimes}
                      onDeleteTime={handleDeleteDeliveryLength}
                      initialCount={25}
                    />
                  )}
                </>
              )}
            </Box>
            <Box mt={1.8}>
              <Field name={`${fieldArrayKey}.deliveryTimes`}>
                {({ meta }) => <>{meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}</>}
              </Field>
            </Box>

            {deliveryTimesType === CUSTOM && (
              <>
                <ButtonMenu text="Add" size="small" color="primary" icon="plus" renderMenu={menuContent} />
              </>
            )}
          </Flex>
        </FormValue>
      </FormKeyValueGrid>
    </>
  )
}

export default React.memo(SectionDeliveryTimes)
