import { Box, Typography } from '@material-ui/core'
import { Timezone } from 'fixtures/timezones'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import PopperTooltip from 'ui/PopperTooltip'
import { convertUtcToZonedTime, formatDate } from 'utils/date'

const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 5px;
`

interface DateTimezonePopoverProps {
  dateInUTC: Date | string
  originalTimezone: Timezone
}

const DateTimezonePopover: React.FC<DateTimezonePopoverProps> = ({ originalTimezone, dateInUTC }) => {
  const toolTipContent = useCallback(() => {
    const originalTimezoneDate = formatDate(convertUtcToZonedTime(dateInUTC, originalTimezone))
    return (
      <Box>
        <div>
          <Typography>
            {t`Originally recorded in time zone`}
            <strong> {originalTimezone}</strong>
          </Typography>
          <Typography>
            {t`as`} <strong>{originalTimezoneDate}</strong>
          </Typography>
        </div>
      </Box>
    )
  }, [originalTimezone, dateInUTC])
  return <PopperTooltip popperLabel={<TooltipIcon icon="globe" size="sm" />} popperContent={toolTipContent()} />
}

export default DateTimezonePopover
