import cloneDeep from 'clone-deep'
import {
  GET_USERCONFIG_FAILURE,
  GET_USERCONFIG_REQUEST,
  GET_USERCONFIG_SUCCESS,
} from 'modules/auth/redux_store/auth.action.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { getUserConfigResultSelector } from 'modules/auth/redux_store/state/getUserConfig'
import { QUERY_KEY_SITE_FORECAST_CONFIGS } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS } from 'modules/dataStreams/api/weatherData.api'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { WeatherConfig } from 'modules/weather/store/weather.types'
import { SAVE_WORKSPACE_CONFIGS_SUCCESS } from 'modules/workspace/store/saveWorkspaceConfigs.state'

import { WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import { queryClient } from 'queryClient'
import { AnyAction, combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { put, race, select, take } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import createReducer from 'utils/createReducer'
import { normalizeWorkspaceConfig } from 'utils/workspace'

// state

interface State {
  result: WorkspaceConfig[]
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
  initialized: false,
}

// types

export const GET_WORKSPACE_CONFIGS_REQUEST = 'GET_WORKSPACE_CONFIGS_REQUEST'
export const GET_WORKSPACE_CONFIGS_SUCCESS = 'GET_WORKSPACE_CONFIGS_SUCCESS'
export const GET_WORKSPACE_CONFIGS_FAILURE = 'GET_WORKSPACE_CONFIGS_FAILURE'
export const GET_WORKSPACE_CONFIGS_DISMISS = 'GET_WORKSPACE_CONFIGS_DISMISS'
export type GET_WORKSPACE_CONFIGS_REQUEST = typeof GET_WORKSPACE_CONFIGS_REQUEST
export type GET_WORKSPACE_CONFIGS_SUCCESS = typeof GET_WORKSPACE_CONFIGS_SUCCESS
export type GET_WORKSPACE_CONFIGS_FAILURE = typeof GET_WORKSPACE_CONFIGS_FAILURE
export type GET_WORKSPACE_CONFIGS_DISMISS = typeof GET_WORKSPACE_CONFIGS_DISMISS
export type GET_WORKSPACE_CONFIGS_ACTION_TYPE =
  | GET_WORKSPACE_CONFIGS_REQUEST
  | GET_WORKSPACE_CONFIGS_SUCCESS
  | GET_WORKSPACE_CONFIGS_FAILURE
  | GET_WORKSPACE_CONFIGS_DISMISS

interface ConfigAction {
  type: GET_WORKSPACE_CONFIGS_ACTION_TYPE | SAVE_WORKSPACE_CONFIGS_SUCCESS
  // SUCCESS
  configs: State['result']
  // SAVE SUCCESS
  config: WorkspaceConfig
  // ERROR
  error: State['error']
}

// reducers

const result = createReducer<State['result'], ConfigAction>(
  (state = initialState.result, { type, configs, config }) => {
    if (type === GET_WORKSPACE_CONFIGS_SUCCESS) {
      return configs
    }
    if (type === SAVE_WORKSPACE_CONFIGS_SUCCESS) {
      const configIds = state.map((c) => c.id)
      return configIds.includes(config.id) ? state.map((c) => (c.id === config.id ? config : c)) : state.concat(config)
    }
    return state
  },
)

const loading = createReducer<State['loading'], ConfigAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case GET_WORKSPACE_CONFIGS_REQUEST:
      return true
    case GET_WORKSPACE_CONFIGS_SUCCESS:
    case GET_WORKSPACE_CONFIGS_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], ConfigAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case GET_WORKSPACE_CONFIGS_SUCCESS:
    case GET_WORKSPACE_CONFIGS_DISMISS:
      return null
    case GET_WORKSPACE_CONFIGS_FAILURE:
      return error
  }
  return state
})

const initialized = createReducer<State['initialized'], ConfigAction>((state = initialState.initialized, { type }) => {
  switch (type) {
    case GET_WORKSPACE_CONFIGS_SUCCESS:
    case GET_WORKSPACE_CONFIGS_FAILURE:
      return true
  }
  return state
})

export const getWorkspaceConfigsReducer = combineReducers({
  result,
  loading,
  error,
  initialized,
})

// selectors

export const workspaceConfigsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.workspace.getWorkspaceConfigs.result,
  (result) => result,
)

export const workspaceConfigsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.workspace.getWorkspaceConfigs.loading,
  (loading) => loading,
)

export const workspaceConfigsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.workspace.getWorkspaceConfigs.error,
  (error) => error,
)

export const workspaceConfigsInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.workspace.getWorkspaceConfigs.initialized,
  (initialized) => initialized,
)

interface SelectedConfigSelector {
  (state: any): WorkspaceConfig | undefined
}
// TODO memoize selector
export const selectedConfigSelector: SelectedConfigSelector = (state) => {
  const { selectedConfig } = state.workspace.view
  return selectedConfig
    ? state.workspace.getWorkspaceConfigs.result.find((config: WorkspaceConfig) => config.id === selectedConfig)
    : undefined
}

// api
// -> handled in auth / userConfig

// sagas
const collectionId = 'workspaceConfigs'

export function* getWorkspaceConfigsSaga(): SagaIterator {
  const user = yield select(getUserResultSelector)

  yield put({
    type: GET_USERCONFIG_REQUEST,
    collectionId,
  })

  const { success, failure } = yield race({
    success: take(
      (action: AnyAction) =>
        action.type === GET_USERCONFIG_SUCCESS && action.collectionId === collectionId && !action.configId,
    ),
    failure: take(
      (action: AnyAction) =>
        action.type === GET_USERCONFIG_FAILURE && action.collectionId === collectionId && !action.configId,
    ),
  })

  if (success) {
    const userConfigs = yield select(getUserConfigResultSelector)
    const forecastConfigs: ForecastConfig[] = queryClient.getQueryData(QUERY_KEY_SITE_FORECAST_CONFIGS) || []
    const weatherConfigs: WeatherConfig[] = queryClient.getQueryData(QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS) || []
    const configs: WorkspaceConfig[] = cloneDeep(Object.values<WorkspaceConfig>(userConfigs?.workspaceConfigs || {}))
    configs.forEach((config) => {
      normalizeWorkspaceConfig(config, user, forecastConfigs, weatherConfigs)
    })
    yield put({
      type: GET_WORKSPACE_CONFIGS_SUCCESS,
      configs,
    })
  } else if (failure) {
    yield put({
      type: GET_WORKSPACE_CONFIGS_FAILURE,
      error: failure.error,
    })
  }
}
