import { isNumeric } from 'utils/dataFormatting'

export type Weights = Partial<Record<string, number>> | undefined

// takes a number of weights and computes relative distances between them to get percentages
// static weights can be optionally passed with are values in percent
// returns weights with each weight being a value between 0 and 1 and with all weights summed up to 1
export const normalizeWeights = (weights: Weights, staticWeightsPercent: Weights = {}): Weights => {
  const staticKeys = Object.keys(staticWeightsPercent)
  const weightKeys = Object.keys(weights || {}).filter((key) => !staticKeys.includes(key))
  const combinedKeys = [...weightKeys, ...staticKeys]

  // const equalDistribution = 1 / Object.keys(weights || {}).length
  const sum = weightKeys.reduce((result, key) => {
    return result + (isNumeric(weights?.[key]) ? (weights?.[key] as number) : 0)
  }, 0)

  let staticSum = 0
  const staticWeights = staticKeys.reduce((result, key) => {
    const staticValue = sum * ((staticWeightsPercent[key] || 0) / 100)
    staticSum += staticValue
    return { ...result, [key]: staticValue }
  }, {})

  const combinedSum = sum + staticSum
  const combinedWeights = { ...weights, ...staticWeights }

  const normalized: Weights = { ...combinedWeights }
  combinedKeys.forEach((key) => {
    normalized[key] = combinedSum > 0 && normalized?.[key] ? (normalized[key] as number) / combinedSum : 0
  })

  return normalized
}

export const gcd = (a, b) => {
  let t = 0
  a < b && ((t = b), (b = a), (a = t)) // swap them if a < b
  t = a % b
  return t ? gcd(b, t) : b
}

export const lcm = (a, b) => {
  return (a / gcd(a, b)) * b
}

export const convertPercentToDecimal = (weights: Weights) => {
  const weightKeys = Object.keys(weights || {})
  const transformedData = { ...weights }
  weightKeys.forEach((key) => {
    transformedData[key] = transformedData[key] ? transformedData[key] / 100 : transformedData[key]
  })
  return transformedData
}

export const scaleWeights = (weights: Weights) => {
  // Use toPrecision on every number including exponential and ensure it converts
  const transformedWeights = Object.keys(weights).reduce((prev, currentKey) => {
    const weight = weights[currentKey]
    const precisionValue = weight.toPrecision()
    const weightAfterConversion = Number(precisionValue)

    return {
      ...prev,
      [currentKey]: weightAfterConversion,
    }
  }, {})

  const allWeights = Object.keys(transformedWeights).map((key) => transformedWeights[key])

  let maxWeight = 0
  for (let i = 0; i < allWeights.length; i++) {
    if (allWeights[i] > maxWeight) {
      maxWeight = allWeights[i]
    }
  }

  // Scale all weights by 1/(maximum value of all weights)

  const scale = 1 / maxWeight
  const scaledWeights = Object.keys(transformedWeights).reduce((prev, currentKey) => {
    return {
      ...prev,
      [currentKey]: scale * transformedWeights[currentKey],
    }
  }, {})
  return scaledWeights
}

export const getOffsetIncreased = (originalValue: number, newValue: number): number => {
  return newValue - originalValue
}

export const increaseValueByOffset = (value: number, offset: number) => {
  return value + offset
}

export const getPercentageIncreased = (originalValue: number, newValue: number): number => {
  return ((newValue - originalValue) / originalValue) * 100
}

export const increaseValueByPercentage = (value: number, percentage: number) => {
  const percentageOfValue = (value / 100) * percentage
  return value + percentageOfValue
}

interface IncreaseChartPointsLinearlyProps {
  points: any[]
  changedPoint: any
  fixedValue?: number
}

export const increaseChartPointsLinearly = ({ points, changedPoint, fixedValue }: IncreaseChartPointsLinearlyProps) => {
  const changedPointTimestamp = Array.isArray(changedPoint)
    ? changedPoint[0]
    : changedPoint?.target?.category || changedPoint?.x

  const changedPointIndex = points.findIndex((p) => {
    const timestamp = Array.isArray(p) ? p[0] : p.x
    return timestamp === changedPointTimestamp
  })
  const changedValue = isNumeric(fixedValue) ? fixedValue : changedPoint?.newPoint?.low
  const leftEdgePoint = points[0]
  const rightEdgePoint = points[points.length - 1]

  if (points != null && changedPointIndex >= 0 && changedPointIndex <= points.length - 1) {
    // Left side of the updated point
    const leftEdgePointValue = Array.isArray(leftEdgePoint) ? leftEdgePoint[1] : leftEdgePoint.low
    // The change from start point to the left edge point
    const leftDifference = changedValue - leftEdgePointValue
    const leftStepSize = leftDifference / changedPointIndex
    for (let i = 1; i < changedPointIndex; i++) {
      const isArrayPoint = Array.isArray(points[i])
      const date = isArrayPoint ? points[i][0] : points[i]?.x
      const newValue = leftEdgePointValue + leftStepSize * i
      points[i] = [date, newValue, newValue]
    }

    // Right side of the updated point
    const rightEdgePointValue = Array.isArray(rightEdgePoint) ? rightEdgePoint[1] : rightEdgePoint.low
    // The change from changed point to the right edge point
    const rightDifference = rightEdgePointValue - changedValue
    const rightStepSize = rightDifference / (points.length - changedPointIndex - 1)

    for (let i = changedPointIndex + 1; i < points.length; i++) {
      const isArrayPoint = Array.isArray(points[i])
      const date = isArrayPoint ? points[i][0] : points[i]?.x
      const newValue = changedValue + rightStepSize * (i - changedPointIndex)
      points[i] = [date, newValue, newValue]
    }
  }

  return points
}
