import React from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import { FormContainer } from 'ui/form/form.style'
import AssetCrudCommonHeader from 'modules/asset/assetCrud/AssetCrudCommonHeader'
import AssetMeterDataTable from 'modules/asset/assetCrud/assetMeterData/AssetMeterDataTable'
import { assetTabNames } from 'fixtures/assetForm'

interface AssetMeterDataProps {
  asset: Asset
}

const AssetMeterData: React.FC<AssetMeterDataProps> = ({ asset }) => {
  return (
    <FormContainer>
      <AssetCrudCommonHeader asset={asset} activeTab={assetTabNames.meterData} />
      <AssetMeterDataTable asset={asset} />
    </FormContainer>
  )
}

export default AssetMeterData
