import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { msgid, ngettext, t } from 'ttag'
import LoadingButton from 'ui/form/LoadingButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Box } from '@material-ui/core'
import BackCastCalculationDialog from 'modules/workspace/header/backCast/BackCastCalculationDialog'
import { getNoOfBackCastsRunning, useCreateBackCastSaveMutation } from 'modules/workspace/header/backCast/backCast.api'
import { useSelector } from 'react-redux'
import {
  workspaceDraftAssetSelectionSelector,
  workspaceDraftDataStreamSelectionSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'
import { QUERY_KEY_BACK_CAST_TIMESERIES } from 'modules/dataStreams/api/timeseries.api'
import { useQueryClient } from 'react-query'
import FadablePopperTooltip from 'ui/elements/FadablePopperTooltip'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { isAdmin, isImpersonatedAdmin, useIsReadOnlyUser, hasPermissionForSiteAssessmentBackcast } from 'utils/user'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const CHECK_NO_OF_BACK_CASTS_RUNNING_IN_MS = 1000 * 15 // 15 seconds

interface CreateBackcastCalculationInterface {
  setTriggeredBackcastCreation: (value: boolean) => void
}

const CreateBackCastCalculation: React.FC<CreateBackcastCalculationInterface> = ({ setTriggeredBackcastCreation }) => {
  const user = useSelector(getUserResultSelector)

  const forecastConfigs = useSiteForecastConfigs()

  const [openBackCastDialog, setOpenBackCastDialog] = useState(false)
  const [noOfBackCastsRunning, setNoOfBackCastsRunning] = useState<number>(0)
  const queryClient = useQueryClient()
  const isReadOnlyUser = useIsReadOnlyUser()

  const { isSuccess, reset } = useCreateBackCastSaveMutation()

  const assetsAndModelsSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const dataStreamsSelection = useSelector(workspaceDraftDataStreamSelectionSelector)

  const selectedSiteForecastDataStreams = dataStreamsSelection?.filter(
    (datastream) =>
      datastream.type === TimeSeriesType.SITE_FORECAST && forecastConfigs?.data?.some((fc) => fc.id === datastream.id),
  )

  const disableCreateBackCastButton = useMemo(
    () => !assetsAndModelsSelection.length || !selectedSiteForecastDataStreams.length,
    [assetsAndModelsSelection, selectedSiteForecastDataStreams],
  )

  const handleToggleBackCastDialog = (value: boolean) => {
    setOpenBackCastDialog(value)
  }

  const handleClickCreateBackCast = () => {
    handleToggleBackCastDialog(true)
    reset()
  }

  // Used ref to clear the timeout outside of useeffect if necessary
  const checkNoOfBackCastsRunningTimer: { current: NodeJS.Timeout | null } = useRef(null)
  // Check no Of backcast running for this user by calling this api periodically for every 15 seconds check useeffect
  const handleCheckNoOfBackCastsRunning = useCallback(() => {
    getNoOfBackCastsRunning().then((response) => {
      setNoOfBackCastsRunning(response?.response?.data as number)
    })
  }, [])

  //TODO Periodic calls, need to replace it by websockets
  useEffect(() => {
    checkNoOfBackCastsRunningTimer.current = setInterval(
      handleCheckNoOfBackCastsRunning,
      CHECK_NO_OF_BACK_CASTS_RUNNING_IN_MS,
    )

    if (noOfBackCastsRunning === 0) {
      clearInterval(checkNoOfBackCastsRunningTimer.current as NodeJS.Timeout)
    }
    return () => {
      clearInterval(checkNoOfBackCastsRunningTimer.current as NodeJS.Timeout)
    }
  }, [noOfBackCastsRunning > 0])

  // Fetch the backcast timeseries after creating, by checking the number of backcast running
  // As the number of backcast changes that means data is ready for atleast one model so we fetch the backcast timeseries
  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEY_BACK_CAST_TIMESERIES)
  }, [noOfBackCastsRunning])

  const toolTipRef = useRef(null)

  const getBackCastCalculationStatus = () => {
    return (
      <span>
        {ngettext(
          msgid`Calculation is in progress for ${noOfBackCastsRunning} Backcast.`,
          `Calculation is in progress for ${noOfBackCastsRunning} Backcasts.`,
          noOfBackCastsRunning,
        )}
      </span>
    )
  }

  return (
    <div ref={toolTipRef}>
      <Box>
        <ConditionalWrapper
          condition={noOfBackCastsRunning > 0}
          wrapper={(children) => (
            <PopperTooltip
              popperLabel={children}
              popperContent={getBackCastCalculationStatus()}
              position={PopperTooltipPosition.BOTTOM_START}
            />
          )}
        >
          <LoadingButton
            variant="contained"
            size="small"
            disabled={isReadOnlyUser || disableCreateBackCastButton}
            isAdminComponent={
              (isAdmin(user) || isImpersonatedAdmin(user)) && !hasPermissionForSiteAssessmentBackcast(user, true)
            }
            startIcon={
              noOfBackCastsRunning > 0 ? (
                <CircularProgress style={{ cursor: 'help' }} size={22} thickness={4.5} />
              ) : (
                <FontAwesomeIcon icon={'chart-line'} fixedWidth />
              )
            }
            onClick={handleClickCreateBackCast}
          >{t`Create backcast`}</LoadingButton>
        </ConditionalWrapper>

        {isSuccess && !openBackCastDialog && noOfBackCastsRunning > 0 && (
          <FadablePopperTooltip tooltipRef={toolTipRef} tooltipContent={getBackCastCalculationStatus()} />
        )}
      </Box>

      {openBackCastDialog && (
        <BackCastCalculationDialog
          selectedSiteForecastDataStreams={selectedSiteForecastDataStreams}
          onCloseDialog={() => handleToggleBackCastDialog(false)}
          onCheckNoOfBackCastsRunning={handleCheckNoOfBackCastsRunning}
          setTriggeredBackcastCreation={(value) => setTriggeredBackcastCreation(value)}
        />
      )}
    </div>
  )
}

export default React.memo(CreateBackCastCalculation)
