import { Typography } from '@material-ui/core'
import { FormApi } from 'final-form'

import { Asset } from 'modules/asset/store/asset.types'
import React, { useState } from 'react'
import { FormSpy } from 'react-final-form'
import { t } from 'ttag'

import Flex from 'ui/styles/Flex'
import { useUniqueAllAssets } from 'utils/asset'
import ParentRelation from 'modules/asset/assetCrud/assetDetails/ParentRelation'
import ChildRelation from 'modules/asset/assetCrud/assetDetails/ChildRelation'

// exported component

interface SectionRelationProps {
  asset: Asset
  form: FormApi<Asset>
  enterManually: boolean
  onRelationChange: () => void
}

const SectionRelation: React.FC<SectionRelationProps> = ({ asset, form, enterManually, onRelationChange }) => {
  const [formState, setFormState] = useState(asset)

  // console.log('form.getState() =', formState)
  const { errors: formErrors } = form.getState()
  const allAssets = useUniqueAllAssets()

  // console.log({ allAssets })

  return (
    <Flex direction="column">
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as Asset)
        }}
      />

      <Typography variant="subtitle1" gutterBottom>
        {t`Relation`}
      </Typography>
      <ParentRelation
        allAssets={allAssets}
        asset={asset}
        formState={formState}
        form={form}
        enterManually={enterManually}
        formErrors={formErrors}
        onRelationChange={onRelationChange}
      />

      <ChildRelation
        formState={formState}
        enterManually={enterManually}
        allAssets={allAssets}
        asset={asset}
        form={form}
        onRelationChange={onRelationChange}
        formErrors={formErrors}
      />
    </Flex>
  )
}

export default React.memo(SectionRelation)
