import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { FormApi } from 'final-form'
import { AnyObject } from 'react-final-form'
import { QUERY_SCHEDULE } from 'utils/query-string'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import { c, t } from 'ttag'
import ScheduleFormFields from 'modules/workspace/schedule/ScheduleFormFields'
import { CreateScheduleInputData, SubmitScheduleSeriesProps } from 'modules/workspace/schedule/schedule.types'
import { usePrevious } from 'utils/state'
import {
  useCreateScheduleInputDataMutation,
  useScheduleSeriesChanged,
  useScheduleSeriesChangedMutation,
} from 'modules/workspace/schedule/schedule.api'
import { getCreateScheduleFormChangedFields, ScheduleLocalStorageKeys } from 'utils/schedule'
import { convertZonedTimeToUtc, isDateValid } from 'utils/date'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { LanguageKeys } from 'fixtures/header'
import { DeepPartial } from 'ts-essentials'
import {
  SAVE_WORKSPACE_DRAFT_REQUEST,
  SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { DataStreamSelectionItem } from 'modules/dataStreams/dataStreams.types'
import { useDispatch, useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useDataStreams } from 'utils/dataStream'
import { Asset } from 'modules/asset/store/asset.types'
import { useUniqueSelectedAssets } from 'utils/asset'
import { Timezone } from 'fixtures/timezones'
import { AppEntityNamesForTranslation, tGetEntityName } from 'fixtures/commonTranslations'

export const ScheduleFormFieldsFirstColumnContainer = styled(Flex)`
  min-width: 12em;
  max-width: 14em;
  & .MuiInput-formControl {
    width: 100%;
  }
`

export const ScheduleFormFieldsSecondColumnContainer = styled(Flex)`
  min-width: 15em;
`
interface ScheduleFormActionsButtonsContainerProps {
  is_lang_eng: number
}

// export const ScheduleFormActionsButtonsContainer = styled(Flex)<ScheduleFormActionsButtonsContainerProps>`
//   width: ${(props) => (props.is_lang_eng ? '13.7em' : '19.2em')};
// `
export const ScheduleFormActionsButtonsContainer = styled(Flex)<ScheduleFormActionsButtonsContainerProps>`
  width: ${(props) => (props.is_lang_eng ? 'inherit' : 'inherit')};
`

export const ScheduleFormAutocompleteErrorMsg = styled.div`
  position: absolute;
  margin-top: 45px;
`

interface handleSaveInputDataAndUpdateDatesProps {
  // prevValues: CreateScheduleInputData
  newValues: CreateScheduleInputData
  setSubmitValue?: boolean
}

interface ScheduleFormProps {
  form: FormApi<CreateScheduleInputData>
  disableSubmitButton: boolean
  onCheckAndChangeMainChartDateRange: (currentValues: CreateScheduleInputData) => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  onSetDisableSubmitButton: (value: boolean) => void
  onSubmitSchedule: (data: SubmitScheduleSeriesProps) => void
  submitInProgress: boolean
  revisionTimingInfo: any
  userTimezone: Timezone
  initialScheduleData: CreateScheduleInputData
}

const ScheduleForm: React.FC<ScheduleFormProps> = ({
  form,
  onFormSubmit,
  onSetDisableSubmitButton,
  onSubmitSchedule,
  onCheckAndChangeMainChartDateRange,
  disableSubmitButton,
  submitInProgress,
  revisionTimingInfo,
  userTimezone,
  initialScheduleData,
}) => {
  const dispatch = useDispatch()

  const langKey = (localStorage.getItem('language') as keyof typeof LanguageKeys) || LanguageKeys.english
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const selectedAssets = useUniqueSelectedAssets()
  const dataStreams = useDataStreams()

  const [currentFormValues, setCurrentFormValues] = useState<CreateScheduleInputData>({})
  const previousFormValues = usePrevious(currentFormValues)
  const [openDiscardSeriesChangesDialog, setOpenDiscardSeriesChangesDialog] = useState(false)

  const formDataWhenResettingAFieldRef = useRef<CreateScheduleInputData | null>(null)

  const { mutate: saveCreateScheduleInputMutation } = useCreateScheduleInputDataMutation()

  const { mutate: saveScheduleSeriesChangedMutation } = useScheduleSeriesChangedMutation()
  const seriesChangedQueryResult = useScheduleSeriesChanged()
  const seriesChangedToBlockForm = seriesChangedQueryResult?.data?.value || false
  const [datesChangedDueToTimezoneUpdate, setDatesChangedDueToTimezoneUpdate] = useState(false)

  /**
   * Check the both new and old values and update the input
   * @param newValues
   * @param prevValues
   *
   */
  const handleAutoSaveInputData = ({ newValues, setSubmitValue = true }: handleSaveInputDataAndUpdateDatesProps) => {
    if (newValues?.asset && newValues?.end && newValues?.start) {
      const fieldsChanged = getCreateScheduleFormChangedFields({
        currentData: newValues,
        previousData: previousFormValues || {},
      })

      const onlyDateFieldsChanged =
        fieldsChanged.length &&
        fieldsChanged?.length <= 2 &&
        fieldsChanged.every((field) => ['start', 'end'].includes(field.key))
      const datesChangedDueToTimezone = datesChangedDueToTimezoneUpdate && onlyDateFieldsChanged

      // If useSource is passed, use that value
      let useSource = newValues.useSource || false
      if (!newValues.hasOwnProperty('useSource')) {
        if (datesChangedDueToTimezone) {
          // If dates are changed due to Timezone do not revert to source
          useSource = false
        }
        if (onlyDateFieldsChanged && !datesChangedDueToTimezone) {
          useSource = true
        }
      }

      if (setSubmitValue) {
        onSetDisableSubmitButton(true)
      }

      // console.log('new values =', newValues)
      if (newValues && Object.keys(newValues).length) {
        // Save the input to create series

        const data = { ...newValues, useSource }
        // Convert the dates
        const startDate = new Date(data?.start?.date)
        startDate.setSeconds(0o0, 0o00)

        data.start = {
          date: convertZonedTimeToUtc(startDate, userTimezone),
          timezone: data.start?.timezone,
        }

        const endDate = new Date(data?.end?.date)
        endDate.setSeconds(0o0, 0o00)
        data.end = {
          date: convertZonedTimeToUtc(endDate, userTimezone),
          timezone: data.end?.timezone,
        }

        data['savedInUserSetting'] = false
        saveCreateScheduleInputMutation(data)
      }
    }
  }

  /**
   * Reset the series changes
   * Update the input with initial values
   */
  const handleResetFormAndSeriesChanges = () => {
    // form.reset(formValues)

    handleAutoSaveInputData({ newValues: { ...initialScheduleData, useSource: true } })
    saveScheduleSeriesChangedMutation({ value: false })
    localStorage.setItem(ScheduleLocalStorageKeys.seriesChanged, 'false')
    localStorage.setItem(ScheduleLocalStorageKeys.outPutSeries, JSON.stringify({ custom: undefined }))
    const draft: DeepPartial<WorkspaceConfig> = {
      schedule: {
        touchedPoints: [],
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }

  /**
   * Revert to source
   */
  const handleRevertToSource = () => {
    // console.log('revert to source')
    handleAutoSaveInputData({ newValues: { ...currentFormValues, useSource: true }, setSubmitValue: false })
  }

  /**
   * Whenever series changed it should be saved before submitting
   * That is why if series changed disable the submit button
   */
  useEffect(() => {
    if (seriesChangedToBlockForm) {
      onSetDisableSubmitButton(true)
    }
  }, [seriesChangedToBlockForm])

  /**
   * Proceed discarding the changes made in series
   */
  const handleProceedDiscardSeriesChanges = () => {
    localStorage.setItem(ScheduleLocalStorageKeys.outPutSeries, JSON.stringify({ custom: undefined }))
    handleAutoSaveInputData({ newValues: { ...currentFormValues, useSource: true } })
    setOpenDiscardSeriesChangesDialog(false)
    formDataWhenResettingAFieldRef.current = null
    const draft: DeepPartial<WorkspaceConfig> = {
      schedule: {
        touchedPoints: [],
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }

  const handleSelectAsset = (asset: Asset) => {
    if (asset) {
      const isAssetAlreadySelected = selectedAssets.some((a) => a.id === asset.id)
      if (!isAssetAlreadySelected) {
        dispatch({
          type: SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
          asset: asset,
          selectionOptions: { ctrlKey: true },
        })
      }
    }
  }

  /**
   * Select the unselected Datastream in the sidenav if its used to create series and series is changed
   * @param dataStream
   */
  const handleSelectDataStream = (dataStream: DataStreamSelectionItem) => {
    if (dataStream && dataStream.id) {
      const isDataStreamAlreadySelected = (selectedDataStreams || []).some(
        (sd) => dataStream?.id && sd.id === dataStream.id,
      )

      if (!isDataStreamAlreadySelected) {
        const filteredDataStreamsMissingFromSelection = dataStreams.filter((d) => d.id == dataStream.id)

        const allPossibleColorIndexes = Array.from(Array(dataStreams.length).keys())
        const oldColors = selectedDataStreams
          .filter((s) => typeof s.color === 'number')
          .map((s) => s.color)
          .sort((a, b) => a - b)

        const transformedDataStreams = filteredDataStreamsMissingFromSelection.map((dataStream) => {
          const color = allPossibleColorIndexes.find((c) => !oldColors.includes(c))
          oldColors.push(color)

          return {
            ...dataStream,
            label: dataStream.name,
            name: dataStream.id,
            color,
          }
        })

        const newSelection = selectedDataStreams.concat(transformedDataStreams)
        const draft: DeepPartial<WorkspaceConfig> = {
          data: {
            selection: newSelection,
          },
        }
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
    }
  }

  /**
   * Do not discard the changes made in series
   * Reset the form field value which triggered to discard the series changes
   */
  const handleDoNotDiscardSeriesChanges = () => {
    const fieldsChanged = getCreateScheduleFormChangedFields({
      currentData: formValues,
      previousData: formDataWhenResettingAFieldRef.current,
    })

    if (fieldsChanged.length) {
      const dataStreamSelectionChanged = fieldsChanged.filter((field) => field.key === 'sourceDataStream')
      if (dataStreamSelectionChanged?.length) {
        handleSelectDataStream(dataStreamSelectionChanged[0]?.oldValue)
      }

      const assetSelectionChanged = fieldsChanged.filter((field) => field.key === 'asset')
      if (assetSelectionChanged?.length) {
        handleSelectAsset(assetSelectionChanged[0]?.oldValue)
      }

      const newValues = fieldsChanged?.map((data) => {
        return {
          field: data.key,
          value: data.oldValue,
        }
      })

      form.mutators.setValues(newValues)
      const newCurrentFormValues = fieldsChanged.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.key]: curr.oldValue,
        }
      }, currentFormValues)
      // console.log('do not discard')
      handleAutoSaveInputData({ newValues: { ...newCurrentFormValues, useSource: false } })
    }
    setOpenDiscardSeriesChangesDialog(false)
  }

  /**
   * Update the currentFormValues state on every form change
   * This state is useful to compare previous and current state
   * After comparing:
   * 1. If the dates doesn't match we should update main chart dates
   * 2. If there is a saved config for asset, source dataStream and target dataStream update the form with saved dates
   */
  const formValues = form.getState().values
  useEffect(() => {
    if (formValues?.start?.date && formValues?.end?.date) {
      const isTimePeriodValid = isDateValid(formValues?.start?.date) && isDateValid(formValues?.end?.date)
      if (isTimePeriodValid) {
        setCurrentFormValues(formValues)
      }
    }
  }, [JSON.stringify(formValues)])

  // console.log('form values =', form.getState())

  /**
   * Check and compare the previous and current form values if changed then save the input
   */
  useEffect(() => {
    if (currentFormValues?.start?.date && currentFormValues?.end?.date) {
      const isTimePeriodValid = isDateValid(currentFormValues?.start?.date) && isDateValid(currentFormValues?.end?.date)
      if (isTimePeriodValid) {
        const isSeriesChanged = JSON.parse(localStorage.getItem(ScheduleLocalStorageKeys.seriesChanged))

        if (isSeriesChanged) {
          // Show the unsaved dialog if the output Series changed
          const fieldsChanged = formDataWhenResettingAFieldRef.current
            ? getCreateScheduleFormChangedFields({
                currentData: currentFormValues,
                previousData: formDataWhenResettingAFieldRef.current,
              })
            : null

          // Check if the dialog is due to date changes which are result of timezone change
          const onlyDateFieldsChanged =
            fieldsChanged?.length &&
            fieldsChanged?.length <= 2 &&
            fieldsChanged.every((field) => ['start', 'end'].includes(field.key))
          const datesChangedDueToTimezone = datesChangedDueToTimezoneUpdate && onlyDateFieldsChanged
          if ((formDataWhenResettingAFieldRef.current && !fieldsChanged?.length) || datesChangedDueToTimezone) {
            // Check if the form change was due to resetting previous value or from user interaction
            setOpenDiscardSeriesChangesDialog(false)
          } else {
            if (!formDataWhenResettingAFieldRef.current) {
              formDataWhenResettingAFieldRef.current = previousFormValues
            }
            setOpenDiscardSeriesChangesDialog(true)
          }
        } else {
          // Series not changed and update the input
          formDataWhenResettingAFieldRef.current = null
          const fieldsChanged = getCreateScheduleFormChangedFields({
            currentData: currentFormValues,
            previousData: previousFormValues || {},
          })
          if (fieldsChanged.length) {
            handleAutoSaveInputData({ newValues: currentFormValues })
          }
        }
      }
    }
  }, [JSON.stringify(currentFormValues), JSON.stringify(previousFormValues), datesChangedDueToTimezoneUpdate])

  return (
    <form onSubmit={onFormSubmit} noValidate>
      {openDiscardSeriesChangesDialog && (
        <ConfirmationDialog
          heading={t`Unsaved changes external`}
          text={t`There are unsaved changes in:`}
          confirmAction={t`Discard and proceed`}
          cancelAction={t`Cancel`}
          onConfirm={handleProceedDiscardSeriesChanges}
          onCancel={handleDoNotDiscardSeriesChanges}
          openDialog={true}
        >
          <>
            <div>{t`There are unsaved changes in:`}</div>
            <ul>
              <li>{tGetEntityName(AppEntityNamesForTranslation.PREPARE_SCHEDULE)}</li>
            </ul>
            <div>{c('Unsaved changes dialog').t`They will be discarded if you proceed.`}</div>
          </>
        </ConfirmationDialog>
      )}

      <FormBlockNavigation
        blockWithExternalChanges={seriesChangedToBlockForm}
        form={form}
        currentPageQueries={[QUERY_SCHEDULE]}
        currentPageRoute={ROUTE_WORKBENCH}
        navigationDialogText={tGetEntityName(AppEntityNamesForTranslation.PREPARE_SCHEDULE)}
        navigationDialogKey={'ManualForecastFormNew'}
        onResetExternalChanges={handleResetFormAndSeriesChanges}
        resetWithCurrentValues={true}
      />
      <>
        <ScheduleFormFields
          disableSubmitButton={disableSubmitButton}
          onSubmitScheduleSeries={onSubmitSchedule}
          submitInProgress={submitInProgress}
          seriesChanged={seriesChangedToBlockForm}
          onCheckAndChangeMainChartDateRange={onCheckAndChangeMainChartDateRange}
          form={form}
          onRevertToSource={handleRevertToSource}
          langKey={langKey}
          revisionTimingInfo={revisionTimingInfo}
          userTimezone={userTimezone}
          onSetDatesChangedDueToTimezoneUpdate={setDatesChangedDueToTimezoneUpdate}
        />
      </>
    </form>
  )
}

export default ScheduleForm
