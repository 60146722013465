import axios from 'axios'
import { apiRequest, useOptimisticMutation } from 'utils/request'
import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query'
import {
  DELIVERY_TARGET_TYPE_ENERCAST_FTP,
  DeliveryTarget,
  DeliveryTargetSaveMainItem,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import {
  defaultDeliveryTargetId,
  enercastFtpServerId,
  getDefaultDeliveryTarget,
  getNewDeliveryTargetData,
  transformDeliveryTargetAfterGet,
} from 'utils/delivery'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { FormApi } from 'final-form'

const DELIVERY_TARGET_BASE_URL = '/api/deliveries/v2'
const DELIVERY_TARGET_ENERCAST_FTP = '/api/customer-ftp/v1'

// Query keys for caching data

export const QUERY_KEY_DELIVERY_TARGETS = 'delivery:targets'
export const QUERY_KEY_DELIVERY_TARGETS_SAVEMAINITEMKEY = 'delivery:targets:saveMainItemKey'

// Get all delivery targets
const getDeliveryTargets = async () => {
  const data = await apiRequest<DeliveryTarget[]>(() => axios.get(`${DELIVERY_TARGET_BASE_URL}/list`))
  // add default target to "real" delivery targets
  const defaultTarget = getDefaultDeliveryTarget()
  return [defaultTarget, ...(data || [])]
}

export const useDeliveryTargets = () => {
  return useQuery<DeliveryTarget[]>(QUERY_KEY_DELIVERY_TARGETS, getDeliveryTargets)
}

export const useDeliveryTargetsTableItems = () => {
  const deliveryTargets = useDeliveryTargets()

  const forecastConfigurations = useSiteForecastConfigs()
  const transformedDeliveryTargets: QueryObserverResult<DeliveryTarget[]> = { ...deliveryTargets, data: undefined }
  if (deliveryTargets.data && forecastConfigurations.data) {
    transformedDeliveryTargets.data = deliveryTargets.data.map((target) =>
      transformDeliveryTargetAfterGet(target, forecastConfigurations.data),
    )
  }
  return transformedDeliveryTargets
}

// Get delivery target by Id
const getDeliveryTargetById = async (id: string) => {
  let data: DeliveryTarget
  if (id === defaultDeliveryTargetId) {
    data = getDefaultDeliveryTarget()
  } else if (id === 'new') {
    data = getNewDeliveryTargetData()
  } else if (id === enercastFtpServerId) {
    data = await apiRequest<any>(() => axios.get(`${DELIVERY_TARGET_ENERCAST_FTP}`))
  } else {
    data = await apiRequest<DeliveryTarget>(() => axios.get(`${DELIVERY_TARGET_BASE_URL}/${id}`))
  }
  return data
}

export const useDeliveryTargetById = (id: string) => {
  return useQuery<DeliveryTarget>([QUERY_KEY_DELIVERY_TARGETS, id], () => getDeliveryTargetById(id))
}

// Delete delivery target
const deleteDeliveryTarget = async (data: DeliveryTarget) => {
  return await apiRequest<DeliveryTarget>(() => {
    return axios.delete(`${DELIVERY_TARGET_BASE_URL}/${data.id}`)
  })
}

export const useDeliveryTargetsDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteDeliveryTarget, {
    onSettled: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_DELIVERY_TARGETS)
    },
  })
}

// Create and update delivery target

const saveDeliveryTarget = async (data: DeliveryTarget, isNew: boolean) => {
  if (data.isNewCopy) {
    isNew = true
  }

  if (isNew) {
    if (data.type === DELIVERY_TARGET_TYPE_ENERCAST_FTP) {
      const protocolType = data.ftpConfiguration?.protocol.toLowerCase()
      return await apiRequest<any>(() =>
        axios.post(`${DELIVERY_TARGET_ENERCAST_FTP}/type/${protocolType}/name/${data.name}`),
      )
    }
    return await apiRequest<DeliveryTarget>(() => axios.post(`${DELIVERY_TARGET_BASE_URL}`, data))
  } else {
    return await apiRequest<DeliveryTarget>(() => axios.put(`${DELIVERY_TARGET_BASE_URL}/${data.id}`, data))
  }
}

export interface UseDeliveryTargetSaveMutationProps {
  isNew: boolean
  targetId: string
  // REACT FINAL FORM REF
  formReference: FormApi
}

export const useDeliveryTargetSaveMutation = (isNew: boolean) => {
  // const forecastConfigurations = useSiteForecastConfigs()

  // save and close/next functionality
  // const { SAVE, SAVE_AND_CLOSE, SAVE_AND_NEW, SAVE_AND_NEXT } = FormSaveOptions
  // const saveMainItemKey = useDeliveryTargetSaveMainItemKey()
  // const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  //
  // const allDeliveryTargets = useDeliveryTargets()

  return useOptimisticMutation<DeliveryTarget, DeliveryTarget, DeliveryTarget[] | undefined>({
    queryCacheKey: QUERY_KEY_DELIVERY_TARGETS,
    apiMutator: (variables) => saveDeliveryTarget(variables, isNew),
    cacheUpdater: (updatedDeliveryTarget, oldDeliveryTargets) => {
      if (isNew) {
        // if it is new do not update the cache
        return oldDeliveryTargets
      } else {
        // if it is an old delivery target we need to update the cache with updated values
        return (oldDeliveryTargets || []).map((deliveryTarget) =>
          deliveryTarget.id === updatedDeliveryTarget.id ? updatedDeliveryTarget : deliveryTarget,
        )
      }
    },
    onSuccess: () => {
      // TODO for some reason getting undefined for formReference so added navigation logic in deliveryTargetdetails
      // queryClient.invalidateQueries([QUERY_KEY_DELIVERY_TARGETS, data.id])
      // const saveOption =
      //   (isNew ? saveMainItemKey?.data?.createDeliveryTarget : saveMainItemKey?.data?.updateDeliveryTarget) || SAVE
      //
      // if (forecastConfigurations?.data && formReference) {
      //   if (isNew && saveOption === SAVE_AND_NEW) {
      //     formReference.reset()
      //   } else {
      //     formReference.reset(transformDeliveryTargetAfterGet(data, forecastConfigurations?.data))
      //   }
      // }
      //
      // switch (saveOption) {
      //   case SAVE_AND_CLOSE:
      //     onDeleteQueryStrings([QUERY_DELIVERY_TARGET])
      //     break
      //   case SAVE_AND_NEW:
      //     onUpdateQueryString(getDeliveryTargetQueryObj())
      //     break
      //   case SAVE_AND_NEXT:
      //     const currentDeliveryTargetIndex = allDeliveryTargets?.data?.findIndex((target) => target.id === data.id)
      //     const deliveryTargetCount = (allDeliveryTargets.data || []).length
      //     let nextDeliveryTargetIndex = 0
      //
      //     if (currentDeliveryTargetIndex !== undefined && currentDeliveryTargetIndex + 1 !== deliveryTargetCount) {
      //       nextDeliveryTargetIndex = currentDeliveryTargetIndex + 1
      //     }
      //     onUpdateQueryString(getDeliveryTargetQueryObj((allDeliveryTargets.data || [])[nextDeliveryTargetIndex]))
      //     break
      //   default:
      //     onUpdateQueryString(getDeliveryTargetQueryObj(data))
      //     break
      // }
    },
  })
}

// user settings for save button in delivery target page
export const useDeliveryTargetSaveMainItemKey = () => {
  return useUserSetting<DeliveryTargetSaveMainItem>(QUERY_KEY_DELIVERY_TARGETS_SAVEMAINITEMKEY)
}

export const useDeliveryTargetSaveMainItemKeySaveMutation = () => {
  return useUserSettingSaveMutation<DeliveryTargetSaveMainItem>(QUERY_KEY_DELIVERY_TARGETS_SAVEMAINITEMKEY)
}
