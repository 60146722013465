import { c, t } from 'ttag'

import { Asset } from 'modules/asset/store/asset.types'
import { isCHP, isCluster, isGenerator, isPark, isSolarPark, isSolarPlant } from 'utils/asset'
import {
  ClusterTypesEnum,
  HSAT_TILT_AZIMUTH_MAX,
  HSAT_TILT_AZIMUTH_MIN,
  MAX_AZIMUTH,
  MAX_DELIVERY_TIMEOUT,
  MAX_LAT,
  MAX_LNG,
  MAX_PORT_NUMBER,
  MAX_PPA_RATE,
  MAX_TILT,
  MIN_AZIMUTH,
  MIN_DELIVERY_TIMEOUT,
  MIN_LAT,
  MIN_LNG,
  MIN_PORT_NUMBER,
  MIN_PPA_RATE,
  MIN_TILT,
  TrackerNamesEnum,
  TSAT_MAX_TILT,
} from 'fixtures/assetForm'
import { Availability } from 'modules/asset/availability/Availability.types'
import { dateDifferenceMs, isDateValid, isSameDate } from 'utils/date'
import { RegistrationType, ResetPasswordType } from 'modules/auth/Auth.types'
import { isJson, isNumeric } from 'utils/dataFormatting'
import { basicTextLengthConstraints, LoginIdLengthConstraints, PasswordLengthConstraints } from 'fixtures/auth'
import { ChangePasswordType } from 'modules/auth/ChangePasswordForm'
import {
  DeliveryTimesType,
  EvaluationDurationType,
  ForecastConfig,
  SiteForecastUpdateTimesFrequency,
} from 'modules/dataStreams/dataStreams.types'
import { DeepPartial } from 'ts-essentials'
import { getIndividualTimes } from 'utils/siteForecastConfiguration'
import { TimingPreview } from 'modules/dataStreams/siteForecast/SiteForecastDetails'

import {
  DELIVERY_TARGET_TYPE_CUSTOMER_FTP,
  DELIVERY_TARGET_TYPE_ENERCAST_FTP,
  DELIVERY_TARGET_TYPE_MAIL,
  DeliveryTarget,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { UserManagementType } from 'modules/userManagement/userManagement.types'
import { isAfter, isBefore } from 'date-fns'
import { TimePeriodWithRange } from 'modules/workspace/header/backCast/BackCastCalculationForm'
import { MeterDataCleansingConfiguration } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { FormApi } from 'final-form'
import {
  PenaltyBandCostType,
  PenaltyBandNew,
  PenaltyGeneratorType,
  PenaltyRegulationNew,
  PenaltyRegulationTableItemNew,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import { CreateScheduleInputData } from 'modules/workspace/schedule/schedule.types'
import { Roles } from 'utils/userManagement'

const { min: minLoginLen, max: maxLoginLen } = LoginIdLengthConstraints

export const authCommonErrorMsgs = () => {
  const { min: minPwdLen, max: maxPwdLen } = PasswordLengthConstraints
  const { min: minBasicTextLength, max: maxBasicTextLength } = basicTextLengthConstraints

  return {
    password: t`Must have ${minPwdLen} to ${maxPwdLen} characters.`,
    pwdMatch: t`Passwords do not match.`,
    email: t`Enter a valid e-mail address.`,
    loginExist: t`enercast ID already in use.`,
    emailExist: t`E-mail already in use.`,
    company: t`Must have ${maxBasicTextLength} characters.`,
    firstName: t`Must have ${minBasicTextLength} to ${maxBasicTextLength} characters.`,
    lastName: t`Must have ${minBasicTextLength} to ${maxBasicTextLength} characters.`,
  }
}

export const validate = {
  email: (value: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return value.trim().length < basicTextLengthConstraints.max * 2 && re.test(String(value.trim()).toLowerCase())
    // const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    // return re.test(value)
  },
  username: (value: string) => {
    const re = new RegExp(`^[a-z0-9_-]{${minLoginLen},${maxLoginLen}}$`, 'g')
    return re.test(value)
  },
  password: (value: string) => {
    return value.length < PasswordLengthConstraints.min || value.length > PasswordLengthConstraints.max
  },
  basicText: (value: string, type?: string) => {
    // removes leading and trailing blanks
    const repl = value.replace(/^\s+|\s+$|\s+(?=\s)/g, '')
    if (type === 'company') return repl.length > basicTextLengthConstraints.max
    return repl.length < basicTextLengthConstraints.min || repl.length > basicTextLengthConstraints.max
  },
  isPwdMatch: (pwd: string, confirmPwd: string) => {
    return pwd != confirmPwd
  },
}

const createMaxNumberErrorMessage = (currentVal, maxVal, minVal = 0) => {
  return isNaN(parseInt(currentVal))
    ? t`Required`
    : currentVal > maxVal || currentVal < minVal
    ? `${t`Should be between`} (${minVal}, ${maxVal})`
    : ''
}

interface ValidateAssetFormType {
  assetData: Asset
  form?: FormApi<Asset> | null
  formChanged?: boolean
  allAssets: Asset[]
}

export const validateAssetForm = ({ assetData, form, formChanged, allAssets }: ValidateAssetFormType) => {
  const errors: Partial<Asset> = {}
  const formDirty = (form && form?.getState().dirty) || formChanged

  // All clusters except the current
  const physicalClusterIds = allAssets
    .filter(
      (asset) => isCluster(asset) && asset.id !== assetData.id && asset.clusterType !== ClusterTypesEnum.COLLECTION,
    )
    .map((cluster) => cluster.id)

  const assetIsAGenerator = isGenerator(assetData)
  const assetIsAPark = isPark(assetData)
  const assetIsAParkWithManualData = isPark(assetData) && assetData?.enterManually
  const assetIsACluster = isCluster(assetData)

  if (formDirty) {
    if (!assetData.name) {
      errors.name = t`Required`
    }

    if (assetData.shape && !isJson(assetData.shape)) {
      errors.shape = t`Not a valid JSON`
    }

    if (assetIsAGenerator && !isNumeric(assetData.nomCap)) {
      errors.nomCap = t`Required`
    }

    // CHP Validation
    if (isCHP(assetData)) {
      errors.typeSpecificAttributes = {}
      if (
        isNumeric(assetData.typeSpecificAttributes.absoluteCapacityHeat) &&
        assetData.typeSpecificAttributes.absoluteCapacityHeat < 0
      ) {
        errors.typeSpecificAttributes['absoluteCapacityHeat'] = t`Needs to be >= 0`
      }
      if (
        isNumeric(assetData.typeSpecificAttributes.namePlateConsumption) &&
        assetData.typeSpecificAttributes.namePlateConsumption < 0
      ) {
        errors.typeSpecificAttributes['namePlateConsumption'] = t`Needs to be >= 0`
      }

      if (assetData.typeSpecificAttributes.absoluteCapacityHeat > 0 && assetData.nomCap > 0) {
        if (
          parseInt(String(assetData.typeSpecificAttributes.absoluteCapacityHeat), 10) +
            parseInt(String(assetData.nomCap), 10) >
          parseInt(String(assetData.typeSpecificAttributes.namePlateConsumption), 10)
        ) {
          errors.typeSpecificAttributes['namePlateConsumption'] = c('Asset:Chp').t`Value is too small`
        }
      }

      // Plausibility of Total electrical energy production
      if (
        assetData.nomCap &&
        assetData.typeSpecificAttributes.periodEnergySum &&
        assetData.typeSpecificAttributes.periodStartDay &&
        assetData.typeSpecificAttributes.periodEndDay
      ) {
        const diffBetweenDates = dateDifferenceMs(
          assetData.typeSpecificAttributes.periodStartDay,
          assetData.typeSpecificAttributes.periodEndDay,
          true,
        )

        if (assetData.nomCap * 24 * diffBetweenDates > +assetData.typeSpecificAttributes.periodEnergySum) {
          errors.typeSpecificAttributes['periodEnergySum'] = c('Asset:Chp').t`Check plausibility`
        }
      }
    }

    if ((assetData.enterManually || (assetData.id && isPark(assetData))) && !isNumeric(assetData.currentNomCap)) {
      errors.currentNomCap = t`Required`
    }

    if (isNumeric(assetData.nomCap) && assetData.nomCap <= 0) {
      errors.nomCap = t`Needs to be > 0`
    }

    if (isNumeric(assetData.currentNomCap) && assetData.currentNomCap <= 0) {
      errors.currentNomCap = t`Needs to be > 0`
    }

    if (
      !isCluster(assetData) &&
      isNumeric(assetData.ppaRate) &&
      (assetData.ppaRate < MIN_PPA_RATE || assetData.ppaRate > MAX_PPA_RATE)
    ) {
      errors.ppaRate = createMaxNumberErrorMessage(assetData.ppaRate, MAX_PPA_RATE, MIN_PPA_RATE)
    }

    if (isGenerator(assetData) || assetData.enterManually) {
      if (!assetData.location) {
        errors.location = {
          ['coordinate']: {
            ['latitude']: createMaxNumberErrorMessage('', MAX_LAT, MIN_LAT),
            ['longitude']: createMaxNumberErrorMessage('', MAX_LNG, MIN_LNG),
          },
        }
      } else {
        const lng = assetData.location.coordinate.longitude
        const lat = assetData.location.coordinate.latitude
        if (!lng || !lat || lat < MIN_LAT || lat > MAX_LAT || lng < MIN_LNG || lng > MAX_LNG) {
          errors.location = {
            ['coordinate']: {
              ['longitude']: createMaxNumberErrorMessage(lng, MAX_LNG, MIN_LNG),
              ['latitude']: createMaxNumberErrorMessage(lat, MAX_LAT, MIN_LAT),
            },
          }
        }
      }
    }

    if ((isSolarPark(assetData) && assetData.enterManually) || isSolarPlant(assetData)) {
      const {
        trackerName,
        azimuth,
        tilt,
        trackerAzimuthFrom,
        trackerAzimuthTo,
        trackerTiltFrom,
        trackerTiltTo,
      } = assetData.typeSpecificAttributes
      switch (trackerName) {
        case TrackerNamesEnum.none:
        default: {
          errors.typeSpecificAttributes = {
            ['azimuth']: createMaxNumberErrorMessage(azimuth, MAX_AZIMUTH, MIN_AZIMUTH) || null,
            ['tilt']: createMaxNumberErrorMessage(tilt, MAX_TILT, MIN_TILT) || null,
          }
          break
        }
        case TrackerNamesEnum.ttdatAadat: {
          errors.typeSpecificAttributes = {
            ['trackerAzimuthFrom']: createMaxNumberErrorMessage(trackerAzimuthFrom, MAX_AZIMUTH, MIN_AZIMUTH),
            ['trackerAzimuthTo']: createMaxNumberErrorMessage(trackerAzimuthTo, MAX_AZIMUTH, MIN_AZIMUTH),
            ['trackerTiltFrom']: createMaxNumberErrorMessage(trackerTiltFrom, MAX_TILT, MIN_TILT),
            ['trackerTiltTo']: createMaxNumberErrorMessage(trackerTiltTo, MAX_TILT, MIN_TILT),
          }
          break
        }
        case TrackerNamesEnum.vsat: {
          errors.typeSpecificAttributes = {
            ['trackerAzimuthFrom']: createMaxNumberErrorMessage(trackerAzimuthFrom, MAX_AZIMUTH, MIN_AZIMUTH),
            ['trackerAzimuthTo']: createMaxNumberErrorMessage(trackerAzimuthTo, MAX_AZIMUTH, MIN_AZIMUTH),
            ['tilt']: createMaxNumberErrorMessage(tilt, MAX_TILT, MIN_TILT),
          }
          break
        }
        case TrackerNamesEnum.tsat: {
          errors.typeSpecificAttributes = {
            ['trackerAzimuthFrom']: createMaxNumberErrorMessage(trackerAzimuthFrom, MAX_AZIMUTH, MIN_AZIMUTH),
            ['trackerAzimuthTo']: createMaxNumberErrorMessage(trackerAzimuthTo, MAX_AZIMUTH, MIN_AZIMUTH),
            ['tilt']: createMaxNumberErrorMessage(tilt, TSAT_MAX_TILT, MIN_TILT),
          }
          break
        }
        case TrackerNamesEnum.hsat: {
          errors.typeSpecificAttributes = {
            ['trackerTiltFrom']: createMaxNumberErrorMessage(
              trackerTiltFrom,
              HSAT_TILT_AZIMUTH_MAX,
              HSAT_TILT_AZIMUTH_MIN,
            ),
            ['trackerTiltTo']: createMaxNumberErrorMessage(trackerTiltTo, HSAT_TILT_AZIMUTH_MAX, HSAT_TILT_AZIMUTH_MIN),
            ['azimuth']: createMaxNumberErrorMessage(azimuth, HSAT_TILT_AZIMUTH_MAX, HSAT_TILT_AZIMUTH_MIN),
          }
          break
        }
      }
      Object.keys(errors.typeSpecificAttributes).map((key) => {
        if (!errors.typeSpecificAttributes[key]) {
          delete errors.typeSpecificAttributes[key]
        }
      })
    }

    const childRelationErrorMsg = t`Cannot add an asset which is already a member of another park or pool.`
    // const clusterInsideClusterErrorMsg = t`Including a (hybrid park|pool) in a cluster is not supported at this time.`

    if (assetIsACluster) {
      errors.generatorIds = ''
      const isPhysicalAggregator = assetData?.clusterType !== ClusterTypesEnum.COLLECTION
      if (isPhysicalAggregator) {
        // CLUSTER CHILDREN ERROR HANDLING: If Cluster has Generators in it
        if (assetData?.generatorIds) {
          // GENERATOR: Check if Generators are present in other physical Clusters
          const generatorsInsideCluster = assetData?.generatorIds.map((id) => allAssets.find((a) => a.id === id)) || []
          const generatorsPresentInOtherPhysicalClusters = generatorsInsideCluster.filter((generator) => {
            return (generator?.clusterIds || []).some((clusterId) => physicalClusterIds.includes(clusterId))
          })

          // GENERATOR: Check if Generators are present in other physical Parks
          const generatorsPresentInOtherPhysicalParks = generatorsInsideCluster.filter((generator) => generator?.parkId)

          if (generatorsPresentInOtherPhysicalClusters?.length || generatorsPresentInOtherPhysicalParks.length) {
            errors.generatorIds = childRelationErrorMsg
          }
        }

        // CLUSTER CHILDREN ERROR HANDLING: If Cluster has Parks in it
        if (assetData?.parkIds && assetData?.parkIds.length) {
          // PARK: Check if Parks are present in other physical Clusters
          const parksInsideCluster = (assetData?.parkIds || []).map((id) => allAssets?.find((a) => a.id === id)) || []
          const parksPresentInOtherPhysicalClusters = parksInsideCluster.filter((generator) => {
            return (generator?.clusterIds || []).some((clusterId) => physicalClusterIds.includes(clusterId))
          })

          if (parksPresentInOtherPhysicalClusters.length) {
            errors.generatorIds = childRelationErrorMsg
          }
        }
      }
    }

    if (assetIsAPark) {
      const parkParentRelationErrorMsg = t`A park can be a member of multiple clusters, but of no more than one hybrid park or pool to which it is physically connected.`
      errors.generatorIds = ''
      errors.parentRelation = ''
      // PARK ZERO CHILDREN ERROR HANDLING: Parks should have at least one Generator
      if (assetIsAParkWithManualData) {
        // Do nothing about children
      } else if (!assetData.generatorIds.length) {
        errors.generatorIds = t`A Park must have at least one plant`
      }

      // PARK WITH CHILDREN ERROR HANDLING: Check if Generators are present in other physical Parks or Clusters
      if (assetData?.generatorIds) {
        // GENERATOR: Check if Generators are present in other physical Clusters
        const generatorsInsideThisPark = assetData?.generatorIds.map((id) => allAssets.filter((a) => a.id === id)) || []
        const generatorsPresentInOtherPhysicalClusters = generatorsInsideThisPark.filter((generator) => {
          return (generator?.clusterIds || []).some((clusterId) => physicalClusterIds.includes(clusterId))
        })

        // GENERATOR: Check if Generators are present in other physical Parks
        const generatorsPresentInOtherPhysicalParks = generatorsInsideThisPark.filter((generator) => generator?.parkId)

        if (generatorsPresentInOtherPhysicalClusters?.length || generatorsPresentInOtherPhysicalParks.length) {
          errors.generatorIds = childRelationErrorMsg
        }
      }

      // PARK PARENT ERROR HANDLING: If park has generators in it
      if (assetData?.clusterIds?.length) {
        const parkBelongsToMultiplePhysicalClusters = assetData.clusterIds.filter((clusterId) =>
          physicalClusterIds.includes(clusterId),
        )
        if (parkBelongsToMultiplePhysicalClusters.length > 1) {
          errors.parentRelation = parkParentRelationErrorMsg
        }
      }
    }

    if (assetIsAGenerator) {
      const generatorParentRelationErrorMsg = t`A plant can either be a member of one or more clusters, or of one park or pool to which it is physically connected.`
      errors.parentRelation = ''
      if (assetData?.uiParkIds?.length && assetData?.clusterIds?.length) {
        errors.parentRelation = generatorParentRelationErrorMsg
      } else if (assetData?.clusterIds && assetData?.clusterIds.length) {
        // IF GENERATOR HAS CLUSTER AS PARENT: If asset is inside the physical clusters
        const generatorBelongsToMultiplePhysicalClusters = assetData.clusterIds.filter((clusterId) =>
          physicalClusterIds.includes(clusterId),
        )
        if (generatorBelongsToMultiplePhysicalClusters.length > 1) {
          errors.parentRelation = generatorParentRelationErrorMsg
        }
      } else if (assetData?.uiParkIds?.length > 1) {
        // IF GENERATOR HAS PARK AS PARENT: If asset is inside multiple parks
        errors.parentRelation = generatorParentRelationErrorMsg
      }
    }

    if (assetData.since) {
      const sinceDate = new Date(assetData.since)
      if (!sinceDate.getTime()) {
        errors.since = t`Invalid date`
      } else {
        const isPastDate = isBefore(assetData.since, new Date())
        if (isPastDate && form?.getState().dirtyFields.since === true) {
          errors.since = t`Date should be in future`
        }
      }
    }

    if (assetData.to) {
      const toDate = new Date(assetData.to)
      if (!toDate.getTime()) {
        errors.to = t`Invalid date`
      } else {
        const isPastDate = isBefore(assetData.to, new Date())
        if (isPastDate && form?.getState().dirtyFields.to === true) {
          errors.to = t`Date should be in future`
        }
      }

      if (assetData.since) {
        const diff = dateDifferenceMs(assetData.since, assetData.to)
        if (diff <= 0) {
          errors.to = t`Should be after start date`
        }
      }
      // if (!assetData.since) {
      //   errors.since = t`Required`
      // }
    }

    if (isCHP(assetData)) {
      if (assetData.typeSpecificAttributes.periodStartDay) {
        const sinceDate = new Date(assetData.typeSpecificAttributes.periodStartDay)
        if (!sinceDate.getTime()) {
          errors.typeSpecificAttributes.periodStartDay = t`Invalid date`
        }
      }

      if (assetData.typeSpecificAttributes.periodEndDay) {
        const toDate = new Date(assetData.typeSpecificAttributes.periodEndDay)
        if (!toDate.getTime()) {
          errors.typeSpecificAttributes.periodEndDay = t`Invalid date`
        }

        if (assetData.typeSpecificAttributes.periodStartDay) {
          const diff = dateDifferenceMs(
            assetData.typeSpecificAttributes.periodStartDay,
            assetData.typeSpecificAttributes.periodEndDay,
          )
          if (diff <= 0) {
            errors.typeSpecificAttributes.periodEndDay = t`Should be after start date`
          }
        }
      }
    }
  }

  if (!errors.parentRelation) {
    delete errors.parentRelation
  }
  if (!errors.generatorIds) {
    delete errors.generatorIds
  }

  return errors
}

export const validateAvailabilityForm = (values: Availability) => {
  const errors: Availability = {} as Availability
  const requiredKeys: (keyof Availability)[] = ['from', 'type', 'unit', 'timeZone']
  requiredKeys.forEach((key) => {
    if (!values[key]) {
      errors[key] = t`Required`
    }
  })
  if (!isNumeric(values.value)) {
    errors.value = t`Required`
  }

  if (values.from) {
    const toDate = new Date(values.from)
    if (!toDate.getTime()) {
      errors.from = t`Invalid date`
    }
  }

  if (values.to) {
    const toDate = new Date(values.to)
    if (!toDate.getTime()) {
      errors.to = t`Invalid date`
    }
  }

  if (values.to && values.from && isBefore(new Date(values.to), new Date(values.from))) {
    errors.to = t`Should be greater than start`
  }

  return errors
}

export const validateUserSettings = (values: Record<string, string>) => {
  const errors: Partial<Record<string, string>> = {}
  const errorMsgObj = authCommonErrorMsgs()
  const requiredKeys = ['salutation', 'firstName', 'lastName', 'email', 'langKey', 'timezone']
  requiredKeys.forEach((key) => {
    if (!values[key]) {
      errors[key] = t`Required`
    }
    if (values.email && !validate.email(values.email)) {
      errors.email = errorMsgObj.email
    }
  })

  return errors
}

export const validateRegistrationForm = (values: RegistrationType) => {
  const errors: Partial<RegistrationType> = {}
  const errorMsgObj = authCommonErrorMsgs()
  const requiredKeys: (keyof RegistrationType)[] = [
    'salutation',
    'firstName',
    'lastName',
    'login',
    'password',
    'email',
    'leadSource',
  ]
  requiredKeys.forEach((key) => {
    if (!values[key]) {
      errors[key] = t`Required`
    }
  })

  if (values.login && !validate.username(values.login)) {
    errors.login = t`Must have ${minLoginLen} to ${maxLoginLen} characters (only lower case letters, digits , "-" and "_").`
  }
  if (values.password && validate.password(values.password)) {
    errors.password = errorMsgObj.password
  }
  if (values.email && !validate.email(values.email)) {
    errors.email = errorMsgObj.email
  }
  if (values.company && validate.basicText(values.company, 'company')) {
    errors.company = errorMsgObj.company
  }
  if (values.firstName && validate.basicText(values.firstName)) {
    errors.firstName = errorMsgObj.firstName
  }
  if (values.lastName && validate.basicText(values.lastName)) {
    errors.lastName = errorMsgObj.lastName
  }
  if (!values.ackTermsAndConditions) {
    errors.ackTermsAndConditions = Boolean(true)
  }
  return errors
}

export const validatePasswordForm = (values: ChangePasswordType | ResetPasswordType) => {
  const errors: Partial<ChangePasswordType> = {}
  const errorMsgObj = authCommonErrorMsgs()
  const requiredKeys: (keyof ChangePasswordType)[] = ['newPassword', 'confirmPassword']
  requiredKeys.forEach((key) => {
    if (!values[key]) {
      errors[key] = t`Required`
    }
  })
  if (values.newPassword && validate.password(values.newPassword)) {
    errors.newPassword = errorMsgObj.password
  }
  if (values.confirmPassword && values.newPassword && validate.isPwdMatch(values.newPassword, values.confirmPassword)) {
    errors.confirmPassword = errorMsgObj.pwdMatch
  }
  return errors
}

const MAX_FORECAST_LENGTH = 31 // 31 days
const MAX_OFFSET_LENGTH = 3 // 3 days
export const validateSiteForecastForm = (
  forecastDetails: ForecastConfig,
  forecastConfigs: ForecastConfig[],
  timingPreviewData: TimingPreview,
) => {
  // console.log('values in validation =', forecastDetails)
  const { INDIVIDUAL } = SiteForecastUpdateTimesFrequency
  const { CUSTOM: CUSTOM_DELIVERY } = DeliveryTimesType

  // FIXME WIP type to recursively convert type to use string for all nested keys
  type ConvertToStringValue<T> = {
    [K in keyof T]: T[K] extends Record<any, any> ? ConvertToStringValue<T[K]> : string
  }
  const errors: DeepPartial<ConvertToStringValue<ForecastConfig>> = {}

  if (!forecastDetails.name) {
    errors.name = t`Required`
  } else {
    if (forecastConfigs.length) {
      const isNewCustomConfig = forecastDetails.customConfig && !forecastDetails.id
      const nameAlreadyExists = forecastConfigs.some((forecast) => {
        return (
          forecast.name ===
            (isNewCustomConfig
              ? forecastDetails.customConfigName // only if it is new and using a custom config
              : forecastDetails.name) && forecast.id !== forecastDetails.id
        )
      })
      if (nameAlreadyExists) {
        errors.name = isNewCustomConfig
          ? c('Site Forecast:Validation').t`Name already exists, please change the name and re-upload the file`
          : c('Site Forecast:Validation').t`Name already exists`
      }
    }
  }

  const maxForecastRangeInMinutes = MAX_FORECAST_LENGTH * 24 * 60
  const maxForecastOffsetInMinutes = MAX_OFFSET_LENGTH * 24 * 60

  const forecastOffsetInHours =
    (forecastDetails?.horizon?.offsetDays || 0) * 24 + (forecastDetails?.horizon?.offsetHours || 0)
  const forecastOffsetInMinutes = forecastOffsetInHours * 60 + (forecastDetails?.horizon?.offsetMinutes || 0)
  const forecastLengthInMinutes = (forecastDetails.horizon?.lengthHours || 0) * 60
  const totalForecastRangeInMinutes = forecastOffsetInMinutes + forecastLengthInMinutes

  if (
    forecastDetails.horizon?.lengthHours != undefined &&
    forecastDetails.horizon?.lengthHours <= 0 &&
    !forecastDetails.horizon.untilEndOfDay
  ) {
    errors.ui = {
      ['forecastLength']: {
        ['hours']: c('Site Forecast:Validation').t`Forecast length needs to be more than 0.`,
      },
    }
  } else if (
    forecastDetails.horizon?.lengthHours &&
    totalForecastRangeInMinutes > maxForecastRangeInMinutes &&
    !forecastDetails.maximumHorizon // we need to disable this validation for site forecast which has maximumHorizon set to true
  ) {
    // Showing error message to a section but not to individual field
    errors.ui = {
      ['forecastRange']: c('Site Forecast:Validation')
        .t`The forecast range (Start + Length) can't be greater than ${MAX_FORECAST_LENGTH} days.`,
    }
  } else if (forecastDetails?.ui?.forecastLength?.hours && forecastDetails.ui.forecastLength.hours > 23) {
    errors.ui = {
      ['forecastLength']: {
        ['hours']: c('Site Forecast:Validation').t`The Forecast hours can't be greater than 23.`,
      },
    }
  }

  if (forecastOffsetInMinutes > maxForecastOffsetInMinutes) {
    errors.horizon = {
      ['offsetMinutes']: c('Site Forecast:Validation').t`Offset can not be more than 3 days.`,
    }
  }

  if (
    forecastDetails?.updateTimes?.frequency === INDIVIDUAL &&
    !forecastDetails?.updateTimes?.individualTimes?.length
  ) {
    errors.updateTimes = {
      ['individualTimes']: c('Site Forecast:Validation').t`Add at least one delivery time.`,
    }
  }

  if (forecastDetails?.updateTimes) {
    let earliestAttemptOfDeliveryErrorMsg = ''
    if (forecastDetails.updateTimes.earliestAttemptOfDelivery == null) {
      earliestAttemptOfDeliveryErrorMsg = t`Required`
    } else {
      if (forecastDetails.updateTimes.earliestAttemptOfDelivery < -60) {
        earliestAttemptOfDeliveryErrorMsg = c('Site Forecast:Validation')
          .t`Delivery window cannot start earlier than 60 min before delivery time`
      } else if (forecastDetails.updateTimes.earliestAttemptOfDelivery > 60) {
        earliestAttemptOfDeliveryErrorMsg = c('Site Forecast:Validation')
          .t`Delivery window cannot start later than 60 min after delivery time`
      } else if (forecastDetails.updateTimes.earliestAttemptOfDelivery % 5 !== 0) {
        earliestAttemptOfDeliveryErrorMsg = c('Site Forecast:Validation')
          .t`Time can only be specified in 5 minute intervals`
      }
    }
    if (earliestAttemptOfDeliveryErrorMsg) {
      errors.updateTimes = {
        ['earliestAttemptOfDelivery']: earliestAttemptOfDeliveryErrorMsg,
      }
    }
  }

  if (forecastDetails?.updateTimes) {
    let latestAttemptOfDeliveryErrorMsg = ''
    if (forecastDetails.updateTimes.latestAttemptOfDelivery == null) {
      latestAttemptOfDeliveryErrorMsg = t`Required`
    } else {
      if (forecastDetails.updateTimes.latestAttemptOfDelivery < forecastDetails.updateTimes.earliestAttemptOfDelivery) {
        latestAttemptOfDeliveryErrorMsg = c('Site Forecast:Validation')
          .t`Delivery window cannot end earlier than delivery window start time`
      } else if (forecastDetails.updateTimes.latestAttemptOfDelivery > 60) {
        latestAttemptOfDeliveryErrorMsg = c('Site Forecast:Validation')
          .t`Delivery window cannot end later than 60 min after delivery time`
      } else if (forecastDetails.updateTimes.latestAttemptOfDelivery % 5 !== 0) {
        latestAttemptOfDeliveryErrorMsg = c('Site Forecast:Validation')
          .t`Time can only be specified in 5 minute intervals`
      }
    }
    if (latestAttemptOfDeliveryErrorMsg) {
      errors.updateTimes = {
        ['latestAttemptOfDelivery']: latestAttemptOfDeliveryErrorMsg,
      }
    }
  }

  if (forecastDetails?.qualityConfigs?.length) {
    const forecastDeliveryTimes = getIndividualTimes(forecastDetails.updateTimes).map(
      (time) => `${parseInt(time.hours)}:${parseInt(time.minutes)}`,
    )
    const configErrors = []
    forecastDetails.qualityConfigs.forEach(() => {
      configErrors.push({})
    })

    forecastDetails.qualityConfigs.forEach((config, index) => {
      let errorObj = {}
      if (config.deliveryTimesType === CUSTOM_DELIVERY && !config.deliveryTimes.length) {
        errorObj = {
          ['deliveryTimes']: c('Site Forecast:Validation').t`Select at least one delivery time`,
        }
      } else {
        const configDeliveryTimes = config.deliveryTimes.map(
          (time) => `${parseInt(time.hours)}:${parseInt(time.minutes)}`,
        )
        const mismatchTimes = configDeliveryTimes.filter((time) => {
          return !forecastDeliveryTimes.includes(time)
        })
        if (mismatchTimes.length) {
          errorObj = {
            ['deliveryTimes']: c('Site Forecast:Validation')
              .t`Quality delivery times need to be a subset of forecast delivery times. Please choose valid delivery times.`,
          }
        }
      }

      if (config.durationType === EvaluationDurationType.CUSTOM) {
        const { lengthDays, lengthHours, lengthMinutes } = config.horizon
        if (!lengthDays && !lengthHours && !lengthMinutes) {
          errorObj = {
            ...errorObj,
            ['horizon']: {
              ['lengthMinutes']: c('Site Forecast:Validation').t`Please add evaluation`,
            },
          }
        }
      }

      if (timingPreviewData && timingPreviewData.qualityConfigTiming && timingPreviewData.forecastDeliveryTiming) {
        const qualityConfigTiming = timingPreviewData.qualityConfigTiming[index] || []

        // console.log({ qualityConfigTiming, timingPreviewData })
        qualityConfigTiming?.forEach((qct) => {
          // data: [
          //   [start, deliveryTime],
          //   [end, deliveryTime],
          // ],
          // Find the matched forecast time by comparing the delivery times
          const matchFdt = timingPreviewData.forecastDeliveryTiming.find(
            (fdt) => fdt.data[0][1] === qct?.data[0][1] && fdt.data[1][1] === qct?.data[1][1],
          )

          // Make the config invalid if the following conditions are met,  because quality time should be a subset of forecast time
          // Evaluation start time is less than forecast start time
          // Evaluation end time is greater than forecast end time
          // Evaluation start time greater than forecast end time
          if (
            qct?.data[0][0] < matchFdt?.data[0][0] ||
            qct?.data[1][0] > matchFdt?.data[1][0] ||
            qct?.data[0][0] > matchFdt?.data[1][0]
          ) {
            errorObj = {
              ...errorObj,
              ['horizon']: {
                ['offsetMinutes']: c('Site Forecast:Validation')
                  .t`At least one quality delivery time is outside of forecast delivery times. Please adjust offset and/or evaluation length accordingly. `,
              },
            }
          }
        })
      }

      configErrors[index] = errorObj
    })
    if (configErrors.length) {
      errors.qualityConfigs = configErrors
    }
  }

  return errors
}

const addErrorMessageForBands = (band: PenaltyBandNew, isLastBand: boolean) => {
  let bandErrorObj = {}
  // if (!isLastBand) {
  if (!band.to && !isLastBand) {
    bandErrorObj = {
      ...bandErrorObj,
      ['to']: t`Required`,
    }
  } else if (parseInt(band.to) <= parseInt(band.from) && !isLastBand) {
    bandErrorObj = {
      ...bandErrorObj,
      ['to']: t`Invalid`,
    }
  }
  // }

  if (isNaN(parseInt(band?.penaltyCostPerUnit))) {
    bandErrorObj = {
      ...bandErrorObj,
      ['penaltyCostPerUnit']: t`Required`,
    }
  }

  if (!band?.penaltyCostType) {
    bandErrorObj = {
      ...bandErrorObj,
      ['penaltyCostType']: t`Required`,
    }
  }

  if (band?.penaltyCostType && band.penaltyCostType === PenaltyBandCostType.TIMESERIES_PERCENTAGE) {
    if (!band?.timeseriesId) {
      bandErrorObj = {
        ...bandErrorObj,
        ['timeseriesId']: t`Required`,
      }
    }
  }
  return bandErrorObj
}

interface validatePenaltyRegulationFormType {
  formData: PenaltyRegulationTableItemNew
  activeRuleSets: PenaltyRegulationTableItemNew[]
  regulationVersions: PenaltyRegulationTableItemNew[]
}

export const validatePenaltyRegulationForm = ({
  formData,
  activeRuleSets,
  regulationVersions,
}: validatePenaltyRegulationFormType) => {
  const errors: Partial<PenaltyRegulationTableItemNew> = {}
  const overInjectionPenaltyBandErrors: Record<string, string>[] = []
  const underInjectionPenaltyBandErrors: Record<string, string>[] = []

  if (formData?.typeOfRow === PenaltyTableTypeOfRow.REGULATION) {
    // Error messages for regulation form
    const requiredKeys: (keyof PenaltyRegulationNew)[] = ['regulationName', 'gridType', 'country', 'state']

    requiredKeys.forEach((key) => {
      if (!formData[key]) {
        errors[key] = t`Required`
      }
    })
  } else if (formData?.typeOfRow === PenaltyTableTypeOfRow.VERSION) {
    // Error messages for version form

    if (!formData?.activeSince) {
      errors.activeSince = t`Required`
    } else if (!isDateValid(formData?.activeSince)) {
      errors.activeSince = t`Invalid date`
    } else {
      const prevVersionsActiveDates = regulationVersions.map((version) => version.activeSince)
      const dateAlreadyPresent = prevVersionsActiveDates.some((prevDate) => isSameDate(formData.activeSince, prevDate))
      if (dateAlreadyPresent) {
        errors.activeSince = t`A version with same active since date is present in this regulation`
      }
    }
    if (!formData.regulationVersionName) {
      errors.regulationVersionName = t`Required`
    }
  } else if (formData?.typeOfRow === PenaltyTableTypeOfRow.RULESET) {
    // Error messages for Rule set form

    const currentGenerators = (formData.generatorTypes as string[]) || []
    if (!currentGenerators?.length) {
      errors.generatorTypes = t`Required`
    } else if (currentGenerators.length) {
      const currentGeneratorsHasUnSpecifiedAndOthers =
        currentGenerators.length > 1 && currentGenerators.includes(PenaltyGeneratorType.UNSPECIFIED)

      if (currentGeneratorsHasUnSpecifiedAndOthers) {
        errors.generatorTypes = t`Unspecified generator cannot exist with others`
      } else if (activeRuleSets.length) {
        const alreadyUsedGenerators = activeRuleSets.reduce((generators: string[], rs) => {
          if (rs.generatorTypes) {
            return [...generators, ...((rs.generatorTypes as string[]) || [])]
          }
          return generators
        }, [])

        const currentGeneratorIsAlreadyUsed = currentGenerators.find((generator) =>
          alreadyUsedGenerators?.includes(generator),
        )

        if (currentGeneratorIsAlreadyUsed) {
          errors.generatorTypes = t`${currentGeneratorIsAlreadyUsed} generator is already used`
        }
      }
    }

    // Error messages for over injection penalty bands
    formData.overInjectionPenaltyBands.forEach(() => overInjectionPenaltyBandErrors.push({}))

    const noOfOverInjectionPenaltyBands = formData?.overInjectionPenaltyBands?.length
    formData.overInjectionPenaltyBands.forEach((band: PenaltyBandNew, index: number) => {
      const isLastBand = index === noOfOverInjectionPenaltyBands - 1
      const bandErrorObjWithMsgs = addErrorMessageForBands(band, isLastBand)
      overInjectionPenaltyBandErrors[index] = bandErrorObjWithMsgs
    })

    if (overInjectionPenaltyBandErrors.length) {
      errors.overInjectionPenaltyBands = overInjectionPenaltyBandErrors
    }

    // Error messages for under injection penalty bands
    formData?.underInjectionPenaltyBands.forEach(() => underInjectionPenaltyBandErrors.push({}))

    const noOfUnderInjectionPenaltyBands = formData?.underInjectionPenaltyBands?.length
    formData?.underInjectionPenaltyBands.forEach((band: PenaltyBandNew, index: number) => {
      const isLastBand = index === noOfUnderInjectionPenaltyBands - 1
      const bandErrorObjWithMsgs = addErrorMessageForBands(band, isLastBand)
      underInjectionPenaltyBandErrors[index] = bandErrorObjWithMsgs
    })

    if (underInjectionPenaltyBandErrors.length) {
      errors.underInjectionPenaltyBands = underInjectionPenaltyBandErrors
    }
  }

  return errors
}

export const validateDeliveryTargetForm = (formData: DeliveryTarget) => {
  const errors: Record<string, any> = {}
  if (!formData.name && formData.type !== DELIVERY_TARGET_TYPE_ENERCAST_FTP) {
    errors.name = t`Required`
  }
  if (formData.type === DELIVERY_TARGET_TYPE_CUSTOMER_FTP) {
    if (!formData?.ftpConfiguration?.username) {
      errors.ftpConfiguration = {
        ['username']: t`Required`,
      }
    }

    if (!formData?.ftpConfiguration?.host) {
      errors.ftpConfiguration = {
        ...(errors.ftpConfiguration || {}),
        ['host']: t`Required`,
      }
    } else {
      if (/[A-Z]/.test(formData?.ftpConfiguration?.host)) {
        errors.ftpConfiguration = {
          ...(errors.ftpConfiguration || {}),
          ['host']: t`Only lowercase letters allowed`,
        }
      }
    }

    if (!formData?.ftpConfiguration?.password) {
      errors.ftpConfiguration = {
        ...(errors.ftpConfiguration || {}),
        ['password']: t`Required`,
      }
    }

    if (formData?.ftpConfiguration?.port && isNumeric(formData?.ftpConfiguration?.port)) {
      if (formData?.ftpConfiguration?.port < MIN_PORT_NUMBER || formData?.ftpConfiguration?.port > MAX_PORT_NUMBER) {
        errors.ftpConfiguration = {
          ...(errors.ftpConfiguration || {}),
          ['port']: createMaxNumberErrorMessage(formData?.ftpConfiguration?.port, MAX_PORT_NUMBER, MIN_PORT_NUMBER),
        }
      }
    } else {
      errors.ftpConfiguration = {
        ...(errors.ftpConfiguration || {}),
        ['port']: t`Please enter valid port number`,
      }
    }

    if (
      formData?.ftpConfiguration?.ftpParameter?.connectTimeout &&
      isNumeric(formData?.ftpConfiguration?.ftpParameter?.connectTimeout)
    ) {
      const connectTimeout = formData?.ftpConfiguration?.ftpParameter?.connectTimeout
      if (connectTimeout < MIN_DELIVERY_TIMEOUT || connectTimeout > MAX_DELIVERY_TIMEOUT) {
        errors.ftpConfiguration = {
          ...(errors.ftpConfiguration || {}),
          ['ftpParameter']: {
            ['connectTimeout']: createMaxNumberErrorMessage(connectTimeout, MAX_DELIVERY_TIMEOUT, MIN_DELIVERY_TIMEOUT),
          },
        }
      }
    } else {
      errors.ftpConfiguration = {
        ...(errors.ftpConfiguration || {}),
        ['ftpParameter']: {
          ['connectTimeout']: t`Please enter valid time`,
        },
      }
    }
  }

  if (formData.type === DELIVERY_TARGET_TYPE_MAIL) {
    if (!formData?.mailConfiguration?.toAddresses?.length) {
      errors.mailConfiguration = {
        ...(errors.mailConfiguration || {}),
        ['toAddresses']: t`Add a recipient email`,
      }
    }
  }

  return errors
}

export const validateUserManagementUserForm = (formData: UserManagementType) => {
  const errors: Partial<UserManagementType> = {}
  const errorMsgObj = authCommonErrorMsgs()
  const requiredKeys: (keyof UserManagementType)[] = ['login', 'email']
  requiredKeys.forEach((key) => {
    if (!formData[key]) {
      errors[key] = t`Required`
    }
    if (formData.login && !validate.username(formData.login)) {
      errors.login = t`Must have ${minLoginLen} to ${maxLoginLen} characters (only lower case letters, digits , "-" and "_").`
    }
    if (formData.email && !validate.email(formData.email)) {
      errors.email = errorMsgObj.email
    }
    if (formData.authorities?.includes(Roles.COMPANY_ACCOUNT) && formData.authorities?.includes(Roles.ADMIN)) {
      errors.authorities = t`User cannot have both 'ADMIN' and 'COMPANY ACCOUNT' roles.`
    }
  })
  return errors
}

export const validateCreateBackcastForm = (formData: TimePeriodWithRange) => {
  const errors: Record<string, string> = {}
  if (!formData.start) {
    errors.start = t`Required`
  } else if (formData.start) {
    const toDate = new Date(formData.start)
    if (!toDate.getTime()) {
      errors.start = t`Invalid date`
    }
  }

  if (!formData.end) {
    errors.end = t`Required`
  } else if (formData.end) {
    const toDate = new Date(formData.end)
    if (!toDate.getTime()) {
      errors.end = t`Invalid date`
    }
  }

  if (formData.end && formData.start && isBefore(new Date(formData.end), new Date(formData.start))) {
    errors.end = t`Should be greater than start`
  }

  return errors
}

export const validateMeterDataCleansingForm = (formData: MeterDataCleansingConfiguration) => {
  const errors: Record<string, string> = {}
  const trainingStartDate = formData?.timePeriodForTraining?.start?.date
  const trainingEndDate = formData?.timePeriodForTraining?.end?.date

  if (!isDateValid(trainingStartDate) || !isDateValid(trainingEndDate)) {
    errors['timePeriodForTraining'] = t`Invalid date`
  } else if (
    isAfter(new Date(trainingStartDate), new Date(trainingEndDate)) ||
    isBefore(new Date(trainingEndDate), new Date(trainingStartDate))
  ) {
    errors['timePeriodForTraining'] = t`Dates should be between total time period`
  }
  return errors
}

export const validateCreateScheduleForm = (formData: CreateScheduleInputData) => {
  const errors: Record<string, any> = {}
  const startDate = formData?.start?.date
  const endDate = formData?.end?.date
  if (!startDate) {
    errors.start = {
      ['date']: t`Required`,
    }
  } else if (startDate && !isDateValid(startDate)) {
    errors.start = {
      ['date']: t`Invalid date`,
    }
  }
  if (!endDate) {
    errors.end = {
      ['date']: t`Required`,
    }
  } else if (endDate && !isDateValid(endDate)) {
    errors.end = {
      ['date']: t`Invalid date`,
    }
  }

  if (endDate && startDate && isBefore(new Date(endDate), new Date(startDate))) {
    errors.end = {
      ['date']: t`Should be greater than start`,
    }
  }

  if (!formData?.asset) {
    errors['asset'] = t`Required`
  }
  if (!formData?.sourceDataStream) {
    errors['sourceDataStream'] = t`Required`
  }
  if (!formData?.targetScheduleDataStream) {
    errors['targetScheduleDataStream'] = t`Required`
  }
  return errors
}
