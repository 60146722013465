import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery } from 'redux-saga/effects'
import * as actionTypes from 'modules/data/fileManager/redux_store/fileManager.action.types'
import { DownloadFileAction } from 'modules/data/fileManager/redux_store/state/fileManager.download'
import {
  downloadFile,
  getMeterDataFiles,
  uploadMeterDataFiles,
} from 'modules/data/fileManager/redux_store/fileManager.api'
import { Result } from 'utils/request'
import { UploadMeterdataFilesAction } from 'modules/data/fileManager/redux_store/state/uploadMeterdataFiles.state'
import { enrichFilesAfterLoad } from 'utils/file'

export function* getMeterDataFilesSaga(): SagaIterator {
  const result = yield call(getMeterDataFiles)
  if (result.isSuccessful) {
    const files = enrichFilesAfterLoad(result.getData())
    yield put({ type: actionTypes.GET_METERDATAFILES_SUCCESS, files: files })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_METERDATAFILES_FAILURE, error })
  }
}

export function* uploadMeterdataFilesSaga({ fileDatas, selectedAssetName }: UploadMeterdataFilesAction): SagaIterator {
  const result = yield call(uploadMeterDataFiles, fileDatas, selectedAssetName)
  if (result.isSuccessful) {
    const uploadResult = result.getData()
    yield put({ type: actionTypes.UPLOAD_METERDATAFILES_SUCCESS, result: uploadResult })
    yield put({ type: actionTypes.GET_METERDATAFILES_REQUEST })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.UPLOAD_METERDATAFILES_FAILURE, error })
  }
}

export function* downloadFileSaga({ fileId }: DownloadFileAction): SagaIterator {
  if (!fileId) return
  const result: Result<string> = yield call(downloadFile, fileId)
  if (result.isSuccessful) {
    yield put({
      type: actionTypes.DOWNLOAD_FILE_SUCCESS,
      result: result.getData(),
      fileId: fileId,
    })
    yield put({ type: actionTypes.GET_METERDATAFILES_REQUEST })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.DOWNLOAD_FILE_FAILURE, error, fileId })
  }
}
export function* deactivateFileSaga({ fileId }: DownloadFileAction): SagaIterator {
  if (!fileId) return
  const result: Result<string> = yield call(downloadFile, fileId)
  if (result.isSuccessful) {
    yield put({
      type: actionTypes.DEACTIVATE_FILE_SUCCESS,
      result: result.getData(),
      fileId: fileId,
    })
    yield put({ type: actionTypes.GET_METERDATAFILES_REQUEST })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.DEACTIVATE_FILE_FAILURE, error, fileId })
  }
}

export function* deleteFileSaga({ fileId }: DownloadFileAction): SagaIterator {
  if (!fileId) return
  const result: Result<string> = yield call(downloadFile, fileId)
  if (result.isSuccessful) {
    yield put({
      type: actionTypes.DELETE_FILE_SUCCESS,
      result: result.getData(),
      fileId: fileId,
    })
    yield put({ type: actionTypes.GET_METERDATAFILES_REQUEST })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.DELETE_FILE_FAILURE, error, fileId })
  }
}

export function* fileManagerSaga() {
  yield takeEvery(actionTypes.GET_METERDATAFILES_REQUEST, getMeterDataFilesSaga)
  yield takeEvery(actionTypes.UPLOAD_METERDATAFILES_REQUEST, uploadMeterdataFilesSaga)
  yield takeEvery(actionTypes.DOWNLOAD_FILE_REQUEST, downloadFileSaga)
  yield takeEvery(actionTypes.DEACTIVATE_FILE_REQUEST, deactivateFileSaga)
  yield takeEvery(actionTypes.DELETE_FILE_REQUEST, deleteFileSaga)
}
