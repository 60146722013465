import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReTable from 'modules/reTable/ReTable'
import { RETABLE_ID_SCHEDULE_TEMPLATE_DATA, ReTableItem } from 'modules/reTable/reTable.types'
import { table, theme } from 'themes/theme-light'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import {
  defaultSelectedColumnsForScheduleTemplateTable,
  getScheduleTemplateDataTableColumns,
  ScheduleUploadNotificationStatusCodes,
  ScheduleUploadWarningStatusCodes,
} from 'utils/schedule'
import { ScheduleTemplateTableItem, ScheduleTemplateUploadStatus } from 'modules/workspace/schedule/schedule.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { getScheduleTemplateUploadErrorAndWarningMessage } from 'utils/schedule'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'
import { convertUtcToZonedTime, formatDate } from 'utils/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'

import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch, useSelector } from 'react-redux'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import AssetIcon from 'modules/asset/AssetIcon'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'

import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'
import { useUniqueAllAssets } from 'utils/asset'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import { t, jt } from 'ttag'
import { AppUnits } from 'utils/units'

interface UploadedScheduleTemplateTableProps {
  templateUploadResponse: Record<string, string>
}

const UploadedScheduleTemplateTable: React.FC<UploadedScheduleTemplateTableProps> = ({ templateUploadResponse }) => {
  const dispatch = useDispatch()
  const allAssets = useUniqueAllAssets()
  const userTimezone = useSelector(getUserTimezoneSelector)

  const capacitySplitUnit = templateUploadResponse?.capcitySplitUnit || AppUnits.MEGA_WATT

  const templateAssets = templateUploadResponse?.templateAssets || []
  const defaultColumnsSelected = useMemo(() => defaultSelectedColumnsForScheduleTemplateTable(), [])
  const [itemsToRender, setItemsToRender] = useState<ScheduleTemplateTableItem[]>([])

  // Add availabilities to the assets and render the items
  const handleItemsToRenderChange = useCallback((items) => {
    // debouncedSetFilteredItems(items)
    const parent = items.find((item) => item.parent)
    const children = items.filter((item) => !item.parent)

    requestAnimationFrame(() => {
      if (parent && children.length) {
        setItemsToRender([parent, ...children])
      } else {
        setItemsToRender(items)
      }
    })
  }, [])

  const getScheduleUploadStatusIcon = (status: ScheduleTemplateUploadStatus) => {
    if (ScheduleUploadWarningStatusCodes.includes(status)) {
      return <FontAwesomeIcon style={{ marginRight: '7px' }} icon="exclamation-triangle" color="#f8bd71" size="1x" />
    } else if (ScheduleUploadNotificationStatusCodes.includes(status)) {
      return <FontAwesomeIcon style={{ marginRight: '7px' }} icon="exclamation-circle" color="#0049B0" />
    }
    return (
      <FontAwesomeIcon
        style={{ marginRight: '7px' }}
        icon="exclamation-circle"
        color={theme.palette.error.main}
        size="1x"
      />
    )
  }

  const columnsAvailable = useMemo(() => getScheduleTemplateDataTableColumns(), [])
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_SCHEDULE_TEMPLATE_DATA)

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_SCHEDULE_TEMPLATE_DATA, columnsAvailable })
  }, [columnsAvailable])

  useEffect(() => {
    const columnsSelectedNames = columnsSelected.map((c) => c.name)
    const updatedColumnsSelected = columnsAvailable
      .filter((c) => {
        return c.fixed || columnsSelectedNames.includes(c.name)
      })
      .map((c) => c.name)
    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_SCHEDULE_TEMPLATE_DATA,
      columnsSelected: updatedColumnsSelected,
    })
  }, [columnsAvailable, columnsSelected])

  const transformedColumnsSelected = columnsSelected.map((col) => {
    if (col.name === 'contractSplit') {
      const transformedColumn = { ...col }
      transformedColumn.label = jt`Contract split (${capacitySplitUnit})`
      return transformedColumn
    } else {
      return col
    }
  })

  return (
    <DynamicTableHeightContainer numberOfRows={itemsToRender.length}>
      <ReTable
        id={RETABLE_ID_SCHEDULE_TEMPLATE_DATA}
        itemHeight={table.rowHeight}
        items={templateAssets as ReTableItem[]}
        defaultColumnsSelected={defaultColumnsSelected}
        hasExtendedHeader={false}
        itemsPaddingHeader={ReTableGenericAttributes.ITEMS_PADDING_HEADER}
        itemsPaddingFooter={ReTableGenericAttributes.ITEMS_PADDING_FOOTER}
        renderAheadCount={30}
        onItemsToRenderChange={handleItemsToRenderChange}
      >
        <ReTableHeader>
          <ReTableRow>
            {transformedColumnsSelected.map((col, index) => (
              <ReTableCell colSpan={col.name === 'status' ? 2 : 1} key={col.name} width={col.width}>
                <Flex>
                  {/*{col.name === 'status' ? <ReTableHeaderActionsCell isHierarchical={false} inline={true} /> : <></>}*/}
                  <ReTableHeaderWithSort column={col} />
                </Flex>
              </ReTableCell>
            ))}
          </ReTableRow>
        </ReTableHeader>

        <ReTableBody>
          {itemsToRender.map((item) => {
            const asset = allAssets.find((a) => a.id === item.assetId)
            const scheduleDate = item?.scheduleSavingTime ? item.scheduleSavingTime.replace('[UTC]', '') : undefined
            const isError = item.errorStatus
            const isWarning = item?.warnings?.length > 0

            let filteredColumnsSelected = [...columnsSelected]
            if (item?.parent) {
              filteredColumnsSelected = [...columnsSelected].filter(
                (col) => col.name !== 'contractSplit' && col.name !== 'savedSchedule',
              )
            }
            return (
              <ReTableRow key={item.assetId}>
                {filteredColumnsSelected.map((col) => {
                  if (col.name === 'status') {
                    return (
                      <ReTableCell key="status" width={col.width} colSpan={2}>
                        {isError || isWarning ? (
                          <PopperTooltip
                            popperLabel={
                              isError
                                ? getScheduleUploadStatusIcon(item?.errorStatus)
                                : getScheduleUploadStatusIcon(
                                    item?.warnings[0] ||
                                      ScheduleTemplateUploadStatus.ASSET_SCHEDULE_PERIOD_LONGER_THAN_TEMPLATE_SCHEDULE_PERIOD,
                                  )
                            }
                            popperContent={
                              <Box flexDirection="column">
                                {item?.errorStatus && (
                                  <Box mt={0.5} mb={0.5} display="flex" flexDirection="row" alignItems="flex-start">
                                    <>{getScheduleUploadStatusIcon(item.errorStatus)}</>
                                    <>{getScheduleTemplateUploadErrorAndWarningMessage(item.errorStatus).message}</>
                                  </Box>
                                )}
                                {item?.warnings?.length > 0 && (
                                  <>
                                    {item.warnings.map((warning) => (
                                      <Box
                                        key={warning}
                                        mt={0.5}
                                        mb={0.5}
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="flex-start"
                                      >
                                        <>{getScheduleUploadStatusIcon(warning)}</>
                                        <>{getScheduleTemplateUploadErrorAndWarningMessage(warning).message}</>
                                      </Box>
                                    ))}
                                  </>
                                )}
                              </Box>
                            }
                          />
                        ) : (
                          <FontAwesomeIcon size="2x" icon={'check'} color={theme.palette.primary.main} />
                        )}
                      </ReTableCell>
                    )
                  } else if (col.name === 'posName') {
                    return (
                      <ReTableCell key={col.name}>
                        <FixedWidthTextContainer text={item.posName} width={col.width}>
                          {item.posName}
                        </FixedWidthTextContainer>
                      </ReTableCell>
                    )
                  } else if (col.name === 'assetName') {
                    return (
                      <ReTableCell key={col.name} colSpan={item?.parent ? 3 : 1}>
                        <Flex style={{ width: 'fit-content' }} justifyContent="center" alignItems="center">
                          {asset ? (
                            <>
                              <Flex style={{ width: 'fit-content' }} justifyContent="center" alignItems="center">
                                {asset && <AssetIcon selected={false} asset={asset} size={AssetLinkIconSize} />}
                                <Box ml={0.5}>
                                  <FixedWidthTextContainer
                                    text={item.assetName}
                                    width={item?.parent ? 'inherit' : col.width}
                                  >
                                    {item.assetName}
                                  </FixedWidthTextContainer>
                                </Box>
                              </Flex>
                            </>
                          ) : (
                            <>-</>
                          )}
                        </Flex>
                      </ReTableCell>
                    )
                  } else if (col.name === 'contractSplit') {
                    const contracts = item?.capacitySplit ? item.capacitySplit.split(',') : []
                    return (
                      <ReTableCell key={col.name}>
                        <Flex justifyContent="flex-end">{contracts.length ? <>{contracts.join(', ')}</> : <>-</>}</Flex>
                      </ReTableCell>
                    )
                  } else if (col.name === 'savedSchedule') {
                    return (
                      <ReTableCell key={col.name}>
                        {scheduleDate ? <>{formatDate(convertUtcToZonedTime(scheduleDate, userTimezone))}</> : <>-</>}
                      </ReTableCell>
                    )
                  } else {
                    return <ReTableCell key={'none'}></ReTableCell>
                  }
                })}
              </ReTableRow>
            )
          })}
        </ReTableBody>
      </ReTable>
    </DynamicTableHeightContainer>
  )
}

export default UploadedScheduleTemplateTable
