export enum CleansingStatusEnum {
  Done = 'Done',
  Pending = 'Pending',
  Failed = 'Failed',
}

export interface BulkMDCMeterDataRaw {
  image?: string
  summary?: Record<string, any>
}

export interface BulkMdcRowObject {
  assetId: string
  cleansingStatus: CleansingStatusEnum
  failureReason?: string
  cleansingParams: {
    range: number
    repetitive: number
    outlierLeft: number
    outlierRight: number
    timePeriodForTraining?: any
  }
  meterDataRaw: BulkMDCMeterDataRaw
}

export interface ResultAllFromBulkMdc {
  cleansingResult: BulkMdcRowObject[]
  done: boolean
}
