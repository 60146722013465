import React from 'react'
import OldUiIframe from 'ui/elements/OldUiIframe'
import { GET_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'

const SubscriptionPage: React.FC = () => {
  return (
    <OldUiIframe unmountAction={GET_USER_REQUEST} unloadAction={GET_USER_REQUEST} url="/iframe/#/products?IFRAME" />
  )
}

export default React.memo(SubscriptionPage)
