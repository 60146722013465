import { Box } from '@material-ui/core'

import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import PenaltyDeviationChart from 'modules/data/penalties/penaltyCalculations/PenaltyDeviationChart'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useMaxErrorPenaltyBlock } from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'

import styled from 'styled-components'
import { WorkspaceChartWidgetHeight } from 'utils/chart'
import { DateRange } from 'utils/date'
import { QueryObserverResult } from 'react-query'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { isJson } from 'utils/dataFormatting'
import { useAllAssets } from 'modules/asset/api/assets.api'
import { appTourColors } from 'modules/app/tour/OnBoardingTourDialog'
import { useUniqueSelectedAssets } from 'utils/asset'
import { PenaltyCalculationResult } from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'

const Content = styled.div`
  position: relative;
  height: ${WorkspaceChartWidgetHeight};
  min-height: ${WorkspaceChartWidgetHeight};
  max-height: ${WorkspaceChartWidgetHeight};
`

export const PenaltyErrorBar = styled.div`
  color: ${appTourColors.darkBlue};
  padding: 1em;
`

interface QualityPenaltyWidgetProps {
  penaltyResult: QueryObserverResult<PenaltyCalculationResult>
  allPenalties: PenaltyCalculationResult[]
  dateRange: DateRange
  showTooltip: boolean
  backCastEnabled: boolean
}
const PenaltyWidget: React.FC<QualityPenaltyWidgetProps> = ({
  penaltyResult,
  allPenalties,
  dateRange,
  showTooltip,
  backCastEnabled,
}) => {
  const maxErrorBlock = useMaxErrorPenaltyBlock(allPenalties)
  const timezone = useSelector(getUserTimezoneSelector)
  const selectedAssetsAndModelIds = useSelector(workspaceDraftAssetSelectionSelector)
  const allAssets = useAllAssets()
  const selectedAssets = useUniqueSelectedAssets()
  const penaltySummary = penaltyResult?.data?.summaryResult
  // const penalty = useMemo(() => allPenalties?.find((data) => data.assetId === asset?.id), [allPenalties, asset])

  const errorData = isJson(penaltyResult?.error?.message)
    ? (JSON.parse(penaltyResult?.error?.message)?.summaryResult as PenaltyCalculationResult)
    : null

  const asset = useMemo(
    () => (allAssets.data || []).find((a) => a.id === penaltySummary?.assetId || a.id === errorData?.assetId),
    [allAssets.data, penaltySummary, errorData],
  )

  const isBackCastPenalty = useMemo(() => penaltySummary?.isBackcastRequest || errorData?.isBackcastRequest, [
    penaltySummary,
    errorData,
  ])

  const modelName = useMemo(() => {
    const modelId = penaltySummary?.forecastModelId || errorData?.forecastModelId
    if (modelId) {
      const matchId = selectedAssetsAndModelIds.find((id) => id.includes(modelId))
      return getBackCastModelDataFromId(matchId).name
    } else return ''
  }, [penaltySummary, errorData, selectedAssetsAndModelIds])

  const title = useMemo(() => {
    if (selectedAssets.length === 1 && !modelName && !isBackCastPenalty && !backCastEnabled) return ''
    return `${asset ? asset.name : ''} ${modelName ? modelName : ''} ${isBackCastPenalty ? '[Backcast]' : ''}`
  }, [modelName, isBackCastPenalty, asset, selectedAssets, backCastEnabled])

  return (
    <>
      <Box>
        <Content>
          <PenaltyDeviationChart
            range={dateRange}
            title={title || ''}
            penaltyCalculations={penaltySummary}
            maxErrorBlock={maxErrorBlock}
            timezone={timezone}
            loading={penaltyResult.isLoading}
            message={''}
            showTooltip={showTooltip}
            backCastEnabled={backCastEnabled}
          />
        </Content>
      </Box>
    </>
  )
}

export default React.memo(PenaltyWidget)
