import React, { useCallback } from 'react'
import { FieldInputProps } from 'react-final-form'
import NumberFieldWithArrows from 'ui/form/NumberFieldWithArrows'

interface SiteForecastFormNumberFieldProps {
  input: FieldInputProps<any, HTMLElement>
  placeholder: string
  min?: number
  max?: number
  step?: number
  repeatNumbers?: boolean
  disabled?: boolean
  minMaxValuesHandle?: boolean
  timeInput?: boolean
}

const NumberFieldReactFinalForm: React.FC<SiteForecastFormNumberFieldProps> = ({
  input,
  placeholder,
  min,
  max,
  step = 1,
  repeatNumbers = true,
  disabled = false,
  minMaxValuesHandle = true,
  timeInput = false,
}) => {
  const handleChange = useCallback((val) => {
    input.onChange(val)
  }, [])

  return (
    <NumberFieldWithArrows
      placeholder={placeholder}
      step={step}
      max={max}
      min={min}
      onChange={handleChange}
      value={input.value}
      repeatNumbers={repeatNumbers}
      disabled={disabled}
      minMaxValuesHandle={minMaxValuesHandle}
      timeInput={timeInput}
    />
  )
}

export default React.memo(NumberFieldReactFinalForm)
