import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import Gips from 'modules/gips/Gips'
import Data from 'pages/data/Data'
import Products from 'pages/products/Products'

import Workbench from 'pages/workbench/Workbench'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import OldUiIframe from 'ui/elements/OldUiIframe'
import { dataStreamTypeQuery } from 'utils/dataStream'
import {
  getAssetQueryObj,
  getDataStreamDetailsQueryObj,
  getManageDataStreamQueryObj,
  getUserSettingsQueryObj,
  QUERY_CATEGORY,
  QUERY_DATA_STREAM,
  stringifyQueryParams,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import {
  hasAnyAuthority,
  isAdmin,
  isDemo,
  isImpersonatedAdmin,
  isPartner,
  hasPermissionForSiteAssessment,
} from 'utils/user'
import SiteAssessmentPage from 'pages/SiteAssessmentPage'
import UserManagement from 'modules/userManagement/UserManagement'
import RunningBackcasts from 'modules/workspace/header/backCast/RunningBackcasts/RunningBackcasts'
import BulkMdc from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMdc'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import DemoResources from 'modules/user/demoResources/DemoResources'

export const REDIRECT_ROUTE_WORKBENCH_ADD_ASSET = '/workbench/add-asset'
export const REDIRECT_ROUTE_WORKBENCH_ASSET_DETAILS = '/workbench/asset-details/:name/:id'
export const REDIRECT_ROUTE_WORKBENCH_FORECAST_CONFIG = '/workbench/forecast/:name/:id'
export const REDIRECT_ROUTE_USER_SETTINGS = '/user-settings'

export const ROUTE_WORKBENCH = '/workbench'
export const ROUTE_WORKBENCH_ASSET_TABLE = '/workbench/assets'

export const ROUTE_METER_DATA_BULK_CLEANSING = '/workbench/bulk-mdc'
export const ROUTE_DATA = '/data'
export const ROUTE_DATA_DELIVERY = '/data/delivery'
export const ROUTE_DATA_FLOW = '/data/flow'
export const ROUTE_FILE_MANAGER = '/data/file-manager'
export const ROUTE_MASTERDATA_IMPORT = '/data/masterdata-import'
export const ROUTE_TRAINING = '/data/training'
export const ROUTE_PENALTY_REGULATION = '/data/penalty-regulations'

export const ROUTE_PRODUCTS = '/products'
export const ROUTE_SUBSCRIPTIONS = '/products/subscriptions'
export const ROUTE_SITE_FORECAST = '/products/site-forecast'
export const ROUTE_AREA_FORECAST = '/products/area-forecast'
export const ROUTE_WEATHER_DATA = '/products/weather-data'

export const ROUTE_SITE_ASSESSMENT = '/site-assessment'

export const ROUTE_DEMO_RESOURCES = '/demo-resources'

export const ROUTE_USER_MANAGEMENT = '/user-management'
export const ROUTE_METRICS = '/metrics'
export const ROUTE_HEALTH = '/health'
export const ROUTE_CONFIGURATION = '/configuration'
export const ROUTE_AUDITS = '/audits'
export const ROUTE_LOGS = '/logs'
export const ROUTE_GIPS = '/gips'
export const ROUTE_RUNNING_BACKCASTS = '/running-backcasts'
export const ROUTE_ACTIVATE_USER = '/auth/activate'
export const ROUTE_RESET_PASSWORD = '/auth/resetPassword'

const Routes: React.FC = () => {
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const user = useSelector(getUserResultSelector)
  const { queryParams } = useQueryParams()

  const { updateQueryString } = useQueryString()

  const {
    [TimeSeriesType.AREA_FORECAST]: areaForecast,
    [TimeSeriesType.WEATHER_DATA]: weatherData,
    [TimeSeriesType.SITE_FORECAST]: siteForecast,
  } = dataStreamTypeQuery

  const hasGlobalAccess = isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user)
  const hasAccessToSiteAssessment = useMemo(() => hasPermissionForSiteAssessment(user), [user])

  const redirectToSiteForecast = useCallback(() => {
    const urlHasOldSiteForecastQuery = queryParams[QUERY_CATEGORY] && queryParams[QUERY_DATA_STREAM]
    if (urlHasOldSiteForecastQuery) {
      const queryParam = {
        ...getManageDataStreamQueryObj(),
        ...getDataStreamDetailsQueryObj(queryParams[QUERY_DATA_STREAM]),
        [QUERY_CATEGORY]: undefined,
        [QUERY_DATA_STREAM]: undefined,
      }
      updateQueryString(queryParam)
    }
  }, [queryParams, updateQueryString])

  useEffect(() => {
    redirectToSiteForecast()
  }, [queryParams])

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to={ROUTE_WORKBENCH} />
      </Route>

      {assetSelection.length > 0 && <Route exact path={ROUTE_METER_DATA_BULK_CLEANSING} component={BulkMdc} />}

      <Route
        exact
        path={REDIRECT_ROUTE_WORKBENCH_ADD_ASSET}
        render={() => {
          const queryString = stringifyQueryParams(getAssetQueryObj())
          return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
        }}
      />
      <Route
        exact
        path={REDIRECT_ROUTE_WORKBENCH_ASSET_DETAILS}
        render={(props) => {
          const queryString = stringifyQueryParams(
            getAssetQueryObj({
              id: props.match.params.id || 'new',
            }),
          )
          return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
        }}
      />
      <Route
        exact
        path={REDIRECT_ROUTE_WORKBENCH_FORECAST_CONFIG}
        render={(props) => {
          const queryString = stringifyQueryParams({
            ...getManageDataStreamQueryObj(),
            ...getDataStreamDetailsQueryObj(props.match.params.id || 'default'),
          })
          return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
        }}
      />

      <Route path={ROUTE_WORKBENCH} component={Workbench} />

      <Route path={ROUTE_DATA} component={Data} />

      {/*Before we had links in different pages that is why we redirected weather, site and area forecast*/}
      {(isAdmin(user) || isImpersonatedAdmin(user) || hasAnyAuthority(user, ['ROLE_WDA'])) && (
        <Route
          exact
          path={ROUTE_WEATHER_DATA}
          render={() => {
            const queryString = stringifyQueryParams(getManageDataStreamQueryObj(weatherData))
            return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
          }}
        />
      )}
      {(hasGlobalAccess || hasAnyAuthority(user, ['ROLE_SITE_FORECAST'])) && (
        <Route
          exact
          path={ROUTE_SITE_FORECAST}
          render={() => {
            const queryString = stringifyQueryParams(getManageDataStreamQueryObj(siteForecast))
            return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
          }}
        />
      )}
      {(hasGlobalAccess ||
        hasAnyAuthority(user, ['ROLE_AREA_FORECAST', 'ROLE_AREA_FORECAST_TRIAL', 'ROLE_ENERCAST'])) && (
        <Route
          exact
          path={ROUTE_AREA_FORECAST}
          render={() => {
            const queryString = stringifyQueryParams(getManageDataStreamQueryObj(areaForecast))
            return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
          }}
        />
      )}

      <Route path={ROUTE_PRODUCTS} component={Products} />

      <Route
        exact
        path={REDIRECT_ROUTE_USER_SETTINGS}
        render={() => {
          const queryString = stringifyQueryParams(getUserSettingsQueryObj())
          return <Redirect to={`${ROUTE_WORKBENCH}?${queryString}`} />
        }}
      />

      {hasGlobalAccess && <Route path={ROUTE_DEMO_RESOURCES} exact component={DemoResources} />}

      {(isAdmin(user) || isImpersonatedAdmin(user) || isPartner(user)) && (
        <Route
          path={ROUTE_USER_MANAGEMENT}
          exact
          component={UserManagement}
          // This was for the iframe
          // component={() => (
          //   <OldUiIframe
          //     unmountAction={GET_USER_REQUEST}
          //     unloadAction={GET_USER_REQUEST}
          //     url="/iframe/#/user-management?IFRAME"
          //   />
          // )}
        />
      )}
      {(isAdmin(user) || isImpersonatedAdmin(user)) && (
        <Route path={ROUTE_METRICS} exact component={() => <OldUiIframe url="/iframe/#/metrics?IFRAME" />} />
      )}
      {(isAdmin(user) || isImpersonatedAdmin(user)) && (
        <Route path={ROUTE_HEALTH} exact component={() => <OldUiIframe url="/iframe/#/health?IFRAME" />} />
      )}
      {(hasAccessToSiteAssessment || isAdmin(user) || isImpersonatedAdmin(user)) && (
        <Route path={ROUTE_SITE_ASSESSMENT} exact component={SiteAssessmentPage} />
      )}
      {(isAdmin(user) || isImpersonatedAdmin(user)) && (
        <Route
          path={ROUTE_CONFIGURATION}
          exact
          component={() => <OldUiIframe url="/iframe/#/configuration?IFRAME" />}
        />
      )}
      {/* Audits is temporarily hidden. We have disabled also the route , to prevent accessing from URL */}
      {/*{(isAdmin(user) || isImpersonatedAdmin(user)) && (*/}
      {/*  <Route path={ROUTE_AUDITS} exact component={() => <OldUiIframe url="/iframe/#/audits?IFRAME" />} />*/}
      {/*)}*/}
      {(isAdmin(user) || isImpersonatedAdmin(user)) && (
        <Route path={ROUTE_LOGS} exact component={() => <OldUiIframe url="/iframe/#/logs?IFRAME" />} />
      )}
      {(isAdmin(user) || isImpersonatedAdmin(user)) && <Route path={ROUTE_GIPS} exact component={() => <Gips />} />}

      {isAdmin(user) && <Route path={ROUTE_RUNNING_BACKCASTS} exact component={RunningBackcasts} />}

      <Route path="*">
        <Redirect to={ROUTE_WORKBENCH} />
      </Route>
    </Switch>
  )
}

export default React.memo(Routes)
