import { ForecastConfig, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import SiteForecastDetails from 'modules/dataStreams/siteForecast/SiteForecastDetails'

import SiteForeCastList from 'modules/dataStreams/siteForecast/SiteForecastList'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { dataStreamTypeQuery } from 'utils/dataStream'
import { TemplateIds } from 'utils/siteForecastConfiguration'
import {
  getSiteForecastTemplateQueryObj,
  QUERY_DATA_STREAM_ID,
  QUERY_DATA_STREAM_TYPE,
  QUERY_NEW_SITE_FORECAST_TEMPLATE,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'

const SiteForecasts: React.FC = () => {
  const [pageLoaded, setPageLoaded] = useState()
  const { queryParams } = useQueryParams()
  const [importedConfig, setImportedConfig] = useState<ForecastConfig | null>(null)
  const { onUpdateQueryString } = useQueryString()

  const { [TimeSeriesType.SITE_FORECAST]: siteForecast } = dataStreamTypeQuery

  const handleSetImportedConfig = useCallback(
    (data: ForecastConfig) => {
      setImportedConfig(data)
      if (data.name) {
        const queryData = {
          ...getSiteForecastTemplateQueryObj(TemplateIds.NEW),
          [QUERY_NEW_SITE_FORECAST_TEMPLATE]: true,
        }
        onUpdateQueryString(queryData)
      }
    },
    [onUpdateQueryString],
  )

  const dataStreamId = useMemo(() => queryParams[QUERY_DATA_STREAM_ID], [queryParams[QUERY_DATA_STREAM_ID]])

  const isDetails = useMemo(() => queryParams[QUERY_DATA_STREAM_TYPE] === siteForecast && Boolean(dataStreamId), [
    dataStreamId,
    queryParams[QUERY_DATA_STREAM_TYPE],
  ])

  useEffect(() => {
    if (queryParams[QUERY_DATA_STREAM_TYPE]) {
      setPageLoaded(true)

      if (!dataStreamId || dataStreamId !== TemplateIds.NEW) {
        setImportedConfig(null)
      }
    }
  }, [queryParams[QUERY_DATA_STREAM_TYPE], dataStreamId, isDetails])

  return (
    <>
      {pageLoaded && (
        <>
          {isDetails ? (
            <SiteForecastDetails importedConfig={importedConfig} forecastId={dataStreamId} />
          ) : (
            <SiteForeCastList onImportConfig={handleSetImportedConfig} />
          )}
        </>
      )}
    </>
  )
}

export default React.memo(SiteForecasts)
