import React, { useState, useEffect, useMemo } from 'react'
import { getWindowStartAndEndTimesTimestamp } from 'modules/workspace/chart/days'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import { t } from 'ttag'

const RevisionCountdownTimer = ({ revisionTimingInfo }) => {
  const { windowStartTimes, windowLength } = revisionTimingInfo

  const [timeLeft, setTimeLeft] = useState({})

  const windowStartAndEndRevisionsTimestampArray = useMemo(() => {
    if (windowStartTimes && windowLength) {
      return getWindowStartAndEndTimesTimestamp(windowStartTimes, windowLength)
    }
    return []
  }, [windowStartTimes, windowLength])

  // calculate time left function
  const calculateTimeLeft = () => {
    let timeLeft = {}

    const nextRevisionStart = windowStartAndEndRevisionsTimestampArray.find((timestamp) => {
      return timestamp > new Date().getTime()
    })

    const difference = nextRevisionStart - new Date().getTime()

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const createTimer = useMemo(() => {
    const timerComponents: any[] = []

    const addZeroForSingleDigitTime = (value: number | string) => {
      if (value.toString().length === 1) {
        value = '0' + value
      }
      return value
    }

    Object.keys(timeLeft).forEach((interval, index) => {
      timerComponents.push(
        <span key={interval} style={{ color: theme.palette.primary.main }}>
          {!timeLeft[interval] ? '00' : addZeroForSingleDigitTime(timeLeft[interval])} {index < 2 && ':'}{' '}
        </span>,
      )
    })

    return timerComponents
  }, [timeLeft])

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (windowStartTimes && windowLength) {
      timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
    }
    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [windowStartTimes, windowLength])

  return (
    <>
      {createTimer.length ? (
        <PopperTooltip
          popperLabel={<div style={{ fontSize: '24px' }}>{createTimer.length ? createTimer : null}</div>}
          popperContent={<div>{t`Time left until next schedule submission deadline.`}</div>}
        />
      ) : null}
    </>
  )
}

export default RevisionCountdownTimer
