import React, { useEffect, useState } from 'react'
import { t } from 'ttag'
import { downloadScheduleTemplate } from 'modules/workspace/schedule/schedule.api'
import { Asset } from 'modules/asset/store/asset.types'
import { FileData } from 'utils/file'
import { DataStream } from 'modules/dataStreams/dataStreams.types'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import Flex from 'ui/styles/Flex'

const DownloadTextLink = styled.div`
  cursor: pointer;
  color: #0277bd;
  text-decoration: none;
`

interface UploadScheduleTemplateSuccessProps {
  asset: Asset
  targetScheduleDataStream: DataStream
  scheduleSourceDataStream: DataStream
  uploadedTemplateFiles: FileData[]
}

const DownloadScheduleTemplate: React.FC<UploadScheduleTemplateSuccessProps> = ({
  asset,
  uploadedTemplateFiles,
  targetScheduleDataStream,
  scheduleSourceDataStream,
}) => {
  const [downloadInProgress, setDownloadInProgress] = useState(false)

  /**
   * Handler to download the file
   */
  const handleDownloadScheduleTemplate = () => {
    const fileName = uploadedTemplateFiles[0]?.file.name
    setDownloadInProgress(true)
    if (asset && targetScheduleDataStream) {
      downloadScheduleTemplate({
        assetId: asset.id,
        targetScheduleId: targetScheduleDataStream.id as string,
        scheduleSourceId: scheduleSourceDataStream.id as string,
        fileName: fileName,
      })
        .then(({ response }) => {
          // create file link in browser's memory
          const csvBlob = new Blob([response?.data], { type: 'text/csv;charset=utf-8' })
          const href = window.URL.createObjectURL(csvBlob)

          // create an anchor tag with href to file & click
          const link = document.createElement('a')
          link.href = href
          link.setAttribute('download', `${fileName}`) //or any other extension
          document.body.appendChild(link)
          link.click()

          // clean up anchor tag element & remove ObjectURL
          document.body.removeChild(link)
          window.URL.revokeObjectURL(href)
        })
        .finally(() => {
          setDownloadInProgress(false)
        })
    }
  }

  /**
   * Automatically download the schedule when this component is loaded
   */
  useEffect(() => {
    handleDownloadScheduleTemplate()
  }, [])

  return (
    <Flex direction="row">
      <DownloadTextLink onClick={handleDownloadScheduleTemplate}>{t`Download schedule again`}</DownloadTextLink>
      {downloadInProgress && (
        <CircularProgress style={{ cursor: 'help', marginLeft: '5px' }} size={18} thickness={4.5} />
      )}
    </Flex>
  )
}
export default DownloadScheduleTemplate
