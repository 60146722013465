import { useEffect, useMemo, useState } from 'react'
import { useForecastModelWeightsByAsset, useForecastModelWeightsByForecastModel } from 'modules/quality/quality.api'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { MetaForecastWidgetType } from 'modules/workspace/store/workspace.types'

export const useSavedForecastWeights = (typeOfModel: MetaForecastWidgetType) => {
  const [weights, setWeights] = useState()
  const selectedAssetsAndModelIds = useSelector(workspaceDraftAssetSelectionSelector)

  const assetId = useMemo(() => {
    // If selected item is a model then we should not return asset Id
    if (selectedAssetsAndModelIds[0].includes(FORECAST_MODEL_FOR_BACK_CAST_ID)) {
      return undefined
    } else return selectedAssetsAndModelIds[0]
  }, [selectedAssetsAndModelIds])

  const metaForecastWeightsByAssetResult = useForecastModelWeightsByAsset({ assetId, typeOfModel })
  const { data: weightsByAsset, isLoading: getWeightsByAssetIsLoading } = metaForecastWeightsByAssetResult

  const selectedModelIds = selectedAssetsAndModelIds.filter((id) => id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID))

  const forecastModelId = useMemo(
    () => (selectedModelIds.length ? getBackCastModelDataFromId(selectedModelIds[0])?.modelId : ''),
    [selectedModelIds],
  )

  const metaForecastWeightsByModelResult = useForecastModelWeightsByForecastModel({ forecastModelId, typeOfModel })
  const { data: weightsByModel, isLoading: getWeightsByModelsIsLoading } = metaForecastWeightsByModelResult

  // console.log({ metaForecastWeightsByModelResult, metaForecastWeightsByAssetResult, typeOfModel })

  /**
   * Get the e3Ensemble weights from Asset/Model
   * */
  useEffect(() => {
    // console.log({ e3EnsembleWeightsByModel, e3EnsembleWeightsByAsset })

    // Weights from default
    let sliderValues = {}
    if (!getWeightsByAssetIsLoading && weightsByAsset && Object.keys(weightsByAsset).length) {
      // Weights from Asset
      sliderValues = weightsByAsset
    }
    if (!getWeightsByModelsIsLoading && weightsByModel && Object.keys(weightsByModel).length) {
      // Weights from Model
      sliderValues = weightsByModel
    }
    setWeights(sliderValues)
  }, [getWeightsByAssetIsLoading, weightsByAsset, getWeightsByModelsIsLoading, weightsByModel])

  return weights
}
