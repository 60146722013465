import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, ButtonGroup, ButtonGroupProps, CircularProgress, Menu, MenuItem, MenuList } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { green } from '@material-ui/core/colors'
import { MenuProps } from '@material-ui/core/Menu/Menu'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { UseMutationResult } from 'react-query'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { t } from 'ttag'

import { SelectAsListItem } from 'ui/form/SelectAsList'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import { FormSaveOptions, RequiredFieldsOfForm } from 'utils/form'
import { useIsReadOnlyUser } from 'utils/user'

// button styles

interface StyledButtonProps {
  isError: boolean
}
const buttonProps = (props: StyledButtonProps) => `
  ${
    props.isError
      ? `
       & .MuiButton-startIcon.MuiButton-iconSizeSmall {
          color: ${theme.palette.error.dark};
        }
  `
      : ''
  }
`
// Change the background to red colour when there is an error
// color: white;
// background-color: ${theme.palette.error.dark};
// &:hover {
//   background-color: ${theme.palette.error.main};
// }
//
// & .MuiButton-contained.Mui-disabled {
//   color: white;
// }

const StyledButton = styled(({ ...rest }) => <Button {...rest} />)<StyledButtonProps>`
  &.MuiButton-root {
    ${(props) => buttonProps(props)}
  }
`
const StyledButtonGroup = styled(({ ...rest }) => <ButtonGroup {...rest} />)<StyledButtonProps>`
  &.MuiButtonGroup-root .MuiButton-root {
    ${(props) => buttonProps(props)}
  }
`

// menu styles

const CaretButton = styled(Button)<ButtonProps>`
  &.MuiButton-containedSizeSmall {
    padding: 4px 0;
  }
  &.MuiButtonGroup-grouped {
    min-width: 24px;
  }
`

const StyledMenu = styled(Menu)<MenuProps>`
  & > .MuiPaper-root {
    max-width: 32em;
  }

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`

// loading styles

const LoadingWrapper = styled.div`
  position: relative;
`
const LoadingProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${green[500]};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`

// error styles

const TooltipWrapper = styled.div`
  max-width: 50rem;
`

const ErrorExplanation = styled.p`
  font-weight: bold;
`

const ErrorMessage = styled.div`
  color: ${theme.palette.error.main};
`

export interface ActionButtonProps<TResult, TError, TVariables, TContext> {
  result: UseMutationResult<TResult, TError, TVariables, TContext>
  formInvalid: boolean
  formTouched: boolean
  formModifiedSinceLastSubmit: boolean // if submit failed due to some backend error, we need to check the changes in the form after submit
  icon: IconProp // icon thas is usually shown. will be exchanged by error and success icons in case of updates
  disabled?: boolean
  menuItems?: SelectAsListItem[] // this needs to be passed if it should be displayed as a split button with multiple options
  mainItemKey?: string // currently selected default option
  onSelectMenuItem?: (item: SelectAsListItem) => void // called when an entry of the split menu options is selected
  saveOptionLoading?: boolean
  dynamicButtonType?: string | undefined // this can be used if there is a single action button
  onCloseDetailsForm?: () => void
  invalidFieldsErrorMessages?: RequiredFieldsOfForm[]
}

const ActionButton: React.FC<ActionButtonProps<any, any, any, any> & (ButtonProps | ButtonGroupProps)> = ({
  result,
  formInvalid = false,
  formTouched = true,
  formModifiedSinceLastSubmit,
  icon,
  disabled = false,
  menuItems,
  mainItemKey,
  onSelectMenuItem,
  className,
  saveOptionLoading = false,
  children,
  dynamicButtonType,
  onCloseDetailsForm,
  invalidFieldsErrorMessages,
  ...buttonProps
}) => {
  const isSuccess = result ? result.isSuccess : false
  const isError = result ? result.isError : false
  const error = result ? result.error : {}
  const resultIsLoading = result ? result.isLoading : false
  const isReadOnlyUser = useIsReadOnlyUser()

  const [firstMenuItem, setFirstMenuItem] = useState<SelectAsListItem>()
  const isLoading = resultIsLoading || saveOptionLoading
  const [disableCopyButton, setDisableCopyButton] = useState(false)

  const isInvalid = useMemo(() => formTouched && formInvalid, [formTouched, formInvalid])

  // button basics
  const mainItem = useMemo(() => {
    return mainItemKey && menuItems ? menuItems.find((item) => item.key === mainItemKey) : undefined
  }, [mainItemKey, menuItems])

  const splitMenuItems = useMemo(() => {
    if (mainItem) {
      const remainingItems: SelectAsListItem[] = (menuItems || []).filter((menuItem) => menuItem.key !== mainItem.key)
      return [mainItem, ...remainingItems]
    } else {
      return menuItems
    }
  }, [menuItems, mainItem])

  const isSplitButton = Boolean(splitMenuItems && splitMenuItems.length > 0)
  // error handling
  const anyError = useMemo(() => {
    if (formModifiedSinceLastSubmit) {
      return isInvalid
    } else {
      return Boolean(isInvalid || (isError && error))
    }
  }, [isInvalid, isError, error, formModifiedSinceLastSubmit])

  const TooltipContent = useMemo(() => {
    if (anyError) {
      let errorMessage = error?.message

      // Attimes some API's return error object as string to handle that we need this condition
      if (error?.message && error.message.includes('exception')) {
        const errorData = JSON.parse(error.message)
        errorMessage = errorData?.message || ''
      }
      return (
        <TooltipWrapper>
          {isInvalid && (
            <>
              <ErrorMessage>{t`Validation failed. Please correct the issues below.`}</ErrorMessage>
              {invalidFieldsErrorMessages && invalidFieldsErrorMessages.length > 0 && (
                <ul>
                  {invalidFieldsErrorMessages?.map((field, index) => {
                    return (
                      <li key={index}>
                        <ErrorMessage>
                          <span style={{ paddingRight: '5px', color: 'black' }}>{field.fieldName}:</span> {field.reason}
                        </ErrorMessage>
                      </li>
                    )
                  })}
                </ul>
              )}
            </>
          )}
          {isError && (
            <>
              <ErrorExplanation>{t`Action returned an error. Please try again.`}</ErrorExplanation>
              <ErrorMessage>{errorMessage || ''}</ErrorMessage>
            </>
          )}
        </TooltipWrapper>
      )
    } else return <></>
  }, [anyError, isInvalid, isError, error, invalidFieldsErrorMessages])

  // icon logic
  const startIcon = useMemo(() => {
    let fontAwesomeIcon: IconProp = icon
    if (anyError) {
      fontAwesomeIcon = 'exclamation-triangle'
    } else if (isSuccess) {
      fontAwesomeIcon = 'check-circle'
    }
    return <FontAwesomeIcon icon={fontAwesomeIcon} fixedWidth />
  }, [icon.toString(), isSuccess, anyError])

  // split functionality
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [firstItem, ...otherMenuItems] = splitMenuItems || []

  useEffect(() => {
    if (splitMenuItems) {
      setFirstMenuItem(splitMenuItems[0])
    }
  }, [splitMenuItems])

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLLIElement, MouseEvent>,
    item: SelectAsListItem,
  ) => {
    if (onSelectMenuItem) {
      onSelectMenuItem(item)
      setFirstMenuItem(item)
    }
    setOpen(false)
    // setDisableCopyButton(true)
    // setTimeout(() => setDisableCopyButton(true), 0)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const disable = useMemo(() => (anyError && !formModifiedSinceLastSubmit) || isLoading || isInvalid || disabled, [
    isLoading,
    isInvalid,
    disabled,
    formModifiedSinceLastSubmit,
    anyError,
  ])

  useEffect(() => {
    if (anyError || resultIsLoading) {
      setDisableCopyButton(true)
    } else {
      setDisableCopyButton(false)
    }
  }, [anyError, JSON.stringify(result), resultIsLoading])

  return (
    <>
      <ConditionalWrapper
        condition={anyError}
        wrapper={(children) => (
          <PopperTooltip
            popperLabel={children}
            popperContent={TooltipContent}
            position={PopperTooltipPosition.BOTTOM_START}
          />
        )}
      >
        <LoadingWrapper className={className}>
          {isSplitButton ? (
            <StyledButtonGroup isError={anyError} disabled={isReadOnlyUser} {...buttonProps}>
              <Button
                ref={anchorRef}
                type="submit"
                startIcon={startIcon}
                disabled={firstMenuItem?.key === FormSaveOptions.CREATE_COPY ? disableCopyButton : disable || anyError}
                onClick={(event) => handleMenuItemClick(event, firstItem)}
              >
                {firstMenuItem ? firstMenuItem?.label : firstItem?.label}
              </Button>
              <CaretButton
                color={buttonProps?.color || 'primary'}
                size="small"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <FontAwesomeIcon icon="caret-down" />
              </CaretButton>
            </StyledButtonGroup>
          ) : dynamicButtonType ? (
            <StyledButton
              isError={anyError}
              type="button"
              onClick={() => (onCloseDetailsForm ? onCloseDetailsForm() : undefined)}
              startIcon={startIcon}
              disabled={isReadOnlyUser || disable}
              {...buttonProps}
            >
              {children}
            </StyledButton>
          ) : (
            <StyledButton
              isError={anyError}
              type="submit"
              startIcon={startIcon}
              disabled={isReadOnlyUser || disable}
              {...buttonProps}
            >
              {children}
            </StyledButton>
          )}
          {isLoading && <LoadingProgress size={24} />}
        </LoadingWrapper>
      </ConditionalWrapper>

      {/*Save Menu items*/}
      {isSplitButton && (
        <StyledMenu anchorEl={anchorRef.current} getContentAnchorEl={null} open={open} onClose={handleClose}>
          <MenuList>
            {otherMenuItems.map((item) => (
              <MenuItem
                disabled={disabled ? item.key !== FormSaveOptions.CREATE_COPY : disabled || anyError}
                key={item.key}
                onClick={(event) => handleMenuItemClick(event, item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
        </StyledMenu>
      )}
    </>
  )
}

export default React.memo(ActionButton)
