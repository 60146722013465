import FormBlockNavigation from 'ui/FormBlockNavigation'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import { ReTableContextMenuItem, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReTableContextMenu from 'modules/reTable/ReTableContextMenu'
import React, { useCallback, useEffect, useState } from 'react'
import { tDeleteDialog, tUnsavedChangesDialog } from 'fixtures/commonTranslations'
import { AnyObject } from 'react-final-form'
import { FormApi } from 'final-form'
import { QUERY_STRING } from 'utils/query-string'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { useCustomMuiStyles } from 'themes/theme-light'
import { useIsReadOnlyUser } from 'utils/user'

const ActionsContainer = styled(Flex)`
  margin-top: 8px;
`

interface UpdateTableItemActionsProps {
  showContextMenu?: boolean
  showFormActions?: boolean
  actionMenuItems?: ReTableContextMenuItem[]
  onClickMenuItem?: (action: ReTableRowContextActions, rowItem?: any) => void
  onSubmit?: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  form?: FormApi
  onClose?: () => void
  onAddItem?: () => void
  dialogText: string
  dialogContext: string
  pageRoute?: string
  pageQueries?: QUERY_STRING[] | null
  navigationDialogKey: string
  rowItem?: any
  showAddActions?: boolean
  showTableRowContextMenu?: boolean
  // For custom action buttons we pass component and current action and unsaved dialog values
  customActionComponent?: React.ReactNode
  currentActionInitialValue?: ReTableRowContextActions | null
  openUnsavedDialogInitialValue?: boolean
  onCancelAction?: () => void
  highlightAdmin?: boolean
  rowSelected?: boolean
  overrideText?: boolean
}

const UpdateTableItemActions: React.FC<UpdateTableItemActionsProps> = ({
  showContextMenu,
  showFormActions,
  actionMenuItems,
  onClickMenuItem,
  onSubmit,
  onClose,
  onAddItem,
  form,
  dialogText,
  dialogContext,
  pageRoute = '',
  pageQueries = null,
  navigationDialogKey,
  rowItem,
  showAddActions = false,
  showTableRowContextMenu,
  customActionComponent,
  currentActionInitialValue,
  openUnsavedDialogInitialValue = false,
  onCancelAction,
  highlightAdmin = false,
  overrideText = false,
}) => {
  //Style
  const classes = useCustomMuiStyles()
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState<boolean | null>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean | null>(null)
  const [currentAction, setCurrentAction] = useState<ReTableRowContextActions | null>(null)
  const unsavedDialogTranslations = tUnsavedChangesDialog()
  const deleteDialogTranslations = tDeleteDialog(dialogContext, dialogText)
  const isReadOnlyUser = useIsReadOnlyUser()

  useEffect(() => setOpenUnsavedDialog(openUnsavedDialogInitialValue), [openUnsavedDialogInitialValue])
  useEffect(() => {
    if (currentActionInitialValue) {
      setCurrentAction(currentActionInitialValue)
    }
  }, [currentActionInitialValue])

  const getUnsavedDialogText = useCallback(() => {
    return (
      <div>
        {unsavedDialogTranslations.text1}
        <ul>
          <li>{`"${dialogText}" `}</li>
        </ul>
        {unsavedDialogTranslations.text2}
      </div>
    )
  }, [dialogText])

  const handleClickRowAction = useCallback(
    (action: ReTableRowContextActions, event: React.MouseEvent<HTMLElement>) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }
      setCurrentAction(action)
      if (action === ReTableRowContextActions.DELETE_ROW) {
        setOpenDeleteDialog(true)
      } else if (form && form.getState().dirty) {
        setOpenUnsavedDialog(true)
      } else if (action === ReTableRowContextActions.ADD_ROW && onAddItem) {
        onAddItem()
      } else {
        if (action === ReTableRowContextActions.CLOSE_FORM && onClose) {
          onClose()
        }
        onClickMenuItem(action, rowItem)
      }
    },
    [onClickMenuItem, rowItem, onAddItem],
  )

  const handleCancelAction = useCallback(() => {
    setOpenDeleteDialog(false)
    setOpenUnsavedDialog(false)
    // for custom components
    if (onCancelAction) {
      onCancelAction()
    }
  }, [])

  const handleConfirmAction = useCallback(() => {
    if (currentAction === ReTableRowContextActions.CLOSE_FORM && onClose) {
      onClose()
    } else if (currentAction === ReTableRowContextActions.ADD_ROW && onAddItem) {
      onAddItem()
    } else if (currentAction) {
      onClickMenuItem(currentAction, rowItem)
    }
    handleCancelAction()
  }, [currentAction, onClickMenuItem, onAddItem, rowItem])

  return (
    <>
      <FormBlockNavigation
        blockWithExternalChanges={false}
        navigationDialogText={dialogContext}
        form={form}
        currentPageRoute={pageRoute}
        currentPageQueries={pageQueries}
        navigationDialogKey={navigationDialogKey}
      />
      {openDeleteDialog && (
        <ConfirmationDialog
          heading={deleteDialogTranslations.heading}
          text={overrideText ? dialogText || deleteDialogTranslations.text : deleteDialogTranslations.text}
          confirmAction={deleteDialogTranslations.confirmAction}
          cancelAction={deleteDialogTranslations.cancelAction}
          onConfirm={handleConfirmAction}
          onCancel={handleCancelAction}
          openDialog={openDeleteDialog}
        />
      )}
      {openUnsavedDialog && (
        <ConfirmationDialog
          heading={unsavedDialogTranslations.heading}
          text={getUnsavedDialogText()}
          confirmAction={unsavedDialogTranslations.confirmAction}
          cancelAction={unsavedDialogTranslations.cancelAction}
          onConfirm={handleConfirmAction}
          onCancel={handleCancelAction}
          openDialog={openUnsavedDialog}
        />
      )}
      {customActionComponent && <>{customActionComponent}</>}
      <Flex alignItems="center" justifyContent="flex-end">
        {showAddActions && (
          <IconButton
            className={highlightAdmin ? classes.adminComponent : 'inherit'}
            onClick={(event) => handleClickRowAction(ReTableRowContextActions.ADD_ROW, event)}
            color={'primary'}
            size="small"
            disabled={isReadOnlyUser}
          >
            <FontAwesomeActionIcon disabled={isReadOnlyUser} size="xs" icon={'plus'} />
          </IconButton>
        )}

        {showTableRowContextMenu && (
          <>
            {showFormActions ? (
              <ActionsContainer justifyContent="flex-end" alignItems="flex-end" flexWrap="nowrap">
                <Box mr={0.5}>
                  <IconButton
                    disabled={isReadOnlyUser}
                    size="small"
                    onClick={() => handleClickRowAction(ReTableRowContextActions.CLOSE_FORM)}
                  >
                    <FontAwesomeIcon icon="times" />
                  </IconButton>
                </Box>

                <IconButton disabled={isReadOnlyUser} size="small" onClick={onSubmit} color="primary">
                  <FontAwesomeIcon icon="check" />
                </IconButton>
              </ActionsContainer>
            ) : (
              <Box display="flex" justifyContent="flex-end" mr={0.5}>
                <ReTableContextMenu
                  menuItems={actionMenuItems}
                  showContextMenu={showContextMenu}
                  onClickMenuItem={handleClickRowAction}
                />
              </Box>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

export default React.memo(UpdateTableItemActions)
