import { TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { t } from 'ttag'
import { getTime, isAfter, isBefore } from 'date-fns'
import {
  MeterDataCleansingConfiguration,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import {
  convertUTCToLocalDate,
  convertUtcToZonedTime,
  convertZonedTimeToAnotherZonedTime,
  convertZonedTimeToUtc,
  EnercastDate,
  exceedDates,
  isBetweenDates,
  isSameDate,
} from 'utils/date'
import { Timezone } from 'fixtures/timezones'
import { Asset, TYPE_SOLARPLANT, TYPE_WINDPLANT } from 'modules/asset/store/asset.types'
import { isParkWithNoPlants, isSolarAsset, isSolarPlant, isWindPlant } from 'utils/asset'

export const meterDataCleansingDataSelection = [
  {
    color: 0,
    id: 'METER_DATA',
    name: 'METER_DATA',
    type: TimeSeriesType.METER_DATA,
    uiAncestors: ['METER_DATA'],
    uiChildren: [],
    uiDescendants: [],
    uiHasMatchingDescendants: false,
    uiHasMatchingParents: true,
    uiIsMatching: true,
    uiLevel: 1,
    uiParents: ['METER_DATA'],
  },
  {
    color: 1,
    id: 'CAPACITY_DATA_PLANNED_AVAILABLE_CAPACITY',
    name: 'CAPACITY_DATA_PLANNED_AVAILABLE_CAPACITY',
    subType: TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY,
    // timeSeriesResultType: TimeSeriesSubType.CAPPED_CAPACITY,
    type: TimeSeriesType.CAPACITY_DATA,
    uiAncestors: ['CAPACITY_DATA'],
    uiChildren: [],
    uiDescendants: [],
    uiHasMatchingDescendants: false,
    uiHasMatchingParents: true,
    uiIsMatching: true,
    uiLevel: 1,
    uiParents: ['CAPACITY_DATA'],
  },
  // {
  //   color: 2,
  //   id: 'EXPERT_ECMWFM_WIND_SPEED_100m_REC',
  //   name: 'Expert ECMWFM wind speed 100m Rec',
  //   type: TimeSeriesType.WEATHER_DATA,
  //   uiAncestors: [TimeSeriesType.WEATHER_DATA],
  //   uiChildren: [],
  //   uiDescendants: [],
  //   uiHasMatchingDescendants: false,
  //   uiHasMatchingParents: true,
  //   uiIsMatching: true,
  //   uiLevel: 1,
  //   uiParents: [TimeSeriesType.WEATHER_DATA],
  // },
]

/**
 * @param date
 * @param remove
 * @description This function is used to remove or add some hours to a certain date. This was needed in order to get easier the edges of red selection.
 */
export const convertDateForEdges = (date: Date | string, remove: boolean) => {
  const t = new Date(date)
  if (remove) {
    t.setHours(t.getHours() - 12)
  } else {
    t.setHours(t.getHours() + 12)
  }

  return t
}

const checkIfTimePeriodIsOverlapping = (
  prevTimePeriod: TimePeriodToHighlightInterface,
  nextTimePeriod: TimePeriodToHighlightInterface,
) => {
  const { start: prevStartData, end: prevEndData } = prevTimePeriod
  const prevStart = prevStartData.date
  const prevEnd = prevEndData.date

  const { start: nextStartData, end: nextEndData } = nextTimePeriod
  const nextStart = nextStartData.date
  const nextEnd = nextEndData.date

  if (
    // Prevent selection , if this one includes some previous selection
    exceedDates(prevStart, prevEnd, nextStart, nextEnd) ||
    //Prevent selecting if we select again same time period
    (isSameDate(prevStart, nextStart) && isSameDate(prevEnd, nextEnd)) ||
    //Prevent selection if selected one is inside a SINGLE previous selection
    (isBetweenDates(prevStart, prevEnd, nextStart) && isBetweenDates(prevStart, prevEnd, nextEnd))
  ) {
    return true
    //When at least , a selection edge is inside a previous selection
  } //else return isBetweenDates(prevStart, prevEnd, nextStart) || isBetweenDates(prevStart, prevEnd, nextEnd)
}

export const getExcludedTimePeriodsColumns: () => Column[] = () => [
  {
    name: 'start',
    label: t`Start`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '9em',
    fieldName: 'start',
    isEditable: true,
    fixed: true,
  },
  {
    name: 'end',
    label: t`End`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '9em',
    fieldName: 'end',
    isEditable: true,
    fixed: true,
  },
]

export const trimMDCConfigToGetCleansingData = (
  formData: MeterDataCleansingConfiguration,
): MeterDataCleansingConfiguration => {
  const newData = Object.assign({}, formData)
  const newExcludedTimePeriods = [...newData.excludedTimePeriods]

  // Remove total time period from final object
  delete newData?.totalTimePeriod

  // Convert values from string to number
  if (newData.availabilityAnalysis) {
    newData.availabilityAnalysis.cappedProduction.value = +newData.availabilityAnalysis.cappedProduction.value
    newData.availabilityAnalysis.scaledProduction.value = +newData.availabilityAnalysis.scaledProduction.value
  }

  //
  // Remove id and name from excluded time period
  if (newExcludedTimePeriods.length > 0) {
    newExcludedTimePeriods.sort(function (a, b) {
      const dateA = new Date(a.start.date),
        dateB = new Date(b.start.date)
      return dateA - dateB
    })
    //
    newExcludedTimePeriods.forEach((timePeriod: TimePeriodToHighlightInterface, index) => {
      const newTimePeriod = { ...newExcludedTimePeriods[index] }

      delete newTimePeriod?.id
      delete newTimePeriod?.name
      delete newTimePeriod?.highlight

      newExcludedTimePeriods[index] = newTimePeriod
    })
    newData.excludedTimePeriods = newExcludedTimePeriods
  }
  return newData
}

// This function returns get all data from cleansing service and returns two different arrays (original data , afterCleansingData)
export const extractDataFromCleansingService = (data: any, asset: Asset) => {
  const originalData: number[] = [],
    afterCleansingData: number[] = []

  const valueToAccess = isSolarAsset(asset) ? 'GHI(W/m^2)' : 'WindSpeed(m/s)'

  if (data) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key]?.flags > 0) {
          afterCleansingData.push([data[key]?.[valueToAccess] || 0, data[key]?.['PowerWatt(kW)']])
        } else {
          originalData.push([data[key]?.[valueToAccess] || 0, data[key]?.['PowerWatt(kW)']])
        }
      }
    }
  }
  return { originalData, afterCleansingData }
}

export const sumOfAvailableSeriesForFullTime = (
  excludedTimePeriodsFromChart: TimePeriodToHighlightInterface[],
  seriesData: any,
  timezone: Timezone,
) => {
  let totalTime = 0

  excludedTimePeriodsFromChart.forEach((timePeriod) => {
    totalTime += CountOfTimeseriesWithinTimePeriodHandler(
      seriesData,
      timePeriod.start.date,
      timePeriod.end.date,
      timezone,
    )
  })
  return totalTime
}

export const sumOfAvailableSeriesForSelectedTime = (
  highlightTimePeriods: TimePeriodToHighlightInterface[],
  timePeriodForTraining: MDCTimePeriod[],
  availableData: any,
  timezone: Timezone,
) => {
  let finalSum = 0

  highlightTimePeriods.forEach((timePeriod) => {
    // Generally this condition checks if start date of a certain selection is not part of time period for training.
    if (
      isAfter(timePeriodForTraining.start.date, timePeriod.start.date) &&
      isBefore(timePeriodForTraining.start.date, timePeriod.end.date) &&
      isAfter(timePeriodForTraining.end.date, timePeriod.end.date) &&
      isAfter(timePeriodForTraining.end.date, timePeriod.start.date)
    ) {
      finalSum += CountOfTimeseriesWithinTimePeriodHandler(
        availableData,
        timePeriodForTraining.start.date,
        timePeriod.end.date,
        timezone,
      )
    }
    // Generally this condition checks if end date of a certain selection is not part of time period for training.
    else if (
      isBefore(timePeriodForTraining.end.date, timePeriod.end.date) &&
      isAfter(timePeriodForTraining.end.date, timePeriod.start.date) &&
      isBefore(timePeriodForTraining.start.date, timePeriod.start.date) &&
      isBefore(timePeriodForTraining.start.date, timePeriod.end.date)
    ) {
      finalSum += CountOfTimeseriesWithinTimePeriodHandler(
        availableData,
        timePeriod.start.date,
        timePeriodForTraining.end.date,
        timezone,
      )
    }
    // Generally this condition checks if end date also start date of a certain selection is not part of time period for training , so we need to keep only difference between time period for training.
    else if (
      isAfter(timePeriodForTraining.start.date, timePeriod.start.date) &&
      isBefore(timePeriodForTraining.end.date, timePeriod.end.date)
    ) {
      finalSum += CountOfTimeseriesWithinTimePeriodHandler(
        availableData,
        timePeriodForTraining.start.date,
        timePeriodForTraining.end.date,
        timezone,
      )
    }
    // This condition checks if start date and end date are outside. Don't need to calculate anything.
    else if (
      (isAfter(timePeriodForTraining.start.date, timePeriod.start.date) &&
        isAfter(timePeriodForTraining.start.date, timePeriod.end.date)) ||
      (isBefore(timePeriodForTraining.end.date, timePeriod.start.date) &&
        isBefore(timePeriodForTraining.end.date, timePeriod.end.date))
    ) {
      finalSum += 0
    }
    // Normal selection
    else {
      finalSum += CountOfTimeseriesWithinTimePeriodHandler(
        availableData,
        timePeriod.start.date,
        timePeriod.end.date,
        timezone,
      )
    }
  })

  return finalSum
}

const CountOfTimeseriesWithinTimePeriodHandler = (
  timeseriesData: { data: any[] }[],
  firstDate: string | number | Date,
  lastDate: string | number | Date,
  timezone: Timezone,
) => {
  // Need to convert them into local dates because timeseries is always in UTC and then converted to local dates
  // So the dates which will be interacting with timeseries should be converted to UTC and then local date
  const startDateInUTC = convertZonedTimeToAnotherZonedTime(firstDate as Date, timezone, 'UTC')
  const endDateInUTC = convertZonedTimeToAnotherZonedTime(lastDate as Date, timezone, 'UTC')

  const localStart = convertUTCToLocalDate(startDateInUTC as Date)
  const localEnd = convertUTCToLocalDate(endDateInUTC as Date)

  const startTimestamp = getTime(localStart)
  const endTimestamp = getTime(localEnd)
  // console.log({ startTimestamp, endTimestamp, firstDate, lastDate, startDateInUTC, endDateInUTC, localStart, localEnd })
  // console.log({ timeseriesData })

  const timeseriesWithinTimePeriod = timeseriesData.filter((singleSeries: any) => {
    return (
      singleSeries[0] > startTimestamp &&
      singleSeries[0] <= endTimestamp &&
      singleSeries[1] !== null &&
      singleSeries[2] !== null
    )
  })

  return timeseriesWithinTimePeriod.length
}

const insideArray = (dateForCheck: EnercastDate, array: TimePeriodToHighlightInterface[]) => {
  return array.find((singleElement: TimePeriodToHighlightInterface) => {
    return isBetweenDates(singleElement.start.date, singleElement.end.date, dateForCheck.date)
  })
}

export const checkIfTimePeriodEdgeIsInsideExistingTimePeriods = (
  timePeriod: TimePeriodToHighlightInterface,
  existingTimePeriods: TimePeriodToHighlightInterface[],
) => {
  const { start: nextStart, end: nextEnd } = timePeriod
  let timePeriodOverlaps = false
  if (insideArray(nextStart, existingTimePeriods)?.id !== insideArray(nextEnd, existingTimePeriods)?.id) {
    const leftEdge = insideArray(nextStart, existingTimePeriods)?.id.length
    const rightEdge = insideArray(nextEnd, existingTimePeriods)?.id.length

    // Here we are handling , if one edge is inside one selection and other edge is inside another one.
    // There is a scenario when one edge is inside a selection and other edge is somewhere else not inside any previous selection.
    if (leftEdge && rightEdge && typeof leftEdge == typeof rightEdge) {
      timePeriodOverlaps = true
    }
  }

  return timePeriodOverlaps
}

const MOVE = {
  RIGHT: 'rightDirection',
  LEFT: 'leftDirection',
}

export enum TimePeriodAction {
  ADD = 'ADD',
  EXTEND_OR_REDUCE = 'EXTEND_OR_REDUCE',
  PREVENT = 'PREVENT',
}

interface CheckTimePeriodToAddOrEditOrPreventInterface {
  action: TimePeriodAction | null
  timePeriod: TimePeriodToHighlightInterface | null
  index?: number | null
}

export const checkIfTimePeriodIsToAddOrExtendOrReduceOrPrevent = (
  timePeriod: TimePeriodToHighlightInterface,
  prevTimePeriods: TimePeriodToHighlightInterface[],
  fromTable?: boolean,
): CheckTimePeriodToAddOrEditOrPreventInterface => {
  const { start: nextStart, end: nextEnd } = timePeriod
  let indexOfElement = -1
  let extendTo = ''
  let reduceTo = ''
  const { RIGHT, LEFT } = MOVE
  const timePeriodToAdd = { ...timePeriod }
  let timePeriodToEdit = { ...timePeriod }

  let actionToPerform: CheckTimePeriodToAddOrEditOrPreventInterface = { action: null, timePeriod: null }

  const skipAddingTimePeriod = prevTimePeriods?.some((prevTimePeriod, index) => {
    const { start: prevStart, end: prevEnd } = prevTimePeriod

    /** Keep the following order for if conditions.**/
    // Prevent selection, if both edges of selected one, are inside one or more previous selections.
    const isTimePeriodInsideAnotherTimePeriod = checkIfTimePeriodEdgeIsInsideExistingTimePeriods(
      timePeriod,
      prevTimePeriods,
    )
    if (isTimePeriodInsideAnotherTimePeriod) return true

    // Reduce from right
    if (
      isBetweenDates(
        convertDateForEdges(prevTimePeriods[index].end.date, true),
        prevTimePeriods[index].end.date,
        nextEnd.date,
        true,
      ) &&
      isBetweenDates(prevTimePeriods[index].start.date, prevTimePeriods[index].end.date, nextStart.date, false)
    ) {
      indexOfElement = index
      reduceTo = LEFT
      return false
    }

    // Reduce from left
    if (
      isBetweenDates(
        prevTimePeriods[index].start.date,
        convertDateForEdges(prevTimePeriods[index].start.date, false),
        nextStart.date,
        true,
      ) &&
      isBetweenDates(prevTimePeriods[index].start.date, prevTimePeriods[index].end.date, nextEnd.date, false)
    ) {
      indexOfElement = index
      reduceTo = RIGHT
      return false
    }

    // Overlapping intervals
    const isOverlapping = checkIfTimePeriodIsOverlapping(prevTimePeriod, timePeriod)
    if (isOverlapping) return true

    // Extend selection from right
    if (isSameDate(prevEnd.date, nextStart.date) || isBetweenDates(prevStart.date, prevEnd.date, nextStart.date)) {
      indexOfElement = index
      extendTo = RIGHT
      return false
      // Extend selection from left
    } else if (isSameDate(prevStart.date, nextEnd.date) || isBetweenDates(prevStart.date, prevEnd.date, nextEnd.date)) {
      indexOfElement = index
      extendTo = LEFT
      return false
    }
  })
  if (skipAddingTimePeriod) {
    actionToPerform = {
      action: TimePeriodAction.PREVENT,
      timePeriod: timePeriod,
    }
  } else if (extendTo || reduceTo) {
    if (extendTo === RIGHT || extendTo === LEFT) {
      timePeriodToEdit = { ...prevTimePeriods[indexOfElement] }
      timePeriodToEdit.start.date = extendTo === LEFT ? nextStart.date : timePeriodToEdit.start.date
      timePeriodToEdit.end.date = extendTo === RIGHT ? nextEnd.date : timePeriodToEdit.end.date
    } else if (reduceTo === RIGHT || reduceTo === LEFT) {
      timePeriodToEdit = { ...prevTimePeriods[indexOfElement] }
      if (fromTable) {
        timePeriodToEdit.end.date = reduceTo === RIGHT ? nextEnd.date : timePeriodToEdit.end.date
        timePeriodToEdit.start.date = reduceTo === LEFT ? nextStart.date : timePeriodToEdit.start.date
      } else {
        timePeriodToEdit.start.date = reduceTo === RIGHT ? nextEnd.date : timePeriodToEdit.start.date
        timePeriodToEdit.end.date = reduceTo === LEFT ? nextStart.date : timePeriodToEdit.end.date
      }
    }
    timePeriodToEdit.start.timezone = timePeriod?.start?.timezone
    timePeriodToEdit.end.timezone = timePeriod?.end?.timezone
    actionToPerform = {
      action: TimePeriodAction.EXTEND_OR_REDUCE,
      timePeriod: timePeriodToEdit,
      index: indexOfElement,
    }
  } else {
    actionToPerform = {
      action: TimePeriodAction.ADD,
      timePeriod: timePeriodToAdd,
    }
  }

  return actionToPerform
}

interface GetDefaultMeterDataCleansingConfigurationProps {
  totalTimePeriod: MDCTimePeriod
  asset: Asset
  filterSettings?: MeterDataCleansingFilterSettings
}

export const getDefaultMeterDataCleansingConfiguration = ({
  totalTimePeriod,
  asset,
  filterSettings,
}: GetDefaultMeterDataCleansingConfigurationProps): MeterDataCleansingConfiguration => {
  const defaultConfig = {
    totalTimePeriod: totalTimePeriod,
    timePeriodForTraining: totalTimePeriod,
    availabilityAnalysis: {
      cappedProduction: {
        remove: false,
        value: 95,
      },
      scaledProduction: {
        remove: false,
        value: 95,
      },
      applyUpscaling: false,
    },
    excludedTimePeriods: [],
    range: filterSettings?.range || 0,
    repetitive: filterSettings?.repetitive || 0,
    outlierLeft: filterSettings?.outlierLeft || 0,
    outlierRight: filterSettings?.outlierRight || 0,
  }
  if (isSolarAsset(asset)) {
    defaultConfig['ghiThresholdNoPower'] = filterSettings?.ghiThresholdNoPower || 200
  }
  return defaultConfig
}

export const checkIfExcludedTimePeriodsChanged = (
  initialExcludedTimePeriods: TimePeriodToHighlightInterface[],
  currentExcludedTimePeriods: TimePeriodToHighlightInterface[],
) => {
  let timePeriodsChanged = false
  if (initialExcludedTimePeriods?.length === currentExcludedTimePeriods?.length) {
    // Check individual start and end times of each current time period with initial time periods
    initialExcludedTimePeriods.forEach((initialTimePeriod, index) => {
      const currentTimePeriod = currentExcludedTimePeriods[index]
      if (
        initialTimePeriod.start?.date &&
        currentTimePeriod.start?.date &&
        initialTimePeriod.end?.date &&
        currentTimePeriod.end?.date
      ) {
        const initialStartDate = initialTimePeriod.start.date
        const initialEndDate = initialTimePeriod.end.date

        const currentStartDate = currentTimePeriod.start.date
        const currentEndDate = currentTimePeriod.end.date
        if (isSameDate(initialStartDate, currentStartDate) && isSameDate(initialEndDate, currentEndDate)) {
          timePeriodsChanged = false
        } else {
          timePeriodsChanged = true
        }
      }
    })
  } else {
    timePeriodsChanged = true
  }
  return timePeriodsChanged
}

export const checkIfMeterDataCleansingConfigChanged = (
  currentConfig: MeterDataCleansingConfiguration,
  configToCompare: MeterDataCleansingConfiguration,
): boolean => {
  // Check if excluded time periods changed
  const excludedTimePeriodsChanged = checkIfExcludedTimePeriodsChanged(
    configToCompare.excludedTimePeriods,
    currentConfig.excludedTimePeriods,
  )
  if (excludedTimePeriodsChanged) return true

  // Check if the multipleGridPoints is changed
  if (currentConfig.multipleGridPoints !== configToCompare.multipleGridPoints) return true

  // Check if the Filter night values changed
  if (currentConfig?.filterNightValues !== configToCompare?.filterNightValues) return true

  if (JSON.stringify(configToCompare.totalTimePeriod) !== JSON.stringify(currentConfig.totalTimePeriod))
    //Check if totalTimePeriod changed
    return true

  //Check if totalTimePeriodForTraining changed
  if (JSON.stringify(configToCompare.timePeriodForTraining) !== JSON.stringify(currentConfig.timePeriodForTraining))
    return true

  // Check if availabilityAnalysis changed
  if (JSON.stringify(configToCompare.availabilityAnalysis) !== JSON.stringify(currentConfig.availabilityAnalysis))
    return true

  // Check if filter settings changed
  const { range, repetitive, outlierLeft, outlierRight, ghiThresholdNoPower } = currentConfig
  const {
    range: initialRange,
    repetitive: initialRepetitive,
    outlierLeft: initialOutlierLeft,
    outlierRight: initialOutlierRight,
    ghiThresholdNoPower: initialGhiThresholdNoPower,
  } = configToCompare
  const initialFilterSettingsAsString = `${initialRange}${initialRepetitive},${initialOutlierLeft},${initialOutlierRight},${initialGhiThresholdNoPower}`
  const currentFilterSettingsAsString = `${range}${repetitive},${outlierLeft},${outlierRight},${ghiThresholdNoPower}`
  if (initialFilterSettingsAsString !== currentFilterSettingsAsString) return true
  else return false
}

interface TransformMDCConfigProps {
  config: MeterDataCleansingConfiguration
  userTimezone: Timezone
  asset: Asset
}

// Convert all dates to UTC before saving
export const transformMDCConfigData = ({
  config,
  userTimezone,
  asset,
}: TransformMDCConfigProps): MeterDataCleansingConfiguration => {
  const timezoneToConvertFrom = userTimezone

  const updatedConfig = { ...config }

  if (updatedConfig && Object.keys(updatedConfig.timePeriodForTraining).length > 0) {
    // Convert the timePeriodForTraining for training to UTC
    let timePeriodForTrainingStart = new Date(updatedConfig.timePeriodForTraining.start.date)
    timePeriodForTrainingStart.setSeconds(0o0, 0o00)
    timePeriodForTrainingStart = convertZonedTimeToUtc(timePeriodForTrainingStart, timezoneToConvertFrom)

    let timePeriodForTrainingEnd = new Date(updatedConfig.timePeriodForTraining.end.date)
    timePeriodForTrainingEnd.setSeconds(0o0, 0o00)
    timePeriodForTrainingEnd = convertZonedTimeToUtc(timePeriodForTrainingEnd, timezoneToConvertFrom)

    updatedConfig.timePeriodForTraining = {
      start: {
        ...updatedConfig.timePeriodForTraining.start,
        date: timePeriodForTrainingStart,
      },
      end: {
        ...updatedConfig.timePeriodForTraining.end,
        date: timePeriodForTrainingEnd,
      },
    }
  }

  // Convert the excluded timePeriods to UTC
  updatedConfig.excludedTimePeriods = (updatedConfig.excludedTimePeriods || []).map((timePeriod) => {
    return {
      ...timePeriod,
      start: {
        ...timePeriod.start,
        date: convertZonedTimeToUtc(timePeriod.start.date, timezoneToConvertFrom),
      },
      end: {
        ...timePeriod.end,
        date: convertZonedTimeToUtc(timePeriod.end.date, timezoneToConvertFrom),
      },
    }
  })

  // Delete the keys which are not needed by solar mdc
  if (isSolarAsset(asset)) {
    delete updatedConfig['binSize']
    delete updatedConfig['filterSnowyDays']
    delete updatedConfig['powerThresholdNoReference']
  }

  return updatedConfig
}

// Response is in string because axios default transformation is overrided with this method
// Convert all the dates to user timezone
export const convertMDCConfigDatesToUserTimezone = (data: MeterDataCleansingConfiguration, userTimezone: Timezone) => {
  // const config = { ...JSON.parse(response) }

  const updatedConfig = { ...data }

  if (updatedConfig && Object.keys(updatedConfig.timePeriodForTraining).length > 0) {
    // Convert the timePeriodForTraining for training to UTC
    let timePeriodForTrainingStart = new Date(updatedConfig.timePeriodForTraining.start.date)
    timePeriodForTrainingStart = convertUtcToZonedTime(timePeriodForTrainingStart, userTimezone)

    let timePeriodForTrainingEnd = new Date(updatedConfig.timePeriodForTraining.end.date)
    timePeriodForTrainingEnd = convertUtcToZonedTime(timePeriodForTrainingEnd, userTimezone)

    updatedConfig.timePeriodForTraining = {
      start: {
        ...updatedConfig.timePeriodForTraining.start,
        date: timePeriodForTrainingStart,
      },
      end: {
        ...updatedConfig.timePeriodForTraining.end,
        date: timePeriodForTrainingEnd,
      },
    }
  }

  // Convert the excluded timePeriods to UTC
  updatedConfig.excludedTimePeriods = (updatedConfig.excludedTimePeriods || []).map((timePeriod) => {
    return {
      ...timePeriod,
      start: {
        ...timePeriod.start,
        date: convertUtcToZonedTime(timePeriod.start.date, userTimezone),
      },
      end: {
        ...timePeriod.end,
        date: convertUtcToZonedTime(timePeriod.end.date, userTimezone),
      },
    }
  })

  return updatedConfig
}

export const getMeterDataCleansingTrainingLabel = (label: string) => {
  switch (label) {
    case 'CLEANSING':
      return t`Filtering with cleansing tool`
    case 'BASIC_FILTERING':
      return t`Basic filtering`
    case 'FILTERING_AND_CLEANSING':
      return t`Both`
    default:
      return ''
  }
}

/********************************************************************************
 ======================= BULK METER DATA CLEANSING ==============================
 ********************************************************************************/
export const BULK_FILTER_SETTINGS_WIND = 'bulkFiltersWind'
export const BULK_FILTER_SETTINGS_SOLAR = 'bulkFiltersSolar'

export const getDefaultValuesForBulkMdcFilterSettings = (): MeterDataCleansingFilterSettings => {
  return {
    range: 0,
    repetitive: 0,
    outlierLeft: 0,
    outlierRight: 0,
    ghiThresholdNoPower: 200,
  }
}

// This function returns how many pages we are going to have
export const calculatePagesCount = (pageSize: number, totalCount: number) => {
  return totalCount < pageSize ? 1 : Math.ceil(totalCount / pageSize)
}

export const isValidAssetForMDC = (rowAsset: Asset) => {
  return (
    isWindPlant(rowAsset) ||
    isParkWithNoPlants(rowAsset, TYPE_WINDPLANT) ||
    isSolarPlant(rowAsset) ||
    isParkWithNoPlants(rowAsset, TYPE_SOLARPLANT)
  )
}
