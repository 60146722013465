import React, { useCallback, useEffect, useState } from 'react'
import { Switch } from '@material-ui/core'

interface BooleanSwitchProps {
  enabled: boolean
  selected?: boolean
  onToggle: (checked: boolean) => void
  disabled?: boolean
}

const BooleanSwitch: React.FC<BooleanSwitchProps> = ({
  enabled,
  selected = false,
  disabled = false,
  onToggle,
  ...rest
}) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(Boolean(enabled))

  const handleToggle = useCallback(
    (event) => {
      event.stopPropagation()
      setInitialized(true)
      setChecked((c) => !c)
    },
    [onToggle],
  )

  useEffect(() => {
    if (!initialized) return
    onToggle(checked)
  }, [initialized, checked])

  return (
    <Switch
      color={selected ? 'default' : 'primary'}
      size="small"
      checked={Boolean(enabled)}
      onChange={handleToggle}
      disabled={disabled}
      {...rest}
    />
  )
}

export default React.memo(BooleanSwitch)
