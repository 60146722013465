import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { UserManagementType } from 'modules/userManagement/userManagement.types'
import Flex from 'ui/styles/Flex'
import UserManagementTableToolbar from 'modules/userManagement/UserManagementTableToolbar'
import { RETABLE_ID_USER_MANAGEMENT, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { getUserManagementTableColumns } from 'utils/userManagement'
import { useDispatch } from 'react-redux'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import styled from 'styled-components'
import ReTable from 'modules/reTable/ReTable'
import { table } from 'themes/theme-light'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import UserManagementTableBody from 'modules/userManagement/UserManagementTableBody'
import { getUserManagementUserQueryObj, useQueryString } from 'utils/query-string'
import { PAGINATION_ITEMS, PaginationIndex } from 'utils/ReTablePagination'

export interface UserManagementTableFilter {
  status: string
  withRole: string[]
  withoutRole: string[]
}

const Table = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

const TableContainer = styled(Flex)`
  height: calc(100vh - 165px);
`

interface UserManagementTableProps {
  tableItems: UserManagementType[]
}

const UserManagementTable: React.FC<UserManagementTableProps> = ({ tableItems }) => {
  const dispatch = useDispatch()
  const tableHeaderHasActions = true
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const { onUpdateQueryString } = useQueryString()

  const [pagination, setPagination] = useState<PaginationIndex>({ start: 0, end: PAGINATION_ITEMS })
  const [itemsToRender, setItemsToRender] = useState<UserManagementType[]>([])
  const [visibleItems, setVisibleItems] = useState<UserManagementType[]>([])
  const [filterData, setFilterData] = useState<UserManagementTableFilter | null>(null)

  const handleItemsToRenderChange = useCallback(
    (rowItems: UserManagementType[]) => {
      requestAnimationFrame(() => {
        if (filterData?.status) {
          rowItems = rowItems.filter((item) => item.uiUserStatus === filterData?.status)
        }

        if (filterData?.withRole?.length) {
          // Check if every filtered role is present in authorities
          rowItems = rowItems.filter((item) =>
            (filterData?.withRole || []).every((filteredRole) => item?.authorities.includes(filteredRole)),
          )
        }
        if (filterData?.withoutRole?.length) {
          // Check if every filtered role is NOT present in authorities
          rowItems = rowItems.filter((item) =>
            (filterData?.withoutRole || []).every((filteredRole) => !item?.authorities.includes(filteredRole)),
          )
        }
        setItemsToRender(rowItems)
      })
    },
    [filterData],
  )

  const handleEditItem = useCallback(
    (item: UserManagementType) => {
      onUpdateQueryString(getUserManagementUserQueryObj(item))
    },
    [onUpdateQueryString],
  )

  const handleAddUser = useCallback(() => {
    onUpdateQueryString(getUserManagementUserQueryObj())
  }, [onUpdateQueryString])

  const handleDeleteItem = (item: UserManagementType) => {
    console.log('delete =', item)
  }

  const columnsAvailable = useMemo(() => getUserManagementTableColumns(), [])
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_USER_MANAGEMENT)

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_USER_MANAGEMENT, columnsAvailable })
  }, [columnsAvailable])

  useEffect(() => {
    const prevSelectedColumns = columnsSelected.map((c) => c.name)
    const columns = columnsAvailable.filter((c) => c.fixed || prevSelectedColumns.includes(c.name)).map((c) => c.name)

    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_USER_MANAGEMENT,
      columnsSelected: columns,
    })
  }, [columnsAvailable, columnsSelected])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'login',
    }),
    [],
  )

  const handlePageChange = useCallback((paginationObj: PaginationIndex) => {
    setPagination(paginationObj)
  }, [])

  const handleUpdateFilterData = useCallback((data: UserManagementTableFilter) => {
    setPagination({ start: 0, end: PAGINATION_ITEMS })
    setFilterData(data)
  }, [])

  useEffect(() => {
    const newVisibleItems = [...itemsToRender]
    setVisibleItems(newVisibleItems.slice(pagination.start, pagination.end))
  }, [itemsToRender, pagination])

  return (
    <Flex direction="column" fullHeight>
      <UserManagementTableToolbar
        onAddUser={handleAddUser}
        columns={columnsSelected}
        tableHeaderHasActions={tableHeaderHasActions}
        totalItems={itemsToRender}
        visibleItems={visibleItems}
        onPageChange={handlePageChange}
        tableId={RETABLE_ID_USER_MANAGEMENT}
        onUpdateFilter={handleUpdateFilterData}
        filterData={filterData}
      />

      <TableContainer direction="column">
        <Table
          filterData={filterData}
          id={RETABLE_ID_USER_MANAGEMENT}
          itemHeight={table.rowHeight}
          items={tableItems as ReTableItem[]}
          hasExtendedHeader={false}
          itemsPaddingHeader={ITEMS_PADDING_HEADER}
          itemsPaddingFooter={ITEMS_PADDING_FOOTER}
          renderAheadCount={RENDER_AHEAD_COUNT}
          defaultSort={defaultSort}
          currentPaginationItems={visibleItems.length || 2}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ReTableGenericHeader columns={columnsSelected} />
          <UserManagementTableBody
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            columns={columnsSelected}
            tableHeaderHasActions={tableHeaderHasActions}
            visibleItems={visibleItems}
          />
        </Table>
      </TableContainer>
    </Flex>
  )
}

export default React.memo(UserManagementTable)
