import { formatDate } from 'utils/date'
import { UserStatus } from 'modules/userManagement/userManagement.types'

const FIELDS = {
  AUTHORITIES: 'authorities',
  STATUS: 'uiUserStatus',
  CREATED_DATE: 'createdDate',
  LAST_MODIFIED_DATE: 'lastModifiedDate',
}

const STATUS = {
  DEACTIVATED: 'Deactivated',
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
}

/**
 * TableCsvExport
 * @param items
 * @param columns , should have at least a property "label"
 * Description: This functions takes two arguments , and turns the available data to csv format.
 */

export const TableCsvExport = (items: any[], columns: any[]) => {
  // Handle mutation
  const copiedItems = [...items]

  // Transform csv row item by add quotes around the string so that comma inside them is not treated as separator
  const separator = ','
  const transformRowItem = (rowItems: any[]) => {
    return rowItems.map((item) => `"${item.label}"`).join(separator)
  }

  // Getting value from object only for selected columns
  const preparedArray: any[] = []
  copiedItems.forEach((el) => {
    const singleEl: any[] = []
    const copiedEl = { ...el }
    columns.forEach((singleSelectedColumn) => {
      // Authorities fields
      if (singleSelectedColumn.fieldName === FIELDS.AUTHORITIES) {
        copiedEl[singleSelectedColumn.fieldName] = copiedEl[singleSelectedColumn.fieldName]?.join(';')
      }

      // Created Date
      if (singleSelectedColumn.fieldName === FIELDS.CREATED_DATE) {
        copiedEl[singleSelectedColumn.fieldName] = formatDate(copiedEl[singleSelectedColumn.fieldName])
      }

      // Last Modified Date
      if (singleSelectedColumn.fieldName === FIELDS.LAST_MODIFIED_DATE) {
        copiedEl[singleSelectedColumn.fieldName] = formatDate(copiedEl[singleSelectedColumn.fieldName])
      }

      // uiUserStatus field
      if (singleSelectedColumn.fieldName === FIELDS.STATUS) {
        if (copiedEl[singleSelectedColumn.fieldName] === UserStatus.DEACTIVATED) {
          copiedEl[singleSelectedColumn.fieldName] = STATUS.DEACTIVATED
        } else if (copiedEl[singleSelectedColumn.fieldName] === UserStatus.ACTIVATED) {
          copiedEl[singleSelectedColumn.fieldName] = STATUS.ACTIVE
        } else {
          copiedEl[singleSelectedColumn.fieldName] = STATUS.EXPIRED
        }
      }
      singleEl.push(copiedEl[singleSelectedColumn.fieldName])
    })
    preparedArray.push(singleEl)
  })

  const csvString = [transformRowItem(columns), ...preparedArray].join('\n')

  const csvFile = new Blob([csvString], { type: 'text/csv' })
  const downloadLink = document.createElement('a')
  const filename = `User-List--${formatDate(new Date())}`
  downloadLink.download = `${filename}.csv`
  downloadLink.href = window.URL.createObjectURL(csvFile)
  downloadLink.style.display = 'none'
  document.body.appendChild(downloadLink)
  downloadLink.click()
}
