import React from 'react'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { Column } from 'modules/reTable/reTable.types'
import { QueryObserverResult } from 'react-query'
import PenaltyCalculationTableRow from 'modules/data/penalties/penaltyCalculations/PenaltyCalculationTableRow'
import { PenaltyCalculationResult } from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'

interface PenaltyTableBodyProps {
  items: QueryObserverResult<PenaltyCalculationResult>[]
  columns: Column[]
  showNumCapFraction: boolean
}

const PenaltyCalculationTableBody: React.FC<PenaltyTableBodyProps> = ({ items, columns, showNumCapFraction }) => {
  return (
    <ReTableBody>
      {items.map((result, index) => {
        return (
          <PenaltyCalculationTableRow
            showNumCapFraction={showNumCapFraction}
            item={result}
            key={index}
            columns={columns}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(PenaltyCalculationTableBody)
