import { FormApi } from 'final-form'

import { Asset } from 'modules/asset/store/asset.types'
import React from 'react'
import { Field } from 'react-final-form'
import { t } from 'ttag'
import { AssetFormLabel, FormKey, FormKeyValueGrid, FormValue, TextFieldWithoutLabel } from 'ui/form/assetForm.style'
import BooleanSwitch from 'ui/form/BooleanSwitch'
import { isCHP, isLocationAsset } from 'utils/asset'
import { useCustomMuiStyles } from 'themes/theme-light'
import Flex from 'ui/styles/Flex'
import PopperTooltip from 'ui/PopperTooltip'
import styled from 'styled-components'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

// styled components
const InfoDiv = styled.div`
  position: relative;
  right: 2em;
`
const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 0.3em;
`

// exported component
interface SectionAdminProps {
  form: FormApi<Asset>
}

const SectionAdmin: React.FC<SectionAdminProps> = ({ form }) => {
  const classes = useCustomMuiStyles({ important: true })

  const formState = form.getState().values

  return (
    <>
      <FormKeyValueGrid>
        <FormKey className={classes.adminComponent}>
          <AssetFormLabel>{t`Order number`}</AssetFormLabel>
        </FormKey>
        <FormValue isAdminComponent>
          <Flex direction="row" alignItems="center">
            <Field name="orderNumber">
              {(props) => {
                return <TextFieldWithoutLabel type="text" fullWidth input={props.input} meta={props.meta} />
              }}
            </Field>
            <InfoDiv>
              <PopperTooltip
                popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
                popperContent={
                  <>
                    {t`The order number entered here will be used to assign this asset to an order for billing. Leave blank to use the order number specified at account level (see User Settings).`}
                  </>
                }
              />
            </InfoDiv>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        {isLocationAsset(formState) && !isCHP(formState) && (
          <>
            <FormKey className={classes.adminComponent}>
              <AssetFormLabel>{t`Auto model update`}</AssetFormLabel>
            </FormKey>
            <FormValue isAdminComponent>
              <Flex direction="row" alignItems="center">
                <Field name="autoDeploymentEnabled">
                  {({ input }) => <BooleanSwitch enabled={input.value} onToggle={input.onChange} />}
                </Field>
                <PopperTooltip
                  popperLabel={<TooltipIcon icon="info" size="sm" />}
                  popperContent={<>{t`Automatically activate the best forecast model after each training.`}</>}
                />
              </Flex>
            </FormValue>
          </>
        )}
      </FormKeyValueGrid>
    </>
  )
}

export default React.memo(SectionAdmin)
