import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Highcharts, { XAxisOptions, YAxisOptions } from 'highcharts/highstock'
import { HighchartsStockChart, Series, XAxis, YAxis } from 'react-jsx-highstock'
import useResizeAware from 'react-resize-aware'
import { useDebounce } from 'use-debounce'
import { c, t } from 'ttag'
import { thousandSeparator } from 'utils/dataFormatting'
import styled from 'styled-components'
import { Timezone } from 'fixtures/timezones'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import TooltipOptions from 'modules/workspace/highstock/TooltipOptions'
import TimeOptions from 'modules/workspace/highstock/TimeOptions'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import { seriesSelector } from 'modules/workspace/store/series.state'
import { createTimeSeriesWithNullValues, cropSeries } from 'utils/timeseries'
import { createMeanDaySeries } from 'modules/workspace/chart/meanDay'
import { convertZonedTimeToAnotherZonedTime, getTimezoneOffset, isEndOfDay, isStartOfDay } from 'utils/date'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { AppUnits } from 'utils/units'
import { addDays, addHours, addSeconds, differenceInDays, endOfDay, startOfDay, subDays } from 'date-fns'
import { CHART_BOOST_THRESHOLD_VALUE, getYAxisData, WorkspaceChartWidgetHeight } from 'utils/chart'
import { alignYAxes, CreateYAxis, getUniqueUnitYAxisParams } from 'modules/workspace/chart/axis'
import { removeDuplicates } from 'utils/array'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'

const Content = styled.div`
  position: relative;
  height: ${WorkspaceChartWidgetHeight};
  min-height: ${WorkspaceChartWidgetHeight};
  max-height: ${WorkspaceChartWidgetHeight};
`

export interface QualityMeanDayChartProps {
  loading: boolean
  isHidden: boolean
  userTimezone?: Timezone
  widgetName: string
  showTooltip: boolean
}

const MeanDayChart: React.FC<QualityMeanDayChartProps> = ({
  // selectedAssets,
  loading,
  isHidden, // this is used in memo equality callback
  userTimezone = 'UTC',
  widgetName,
  showTooltip,
}) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const [resizeListener, { width, height }] = useResizeAware()
  const [chart, setChart] = useState<HighchartsStockChart | undefined>()

  const chartSelectedDateRangeInUTC = useWorkspaceChartSelectedDateRange()

  const chartSelectedDateRangeInUserTimezone = useMemo(() => {
    return [
      convertZonedTimeToAnotherZonedTime(chartSelectedDateRangeInUTC[0], 'UTC', userTimezone),
      convertZonedTimeToAnotherZonedTime(addSeconds(new Date(chartSelectedDateRangeInUTC[1]), 1), 'UTC', userTimezone),
    ]
  }, [chartSelectedDateRangeInUTC, userTimezone])

  // chartSelectedDateRangeInUserTimezone has the zoom in range, we need complete days therefore
  // here we extract the full days from the chart selected date range because we consider full days to calculate mean day values
  const fullDaysInUserTimezone = useMemo(() => {
    const startDateInUserTimezone = isStartOfDay(chartSelectedDateRangeInUserTimezone[0])
      ? chartSelectedDateRangeInUserTimezone[0]
      : startOfDay(addDays(chartSelectedDateRangeInUserTimezone[0], 1))

    const endDateInUserTimezone = isEndOfDay(chartSelectedDateRangeInUserTimezone[1])
      ? chartSelectedDateRangeInUserTimezone[1]
      : endOfDay(subDays(chartSelectedDateRangeInUserTimezone[1], 1))

    return [startDateInUserTimezone, endDateInUserTimezone]
  }, [chartSelectedDateRangeInUserTimezone])

  // Need to add one second to endDate while calculating the difference because endOfDay from date-fns returns ... 23:59 so one second is missing
  const noOfDays = useMemo(
    () => differenceInDays(addSeconds(fullDaysInUserTimezone[1], 1), fullDaysInUserTimezone[0]),
    [fullDaysInUserTimezone],
  )

  // Convert the user timezoned dates to local dates because highcharts expect dates in local timezone
  const chartRangeToBePlotted = useMemo(() => {
    const startDateInUserTimezone = fullDaysInUserTimezone[0]
    const endDateInUserTimezone = fullDaysInUserTimezone[1]

    const startDateInLocal = convertZonedTimeToAnotherZonedTime(startDateInUserTimezone, userTimezone, localTimezone)
    const endDateInLocal = convertZonedTimeToAnotherZonedTime(endDateInUserTimezone, userTimezone, localTimezone)

    return [startDateInLocal, endDateInLocal]
  }, [noOfDays, userTimezone, localTimezone, fullDaysInUserTimezone])

  const [debouncedChartRange] = useDebounce(chartRangeToBePlotted, 100)

  // actual data that is plotted
  const series = useSelector(seriesSelector)

  const seriesCropped = useMemo(() => {
    return cropSeries(series, debouncedChartRange)
  }, [series, debouncedChartRange])

  const seriesSet = useMemo(() => {
    return createMeanDaySeries({ seriesSet: seriesCropped, initialDate: debouncedChartRange[0] })
  }, [seriesCropped, debouncedChartRange, noOfDays])

  const hasData = useMemo(
    () => seriesSet.length > 0 && seriesSet.some((series) => Array.isArray(series.data) && series.data.length > 0),
    [seriesSet],
  )

  const handleChartMounted = useCallback((c) => {
    setChart(c)
  }, [])

  // axis definitions

  const xAxis: XAxisOptions = useMemo(() => {
    return {
      crosshair: true,
      labels: {
        rotation: -45,
        formatter: function () {
          // this.value is local date because highcharts expects date to be local
          // and convert to the passed timezone internally when plotting
          // Since we explicitly label the x-axis the we need to minus the offset
          // const timezoneOffset = getTimezoneOffset(userTimezone, new Date(this.value))
          // console.log({ timezoneOffset, date: new Date(this.value), test: new Date(this.value).getTimezoneOffset() })
          const offset = getTimezoneOffset(userTimezone, new Date(this.value)) * 60 * 1000
          return Highcharts.dateFormat('%H:%M', this.value - offset)
        },
      },
      minRange: 1000 * 60 * 60 * 2, // 2 hours
      minTickInterval: 1000 * 60 * 15, // 15 minutes
      ordinal: false,
      tickPixelInterval: 50,
      type: 'datetime',
    }
  }, [userTimezone])

  const emptyChartYAxis: YAxisOptions = useMemo(() => {
    return {
      alignTicks: true,
      crosshair: true,
      gridLineWidth: 1,
      labels: {
        align: 'right',
        formatter: function () {
          return thousandSeparator(this.value)
        },
        reserveSpace: true,
      },
      lineWidth: 1,
      minPadding: 0,
      opposite: false,
      showEmpty: false,
      // tickPositions:[0, 5000, 10000],
      title: {
        // offset: 48,
        text: `${t`Power`} (${AppUnits.KILO_WATT})`,
      },
    }
  }, [])

  const yAxis: YAxisOptions[] = useMemo(() => {
    const createYAxisParams: CreateYAxis[] = []
    const units = removeDuplicates(seriesSet?.filter((fs) => fs.custom?.unit).map((ms) => ms.custom?.unit))

    const seriesForEachUnit = (units || []).reduce((prevVal, currentVal) => {
      const currentUnitSeries = seriesSet?.filter((fs) => fs?.custom?.unit === currentVal).map((ms) => ms?.data)
      return {
        ...prevVal,
        [currentVal]: currentUnitSeries.reduce((a, b) => a.concat(b), []),
      }
    }, {})

    const yAxisPartialDataForEachUnit = {}
    for (const [key, value] of Object.entries(seriesForEachUnit)) {
      yAxisPartialDataForEachUnit[key] = getYAxisData(value)
    }

    seriesSet.forEach((series) => {
      const paramData = series.custom?.paramData
      const unit = series.custom?.unit
      if (unit) {
        createYAxisParams.push({
          paramData,
          unit,
          yAxisPartialData: yAxisPartialDataForEachUnit[unit],
          hasData: series?.data.length > 0,
        })
      }
    })

    const yAxes = getUniqueUnitYAxisParams(createYAxisParams)
    const transformedYAxes = alignYAxes(yAxes)

    return transformedYAxes
  }, [seriesSet])

  // chart and plot options

  const initialChartOptions = useMemo(
    () => ({
      height: '100%',
    }),
    [],
  )

  const chartOptions = useMemo(
    () => ({
      zoomType: undefined,
      // height: AdvancedChartHeight,
    }),
    [],
  )

  const plotOptions = useMemo(() => {
    return {
      area: {
        animation: false,
        lineWidth: 2,
        marker: { enabled: false },
        shadow: false,
      },
      series: {
        animation: {
          duration: 100,
        },
        boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
        connectNulls: false,
        states: {
          inactive: {
            enabled: false, // do not fade out other series if one is hovered
          },
        },
      },
    }
  }, [])

  // handle size changes
  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }
  }, [chart, width, height, showTooltip])

  const emptySeries = createTimeSeriesWithNullValues([startOfDay(new Date()), addHours(endOfDay(new Date()), 1)])

  return (
    <Content>
      {resizeListener}
      <HighchartsStockChart chart={initialChartOptions} plotOptions={plotOptions} callback={handleChartMounted}>
        <GenericOptions
          title={widgetName}
          message={
            !loading && !hasData
              ? c('Workbench:Quality')
                  .t`Diurnal profile can only be shown if there is data within the selected time range.`
              : !loading && hasData && noOfDays < 2
              ? c('Workbench:Quality').t`Please select at least 2 days for calculating the values.`
              : ''
          }
          showTooltip={showTooltip}
        />
        <ChartOptions options={chartOptions} />
        <LoadingOptions isLoading={loading} />
        <TimeOptions seriesStartDate={chartSelectedDateRangeInUTC[0]} timezone={userTimezone} />

        {showTooltip && <TooltipOptions timezone={userTimezone} displayOnlyTime={true} />}

        <XAxis {...xAxis}>
          <XAxis.Title>{xAxis.title?.text}</XAxis.Title>
        </XAxis>

        {hasData && noOfDays >= 2 ? (
          yAxis.map((y) => {
            return (
              <YAxis key={y.id} {...y}>
                <YAxis.Title>{y.title?.text}</YAxis.Title>
                {seriesSet
                  .filter((series) => series.yAxis === y.id)
                  .map((series) => {
                    const resultId = series?.custom?.result?.data?.id || series?.custom?.result?.error?.id

                    const seriesIdentifier = resultId?.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)
                      ? resultId
                      : series.name
                    return <Series key={seriesIdentifier} id={seriesIdentifier} {...series} />
                  })}
              </YAxis>
            )
          })
        ) : (
          // For empty chart
          <YAxis {...emptyChartYAxis}>
            <YAxis.Title>{emptyChartYAxis?.title?.text}</YAxis.Title>
            {emptySeries.map((series) => (
              <Series key={series.name} id={series.name} {...series} />
            ))}
          </YAxis>
        )}
      </HighchartsStockChart>
    </Content>
  )
}

const areEqual = (prevProps: QualityMeanDayChartProps, nextProps: QualityMeanDayChartProps) => {
  // do not render if we are showing asset details
  return nextProps.isHidden || prevProps === nextProps
}

// Chart.whyDidYouRender = true
export default React.memo(MeanDayChart, areEqual)
