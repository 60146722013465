import React, { useCallback, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { c, t } from 'ttag'
import FixedWidthTextContainer from '../../../ui/styles/FixedWidthTextContainer'
import FileUploadZone, { FileUploadHandler } from '../../../ui/FileUploadZone'
import { FormRow } from './UserSettings'
import styled from 'styled-components'
import { KEY_USER_COMPANY_LOGO, useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { FILE_SIZE, formatBytes } from 'utils/file'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

export interface CompanyLogo {
  imgSrc: string
  fileName: string
}

interface FileNameContainerProps {
  error: number
}
const FileNameContainer = styled(Box)<FileNameContainerProps>`
  color: ${(props) => (props.error ? 'red' : 'inherit')};
`

interface SectionProfilePicUploadProps {
  // onHandleFileSelect: (validFiles: any, errorFiles: any) => void
  fileName: string
  errorFile: any
  onSetCompanyLogoData: any
}

const SectionUploadCompanyLogo: React.FC<SectionProfilePicUploadProps> = ({
  fileName,
  errorFile,
  onSetCompanyLogoData,
}) => {
  const userCompanyLogoResult = useUserSetting<CompanyLogo>(KEY_USER_COMPANY_LOGO)

  const { mutate: mutateUserCompanyLogo } = useUserSettingSaveMutation<CompanyLogo>(KEY_USER_COMPANY_LOGO)

  const maxFileSize = useMemo(() => formatBytes(FILE_SIZE.COMPANY_LOGO_MAX), [])

  const handleFileSelect: FileUploadHandler = useCallback(({ validFiles, invalidFiles }) => {
    if (validFiles.length) {
      const reader = new FileReader()
      reader.readAsDataURL(validFiles[0].file)
      reader.onload = function () {
        onSetCompanyLogoData({
          file: validFiles[0].file,
          base64ImgSrc: reader.result,
        })
      }
    }
    if (invalidFiles.length) {
      onSetCompanyLogoData({
        file: invalidFiles?.length > 1 ? undefined : invalidFiles[0].file,
        errorMsg: t`Please select a file with any of the following formats .png, .jpeg and .jpg. Maximum file size is ${maxFileSize}.`,
      })
    }
  }, [])

  const handleDeleteLogoData = useCallback(() => {
    onSetCompanyLogoData(null)
    mutateUserCompanyLogo({ imgSrc: '', fileName: '' })
  }, [])

  return (
    <FormRow>
      <Box mb={0.5} display="flex">
        {userCompanyLogoResult?.data?.imgSrc ? (
          <div>{c('User Settings').t`Update company logo:`}</div>
        ) : (
          <div>{c('User Settings').t`Add company logo:`}</div>
        )}
        <FileNameContainer error={errorFile ? 1 : 0} ml={0.5}>
          <FixedWidthTextContainer text={fileName || userCompanyLogoResult?.data?.fileName} width="19em">
            {fileName || userCompanyLogoResult?.data?.fileName}
          </FixedWidthTextContainer>
        </FileNameContainer>
        {(fileName || userCompanyLogoResult?.data?.fileName) && (
          <FontAwesomeActionIcon onClick={handleDeleteLogoData} icon="trash-alt" />
        )}
      </Box>
      <Box>
        <FileUploadZone
          accept=".jpg, .jpeg, .png"
          buttonText={t`Select image`}
          buttonIcon={'file-image'}
          maxSize={FILE_SIZE.COMPANY_LOGO_MAX}
          compact
          onFileSelect={handleFileSelect}
          multiple={false}
          label={t`Drag and drop image here, or`}
          showHelpIcon={false}
          ignoreReadOnlyRole={true}
        />
      </Box>
    </FormRow>
  )
}

export default React.memo(SectionUploadCompanyLogo)
