import React, { useEffect, useState, createContext, useMemo } from 'react'
import BulkMdcHeader from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingHeader/BulkMdcHeader'
import BulkMdcBody from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingBody/BulkMdcBody'

import styled from 'styled-components'
import AssetPortfolio from 'modules/asset/portfolio/AssetPortfolio'
import { sideNavWidth } from 'themes/theme-light'
import { useDispatch, useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { SAVE_WORKSPACE_DRAFT_REQUEST } from 'modules/workspace/store/workspace.types'
import { QUERY_KEY_BULK_METER_DATA_CLEANSING } from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.api'
import { useQueryClient } from 'react-query'
import { BulkMeterDataCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { isSolarAsset, isWindAsset, useUniqueAllAssets, useUniqueSelectedAssets } from 'utils/asset'
import { hasPermissionForPvBulkMeterDataCleansing, hasPermissionForWindBulkMeterDataCleansing } from 'utils/user'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const Container = styled.div`
  height: 100%;
`

const AreaWorkspace = styled.div`
  position: absolute;
  margin-left: ${sideNavWidth};
  width: calc(100vw - ${sideNavWidth});
  background-color: #fff;
  overflow: hidden;
  height: 100%;
  padding: 1.2em 1.2em 0 1.2em;
`

export const FilterSettingsContext = React.createContext({
  triggerFilterSettings: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTriggerFilterSettings: (b: BulkMeterDataCleansingFilterSettings) => {},
  refresh: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setRefresh: (b: any) => {},
})

const BulkMdc: React.FC = () => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const selectedAssets = useUniqueSelectedAssets()
  const allAssets = useUniqueAllAssets()
  const user = useSelector(getUserResultSelector)

  // This part handles triggering of filter settings
  const [triggerFilterSettings, setTriggerFilterSettings] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const value = { triggerFilterSettings, setTriggerFilterSettings, refresh, setRefresh }

  const [linkToDefault, setLinkToDefault] = useState(false)
  const [assetsSelectedInWorkbench, setAssetsSelectedInWorkbench] = useState<string[]>(assetSelection)

  // we need to set this timeout to avoid conflicts when we close the view
  // because here we are passing the initial asset selection that has been made in workbench
  useEffect(() => {
    return () => {
      const draft = {
        asset: {
          selection: assetsSelectedInWorkbench,
        },
      }

      setTimeout(() => {
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }, 500)
    }
  }, [])

  useEffect(() => {
    return () => {
      queryClient.removeQueries(QUERY_KEY_BULK_METER_DATA_CLEANSING)
    }
  }, [])

  return (
    <Container>
      <AssetPortfolio />
      <AreaWorkspace>
        <FilterSettingsContext.Provider value={value}>
          <BulkMdcHeader
            linkToDefault={linkToDefault}
            handleLinkToDefaultChange={() => setLinkToDefault((prevState) => !prevState)}
            user={user}
          />
          {user && selectedAssets.length > 0 && (
            <BulkMdcBody
              allAssets={allAssets}
              assetsForBulkMdc={selectedAssets}
              linkToDefault={linkToDefault}
              user={user}
            />
          )}
        </FilterSettingsContext.Provider>
      </AreaWorkspace>
    </Container>
  )
}

export default BulkMdc
