import { plausibilityReducer } from 'modules/asset/plausability/redux_store/plausability.store'
import {
  ACTIVATE_MODELS_REQUEST,
  activateModelsReducer,
  activateModelsSaga,
} from 'modules/asset/store/activateModels.state'
import { e3MetaForecastReducer } from 'modules/asset/store/e3MetaForecast.state'
import {
  GET_METERDATA_SUMMARIES_REQUEST,
  getMeterdataSummariesReducer,
  getMeterdataSummariesSaga,
} from 'modules/asset/store/getMeterdataSummaries.state'
import {
  SET_AUTODEPLOY_REQUEST,
  setAutoDeployReducer,
  setAutoDeploySaga,
} from 'modules/asset/store/setAutoDeploy.state'
import {
  START_TRAINING_REQUEST,
  startTrainingReducer,
  startTrainingSaga,
} from 'modules/asset/store/startTraining.state'
import { combineReducers } from 'redux'
import { takeEvery } from 'redux-saga/effects'
import {
  START_TRAINING_REQUEST_FOR_SOLAR,
  startTrainingForSolarReducer,
  startTrainingForSolarSaga,
} from 'modules/asset/store/startTrainingForSolar.state'

export const assetReducer = combineReducers({
  getMeterdataSummaries: getMeterdataSummariesReducer,
  e3MetaForecast: e3MetaForecastReducer,
  plausibility: plausibilityReducer,
  setAutoDeploy: setAutoDeployReducer,
  startTraining: startTrainingReducer,
  activateModels: activateModelsReducer,
  startTrainingForSolar: startTrainingForSolarReducer,
})

export function* assetSaga() {
  yield takeEvery(GET_METERDATA_SUMMARIES_REQUEST, getMeterdataSummariesSaga)
  yield takeEvery(SET_AUTODEPLOY_REQUEST, setAutoDeploySaga)
  yield takeEvery(START_TRAINING_REQUEST, startTrainingSaga)
  yield takeEvery(ACTIVATE_MODELS_REQUEST, activateModelsSaga)
  yield takeEvery(START_TRAINING_REQUEST_FOR_SOLAR, startTrainingForSolarSaga)
}
