import React, { useMemo } from 'react'
import { c } from 'ttag'
import { Button } from '@material-ui/core'
import { DeepPartial } from 'ts-essentials'
import {
  MetaForecastWidgetType,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { useSavedForecastWeights } from 'utils/hooks/useSavedForecastWeights'
import { useDataStreams } from 'utils/dataStream'
import { useSortedSelectedDataStreams } from 'utils/hooks/datastreams/useSortedSelectedDataStreams'
import { useDispatch, useSelector } from 'react-redux'
import { Asset } from 'modules/asset/store/asset.types'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'
import { getActiveModels, isMetaForecastModel } from 'utils/forecastModel'
import PopperTooltip from 'ui/PopperTooltip'
import { workspaceDraftSelectedModelsSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { ForecastModelCategoryEnum } from 'modules/forecastModels/forecastModels.types'

interface RetrieveModelWeightsActionProps {
  activeWidgetType: MetaForecastWidgetType
  modelWeights: Record<string, any>
  handleSetLoadSavedWeights: () => void
  invalidNumberOfSelection: boolean
  selectedAssets: Asset[]
  selectedModelIds: string[]
}

const RetrieveModelWeightsAction: React.FC<RetrieveModelWeightsActionProps> = ({
  activeWidgetType,
  modelWeights,
  handleSetLoadSavedWeights,
  invalidNumberOfSelection,
  selectedAssets,
}) => {
  const dispatch = useDispatch()
  const savedE3Weights = useSavedForecastWeights(activeWidgetType) || {}
  const forecastModelsQueryResult = useForecastModelsByAsset(selectedAssets[0])
  const selectedModels = useSelector(workspaceDraftSelectedModelsSelector)

  const { E3_THIRD_PARTY_FORECAST, E3_WEATHER_TRACK } = TimeSeriesSubType

  // This is used only for editing the forecast models
  const assetForecastModels = useMemo(() => forecastModelsQueryResult.data || [], [forecastModelsQueryResult?.data])
  const activeModels = useMemo(() => {
    if (assetForecastModels.length) {
      return getActiveModels(assetForecastModels)
    } else return []
  }, [assetForecastModels])

  const modelToUse = useMemo(() => {
    if (selectedAssets.length) {
      const assetActiveE3Model = activeModels.find((am) => am.category === ForecastModelCategoryEnum.E3)
      const assetActiveMetaModel = activeModels.find((am) => am.category === ForecastModelCategoryEnum.META)
      return activeWidgetType === TimeSeriesSubType.E3_WEATHER_TRACK ? assetActiveE3Model : assetActiveMetaModel
    } else if (selectedModels.length) {
      return selectedModels[0]
    } else return null
  }, [selectedModels, activeModels, selectedAssets, activeWidgetType])

  const isThirdPartyActiveModel = isMetaForecastModel(modelToUse)
  const isE3ActiveModel = !isMetaForecastModel(modelToUse)

  const dataStreams = useDataStreams()
  const selectedDataStreams = useSortedSelectedDataStreams()

  const handleLoadSavedWeights = () => {
    const selectedDataStreamIds = selectedDataStreams.map((dataStream) => dataStream.id)
    const weightIds = Object.keys(savedE3Weights) // Weight Ids are dataStream Ids

    const dataStreamsMissingFromSelection = weightIds
      .filter((weightId) => !selectedDataStreamIds.includes(weightId))
      .map((missingDataStreamId) => dataStreams.find((dataStream) => dataStream.id === missingDataStreamId))

    if (dataStreamsMissingFromSelection.length) {
      const filteredDataStreamsMissingFromSelection = dataStreamsMissingFromSelection.filter(
        (dsmfs) => dsmfs != undefined,
      )
      // console.log({ dataStreamsMissingFromSelection, filteredDataStreamsMissingFromSelection })

      const allPossibleColorIndexes = Array.from(Array(dataStreams.length).keys())
      const oldColors = selectedDataStreams
        .filter((s) => typeof s.color === 'number')
        .map((s) => s.color)
        .sort((a, b) => a - b)

      const transformedDataStreams = filteredDataStreamsMissingFromSelection.map((dataStream) => {
        const color = allPossibleColorIndexes.find((c) => !oldColors.includes(c))
        oldColors.push(color)

        return {
          ...dataStream,
          label: dataStream.name,
          name: dataStream.id,
          color,
        }
      })
      const newSelection = selectedDataStreams.concat(transformedDataStreams)
      const draft: DeepPartial<WorkspaceConfig> = {
        data: {
          selection: newSelection,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    }

    // Set all weights to 0 for the currently displayed weights
    const modifiedCurrentlyDisplayedWeights = { ...modelWeights }
    Object.keys(modelWeights).forEach((key) => {
      modifiedCurrentlyDisplayedWeights[key] = 0
    })
    // Set the new values from saved Weights
    const newWeights = { ...modifiedCurrentlyDisplayedWeights, ...savedE3Weights }
    const e3WidgetDraft: DeepPartial<WorkspaceConfig> = {
      e3WidgetSettings: {
        activeWidget: activeWidgetType,
        [activeWidgetType === E3_WEATHER_TRACK ? 'weatherModelSliders' : 'thirdPartySliders']: newWeights,
      },
    }

    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft: e3WidgetDraft })

    handleSetLoadSavedWeights()
  }

  const modelName = activeWidgetType === E3_WEATHER_TRACK ? c('e³').t`e³` : c('e³').t`Meta forecast`

  const noActiveModelMsg = c('e³')
    .jt`As there is no active ${modelName} model to this asset, weights cannot be retrieved.  Please set your own weights and save the model.`

  let toolTipMsg = ''
  if (invalidNumberOfSelection) {
    toolTipMsg = c('e³').jt`To create a ${modelName} model, please select a single asset.`
  } else {
    if (modelToUse) {
      // modelToUse is the active asset model when assets are selected
      if (selectedAssets.length) {
        if (activeWidgetType === E3_THIRD_PARTY_FORECAST && !isThirdPartyActiveModel) {
          toolTipMsg = noActiveModelMsg
        }
        if (activeWidgetType === E3_WEATHER_TRACK && !isE3ActiveModel) {
          toolTipMsg = noActiveModelMsg
        }
      } else {
        const activeWidgetAndModelCategoryDoNotMatch =
          (activeWidgetType === E3_THIRD_PARTY_FORECAST && modelToUse.category !== ForecastModelCategoryEnum.META) ||
          (activeWidgetType === E3_WEATHER_TRACK && modelToUse.category !== ForecastModelCategoryEnum.E3)

        if (activeWidgetAndModelCategoryDoNotMatch) {
          const selectedModelType =
            modelToUse.category === ForecastModelCategoryEnum.E3 ? c('e³').t`e³` : c('e³').t`Meta forecast`

          toolTipMsg = c('e³')
            .jt`${modelName} model weights cannot be retrieved for the selected ${selectedModelType} model. Please set your own weights and save the model.`
        }
      }
    } else {
      toolTipMsg = noActiveModelMsg
    }
  }

  // console.log({
  //   activeModel,
  //   isE3ActiveModel,
  //   isThirdPartyActiveModel,
  //   activeWidgetType,
  //   selectedModels,
  //   selectedAssets,
  // })

  return (
    <PopperTooltip
      popperLabel={
        <Button
          style={{ marginLeft: '8px' }}
          onClick={handleLoadSavedWeights}
          color="default"
          variant="contained"
          size="small"
          disabled={Boolean(toolTipMsg)}
        >{c('e3').t`Retrieve model weights`}</Button>
      }
      popperContent={toolTipMsg}
    />
  )
}

export default RetrieveModelWeightsAction
