import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useDispatch, useSelector } from 'react-redux'
import { getMeta } from '../selector/metaSelector'
import { getSelected, getSelectedPoint, getSelectedPoints, getSelectedRow } from '../selector/selectedSelector'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import { getBbox } from '../selector/bboxSelector'
import { getPoints } from '../selector/pointsSelector'
import { Checkbox, Table, TableBody, TextField } from '@material-ui/core'
import { SET_SELECTED_POINT, SET_SELECTED_POINTS } from '../actions/selectedActions'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { ADD_POINTS, SET_POINTS } from '../actions/pointsActions'
import { toast } from 'react-toastify'
import { checkPointsShape, getMetaInfo, importFiles } from 'modules/gips/helper/FetchCalls'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    paddingLeft: '0',
    paddingRight: '0.5em',
  },
  body: {
    fontSize: 13,
    padding: '0.1em',
  },
}))(TableCell)

const FirstStyledTableCell = withStyles(() => ({
  body: {
    paddingLeft: '0.1em',
    width: '1em',
  },
  head: {
    paddingLeft: '0.1em',
    width: '1em',
  },
}))(StyledTableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '&.Mui-selected': {
      backgroundColor: '#66a126',
    },
  },
}))(TableRow)

const StyledCheckBox = withStyles(() => ({
  root: {
    color: 'green',
    height: 8,
    width: 8,
  },
}))(Checkbox)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ padding: '1px', height: 'calc(100% - 3px)', paddingTop: '2.5px' }}>
        {children}
      </Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  tr: {
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  panel: {
    height: 'calc(100% - 48px)',
    maxHeight: 'calc(100% - 48px)',
    overflow: 'auto',
  },
}))

export default function ShapeOptions() {
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const meta = useSelector(getMeta)
  const selectedShapes = useSelector(getSelected)
  const selectedRow = useSelector(getSelectedRow)
  const selectedPoint = useSelector(getSelectedPoint)
  const selectedPoints = useSelector(getSelectedPoints)
  const bounds = useSelector(getBbox)
  const pointList = useSelector(getPoints)
  const [value, setValue] = React.useState(0)
  const [shape, setShape] = React.useState(null)
  const [displayed, setDisplayed] = React.useState([])
  const [initRun, setInitRun] = React.useState(false)
  const [searchResult, setResult] = React.useState(null)
  const [pointCount, setPointCount] = React.useState(0)
  const [latFieldVariable, setLat] = React.useState(1337)
  const [lngFieldVariable, setLng] = React.useState(1337)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const getSelectedShape = () => {
    // if (selectedRow !== "-1") setValue(0);
    setShape(meta.find((meta) => meta.uuid === selectedRow))
  }

  const handleClick = (event, key) => {
    if (event.target.tagName === 'TD') {
      let point = '-1'
      if (selectedPoint !== key) point = key
      dispatch({ type: SET_SELECTED_POINT, point })
    } else if (event.target.tagName === 'INPUT') {
      let newSelectedPoints
      if (selectedPoints.includes(key)) {
        newSelectedPoints = selectedPoints.filter((selected) => selected !== key)
      } else {
        newSelectedPoints = [...selectedPoints, key]
      }
      newSelectedPoints.sort()
      dispatch({ type: SET_SELECTED_POINTS, points: newSelectedPoints })
    }
  }

  const generateObject = (key, objValue) => {
    return (
      <TreeItem
        key={key}
        nodeId={key}
        label={
          <div>
            <b>{key}: </b>
          </div>
        }
      >
        <table>
          <tbody>
            {Object.keys(objValue)
              .filter((key) => typeof objValue[key] !== 'object')
              .map((key) => generateItem(key, objValue[key]))}
          </tbody>
        </table>
      </TreeItem>
    )
  }

  const generateRow = (key) => {
    let lat = displayed[key][0]
    let lng = displayed[key][1]
    // console.log("Adding: " + key + " -> [" + lat + ", " + lng + "]");
    return (
      <StyledTableRow key={key} onClick={(event) => handleClick(event, key)} selected={selectedPoint === key}>
        <FirstStyledTableCell width="5%">
          <StyledCheckBox checked={selectedPoints.includes(key)} />
        </FirstStyledTableCell>
        <StyledTableCell width="45%">{key}</StyledTableCell>
        <StyledTableCell width="25%">{lat}</StyledTableCell>
        <StyledTableCell width="25%">{lng}</StyledTableCell>
      </StyledTableRow>
    )
  }

  const displayPoints = (searchInput) => {
    // console.log("Event: {" + searchInput + "}");

    if (searchInput === '') {
      setDisplayed(pointList)
      setInitRun(false)
    } else {
      setInitRun(true)
      let keyList = Object.keys(pointList).filter((key) => key.includes(searchInput.toLowerCase()))
      let filteredPointList = []
      keyList.forEach((key) => {
        filteredPointList[key] = pointList[key]
      })
      setDisplayed(filteredPointList.sort().reverse())
    }
  }

  const generateItem = (key, objValue) => {
    return (
      <tr key={key} className={classes.tr}>
        <td>
          <b>{key}:</b>
        </td>
        <td style={{ width: '100%' }}>{objValue}</td>
      </tr>
    )
  }

  const generateSearchRequest = () => {
    let request = ''
    request += '{ ' + '"uuids":' + '['
    for (let i = 0; i < selectedShapes.length; i++) {
      request += '"' + selectedShapes[i] + '"'
      if (i + 1 !== selectedShapes.length) request += ','
    }
    request += '],' + '"points":' + '{'
    for (let i = 0; i < selectedPoints.length; i++) {
      let id = selectedPoints[i]
      request += '"' + id + '" : {' + '"latitude":' + pointList[id][0] + ',' + '"longitude":' + pointList[id][1] + '}'
      if (i + 1 !== selectedPoints.length) request += ','
    }
    request += '}}'
    return request
  }

  const generateSinglePoint = () => {
    let rBody = '{' + '"uuids":' + '['
    for (let i = 0; i < selectedShapes.length; i++) {
      rBody += '"' + selectedShapes[i] + '"'
      if (i + 1 !== selectedShapes.length) rBody += ','
    }
    rBody +=
      '],' +
      '"points":' +
      '{' +
      '"' +
      generateID() +
      '" : {' +
      '"latitude":' +
      latFieldVariable +
      ',' +
      '"longitude":' +
      lngFieldVariable +
      '}' +
      '}}'
    return rBody
  }

  const generateString = (key) => {
    let result = ''
    // console.log(searchResult[key]);
    for (let i = 0; i < searchResult[key].length; i++) {
      result += searchResult[key][i]
      if (i + 1 !== searchResult[key].length) result += ', '
    }
    return result
  }

  const requestCheck = async () => {
    let rBody
    let success = true
    if (selectedPoints.length === 0) {
      if (checkInput(latFieldVariable, lngFieldVariable)) rBody = generateSinglePoint()
      else return
    } else rBody = generateSearchRequest()
    toast.info('Search started.')
    await checkPointsShape(rBody)
      .then((response) => {
        if (response.ok && response.status !== 204) {
          setPointCount(selectedPoints.length)
          return response.json()
        } else throw new Error(response.status + ' - ' + response.statusText)
      })
      .then((item) => setResult(item))
      .catch(() => {
        success = false
        // console.log('Error while fetching data. ' + error)
        toast.error('Error while fetching data. Look console for more info.')
      })
    if (success) toast.success('Search done!')
  }

  const generateResultRow = (key) => {
    let labelString = (
      <div>
        <b>No Match:</b>
      </div>
    )
    let nodeIdString = '404'
    let rowString = generateString(key)
    if (key !== '404') {
      let shape = meta.filter((meta) => meta.uuid === key)[0]
      nodeIdString = key
      let stringLabel = shape['shapeInfo'].name
      if (stringLabel === '') stringLabel = shape['shapeInfo'].label
      labelString = (
        <div>
          <b>{stringLabel}:</b>
        </div>
      )
    }
    return (
      <TreeItem
        key={nodeIdString}
        id={nodeIdString}
        nodeId={nodeIdString}
        label={labelString}
        // style={{height:"100%"}}
      >
        <div>
          <b>{rowString}</b>
        </div>
      </TreeItem>
    )
  }

  const selectAllPoints = () => {
    let allPoints = []
    if (Object.keys(displayed).length !== selectedPoints.length) allPoints = Object.keys(displayed)
    dispatch({ type: SET_SELECTED_POINTS, points: allPoints })
  }

  const clearList = () => {
    if (pointList.length !== 0) {
      dispatch({ type: SET_SELECTED_POINT, point: '-1' })
      dispatch({ type: SET_SELECTED_POINTS, points: [] })
      dispatch({ type: SET_POINTS, points: [] })
      setResult(null)
      setPointCount(0)
    }
  }

  const savePointList = () => {
    let json = JSON.stringify(pointList)
    let blob = new Blob([json], { type: 'text/plain' })
    let url = URL.createObjectURL(blob)
    let link = document.createElement('a')
    link.download = Date.now() + '_Pointlist.json'
    link.href = url
    link.click()
  }

  const onChangeHandler = (event) => {
    let msg = false
    // console.log('target:' + event.target + ' id:' + event.target.id)
    if (event.target.id === 'button-shapes') {
      uploadShapes(event.target.files)
        .then(() => console.log('Shape Import Started!'))
        .then(() => (msg = true))
    } else if (event.target.id === 'button-points') {
      uploadPoints(event.target.files[0])
    }
    if (msg) toast.info('Shape Import started!')
  }

  const uploadShapes = async (shapes) => {
    if (shapes) {
      const files = Array.from(shapes)
      const formData = new FormData()

      files.forEach((file) => {
        formData.append(file.name.split('.')[1], file)
      })

      await importFiles(formData)
      updateList()
      toast.success('Import finished!')
    }
  }

  const checkInputFile = (coords) => {
    if (!coords) {
      // File is empty
      return false
    }

    if (Object.keys(coords).length === 0) {
      // No keys found, wrong format?
      return false
    }

    Object.keys(coords).forEach((key) => {
      let lat = coords[key][0]
      let lng = coords[key][1]

      if (!lat || !lng) {
        // First or second entry of array in field is not available.
        return false
      }
      if (isNaN(lat) || isNaN(lng)) {
        // One of the array Elements is not a valid number.
        return false
      }
    })
    return true
  }

  useEffect(getSelectedShape, [selectedRow])
  useEffect(() => {
    if (!initRun) {
      displayPoints('')
    }
  })

  const generateID = () => {
    return 'point_' + parseFloat(latFieldVariable).toFixed(6) + '_' + parseFloat(lngFieldVariable).toFixed(6)
  }

  const generateFileId = (lat, lng) => {
    return 'point_' + parseFloat(lat).toFixed(6) + '_' + parseFloat(lng).toFixed(6)
  }

  const addSinglePoint = () => {
    if (pointList[generateID()]) {
      // ID is auto generated with point_ and both coordinates rounded to 6 digits after comma.
      toast.error('ID already exists! For more info, check console.')
    } else {
      addPoint(generateID(), latFieldVariable, lngFieldVariable)
      setLng(1337)
      setLat(1337)
    }
  }

  const uploadPoints = (points) => {
    const handleFileLoad = (event) => {
      // console.log(event.target.result);
      let coords = JSON.parse(event.target.result)
      if (Array.isArray(coords)) {
        let coordsNew = {}
        for (let i = 0; i < coords.length; i++) {
          coordsNew = { ...coordsNew, [generateFileId(coords[i][0], coords[i][1])]: [coords[i][0], coords[i][1]] }
          // coordsNew.push(id:[coords[i][0], coords[i][1]]);
        }
        // console.log(coordsNew);
        coords = coordsNew
      }

      if (checkInputFile(coords)) {
        // console.log(Array.isArray(coords));
        if (pointList.length === 0) {
          dispatch({ type: SET_POINTS, points: coords })
        } else {
          let keys = Object.keys(coords)
          for (let i = 0; i < keys.length; i++) addPoint(keys[i], coords[keys[i]][0], coords[keys[i]][1], true)
        }
        toast.success('Points imported!')
      } else toast.error('Wrong Input Format. See console for more Info.')
    }

    if (points) {
      const reader = new FileReader()
      reader.onload = handleFileLoad
      reader.readAsText(points, 'utf-8')
    }
  }

  const checkInput = (lat, lng) => {
    let uuid = 'gen_' + lat + '_' + lng
    if (pointList[uuid]) {
      // Name duplicate
      toast.error('Name already exists!')
      return false
    }
    if (isNaN(lat) || lat === undefined) {
      toast.error('Lat is not a number!')
      return false
    }
    if (lng === undefined || isNaN(lng)) {
      toast.error('Lng is not a number!')
      return false
    }
    if (lat === 1337 || lng === 1337) {
      // No coordinates in LAT/LNG boxes. Both or one is empty because 1337 is empty box value.
      // alert.error("Please fill both the lat and lng boxes.");
      toast.error('Please fill both the lat and lng boxes.')
      return false
    }
    if (lat > 90 || lat < -90 || lng > 180 || lng < -180) {
      // Out of bounds.
      // alert.error("Lat/Lng out of bounds!");
      toast.error('Lat/Lng out of bounds!')
      return false
    }
    Object.keys(pointList).forEach((point) => {
      if (point[0] === lat && point[1] === lng) {
        // Point with this lat and lng already exists!
        toast.error('Point already exists!')
        return false
      }
    })
    return true
  }

  const addPoint = (uuid, pLat, pLng, mute = false) => {
    if (!mute) {
      // console.log('uuid:' + uuid + ' lat:' + pLat + ' lng:' + pLng + '')
    }
    if (checkInput(pLat, pLng)) {
      // Input ok!
      dispatch({
        type: ADD_POINTS,
        uuid: uuid,
        lat: parseFloat(pLat),
        lng: parseFloat(pLng),
      })
    }
  }

  const displayCheck = (coordString) => {
    if (coordString === 'lat')
      if (latFieldVariable === 1337) return ''
      else return latFieldVariable
    else if (coordString === 'lng')
      if (lngFieldVariable === 1337) return ''
      else return lngFieldVariable
  }

  const updateList = () => {
    getMetaInfo(dispatch, null).then()
  }

  return (
    <div className="shape-options">
      <AppBar position="static" style={{ backgroundColor: '#000000', height: 48 }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab fullWidth label="Details" color="#fff" {...a11yProps(0)} style={{ width: '240px' }} />
          <Tab fullWidth label="Points" color="#fff" {...a11yProps(1)} style={{ width: '240px' }} />
        </Tabs>
      </AppBar>
      <TabPanel className={classes.panel} value={value} index={0} dir={theme.direction}>
        <Paper style={{ height: '100%', overflow: 'auto' }}>
          {shape ? (
            <TreeView defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
              <TreeItem
                nodeId="info"
                label={
                  <div>
                    <b>Info: </b>
                  </div>
                }
              >
                <table>
                  <tbody>
                    <tr key="uuid" className={classes.tr}>
                      <td>
                        <b>uuid:</b>
                      </td>
                      <td style={{ width: '100%' }}>{shape.uuid}</td>
                    </tr>
                    <tr key="version" className={classes.tr}>
                      <td>
                        <b>version:</b>
                      </td>
                      <td style={{ width: '100%' }}>{shape.version}</td>
                    </tr>
                  </tbody>
                </table>
                <TreeItem
                  key="bounds"
                  nodeId="bounds"
                  label={
                    <div>
                      <b>Bounds: </b>
                    </div>
                  }
                >
                  <table>
                    <tbody>
                      <tr key="boundsCenter" className={classes.tr}>
                        <td>
                          <b>center:</b>
                        </td>
                        <td>
                          {bounds[selectedRow] && bounds[selectedRow].center && bounds[selectedRow].center.lat}
                          {', '}
                          {bounds[selectedRow] && bounds[selectedRow].center && bounds[selectedRow].center.lng}
                        </td>
                      </tr>
                      <tr key="boundsRadius" className={classes.tr}>
                        <td>
                          <b>radius:</b>
                        </td>
                        <td>{bounds[selectedRow] && bounds[selectedRow].center && bounds[selectedRow].radius}</td>
                      </tr>
                    </tbody>
                  </table>
                </TreeItem>
                <TreeItem
                  key="shapeInfo"
                  nodeId="shapeInfo"
                  label={
                    <div>
                      <b>ShapeInfo: </b>
                    </div>
                  }
                >
                  <table>
                    <tbody>
                      {Object.keys(shape['shapeInfo'])
                        .filter((key) => typeof shape['shapeInfo'][key] !== 'object')
                        .map((key) => generateItem(key, shape['shapeInfo'][key]))}
                    </tbody>
                  </table>
                  {Object.keys(shape['shapeInfo'])
                    .filter((key) => typeof shape['shapeInfo'][key] === 'object')
                    .map((key) => generateObject(key, shape['shapeInfo'][key]))}
                </TreeItem>
              </TreeItem>
            </TreeView>
          ) : (
            <Paper align="center">
              <b>Please select a Shape.</b>
            </Paper>
          )}
        </Paper>
      </TabPanel>
      <TabPanel className={classes.panel} value={value} index={1} dir={theme.direction} style={{ overflow: 'hidden' }}>
        <Paper className="point-list" style={{ height: '100%', maxHeight: '100%' }}>
          <div style={{ height: '45%', maxHeight: '45%', padding: 0 }}>
            <div style={{ width: '100%', justify: 'center', height: '162px' }}>
              <Grid container spacing={1} alignItems="center" style={{ height: '60px' }}>
                <Grid item xs>
                  <TextField
                    id="lat"
                    label="LAT"
                    variant="outlined"
                    margin="dense"
                    onChange={(event) => setLat(event.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') addSinglePoint()
                    }}
                    value={displayCheck('lat')}
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="lng"
                    label="LNG"
                    variant="outlined"
                    margin="dense"
                    onChange={(event) => setLng(event.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') addSinglePoint()
                    }}
                    value={displayCheck('lng')}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button align="right" variant="contained" size="small" onClick={addSinglePoint}>
                    Add
                  </Button>
                </Grid>
              </Grid>
              <TextField
                id="search"
                label="Filter Point List"
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={(event) => displayPoints(event.target.value)}
              />
              <Grid
                item
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '48px',
                  color: '#fff',
                  alignItems: 'center',
                  direction: 'column',
                  backgroundColor: '#000000',
                }}
              >
                <div style={{ paddingLeft: '5%', width: '45%' }}>ID</div>
                <div style={{ width: '25%' }}>Lat</div>
                <div style={{ paddingRight: '15px', width: '25%' }}>Lng</div>
              </Grid>
            </div>
            <Paper style={{ overflow: 'auto', width: '100%', height: 'calc(100% - 162px)' }}>
              {pointList.length !== 0 ? (
                <Table style={{ width: '100%' }}>
                  <TableBody>
                    {displayed &&
                      Object.keys(displayed)
                        .sort((a, b) => a - b)
                        .reverse()
                        .map((key) => generateRow(key))}
                  </TableBody>
                </Table>
              ) : (
                <Paper
                  style={{
                    display: 'grid',
                    height: '100%',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <b> No Points available.</b>
                  <b>Import some via Import or add some manually. </b>
                </Paper>
              )}
            </Paper>
          </div>
          <div style={{ height: '20%', padding: 0, paddingTop: '20px' }}>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: 0, marginRight: 0, width: '100%', height: '100%' }}
              justify="center"
            >
              <Grid key={1} container item justify="center">
                <Grid container item style={{ width: '50%', height: '22.75px' }} justify="center">
                  <Button variant="contained" size="small" style={{ width: '150px' }} onClick={requestCheck}>
                    Check Points
                  </Button>
                </Grid>
                <Grid container item style={{ width: '50%', height: '22.75px' }} justify="center">
                  <Button variant="contained" size="small" style={{ width: '150px' }} onClick={selectAllPoints}>
                    Select All
                  </Button>
                </Grid>
              </Grid>
              <Grid key={2} container item justify="center">
                <Grid container item style={{ width: '50%', height: '22.75px' }} justify="center">
                  <Button variant="contained" size="small" style={{ width: '150px' }} onClick={savePointList}>
                    Save As File
                  </Button>
                </Grid>
                <Grid container item style={{ width: '50%', height: '22.75px' }} justify="center">
                  <Button variant="contained" size="small" style={{ width: '150px' }} onClick={clearList}>
                    Clear List
                  </Button>
                </Grid>
              </Grid>
              <Grid key={3} container item justify="center">
                <Grid container item style={{ width: '50%', height: '22.75px' }} justify="center">
                  <input
                    accept="file"
                    id="button-points"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onChangeHandler}
                  />
                  <label htmlFor="button-points">
                    <Button variant="contained" component="span" size="small" style={{ width: '150px' }}>
                      Import Points
                    </Button>
                  </label>
                </Grid>
                <Grid container item style={{ width: '50%', height: '22.75px' }} justify="center">
                  <input
                    accept="file"
                    id="button-shapes"
                    multiple
                    type="file"
                    style={{ display: 'none' }}
                    onChange={onChangeHandler}
                  />
                  <label htmlFor="button-shapes">
                    <Button variant="contained" component="span" size="small" style={{ width: '150px' }}>
                      Import Shapes
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ height: '33%', maxHeight: '33%', padding: 0 }}>
            <Grid
              container
              style={{
                width: '100%',
                height: '24px',
                color: '#fff',
                backgroundColor: '#000000',
                paddingLeft: '1em',
              }}
            >
              <Grid item style={{ width: '75%' }}>
                <b>Search Result:</b>
              </Grid>
              <Grid item align="right" style={{ width: '25%' }}>
                <b>{pointCount} point(s)</b>
              </Grid>
            </Grid>
            <div
              style={{
                height: 'calc(100% - 24px)',
                maxHeight: 'calc(100% - 24px)',
                overflow: 'auto',
              }}
            >
              {searchResult && (
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  // style={{height: "calc(100% + 48px)"}}
                >
                  {Object.keys(searchResult).map((key) => generateResultRow(key))}
                </TreeView>
              )}
            </div>
          </div>
        </Paper>
      </TabPanel>
    </div>
  )
}
