import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import styled from 'styled-components'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css'
import SearchInput from 'ui/form/SearchInput'
import { SelectAsListItem } from 'ui/form/SelectAsList' // theme css file
import { DateRange } from 'react-date-range'
import { ChartDataRangeType } from 'modules/workspace/store/workspace.types'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover/Popover'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import { c } from 'ttag'
import Flex from 'ui/styles/Flex'
import { theme } from 'themes/theme-light'
import { addYears, endOfDay, endOfYear, startOfYear, subYears } from 'date-fns'
import Spacer from 'ui/Spacer'

const Container = styled.div`
  & .rdrDateDisplayItem {
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #ddd;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);

    input {
      height: 2.4em;
      line-height: 2.4em;
      color: black;
    }
  }

  & .rdrMonthAndYearWrapper {
    height: 30px;
  }

  & .rdrDateDisplayItemActive input {
    color: black;
  }

  & .rdrMonth {
    padding: 0 0.833em;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background: ${theme.palette.primary.dark};
  }

  display: flex;
  flex-direction: row;
  overflow: auto;
`

const StaticRanges = styled.div`
  width: 150px;
`

const StaticRangeList = styled.div`
  height: 360px;
  overflow-y: auto;
  border-right: 1px solid #eef2f6;
`

const StaticRangeSearchContainer = styled.div`
  background: #eef2f6;
  padding: 0.75em;
`

const TimeRangeInfo = styled(Flex)`
  color: ${theme.palette.secondary.main};
  margin-left: 0.2em;
  width: 38em;
`

export interface DateTimeRange {
  startDate: Date
  endDate: Date
  key?: string // This is needed for the calendar
  rangeType?: ChartDataRangeType
}

interface DateTimeRangeSelectorProps {
  rangeItems: SelectAsListItem[]
  selectedDateTimeRange: DateTimeRange
  onRangeChange: (rangeType: ChartDataRangeType, customRange?: DateTimeRange) => void
  label: string
  iconOnly?: boolean
  info?: string
  onClose: () => void
}

const DateTimeRangeSelector: React.FC<DateTimeRangeSelectorProps> = ({
  rangeItems,
  selectedDateTimeRange,
  onRangeChange,
  label,
  iconOnly = false,
  info,
  onClose,
}) => {
  const [dateTimeRange, setDateTimeRange] = useState<DateTimeRange[]>([{ ...selectedDateTimeRange, key: 'selection' }])
  const [staticRangeQuery, setStaticRangeQuery] = useState('')

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'dateRange-popover' : undefined
  const startOfThisYear = startOfYear(new Date())
  const endOfThisYear = endOfYear(new Date())

  // Triggered only when selecting custom dates, always pass CHART_DATA_RANGE_CUSTOM
  const handleChange = useCallback(
    (item) => {
      const data: DateTimeRange = {
        ...item.selection,
        endDate: endOfDay(item.selection.endDate),
      }

      onRangeChange(ChartDataRangeType.CHART_DATA_RANGE_CUSTOM, data)
    },
    [onRangeChange],
  )

  // const handleTimeChange = useCallback(
  //   (time, type) => {
  //     const data = [...dateTimeRange]
  //     if (type === TIME_TYPE.START) {
  //       data[0].startDate = time
  //     }
  //     if (type === TIME_TYPE.END) {
  //       data[0].endDate = time
  //     }
  //     onRangeChange(ChartDataRangeType.CHART_DATA_RANGE_CUSTOM, data[0])
  //   },
  //   [dateTimeRange, onRangeChange],
  // )

  const handleSearch = useCallback((event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement // not sure why need to cast again
    const query = target.value || ''
    setStaticRangeQuery(query)
  }, [])

  const handleClearSearch = useCallback(() => {
    setStaticRangeQuery('')
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
    onClose()
  }, [onClose])

  const handleStaticRangeClick = useCallback(
    (range: SelectAsListItem) => {
      onRangeChange(range.key as ChartDataRangeType)
      handleClose()
    },
    [onRangeChange],
  )

  useEffect(() => {
    setDateTimeRange([{ ...selectedDateTimeRange, key: 'selection' }])
  }, [JSON.stringify(selectedDateTimeRange)])

  return (
    <div>
      <Button aria-describedby={id} aria-haspopup="true" size="small" color="primary" onClick={handleClick}>
        <FontAwesomeIcon icon="calendar-alt" size="1x" />
        {!iconOnly && (
          <>
            {label && <Box ml={1}>{label}</Box>}
            <Box ml={1}>
              <FontAwesomeIcon icon="caret-down" />
            </Box>
          </>
        )}
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Container>
          <StaticRanges>
            <StaticRangeSearchContainer>
              <SearchInput onChange={handleSearch} onClearSearch={handleClearSearch} />
            </StaticRangeSearchContainer>
            <StaticRangeList>
              <List component="nav" aria-label="static ranges">
                {rangeItems
                  .filter((range) => range.label.toLowerCase().includes(staticRangeQuery.toLowerCase()))
                  .map((filteredValue) => (
                    <ListItem
                      selected={selectedDateTimeRange.rangeType === filteredValue.key}
                      key={filteredValue.key}
                      onClick={() => handleStaticRangeClick(filteredValue)}
                      button
                    >
                      {filteredValue.label}
                    </ListItem>
                  ))}
              </List>
            </StaticRangeList>
          </StaticRanges>

          <Flex direction="column" justifyContent="space-between">
            <DateRange
              onChange={(item) => handleChange(item)}
              months={2}
              ranges={dateTimeRange}
              dateDisplayFormat={'yyyy-MM-dd'}
              rangeColors={[theme.palette.primary.main]}
              direction="horizontal"
              editableDateInputs={true}
              minDate={subYears(startOfThisYear, 10)}
              maxDate={addYears(endOfThisYear, 3)}
            />

            <div>
              {/*<Flex>*/}
              {/*  <Flex justifyContent="center" flexBasis="50%">*/}
              {/*    <TimeInput*/}
              {/*      onTimeChange={handleTimeChange}*/}
              {/*      dateTime={dateTimeRange[0].startDate}*/}
              {/*      timeType={TIME_TYPE.START}*/}
              {/*    />*/}
              {/*  </Flex>*/}
              {/*  <Flex justifyContent="center" flexBasis="50%">*/}
              {/*    <TimeInput*/}
              {/*      onTimeChange={handleTimeChange}*/}
              {/*      dateTime={dateTimeRange[0].endDate}*/}
              {/*      timeType={TIME_TYPE.END}*/}
              {/*    />*/}
              {/*  </Flex>*/}
              {/*</Flex>*/}
              <Flex justifyContent="space-between" alignItems="center">
                {info && (
                  <TimeRangeInfo alignItems="center">
                    <FontAwesomeIcon size="xs" icon="info" /> <Box ml={0.5}>{info}</Box>
                  </TimeRangeInfo>
                )}
                <Spacer />
                <Box mb={1} mr={1}>
                  <Button onClick={handleClose} color="primary" size="small">{c(`Datepicker`).t`Hide`}</Button>
                </Box>
              </Flex>
            </div>
          </Flex>
        </Container>
      </Popover>
    </div>
  )
}

export default React.memo(DateTimeRangeSelector)
