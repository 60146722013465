import React from 'react'

import { Box } from '@material-ui/core'
import FileManagerTable from 'modules/data/fileManager/FileManagerTable'

import styled from 'styled-components'
import { t } from 'ttag'

import LayoutTitle from 'ui/LayoutTitle'
import Spacer from 'ui/Spacer'
import { useSelector } from 'react-redux'
import { getMeterdataFilesLoadingSelector } from 'modules/data/fileManager/redux_store/state/getMeterdataFiles.state'
import InlineLoading from 'ui/InlineLoading'

const TableWrapper = styled(Box)`
  width: ${(props) => props.width};
  height: calc(100% - 5.2em);
`

const FileManager: React.FC = () => {
  const loading = useSelector(getMeterdataFilesLoadingSelector)
  return (
    <>
      <Box ml={1}>
        <LayoutTitle icon="folder">
          {t`File manager`}
          {loading && <InlineLoading />}
          <Spacer />
        </LayoutTitle>
      </Box>
      <TableWrapper ml={1} mr={1}>
        <FileManagerTable />
      </TableWrapper>
    </>
  )
}

export default React.memo(FileManager)
