import { SET_COORDS, SET_MULTIPOINT, SET_MULTIPOLYGON } from '../actions/coordsActions'

const initialState = {}

export default function coordsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COORDS:
      return {
        ...state,
        [action.uuid]: action.coords,
      }
    case SET_MULTIPOLYGON:
      return {
        ...state,
        [action.uuid]: {
          ...state.coords,
          multipolygon: action.multipolygon,
        },
      }
    case SET_MULTIPOINT:
      return {
        ...state,
        [action.uuid]: {
          ...state.coords,
          multipoint: action.multipoint,
        },
      }
    default:
      return state
  }
}
