import React, { useEffect, useMemo } from 'react'
import { Chart, HighchartsStockChart, PlotBand, Series, useChart, XAxis, YAxis } from 'react-jsx-highstock'
import { isAfter, isBefore } from 'date-fns'
import * as Highcharts from 'highcharts'
import { useDebounce } from 'use-debounce'
import { createDayPlotBandsForTimingPreview } from 'modules/workspace/chart/days'

type GenericOptionsProps = Highcharts.Options

const GenericOptions: React.FC<GenericOptionsProps> = (options: Highcharts.Options) => {
  const chart = useChart()

  useEffect(() => {
    if (!chart) return
    chart.update(options)
  }, [chart, options])

  return null
}

interface SectionTimingPreviewChartProps {
  chartHeight: number
  chartWidth: number
  yAxisMaxValue: any
  yAxisTitle: string
  seriesSet: any
}

const SectionTimingPreviewChart: React.FC<SectionTimingPreviewChartProps> = ({
  chartHeight,
  chartWidth,
  yAxisMaxValue,
  yAxisTitle,
  seriesSet,
}) => {
  // TODO make this individual for this chart only
  Highcharts.SVGRenderer.prototype.symbols.lineLimit = function (x, y, w, h) {
    return ['M', x + w / 2, y, 'L', x + w / 2, y + h, 'z']
  }
  const plotOptions = useMemo(() => {
    return {
      series: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
            // opacity: 1,
          },
        },
      },
      line: {
        marker: {
          //enabled: false,
          symbol: 'lineLimit',
          lineWidth: 1,
          lineColor: undefined,
          states: { hover: { enabled: false } },
        },
      },
    }
  }, [])

  const dayBandsInAMonth = useMemo(() => {
    return createDayPlotBandsForTimingPreview(new Date(0))
  }, [])

  const maxEndDateInSeriesSet = useMemo(() => {
    return seriesSet.reduce((maxEndDate, currentSeries) => {
      const currEndDate = currentSeries?.data[1][0]
      return isAfter(currEndDate, maxEndDate) ? currEndDate : maxEndDate
    }, new Date(0))
  }, [seriesSet])

  const dayBandsToBeDisplayed = useMemo(() => {
    const maxDateInSeries = new Date(maxEndDateInSeriesSet)
    const bands = dayBandsInAMonth.filter((band) => {
      const bandStartDate = new Date(band.from)
      return isBefore(bandStartDate, maxDateInSeries)
    })
    return bands
  }, [dayBandsInAMonth, maxEndDateInSeriesSet])

  const xAxis = useMemo(() => {
    return {
      min: 0,
      tickInterval: 1000 * 60 * 60 * 1,
      tickPixelInterval: 400,
      labels: { format: '{value:%H:%M}' },
      // labels: {
      //   formatter: function () {
      //     // console.log('this value =', this.value, new Date(this.value), convertLocalDateToUTC(this.value))
      //     const t = convertLocalDateToUTC(this.value)
      //     return 'D' + (getDayOfYear(t) - 1) + '-' + format(t, 'HH:mm')
      //   },
      // },
    }
  }, [])

  const yAxis = useMemo(() => {
    return {
      // lineColor: 'black',
      // lineWidth: 2,
      tickInterval: 60 * 60 * 1000,
      labels: { format: '{value:%H:%M}' },
      gridLineWidth: 0,
      type: 'datetime',
      min: 0,
      max: yAxisMaxValue,
    }
  }, [yAxisMaxValue])

  const genericOptions: Highcharts.Options = useMemo(() => {
    return {
      credits: { enabled: false },
      exporting: { enabled: false },
      legend: { enabled: false, align: 'center', verticalAlign: 'bottom', width: 0, y: 0 },
      title: undefined,
      tooltip: { enabled: false },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 300,
            },
          },
        ],
      },
    }
  }, [])

  const chartOptions = useMemo(() => {
    return {
      // animation: false,
      width: chartWidth > 800 ? 800 : chartWidth,
      height: chartHeight,
    }
  }, [chartHeight, chartWidth])

  // do not update chart immediately because it will slow down the whole form
  const [debouncedGenericOptions] = useDebounce(genericOptions, 100)

  // this useEffect is used to fix a bug (PR-7858) in firefox
  useEffect(() => {
    const chartHtml = document.getElementsByClassName('chart')

    if (chartHtml && navigator.userAgent.indexOf('Firefox') != -1) {
      chartHtml[0].style.display = 'none'

      setTimeout(() => {
        chartHtml[0].style.display = 'block'
      }, 100)
    }
  }, [seriesSet])

  return (
    <div style={{ height: chartHeight }}>
      <HighchartsStockChart plotOptions={plotOptions}>
        <Chart {...chartOptions} />
        <GenericOptions {...debouncedGenericOptions} />
        <XAxis {...xAxis}>
          {(dayBandsToBeDisplayed || []).map((plotBand) => (
            <PlotBand key={`plotband-${plotBand.label?.text}-${plotBand.from}-${plotBand.to}`} {...plotBand} />
          ))}
        </XAxis>

        <YAxis {...yAxis}>
          <YAxis.Title>{yAxisTitle}</YAxis.Title>
          {(seriesSet || []).map((series, index) => (
            <Series key={index} id={index} {...series} />
          ))}
        </YAxis>
      </HighchartsStockChart>
    </div>
  )
}

export default React.memo(SectionTimingPreviewChart)
