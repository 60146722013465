import React, { useCallback, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { Location } from 'history'
import LoadingButton from 'ui/form/LoadingButton'
import styled from 'styled-components'

interface BasicProps {
  $customSize: boolean
}

const DialogContainer = styled(Dialog)<BasicProps>`
  & .MuiDialog-paperWidthSm {
    width: ${(props) => props.$customSize && '60vh'};
    max-width: ${(props) => props.$customSize && '60vh'};
  }
`

const DialogBody = styled(DialogContent)<BasicProps>`
  &.MuiDialogContent-root {
    max-height: ${(props) => props.$customSize && '71vh'};
  }
`

interface ConfirmationDialogProps {
  heading: string
  text: string
  headingColor?: string
  confirmAction?: string
  cancelAction?: string
  onConfirm?: () => void
  onCancel?: () => void
  openDialog?: boolean
  loading?: boolean
  closeDialogOnConfirm?: boolean
  $customSize?: boolean // This prop is used when you have children inside this component , and ofc the size should change. $ sign usage:  styled-components knows not to add it to the rendered DOM element
  children?: React.ReactNode
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  heading,
  headingColor,
  text,
  confirmAction,
  cancelAction,
  onConfirm,
  onCancel,
  openDialog = false,
  loading = false,
  closeDialogOnConfirm = true,
  $customSize = false,
  children,
}) => {
  const [open, setOpen] = React.useState(openDialog)
  const [newLocation, setNewLocation] = useState<Location>({} as Location)

  const handleCancel = useCallback(() => {
    if (onCancel) onCancel()
    setOpen(false)
    setNewLocation({} as Location)
  }, [onCancel])

  const handleConfirm = useCallback(() => {
    if (onConfirm) onConfirm()
    if (closeDialogOnConfirm && !children) {
      setOpen(false)
    }
  }, [onConfirm, newLocation, closeDialogOnConfirm])

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  return (
    //  Whole component is visible to the parent component and Dialog is opened based on the conditions
    <>
      {open && (
        <div id="unsaved-changes">
          <DialogContainer
            data-testid="confirmation-dialog"
            id="confirmation-dialog"
            open={open}
            onClose={handleCancel}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            onEscapeKeyDown={() => false}
            onBackdropClick={() => false}
            $customSize={$customSize}
          >
            <DialogTitle disableTypography>
              <Typography color={headingColor ? headingColor : 'primary'} variant="subtitle1">
                {heading}
              </Typography>
            </DialogTitle>
            <DialogBody $customSize={$customSize}>{children ? children : <div>{text}</div>}</DialogBody>
            <DialogActions>
              {cancelAction && onCancel && (
                <Button onClick={handleCancel} color="primary" size="small" autoFocus>
                  {cancelAction}
                </Button>
              )}

              {confirmAction && onConfirm && (
                <LoadingButton
                  loading={loading}
                  onClick={handleConfirm}
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  {confirmAction}
                </LoadingButton>
              )}
            </DialogActions>
          </DialogContainer>
        </div>
      )}
    </>
  )
}

export default React.memo(ConfirmationDialog)
