import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SAVE_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'
import axios from 'axios'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { AppBanner, SessionActions } from 'modules/app/app.types'

const CheckUserDefaults: React.FC = () => {
  const dispatch = useDispatch()
  const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const user = useSelector(getUserResultSelector)

  const handleShowTimezoneBannerRequest = useCallback((showBanner: boolean) => {
    axios.post('api/usersettings/save/showTimezoneBanner', { value: showBanner })
  }, [])

  // Set the browser timezone as default if user timezone is not set
  const setDefaultUserTimezone = useCallback(() => {
    const settings = {
      ...user,
      timezone: browserTimezone,
    }
    dispatch({ type: SAVE_USER_REQUEST, user: settings })
    handleShowTimezoneBannerRequest(true)
  }, [user, handleShowTimezoneBannerRequest])

  useEffect(() => {
    if (user && !user.timezone) {
      setDefaultUserTimezone()
    }
    if (user && user.timezone) {
      axios.get('api/usersettings/showTimezoneBanner').then((res) => {
        if (res.data?.value) {
          dispatch({ type: SessionActions.ADD_APP_BANNER, appBanner: AppBanner.TIMEZONE })
        }
      })
    }
    if (!user) {
      dispatch({ type: SessionActions.REMOVE_APP_BANNER, appBanner: AppBanner.TIMEZONE })
    }
  }, [user, setDefaultUserTimezone])

  return <></>
}

export default React.memo(CheckUserDefaults)
