import React from 'react'
import {
  DELIVERY_TARGET_TYPE_CUSTOMER_FTP,
  DELIVERY_TARGET_TYPE_MAIL,
  DeliveryTarget,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import EnercastIconSmall from 'ui/icons/EnercastIconSmall'
import { Box } from '@material-ui/core'
import { theme } from 'themes/theme-light'

const TargetTypeIcon = styled(FontAwesomeIcon)`
  font-size: 1.9em;
`

const FileUploadIcon = styled(TargetTypeIcon)`
  &.svg-inline--fa.fa-w-12 {
    width: 0.9em;
  }
`

interface DeliveryTargetTypeCellProps {
  deliveryTarget: DeliveryTarget
}

const DeliveryTargetTypeCell: React.FC<DeliveryTargetTypeCellProps> = ({ deliveryTarget }) => {
  switch (deliveryTarget.type) {
    case DELIVERY_TARGET_TYPE_MAIL:
      return <TargetTypeIcon color={theme.palette.primary.main} icon="envelope" />
    case DELIVERY_TARGET_TYPE_CUSTOMER_FTP:
      return <FileUploadIcon color={theme.palette.primary.main} icon="server" />
    default:
      return (
        <Box mt={0.5}>
          <EnercastIconSmall size="15" />
        </Box>
      )
  }
}

export default React.memo(DeliveryTargetTypeCell)
