import { LanguageKey } from 'fixtures/header'
import { Timezone } from 'fixtures/timezones'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { LOGOUT_SUCCESS } from 'modules/auth/redux_store/auth.action.types'

export enum SubscribedProduct {
  'QUALITY_AND_SYSTEM_MONITORING' = '1-1131',
  'MASTER_DATA_CUSTOM' = '1-1114',
  'CALCULATION_OF_REVENUE_SPREAD' = '1-1211',
  // TODO add others as needed
}

export interface User {
  login: string
  email: string
  activated: boolean
  salutation?: string
  firstName?: string
  lastName?: string
  langKey: LanguageKey
  timezone?: Timezone
  authorities: string[]
  subscribedProducts: SubscribedProduct[]
  permissions: any[]
  company?: string
  phoneNumber?: string
  country?: string
  profilePic?: string
  leadSource?: string
  originalUser?: any
}

export interface UserConfig {
  collectionId: string
  configId: string
  value: Record<string, any> | any[]
}

export interface LoginType {
  username: string
  password: string
  rememberMe: boolean
}

export interface LoginAction {
  type: actionTypes.LOGIN_ACTION_TYPE | LOGOUT_SUCCESS
  loginData: LoginType
}

export interface RegistrationType {
  salutation: string
  firstName: string
  lastName: string
  login: string
  password: string
  confirmPassword: string
  email: string
  company?: string
  ackTermsAndConditions: boolean
  langKey: string
  leadSource: string
}

export interface RegistrationAction {
  type: actionTypes.REGISTRATION_ACTION_TYPE
  regData: RegistrationType
}

export interface ResetPasswordType {
  key: string
  newPassword: string
  confirmPassword?: string
}

export interface ResetPasswordActionType {
  type: actionTypes.RESET_PASSWORD_ACTION_TYPE
  resetPasswordData: ResetPasswordType
}

export interface AdminSettings {
  orderNumber?: string
}
