import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import { Checkbox, TextField } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { SET_SELECTED, SET_SELECTED_ROW } from '../actions/selectedActions'
import { getSelected, getSelectedRow } from '../selector/selectedSelector'
import { getMeta, getTypes } from '../selector/metaSelector'
import { getCoords } from '../selector/coordsSelector'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import { getBbox } from '../selector/bboxSelector'
import MuiVirtualizedTable from 'mui-virtualized-table'
import Tooltip from '@material-ui/core/Tooltip'
import { getUrlParams } from '../selector/urlParamsSelector'
import { SET_TYPES } from '../actions/metaActions'
import { getCombinedInfo, getMetaInfo, getShape } from 'modules/gips/helper/FetchCalls'

const StyledCheckBox = withStyles(() => ({
  root: {
    color: 'green',
    height: 8,
    width: 8,
  },
}))(Checkbox)

export default function ResultTable() {
  const dispatch = useDispatch()
  const meta = useSelector(getMeta)
  const selectedShapes = useSelector(getSelected)
  const selectedRow = useSelector(getSelectedRow)
  const bbox = useSelector(getBbox)
  const coords = useSelector(getCoords)
  const [displayed, setDisplayed] = React.useState([])
  const types = useSelector(getTypes)
  const params = useSelector(getUrlParams)

  const getMetadata = () => {
    getMetaInfo(dispatch, params).then()
  }

  const markedRow = (uuid) => {
    let row = '-1'
    if (selectedRow !== uuid) row = uuid
    if (!coords[uuid]) preLoadShape(uuid).then()
    dispatch({ type: SET_SELECTED_ROW, row: row })
  }

  const checkedRow = (uuid) => {
    let newSelected
    if (selectedShapes.includes(uuid)) {
      newSelected = selectedShapes.filter((selected) => selected !== uuid)
    } else {
      if (!coords[uuid]) getShape(dispatch, uuid).then()
      newSelected = [...selectedShapes, uuid]
    }
    dispatch({ type: SET_SELECTED, shapes: newSelected })
  }

  const clickCheck = (what, uuid) => {
    if (what === 'marked') markedRow(uuid)
    else if (what === 'checked') checkedRow(uuid)
    else console.log('ERRROR! what was: ' + what)
  }

  const displayMeta = (event) => {
    // console.log("Event: {" + event.target.value + "}");
    if (event.target.value === '') setDisplayed(meta)
    else setDisplayed(meta.filter((testMeta) => checkInclude(testMeta, event.target.value.toLowerCase())))
  }

  const checkInclude = (meta, val) => {
    return !!(
      meta['shapeInfo'].name.toLowerCase().includes(val) ||
      meta['shapeInfo']['note'].toLowerCase().includes(val) ||
      meta['shapeInfo']['iso3'].toLowerCase().includes(val)
    )
  }

  const getTypesFromMeta = () => {
    let typeList = []
    if (meta) {
      for (let i = 0; i < meta.length; i++) {
        if (!typeList.includes(meta[i]['shapeInfo'].type)) typeList.push(meta[i]['shapeInfo'].type)
      }
      dispatch({ type: SET_TYPES, types: typeList })
      // console.log("typeList:" + typeList);
    }
  }

  async function preLoadShape(uuid) {
    await getShape(dispatch, uuid)
  }

  const checkBbox = () => {
    if (selectedRow !== '-1') {
      if (!bbox[selectedRow]) {
        getCombinedInfo(dispatch, selectedRow).then()
      }
    }
  }

  const generateRows = (shapeType) => {
    let filteredList = displayed.filter((item) => item['shapeInfo'].type === shapeType).sort()
    let data = []

    filteredList.forEach((item) => {
      let iso = item['shapeInfo']['iso3']
      if (iso === '-99') iso = ''

      let thing = {
        id: item.uuid,
        name: item['shapeInfo'].name,
        note: item['shapeInfo']['note'],
        iso: iso,
      }
      data.push(thing)
    })
    return data
  }

  const setPreselected = () => {
    if (params && params['shape']) {
      let preselected = []
      meta.forEach((shape) => {
        if (!preselected.includes(shape.uuid)) preselected.push(shape.uuid)
      })

      if (preselected.length !== 0) {
        dispatch({ type: SET_SELECTED, shapes: preselected })
        if (preselected.length === 1) dispatch({ type: SET_SELECTED_ROW, row: preselected[0] })
        preselected.forEach((uuid) => {
          if (!coords[uuid]) getShape(dispatch, uuid).then()
        })
      }
    }
  }

  useEffect(getMetadata, [])
  useEffect(() => {
    setDisplayed(meta)
  }, [meta])
  useEffect(getTypesFromMeta, [meta])
  useEffect(checkBbox, [selectedRow])
  useEffect(setPreselected, [types])

  function getCell(choice) {
    return function choiceCell(d) {
      return (
        <Tooltip title={d[choice]} placement="top-end">
          <label>{d[choice]}</label>
        </Tooltip>
      )
    }
  }

  function getCheckboxCell() {
    return function checkboxCell(rowData) {
      return (
        <StyledCheckBox
          checked={selectedShapes.includes(rowData.id)}
          onChange={() => clickCheck('checked', rowData.id)}
        />
      )
    }
  }

  return (
    <Paper className="shape-list" style={{ height: '100%' }}>
      <div style={{ padding: '2px' }}>
        <TextField
          id="search"
          label="Search"
          variant="outlined"
          margin="dense"
          fullWidth
          onChange={(event) => displayMeta(event)}
        />
      </div>
      <div
        style={{
          display: 'flex',
          height: '48px',
          paddingLeft: '5px',
          paddingRight: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '48px',
            color: '#fff',
            alignItems: 'center',
            direction: 'column',
            backgroundColor: '#000000',
          }}
        >
          <div style={{ paddingLeft: '30px', width: '300px' }}>Name</div>
          <div style={{ width: '100%' }}>Note</div>
          <div style={{ paddingRight: '15px', width: '50px' }}>Iso</div>
        </div>
      </div>
      <div
        style={{
          height: 'calc(100% - 105px)',
          maxHeight: 'calc(100% - 105px)',
          overflow: 'auto',
        }}
      >
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          style={{ height: '100%' }}
        >
          {types.sort().map((type) => (
            <TreeItem
              key={type}
              nodeId={type}
              label={
                <div>
                  <b>{type}: </b>
                </div>
              }
            >
              <MuiVirtualizedTable
                width={366}
                maxHeight={790}
                data={generateRows(type)}
                rowHeight={26}
                style={{ backgroundColor: 'white' }}
                columns={[
                  {
                    name: 'checkbox',
                    cell: getCheckboxCell(),
                    cellProps: { style: { paddingLeft: '0.1em' } },
                    width: 50,
                  },
                  {
                    name: 'name',
                    header: 'Name',
                    cell: getCell('name'),
                    cellProps: { style: { padding: '0.1em' } },
                    width: 90,
                  },
                  {
                    name: 'note',
                    header: 'Note',
                    cell: getCell('note'),
                    cellProps: { style: { padding: '0.1em' } },
                    width: 169,
                  },
                  {
                    name: 'iso',
                    header: 'ISO',
                    cell: getCell('iso'),
                    cellProps: { style: { padding: '0.1em', size: 'small' } },
                    width: 40,
                  },
                ]}
                isCellSelected={(column, rowData) => {
                  if (rowData.id === selectedRow) return true
                }}
                onCellClick={(column, rowData) => {
                  if (column.name !== 'checkbox') clickCheck('marked', rowData.id)
                }}
                cellProps={(column, rowData) => {
                  // {checkIfSelected(column, rowData)
                  // rowData && rowData.id === selectedRow && rowData.id !== undefined
                  // console.log("rowID:" +  rowData.id + " selectedRow: " + selectedRow);
                  return rowData.id === selectedRow
                    ? { style: { backgroundColor: 'rgba(255,0,0,0.5)', color: 'white' } }
                    : {}
                }}
              />
            </TreeItem>
          ))}
        </TreeView>
      </div>
    </Paper>
  )
}
