import React from 'react'
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getUserMenuItemData, UserMenuItemKeys } from 'utils/user'
import { useCustomMuiStyles } from 'themes/theme-light'

interface UserMenuItemProps {
  itemKey: UserMenuItemKeys
  onMenuItemClick: (key: UserMenuItemKeys) => void
  isAdminComponent?: boolean
}
const UserMenuItem: React.FC<UserMenuItemProps> = ({
  itemKey,
  onMenuItemClick,
  isAdminComponent = false,
  children,
}) => {
  //Style
  const classes = useCustomMuiStyles()

  const handleItemClick = () => {
    onMenuItemClick(itemKey)
  }
  const menuData = getUserMenuItemData(itemKey)
  if (menuData.custom) {
    return <MenuItem className={isAdminComponent ? classes.adminComponent : 'inherit'}>{children}</MenuItem>
  } else {
    return (
      <MenuItem onClick={handleItemClick} className={isAdminComponent ? classes.adminComponent : 'inherit'}>
        {menuData.icon ? (
          <ListItemIcon>
            <FontAwesomeIcon icon={menuData.icon} fixedWidth />
          </ListItemIcon>
        ) : null}

        <ListItemText primary={menuData.label} />
      </MenuItem>
    )
  }
}

export default UserMenuItem
