import React, { useEffect, useMemo, useState } from 'react'
import { chpGenerator } from 'modules/asset/store/asset.types'
import { FormApi } from 'final-form'
import { Field, FormSpy, useField } from 'react-final-form'
import {
  AssetFormLabel,
  DateField,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  NumberFieldWithoutLabel,
  SelectFieldWithoutLabel,
  SubField,
} from 'ui/form/assetForm.style'
import { c, t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { AppUnits } from 'utils/units'
import PopperTooltip from 'ui/PopperTooltip'
import { Box, MenuItem } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import { Select } from 'final-form-material-ui'
import { isCluster } from 'utils/asset'
import { useSelector } from 'react-redux'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { convertZonedTimeToUtc, formatDateShort, getPastYears, isDateValid } from 'utils/date'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'
import { objectToDropdownValuesHandler } from 'utils/array'
import { useChpProfiles } from 'modules/asset/api/assets.api'
import DateTimePickerFinalForm from 'ui/elements/DateTimePickerFinalForm'
import { getSavedLanguage } from 'utils/user'

// Interface
interface ConfigurationCHPProps {
  asset: chpGenerator
  form: FormApi<chpGenerator>
  onFormChangeExternally: () => void
  isNew: boolean
}

// Language
const availableLanguage = {
  en: 'English',
  de: 'German',
}

const ConfigurationCHP: React.FC<ConfigurationCHPProps> = ({ asset, form, isNew }) => {
  const loadProfileInputValue = useField<string>('typeSpecificAttributes.loadProfile').input.value

  // Form State
  const [expressionClassValues, setExpressionClassValues] = useState([])
  const [language, setLanguage] = useState(availableLanguage.de)
  const [namePlateConsumptionFieldError, setNamePlateConsumptionFieldError] = useState(undefined)
  const [periodEnergySumFieldError, setPeriodEnergySumFieldError] = useState(undefined)
  const [formState, setFormState] = useState(asset)

  // Used to get selected language
  const user = useSelector(getUserResultSelector)

  // Get chp profiles
  const chpProfiles = useChpProfiles(language)

  // User Timezone
  const userTimezone = useSelector(getUserTimezoneSelector)

  useEffect(() => {
    if (user) {
      // Get language from user
      const language = getSavedLanguage(user)
      setLanguage(availableLanguage[language])
    }
  }, [user])

  // Get regions
  const regionValues = useMemo(() => {
    if (chpProfiles?.isSuccess) {
      return objectToDropdownValuesHandler(chpProfiles?.data.regions)
    }
  }, [chpProfiles?.isSuccess, JSON.stringify(chpProfiles?.data)])

  // Get load profiles
  const loadProfileValues = useMemo(() => {
    if (chpProfiles?.isSuccess) {
      return objectToDropdownValuesHandler(
        chpProfiles?.data.profiles,
        true,
        'profile_short_description',
        'expression_class',
      )
    }
  }, [chpProfiles?.isSuccess, JSON.stringify(chpProfiles?.data)])

  // Get Class
  const handleLoadProfileChange = (loadProfile: any) => {
    form.mutators.setValue('typeSpecificAttributes.expressionClass', '')
    setExpressionClassValues(loadProfile.other)
  }

  const expressionClassValuesHandler = useMemo(() => {
    return objectToDropdownValuesHandler(expressionClassValues)
  }, [expressionClassValues])

  useEffect(() => {
    if (!isNew) {
      const result = loadProfileValues?.find((obj) => {
        return obj.id === loadProfileInputValue
      })
      if (result) {
        setExpressionClassValues(result.other)
      }
    }
  }, [loadProfileValues, isNew, loadProfileInputValue])

  // Handling timezone popover
  const periodStartDayInUTC = useMemo(
    () =>
      !isCluster(formState) && isDateValid(formState.typeSpecificAttributes.periodStartDay)
        ? convertZonedTimeToUtc(formState.typeSpecificAttributes.periodStartDay, userTimezone)
        : undefined,
    [userTimezone, formState?.typeSpecificAttributes.periodStartDay],
  )
  const periodEndDayInUTC = useMemo(
    () =>
      !isCluster(formState) && isDateValid(formState.typeSpecificAttributes.periodEndDay)
        ? convertZonedTimeToUtc(formState.typeSpecificAttributes.periodEndDay, userTimezone)
        : undefined,
    [userTimezone, formState?.typeSpecificAttributes.periodEndDay],
  )

  const chpNote = useMemo(() => {
    return (
      <PopperTooltip
        popperLabel={
          <Box ml={0.7}>
            <FontAwesomeIcon color={namePlateConsumptionFieldError ? 'red' : theme.palette.primary.main} icon="info" />
          </Box>
        }
        popperContent={
          <div>{c('Asset:Chp')
            .t`If electrical output is greater than 0 and thermal output is greater than 0, the sum of them must be less than fuel use.`}</div>
        }
      />
    )
  }, [namePlateConsumptionFieldError])

  const totalElectricalEnergyProductionNote = useMemo(() => {
    return (
      <PopperTooltip
        popperLabel={
          <Box ml={0.7}>
            <FontAwesomeIcon color={periodEnergySumFieldError ? 'red' : theme.palette.primary.main} icon="info" />
          </Box>
        }
        popperContent={
          <div>{c('Asset:Chp')
            .t`This value must be greater than the multiplication of electrical output by 24 and difference of total electrical energy production period.`}</div>
        }
      />
    )
  }, [periodEnergySumFieldError])

  const classNote = useMemo(() => {
    return (
      <PopperTooltip
        popperLabel={
          <Box ml={0.7}>
            <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
          </Box>
        }
        popperContent={
          <div>{c('Asset:Chp').t`In order to select a Class , firstly you have to select a Load profile.`}</div>
        }
      />
    )
  }, [])

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as chpGenerator)
        }}
      />

      {/*absoluteCapacityHeat or Thermal output*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Thermal output`}: </AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="49%" direction="row">
              <Field name="typeSpecificAttributes.absoluteCapacityHeat">
                {(props) => {
                  return <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />
                }}
              </Field>
              <FieldUnit>
                <Flex direction="row" alignItems="center">
                  {AppUnits.KILO_WATT}
                </Flex>
              </FieldUnit>
            </Flex>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*namePlateConsumption or Fuel use*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Fuel use`}: </AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="49%" direction="row">
              <Field name="typeSpecificAttributes.namePlateConsumption">
                {(props) => {
                  props.meta.touched && props.meta.error
                    ? setNamePlateConsumptionFieldError(props.meta.error)
                    : setNamePlateConsumptionFieldError(undefined)
                  return <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />
                }}
              </Field>
              <FieldUnit>
                <Flex direction="row" alignItems="center">
                  {AppUnits.KILO_WATT}
                  {chpNote}
                </Flex>
              </FieldUnit>
            </Flex>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*periodEnergySum*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Total electrical energy production`}: </AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="49%" direction="row">
              <Field name="typeSpecificAttributes.periodEnergySum">
                {(props) => {
                  props.meta.touched && props.meta.error
                    ? setPeriodEnergySumFieldError(props.meta.error)
                    : setPeriodEnergySumFieldError(undefined)
                  return <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />
                }}
              </Field>
              <FieldUnit>
                <Flex direction="row" alignItems="center">
                  {AppUnits.KILO_WATT_HOUR}
                  {totalElectricalEnergyProductionNote}
                </Flex>
              </FieldUnit>
            </Flex>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*Total electrical energy production period*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Total electrical energy production period`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="50%" alignItems="center">
              <DateField
                name="typeSpecificAttributes.periodStartDay"
                label={t`Start`}
                onlyDate={true}
                minDate={formatDateShort(getPastYears(4))}
                maxDate={formState.typeSpecificAttributes.periodEndDay}
                component={DateTimePickerFinalForm}
              />
              {periodStartDayInUTC &&
                formState?.periodStartDayTimeZone &&
                formState?.periodStartDayTimeZone !== userTimezone && (
                  <DateTimezonePopover
                    dateInUTC={periodStartDayInUTC}
                    originalTimezone={formState?.DateTimePickerFinalForm}
                  />
                )}
            </Flex>

            <SubField flexBasis="50%" alignItems="center">
              <DateField
                name="typeSpecificAttributes.periodEndDay"
                label={t`End`}
                onlyDate={true}
                minDate={formState.typeSpecificAttributes.periodStartDay}
                component={DateTimePickerFinalForm}
              />
              {periodEndDayInUTC &&
                formState?.periodEndtDayTimeZone &&
                formState?.periodEndtDayTimeZone !== userTimezone && (
                  <DateTimezonePopover
                    dateInUTC={periodEndDayInUTC}
                    originalTimezone={formState?.periodEndtDayTimeZone}
                  />
                )}
            </SubField>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*Region*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Region`}: </AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex flexBasis="100%" direction="row">
            <Field name="typeSpecificAttributes.region">
              {(props) => {
                return (
                  <SelectFieldWithoutLabel>
                    <Select input={props.input} meta={props.meta}>
                      {regionValues?.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFieldWithoutLabel>
                )
              }}
            </Field>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*Load Profile*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Load profile`}: </AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex flexBasis="100%" direction="row">
            <Field name="typeSpecificAttributes.loadProfile">
              {(props) => {
                return (
                  <SelectFieldWithoutLabel>
                    <Select input={props.input} meta={props.meta}>
                      {loadProfileValues?.map((el) => (
                        <MenuItem onClick={() => handleLoadProfileChange(el)} key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFieldWithoutLabel>
                )
              }}
            </Field>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*Expression Class*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Class`}: </AssetFormLabel>
        </FormKey>
        <FormValue>
          <Flex flexBasis="100%">
            <Field name="typeSpecificAttributes.expressionClass">
              {(props) => {
                return (
                  <SelectFieldWithoutLabel>
                    <Select input={props.input} meta={props.meta} disabled={expressionClassValues.length === 0}>
                      {expressionClassValuesHandler.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFieldWithoutLabel>
                )
              }}
            </Field>
            {expressionClassValues.length === 0 && (
              <FieldUnit>
                <Flex direction="row" alignItems="center">
                  {classNote}
                </Flex>
              </FieldUnit>
            )}
          </Flex>
        </FormValue>
      </FormKeyValueGrid>
    </>
  )
}

export default React.memo(ConfigurationCHP)
