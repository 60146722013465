import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormApi, Mutator } from 'final-form'

import {
  CreateScheduleInputData,
  SavedScheduleConfig,
  SubmitScheduleSeriesProps,
} from 'modules/workspace/schedule/schedule.types'
import { Form } from 'react-final-form'
import { validateCreateScheduleForm } from 'utils/formValidations'
import ScheduleForm from 'modules/workspace/schedule/ScheduleForm'
import { useDispatch, useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import {
  calendarRangeOptions,
  convertLocalDateToUTC,
  convertUtcToZonedTime,
  convertZonedTimeToAnotherZonedTime,
  convertZonedTimeToUtc,
  DATE_FORMAT_DEFAULT_WITH_SECONDS,
  DATE_FORMAT_INTERNAL_LONGER,
  formatDate,
  formatUTCDate,
  getRangeTypeFromTimePeriod,
  isDateValid,
  isStartOfDay,
} from 'utils/date'
import { subSeconds } from 'date-fns'
import {
  ChartDataRangeType,
  RangeTypes,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { DeepPartial } from 'ts-essentials'
import { createDefaultDateRange, useWorkspaceChartWholeDateRange } from 'utils/workspace'
import {
  saveScheduleSeries,
  useCreateScheduleInput,
  useCreateScheduleInputDataMutation,
  useSaveScheduleMutation,
  useScheduleSeriesChanged,
  useScheduleSeriesChangedMutation,
} from 'modules/workspace/schedule/schedule.api'
import { SimpleRange } from 'utils/timeseries'
import { convertChartSeriesToValuesMap, ScheduleLocalStorageKeys } from 'utils/schedule'
import { useCreateScheduleSeries } from 'utils/hooks/useCreateScheduleSeries'
import { Asset } from 'modules/asset/store/asset.types'
import { useQueryClient } from 'react-query'
import { QUERY_KEY_TIMESERIES } from 'modules/dataStreams/api/timeseries.api'
import { t } from 'ttag'
import UploadScheduleTemplateDialog from 'modules/workspace/schedule/UploadScheduleTemplateDialog'

interface CreateScheduleProps {
  initialScheduleData: CreateScheduleInputData
  savedSchedulesData: CreateScheduleInputData[]
  setNotificationState: any
  revisionTimingInfo: any
}

let formReference: FormApi<CreateScheduleInputData>

const CreateSchedule: React.FC<CreateScheduleProps> = ({
  initialScheduleData,
  savedSchedulesData,
  setNotificationState,
  revisionTimingInfo,
}) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const scheduleInputSeriesQueryResult = useCreateScheduleInput()
  const chartWholeRange = useWorkspaceChartWholeDateRange()

  const scheduleInputSeriesData = scheduleInputSeriesQueryResult?.data
  const userTimezone = useSelector(getUserTimezoneSelector)
  const scheduleSeries = useCreateScheduleSeries()
  const seriesToSubmit = scheduleSeries[0]?.data

  const [disableSubmitButton, setDisableSubmitButton] = useState(true)
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [openUploadTemplateDialog, setOpenUploadTemplateDialog] = useState(false)
  const [downloadSchedule, setDownloadSchedule] = useState(false)

  const mainChartRanges = useMemo(() => calendarRangeOptions(), [])
  const scheduleInputWhenSubmitWithTemplateRef = useRef<CreateScheduleInputData>()

  const { mutate: saveScheduleMutation } = useSaveScheduleMutation()
  const { mutate: saveCreateScheduleInputMutation } = useCreateScheduleInputDataMutation()
  const { mutate: saveScheduleSeriesChangedMutation } = useScheduleSeriesChangedMutation()
  const seriesChangedQueryResult = useScheduleSeriesChanged()
  const seriesChanged = seriesChangedQueryResult?.data?.value || false

  const handleShowNotification = () => {
    setNotificationState((prevState) => {
      return { ...prevState, open: true, message: t`Schedule has been submitted successfully.` }
    })
  }

  //Refresh the series and remove the series from saved schedule from mongodb
  const handleRefreshSeriesAndDeleteSavedSchedule = (savedConfigs: SavedScheduleConfig[]) => {
    // Refresh the timeseries
    queryClient.invalidateQueries(QUERY_KEY_TIMESERIES)

    // Delete the schedule from saved items
    const updatedSchedules = savedSchedulesData?.length ? [...savedSchedulesData] : []
    savedConfigs.forEach((config) => {
      const savedForecastIndex = updatedSchedules.findIndex(
        (smf) =>
          smf.asset?.id === config?.assetId &&
          smf.targetScheduleDataStream?.id === config.targetScheduleDataStreamId &&
          smf.sourceDataStream?.id === config.sourceDataStreamId,
      )
      updatedSchedules.splice(savedForecastIndex, 1)
    })

    saveScheduleMutation(updatedSchedules)
  }

  /**
   * Save the schedule as a draft into the user settings mongoDB
   * @param values
   */
  const handleSaveDraftSchedule = (values: CreateScheduleInputData) => {
    if (values?.start?.date && values?.end?.date) {
      values.start.timezone = userTimezone
      values.end.timezone = userTimezone
    }

    formReference?.reset(values)

    const data = { ...values }

    const savingTime = {
      date: formatDate(convertLocalDateToUTC(new Date()), null, DATE_FORMAT_INTERNAL_LONGER),
      timezone: userTimezone,
    }

    // Convert the dates
    const startDate = new Date(data?.start?.date)
    startDate.setSeconds(0o0, 0o00)

    data.start = {
      date: convertZonedTimeToUtc(startDate, userTimezone),
      timezone: userTimezone,
    }

    const endDate = new Date(data?.end?.date)
    endDate.setSeconds(0o0, 0o00)
    data.end = {
      date: convertZonedTimeToUtc(endDate, userTimezone),
      timezone: userTimezone,
    }

    const seriesChanged = JSON.parse(localStorage.getItem(ScheduleLocalStorageKeys.seriesChanged))
    const localSeries = JSON.parse(localStorage.getItem(ScheduleLocalStorageKeys.outPutSeries))
    setDisableSubmitButton(false)

    const outputSeries = seriesChanged ? localSeries?.data || [] : (scheduleSeries[0]?.data as SimpleRange[])

    // Remove the first timestamp if it is from previous day timestamp when saving in values map
    // This point is added to cover the gap in the dataStream chart
    const seriesForValuesMap = [...outputSeries]
    const firstPointData = seriesForValuesMap[0]
    const firstTimestamp = Array.isArray(firstPointData)
      ? firstPointData[0]
      : firstPointData?.xAxis || firstPointData?.x
    if (isStartOfDay(startDate) && isStartOfDay(convertUtcToZonedTime(new Date(firstTimestamp), userTimezone))) {
      seriesForValuesMap.shift()
    }

    const valuesMap = convertChartSeriesToValuesMap(seriesForValuesMap)
    const dataWithSeries = { ...data, savedSeries: outputSeries, valuesMap, savingTime }

    const updatedSchedules = savedSchedulesData?.length ? [...savedSchedulesData] : []

    const savedForecastIndexes: number[] = updatedSchedules.reduce(function (ind, smf, index) {
      if (
        smf.asset?.id === data?.asset?.id &&
        smf.targetScheduleDataStream?.id === data.targetScheduleDataStream?.id &&
        data.sourceDataStream?.id === smf.sourceDataStream?.id
      ) {
        ind.push(index)
      }
      return ind
    }, [])

    if (savedForecastIndexes.length) {
      // Remove the old schedule if any
      // Sort the indexes in descending order to prevent shifting issues
      const descendingSortedIndexes = savedForecastIndexes.sort((a, b) => b - a)
      descendingSortedIndexes.forEach((savedIndex) => {
        updatedSchedules.splice(savedIndex, 1)
      })
    }

    // Add the new one to the existing schedules
    updatedSchedules.push(dataWithSeries)

    saveScheduleMutation(updatedSchedules)
    // Need to refetch the series because the submit handler will use latest saved data from hook
    saveCreateScheduleInputMutation({ ...data, refetch: true, savedInUserSetting: true })
    saveScheduleSeriesChangedMutation({ value: false })
    localStorage.setItem(ScheduleLocalStorageKeys.outPutSeries, JSON.stringify({ custom: undefined }))
    localStorage.setItem(ScheduleLocalStorageKeys.seriesChanged, 'false')

    const draft: DeepPartial<WorkspaceConfig> = {
      schedule: {
        touchedPoints: [],
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }

  /**
   * Save the schedule series to the timeseries database
   * @param data
   */
  const handleSaveScheduleSeries = (data: CreateScheduleInputData) => {
    saveScheduleSeries(data)
      .then(() => {
        // console.log('response =', response)
      })
      .finally(() => {
        if (data?.asset?.id && data?.sourceDataStream?.id && data?.targetScheduleDataStream?.id) {
          handleRefreshSeriesAndDeleteSavedSchedule([
            {
              assetId: data.asset.id,
              sourceDataStreamId: data.sourceDataStream.id,
              targetScheduleDataStreamId: data.targetScheduleDataStream.id,
            },
          ])
        }
        setSubmitInProgress(false)
        saveCreateScheduleInputMutation({ ...data, useSource: true })
        handleShowNotification()
      })
  }

  /**
   * Handler to decide if user want to submit with or without template
   * @param inputData Schedule Input configuration
   * @param withTemplate Boolean
   */
  const handleSubmitSchedule = ({ scheduleInput: inputData, withTemplate }: SubmitScheduleSeriesProps) => {
    if (!withTemplate) {
      // If its with template submit will be done after uploading the template
      setSubmitInProgress(true)
    }

    const data: CreateScheduleInputData = {
      ...inputData,
      savedSeries: seriesToSubmit,
      asset: inputData?.asset as Asset,
      targetScheduleDataStream: inputData.targetScheduleDataStream,
      detectedInterval: scheduleSeries[0]?.custom?.detectedInterval,
    }

    if (withTemplate) {
      // With Template, user should upload a template and then submit the series
      scheduleInputWhenSubmitWithTemplateRef.current = data
      setOpenUploadTemplateDialog(true)
      setDownloadSchedule(false)
    } else {
      setDownloadSchedule(false)

      const transformedData = { ...data }

      // Convert the dates
      const startDate = new Date(transformedData?.start?.date)
      startDate.setSeconds(0o0, 0o00)

      transformedData.start = {
        date: convertZonedTimeToUtc(startDate, userTimezone),
        timezone: userTimezone,
      }

      const endDate = new Date(transformedData?.end?.date)
      endDate.setSeconds(0o0, 0o00)
      transformedData.end = {
        date: convertZonedTimeToUtc(endDate, userTimezone),
        timezone: userTimezone,
      }

      handleSaveScheduleSeries(transformedData)
    }
  }

  /**
   * Update the main chart time period if the manual forecast input period is not same or subset of main chart
   */
  const handleCheckAndChangeMainChartDateRange = useCallback(
    (currentFormData) => {
      const currentStart = currentFormData?.start?.date
      const currentEnd = currentFormData?.end?.date

      const currentDatesValid = isDateValid(currentStart) && isDateValid(currentEnd)

      if (currentDatesValid && currentStart && currentEnd) {
        const currentStartDateInUTC = convertZonedTimeToAnotherZonedTime(currentStart, userTimezone, 'UTC')
        const currentEndDateInUTC = convertZonedTimeToAnotherZonedTime(currentEnd, userTimezone, 'UTC')

        const rangeType =
          currentFormData?.start?.timezone === currentFormData?.end?.timezone
            ? getRangeTypeFromTimePeriod({
                timePeriod: {
                  start: currentStartDateInUTC,
                  end: subSeconds(currentEndDateInUTC, 1),
                },
                rangeOptions: mainChartRanges,
                rangeType: RangeTypes.MAIN_CHART_RANGE,
                timezone: userTimezone,
              })
            : ChartDataRangeType.CHART_DATA_RANGE_CUSTOM

        const draft: DeepPartial<WorkspaceConfig> = {
          chart: {
            range: createDefaultDateRange(),
            dataRange: {
              rangeType,
              customRange:
                rangeType === ChartDataRangeType.CHART_DATA_RANGE_CUSTOM
                  ? [
                      formatUTCDate(currentStartDateInUTC, DATE_FORMAT_DEFAULT_WITH_SECONDS),
                      formatUTCDate(currentEndDateInUTC, DATE_FORMAT_DEFAULT_WITH_SECONDS),
                    ]
                  : null,
            },
          },
        }

        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
    },
    [userTimezone, chartWholeRange, mainChartRanges],
  )

  // Here we are disabling submit button depending on saved schedule data
  // So when we reopen the schedule feature and the following conditions are met , then we need to enable submit button
  useEffect(() => {
    if (savedSchedulesData && savedSchedulesData.length > 0 && scheduleInputSeriesData) {
      const currentStateIsSaved = savedSchedulesData.find((singleSaveScheduleData) => {
        return (
          scheduleInputSeriesData?.asset?.id === singleSaveScheduleData.asset?.id &&
          scheduleInputSeriesData?.sourceDataStream?.id === singleSaveScheduleData.sourceDataStream?.id &&
          scheduleInputSeriesData?.targetScheduleDataStream?.id ===
            singleSaveScheduleData.targetScheduleDataStream?.id &&
          scheduleInputSeriesData?.start?.date === singleSaveScheduleData.start?.date &&
          scheduleInputSeriesData?.end?.date === singleSaveScheduleData.end?.date
        )
      })
      if (currentStateIsSaved && !seriesChanged) {
        setDisableSubmitButton(false)
      } else {
        setDisableSubmitButton(true)
      }
    }
  }, [savedSchedulesData, seriesChanged, disableSubmitButton, scheduleInputSeriesData])

  // Reset the form when new schedule data is available
  useEffect(() => {
    formReference.reset(initialScheduleData)
  }, [initialScheduleData])

  // Form mutators
  const mutators = useMemo<{
    [key: string]: Mutator<CreateScheduleInputData, Partial<CreateScheduleInputData>>
  }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
      setValues: ([changes], state, { changeValue }) => {
        changes.forEach((change) => {
          changeValue(state, change.field, () => change.value)
        })
      },
    }
  }, [])

  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <ScheduleForm
          form={form}
          onSubmitSchedule={handleSubmitSchedule}
          onFormSubmit={handleSubmit}
          onCheckAndChangeMainChartDateRange={handleCheckAndChangeMainChartDateRange}
          disableSubmitButton={disableSubmitButton}
          onSetDisableSubmitButton={setDisableSubmitButton}
          submitInProgress={submitInProgress}
          revisionTimingInfo={revisionTimingInfo}
          userTimezone={userTimezone}
          initialScheduleData={initialScheduleData}
        />
      )
    },
    [
      disableSubmitButton,
      handleCheckAndChangeMainChartDateRange,
      submitInProgress,
      revisionTimingInfo,
      userTimezone,
      initialScheduleData,
    ],
  )

  const openDialog = openUploadTemplateDialog && scheduleInputWhenSubmitWithTemplateRef.current

  return (
    <>
      {openDialog ? (
        <UploadScheduleTemplateDialog
          scheduleInputData={scheduleInputWhenSubmitWithTemplateRef.current}
          onCloseDialog={setOpenUploadTemplateDialog}
          onSetSubmitInProgress={setSubmitInProgress}
          submitInProgress={submitInProgress}
          onSetDownloadSchedule={setDownloadSchedule}
          downloadSchedule={downloadSchedule}
          userTimezone={userTimezone}
          onRefreshSeriesAndDeleteSavedSchedule={handleRefreshSeriesAndDeleteSavedSchedule}
        />
      ) : (
        <></>
      )}

      <Form
        mutators={mutators}
        onSubmit={handleSaveDraftSchedule}
        initialValues={initialScheduleData}
        validate={validateCreateScheduleForm}
        render={formRender}
      />
    </>
  )
}

export default CreateSchedule
