import React from 'react'
import Flex from 'ui/styles/Flex'
import { Typography } from '@material-ui/core'
import { c } from 'ttag'

const ApiDocumentation: React.FC = () => {
  return (
    <>
      <Flex alignItems="center">
        <Typography variant="h5">{c('Api documentation').t`API documentation`}</Typography>
      </Flex>

      <Typography>{c('Api documentation')
        .t`enercast IPL supports communication via its RESTful APIs. Please refer to the following documentation.`}</Typography>
      <ul>
        <li>
          <a href="api/v1/download/get/samples/4" download>
            Site Forecast API
          </a>
          : <span>{c('Api documentation').t`Allows to retrieve site forecasts.`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/6" download>
            Availability API
          </a>
          : <span>{c('Api documentation').t`Allows to manage availability limitations.`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/5" download>
            Master Data API
          </a>
          : <span>{c('Api documentation').t`Allows to retrieve asset master data.`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/8" download>
            SEF API
          </a>
          : <span>{c('Api documentation').t`enercast SEF Smart Energy Forecast API`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/7" download>
            Area Forecast API
          </a>
          : <span>{c('Api documentation').t`Allows to retrieve area forecasts.`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/9" download>
            Weather Data API
          </a>
          : <span>{c('Api documentation').t`Allows to retrieve weather data.`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/10" download>
            Full Backcast API
          </a>
          : <span>{c('Api documentation').t`Allows to generate full backcasts.`}</span>
        </li>
        <li>
          <a href="api/v1/download/get/samples/11" download>
            Revenue Spread API
          </a>
          :{' '}
          <span>{c('Api documentation')
            .t`Allows to request revenue spread and physical model simulation provided by enercast YAS Yield assessment for power generation sites.`}</span>
        </li>
      </ul>
      <Typography>{c('Api documentation').t`Note: API access requires an appropriate subscription.`}</Typography>
    </>
  )
}

export default React.memo(ApiDocumentation)
