import React, { useCallback, useMemo } from 'react'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Column, RETABLE_ID_PENALTY_REGULATIONS_NEW } from 'modules/reTable/reTable.types'
import { Button } from '@material-ui/core'
import { math } from 'polished'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import { TableHeaderActionCellWidth } from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableGenericToolbarContainer } from 'ui/styles/table'
import { c, t } from 'ttag'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import { getPenaltyDetailsNewQueryObj, useQueryString } from 'utils/query-string'

interface PenaltyRegulationsTableToolbarProps {
  onToggleShowInActive: () => void
  showInActive?: boolean
  columns: Column[]
  tableHeaderHasActions: boolean
}

const PenaltyRegulationsTableToolbarNew: React.FC<PenaltyRegulationsTableToolbarProps> = ({
  showInActive,
  onToggleShowInActive,
  columns,
  tableHeaderHasActions,
}) => {
  const { onUpdateQueryString } = useQueryString()

  const columnsWidth = useMemo(() => {
    const width = (columns || []).reduce((sum, column) => {
      return math(`${sum} + ${column.width}`)
    }, '0')
    return math(`${width} + ${editableRowActionsCellWidth} + ${tableHeaderHasActions ? TableHeaderActionCellWidth : 0}`)
  }, [columns])

  const handleAddRegulation = useCallback(() => {
    const queryObj = {
      ...getPenaltyDetailsNewQueryObj('new', PenaltyTableTypeOfRow.REGULATION),
    }

    onUpdateQueryString(queryObj)
  }, [onUpdateQueryString])

  const isPastRegulationEdit = false

  const actions = useMemo(() => {
    return (
      <>
        <Button onClick={onToggleShowInActive} color="primary" size="small">
          {showInActive ? c('PenaltyRegulation').t`Hide History` : c('PenaltyRegulation').t`Show History`}
        </Button>

        <Button onClick={handleAddRegulation} color="primary" size="small">
          {t`Add new regulation`}
        </Button>
      </>
    )
  }, [handleAddRegulation, isPastRegulationEdit, showInActive])

  return (
    <ReTableGenericToolbarContainer width={columnsWidth}>
      <ReTableToolbar
        showSearchField={false}
        id={RETABLE_ID_PENALTY_REGULATIONS_NEW}
        actions={actions}
        showClearSorting={true}
      />
    </ReTableGenericToolbarContainer>
  )
}

export default React.memo(PenaltyRegulationsTableToolbarNew)
