import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { CreateScheduleInputData } from 'modules/workspace/schedule/schedule.types'
import { convertLocalDateToUTC, DATE_FORMAT_INTERNAL_LONG, formatDate } from 'utils/date'
import axios from 'axios'
import { apiRequest, request } from 'utils/request'
import { FileData } from 'utils/file'
import { UploadResult } from 'modules/data/fileManager/redux_store/state/uploadMeterdataFiles.state'
import { DataStream } from 'modules/dataStreams/dataStreams.types'
import { Asset } from 'modules/asset/store/asset.types'

import { ScheduleFormSaveOptions } from 'utils/form'
import { format } from 'date-fns'
import { timeseriesDatetimeFormat } from 'modules/dataStreams/api/timeseries.api'
import { isCluster, isPark } from 'utils/asset'
import { convertChartSeriesToValuesMap } from 'utils/schedule'
import { Timezone } from 'fixtures/timezones'

export const QUERY_KEY_MANUAL_FORECAST_INPUT = 'manualForecastInput'
export const QUERY_KEY_SAVED_MANUAL_FORECASTS = 'savedManualForecasts'
export const QUERY_KEY_SCHEDULE_SERIES_CHANGED = 'scheduleSeriesChanged'
export const QUERY_KEY_SCHEDULE_SAVE_MAIN_ITEM_KEY = 'schedule:saveMainItemKey'

// ------------------ MANUAL FORECAST INPUT START HERE -----------------
// Save the current input for manual forecast
export const useCreateScheduleInput = () => {
  const queryResult = useUserSetting<CreateScheduleInputData>(QUERY_KEY_MANUAL_FORECAST_INPUT)
  return queryResult
}

// Get the current input for manual forecast
export const useCreateScheduleInputDataMutation = () => {
  return useUserSettingSaveMutation<CreateScheduleInputData>(QUERY_KEY_MANUAL_FORECAST_INPUT)
}
// -------------- MANUAL INPUT END HERE ----------------------------------

// -------------- SAVED MANUAL FORECASTS STARTS HERE ---------------------
export const useSavedSchedules = () => {
  return useUserSetting<CreateScheduleInputData[]>(QUERY_KEY_SAVED_MANUAL_FORECASTS)
}
export const useSaveScheduleMutation = () => {
  return useUserSettingSaveMutation<CreateScheduleInputData[]>(QUERY_KEY_SAVED_MANUAL_FORECASTS)
}
// -------------- SAVED MANUAL FORECASTS ENDS HERE -----------------------

// -------------- SCHEDULE SERIES CHANGED STARTS HERE ---------------------
// TODO need to replace the localstorage value which has series changed value "ScheduleLocalStorageKeys.seriesChanged"
export const useScheduleSeriesChanged = () => {
  return useUserSetting<Record<string, boolean>>(QUERY_KEY_SCHEDULE_SERIES_CHANGED)
}
export const useScheduleSeriesChangedMutation = () => {
  return useUserSettingSaveMutation<Record<string, boolean>>(QUERY_KEY_SCHEDULE_SERIES_CHANGED)
}
// -------------- SCHEDULE SERIES CHANGED ENDS HERE -----------------------

// -------------- SUBMIT MANUAL FORECAST TO TIMESERIES DB --------------------------
export const saveScheduleSeries = async (data: CreateScheduleInputData) => {
  // convert the timeseries into key value
  const valuesMap = convertChartSeriesToValuesMap(data.savedSeries || [])
  const metaDataMap = {
    PRODUCT_CONFIG_ID: data.targetScheduleDataStream?.id,
  }
  return await apiRequest(() =>
    axios.post(`/api/v1/ts/${data.asset.id}?seriesType=ENSEMBLE&valueType=ABSOLUTE_POWER`, { valuesMap, metaDataMap }),
  )
}

interface UploadScheduleTemplateFilesProps {
  fileList: FileData[]
  targetScheduleDataStream: DataStream
  asset: Asset
  startInUTC: Date
  endInUTC: Date
  timezone: Timezone
  scheduleSourceDataStream: DataStream
}

export const uploadScheduleTemplateFiles = ({
  fileList,
  targetScheduleDataStream,
  scheduleSourceDataStream,
  asset,
  startInUTC,
  endInUTC,
  timezone,
}: UploadScheduleTemplateFilesProps) => {
  const start = format(new Date(startInUTC), timeseriesDatetimeFormat)
  const end = format(new Date(endInUTC), timeseriesDatetimeFormat)

  const scheduleConfigId = targetScheduleDataStream.id
  const scheduleSourceId = scheduleSourceDataStream.id
  const assetId = asset.id
  const formData = new FormData()
  fileList.forEach((fileData) => {
    formData.append(fileData.file?.name, fileData.file)
  })
  const assetType = isCluster(asset) ? 'cluster' : isPark(asset) ? 'park' : 'generator'

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  return request(() =>
    axios.post<UploadResult>(
      `/api/v1/scheduling/assetId/${assetId}/assetType/${assetType}/scheduleConfigId/${scheduleConfigId}/scheduleSourceId/${scheduleSourceId}`,
      formData,
      {
        headers,
      },
    ),
  )
}

export const useScheduleSaveMainItemKey = () => {
  return useUserSetting<Record<string, ScheduleFormSaveOptions>>(QUERY_KEY_SCHEDULE_SAVE_MAIN_ITEM_KEY)
}

export const useScheduleSaveMainItemKeySaveMutation = () => {
  return useUserSettingSaveMutation<Record<string, ScheduleFormSaveOptions>>(QUERY_KEY_SCHEDULE_SAVE_MAIN_ITEM_KEY)
}

interface DownloadScheduleTemplateProps {
  assetId: string
  fileName: string
  targetScheduleId: string
  scheduleSourceId: string
}

export const downloadScheduleTemplate = ({
  assetId,
  fileName,
  targetScheduleId,
  scheduleSourceId,
}: DownloadScheduleTemplateProps) => {
  const data = {
    assetId: assetId,
    scheduleConfigId: targetScheduleId,
    scheduleSourceId: scheduleSourceId,
    fileName: fileName,
  }
  return request(() => axios.post('/api/v1/scheduling/template/download', data, { responseType: 'blob' }))
}

interface SubmitScheduleWithTemplateProps {
  templateUploadResponse: Record<string, any>
  assetId: string
  scheduleConfigId: string
  scheduleSourceId: string
}

export const submitScheduleWithTemplate = ({
  templateUploadResponse,
  assetId,
  scheduleConfigId,
  scheduleSourceId,
}: SubmitScheduleWithTemplateProps) => {
  // const start = format(new Date(startInUTC), timeseriesDatetimeFormat)
  // const end = format(new Date(endInUTC), timeseriesDatetimeFormat)

  return request(() =>
    axios.post(
      `/api/v1/scheduling/assetId/${assetId}/scheduleConfigId/${scheduleConfigId}/scheduleSourceId/${scheduleSourceId}/submit`,
      templateUploadResponse,
    ),
  )
}
