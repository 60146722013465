import { Asset } from 'modules/asset/store/asset.types'
import { GjsonImport, Operations, Requirements, ShapeCollectionType } from 'modules/gips/helper/Gips.types'

//allAssets = alle alten assets,oldAsset ist das Asset vor der bearbeitung, newAsset ist das geänderte asset.
export const prepareShapeUpdate = (allAssets: Asset[], newAsset: Asset, user = ''): Requirements => {
  const oldAssetArray = allAssets.filter((asset) => asset.id === newAsset.id)
  if (oldAssetArray.length === 1) {
    const oldAsset = oldAssetArray[0]
    if (!oldAsset.shape && newAsset.shape) {
      return { gjson: generateGJSON(newAsset, user), ops: Operations.update }
    } else if (oldAsset.shape && !newAsset.shape) {
      return { gjson: {}, ops: Operations.delete, uuid: oldAsset.shapeUUID }
    } else if (oldAsset.shape && newAsset.shape) {
      if (JSON.stringify(oldAsset.shape) !== JSON.stringify(newAsset.shape)) {
        return { gjson: generateGJSON(newAsset, user), ops: Operations.update }
      }
    }
  }
  return { ops: Operations.nothing }
}

const generateGJSON = (asset: Asset, user: string): GjsonImport => {
  return {
    gjson: JSON.parse(asset.shape as string),
    shapeInfo: {
      name: asset.shape === typeof undefined ? '' : asset.name,
      note: 'Assset with ID:' + asset.id,
      description: asset.description + ' Created with jsonString in Asset details.',
      label: asset.name,
      customerID: user,
      assetID: asset.id,
      licence: {
        type: 'free',
      },
      type: ShapeCollectionType.ASSET,
    },
  }
}
