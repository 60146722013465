import { User } from 'modules/auth/Auth.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { QUERY_KEY_SITE_FORECAST_CONFIGS } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS } from 'modules/dataStreams/api/weatherData.api'
import {
  DATA_SELECTION_MODE_SINGLE,
  DataSelectionMode,
  DataStreamSelection,
  ForecastConfig,
} from 'modules/dataStreams/dataStreams.types'
import { WeatherConfig } from 'modules/weather/store/weather.types'
import { SET_SELECTED_CONFIG } from 'modules/workspace/store/view.state'

import {
  ASSET_SELECTION_MODE_SINGLE,
  AssetSelectionMode,
  CHART_TYPE_LINE,
  ChartAggregationMode,
  ChartDataRange,
  ChartDataRangeType,
  ChartRangeSelectionOffset,
  ChartType,
  ChartWidget,
  GET_WORKSPACE_DRAFT_ACTION_TYPE,
  GET_WORKSPACE_DRAFT_DISMISS,
  GET_WORKSPACE_DRAFT_FAILURE,
  GET_WORKSPACE_DRAFT_REQUEST,
  GET_WORKSPACE_DRAFT_SUCCESS,
  SAVE_WORKSPACE_DRAFT_SUCCESS,
  WorkspaceConfig,
  WorkspaceDraftScheduleSeries,
} from 'modules/workspace/store/workspace.types'
import { queryClient } from 'queryClient'
import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import createReducer from 'utils/createReducer'
import { Result } from 'utils/request'
import { createSelectorWithStringifyComparator } from 'utils/store'
import {
  createDefaultChartConfig,
  createDefaultDateRange,
  createDefaultWorkspaceConfig,
  normalizeWorkspaceConfig,
} from 'utils/workspace'
import { removeDuplicates } from 'utils/array'

// state

interface State {
  result: Partial<WorkspaceConfig>
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  result: createDefaultWorkspaceConfig(),
  loading: false,
  error: null,
  initialized: false,
}

// types

interface GetDraftAction {
  type: GET_WORKSPACE_DRAFT_ACTION_TYPE | SAVE_WORKSPACE_DRAFT_SUCCESS
  // SUCCESS
  draft: State['result']
  // ERROR
  error: State['error']
}

// reducers

const result = createReducer<State['result'], GetDraftAction>((state = initialState.result, { type, draft }) => {
  if (type === GET_WORKSPACE_DRAFT_SUCCESS || type === SAVE_WORKSPACE_DRAFT_SUCCESS) {
    return draft
    // } else if (type === SAVE_WORKSPACE_CONFIGS_SUCCESS) {
    //   return state && draft ? deepmerge(state, draft) : draft || state || null
  }
  return state
})

const loading = createReducer<State['loading'], GetDraftAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case GET_WORKSPACE_DRAFT_REQUEST:
      return true
    case GET_WORKSPACE_DRAFT_SUCCESS:
    case GET_WORKSPACE_DRAFT_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], GetDraftAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case GET_WORKSPACE_DRAFT_SUCCESS:
    case GET_WORKSPACE_DRAFT_DISMISS:
      return null
    case GET_WORKSPACE_DRAFT_FAILURE:
      return error
  }
  return state
})

const initialized = createReducer<State['initialized'], GetDraftAction>(
  (state = initialState.initialized, { type }) => {
    switch (type) {
      case GET_WORKSPACE_DRAFT_SUCCESS:
      case GET_WORKSPACE_DRAFT_FAILURE:
        return true
    }
    return state
  },
)

export const getDraftReducer = combineReducers({
  result,
  loading,
  error,
  initialized,
})

// selectors

export const workspaceDraftResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.workspace.getDraft.result,
  (result) => result,
)

export const workspaceDraftLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.workspace.getDraft.loading,
  (loading) => loading,
)

export const workspaceDraftErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.workspace.getDraft.error,
  (error) => error,
)

export const workspaceDraftInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.workspace.getDraft.initialized,
  (initialized) => initialized,
)

export const workspaceDraftIdSelector = createSelector<any, string, string>(
  (state) => state.workspace.getDraft.result?.id,
  (id) => id,
)

export const workspaceDraftAssetSelectionSelector = createSelectorWithStringifyComparator<any, string[], string[]>(
  (state) => {
    const allSelectedItems = state.workspace.getDraft.result?.asset?.selection || []
    return removeDuplicates(allSelectedItems)
  },
  (selection) => selection || [],
)

export const workspaceDraftSelectedModelsSelector = createSelector<any, any[], any[]>(
  (state) => state.workspace.getDraft.result.selectedModels || [],
  (selectedModels) => selectedModels || [],
)

export const workspaceDraftE3WidgetSettingsSelector = createSelector<any, Record<string, any>, Record<string, any>>(
  (state) => state.workspace.getDraft.result?.e3WidgetSettings,
  (e3WidgetSettings) => e3WidgetSettings || {},
)

export const workspaceDraftScheduleSelector = createSelector<
  any,
  WorkspaceDraftScheduleSeries,
  WorkspaceDraftScheduleSeries
>(
  (state) => state.workspace.getDraft.result?.schedule,
  (schedule) => schedule || {},
)

export const workspaceDraftPenaltyWidgetSelector = createSelector<any, Record<string, any>, Record<string, any>>(
  (state) => state.workspace.getDraft.result?.penaltyWidget,
  (penaltyWidget) => penaltyWidget || {},
)

export const workspaceDraftMonthlyViewWidgetSelector = createSelector<any, Record<string, any>, Record<string, any>>(
  (state) => state.workspace.getDraft.result?.monthlyViewWidget,
  (monthlyViewWidget) => monthlyViewWidget || null,
)

export const workspaceDraftAssetSelectionModeSelector = createSelector<any, AssetSelectionMode, AssetSelectionMode>(
  (state) => state.workspace.getDraft.result?.asset?.selectionMode || ASSET_SELECTION_MODE_SINGLE,
  (selectionMode) => selectionMode,
)

export const workspaceDraftChartNameSelector = createSelector<any, string, string>(
  (state) => state.workspace.getDraft.result?.chart?.name || '',
  (name: string) => name,
)

export const workspaceDraftChartAggregationModeSelector = createSelector<
  any,
  ChartAggregationMode,
  ChartAggregationMode
>(
  (state) =>
    state.workspace.getDraft.result?.chart?.aggregationMode ||
    ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET,
  (aggregationMode) => aggregationMode,
)

export const workspaceDraftChartRangeSelector = createSelector<
  any,
  ChartRangeSelectionOffset,
  ChartRangeSelectionOffset
>(
  (state) => state.workspace.getDraft.result?.chart?.range || createDefaultDateRange(),
  (range) => range,
)

export const workspaceDraftChartDataRangeSelector = createSelector<any, ChartDataRange, ChartDataRange>(
  (state) =>
    state.workspace.getDraft.result?.chart?.dataRange || {
      type: ChartDataRangeType.CHART_DATA_RANGE_PLUS_MINUS_7_DAYS,
      customRange: null,
    },
  (dataRange) => dataRange,
)

export const workspaceDraftChartWidgetsSelector = createSelector<any, ChartWidget[], ChartWidget[]>(
  (state) => state.workspace.getDraft.result?.chart?.widgets || createDefaultChartConfig().widgets,
  (widgets) => widgets,
)

export const workspaceDraftChartTypeSelector = createSelector<any, ChartType, ChartType>(
  (state) => state.workspace.getDraft.result?.chart?.type || CHART_TYPE_LINE,
  (type) => type,
)

export const workspaceDraftDataStreamSelectionSelector = createSelectorWithStringifyComparator<
  any,
  DataStreamSelection,
  DataStreamSelection
>(
  (state) => {
    const selectedDataStreams = state.workspace.getDraft.result?.data?.selection
    const sortedDataStreams = ([...selectedDataStreams] || []).sort((a, b) => {
      const valueA = (a?.label || a?.name)?.toUpperCase()
      const valueB = (b?.label || a?.name)?.toUpperCase()
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    })

    return sortedDataStreams
  },
  (selection) => selection || [],
)
export const workspaceDraftDataStreamSelectionModeSelector = createSelector<any, DataSelectionMode, DataSelectionMode>(
  (state) => state.workspace.getDraft.result?.data?.selectionMode,
  (selectionMode) => selectionMode || DATA_SELECTION_MODE_SINGLE,
)

// api
export const getWorkspaceDraft = (user: User) => {
  const workspaceDraft: Record<string, WorkspaceConfig> =
    JSON.parse(localStorage.getItem('workspaceDraft') || '{}') || {}

  const result: Result<WorkspaceConfig> = {
    hasError: false,
    hasAuthError: false,
    isSuccessful: true,
    getData: () => workspaceDraft[user?.login] || createDefaultWorkspaceConfig(),
    getError: () => '',
  }
  return result
}

export const getSelectedConfig = (user: User) => {
  const selectedConfig: Record<string, string | null> =
    JSON.parse(localStorage.getItem('workspaceSelectedConfig') || '{}') || {}

  let selectedConfigId: string | undefined = selectedConfig[user?.login] || undefined

  if (!selectedConfigId) {
    const workspaceDraftResult = getWorkspaceDraft(user)
    selectedConfigId = workspaceDraftResult.getData().id
  }

  const result: Result<string | undefined> = {
    hasError: false,
    hasAuthError: false,
    isSuccessful: true,
    getData: () => selectedConfigId,
    getError: () => '',
  }
  return result
}

// sagas
export function* getWorkspaceDraftSaga(): SagaIterator {
  const user = yield select(getUserResultSelector)
  const draftResult = yield call(getWorkspaceDraft, user)
  if (draftResult.isSuccessful) {
    const draft = draftResult.getData()
    const forecastConfigs: ForecastConfig[] = queryClient.getQueryData(QUERY_KEY_SITE_FORECAST_CONFIGS) || []
    const weatherConfigs: WeatherConfig[] = queryClient.getQueryData(QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS) || []
    normalizeWorkspaceConfig(draft, user, forecastConfigs, weatherConfigs)
    yield put({ type: GET_WORKSPACE_DRAFT_SUCCESS, draft })
  } else {
    const error = draftResult.getError()
    yield put({ type: GET_WORKSPACE_DRAFT_FAILURE, error })
  }
  const selectedResult = yield call(getSelectedConfig, user)
  if (draftResult.isSuccessful) {
    const selectedConfig = selectedResult.getData()
    yield put({ type: SET_SELECTED_CONFIG, selectedConfig })
  } else {
    const error = selectedResult.getError()
    console.error('last workspace config selection cannot be retrieved ', error)
    yield put({ type: SET_SELECTED_CONFIG, selectedConfig: null })
  }
}
