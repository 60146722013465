import React, { useCallback } from 'react'
import { Column, RETABLE_ID_RUNNING_BACKCASTS } from 'modules/reTable/reTable.types'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { RunningBackcastsStatus, singleRunningBackcastType } from 'modules/workspace/header/backCast/backcast.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import Highlight from 'ui/Highlight'
import { t } from 'ttag'
import { formatDateShort } from 'utils/date'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import { IconButton } from '@material-ui/core'
import Flex from 'ui/styles/Flex'

interface RunningBackcastTableBodyProps {
  visibleItems: singleRunningBackcastType[]
  columns: Column[]
  tableHeaderHasActions: boolean
  onDeleteItem: (data: singleRunningBackcastType) => void
}

const RunningBackcastTableBody: React.FC<RunningBackcastTableBodyProps> = ({
  visibleItems,
  columns,
  tableHeaderHasActions,
  onDeleteItem,
}) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_RUNNING_BACKCASTS)

  const handleGetCustomCellComponent = useCallback(
    (column: Column, item: singleRunningBackcastType, query: string) => {
      if (column.name === 'ignorePeriodOfOperation') {
        return (
          <Highlight text={item.ignorePeriodOfOperation ? t`Is ignored` : t`Is taken into account`} query={query} />
        )
      } else if (column.name === 'start' || column.name === 'end') {
        return <Highlight text={formatDateShort(item[column.name])} query={query} />
      }
    },
    [query],
  )

  const getCustomEditableRowActionsCell = useCallback(
    (row: singleRunningBackcastType) => {
      if (row.status === RunningBackcastsStatus.QUEUED) {
        return (
          <Flex alignItems="center" justifyContent="center">
            <IconButton onClick={() => onDeleteItem(row)} size="small" style={{ marginTop: '0.1em' }}>
              <FontAwesomeIcon size="sm" icon="trash-alt" color={theme.palette.primary.main} />
            </IconButton>
          </Flex>
        )
      }
    },
    [query],
  )

  return (
    <ReTableBody>
      {visibleItems.map((row: singleRunningBackcastType, index) => {
        return (
          <ReTableEditableRow
            key={index}
            isRowEdit={false}
            rowItem={row}
            columns={columns}
            onDeleteRowItem={onDeleteItem}
            tableHeaderHasActions={tableHeaderHasActions}
            showEditableRowActions={row.status === RunningBackcastsStatus.QUEUED}
            getCustomCellComponent={handleGetCustomCellComponent}
            customEditableRowActionsCell={getCustomEditableRowActionsCell(row)}
            query={query}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(RunningBackcastTableBody)
