import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RETABLE_ID_FILEMANAGER } from 'modules/reTable/reTable.types'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Box } from '@material-ui/core'
import FileUploadZone, { FileUploadHandler } from 'ui/FileUploadZone'
import { t } from 'ttag'
import UploadModal from 'modules/data/fileManager/UploadModal'
import ErrorMessage from 'ui/form/ErrorMessage'
import { convertRejectionErrors, FILE_RENAMED_OR_DELETED, FileData, FileError } from 'utils/file'
import { FileRejection } from 'react-dropzone'
import { UPLOAD_METERDATAFILES_REQUEST } from 'modules/data/fileManager/redux_store/fileManager.action.types'
import { useDispatch, useSelector } from 'react-redux'
import { getMeterdataFilesErrorSelector } from 'modules/data/fileManager/redux_store/state/getMeterdataFiles.state'
import { uploadMeterdataFilesResultSelector } from 'modules/data/fileManager/redux_store/state/uploadMeterdataFiles.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { math } from 'polished'
import { TableHeaderActionCellWidth } from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableGenericToolbarContainer } from 'ui/styles/table'
import { MeterdataSummary } from 'modules/asset/store/asset.types'
import AssetMeterdataDescription from 'ui/meterdata/AssetMeterdataDescription'

interface FileManagerTableToolbarProps {
  selectedAssetName?: string
  summary?: MeterdataSummary
  toolTip?: boolean
  showSearchField?: boolean
}

const FileManagerTableToolbar: React.FC<FileManagerTableToolbarProps> = ({
  selectedAssetName,
  summary,
  toolTip,
  showSearchField = true,
}) => {
  const dispatch = useDispatch()
  const error = useSelector(getMeterdataFilesErrorSelector)
  const result = useSelector(uploadMeterdataFilesResultSelector)
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_FILEMANAGER)
  const [fileDatas, setFileDatas] = useState<FileData[]>([])
  const [fileErrors, setFileErrors] = useState<FileError[]>([])
  const [showModal, setShowModal] = useState<boolean>()
  const tableHeaderHasActions = false

  const columnsWidth = useMemo(() => {
    const width = (columnsSelected || []).reduce((sum, column) => {
      return math(`${sum} + ${column.width}`)
    }, '0')
    return math(`${width} + ${tableHeaderHasActions ? TableHeaderActionCellWidth : 0}`)
  }, [columnsSelected])

  const handleClose = useCallback(() => {
    setShowModal(false)
    setFileDatas([])
  }, [])

  const handleResetErrors = useCallback(() => {
    setFileErrors([])
  }, [])

  useEffect(() => {
    handleClose()
  }, [result])

  const handleFileSelect: FileUploadHandler = useCallback(({ validFiles, invalidFiles }) => {
    setShowModal(true)
    setFileDatas((prevFileDatas) => [...prevFileDatas, ...validFiles])
    setFileErrors(invalidFiles)
  }, [])

  const handleRemove = useCallback((fileData: FileData, index: number) => {
    setFileDatas((prevFileDatas) => [...prevFileDatas.filter((fd, i) => i !== index)])
  }, [])

  const handleSubmit = useCallback(() => {
    const filesArePresentLocally: Promise<FileData>[] = []

    fileDatas.forEach((fileData) => {
      filesArePresentLocally.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => resolve(fileData)
          reader.onerror = () => reject(fileData)
          reader.onabort = () => reject(fileData)
          reader.readAsText(fileData.file, 'UTF-16')
        }),
      )
    })

    Promise.allSettled(filesArePresentLocally).then((result) => {
      const rejectedFileDatas = result.filter((r) => r.status === 'rejected').map((r) => r.reason)
      if (rejectedFileDatas.length) {
        const rejectedFileErrors: FileError[] = rejectedFileDatas.map((fd) => {
          const fileRejection: FileRejection = {
            file: fd.file,
            errors: [
              {
                message: 'file renamed or deleted',
                code: FILE_RENAMED_OR_DELETED,
              },
            ],
          }

          return {
            file: fd.file,
            errors: convertRejectionErrors(fileRejection),
          }
        })

        setFileDatas((fds) => fds.filter((fd) => !rejectedFileDatas.map((rfd) => rfd.file).includes(fd.file)))
        setFileErrors(rejectedFileErrors)
      } else {
        // Change the name if this is from asset details
        dispatch({ type: UPLOAD_METERDATAFILES_REQUEST, fileDatas, selectedAssetName })
      }
    })
  }, [fileDatas, selectedAssetName])

  const fileUploadActions = (
    <>
      <Box>
        <FileUploadZone
          accept=".csv, text/csv, text/plain, application/vnd.ms-excel"
          compact
          label={t`Drag and drop CSV files here, or`}
          onFileSelect={handleFileSelect}
        />
      </Box>

      {showModal && (
        <UploadModal
          files={fileDatas}
          errors={fileErrors}
          showModal={showModal}
          onFileSelect={handleFileSelect}
          onRemove={handleRemove}
          onSubmit={handleSubmit}
          onClose={handleClose}
          onResetErrors={handleResetErrors}
        />
      )}
    </>
  )

  return (
    <div>
      {error && <ErrorMessage errorMsg={error} />}
      <ReTableGenericToolbarContainer width={columnsWidth}>
        {summary && (
          <Box pt={'1.5em'} width={'100%'}>
            <AssetMeterdataDescription summary={summary} toolTip={toolTip || false} />
          </Box>
        )}
        <ReTableToolbar
          toolBarItemsAlignment="flex-end"
          id={RETABLE_ID_FILEMANAGER}
          actions={fileUploadActions}
          showClearSorting={false}
          showSearchField={showSearchField}
        />
      </ReTableGenericToolbarContainer>
    </div>
  )
}

export default React.memo(FileManagerTableToolbar)
