import { Box, Divider, Typography } from '@material-ui/core'
import { workspaceDraftChartAggregationModeSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { t } from 'ttag'

import ButtonMenu from 'ui/form/ButtonMenu'
import CheckmarkRadio from 'ui/form/CheckmarkRadio'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { getAggregationLabel } from 'utils/workspace'

export interface AggregationMenuProps {
  onChangeMode: (key: ChartAggregationMode) => void
  hideLabel: boolean
}

const AggregationMenu: React.FC<AggregationMenuProps> = ({ onChangeMode, hideLabel, ...rest }) => {
  const chartAggregationMode = useSelector(workspaceDraftChartAggregationModeSelector)
  const modeOptions = useMemo(
    () =>
      [
        ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET,
        ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_SUM,
        ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_AVG,
        ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MIN,
        ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MAX,
      ].map((aggregationMode) => ({
        key: aggregationMode,
        label: getAggregationLabel(aggregationMode),
      })),
    [],
  )
  const activeMode = modeOptions.find((modeOption) => modeOption.key === chartAggregationMode)

  const handleChange = useCallback(
    (handleClose) => (modeOption: SelectAsListItem) => {
      onChangeMode(modeOption.key as ChartAggregationMode)
      handleClose()
    },
    [onChangeMode],
  )

  const menuContent = useCallback(
    (handleClose) => (
      <>
        <Box m={2}>
          <Typography variant="subtitle2">{t`Aggregation`}</Typography>
        </Box>
        <Divider />
        <CheckmarkRadio options={modeOptions} selected={chartAggregationMode} onChange={handleChange(handleClose)} />
        <Divider />
        <Box mx={2} my={1}>
          <Typography>{t`Data streams are aggregated over all selected assets.`}</Typography>
        </Box>
      </>
    ),
    [modeOptions, chartAggregationMode, handleChange],
  )

  return (
    <ButtonMenu
      icon="calculator"
      title={activeMode ? activeMode.label : ''}
      text={hideLabel ? '' : activeMode ? activeMode.label : ''}
      color="primary"
      size="small"
      renderMenu={menuContent}
      {...rest}
    />
  )
}

export default React.memo(AggregationMenu)
