import React from 'react'
import { MonthlyViewModeIds, SeasonalForecastDataColors } from 'utils/seasonalForecast'
import { t } from 'ttag'
import PopperTooltip from 'ui/PopperTooltip'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { Box } from '@material-ui/core'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getDataStreamTypeQueryLabel } from 'utils/dataStream'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { QUERY_PRINT_VIEW, useQueryParams } from 'utils/query-string'

const LegendIcon = styled(FontAwesomeIcon)`
  margin-right: 4px;
  margin-left: 8px;
`

const PopperTooltipDiv = styled.div`
  width: 1em;
  text-align: center;
`

interface SeasonalForecastWidgetInfoProps {
  showClimatologyInfo: boolean
  showMonthlyForecastInfo: boolean
  monthlyViewMode: MonthlyViewModeIds
}

const SeasonalForecastWidgetInfo: React.FC<SeasonalForecastWidgetInfoProps> = ({
  showClimatologyInfo,
  monthlyViewMode,
}) => {
  const { queryParams } = useQueryParams()
  const seasonalForecastLabel = getDataStreamTypeQueryLabel(TimeSeriesType.SEASONAL_FORECAST)?.label
  const climatologyLabel = getDataStreamTypeQueryLabel(TimeSeriesType.CLIMATOLOGY)?.label

  const isPrintView = () => {
    return queryParams[QUERY_PRINT_VIEW]
  }

  return (
    <Box display="flex" alignItems="center">
      {monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW && (
        <>
          <LegendIcon icon="circle" size="sm" color={SeasonalForecastDataColors.METER_DATA_COLOR} /> {t`Meter data`}
          {!isPrintView() ? (
            <PopperTooltipDiv>
              <PopperTooltip
                popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
                popperContent={<>{t`Actuals from generation, wind speed and solar radiation measurements.`}</>}
              />
            </PopperTooltipDiv>
          ) : null}
        </>
      )}

      <>
        <LegendIcon icon="circle" size="sm" color={SeasonalForecastDataColors.INTRA_MONTH_FORECAST_COLOR} />
        {t`Intramonth Forecast`}
        {!isPrintView() ? (
          <PopperTooltipDiv>
            <PopperTooltip
              popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
              popperContent={
                <>{t`Generation, wind speed and solar radiation forecasts for the current month, based on the most up-to-date data sources present for each parameter (historical and live meter data, ensemble of multiple numerical weather forecasts).`}</>
              }
            />
          </PopperTooltipDiv>
        ) : null}
      </>
      <>
        <LegendIcon icon="circle" size="sm" color={SeasonalForecastDataColors.FORECAST_DATA_COLOR} />{' '}
        <>{seasonalForecastLabel}</>
        {!isPrintView() ? (
          <PopperTooltipDiv>
            <PopperTooltip
              popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
              popperContent={
                <>
                  {monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW
                    ? t`Monthly generation, wind speed and solar radiation forecasts with 6 months-ahead horizons.`
                    : t`Monthly generation, wind speed and solar radiation forecasts with 6 months-ahead horizons extended with site-specific climatology to cover 12 months ahead.`}
                </>
              }
            />
          </PopperTooltipDiv>
        ) : null}
      </>

      {showClimatologyInfo && (
        <>
          <LegendIcon icon="circle" size="sm" color={SeasonalForecastDataColors.CLIMATOLOGY_COLOR} />{' '}
          <>{climatologyLabel}</>
          {!isPrintView() ? (
            <PopperTooltipDiv>
              <PopperTooltip
                popperLabel={<FontAwesomeActionIcon icon="info" size="sm" />}
                popperContent={<>{t`Long-term monthly statistics of generation, wind speed and solar radiation.`}</>}
              />
            </PopperTooltipDiv>
          ) : null}
        </>
      )}
      {/*{showMonthlyForecastInfo && (*/}
      {/*  <>*/}
      {/*    <LegendIcon icon="circle" size="sm" color={SeasonalForecastChartColor.SOLAR_ASSETS} /> {t`Solar Assets`}*/}
      {/*    <LegendIcon icon="circle" size="sm" color={SeasonalForecastChartColor.WIND_ASSETS} /> {t`Wind Assets`}*/}
      {/*  </>*/}
      {/*)}*/}
    </Box>
  )
}

export default SeasonalForecastWidgetInfo
