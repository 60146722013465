import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query'
import axios from 'axios'

import { apiRequest, Error } from 'utils/request'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'

// Query keys for caching data
export const QUERY_KEY_DELIVERY_FORMATS = 'delivery:formats'

// Transform methods

const transformDeliveryFormatsAfterGet = (data: DeliveryFormat[], forecastConfigurations: ForecastConfig[]) => {
  const transformedItems = (data || []).map((format: DeliveryFormat) => {
    const configsUsingThisTarget = forecastConfigurations.filter((config) => config.exportFormatConfigId === format.id)
    return {
      ...format,
      usedIn: configsUsingThisTarget,
      usedInLength: configsUsingThisTarget.length,
    }
  })
  return transformedItems
}

// Async API requests to fetch and update data

export const getDeliveryFormats = async () => {
  return await apiRequest<DeliveryFormat[]>(() => axios.get('/api/exportformat/v2/list'))
}

export const saveDeliveryFormat = async (deliveryFormat: DeliveryFormat) => {
  if (deliveryFormat.id) {
    // update existing
    return await apiRequest<DeliveryFormat>(() =>
      axios.put(`/api/exportformat/v2/${deliveryFormat.id}`, deliveryFormat),
    )
  } else {
    // create new
    return await apiRequest<DeliveryFormat>(() => axios.post('/api/exportformat/v2', deliveryFormat))
  }
}

// Hooks to fetch and update via react-query

export const useDeliveryFormats = () => {
  return useQuery<DeliveryFormat[]>(QUERY_KEY_DELIVERY_FORMATS, getDeliveryFormats)
}

export const useDeliveryFormatsTableItems = () => {
  const deliveryFormats = useDeliveryFormats()
  const forecastConfigurations = useSiteForecastConfigs()
  const transformedDeliveryFormats: QueryObserverResult<DeliveryFormat[]> = { ...deliveryFormats, data: undefined }
  if (deliveryFormats.data && forecastConfigurations.data) {
    transformedDeliveryFormats.data = transformDeliveryFormatsAfterGet(
      deliveryFormats.data,
      forecastConfigurations.data,
    )
  }
  return transformedDeliveryFormats
}

export const useDeliveryFormatSaveMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<DeliveryFormat, Error, DeliveryFormat>(saveDeliveryFormat, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMATS)
    },
  })
}

const deleteDeliveryFormat = async (data: DeliveryFormat) => {
  return await apiRequest<DeliveryFormat>(() => {
    return axios.delete(`/api/exportformat/v2/${data.id}`)
  })
}

export const useDeliveryFormatsDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteDeliveryFormat, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMATS)
    },
  })
}
