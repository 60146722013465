import { Box } from '@material-ui/core'
import { FormApi, FormSubscription, Mutator } from 'final-form'
import arrayMutators from 'final-form-arrays'
import setFieldTouched from 'final-form-set-field-touched'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import {
  useSiteForecastConfigs,
  useSiteForecastConfigSaveMutation,
  validateSiteForecastCustomConfig,
} from 'modules/dataStreams/api/siteForecastConfigs.api'
import {
  useSiteForecastConfigTemplates,
  useSiteForecastConfigTemplateSaveMutation,
} from 'modules/dataStreams/api/siteForecastConfigTemplates.api'

import {
  CustomConfigurationFileResponse,
  ForecastConfig,
  ForecastConfigurationCategory,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import SiteForecastForm from 'modules/dataStreams/siteForecast/SiteForecastForm'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { c } from 'ttag'
import CenteredLoading from 'ui/CenteredLoading'
import { dataStreamTypeQuery } from 'utils/dataStream'
import {
  transformSiteForecastDataForForm,
  siteForecastMasterData,
  createNameSuggestion,
  deliveryTemplateExtension,
} from 'utils/siteForecastConfiguration'
import { validateSiteForecastForm } from 'utils/formValidations'
import {
  QUERY_COPY_SITE_FORECAST_TEMPLATE,
  QUERY_DATA_STREAM_ID,
  QUERY_DATA_STREAM_TYPE,
  QUERY_NEW_SCHEDULE,
  QUERY_NEW_SITE_FORECAST,
  QUERY_NEW_SITE_FORECAST_TEMPLATE,
  QUERY_SITE_FORECAST_TEMPLATE,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import { saveDeliveryFormat, useDeliveryFormats } from 'modules/delivery/deliveryFormats/api/deliveryFormats.api'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import { useDeliveryFormatTemplates } from 'modules/delivery/deliveryFormats/api/deliveryFormatTemplates.api'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import FullHeight from 'ui/FullHeight'
import styled from 'styled-components'
import { FormSaveOptions, getCopyName } from 'utils/form'
import { useSiteForecastSaveMainItemKey } from 'modules/dataStreams/api/dataStreamsUserSettings.api'

const Container = styled(FullHeight)`
  width: 100%;
`

export interface TimingPreview {
  forecastDeliveryTiming: any[]
  qualityConfigTiming: any[]
}

let formReference: FormApi<ForecastConfig>

interface SiteForecastDetailsProps {
  forecastId: string
  importedConfig: ForecastConfig | null
}

const SiteForecastDetails: React.FC<SiteForecastDetailsProps> = ({ forecastId, importedConfig }) => {
  const { [TimeSeriesType.SITE_FORECAST]: siteForecast } = dataStreamTypeQuery
  const user = useSelector(getUserResultSelector)
  const { queryParams } = useQueryParams()
  const { onDeleteQueryStrings } = useQueryString()

  const siteForecastConfigs = useSiteForecastConfigs()
  const siteForecastConfigTemplates = useSiteForecastConfigTemplates()
  const deliveryFormats = useDeliveryFormats()
  const deliveryFormatTemplates = useDeliveryFormatTemplates()

  const [isPageLoading, setIsPageLoading] = useState<boolean>(true)
  const [uploadWarnings, setUploadWarning] = useState<string[] | null>(null)
  const [uploadErrors, setUploadErrors] = useState<string[] | null>(null)
  const [formChanged, setFormChanged] = useState<boolean>(false)
  const [forecastDetails, setForecastDetails] = useState<Partial<ForecastConfig> | undefined>()
  const [isCustomConfigFileValid, setIsCustomConfigFileValid] = useState<boolean>(false)
  const [customConfigFile, setCustomConfigFile] = useState<File>()
  const [timingPreviewData, setTimingPreviewData] = useState<TimingPreview | null>(null)

  const isNewSiteForecast = useMemo(() => Boolean(queryParams[QUERY_NEW_SITE_FORECAST]), [queryParams])
  const isNewSchedule = useMemo(() => Boolean(queryParams[QUERY_NEW_SCHEDULE]), [queryParams])
  const isTemplate = useMemo(() => Boolean(queryParams[QUERY_SITE_FORECAST_TEMPLATE]), [queryParams])
  const isNewTemplate = useMemo(() => Boolean(queryParams[QUERY_NEW_SITE_FORECAST_TEMPLATE]), [queryParams])
  const isCopyTemplate = queryParams[QUERY_COPY_SITE_FORECAST_TEMPLATE]
  const mutators = useMemo<{ [key: string]: Mutator<ForecastConfig, Partial<ForecastConfig>> }>(() => {
    return {
      ...arrayMutators,
      setFieldTouched,
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
      setValues: ([changes], state, { changeValue }) => {
        changes.forEach((change: any) => {
          changeValue(state, change.field, () => change.value)
        })
      },
    }
  }, [])

  const deliveryFormatsToDisplay = useMemo(() => {
    if (
      deliveryFormats.isSuccess &&
      deliveryFormatTemplates.isSuccess &&
      deliveryFormats.data &&
      deliveryFormatTemplates.data
    ) {
      const deliveryTemplates = [
        ...deliveryFormatTemplates.data.map((template: DeliveryFormat) => ({
          ...template,
          name: `${template.name}${deliveryTemplateExtension}`,
        })),
      ]
      return isTemplate ? [...deliveryTemplates] : [...deliveryFormats.data, ...deliveryTemplates]
    } else {
      return []
    }
  }, [
    deliveryFormats.isSuccess,
    deliveryFormatTemplates.isSuccess,
    deliveryFormats.data,
    deliveryFormatTemplates.data,
    isTemplate,
  ])
  const configNames = useMemo(() => (siteForecastConfigs.data || []).map((config) => config.name), [
    siteForecastConfigs?.data,
  ])

  const saveForecastConfigResult = useSiteForecastConfigSaveMutation(Boolean(customConfigFile), formReference, true)
  const saveForecastConfigMutation = saveForecastConfigResult.mutate
  const { mutate: saveForecastConfigTemplateMutation } = useSiteForecastConfigTemplateSaveMutation(formReference)
  const saveMainItemKey = useSiteForecastSaveMainItemKey()

  const savedMainItem = useMemo(() => {
    const mainItem = isNewSiteForecast ? saveMainItemKey.data?.createForecast : saveMainItemKey.data?.updateForecast
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNewSiteForecast, saveMainItemKey.data])

  const handleSaveForecastConfig = useCallback(
    (data: ForecastConfig) => {
      // Handle new schedule
      if (isNewSchedule) {
        data.category = ForecastConfigurationCategory.SITE_SCHEDULE
      }

      if (isCustomConfigFileValid && customConfigFile) {
        saveForecastConfigMutation(customConfigFile)
      } else {
        if (savedMainItem === FormSaveOptions.CREATE_COPY && data?.customer && data.customer && data.id) {
          delete data.customer
          data.name = getCopyName(data?.name, configNames)
          data.id = null
        }

        saveForecastConfigMutation(data)
      }
      setFormChanged(false)
    },
    [saveForecastConfigMutation, isCustomConfigFileValid, customConfigFile, savedMainItem, configNames, isNewSchedule],
  )

  const handleFormSubmit = useCallback(
    (data: ForecastConfig) => {
      const usedDeliveryFormatTemplate = (deliveryFormatTemplates.data || []).find(
        (template) => template.id === data.exportFormatConfigId,
      )
      const needsCreationOfNewDeliveryFormat = usedDeliveryFormatTemplate && !usedDeliveryFormatTemplate.customer
      if (isTemplate) {
        saveForecastConfigTemplateMutation(data)
        setFormChanged(false)
      } else {
        if (needsCreationOfNewDeliveryFormat && usedDeliveryFormatTemplate) {
          usedDeliveryFormatTemplate.id = null
          saveDeliveryFormat(usedDeliveryFormatTemplate).then((template) => {
            data.exportFormatConfigId = template.id
            handleSaveForecastConfig(data)
          })
        } else {
          handleSaveForecastConfig(data)
        }
      }
    },
    [handleSaveForecastConfig, deliveryFormatTemplates.data, isNewTemplate, isTemplate],
  )

  const handleValidateCustomConfig = useCallback(
    (file: File) => {
      setCustomConfigFile(file)
      validateSiteForecastCustomConfig(file).then((response: CustomConfigurationFileResponse) => {
        setFormChanged(true)
        setUploadWarning(response.warnings ? response.warnings : null)
        setUploadErrors(response.errors ? response.errors : null)
        if (!response?.errors?.length) {
          setIsCustomConfigFileValid(true)
          const customConfigData = transformSiteForecastDataForForm(response.productConfigDTO, false, false, user)
          if (customConfigData) {
            customConfigData.exportFormatConfigId = customConfigData.exportFormatConfigId
              ? customConfigData.exportFormatConfigId
              : forecastDetails?.exportFormatConfigId
            customConfigData.ui.hasValidCustomConfigFile = true
          }
          setForecastDetails(customConfigData)
        }
      })
    },
    [forecastDetails, user],
  )

  const handleCloseSlider = useCallback(() => {
    const commonQueryStrings = [QUERY_DATA_STREAM_ID, QUERY_SITE_FORECAST_TEMPLATE, QUERY_COPY_SITE_FORECAST_TEMPLATE]
    const contextQueryStrings =
      isNewTemplate || isNewSiteForecast
        ? [QUERY_NEW_SITE_FORECAST, QUERY_NEW_SITE_FORECAST_TEMPLATE, QUERY_NEW_SCHEDULE]
        : [QUERY_DATA_STREAM_TYPE]
    const queryStrings = [...commonQueryStrings, ...contextQueryStrings]
    onDeleteQueryStrings(queryStrings)
  }, [onDeleteQueryStrings, isNewSiteForecast, isNewTemplate])

  const handleTimingPreview = useCallback((data: TimingPreview) => {
    setTimingPreviewData(data)
  }, [])

  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <SiteForecastForm
          form={form}
          formChanged={Boolean(formChanged)}
          isCustomConfigFileValid={isCustomConfigFileValid}
          setFormChanged={(newValue) => {
            setFormChanged(newValue)
          }}
          saveResult={saveForecastConfigResult}
          handleSubmit={handleSubmit}
          onValidateCustomConfig={handleValidateCustomConfig}
          onCloseSlider={handleCloseSlider}
          uploadErrors={uploadErrors}
          uploadWarnings={uploadWarnings}
          isTemplate={isTemplate}
          deliveryFormatsToDisplay={deliveryFormatsToDisplay}
          onUpdateTimingPreview={handleTimingPreview}
        />
      )
    },
    [saveForecastConfigResult, formChanged, isCustomConfigFileValid, handleValidateCustomConfig, handleCloseSlider],
  )

  const formSubscription = useMemo<FormSubscription>(
    () => ({
      // make sure to have everything listed that is needed in `formRender()`
      // it will enable much faster form rendering
      dirty: true,
      errors: true,
      submitFailed: true,
      dirtyFields: true,

      // WARNING: do NOT add "active" or "values" here because it would rerender all the time
      // instead use <FormSpy> to subscribe to them where it is needed
    }),
    [],
  )

  const loadDataIntoForm = useCallback(() => {
    let details: ForecastConfig | Partial<ForecastConfig> | null = importedConfig
    const forecastList = isNewSiteForecast || isTemplate ? siteForecastConfigTemplates.data : siteForecastConfigs.data

    // Create template by importing a template
    if (!importedConfig?.name || !isNewTemplate) {
      // New template assign details from master data source
      if (isNewTemplate && !isCopyTemplate) {
        details = siteForecastMasterData()
      }
      // Copying and editing template, creating and editing forecast config
      // details are assigned from the forecast list
      else if (forecastList && forecastList.length > 0) {
        details = forecastList.find((forecast) => forecastId === forecast.id) || {}
      }
    }

    if (details && Object.keys(details).length > 0) {
      const transformedData = transformSiteForecastDataForForm(
        details,
        isNewSiteForecast,
        isTemplate,
        user,
        isCopyTemplate,
        deliveryFormatsToDisplay,
      )
      if (isNewSiteForecast) {
        if (details?.name && configNames.includes(details.name)) {
          transformedData.name = createNameSuggestion(details, configNames)
        }
      }
      if (isNewSiteForecast || isCopyTemplate || isNewTemplate) {
        setFormChanged(true)
      }
      setForecastDetails(transformedData)
    }
    saveForecastConfigResult?.reset()
    setIsPageLoading(false)
  }, [
    forecastId,
    siteForecastConfigTemplates.data,
    siteForecastConfigs.data,
    deliveryFormatsToDisplay,
    isNewSiteForecast,
    isNewTemplate,
    isCopyTemplate,
    isTemplate,
    importedConfig,
    configNames,
  ])

  useEffect(() => {
    if (queryParams[QUERY_DATA_STREAM_TYPE] !== siteForecast) return

    if (!siteForecastConfigTemplates.isSuccess && !siteForecastConfigs.isSuccess) return

    if (Boolean(formReference) && formReference.getState().dirty) {
      const formDirtyFields = formReference.getState().dirtyFields
      const formDirty = Object.keys(formDirtyFields).length > 0
      if (formDirty) return
    }

    if (forecastDetails?.id === forecastId) return

    if (!deliveryFormatsToDisplay.length) return
    setIsPageLoading(true)
    loadDataIntoForm()
  }, [
    queryParams,
    loadDataIntoForm,
    forecastDetails?.id,
    forecastId,
    siteForecastConfigTemplates.isSuccess,
    siteForecastConfigs.isSuccess,
    deliveryFormatsToDisplay,
    importedConfig,
  ])

  const detailsNotFoundMsg = useMemo(
    () => c('Site Forecast:Config').t`Site forecast not found. Please select a site forecast from the list.`,
    [],
  )

  return (
    <Container>
      {isPageLoading ? (
        <Box mt={3}>
          <CenteredLoading size="2em" />
        </Box>
      ) : forecastDetails && Object.keys(forecastDetails).length > 0 ? (
        <Form
          mutators={mutators}
          onSubmit={handleFormSubmit}
          initialValues={forecastDetails}
          validate={(values) => validateSiteForecastForm(values, siteForecastConfigs.data || [], timingPreviewData)}
          subscription={formSubscription}
          render={formRender}
        />
      ) : (
        <DetailsNotFound message={detailsNotFoundMsg} onClose={handleCloseSlider} />
      )}
    </Container>
  )
}

export default React.memo(SiteForecastDetails)
