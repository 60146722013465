import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'

// state

interface State {
  loading: boolean
  error: string | null
}

const initialState: State = {
  loading: false,
  error: null,
}

export interface DeleteUserConfigAction {
  type: actionTypes.DELETE_USERCONFIG_ACTION_TYPE
  // REQUEST
  collectionId: string
  configId: string
}

// reducers

const loading = createReducer<State['loading'], DeleteUserConfigAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.DELETE_USERCONFIG_REQUEST:
      return true
    case actionTypes.DELETE_USERCONFIG_SUCCESS:
    case actionTypes.DELETE_USERCONFIG_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], DeleteUserConfigAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.DELETE_USERCONFIG_SUCCESS:
      case actionTypes.DELETE_USERCONFIG_DISMISS:
        return null
      case actionTypes.DELETE_USERCONFIG_FAILURE:
        return error
    }
    return state
  },
)

export const deleteUserConfigReducer = combineReducers({
  loading,
  error,
})

// selectors
export const deleteUserConfigLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.deleteUserConfig.loading,
  (loading) => loading,
)

export const deleteUserConfigErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.deleteUserConfig.error,
  (error) => error,
)
