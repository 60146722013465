import { Box, Typography } from '@material-ui/core'
import { addSeconds } from 'date-fns'
import { Asset } from 'modules/asset/store/asset.types'
import { TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import PenaltyWidget, { PenaltyErrorBar } from 'modules/data/penalties/penaltyCalculations/PenaltyWidget'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { c } from 'ttag'
import InlineLoading from 'ui/InlineLoading'
import Flex from 'ui/styles/Flex'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import { useDataStreams } from 'utils/dataStream'
import { DATE_FORMAT_INTERNAL_LONGER, DateRange, formatUTCDate } from 'utils/date'
import { filterSelectionByChartType, useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import PenaltyCalculationTable from 'modules/data/penalties/penaltyCalculations/PenaltyCalculationTable'

import PenaltyCalculationInput, {
  PenaltyInputData,
} from 'modules/data/penalties/penaltyCalculations/PenaltyCalculationInput'
import { QueryObserverResult } from 'react-query'
import { PenaltyCalculationResult } from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import { useCalculatePenalties } from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'

interface PenaltiesProps {
  assets: Asset[]
  selectedAssetsAndModelIds: string[]
  loading: boolean
  range: DateRange
  widgetName: string
  showTooltip: boolean
  backCastEnabled: boolean
}
const PenaltyCalculations: React.FC<PenaltiesProps> = ({
  assets,
  selectedAssetsAndModelIds,
  loading,
  widgetName,
  showTooltip,
  backCastEnabled,
}) => {
  const chartSelectedDateRange = useWorkspaceChartSelectedDateRange()

  const [penaltyInput, setPenaltyInput] = useState<PenaltyInputData | null>(null)

  const handleUpdatePenaltyInputData = useCallback((data: PenaltyInputData) => {
    setPenaltyInput(data)
  }, [])

  const dateRange: [string, string] = useMemo(
    () => [
      formatUTCDate(addSeconds(new Date(chartSelectedDateRange[0]), 1), DATE_FORMAT_INTERNAL_LONGER),
      formatUTCDate(addSeconds(new Date(chartSelectedDateRange[1]), 1), DATE_FORMAT_INTERNAL_LONGER),
    ],
    [chartSelectedDateRange],
  )

  // TODO user-overridden penalty regulation id (via additional dropdown in widget?)
  const penaltyRegulationId = undefined
  const dataStreams = useDataStreams()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const availableCapacityItem = useMemo(() => {
    const capacityDataSelection = filterSelectionByChartType(dataStreams, dataSelection, TimeSeriesType.CAPACITY_DATA)
    // TODO support multiple selections
    return capacityDataSelection.length > 0 ? capacityDataSelection[0] : undefined
  }, [dataStreams, dataSelection])

  const penaltyResults = useCalculatePenalties({
    dateRange,
    assets,
    selectedAssetsAndModelIds,
    penaltyRegulationId,
    siteForecastId: penaltyInput?.siteForecastId,
    actualDataItem: null, // Currently backend only support Meterdata latest and is taken from backend so we pass null
    availableCapacityItem,
  })

  const penalties = useMemo(
    () =>
      penaltyResults
        .filter((r) => r.isFetched && r.data)
        .map((result) => {
          return result.data.summaryResult as PenaltyCalculationResult
        }),
    [penaltyResults],
  )

  const penaltiesLoading = useMemo(() => penaltyResults.some((r) => r.isLoading) || loading, [penaltyResults, loading])

  // forecast selected can be site forecast or schedule
  const forecastSelected = useMemo(
    () =>
      dataSelection?.some(
        (item) =>
          item.type === TimeSeriesType.SITE_FORECAST ||
          item.type === TimeSeriesType.SCHEDULE ||
          item.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST,
      ),
    [dataSelection],
  )

  return (
    <div>
      <Flex alignItems="center" justifyContent="space-between">
        <Box mb={0.5} display="flex" alignItems="center">
          <Typography variant="subtitle1">{widgetName}</Typography>
          <Box width="2em">{penaltiesLoading && <InlineLoading />}</Box>

          <PenaltyCalculationInput onUpdatePenaltyInput={handleUpdatePenaltyInputData} />
        </Box>
      </Flex>
      {!forecastSelected && (
        <Box mt={1} mb={1}>
          <PenaltyErrorBar>
            {c('Workbench:Penalty')
              .t`In order to calculate penalties, please select a Site Forecast from the Data Streams on the right sidebar.`}
          </PenaltyErrorBar>
        </Box>
      )}

      {forecastSelected && penaltyResults.length > 0 && (
        <>
          <DynamicTableHeightContainer numberOfRows={penaltyResults?.length}>
            <PenaltyCalculationTable
              assets={assets}
              penaltyResults={penaltyResults}
              selectedAssetsAndModelIds={selectedAssetsAndModelIds}
            />
          </DynamicTableHeightContainer>
          <Box>
            {(penaltyResults || []).map((result: QueryObserverResult<PenaltyCalculationResult>, index) => {
              return (
                <PenaltyWidget
                  key={index}
                  penaltyResult={result}
                  siteForecastId={penaltyInput?.siteForecastId}
                  actualGeneration={penaltyInput?.actualGeneration}
                  dateRange={chartSelectedDateRange}
                  allPenalties={penalties}
                  availableCapacityItem={availableCapacityItem}
                  penaltyRegulationId={penaltyRegulationId}
                  showTooltip={showTooltip}
                  backCastEnabled={backCastEnabled}
                />
              )
            })}
          </Box>
        </>
      )}
    </div>
  )
}

export default React.memo(PenaltyCalculations)
