import { combineReducers } from 'redux'
import * as actionTypes from '../auth.action.types'
import createReducer from 'utils/createReducer'
import { createSelector } from 'reselect'

// state
interface State {
  loading: boolean
  error: string | null
}

const initialState: State = {
  loading: false,
  error: null,
}

export interface ActivateUserAction {
  type: actionTypes.ACTIVATE_USER_TYPE
  // REQUEST
  activationKey: string
  // ERROR
  error: string | null
}

const loading = createReducer<State['loading'], ActivateUserAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.ACTIVATE_USER_REQUEST:
      return true
    case actionTypes.ACTIVATE_USER_SUCCESS:
    case actionTypes.ACTIVATE_USER_FAILURE:
    case actionTypes.ACTIVATE_USER_DISMISS:
      return false
  }
  return state
})

const error = createReducer<State['error'], ActivateUserAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.ACTIVATE_USER_SUCCESS:
    case actionTypes.ACTIVATE_USER_DISMISS:
      return null
    case actionTypes.ACTIVATE_USER_FAILURE:
      return error
  }
  return state
})

export const activateUserReducer = combineReducers({
  loading,
  error,
})

// Selectors
export const activateUserLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.activateUser.loading,
  (loading) => loading,
)
export const activateUserErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.activateUser.error,
  (error) => error,
)
