// inspired by https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
import axios, { AxiosResponse } from 'axios'

import { SessionActions, SessionStatus } from 'modules/app/app.types'
import { User } from 'modules/auth/Auth.types'
import { MutationFunction, QueryObserverResult, useMutation, UseMutationResult, useQueryClient } from 'react-query'
import { MutateFunction } from 'react-query/types/core/types'
import { jt, t } from 'ttag'
import { isObject } from 'utils/array'
import { isJson } from 'utils/dataFormatting'
import { isImpersonatedAdmin } from 'utils/user'
import { LinkToContactSupport } from 'ui/elements/MailLink'

// simple error object

export interface Error {
  data?: any
  message?: string
}

// advanced error object

export interface RelatedObject {
  assetId: string
  assetName: string
  conflictingMaintenanceIds: string[]
}

export interface Context {
  customerId: string
  assetId?: string
  relatedObjects: Record<string, RelatedObject>
}

export interface ErrorData {
  code: string
  message: string
  context: Context
  correlationId: string
  docLink: string
}

// result type

export interface Result<T> {
  response?: AxiosResponse<T>
  error?: Error
  hasError: boolean
  hasAuthError: boolean
  isSuccessful: boolean
  getData: () => T
  getError: () => string
}

export const UNAUTHORIZED = 401
export const PAYMENT_REQUIRED = 402
export const FORBIDDEN = 403
export const CONFLICT = 409
export const BAD_REQUEST = 400
export const BAD_GATEWAY = 502
export const ACCOUNT_EXPIRED_ERR_MSG = 'Account expired'

export const BAD_REQUEST_ERROR_MSG = t`Operation could not be completed.`
export const FORBIDDEN_ERROR_MSG = jt`You need additional permissions to perform this action. Please contact ${LinkToContactSupport}.`

// we are handling 503 Service Unavailable server here , because BE needs to improve the way they send error response.
const formatErrorMsg = (msg: string | Record<string, any> | null) => {
  let formattedMsg = msg
  if (typeof msg === 'string') {
    if (msg.includes('exception') || msg.includes('<html')) {
      formattedMsg = BAD_REQUEST_ERROR_MSG
    }
    if (msg.includes('<html')) {
      formattedMsg = BAD_REQUEST_ERROR_MSG
    }
  }
  if (isObject(msg)) {
    formattedMsg = BAD_REQUEST_ERROR_MSG
  }
  return formattedMsg
}

// react-query

export const queryOptionsForStaticData = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  staleTime: Infinity,
}

export interface OptimisticMutationResult<TResult, TError, TVariables, TSnapshot> {
  mutate: MutateFunction<TResult, TError, TVariables, TSnapshot>
  mutationResult: UseMutationResult<TResult, TError>
}

export interface UseOptimisticMutationProps<TResult, TVariables, TSnapshot, TError> {
  queryCacheKey: string | string[]
  apiMutator: MutationFunction<TResult, TVariables>
  cacheUpdater?: (newData: TVariables, oldData: TSnapshot | undefined) => TSnapshot
  onUpdate?: (newData: TVariables) => void
  onSuccess?: (data: TResult, variables: TVariables) => Promise<unknown> | void
  onError?: (error: TError, variables: TVariables, snapshotValue: TSnapshot) => Promise<unknown> | void
  onSettled?: (
    data: undefined | TResult,
    error: TError | null,
    variables: TVariables,
    snapshotValue?: TSnapshot,
  ) => Promise<unknown> | void
}
export const useOptimisticMutation = <
  TResult, // result from the API call. usually an array or object (e.g. a single updated penalty regulation)
  TVariables, // request payload which is sent to the API. might be different than the result (e.g. a single updated penalty regulation)
  TSnapshot = TResult, // data that is saved in the query cache. might be different than the result (e.g. array of penalty regulations)
  TError = Error // data in case of an error (e.g. different error object with message and code)
>({
  queryCacheKey, // cache identifier (usually the same as for the fetcher)
  apiMutator, // async request that actually sends the mutation data to the backend
  cacheUpdater = (newData, oldData) => (newData as unknown) as TSnapshot, // callback that updates the cache with the (successful) backend response
  onUpdate,
  onSuccess = () => undefined,
  onError = () => undefined,
  onSettled = () => undefined,
}: UseOptimisticMutationProps<TResult, TVariables, TSnapshot, TError>) => {
  const queryClient = useQueryClient()

  return useMutation<TResult, TError, TVariables, TSnapshot>(apiMutator, {
    onMutate: (newValue) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryClient.cancelQueries(queryCacheKey)

      // Snapshot the previous value
      const previous = queryClient.getQueryData<TSnapshot>(queryCacheKey)

      // Optimistically update to the new value
      if (onUpdate) {
        // Custom updater (e.g. for updating more than one query key)
        onUpdate(newValue)
      } else {
        // default cache updater
        queryClient.setQueryData<TSnapshot>(queryCacheKey, (oldData) => cacheUpdater(newValue, oldData))
      }

      // Restore the snapshotted value
      return previous as TSnapshot
    },

    onSuccess: (data, variables) => {
      // Custom onSuccess
      onSuccess(data, variables)
    },

    // If the mutation fails, use the value returned from onMutate to roll back
    onError: (error, newData, previous) => {
      // TODO proper handling of failed mutations
      queryClient.setQueryData(queryCacheKey, previous)

      // Custom onError
      onError(error, newData, previous)
    },

    // Always refetch after error or success:
    onSettled: (data, error, variables, snapshotValue) => {
      queryClient.invalidateQueries(queryCacheKey)

      // Custom onSettled
      onSettled(data, error, variables, snapshotValue)
    },
  })
}

// request method

export const apiRequest = async <T extends any>(axiosCallable: () => Promise<AxiosResponse<T>>): Promise<T> => {
  let response: AxiosResponse<T> | undefined
  let data: T | undefined
  let error: Error | undefined

  try {
    response = await axiosCallable()
    // Success 🎉
    data = response.data
    // Remove Banners soon after the connection is established
    import('store').then((module) => {
      const store = module.default
      const session = store.getState().session
      const auth = store.getState().auth
      if (session.sessionStatus !== SessionStatus.AUTHED && auth.getUser.result) {
        store.dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.AUTHED })
      }
      if (session.actionFailed) {
        store.dispatch({ type: SessionActions.SET_ACTION_FAILED, actionFailed: false })
      }
    })
  } catch (catchedError) {
    // Error 😨
    if (catchedError.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      response = catchedError.response
      const errorData = response?.data || null
      const forbiddenErrorMsg = response?.status === FORBIDDEN ? FORBIDDEN_ERROR_MSG : null
      // const badRequestErrorMsg = response?.status === BAD_REQUEST ? BAD_REQUEST_ERROR_MSG : null

      const message =
        (response ? errorData?.message || errorData?.error || errorData?.status || errorData?.cause : null) ||
        data ||
        forbiddenErrorMsg

      error = {
        data: errorData,
        message: formatErrorMsg(message),
      }

      // handling for expired sessions
      // note: need to be a dynamic import to not break store initialization
      import('store').then((module) => {
        const store = module.default
        const session = store.getState().session
        if (response?.status === UNAUTHORIZED) {
          if (session.initiateReload) return
          if (session.sessionStatus === SessionStatus.AUTHED) {
            store.dispatch({ type: SessionActions.SET_ACTION_FAILED, actionFailed: true })
          }
          if (session.sessionStatus !== SessionStatus.EXPIRED) {
            store.dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.EXPIRED })
          }
          if (session.sessionRecovering) {
            store.dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: false })
          }
        }
      })
    } else if (catchedError.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */

      // handling for expired sessions
      // note: need to be a dynamic import to not break store initialization
      import('store').then((module) => {
        // on page reload (go to main url or refresh page) we don't want to trigger connection lost dialog
        // therefore let's wait some seconds before activating it
        setTimeout(() => {
          const store = module.default
          const session = store.getState().session

          if (session.initiateReload) return
          if (session.sessionStatus === SessionStatus.AUTHED) {
            store.dispatch({ type: SessionActions.SET_ACTION_FAILED, actionFailed: true })
          }
          if (session.sessionStatus !== SessionStatus.OFFLINE) {
            store.dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.OFFLINE })
          }
          if (session.sessionRecovering) {
            store.dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: false })
          }
        }, 500)
      })

      error = {
        message: t`Request sent, but received no response from server`,
      }
    } else {
      // Something happened in setting up the request and triggered an Error
      error = {
        message: t`Could not send request`,
      }
    }
  }

  const hasError = Boolean(error)
  const hasAuthError = hasError && response && [UNAUTHORIZED, FORBIDDEN].includes(response.status)

  if (hasError) {
    const message = hasAuthError
      ? t`You are not authorized`
      : (isJson(error?.data) ? JSON.stringify(error?.data) : null) || error?.message || BAD_REQUEST_ERROR_MSG

    const MAX_LENGTH = Number.MAX_SAFE_INTEGER
    const croppedMessage =
      typeof message === 'string' && message?.length > MAX_LENGTH
        ? message.substring(0, MAX_LENGTH - 3) + '...'
        : message
    throw new Error(croppedMessage)
  }

  return data as T
}

// old request method

export const request = async <T extends unknown>(
  axiosCallable: () => Promise<AxiosResponse<T>>,
): Promise<Result<T>> => {
  let result: any

  try {
    const response: AxiosResponse<T> = await axiosCallable()
    // Success 🎉
    result = {
      response,
    }
    // Remove Banners soon after the connection is established
    import('store').then((module) => {
      const store = module.default
      const session = store.getState().session
      const auth = store.getState().auth
      if (session.sessionStatus !== SessionStatus.AUTHED && auth.getUser.result) {
        store.dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.AUTHED })
      }
      if (session.actionFailed) {
        store.dispatch({ type: SessionActions.SET_ACTION_FAILED, actionFailed: false })
      }
    })
  } catch (error) {
    // Error 😨
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      const { response } = error
      const { data } = response

      const forbiddenErrorMsg = response.status === FORBIDDEN ? FORBIDDEN_ERROR_MSG : null

      // const badRequestErrorMsg = response?.status === BAD_REQUEST ? BAD_REQUEST_ERROR_MSG : null

      // TODO need to replace the badRequestErrorMsg with the meaningFul message from the backend
      const message =
        (data?.message !== 'No message available' ? data?.message : data?.error) ||
        forbiddenErrorMsg ||
        data?.status ||
        data

      result = {
        response,
        error: {
          data,
          message: formatErrorMsg(message),
        },
      }

      // handling for expired sessions
      // note: need to be a dynamic import to not break store initialization
      import('store').then((module) => {
        const store = module.default
        const session = store.getState().session
        if (response.status === UNAUTHORIZED) {
          if (session.initiateReload) return
          if (session.sessionStatus === SessionStatus.AUTHED) {
            store.dispatch({ type: SessionActions.SET_ACTION_FAILED, actionFailed: true })
          }
          if (session.sessionStatus !== SessionStatus.EXPIRED) {
            store.dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.EXPIRED })
          }
          if (session.sessionRecovering) {
            store.dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: false })
          }
        }
      })
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */

      // handling for expired sessions
      // note: need to be a dynamic import to not break store initialization
      import('store').then((module) => {
        // on page reload (go to main url or refresh page) we don't want to trigger connection lost dialog
        // therefore let's wait some seconds before activating it
        setTimeout(() => {
          const store = module.default
          const session = store.getState().session

          if (session.initiateReload) return
          if (session.sessionStatus === SessionStatus.AUTHED) {
            store.dispatch({ type: SessionActions.SET_ACTION_FAILED, actionFailed: true })
          }
          if (session.sessionStatus !== SessionStatus.OFFLINE) {
            store.dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.OFFLINE })
          }
          if (session.sessionRecovering) {
            store.dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: false })
          }
        }, 500)
      })

      result = {
        error: {
          message: t`Request sent, but received no response from server`,
        },
      }
    } else {
      // Something happened in setting up the request and triggered an Error
      result = {
        error: {
          message: t`Could not send request`,
        },
      }
    }
    console.error(result, error)
  }

  const hasError = Boolean(result.error && 'message' in result.error)
  const hasAuthError = hasError && result.response && [UNAUTHORIZED, FORBIDDEN].includes(result.response.status)
  const isSuccessful = !hasError && result.response && 'data' in result.response

  const getData = () => result.response?.data
  const getError = () => {
    const message =
      result.error?.message ||
      (result.error?.data ? JSON.stringify(result.error?.data) : t`Service not reachable, please try again later`)

    const MAX_LENGTH = 500
    const croppedMessage = message?.length > MAX_LENGTH ? message.substring(0, MAX_LENGTH - 3) + '...' : message

    return croppedMessage
  }

  return {
    ...result,
    hasError,
    hasAuthError,
    isSuccessful,
    getData,
    getError,
  }
}

// react-query helpers

export const mergeQueryResults = <TData, TError>(
  queryResults: QueryObserverResult<TData, TError>[],
  mergedData?: TData | undefined,
): QueryObserverResult<TData, TError> => {
  // TODO this merging is not perfect
  //  because first of all it does not contain all react-query result fields
  //  and also the merged info might be insufficient
  //  see also: https://react-query.tanstack.com/reference/useQuery

  const initialQueryResult = {
    ...queryResults[0],
    data: mergedData,
  } as QueryObserverResult<TData, TError>

  return queryResults.reduce((mergedQueryResult, queryResult) => {
    return {
      ...mergedQueryResult,
      isError: mergedQueryResult.isError || queryResult.isError,
      isFetched: mergedQueryResult.isFetched && queryResult.isFetched,
      isFetchedAfterMount: mergedQueryResult.isFetchedAfterMount && queryResult.isFetchedAfterMount,
      isFetching: mergedQueryResult.isFetching || queryResult.isFetching,
      isIdle: mergedQueryResult.isIdle && queryResult.isIdle,
      isLoading: mergedQueryResult.isLoading || queryResult.isLoading,
      isLoadingError: mergedQueryResult.isLoadingError || queryResult.isLoadingError,
      isPlaceholderData: mergedQueryResult.isPlaceholderData && queryResult.isPlaceholderData,
      isPreviousData: mergedQueryResult.isPreviousData && queryResult.isPreviousData,
      isRefetchError: mergedQueryResult.isRefetchError || queryResult.isRefetchError,
      isStale: mergedQueryResult.isStale && queryResult.isStale,
      isSuccess: mergedQueryResult.isSuccess && queryResult.isSuccess,
    } as QueryObserverResult<TData, TError>
  }, initialQueryResult)
}

// Switch to Classic UI

export const switchToClassicUI = (user: User) => {
  const switchUi = () => {
    window.location.assign('/classic')
  }

  if (process.env.NODE_ENV === 'development') {
    window.location.assign('http://localhost:3000')
  } else if (isImpersonatedAdmin(user)) {
    switchUi()
  } else {
    axios
      .post('api/usersettings/save/prefersBeta', false, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .finally(switchUi)
  }
}
