import React, { useCallback, useEffect, useState } from 'react'
import Flex from '../../../ui/styles/Flex'
import ChartDataRangeSelector from './ChartDataRangeSelector'
import AggregationMenu from './AggregationMenu'
import WidgetMenu from './WidgetMenu'
import WorkspaceRangeNavigatorButtons from './WorkspaceRangeNavigatorButtons'
import { Box } from '@material-ui/core'
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
  convertZonedTimeToUtc,
  DATE_FORMAT_DEFAULT_SHORT,
  DATE_FORMAT_DEFAULT_WITH_SECONDS,
  formatDate,
  formatUTCDate,
} from '../../../utils/date'
import { t } from 'ttag'
import TimezoneInfo from '../../../ui/datetime/TimezoneInfo'
import LineChartSettingsMenu from '../LineChartSettings'
import {
  ChartAggregationMode,
  ChartDataRange,
  ChartWidget,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { DeepPartial } from 'ts-essentials'
import { createDefaultDateRange, useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { useDispatch, useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { theme } from 'themes/theme-light'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { ScreenSize, useScreenSizeLessThanGivenSize } from 'utils/hooks/responsiveUI/useScreenSize'
import Joyride, { Step } from 'react-joyride'
import { AppTourColour } from 'modules/app/tour/AppTour'
import RefreshSeriesButton from 'ui/elements/RefreshSeriesButton'
import UpdateScheduleSeriesMode from 'modules/workspace/schedule/UpdateScheduleSeriesMode'
import { QUERY_SCHEDULE, useQueryMatch } from 'utils/query-string'

const Container = styled.div`
  margin-left: 12px;
  margin-right: 16px;
  margin-top: -3px;
`

interface ChartToolbarInterface {
  triggeredBackcastCreation: boolean
  setTriggeredBackcastCreation: (value: boolean) => void
}

const ChartToolbar: React.FC<ChartToolbarInterface> = ({ triggeredBackcastCreation, setTriggeredBackcastCreation }) => {
  const dispatch = useDispatch()
  const isSmallScreen = useScreenSizeLessThanGivenSize(ScreenSize.md)
  const isExtraSmallScreen = useScreenSizeLessThanGivenSize(ScreenSize.sm)
  const isCreateScheduleActive = useQueryMatch(QUERY_SCHEDULE)

  const timezone = useSelector(getUserTimezoneSelector)

  const chartSelectedDateRange = useWorkspaceChartSelectedDateRange()

  const [chartInfoMsg, setChartInfoMsg] = useState('')

  const handleChangeDataRange = useCallback(
    (selectedDataRange: ChartDataRange) => {
      const datesInUTC = selectedDataRange?.customRange
        ? [
            convertZonedTimeToUtc(convertLocalDateToUTC(selectedDataRange?.customRange[0]), timezone),
            convertZonedTimeToUtc(convertLocalDateToUTC(selectedDataRange?.customRange[1]), timezone),
          ]
        : null

      const draft: DeepPartial<WorkspaceConfig> = {
        chart: {
          range: createDefaultDateRange(),
          dataRange: {
            rangeType: selectedDataRange.rangeType,
            customRange: datesInUTC
              ? [
                  formatUTCDate(datesInUTC[0], DATE_FORMAT_DEFAULT_WITH_SECONDS),
                  formatUTCDate(datesInUTC[1], DATE_FORMAT_DEFAULT_WITH_SECONDS),
                ]
              : null,
          },
        },
      }

      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    },
    [timezone],
  )

  const handleChangeAggregationMode = useCallback((aggregationMode: ChartAggregationMode) => {
    const draft: DeepPartial<WorkspaceConfig> = {
      chart: { aggregationMode },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }, [])

  const handleChangeWidgets = useCallback((widgets: ChartWidget[]) => {
    const draft: DeepPartial<WorkspaceConfig> = {
      chart: { widgets },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }, [])

  const handleChangeChartInfoMsg = (msg: string) => {
    setChartInfoMsg(msg)
  }

  // this step is used when we automatically enable show backcast
  // only for information purpose
  const helperStep: Step[] = [
    {
      target: '.parent-line-chart-settings-menu',
      content: t`Rendering of backcasts in the chart has been enabled. You can disable it here.`,
      spotlightClicks: true,
      disableBeacon: true, // Open the dialog by default
      hideCloseButton: false,
      hideFooter: true,
      placement: 'right',
    },
  ]

  useEffect(() => {
    let timer: any
    if (triggeredBackcastCreation) {
      // 10 sec delay
      const delay = 3
      timer = setTimeout(() => setTriggeredBackcastCreation(false), delay * 1000)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [triggeredBackcastCreation])

  return (
    <Container>
      <Flex alignItems="center" flexWrap="wrap" justifyContent="space-between">
        <Flex flexWrap="wrap" flexGrow={1} alignItems="center">
          <ChartDataRangeSelector
            onChangeRange={handleChangeDataRange}
            onChangeChartInfoMsg={handleChangeChartInfoMsg}
            hideLabel={isSmallScreen}
          />
          <Box ml={0.5} mr={isSmallScreen ? 1.5 : 0.5}>
            <AggregationMenu hideLabel={isSmallScreen} onChangeMode={handleChangeAggregationMode} />
          </Box>

          <WidgetMenu hideLabel={isSmallScreen} onChangeWidgets={handleChangeWidgets} />

          {isCreateScheduleActive && <UpdateScheduleSeriesMode hideLabel={isSmallScreen} disablePointMode={false} />}

          <div className="parent-line-chart-settings-menu">
            <LineChartSettingsMenu />
          </div>

          <RefreshSeriesButton />

          {triggeredBackcastCreation && (
            <Joyride
              steps={helperStep}
              disableOverlay={true}
              spotlightPadding={-10}
              styles={{
                options: {
                  arrowColor: AppTourColour,
                  backgroundColor: AppTourColour,
                  textColor: 'black',
                },
                tooltip: {
                  padding: 0,
                },
              }}
            />
          )}
        </Flex>

        <Flex flexWrap="wrap" alignItems="center" justifyContent="flex-end">
          <WorkspaceRangeNavigatorButtons onRangeChange={handleChangeDataRange} />
          <Flex flexWrap="wrap">
            <Flex direction={isExtraSmallScreen ? 'column' : 'row'} flexWrap="wrap">
              <Flex>
                {formatDate(convertUTCToLocalDate(chartSelectedDateRange[0]), timezone, DATE_FORMAT_DEFAULT_SHORT)}
                <Box mr={0.5} ml={0.5}>{t` to `}</Box>
              </Flex>

              <Flex>
                {formatDate(convertUTCToLocalDate(chartSelectedDateRange[1]), timezone, DATE_FORMAT_DEFAULT_SHORT)}
              </Flex>
              <Box display="flex" flexDirection="row" ml={isExtraSmallScreen ? 0 : 0.3}>
                (<TimezoneInfo showHeading={false} />)
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {chartInfoMsg && (
        <Flex alignItems="center" style={{ marginLeft: '1.5em', color: theme.palette.secondary.main }}>
          <FontAwesomeIcon size="xs" icon="info" /> <Box ml={0.5}>{chartInfoMsg}</Box>
        </Flex>
      )}
    </Container>
  )
}

export default React.memo(ChartToolbar)
