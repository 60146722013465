import React from 'react'
import { t } from 'ttag'
import { RangeSelector } from 'react-jsx-highstock'

const ResetZoomButton: React.FC = () => {
  return (
    <RangeSelector
      buttonTheme={{
        fill: '#e0e0e0',
        width: '20px',
        style: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontWeight: 'normal',
        },
        states: {
          hover: {
            fill: '#e0e0e0',
            width: '20px',
            style: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: 'normal',
            },
          },
          select: {
            fill: '#e0e0e0',
            width: '20px',
            style: {
              color: 'rgba(0, 0, 0, 0.87)',
              fontWeight: 'normal',
            },
          },
        },
      }}
      labelStyle={{ display: 'none' }}
      floating={true}
      x={-84}
      y={-8}
      verticalAlign="bottom"
    >
      <RangeSelector.Button offsetMin={0} offsetMax={0} type="all">{t`Zoom Reset`}</RangeSelector.Button>
    </RangeSelector>
  )
}

export default ResetZoomButton
