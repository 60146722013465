import createReducer from 'utils/createReducer'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import * as actionTypes from 'modules/dataStreams/areaForecast/redux_store/areaForecast.action.types'

interface State {
  subscribedProductCodes: string[]
}

const initialState: State = {
  subscribedProductCodes: [],
}

export interface SubscribedProductCodesAction {
  type: actionTypes.GET_SUBSCRIBED_PRODUCT_CODES_ACTION_TYPE
  codes: string[]
}

const subscribedProductCodes = createReducer<State['subscribedProductCodes'], SubscribedProductCodesAction>(
  (state = initialState.subscribedProductCodes, { type, codes }) => {
    if (type === actionTypes.GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS) {
      return codes
    }
    return state
  },
)

export const areaForecastProductsReducer = combineReducers({
  subscribedProductCodes,
})

export const getSubscribedProductCodesResultSelector = createSelector<
  any,
  State['subscribedProductCodes'],
  State['subscribedProductCodes']
>(
  (state) => state.dataStreamsMgmnt.areaForecasts.areaForecastsProducts.subscribedProductCodes,
  (subscribedProductCodes) => subscribedProductCodes,
)
