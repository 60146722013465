import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { UseMutationResult } from 'react-query'
import { AnyObject, FormSpy, useField } from 'react-final-form'
import { Box, Typography } from '@material-ui/core'
import { FormApi } from 'final-form'
import { c, t } from 'ttag'

import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import SectionForecastSites from 'modules/dataStreams/siteForecast/SectionForecastSites'
import SectionHeaderSiteForecast from 'modules/dataStreams/siteForecast/SectionHeaderSiteForecast'
import SectionTimingPreview from 'modules/dataStreams/siteForecast/SectionTimingPreview'
import SectionForecastQualityEvaluations from 'modules/dataStreams/siteForecast/SectionForecastQualityEvaluations'
import SectionDeliveryTargetAndFormat from 'modules/dataStreams/siteForecast/SectionDeliveryTargetAndFormat'
import SectionForecast from 'modules/dataStreams/siteForecast/SectionForecast'
import SectionDelivery from 'modules/dataStreams/siteForecast/SectionDelivery'
import SectionDeliveryWindowSettings from 'modules/dataStreams/siteForecast/SectionDeliveryWindowSettings'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import {
  QUERY_DATA_STREAM_ID,
  QUERY_DATA_STREAM_TYPE,
  QUERY_LANG_CHANGED,
  QUERY_NEW_SITE_FORECAST,
  QueryParamType,
  useQueryMatch,
  useQueryParams,
} from 'utils/query-string'
import { DEFAULT_FORECAST_CONFIG_ID } from 'utils/dataStream'
import SectionCustomConfiguration from 'modules/dataStreams/siteForecast/SectionCustomConfiguration'
import qs from 'query-string'
import { blockCommonQueries, UnsavedChangesContext } from 'ui/elements/BlockNavigationDialog'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { TimingPreview } from 'modules/dataStreams/siteForecast/SiteForecastDetails'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import { FormContainer } from 'ui/form/form.style'
import { addDeliveryTargetToSiteForecastSteps, createSiteForecastSteps } from 'modules/app/tour/appTourSteps'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { TourStepCategory } from 'modules/app/tour/appTour.types'
import { AddTourStepsTime, DeliveryUsed, useAtLeastOneDeliveryIsUsed } from 'modules/app/tour/appTourUtils'
import { useDeliveryTargetsTableItems } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import { AssetFormLabel, FormKey, FormKeyValueGrid, FormValue, KeyValuePair } from 'ui/form/assetForm.style'
import SectionFecsProcessing from 'modules/dataStreams/siteForecast/SectionFecsProcessing'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import CopyToClipboard from 'ui/elements/CopyToClipboard'
import SectionModelType from 'modules/dataStreams/siteForecast/SectionModelType'

const TimingPreviewContainer = styled.div`
  @media (max-width: 1024px) {
    position: relative;
    margin-top: 1em;
  }
  position: fixed;
  margin-left: 1em;
`

const FormBody = styled(Flex)`
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`

const FormSections = styled(Flex)`
  @media (max-width: 1024px) {
    flex-grow: 1;
  }
`

interface DetailsContainerProps {
  disable: boolean
}

export const DisableContainer = styled.div<DetailsContainerProps>`
  pointer-events: ${(props) => (props.disable ? 'none' : 'inherit')};
  opacity: ${(props) => (props.disable ? '0.6' : 'inherit')};
`

export enum ForecastFormFlexWidth {
  leftColumn = '54.5%',
  rightColumn = '46%',
  largeScreenRightColumn = '82%',
}

interface SiteForecastFormProps {
  form: FormApi<ForecastConfig>
  formChanged: boolean
  setFormChanged: (formChanged: boolean) => void
  saveResult: UseMutationResult<ForecastConfig, Error>
  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  onValidateCustomConfig: (file: File) => void
  isCustomConfigFileValid: boolean
  onCloseSlider: () => void
  uploadWarnings: string[] | null
  uploadErrors: string[] | null
  isTemplate: boolean
  deliveryFormatsToDisplay: DeliveryFormat[]
  onUpdateTimingPreview: (data: TimingPreview) => void
}

const SiteForecastForm: React.FC<SiteForecastFormProps> = ({
  form,
  formChanged,
  setFormChanged,
  saveResult,
  handleSubmit,
  onValidateCustomConfig,
  isCustomConfigFileValid,
  onCloseSlider,
  uploadWarnings,
  uploadErrors,
  isTemplate,
  deliveryFormatsToDisplay,
  onUpdateTimingPreview,
}) => {
  const { addTourSteps, isTourActive } = useContext(AppTourContext)
  const siteForecastsResult = useSiteForecastConfigs()
  const deliveryTargetsResult = useDeliveryTargetsTableItems()
  const atLeastOneDeliveryIsAddedToSiteForecast = useAtLeastOneDeliveryIsUsed()
  const user = useSelector(getUserResultSelector)
  const isNewForecast = useQueryMatch(QUERY_NEW_SITE_FORECAST)

  const forecastDetails = form.getState().values
  const { queryParams } = useQueryParams()
  const isCustomConfig = useField<boolean>('customConfig').input.value
  const forecastName = useField<string>('name').input.value
  const customUnRemovable = useMemo(() => forecastDetails.id && isCustomConfig, [forecastDetails, isCustomConfig])

  const isMaximumHorizonWithoutPermission =
    forecastDetails.maximumHorizon && !isAdmin(user) && !isImpersonatedAdmin(user)

  const isDefaultDataStream = useMemo(() => queryParams[QUERY_DATA_STREAM_ID] === DEFAULT_FORECAST_CONFIG_ID, [
    queryParams,
  ])
  const handleFormChange = useCallback(() => {
    setFormChanged(true)
  }, [])

  const disableForm = useMemo(
    () => isDefaultDataStream || isMaximumHorizonWithoutPermission || isCustomConfig || isCustomConfigFileValid,
    [isDefaultDataStream, isCustomConfig, isCustomConfigFileValid, forecastDetails, user],
  )

  // block navigation handling
  const { addNavigationBlockHandler, removeNavigationBlockHandler } = useContext(UnsavedChangesContext)
  const formDirtyFields = useMemo(() => form.getState().dirtyFields, [form.getState().dirtyFields])
  const formDirty = useMemo(() => {
    return Object.keys(formDirtyFields).length > 0
    // return Object.keys(formDirtyFields).filter((dirtyField) => !dirtyField.startsWith('ui.')).length > 0
  }, [formDirtyFields])

  const blockNavigation = useMemo(() => formDirty || formChanged, [formDirty, formChanged])

  const checkNavigationWillDestroyChanges = useCallback(
    (currentLocation: Location, nextLocation: Location) => {
      const isBlocking = blockNavigation && !siteForecastsResult.isLoading
      let isDestroying = false
      if (isBlocking) {
        const nextParams = qs.parse(nextLocation.search) as QueryParamType
        const currentParams = qs.parse(currentLocation.search) as QueryParamType
        const navigateToAnotherConfigType = nextParams[QUERY_DATA_STREAM_TYPE] !== currentParams[QUERY_DATA_STREAM_TYPE]
        const navigateToAnotherConfigId = nextParams[QUERY_DATA_STREAM_ID] !== currentParams[QUERY_DATA_STREAM_ID]
        isDestroying = Boolean(
          navigateToAnotherConfigType ||
            navigateToAnotherConfigId ||
            !nextParams[QUERY_DATA_STREAM_TYPE] ||
            !nextParams[QUERY_DATA_STREAM_ID] ||
            nextParams[QUERY_LANG_CHANGED] ||
            blockCommonQueries.some((query) => nextParams[query]),
        )
      }

      return {
        isBlocking,
        isDestroying,
      }
    },
    [blockNavigation, siteForecastsResult.isLoading],
  )

  const abortNavigation = useCallback(() => {
    // setOpenDialog(false)
  }, [])

  const proceedNavigation = useCallback(() => {
    setFormChanged(false)
    form.reset()
  }, [Boolean(form)])

  useEffect(() => {
    if (addNavigationBlockHandler && removeNavigationBlockHandler) {
      if (queryParams[QUERY_DATA_STREAM_TYPE] && queryParams[QUERY_DATA_STREAM_ID]) {
        addNavigationBlockHandler({
          siteForecastDetails: {
            dialogText: <>{`"${forecastName}" ${c('Site Forecast:Confirmation dialog').t`Details`}`}</>,
            checkNavigationWillDestroyChanges: checkNavigationWillDestroyChanges,
            abortNavigation: abortNavigation,
            proceedNavigation: proceedNavigation,
          },
        })
      } else {
        removeNavigationBlockHandler('siteForecastDetails')
      }
    }
  }, [
    location,
    forecastName,
    checkNavigationWillDestroyChanges,
    queryParams[QUERY_DATA_STREAM_TYPE],
    queryParams[QUERY_DATA_STREAM_ID],
    abortNavigation,
    proceedNavigation,
    addNavigationBlockHandler,
    removeNavigationBlockHandler,
  ])

  // console.log('errors =', form.getState().errors)
  // console.log('values =', form.getState())

  useEffect(() => {
    if (isCustomConfig && !forecastDetails.id && !forecastDetails.customer) {
      form.mutators.setFieldTouched('name', true)
    }
  }, [isCustomConfig, forecastDetails])

  const isNewCustomConfig = useMemo(() => {
    return isCustomConfig && !forecastDetails.id
  }, [isCustomConfig, forecastDetails])

  // const handleCloseTour = useCallback(() => {
  //   if (typeof removeTourSteps === 'function' && isTourActive) removeTourSteps(AppTours.ADD_SITE_FORECAST)
  // }, [removeTourSteps, isTourActive])

  // Add Steps to create site forecast
  useEffect(() => {
    const addSteps =
      siteForecastsResult?.data?.length == 1 && typeof addTourSteps === 'function' && isTourActive && isNewForecast
    if (!addSteps) return
    const steps = createSiteForecastSteps().filter((item) => item.stepCategory === TourStepCategory.FORM)
    const createSiteForecastStepsTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (createSiteForecastStepsTimer) clearTimeout(createSiteForecastStepsTimer)
    }
  }, [siteForecastsResult?.data, addTourSteps, isNewForecast, isTourActive])

  // Add Steps to assign delivery target to site forecast
  useEffect(() => {
    const addSteps =
      isTourActive &&
      atLeastOneDeliveryIsAddedToSiteForecast === DeliveryUsed.NO &&
      siteForecastsResult?.data?.length > 1 &&
      deliveryTargetsResult?.data?.length > 1
    if (!addSteps) return
    const steps = addDeliveryTargetToSiteForecastSteps().filter((item) => item.stepCategory === TourStepCategory.FORM)
    const assignDeliveryTargetToSiteForecastTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (assignDeliveryTargetToSiteForecastTimer) clearTimeout(assignDeliveryTargetToSiteForecastTimer)
    }
  }, [
    addTourSteps,
    siteForecastsResult?.data,
    deliveryTargetsResult?.data,
    isTourActive,
    atLeastOneDeliveryIsAddedToSiteForecast,
  ])

  // useEffect(() => {
  //   if (saveResult?.isSuccess) {
  //     handleCloseTour()
  //   }
  // }, [saveResult?.isSuccess, handleCloseTour])
  //
  // useEffect(() => {
  //   return () => {
  //     console.log('handle close')
  //     handleCloseTour()
  //   }
  // }, [handleCloseTour])

  // Navigate to workbench if at least one delivery is assigned to siteforecast
  // useEffect(() => {
  //   if (atLeastOneDeliveryIsAddedToSiteForecast === DeliveryUsed.YES) return
  //   if (!saveResult?.isSuccess) return
  //   const NavigateToWorkbenchTimer = setTimeout(() => {
  //     removeTourSteps(AppTours.ADD_DELIVERY_TARGET_TO_SITE_FORECAST)
  //     history.push(ROUTE_WORKBENCH)
  //   }, 2000)
  //   return () => {
  //     if (NavigateToWorkbenchTimer) clearTimeout(NavigateToWorkbenchTimer)
  //   }
  // }, [atLeastOneDeliveryIsAddedToSiteForecast, saveResult?.isSuccess])

  return (
    <FormContainer nopadding={1}>
      <form onSubmit={handleSubmit} noValidate>
        <Flex direction="column" fullHeight>
          <SectionHeaderSiteForecast
            form={form}
            isNewCustomConfig={isNewCustomConfig}
            isDefaultDataStream={isDefaultDataStream}
            enableSaveBtn={formDirty || formChanged}
            onCloseSlider={onCloseSlider}
            saveResult={saveResult}
            isTemplate={isTemplate}
            onFormSubmit={handleSubmit}
          />

          <FormBody alignItems="space-between" justifyContent="space-between">
            <FormSections direction="column" flexBasis={ForecastFormFlexWidth.leftColumn}>
              <KeyValuePair>
                {!isTemplate && (
                  <SectionCustomConfiguration
                    isDefaultDataStream={isDefaultDataStream}
                    onValidateCustomConfig={onValidateCustomConfig}
                    uploadErrors={uploadErrors}
                    uploadWarnings={uploadWarnings}
                    forecastDetails={forecastDetails}
                  />
                )}
                <DisableContainer disable={disableForm}>
                  <Box mb={1.5}>
                    <SectionForecast form={form} />
                  </Box>

                  <Box mb={1.5}>
                    <SectionDelivery disableForm={disableForm} form={form} onFormChange={handleFormChange} />
                  </Box>

                  <Box mb={1.5}>
                    <SectionDeliveryWindowSettings />
                  </Box>
                </DisableContainer>

                <DisableContainer disable={customUnRemovable ? isCustomConfigFileValid : disableForm}>
                  <Box mb={1.5}>
                    <SectionForecastQualityEvaluations onFormChange={handleFormChange} form={form} />
                  </Box>
                </DisableContainer>

                <Box>
                  <Typography variant="subtitle2">{c('Delivery').t`Delivery`}</Typography>

                  <FormKeyValueGrid>
                    <FormKey>
                      <AssetFormLabel>{t`Data stream ID`}:</AssetFormLabel>
                    </FormKey>
                    <FormValue>
                      <Flex alignItems="center">
                        <div>{forecastDetails?.id}</div>
                        <CopyToClipboard title={t`Copy data stream ID`} textToCopy={forecastDetails?.id || ''} />
                      </Flex>
                    </FormValue>
                  </FormKeyValueGrid>

                  <DisableContainer disable={disableForm}>
                    <SectionDeliveryTargetAndFormat deliveryFormatsToDisplay={deliveryFormatsToDisplay} form={form} />
                  </DisableContainer>
                  {!isTemplate && (
                    <DisableContainer disable={customUnRemovable ? isCustomConfigFileValid : disableForm}>
                      <SectionForecastSites form={form} onFormChange={handleFormChange} />
                    </DisableContainer>
                  )}
                </Box>
                {(isAdmin(user) || isImpersonatedAdmin(user)) && (
                  <Box>
                    <SectionFecsProcessing disableFecs={!forecastDetails.useFecs} />
                  </Box>
                )}

                <Box>
                  <SectionModelType disableFecs={!forecastDetails.useFecs} />
                </Box>
              </KeyValuePair>
            </FormSections>

            <FormSections
              flexBasis={ForecastFormFlexWidth.rightColumn}
              largeScreenFlexBasis={ForecastFormFlexWidth.largeScreenRightColumn}
              direction="column"
            >
              <DisableContainer disable={disableForm}>
                <TimingPreviewContainer>
                  <FormSpy subscription={{ values: true }}>
                    {({ values }) => {
                      return (
                        <SectionTimingPreview onUpdateTimingPreview={onUpdateTimingPreview} forecastDetails={values} />
                      )
                    }}
                  </FormSpy>
                </TimingPreviewContainer>
              </DisableContainer>
            </FormSections>
          </FormBody>
        </Flex>
      </form>
    </FormContainer>
  )
}

export default React.memo(SiteForecastForm)
