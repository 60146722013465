import React from 'react'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const PrintViewFooter: React.FC = () => {
  const date = new Date()
  const user = useSelector(getUserResultSelector)

  return (
    <Flex style={{ height: '4em' }} direction="row" alignItems="center" justifyContent="space-between">
      <div>{user && <>{user.login}</>}</div>
      <div>
        © {date.getFullYear()} enercast GmbH | {t`Proprietary and Confidential`}
      </div>
    </Flex>
  )
}

export default PrintViewFooter
