import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { c } from 'ttag'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useDataStreams } from 'utils/dataStream'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { filterSelectionByChartType } from 'utils/workspace'

const InputSelector = styled(FormControl)`
  &.MuiFormControl-root {
    margin-top: -1em;
    width: 13em;
  }
`

interface MeterDataSelectInput {
  disabled: boolean
  onChangeMeterDataSelection: (meterDataId: string) => void
  showSelectedItems: boolean
}

const MeterDataSelectInput: React.FC<MeterDataSelectInput> = ({
  disabled,
  onChangeMeterDataSelection,
  showSelectedItems,
}) => {
  const dataStreams = useDataStreams()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const [selectedMeterDataId, setSelectedMeterDataId] = useState('')

  const selectedActualGenerationItems = useMemo(() => {
    if (showSelectedItems) {
      const selectedActualGenerationDataStreams = filterSelectionByChartType(
        dataStreams,
        dataSelection,
        TimeSeriesType.METER_DATA,
      )

      return selectedActualGenerationDataStreams.length > 0
        ? selectedActualGenerationDataStreams.map((selectedItem) => {
            const item = dataStreams.find((ds) => ds.id === selectedItem.id)
            return { ...selectedItem, name: item.name }
          })
        : undefined
    } else {
      // Always take the Meter data latest
      const actualGenerationDataStreams = dataStreams?.filter((item) => item.id === 'METER_DATA')
      return actualGenerationDataStreams
    }
  }, [dataStreams, dataSelection, showSelectedItems])

  const handleChange = useCallback((event) => {
    const value = (event?.target?.value || '') as string
    setSelectedMeterDataId(value)
  }, [])

  useEffect(() => {
    if (selectedActualGenerationItems && selectedActualGenerationItems.length > 0) {
      const actualGenerationId = selectedActualGenerationItems[selectedActualGenerationItems.length - 1]?.id as string
      setSelectedMeterDataId(actualGenerationId)
    } else {
      // When we don't have any selected item , we need to have as input value empty and not the previous selected item from prev state
      setSelectedMeterDataId('')
    }
  }, [JSON.stringify(selectedActualGenerationItems)])

  useEffect(() => {
    onChangeMeterDataSelection(selectedMeterDataId)
  }, [selectedMeterDataId])

  return (
    <InputSelector disabled={disabled} variant="standard" fullWidth>
      <InputLabel id="meter-data-label">{c('Data Stream').t`Actual Generation`}</InputLabel>
      <Select
        labelId="meter-data-label"
        id="meter-data"
        value={selectedMeterDataId || ''}
        onChange={handleChange}
        label={c('Data Stream').t`Actual Generation`}
      >
        {(selectedActualGenerationItems || []).map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </InputSelector>
  )
}

export default MeterDataSelectInput
