import React, { useCallback, useState } from 'react'
import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'

interface PasswordFieldContainerProps {
  allocate_unit_space: number
  removeBorders?: boolean
}

const PasswordFieldContainer = styled(Flex)<PasswordFieldContainerProps>`
  width: ${(props) => (props.allocate_unit_space ? `calc(100% - 2.5em)` : 'inherit')};
  & .MuiInputBase-input {
    padding-right: 24px;
  }
  .MuiInput-underline:before {
    border-bottom: ${(props) => (props.removeBorders ? `none` : '1px solid rgba(0, 0, 0, 0.42)')};
  }
  .MuiInput-underline:after {
    border-bottom: ${(props) => (props.removeBorders ? `none` : '2px solid #419e45')};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: ${(props) => (props.removeBorders ? `none` : '2px solid rgba(0, 0, 0, 0.87)')};
  }
  position: relative;
`

interface PasswordViewIconContainerProps {
  form_style_key_value: number
}
const PasswordViewIconContainer = styled.div<PasswordViewIconContainerProps>`
  position: absolute;
  right: 4px;
  margin-top: ${(props) => (props.form_style_key_value ? '8px' : '22px')};
  cursor: pointer;
`

interface PasswordFieldProps {
  disabled?: boolean
  name: string
  label: string
  formFieldsHasUnits?: number
  formStyleKeyValue?: number
  readOnly?: boolean
  withOutBorders?: boolean
}

const PasswordField: React.FC<PasswordFieldProps> = ({
  disabled,
  name,
  label,
  formFieldsHasUnits = 0,
  formStyleKeyValue = 0,
  readOnly,
  withOutBorders,
}) => {
  const [viewPassword, setViewPassword] = useState(false)

  const togglePassword = useCallback(() => {
    setViewPassword((viewPassword) => !viewPassword)
  }, [viewPassword])

  return (
    <PasswordFieldContainer removeBorders={withOutBorders} allocate_unit_space={formFieldsHasUnits} direction="row">
      <Field
        disabled={disabled}
        InputProps={{
          readOnly: readOnly,
        }}
        fullWidth
        name={name}
        component={TextField}
        type={viewPassword ? 'text' : 'password'}
        label={label}
      />
      <PasswordViewIconContainer form_style_key_value={formStyleKeyValue}>
        <FontAwesomeActionIcon icon={viewPassword ? 'eye-slash' : 'eye'} onClick={togglePassword} />
      </PasswordViewIconContainer>
    </PasswordFieldContainer>
  )
}

export default React.memo(PasswordField)
