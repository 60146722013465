import React, { useCallback, useMemo, useState } from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import { useUniqueAllAssets } from 'utils/asset'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'

import { ReTableRow } from 'modules/reTable/ReTableRow'
import { Column } from 'modules/reTable/reTable.types'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import { colors } from 'themes/theme-light'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { t } from 'ttag'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'

import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import { getAssetQueryObj } from 'utils/query-string'
import Box from '@material-ui/core/Box'
import { QualityOverview } from 'modules/quality/quality.types'
import CapacitySummaryCell from 'modules/asset/tree/treeSummary/CapacitySummaryCell'
import MeterDataSummaryCell from 'modules/asset/tree/treeSummary/MeterDataSummaryCell'
import QualitySummaryCell from 'modules/asset/tree/treeSummary/QualitySummaryCell'
import { useIsReadOnlyUser } from 'utils/user'

const FooterCell = styled(ReTableCell)`
  &.MuiTableCell-root {
    padding-top: 0px !important;
  }
`

interface FooterCellContentProps {
  border?: boolean
}
const FooterCellContent = styled(Flex)<FooterCellContentProps>`
  border-bottom: 1.3px solid ${colors.marked};
  border-right: ${(props) => (props.border ? '2px solid white' : 'none')};
  height: 29px;
  &:last-child {
    border-bottom: none;
  }
`

const AddAssetLink = styled.div`
  margin: 0 0.3em;
`

const AddAssetButton = styled(Button)`
  &.MuiButton-text {
    padding-top: 0;
    padding-bottom: 0;
  }

  *.MuiButton-iconSizeMedium > *:first-child {
    font-size: 14px;
  }
`

const EmptyCell = styled.div`
  color: transparent;
`

const SummaryFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 9em;
    background-color: transparent;
  }
  & .MuiOutlinedInput-root {
    background-color: transparent;
  }
  & .MuiOutlinedInput-input {
    padding: 5px;
  }
`

export enum SummaryType {
  total,
  selected,
}

export enum SummaryAggregation {
  sum = 'sum',
  min = 'min',
  max = 'max',
  avg = 'avg',
}

export interface AssetsCapacitySummary {
  max: number
  min: number
  sum: number
  avg: number
}

interface AssetTreeSummaryRowProps {
  columns: Column[]
  isWide: boolean
  onCapacitySummaryValuesHasFractions: (value: boolean) => void
  qualityOverview: QualityOverview | null
}

const AssetTreeSummaryRow: React.FC<AssetTreeSummaryRowProps> = ({
  columns,
  isWide,
  onCapacitySummaryValuesHasFractions,
  qualityOverview,
}) => {
  const { onAddQueryString } = useUpdateQueryString()
  const { total, selected } = SummaryType
  const { sum, min, max, avg } = SummaryAggregation
  const allAssets = useUniqueAllAssets()
  const selectedAssetsAndModelIds = useSelector(workspaceDraftAssetSelectionSelector)
  const isReadOnlyUser = useIsReadOnlyUser()

  const [activeSummary, setActiveSummary] = useState<SummaryType>(total)
  const [activeAggregation, setActiveAggregation] = useState<SummaryAggregation>(sum)

  const summaryAggregationItems = [
    { id: sum, label: t`Sum` },
    { id: min, label: t`Minimum` },
    { id: max, label: t`Maximum` },
    { id: avg, label: t`Average` },
  ]

  const summaryTypeItems = [
    { id: total, label: t`Total` },
    { id: selected, label: t`Selected` },
  ]

  const selectedAssets: Asset[] = useMemo(() => {
    return allAssets.filter((asset) => selectedAssetsAndModelIds.includes(asset.id))
  }, [allAssets, selectedAssetsAndModelIds])

  const handleAdd = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      onAddQueryString(getAssetQueryObj({}))
    },
    [onAddQueryString],
  )

  const handleChangeActiveSummary = (event: React.ChangeEvent<{ value: SummaryType }>) => {
    setActiveSummary(event.target.value)
  }

  const handleChangeAggregation = (event: React.ChangeEvent<{ value: SummaryAggregation }>) => {
    setActiveAggregation(event.target.value)
  }

  const getCellContent = (column: Column) => {
    const parts = column.name.split('.')
    switch (parts[0]) {
      case 'nomcap':
        return (
          <CapacitySummaryCell
            activeAggregation={activeAggregation}
            activeSummary={activeSummary}
            allAssets={allAssets}
            selectedAssets={selectedAssets}
            onCapacitySummaryValuesHasFractions={onCapacitySummaryValuesHasFractions}
          />
        )
      case 'meterdata':
        return (
          <MeterDataSummaryCell allAssets={allAssets} activeSummary={activeSummary} selectedAssets={selectedAssets} />
        )
      case 'quality':
        const forecastConfigId = parts[1]
        return (
          <QualitySummaryCell
            forecastConfigId={forecastConfigId}
            qualityOverview={qualityOverview}
            activeAggregation={activeAggregation}
            activeSummary={activeSummary}
            selectedAssets={selectedAssets}
          />
        )
      default:
        return <EmptyCell>-</EmptyCell>
    }
  }

  return (
    <ReTableRow>
      <ReTableCell no_border={true} colSpan={2}>
        <FooterCellContent border={isWide} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <SummaryFormControl variant="outlined">
              <Select value={activeSummary} onChange={handleChangeActiveSummary}>
                {summaryTypeItems.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}:
                  </MenuItem>
                ))}
              </Select>
            </SummaryFormControl>
            <Box ml={0.5}>{activeSummary === SummaryType.total ? allAssets.length : selectedAssets.length}</Box>
          </Flex>
          {isWide && (
            <SummaryFormControl variant="outlined">
              <Select value={activeAggregation} onChange={handleChangeAggregation}>
                {summaryAggregationItems.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}:
                  </MenuItem>
                ))}
              </Select>
            </SummaryFormControl>
          )}
        </FooterCellContent>

        <FooterCellContent>
          <AddAssetLink style={{ marginTop: '2px' }}>
            <AddAssetButton
              onClick={handleAdd}
              color="primary"
              startIcon={<FontAwesomeActionIcon disabled={isReadOnlyUser} size="xs" icon="plus" />}
              disabled={isReadOnlyUser}
            >
              {t`Add Asset`}
            </AddAssetButton>
          </AddAssetLink>
        </FooterCellContent>
      </ReTableCell>

      {isWide &&
        columns.map((column) => (
          <FooterCell no_border={true} key={column.name}>
            <FooterCellContent border={isWide} alignItems="center">
              {getCellContent(column)}
            </FooterCellContent>
            <FooterCellContent>
              <EmptyCell>-</EmptyCell>
            </FooterCellContent>
          </FooterCell>
        ))}
    </ReTableRow>
  )
}

export default React.memo(AssetTreeSummaryRow)
