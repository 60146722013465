import styled from 'styled-components'
import FullHeight from 'ui/FullHeight'
import { Grid } from '@material-ui/core'
import { rgba } from 'polished'
import { colors } from 'themes/theme-light'
import Flex from 'ui/styles/Flex'

interface FormContainerProps {
  // For some forms the container itself has padding from the parent
  nopadding?: number
}

export const FormContainer = styled(FullHeight)<FormContainerProps>`
  width: 100%;
  padding: ${(props) => (props.nopadding ? '0' : '0rem 1rem 1rem 1rem')};

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
  }
`

interface FormHeaderContainerProps {
  nopaddingbottom?: number
}

// We need padding bottom by default because most of the forms does not have anything
// below the title, since we have border in the bottom we need some padding to fit the error message
export const FormHeaderContainer = styled(Grid)<FormHeaderContainerProps>`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 10;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding-bottom: ${(props) => (props.nopaddingbottom ? '0' : '15px')};
`

export const RequiredFieldContainer = styled(Flex)`
  *.MuiInputBase-root {
    background-color: ${rgba(colors.primaryMain, 0.07)};
  }
  *.MuiInputLabel-shrink {
    z-index: 9 !important;
  }
`
interface DisabledFormContainerProps {
  disable: boolean
}

export const DisabledFormContainer = styled.div<DisabledFormContainerProps>`
  pointer-events: ${(props) => (props.disable ? 'none' : 'inherit')};
  opacity: ${(props) => (props.disable ? '0.6' : 'inherit')};
`
