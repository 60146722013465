import { createMuiTheme, darken, decomposeColor, lighten, makeStyles, recomposeColor } from '@material-ui/core/styles'
import { amber, blue, grey, lightBlue, red } from '@material-ui/core/colors'
import { META_FORECAST_COLOR } from 'modules/dataStreams/dataStreams.types'

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    app: {
      color: {
        main: string
        contrast: string
        dark: string
        border: string
      }
    }
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    app?: {
      color?: {
        main?: string
        contrast?: string
        dark?: string
        border?: string
      }
    }
  }
}

export const colors = {
  primaryMain: '#419e45',
  secondaryMain: blue[500],
  border: '#a1a1a1',
  borderLight: '#dddddd',
  marked: '#e3e3e3',
  markedLight: 'rgb(239, 239, 239)',
  adminFunctionalityColor: {
    light: '#ffa50017',
    dark: '#ffa50026',
  },
}

export const penaltyBandColors = ['#42b41e', '#e8ce40', '#e7850e', '#e82b17']

export const scheduleTimingColors = {
  revisionWindow: '#1733e8',
  realTime: 'green',
  forecastOffset: '#e82b17',
}

const dialog = {
  padding: {
    horizontal: '1.25rem',
    vertical: '1rem',
    inner: '0.25rem',
  },
}

export const table = {
  rowHeight: 30,
  itemsPaddingHeader: 1, // table header
  itemsPaddingFooter: 1, // table footer
  renderAheadCount: 25, // how many items are rendered before and after
  narrowRowHeight: 18,
}

export const sideNavWidth = '20em'

export const translateShrinkedInputLabel = 'translate(0px, 9px) scale(0.75)'

export const theme = createMuiTheme({
  app: {
    color: {
      main: '#f6f6f5',
      contrast: '#282c34',
      dark: '#555555',
      border: colors.border,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false, // disables ripple effect in the whole application 💣
    },
    MuiMenu: {
      getContentAnchorEl: null,
      anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
      },
      transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
      },
    },
  },
  overrides: {
    MuiAlert: {
      filledInfo: {
        color: grey[800],
      },
      filledWarning: {
        color: grey[800],
      },
      filledError: {
        color: grey[800],
      },
    },
    MuiButtonGroup: {
      groupedContainedPrimary: {
        '&:not(:last-child)': { borderColor: colors.borderLight },
      },
    },
    MuiCard: {
      root: {
        borderRadius: 0,
        border: '1px solid #ddd',
      },
    },
    MuiCheckbox: {
      root: { padding: 4 },
    },
    MuiDialog: {
      paper: {
        minWidth: 'min(32rem, 100%)',
      },
    },
    MuiDialogActions: {
      spacing: {
        paddingTop: `${dialog.padding.inner}`,
        paddingBottom: `${dialog.padding.vertical}`,
        paddingLeft: `${dialog.padding.horizontal}`,
        paddingRight: `${dialog.padding.horizontal}`,
      },
    },
    MuiDialogContent: {
      root: {
        paddingTop: `${dialog.padding.inner}`,
        paddingBottom: `${dialog.padding.inner}`,
        paddingLeft: `${dialog.padding.horizontal}`,
        paddingRight: `${dialog.padding.horizontal}`,
      },
    },
    MuiDialogTitle: {
      root: {
        paddingTop: `${dialog.padding.vertical}`,
        paddingBottom: `${dialog.padding.inner}`,
        paddingLeft: `${dialog.padding.horizontal}`,
        paddingRight: `${dialog.padding.horizontal}`,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#fff',
        '&:hover': { backgroundColor: '#f4f4f4' },
        '&.Mui-focused': { backgroundColor: '#eef1ee' },
      },
    },
    MuiList: {
      padding: {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    MuiListItem: {
      secondaryAction: { paddingRight: '64px' },
    },
    MuiListItemIcon: {
      root: { marginRight: '1em', minWidth: 'auto' },
    },
    MuiIconButton: {
      root: {
        fontSize: '20px',
      },
    },
    MuiInputBase: {
      root: {
        '& .MuiInputBase-input': {
          textOverflow: 'ellipsis',
        },
        fontSize: 'inherit',
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: translateShrinkedInputLabel,
        zIndex: 1,
      },
    },
    MuiMenuItem: {
      root: {
        maxHeight: '32px',
      },
    },
    MuiOutlinedInput: {
      root: { backgroundColor: '#fff' },
    },
    MuiPaper: {
      root: {
        border: '1px solid #ddd',
      },
      rounded: {
        borderRadius: 3,
      },
      elevation1: {
        boxShadow: '0 1px 1px rgba(0,0,0,.05)',
      },
    },
    MuiRadio: {
      root: {
        padding: '9px 5px 9px 9px',
      },
    },
    MuiSnackbarContent: {
      root: {
        // TODO this should come from breakpoints API
        //  https://material-ui.com/customization/breakpoints/
        //  but it is not available here yet, only after creation of the theme
        '@media (min-width: 600px)': {
          minWidth: 'auto',
        },
      },
    },
    MuiTableRow: {
      root: {
        cursor: 'pointer',
        '&:last-child .MuiTableCell-root': {
          borderBottom: 'none',
        },
        '&:hover': {
          backgroundColor: colors.marked,
        },
        '&.Mui-selected': {
          backgroundColor: colors.primaryMain,
        },
        '&.Mui-selected:hover': {
          backgroundColor: lighten(colors.primaryMain, 0.1),
        },
      },
    },
    MuiTableCell: {
      root: {
        height: `inherit`, // TODO this won't work for variable height or even multi-line rows (consider for ReTable implementation)
        whiteSpace: 'nowrap', // TODO also not working for multi-line rows (ReTable)
        padding: '0.1em 0',
      },
      body: {
        padding: '0.1em 0',
        '& > *': {
          padding: '0 0.4em',
        },
        '&:first-child > *': {
          paddingLeft: '0.1em',
        },
        '&:last-child > *': {
          paddingRight: '0.1em',
        },
      },
      head: {
        backgroundColor: colors.markedLight,
      },
      stickyHeader: {
        backgroundColor: colors.markedLight,
        left: 'unset',
      },
      footer: {
        backgroundColor: colors.markedLight,
        left: 0,
        bottom: 0, // <-- KEY
        zIndex: 2,
        position: 'sticky',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: '1em',
        lineHeight: '1.5em',
        '& > *': {
          padding: '0 0.4em',
        },
        '&:first-child > *': {
          paddingLeft: '0.1em',
        },
        '&:last-child > *': {
          paddingRight: '0.1em',
        },
      },
    },
    MuiTabs: {
      indicator: {
        transition: 'none',
      },
    },
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#9a9898',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: colors.primaryMain,
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#646161',
        '$checked$checked + &': {
          // Controls checked color for the track
          backgroundColor: colors.primaryMain,
        },
      },
    },
  },

  palette: {
    primary: {
      light: '#6abf69',
      main: colors.primaryMain,
      dark: '#00600f',
      contrastText: '#fff',
    },
    secondary: {
      light: lighten(colors.secondaryMain, 0.2),
      main: colors.secondaryMain,
      dark: darken(colors.secondaryMain, 0.2),
      contrastText: '#fff',
    },
    info: {
      main: lightBlue[200],
    },
    warning: {
      main: amber[200],
    },
    error: {
      light: red[400],
      main: red[600],
      dark: red[800],
    },
  },
  typography: {
    fontFamily: 'Lato',
    h1: {
      fontSize: 24,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    h2: {
      fontSize: 22,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    h3: {
      fontSize: 20,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    h5: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    h6: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.primaryMain,
    },
    body1: {
      fontSize: 'inherit',
    },
    body2: {
      fontSize: 'inherit',
    },
  },
})

// color palette definitions
// source: https://experience.sap.com/fiori-design-web/values-and-names/
const paletteQualitative = [
  '#5899DA',
  '#E8743B',
  '#19A979',
  '#ED4A7B',
  '#945ECF',
  '#13A4B4',
  '#525DF4',
  '#BF399E',
  '#6C8893',
  '#EE6868',
  '#2F6497',
]

export const paletteTimingPreview = [
  '#5899DA',
  '#f58231',
  '#bfef45',
  '#f032e6',
  '#e6194B',
  '#3cb44b',
  '#a9a9a9',
  '#4363d8',
  '#3cb44b',
]

export const COLOR_INDEX_MARKED = -1
export const MetaForecastColor = '#4d4c4c'

export const getColor = (
  number?: number | META_FORECAST_COLOR,
  lightness?: number,
  variation?: number,
  palette = paletteQualitative,
) => {
  if (number === 'META_FORECAST_COLOR') {
    return MetaForecastColor
  }
  if (typeof number !== 'number') return 'inherit'

  if (number === COLOR_INDEX_MARKED) return colors.marked

  const len = palette.length
  const index = number % len
  const paletteColor = palette[index]

  let color = paletteColor
  if (lightness) {
    color = lightness >= 0 ? lighten(paletteColor, lightness) : darken(paletteColor, -lightness)
  }
  if (variation) {
    const decomposedColor = decomposeColor(color)
    decomposedColor.values[variation % 3] = Math.min(
      255,
      Math.max(0, decomposedColor.values[variation % 3] + Math.floor(variation / 3) * 40),
    )
    decomposedColor.values[(variation + 1) % 3] = Math.min(
      255,
      Math.max(0, decomposedColor.values[(variation + 1) % 3] - Math.floor(variation / 3) * 40),
    )
    color = recomposeColor(decomposedColor)
  }
  if (number < len) {
    return color
  } else if (number < len * 2) {
    return lighten(color, 0.25)
  } else if (number < len * 3) {
    return darken(color, 0.25)
  } else if (number < len * 4) {
    return lighten(color, 0.45)
  } else if (number < len * 5) {
    return darken(color, 0.45)
  } else if (number < len * 6) {
    return lighten(color, 0.6)
  } else if (number < len * 7) {
    return darken(color, 0.6)
  }
}

const transitionOptions = 'cubic-bezier(0.4, 0, 0.2, 1) 0ms'

export type TransitionFor = (attributes: string[], duration?: number) => string
export const transitionFor: TransitionFor = (attributes, duration = 300) => {
  return attributes.map((attribute) => `${attribute} ${duration}ms ${transitionOptions}`).join(', ')
}

export const sidebarRowItemLeftRightPadding = 0.05
export const toolbarHeight = '48px'
export const sidePanelBgColor = '#fcfcfc'
export const sidebarRowItemPadding = `0.15em ${sidebarRowItemLeftRightPadding}em`

export const miscellaneousColors = {
  availabilityPlotBand: '#d2d2a2',
  rowEditableBgColor: '#d4e4d4',
  mutedText: '#70707a',
  mutedBorder: '#bbb',
  meterDataCleansingExcludedBand: '#ef4747',
}

type CoefficientKey = {
  [key: string]: string
}
export const bulkMeterDataCleansingCorrelationCoefficient: CoefficientKey = {
  red: '#e82b17',
  orange: '#e7850e',
  green: '#42b41e',
}

interface AdminStyle {
  important?: boolean
  dark?: boolean
}

export const useCustomMuiStyles = makeStyles(() => ({
  adminComponent: (props?: AdminStyle) => ({
    backgroundColor: props?.important
      ? `${colors.adminFunctionalityColor.dark} !important`
      : props?.dark
      ? colors.adminFunctionalityColor.dark
      : colors.adminFunctionalityColor.light,
  }),
}))
