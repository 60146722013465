import React from 'react'
import styled from 'styled-components'

import { escapeRegExp } from 'utils/regexp'

interface PartProps {
  highlighted: boolean
  disable_user_select: number
}

const Part = styled.span<PartProps>`
  /* (1) needed to show spaces in front or end of a part */
  color: ${(props) => (props.highlighted ? 'black' : 'inherit')};
  background-color: ${(props) => (props.highlighted ? 'yellow' : 'initial')};
  white-space: pre; /* (1) */
  -webkit-touch-callout: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* iOS Safari */
  -webkit-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Safari */
  -khtml-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Konqueror HTML */
  -moz-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Old versions of Firefox */
  -ms-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Internet Explorer/Edge */
  user-select: ${(props) =>
    props.disable_user_select
      ? 'none'
      : 'inherit'}; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`

interface TextContainerProps {
  disable_user_select: number
}

const TextContainer = styled.div<TextContainerProps>`
  -webkit-touch-callout: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* iOS Safari */
  -webkit-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Safari */
  -khtml-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Konqueror HTML */
  -moz-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Old versions of Firefox */
  -ms-user-select: ${(props) => (props.disable_user_select ? 'none' : 'inherit')}; /* Internet Explorer/Edge */
  user-select: ${(props) =>
    props.disable_user_select
      ? 'none'
      : 'inherit'}; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`

interface HighlightProps {
  text: string
  query: string
  hasSeparator?: boolean
  hasFontStyle?: boolean
  disableUserSelect?: boolean
}

const Highlight: React.FC<HighlightProps> = ({
  text = '',
  query = '',
  hasSeparator,
  hasFontStyle = false,
  disableUserSelect = false,
}) => {
  const data = hasSeparator ? query.split('').join(',?') : query

  const parts = query.length > 0 ? text.split(new RegExp(`(${escapeRegExp(data)})`, 'gi')) : [text]

  return (
    <>
      {query.length > 0 ? (
        parts.map((part, i) => {
          const highlighted = part.toLowerCase().replace(',', '') === query.toLowerCase()
          return (
            <Part disable_user_select={disableUserSelect ? 1 : 0} key={i} highlighted={highlighted}>
              {part}
            </Part>
          )
        })
      ) : (
        <TextContainer
          disable_user_select={disableUserSelect ? 1 : 0}
          style={{ fontStyle: hasFontStyle ? 'italic' : 'inherit' }}
        >
          {text}
        </TextContainer>
      )}
    </>
  )
}

export default React.memo(Highlight)
