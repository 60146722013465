import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  DATA_SELECTION_MODE_MULTIPLE,
  DATA_SELECTION_MODE_SINGLE,
  DataStream,
  DataStreamSelectionItem,
  DAY_AHEAD_DATA_STREAM_SEARCH_KEY,
  GERMANY_REVENUE_SEARCH_KEY,
  INTRA_DAY_DATA_STREAM_SEARCH_KEY,
  isDataStreamItem,
  META_FORECAST_COLOR,
  TimeSeriesClassifier,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { dataStreamTypeQuery, getDataStreamId, getDataStreamsRequiredForEquations } from 'utils/dataStream'
import DataStreamTypeRow from 'modules/dataStreams/tree/DataStreamTypeRow'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableItem } from 'modules/reTable/reTable.types'
import {
  getDataStreamDetailsQueryObj,
  getManageDataStreamQueryObj,
  isQueryPresentInUrl,
  QUERY_DATA_STREAM_ID,
  QUERY_DATA_STREAM_TYPE,
} from 'utils/query-string'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import { TreeDataStreamsSelectionOptions } from 'modules/asset/store/asset.types'
import { DeepPartial } from 'ts-essentials'
import {
  PenaltyWidgetSelectionModes,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { useDispatch } from 'react-redux'
import DatastreamRow from 'modules/dataStreams/tree/DatastreamRow'

interface DataStreamTreeBodyProps {
  itemsToRender: ReTableItem[]
  selectedDataStreams: DataStreamSelectionItem[]
  isDataStreamDetails: boolean
  siteForecastDetailsId: string
  dataStreamsWithCategory: ReTableItem[]
  isMetaForecastWidgetSelected: boolean
  isMetaForecastDataStreamSelected: boolean
  hasAccessToMetaForecast: boolean
  metaForecastDataStream: DataStream | undefined
}

const DataStreamTreeBody: React.FC<DataStreamTreeBodyProps> = ({
  itemsToRender,
  selectedDataStreams,
  isDataStreamDetails,
  siteForecastDetailsId,
  dataStreamsWithCategory,
  isMetaForecastWidgetSelected,
  isMetaForecastDataStreamSelected,
  hasAccessToMetaForecast,
  metaForecastDataStream,
}) => {
  const dispatch = useDispatch()
  const { onDeleteQueryStrings, onAddQueryString } = useUpdateQueryString()
  const [prevSelectedDataStream, setPrevSelectedDataStream] = useState<DataStreamSelectionItem | null>()

  const { [TimeSeriesType.SITE_FORECAST]: siteForecast } = dataStreamTypeQuery
  const {
    GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
  } = TimeSeriesClassifier

  const [selectedDataStreamDetailsId, setSelectedDataStreamDetailsId] = useState<string[]>([])
  const dataSelectionRef = useRef([])
  const selectedDataStreamIds = selectedDataStreams.map((d) => d.id)

  // Handlers to select/deselect datastreams Ctrl-click, then hold Ctrl+mouse key and move the mouse
  // Start here
  const [isCtrlPressed, setIsCtrlPressed] = useState(false)
  const [selectionStartIndex, setSelectionStartIndex] = useState(-1)
  const [selectionEndIndex, setSelectionEndIndex] = useState(-1)
  const [unSelectMultipleDataStreams, setUnSelectMultipleDataStreams] = useState(false)

  const handleMouseDown = (index: number, event) => {
    if (event.ctrlKey || event.metaKey) {
      // deselect multiple assets if the start asset is already selected
      setUnSelectMultipleDataStreams(selectedDataStreamIds.includes(dataStreamsWithCategory[index]?.id))
      setIsCtrlPressed(true)
      if (selectionStartIndex === -1) {
        setSelectionStartIndex(index)
      }
      event.preventDefault()
    }
  }

  const handleMouseEnter = (index: number, event) => {
    if (isCtrlPressed && selectionStartIndex !== -1) {
      setSelectionEndIndex(index)
      handleSelectMultipleDataStreamsWithCtrlClick(selectionStartIndex, index)
    }
  }

  const handleMouseUp = (index: number, event) => {
    if (isCtrlPressed && selectionStartIndex !== -1) {
      setSelectionEndIndex(index)
      handleSelectMultipleDataStreamsWithCtrlClick(selectionStartIndex, index)
      resetSelection()
    }
  }

  const handleSelectMultipleDataStreamsWithCtrlClick = (start: number, end: number) => {
    if (start !== end) {
      // We set to null because this is only used when shift click
      setPrevSelectedDataStream(null)
      const dataStreamsBetweenStartAndEnd = dataStreamsWithCategory
        .filter((dwc, index) => {
          return index >= Math.min(start, end) && index <= Math.max(start, end)
        })
        .filter((d) => isDataStreamItem(d))
      const selectedDataStreams = dataSelectionRef.current as DataStreamSelectionItem[]
      const selectedDataStreamIds = selectedDataStreams.map((d) => d.id)
      let newSelection: DataStreamSelectionItem[] = []
      if (unSelectMultipleDataStreams) {
        const dataStreamIdsBetweenStartAndEnd = dataStreamsBetweenStartAndEnd.map((d) => d.id)
        newSelection = selectedDataStreams?.filter((sd) => !dataStreamIdsBetweenStartAndEnd.includes(sd.id))
      } else {
        const dataStreamsToSelectWithCtrlClick = dataStreamsBetweenStartAndEnd?.filter(
          (d) => !selectedDataStreamIds.includes(d.id),
        )
        // Add color to the dataStream before passing to newSelection
        newSelection = dataStreamsToSelectWithCtrlClick.reduce((prev, curr) => {
          const dataStreamWithColor = { ...curr, color: getDataStreamSelectionColor(curr, prev) }
          return [dataStreamWithColor, ...prev]
        }, selectedDataStreams)
      }

      const draft: DeepPartial<WorkspaceConfig> = {
        data: {
          selection: newSelection,
        },
        //TODO: post penaltyWidget if penalty widget is selected
        penaltyWidget: {
          selectionMode: PenaltyWidgetSelectionModes.SELECTION,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    }
  }

  const resetSelection = () => {
    setIsCtrlPressed(false)
    setSelectionStartIndex(-1)
    setSelectionEndIndex(-1)
  }

  // ------------------------ Ends here -------------------

  const getDataStreamSelectionColor = (
    dataStream: DataStreamSelectionItem,
    prevSelectedDataStreams: DataStreamSelectionItem[],
  ): number | META_FORECAST_COLOR | undefined => {
    let color
    const oldColors = prevSelectedDataStreams
      .filter((s) => typeof s.color === 'number')
      .map((s) => s.color)
      .sort((a, b) => a - b)

    if (dataStream.subType === TimeSeriesSubType.META_ENSEMBLE) {
      // Meta forecast color is fixed and is 'grey'
      color = 'META_FORECAST_COLOR' as META_FORECAST_COLOR
    } else {
      color =
        oldColors.reduce((result, color, index) => {
          return result ?? (color !== index ? index : undefined)
        }, undefined) ?? prevSelectedDataStreams.length
    }

    return color
  }

  const handleSelectDataStream = useCallback(
    (dataStream: DataStreamSelectionItem, selectionOptions: TreeDataStreamsSelectionOptions) => {
      const selectionMode =
        selectionOptions.ctrlKey || selectionOptions.shiftKey
          ? DATA_SELECTION_MODE_MULTIPLE
          : DATA_SELECTION_MODE_SINGLE

      const selectedDataStreams = (dataSelectionRef.current || []) as DataStreamSelectionItem[]
      let newSelection: DataStreamSelectionItem[] = []
      const currentDataStream = { ...dataStream, color: getDataStreamSelectionColor(dataStream, selectedDataStreams) }
      setPrevSelectedDataStream(currentDataStream)

      if (selectionMode === DATA_SELECTION_MODE_SINGLE) {
        if (currentDataStream.subType !== TimeSeriesSubType.META_ENSEMBLE) {
          currentDataStream.color = 0
        }
        newSelection = [currentDataStream]
      } else {
        const alreadySelected = selectedDataStreams.some((sel) => sel.id === currentDataStream.id)
        if (selectionOptions.shiftKey && prevSelectedDataStream) {
          const dataStreams = dataStreamsWithCategory.filter((d) => isDataStreamItem(d))
          // Select dataStreams between previously and current selected dataStream
          const prevDataStreamSelectedIndex = dataStreams.findIndex((a) => a.id === prevSelectedDataStream?.id)
          const currDataStreamIndex = dataStreams.findIndex((a) => a.id === currentDataStream?.id)
          const sortIndexes = [prevDataStreamSelectedIndex, currDataStreamIndex].sort((a, b) => a - b)
          const dataStreamsBetweenPrevAndCurrent = dataStreams.slice(sortIndexes[0], sortIndexes[1] + 1)

          // Filter out already selected dataStreams
          const prevSelectionIds = selectedDataStreams.map((ps) => ps.id)
          const dataStreamsToSelectWithShiftClick = dataStreamsBetweenPrevAndCurrent.filter(
            (d) => !prevSelectionIds.includes(d.id),
          )

          // Add color to the dataStream before passing to newSelection
          newSelection = dataStreamsToSelectWithShiftClick.reduce((prev, curr) => {
            const dataStreamWithColor = { ...curr, color: getDataStreamSelectionColor(curr, prev) }
            return [dataStreamWithColor, ...prev]
          }, selectedDataStreams)
        } else if (selectionOptions.shiftKey && !prevSelectedDataStream) {
          // No Previous selection and shiftKey should select only one asset
          if (currentDataStream.subType !== TimeSeriesSubType.META_ENSEMBLE) {
            currentDataStream.color = 0
          }
          newSelection = [currentDataStream]
        } else if (alreadySelected) {
          // Remove if already selected
          newSelection = selectedDataStreams.filter((sel) => sel.id !== currentDataStream.id)
          setPrevSelectedDataStream(null)
        } else {
          // Add if not selected
          newSelection = [...selectedDataStreams, currentDataStream]
        }
      }

      // Add required DataStreams needed for calculating the revenue
      if (currentDataStream.classifier?.includes(GERMANY_REVENUE_SEARCH_KEY)) {
        const dataStreamsToBeAdded = getDataStreamsRequiredForEquations({
          equationDataStream: currentDataStream,
          allDataStreams: dataStreamsWithCategory,
          prevSelection: newSelection,
        })
        if (dataStreamsToBeAdded.length > 0) {
          newSelection = newSelection.concat(dataStreamsToBeAdded)
        }
      }

      const draft: DeepPartial<WorkspaceConfig> = {
        data: {
          selection: newSelection,
        },
        //TODO: post penaltyWidget if penalty widget is selected
        penaltyWidget: {
          selectionMode: PenaltyWidgetSelectionModes.SELECTION,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    },
    [dataStreamsWithCategory, prevSelectedDataStream],
  )

  const handleShowForecastDetails = useCallback((dataStream: DataStreamSelectionItem) => {
    const forecastId = dataStream?.id ? dataStream.id : dataStream.name
    const urlQueryData = isQueryPresentInUrl(QUERY_DATA_STREAM_ID, forecastId)
    const closeDetails = urlQueryData.queryPresent && urlQueryData.hasPassedValue
    if (closeDetails) {
      onDeleteQueryStrings([QUERY_DATA_STREAM_ID, QUERY_DATA_STREAM_TYPE])
    } else {
      const queryData = {
        ...getManageDataStreamQueryObj(siteForecast),
        ...getDataStreamDetailsQueryObj(forecastId),
      }
      onAddQueryString(queryData)
    }
    setSelectedDataStreamDetailsId(closeDetails ? [] : [forecastId])
  }, [])

  const handleSelectSiteForecastDataStream = useCallback(
    (item: DataStreamSelectionItem, selectionOptions: TreeDataStreamsSelectionOptions) => {
      const urlQueryData = isQueryPresentInUrl(QUERY_DATA_STREAM_ID)
      // if urlQueryData has the query that indicates that already details slider is open so we need show details
      if (urlQueryData.queryPresent) {
        handleShowForecastDetails(item)
      } else {
        handleSelectDataStream(item, selectionOptions)
      }
    },
    [handleSelectDataStream],
  )

  useEffect(() => {
    dataSelectionRef.current = (selectedDataStreams || []) as DataStreamSelectionItem[]
  }, [selectedDataStreams])

  useEffect(() => {
    if (!isDataStreamDetails) {
      setSelectedDataStreamDetailsId([])
    } else if (siteForecastDetailsId && !selectedDataStreamDetailsId.includes(siteForecastDetailsId)) {
      setSelectedDataStreamDetailsId([siteForecastDetailsId])
    }
  }, [isDataStreamDetails, selectedDataStreamDetailsId.length, siteForecastDetailsId])

  const siteForecastRelatedToRevenue = (classifierType: TimeSeriesClassifier): any => {
    if (
      classifierType === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST ||
      classifierType === GERMANY_REVENUE_DAY_AHEAD_REVENUE
    ) {
      const selectedDayAheadDataStreams = dataStreamsWithCategory.filter(
        (ds) =>
          ds.type === TimeSeriesType.SITE_FORECAST &&
          (ds?.name.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY) ||
            ds?.label?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY)),
      )

      // return always the first one
      return selectedDayAheadDataStreams[0]
    } else if (classifierType === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST) {
      const selectedIntraDayDataStreams = dataStreamsWithCategory.filter(
        (ds) =>
          ds.type === TimeSeriesType.SITE_FORECAST &&
          (ds?.name.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY) ||
            ds?.label?.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY)),
      )

      // return always the first one
      return selectedIntraDayDataStreams[0]
    }
  }

  // Select/UnSelect the Meta Forecast DataStream based on the widget selection
  useEffect(() => {
    let updateSelection = false
    if (isMetaForecastWidgetSelected && !isMetaForecastDataStreamSelected && metaForecastDataStream) {
      updateSelection = true
    } else if (!isMetaForecastWidgetSelected && isMetaForecastDataStreamSelected) {
      updateSelection = true
    }
    // For Selecting and UnSelecting the DataStream we need to pass the dataStream to the handler.
    // Handler will take care if it should be selected or remove from selection
    if (updateSelection) {
      handleSelectDataStream(metaForecastDataStream, { ctrlKey: true })
    }
  }, [isMetaForecastDataStreamSelected, isMetaForecastWidgetSelected, hasAccessToMetaForecast, metaForecastDataStream])

  return (
    <ReTableBody tableBodyClassName="appTour-addDeliveryTarget-select-forecast">
      {itemsToRender.map((item, index) => {
        if (isDataStreamItem(item)) {
          const key = `${item.type}--${item.id}`
          const dataStreamId = getDataStreamId(item)
          const selectedItem = selectedDataStreams.find((sel) => dataStreamId && getDataStreamId(sel) === dataStreamId)
          const isSelected = Boolean(selectedItem)
          const color = selectedItem?.color
          const detailsItem = selectedDataStreamDetailsId.find((id) => dataStreamId && id === dataStreamId)

          const lightMode = isSelected && selectedDataStreamDetailsId.find((id) => Boolean(id))
          const onSelect =
            item.type === TimeSeriesType.SITE_FORECAST ? handleSelectSiteForecastDataStream : handleSelectDataStream

          return (
            <DatastreamRow
              key={key}
              lightMode={Boolean(lightMode)}
              detailsVisible={Boolean(detailsItem)}
              isSelected={isSelected}
              onDetails={handleShowForecastDetails}
              item={item}
              color={color}
              onSelect={onSelect}
              revenueRelatedDataStream={
                (item.classifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST ||
                  item.classifier === GERMANY_REVENUE_DAY_AHEAD_REVENUE ||
                  item.classifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST) &&
                isSelected
                  ? siteForecastRelatedToRevenue(item.classifier)
                  : ''
              }
              index={index}
              mouseDown={handleMouseDown}
              mouseEnter={handleMouseEnter}
              mouseUp={handleMouseUp}
            />
          )
        } else {
          return <DataStreamTypeRow key={item.id} typeItem={item} />
        }
      })}
    </ReTableBody>
  )
}
//
// DataStreamTreeBody.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default React.memo(DataStreamTreeBody)
