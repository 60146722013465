import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'ttag'

import TrainingMenuContent from 'modules/asset/portfolio/header/TrainingMenuContent'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import Flex from 'ui/styles/Flex'
import ButtonMenu from 'ui/form/ButtonMenu'
import { theme } from 'themes/theme-light'
import { Box } from '@material-ui/core'
import { useIsReadOnlyUser } from 'utils/user'

const SmallButtonMenu = styled(ButtonMenu)`
  & .MuiButton-root {
    margin-left: 1em;
    height: 32px;
  }
`

const AssetsToolbar: React.FC = () => {
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const isReadOnlyUser = useIsReadOnlyUser()

  return (
    <Box ml={1}>
      <Flex direction="row" flexGrow={1}>
        <SmallButtonMenu
          text={t`Training`}
          size="small"
          variant="outlined"
          icon="project-diagram"
          iconSize="sm"
          iconColor={theme.palette.primary.main}
          disabled={isReadOnlyUser || assetSelection.length === 0}
          renderMenu={(handleClose) => <TrainingMenuContent onClose={handleClose} />}
        />
      </Flex>
    </Box>
  )
}

export default React.memo(AssetsToolbar)
