import React, { useMemo } from 'react'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { TDIndented } from 'ui/styles/table'
import Flex from 'ui/styles/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import LabelWithoutWrap from 'ui/LabelWithoutWrap'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { getForecastModelName } from 'utils/forecastModel'
import { backcastStatusValue } from 'fixtures/assetTree'
import styled from 'styled-components'
import { lighten } from '@material-ui/core'
import { ForecastModel } from 'modules/quality/quality.types'
import { Column } from 'modules/reTable/reTable.types'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import ForecastModelIcon from 'ui/elements/ForecastModelIcon'

const ModelNameWidth = 17.5

interface RowProps {
  selected: boolean
  lightMode: boolean
  inProduction: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Row = styled(({ inProduction, lightMode, ...rest }) => <ReTableRow {...rest} />)<RowProps>`
  *.MuiTableCell-body {
    color: ${(props) => (props.inProduction ? 'inherit' : 'grey')};
  }
  &.MuiTableRow-root.Mui-selected {
    background-color: ${(props) => lighten(theme.palette.primary.main, props.lightMode ? 0.8 : 0)};
  }
  &.MuiTableRow-root.Mui-selected:hover {
    background-color: ${(props) => lighten(theme.palette.primary.main, props.lightMode ? 0.85 : 0.1)};
  }
  &.MuiTableRow-root.Mui-selected .MuiTableCell-body {
    color: ${(props) => {
      if (props.lightMode) {
        return props.inProduction ? 'inherit' : 'grey'
      }
      return 'white'
    }};
  }
`

interface ForecastModelRowProps {
  lightMode: boolean
  isWide: boolean
  treeLevel: number
  forecastModel: ForecastModel
  activeModels: ForecastModel[]
  isSelected: boolean
  onForecastModelSelect: (event: React.MouseEvent<HTMLDivElement>, forecastModel: ForecastModel) => void
  backcastColumn: Column | undefined
  columnsSelected: Column[]
}

const ForecastModelRow: React.FC<ForecastModelRowProps> = ({
  lightMode,
  isWide,
  treeLevel,
  forecastModel,
  activeModels,
  isSelected,
  onForecastModelSelect,
  backcastColumn,
  columnsSelected,
}) => {
  const nameWidth = isWide ? 'inherit' : `${ModelNameWidth - treeLevel}em`

  const activeModelForThisForecastModelCategory = useMemo(
    () => activeModels.find((am) => am.category === forecastModel.category),
    [activeModels, forecastModel],
  )

  return (
    <Row
      onClick={(event) => onForecastModelSelect(event, forecastModel)}
      selected={isSelected}
      key={forecastModel.uuid}
      inProduction={forecastModel.uuid === activeModelForThisForecastModelCategory?.uuid}
      lightMode={lightMode}
    >
      <ReTableCell colSpan={isWide && backcastColumn ? 2 : columnsSelected.length}>
        <TDIndented level={treeLevel} isWide={isWide}>
          <Flex direction="row" alignItems="center" justifyContent="flex-start">
            <ForecastModelIcon
              forecastModel={forecastModel}
              activeModel={activeModelForThisForecastModelCategory?.uuid || ''}
              selected={isSelected}
              lightMode={lightMode}
            />
            <LabelWithoutWrap>
              <FixedWidthTextContainer text={getForecastModelName(forecastModel)} width={nameWidth || 'inherit'}>
                {getForecastModelName(forecastModel)}
              </FixedWidthTextContainer>
            </LabelWithoutWrap>
          </Flex>
        </TDIndented>
      </ReTableCell>
      {isWide && backcastColumn && (
        <ReTableCell colSpan={columnsSelected.length - 2}>
          {forecastModel?.backcastStatus === backcastStatusValue.NOTHING ? (
            <></>
          ) : (
            <FontAwesomeIcon
              color={isSelected && !lightMode ? 'white' : theme.palette.primary.main}
              size="2x"
              icon={forecastModel?.backcastStatus === backcastStatusValue.READY ? 'check' : 'circle-notch'}
            />
          )}
        </ReTableCell>
      )}
    </Row>
  )
}

export default React.memo(ForecastModelRow)
