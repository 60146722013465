import {
  ParamDto,
  TimeSeriesClassifier,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { UnitType } from 'fixtures/types'
import { c } from 'ttag'

export enum AppUnits {
  // Power units
  WATT = 'W',

  KILO_WATT = 'kW',
  KILO_WATT_HOUR = 'kWh',
  KILO_WATT_HOUR_PER_SQUARE_METER = 'kWh/m2',
  WATT_PER_SQUARE_METER = 'W/m²',
  KILO_WATT_PEAK = 'kWp',
  MEGA_WATT = 'MW',
  MEGA_WATT_HOUR = 'MWh',
  GIGA_WATT_HOUR = 'GWh',
  METER_PER_SECOND = 'm/s',

  PERCENTAGE = '%',
  EURO = '€',
  EURO_PER_MEGA_WATT_HOUR = '€/MWh',
  EURO_PER_KILO_WATT_HOUR = '€/kWh',
  LIRA_PER_MEGA_WATT_HOUR = '₺/MWh',
  RUPEE_PER_KILO_WATT_HOUR = '₹/kWh',
  RUPEE_CURRENCY = '₹',

  // File sizes
  BYTES = 'bytes',
  KILO_BYTE = 'kB',
  MEGA_BYTE = 'MB',
  GIGA_BYTE = 'GB',
  TERA_BYTE = 'TB',
  PETA_BYTE = 'PB',
  EXA_BYTE = 'EB',
  ZETTA_BYTE = 'ZB',
  YOTTA_BYTE = 'YB',
}

// Coordinates should be outside of AppUnits because we have to add some translation
export const COORDINATE_LATITUDE = '°N'
export const COORDINATE_LONGITUDE = c('Units').t`°E`

export const DEFAULT_POWER_UNIT = AppUnits.KILO_WATT

// TODO hacky frontend solution until backend provides proper limit data
export const getSoftMinimumForUnit = (paramData: ParamDto) => {
  const unit = paramData?.unit || DEFAULT_POWER_UNIT
  switch (unit) {
    case '%':
      return 0
    case 'degrees':
      return 0
    case '° Celsius':
      return -20
    case 'Fahrenheit':
      return 0
    case 'Kelvin':
      return 250
    default:
      return paramData?.minValue || 0
  }
}

// TODO hacky frontend solution until backend provides proper limit data
export const getSoftMaximumForUnit = (paramData: ParamDto) => {
  const unit = paramData?.unit || DEFAULT_POWER_UNIT
  switch (unit) {
    case '%':
      return 100
    case 'degrees':
      return 360
    case '° Celsius':
      return 40
    case 'Fahrenheit':
      return 100
    case 'Kelvin':
      return 320
    default:
      return undefined
  }
}

export const aggregateValuesForUnit = (values: Array<[number, number]>, unit: string) => {
  const UNITS_WITHOUT_AGGREGATION = ['°']
  const UNITS_TO_SUM_UP = ['kWh', 'l/m²']

  if (UNITS_WITHOUT_AGGREGATION.includes(unit)) {
    return values[0][1]
  } else {
    const sum = values.reduce((sum, value) => sum + value[1], 0)
    if (UNITS_TO_SUM_UP.includes(unit)) {
      return sum
    } else {
      return sum / values.length
    }
  }
}

export const availabilityUnits: UnitType[] = [
  { name: 'WATT', value: AppUnits.WATT },
  { name: 'KILOWATT', value: AppUnits.KILO_WATT },
  { name: 'MEGAWATT', value: AppUnits.MEGA_WATT },
  { name: 'PERCENT', value: AppUnits.PERCENTAGE },
]

// We are getting units depending on data stream category
export const getUnitForDataStreamCategory = (dataStreamType: string, subType?: string, classifier?: string) => {
  if (dataStreamType === TimeSeriesType.SITE_FORECAST || dataStreamType === TimeSeriesType.METER_DATA) {
    return AppUnits.KILO_WATT
  } else if (dataStreamType === TimeSeriesType.MARKET_PRICE_DATA) {
    if (subType && subType === TimeSeriesSubType.TURKEY_MARKET_PRICE_DATA) {
      return AppUnits.LIRA_PER_MEGA_WATT_HOUR
    } else if (subType && subType === TimeSeriesSubType.INDIAN_MARKET_PRICE_DATA) {
      return AppUnits.RUPEE_PER_KILO_WATT_HOUR
    } else if (
      subType &&
      classifier &&
      subType === TimeSeriesSubType.GERMANY_MARKET_PRICE_DATA &&
      classifier === TimeSeriesClassifier.GERMANY_REVENUE
    ) {
      return AppUnits.EURO
    }
    return AppUnits.EURO_PER_MEGA_WATT_HOUR
  }

  return ''
}
