import { FormControl, lighten } from '@material-ui/core'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'

export const FormControlFullWidth = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
  }
`
interface EditableRowProps {
  is_edit: number
  is_shaded?: number
  is_selected?: number
}
export const EditableRow = styled(ReTableRow)<EditableRowProps>`
  &.MuiTableRow-root {
    cursor: pointer;
    background-color: ${(props) =>
      props.is_edit || props.is_selected ? lighten(theme.palette.primary.main, 0.8) : 'inherit'};
  }
  *.MuiTableCell-body {
    color: ${(props) => (props.is_shaded ? 'grey' : 'inherit')};
    ${(props) => (props.is_edit ? 'padding-bottom: 17px;' : '')}
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
  }
`
