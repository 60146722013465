import React from 'react'
import { Box } from '@material-ui/core'
import { KEY_USER_COMPANY_LOGO, useUserSetting } from 'modules/auth/api/userSettings.api'
import { CompanyLogo } from 'modules/user/userSettings/SectionUploadCompanyLogo'
import styled from 'styled-components'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const ProfilePic = styled.img`
  height: 1.8em;
  max-width: 19em;
`

interface UserProfilePicProps {
  showUserNameIfNoProfilePic: boolean
  showOnLeft?: boolean
  logoHeight?: string
}

const UserProfilePic: React.FC<UserProfilePicProps> = ({
  showUserNameIfNoProfilePic,
  showOnLeft = false,
  logoHeight,
}) => {
  const user = useSelector(getUserResultSelector)
  const userCompanyLogoResult = useUserSetting<CompanyLogo>(KEY_USER_COMPANY_LOGO)
  const noProfilePic = !Boolean(userCompanyLogoResult?.data?.imgSrc)

  const username = user ? user.login : ''

  return (
    <>
      {userCompanyLogoResult.data?.imgSrc && (
        <Box width="19em" display="flex" flexDirection="row" justifyContent={showOnLeft ? 'flex-start' : 'flex-end'}>
          <ProfilePic style={{ height: logoHeight || '1.8em' }} src={userCompanyLogoResult?.data?.imgSrc || ''} />
        </Box>
      )}

      {noProfilePic && showUserNameIfNoProfilePic && (
        <Box display="flex" flexDirection="row" justifyContent="center">
          <FontAwesomeActionIcon size="lg" icon="user-circle" />
          <Box style={{ textTransform: 'none' }} ml={0.5} mr={0.4}>
            {username}
          </Box>
        </Box>
      )}
    </>
  )
}

export default UserProfilePic
