import React from 'react'
import Flex from 'ui/styles/Flex'
import NumberFieldReactFinalForm from 'ui/elements/NumberFieldReactFinalForm'
import { FieldInputProps } from 'react-final-form'
import styled from 'styled-components'

const DurationText = styled.div`
  margin-left: 4px;
`

interface SiteForecastDurationFieldProps {
  input: FieldInputProps<any, HTMLElement>
  durationText: string | React.ReactNode
  placeholder?: string
  min?: number
  max?: number
  step?: number
  repeatNumbers?: boolean
  disabled?: boolean
  minMaxValuesHandle?: boolean
  timeInput?: boolean
}

const SiteForecastDurationField: React.FC<SiteForecastDurationFieldProps> = ({
  input,
  placeholder = '',
  durationText,
  min,
  max,
  step = 1,
  repeatNumbers,
  disabled = false,
  minMaxValuesHandle = true,
  timeInput = false,
}) => {
  return (
    <div>
      <Flex alignItems="center">
        <NumberFieldReactFinalForm
          step={step}
          min={min}
          max={max}
          placeholder={placeholder}
          input={input}
          repeatNumbers={repeatNumbers}
          disabled={disabled}
          minMaxValuesHandle={minMaxValuesHandle}
          timeInput={timeInput}
        />
        <DurationText>{durationText}</DurationText>
      </Flex>

      {/*{meta.touched && meta.error && <span>{meta.error}</span>}*/}
    </div>
  )
}

export default SiteForecastDurationField
