import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import {
  QUERY_KEY_RUNNING_BACKCASTS,
  useRunningBackcastsTableItems,
} from 'modules/workspace/header/backCast/backCast.api'
import LayoutTitle from 'ui/LayoutTitle'
import { t } from 'ttag'
import InlineLoading from 'ui/InlineLoading'
import Spacer from 'ui/Spacer'
import RunningBackcastsTable from 'modules/workspace/header/backCast/RunningBackcasts/RunningBackcastsTable'
import { useQueryClient } from 'react-query'

const TableWrapper = styled(Box)`
  width: ${(props) => props.width};
  height: calc(100% - 5.2em);
`

const Container = styled.div`
  height: 100%;
  width: 100vw;
  background: white;
`

const RunningBackcasts: React.FC = () => {
  const queryClient = useQueryClient()
  const runningBackcastsTableResults = useRunningBackcastsTableItems()

  useEffect(() => {
    queryClient.invalidateQueries(QUERY_KEY_RUNNING_BACKCASTS)
  }, [])

  return (
    <Container>
      <Box ml={1}>
        <LayoutTitle icon="chart-line">
          {t`Running backcasts`}
          {runningBackcastsTableResults?.isLoading && <InlineLoading />}
          <Spacer />
        </LayoutTitle>
      </Box>
      <TableWrapper ml={1} mr={1}>
        {runningBackcastsTableResults.data && <RunningBackcastsTable tableItems={runningBackcastsTableResults.data} />}
        {!runningBackcastsTableResults.isLoading && !runningBackcastsTableResults.data && (
          <>{t`There are no running backcasts.`}</>
        )}
      </TableWrapper>
    </Container>
  )
}

export default React.memo(RunningBackcasts)
