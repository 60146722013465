import { Box, Typography } from '@material-ui/core'
import cloneDeep from 'clone-deep'
import {
  testIdWorkspaceHeaderChartName,
  testIdWorkspaceHeaderMenu,
  testIdWorkspaceHeaderRoot,
  testIdWorkspaceHeaderToolbar,
  testIdWorkspaceHeaderToolbarSaveButton,
} from 'modules/workspace/header/WorkspaceHeader.ids'
import WorkspaceMenu from 'modules/workspace/header/WorkspaceMenu'

import WorkspaceToolbar from 'modules/workspace/header/WorkspaceToolbar'
import {
  selectedConfigSelector,
  workspaceConfigsLoadingSelector,
  workspaceConfigsResultSelector,
} from 'modules/workspace/store/getWorkspaceConfigs.state'
import {
  workspaceDraftChartNameSelector,
  workspaceDraftResultSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { SAVE_WORKSPACE_CONFIGS_REQUEST } from 'modules/workspace/store/saveWorkspaceConfigs.state'
import { SET_SELECTED_CONFIG } from 'modules/workspace/store/view.state'
import { ChartConfig, SAVE_WORKSPACE_DRAFT_REQUEST, WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { DeepPartial } from 'ts-essentials'
import { t } from 'ttag'

import Flex from 'ui/styles/Flex'

import { getUuid } from 'utils/state'
import {
  createDefaultChartConfig,
  createDefaultWorkspaceConfig,
  getDefaultChartName,
  workspaceConfigsAreEqual,
} from 'utils/workspace'

import { ToolbarMenuActionsEnum } from 'fixtures/workspace'
import { DELETE_WORKSPACE_CONFIGS_REQUEST } from 'modules/workspace/store/deleteWorkspaceConfigs.state'
import ChartToolbar from 'modules/workspace/header/ChartToolbar'
import WorkspaceHeaderDialogs from 'modules/workspace/header/WorkspaceHeaderDialogs'
import { ScreenSize, useScreenSizeLessThanGivenSize } from 'utils/hooks/responsiveUI/useScreenSize'
import PopperTooltip from 'ui/PopperTooltip'
import SplitButton from 'ui/form/SplitButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from 'ui/form/LoadingButton'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { useIsReadOnlyUser } from 'utils/user'

interface HeaderContainerProps {
  positionTop: number
}

const HeaderContainer = styled.div<HeaderContainerProps>`
  z-index: 1;
  background: white;
  width: calc(100vw - 40em);
  padding-top: 0.7em;
  transition: 200ms;
  box-shadow: ${(props) => (props.positionTop > 0 ? '0 3px 5px 2px rgba(0, 0, 0, 0.1)' : 'none')};
`

interface ChartNameProps {
  width: string
}

const ChartName = styled(Typography)<ChartNameProps>`
  width: ${(props) => props.width || 'inherit'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SAVE = 'SAVE'
const SAVE_AS = 'SAVE_AS'
const DISCARD_CHANGES = 'DÍSCARD_CHANGES'

export interface ConfirmWorkspaceHeaderMenuBarActionsProps {
  isUnsavedDialogOpen: boolean
  activeMenuAction: number
  selectedChartId: string
  isDeleteDialogOpen: boolean
}

const initialConfirmMenuBarActions: ConfirmWorkspaceHeaderMenuBarActionsProps = {
  isUnsavedDialogOpen: false,
  activeMenuAction: 0,
  selectedChartId: '',
  isDeleteDialogOpen: false,
}

export interface WorkspaceHeaderProps {
  positionTop: number
  isWorkbenchReadyWithSufficientData: boolean
}

const WorkspaceHeader: React.FC<WorkspaceHeaderProps> = ({ positionTop, isWorkbenchReadyWithSufficientData }) => {
  const dispatch = useDispatch()
  const isSmallScreen = useScreenSizeLessThanGivenSize(ScreenSize.md)
  const draft = useSelector(workspaceDraftResultSelector)
  const chartName = useSelector(workspaceDraftChartNameSelector)
  const configs = useSelector(workspaceConfigsResultSelector)
  const loading = useSelector(workspaceConfigsLoadingSelector)
  // const error = useSelector(workspaceConfigsErrorSelector)
  const selectedConfig = useSelector(selectedConfigSelector)
  const workspaceConfig = useSelector(workspaceDraftResultSelector)
  const defaultConfig = useMemo(createDefaultWorkspaceConfig, [])
  const isReadOnlyUser = useIsReadOnlyUser()

  const chartNameText = chartName || `Unsaved`

  const unsavedChanges = useMemo(
    () => !workspaceConfigsAreEqual(workspaceConfig, selectedConfig ? selectedConfig : defaultConfig, true),
    [workspaceConfig, selectedConfig, defaultConfig],
  )

  const [confirmMenuBarActions, setConfirmMenuBarActions] = useState<ConfirmWorkspaceHeaderMenuBarActionsProps>(
    initialConfirmMenuBarActions,
  )
  const [renameDialogOpen, setRenameDialogOpen] = useState(false)
  const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false)
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false)
  const [triggeredBackcastCreation, setTriggeredBackcastCreation] = useState(false)

  const handleLoadConfig = useCallback(
    (selectedConfigId: string) => {
      if (!confirmMenuBarActions.selectedChartId && unsavedChanges) {
        setConfirmMenuBarActions({
          isUnsavedDialogOpen: true,
          selectedChartId: selectedConfigId,
          activeMenuAction: ToolbarMenuActionsEnum.loadChart,
          isDeleteDialogOpen: false,
        })
        return
      }
      const newSelectedConfig = configs.find((config) => config.id === selectedConfigId)
      if (newSelectedConfig) {
        const draft: DeepPartial<WorkspaceConfig> = newSelectedConfig
        dispatch({ type: SET_SELECTED_CONFIG, selectedConfig: selectedConfigId })
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
      setConfirmMenuBarActions(initialConfirmMenuBarActions)
    },

    [configs, unsavedChanges, confirmMenuBarActions],
  )

  const handleSaveConfig = useCallback(
    (workspaceConfigToSave = draft, saveRenaming = false) => {
      const id = workspaceConfigToSave.id || getUuid()

      const configToSave = {
        ...workspaceConfigToSave,
        id,
      }

      if (configToSave.default) delete configToSave.default
      dispatch({ type: SAVE_WORKSPACE_CONFIGS_REQUEST, config: configToSave, hideLoading: saveRenaming })

      // when renaming the chart we do not update the draft because
      // we save the initial state and keep other changes as modified
      if (!saveRenaming) {
        dispatch({ type: SET_SELECTED_CONFIG, selectedConfig: id })
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft: configToSave })
      }
    },
    [draft],
  )

  const handleValidate = useCallback(
    (name: string, validateSelectedConfig: boolean) => {
      // all chart names, but not the selected one
      const chartNames = configs
        .filter((config) => validateSelectedConfig || config.id !== selectedConfig?.id)
        .map((config) => config.chart.name.toLowerCase())
      let error
      if (!name) {
        error = t`Required`
      } else if (chartNames.includes(name.toLowerCase())) {
        error = t`Name already exists`
      }
      return error
    },
    [configs, selectedConfig],
  )

  const handleValidateRename = useCallback(
    (name: string) => {
      return handleValidate(name, false)
    },
    [handleValidate],
  )

  const handleValidateSaveAs = useCallback(
    (name: string) => {
      return handleValidate(name, true)
    },
    [handleValidate],
  )

  const openRenameDialog = useCallback(() => {
    setRenameDialogOpen(true)
  }, [])

  const closeRenameDialog = useCallback(() => {
    setRenameDialogOpen(false)
  }, [])

  const handleRenameChart = useCallback(
    (name: string) => {
      const renamedDraft = cloneDeep(draft)
      if (!renamedDraft.chart) renamedDraft.chart = createDefaultChartConfig()
      renamedDraft.chart.name = name
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft: renamedDraft })
      if (selectedConfig?.id) {
        const renamedSelectedConfig = cloneDeep(selectedConfig)
        renamedSelectedConfig.chart.name = name
        handleSaveConfig(renamedSelectedConfig, true)
      }
      closeRenameDialog()
    },
    [draft, selectedConfig, handleSaveConfig, closeRenameDialog],
  )

  const openSaveAsDialog = useCallback(() => {
    setSaveAsDialogOpen(true)
  }, [])

  const closeSaveAsDialog = useCallback(() => {
    setSaveAsDialogOpen(false)
  }, [])

  const saveDuplicatedChart = useCallback(
    (name: string) => {
      const newWorkspaceConfig = cloneDeep(draft)
      delete newWorkspaceConfig.id
      if (!newWorkspaceConfig.chart) newWorkspaceConfig.chart = createDefaultChartConfig()
      newWorkspaceConfig.chart.name = name
      handleSaveConfig(newWorkspaceConfig)
      closeSaveAsDialog()
    },
    [draft, handleSaveConfig, closeSaveAsDialog],
  )

  const handleDiscardChanges = useCallback(() => {
    const draft = selectedConfig
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    setDiscardDialogOpen(false)
  }, [selectedConfig])

  const closeDiscardChanges = useCallback(() => {
    setDiscardDialogOpen(false)
  }, [])

  const openDiscardChanges = useCallback(() => {
    setDiscardDialogOpen(true)
  }, [])

  const handleAdd = useCallback(() => {
    if (!confirmMenuBarActions.isUnsavedDialogOpen && unsavedChanges) {
      setConfirmMenuBarActions({
        isUnsavedDialogOpen: true,
        selectedChartId: '',
        activeMenuAction: ToolbarMenuActionsEnum.addChart,
        isDeleteDialogOpen: false,
      })
      return
    }

    const draft: DeepPartial<WorkspaceConfig> = createDefaultWorkspaceConfig()
    dispatch({ type: SET_SELECTED_CONFIG, selectedConfig: null })
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft, isNew: true })
  }, [unsavedChanges, confirmMenuBarActions])

  const handleDeleteConfig = useCallback(() => {
    setConfirmMenuBarActions({ ...confirmMenuBarActions, isDeleteDialogOpen: true })
  }, [confirmMenuBarActions])

  const confirmDeleteConfig = useCallback(() => {
    dispatch({ type: DELETE_WORKSPACE_CONFIGS_REQUEST, config: workspaceConfig })
    setConfirmMenuBarActions(initialConfirmMenuBarActions)

    const unsavedDraft = { ...workspaceConfig }
    unsavedDraft.chart = { ...(unsavedDraft.chart || {}), name: getDefaultChartName() } as ChartConfig
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft: unsavedDraft, isNew: true })
  }, [workspaceConfig, confirmMenuBarActions])

  const handleConfirmUnsavedChanges = useCallback(() => {
    confirmMenuBarActions?.activeMenuAction === ToolbarMenuActionsEnum.loadChart
      ? handleLoadConfig(confirmMenuBarActions.selectedChartId)
      : handleAdd()
  }, [confirmMenuBarActions, handleLoadConfig, handleAdd])

  const closeConfirmUnsavedChanges = useCallback(() => {
    setConfirmMenuBarActions(initialConfirmMenuBarActions)
  }, [initialConfirmMenuBarActions])

  const handleConfirmDeleteChart = useCallback(() => {
    confirmDeleteConfig()
  }, [confirmDeleteConfig])

  const closeConfirmDeleteChart = useCallback(() => {
    setConfirmMenuBarActions(initialConfirmMenuBarActions)
  }, [initialConfirmMenuBarActions])

  const saveMenuItems: SelectAsListItem[] = [
    {
      key: SAVE,
      label: t`Save`,
    },
    {
      key: SAVE_AS,
      label: t`Save as`,
    },
    {
      key: DISCARD_CHANGES,
      label: t`Discard changes`,
    },
  ]

  const handleSaveAs = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      openSaveAsDialog()
    },
    [openSaveAsDialog],
  )

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      switch (item.key) {
        case SAVE:
          handleSaveConfig()
          break
        case SAVE_AS:
          openSaveAsDialog()
          break
        case DISCARD_CHANGES:
          handleDiscardChanges()
          break
      }
    },
    [handleSaveConfig, openSaveAsDialog, handleDiscardChanges],
  )

  return (
    <HeaderContainer data-testid={testIdWorkspaceHeaderRoot} positionTop={positionTop}>
      <Flex alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Flex alignItems="center">
          <WorkspaceMenu
            data-testid={testIdWorkspaceHeaderMenu}
            unsavedChanges={unsavedChanges}
            onAdd={handleAdd}
            onRename={openRenameDialog}
            onSave={handleSaveConfig}
            onDuplicate={openSaveAsDialog}
            onDiscard={openDiscardChanges}
            onDelete={handleDeleteConfig}
            onSelect={handleLoadConfig}
          />
          <PopperTooltip
            popperLabel={
              <ChartName
                variant={'h1'}
                width={isSmallScreen ? `4em` : `inherit`}
                data-testid={testIdWorkspaceHeaderChartName}
              >
                {chartName}
              </ChartName>
            }
            popperContent={chartName}
          />

          {/*{loading && <InlineLoading />}*/}
          {/*Save Workspace Draft Button*/}
          <Box ml={2} mr={1}>
            {draft.id && unsavedChanges ? (
              <SplitButton
                data-testid={testIdWorkspaceHeaderToolbarSaveButton}
                items={saveMenuItems}
                loading={loading}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<FontAwesomeIcon icon="save" fixedWidth />}
                onSelectItem={handleSaveMenuSelect}
              />
            ) : (
              <LoadingButton
                data-testid={testIdWorkspaceHeaderToolbarSaveButton}
                loading={loading}
                variant="contained"
                size="small"
                startIcon={<FontAwesomeIcon icon="save" fixedWidth />}
                onClick={handleSaveAs}
                disabled={isReadOnlyUser}
              >{t`Save as`}</LoadingButton>
            )}
          </Box>
        </Flex>
        <Box ml={1}>
          <WorkspaceToolbar
            data-testid={testIdWorkspaceHeaderToolbar}
            setTriggeredBackcastCreation={(value) => setTriggeredBackcastCreation(value)}
            isWorkbenchReadyWithSufficientData={isWorkbenchReadyWithSufficientData}
          />
        </Box>
      </Flex>

      <Box mt={1}>
        <ChartToolbar
          triggeredBackcastCreation={triggeredBackcastCreation}
          setTriggeredBackcastCreation={(value) => setTriggeredBackcastCreation(value)}
        />
      </Box>

      <WorkspaceHeaderDialogs
        confirmMenuBarActions={confirmMenuBarActions}
        renameDialogOpen={renameDialogOpen}
        saveAsDialogOpen={saveAsDialogOpen}
        discardDialogOpen={discardDialogOpen}
        chartNameText={chartNameText}
        chartName={chartName}
        onConfirmUnsavedChanges={handleConfirmUnsavedChanges}
        onCloseConfirmUnsavedChanges={closeConfirmUnsavedChanges}
        onConfirmDeleteChart={handleConfirmDeleteChart}
        onCloseConfirmDeleteChart={closeConfirmDeleteChart}
        onDiscardChanges={handleDiscardChanges}
        onCloseDiscardChanges={closeDiscardChanges}
        onRenameChart={handleRenameChart}
        onCloseRenameDialog={closeRenameDialog}
        onValidateRename={handleValidateRename}
        onValidateSaveAs={handleValidateSaveAs}
        onSaveDuplicatedChart={saveDuplicatedChart}
        onCloseSaveAsDialog={closeSaveAsDialog}
      />
    </HeaderContainer>
  )
}

// WorkspaceHeader.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(WorkspaceHeader)
