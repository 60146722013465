import { apiRequest, request, useOptimisticMutation } from 'utils/request'
import axios from 'axios'
import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { queryClient } from 'queryClient'
import { RunningBackcastsType, singleRunningBackcastType } from 'modules/workspace/header/backCast/backcast.types'
import { transformRunningBackcastsItemsForTable } from 'utils/backcasts'

export const QUERY_KEY_CALL_BACK_CAST_API = 'callBackCastApi'
export const QUERY_KEY_USED_BACK_CAST_COORDINATES = 'usedBackCastCoordinates'
export const KEY_GET_BACK_CAST_CREATION_STATUS = 'creationStatus'
export const QUERY_KEY_CREATE_BACK_CAST = 'createBackCast'
export const QUERY_KEY_RUNNING_BACKCASTS = 'runningBackcasts'

export interface BackCastUsedCoordinate {
  latitude: number
  longitude: number
  timestamp: number
}

export interface BackCastCreationResponse {
  message?: string
  request: BackCastCreationData
  status: string
}

export interface BackCastCreationData {
  forecastModelId?: string
  assetId?: string
  forecastConfigId: string
  start: Date | string
  end: Date | string
}

// Used normal request instead of async and await because we are not using react query
// export const createBackCast = (dataToCreateBackast: BackCastCreationData[]) => {
//   return request(() => axios.post('api/backcast/v1/calculate', dataToCreateBackast))
// }
export const getNoOfBackCastsRunning = () => {
  return request(() => axios.get('api/backcast/v1/running'))
}
export const createBackCast = async (dataToCreateBackast: BackCastCreationData[]) => {
  return await apiRequest<BackCastCreationResponse[]>(() =>
    axios.post('api/backcast/v1/calculate', dataToCreateBackast),
  )
}

export const getBackCastCoordinatesLimit = async () => {
  return await apiRequest<number>(() => axios.get('api/usersettings/siteAssessmentCoordinatesLimit'))
}

const getUsedBackCastCoordinates = async () => {
  return await apiRequest<BackCastUsedCoordinate[]>(() => axios.get('/api/v1/site/assessment/usedcoordinates'))
}

export const useBackCastCoordinates = () => {
  return useQuery<BackCastUsedCoordinate[]>(QUERY_KEY_USED_BACK_CAST_COORDINATES, getUsedBackCastCoordinates)
}

// const getDefaultValueForCallBackCastApi = (value: boolean): Promise<boolean> => {
//   console.log('inside debba =', value)
//
//   return new Promise((resolve, reject) => {
//     resolve(value)
//   })
// }
//
// export const useCallBackCastApi = () => {
//   return useQuery(QUERY_KEY_CALL_BACK_CAST_API, () => getDefaultValueForCallBackCastApi(false))
// }
//
// export const useCallBackCastApiSaveMutation = () => {
//   return useOptimisticMutation<boolean, boolean, boolean | undefined>({
//     queryCacheKey: QUERY_KEY_CALL_BACK_CAST_API,
//     apiMutator: (data) => getDefaultValueForCallBackCastApi(data),
//     cacheUpdater: (newValue, oldValue) => {
//       return newValue
//     },
//   })
// }

export const useCreateBackCastSaveMutation = () => {
  return useOptimisticMutation({
    queryCacheKey: QUERY_KEY_CREATE_BACK_CAST,
    apiMutator: (data) => createBackCast(data),
    onSettled: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_USED_BACK_CAST_COORDINATES)
    },
  })
}

// export const useCallBackCastApi = () => {
//   const [callApi, setCallApi] = useState(false)
//   const allAssets = useUniqueAllAssets()
//   const assetAndModelsSelection = useSelector(workspaceDraftAssetSelectionSelector)
//   const { data: coordinatesAlreadyUsed, isLoading, isSuccess: CoordinatesFetched } = useBackCastCoordinates()
//   const {
//     isLoading: createBackCastLoading,
//     data: createBackCastData,
//     isSuccess: createBackCastSuccess,
//   } = useCreateBackCastSaveMutation()
//
//   const selectedForecastModelsForBackCast = assetAndModelsSelection?.filter(
//     (id) => id && id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID),
//   )
//
//   useEffect(() => {
//     if (CoordinatesFetched && allAssets.length && selectedForecastModelsForBackCast.length && createBackCastSuccess) {
//       // const backCastAssetIds = selectedForecastModelsForBackCast.map((id) => getBackCastModelDataFromId(id)?.assetId)
//       // const selectedBackCastAssets = allAssets.filter((asset) => backCastAssetIds.includes(asset?.id))
//
//       // const hasNewCoordinate = selectedBackCastAssets?.some((asset) => {
//       //   const newCoordinateFound = (coordinatesAlreadyUsed || []).find(
//       //     (usedCoordinate) =>
//       //       asset?.location?.coordinate.latitude !== usedCoordinate.latitude &&
//       //       asset?.location?.coordinate.longitude !== usedCoordinate.longitude,
//       //   )
//       //   return Boolean(newCoordinateFound)
//       // })
//
//       setCallApi(true)
//     }
//   }, [allAssets, coordinatesAlreadyUsed, isLoading, CoordinatesFetched, createBackCastSuccess, createBackCastData])
//
//   return callApi
// }

// Get all running backcasts
const getAllRunningBackcasts = async () => {
  return await apiRequest<RunningBackcastsType>(() => axios.get(`/api/backcast/v1/running/info/all`))
}

export const useAllRunningBackcasts = () => {
  return useQuery<RunningBackcastsType>(QUERY_KEY_RUNNING_BACKCASTS, getAllRunningBackcasts)
}

export const useRunningBackcastsTableItems = () => {
  const runningBackcastsResults = useAllRunningBackcasts()
  const tableItemsResult: QueryObserverResult<RunningBackcastsType> = { ...runningBackcastsResults, data: undefined }

  if (runningBackcastsResults.data) {
    if (Object.keys(runningBackcastsResults.data).length > 0) {
      tableItemsResult.data = transformRunningBackcastsItemsForTable(runningBackcastsResults.data)
    }
  }

  return tableItemsResult
}

// Delete queued backcasts
const deleteRunningBackcasts = async (item: singleRunningBackcastType) => {
  return await apiRequest<singleRunningBackcastType>(() => {
    return axios.delete(`/api/backcast/v1/cancel`, { data: item })
  })
}

export const useRunningBackcastsDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteRunningBackcasts, {
    onSettled: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_RUNNING_BACKCASTS)
    },
  })
}
