import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { RETABLE_ID_TRAINING, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { getTrainingColumns } from 'utils/training'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import ReTable from 'modules/reTable/ReTable'
import TrainingTableBody from 'modules/quality/TrainingTable/TrainingTableBody'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import { table } from 'themes/theme-light'

interface TrainingTableProps {
  trainingTableValues: any[]
}

const TrainingTable: React.FC<TrainingTableProps> = ({ trainingTableValues }) => {
  const dispatch = useDispatch()
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const [itemsToRender, setItemsToRender] = useState<any[]>([])

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_TRAINING)

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'jobName',
    }),
    [],
  )

  const handleItemsToRenderChange = useCallback((items: any[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  const columnsAvailable = useMemo(() => getTrainingColumns(), [])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_TRAINING,
      columnsAvailable,
    })
  }, [columnsAvailable])

  const tableHeaderHasAction = false

  return (
    <ReTable
      id={RETABLE_ID_TRAINING}
      itemHeight={table.rowHeight}
      itemsPaddingHeader={ITEMS_PADDING_HEADER}
      itemsPaddingFooter={ITEMS_PADDING_FOOTER}
      items={trainingTableValues as ReTableItem[]}
      renderAheadCount={RENDER_AHEAD_COUNT}
      hasExtendedHeader={tableHeaderHasAction}
      defaultSort={defaultSort}
      onItemsToRenderChange={handleItemsToRenderChange}
      tableHeight={trainingTableValues.length === 0 ? '5em' : 'auto'}
    >
      <ReTableGenericHeader tableHeaderHasActions={tableHeaderHasAction} columns={columnsSelected} />
      <TrainingTableBody visibleItems={itemsToRender} columns={columnsSelected} />
    </ReTable>
  )
}

export default TrainingTable
