import React, { useCallback, useEffect } from 'react'
import Flex from 'ui/styles/Flex'
import { Box, Button, Checkbox as MaterialCheckbox, FormControlLabel, Typography } from '@material-ui/core'
import { c } from 'ttag'
import SliderElement from 'ui/elements/SliderElement'
import { isNumeric } from 'utils/dataFormatting'
import {
  MDCAssetType,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { useDefaultMDCFilterSettingsSaveMutation } from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { Field, useField } from 'react-final-form'
import { FormApi } from 'final-form'
import { isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import styled from 'styled-components'
import LoadingButton from 'ui/form/LoadingButton'
import { BULK_FILTER_SETTINGS_SOLAR, BULK_FILTER_SETTINGS_WIND } from 'utils/meterDataCleansing'
import { useCustomMuiStyles } from 'themes/theme-light'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { Checkbox } from 'final-form-material-ui'

import { AppUnits } from 'utils/units'

const CustomFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0 0 0 -5px;
  }
`

const ConfigurationFilters = {
  RANGE: 'range',
  REPETITIVE: 'repetitive',
  OUTLIER_LEFT: 'outlierLeft',
  OUTLIER_RIGHT: 'outlierRight',
  GHI_THRESHOLD_NO_POWER: 'ghiThresholdNoPower',
  MULTIPLE_GRID_POINTS: 'multipleGridPoints',
  REJECT_NIGHT_VALUES: 'filterNightValues',
}

interface FilterSettingsProps {
  form: FormApi
  defaultFilterSettings?: MeterDataCleansingFilterSettings
  disabled?: boolean
  hasLinkToDefault?: boolean
  linkToDefault: boolean
  onLinkToDefaultChange: () => void
  showAdditionalFiltersForSolar: boolean
  showAdditionalFiltersForWind: boolean
  assetTypeBulkFilterKeys?: Record<string, string>
  bulkFilterSettingsHeading?: string
}

const FilterSettings: React.FC<FilterSettingsProps> = ({
  form,
  defaultFilterSettings,
  disabled = false,
  hasLinkToDefault = false,
  linkToDefault,
  onLinkToDefaultChange,
  showAdditionalFiltersForSolar,
  showAdditionalFiltersForWind,
  assetTypeBulkFilterKeys,
  bulkFilterSettingsHeading,
}) => {
  const user = useSelector(getUserResultSelector)
  const { mutate: saveMutation, isLoading, isSuccess } = useDefaultMDCFilterSettingsSaveMutation(
    showAdditionalFiltersForSolar ? MDCAssetType.SOLAR : MDCAssetType.WIND,
  )

  let filterKeys = ConfigurationFilters
  if (assetTypeBulkFilterKeys) {
    filterKeys = assetTypeBulkFilterKeys
  }

  const {
    RANGE,
    REPETITIVE,
    OUTLIER_LEFT,
    OUTLIER_RIGHT,
    GHI_THRESHOLD_NO_POWER,
    MULTIPLE_GRID_POINTS,
    REJECT_NIGHT_VALUES,
  } = filterKeys
  const rangeValue = useField<number>(RANGE).input.value
  const repetitiveValue = useField<number>(REPETITIVE).input.value
  const outlineLeftValue = useField<number>(OUTLIER_LEFT).input.value
  const outlineRightValue = useField<number>(OUTLIER_RIGHT).input.value
  const ghiThresholdNoPowerValue = useField<number>(GHI_THRESHOLD_NO_POWER).input.value
  const isReadOnlyUser = useIsReadOnlyUser()
  const classes = useCustomMuiStyles()

  const updateValue = useCallback((inputName: string, value: number) => {
    form?.mutators.setValue(inputName, value)
  }, [])

  const handleResetSliders = () => {
    const filters = [RANGE, OUTLIER_LEFT, OUTLIER_RIGHT]
    filters.forEach((filter) => {
      updateValue(filter, 0)
    })
    // Wind specific filter
    if (showAdditionalFiltersForWind) {
      updateValue(REPETITIVE, 0)
    }
    // Solar specific filter
    if (showAdditionalFiltersForSolar) {
      updateValue(GHI_THRESHOLD_NO_POWER, 10)
    }
  }

  const handleUseDefaultFilters = useCallback(() => {
    // Common filters for both type of assets
    const filters = [RANGE, OUTLIER_LEFT, OUTLIER_RIGHT]
    if (defaultFilterSettings) {
      filters.forEach((filter) => {
        if (isNumeric(defaultFilterSettings[filter])) {
          form?.mutators.setValue(filter, defaultFilterSettings[filter])
        }
      })
      // Wind specific filter
      if (showAdditionalFiltersForWind) {
        form?.mutators.setValue(REPETITIVE, defaultFilterSettings[REPETITIVE])
      }
      // Solar specific filter
      if (showAdditionalFiltersForSolar) {
        form?.mutators.setValue(GHI_THRESHOLD_NO_POWER, defaultFilterSettings[GHI_THRESHOLD_NO_POWER])
      }
    }
  }, [defaultFilterSettings, showAdditionalFiltersForWind, showAdditionalFiltersForSolar])

  useEffect(() => {
    if (isSuccess) {
      handleUseDefaultFilters()
    }
  }, [handleUseDefaultFilters, isSuccess])

  const handleSetAsDefaultFilters = useCallback(() => {
    const data: MeterDataCleansingFilterSettings = {
      range: rangeValue,
      outlierLeft: outlineLeftValue,
      outlierRight: outlineRightValue,
    }
    if (showAdditionalFiltersForWind) {
      data['repetitive'] = repetitiveValue
    }
    if (showAdditionalFiltersForSolar) {
      data['ghiThresholdNoPower'] = ghiThresholdNoPowerValue
    }

    saveMutation({ data, mdcAssetType: showAdditionalFiltersForSolar ? MDCAssetType.SOLAR : MDCAssetType.WIND })
  }, [
    rangeValue,
    repetitiveValue,
    outlineRightValue,
    outlineLeftValue,
    showAdditionalFiltersForSolar,
    showAdditionalFiltersForWind,
  ])

  const handleSaveMenuSelect = useCallback(() => {
    handleSetAsDefaultFilters()
    updateValue(RANGE, rangeValue)
    updateValue(OUTLIER_LEFT, outlineLeftValue)
    updateValue(OUTLIER_RIGHT, outlineRightValue)
    if (showAdditionalFiltersForWind) {
      updateValue(REPETITIVE, repetitiveValue)
    } else {
      updateValue(GHI_THRESHOLD_NO_POWER, ghiThresholdNoPowerValue)
    }

    // we are updating default settings , so we need to clean session
    if (showAdditionalFiltersForWind && sessionStorage.getItem(BULK_FILTER_SETTINGS_WIND)) {
      sessionStorage.removeItem(BULK_FILTER_SETTINGS_WIND)
    }
    if (showAdditionalFiltersForSolar && sessionStorage.getItem(BULK_FILTER_SETTINGS_SOLAR)) {
      sessionStorage.removeItem(BULK_FILTER_SETTINGS_SOLAR)
    }
  }, [handleSetAsDefaultFilters, showAdditionalFiltersForWind, showAdditionalFiltersForSolar])

  const handleLinkToDefaultChange = () => {
    // Set filters value as default values
    handleUseDefaultFilters()
    // Handle link to default change
    onLinkToDefaultChange()
  }

  return (
    <Flex direction="column">
      <Flex direction="row" justifyContent="space-between" flexWrap="wrap">
        <Typography variant="subtitle1">
          {bulkFilterSettingsHeading ? bulkFilterSettingsHeading : c('Meter data cleansing').t`Filter settings`}
        </Typography>

        <Flex flexGrow={1} justifyContent="flex-end" style={{ marginBottom: '0.5em' }} flexWrap="wrap">
          {hasLinkToDefault && (
            <CustomFormControlLabel
              control={
                <MaterialCheckbox
                  color="primary"
                  checked={linkToDefault}
                  onChange={() => handleLinkToDefaultChange()}
                />
              }
              label={c('Meter data cleansing').t`Link to default`}
            />
          )}
          <Flex>
            <Box ml={hasLinkToDefault ? 1 : 0}>
              <LoadingButton
                color="primary"
                size="small"
                variant="contained"
                loading={isLoading}
                disabled={isReadOnlyUser || disabled || linkToDefault}
                onClick={handleSaveMenuSelect}
              >{c('Meter data cleansing').t`Save current settings as default`}</LoadingButton>
            </Box>

            <Box ml={1}>
              <Button
                onClick={handleResetSliders}
                color="primary"
                size="small"
                variant="contained"
                disabled={isReadOnlyUser || disabled || linkToDefault}
              >
                {c('Meter data cleansing').t`Set to 0`}
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>

      <SliderElement
        inputName={`${RANGE}`}
        sliderName={c('Meter data cleansing').t`Range`}
        sliderUnit={AppUnits.PERCENTAGE}
        sliderInfo={c('Meter data cleansing')
          .t`Detects erroneous or extreme power values (below zero and above the nameplate capacity) and filters them out. When set to 0%, the acceptance tolerance is 5% of the nameplate capacity and when set to 100% the tolerance is 0%.`}
        onChangeValue={updateValue}
        initialValue={rangeValue}
        disabled={disabled || linkToDefault}
      />
      {showAdditionalFiltersForWind && (
        <SliderElement
          inputName={`${REPETITIVE}`}
          sliderName={c('Meter data cleansing').t`Repetitive`}
          sliderUnit={AppUnits.PERCENTAGE}
          sliderInfo={c('Meter data cleansing')
            .t`Detects similar power values when consecutive and filters them out. When set to 0%, nothing is detected and when set to 100%, detection is maximized.`}
          onChangeValue={updateValue}
          initialValue={repetitiveValue}
          disabled={disabled || linkToDefault}
        />
      )}

      <SliderElement
        inputName={`${OUTLIER_LEFT}`}
        sliderName={c('Meter data cleansing').t`Outlier left`}
        sliderUnit={AppUnits.PERCENTAGE}
        sliderInfo={c('Meter data cleansing')
          .t`Detects outlier values on the left side of the power curve distribution plot and filters them out. When set to 0%, nothing is detected and when set to 100%, detection is maximized.`}
        onChangeValue={updateValue}
        initialValue={outlineLeftValue}
        disabled={disabled || linkToDefault}
      />
      <SliderElement
        inputName={`${OUTLIER_RIGHT}`}
        sliderName={c('Meter data cleansing').t`Outlier right`}
        sliderUnit={AppUnits.PERCENTAGE}
        sliderInfo={c('Meter data cleansing')
          .t`Detects outlier values on the right side of the power curve distribution plot and filters them out. When set to 0%, nothing is detected and when set to 100%, detection is maximized.`}
        onChangeValue={updateValue}
        initialValue={outlineRightValue}
        disabled={disabled || linkToDefault}
      />

      {isAdmin(user) || isImpersonatedAdmin(user) ? (
        <div className={classes.adminComponent}>
          {showAdditionalFiltersForSolar && (
            <>
              <SliderElement
                inputName={`${GHI_THRESHOLD_NO_POWER}`}
                sliderName={c('Meter data cleansing').t`GHI limit`}
                sliderUnit={AppUnits.WATT_PER_SQUARE_METER}
                sliderInfo={c('Meter data cleansing')
                  .t`Detects low power values where GHI is above the minimum limit that is set and filters them out.`}
                onChangeValue={updateValue}
                initialValue={ghiThresholdNoPowerValue}
                sliderStep={10}
                disabled={disabled || linkToDefault}
                minValue={10}
                maxValue={500}
              />
              <Flex direction="row" alignItems="center">
                <CustomFormControlLabel
                  label={''}
                  control={<Field name={`${REJECT_NIGHT_VALUES}`} type="checkbox" component={Checkbox} />}
                />
                <>{c('Meter data cleansing').t`Reject night values`}</>
              </Flex>
            </>
          )}

          <Flex direction="row" alignItems="center">
            <CustomFormControlLabel
              label={''}
              control={<Field name={`${MULTIPLE_GRID_POINTS}`} type="checkbox" component={Checkbox} />}
            />
            <>{c('Meter data cleansing').t`Check surrounding grid points`}</>
          </Flex>
        </div>
      ) : null}
    </Flex>
  )
}

export default React.memo(FilterSettings)
