import { useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'

// value         |0px     600px    960px    1280px   1920px
// key           |xs      sm       md       lg       xl
// screen width  |--------|--------|--------|--------|-------->
// range         |   xs   |   sm   |   md   |   lg   |   xl

export enum ScreenSize {
  xs = 'xs', // > 0px
  sm = 'sm', // >= 600px
  md = 'md', // >= 960px
  lg = 'lg', // >= 1280px
  xl = 'xl', // >= 1920px
}

// interface CurrentScreenSize {
//   xs: boolean
//   sm: boolean
//   md: boolean
//   lg: boolean
//   xl: boolean
// }

// useMediaQuery(theme.breakpoints.up(size)) --> matches screen widths greater than and including the screen size given by the breakpoint key.
// useMediaQuery(theme.breakpoints.down(size)) --> matches screen widths less than and including the screen size given by the breakpoint key.

export const useScreenSizeLessThanGivenSize = (size: ScreenSize) => {
  const theme = useTheme()
  const isSizeMatched = useMediaQuery(theme.breakpoints.down(size)) // matches screen widths greater than and including the screen size given by the breakpoint key.
  return isSizeMatched
}
