import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  useDeliveryTargetById,
  useDeliveryTargets,
  useDeliveryTargetSaveMainItemKey,
  useDeliveryTargetSaveMutation,
} from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import { FormApi, Mutator } from 'final-form'
import { DeliveryTarget } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import DeliveryTargetForm from 'modules/delivery/deliveryTargets/DeliveryTargetForm'
import { FormSaveOptions, genericFormSubscription, getCopyName } from 'utils/form'

import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { Form } from 'react-final-form'
import { Box } from '@material-ui/core'
import CenteredLoading from 'ui/CenteredLoading'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import { getDeliveryTargetQueryObj, QUERY_DELIVERY_TARGET, useGetFullPath, useQueryString } from 'utils/query-string'
import { c } from 'ttag'
import { Link } from 'react-router-dom'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { validateDeliveryTargetForm } from 'utils/formValidations'
import { prepareDeliveryTargetBeforeSave, transformDeliveryTargetAfterGet } from 'utils/delivery'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isDemo } from 'utils/user'

const FormContainer = styled(Flex)`
  height: 100%;

  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
  }
`

let formReference: FormApi<DeliveryTarget>

interface DeliveryTargetDetailsProps {
  targetId: string
}

const DeliveryTargetDetails: React.FC<DeliveryTargetDetailsProps> = ({ targetId }) => {
  const isNew = targetId === 'new'
  const user = useSelector(getUserResultSelector)
  const allDeliveryTargets = useDeliveryTargets()
  const saveMainItemKey = useDeliveryTargetSaveMainItemKey()

  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const forecastConfigurations = useSiteForecastConfigs()
  const [deliveryTarget, setDeliveryTarget] = useState<DeliveryTarget | null>(null)

  const saveDeliveryTargetResult = useDeliveryTargetSaveMutation(isNew)
  const { reset: resetSaveResult } = saveDeliveryTargetResult
  const saveDeliveryTargetMutation = saveDeliveryTargetResult.mutate

  const deliveryTargetResult = useDeliveryTargetById(targetId)

  const savedMainItem = useMemo(() => {
    const mainItem = isNew ? saveMainItemKey.data?.createDeliveryTarget : saveMainItemKey.data?.updateDeliveryTarget
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNew, saveMainItemKey.data])

  const deliveryTargetNames = useMemo(() => allDeliveryTargets?.data?.map((dt) => dt.name), [allDeliveryTargets?.data])

  const handleFormSubmit = useCallback(
    (formData: DeliveryTarget) => {
      if (
        savedMainItem === FormSaveOptions.CREATE_COPY &&
        formData?.id &&
        deliveryTargetNames &&
        deliveryTargetNames?.length
      ) {
        formData.isNewCopy = true
        formData.name = getCopyName(formData.name, deliveryTargetNames)
        delete formData.id
      }

      const preparedData = prepareDeliveryTargetBeforeSave(formData)
      saveDeliveryTargetMutation(preparedData)
    },
    [savedMainItem, deliveryTargetNames],
  )

  const handleCloseSlider = useCallback(() => {
    onDeleteQueryStrings([QUERY_DELIVERY_TARGET])
  }, [onDeleteQueryStrings])

  const formMutators = useMemo<{ [key: string]: Mutator<DeliveryTarget, Partial<DeliveryTarget>> }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
    }
  }, [])

  const isDemoUser = useMemo(() => isDemo(user), [user])

  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <DeliveryTargetForm
          isNew={isNew}
          form={form}
          onFormSubmit={handleSubmit}
          onCloseSlider={handleCloseSlider}
          siteForecastConfigs={forecastConfigurations.data}
          saveResult={saveDeliveryTargetResult}
          isDemoUser={isDemoUser}
        />
      )
    },
    [
      isNew,
      saveDeliveryTargetResult.isLoading,
      saveDeliveryTargetResult?.isError,
      forecastConfigurations.data,
      isDemoUser,
    ],
  )

  // Details not found message with create link
  const routeToNewDeliveryTarget = useGetFullPath(getDeliveryTargetQueryObj())
  const detailsNotFoundMessage = useMemo(() => {
    const createNewLink = <Link to={routeToNewDeliveryTarget}>{c('Delivery:Target').t`new delivery target`}</Link>
    return c('Delivery:Target').jt`Delivery target not found. Please select from the list or create a ${createNewLink}.`
  }, [routeToNewDeliveryTarget])

  const formSubscription = useMemo(() => genericFormSubscription(), [])

  useEffect(() => {
    if (deliveryTargetResult?.data && forecastConfigurations?.isSuccess) {
      const data = transformDeliveryTargetAfterGet(deliveryTargetResult?.data, forecastConfigurations.data)
      setDeliveryTarget(deliveryTargetResult?.isSuccess ? data : null)
      resetSaveResult()
    }
  }, [deliveryTargetResult?.data, forecastConfigurations?.isSuccess, isNew, targetId, user])

  // Actions need to be performed after saving the delivery target
  useEffect(() => {
    if (saveDeliveryTargetResult?.isSuccess && saveDeliveryTargetResult?.data && forecastConfigurations?.data) {
      const data = saveDeliveryTargetResult?.data
      const { SAVE, SAVE_AND_NEW, SAVE_AND_NEXT, CREATE_COPY } = FormSaveOptions

      if (isNew && savedMainItem === SAVE_AND_NEW) {
        formReference.reset()
        formReference.restart()
      } else {
        formReference.reset(transformDeliveryTargetAfterGet(data, forecastConfigurations?.data))
      }
      resetSaveResult()

      setTimeout(() => {
        if (savedMainItem === SAVE || savedMainItem === CREATE_COPY) {
          onUpdateQueryString(getDeliveryTargetQueryObj(data))
        } else if (savedMainItem === SAVE_AND_NEW) {
          formReference.restart()
        } else if (savedMainItem === SAVE_AND_NEXT) {
          const currentDeliveryTargetIndex = allDeliveryTargets?.data?.findIndex((target) => target.id === data.id)
          const deliveryTargetCount = (allDeliveryTargets.data || []).length
          let nextDeliveryTargetIndex = 0

          if (currentDeliveryTargetIndex !== undefined && currentDeliveryTargetIndex + 1 !== deliveryTargetCount) {
            nextDeliveryTargetIndex = currentDeliveryTargetIndex + 1
          }
          onUpdateQueryString(getDeliveryTargetQueryObj((allDeliveryTargets.data || [])[nextDeliveryTargetIndex]))
        } else {
          onDeleteQueryStrings([QUERY_DELIVERY_TARGET])
        }
      }, 400)
    }
  }, [
    onUpdateQueryString,
    isNew,
    JSON.stringify(allDeliveryTargets),
    saveDeliveryTargetResult?.isSuccess,
    JSON.stringify(saveDeliveryTargetResult?.data),
    JSON.stringify(forecastConfigurations?.data),
    savedMainItem,
  ])

  return (
    <div>
      {deliveryTarget ? (
        <FormContainer direction="column">
          <Form
            mutators={formMutators}
            onSubmit={handleFormSubmit}
            initialValues={deliveryTarget}
            validate={validateDeliveryTargetForm}
            subscription={formSubscription}
            render={formRender}
          />
        </FormContainer>
      ) : deliveryTargetResult?.isLoading ? (
        <Box mt={3}>
          <CenteredLoading size="2em" />
        </Box>
      ) : (
        <DetailsNotFound key="details-not-found" message={detailsNotFoundMessage} onClose={handleCloseSlider} />
      )}
    </div>
  )
}

export default React.memo(DeliveryTargetDetails)
