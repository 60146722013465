import React from 'react'
import { TableBody } from '@material-ui/core'

import { ReTableRow } from 'modules/reTable/ReTableRow'
import { reTableVirtualRangeSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelector } from 'modules/reTable/reTable.hooks'

interface ReTableBodyProps {
  tableBodyClassName?: string
}

export const ReTableBody: React.FC<ReTableBodyProps> = ({ tableBodyClassName, children }) => {
  const [ref, virtualRange] = useReTableSelector(reTableVirtualRangeSelector)
  // const [startGap, setStartGap] = useState(0)
  // const [endGap, setEndGap] = useState(0)

  // useEffect(() => {
  //   // delay setting gaps so that it synchronizes with displayed items of virtual range
  //   requestAnimationFrame(() => setStartGap(virtualRange.startGap))
  // }, [virtualRange.startGap])

  // useEffect(() => {
  //   // delay setting gaps so that it synchronizes with displayed items of virtual range
  //   requestAnimationFrame(() => setEndGap(virtualRange.endGap))
  // }, [virtualRange.endGap])

  return (
    <TableBody ref={ref}>
      <ReTableRow className={tableBodyClassName} style={{ height: `${virtualRange.startGap}px` }} />
      {children}
      <ReTableRow style={{ height: `${virtualRange.endGap}px` }} />
    </TableBody>
  )
}
