import React, { useMemo } from 'react'
import Highlight from 'ui/Highlight'
import { FormControlFullWidth } from 'ui/form/reactFinalFormFields.style'
import { Field } from 'react-final-form'
import { Select } from 'final-form-material-ui'
import { Box, MenuItem } from '@material-ui/core'
import { Availability } from 'modules/asset/availability/Availability.types'
import { Column, RETABLE_ID_AVAILABILITIES } from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'
import windAvailabilityTypeNormal from 'media/wind-availability-type-normal.png'
import windAvailabilityCap from 'media/wind-availability-type-cap.png'
import windAvailabilityTypeScale from 'media/wind-availability-type-scale.png'
import solarAvailabilityTypeNormal from 'media/solar-availability-type-normal.png'
import solarAvailabilityTypeCap from 'media/solar-availability-type-cap.png'
import solarAvailabilityTypeScale from 'media/solar-availability-type-scale.png'
import styled from 'styled-components'
import { Asset } from 'modules/asset/store/asset.types'
import { isCluster, isSolarPark, isSolarPlant } from 'utils/asset'
import { AvailabilityTypes } from 'fixtures/assetMaintenance'

const AvailabilityTypeImage = styled.img`
  height: 16em;
`

const clusterContentWidth = '62em'

interface AvailabilityTypeCellProps {
  availability: Availability
  column: Column
  isEdit: boolean
  asset: Asset
}

const AvailabilityTypeCell: React.FC<AvailabilityTypeCellProps> = ({ availability, column, isEdit, asset }) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_AVAILABILITIES)
  const selectedMenuItem = (column.selectItems || []).find((item) => item.id === availability.type)
  const menuItems = useMemo(() => {
    if (isCluster(asset) && column?.selectItems) {
      return (column.selectItems || []).filter((menuItem) => menuItem.id === AvailabilityTypes.CAP)
    } else return column.selectItems
  }, [column, asset])

  const solarAsset = useMemo(() => isSolarPark(asset) || isSolarPlant(asset), [asset])

  return (
    <Box width={column.width}>
      {!isEdit && selectedMenuItem && <Highlight text={selectedMenuItem?.label || ''} query={query} />}
      {isEdit && column?.fieldName && (
        <Flex justifyContent="center" alignItems="flex-end">
          <FormControlFullWidth>
            <Field name={column?.fieldName} component={Select} fullWidth label={''}>
              {(menuItems || []).map((type) => (
                <MenuItem key={type.label} value={type.id}>
                  {type.label}
                </MenuItem>
              ))}
            </Field>
          </FormControlFullWidth>
          <PopperTooltip
            contentWidth={isCluster(asset) ? clusterContentWidth : ''}
            popperLabel={
              <Box ml={0.7} mb={0.5}>
                <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
              </Box>
            }
            popperContent={
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                width={isCluster(asset) ? clusterContentWidth : ''}
              >
                {(solarAsset || isCluster(asset)) && (
                  <Box width="min-content">
                    {isCluster(asset) && <div>Solar</div>}
                    <AvailabilityTypeImage src={solarAvailabilityTypeNormal} />
                    <AvailabilityTypeImage src={solarAvailabilityTypeCap} />
                    <AvailabilityTypeImage src={solarAvailabilityTypeScale} />
                  </Box>
                )}
                {(!solarAsset || isCluster(asset)) && (
                  <Box width="min-content">
                    {isCluster(asset) && <div>Wind</div>}
                    <AvailabilityTypeImage src={windAvailabilityTypeNormal} />
                    <AvailabilityTypeImage src={windAvailabilityCap} />
                    <AvailabilityTypeImage src={windAvailabilityTypeScale} />
                  </Box>
                )}
              </Box>
            }
          />
        </Flex>
      )}
    </Box>
  )
}

export default React.memo(AvailabilityTypeCell)
