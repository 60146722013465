import React, { ErrorInfo } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { t } from 'ttag'

import Flex from 'ui/styles/Flex'

const MAX_RETRIES = 3

const StyledButton = styled(Button)`
  &.MuiButton-root {
    width: fit-content;
  }
`

interface State {
  hasError: boolean
  error: Error | null
  retryCount: number
}

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false, error: null, retryCount: 0 }
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO log error for monitoring
    console.error(error, errorInfo)
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<State>): void {
    if (!prevState.hasError) {
      if (this.state.retryCount > 0) {
        this.setState({
          hasError: false,
          error: null,
          retryCount: 0,
        })
      }
      return
    }

    if (this.state.retryCount < MAX_RETRIES) {
      this.handleRetry()
    }
  }

  handleRetry() {
    requestAnimationFrame(() => {
      this.setState({
        hasError: false,
        error: null,
        retryCount: this.state.retryCount + 1,
      })
    })
  }

  render() {
    if (this.state.hasError && this.state.retryCount === MAX_RETRIES) {
      const error = this.state.error || {
        name: t`General error`,
        message: t`Unknown`,
      }
      return (
        <Box mx={2}>
          <Flex direction="column">
            <Typography variant="subtitle1" color="error">
              Something went wrong
            </Typography>
            <Box my={1}>
              <StyledButton variant="contained" color="primary" onClick={this.handleRetry.bind(this)}>
                <Box mr={1}>
                  <FontAwesomeIcon icon="sync" color="white" />
                </Box>
                {t`Retry`}
              </StyledButton>
            </Box>
            <Typography>
              {error.name}: {error.message}
            </Typography>
            {/*{error.stack && <Typography>{error.stack}</Typography>}*/}
          </Flex>
        </Box>
      )
    }

    return this.props.children
  }
}

export default React.memo(ErrorBoundary)
