import React, { useMemo } from 'react'
import { Box, Typography } from '@material-ui/core'
import { c } from 'ttag'
import { useDeliveryTargetsTableItems } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import styled from 'styled-components'
import DeliveryTargetsTable from 'modules/delivery/deliveryTargets/DeliveryTargetsTable'
import Flex from 'ui/styles/Flex'
import InlineLoading from 'ui/InlineLoading'

const Info = styled(Box)`
  max-width: 68em;
`
const DeliveryTargets: React.FC = () => {
  const deliveryTargetResult = useDeliveryTargetsTableItems()
  const deliveriesLoading = useMemo(() => deliveryTargetResult.isLoading, [deliveryTargetResult])

  return (
    <>
      <Box mb={1}>
        <Flex alignItems="center">
          <Typography variant="h5">{c('Delivery:Target').t`Delivery targets`}</Typography>
          {deliveriesLoading && <InlineLoading />}
        </Flex>

        <Info>
          <Typography>{c('Delivery:Target')
            .t`Where do you want your forecasts to be delivered to? Please define one or more targets for delivering your forecasts, such as an e-mail address or an ftp server. You can later assign these targets to individual forecasts. You can also use the default delivery target which will display the forecasts online only.`}</Typography>
        </Info>
      </Box>
      {deliveryTargetResult.data && <DeliveryTargetsTable deliveryTargets={deliveryTargetResult.data} />}
    </>
  )
}

export default React.memo(DeliveryTargets)
