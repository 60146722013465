import { Box, IconButton } from '@material-ui/core'

import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import AssetLink from 'modules/asset/AssetLink'
import { Asset } from 'modules/asset/store/asset.types'
import React from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'themes/theme-light'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import Flex from 'ui/styles/Flex'
import { getAssetQueryObj, stringifyQueryParams } from 'utils/query-string'

interface AssetListProps {
  assets: Asset[]
}

const AssetList: React.FC<AssetListProps> = ({ assets }) => {
  return (
    <>
      {assets.map((asset) => {
        const queryString = stringifyQueryParams(
          getAssetQueryObj({
            id: asset?.id,
          }),
        )
        const assetDetailsURL = `${ROUTE_WORKBENCH}?${queryString}`

        return (
          <Box key={asset.id} mt={1}>
            <Flex alignItems="center" justifyContent="space-between">
              <AssetLink asset={asset} />
              <Link className="details-link" to={assetDetailsURL}>
                <IconButton size="small">
                  <FontAwesomeActionIcon icon="eye" color={theme.palette.primary.main} />
                </IconButton>
              </Link>
            </Flex>
          </Box>
        )
      })}
    </>
  )
}

export default React.memo(AssetList)
