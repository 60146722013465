import { AREA_ID } from 'fixtures/areaLabels'
import { Timezone } from 'fixtures/timezones'
import { ReTableContextMenuItemName } from 'modules/reTable/reTable.types'
import { ForecastType } from 'modules/reTable/ReTableForecastsManagement'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'
import { FormSaveOptions } from 'utils/form'

// selection mode
export const DATA_SELECTION_MODE_SINGLE = 'DATA_SELECTION_MODE_SINGLE'
export const DATA_SELECTION_MODE_MULTIPLE = 'DATA_SELECTION_MODE_MULTIPLE'
export type DATA_SELECTION_MODE_SINGLE = typeof DATA_SELECTION_MODE_SINGLE
export type DATA_SELECTION_MODE_MULTIPLE = typeof DATA_SELECTION_MODE_MULTIPLE
export type DataSelectionMode = DATA_SELECTION_MODE_SINGLE | DATA_SELECTION_MODE_MULTIPLE

// data stream definition

//
export const scheduleDataMenuName = 'schedule'

// This is used only to check results from Backend.
export enum ForecastConfigurationCategory {
  ENERCAST_SITE_FORECAST = 'ENERCAST_SITE_FORECAST',
  SITE_SCHEDULE = 'SITE_SCHEDULE',
  EXTERNAL_SITE_FORECAST = 'EXTERNAL_SITE_FORECAST',
}

export type TimeSeries = Record<string, number | null>

export enum TimeSeriesType {
  SITE_FORECAST = 'SITE_FORECAST',
  META_FORECAST = 'META_FORECAST', // This type is also comes under site forecast in dataStream tree
  BACK_CAST = 'BACK_CAST',
  E3_META_FORECAST = 'E3_META_FORECAST',
  AREA_FORECAST = 'AREA_FORECAST',
  CAPACITY_DATA = 'CAPACITY_DATA',
  METER_DATA = 'METER_DATA',
  WEATHER_DATA = 'WEATHER_DATA',
  MARKET_PRICE_DATA = 'MARKET_PRICE_DATA',
  SEASONAL_FORECAST = 'SEASONAL_FORECAST',
  CLIMATOLOGY = 'CLIMATOLOGY',
  SCHEDULE = 'SCHEDULE',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum TimeSeriesSubType {
  // capacity related
  PLANNED_AVAILABLE_CAPACITY = 'PLANNED_AVAILABLE_CAPACITY', // available capacity planned in our portal
  MAX_AVAILABLE_CAPACITY = 'MAX_AVAILABLE_CAPACITY', // available capacity time series
  INSTALLED_CAPACITY = 'INSTALLED_CAPACITY', // nameplate capacity time series
  SCALED_CAPACITY = 'SCALED_CAPACITY',
  CAPPED_CAPACITY = 'CAPPED_CAPACITY',

  // e³ related
  META_ENSEMBLE = 'META_ENSEMBLE',
  E3_WEATHER_TRACK = 'E3_WEATHER_TRACK',
  E3_THIRD_PARTY_FORECAST = 'E3_THIRD_PARTY_FORECAST',

  // market price data
  INDIAN_MARKET_PRICE_DATA = 'INDIAN_MARKET_PRICE_DATA',
  GERMANY_MARKET_PRICE_DATA = 'GERMANY_MARKET_PRICE_DATA',
  FRANCE_MARKET_PRICE_DATA = 'FRANCE_MARKET_PRICE_DATA',
  TURKEY_MARKET_PRICE_DATA = 'TURKEY_MARKET_PRICE_DATA',
  AUSTRIA_MARKET_PRICE_DATA = 'AUSTRIA_MARKET_PRICE_DATA',

  // long range forecast
  SEASONAL_FORECAST = 'SEASONAL_FORECAST',
  CLIMATOLOGY = 'CLIMATOLOGY',
}

export enum TimeSeriesClassifier {
  // time series related
  // see https://enercast.atlassian.net/browse/PR-5978?focusedCommentId=208979
  UNKNOWN = 'UNKNOWN', // not specified
  SCADA = 'SCADA', // live or real-time data
  METERING_FINAL = 'METERING_FINAL', // end of month data
  CLEANED_BY_ENERCAST = 'CLEANED_BY_ENERCAST', // currently used for uploaded in portal, should be changed
  // Market price data
  // INDIAN_NORMAL_RATE = 'INDIAN_NORMAL_RATE', Indian classifiers are nothing but indian states so we get them from database
  GERMANY_EPEX_DAY_AHEAD_AUCTION = 'GERMANY_EPEX_DAY_AHEAD_AUCTION',
  FRANCE_EPEX_DAY_AHEAD_AUCTION = 'FRANCE_EPEX_DAY_AHEAD_AUCTION',
  FRANCE_M0_SOLAR = 'FRANCE_M0_SOLAR',
  FRANCE_M0_WIND = 'FRANCE_M0_WIND',
  AUSTRIA_REFERENZ_MARKTWERT_SOLAR = 'AUSTRIA_REFERENZ_MARKTWERT_SOLAR',
  AUSTRIA_REFERENZ_MARKTWERT_WIND = 'AUSTRIA_REFERENZ_MARKTWERT_WIND',
  GERMANY_EPEX_INTRA_DAY_AUCTION = 'GERMANY_EPEX_INTRA_DAY_AUCTION',
  GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LOW = 'GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LOW',
  GERMANY_EPEX_INTRA_DAY_CONTINUOUS_HIGH = 'GERMANY_EPEX_INTRA_DAY_CONTINUOUS_HIGH',
  GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE = 'GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE',
  GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LAST = 'GERMANY_EPEX_INTRA_DAY_CONTINUOUS_LAST',
  GERMANY_REBAP = 'GERMANY_REBAP',
  GERMANY_ESTIMATED_REBAP = 'GERMANY_ESTIMATED_REBAP',
  GERMANY_MARKTWERT_SOLAR = 'GERMANY_MARKTWERT_SOLAR',
  GERMANY_MARKTWERT_WIND_ON_SHORE = 'GERMANY_MARKTWERT_WIND_ON_SHORE',
  GERMANY_MARKTWERT_WIND_OFF_SHORE = 'GERMANY_MARKTWERT_WIND_OFF_SHORE',
  // Germany revenue datastreams
  GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST = 'GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST',
  GERMANY_REVENUE_INTRA_DAY_BALANCING_COST = 'GERMANY_REVENUE_INTRA_DAY_BALANCING_COST',
  GERMANY_REVENUE_DAY_AHEAD_REVENUE = 'GERMANY_REVENUE_DAY_AHEAD_REVENUE',
  GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE = 'GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE',
  GERMANY_REVENUE_SPREAD = 'GERMANY_REVENUE_SPREAD',
  GERMANY_REVENUE = 'GERMANY_REVENUE',

  TURKEY_MARKET_CLEARANCE_PRICE = 'TURKEY_MARKET_CLEARANCE_PRICE',
  TURKEY_SYSTEM_MARGINAL_PRICE = 'TURKEY_SYSTEM_MARGINAL_PRICE',

  // Long range data
  SEASONAL_FORECAST_GENERATION = 'SEASONAL_FORECAST_GENERATION',
  SEASONAL_FORECAST_WIND_SPEED = 'SEASONAL_FORECAST_WIND_SPEED',
  SEASONAL_FORECAST_SOLAR_IRRADIATION = 'SEASONAL_FORECAST_SOLAR_IRRADIATION',
  CLIMATOLOGY_GENERATION = 'CLIMATOLOGY_GENERATION',
  CLIMATOLOGY_WIND_SPEED = 'CLIMATOLOGY_WIND_SPEED',
  CLIMATOLOGY_SOLAR_IRRADIATION = 'CLIMATOLOGY_SOLAR_IRRADIATION',
}
// this is used to exclude some of germany revenue from evaluation
// GERMANY_REVENUE , GERMANY_REVENUE_SPREAD is not included because we need to show both of them as column
export const germanyRevenueDatastreams = [
  TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
  TimeSeriesClassifier.GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
  TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_REVENUE,
  TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE,
]

// In some places we are having conditions to check if classifier includes epex
export const EPEX = 'EPEX'

// EQUATION TIMESERIES CONSTANTS START HERE
/** These constants are used for calculating the revenue (EQUATIONS) timeseries */
/* This is used to check if any of the Germany revenue datastream selected
   the classifier should start exactly with this search key
    Ex: TimeSeriesClassifier.GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST.includes(GERMANY_REVENUE)*/
export const GERMANY_REVENUE_SEARCH_KEY = 'GERMANY_REVENUE'
export const DAY_AHEAD_DATA_STREAM_SEARCH_KEY = '[da]' // Day ahead
export const INTRA_DAY_DATA_STREAM_SEARCH_KEY = '[id]' // Intra day forecast
export const PRICE_DATA_INTERVAL = {
  GERMANY_REBAP: 900000, // milliseconds which is 15min
  GERMANY_EPEX_DAY_AHEAD_AUCTION: 3600000, // milliseconds which is 60min
  GERMANY_EPEX_INTRA_DAY_AUCTION: 900000, // milliseconds which is 15min
}

// EQUATION TIMESERIES CONSTANTS END HERE

export enum TimeSeriesSeriesType {
  ENSEMBLE = 'ENSEMBLE', // forecasted power data
  POWER = 'POWER', // actual power data
}

export enum TimeSeriesValueType {
  ABSOLUTE_POWER = 'ABSOLUTE_POWER', // measured/predicted data
  MAX_POWER = 'MAX_POWER', // available capacity
}

export interface TimeSeriesResult {
  data?: TimeSeries
  assetId?: string
  assetIds?: string[]
  dataStreamId?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
  aggregation: ChartAggregationMode
  cacheIsStillBeingBuilt?: boolean
  id?: string
  modelId?: string | null
}

export interface DataStream {
  id?: string
  name?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
  customUICategory?: string // You can assign different type to data stream , but still have a different category from this type in UI.
}

export enum DataIndicationType {
  ALL_SITES = 'ALL_SITES',
  PARTIAL_SITES = 'PARTIAL_SITES',
  NO_SITES = 'NO_SITES',
}

export type META_FORECAST_COLOR = 'META_FORECAST_COLOR'

export interface DataStreamSelectionItem {
  id?: string
  label?: string
  name: string
  type: TimeSeriesType
  color?: number | META_FORECAST_COLOR
  subType?: TimeSeriesSubType
  timeSeriesResultType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
}

export type DataStreamSelection = DataStreamSelectionItem[]

export const DataStreamOrder: TimeSeriesType[] = [
  TimeSeriesType.SITE_FORECAST,
  TimeSeriesType.E3_META_FORECAST,
  TimeSeriesType.THIRD_PARTY,
  TimeSeriesType.SCHEDULE,
  TimeSeriesType.SEASONAL_FORECAST,
  TimeSeriesType.CLIMATOLOGY,
  TimeSeriesType.METER_DATA,
  TimeSeriesType.CAPACITY_DATA,
  TimeSeriesType.WEATHER_DATA,
  TimeSeriesType.AREA_FORECAST,
  TimeSeriesType.MARKET_PRICE_DATA,
]

export interface DataStreamItem extends DataStream {
  // added in UI for displaying hierarchy
  uiAncestors?: string[]
  uiParents?: string[]
  uiChildren?: string[]
  uiDescendants?: string[]
  uiLevel?: number
}
export interface DataStreamTypeItem {
  id: TimeSeriesType
}
export type DataStreamRowItem = DataStreamItem | DataStreamTypeItem

export const isDataStreamItem = (value: DataStreamRowItem): value is DataStreamItem => {
  return typeof value === 'object' && Boolean((value as DataStreamItem)?.type)
}

// meterdata files
export type DELIVERY_WAY = 'UI_UPLOAD'
export type FILE_STATUS =
  | 'ASSET_NOT_FOUND'
  | 'DELETED'
  | 'ENQUEUED_FOR_IMPORT'
  | 'ERROR'
  | 'IMPORTED'
  | 'IMPORTED_AND_ASSIGNED'
  | 'INVALID_FORMAT'
  | 'IN_USE'
  | 'PARSE_ERROR'
export type USED_BY = 'TRAINING' | 'REALTIME'

export interface MeterdataFile {
  customer: string
  customerUUID: string
  deliveryWay: DELIVERY_WAY
  filePath?: string
  fileSize: number
  importTs?: Date
  messages: string[]
  originalFileName: string
  sha1Checksum: string
  siteIds?: string[]
  status: FILE_STATUS
  timeSeriesInfo?: any
  timeseriesIds: string[]
  uploadId: string
  uploadTs: Date
  usedBy?: USED_BY
  uuid: string
}

// meta data
export interface ParamDto {
  name: string
  unit: string
  category: string
  paramType: string
  description: string
  displayName: string
  minValue: number
  maxValue: number
  notAggregateable: boolean
  aggregationFunction: string[]
  modelRun: any[]
  role: string[]
}

export interface MetaData {
  paramsDTO: ParamDto[]
  aggregationFunctions: string[]
  aggregationPeriods: string[]
}

export interface Structure {
  type: string
  value: string
}

export interface MetaDataHierarchyItem {
  id: string
  type: string
  unit: string
  hourhtml: string
  structure: Structure[]
  avgFormatter: string
}

export type MetaDataHierarchy = MetaDataHierarchyItem[]

// area config
export interface AreaConfig {
  id: AREA_ID
  name: string
  productCode: string
  args?: null | Record<any, any>
}

// e³ forecast config
export type E3ForecastModel =
  | 'CMC'
  | 'ECMWF'
  | 'ECMWF_VAR_EPS_MONTHLY_FORECAST'
  | 'GFS'
  | 'ICON_EU'
  | 'NAM'
  | 'UKMET_UM'
  | 'E3_META_FORECAST'
  | 'METEOBLUE'
export type E3ForecastCategory = 'forecast'
export type E3ForecastType = 'SINGLE_MODEL'
export type E3ForecastSource = 'enercast'

export interface E3ForecastConfig {
  name: E3ForecastModel
  category: E3ForecastCategory
  type: E3ForecastType
  model: E3ForecastModel
  source: E3ForecastSource
}

export type E3ForecastConfigs = Partial<Record<E3ForecastModel, E3ForecastConfig>>

export type E3Ensemble = Partial<Record<E3ForecastModel, number>>

// site forecast config
export enum SiteForecastHorizonType {
  TIME_RELATIVE = 'TIME_RELATIVE',
  DAY_RELATIVE = 'DAY_RELATIVE',
}

export enum SiteForecastHorizonTemplateName {
  INTRADAY = 'INTRADAY',
  DAYAHEAD = 'DAYAHEAD',
  CUSTOM = 'CUSTOM',
}

export interface SiteForecastHorizon {
  templateName: string
  type: SiteForecastHorizonType
  offsetDays: number
  offsetHours: number
  offsetMinutes: number
  lengthHours: number
  lengthDays?: number
  lengthMinutes?: number
  timeZone: Timezone
  modifiable?: boolean
  untilEndOfDay?: boolean
}

export interface IndividualTime {
  hours: number
  minutes: number
  name: string
}

export enum SiteForecastUpdateTimesFrequency {
  QUARTER_HOURLY = 'QUARTER_HOURLY',
  HOURLY = 'HOURLY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum SiteForecastFecsProcessing {
  DISABLE_REALTIME_PROCESSING = 'disable-realtime',
  DISABLE_POST_PROCESSING = 'disable-postprocessing',
}

export interface SiteForecastUpdateTimes {
  name?: string
  frequency: SiteForecastUpdateTimesFrequency
  individualTimes: IndividualTime[]
  redeliveryMinutes?: number | null
  offsetMinutes: number
  timeZone: Timezone
  earliestAttemptOfDelivery: number
  latestAttemptOfDelivery: number
  earliestAttemptOfDeliveryOffsetMinutes?: number | null
  latestAttemptOfDeliveryOffsetMinutes?: number | null
}

export interface DeliveryTime {
  hours: string
  minutes: string
  name?: string | null
}

export enum SitesSelectionType {
  ALL_SITES = 'ALL_SITES',
  SELECTED_SITES = 'SELECTED_SITES',
}

export enum DeliveryTimesType {
  AUTOMATIC = 'AUTOMATIC',
  CUSTOM = 'CUSTOM',
}

export enum OffsetType {
  START_OF_DAY = 'START_OF_DAY',
  START_OF_DELIVERY = 'START_OF_DELIVERY',
}

export enum EvaluationDurationType {
  AUTOMATIC = 'AUTOMATIC',
  CUSTOM = 'CUSTOM',
}

export interface QualityHorizon {
  offsetDays: number
  offsetHours: number
  offsetMinutes: number
  lengthDays?: number | null
  lengthHours?: number | null
  lengthMinutes?: number | null
}

export interface SiteForecastQualityConfig {
  uuid?: string
  autoName?: string
  name?: string
  primary: boolean
  primaryMeasurement: string
  errorMeasurements: string[]
  deliveryTimes: DeliveryTime[]
  deliveryTimesType: DeliveryTimesType
  durationType: string
  offsetType: OffsetType
  horizon: QualityHorizon
  penaltyNotificationEnabled: boolean
  penaltyNotificationThreshold?: number | null
}

export enum ExportFormat {
  NONE = 'NONE',
  CSV_COMMA_CLUSTER_LEVEL = 'CSV_COMMA_CLUSTER_LEVEL',
  CSV_SEMICOLON_CLUSTER_LEVEL = 'CSV_SEMICOLON_CLUSTER_LEVEL',
  CSV_COMMA_SITE_LEVEL = 'CSV_COMMA_SITE_LEVEL',
  CSV_SEMICOLON_SITE_LEVEL = 'CSV_SEMICOLON_SITE_LEVEL',
}

export enum IndiaExportFormat {
  CSV_INDIA_STANDARD_KW = 'CSV_INDIA_STANDARD_KW',
  CSV_INDIA_STANDARD_MW = 'CSV_INDIA_STANDARD_MW',
  CSV_INDIA_STANDARD_V2_KW = 'CSV_INDIA_STANDARD_V2_KW',
  CSV_INDIA_STANDARD_V2_MW = 'CSV_INDIA_STANDARD_V2_MW',
}

export interface SiteForecastUIData {
  forecastLength: {
    hours: number
    days: number
  }
  deliveryFrequencyCustom: {
    hours: number
    minutes: number
  }
  deliveryCustomName: string
  custom: boolean
  customUnRemovable: boolean
  hasValidCustomConfigFile: boolean
  sitesSelectionType: SitesSelectionType
  isTemplate: boolean
  timeZone: Timezone
  deliveryFrequencyChanged: boolean
  disableFecsRealTime: boolean
  disableFecsPostProcessing: boolean
}

export interface ForecastConfig {
  id: string | null
  name: string
  version?: Date
  deleted?: any
  horizon: SiteForecastHorizon
  updateTimes: SiteForecastUpdateTimes
  customer: string
  exportFormatConfiguration: ExportFormat
  exportFormatConfigId: string
  qualityConfigs: SiteForecastQualityConfig[]
  sites?: string[]
  deliveryEndpoints: any[]
  defaultUnpaps?: any
  inDryRunMode: boolean
  templateId?: any
  templateVersion?: any
  customConfig: boolean
  customConfigName?: any
  customConfigOptional?: any
  fecsForecastInstructions?: string
  notOperational?: boolean
  ui?: SiteForecastUIData
  primary?: boolean
  maximumHorizon?: boolean
  category?: string
  useFecs?: boolean
}

export interface CustomConfigurationFileResponse {
  customer: string
  errors: string[]
  exportConfigName: string
  productConfigDTO: ForecastConfig
  warnings: string[]
}

export enum QualityMeasurementTypes {
  NRMSE = 'NRMSE',
  NMAE = 'NMAE',
  ABSOLUTE_DEVIATION = 'ABSOLUTE_DEVIATION',
  NBIAS = 'NBIAS',
  RMSE = 'RMSE',
  MAE = 'MAE',
}

export interface QualityMeasurement {
  name: QualityMeasurementTypes
  label: string
}

export interface ForecastSaveMainItem {
  updateForecast: FormSaveOptions
  createForecast: FormSaveOptions
  createForecastFromTable?: FormSaveOptions
}

export interface SelectedMenuData {
  itemName: ReTableContextMenuItemName
  item: ForecastType
}
