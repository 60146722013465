import React, { FormEvent, ReactNode, useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'
import SearchInput from 'ui/form/SearchInput'
import { useDebounce } from 'use-debounce'
import { RETABLE_SEARCH, RETABLE_SET_SORT } from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch } from 'react-redux'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector, reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'

const LeftActions = styled(Flex)``

const RightActions = styled(Flex)`
  & .MuiButton-root {
    margin-right: 1em;
  }

  & .MuiButton-root:last-child {
    margin-right: 0;
  }
`

const SearchContainer = styled(Box)`
  width: 100%;
  max-width: 18em;
`

const TableButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 0.5em;
  }
`

interface ReTableToolbarProps {
  id: string
  actions?: ReactNode
  summary?: ReactNode
  filters?: ReactNode
  toolBarItemsAlignment?: string
  showClearSorting: boolean
  showClearFiltering?: boolean
  showSearchField?: boolean
  onClearFilter?: () => void
}

const ReTableToolbar: React.FC<ReTableToolbarProps> = ({
  id,
  actions,
  summary,
  filters,
  toolBarItemsAlignment = 'center',
  showClearSorting,
  showClearFiltering,
  showSearchField = true,
  onClearFilter,
}) => {
  const dispatch = useDispatch()

  const sort = useReTableSelectorWithId(reTableSortSelector, id)
  const search = useReTableSelectorWithId(reTableSearchSelector, id)
  const [query, setQuery] = useState(search)
  const [debouncedQuery] = useDebounce(query, 200, { leading: false })

  const handleClearSortingAndFiltering = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatch({
      type: RETABLE_SET_SORT,
      table: id,
      sort: { active: false },
    })
    onClearFilter()
  }, [])

  // const filters = useSelector(filtersSelector)
  // const filterActive = filters && Object.keys(filters).length > 0

  const handleSearch = useCallback((event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement // not sure why need to cast again
    const query = target.value || ''
    setQuery(query)
  }, [])

  const handleClearSearch = useCallback(() => {
    setQuery('')
  }, [])

  useEffect(() => {
    dispatch({ type: RETABLE_SEARCH, table: id, search: debouncedQuery })
  }, [debouncedQuery])

  return (
    <Box
      mb={1}
      display="flex"
      width="100%"
      flexDirection="row"
      justifyContent="space-between"
      alignItems={toolBarItemsAlignment}
    >
      <LeftActions alignItems="center" flexGrow={1}>
        {showSearchField && (
          <SearchContainer>
            <SearchInput onChange={handleSearch} onClearSearch={handleClearSearch} />
          </SearchContainer>
        )}

        {filters && <Box ml={1}>{filters}</Box>}

        {((showClearSorting && sort.active) || showClearFiltering) && (
          <TableButton onClick={handleClearSortingAndFiltering} color="primary" size="medium">
            {t`Clear`}
          </TableButton>
        )}
        {summary && <Box>{summary}</Box>}
      </LeftActions>

      {actions && <RightActions justifyContent="flex-end">{actions}</RightActions>}
    </Box>
  )
}

export default React.memo(ReTableToolbar)
