import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { GET_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'

const OldUI = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  background: white;
`

interface OldUiIframeProps {
  url: string
  unmountAction?: string
  unloadAction?: string
  unloadLocation?: string
  unloadLocationBack?: boolean
}

const OldUiIframe: React.FC<OldUiIframeProps> = ({
  url,
  unmountAction,
  unloadAction,
  unloadLocation,
  unloadLocationBack = false,
}) => {
  const dispatch = useDispatch()
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [initialized, setInitialized] = useState<boolean>(false)

  const init = useCallback(() => {
    setInitialized(true)
  }, [])

  const dispatchAction = useCallback((action) => {
    if (action === GET_USER_REQUEST) {
      console.warn('WARNING', '"GET_USER_REQUEST" sometimes causes infinite loops or refreshs, ignored')
    } else {
      dispatch({ type: action })
    }
  }, [])

  const handleReload = useCallback(() => {
    // const location = iframeRef?.current?.contentWindow?.window?.location || ({} as Location)
    // const newUrl = `${location.pathname}${location.hash}`
    // if (!initialized || newUrl === url) return
    if (!initialized) return

    if (unloadAction) {
      dispatchAction(unloadAction)
    }
    if (unloadLocation) {
      window.location.replace(`#${unloadLocation}`)
    } else if (unloadLocationBack) {
      window.history.go(-2)
    }

    // the following lines are commented out because they show a browser
    // prompt which asks the user if he really wants to leave the page
    // event.preventDefault()
    // event.returnValue = ''
    return
  }, [initialized, /*iframeRef, url,*/ unloadLocation, unloadAction])

  useEffect(() => {
    const iframe = iframeRef?.current?.contentWindow
    if (!iframe) return

    iframe.window.addEventListener('DOMContentLoaded', init)
    return () => {
      iframe.window.removeEventListener('DOMContentLoaded', init)
    }
  }, [iframeRef, init])

  useEffect(() => {
    const iframe = iframeRef?.current?.contentWindow
    // console.log('iframe ref =', debouncedIframeRef)
    if (!iframe) return

    // beforeunload triggers when page inside iframe is changing url (reload)
    // popstate triggers when page inside iframe is routing to another location (angular)
    iframe.window.addEventListener('beforeunload', handleReload)
    iframe.window.addEventListener('popstate', handleReload)
    return () => {
      iframe.window.removeEventListener('beforeunload', handleReload)
      iframe.window.removeEventListener('popstate', handleReload)
    }
  }, [iframeRef, handleReload])

  useEffect(() => {
    return () => {
      if (unmountAction) {
        dispatchAction(unmountAction)
      }
    }
  }, [])

  return <OldUI src={url} ref={iframeRef} />
}

// OldUiIframe.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(OldUiIframe)
