import { AnyAction, combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { put, race, select, take } from 'redux-saga/effects'

import { SAVE_WORKSPACE_DRAFT_REQUEST, WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import createReducer from 'utils/createReducer'
import { createSelector } from 'reselect'
import {
  SET_USERCONFIG_FAILURE,
  SET_USERCONFIG_REQUEST,
  SET_USERCONFIG_SUCCESS,
} from 'modules/auth/redux_store/auth.action.types'
import { SET_SELECTED_CONFIG } from 'modules/workspace/store/view.state'
import { selectedConfigSelector } from 'modules/workspace/store/getWorkspaceConfigs.state'
import { workspaceDraftResultSelector } from 'modules/workspace/store/getWorkspaceDraft.state'

// state

interface State {
  configs: WorkspaceConfig[]
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  configs: [],
  loading: false,
  error: null,
  initialized: false,
}

// types

export const SAVE_WORKSPACE_CONFIGS_REQUEST = 'SAVE_WORKSPACE_CONFIGS_REQUEST'
export const SAVE_WORKSPACE_CONFIGS_SUCCESS = 'SAVE_WORKSPACE_CONFIGS_SUCCESS'
export const SAVE_WORKSPACE_CONFIGS_FAILURE = 'SAVE_WORKSPACE_CONFIGS_FAILURE'
export const SAVE_WORKSPACE_CONFIGS_DISMISS = 'SAVE_WORKSPACE_CONFIGS_DISMISS'
export type SAVE_WORKSPACE_CONFIGS_REQUEST = typeof SAVE_WORKSPACE_CONFIGS_REQUEST
export type SAVE_WORKSPACE_CONFIGS_SUCCESS = typeof SAVE_WORKSPACE_CONFIGS_SUCCESS
export type SAVE_WORKSPACE_CONFIGS_FAILURE = typeof SAVE_WORKSPACE_CONFIGS_FAILURE
export type SAVE_WORKSPACE_CONFIGS_DISMISS = typeof SAVE_WORKSPACE_CONFIGS_DISMISS
export type SAVE_WORKSPACE_CONFIGS_ACTION_TYPE =
  | SAVE_WORKSPACE_CONFIGS_REQUEST
  | SAVE_WORKSPACE_CONFIGS_SUCCESS
  | SAVE_WORKSPACE_CONFIGS_FAILURE
  | SAVE_WORKSPACE_CONFIGS_DISMISS

interface ConfigAction {
  type: SAVE_WORKSPACE_CONFIGS_ACTION_TYPE
  // REQUEST
  config: WorkspaceConfig
  hideLoading?: boolean
  // ERROR
  error: State['error']
}

// reducers

const loading = createReducer<State['loading'], ConfigAction>(
  (state = initialState.loading, { type, hideLoading = false }) => {
    switch (type) {
      case SAVE_WORKSPACE_CONFIGS_REQUEST:
        return true && !hideLoading
      case SAVE_WORKSPACE_CONFIGS_SUCCESS:
      case SAVE_WORKSPACE_CONFIGS_FAILURE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'], ConfigAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case SAVE_WORKSPACE_CONFIGS_SUCCESS:
    case SAVE_WORKSPACE_CONFIGS_DISMISS:
      return null
    case SAVE_WORKSPACE_CONFIGS_FAILURE:
      return error
  }
  return state
})

const initialized = createReducer<State['initialized'], ConfigAction>((state = initialState.initialized, { type }) => {
  switch (type) {
    case SAVE_WORKSPACE_CONFIGS_SUCCESS:
    case SAVE_WORKSPACE_CONFIGS_FAILURE:
      return true
  }
  return state
})

export const saveWorkspaceConfigsReducer = combineReducers({
  loading,
  error,
  initialized,
})

// selectors

export const saveWorkspaceConfigsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.workspace.saveWorkspaceConfigs.loading,
  (loading) => loading,
)
export const saveWorkspaceConfigsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.workspace.saveWorkspaceConfigs.error,
  (error) => error,
)
export const saveWorkspaceConfigsInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.workspace.saveWorkspaceConfigs.initialized,
  (initialized) => initialized,
)

// api
// -> handled in auth / userConfig

// sagas
const collectionId = 'workspaceConfigs'

export function* saveWorkspaceConfigsSaga({ config }: ConfigAction): SagaIterator {
  // optimistic ui. in case of failure, we reset the data
  const oldSelectedConfig = yield select(selectedConfigSelector)
  const oldWorkspaceDraft = yield select(workspaceDraftResultSelector)

  // save configs
  yield put({
    type: SET_USERCONFIG_REQUEST,
    collectionId,
    configId: config.id,
    value: config,
  })

  const { success, failure } = yield race({
    success: take(
      (action: AnyAction) =>
        action.type === SET_USERCONFIG_SUCCESS && action.collectionId === collectionId && action.configId === config.id,
    ),
    failure: take(
      (action: AnyAction) =>
        action.type === SET_USERCONFIG_FAILURE && action.collectionId === collectionId && action.configId === config.id,
    ),
  })

  if (success) {
    yield put({ type: SAVE_WORKSPACE_CONFIGS_SUCCESS, config })
  } else {
    // reset the data because of optimistic ui
    yield put({ type: SET_SELECTED_CONFIG, selectedConfig: oldSelectedConfig.id })
    yield put({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft: oldWorkspaceDraft })
    yield put({ type: SAVE_WORKSPACE_CONFIGS_FAILURE, error: failure.error })
  }
}
