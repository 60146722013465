import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { convertUtcToZonedTime, formatDate } from 'utils/date'
import { t } from 'ttag'

import {
  getScheduleTemplateUploadErrorAndWarningMessage,
  ScheduleUploadNotificationStatusCodes,
  ScheduleUploadWarningStatusCodes,
} from 'utils/schedule'
import { ScheduleTemplateUploadStatus } from 'modules/workspace/schedule/schedule.types'
import { Timezone } from 'fixtures/timezones'
import UploadedScheduleTemplateTable from 'modules/workspace/schedule/uiHelperElements/UploadedScheduleTemplateTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'

interface UploadScheduleTemplateSuccessProps {
  templateUploadResponse: Record<string, string>
}

const UploadedScheduleTemplateData: React.FC<UploadScheduleTemplateSuccessProps> = ({ templateUploadResponse }) => {
  const templateDate = templateUploadResponse?.date ? templateUploadResponse?.date.replace('[UTC]', '') : undefined

  const templateHasWarnings = templateUploadResponse.status === ScheduleTemplateUploadStatus.SUCCESS_WITH_WARNINGS
  const templateAssets = templateUploadResponse?.templateAssets || []
  const errorMessage = getScheduleTemplateUploadErrorAndWarningMessage(templateUploadResponse.status)

  const getScheduleUploadStatusIcon = (status: ScheduleTemplateUploadStatus) => {
    if (ScheduleUploadWarningStatusCodes.includes(status)) {
      return <FontAwesomeIcon style={{ marginRight: '7px' }} icon="exclamation-triangle" color="#f8bd71" size="1x" />
    } else if (ScheduleUploadNotificationStatusCodes.includes(status)) {
      return <FontAwesomeIcon style={{ marginRight: '7px' }} icon="exclamation-circle" color="#0049B0" />
    }
    return (
      <FontAwesomeIcon
        style={{ marginRight: '7px' }}
        icon="exclamation-circle"
        color={theme.palette.error.main}
        size="1x"
      />
    )
  }

  return (
    <div>
      <Box mt={1}>
        <Typography variant="subtitle1">{t`Data found in the schedule template`}</Typography>
      </Box>
      {templateDate ? (
        <Box display="flex" flexDirection="row" mt={0.3} mb={0.3}>
          <Box mr={0.2}>{t`Date`}:</Box>
          {templateDate
            ? formatDate(convertUtcToZonedTime(templateDate, templateUploadResponse.timezone as Timezone))
            : ''}
          <Box ml={1} mr={0.2}>
            {t`Timezone`}:
          </Box>
          {templateUploadResponse?.timezone}
        </Box>
      ) : (
        <></>
      )}
      <>
        {templateAssets.length > 0 ? (
          <UploadedScheduleTemplateTable templateUploadResponse={templateUploadResponse} />
        ) : (
          <Box mt={1} mb={0.5}>
            {/* --------------- Display general error messages ------------------- */}
            <>{getScheduleUploadStatusIcon(templateUploadResponse?.status)}</>
            {errorMessage?.message || ''}
          </Box>
        )}
      </>

      <>
        {/* --------------- Display general warning and notification messages ------------------- */}
        {templateAssets.length > 0 && templateHasWarnings && templateUploadResponse.warnings.length > 0 && (
          <Box mt={1}>
            {(templateUploadResponse?.warnings || [])?.map((warning: ScheduleTemplateUploadStatus) => (
              <Box key={warning} mt={1} mr={0.7} ml={0.7} display="flex" flexDirection="row" alignItems="flex-start">
                <>{getScheduleUploadStatusIcon(warning)}</>
                <>{getScheduleTemplateUploadErrorAndWarningMessage(warning).message}</>
              </Box>
            ))}
          </Box>
        )}
      </>
    </div>
  )
}

export default UploadedScheduleTemplateData
