import { FormApi, FormSubscription, Mutator } from 'final-form'
import { AssetMutationType, useAllAssets, useAssetModifyMutation } from 'modules/asset/api/assets.api'
import AssetForm from 'modules/asset/assetCrud/assetDetails/AssetForm'
import { Asset } from 'modules/asset/store/asset.types'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'

import { isAsset, isParkWithNoPlants, prepareAssetDataBeforeSave } from 'utils/asset'
import { validateAssetForm } from 'utils/formValidations'

import { FormSaveOptions, getCopyName } from 'utils/form'
import { useAssetSaveMainItemKey } from 'modules/asset/api/assetsUserSettings.api'

let formReference: FormApi<Asset>

interface AssetDetailsProps {
  isNew: boolean
  id: string
  asset: Asset
}

const AssetDetails: React.FC<AssetDetailsProps> = ({ isNew, id, asset }) => {
  const saveMainItemKey = useAssetSaveMainItemKey()
  const savedMainItem = useMemo(() => {
    const mainItem = isNew ? saveMainItemKey.data?.createAsset : saveMainItemKey.data?.updateAsset
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNew, saveMainItemKey.data])

  const [enterManually, setEnterManually] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const [currentAssetIndex, setCurrentAssetIndex] = useState(0)

  const userTimezone = useSelector(getUserTimezoneSelector)
  const allAssetsQueryResult = useAllAssets()

  // setValue is for setting one value and setValues is use set multiple values at same time
  const mutators = useMemo<{ [key: string]: Mutator<Asset, Partial<Asset>> }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
      setValues: ([changes], state, { changeValue }) => {
        changes.forEach((change) => {
          changeValue(state, change.field, () => change.value)
        })
      },
    }
  }, [])

  const assetModifyResult = useAssetModifyMutation(asset?.id ? AssetMutationType.UPDATE : AssetMutationType.ADD, {
    isNew: isNew,
    currentAssetIndex: currentAssetIndex,
    formReference: formReference,
  })
  const assetMutate = assetModifyResult.mutate
  const saveSuccess = assetModifyResult.isSuccess
  const saveIsInProgress = assetModifyResult.isLoading
  const assetNames = useMemo(() => allAssetsQueryResult?.data?.map((asset) => asset.name), [allAssetsQueryResult?.data])

  const handleFormSubmit = useCallback(
    (formData: Asset) => {
      const assetData = Object.assign({}, formData)
      if (savedMainItem === FormSaveOptions.CREATE_COPY && assetData?.id) {
        assetData.name = getCopyName(asset?.name, assetNames)
        delete assetData.id
      }

      if (formReference.getState().invalid) return
      const preparedData = prepareAssetDataBeforeSave(assetData, enterManually, formReference, userTimezone)
      setFormChanged(false)
      assetMutate(preparedData)
    },
    [enterManually, currentAssetIndex, savedMainItem, assetNames],
  )

  useEffect(() => {
    if (id || isNew) {
      if (isNew) {
        setEnterManually(false)
      }
      assetModifyResult.reset() // clear errors when id changes
    }

    setFormChanged(false)
  }, [asset, isNew, id])

  useEffect(() => {
    if (!saveIsInProgress && saveSuccess) {
      setFormChanged(false)
      assetModifyResult.reset()
    }
  }, [saveIsInProgress, saveSuccess])

  useEffect(() => {
    if (asset && asset.id) {
      setCurrentAssetIndex((allAssetsQueryResult.data || []).findIndex((a) => a.id === asset.id))
    }
  }, [asset, allAssetsQueryResult.data])

  useEffect(() => {
    if (asset && asset.id && formReference) {
      setEnterManually(isParkWithNoPlants(asset))
      formReference.mutators.setValue('enterManually', isParkWithNoPlants(asset))
    }
  }, [asset])

  const formRender = useCallback(
    ({
      form,
      handleSubmit,
      /* here begin attributes that need to be subscribed to in `formSubscription()` */
      // dirty,
    }) => {
      formReference = form
      return isAsset(asset) ? (
        <AssetForm
          asset={asset}
          isNew={isNew}
          formChanged={formChanged}
          setFormChanged={setFormChanged}
          enterManually={enterManually}
          setEnterManually={setEnterManually}
          // be cautious with form.getState()
          // it is only possible to get data that we have subscribed to
          form={form}
          saveResult={assetModifyResult}
          handleSubmit={handleSubmit}
        />
      ) : (
        <></>
      )
    },
    [
      asset,
      isNew,
      assetModifyResult?.isError,
      assetModifyResult?.isLoading,
      assetModifyResult?.isSuccess,
      formChanged,
      enterManually,
    ],
  )

  const formSubscription = useMemo<FormSubscription>(
    () => ({
      // make sure to have everything listed that is needed in `formRender()`
      // it will enable much faster form rendering
      // (1) used in AssetForm, ConfigurationSolarPlant
      // (2) used in SectionRelation
      dirty: true, // (1)
      errors: true, // (2)
      submitFailed: true, // (2)
      // WARNING: do NOT add "active" or "values" here because it would rerender all the time
      // instead use <FormSpy> to subscribe to them where it is needed
    }),
    [],
  )

  return (
    <Form
      mutators={mutators}
      onSubmit={handleFormSubmit}
      initialValues={asset as Asset}
      validate={(values) =>
        validateAssetForm({
          assetData: values,
          form: formReference,
          formChanged,
          allAssets: allAssetsQueryResult?.data || [],
        })
      }
      subscription={formSubscription}
      render={formRender}
    />
  )
}

// AssetDetails.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(AssetDetails)
