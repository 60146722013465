import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DeliveryTime } from 'modules/dataStreams/dataStreams.types'
import { createTimeObj, getTimeLabel } from 'utils/siteForecastConfiguration'
import { TimeChip } from 'modules/dataStreams/siteForecast/SectionDelivery'

import { t } from 'ttag'
import { ShowMoreLessLink } from 'ui/styles/ShowLessMoreLink'

interface SiteForecastTimeChipsProps {
  times: DeliveryTime[]
  onDeleteTime?: (time: DeliveryTime) => void
  initialCount: number
}

const SiteForecastTimeChips: React.FC<SiteForecastTimeChipsProps> = ({ times, onDeleteTime, initialCount }) => {
  const [visibleChipsCount, setVisibleChipsCount] = useState(0)

  const moreCount = useMemo(() => times.length - visibleChipsCount, [times, visibleChipsCount])

  const handleShowMore = useCallback(() => {
    const newCount = visibleChipsCount < times.length ? times.length : initialCount
    setVisibleChipsCount(newCount)
  }, [times, visibleChipsCount])

  useEffect(() => {
    setVisibleChipsCount(initialCount)
  }, [initialCount])

  return (
    <>
      {times
        .filter((val, i) => i < visibleChipsCount)
        .map((time) => {
          const timeObj = createTimeObj(time.hours, time.minutes, time.name)
          const label = getTimeLabel(timeObj)
          return (
            <React.Fragment key={label}>
              {onDeleteTime ? (
                <TimeChip label={label} onDelete={() => onDeleteTime(time)} />
              ) : (
                <TimeChip label={label} />
              )}
            </React.Fragment>
          )
        })}
      {times.length >= initialCount && (
        <ShowMoreLessLink onClick={handleShowMore}>
          {times.length === visibleChipsCount ? <> {t`Show less...`}</> : <>{t`Show ${moreCount} more...`}</>}
        </ShowMoreLessLink>
      )}
    </>
  )
}

export default React.memo(SiteForecastTimeChips)
