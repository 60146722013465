import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { ActivateForecastModelAction, ActivateForecastModelState } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import createReducer from 'utils/createReducer'

const initialState: ActivateForecastModelState = {
  isActivated: false,
  loading: false,
  error: null,
}

const isActivated = createReducer<ActivateForecastModelState['isActivated'], ActivateForecastModelAction>(
  (state = initialState.isActivated, { type }) => {
    switch (type) {
      case actionTypes.ACTIVATE_FORECAST_MODEL_SUCCESS:
        return true
      case actionTypes.ACTIVATE_FORECAST_MODEL_REQUEST:
      case actionTypes.ACTIVATE_FORECAST_MODEL_FAILURE:
        return false
    }
    return state
  },
)

const loading = createReducer<ActivateForecastModelState['loading'], ActivateForecastModelAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.ACTIVATE_FORECAST_MODEL_SUCCESS:
      case actionTypes.ACTIVATE_FORECAST_MODEL_FAILURE:
        return false
      case actionTypes.ACTIVATE_FORECAST_MODEL_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<ActivateForecastModelState['error'], ActivateForecastModelAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.ACTIVATE_FORECAST_MODEL_FAILURE:
        return error
      case actionTypes.ACTIVATE_FORECAST_MODEL_REQUEST:
      case actionTypes.ACTIVATE_FORECAST_MODEL_SUCCESS:
        return null
    }
    return state
  },
)

export const activateForecastModelReducer = combineReducers({ isActivated, loading, error })

// selectors
export const activateForecastModelIsActivatedSelector = createSelector<
  any,
  ActivateForecastModelState['isActivated'],
  ActivateForecastModelState['isActivated']
>(
  (state) => state.quality.activateForecastModel.isActivated,
  (isActivated) => isActivated,
)

export const activateForecastModelLoadingSelector = createSelector<
  any,
  ActivateForecastModelState['loading'],
  ActivateForecastModelState['loading']
>(
  (state) => state.quality.activateForecastModel.loading,
  (loading) => loading,
)
export const activateForecastModelErrorSelector = createSelector<
  any,
  ActivateForecastModelState['error'],
  ActivateForecastModelState['error']
>(
  (state) => state.quality.activateForecastModel.error,
  (error) => error,
)
