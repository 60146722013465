import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { closest } from 'utils/dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  reTableCollapsedSearchSelector,
  reTableCollapsedSelector,
  reTableCollapsibleIdsSelector,
  reTableColumnsAvailableSelector,
  reTableColumnsSelectedSelector,
  reTableFilteredItemsSelector,
  reTableFiltersSelector,
  reTableInitialState,
  reTableSearchSelector,
  reTableSelectedIdsSelector,
  reTableSortDefaultSelector,
  reTableSortSelector,
  reTableVirtualRangeSelector,
} from 'modules/reTable/redux_store/state/view.state'
import { Collapsed, Columns, Filters, ReTableId, Search, Sort } from 'modules/reTable/reTable.types'
import { VirtualRange } from 'ui/scroll/VirtualScroll'
import { useUserConfigInitialized, useUserConfigResult } from 'modules/auth/redux_store/state/getUserConfig'
import { buildReTableCollectionId, RETABLE_SAVE_ACTION_USERCONFIG } from 'modules/reTable/redux_store/reTable.saga'
import { GET_USERCONFIG_REQUEST } from 'modules/auth/redux_store/auth.action.types'

export const useReTableId = (): [MutableRefObject<any>, string] => {
  const ref = useRef(null)
  const [tableId, setTableId] = useState('')

  useEffect(() => {
    let id
    // let's traverse all parents and find the closest one that has defined a reTable id
    if (ref?.current) {
      // we use custom `closest` method instead of native one
      // because IE11 does not provide that functionality
      const reTableElement = closest(ref.current, '[data-retable-id]')
      id = reTableElement?.getAttribute('data-retable-id')
      if (id) {
        setTableId(id)
      }
    }
  }, [ref?.current])

  // the ref needs to be set in any html element inside <ReTable />
  return [ref, tableId]
}

export type RETABLE_SELECTOR =
  | typeof reTableCollapsedSelector
  | typeof reTableCollapsedSearchSelector
  | typeof reTableCollapsibleIdsSelector
  | typeof reTableColumnsAvailableSelector
  | typeof reTableColumnsSelectedSelector
  | typeof reTableFilteredItemsSelector
  | typeof reTableFiltersSelector
  | typeof reTableSearchSelector
  | typeof reTableSelectedIdsSelector
  | typeof reTableSortSelector
  | typeof reTableSortDefaultSelector
  | typeof reTableVirtualRangeSelector

// overloaded function definitions for typescript
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableCollapsedSelector,
  tableId: ReTableId,
): Collapsed
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableCollapsedSearchSelector,
  tableId: ReTableId,
): Collapsed
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableCollapsibleIdsSelector,
  tableId: ReTableId,
): string[]
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableColumnsAvailableSelector,
  tableId: ReTableId,
): Columns
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableColumnsSelectedSelector,
  tableId: ReTableId,
): Columns
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableFilteredItemsSelector,
  tableId: ReTableId,
): string[]
export function useReTableSelectorWithId(reTableSelector: typeof reTableFiltersSelector, tableId: ReTableId): Filters
export function useReTableSelectorWithId(reTableSelector: typeof reTableSearchSelector, tableId: ReTableId): Search
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableSelectedIdsSelector,
  tableId: ReTableId,
): string[]
export function useReTableSelectorWithId(reTableSelector: typeof reTableSortSelector, tableId: ReTableId): Sort
export function useReTableSelectorWithId(reTableSelector: typeof reTableSortDefaultSelector, tableId: ReTableId): Sort
export function useReTableSelectorWithId(
  reTableSelector: typeof reTableVirtualRangeSelector,
  tableId: ReTableId,
): VirtualRange
// actual hook for getting selector of specific table
export function useReTableSelectorWithId(
  reTableSelector: RETABLE_SELECTOR,
  tableId: ReTableId,
): ReturnType<RETABLE_SELECTOR> {
  const dataSet = useSelector(reTableSelector)

  let type
  switch (reTableSelector) {
    case reTableCollapsedSelector:
      type = 'collapsed'
      break
    case reTableCollapsedSearchSelector:
      type = 'collapsedSearch'
      break
    case reTableCollapsibleIdsSelector:
      type = 'collapsibleIds'
      break
    case reTableColumnsAvailableSelector:
      type = 'columnsAvailable'
      break
    case reTableColumnsSelectedSelector:
      type = 'columnsSelected'
      break
    case reTableFilteredItemsSelector:
      type = 'filteredItems'
      break
    case reTableFiltersSelector:
      type = 'filters'
      break
    case reTableSearchSelector:
      type = 'search'
      break
    case reTableSelectedIdsSelector:
      type = 'selectedIds'
      break
    case reTableSortSelector:
      type = 'sort'
      break
    case reTableSortDefaultSelector:
      type = 'sortDefault'
      break
    case reTableVirtualRangeSelector:
      type = 'virtualRange'
      break
  }

  return dataSet[tableId] || (type ? reTableInitialState[type] : null)
}

export const useReTableSelector = (
  reTableSelector: RETABLE_SELECTOR,
): [MutableRefObject<any>, ReturnType<RETABLE_SELECTOR>] => {
  const [ref, tableId] = useReTableId()
  const data = useReTableSelectorWithId(reTableSelector, tableId)
  return [ref, data]
}

export const useSavedColumnNamesResult = (tableId: ReTableId) => {
  return useUserConfigResult(buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.SELECTED_COLUMNS), tableId) || null
}

export const useSavedColumnNamesInitialized = (tableId: ReTableId) => {
  return (
    useUserConfigInitialized(buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.SELECTED_COLUMNS), tableId) ||
    false
  )
}

// export const useSavedColumns = (tableId: ReTableId) => {
//   const savedColumnNames = useSavedColumnNames(tableId)
//   const columnsAvailable = useReTableSelectorWithId(reTableColumnsAvailableSelector, tableId)
//
//   return columnsAvailable.filter((column) => savedColumnNames.includes(column.name))
// }

export const useSavedCollapsedResult = (tableId: ReTableId) => {
  return useUserConfigResult(buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.COLLAPSED_IDS), tableId) || null
}

export const useSavedCollapsedInitialized = (tableId: ReTableId) => {
  return (
    useUserConfigInitialized(buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.COLLAPSED_IDS), tableId) || false
  )
}

export const useReTableFetchSavedData = () => {
  const dispatch = useDispatch()

  const dispatchReTableFetchSavedData = () => {
    dispatch({
      type: GET_USERCONFIG_REQUEST,
      collectionId: buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.SELECTED_COLUMNS),
    })
    dispatch({
      type: GET_USERCONFIG_REQUEST,
      collectionId: buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.COLLAPSED_IDS),
    })
    // dispatch({
    //   type: GET_USERCONFIG_REQUEST,
    //   collectionId: buildReTableCollectionId(RETABLE_SAVE_ACTION_USERCONFIG.VIRTUAL_RANGE),
    // })
  }

  return { dispatchReTableFetchSavedData }
}
