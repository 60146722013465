import React, { useMemo } from 'react'

import { isLocationAsset, useUniqueAllAssets } from 'utils/asset'
import { LocationAsset } from 'modules/asset/store/asset.types'
import AssetGoogleMaps from 'modules/maps/AssetGoogleMaps'

// exported component
const AssetMap: React.FC = () => {
  const mapConfig = useMemo(() => {
    return {
      options: {
        mapTypeControl: true,
        gestureHandling: 'greedy',
        scaleControl: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_LEFT,
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
        },
        tilt: 0,
      },
      zoom: 6,
      labelColor: 'black',
    }
  }, [])

  const allAssets = useUniqueAllAssets()
  const locationAssets = allAssets.filter((asset) => isLocationAsset(asset)) as LocationAsset[]

  return (
    <>
      <AssetGoogleMaps
        options={mapConfig.options as google.maps.MapOptions}
        labelColor={mapConfig.labelColor}
        assets={locationAssets}
      />
    </>
  )
}

// Workbench.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(AssetMap)
