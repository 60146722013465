import { GetTrainingJobsAction, TrainingJob } from 'modules/quality/quality.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  result: TrainingJob[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
}

const result = createReducer<State['result'], GetTrainingJobsAction>(
  (state = initialState.result, { type, trainingJobs }) => {
    if (type === actionTypes.GET_TRAINING_JOBS_SUCCESS) {
      return trainingJobs
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetTrainingJobsAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.GET_TRAINING_JOBS_SUCCESS:
    case actionTypes.GET_TRAINING_JOBS_FAILURE:
      return false
    case actionTypes.GET_TRAINING_JOBS_REQUEST:
      return true
  }
  return state
})

const error = createReducer<State['error'], GetTrainingJobsAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.GET_TRAINING_JOBS_FAILURE:
      return error
    case actionTypes.GET_TRAINING_JOBS_REQUEST:
    case actionTypes.GET_TRAINING_JOBS_SUCCESS:
      return null
  }
  return state
})

export const getTrainingJobsReducer = combineReducers({ result, loading, error })

// selectors

export const getTrainingJobsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.quality.getTrainingJobs.result,
  (result) => result,
)
export const getTrainingJobsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.quality.getTrainingJobs.loading,
  (loading) => loading,
)
export const getTrainingJobsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.quality.getTrainingJobs.error,
  (error) => error,
)
