import React from 'react'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { t } from 'ttag'

import { Sort } from 'modules/reTable/reTable.types'
import iconListNestedDisabled from 'media/icon-list-nested-primary.svg'
import iconListNestedEnabled from 'media/icon-list-nested-white.svg'

const TreeToggleButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 24px;
    padding: 1px 1px;
  }
`

const ToggleButton = styled(TreeToggleButton)`
  &.MuiButton-root {
    margin: 0;
  }
`

const IconImage = styled.img`
  width: 15px;
`

interface ReTableHeaderTreeToggleProps {
  sort: Sort
  onToggle: () => void
}

const ReTableHeaderTreeToggle: React.FC<ReTableHeaderTreeToggleProps> = ({ sort, onToggle }) => {
  return (
    <div>
      <ToggleButton
        size="small"
        color="primary"
        variant={sort.active ? 'outlined' : 'contained'}
        title={sort.active ? t`Enable tree view` : t`Disable tree view`}
        onClick={onToggle}
      >
        <IconImage src={sort.active ? iconListNestedDisabled : iconListNestedEnabled} />
      </ToggleButton>
    </div>
  )
}

export default React.memo(ReTableHeaderTreeToggle)
