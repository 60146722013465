export function getBbox(state) {
  return state.gips.bbox
}

export function getCenter(state) {
  return state.gips.center
}

export function getRadius(state) {
  return state.gips.radius
}
