import React from 'react'
import {
  BulkMDCMeterDataRaw,
  CleansingStatusEnum,
} from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.types'
import ConvertSvgToImage from 'modules/meterDataCleansing/ConvertSvgToImage'
import CenteredLoading from 'ui/CenteredLoading'
import { Typography } from '@material-ui/core'
import { t } from 'ttag'
import styled from 'styled-components'
import { isValidAssetForMDC } from 'utils/meterDataCleansing'
import { Asset } from 'modules/asset/store/asset.types'

const StatusDiv = styled.div`
  text-align: center;
  width: 43vw;
`

interface BulkMDCBeforeAfterFilteringChartProps {
  asset: Asset | undefined
  meterDataRaw: BulkMDCMeterDataRaw
  cleansingStatus: CleansingStatusEnum
  failureReason?: string
}

const BulkMDCBeforeAfterFilteringChart: React.FC<BulkMDCBeforeAfterFilteringChartProps> = ({
  asset,
  meterDataRaw,
  cleansingStatus,
  failureReason,
}) => {
  const handleLoadChartContent = () => {
    if (meterDataRaw?.image && cleansingStatus === CleansingStatusEnum.Done) {
      return <ConvertSvgToImage imageUrl={meterDataRaw.image} />
    } else if (cleansingStatus === CleansingStatusEnum.Pending) {
      return (
        <StatusDiv>
          <CenteredLoading fullHeight size="3em" />
        </StatusDiv>
      )
    } else {
      return (
        <StatusDiv>
          <Typography variant="subtitle1" color="error" style={{ margin: '0 2em' }}>
            {failureReason ? failureReason : t`Not available for selected asset.`}
          </Typography>
        </StatusDiv>
      )
    }
  }

  return (
    <>
      {asset && isValidAssetForMDC(asset) ? (
        <>{handleLoadChartContent()}</>
      ) : (
        <StatusDiv>
          <Typography variant="subtitle1" color="error" style={{ margin: '0 2em' }}>
            {t`Not available for selected asset. At this time, interactive meter data cleaning is supported only for
        wind and solar.`}
          </Typography>
        </StatusDiv>
      )}
    </>
  )
}

export default BulkMDCBeforeAfterFilteringChart
