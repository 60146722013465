import { SagaIterator } from 'redux-saga'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import {
  ActivateForecastModelAction,
  ForecastModel,
  GetForecastModelsAction,
  GetQualityOverviewAction,
  GetTrainingInfoAction,
  HideForecastModelAction,
  QualityEvaluation,
  ReevaluateForecastModelAction,
  SaveForecastModelAction,
  SaveForecastModelNameAction,
  TrainingInfoPerAsset,
  TrainingJob,
  TriggerWeightOptimizationAction,
  WeightOptimizationJobMap,
} from 'modules/quality/quality.types'
import {
  activateForecastModel,
  getForecastModels,
  getQualityEvaluation,
  getQualityOverview,
  getTrainingInfo,
  getTrainingJobs,
  hideForecastModel,
  QUERY_KEY_FORECAST_MODELS_BY_ASSETS,
  reevaluateForecastModel,
  saveForecastModel,
  saveForecastModelName,
  triggerWeightOptimization,
} from 'modules/quality/quality.api'
import { Result } from 'utils/request'
import { isAfter } from 'date-fns'
import { queryClient } from 'queryClient'

export function* getForecastModelsSaga({ asset }: GetForecastModelsAction): SagaIterator {
  const result: Result<ForecastModel[]> = yield call(getForecastModels, asset.id)
  if (result.isSuccessful) {
    let forecastModels = result.getData()

    // convert serialized data
    forecastModels = forecastModels.map((forecastModel) => {
      forecastModel.parameter.forcePhysical = ((forecastModel.parameter.forcePhysical as unknown) as string) === 'true'
      if (forecastModel.parameter.anns) {
        delete forecastModel.parameter.anns
      }
      if (forecastModel.parameter.capacitiesSources) {
        forecastModel.parameter.capacitiesSources = JSON.parse(
          (forecastModel.parameter.capacitiesSources as unknown) as string,
        )
      }
      if (forecastModel.parameter?.algorithmChain) {
        forecastModel.parameter.algorithmChain = JSON.parse(
          (forecastModel.parameter.algorithmChain as unknown) as string,
        )
      }
      if (forecastModel.parameter.weatherModelConfiguration) {
        forecastModel.parameter.weatherModelConfiguration = JSON.parse(
          (forecastModel.parameter.weatherModelConfiguration as unknown) as string,
        )
      }
      if (forecastModel.parameter?.weatherPoints) {
        forecastModel.parameter['weatherPoints'] = JSON.parse(
          (forecastModel.parameter.weatherPoints as unknown) as string,
        )
      }
      if (forecastModel.parameter.windCorrections) {
        forecastModel.parameter.windCorrections = JSON.parse(
          (forecastModel.parameter.windCorrections as unknown) as string,
        )
      }
      const sortedParameter = Object.keys(forecastModel.parameter)
        .sort()
        .reduce((obj, key) => {
          obj[key] = forecastModel.parameter[key]
          return obj
        }, {})

      return { ...forecastModel, parameter: sortedParameter }
    })

    forecastModels.sort((a, b) => {
      return isAfter(new Date(a.creationDate), new Date(b.creationDate)) ? -1 : 1
    })
    queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
    yield put({ type: actionTypes.GET_FORECAST_MODELS_SUCCESS, forecastModels, asset })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_FORECAST_MODELS_FAILURE, error, asset })
  }
}

export function* triggerWeightOptimizationSaga({ assets }: TriggerWeightOptimizationAction): SagaIterator {
  const assetIds = (assets || []).map((asset) => asset.id)
  const result: Result<WeightOptimizationJobMap> = yield call(triggerWeightOptimization, assetIds)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_FAILURE, error })
  }
}

export function* saveForecastModelSaga({ assets, data, activate }: SaveForecastModelAction): SagaIterator {
  const result: Result<ForecastModel[]> = yield call(saveForecastModel, data, activate)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.SAVE_FORECAST_MODEL_SUCCESS })

    // we are adding this for loop , because type: actionTypes.GET_FORECAST_MODELS_REQUEST , does not support multiple assets
    for (let i = 0; i < assets.length; i++) {
      yield put({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset: assets[i] })
    }

    yield put({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: assets })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.SAVE_FORECAST_MODEL_FAILURE, error })
  }
}

export function* hideForecastModelSaga({ asset, forecastModel, hide }: HideForecastModelAction): SagaIterator {
  const result: Result<any> = yield call(hideForecastModel, forecastModel.uuid, hide)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.HIDE_FORECAST_MODEL_SUCCESS })
    queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
    yield put({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
    yield put({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: [asset] })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.HIDE_FORECAST_MODEL_FAILURE, error })
  }
}

export function* saveForecastModelNameSaga({
  asset,
  forecastModel,
  inputValue,
}: SaveForecastModelNameAction): SagaIterator {
  const result: Result<any> = yield call(saveForecastModelName, forecastModel.uuid, inputValue)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.SAVE_FORECAST_MODEL_NAME_SUCCESS })
    queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
    yield put({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
    yield put({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: [asset] })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.SAVE_FORECAST_MODEL_NAME_FAILURE, error })
  }
}

export function* reevaluateForecastModelSaga({ asset, forecastModel }: ReevaluateForecastModelAction): SagaIterator {
  const result: Result<ForecastModel[]> = yield call(reevaluateForecastModel, asset.id, forecastModel.trainingId)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.REEVALUATE_FORECAST_MODEL_SUCCESS, forecastModel: result.getData() })
    yield put({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
    yield put({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: [asset] })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.REEVALUATE_FORECAST_MODEL_FAILURE, error })
  }
}

export function* activateForecastModelSaga({ asset, forecastModel }: ActivateForecastModelAction): SagaIterator {
  const result: Result<ForecastModel[]> = yield call(activateForecastModel, forecastModel.uuid)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.ACTIVATE_FORECAST_MODEL_SUCCESS, forecastModel: result.getData() })
    yield put({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.ACTIVATE_FORECAST_MODEL_FAILURE, error })
  }
}

export function* getTrainingJobsSaga(): SagaIterator {
  const result: Result<TrainingJob[]> = yield call(getTrainingJobs)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.GET_TRAINING_JOBS_SUCCESS, trainingJobs: result.getData() })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_TRAINING_JOBS_FAILURE, error })
  }
}

export function* getTrainingInfoSaga({ assets }: GetTrainingInfoAction): SagaIterator {
  const assetIds = (assets || []).map((asset) => asset.id)
  const result: Result<TrainingInfoPerAsset> = yield call(getTrainingInfo, assetIds)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.GET_TRAINING_INFO_SUCCESS, trainingInfo: result.getData() })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_TRAINING_INFO_FAILURE, error })
  }
}

export function* getQualityEvaluationsSaga({ assets }: GetTrainingInfoAction): SagaIterator {
  const assetIds = (assets || []).map((asset) => asset.id)
  const result: Result<QualityEvaluation[]> = yield call(getQualityEvaluation, assetIds)
  if (result.isSuccessful) {
    const qualityEvaluations = result.getData()
    qualityEvaluations.sort((a, b) => {
      return isAfter(new Date(a.created), new Date(b.created)) ? -1 : 1
    })
    yield put({ type: actionTypes.GET_QUALITY_EVALUATION_SUCCESS, qualityEvaluations })
    yield put({ type: actionTypes.GET_TRAINING_INFO_REQUEST, assets })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_QUALITY_EVALUATION_FAILURE, error })
  }
}

export function* getQualityOverviewSaga({ assets, forecastConfigs }: GetQualityOverviewAction): SagaIterator {
  const assetIds = (assets || []).map((asset) => asset.id)
  const forecastConfigIds = (forecastConfigs || []).map((forecastConfig) => forecastConfig.id)
  const result: Result<QualityEvaluation[]> = yield call(getQualityOverview, assetIds, forecastConfigIds)
  if (result.isSuccessful) {
    const qualityOverview = result.getData()
    yield put({ type: actionTypes.GET_QUALITY_OVERVIEW_SUCCESS, qualityOverview })
    yield put({ type: actionTypes.GET_TRAINING_INFO_REQUEST, assets })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_QUALITY_OVERVIEW_FAILURE, error })
  }
}

export function* qualitySaga() {
  yield takeEvery(actionTypes.GET_FORECAST_MODELS_REQUEST, getForecastModelsSaga)
  yield takeEvery(actionTypes.TRIGGER_WEIGHT_OPTIMIZATION_REQUEST, triggerWeightOptimizationSaga)
  yield takeEvery(actionTypes.SAVE_FORECAST_MODEL_REQUEST, saveForecastModelSaga)
  yield takeEvery(actionTypes.HIDE_FORECAST_MODEL_REQUEST, hideForecastModelSaga)
  yield takeEvery(actionTypes.REEVALUATE_FORECAST_MODEL_REQUEST, reevaluateForecastModelSaga)
  yield takeEvery(actionTypes.ACTIVATE_FORECAST_MODEL_REQUEST, activateForecastModelSaga)
  yield takeLatest(actionTypes.GET_TRAINING_JOBS_REQUEST, getTrainingJobsSaga)
  yield takeLatest(actionTypes.GET_TRAINING_INFO_REQUEST, getTrainingInfoSaga)
  yield takeLatest(actionTypes.GET_QUALITY_EVALUATION_REQUEST, getQualityEvaluationsSaga)
  yield takeLatest(actionTypes.GET_QUALITY_OVERVIEW_REQUEST, getQualityOverviewSaga)
  yield takeEvery(actionTypes.SAVE_FORECAST_MODEL_NAME_REQUEST, saveForecastModelNameSaga)
}
