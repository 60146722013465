import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { ReevaluateForecastModelAction, ReevaluateForecastModelState } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import createReducer from 'utils/createReducer'

const initialState: ReevaluateForecastModelState = {
  isReEvaluated: false,
  loading: false,
  error: null,
}

const isReEvaluated = createReducer<ReevaluateForecastModelState['isReEvaluated'], ReevaluateForecastModelAction>(
  (state = initialState.isReEvaluated, { type }) => {
    switch (type) {
      case actionTypes.REEVALUATE_FORECAST_MODEL_SUCCESS:
        return true
      case actionTypes.REEVALUATE_FORECAST_MODEL_REQUEST:
      case actionTypes.REEVALUATE_FORECAST_MODEL_FAILURE:
        return false
    }
    return state
  },
)

const loading = createReducer<ReevaluateForecastModelState['loading'], ReevaluateForecastModelAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.REEVALUATE_FORECAST_MODEL_SUCCESS:
      case actionTypes.REEVALUATE_FORECAST_MODEL_FAILURE:
        return false
      case actionTypes.REEVALUATE_FORECAST_MODEL_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<ReevaluateForecastModelState['error'], ReevaluateForecastModelAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.REEVALUATE_FORECAST_MODEL_FAILURE:
        return error
      case actionTypes.REEVALUATE_FORECAST_MODEL_REQUEST:
      case actionTypes.REEVALUATE_FORECAST_MODEL_SUCCESS:
        return null
    }
    return state
  },
)

export const reevaluateForecastModelReducer = combineReducers({ isReEvaluated, loading, error })

// selectors

export const reevaluateForecastModelIsReEvaluatedSelector = createSelector<
  any,
  ReevaluateForecastModelState['isReEvaluated'],
  ReevaluateForecastModelState['isReEvaluated']
>(
  (state) => state.quality.reevaluateForecastModel.isReEvaluated,
  (loading) => loading,
)

export const reevaluateForecastModelLoadingSelector = createSelector<
  any,
  ReevaluateForecastModelState['loading'],
  ReevaluateForecastModelState['loading']
>(
  (state) => state.quality.reevaluateForecastModel.loading,
  (loading) => loading,
)
export const reevaluateForecastModelErrorSelector = createSelector<
  any,
  ReevaluateForecastModelState['error'],
  ReevaluateForecastModelState['error']
>(
  (state) => state.quality.reevaluateForecastModel.error,
  (error) => error,
)
