import React, { useMemo } from 'react'
import {
  areDatesEqualIgnoringMilliseconds,
  convertZonedTimeToAnotherZonedTime,
  getInclusiveDateRangeFromChartDataRange,
} from 'utils/date'
import { ChartDataRangeType } from 'modules/workspace/store/workspace.types'
import { isAfter, isBefore } from 'date-fns'
import { useWorkspaceChartWholeDateRange } from 'utils/workspace'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { CreateScheduleInputData } from 'modules/workspace/schedule/schedule.types'
import { t } from 'ttag'
import styled from 'styled-components'

interface ShowInChartButtonProps {
  enable_btn: number
}
export const ShowInChartButton = styled.div<ShowInChartButtonProps>`
  font-size: 12px;
  padding: 5px 3px;
  background: rgba(224, 224, 224);
  cursor: ${(props) => (props.enable_btn ? 'pointer' : 'default')};
  color: ${(props) => (props.enable_btn ? 'inherit' : 'rgba(0, 0, 0, 0.30);')};
  border-radius: 1px;
`

interface ScheduleShowInChartButtonProps {
  formValues: CreateScheduleInputData
  onCheckAndChangeMainChartDateRange: (currentValues: CreateScheduleInputData) => void
}

const ScheduleShowInChartButton: React.FC<ScheduleShowInChartButtonProps> = ({
  onCheckAndChangeMainChartDateRange,
  formValues,
}) => {
  const currentStartDate = formValues?.start?.date
  const currentEndDate = formValues?.end?.date

  const chartWholeRange = useWorkspaceChartWholeDateRange()
  const timezone = useSelector(getUserTimezoneSelector)

  const enableShowChartButton = useMemo(() => {
    const chartDateRange = getInclusiveDateRangeFromChartDataRange(
      { rangeType: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM, customRange: chartWholeRange },
      timezone,
    )

    const mainChartStart = convertZonedTimeToAnotherZonedTime(chartDateRange[0], 'UTC', timezone)
    const mainChartEnd = convertZonedTimeToAnotherZonedTime(chartDateRange[1], 'UTC', timezone)
    mainChartStart.setSeconds(0)
    mainChartEnd.setSeconds(0)

    const newCurrentStartDate = new Date(currentStartDate)
    const newCurrentEndDate = new Date(currentEndDate)
    newCurrentStartDate.setSeconds(0)
    newCurrentEndDate.setSeconds(0)

    const isPeriodDifferent =
      !areDatesEqualIgnoringMilliseconds(newCurrentStartDate, mainChartStart) ||
      !areDatesEqualIgnoringMilliseconds(newCurrentEndDate, mainChartEnd)

    const isStartSubset =
      areDatesEqualIgnoringMilliseconds(new Date(newCurrentStartDate), new Date(mainChartStart)) ||
      isAfter(new Date(newCurrentStartDate), new Date(mainChartStart))
    const isEndSubset =
      areDatesEqualIgnoringMilliseconds(new Date(newCurrentEndDate), new Date(mainChartEnd)) ||
      isBefore(new Date(newCurrentEndDate), new Date(mainChartEnd))

    const isPeriodSubSet = isStartSubset && isEndSubset

    return isPeriodDifferent && !isPeriodSubSet
  }, [currentStartDate, currentEndDate, timezone, chartWholeRange])

  const handleShowInChart = () => {
    if (enableShowChartButton) {
      onCheckAndChangeMainChartDateRange(formValues)
    }
  }

  return (
    <ShowInChartButton
      enable_btn={enableShowChartButton ? 1 : 0}
      onClick={handleShowInChart}
    >{t`Show in chart`}</ShowInChartButton>
  )
}

export default ScheduleShowInChartButton
