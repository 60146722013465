import { createStory } from 'test/utils'

// stories
export const storyCategory = 'Ui/datetime/TimezoneSelector'
export const storyUTC = createStory(storyCategory, 'UTC')
export const storyEuropeBerlin = createStory(storyCategory, 'Europe/Berlin')

// tests
const testIdPrefix = 'timezone-selector'
export const testIdTimezoneSelector = `${testIdPrefix}-root`
export const testIdTimezoneEntry = `${testIdPrefix}-entry`
