import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'

import { c, msgid } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'

import { FileData, FileError } from 'utils/file'

const PreviewContainer = styled.div`
  overflow-y: auto;
  margin-bottom: 1em;
`

export const StaticList = styled(List)`
  background: white;
`

export const FileErrorTextContainer = styled.div`
  width: 30em;
`

export const StyledFontAwesomeActionIcon = styled(FontAwesomeActionIcon)`
  margin-right: 0.5em;
`

export const InlineTypography = styled(Typography)`
  margin-left: 0.5em;
`

export interface FileRowProps {
  index: number
  fileData: FileData | FileError
  onRemove?: (fileData: FileData, index: number) => void
}

const FileRow: React.FC<FileRowProps> = ({ index, fileData, onRemove }) => {
  const hasError = Boolean(fileData.errors && Object.keys(fileData.errors).length)
  return (
    <PopperTooltip
      position={PopperTooltipPosition.TOP}
      popperLabel={
        <ListItem>
          <ListItemText>
            <Typography>
              {fileData.file.name}
              {hasError && (
                <InlineTypography color="error" component="span">
                  {' '}
                  -{' '}
                  {Object.keys(fileData.errors)
                    .map((errorCode) => fileData.errors[errorCode].errorMessage)
                    .join(' ')}
                </InlineTypography>
              )}
            </Typography>
          </ListItemText>

          <ListItemSecondaryAction>
            <ListItemIcon>
              {!hasError && onRemove && (
                <IconButton onClick={() => onRemove && onRemove(fileData as FileData, index)}>
                  <FontAwesomeActionIcon color={theme.palette.action.active} size="sm" icon="times" />
                </IconButton>
              )}
            </ListItemIcon>
          </ListItemSecondaryAction>
        </ListItem>
      }
      popperContent={
        hasError
          ? Object.keys(fileData.errors || {}).map((errorCode) => {
              const error = fileData.errors[errorCode]
              return (
                <div key={errorCode}>
                  <Typography color={'error'} variant="subtitle1">
                    {error.errorMessage}
                  </Typography>
                  <FileErrorTextContainer>
                    <Typography color="inherit">{error.helpMessage}</Typography>
                  </FileErrorTextContainer>
                </div>
              )
            })
          : null
      }
    />
  )
}

export interface FileUploadPreviewProps {
  fileDatas: FileData[]
  fileErrors: FileError[]
  onRemove?: (fileData: FileData, index: number) => void
  showHeading?: boolean
}

const FileUploadPreview: React.FC<FileUploadPreviewProps> = ({
  fileDatas,
  fileErrors,
  onRemove,
  showHeading = true,
}) => {
  return (
    <PreviewContainer>
      <StaticList dense>
        {fileErrors.length > 0 && (
          <Box mt={2} ml={2}>
            <Typography variant="subtitle1">
              <StyledFontAwesomeActionIcon
                color={theme.palette.error.main}
                icon="exclamation-circle"
                size="sm"
                fixedWidth
              />
              {c('File Manager:Upload').ngettext(
                msgid`The following file cannot be uploaded, please check:`,
                `The following files cannot be uploaded, please check:`,
                fileErrors.length,
              )}
            </Typography>
          </Box>
        )}
        {fileErrors.map((fileError, index) => (
          <FileRow key={index} index={index} fileData={fileError} />
        ))}

        {showHeading ? (
          <Box mt={2} ml={2}>
            <Typography variant="subtitle1">
              <StyledFontAwesomeActionIcon color={theme.palette.success.main} icon="upload" size="sm" fixedWidth />
              {c('File Manager:Upload').ngettext(msgid`File to upload:`, `Files to upload:`, fileDatas.length)}
            </Typography>
          </Box>
        ) : (
          <></>
        )}

        {fileDatas.length ? (
          fileDatas.map((fileData, index) => (
            <FileRow key={index} index={index} fileData={fileData} onRemove={onRemove} />
          ))
        ) : (
          <>
            {showHeading && (
              <Box mt={1} ml={2}>
                <em>{c('File Manager:Upload').t`None`}</em>
              </Box>
            )}
          </>
        )}
      </StaticList>
    </PreviewContainer>
  )
}

export default React.memo(FileUploadPreview)
