import React, { useEffect, useMemo } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { c } from 'ttag'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { Field, useField } from 'react-final-form'
import { Checkbox } from 'final-form-material-ui'
import { CheckboxLabel } from 'ui/form/authForm.style'
import { Box, Typography } from '@material-ui/core'

import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import {
  ForecastConfig,
  ForecastConfigurationCategory,
  SiteForecastHorizonType,
} from 'modules/dataStreams/dataStreams.types'
import { FormApi } from 'final-form'
import ForecastAndEvaluationStart from 'modules/dataStreams/siteForecast/ForecastAndEvaluationStart'
import DurationWithArrows from 'ui/datetime/DurationWithArrows'
import FieldError from 'ui/form/FieldError'
import { QUERY_NEW_SCHEDULE, useQueryParams } from 'utils/query-string'

const CheckBoxContainer = styled(Flex)`
  margin-left: 5px;
`

const CheckboxControl = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    padding-top: 6px;
  }
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }
`

interface SectionForecast {
  form: FormApi<ForecastConfig>
}

const SectionForecast: React.FC<SectionForecast> = ({ form }) => {
  const { queryParams } = useQueryParams()
  const forecastDetails = form.getState().values
  const forecastLengthDays = useField<number>('ui.forecastLength.days').input.value
  const forecastLengthHours = useField<number>('ui.forecastLength.hours').input.value
  const currentLengthHours = useField<number>('horizon.lengthHours').input.value
  const customConfig = useField<boolean>('customConfig').input.value
  const horizonType = useField<SiteForecastHorizonType>('horizon.type').input.value

  const { DAY_RELATIVE, TIME_RELATIVE } = SiteForecastHorizonType

  const isNewSchedule = useMemo(() => Boolean(queryParams[QUERY_NEW_SCHEDULE]), [queryParams])

  useEffect(() => {
    const newLengthHours = (forecastLengthDays || 0) * 24 + (forecastLengthHours || 0)
    if (newLengthHours !== currentLengthHours && !customConfig) {
      // we need to transform day and hours back to lengthHours for the API
      // This value is set to 24 by default if it is a custom config so no need to calculate
      form.mutators.setValue('horizon.lengthHours', newLengthHours)
    }
  }, [forecastLengthDays, forecastLengthHours, currentLengthHours])

  const forecastRangeError = form.getState().errors?.ui?.forecastRange || ''

  const getSectionTitle = () => {
    if (forecastDetails.category === ForecastConfigurationCategory.SITE_SCHEDULE || isNewSchedule) {
      return c('Site Forecast:Config').t`Schedule range`
    }

    return c('Site Forecast:Config').t`Forecast range`
  }

  return (
    <>
      <Flex direction="column" flexWrap="nowrap" alignItems="space-between" justifyContent="space-between">
        <Flex style={{ marginTop: '7px' }}>
          <Typography variant="subtitle2">{getSectionTitle()}</Typography>
          {forecastRangeError && <FieldError error={forecastRangeError} />}
        </Flex>

        <Flex direction="column">
          <FormKeyValueGrid>
            <FormKey>{c('Site Forecast:Config').t`Start:`}</FormKey>
            <FormValue>
              <ForecastAndEvaluationStart
                daysInputName={'horizon.offsetDays'}
                hoursInputName={'horizon.offsetHours'}
                minutesInputName={'horizon.offsetMinutes'}
                radioButtonName={'horizon.type'}
                radioButtonValue={horizonType}
                firstRadioButtonOptionValue={TIME_RELATIVE}
                secondRadioButtonOptionValue={DAY_RELATIVE}
                maxDays={3}
              />
            </FormValue>
          </FormKeyValueGrid>
          <FormKeyValueGrid>
            <FormKey>{c('Site Forecast:Config').t`Length:`}</FormKey>
            <FormValue>
              <Flex direction="row" alignItems="center">
                <Flex>
                  <DurationWithArrows
                    daysInputName={'ui.forecastLength.days'}
                    repeatDays={false}
                    minDays={0}
                    hoursInputName={'ui.forecastLength.hours'}
                  />
                </Flex>
                <Box ml={0.5} mb={0.5}>
                  <CheckBoxContainer direction="row" alignItems="center" justifyContent="flex-start">
                    <CheckboxControl
                      label={''}
                      control={<Field name="horizon.untilEndOfDay" type="checkbox" component={Checkbox} />}
                    />
                    <CheckboxLabel>{c('Site Forecast:Config')
                      .t`Extend forecast until end of the last forecast day`}</CheckboxLabel>
                  </CheckBoxContainer>
                </Box>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>
        </Flex>
      </Flex>
    </>
  )
}

export default React.memo(SectionForecast)
