import { SeriesAreaOptions, SeriesOptions } from 'highcharts/highstock'
import { c } from 'ttag'
import { isNumeric } from 'utils/dataFormatting'
import { getHours, getMinutes } from 'date-fns'
import { Timezone } from 'fixtures/timezones'
import { TimeSeries, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

interface AverageData {
  timeOfDay: number
  value: number
  data: [number, number]
}
interface AverageProps {
  data: AverageData[]
  series: SeriesOptions
  isArearange: boolean
}

type CreateMeanDaySeries = ({
  timezone,
  seriesSet,
  initialDate,
  noOfDays,
}: {
  timezone?: Timezone
  seriesSet: SeriesOptions[]
  initialDate: Date
  noOfDays?: number
}) => SeriesAreaOptions[]

const createDayTimeSeriesWithNullValues = (intervalInMin: number, initialDate: number) => {
  const timeSeries = []
  if (intervalInMin) {
    const minutesInDay = 60 * 24
    const numOfIntervals = minutesInDay / intervalInMin
    // We need start of day according to local time so we take timezoneoffset which will give
    // the difference in minutes, we convert to milliseconds and add or sub them
    const initialDateTime = new Date(initialDate)
    for (let i = 0; i < numOfIntervals; i++) {
      const nextIntervalTime = initialDateTime.getTime() + i * (intervalInMin * 60 * 1000)
      timeSeries.push([nextIntervalTime, null])
    }
  }
  return timeSeries
}

export const createMeanDaySeries: CreateMeanDaySeries = ({ seriesSet, initialDate }) => {
  const averageMap = seriesSet.map<AverageProps>((series) => {
    let avgDataInLineFormat: TimeSeries = []
    // calculate average values for each time
    const isArearange = series.type === 'arearange'
    // Line has two values [timestamp, value]
    if (series.data.length && series?.custom?.detectedInterval) {
      const timeSeries = createDayTimeSeriesWithNullValues(
        series?.custom?.detectedInterval / 1000 / 60,
        initialDate.getTime(),
      )
      timeSeries.shift()

      for (let i = 0; i < timeSeries.length; i++) {
        const hours = getHours(timeSeries[i][0])
        const minutes = getMinutes(timeSeries[i][0])
        let noOfValidValuesAtThisTime = 0

        const valuesAtThisTime = series.data.filter(
          (values) => hours === getHours(values[0]) && minutes === getMinutes(values[0]),
        )

        const sum = valuesAtThisTime.reduce((prevVal, currentVal) => {
          let value
          if (isArearange) {
            value = isNumeric(currentVal[1]) && isNumeric(currentVal[2]) ? (currentVal[1] + currentVal[2]) / 2 : null
          } else {
            value = currentVal[1]
          }
          if (isNumeric(value)) {
            noOfValidValuesAtThisTime++
          }
          return prevVal + value
        }, 0)

        timeSeries[i][1] = sum / noOfValidValuesAtThisTime
      }

      avgDataInLineFormat = timeSeries
    }

    return {
      data: avgDataInLineFormat,
      series,
      isArearange,
    }
  }, [])

  return averageMap.map<SeriesAreaOptions>((averageProps) => {
    const { series, data } = averageProps
    const color = series.color
    const name = c('Workbench:Quality').t`Mean Diurnal` + ' - ' + series.name
    const lineWidth = 1
    // const type = series.custom.datastreamType === METER_DATA ? 'area' : 'line'
    const type = 'line'

    const seriesData = {
      color,
      connectNulls: false,
      data: data,
      // gapSize: 1, // WARNING: don't use gapSize because it might cause the series to disappear when zoomed in
      lineWidth,
      custom: {
        alignment: 'right',
        label: name,
        shouldBeFilled: false,
        detectedInterval: series?.custom?.detectedInterval,
        unit: series?.custom?.unit,
        result: series?.custom?.result,
      },
      name,
      step: 'right', // seems to avoid random transformation of series in step charts
      type,
      dataGrouping: { approximation: 'range', smoothed: false },
      yAxis: series.yAxis,
    }

    if (series?.custom?.datastreamType === TimeSeriesType.BACK_CAST) {
      seriesData['dashStyle'] = 'ShortDash'
    }
    return seriesData
  })
}
