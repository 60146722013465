import { Box, Typography } from '@material-ui/core'
import { TableCellIcon } from 'modules/data/fileManager/FileManagerTableRow'
import FileTitle from 'modules/data/fileManager/FileTitle'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'
import { Column } from 'modules/reTable/reTable.types'
import React from 'react'
import styled from 'styled-components'
import { t } from 'ttag'
import Number from 'ui/Number'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'
import { formatDate } from 'utils/date'
import { parseFileUploadStatus } from 'utils/file'
import { AppUnits } from 'utils/units'

const StatusCell = styled.div`
  padding-left: 3px;
`
const TooltipContent = styled.div`
  max-height: 9.5em;
  overflow: hidden;
`

interface FileManagerTableCellProps {
  column: Column
  file: MeterdataFile
  search: string
  hovered: boolean
}

const FileManagerTableCell: React.FC<FileManagerTableCellProps> = ({ column, file, search, hovered }) => {
  const status = parseFileUploadStatus(file)

  const popperLabel = (
    <Flex>
      <TableCellIcon icon={status.icon} color={status.color} />
      {/*{status.type === 'success' && <Success>{status.label}</Success>}*/}
      {/*{status.type === 'progress' && <Info>{status.label}</Info>}*/}
      {/*{status.type === 'error' && <Error>{status.label}</Error>}*/}
    </Flex>
  )

  const popperContent = (
    <TooltipContent>
      <Typography color={status?.type === 'error' ? 'error' : 'primary'} variant="subtitle1">
        {status.label}
      </Typography>
      <Typography color="inherit">{status.description}</Typography>
      <Box mt={1}>
        {(status.messages || []).slice(0, 7).map((message, index) => (
          <div key={index}>{message}</div>
        ))}
      </Box>
    </TooltipContent>
  )

  switch (column.name) {
    case 'status':
      return (
        <StatusCell>
          <PopperTooltip popperLabel={popperLabel} popperContent={popperContent} />
        </StatusCell>
      )
    case 'originalFileName':
      return <FileTitle column={column} query={search} hovered={hovered} file={file} />

    case 'contentType':
      return (
        <Flex>
          <TableCellIcon icon="tachometer-alt" />
          {t`Meter data`}
        </Flex>
      )
    case 'uploadTs':
      return <>{formatDate(file.uploadTs)}</>
    case 'fileSize':
      return <Number showFractionalPart={true} unit={AppUnits.KILO_BYTE} limit={1} data={file.fileSize / 1024} />
  }
}

export default React.memo(FileManagerTableCell)
