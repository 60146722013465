import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Asset } from 'modules/asset/store/asset.types'
import { Availability, AvailabilityTableItem } from 'modules/asset/availability/Availability.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import {
  reTableColumnsSelectedSelector,
  reTableSearchSelector,
  reTableSortSelector,
} from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_AVAILABILITIES, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { isAfter, isBefore } from 'date-fns'
import { getNewAvailabilityData, isAvailability, prepareAvailabilityDataBeforeSave } from 'utils/availabilities'
import { getAvailabilityColumns } from 'fixtures/assetMaintenance'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import ReTable from 'modules/reTable/ReTable'
import Flex from 'ui/styles/Flex'
import { Form } from 'react-final-form'
import { validateAvailabilityForm } from 'utils/formValidations'
import { FormApi } from 'final-form'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'

import { hasAnyAuthority, isAdmin, isImpersonatedAdmin } from 'utils/user'
import { jt, t } from 'ttag'
import { table } from 'themes/theme-light'
import {
  useAvailabilityDeleteMutation,
  useAvailabilitySaveMutation,
} from 'modules/asset/availability/api/availability.api'
import { testIdAvailabilityTableRoot } from 'modules/asset/availability/Availabilities.ids'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import AvailabilityTableBody from 'modules/asset/availability/AvailablilityTableBody'
import AvailabilityTableHeader from 'modules/asset/availability/AvailabilityTableHeader'
import AvailabilityTableToolbar from 'modules/asset/availability/AvailabilityTableToolbar'
import { CONFLICT } from 'utils/request'
import { LinkToContactSales } from 'ui/elements/MailLink'

const AvailabilityForm = styled.form`
  height: 100%;
`

const ErrorMessage = styled.div`
  margin: 0.5em 0;
  color: red;
  height: 0.8rem;
`

const TableWrapper = styled.div`
  height: calc(100vh - 15em);
`

const StyledReactFinalForm = styled(Form)`
  height: 100%;
`

const StyledReTable = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
  height: 100%;
`

let availabilityFormRef: FormApi<Availability>

interface AvailabilityTableRQProps {
  assets: Asset[]
  availabilities: AvailabilityTableItem[]
  rootAsset: Asset
}

const AvailabilityTable: React.FC<AvailabilityTableRQProps> = ({ assets, availabilities, rootAsset }) => {
  const dispatch = useDispatch()
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const tableHeaderHasActions = true

  const search = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_AVAILABILITIES)
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_AVAILABILITIES)
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_AVAILABILITIES)
  const userTimezone = useSelector(getUserTimezoneSelector)
  const user = useSelector(getUserResultSelector)

  const [editedAvailability, setEditedAvailability] = useState<Availability | null>(null)
  const [itemsToRender, setItemsToRender] = useState<AvailabilityTableItem[]>([])
  const [visibleItems, setVisibleItems] = useState<AvailabilityTableItem[]>([])
  const [showHistory, setShowHistory] = useState<boolean>(true)
  const [showHistoryButton, setShowHistoryButton] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const { mutate: saveMutation, isSuccess: saveSuccess, error, isError } = useAvailabilitySaveMutation()
  const { mutate: deleteMutation } = useAvailabilityDeleteMutation()

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'uiAssetName',
    }),
    [],
  )
  const collapsibleIds = useMemo(() => assets.map((asset) => asset.id), [assets])
  const defaultCollapsed = useMemo(() => [], [])

  const checkUserHasAuthority = useCallback(() => {
    const hasGlobalAccess = isAdmin(user) || isImpersonatedAdmin(user)
    const hasAccess = hasAnyAuthority(user, ['ROLE_MAINTENANCE']) || hasGlobalAccess

    if (!hasAccess) {
      setErrorMsg(
        jt`You are not subscribed to make changes to the availabilties. Please contact ${LinkToContactSales} to use this feature.`,
      )
    } else {
      setErrorMsg(null)
    }
    return hasAccess
  }, [user])

  // Add availabilities to the assets and render the items
  const handleItemsToRenderChange = useCallback(
    (items) => {
      let rowItems: typeof itemsToRender = []
      if (sort.active) {
        rowItems = items
      } else {
        assets.forEach((asset) => {
          const assetAvailabilities = items.filter((item) => asset.id === item.siteId)
          if (assetAvailabilities.length > 0 || !search) {
            rowItems.push(asset)
            rowItems = rowItems.concat(assetAvailabilities)
          }
        })
      }

      // debouncedSetFilteredItems(items)
      requestAnimationFrame(() => {
        setItemsToRender(rowItems)
      })
    },
    [search, sort, assets],
  )

  const handleAddAvailability = useCallback(
    (event: React.MouseEvent<HTMLElement> | null, asset: Asset) => {
      if (!checkUserHasAuthority()) {
        return
      }
      const items = [...visibleItems.filter((a) => !a.isNewAvailability)]
      const newAvailabilityObj = getNewAvailabilityData(asset, userTimezone)
      const newItemIndex = visibleItems.findIndex((item) => item.id === asset.id) + 1
      items.splice(newItemIndex, 0, newAvailabilityObj)
      setVisibleItems(items)
      setEditedAvailability(newAvailabilityObj)
    },
    [checkUserHasAuthority, visibleItems],
  )

  const handleEditAvailability = useCallback(
    (availability: AvailabilityTableItem) => {
      if (!checkUserHasAuthority()) {
        return
      }
      const items = [...visibleItems.filter((a) => !a.isNewAvailability)]
      setVisibleItems(items)
      setEditedAvailability(availability)
    },
    [checkUserHasAuthority, editedAvailability, visibleItems],
  )

  const handleDeleteItem = useCallback((data: AvailabilityTableItem) => {
    deleteMutation(data)
  }, [])

  const handleCloseForm = useCallback(() => {
    if (editedAvailability && editedAvailability.isNewAvailability) {
      const items = visibleItems.filter((item) => !item.isNewAvailability)
      setVisibleItems(items)
    }
    setEditedAvailability(null)
    setErrorMsg(null)
    availabilityFormRef.reset()
  }, [editedAvailability, visibleItems])

  const handleFormSubmit = useCallback(
    (formData: Availability) => {
      const data = Object.assign({}, formData)
      const preparedData = prepareAvailabilityDataBeforeSave(data, availabilityFormRef, userTimezone)
      saveMutation(preparedData)
    },
    [availabilityFormRef, userTimezone],
  )

  const handleToggleHistory = useCallback(() => {
    setShowHistory((showHistory) => !showHistory)
  }, [])

  const columnsAvailable = useMemo(
    () => (!sort.active ? getAvailabilityColumns().filter((h) => h.name !== 'uiAssetName') : getAvailabilityColumns()),
    [sort],
  )

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_AVAILABILITIES, columnsAvailable })
  }, [columnsAvailable])

  useEffect(() => {
    const columnsSelectedNames = columnsSelected.map((c) => c.name)
    const updatedColumnsSelected = columnsAvailable
      .filter((c) => {
        return c.fixed || columnsSelectedNames.includes(c.name)
      })
      .map((c) => c.name)
    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_AVAILABILITIES,
      columnsSelected: updatedColumnsSelected,
    })
  }, [columnsAvailable, columnsSelected])

  useEffect(() => {
    const now = new Date()
    let newVisibleItems = [...itemsToRender]
    setShowHistoryButton(newVisibleItems.some((item) => isAvailability(item) && isBefore(new Date(item.to), now)))
    if (!showHistory) {
      // don't show past regulation versions
      newVisibleItems = newVisibleItems.filter((item) => {
        return isAvailability(item) ? isAfter(new Date(item.to), now) : true
      })
    }
    setVisibleItems(newVisibleItems)
  }, [itemsToRender, showHistory])

  useEffect(() => {
    if (saveSuccess) {
      setEditedAvailability(null)
      setErrorMsg(null)
    }
  }, [saveSuccess])

  useEffect(() => {
    if (error && JSON.parse(error?.message).status === CONFLICT && isError) {
      setErrorMsg(t`This availability is in conflict with another availability`)
    }
  }, [error])

  return (
    <TableWrapper direction="column" data-testid={testIdAvailabilityTableRoot}>
      <Flex direction="column" fullHeight>
        <ErrorMessage>{errorMsg && <> {errorMsg}</>}</ErrorMessage>
        <StyledReactFinalForm
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          onSubmit={handleFormSubmit}
          initialValues={editedAvailability || {}}
          validate={validateAvailabilityForm}
          render={({ form, handleSubmit }) => {
            availabilityFormRef = form

            return (
              <AvailabilityForm onSubmit={handleSubmit}>
                <AvailabilityTableToolbar
                  onAddNewItem={handleAddAvailability}
                  onToggleHistory={handleToggleHistory}
                  showHistory={showHistory}
                  columns={columnsSelected}
                  tableHeaderHasActions={true}
                  formDirty={form?.getState()?.dirty}
                  assets={assets}
                  showHistoryButton={showHistoryButton}
                  editedAvailability={editedAvailability}
                />
                <StyledReTable
                  id={RETABLE_ID_AVAILABILITIES}
                  itemHeight={table.rowHeight}
                  items={availabilities as ReTableItem[]}
                  hasExtendedHeader={false}
                  itemsPaddingHeader={ITEMS_PADDING_HEADER}
                  itemsPaddingFooter={ITEMS_PADDING_FOOTER}
                  renderAheadCount={RENDER_AHEAD_COUNT}
                  collapsibleIds={collapsibleIds}
                  defaultCollapsed={defaultCollapsed}
                  defaultSort={defaultSort}
                  onItemsToRenderChange={handleItemsToRenderChange}
                >
                  <AvailabilityTableHeader isFormOpen={false} columns={columnsSelected} />
                  <AvailabilityTableBody
                    onAddNewItem={handleAddAvailability}
                    onEditItem={handleEditAvailability}
                    onDeleteItem={handleDeleteItem}
                    onSubmit={handleSubmit}
                    columns={columnsSelected}
                    visibleItems={visibleItems}
                    form={form as FormApi}
                    onClose={handleCloseForm}
                    editedAvailability={editedAvailability}
                    tableHeaderHasActions={tableHeaderHasActions}
                    assets={assets}
                    rootAsset={rootAsset}
                    availabilities={availabilities}
                  />
                </StyledReTable>
              </AvailabilityForm>
            )
          }}
        />
      </Flex>
    </TableWrapper>
  )
}

export default React.memo(AvailabilityTable)
