import React, { useEffect, useMemo } from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import {
  useSeasonalClimatologyByAssets,
  useSeasonalIntraMonthForecastByAssets,
  useSeasonalMonthlyForecastByAssets,
  useSeasonalMonthlyMeterDataByAssets,
} from 'modules/workspace/advancedChartWidgets/seasonalForecast/seasonalForecast.api'
import AssetLink from 'modules/asset/AssetLink'
import InlineLoading from 'ui/InlineLoading'
import Flex from 'ui/styles/Flex'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { theme } from 'themes/theme-light'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { Column } from 'modules/reTable/reTable.types'

interface SeasonalForecastAssetRowProps {
  asset: Asset
  tableHeaderHasActions: boolean
  columns: Column[]
  setAllResultsLoading: (value: boolean) => void
}

const SeasonalForecastAssetRow: React.FC<SeasonalForecastAssetRowProps> = ({
  asset,
  tableHeaderHasActions,
  columns,
  setAllResultsLoading,
}) => {
  const seasonalMonthlyForecastResult = useSeasonalMonthlyForecastByAssets({ assets: [asset] })
  const currentAssetForecastResult = seasonalMonthlyForecastResult[0]

  const seasonalMonthlyMeterDataResult = useSeasonalMonthlyMeterDataByAssets({ assets: [asset] })
  const currentAssetMeterDataResult = seasonalMonthlyMeterDataResult[0]

  const seasonalClimatologyResult = useSeasonalClimatologyByAssets({ assets: [asset] })
  const currentAssetClimatologyResult = seasonalClimatologyResult[0]

  const intraMonthForecastResult = useSeasonalIntraMonthForecastByAssets({ assets: [asset] })
  const currentAssetIntraMonthForecastResult = intraMonthForecastResult[0]

  const isResultLoading = useMemo(
    () =>
      currentAssetForecastResult?.isLoading ||
      currentAssetMeterDataResult?.isLoading ||
      currentAssetClimatologyResult?.isLoading ||
      currentAssetIntraMonthForecastResult?.isLoading,

    [
      currentAssetForecastResult?.isLoading,
      currentAssetMeterDataResult?.isLoading,
      currentAssetClimatologyResult?.isLoading,
      currentAssetIntraMonthForecastResult?.isLoading,
    ],
  )

  useEffect(() => {
    setAllResultsLoading(isResultLoading)
  }, [isResultLoading])

  return (
    <ReTableCell colSpan={columns.length + (tableHeaderHasActions ? 1 : 0)}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexBasis="40%">
          <ReTableExpandToggle item={asset} />

          <AssetLink color={theme.palette.primary.main} query={''} asset={asset} />
          {isResultLoading && <InlineLoading />}
        </Flex>
      </Flex>
    </ReTableCell>
  )
}

export default React.memo(SeasonalForecastAssetRow)
