import React from 'react'
import { IconSelectedColor, SvgIconPath } from 'ui/styles/SvgIconPath'
import { TYPE_CLUSTER } from 'modules/asset/store/asset.types'
import { getTypeLabel } from 'utils/asset'

export interface AssetIconProps {
  iconSelected?: boolean
  size?: string
}

const ClusterIcon: React.FC<AssetIconProps> = ({ iconSelected, size }) => {
  return (
    <svg width={size} height={size} viewBox="200 23 100 490" xmlns="http://www.w3.org/2000/svg">
      <title>{getTypeLabel(TYPE_CLUSTER)}</title>
      <SvgIconPath
        transform="rotate(0 253 230)"
        iconColor={iconSelected ? IconSelectedColor : ''}
        d="M384 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V96c0-35.35-28.65-64-64-64zM128 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm0-192c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm96 96c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm96 96c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm0-192c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"
      />
    </svg>
  )
}

export default ClusterIcon
