import React, { useCallback, useMemo } from 'react'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import Flex from 'ui/styles/Flex'
import AssetLink, { ShowPath } from 'modules/asset/AssetLink'
import { Asset } from 'modules/asset/store/asset.types'
import { Chip } from '@material-ui/core'
import styled from 'styled-components'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { Column, RETABLE_ID_AVAILABILITIES } from 'modules/reTable/reTable.types'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import ErrorMessage from 'ui/form/ErrorMessage'
import { theme } from 'themes/theme-light'
import { AvailabilityTableItem } from 'modules/asset/availability/Availability.types'
import { c } from 'ttag'
import { QUERY_ACTIVE_TAB, QUERY_ASSET } from 'utils/query-string'
import { FormApi } from 'final-form'
import UpdateTableItemActions from 'modules/reTable/UpdateTableItemsActions'
import { hasPermissionForMaintenance, isAdmin, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const AvailabilitiesCount = styled(Chip)`
  margin-left: 0.5em;
`

const Dash = styled.span`
  margin-right: 1em;
`

interface AssetRowProps {
  asset: Asset
  error: string | null
  onAddNewAvailability: (event: React.MouseEvent<HTMLElement>, asset: Asset) => void
  columns: Column[]
  tableHeaderHasActions: boolean
  rootAsset: Asset
  availabilities: AvailabilityTableItem[]
  showCreateButton?: boolean
  form: FormApi
  isReadOnlyUser: boolean
}

const AssetRow: React.FC<AssetRowProps> = ({
  asset,
  error,
  onAddNewAvailability,
  columns,
  tableHeaderHasActions,
  rootAsset,
  availabilities,
  showCreateButton,
  form,
}) => {
  const user = useSelector(getUserResultSelector)
  const search = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_AVAILABILITIES)

  const totalCount = useMemo(() => availabilities.filter((item) => item.siteId == asset.id).length, [
    asset,
    availabilities,
  ])

  const levelDashes = useMemo(() => {
    if (asset && rootAsset) {
      return [...Array(Math.max(asset.uiLevel - rootAsset.uiLevel, 0)).keys()]
    } else {
      return []
    }
  }, [asset, rootAsset])

  const handleAddItem = useCallback(() => onAddNewAvailability(null, asset), [onAddNewAvailability, asset])

  return (
    <ReTableCell no_border={true} colSpan={columns.length + (tableHeaderHasActions ? 1 : 0)}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flexBasis="40%">
          <ReTableExpandToggle item={asset} />
          {levelDashes.map((index) => (
            <Dash key={index}>&nbsp;</Dash>
          ))}

          <AssetLink
            color={theme.palette.primary.main}
            query={search}
            asset={asset}
            rootAsset={rootAsset}
            showPath={ShowPath.TOOLTIP}
          />

          {totalCount > 0 && <AvailabilitiesCount size="small" color="primary" label={totalCount.toString()} />}
        </Flex>
        <Flex flexBasis="30%" alignItems="center">
          {error?.assetId === asset.id && error?.message && <ErrorMessage errorMsg={error.message} />}
        </Flex>
        <Flex flexBasis="30%" justifyContent="flex-end">
          <UpdateTableItemActions
            onAddItem={handleAddItem}
            showAddActions={showCreateButton}
            highlightAdmin={
              (isAdmin(user) || isImpersonatedAdmin(user)) && !hasPermissionForMaintenance(user) ? true : false
            }
            form={form}
            dialogContext={c('Availability').t`availability limitation`}
            dialogText={c(`Availability`).t`availability`}
            pageQueries={[QUERY_ASSET, QUERY_ACTIVE_TAB]}
            navigationDialogKey={'availabilityLimitation'}
          />
        </Flex>
      </Flex>
    </ReTableCell>
  )
}

export default React.memo(AssetRow)
