import React, { useCallback, useEffect, useState } from 'react'
import {
  getChildren,
  getTypeLabel,
  isCHP,
  isCluster,
  isGenerator,
  isPark,
  isSolarPark,
  isSolarPlant,
  isWindPark,
  isWindPlant,
  plantsWithoutParent,
  removeDuplicateAssets,
} from 'utils/asset'
import { Box } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { jt, t } from 'ttag'
import AssetAutocomplete from 'ui/elements/AssetAutocomplete'
import { AssetAutocompleteContainer, ErrorMsg } from 'ui/form/assetForm.style'
import { Asset, TYPE_SOLARPLANT, TYPE_WINDPLANT } from 'modules/asset/store/asset.types'

import { FormApi } from 'final-form'
import { ClusterTypesEnum } from 'fixtures/assetForm'

interface ChildRelationProps {
  formState: Asset
  enterManually: boolean
  asset: Asset
  allAssets: Asset[]
  form: FormApi<Asset>
  formErrors: any
  onRelationChange: () => void
}

const ChildRelation: React.FC<ChildRelationProps> = ({
  formState,
  enterManually,
  asset,
  allAssets,
  form,
  formErrors,
  onRelationChange,
}) => {
  const currentAssetChildren = formState?.id ? getChildren(asset, allAssets) : []
  const assetType = getTypeLabel(formState?.type)
  const [childrenAssetList, setChildrenAssetList] = useState<Asset[]>([])

  const getChildSelectedIds = () => {
    if (!formState?.id) {
      return []
    }
    if (isCluster(formState)) {
      return [...formState.parkIds, ...formState.generatorIds]
    } else {
      return formState.generatorIds
    }
  }

  const setAssetChildren = useCallback(() => {
    const allPhysicalClusterIds = allAssets
      .filter((a) => isCluster(a) && !a.deleted && a.clusterType !== ClusterTypesEnum.COLLECTION)
      .map((pc) => pc.id)

    const allPlantsWithOutPhysicalConnection = allAssets.filter((a) => {
      if (isGenerator(a) && !a.deleted) {
        const isPlantNotBelongsToPhysicalCluster = a?.clusterIds?.length
          ? a?.clusterIds.every((cid) => !allPhysicalClusterIds.includes(cid))
          : true
        const isPlantNotBelongsToPhysicalPark = !a?.parkId
        return isPlantNotBelongsToPhysicalCluster && isPlantNotBelongsToPhysicalPark
      } else return false
    })

    const allParksWithOutPhysicalConnection = allAssets.filter((a) => {
      if (isPark(a) && !a.deleted) {
        const isParkNotBelongsToPhysicalCluster = a?.clusterIds?.length
          ? a?.clusterIds.every((cid) => !allPhysicalClusterIds.includes(cid))
          : true
        return isParkNotBelongsToPhysicalCluster
      } else return false
    })

    if (isPark(formState)) {
      if (isWindPark(formState)) {
        const windGeneratorsWithoutParents = plantsWithoutParent(allAssets, TYPE_WINDPLANT)
        setChildrenAssetList([...currentAssetChildren, ...windGeneratorsWithoutParents])
      } else if (isSolarPark(formState)) {
        const solarGeneratorsWithoutParents = plantsWithoutParent(allAssets, TYPE_SOLARPLANT)
        setChildrenAssetList([...currentAssetChildren, ...solarGeneratorsWithoutParents])
      }
    } else if (isCluster(formState)) {
      const allParks = allAssets.filter((a) => isPark(a) && !a.deleted)
      let allChildren = []
      const isHybridOrPool = formState.clusterType !== ClusterTypesEnum.COLLECTION

      if (isHybridOrPool) {
        allChildren = [
          ...allParksWithOutPhysicalConnection,
          ...allPlantsWithOutPhysicalConnection,
          ...currentAssetChildren,
        ]
      } else {
        const allPlantsNotInsideAPark = allAssets.filter((a) => isGenerator(a) && !a.parkId && !a.deleted)
        allChildren = [...allParks, ...allPlantsNotInsideAPark, ...currentAssetChildren]
      }

      // Do not put chp plants inside the hybrid parks
      if (formState.clusterType === ClusterTypesEnum.HYBRID && allChildren.length > 1) {
        const childrenWithoutChp = [...allChildren].filter((a) => !isCHP(a))
        allChildren = childrenWithoutChp
      }

      // We need to remove the duplicate entries as parks and plants can be inside multiple clusters
      const uniqueChildren = removeDuplicateAssets(allChildren)
      setChildrenAssetList(uniqueChildren)
    }
  }, [allAssets, formState, JSON.stringify(currentAssetChildren)])

  const handleChildSelection = useCallback(
    (ids: string[]) => {
      if (isCluster(formState)) {
        const parkIds = allAssets.filter((a) => isPark(a) && ids.includes(a.id)).map((obj) => obj.id)
        form.mutators.setValue('parkIds', parkIds)
        const plantIds = allAssets
          .filter((a) => (isSolarPlant(a) || isWindPlant(a) || isCHP(a)) && ids.includes(a.id))
          .map((obj) => obj.id)
        form.mutators.setValue('generatorIds', plantIds)
      } else if (isPark(formState)) {
        form.mutators.setValue('generatorIds', ids)
      }
      onRelationChange()
    },

    [formState, allAssets],
  )

  const handleValidate = (selectedAssets: string[], selectChild: boolean) => {
    if (!selectChild) {
      // updateParentSelection(selectedAssets)
    }
  }

  useEffect(() => {
    if (isCluster(formState)) {
      setAssetChildren()
    } else if (isPark(formState)) {
      setAssetChildren()
    }
  }, [formState, formState?.type, setAssetChildren])

  return (
    <>
      {((isPark(formState) && !enterManually) || isCluster(formState)) && (
        <Box mt={1} mb={1}>
          <Flex direction="column" alignItems="flex-start" justifyContent="center">
            <Box mb={1.4}>{jt`Assets in this ${assetType}`}:</Box>

            {childrenAssetList.length > 0 ? (
              <AssetAutocompleteContainer>
                <AssetAutocomplete
                  onAssetSelect={handleChildSelection}
                  onValidate={handleValidate}
                  selectedIds={getChildSelectedIds() || []}
                  autocompleteAssetList={childrenAssetList || []}
                  selectChild={true}
                  multiple={true}
                />
                {formErrors.generatorIds && <ErrorMsg>{formErrors.generatorIds}</ErrorMsg>}
              </AssetAutocompleteContainer>
            ) : (
              <Box>
                <AssetAutocompleteContainer>
                  <ErrorMsg>
                    {formErrors.generatorIds ? formErrors.generatorIds : ''}{' '}
                    {t`All assets are currently assigned to a Park or Cluster. Please update an existing asset to be unassigned or create a new asset in order to see it here.`}
                  </ErrorMsg>
                </AssetAutocompleteContainer>
              </Box>
            )}
          </Flex>
        </Box>
      )}
    </>
  )
}

export default ChildRelation
