import React from 'react'
import { Column, ReTableItem } from 'modules/reTable/reTable.types'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { Asset } from 'modules/asset/store/asset.types'
import styled from 'styled-components'
import { getClimatologyDataStreams, getSeasonalForecastDataStreams, MonthlyViewModeIds } from 'utils/seasonalForecast'
import SeasonalForecastAssetRow from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastAssetRow'
import SeasonalForecastCategoryAndDataRows from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastCategoryAndDataRows'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { getDataStreamTypeQueryLabel } from 'utils/dataStream'
import { rgba } from 'polished'

interface SeasonalForecastTableCellProps {
  bg_color?: string
  border_bottom_color?: string
}

export const SeasonalForecastTableCell = styled(ReTableCell)<SeasonalForecastTableCellProps>`
  &.MuiTableCell-root {
    background: ${(props) => props.bg_color || 'inherit'};
    border-bottom-color: ${(props) => props.border_bottom_color || rgba(224, 224, 224, 1)};
  }
`

const SeasonalForecastTableRow = styled(ReTableRow)`
  &.MuiTableRow-root:hover {
    background: none;
  }
`

interface SeasonalDataTableBodyProps {
  columns: Column[]
  itemsToRender: ReTableItem[] | Asset[]
  tableHeaderHasActions: boolean
  assets: Asset[]
  setAllResultsLoading: (value: boolean) => void
  climatologySelected: boolean
  forecastSelected: boolean
  collapsedIds: string[]
  monthlyViewMode: MonthlyViewModeIds
}

const SeasonalForecastTableBody: React.FC<SeasonalDataTableBodyProps> = ({
  columns,
  itemsToRender,
  tableHeaderHasActions,
  assets,
  setAllResultsLoading,
  climatologySelected,
  forecastSelected,
  collapsedIds,
  monthlyViewMode,
}) => {
  const climatologyDataStreamName = getDataStreamTypeQueryLabel(TimeSeriesSubType.CLIMATOLOGY)?.label || ''
  const seasonalForecastDataStreamName = getDataStreamTypeQueryLabel(TimeSeriesSubType.SEASONAL_FORECAST)?.label || ''

  const seasonalForecastDataStreams = getSeasonalForecastDataStreams()
  const climatologyDataStreams = getClimatologyDataStreams()

  return (
    <ReTableBody>
      {assets.map((asset) => {
        const assetSpecificItems = itemsToRender?.filter((item) => asset.id === item?.assetId)
        const assetSeasonalItems = assetSpecificItems.filter((item) => seasonalForecastDataStreams.includes(item.type))
        const assetClimatologyItems = assetSpecificItems.filter((item) => climatologyDataStreams.includes(item.type))

        return (
          <React.Fragment key={asset.id}>
            <SeasonalForecastTableRow>
              <SeasonalForecastAssetRow
                asset={asset}
                tableHeaderHasActions={tableHeaderHasActions}
                columns={columns}
                setAllResultsLoading={setAllResultsLoading}
              />
            </SeasonalForecastTableRow>
            {forecastSelected && (
              <SeasonalForecastCategoryAndDataRows
                key={`asset-${asset.id}-seasonalForecast`}
                category={seasonalForecastDataStreamName}
                assetSpecificItems={assetSeasonalItems}
                asset={asset}
                columns={columns}
                tableHeaderHasActions={tableHeaderHasActions}
                setAllResultsLoading={setAllResultsLoading}
                collapsedIds={collapsedIds}
                monthlyViewMode={monthlyViewMode}
              />
            )}

            {climatologySelected && (
              <SeasonalForecastCategoryAndDataRows
                key={`asset-${asset.id}-climatology`}
                category={climatologyDataStreamName}
                assetSpecificItems={assetClimatologyItems}
                asset={asset}
                columns={columns}
                tableHeaderHasActions={tableHeaderHasActions}
                setAllResultsLoading={setAllResultsLoading}
                collapsedIds={collapsedIds}
                monthlyViewMode={monthlyViewMode}
              />
            )}
          </React.Fragment>
        )
      })}
    </ReTableBody>
  )
}

export default SeasonalForecastTableBody
