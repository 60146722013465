import { useAllAssets } from 'modules/asset/api/assets.api'

import { Asset, FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import ReTable, { ReTableProps } from 'modules/reTable/ReTable'
import { RETABLE_ID_QUALITY_PREVIEW } from 'modules/reTable/reTable.types'

import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { table } from 'themes/theme-light'

import { DynamicTableHeightContainer } from 'ui/styles/table'

import {
  filterQualityTableDataStreams,
  getQualityTableColumns,
  QualityPreviewErrors,
  QualityPreviewResult,
} from 'utils/quality'
import { TimeSeriesResult, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useDispatch, useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useMetaForecastData } from 'utils/hooks/useMetaForecastData'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import QualityPreviewTableBody from 'modules/workspace/advancedChartWidgets/quality/QualityPreviewTableBody'
import { QueryObserverResult } from 'react-query'
import { useUniqueSelectedAssets } from 'utils/asset'
import { useSortedSelectedDataStreams } from 'utils/hooks/datastreams/useSortedSelectedDataStreams'

const PreviewTable = styled(ReTable)<ReTableProps>`
  .MuiTableRow-root .details-link {
    visibility: hidden;
  }
  .MuiTableRow-root:hover .details-link {
    visibility: visible;
  }
`

// we have 3 different types:
//    Asset => top hierarchy
//    Error => Details about quality calculation
//    null => Data is missing
export type QualityPreviewRowItem = Asset | QualityPreviewErrors | null

interface QualityPreviewProps {
  result: QualityPreviewResult
  hasMeterData: boolean
  hasForecastData: boolean
  loading: boolean
  forecastAndBackcastQueryResults: QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[]
  isBackCastEnabled: boolean
}
const QualityPreviewTable: React.FC<QualityPreviewProps> = ({
  result,
  hasMeterData,
  hasForecastData,
  loading,
  forecastAndBackcastQueryResults,
  isBackCastEnabled,
}) => {
  const dispatch = useDispatch()
  const allAssets = useAllAssets()
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const metaForecastWidgetData = useMetaForecastData()
  const showMetaForecastWidget = metaForecastWidgetData.showMetaForecastWidget
  const selectedAssets = useUniqueSelectedAssets()

  const [columnsSelectedWithDynamicWidth, setColumnsSelectedWithDynamicWidth] = useState<any>()

  const selectedDataStreams = useSortedSelectedDataStreams()

  const qualityTableDataStreams = filterQualityTableDataStreams(selectedDataStreams)

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_QUALITY_PREVIEW)
  const columnsAvailable = useMemo(() => getQualityTableColumns(), [])

  const assets = selectedAssets

  const itemsToRender = useMemo(() => {
    const items: Partial<QualityPreviewRowItem>[] = []
    // Quality values for each asset
    if (hasMeterData || hasForecastData || showMetaForecastWidget) {
      assets.forEach((asset) => {
        items.push(asset)

        qualityTableDataStreams.forEach((dataStream) => {
          if (dataStream?.id) {
            // Quality values for selected asset and dataStream
            const qualityErrorValues = result?.[asset.id]?.[dataStream?.id]
            if (qualityErrorValues && Object.keys(qualityErrorValues).length) {
              items.push(qualityErrorValues)
            } else {
              // Need to show empty row
              items.push({ model: dataStream?.id, assetId: asset.id })
            }

            if (isBackCastEnabled && dataStream.type === TimeSeriesType.SITE_FORECAST) {
              // BackCast quality values for selected assets and site forecast dataStream
              // BackCast is available only for site forecast dataStreams
              const assetBackCastId = `${asset.id}BACK_CAST`
              const backCastQualityErrorValues = result?.[assetBackCastId]?.[dataStream?.id]
              if (backCastQualityErrorValues && Object.keys(backCastQualityErrorValues).length) {
                items.push(backCastQualityErrorValues)
              } else {
                // Need to show empty row
                items.push({
                  model: dataStream?.id,
                  assetId: assetBackCastId,
                  correlationSetType: TimeSeriesType.BACK_CAST,
                })
              }

              // BackCast Quality values for selected models and site forecast dataStream
              const selectedModelsByAsset = assetSelection.filter(
                (assetSelectionId) =>
                  assetSelectionId.includes(asset?.id) && assetSelectionId.includes(FORECAST_MODEL_FOR_BACK_CAST_ID),
              )
              selectedModelsByAsset.forEach((id) => {
                const backCastModelId = `${id}BACK_CAST`
                const modelBackCastQualityErrorValues = result?.[backCastModelId]?.[dataStream?.id]
                if (modelBackCastQualityErrorValues && Object.keys(modelBackCastQualityErrorValues)) {
                  items.push(modelBackCastQualityErrorValues)
                } else {
                  items.push({
                    // Need to show empty row
                    model: dataStream?.id,
                    assetId: backCastModelId,
                    correlationSetType: TimeSeriesType.BACK_CAST,
                  })
                }
              })
            }
          }
        })
      })
    }

    return items
  }, [
    result,
    assets,
    assetSelection,
    isBackCastEnabled,
    forecastAndBackcastQueryResults,
    qualityTableDataStreams,
    hasMeterData,
  ])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_QUALITY_PREVIEW,
      columnsAvailable: columnsAvailable,
    })
  }, [JSON.stringify(columnsAvailable)])

  useEffect(() => {
    const columnsSelectedNames = columnsSelected.map((c) => c.name)
    const updatedColumnsSelected = columnsAvailable
      .filter((c) => {
        return c.fixed || columnsSelectedNames.includes(c.name)
      })
      .map((c) => c.name)
    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_QUALITY_PREVIEW,
      columnsSelected: updatedColumnsSelected,
    })
  }, [columnsAvailable, columnsSelected])

  useEffect(() => {
    if (columnsSelected.length > 0) {
      const columnsSelectedCopy = [...columnsSelected]
      const lastItemIndex = columnsSelectedCopy.length - 1
      const copyOfLastItem = { ...columnsSelectedCopy[lastItemIndex] }
      copyOfLastItem.customDynamicWidth = 'auto'
      columnsSelectedCopy[lastItemIndex] = copyOfLastItem
      setColumnsSelectedWithDynamicWidth(columnsSelectedCopy)
    }
  }, [columnsSelected])

  return (
    <DynamicTableHeightContainer
      narrowRows={showMetaForecastWidget ? 1 : 0} // For Meta forecast widget alignment
      numberOfRows={(itemsToRender.length || 1) - (showMetaForecastWidget ? 1 : 0)}
    >
      <PreviewTable
        id={RETABLE_ID_QUALITY_PREVIEW}
        itemHeight={table.rowHeight}
        itemCount={itemsToRender.length}
        hasExtendedHeader={false}
        itemsPaddingHeader={1}
        itemsPaddingFooter={0}
        renderAheadCount={30}
      >
        <ReTableGenericHeader
          tableRowsHasCrudActions={false}
          isHierarchical={false}
          columns={columnsSelectedWithDynamicWidth?.length > 0 ? columnsSelectedWithDynamicWidth : columnsSelected}
          inheritWidthForHeaderWithSort={true}
          tableId={RETABLE_ID_QUALITY_PREVIEW}
          // headerActionCellWidth={'1em'}
        />
        <QualityPreviewTableBody
          hasForecastData={hasForecastData}
          hasMeterData={hasMeterData}
          itemsToRender={itemsToRender}
          columns={columnsSelectedWithDynamicWidth?.length > 0 ? columnsSelectedWithDynamicWidth : columnsSelected}
          allAssets={allAssets}
          loading={loading}
          showMetaForecastWidget={showMetaForecastWidget}
        />
      </PreviewTable>
    </DynamicTableHeightContainer>
  )
}

export default React.memo(QualityPreviewTable)
