// Query keys for caching data

import { AssetSaveMainItem } from 'utils/asset'
import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'

export const QUERY_KEY_ASSET_SAVEMAINITEMKEY = 'assets:saveMainItemKey'

// Hooks to fetch and update via react-query

export const useAssetSaveMainItemKey = () => {
  return useUserSetting<AssetSaveMainItem>(QUERY_KEY_ASSET_SAVEMAINITEMKEY)
}

export const useAssetSaveMainItemKeySaveMutation = () => {
  return useUserSettingSaveMutation<AssetSaveMainItem>(QUERY_KEY_ASSET_SAVEMAINITEMKEY)
}
