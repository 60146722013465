export const GET_ALL_PLAUSIBILITY_REQUEST = 'GET_ALL_PLAUSIBILITY_REQUEST'
export const GET_ALL_PLAUSIBILITY_SUCCESS = 'GET_ALL_PLAUSIBILITY_SUCCESS'
export const GET_ALL_PLAUSIBILITY_FAILURE = 'GET_ALL_PLAUSIBILITY_FAILURE'
export const GET_ALL_PLAUSIBILITY_DISMISS = 'GET_ALL_PLAUSIBILITY_DISMISS'
export type GET_ALL_PLAUSIBILITY_REQUEST = typeof GET_ALL_PLAUSIBILITY_REQUEST
export type GET_ALL_PLAUSIBILITY_SUCCESS = typeof GET_ALL_PLAUSIBILITY_SUCCESS
export type GET_ALL_PLAUSIBILITY_FAILURE = typeof GET_ALL_PLAUSIBILITY_FAILURE
export type GET_ALL_PLAUSIBILITY_DISMISS = typeof GET_ALL_PLAUSIBILITY_DISMISS
export type GET_ALL_PLAUSIBILITY_ACTION_TYPE =
  | GET_ALL_PLAUSIBILITY_REQUEST
  | GET_ALL_PLAUSIBILITY_SUCCESS
  | GET_ALL_PLAUSIBILITY_FAILURE
  | GET_ALL_PLAUSIBILITY_DISMISS
