import { apiRequest } from 'utils/request'
import { MasterDataConfig } from 'modules/masterData/masterData.types'
import axios from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'

// Query keys for caching data

export const QUERY_KEY_MASTER_DATA_CUSTOM_FORMATS = 'masterData:customFormats'

// API calls and Hooks to fetch and update via react-query

const getMasterDataCustomFormats = async () => {
  return await apiRequest<MasterDataConfig[]>(() => axios.get('/api/masterdata/v1/import/configs'))
}

export const useMasterDataCustomFormats = () => {
  return useQuery<MasterDataConfig[]>(QUERY_KEY_MASTER_DATA_CUSTOM_FORMATS, getMasterDataCustomFormats)
}

const deleteMasterDataCustomFormat = async (data: MasterDataConfig) => {
  return await apiRequest<MasterDataConfig>(() => {
    return axios.delete(`/api/masterdata/v1/import/configs/${data.id}`)
  })
}

export const useMasterDataCustomFormatDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteMasterDataCustomFormat, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_MASTER_DATA_CUSTOM_FORMATS)
    },
  })
}
