import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { SaveForecastModelNameAction, SaveForecastModelNameState } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import createReducer from 'utils/createReducer'

const initialState: SaveForecastModelNameState = {
  result: null,
  loading: false,
  error: null,
}

const result = createReducer<SaveForecastModelNameState['result'], SaveForecastModelNameAction>(
  (state = initialState.result, { type }) => {
    switch (type) {
      case actionTypes.SAVE_FORECAST_MODEL_NAME_SUCCESS:
        return true
      case actionTypes.SAVE_FORECAST_MODEL_NAME_REQUEST:
      case actionTypes.SAVE_FORECAST_MODEL_NAME_FAILURE:
        return false
    }
    return state
  },
)

const loading = createReducer<SaveForecastModelNameState['loading'], SaveForecastModelNameAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.SAVE_FORECAST_MODEL_NAME_SUCCESS:
      case actionTypes.SAVE_FORECAST_MODEL_NAME_FAILURE:
        return false
      case actionTypes.SAVE_FORECAST_MODEL_NAME_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<SaveForecastModelNameState['error'], SaveForecastModelNameAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.SAVE_FORECAST_MODEL_NAME_FAILURE:
        return error
      case actionTypes.SAVE_FORECAST_MODEL_NAME_REQUEST:
      case actionTypes.SAVE_FORECAST_MODEL_NAME_SUCCESS:
        return null
    }
    return state
  },
)

export const saveForecastModelNameReducer = combineReducers({ result, loading, error })

// selectors
export const saveForecastModelNameResultSelector = createSelector<
  any,
  SaveForecastModelNameState['result'],
  SaveForecastModelNameState['result']
>(
  (state) => state.quality.saveForecastModelName.result,
  (result) => result,
)

export const saveForecastModelNameLoadingSelector = createSelector<
  any,
  SaveForecastModelNameState['loading'],
  SaveForecastModelNameState['loading']
>(
  (state) => state.quality.saveForecastModelName.loading,
  (loading) => loading,
)
export const saveForecastModelNameErrorSelector = createSelector<
  any,
  SaveForecastModelNameState['error'],
  SaveForecastModelNameState['error']
>(
  (state) => state.quality.saveForecastModelName.error,
  (error) => error,
)
