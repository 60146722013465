import React, { useEffect } from 'react'
import OldUiIframe from 'ui/elements/OldUiIframe'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useQueryClient } from 'react-query'
import { QUERY_KEY_DELIVERY_TARGETS } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import { QUERY_KEY_DELIVERY_FORMATS } from 'modules/delivery/deliveryFormats/api/deliveryFormats.api'
import { QUERY_KEY_DELIVERY_FORMAT_TEMPLATES } from 'modules/delivery/deliveryFormats/api/deliveryFormatTemplates.api'

interface DeliveryIframeProps {
  url: string
}

const DeliveryIframe: React.FC<DeliveryIframeProps> = ({ url }) => {
  const user = useSelector(getUserResultSelector)

  // Fetch delivery targets when leaving iframe as user might updated
  const queryClient = useQueryClient()
  useEffect(() => {
    return () => {
      if (user) {
        queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMAT_TEMPLATES, { refetchInactive: true })

        queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMATS, { refetchInactive: true })

        queryClient.invalidateQueries(QUERY_KEY_DELIVERY_TARGETS, { refetchInactive: true })
      }
    }
  }, [user])
  return <OldUiIframe url={url} />
}

export default DeliveryIframe
