import React, { useEffect, useMemo, useState } from 'react'
import { getBackCastCoordinatesLimit, useBackCastCoordinates } from 'modules/workspace/header/backCast/backCast.api'
import { jt, msgid, ngettext, t } from 'ttag'
import { Box, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { table } from 'themes/theme-light'

import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { LinkToContactSales } from 'ui/elements/MailLink'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { useUniqueAllAssets } from 'utils/asset'
import Number from 'ui/Number'
import { ReTableCell } from 'modules/reTable/ReTableCell'

const useStyles = makeStyles({
  root: {
    width: 350,
    boxShadow: 'none',
    border: 'none',
  },
  container: {
    maxHeight: 240,
  },
})

interface BackcastCoordinatesInfoProps {
  assetsAndModelsSelection: string[]
}

const BackCastCoordinatesInfo: React.FC<BackcastCoordinatesInfoProps> = ({ assetsAndModelsSelection }) => {
  const classes = useStyles()
  const [backCastCoordinateLimit, setBackCastCoordinateLimit] = useState<number>(0)
  const usedBackCastCoordinatesResult = useBackCastCoordinates()
  const allAssets = useUniqueAllAssets()

  const coordinatesUsed = usedBackCastCoordinatesResult?.data || []

  const assetIds = assetsAndModelsSelection.map((id) => {
    const isModel = id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)
    return isModel ? getBackCastModelDataFromId(id).assetId : id
  })

  const assetsToCreateBackcast = useMemo(() => {
    return allAssets.filter((asset) => {
      return assetIds.includes(asset.id)
    })
  }, [allAssets, assetIds])

  const newCoordinates = useMemo(() => {
    return assetsToCreateBackcast.filter((asset) => {
      const alreadyUsed = coordinatesUsed.find(
        (coordinate) =>
          coordinate.longitude === asset?.location?.coordinate.longitude &&
          coordinate.latitude === asset?.location?.coordinate.latitude,
      )
      return !alreadyUsed
    })
  }, [assetsToCreateBackcast, coordinatesUsed])

  useEffect(() => {
    getBackCastCoordinatesLimit().then((limitNumber) => {
      setBackCastCoordinateLimit(limitNumber || 0)
    })
  }, [])

  const remainingCoordinates = useMemo(() => {
    return backCastCoordinateLimit - coordinatesUsed.length
  }, [coordinatesUsed, backCastCoordinateLimit])

  const getInfoToolTip = () => {
    return coordinatesUsed.length ? (
      <PopperTooltip
        popperLabel={
          <Box mr={0.5}>
            <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
          </Box>
        }
        popperContent={
          <Paper className={classes.root}>
            <Box mb={1}>
              <Typography variant="h5">{t`Backcast subscription`}</Typography>

              <Typography>
                {t`You are currently using enercast YAS with a purchased package of unique sites.`}
              </Typography>
            </Box>
            <Typography>{t`Sites used:`}</Typography>
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small" aria-label="used coordinate table">
                <TableHead>
                  <TableRow>
                    <ReTableCell height={table.rowHeight} align="right">{t`Latitude`}</ReTableCell>
                    <ReTableCell height={table.rowHeight} align="right">{t`Longitude`}</ReTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {coordinatesUsed.map((coordinate) => (
                    <TableRow key={coordinate.timestamp}>
                      <ReTableCell align="right">{coordinate.latitude}</ReTableCell>
                      <ReTableCell align="right">{coordinate.longitude}</ReTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        }
      />
    ) : (
      <></>
    )
  }

  const remainingCoordinatesCountComponent = <Number showFractionalPart={false} data={remainingCoordinates || 0} />
  const newCoordinatesCountComponent = <Number showFractionalPart={false} data={newCoordinates.length || 0} />

  return (
    <Box width="24em">
      {usedBackCastCoordinatesResult.isSuccess && (
        <Box display="flex" flexDirection="row">
          {newCoordinates.length > 0 && coordinatesUsed.length >= backCastCoordinateLimit ? (
            <Box display="flex" flexDirection="row">
              {getInfoToolTip()}
              <div>
                {jt`You have used all of your quota of ${backCastCoordinateLimit} unique sites. Please contact ${LinkToContactSales} to upgrade your subscription.`}
              </div>
            </Box>
          ) : (
            <>
              {newCoordinates.length > 0 ? (
                <Box display="flex" flexDirection="row">
                  {getInfoToolTip()}
                  <div>
                    {newCoordinates.length > 1 ? (
                      <>{jt`Clicking SUBMIT will deduct ${newCoordinatesCountComponent} site from your remaining quota of ${remainingCoordinatesCountComponent} unique sites.`}</>
                    ) : (
                      <>{jt`Clicking SUBMIT will deduct ${newCoordinatesCountComponent} sites from your remaining quota of ${remainingCoordinatesCountComponent} unique sites.`}</>
                    )}
                  </div>
                </Box>
              ) : (
                ngettext(
                  msgid`Free backcast: This request does not affect your remaining quota as a backcast has previously been created for this site.`,
                  `Free backcast: This request does not affect your remaining quota as a backcast has previously been created for this sites.`,
                  assetsToCreateBackcast.length,
                )
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  )
}

export default BackCastCoordinatesInfo
