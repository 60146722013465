import {
  addHours,
  addMinutes,
  addMonths,
  addSeconds,
  differenceInHours,
  differenceInMinutes,
  endOfMonth,
  getTime,
  isAfter,
  isBefore,
  isWithinInterval,
  max,
  roundToNearestMinutes,
  subMonths,
  subSeconds,
} from 'date-fns'

import {
  DataStreamSelectionItem,
  TimeSeries,
  TimeSeriesClassifier,
  TimeSeriesResult,
  TimeSeriesSeriesType,
  TimeSeriesType,
  TimeSeriesValueType,
} from 'modules/dataStreams/dataStreams.types'
import { ChartAggregationMode, EquidistantTimeSeries } from 'modules/workspace/store/workspace.types'
import { sortObjectByKeys } from 'utils/array'
import { createSeriesDataWithCustomInterval, detectTimeSeriesInterval, findNearestMatchingInterval } from 'utils/chart'
import { isNumeric } from 'utils/dataFormatting'
import {
  convertLocalDateToUTC,
  convertUTCToLocalDate,
  DATE_FORMAT_INTERNAL_LONG,
  DateRange,
  formatDate,
  isSameDate,
} from 'utils/date'
import { normalizeWeights } from 'utils/math'
import { aggregateValuesForUnit } from 'utils/units'
import { SeriesAreaOptions, SeriesArearangeOptions, SeriesLineOptions } from 'highcharts/highstock'
import { c } from 'ttag'
import { Timezone } from 'fixtures/timezones'

export type SimplePoint = [number, number | null]
export type SimpleRange = [number, number | null, number | null]

interface GetTimeSeriesSeriesType {
  type: TimeSeriesType
}
export const getTimeSeriesSeriesType = ({ type }: GetTimeSeriesSeriesType) => {
  switch (type) {
    case TimeSeriesType.SITE_FORECAST:
    case TimeSeriesType.SCHEDULE:
      return TimeSeriesSeriesType.ENSEMBLE
    case TimeSeriesType.METER_DATA:
    case TimeSeriesType.CAPACITY_DATA:
      return TimeSeriesSeriesType.POWER
  }
}

interface GetTimeSeriesSeriesType {
  type: TimeSeriesType
}
export const getTimeSeriesValueType = ({ type }: GetTimeSeriesSeriesType) => {
  switch (type) {
    case TimeSeriesType.SITE_FORECAST:
    case TimeSeriesType.SCHEDULE:
    case TimeSeriesType.METER_DATA:
      return TimeSeriesValueType.ABSOLUTE_POWER
    case TimeSeriesType.CAPACITY_DATA:
      return TimeSeriesValueType.MAX_POWER
  }
}

export enum EnrichTimeseriesFillMode {
  'NONE' = 'NONE', // ignore data holes completely and do not fill anything
  'EQUiDISTANT' = 'EQUiDISTANT', // detect equidistant interval and handle holes according to `fillContent` option
}
export enum EnrichTimeseriesFillContent {
  'HOLE_CENTER_NULL' = 'HOLE_CENTER_NULL', // don't fill all values within, only add null to the first and last timestamp for each hole (useful for all equidistant timeseries that can have holes)
  'INTERVAL_NULL' = 'INTERVAL_NULL', // fill all values within each hole with nulls
  'INTERVAL_PREVIOUS' = 'INTERVAL_PREVIOUS', // fill all values within each hole with previous value (e.g. for capacity)
}
export interface EnrichTimeseriesOptions {
  fillMode: EnrichTimeseriesFillMode
  fillContent?: EnrichTimeseriesFillContent
  fillIntervalMinutes?: number
}
export const enrichTimeseriesAfterLoad = (
  timeseries: TimeSeries,
  range?: DateRange,
  options?: EnrichTimeseriesOptions,
  dataStream?: DataStreamSelectionItem,
  timezone?: Timezone,
): TimeSeries => {
  const seriesData = Object.keys(timeseries).map<SimplePoint>((time) => [new Date(time).getTime(), timeseries[time]])
  const fillMode = options?.fillMode || EnrichTimeseriesFillMode.NONE
  const fillContent = options?.fillContent
  const fillIntervalMinutes = options?.fillIntervalMinutes || detectTimeSeriesInterval(seriesData) / 1000 / 60
  const marketPriceDataWithFillInterval60Min =
    dataStream?.type === TimeSeriesType.MARKET_PRICE_DATA && fillIntervalMinutes === 60

  if (fillMode !== EnrichTimeseriesFillMode.NONE && isNotMonthlyMarketPriceTimeseries(dataStream?.classifier)) {
    // add start and end times explicitly so that whole range is used
    // add detected interval in minutes to the start to avoid empty timeseries in the beginning of the chart
    if (range && fillIntervalMinutes) {
      const start = formatDate(
        addMinutes(new Date(range[0]).setSeconds(0), fillIntervalMinutes),
        null,
        DATE_FORMAT_INTERNAL_LONG,
      )
      const end = formatDate(new Date(range[1]).setSeconds(0), null, DATE_FORMAT_INTERNAL_LONG)

      // Fix only for PR-10224
      if (!marketPriceDataWithFillInterval60Min) {
        timeseries[start] = isNumeric(timeseries[start]) ? timeseries[start] : null
      }
      timeseries[end] = isNumeric(timeseries[end]) ? timeseries[end] : null
    }

    const times = Object.keys(timeseries)

    for (let index = 0; index < times.length - 1; index++) {
      const fromTime = new Date(times[index]).getTime()
      const toTime = new Date(times[index + 1]).getTime()

      if (fillContent === EnrichTimeseriesFillContent.HOLE_CENTER_NULL) {
        const holeSize = Math.round(toTime - fromTime)
        const fromTimeValue = timeseries[times[index]]
        const toTimeValue = timeseries[times[index + 1]]

        // isNumeric in the below condition, prevents creating null between null and value
        if (holeSize > 1000 * 60 * fillIntervalMinutes * 2 && isNumeric(fromTimeValue) && isNumeric(toTimeValue)) {
          // add exactly null value in hole (regardless of its size)
          // so that highcharts doesn't connect gaps with lines
          const formattedTime = formatDate(addMinutes(fromTime, fillIntervalMinutes), 'UTC', DATE_FORMAT_INTERNAL_LONG)

          timeseries[formattedTime] = timeseries[formattedTime] ? timeseries[formattedTime] : null
        }
      } else {
        const value = timeseries[times[index]]

        let fromTimeRounded = roundToNearestMinutes(fromTime + 1000 * 60 * fillIntervalMinutes, {
          nearestTo: 30,
        }).getTime()
        fromTimeRounded = fromTimeRounded > fromTime ? fromTimeRounded : fromTimeRounded + 30

        if (fromTimeRounded < toTime) {
          for (let time = fromTimeRounded; time < toTime; time += 1000 * 60 * fillIntervalMinutes) {
            const formattedTime = formatDate(time, 'UTC', DATE_FORMAT_INTERNAL_LONG)
            if (time > fromTime) {
              if (fillContent === EnrichTimeseriesFillContent.INTERVAL_PREVIOUS) {
                // fill each hole with equidistant duplicates of last value
                timeseries[formattedTime] = value
              } else if (fillContent === EnrichTimeseriesFillContent.INTERVAL_NULL) {
                // fill each hole with equidistant null values
                timeseries[formattedTime] = null
              }
            }
          }
        }
      }
    }
  }

  // Basically Market price has one value for every beginning of month so it should be handled differently
  if (
    (isMarktwertTimeSeries(dataStream?.classifier || '') ||
      isFranceM0TimeSeries(dataStream?.classifier || '') ||
      isAustriaMarketTimeSeries(dataStream?.classifier)) &&
    timezone &&
    range?.length
  ) {
    const timeseriesAfterTrim = { ...timeseries }

    const timeseriesDates = Object.keys(timeseries).sort()

    const timeseriesFirstDateKey = timeseriesDates[0]

    // Maybe used later
    // Ensure marketPriceDataWithFillInterval60Min is defined and truthy
    // Fix only for PR-10224
    // if (marketPriceDataWithFillInterval60Min) {
    // const timeSeriesSecondDateKey = timeseriesDates[1]
    // const timeSeriesThirdDateKey = timeseriesDates[2]
    //
    // // Retrieve values for the second and third dates
    // const timeseriesSecondValue = timeseries[timeSeriesSecondDateKey]
    // const timeseriesThirdValue = timeseries[timeSeriesThirdDateKey]
    //
    // // Check if the second value is null and replace it with the third value if necessary
    // // timeseriesSecondValue is null , when fillInterval is 60 and timezone is hour + 45 , or + 30 (example Asia/Kolkata)
    // // And is coming from above logic , line 121 timeseries[start] = isNumeric(timeseries[start]) ? timeseries[start] : null
    // if (timeseriesSecondValue === null) {
    //   timeseriesAfterTrim[timeSeriesSecondDateKey] = timeseriesThirdValue
    // }

    // Sort the object by keys before returning it
    // return sortObjectByKeys(timeseriesAfterTrim)
    // }

    const timeseriesLastDateKey = timeseriesDates[timeseriesDates.length - 1]
    const timeseriesLastDatePlusMonthInUTC = convertLocalDateToUTC(addMonths(new Date(timeseriesLastDateKey), 1))

    const timeseriesFirstValue = timeseries[timeseriesFirstDateKey]
    const timeseriesLastValue = timeseries[timeseriesLastDateKey]

    const rangeStartDate = range[0]
    const rangeEndDate = range[1]
    const rangeStartDateInTimeSeriesKeyFormat = formatDate(
      subSeconds(rangeStartDate as Date, 1),
      null,
      DATE_FORMAT_INTERNAL_LONG,
    )
    const rangeEndDateInTimeSeriesKeyFormat = formatDate(rangeEndDate as Date, null, DATE_FORMAT_INTERNAL_LONG)

    const currentMonthDate = new Date()
    const previousMonthDate = subMonths(currentMonthDate, 1)

    const currentMonthEndDateInUTC = convertLocalDateToUTC(addSeconds(endOfMonth(currentMonthDate), 1))
    const previousMonthEndDateInUTC = convertLocalDateToUTC(addSeconds(endOfMonth(previousMonthDate), 1))

    /**
     * ADDING VALUE FOR LAST TIMESTAMP
     * Timeseries should always finish with the end date from selected time range
     * NOTE: Only if the end date from selected time range is less than previous month end date
     * because we get the current month data on the end of previous month
     * */
    if (isBefore(rangeEndDate, previousMonthEndDateInUTC) || isSameDate(rangeEndDate, previousMonthEndDateInUTC)) {
      timeseriesAfterTrim[rangeEndDateInTimeSeriesKeyFormat] = timeseriesLastValue // Adding value for last timestamp
    } else if (isBefore(timeseriesLastDatePlusMonthInUTC, currentMonthEndDateInUTC)) {
      /**
       * ADDING VALUES BETWEEN TIMESERIES LAST TIMESTAMP AND END OF SELECTED TIME RANGE
       * Timeseries should have nulls for current month and future months
       * */
      const timeseriesLastDatePlusMonthInUTCKeyFormat = formatDate(
        timeseriesLastDatePlusMonthInUTC,
        null,
        DATE_FORMAT_INTERNAL_LONG,
      )
      timeseriesAfterTrim[timeseriesLastDatePlusMonthInUTCKeyFormat] = timeseriesLastValue // Always add an extra end value
      timeseriesAfterTrim[rangeEndDateInTimeSeriesKeyFormat] = null //  Adding null for last timestamp
    }

    /**
     * UPDATE THE FIRST TIMESTAMP KEY IF IT IS NOT BEGINNING FROM START OF MONTH
     * If the first timestamp key is not at beginning of the month then timeseries should start from the selected date
     * */
    if (rangeStartDateInTimeSeriesKeyFormat !== timeseriesFirstDateKey) {
      timeseriesAfterTrim[rangeStartDateInTimeSeriesKeyFormat] = timeseriesFirstValue
      delete timeseriesAfterTrim[timeseriesFirstDateKey]
    }

    // console.log({ timeseries, timeseriesAfterTrim: sortObjectByKeys(timeseriesAfterTrim) })

    return sortObjectByKeys(timeseriesAfterTrim)
  }

  return sortObjectByKeys(timeseries)
}

interface ConvertTimeseries {
  (timeseries: TimeSeries, dataStream: DataStreamSelectionItem): TimeSeries
}
export const convertWattTimeseries: ConvertTimeseries = (timeseries, dataStream) => {
  const convertedTimeseries: TimeSeries = {}

  Object.keys(timeseries || {}).forEach((key) => {
    const convertedValue = timeseries[key] !== null ? timeseries[key] / 1000 : null // conversion from Watt to Kilowatt
    convertedTimeseries[key] = convertedValue
  })

  if (dataStream && dataStream.type === TimeSeriesType.AREA_FORECAST) {
    Object.keys(timeseries || {}).forEach((key) => {
      const convertedValue = convertedTimeseries[key] !== null ? convertedTimeseries[key] / 1000 : null // conversion from Kilowatt to Megawatt
      convertedTimeseries[key] = convertedValue
    })
  }

  return convertedTimeseries
}

type WeightedAverage = { key: string; value: number; weight: number }[]
type WeightedAverageData = Record<string, WeightedAverage>

interface AggregateTimeSeriesParams {
  timeSeriesResults: TimeSeriesResult[]
  chartAggregationMode: ChartAggregationMode | null
  aggregationAverageWeights?: Partial<Record<string, number>>
  selectedKeys?: string[]
}
export const aggregateTimeSeries = ({
  timeSeriesResults,
  chartAggregationMode,
  aggregationAverageWeights,
  selectedKeys = [],
}: AggregateTimeSeriesParams): TimeSeries => {
  const combinedTimeseries: TimeSeries = {}
  const averageCount: Record<string, number> = {}
  const weightedAverageData: WeightedAverageData = {}

  timeSeriesResults.forEach((timeSeriesResult) => {
    const timeSeries = timeSeriesResult.data || {}
    // TODO empty string makes little sense here
    const dataStreamId = timeSeriesResult.dataStreamId || ''
    Object.keys(timeSeries).forEach((timestamp) => {
      const value = timeSeries[timestamp]
      const combinedValue = combinedTimeseries[timestamp]
      if (isNumeric(combinedValue)) {
        switch (chartAggregationMode) {
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_AVG:
            // cumulative average
            //  inspired by chapter 5 of https://jrsinclair.com/articles/2019/five-ways-to-average-with-js-reduce/
            combinedTimeseries[timestamp] =
              ((value || 0) + averageCount[timestamp] * (combinedValue || 0)) / (averageCount[timestamp] + 1)
            averageCount[timestamp]++
            break
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_WEIGHTED_AVG:
            // Currently used for calculating Meta forecast
            // add weight information
            weightedAverageData[timestamp].push({
              key: dataStreamId,
              value: value || 0,
              weight: aggregationAverageWeights?.[dataStreamId] || 1 / selectedKeys.length,
            })
            // [{key:cmc, value:23365.23, weight:50}, {key:cmc, value:23365.23, weight:100}, ...]

            // normalize weights
            const weights = weightedAverageData[timestamp].reduce(
              (result, data) => ({ ...result, [data.key]: data.weight }),
              {},
            )
            // [ {'cmc':100},{'ABC':50}] = 150

            const normalizedWeights = normalizeWeights(weights) || {}

            // [{cmc:0.7}, {'ABC':0.3}] = 1.0

            const normalizedWeightedAverageData = Object.keys(normalizedWeights).reduce<WeightedAverage>(
              (result, key) => {
                const value = weightedAverageData[timestamp].find((d) => d.key === key)?.value || 0
                return [
                  ...result,
                  {
                    key,
                    value,
                    weight: normalizedWeights[key] || selectedKeys.length,
                  },
                ]
              },
              [],
            )
            // [{key:cmc, value:23365.23, weight:0.7}, {key:ABC, value:23365.23, weight:0.3}, ...]

            // compute weighted average
            combinedTimeseries[timestamp] = normalizedWeightedAverageData.reduce(
              (result, { value, weight }) => result + value * weight,
              0,
            )
            break
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MIN:
            combinedTimeseries[timestamp] =
              isNumeric(combinedValue) && isNumeric(value)
                ? Math.min(combinedValue, value)
                : combinedTimeseries[timestamp]
            break
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MAX:
            combinedTimeseries[timestamp] =
              isNumeric(combinedValue) && isNumeric(value)
                ? Math.max(combinedValue, value)
                : combinedTimeseries[timestamp]
            break
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_SUM:
          default:
            combinedTimeseries[timestamp] = (combinedValue || 0) + (value || 0)
            break
        }
      } else {
        switch (chartAggregationMode) {
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_AVG:
            averageCount[timestamp] = 1
            break
          case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_WEIGHTED_AVG:
            weightedAverageData[timestamp] = [
              {
                key: dataStreamId,
                value: value || 0,
                weight: aggregationAverageWeights?.[dataStreamId] || 1 / selectedKeys.length,
              },
            ]
            break
        }
        combinedTimeseries[timestamp] = value
      }
    })
  })

  return sortObjectByKeys(combinedTimeseries)
}

interface MergeTimeSeriesResults {
  timeSeriesResults: TimeSeriesResult[]
  chartAggregationMode: ChartAggregationMode
}
export const mergeAndAggregateTimeSeriesResults = ({
  timeSeriesResults,
  chartAggregationMode,
}: MergeTimeSeriesResults): TimeSeriesResult => {
  const timeSeriesResultsWithData = timeSeriesResults.filter((timeSeriesResult) => {
    return typeof timeSeriesResult.data !== 'undefined'
  })

  const aggregatedTimeSeriesSet = aggregateTimeSeries({
    timeSeriesResults: timeSeriesResultsWithData,
    chartAggregationMode,
  })

  const initialResult = {
    ...timeSeriesResults[0],
  }

  // instead of one result for each asset, we have a single result for all assets with their aggregated values
  const mergedResult = timeSeriesResults.reduce((merged, timeSeriesResult) => {
    return {
      ...merged,
      assetIds: (merged.assetIds || []).concat(timeSeriesResult.assetId ? [timeSeriesResult.assetId] : []),
    }
  }, initialResult)

  return {
    ...mergedResult,
    data: aggregatedTimeSeriesSet,
    assetId: undefined,
    aggregation: chartAggregationMode,
  }
}

interface ConvertToEquidistantTimeseriesOptions {
  timeseries: TimeSeries
  fill?: boolean
  range?: DateRange
}
export const convertToEquidistantTimeseries = ({
  timeseries,
  fill = false,
  range,
}: ConvertToEquidistantTimeseriesOptions): EquidistantTimeSeries => {
  // in fill mode we want to find the latest value before start of range to avoid null values
  let fillValueStart: number | null | undefined = undefined
  if (fill && range) {
    Object.keys(timeseries || {}).forEach((t) => {
      const time = new Date(t)
      const formattedTime = formatDate(time, 'UTC', DATE_FORMAT_INTERNAL_LONG)
      const value = timeseries[formattedTime]
      if (isBefore(time, range[0]) && isNumeric(value)) {
        fillValueStart = value
      }
    })
  }

  // find earliest/latest dates and smallest resolution
  let resolutionMinutes = 60
  let earliest: Date = new Date()
  let latest: Date = new Date()
  let lastTime: Date = new Date()
  Object.keys(timeseries || {})
    .filter((t) => {
      const time = new Date(t)
      return range ? isWithinInterval(time, { start: range[0], end: range[1] }) : true
    })
    .sort((a, b) => {
      const timeA = new Date(a)
      const timeB = new Date(b)
      return isBefore(timeA, timeB) ? -1 : 1
    })
    .forEach((t, index) => {
      const time = new Date(t)

      if (index === 0) {
        earliest = time
        latest = time
        lastTime = earliest
      } else {
        if (isBefore(time, earliest)) earliest = time
        if (isAfter(time, latest)) latest = time
        const res = Math.abs(differenceInMinutes(time, lastTime))
        if (res < resolutionMinutes) resolutionMinutes = res
        lastTime = time
      }
    })

  if (fill && range) {
    earliest = max([earliest, range[0]])
    latest = max([latest, range[1]])
  }

  // construct data array and identify missing values
  // TODO only works for left-sided time series for now
  const data: (number | null)[] = []
  let lastValue: number | undefined = fill && range && isNumeric(fillValueStart) ? fillValueStart : undefined
  for (let time = earliest.getTime(); time < latest.getTime(); time += resolutionMinutes * 60 * 1000) {
    const formattedTime = formatDate(time, 'UTC', DATE_FORMAT_INTERNAL_LONG)
    const value = (timeseries || {})[formattedTime]

    if (!isNumeric(lastValue) && isNumeric(value)) {
      lastValue = value as number
    }

    const emptyValue = fill && lastValue ? lastValue : null
    const dataValue = isNumeric(value) ? value : emptyValue
    data.push(dataValue)
  }

  const resolution = `PT${resolutionMinutes}M`
  const start = earliest
  const end = addMinutes(earliest, resolutionMinutes)

  const equidistantTS: EquidistantTimeSeries = {
    resolution,
    firstTimeKey: {
      start,
      end,
    },
    data,
  }

  return equidistantTS
}

// interface FillTimeseries {
//   (timeseries: TimeSeries, durationMs: number): TimeSeries
// }
// export const fillTimeseries: FillTimeseries = (timeseries, durationMs) => {
//   const times = Object.keys(timeseries)
//   if (times.length < 3) return timeseries
//
//   const first = new Date(times[0]).getTime()
//   const last = new Date(times[times.length - 1]).getTime()
//
//   const filledTimeseries: TimeSeries = { ...timeseries }
//   for (let time = first; time <= last - durationMs; time += durationMs) {
//     const formattedTime = formatDateInternalLong(time)
//     if (typeof filledTimeseries[formattedTime] === 'undefined') {
//       filledTimeseries[formattedTime] = null
//     }
//   }
//
//   return sortObjectByKeys(filledTimeseries)
// }

export enum CropSeriesTriggeredFrom {
  convertSeriesToCSVAndStartDownload = 'convertSeriesToCSVAndStartDownload',
}

export const cropSeries = (
  seriesSet: (SeriesArearangeOptions | SeriesLineOptions)[],
  range: DateRange,
  triggeredFrom?: string,
): (SeriesArearangeOptions | SeriesLineOptions)[] => {
  const start = new Date(range[0])
  const end = new Date(range[1])

  // In local time. Used to add last 15 min , which is missing from chart.
  if (triggeredFrom === 'convertSeriesToCSVAndStartDownload') {
    end.setDate(end.getDate() + 1)
  }

  // delete all times outside of range
  return seriesSet.map<SeriesArearangeOptions | SeriesLineOptions>((series) => {
    const isMarktWert = isMarktwertTimeSeries(series.custom?.datastreamClassifier)
    const isFranceM0 = isFranceM0TimeSeries(series.custom?.datastreamClassifier)
    const isAustriaTimeSeries = isAustriaMarketTimeSeries(series.custom?.datastreamClassifier)

    if ((isMarktWert || isFranceM0 || isAustriaTimeSeries) && series.data.length) {
      const seriesWithoutLast = [...series?.data].slice(0, -1) // Remove the last added value in the transformer

      return {
        ...series,
        data: seriesWithoutLast,
      }
    }
    return {
      ...series,
      data: (series.data || []).filter((d) => {
        let time
        if (d['x']) {
          time = new Date(d['x'])
        } else if (d[0]) {
          time = new Date(d[0])
        }

        const outOfBounds = isBefore(time, start) || isAfter(time, end)
        return !outOfBounds
      }),
    }
  })
}

export const normalizeSeriesToInterval = (
  seriesSet: (SeriesArearangeOptions | SeriesLineOptions)[],
  range: DateRange,
  intervalLength: number,
) => {
  const rangeStart = getTime(roundToNearestMinutes(range[0], { nearestTo: Math.min(30, intervalLength / 1000 / 60) }))
  const rangeEnd = getTime(roundToNearestMinutes(range[1], { nearestTo: Math.min(30, intervalLength / 1000 / 60) }))

  const maxInterval = seriesSet.reduce((max, series) => {
    return series.custom?.detectedInterval ? Math.max(max, Number(series.custom.detectedInterval)) : max
  }, 0)
  const rangeEndPlusInterval = rangeEnd + Math.max(Number(intervalLength), maxInterval)

  // prepare data for normalization
  const filledSeriesSetInRange = seriesSet.map((series) => {
    const filledData = [...(series.data || [])]

    // fill holes for series that have continuous values (like capacity)
    if (series.custom?.shouldBeFilled) {
      const times = filledData.map((d) => d[0])
      const firstTime = times[0] - Number(intervalLength)
      const lastTime = times[times.length - 1]

      let lastValue
      for (let time = firstTime; time <= rangeEndPlusInterval; time += intervalLength) {
        if (times.includes(time)) {
          // new value match for this exact time
          lastValue = filledData.find((d) => d[0] === time)[1]
        } else {
          if (time > lastTime) {
            // do not fill values after last item
            break
          } else if (lastValue) {
            // fill hole with last value
            filledData.push([time, lastValue])
          }
        }
      }
    }

    // remove data that is outside of requested range
    const filteredData = filledData.filter((item) => item && item[0] >= rangeStart && item[0] <= rangeEndPlusInterval)

    // sort by date ascending
    filteredData.sort((a, b) => {
      return isBefore(new Date(a[0]), new Date(b[0])) ? -1 : 1
    })

    return {
      ...series,
      data: filteredData,
    }
  })

  // normalize series to align with chosen interval
  const transformedSeriesSetInRange = filledSeriesSetInRange.map((series) => {
    const transformedData = []
    for (let time = rangeEndPlusInterval; time >= rangeStart; time -= intervalLength) {
      const filteredValues = series.data.filter(
        // we pick up all values that are inside the interval we are currently looking at
        (d) => {
          const isFromNowToIntervalEnd = d[0] >= time && d[0] < time + Number(series.custom?.detectedInterval)
          const isFromIntervalStartToNow = d[0] > time - intervalLength && d[0] <= time
          return !series.custom?.pointInterval || intervalLength > Number(series.custom.pointInterval)
            ? isFromIntervalStartToNow
            : isFromNowToIntervalEnd
        },
      )
      const value = filteredValues.length ? aggregateValuesForUnit(filteredValues, series.custom.unit) : null
      const valueArray = series.type === 'arearange' ? [value, value] : [value]
      transformedData.push([time, ...valueArray])
    }

    // sort by date ascending
    transformedData.sort((a, b) => {
      return isBefore(new Date(a[0]), new Date(b[0])) ? -1 : 1
    })

    return {
      ...series,
      data: transformedData,
    }
  })

  return transformedSeriesSetInRange
}

export const createTimeSeriesWithNullValues = (range: DateRange) => {
  const timeSeries = []
  if (range.length) {
    const diffInHours = differenceInHours(new Date(range[1]), new Date(range[0]))
    const initialDateTime = convertUTCToLocalDate(new Date(range[0]))
    for (let i = 0; i < diffInHours; i++) {
      const nextIntervalTime = addHours(initialDateTime, i)
      timeSeries.push([nextIntervalTime.getTime(), null])
    }
  }
  timeSeries.push([addSeconds(convertUTCToLocalDate(new Date(range[1])), 1).getTime(), null])
  return [{ name: '', data: timeSeries }]
}

type CreateForecastErrorTimeSeries = ({
  seriesMeterdata,
  seriesSiteForecasts,
}: {
  seriesMeterdata: SeriesArearangeOptions[]
  seriesSiteForecasts: SeriesArearangeOptions[]
}) => SeriesAreaOptions[]

export const createForecastErrorTimeSeries: CreateForecastErrorTimeSeries = ({
  seriesMeterdata,
  seriesSiteForecasts,
}) => {
  const errorSeries = seriesSiteForecasts.reduce<SeriesAreaOptions[]>((previous, siteForecastSeries) => {
    const meterdataSeries = seriesMeterdata.find((sm) => sm.custom.assetId === siteForecastSeries.custom.assetId)
    // const meterdataSeries = seriesMeterdata?.[0]
    if (!meterdataSeries) return previous

    const meterDataIntervalInMinutes = meterdataSeries?.custom?.detectedInterval / 1000 / 60
    const forecastDataIntervalInMinutes = siteForecastSeries?.custom?.detectedInterval / 1000 / 60
    // console.log({ meterdataSeries, siteForecastSeries, meterDataIntervalInMinutes, forecastDataIntervalInMinutes })
    const matchingInterval = findNearestMatchingInterval(forecastDataIntervalInMinutes, meterDataIntervalInMinutes)

    // calculate difference between forecast and reference data
    const meterdata = meterdataSeries.data as [number, number, number][]

    const siteForecastSeriesWithCustomInterval = createSeriesDataWithCustomInterval(
      siteForecastSeries?.data,
      forecastDataIntervalInMinutes,
      matchingInterval,
    )

    const meterDataSeriesWithCustomInterval = createSeriesDataWithCustomInterval(
      meterdata,
      meterDataIntervalInMinutes,
      matchingInterval,
    )

    // console.log({ seriesMeterdata, seriesSiteForecasts, matchingInterval })
    // console.log({ meterDataSeriesWithCustomInterval, siteForecastSeriesWithCustomInterval })

    const data = (siteForecastSeriesWithCustomInterval || []).reduce<[number, number, number][]>(
      (d, siteForecastTimeSeriesValue) => {
        const time = siteForecastTimeSeriesValue[0]
        const meterDataReference = (meterDataSeriesWithCustomInterval || []).find((m) => m?.[0] === time)

        if (
          meterDataReference &&
          isNumeric(meterDataReference[1]) &&
          isNumeric(meterDataReference[2]) &&
          isNumeric(siteForecastTimeSeriesValue[1]) &&
          isNumeric(siteForecastTimeSeriesValue[2])
        ) {
          const diffLow = siteForecastTimeSeriesValue[1] - meterDataReference[1]
          const diffHigh = siteForecastTimeSeriesValue[2] - meterDataReference[2]
          return [...d, [time, diffLow, diffHigh]]
        } else {
          if (time) {
            return [...d, [time, null, null]]
          }
          // we need this piece of code in case of not showing gaps
          return siteForecastTimeSeriesValue[0] === null && siteForecastTimeSeriesValue[1] === null
            ? [...d, [time, null, null]]
            : d
        }
      },
      [],
    )

    const color = siteForecastSeries.color
    const name = c('Workbench:Quality').t`Forecast Error` + ' - ' + siteForecastSeries.name
    const lineWidth = 1
    // const pointInterval = detectTimeSeriesInterval(data)

    if (data.length > 0) {
      const current = {
        color,
        connectNulls: false,
        data: data,
        // gapSize: 1, // WARNING: don't use gapSize because it might cause the series to disappear when zoomed in
        lineWidth,
        custom: {
          alignment: 'right',
          label: name,
          // assetId: asset?.id,
          // assetIds: assets.map((a) => a.id),
          // datastreamId: forecastId,
          // datastreamType: SITE_FORECAST,
        },
        name,
        pointStart: data.length > 0 && data[0][0] ? data[0][0] : undefined,
        // pointInterval,
        step: 'right', // seems to avoid random transformation of series in step charts
        type: 'arearange',
      }
      if (siteForecastSeries?.custom?.datastreamType === TimeSeriesType.BACK_CAST) {
        current['dashStyle'] = 'ShortDash'
      }
      return [...previous, current]
    } else {
      return previous
    }
  }, [])

  return errorSeries
}

/**
 *  !!! THIS FUNCTION IS USED ONLY FOR UI PURPOSE !!!
 * @param pointInTime
 * @param detectedInterval
 * @param isLastPoint
 * Description: Series from timeseries Api are coming with right sided value. Also in Step Chart we have right sided value.
 * For example: First segment in timeseries (with right sided value) is 00:00 - 00:15 (15 min resolution)  , we need to add an artificial segment 23:45 - 00:00 (with right sided value) , in order to draw an artificial line.
 */

export const addPointForArtificialSegment = (pointInTime: any, detectedInterval: number, isLastPoint?: boolean) => {
  // Point
  let artificialPoint: any = {
    marker: {
      states: {
        hover: { enabled: false },
      },
    },
    noToolTip: true,
  }

  if (isLastPoint) {
    artificialPoint = { x: pointInTime[0] + detectedInterval, low: null, high: null, ...artificialPoint }
    return artificialPoint
  }

  // Additional key for first point
  if (pointInTime[0] && detectedInterval) {
    artificialPoint = { x: pointInTime[0] - detectedInterval, ...artificialPoint }
  }

  if (isNumeric(pointInTime[1]) && detectedInterval) {
    artificialPoint = { low: pointInTime[1], ...artificialPoint }
  }

  if (isNumeric(pointInTime[2]) && detectedInterval) {
    artificialPoint = { high: pointInTime[2], ...artificialPoint }
  }

  return artificialPoint
}

/**
 * Formula for Day-Ahead and Intra-Day balancing cost
 * @param simulationValue
 * @param measuredValue
 * @param reBapValue
 * @description This is formula for calculation Day-Ahead balancing cost.
 */
const equationForBalancingCost = (simulationValue: number, measuredValue: number, reBapValue: number) => {
  // Formula
  return (simulationValue - measuredValue) * reBapValue
}

/**
 * Formula for Day-Ahead Revenue
 * @param simulationDayAheadValue
 * @param epexDayAheadValue
 * @description This is formula for calculation Day-Ahead Revenue
 */
const equationForDayAheadRevenue = (simulationDayAheadValue: number, epexDayAheadValue: number) => {
  // Formula
  return simulationDayAheadValue * epexDayAheadValue
}

/**
 * Formula for Day-Ahead & Intraday revenue
 * @param simulationDayAheadValue
 * @param epexDayAheadValue
 * @param simulationIntraDayValue
 * @param epexIntraDayValue
 * @description This is formula for calculation Day-Ahead Revenue
 */
const equationForDayAheadAndIntraDayRevenue = (
  simulationDayAheadValue: number,
  epexDayAheadValue: number,
  simulationIntraDayValue: number,
  epexIntraDayValue: number,
) => {
  // Formula
  return (
    simulationDayAheadValue * epexDayAheadValue +
    (simulationDayAheadValue - simulationIntraDayValue) * epexIntraDayValue
  )
}

interface calculateRevenueEquationsProps {
  equationType: TimeSeriesClassifier // Pass Germany revenue
  simulation?: TimeSeries
  secondSimulation?: TimeSeries
  measuredData?: TimeSeries
  reBapData?: TimeSeries
  epexSpotDayAhead?: TimeSeries
  epexSpotIntraDayContinuousAverage?: TimeSeries
  equationIntervalInMinutes: number
}

export const calculateRevenueEquations = ({
  equationType,
  simulation,
  secondSimulation,
  measuredData,
  reBapData,
  epexSpotDayAhead,
  epexSpotIntraDayContinuousAverage,
  equationIntervalInMinutes,
}: calculateRevenueEquationsProps) => {
  const {
    GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
    GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE,
  } = TimeSeriesClassifier
  // console.log('calculate')
  // 1Mw = 1000Kw
  const fifteenMinutesInMwConverter = 1000 * 4 // 1hour in 15min blocks will give 4 blocks
  const ThirtyMinutesInMwConverter = 1000 * 2 // 1hour in 30min blocks will give 2 blocks
  const OneHourInMwConverter = 1000 // 1hour in 60min blocks will give 1 block
  // Need to convert the meterdata forecast/backcast timeseries values (kwh) to price unit
  const baseConversionToMwh =
    equationIntervalInMinutes === 15
      ? fifteenMinutesInMwConverter
      : equationIntervalInMinutes === 30
      ? ThirtyMinutesInMwConverter
      : OneHourInMwConverter

  // first simulation is used for all euqation
  const simulationInMwh = Object.keys(simulation).reduce((prev, timeStamp) => {
    return {
      ...prev,
      [timeStamp]: isNumeric(simulation[timeStamp]) ? simulation[timeStamp] / baseConversionToMwh : null,
    }
  }, {})

  let revenueCalculation = {}

  // Day-Ahead balancing cost
  if (
    equationType === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST ||
    equationType === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST
  ) {
    const measuredDataInMwh = Object.keys(measuredData).reduce((prev, timeStamp) => {
      return {
        ...prev,
        [timeStamp]: isNumeric(measuredData[timeStamp]) ? measuredData[timeStamp] / baseConversionToMwh : null,
      }
    }, {})

    // Calculate the revenue  Day-Ahead and Intra day balancing cost = (Esimulation DA - Emeassured) * reBAP-Price
    const rebapTimeStamps = Object.keys(reBapData)
    revenueCalculation = rebapTimeStamps.reduce((prev, timestamp) => {
      return {
        ...prev,
        [timestamp]:
          isNumeric(simulationInMwh[timestamp]) &&
          isNumeric(measuredDataInMwh[timestamp]) &&
          isNumeric(reBapData[timestamp])
            ? equationForBalancingCost(simulationInMwh[timestamp], measuredDataInMwh[timestamp], reBapData[timestamp])
            : null,
      }
    }, {})
  } else if (equationType === GERMANY_REVENUE_DAY_AHEAD_REVENUE) {
    // Calculate the revenue  Day-Ahead and Intra day balancing cost = Esimulation DA * EPEX-SPOT-Day-Ahead-Price
    const epexSpotDayAheadTimeStamps = Object.keys(epexSpotDayAhead)
    revenueCalculation = epexSpotDayAheadTimeStamps.reduce((prev, timestamp) => {
      return {
        ...prev,
        [timestamp]:
          isNumeric(simulationInMwh[timestamp]) && isNumeric(epexSpotDayAhead[timestamp])
            ? equationForDayAheadRevenue(simulationInMwh[timestamp], epexSpotDayAhead[timestamp])
            : null,
      }
    }, {})
  } else if (equationType === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE) {
    const secondSimulationInMwh = Object.keys(secondSimulation).reduce((prev, timeStamp) => {
      return {
        ...prev,
        [timeStamp]: isNumeric(secondSimulation[timeStamp]) ? secondSimulation[timeStamp] / baseConversionToMwh : null,
      }
    }, {})

    const epexSpotDayAheadTimeStamps = Object.keys(epexSpotDayAhead)

    revenueCalculation = epexSpotDayAheadTimeStamps.reduce((prev, timestamp) => {
      return {
        ...prev,
        [timestamp]:
          isNumeric(simulationInMwh[timestamp]) &&
          isNumeric(secondSimulationInMwh[timestamp]) &&
          isNumeric(epexSpotDayAhead[timestamp]) &&
          isNumeric(epexSpotIntraDayContinuousAverage[timestamp])
            ? equationForDayAheadAndIntraDayRevenue(
                simulationInMwh[timestamp],
                epexSpotDayAhead[timestamp],
                secondSimulationInMwh[timestamp],
                epexSpotIntraDayContinuousAverage[timestamp],
              )
            : null,
      }
    }, {})
  }

  return revenueCalculation
}

export const isMarktwertTimeSeries = (classifier: TimeSeriesClassifier) => {
  return (
    classifier === TimeSeriesClassifier.GERMANY_MARKTWERT_SOLAR ||
    classifier === TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_ON_SHORE ||
    classifier === TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_OFF_SHORE
  )
}

export const isFranceM0TimeSeries = (classifier: TimeSeriesClassifier) => {
  return classifier === TimeSeriesClassifier.FRANCE_M0_SOLAR || classifier === TimeSeriesClassifier.FRANCE_M0_WIND
}

export const isAustriaMarketTimeSeries = (classifier: TimeSeriesClassifier) => {
  return (
    classifier === TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_SOLAR ||
    classifier === TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_WIND
  )
}

// Excluding all monthly market price timeseries
export const isNotMonthlyMarketPriceTimeseries = (classifier: TimeSeriesClassifier) => {
  return (
    classifier !== TimeSeriesClassifier.GERMANY_MARKTWERT_SOLAR &&
    classifier !== TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_ON_SHORE &&
    classifier !== TimeSeriesClassifier.GERMANY_MARKTWERT_WIND_OFF_SHORE &&
    classifier !== TimeSeriesClassifier.FRANCE_M0_SOLAR &&
    classifier !== TimeSeriesClassifier.FRANCE_M0_WIND &&
    classifier !== TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_SOLAR &&
    classifier !== TimeSeriesClassifier.AUSTRIA_REFERENZ_MARKTWERT_WIND
  )
}
