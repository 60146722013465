import styled from 'styled-components'

interface SpacerProps {
  minWidth?: string
}

const Spacer = styled.div<SpacerProps>`
  flex-grow: 1;
  min-width: ${(props) => props.minWidth || 'auto'};
`

export default Spacer
