import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Modal, Typography } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import FileUploadHelpPopover from 'modules/data/fileManager/FileUploadHelpPopover'

import { uploadMeterdataFilesLoadingSelector } from 'modules/data/fileManager/redux_store/state/uploadMeterdataFiles.state'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { msgid, ngettext, t } from 'ttag'
import FileUploadPreview from 'ui/FileUploadPreview'
import FileUploadZone, { FileUploadHandler } from 'ui/FileUploadZone'
import LoadingButton from 'ui/form/LoadingButton'
import Flex from 'ui/styles/Flex'
import { FILE_SIZE, FileData, FileError, formatBytes } from 'utils/file'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      top: '4%',
      margin: '0 auto',
      width: '95%',
      maxWidth: '46em',
      maxHeight: '92vh',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 0,
    },
    button: {
      margin: theme.spacing(1),
    },
    input: {
      display: 'none',
    },
  }),
)

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
`

const GroupedButton = styled(Button)`
  margin-left: ${theme.spacing(1)}px !important;
`

const GroupedLoadingButton = styled(LoadingButton)`
  margin-left: ${theme.spacing(1)}px !important;
`

interface UploadModalProps {
  files: FileData[]
  errors: FileError[]
  showModal: boolean
  onFileSelect: FileUploadHandler
  onRemove: (file: FileData, index: number) => void
  onSubmit: () => void
  onClose: () => void
  onResetErrors: () => void
}

const UploadModal: React.FC<UploadModalProps> = ({
  files,
  errors,
  showModal,
  onFileSelect,
  onRemove,
  onSubmit,
  onClose,
  onResetErrors,
}) => {
  const classes = useStyles()

  const loading = useSelector(uploadMeterdataFilesLoadingSelector)

  const maxFileSize = useMemo(() => formatBytes(FILE_SIZE.MAX), [])
  const fileSizeTooLarge = useMemo(() => files.some((data) => data.file.size > FILE_SIZE.MAX), [files])

  return (
    <Modal
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
      open={showModal}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div className={classes.paper}>
        <Flex justifyContent="space-between">
          <Typography id="upload-modal-title" variant="h1" color="primary">
            {t`Upload Files`}
          </Typography>
          <FileUploadHelpPopover showHelpTextBtn={true} />
        </Flex>

        <Box mt={1}>
          <Typography variant="body1" gutterBottom={true}>
            {t`Select one or more files to upload. File size limit: ${maxFileSize}.`}
          </Typography>
        </Box>

        <Box mt={3} mb={2}>
          <FileUploadZone
            label={t`Drag and drop CSV files here, or select them from your computer`}
            accept=".csv, text/csv, text/plain, application/vnd.ms-excel"
            onFileSelect={onFileSelect}
            onResetErrors={onResetErrors}
          />
        </Box>

        <FileUploadPreview fileDatas={files} fileErrors={errors} onRemove={onRemove} />

        <ModalActions>
          <GroupedButton
            variant="contained"
            color="default"
            // disabled={loading}
            onClick={onClose}
            data-testid="upload-modal-form-button-submit"
          >
            {t`Cancel`}
          </GroupedButton>

          {files.length > 0 && (
            <GroupedLoadingButton
              loading={loading}
              variant="contained"
              color="primary"
              startIcon={<FontAwesomeIcon icon="upload" />}
              onClick={onSubmit}
              data-testid="upload-modal-form-button-submit"
              disabled={fileSizeTooLarge}
            >
              {ngettext(msgid`Upload File`, `Upload Files`, files.length)}
            </GroupedLoadingButton>
          )}
        </ModalActions>
      </div>
    </Modal>
  )
}

export default React.memo(UploadModal)
