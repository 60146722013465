import React, { useCallback, useMemo } from 'react'
import { ChartDataRange, ChartDataRangeType } from 'modules/workspace/store/workspace.types'
import {
  addMilliseconds,
  addMonths,
  differenceInMilliseconds,
  differenceInMonths,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  lastDayOfMonth,
  subMilliseconds,
  subMonths,
} from 'date-fns'
import { useWorkspaceChartWholeDateRange } from 'utils/workspace'
import NextPreviousButtons from 'ui/elements/NextPreviousButtons'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { convertUTCToLocalDate, convertUtcToZonedTime } from 'utils/date'

interface WorkspaceRangeNavigatorButtonsProps {
  onRangeChange: (selectedDataRange: ChartDataRange) => void
}

const WorkspaceRangeNavigatorButtons: React.FC<WorkspaceRangeNavigatorButtonsProps> = ({ onRangeChange }) => {
  const timezone = useSelector(getUserTimezoneSelector)
  const dateRangeInUTC = useWorkspaceChartWholeDateRange()

  const dateRangeInUserTimezone = useMemo(
    () => [
      convertUtcToZonedTime(convertUTCToLocalDate(dateRangeInUTC[0]), timezone),
      convertUtcToZonedTime(convertUTCToLocalDate(dateRangeInUTC[1]), timezone),
    ],
    [dateRangeInUTC, timezone],
  )

  const handleGoToNextPrevRange = useCallback(
    (nextRange: boolean) => {
      let startDate = dateRangeInUserTimezone[0]
      let endDate = dateRangeInUserTimezone[1]

      if (isFirstDayOfMonth(dateRangeInUserTimezone[0]) && isLastDayOfMonth(dateRangeInUserTimezone[1])) {
        // differenceInMonths in always return the full months between two dates so we add one in the end
        const noOfMonths = differenceInMonths(dateRangeInUserTimezone[1], dateRangeInUserTimezone[0]) + 1

        startDate = nextRange ? addMonths(startDate, noOfMonths) : subMonths(startDate, noOfMonths)
        endDate = nextRange ? addMonths(endDate, noOfMonths) : subMonths(endDate, noOfMonths)
        endDate = lastDayOfMonth(endDate) // we need this because some months end dates are 28, 30 and 31
      } else {
        const milliseconds = differenceInMilliseconds(dateRangeInUserTimezone[1], dateRangeInUserTimezone[0]) + 1000

        startDate = nextRange
          ? addMilliseconds(dateRangeInUserTimezone[0], milliseconds)
          : subMilliseconds(dateRangeInUserTimezone[0], milliseconds)
        endDate = nextRange
          ? addMilliseconds(dateRangeInUserTimezone[1], milliseconds)
          : subMilliseconds(dateRangeInUserTimezone[1], milliseconds)
      }

      const data: ChartDataRange = {
        rangeType: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM,
        customRange: [startDate, endDate],
      }

      onRangeChange(data)
    },
    [dateRangeInUserTimezone, onRangeChange, timezone],
  )
  return (
    <>
      <NextPreviousButtons showNextPrevOptions={true} onNextPrevItem={handleGoToNextPrevRange} totalItems={4} />
    </>
  )
}

export default WorkspaceRangeNavigatorButtons
