import React, { useEffect, useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'
import { c, t } from 'ttag'
import { AssetFormLabel, FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { Field } from 'react-final-form'
import { Checkbox } from 'final-form-material-ui'
import { CheckboxLabel } from 'ui/form/authForm.style'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import MultipleEmailsInput from 'modules/delivery/deliveryTargets/MultipleEmailsInput'
import { FormApi } from 'final-form'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isImpersonatedAdmin, isImpersonatedPartner } from 'utils/user'
import { EmailAddress } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { useCustomMuiStyles } from 'themes/theme-light'

const CheckboxControl = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    padding-top: 6px;
  }
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }
`

const CheckBoxContainer = styled(Flex)`
  margin-left: 5px;
`

interface SectionEmailDeliveryTargetFormProps {
  form: FormApi
  isNew: boolean
}

const SectionEmailDeliveryTargetForm: React.FC<SectionEmailDeliveryTargetFormProps> = ({ form, isNew }) => {
  //Style
  const classes = useCustomMuiStyles({ important: true })

  const user = useSelector(getUserResultSelector)

  const userHasAccessToMultipleEmails = useMemo(
    () => isAdmin(user) || isImpersonatedAdmin(user) || isImpersonatedPartner(user),
    [user],
  )

  useEffect(() => {
    if (isNew) {
      // Prepared Array which is related to e-mail of Recipients
      const defaultInitialToAddress: EmailAddress[] = [
        {
          address: user?.email,
          valid: null,
        },
      ]
      form.mutators.setValue('mailConfiguration.toAddresses', defaultInitialToAddress)
    }
  }, [isNew])

  return (
    <Flex direction="column">
      <Box mt={0.4} mb={0.4}>{c('Delivery:Target')
        .t`The forecasts will be sent as e-mail attachment to the specified e-mail addresses.`}</Box>

      <CheckBoxContainer direction="row" alignItems="center" justifyContent="flex-start">
        <CheckboxControl
          label={''}
          control={<Field name="mailConfiguration.multiFile" type="checkbox" component={Checkbox} />}
        />
        <CheckboxLabel>{c('Delivery:Target').t`Combine all attachments in one email`}</CheckboxLabel>
      </CheckBoxContainer>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Recipients`}:</AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <Flex>
            <Field name="mailConfiguration.toAddresses">
              {({ input, meta }) => {
                return (
                  <MultipleEmailsInput
                    fieldErrorMsg={form.getState().submitFailed && meta.error ? meta.error : ''}
                    initialEmails={input.value}
                    onChange={input.onChange}
                    addMultiple={userHasAccessToMultipleEmails}
                  />
                )
              }}
            </Field>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {user && userHasAccessToMultipleEmails && (
        <>
          <FormKeyValueGrid>
            <FormKey className={isImpersonatedPartner(user) ? 'inherit' : classes.adminComponent}>
              <AssetFormLabel>{t`Carbon copies (CC)`}:</AssetFormLabel>
            </FormKey>
            <FormValue isAdminComponent={!isImpersonatedPartner(user)}>
              <Flex>
                <Field name="mailConfiguration.ccAddresses">
                  {({ input }) => (
                    <MultipleEmailsInput
                      addMultiple={userHasAccessToMultipleEmails}
                      initialEmails={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>

          <FormKeyValueGrid>
            <FormKey className={isImpersonatedPartner(user) ? 'inherit' : classes.adminComponent}>
              <AssetFormLabel>{t`Blind copies (CC)`}:</AssetFormLabel>
            </FormKey>
            <FormValue isAdminComponent={!isImpersonatedPartner(user)}>
              <Flex>
                <Field name="mailConfiguration.bccAddresses">
                  {({ input }) => (
                    <MultipleEmailsInput
                      addMultiple={userHasAccessToMultipleEmails}
                      initialEmails={input.value}
                      onChange={input.onChange}
                    />
                  )}
                </Field>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>
        </>
      )}
    </Flex>
  )
}

export default React.memo(SectionEmailDeliveryTargetForm)
