import React from 'react'
import { IconSelectedColor, SvgIconPath } from 'ui/styles/SvgIconPath'
import { AssetIconProps } from 'ui/icons/ClusterPoolIcon'
import { getTypeLabel } from 'utils/asset'
import { UiClusterTypes } from 'modules/asset/store/asset.types'

const ClusterHybridIcon: React.FC<AssetIconProps> = ({ iconSelected, size }) => {
  return (
    <svg viewBox="15 -500 1400 1100" xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <title>{getTypeLabel(UiClusterTypes.CLUSTER_HYBRID)}</title>
      <g className="currentLayer">
        <g transform="translate(-100 620) scale(0.15, -0.15)">
          <SvgIconPath
            d="M2369,6589.571430206299 l-29,-29 l0,-154 c0,-171 5,-188 64,-208 c40,-13 77,-1 100,32 c13,18 16,51 16,178 c-1,162 -4,175 -47,198 c-37,20 -73,14 -104,-17 z"
            id="svg_2"
            iconColor={iconSelected ? IconSelectedColor : ''}
            fillOpacity="1"
            strokeWidth="100"
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M665,6096.571430206299 c-49,-58 -36,-82 128,-245 c132,-131 146,-143 180,-143 c54,0 92,36 92,89 c0,41 -5,47 -146,186 c-140,139 -147,145 -186,145 c-34,0 -46,-6 -68,-32 z"
            id="svg_3"
            iconColor={iconSelected ? IconSelectedColor : ''}
            fillOpacity="1"
            strokeWidth="100"
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M3943,5985.571430206299 c-139,-139 -143,-144 -143,-185 c0,-57 33,-92 87,-92 c35,0 47,10 185,148 c138,138 148,150 148,185 c0,54 -35,87 -92,87 c-41,0 -46,-4 -185,-143 z"
            id="svg_4"
            iconColor={iconSelected ? IconSelectedColor : ''}
            fillOpacity="1"
            strokeWidth="100"
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M2275,5882.571430206299 c-128,-18 -235,-41 -323,-70 c-527,-171 -911,-599 -1035,-1152 c-29,-133 -32,-481 -4,-612 c46,-217 75,-270 146,-270 c32,0 44,6 65,31 c31,37 32,58 7,132 c-43,123 -56,215 -56,397 c0,194 9,258 61,420 c65,203 163,366 316,526 c344,357 837,500 1312,380 c238,-61 446,-181 633,-365 c76,-75 85,-81 123,-81 c75,0 115,78 74,140 c-48,71 -192,196 -327,284 c-161,106 -364,187 -552,221 c-86,16 -376,28 -440,19 z"
            id="svg_5"
            iconColor={iconSelected ? IconSelectedColor : ''}
            fillOpacity="1"
            strokeWidth="100"
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M181,4402.571430206299 c-30,-38 -28,-94 5,-125 c25,-23 30,-24 184,-24 c154,0 159,1 184,24 c33,31 35,87 5,125 l-20,26 l-169,0 l-169,0 l-20,-26 z"
            id="svg_6"
            iconColor={iconSelected ? IconSelectedColor : ''}
            fillOpacity="1"
            strokeWidth="100"
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M1883,4406.571430206299 c-11,-13 -294,-516 -629,-1118 c-662,-1193 -639,-1146 -581,-1196 l28,-24 l1065,0 l1064,0 l0,-525 l0,-525 l-348,0 l-349,0 l-20,-22 c-27,-30 -34,-63 -21,-99 c21,-62 -39,-59 1167,-59 l1099,0 l31,26 c41,34 44,92 6,129 l-24,25 l-316,0 l-315,0 l0,525 l0,525 l1061,0 l1061,0 l29,29 c18,18 29,40 29,56 c0,29 -1204,2210 -1243,2253 l-20,22 l-1377,0 l-1377,0 l-20,-22 zm792,-180 c-3,-13 -30,-132 -59,-266 c-30,-134 -56,-246 -59,-248 c-2,-2 -195,-3 -429,-2 l-425,3 l95,170 c52,94 119,214 149,268 l54,97 l340,0 l339,0 l-5,-22 zm1076,-243 c32,-143 59,-263 59,-267 c0,-5 -239,-8 -531,-8 l-531,0 l5,23 c3,12 29,126 57,252 c28,127 53,238 56,248 c5,16 31,17 416,15 l411,-3 l58,-260 zm960,-5 l147,-265 l-426,-3 c-234,-1 -427,0 -429,2 c-3,2 -29,114 -59,248 c-29,134 -56,253 -59,266 l-5,22 l341,-2 l342,-3 l148,-265 zm-2202,-497 c-50,-228 -109,-494 -109,-498 c0,-3 -248,-5 -551,-5 c-331,0 -549,4 -547,9 c2,5 68,127 148,270 l145,261 l461,0 l462,0 l-9,-37 zm1404,-226 c31,-144 57,-265 57,-269 c0,-11 -1380,-10 -1380,0 c0,10 109,503 115,520 c3,9 128,12 577,12 l573,0 l58,-263 zm1200,-1 c81,-146 147,-267 147,-270 c0,-3 -247,-6 -550,-6 c-302,0 -550,2 -550,5 c0,4 -59,270 -109,498 l-9,37 l462,0 l462,0 l147,-264 zm-2774,-538 c-10,-46 -37,-170 -60,-275 l-42,-193 l-674,0 l-674,0 l38,68 c21,37 90,161 153,275 l115,207 l581,0 l582,0 l-19,-82 zm1737,-193 l61,-275 l-854,0 c-694,0 -853,2 -853,13 c0,14 107,502 115,525 c3,9 160,12 737,12 l733,0 l61,-275 zm1404,68 c63,-114 132,-238 153,-275 l38,-68 l-674,0 l-673,0 l-43,193 c-23,105 -50,229 -60,275 l-19,82 l582,0 l581,0 l115,-207 zm-1930,-1038 l0,-515 l-270,0 l-270,0 l0,515 l0,515 l270,0 l270,0 l0,-515 z"
            id="svg_7"
            iconColor={iconSelected ? IconSelectedColor : ''}
            fillOpacity="1"
            strokeWidth="100"
            strokeOpacity="1"
          />
        </g>
      </g>
      <g transform="translate(1000 0)">
        <SvgIconPath
          transform="rotate(-180 253 230)"
          d="M868.503 292.257c-31.855 7.828-74.728 20.627-125.817 37.27-9.551 3.111-19.331 6.339-29.315 9.671-36.654
      12.235-74.463 25.349-111.288 38.463-12.889 4.59-15.651 4.963-26.183 8.787v-28.543c2.719-0.905 8.393-2.894
      17.022-5.968 36.984-13.171 74.96-26.343 111.804-38.641 10.045-3.353 19.888-6.601 29.503-9.733 138.393-45.083
      195.304-59.069 205.885-35.573 10.394 23.080-38.46 56.319-171.036 133.732-7.75 4.525-15.693 9.132-23.817
      13.814-41.542 23.943-86.034 48.85-130.522 73.265-15.569 8.544-38.56 20.445-51.537 27.465v-33.096c15.254-6.527
      28.054-12.63 38.4-18.308 44.334-24.33 88.665-49.148 130.024-72.985 8.079-4.657 15.978-9.238 23.683-13.737
      49.148-28.698 89.156-53.317 117.421-72.548 11.302-7.689 20.592-14.431 27.698-20.107-8.625 1.403-19.332
      3.677-31.923 6.771zM154.881 292.257c31.855 7.828 74.728 20.627 125.817 37.27 9.551 3.111 19.331 6.339
      29.315 9.671 36.654 12.235 74.463 25.349 111.288 38.463 12.889 4.59 15.651 4.963 26.183
      8.787v-28.543c-2.719-0.905-8.393-2.894-17.022-5.968-36.984-13.171-74.96-26.343-111.804-38.641-10.045-3.353-19.888-6.601-29.503-9.733-138.393-45.083-195.304-59.069-205.885-35.573-10.394
      23.080 38.46 56.319 171.036 133.732 7.75 4.525 15.693 9.132 23.817 13.814 41.542 23.943 86.034 48.85 130.522
      73.265 15.569 8.544 38.56 20.445 51.537 27.465v-33.096c-15.254-6.527-28.054-12.63-38.4-18.308-44.334-24.33-88.665-49.148-130.024-72.985-8.079-4.657-15.978-9.238-23.683-13.737-49.148-28.698-89.156-53.317-117.421-72.548-11.302-7.689-20.592-14.431-27.698-20.107
      8.625 1.403 19.332 3.677 31.923 6.771zM505.045 850.451c-6.076-35.692-12.060-84.748-17.846-143.868-0.873-8.924-1.738-18.038-2.594-27.323-4.059-44.062-7.752-90.149-11.006-135.477-1.139-15.866-3.1-36.627-3.958-49.687-0.343-5.22-9.634-11.017-27.874-17.392
      0.182 2.985 3.452 53.111 4.595 69.034 3.266 45.499 6.973 91.763 11.051 136.027 0.86 9.336 1.73 18.501 2.608 27.478
      15.529 158.678 26.378 222.412 53.038 222.412 26.187 0 36.667-60.666 51.808-213.832 0.837-8.463 1.666-17.111 2.488-25.929
      4.414-47.369 8.44-97.694 11.996-147.746 1.245-17.516 2.701-52.901 3.641-67.444-18.188 8.216-27.391 14.013-27.609 17.392-0.936
      14.487-2.030 30.661-3.27 48.117-3.544 49.867-7.553 100-11.947 147.148-0.817 8.769-1.642 17.365-2.473 25.777-5.66 57.261-11.49
      104.47-17.39 138.626-2.463 14.259-4.912 26.063-7.306 35.173-2.606-9.813-5.272-22.739-7.953-38.485zM953.6-14.080c7.541 0 13.653-6.113
      13.653-13.653s-6.113-13.653-13.653-13.653h-883.2c-7.541 0-13.653 6.113-13.653 13.653s6.113 13.653 13.653 13.653h883.2zM555.947
      468.917c0 24.255-19.692 43.947-43.947 43.947-24.278 0-43.947-19.677-43.947-43.947v-483.012h87.893v483.012zM512 540.17c39.336 0
      71.253-31.918 71.253-71.253v-496.666c0-7.541-6.113-13.653-13.653-13.653h-115.2c-7.541 0-13.653 6.113-13.653
      13.653v496.666c0 39.348 31.891 71.253 71.253 71.253zM512 449.707c8.481 0 15.36 6.878 15.36 15.36s-6.879
      15.36-15.36 15.36c-8.484 0-15.36-6.876-15.36-15.36s6.876-15.36 15.36-15.36zM512 429.227c-19.795 0-35.84 16.046-35.84 35.84s16.045 35.84
      35.84 35.84c19.792 0 35.84-16.047 35.84-35.84s-16.048-35.84-35.84-35.84z"
          iconColor={iconSelected ? IconSelectedColor : ''}
          strokeWidth="25"
        />
      </g>
    </svg>
  )
}

export default ClusterHybridIcon
