import React, { useCallback, useEffect, useMemo } from 'react'
import { c } from 'ttag'

import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { useDispatch, useSelector } from 'react-redux'
import { authedSelector } from 'modules/auth/redux_store/state/getUser'
import {
  actionFailedSelector,
  appBannersSelector,
  sessionLoadingSelector,
  sessionRecoveringSelector,
  sessionStatusChangedDateSelector,
  sessionStatusSelector,
} from 'modules/app/checkConnectivity.state'
import { tConnectivityAndBanners } from 'fixtures/commonTranslations'
import { AppBanner, SessionActions, SessionStatus } from 'modules/app/app.types'
import TimeAgo from 'ui/datetime/TimeAgo'
import { useDebouncedCallback } from 'use-debounce'
import { getReloadWindowQueryObj, useQueryString } from 'utils/query-string'
import { loginInitializedSelector } from 'modules/auth/redux_store/state/login'
import AppBanners from 'modules/app/banners/AppBanners'

const Timing = {
  CHECK_INTERVAL_IN_MS: 1000 * 15,
  RECOVERING_DELAY_IN_MIN: 30,
}

const CheckConnectivity: React.FC = () => {
  const dispatch = useDispatch()
  const authed = useSelector(authedSelector)
  const sessionStatus = useSelector(sessionStatusSelector)
  const actionFailed = useSelector(actionFailedSelector)
  const sessionStatusChangedDate = useSelector(sessionStatusChangedDateSelector)
  const sessionRecovering = useSelector(sessionRecoveringSelector)
  const sessionLoading = useSelector(sessionLoadingSelector)
  const appBanners = useSelector(appBannersSelector)
  const { onUpdateQueryString } = useQueryString()

  const sessionTimeAgo = <TimeAgo key="timeago" date={sessionStatusChangedDate} />
  const translations = useMemo(() => tConnectivityAndBanners(sessionTimeAgo), [sessionTimeAgo])

  const updateSessionStatus = useDebouncedCallback((nextSessionStatus: SessionStatus) => {
    if (sessionStatus !== nextSessionStatus) {
      dispatch({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: nextSessionStatus })
      if (
        !actionFailed &&
        !sessionRecovering &&
        sessionStatus === SessionStatus.AUTHED &&
        nextSessionStatus !== SessionStatus.AUTHED
      ) {
        dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: true })
      }
    }
  }, 500)

  const setSessionLoadingFalse = useCallback(() => {
    setTimeout(() => {
      dispatch({ type: SessionActions.SET_SESSION_LOADING, loading: false })
    }, 1000)
  }, [])

  const checkAppSessionStatus = useCallback(() => {
    dispatch({ type: SessionActions.SET_SESSION_LOADING, loading: true })
    // 401: user is not logged in
    // 403: connection is offline (disabled vpn)
    fetch('/api/account/')
      .then((response) => {
        if (response.status === 200 && authed) {
          updateSessionStatus(SessionStatus.AUTHED)
        } else if (response.status === 401) {
          updateSessionStatus(SessionStatus.EXPIRED)
        } else {
          updateSessionStatus(SessionStatus.OFFLINE)
        }
        // const dateDifference = differenceInMinutes(sessionStatusChangedDate, new Date())
        // if (sessionRecovering && dateDifference >= Timing.RECOVERING_DELAY_IN_MIN) {
        setSessionLoadingFalse()
      })
      .catch(() => {
        updateSessionStatus(SessionStatus.OFFLINE)
        setSessionLoadingFalse()
      })
  }, [
    authed,
    actionFailed,
    sessionStatus, // we want also sessionStatus as dependency so that the timer resets on each change
    sessionRecovering,
    sessionStatusChangedDate,
    updateSessionStatus,
  ])

  useEffect(() => {
    const checkInterval = setInterval(checkAppSessionStatus, Timing.CHECK_INTERVAL_IN_MS)

    return () => {
      clearInterval(checkInterval)
    }
  }, [checkAppSessionStatus])

  // show/hide banners

  const addActionFailedBanner = useCallback(() => {
    const showActionFailedBanner = actionFailed
    const isAlreadyFailed = appBanners.includes(AppBanner.ACTION_FAILED)
    if (showActionFailedBanner && !isAlreadyFailed) {
      dispatch({ type: SessionActions.ADD_APP_BANNER, appBanner: AppBanner.ACTION_FAILED })
    }
  }, [actionFailed, appBanners])

  const actionFailedBannerShown = useMemo(() => appBanners.includes(AppBanner.ACTION_FAILED), [appBanners])

  useEffect(() => {
    addActionFailedBanner()
    const showExpiredBanner = authed && sessionStatus === SessionStatus.EXPIRED
    const isAlreadyExpired = appBanners.includes(AppBanner.SESSION_EXPIRED)
    if (!actionFailedBannerShown && !actionFailed) {
      if (showExpiredBanner && !isAlreadyExpired) {
        dispatch({ type: SessionActions.ADD_APP_BANNER, appBanner: AppBanner.SESSION_EXPIRED })
      } else if (!showExpiredBanner && isAlreadyExpired) {
        dispatch({ type: SessionActions.REMOVE_APP_BANNER, appBanner: AppBanner.SESSION_EXPIRED })
      }
    }
  }, [authed, sessionStatus, appBanners, actionFailed, addActionFailedBanner, actionFailedBannerShown])

  useEffect(() => {
    addActionFailedBanner()
    const showOfflineBanner = sessionStatus === SessionStatus.OFFLINE
    const isAlreadyExpired = appBanners.includes(AppBanner.SESSION_OFFLINE)
    if (!actionFailedBannerShown && !actionFailed) {
      if (showOfflineBanner && !isAlreadyExpired) {
        dispatch({ type: SessionActions.ADD_APP_BANNER, appBanner: AppBanner.SESSION_OFFLINE })
      } else if (!showOfflineBanner && isAlreadyExpired) {
        dispatch({ type: SessionActions.REMOVE_APP_BANNER, appBanner: AppBanner.SESSION_OFFLINE })
      }
    }
  }, [sessionStatus, appBanners, actionFailed, addActionFailedBanner, actionFailedBannerShown])

  const handleReload = useCallback(() => {
    onUpdateQueryString(getReloadWindowQueryObj())
  }, [onUpdateQueryString])

  const handleCancel = useCallback(() => {
    dispatch({ type: SessionActions.SET_SESSION_RECOVERING, sessionRecovering: true })
  }, [])

  const handleRetry = useCallback(() => {
    checkAppSessionStatus()
  }, [checkAppSessionStatus])

  // we check for loginInitialized so that the dialog is not flickering just before
  // the page refreshes after successful login (see location.reload in login saga)
  const loginInitialized = useSelector(loginInitializedSelector)

  return (
    <>
      {authed && !sessionRecovering && !loginInitialized && (
        <>
          {actionFailed ? (
            <ConfirmationDialog
              heading={c('App:Connectivity').t`Action failed`}
              text={
                sessionStatus === SessionStatus.AUTHED
                  ? translations.textActionFailedOnline
                  : sessionStatus === SessionStatus.EXPIRED
                  ? translations.textActionFailedExpired
                  : translations.textActionFailedOffline
              }
              confirmAction={
                [SessionStatus.AUTHED, SessionStatus.EXPIRED].includes(sessionStatus)
                  ? translations.actionReload
                  : translations.actionOk
              }
              cancelAction={
                [SessionStatus.AUTHED, SessionStatus.EXPIRED].includes(sessionStatus)
                  ? translations.actionCancel
                  : undefined
              }
              onConfirm={
                [SessionStatus.AUTHED, SessionStatus.EXPIRED].includes(sessionStatus) ? handleReload : handleCancel
              }
              onCancel={handleCancel}
              openDialog={true}
            />
          ) : (
            <>
              {!actionFailedBannerShown && (
                <>
                  {sessionStatus === SessionStatus.EXPIRED && (
                    <ConfirmationDialog
                      heading={c('App:Connectivity').t`Session expired`}
                      text={translations.textExpired}
                      confirmAction={translations.actionReload}
                      cancelAction={translations.actionCancel}
                      onConfirm={handleReload}
                      onCancel={handleCancel}
                      openDialog={true}
                    />
                  )}
                  {sessionStatus === SessionStatus.OFFLINE && (
                    <ConfirmationDialog
                      heading={c('App:Connectivity').t`Connection lost`}
                      text={translations.textOffline}
                      confirmAction={translations.actionRetry}
                      cancelAction={translations.actionCancel}
                      onConfirm={handleRetry}
                      onCancel={handleCancel}
                      loading={sessionLoading}
                      closeDialogOnConfirm={false}
                      openDialog={true}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      <AppBanners onCheckAppSessionStatus={checkAppSessionStatus} />
    </>
  )
}

export default React.memo(CheckConnectivity)
