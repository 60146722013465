import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import AssetsHeader from 'modules/asset/portfolio/header/AssetsHeader'
import AssetTree from 'modules/asset/tree/AssetTree'
import AssetRevisionChooser from 'modules/asset/portfolio/AssetRevisionChooser'
import Flex from 'ui/styles/Flex'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { SideNavContainer } from 'modules/app/app.styles'
import { sideNavWidth, transitionFor } from 'themes/theme-light'
import { useRouterMatch } from 'utils/route'
import { ROUTE_WORKBENCH_ASSET_TABLE } from 'modules/app/Routes'
import { QUERY_ASSET, useQueryMatch, useQueryParams } from 'utils/query-string'

const FullFlex = styled(Flex)`
  flex: 1 1 auto; /* use exactly the remaining screen space available */
  margin-left: 1em;
`

const ToolbarWrapper = styled.div`
  margin: 0 1em;
`

const AssetsSideNav = styled(SideNavContainer)`
  left: 0;
  transition: ${transitionFor(['width'], 800)};
`

const AssetPortfolio: React.FC = () => {
  const { isMatch: isAssetTable } = useRouterMatch(ROUTE_WORKBENCH_ASSET_TABLE)
  const [assetTreeZIndex, setAssetTreeZIndex] = useState(2)
  const [assetDetailsId, setAssetDetailsId] = useState<string>('')
  const isAssetDetails = useQueryMatch(QUERY_ASSET)
  const { queryParams } = useQueryParams()
  const [isWide, setIsWide] = useState(isAssetTable)

  useEffect(() => {
    if (queryParams[QUERY_ASSET]) {
      setAssetDetailsId(queryParams[QUERY_ASSET])
    } else {
      setAssetDetailsId('')
    }
  }, [queryParams[QUERY_ASSET]])

  useEffect(() => {
    setTimeout(() => {
      setAssetTreeZIndex(isAssetTable ? '5' : '2')
    }, 500)
  }, [isAssetTable])

  useEffect(() => {
    const toggle = () => setIsWide(isAssetTable)
    if (isAssetTable) {
      toggle()
    } else {
      const timeoutId = setTimeout(toggle, 150)
      return () => clearTimeout(timeoutId)
    }
  }, [isAssetTable])

  return (
    <AssetsSideNav
      className="not-printable"
      containerWidth={isAssetTable ? '100vw' : sideNavWidth}
      zIndex={assetTreeZIndex.toString()}
    >
      <Flex direction="column" fullHeight>
        <AssetsHeader />
        <ToolbarWrapper>
          <ReTableToolbar id={RETABLE_ID_ASSETS} showClearSorting={false} />
        </ToolbarWrapper>
        <FullFlex direction="column" fullHeight>
          <AssetTree
            isWide={isWide}
            assetDetailsId={assetDetailsId}
            isAssetTable={isAssetTable}
            isAssetDetails={isAssetDetails}
          />
          {/*<TreeSummary isAssetDetails={isAssetDetails} isWide={isWide} />*/}
        </FullFlex>
        <AssetRevisionChooser />
      </Flex>
    </AssetsSideNav>
  )
}

// AssetPortfolio.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(AssetPortfolio)
