import React, { useCallback, useEffect, useState } from 'react'
import {
  DeliveryTimesType,
  ForecastConfig,
  SiteForecastQualityConfig,
  SiteForecastUpdateTimes,
} from 'modules/dataStreams/dataStreams.types'
import { FormApi } from 'final-form'
import Flex from 'ui/styles/Flex'
import SectionForecastQualityEvaluationBody from 'modules/dataStreams/siteForecast/SectionForecastQualityEvaluationBody'
import SectionForecastQualityEvaluationHeader from 'modules/dataStreams/siteForecast/SectionForecastQualityEvaluationHeader'
import { FormSpy, useField } from 'react-final-form'
import { getIndividualTimes } from 'utils/siteForecastConfiguration'

interface SectionForecastQualityEvaluationProps {
  onFormChange: () => void
  form: FormApi<ForecastConfig>
  fieldArrayKey: string
  index: number
  onSetPrimaryQualityConfig: (index: number) => void
  onRemoveQualityConfig: (index: number, qualityConfig: SiteForecastQualityConfig) => void
}

const SectionForecastQualityEvaluationDetails: React.FC<SectionForecastQualityEvaluationProps> = ({
  onFormChange,
  form,
  fieldArrayKey,
  index,
  onSetPrimaryQualityConfig,
  onRemoveQualityConfig,
}) => {
  const { CUSTOM } = DeliveryTimesType
  const initialForecastDetails = form.getState().initialValues
  const deliveryFrequencyChanged = form.getState().values.ui?.deliveryFrequencyChanged
  const currentUpdateTimes = useField<SiteForecastUpdateTimes>('updateTimes').input.value
  const deliveryTimesType = useField<DeliveryTimesType>(`${fieldArrayKey}.deliveryTimesType`).input.value
  const forecastDetails = form.getState().values
  const qualityConfig = useField<SiteForecastQualityConfig>(`${fieldArrayKey}`).input.value

  const [showDetails, setShowDetails] = useState<boolean>(false)

  const handleShowDetails = useCallback(() => {
    setShowDetails((showDetails) => !showDetails)
  }, [])

  useEffect(() => {
    if (!qualityConfig.uuid) {
      setShowDetails(true)
    }
  }, [index, forecastDetails?.id])

  useEffect(() => {
    const initialUpdateValues = initialForecastDetails?.updateTimes
    if (
      initialUpdateValues &&
      (JSON.stringify(currentUpdateTimes) != JSON.stringify(initialUpdateValues) || deliveryFrequencyChanged)
    ) {
      const forecastConfig = form.getState().values
      const availableTimes = getIndividualTimes(currentUpdateTimes)
      const customSelectedTimes = forecastConfig.qualityConfigs[0].deliveryTimes
      const matchingCustomTimes = availableTimes.filter((at) => {
        return customSelectedTimes.find((ct) => ct.hours == at.hours && ct.minutes == at.minutes)
      })

      const newTimes = deliveryTimesType === CUSTOM ? matchingCustomTimes : availableTimes

      // We need to convert the times to number format before checking
      // because backend gives numbers and react form accepts strings
      const oldDeliveryTimesInNumberFormat = forecastConfig.qualityConfigs[index].deliveryTimes.map((time) => {
        return {
          ...time,
          hours: parseInt(`${time.hours}`),
          minutes: parseInt(`${time.minutes}`),
        }
      })
      const newTimesInNumberFormat = newTimes.map((time) => {
        return {
          ...time,
          hours: parseInt(time.hours),
          minutes: parseInt(time.minutes),
        }
      })

      if (JSON.stringify(oldDeliveryTimesInNumberFormat) !== JSON.stringify(newTimesInNumberFormat)) {
        form.mutators.setValue(`${fieldArrayKey}.deliveryTimes`, newTimes)
      }
    }
  }, [initialForecastDetails, currentUpdateTimes, deliveryTimesType, deliveryFrequencyChanged])

  return (
    <Flex key={index} direction="column">
      <FormSpy subscription={{ values: true, errors: true }}>
        {({ values, errors }) => {
          return (
            <SectionForecastQualityEvaluationHeader
              onSetPrimaryQualityConfig={onSetPrimaryQualityConfig}
              onRemoveQualityConfig={onRemoveQualityConfig}
              forecastDetails={values as ForecastConfig}
              index={index}
              showDetails={showDetails}
              onShowDetails={handleShowDetails}
              hasError={
                errors?.qualityConfigs &&
                errors.qualityConfigs[index] &&
                Object.keys(errors.qualityConfigs[index]).length > 0
              }
            />
          )
        }}
      </FormSpy>
      {showDetails && (
        <SectionForecastQualityEvaluationBody
          fieldArrayKey={fieldArrayKey}
          form={form}
          index={index}
          onFormChange={onFormChange}
        />
      )}
    </Flex>
  )
}

export default React.memo(SectionForecastQualityEvaluationDetails)
