import { Popper } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'

export enum PopperTooltipPosition {
  TOP_START = 'top-start',
  TOP = 'top',
  TOP_END = 'top-end',
  LEFT_START = 'left-start',
  LEFT = 'left',
  LEFT_END = 'left-end',
  RIGHT_START = 'right-start',
  RIGHT = 'right',
  RIGHT_END = 'right-end',
  BOTTOM_START = 'bottom-start',
  BOTTOM = 'bottom',
  BOTTOM_END = 'bottom-end',
}

interface PopperContainerProps {
  hasContent: number
}
const PopperContainer = styled.div<PopperContainerProps>`
  cursor: ${(props) => (props.hasContent ? 'help' : 'inherit')};
`
const Label = styled.div``
interface ContentProps {
  background_color?: string
  content_width?: string
}
const Content = styled(Paper)<ContentProps>`
  &.MuiPaper-root {
    background-color: ${(props) => props.background_color || 'white'};
  }
  max-width: ${(props) => props.content_width || '40em'};
  padding: 1em;
`

interface PopperToolTipProps {
  popperLabel: React.ReactNode
  popperContent: React.ReactNode
  contentBackgroundColor?: string
  position?: PopperTooltipPosition
  contentWidth?: string
}

const PopperTooltip: React.FC<PopperToolTipProps> = ({
  popperLabel,
  popperContent,
  contentBackgroundColor,
  position = PopperTooltipPosition.BOTTOM_START,
  contentWidth,
}) => {
  const refLabel = useRef(null)
  const refPopper = useRef(null)

  const [popperLabelHovered, setPopperLabelHovered] = useState<boolean>(false)
  const [popperContentHovered, setPopperContentHovered] = useState<boolean>(false)

  const handlePopperLabelMouseEnter = useCallback(() => {
    setPopperLabelHovered(true)
  }, [])

  const handlePopperLabelMouseLeave = useCallback(() => {
    setPopperLabelHovered(false)
  }, [])

  const handlePopperContentMouseEnter = useCallback(() => {
    setPopperContentHovered(true)
  }, [])

  const handlePopperContentMouseLeave = useCallback(() => {
    setPopperContentHovered(false)
  }, [])

  const showPopper = useMemo(() => popperLabelHovered || popperContentHovered, [
    popperLabelHovered,
    popperContentHovered,
  ])
  const [showPopperDebounced] = useDebounce(showPopper, 50)

  return (
    <PopperContainer hasContent={popperContent ? 1 : 0}>
      <Label ref={refLabel} onMouseOver={handlePopperLabelMouseEnter} onMouseLeave={handlePopperLabelMouseLeave}>
        {popperLabel}
      </Label>
      {popperContent && (
        <Popper
          ref={refPopper}
          open={showPopperDebounced}
          anchorEl={refLabel.current}
          placement={position}
          // we need a high z-index so that it is visible even in modals (e.g. UploadModal.tsx)
          style={{ zIndex: 2000 }}
        >
          {popperContent && (
            <Content
              elevation={3}
              onMouseOver={handlePopperContentMouseEnter}
              onMouseLeave={handlePopperContentMouseLeave}
              background_color={contentBackgroundColor}
              content_width={contentWidth}
            >
              {popperContent}
            </Content>
          )}
        </Popper>
      )}
    </PopperContainer>
  )
}

export default React.memo(PopperTooltip)
