import React, { useMemo } from 'react'

import { SliderComponent } from 'ui/elements/SliderComponent'
import {
  QUERY_ASSET,
  QUERY_CHANGE_PASSWORD,
  QUERY_DATA_STREAM_ID,
  QUERY_DATA_STREAM_TYPE,
  QUERY_DELIVERY_TARGET,
  QUERY_PENALTY_DETAILS,
  QUERY_PENALTY_NEW_ENTITY_TYPE,
  QUERY_USER_MANAGEMENT_DETAILS,
  QUERY_USER_SETTINGS,
  useQueryMatch,
  useQueryParams,
} from 'utils/query-string'
import UserSettings from 'modules/user/userSettings/UserSettings'
import { OverlayZIndex } from 'fixtures/mainPage'
import ChangePasswordForm from 'modules/auth/ChangePasswordForm'
import { useDebounce } from 'use-debounce'
import ManageDataStreams from 'modules/dataStreams/ManageDataStreams'
import DeliveryTargetDetails from 'modules/delivery/deliveryTargets/DeliveryTargetDetails'
import UserManagementDetails from 'modules/userManagement/UserManagementDetails'
import AssetCrud from 'modules/asset/assetCrud/AssetCrud'
import PenaltyRegulationDetails from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationDetails'

// TODO: https://github.com/tomkp/react-split-pane#example-styling

const OverlayContainer: React.FC = () => {
  const {
    assetDetailsIndex,
    userSettingsIndex,
    changePasswordIndex,
    dataStreamsIndex,
    deliveryIndex,
    userManagementDetailsIndex,
    penaltyRegulationDetailsIndex,
  } = OverlayZIndex

  const smallSliderWidth = '35em'
  const standardSliderWidth = '48em'
  const penaltySliderWidth = '50em'

  const { queryParams } = useQueryParams()

  const isAssetSlider = useQueryMatch(QUERY_ASSET)
  const isDataStreams = useQueryMatch(QUERY_DATA_STREAM_TYPE)
  const isUserSettings = useQueryMatch(QUERY_USER_SETTINGS)
  const isChangePassword = useQueryMatch(QUERY_CHANGE_PASSWORD)
  const isDeliveryTarget = useQueryMatch(QUERY_DELIVERY_TARGET)
  const isUserManagementDetails = useQueryMatch(QUERY_USER_MANAGEMENT_DETAILS)
  const isPenaltyRegulationDetails = useQueryMatch(QUERY_PENALTY_DETAILS)

  const [debouncedIsAssetSlider] = useDebounce(isAssetSlider, 100)
  const [debouncedIsDataStreams] = useDebounce(isDataStreams, 100)
  const [debouncedIsUserSettings] = useDebounce(isUserSettings, 100)
  const [debouncedIsChangePassword] = useDebounce(isChangePassword, 100)
  const [debouncedIsDeliveryTarget] = useDebounce(isDeliveryTarget, 100)
  const [debouncedIsUserManagementDetails] = useDebounce(isUserManagementDetails, 100)
  const [debouncedIsPenaltyRegulationDetails] = useDebounce(isPenaltyRegulationDetails, 100)

  const [showAssetSlider, animateAssetSlider] = useMemo(
    () => (isAssetSlider ? [isAssetSlider, debouncedIsAssetSlider] : [debouncedIsAssetSlider, isAssetSlider]),
    [isAssetSlider, debouncedIsAssetSlider],
  )

  const isDataStreamsDetails = useMemo(() => isDataStreams && queryParams[QUERY_DATA_STREAM_ID], [
    queryParams,
    isDataStreams,
  ])

  const [showDataStreams, animateForecastConfig] = useMemo(
    () => (isDataStreams ? [isDataStreams, debouncedIsDataStreams] : [debouncedIsDataStreams, isDataStreams]),
    [isDataStreams, debouncedIsDataStreams],
  )

  const [showUserSettings, animateUserSettings] = useMemo(
    () => (isUserSettings ? [isUserSettings, debouncedIsUserSettings] : [debouncedIsUserSettings, isUserSettings]),
    [isUserSettings, debouncedIsUserSettings],
  )

  const [showChangePassword, animateChangePassword] = useMemo(
    () =>
      isChangePassword ? [isChangePassword, debouncedIsChangePassword] : [debouncedIsChangePassword, isChangePassword],
    [isChangePassword, debouncedIsChangePassword],
  )

  const [showDeliveryTarget, animateDeliveryTarget] = useMemo(
    () =>
      isDeliveryTarget ? [isDeliveryTarget, debouncedIsDeliveryTarget] : [debouncedIsDeliveryTarget, isDeliveryTarget],
    [isDeliveryTarget, debouncedIsDeliveryTarget],
  )

  const [showUserManagementDetails, animateUserManagementDetails] = useMemo(
    () =>
      isUserManagementDetails
        ? [isUserManagementDetails, debouncedIsUserManagementDetails]
        : [debouncedIsUserManagementDetails, isUserManagementDetails],
    [isUserManagementDetails, debouncedIsUserManagementDetails],
  )

  const [showPenaltyRegulationDetails, animatePenaltyRegulationDetails] = useMemo(
    () =>
      isUserManagementDetails
        ? [isPenaltyRegulationDetails, debouncedIsPenaltyRegulationDetails]
        : [debouncedIsPenaltyRegulationDetails, isPenaltyRegulationDetails],
    [isPenaltyRegulationDetails, debouncedIsPenaltyRegulationDetails],
  )

  return (
    <div>
      {showDataStreams && (
        <SliderComponent
          zIndex={dataStreamsIndex}
          width={isDataStreamsDetails ? 'calc(100vw - 20em)' : 'calc(100vw - 40.7em)'}
          right={animateForecastConfig ? '20em' : 'calc(-100vw + 40em)'}
        >
          <ManageDataStreams />
        </SliderComponent>
      )}
      {showAssetSlider && (
        <SliderComponent
          marginRight={animateAssetSlider ? '0' : '-100vw'}
          width="calc(100vw - 20.7em)"
          right="0"
          zIndex={assetDetailsIndex}
        >
          <AssetCrud isNew={queryParams[QUERY_ASSET] === 'new'} />
        </SliderComponent>
      )}
      {showUserSettings && (
        <SliderComponent
          marginRight={animateUserSettings ? '0' : '-35em'}
          width="35em"
          right="0"
          zIndex={userSettingsIndex}
        >
          <UserSettings />
        </SliderComponent>
      )}
      {showChangePassword && (
        <SliderComponent
          marginRight={animateChangePassword ? '0' : `-${smallSliderWidth}`}
          width={smallSliderWidth}
          right="0"
          zIndex={changePasswordIndex}
        >
          <ChangePasswordForm />
        </SliderComponent>
      )}
      {showDeliveryTarget && queryParams[QUERY_DELIVERY_TARGET] && (
        <SliderComponent
          marginRight={animateDeliveryTarget ? '0' : `-${standardSliderWidth}`}
          width={standardSliderWidth}
          right="0"
          zIndex={deliveryIndex}
        >
          <DeliveryTargetDetails targetId={queryParams[QUERY_DELIVERY_TARGET]} />
        </SliderComponent>
      )}
      {showUserManagementDetails && queryParams[QUERY_USER_MANAGEMENT_DETAILS] && (
        <SliderComponent
          marginRight={animateUserManagementDetails ? '0' : `-${standardSliderWidth}`}
          width={standardSliderWidth}
          right="0"
          zIndex={userManagementDetailsIndex}
        >
          <UserManagementDetails userId={queryParams[QUERY_USER_MANAGEMENT_DETAILS]} />
        </SliderComponent>
      )}
      {showPenaltyRegulationDetails && queryParams[QUERY_PENALTY_DETAILS] && (
        <SliderComponent
          marginRight={animatePenaltyRegulationDetails ? '0' : `-${penaltySliderWidth}`}
          width={penaltySliderWidth}
          right="0"
          zIndex={penaltyRegulationDetailsIndex}
        >
          <PenaltyRegulationDetails
            newEntityType={queryParams[QUERY_PENALTY_NEW_ENTITY_TYPE] || null}
            id={queryParams[QUERY_PENALTY_DETAILS]}
          />
        </SliderComponent>
      )}
    </div>
  )
}

export default OverlayContainer
