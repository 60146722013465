import { XAxisPlotBandsOptions, XAxisPlotLinesOptions } from 'highcharts/highstock'
import { addDays, addMinutes, endOfDay, getTime, startOfDay, subDays } from 'date-fns'
import { fade, lighten } from '@material-ui/core/styles'
import { t } from 'ttag'

import { CHART_PLOTBAND_DAYS } from 'modules/app/app-config'
import { scheduleTimingColors, theme } from 'themes/theme-light'
import { convertLocalDateToZonedTime, convertUTCToLocalDate, convertZonedTimeToAnotherZonedTime } from 'utils/date'
import { Timezone } from 'fixtures/timezones'
import { removeDuplicates } from 'utils/array'
import { TypesOfScheduleTimingLines } from 'utils/schedule'

const SHOW_DAY_PLOTBANDS_FOR_DAYS_LESS_THAN = 90
// const SHOW_DAY_PLOTBAND_LABELS_FOR_DAYS_LESS_THAN = 30

type CreateDayPlotBands = (startDate: Date, dateRangeInDays?: number, timezone?: Timezone) => XAxisPlotBandsOptions[]

export const createDayPlotBands: CreateDayPlotBands = (startDate: Date, dateRangeInDays = 0, timezone) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // 1. Now date , is converted from local timezone (user timezone) to timezone selected in application
  const now = timezone ? convertZonedTimeToAnotherZonedTime(startDate, localTimezone, timezone) : startDate

  // 2. In the above response , we need to find start of the day
  const startOfTodayInSelectedTimezone = startOfDay(now)

  // 3. startOfTodayInSelectedTimezone should be converted back to user time zone (local timezone) , because Highchart it handling timezone conversion in chart
  const startOfToday = convertZonedTimeToAnotherZonedTime(startOfTodayInSelectedTimezone, timezone, localTimezone)

  const dayRects: XAxisPlotBandsOptions[] =
    dateRangeInDays < SHOW_DAY_PLOTBANDS_FOR_DAYS_LESS_THAN
      ? Array.from(Array(CHART_PLOTBAND_DAYS + 1).keys()).map((day, index) => {
          // const coefficient = 0.3 * (index % 2)
          const coefficient = Math.log10(1 + 0.8 * index)
          const from = addDays(startOfToday, day)
          const to = addDays(startOfToday, day + 1)

          return {
            id: index.toString(),
            from: getTime(from),
            to: getTime(to),
            color: fade(lighten('#aaa', Math.min(1, coefficient)), 0.2),
            label: {
              text: day === 0 ? t`Intraday` : day === 1 ? t`Day-Ahead` : t`d+${day}`,
              rotation: 270,
              width: 9,
              y: 8,
              style: {
                color: lighten('#aaa', Math.min(1, coefficient / 2)),
                fontSize: '2em',
              },
              textAlign: 'right',
            },
            borderColor: '#fff',
            borderWidth: 3,
          }
        })
      : []

  return dayRects
}

type CreateTodayLine = (
  createScheduleActive: boolean,
  setRevisionTimingInfo?: any,
  revisionTimingInfo?: any,
  showTooltip?: boolean,
  timeBlockLength?: number,
  forecastOffset?: number,
) => XAxisPlotLinesOptions

export const createTodayLine: CreateTodayLine = (
  createScheduleActive: boolean,
  setRevisionTimingInfo?: any,
  revisionTimingInfo?: any,
  showTooltip?: boolean,
  timeBlockLength?: number,
  forecastOffset?: number,
) => {
  const date = forecastOffset ? addMinutes(new Date(), forecastOffset) : new Date()

  const color = () => {
    if (timeBlockLength && !forecastOffset) {
      return scheduleTimingColors.realTime
    } else if (timeBlockLength && forecastOffset) {
      return scheduleTimingColors.forecastOffset
    }
    return createScheduleActive ? theme.palette.primary.main : fade(theme.palette.primary.main, 0.4)
  }

  const todayLine: XAxisPlotLinesOptions = {
    value: getTodayLineValue(date, timeBlockLength as number),
    width: timeBlockLength ? 5 : 1,
    color: color(),
    dashStyle: timeBlockLength ? 'Solid' : 'LongDash',
    // plot lines should appear above plot bands
    //   no zIndex  =>  below plot bands
    //   zIndex==3  =>  above plot bands
    //   zIndex==5  =>  above plot bands and series
    zIndex: createScheduleActive ? 4 : 3,
  }

  if (createScheduleActive) {
    let hoveredPlotLine: TypesOfScheduleTimingLines | null = null

    if (!timeBlockLength && !forecastOffset) {
      todayLine.label = {
        useHTML: true,
        text: `<img src="../../../../public/map/arrow-down-solid.svg" alt="lock" height="25px" width="11px">`,
        verticalAlign: 'top',
        textAlign: 'center',
        rotation: 0,
        y: -4,
        x: -0.003,
      }
      hoveredPlotLine = TypesOfScheduleTimingLines.realTime
    } else if (timeBlockLength && forecastOffset) {
      hoveredPlotLine = TypesOfScheduleTimingLines.forecastOffset
    }

    if (showTooltip) {
      todayLine.events = {
        mouseover: (e) => {
          if (e?.layerX !== revisionTimingInfo?.locationX || e?.layerY !== revisionTimingInfo?.locationY) {
            setRevisionTimingInfo((prev) => {
              return { ...prev, hoveredPlotLine, locationX: e?.layerX, locationY: e?.layerY }
            })
          }
        },
        mouseout: () => {
          setRevisionTimingInfo((prev) => {
            return { ...prev, hoveredPlotLine: null, locationX: null, locationY: null }
          })
        },
      }
    }
  }

  return todayLine
}

export const getTodayLineValue = (date: Date, timeBlockLength?: number) => {
  if (timeBlockLength) {
    // timeBlockLength in milliseconds
    const coefficient = 1000 * 60 * timeBlockLength
    return Math.ceil(date.getTime() / coefficient) * coefficient
  }
  return getTime(date)
}

export const createDayPlotBandsForTimingPreview: CreateDayPlotBands = (
  startDate: Date,
  dateRangeInDays = 0,
  timezone,
) => {
  const start = timezone ? convertLocalDateToZonedTime(startDate, timezone) : startDate

  const dayRects: XAxisPlotBandsOptions[] =
    dateRangeInDays < SHOW_DAY_PLOTBANDS_FOR_DAYS_LESS_THAN
      ? Array.from(Array(CHART_PLOTBAND_DAYS + 1).keys()).map((day, index) => {
          // const coefficient = 0.3 * (index % 2)
          const coefficient = Math.log10(1 + 0.8 * index)
          const from = addDays(start, day)
          const to = addDays(start, day + 1)

          return {
            id: index.toString(),
            from: getTime(from),
            to: getTime(to),
            color: fade(lighten('#aaa', Math.min(1, coefficient)), 0.2),
            label: {
              text: day === 0 ? t`Intraday` : day === 1 ? t`Day-Ahead` : t`d+${day}`,
              rotation: 270,
              width: 9,
              y: 250,
              style: {
                color: lighten('#aaa', Math.min(1, coefficient / 2)),
                fontSize: '2em',
              },
              textAlign: 'right',
            },
            borderColor: '#fff',
            borderWidth: 3,
          }
        })
      : []

  return dayRects
}

export const basicSchedulePlotBand = (
  startDate: string | Date | number,
  endDate: string | Date | number,
  excludedTimingPlotBand?: boolean,
) => {
  return {
    id: `${startDate}${endDate}`,
    from: getTime(startDate),
    to: getTime(endDate),
    zIndex: excludedTimingPlotBand ? 3 : 2,
    color: fade(
      excludedTimingPlotBand ? scheduleTimingColors.forecastOffset : lighten(theme.palette.primary.main, 0.5),
      0.2,
    ),
  }
}

interface CreateSchedulePlotBandsProps {
  startDate: Date | string
  endDate: Date | string
}

export const createSchedulePlotBand = ({ startDate, endDate }: CreateSchedulePlotBandsProps) => {
  const schedulePlotBand: XAxisPlotBandsOptions[] = [basicSchedulePlotBand(startDate, endDate)]

  return schedulePlotBand
}

/**
 ********************** Schedule editor *******************
 ************************ Starts here *********************
 **/

/**
 * getWindowStartAndEndTimesTimestamp
 * @param windowStartTimes
 * @param windowLength in UTC
 * @param timezone
 */
export const getWindowStartAndEndTimesTimestamp = (
  windowStartTimes: string[],
  windowLength: number,
  timezone: Timezone,
) => {
  const timestampArray: number[] = []

  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const today = new Date()

  // Today , and tomorrow
  const daysToBeShown = [startOfDay(today), addDays(startOfDay(today), 1)]

  daysToBeShown.forEach((d) => {
    // Window revision start time + timezone goes to prev days or next day depending on timezone
    // Here we are preparing -1 day , +1 day
    const dayToBeShowBasedOnThreeDays = [subDays(d, 1), d, addDays(d, 1)]

    dayToBeShowBasedOnThreeDays.forEach((dayFromThreeDaysArray) => {
      windowStartTimes.forEach((time: string) => {
        // 'time: string' is also in UTC , that's why we are preparing full utc date
        // 1. Start time (utc)
        const prepareFullUtcDateWindowsStartTime = `${dayFromThreeDaysArray.getUTCFullYear()}-${
          dayFromThreeDaysArray.getUTCMonth() + 1
        }-${dayFromThreeDaysArray.getUTCDate()} ${time}`

        // 2. End time (utc): Windows Start Time + Windows length = Windows end time
        const prepareFullUtcDateWindowsEndTime = addMinutes(new Date(prepareFullUtcDateWindowsStartTime), windowLength)

        // utc to local timezone
        const utcToZonedTimeWindowsStartTime = convertUTCToLocalDate(prepareFullUtcDateWindowsStartTime)
        const utcToZonedTimeWindowsEndTime = convertUTCToLocalDate(prepareFullUtcDateWindowsEndTime)

        // Here we are converting date in local time (from prev two above lines) to selected timezone
        // This is just for comparison in the below code
        const windowsStarTimeInSelectedTimezone = convertZonedTimeToAnotherZonedTime(
          utcToZonedTimeWindowsStartTime,
          localTimezone,
          timezone,
        )
        // Here we are converting date in local time (from prev two above lines) to selected timezone
        // This is just for comparison in the below code
        const windowsEndTimeInSelectedTimezone = convertZonedTimeToAnotherZonedTime(
          utcToZonedTimeWindowsEndTime,
          localTimezone,
          timezone,
        )

        // Since we are using three days , we need to push only timestamp that after converting to selected timezone are inside d (daysToBeShown)
        if (windowsStarTimeInSelectedTimezone >= startOfDay(d) && windowsStarTimeInSelectedTimezone <= endOfDay(d)) {
          timestampArray.push(utcToZonedTimeWindowsStartTime.getTime())
        }

        // Since we are using three days , we need to push only timestamp that after converting to selected timezone are inside d (daysToBeShown)
        if (windowsEndTimeInSelectedTimezone >= startOfDay(d) && windowsEndTimeInSelectedTimezone <= endOfDay(d)) {
          timestampArray.push(utcToZonedTimeWindowsEndTime.getTime())
        }
      })
    })
  })

  // Generally windows End Time is equal with next windows Start Time , that's why we remove duplicates
  const uniqueTimeStampArray = removeDuplicates(timestampArray)

  // Is sorted in ascending order
  uniqueTimeStampArray.sort(function (a, b) {
    return a - b
  })

  return uniqueTimeStampArray
}

/**
 * createScheduleTimingPlotLine
 * @param timestamp
 * @param setRevisionTimingInfo
 * @param revisionTimingInfo
 * @param showTooltip
 */
export const createScheduleTimingPlotLine = (
  timestamp: number,
  setRevisionTimingInfo: any,
  revisionTimingInfo: any,
  showTooltip: boolean,
): XAxisPlotLinesOptions => {
  const lineColor = fade(scheduleTimingColors.revisionWindow, 0.3)
  const dashStyle = 'Dash'

  const line: XAxisPlotLinesOptions = {
    value: timestamp,
    color: lineColor,
    width: 2,
    dashStyle: dashStyle,
    zIndex: 3,
  }

  if (showTooltip) {
    line.events = {
      mouseover: (e) => {
        if (e?.layerX !== revisionTimingInfo?.locationX || e?.layerY !== revisionTimingInfo?.locationY) {
          setRevisionTimingInfo((prev) => {
            return {
              ...prev,
              hoveredPlotLine: TypesOfScheduleTimingLines.revisionWindow,
              locationX: e?.layerX,
              locationY: e?.layerY,
            }
          })
        }
      },
      mouseout: () => {
        setRevisionTimingInfo((prev) => {
          return { ...prev, hoveredPlotLine: null, locationX: null, locationY: null }
        })
      },
    }
  }

  return line
}

/**
 ********************** Schedule editor *******************
 ************************ Ends here ***********************
 **/
