import React from 'react'
import { Box } from '@material-ui/core'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import Flex from 'ui/styles/Flex'

const FlexContainer = styled(Flex)`
  margin: 0 auto;
  padding: 3em;
  color: rgb(130, 130, 130);
  background: rgb(245, 245, 245);
  border: 1px solid rgb(220, 220, 220);
`

interface EmptyBoxProps {
  icon: IconProp
  hideIcon?: boolean
}

const EmptyBox: React.FC<EmptyBoxProps> = ({ hideIcon = false, icon, children }) => (
  <FlexContainer direction="column" alignItems="center" justifyContent="center">
    {!hideIcon && (
      <Box mb={3}>
        <FontAwesomeIcon icon={icon} size="8x" color="rgb(210, 210, 210)" />
      </Box>
    )}

    <Flex direction="row" alignItems="center" justifyContent="center">
      {children}
    </Flex>
  </FlexContainer>
)

export default React.memo(EmptyBox)
