import React from 'react'
import { Box, Typography } from '@material-ui/core'
import styled from 'styled-components'

import Flex from 'ui/styles/Flex'
import { Status } from 'modules/reTable/reTable.types'
// import { TableCellIcon } from 'ui/elements/TableCellIcon'
import PopperTooltip from 'ui/PopperTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TooltipContent = styled.div`
  max-height: 9.5em;
  overflow: hidden;
`

export const TableCellIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`

interface StatusProps {
  status: Status
}
const StatusIcon: React.FC<StatusProps> = ({ status }) => {
  return (
    <PopperTooltip
      popperLabel={
        <Flex>
          {status?.icon && <TableCellIcon icon={status.icon} color={status.color} />}
          {/*{status.type === 'success' && <Success>{status.label}</Success>}*/}
          {/*{status.type === 'progress' && <Info>{status.label}</Info>}*/}
          {/*{status.type === 'error' && <Error>{status.label}</Error>}*/}
        </Flex>
      }
      popperContent={
        <TooltipContent>
          <Typography color={status?.type === 'error' ? 'error' : 'primary'} variant="subtitle1">
            {status?.label}
          </Typography>
          <Typography color="inherit">{status?.description || ''}</Typography>
          <Box mt={1}>
            {(status?.messages || []).slice(0, 7).map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </Box>
        </TooltipContent>
      }
    ></PopperTooltip>
  )
}

export default React.memo(StatusIcon)
