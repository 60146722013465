import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

export interface ForgotPasswordType {
  email: string
}

export interface ForgotPasswordActionType {
  type: actionTypes.FORGOT_PASSWORD_ACTION_TYPE
  forgotPasswordData: ForgotPasswordType
}

interface State {
  loading: boolean
  error: string | null
  result: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  result: false,
}

const loading = createReducer<State['loading'], ForgotPasswordActionType>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.FORGOT_PASSWORD_REQUEST:
      return true
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
    case actionTypes.FORGOT_PASSWORD_FAILURE:
    case actionTypes.CLEAR_FORGOT_PASSWORD_STATE:
      return false
  }
  return state
})

const error = createReducer<State['error'], ForgotPasswordActionType & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.FORGOT_PASSWORD_SUCCESS:
      case actionTypes.FORGOT_PASSWORD_DISMISS:
      case actionTypes.CLEAR_FORGOT_PASSWORD_STATE:
        return null
      case actionTypes.FORGOT_PASSWORD_FAILURE:
        return error
    }
    return state
  },
)

const result = createReducer<State['result'], ForgotPasswordActionType>((state = initialState.result, { type }) => {
  switch (type) {
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return true
    case actionTypes.FORGOT_PASSWORD_FAILURE:
    case actionTypes.CLEAR_FORGOT_PASSWORD_STATE:
      return false
  }
  return state
})

export const forgotPasswordReducer = combineReducers({ loading, error, result })

// Selectors
export const forgotPasswordResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.auth.forgotPassword.result,
  (result) => result,
)
export const forgotPasswordLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.forgotPassword.loading,
  (loading) => loading,
)
export const forgotPasswordErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.forgotPassword.error,
  (error) => error,
)
