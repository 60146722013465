import React from 'react'
import styled from 'styled-components'
import { jt, t } from 'ttag'
import { LoginIdLengthConstraints, PasswordLengthConstraints } from 'fixtures/auth'

interface HelpTextContainerProps {
  maxWidthMarginTop?: string
}

export const HelpTextContainer = styled.div<HelpTextContainerProps>`
  @media all and (max-width: 1000px) {
    margin-left: 0em;
    margin-top: ${(props) => props.maxWidthMarginTop || '41em'};
  }

  position: absolute;
  margin-left: 34em;
  margin-top: 6em;
  width: 20em;
`

const HelpText = styled.div`
  color: grey;
`

interface AuthHelpTextProps {
  activeField: string
}

const AuthHelpText: React.FC<AuthHelpTextProps> = ({ activeField }) => {
  const loginField = 'login'
  const passwordField = 'password'
  const newPassword = 'newPassword'
  const { min: minPwdLen, max: maxPwdLen } = PasswordLengthConstraints
  const { min: minLoginLen, max: maxLoginLen } = LoginIdLengthConstraints
  return (
    <>
      {activeField === loginField && (
        <HelpText>{t`Please choose your unique personal enercast ID. It must have ${minLoginLen} to ${maxLoginLen} characters consist of only lower-case letters, digits, "-" and "_".`}</HelpText>
      )}
      {(activeField === passwordField || activeField === newPassword) && (
        <HelpText>{jt`Please create a new password. It must have ${minPwdLen} to ${maxPwdLen} characters and may consist of letters (case-sensitive), numbers, and special characters.`}</HelpText>
      )}
    </>
  )
}

export default AuthHelpText
