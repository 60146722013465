import { apiRequest } from 'utils/request'
import axios from 'axios'
import { ForecastModel } from 'modules/forecastModels/forecastModels.types'
import { Asset } from 'modules/asset/store/asset.types'
import { useQueries, useQuery } from 'react-query'
import { isGenerator, isParkWithNoPlants } from 'utils/asset'
import { convertSerializedForecastModelData } from 'utils/forecastModel'

export const QUERY_KEY_FORECAST_MODELS_BY_ASSETS = 'assets:forecastModelsByAssets'

const getForecastModelsByAsset = async (assetId: string) => {
  return await apiRequest(() =>
    axios.get<ForecastModel[]>(`/api/productconfig/v2/forecast-model/asset/${assetId}?category=all`),
  )
}

export const useForecastModelsByAsset = (asset: Asset) => {
  return useQuery<ForecastModel[]>(
    [QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset?.id],
    () => getForecastModelsByAsset(asset.id),
    { enabled: asset?.id ? true : false },
  )
}

export const useForecastModelsByAssets = (assets: Asset[], fetch = true) => {
  if (!assets.length || !fetch) return {}
  const queryOptions = assets
    ?.filter((a) => isGenerator(a) || isParkWithNoPlants(a))
    .map((asset) => {
      return {
        queryKey: [QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id],
        queryFn: () => {
          return getForecastModelsByAsset(asset.id)
        },
      }
    })

  return useQueries(queryOptions)
}

export const useForecastModelsForE3Optimization = (assets: Asset[]) => {
  if (!assets.length) return {}
  const forecastModelsQueryResult = useForecastModelsByAssets(assets)

  const allModels = forecastModelsQueryResult.reduce((prev, curr) => {
    return [...prev, ...curr?.data]
  }, [])

  // convert serialized data
  const transformedModels = allModels
    ?.filter((model) => Boolean(model))
    .map((forecastModel) => convertSerializedForecastModelData(forecastModel))

  const modelsByAssetId = assets.reduce((prev, asset) => {
    const assetModels = transformedModels.filter((model: ForecastModel) => model?.assetId === asset.id)
    return {
      ...prev,
      [asset.id]: assetModels,
    }
  }, {})
  // const transformForecastModelsQueryResult =
  return modelsByAssetId
}
