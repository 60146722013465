import { Plausibility } from 'modules/asset/plausability/Plausability.types'
import * as actionTypes from 'modules/asset/plausability/redux_store/plausability.action.types'

import { getAllPlausibility } from 'modules/asset/plausability/redux_store/plausability.api'
import { selectedStageSelector } from 'pages/workbench/store/revision.state'
import { STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { Result } from 'utils/request'

// sagas

export function* getAllPlausibilitySaga() {
  const selectedStage = yield select(selectedStageSelector)
  const stage = selectedStage || STAGE_PRODUCTIVE
  const result: Result<Plausibility[]> = yield call(getAllPlausibility, stage)
  if (result.isSuccessful) {
    const transformedData = result
      .getData()
      .reduce((prev, curr) => ({ ...prev, [curr.siteId]: { status: curr.status, results: curr.results } }), {})

    yield put({ type: actionTypes.GET_ALL_PLAUSIBILITY_SUCCESS, plausibilityData: transformedData })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_ALL_PLAUSIBILITY_FAILURE, error })
  }
}

export function* plausibilitySaga() {
  yield takeLatest(actionTypes.GET_ALL_PLAUSIBILITY_REQUEST, getAllPlausibilitySaga)
}
