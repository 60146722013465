import React, { useCallback, useMemo } from 'react'
import { SITE_FORECAST_COLUMN_WIDTH } from '../../../fixtures/assetTree'
import FixedWidthTextContainer from '../../../ui/styles/FixedWidthTextContainer'
import { getAssetSiteForecasts } from '../../../utils/asset'
import { useSiteForecastConfigs } from '../../dataStreams/api/siteForecastConfigs.api'
import { Asset } from '../store/asset.types'
import PopperTooltipContentSiteForecasts from 'ui/elements/PopperTooltipContentSiteForecasts'

interface SiteForecastCellProps {
  asset: Asset
}

const SiteForecastCell: React.FC<SiteForecastCellProps> = ({ asset }) => {
  const siteForecastConfigs = useSiteForecastConfigs()

  const assetSiteForecasts = useMemo(
    () => (siteForecastConfigs.data ? getAssetSiteForecasts(asset, siteForecastConfigs.data) : []),
    [siteForecastConfigs],
  )

  const siteForecastNames = useCallback(() => {
    const names = assetSiteForecasts.map((forecast) => forecast.name).join(', ')
    const tooltipContent = <PopperTooltipContentSiteForecasts siteForecasts={assetSiteForecasts} />

    return (
      <FixedWidthTextContainer
        showTooltipByDefault={true}
        toolTipContent={tooltipContent}
        text={names}
        width={SITE_FORECAST_COLUMN_WIDTH}
      >
        {names}
      </FixedWidthTextContainer>
    )
  }, [assetSiteForecasts])

  return <>{siteForecastNames()}</>
}

export default React.memo(SiteForecastCell)
