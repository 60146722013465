import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

export interface ResendConfirmationType {
  email: string
}

export interface ResendConfirmationActionType {
  type: actionTypes.RESEND_CONFIRMATION_ACTION_TYPE
  resendConfirmationData: ResendConfirmationType
}

interface State {
  loading: boolean
  error: string | null
  result: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  result: false,
}

const loading = createReducer<State['loading'], ResendConfirmationActionType>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.RESEND_CONFIRMATION_REQUEST:
        return true
      case actionTypes.RESEND_CONFIRMATION_SUCCESS:
      case actionTypes.RESEND_CONFIRMATION_FAILURE:
      case actionTypes.CLEAR_RESEND_CONFIRMATION_STATE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'], ResendConfirmationActionType & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.RESEND_CONFIRMATION_SUCCESS:
      case actionTypes.RESEND_CONFIRMATION_DISMISS:
      case actionTypes.CLEAR_RESEND_CONFIRMATION_STATE:
        return null
      case actionTypes.RESEND_CONFIRMATION_FAILURE:
        return error
    }
    return state
  },
)

const result = createReducer<State['result'], ResendConfirmationActionType>((state = initialState.result, { type }) => {
  switch (type) {
    case actionTypes.RESEND_CONFIRMATION_SUCCESS:
      return true
    case actionTypes.RESEND_CONFIRMATION_FAILURE:
    case actionTypes.CLEAR_RESEND_CONFIRMATION_STATE:
      return false
  }
  return state
})

export const resendConfirmationReducer = combineReducers({ loading, error, result })

// Selectors
export const resendConfirmationResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.auth.resendConfirmation.result,
  (result) => result,
)
export const resendConfirmationLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.resendConfirmation.loading,
  (loading) => loading,
)
export const resendConfirmationErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.resendConfirmation.error,
  (error) => error,
)
