import { combineReducers } from 'redux'
import { takeEvery } from 'redux-saga/effects'

import { GET_WEATHERCONFIGS_REQUEST, getWeatherConfigsReducer, getWeatherConfigsSaga } from './getWeatherConfig.state'

export const weatherReducer = combineReducers({
  getWeatherConfigs: getWeatherConfigsReducer,
})

export function* weatherSaga() {
  yield takeEvery(GET_WEATHERCONFIGS_REQUEST, getWeatherConfigsSaga)
}
