import { Asset } from 'modules/asset/store/asset.types'
import { getMeterdataSummariesResultSelector } from 'modules/asset/store/getMeterdataSummaries.state'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import FileManagerTable from 'modules/data/fileManager/FileManagerTable'

const SectionMeterDataContainer = styled.div`
  padding-top: 0.5em;
  height: calc(100vh - 12em);
`

interface AssetMeterDataTableProps {
  asset: Asset
}

const AssetMeterDataTable: React.FC<AssetMeterDataTableProps> = ({ asset }) => {
  const summaries = useSelector(getMeterdataSummariesResultSelector)

  return (
    <SectionMeterDataContainer>
      <FileManagerTable
        showSearchField={false}
        assetsToBeDisplayed={[asset]}
        summary={(summaries || {})[asset.id]}
        toolTip={false}
      />
    </SectionMeterDataContainer>
  )
}

export default React.memo(AssetMeterDataTable)
