import React from 'react'
import { c } from 'ttag'
import { Button, Typography } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { TimePeriodToHighlightInterface } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import ExcludedTimePeriodsTable from 'modules/asset/assetCrud/meterDataCleansing/ExcludedPeriodsTable/ExcludedTimePeriodsTable'
import styled from 'styled-components'
import { Timezone } from 'fixtures/timezones'

const ExcludedTimePeriodDiv = styled(Flex)`
  height: calc((((100vh - 16em) / 2) * 100) / 100);
  min-height: 15em;
  flex: 1 1 0;
  margin-top: 1em;
`

interface ExcludedTimePeriodsFromChartProps {
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  onChangeTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onCancelEditExcludedTimePeriod: () => void
  onSaveExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteAllExcludedTimePeriods: () => void
  userTimezone: Timezone
  linkToDefault: boolean
}

const ExcludedTimePeriodsFromChart: React.FC<ExcludedTimePeriodsFromChartProps> = ({
  timePeriodToExcludeFromChart,
  onChangeTimePeriod,
  timePeriodToEdit,
  onEditExcludedTimePeriod,
  onCancelEditExcludedTimePeriod,
  onSaveExcludedTimePeriod,
  onDeleteExcludedTimePeriod,
  onDeleteAllExcludedTimePeriods,
  userTimezone,
  linkToDefault,
}) => {
  return (
    <ExcludedTimePeriodDiv direction="column">
      <Flex direction="row" justifyContent="space-between">
        <Typography variant="subtitle1">{c('Meter data cleansing').t`Excluded time period`}</Typography>
        {timePeriodToExcludeFromChart.length > 0 && (
          <Button onClick={onDeleteAllExcludedTimePeriods} color="primary" size="small">
            {c('Meter data cleansing').t`Reset all`}
          </Button>
        )}
      </Flex>
      <ExcludedTimePeriodsTable
        timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
        onChangeTimePeriod={onChangeTimePeriod}
        timePeriodToEdit={timePeriodToEdit}
        onEditExcludedTimePeriod={onEditExcludedTimePeriod}
        onCancelEditExcludedTimePeriod={onCancelEditExcludedTimePeriod}
        onSaveExcludedTimePeriod={onSaveExcludedTimePeriod}
        onDeleteExcludedTimePeriod={onDeleteExcludedTimePeriod}
        userTimezone={userTimezone}
        linkToDefault={linkToDefault}
      />
    </ExcludedTimePeriodDiv>
  )
}

export default React.memo(ExcludedTimePeriodsFromChart)
