import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'

export interface ProductionAvailabilityAnalysis {
  remove: boolean
  value: number
}

export interface MeterDataCleansingFilterSettings {
  range: number
  repetitive?: number
  outlierLeft: number
  outlierRight: number
  ghiThresholdNoPower?: number
}

export interface BulkMeterDataCleansingFilterSettings {
  wind: MeterDataCleansingFilterSettings
  solar: MeterDataCleansingFilterSettings
}

export interface MeterDataCleansingConfiguration extends MeterDataCleansingFilterSettings {
  totalTimePeriod: MDCTimePeriod
  timePeriodForTraining: MDCTimePeriod
  availabilityAnalysis: {
    cappedProduction: ProductionAvailabilityAnalysis
    scaledProduction: ProductionAvailabilityAnalysis
    applyUpscaling: boolean
  }
  excludedTimePeriods: TimePeriodToHighlightInterface[]
  multipleGridPoints: boolean
  filterNightValues?: boolean
  binSize?: number
  filterSnowyDays?: boolean
  powerThresholdNoReference?: boolean
}

export interface FindAllCleansingFilterSettings {
  specificFilterSetings: boolean
  cleansingParams?: {
    timePeriodForTraining?: any
    range: number
    repetitive: number
    outlierLeft: number
    outlierRight: number
  }
}

export interface UserMdcTrainingData {
  user: string
  setting: string
}

export enum MDCAssetType {
  SOLAR = 'solar',
  WIND = 'wind',
}
