import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { Field } from 'react-final-form'
import { c } from 'ttag'
import SiteForecastDurationField from 'ui/elements/SiteForecastDurationField'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import FieldError from 'ui/form/FieldError'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'

const SectionDeliveryWindowSettings: React.FC = () => {
  return (
    <>
      <Typography variant="subtitle2">
        <Flex>
          <>{c('Delivery').t`Delivery window`}</>
          <PopperTooltip
            popperLabel={
              <Box ml={0.7}>
                <FontAwesomeIcon icon="info" />
              </Box>
            }
            popperContent={
              <div>
                {c('Delivery')
                  .t`Physical delivery of the forecast should be expected within the delivery window, but no specific timing is guaranteed. No delivery attempt will be made prior to the start of the delivery window. If delivery was not successful by the end of the delivery window, no further attempts will be made and delivery will fail.`}
              </div>
            }
          />
        </Flex>
      </Typography>
      <Flex direction="column">
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`Start`}:</FormKey>
          <FormValue>
            <Field name="updateTimes.earliestAttemptOfDelivery">
              {({ input, meta }) => (
                <Flex direction="row" alignItems="center">
                  <SiteForecastDurationField
                    step={5}
                    minMaxValuesHandle={false}
                    durationText={c('Delivery').t`[min before/after delivery time]`}
                    input={input}
                    timeInput={true}
                  />
                  {meta.error && <FieldError error={meta.error} />}
                </Flex>
              )}
            </Field>
          </FormValue>
        </FormKeyValueGrid>
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`End`}:</FormKey>
          <FormValue>
            <Field name="updateTimes.latestAttemptOfDelivery">
              {({ input, meta }) => (
                <Flex direction="row" alignItems="center">
                  <SiteForecastDurationField
                    step={5}
                    minMaxValuesHandle={false}
                    durationText={c('Delivery').t`[min before/after delivery time]`}
                    input={input}
                    timeInput={true}
                  />
                  {meta.error && <FieldError error={meta.error} />}
                </Flex>
              )}
            </Field>
          </FormValue>
        </FormKeyValueGrid>
      </Flex>
    </>
  )
}

export default React.memo(SectionDeliveryWindowSettings)
