import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import {
  RETABLE_ID_AVAILABLE_FORECAST_TEMPLATES,
  ReTableContextMenuItemName,
  ReTableRowContextActions,
} from 'modules/reTable/reTable.types'
import { useDispatch, useSelector } from 'react-redux'
import { getSiteForecastTemplatesColumns } from 'utils/dataStream'
import {
  getSiteForecastListContextMenuItems,
  saveSiteForecastAsJSON,
  TemplateIds,
} from 'utils/siteForecastConfiguration'
import {
  getSiteForecastTemplateQueryObj,
  QUERY_COPY_SITE_FORECAST_TEMPLATE,
  QUERY_NEW_SCHEDULE,
  QUERY_NEW_SITE_FORECAST_TEMPLATE,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import Flex from 'ui/styles/Flex'
import { Box, Button } from '@material-ui/core'
import { c, t } from 'ttag'
import ReTableForecastsManagement, { ForecastType } from 'modules/reTable/ReTableForecastsManagement'
import { table, theme, useCustomMuiStyles } from 'themes/theme-light'
import { ForecastConfig, SelectedMenuData } from 'modules/dataStreams/dataStreams.types'
import {
  useSiteForecastConfigTemplateDeleteMutation,
  useSiteForecastConfigTemplates,
} from 'modules/dataStreams/api/siteForecastConfigTemplates.api'

import SiteForecastPreview from 'modules/dataStreams/siteForecast/SiteForecastPreview'
import LayoutTitle from 'ui/LayoutTitle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isAdmin } from 'utils/user'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import styled from 'styled-components'
import Alert from '@material-ui/lab/Alert/Alert'
import { DataStreamListContainer } from 'modules/dataStreams/ManageDataStreams'

const UploadInput = styled.input`
  display: none;
`

const ImportConfigFile = styled.label`
  display: inline-block;
  cursor: pointer;
  color: ${theme.palette.primary.main};
  width: fit-content;
  padding: 7px 0;
  margin-right: 3px;
`

interface SiteForecastListProps {
  onImportConfig: (config: ForecastConfig) => void
}

const SiteForeCastList: React.FC<SiteForecastListProps> = ({ onImportConfig }) => {
  //Style
  const classes = useCustomMuiStyles()
  const { queryParams } = useQueryParams()

  const dispatch = useDispatch()
  const user = useSelector(getUserResultSelector)
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)
  const [selectedMenuData, setSelectedMenuData] = useState<SelectedMenuData | null>(null)
  const { onUpdateQueryString } = useQueryString()
  const contextMenuItems = useMemo(() => getSiteForecastListContextMenuItems(), [])
  const siteForecastConfigTemplatesResult = useSiteForecastConfigTemplates()

  const isNewSchedule = useMemo(() => Boolean(queryParams[QUERY_NEW_SCHEDULE]), [queryParams])

  // We don't need to show site forecast template which has maximumHorizon set to true , because it's handle automatically in BE , that's why we are filtering
  const siteForecastConfigTemplates = useMemo(() => {
    const sortedConfigs = (siteForecastConfigTemplatesResult?.data || [])
      .filter((siteForecastTemplate) => !siteForecastTemplate.maximumHorizon)
      .sort((a, b) => {
        const valueA = a.name.toUpperCase()
        const valueB = b.name.toUpperCase()
        if (valueA < valueB) return -1
        if (valueA > valueB) return 1
        return 0
      })
    return sortedConfigs || []
  }, [siteForecastConfigTemplatesResult?.data])

  const availableSiteForecastTemplateColumns = useMemo(() => getSiteForecastTemplatesColumns(), [])

  const [availableSiteForecastToRender, setAvailableSiteForecastToRender] = useState<ForecastConfig[]>([])
  const [selectedForecast, setSelectedForecast] = useState<ForecastConfig | null>(null)
  const handleAvailableSiteForecastToRenderChange = useCallback((items: ForecastConfig[]) => {
    requestAnimationFrame(() => {
      setAvailableSiteForecastToRender(items)
    })
  }, [])

  const { EDIT_ROW, COPY_ROW, DELETE_ROW, SAVE_TEMPLATE_AS_FILE_MENU_ITEM } = ReTableRowContextActions

  const handleSelectForecast = useCallback((forecast: ForecastConfig) => {
    setSelectedForecast(forecast)
  }, [])

  const handleAddNewTemplate = useCallback(() => {
    const queryData = {
      ...getSiteForecastTemplateQueryObj(TemplateIds.NEW),
      [QUERY_NEW_SITE_FORECAST_TEMPLATE]: true,
    }
    onUpdateQueryString(queryData)
  }, [onUpdateQueryString])

  const handleEditTemplate = useCallback(
    (item: ForecastType) => {
      onUpdateQueryString(getSiteForecastTemplateQueryObj(item.id))
    },
    [onUpdateQueryString],
  )

  const handleCopyTemplate = useCallback(
    (item: ForecastType) => {
      const queryData = {
        ...getSiteForecastTemplateQueryObj(item.id),
        [QUERY_NEW_SITE_FORECAST_TEMPLATE]: true,
        [QUERY_COPY_SITE_FORECAST_TEMPLATE]: true,
      }
      onUpdateQueryString(queryData)
    },
    [onUpdateQueryString],
  )

  const { mutate: deleteForecastConfigTemplateMutation } = useSiteForecastConfigTemplateDeleteMutation()

  const handleDeleteTemplate = useCallback((item: ForecastType) => {
    deleteForecastConfigTemplateMutation(item.id)
  }, [])

  const handleSaveTemplateAsFile = useCallback((item: ForecastType) => {
    saveSiteForecastAsJSON(item)
  }, [])

  const handleImportTemplateFiled = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileReader = new FileReader()
      if (e && e?.target && e?.target?.files?.length) {
        fileReader.readAsText(e.target.files[0], 'UTF-8')
        fileReader.onload = (e) => {
          const configData = JSON.parse(e.target.result)
          if (configData.id) delete configData.id
          delete configData.deleted
          onImportConfig(configData)
        }
      }
    },
    [onImportConfig],
  )

  const proceedAction = useCallback((menuData: SelectedMenuData) => {
    const { itemName, item } = menuData

    switch (itemName) {
      case EDIT_ROW:
        handleEditTemplate(item)
        break
      case COPY_ROW:
        handleCopyTemplate(item)
        break
      case DELETE_ROW:
        handleDeleteTemplate(item)
        break
      case SAVE_TEMPLATE_AS_FILE_MENU_ITEM:
        handleSaveTemplateAsFile(item)
        break
    }
    setSelectedMenuData(null)
  }, [])

  const handleClickContextMenuItem = useCallback((itemName: ReTableContextMenuItemName, item: ForecastType) => {
    const menuData = { itemName: itemName, item: item }
    setSelectedMenuData(menuData)
    if (itemName === EDIT_ROW || itemName === DELETE_ROW) {
      setOpenConfirmDialog(true)
    } else {
      proceedAction(menuData)
    }
  }, [])

  const handleConfirmAction = useCallback(() => {
    setOpenConfirmDialog(false)
    if (selectedMenuData) {
      proceedAction(selectedMenuData)
    }
  }, [proceedAction, selectedMenuData])

  const handleCancelAction = useCallback(() => {
    setOpenConfirmDialog(false)
    setSelectedMenuData(null)
  }, [])

  const actions = useMemo(() => {
    return (
      <>
        {isAdmin(user) && (
          <>
            <ImportConfigFile className={classes.adminComponent}>
              <Flex>
                <Box mr={0.5}>
                  <FontAwesomeIcon icon="upload" />
                </Box>

                {c('Site Forecast:Import Config').t`IMPORT`}
              </Flex>
              <UploadInput type="file" accept=".productConfigTemplate" onChange={handleImportTemplateFiled} />
            </ImportConfigFile>
            <Button
              size="small"
              color="primary"
              startIcon={<FontAwesomeIcon icon="plus" />}
              onClick={handleAddNewTemplate}
              className={classes.adminComponent}
            >
              {t`Add`}
            </Button>
          </>
        )}
      </>
    )
  }, [user])

  const dialogHeading = useCallback(() => {
    if (!selectedMenuData) return ''
    const heading =
      selectedMenuData.itemName === DELETE_ROW
        ? c('Site Forecast: List Actions').t`Delete template`
        : selectedMenuData.itemName === EDIT_ROW
        ? c('Site Forecast: List Actions').t`Edit template`
        : ''
    return heading
  }, [selectedMenuData])

  const dialogContent = useCallback(() => {
    if (!selectedMenuData) return ''
    const content =
      selectedMenuData.itemName === DELETE_ROW
        ? c('Site Forecast: List Actions').t` Do you want to delete this template?`
        : selectedMenuData.itemName === EDIT_ROW
        ? c('Site Forecast: List Actions').t` Do you want to edit this template?`
        : ''
    return (
      <div>
        <Box mb={1}>{content}</Box>

        <Alert severity="warning" variant="filled">
          <div>{t`This is an enercast support access that changes templates of ALL users.`}</div>
        </Alert>
      </div>
    )
  }, [selectedMenuData])

  const getLayoutTitle = () => {
    if (isNewSchedule) {
      return c('Site Forecast:Preview').t`Add Schedule`
    }
    return c('Site Forecast:Preview').t`Add Site Forecast`
  }

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_AVAILABLE_FORECAST_TEMPLATES,
      columnsAvailable: availableSiteForecastTemplateColumns,
    })
  }, [availableSiteForecastTemplateColumns])

  return (
    <>
      {openConfirmDialog && (
        <ConfirmationDialog
          heading={dialogHeading()}
          text={dialogContent()}
          confirmAction={t`Ok`}
          cancelAction={t`Cancel`}
          onConfirm={handleConfirmAction}
          onCancel={handleCancelAction}
          openDialog={openConfirmDialog}
        />
      )}

      <Flex direction="row">
        <DataStreamListContainer className="appTour-createSiteForecast-selectTemplate" direction="column" fullHeight>
          <LayoutTitle>
            <Box>{getLayoutTitle()}</Box>
          </LayoutTitle>
          <ReTableForecastsManagement
            tableId={RETABLE_ID_AVAILABLE_FORECAST_TEMPLATES}
            itemHeight={table.rowHeight}
            items={siteForecastConfigTemplates}
            itemsToRender={availableSiteForecastToRender}
            onItemsToRenderChange={handleAvailableSiteForecastToRenderChange}
            columns={availableSiteForecastTemplateColumns}
            onSelectItem={handleSelectForecast}
            selectedItem={selectedForecast}
            hasAuthority={isAdmin(user)}
            icon="plus"
            actions={actions}
            onClickMenuItem={handleClickContextMenuItem}
            contextMenuItems={contextMenuItems}
          />
        </DataStreamListContainer>

        <SiteForecastPreview siteForecast={selectedForecast} />
      </Flex>
    </>
  )
}

export default React.memo(SiteForeCastList)
