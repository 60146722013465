import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField as MaterialTextField,
  useMediaQuery,
} from '@material-ui/core'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import { Autocomplete } from '@react-google-maps/api'
import { FormApi } from 'final-form'
import { TextField } from 'final-form-material-ui'
import { defaultLatLng, TrackerNamesEnum } from 'fixtures/assetForm'
import { countryOptions } from 'fixtures/countries'
import compassArrow from 'media/compass-arrow.svg'

import compassRose from 'media/compass-rose.svg'
import { useAllAssets } from 'modules/asset/api/assets.api'

import { Asset, AssetLocation, Coordinate, LocationAsset } from 'modules/asset/store/asset.types'
import { getWeatherCoordinatesResultSelector } from 'modules/data/store/getWeatherCoordinates.state'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { transitionFor } from 'themes/theme-light'

import { t } from 'ttag'
import { AutoCompleteWithoutUnit, NumberField } from 'ui/form/assetForm.style'
import GoogleMaps, { WeatherCoordinate } from 'ui/GoogleMaps'
import Flex from 'ui/styles/Flex'
import { useDebouncedCallback } from 'use-debounce'

import {
  getAddress,
  getCoords,
  getDescendants,
  isCluster,
  isGenerator,
  isLocationAsset,
  isPark,
  isSolarPark,
  isSolarPlant,
  isWindPlant,
} from 'utils/asset'
import { isNumeric } from 'utils/dataFormatting'
import { GeocoderAddressComponentType } from 'utils/map'
import { LARGE_SCREEN_MEDIA_QUERY, LARGE_SCREEN_MEDIA_QUERY_HEIGHT } from 'ui/styles/styleVariables'

// state to keep track sync
let coordsTriggeredSync = false
let addressTriggeredSync = false

// constants
const { none, hsat, tsat, vsat, ttdatAadat } = TrackerNamesEnum
const azimuthField = 'typeSpecificAttributes.azimuth'
const azimuthFromField = 'typeSpecificAttributes.trackerAzimuthFrom'
const azimuthToField = 'typeSpecificAttributes.trackerAzimuthTo'

// styled components

const CompassButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 10px;
    background: white;
    position: absolute;
    z-index: 3;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    font-size: 18px;
    &:hover {
      background-color: #fff;
    }
  }
`

const HybridMapContainer = styled(Flex)`
  position: relative;
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    margin-left: 7px;
  }
`

const MapOverlayContainer = styled(Flex)`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  height: 100%;
`

const OverlayCompassRose = styled.img`
  pointer-events: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
`

interface OverlayCompassArrowProps {
  rotationAngle: number
}
const OverlayCompassArrow = styled.img<OverlayCompassArrowProps>`
  pointer-events: none;
  transform: rotate(${(props) => props.rotationAngle}deg);
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`

const ContainerFlex = styled(Flex)`
  width: 100%;
  padding-top: 2px;
`

const MapContainer = styled.div`
  width: 100%;
  margin-bottom: 1em;
  min-height: 17em;
  position: relative;
  flex-grow: 1;
  height: 23%;
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    width: auto;
    height: 98%;
  }
`

const StreetAddressField = styled.div`
  margin-top: 0.3em;
`

const OrientationDropDown = styled(Select)`
  margin-right: 0.5em;
`

const LocationSearchContainer = styled.div`
  width: 100%;
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    padding-right: 3px;
  }
`

const GoogleSearchFlex = styled(Flex)`
  width: 100%;
`

const GoogleSearchInput = styled.input`
  width: 100%;
  min-width: 20em;
  height: 32px;
  padding: 0 12px;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: inherit;
  outline: none;
  text-overflow: ellipsis;
  font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`

const SliderContainer = styled(Flex)`
  width: 100%;
  padding: 2px 10px;
  margin-bottom: 1em;
  border-radius: 4px;
  background: #f3f3f3;
`

interface SliderWrapperProps {
  enabled: boolean
}
const SliderWrapper = styled(Flex)<SliderWrapperProps>`
  width: 100%;
  height: ${(props) => (props.enabled ? '42px' : '0')};
  transition: ${transitionFor(['height'], 300)};
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    width: 50%;
    padding-left: 3px;
  }
`

// exported component

interface SectionLocationProps {
  asset: Asset
  form: FormApi<Asset>
  formField: string
  enterManually: boolean
  onFormChangeExternally: () => void
}

const SectionLocation: React.FC<SectionLocationProps> = ({
  asset,
  form,
  formField,
  enterManually,
  onFormChangeExternally,
}) => {
  const [formActive, setFormActive] = useState()
  const [formState, setFormState] = useState(asset)

  // It's related to properly show map section in large screens.
  const matchesWidth = useMediaQuery(`(min-width:${LARGE_SCREEN_MEDIA_QUERY})`)
  const matchesHeight = useMediaQuery(`(min-height:${LARGE_SCREEN_MEDIA_QUERY_HEIGHT})`)

  const trackerName = formState?.typeSpecificAttributes?.trackerName || none
  const [trackerHasStartAndEnd, setTrackerHasStartAndEnd] = useState(false)

  const weatherCoords = useSelector(getWeatherCoordinatesResultSelector)

  const allAssets = useAllAssets()
  const descendants: LocationAsset[] = useMemo(() => {
    if (!formState?.id) return []
    return getDescendants(formState, allAssets.data || []) as LocationAsset[]
  }, [allAssets.data, formState?.id])
  const descendantsWithCoords = useMemo(() => {
    return isGenerator(formState)
      ? []
      : !enterManually
      ? descendants.filter(
          (asset) =>
            asset.location.coordinate && asset.location.coordinate.longitude && asset.location.coordinate.latitude,
        )
      : []
  }, [enterManually, descendants, formState?.type])

  const [coords, setCoords] = useState(getCoords(asset))
  const [activeOrientationField, setActiveOrientationField] = useState<string>(azimuthField)
  const [sliderValue, setSliderValue] = useState<number>(0)
  // const [weatherCoordinates, setWeatherCoordinates] = useState<WeatherCoordinate[]>([])
  const [showCompass, setShowCompass] = useState(false)

  // const [showWeatherCoords, setShowWeatherCoords] = useState(false)
  const [synchronizeEnabled, setSynchronizeEnabled] = useState(false)

  const handleToggleSynchronized = useCallback(() => {
    setSynchronizeEnabled((enabled) => !enabled)
  }, [])

  const roadMapConfig = useMemo(() => {
    return {
      options: { mapTypeControl: false, mapTypeId: 'roadmap', scaleControl: true },
      zoom: 6,
      labelColor: 'black',
    }
  }, [])
  const hybridMapConfig = useMemo(() => {
    return {
      options: { mapTypeControl: false, mapTypeId: 'hybrid', scaleControl: true },
      zoom: 16,
      labelColor: 'white',
    }
  }, [])

  const handleAddressFromPlace = useCallback((place: google.maps.GeocoderResult) => {
    // prepare google place result
    const assetLocation: Partial<AssetLocation> = {}
    const ac = place.address_components.reduce<
      Partial<Record<GeocoderAddressComponentType, google.maps.GeocoderAddressComponent>>
    >((previous, addrComponent) => {
      return {
        ...previous,
        [addrComponent.types[0]]: addrComponent,
      }
    }, {})

    // convert place result to asset location
    if (ac.street_address) {
      assetLocation.address = ac.street_address.long_name
    } else if (ac.route) {
      assetLocation.address = `${ac.route.long_name}${ac.street_number ? ' ' + ac.street_number.long_name : ''}`
    }

    assetLocation.city =
      ac.locality?.long_name ||
      ac.administrative_area_level_5?.long_name ||
      ac.administrative_area_level_4?.long_name ||
      ac.administrative_area_level_3?.long_name ||
      ac.administrative_area_level_2?.long_name ||
      ac.administrative_area_level_1?.long_name

    assetLocation.zip = ac.postal_code?.long_name
    assetLocation.country = ac.country?.long_name
    assetLocation.state = ac.administrative_area_level_1?.long_name

    // set address in form

    form.mutators.setValues([
      {
        field: 'location.address',
        value: assetLocation.address || '',
      },
      {
        field: 'location.city',
        value: assetLocation.city || '',
      },
      {
        field: 'location.zip',
        value: assetLocation.zip || '',
      },
      {
        field: 'location.country',
        value: assetLocation.country || '',
      },
      {
        field: 'location.state',
        value: assetLocation.state || '',
      },
    ])
    coordsTriggeredSync = false
  }, [])

  const changeFormCoords = useCallback((lat: number, lng: number): void => {
    form.mutators.setValues([
      {
        field: 'location.coordinate.latitude',
        value: lat,
      },
      {
        field: 'location.coordinate.longitude',
        value: lng,
      },
    ])
  }, [])

  const handleCoordsFromPlace = useCallback(
    (place: google.maps.GeocoderResult) => {
      if (!place.geometry?.location) return
      const { lat, lng } = place.geometry.location
      changeFormCoords(lat(), lng())
    },
    [changeFormCoords],
  )

  const geocodeLookupFromCoords = useCallback(
    (location: google.maps.LatLng) => {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ location }, (results, status) => {
        if (status === 'OK') {
          if (results.length === 0) return

          const place = results[0]
          handleAddressFromPlace(place)
        } else {
          console.error('geocode request was not successful: ' + status)
        }
      })
    },
    [handleAddressFromPlace],
  )
  const debouncedGeocodeLookupFromCoords = useDebouncedCallback(geocodeLookupFromCoords, 400)

  const geocodeLookupFromAddress = useCallback(
    (address: string) => {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          if (results.length === 0) return

          const place = results[0]
          handleCoordsFromPlace(place)
        } else {
          console.error('geocode request was not successful: ' + status)
        }
      })
    },
    [handleCoordsFromPlace],
  )
  const debouncedGeocodeLookupFromAddress = useDebouncedCallback(geocodeLookupFromAddress, 400)

  const handleAutocompletePlaceChange = useCallback(
    (place: google.maps.GeocoderResult) => {
      handleCoordsFromPlace(place)

      if (synchronizeEnabled) {
        handleAddressFromPlace(place)
      }
    },
    [synchronizeEnabled],
  )

  const autocompleteRef = useRef(null)

  const handleAutocompleteLoaded = useCallback((ref) => {
    autocompleteRef.current = ref
  }, [])

  const handleAutocompletePlaceChanged = useCallback(() => {
    if (!autocompleteRef.current) return
    const place: google.maps.GeocoderResult = autocompleteRef.current.getPlace()
    handleAutocompletePlaceChange(place)
  }, [autocompleteRef.current, handleAutocompletePlaceChange])

  useEffect(() => {
    if (!isLocationAsset(formState)) return

    const coordsData: Coordinate = defaultLatLng()
    if (formState.location && formState.location.coordinate) {
      if (formState.location.coordinate.latitude) {
        coordsData.latitude = parseFloat(formState.location.coordinate.latitude)
      }
      if (formState.location.coordinate.longitude) {
        coordsData.longitude = parseFloat(formState.location.coordinate.longitude)
      }
    }
    setCoords(coordsData)

    if (!synchronizeEnabled) return

    if (addressTriggeredSync) {
      addressTriggeredSync = false
      return
    }

    coordsTriggeredSync = true
    const latLng = new google.maps.LatLng(coordsData.latitude, coordsData.longitude)
    debouncedGeocodeLookupFromCoords(latLng)
  }, [
    synchronizeEnabled,
    formState?.type,
    formState?.location?.coordinate.latitude,
    formState?.location?.coordinate.longitude,
  ])

  useEffect(() => {
    if (asset.id) {
      setCoords(getCoords(asset))
    }
  }, [asset.id])

  const handleDragEnd = useCallback(
    (args: any): void => {
      const lat = args.latLng.lat()
      const lng = args.latLng.lng()
      changeFormCoords(lat, lng)

      // if (synchronizeEnabled) {
      //   debouncedGeocodeLookupFromCoords(args.latLng)
      // }
    },
    [changeFormCoords],
  )

  // const address = useMemo(() => getAddress(asset), [asset])

  // const handleCoordsChangeByUser = () => {
  //   if (!synchronizeEnabled || !coords?.latitude || !coords?.longitude) return
  //   debouncedGeocodeLookupFromCoords(new google.maps.LatLng(coords.latitude, coords.longitude))
  // }

  const handleAddressChangeByUser = useCallback(
    (value, previous, field: keyof AssetLocation) => {
      if (!synchronizeEnabled || coordsTriggeredSync) return

      const addr = getAddress(formState)
      addr[field] = value
      const addrString = `${addr?.address} ${addr?.zip} ${addr?.city} ${addr?.country}`
      addressTriggeredSync = true
      debouncedGeocodeLookupFromAddress(addrString)
    },
    [synchronizeEnabled, formState],
  )

  const handleCountrySelection = (event: React.ChangeEvent, country: string) => {
    form.mutators.setValue('location.country', country)
    onFormChangeExternally()
  }

  // const handleWeatherCoordinates = useCallback(() => {
  //   setShowWeatherCoords(showWeatherCoords => !showWeatherCoords)
  // }, [])

  const handleToggleCompass = useCallback(() => {
    setShowCompass((showCompass) => !showCompass)
  }, [])

  // console.log('form state =', form.getState())
  const handleSliderChange = useCallback(
    (event: React.ChangeEvent, value: number) => {
      if (isSolarPlant(formState) || (isSolarPark(formState) && enterManually)) {
        form.mutators.setValue(activeOrientationField, value)
      }
      setSliderValue(value)
    },
    [form, formState, activeOrientationField],
  )

  const [sliderActive, setSliderActive] = useState(false)
  const handleSliderFocus = useCallback(() => {
    setSliderActive(true)
  }, [])
  const handleSliderBlur = useCallback(() => {
    setSliderActive(false)
  }, [])

  const handleOrientationChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const value = event.target.value as string
      setActiveOrientationField(value)
      if (value === azimuthFromField) {
        setSliderValue(formState?.typeSpecificAttributes?.trackerAzimuthFrom)
      } else if (value === azimuthToField) {
        setSliderValue(formState?.typeSpecificAttributes?.trackerAzimuthTo)
      }
    },
    [formState],
  )
  useEffect(() => {
    if (weatherCoords && Object.keys(weatherCoords)?.length) {
      const tempWeatherCoords: WeatherCoordinate[] = []
      Object.keys(weatherCoords).forEach((key: string) => {
        weatherCoords[key].forEach((coord: Coordinate) => {
          if (coord.latitude && coord.longitude) {
            const data = {
              position: { lat: coord?.latitude, lng: coord.longitude },
              provider: key,
            }
            tempWeatherCoords.push(data)
          }
        })
      })
      // setWeatherCoordinates(tempWeatherCoords)
    }
  }, [weatherCoords])

  // useEffect(() => {
  //   if (showWeatherCoords && isGenerator(formState)) {
  //     dispatch({ type: GET_WEATHER_COORDINATES_REQUEST, location: formState?.location?.coordinate })
  //   }
  // }, [showWeatherCoords, formState?.type])

  const activeFieldIsOrientation = useMemo(() => {
    const activeField = formActive
    return [azimuthField, azimuthFromField, azimuthToField].includes(activeField)
  }, [formActive])

  const compassIsVisible = useMemo(() => {
    return showCompass || activeFieldIsOrientation || sliderActive
  }, [showCompass, activeFieldIsOrientation, sliderActive])

  useEffect(() => {
    const activeField = formActive
    // When field is active sync input with compass and slider
    if (activeFieldIsOrientation) {
      setActiveOrientationField(activeField)

      const [typeSpecificAttribute, field] = activeField.split('.')
      const orientationValue = parseInt(formState[typeSpecificAttribute][field])
      if (isNumeric(orientationValue)) {
        setSliderValue(orientationValue)
      }
    }
  }, [formActive, activeFieldIsOrientation, formState?.typeSpecificAttributes])

  useEffect(() => {
    // When form is loaded
    // const trackerValues = defaultTrackerValues(trackerName || none)
    if (trackerName === none || trackerName === hsat) {
      setActiveOrientationField(azimuthField)
      setTrackerHasStartAndEnd(false)
      setSliderValue(
        isNumeric(formState?.typeSpecificAttributes?.azimuth) ? formState?.typeSpecificAttributes?.azimuth : 0,
      )
    } else if (trackerName === tsat || trackerName === vsat || trackerName === ttdatAadat) {
      setActiveOrientationField(azimuthFromField)
      setTrackerHasStartAndEnd(true)
      setSliderValue(
        isNumeric(formState?.typeSpecificAttributes?.trackerAzimuthFrom)
          ? formState?.typeSpecificAttributes?.trackerAzimuthFrom
          : -180,
      )
    }
  }, [trackerName, formState?.typeSpecificAttributes?.azimuth, formState?.typeSpecificAttributes?.trackerAzimuthFrom])

  return (
    <ContainerFlex display="flex" direction="column">
      <FormSpy
        subscription={{ active: true }}
        onChange={({ active }) => {
          setFormActive(active)
        }}
      />
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as Asset)
        }}
      />
      <Flex
        direction={matchesWidth ? 'row' : 'column'}
        justifyContent="center"
        largeScreenFlexGrow={matchesHeight ? 0.47 : 0.288}
      >
        <MapContainer>
          <GoogleMaps
            assets={descendantsWithCoords}
            mapLocation={descendantsWithCoords.length > 0 ? undefined : coords}
            zoom={roadMapConfig.zoom}
            options={roadMapConfig.options}
            onDragMarker={handleDragEnd}
            labelColor={roadMapConfig.labelColor}
          />
        </MapContainer>
        <HybridMapContainer
          direction={matchesWidth ? 'row' : 'column'}
          alignItems={matchesWidth ? 'flex-start' : 'center'}
          justifyContent={matchesWidth ? 'start' : 'center'}
          flexGrow="1"
        >
          <MapContainer>
            {compassIsVisible && (
              <Flex justifyContent="center">
                <MapOverlayContainer>
                  <OverlayCompassRose src={compassRose} />
                </MapOverlayContainer>

                <MapOverlayContainer>
                  <OverlayCompassArrow rotationAngle={sliderValue} src={compassArrow} />
                </MapOverlayContainer>
              </Flex>
            )}

            <CompassButton
              edge="end"
              color={showCompass ? 'primary' : 'default'}
              aria-label="Toggle Compass"
              onClick={handleToggleCompass}
            >
              <FontAwesomeIcon size="sm" icon="compass" />
            </CompassButton>

            <GoogleMaps
              assets={descendantsWithCoords}
              mapLocation={descendantsWithCoords.length > 0 ? undefined : coords}
              mapZoom={hybridMapConfig.zoom}
              options={hybridMapConfig.options}
              onDragMarker={handleDragEnd}
              labelColor={hybridMapConfig.labelColor}
            />
          </MapContainer>
        </HybridMapContainer>
      </Flex>

      <Flex direction={matchesWidth ? 'row-reverse' : 'column'}>
        <SliderWrapper enabled={compassIsVisible}>
          {compassIsVisible && (
            <Flex flexBasis="100%" alignItems="flex-start">
              {trackerName !== none && trackerHasStartAndEnd && (
                <OrientationDropDown
                  value={activeOrientationField}
                  onFocus={handleSliderFocus}
                  onBlur={handleSliderBlur}
                  onChange={handleOrientationChange}
                >
                  <MenuItem value={azimuthFromField}>{t`Start Angle`}</MenuItem>
                  <MenuItem value={azimuthToField}>{t`End Angle`}</MenuItem>
                </OrientationDropDown>
              )}

              <SliderContainer>
                <Slider
                  value={sliderValue}
                  onChange={handleSliderChange}
                  onFocus={handleSliderFocus}
                  onBlur={handleSliderBlur}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="auto"
                  min={trackerName === hsat ? -90 : -180}
                  max={trackerName === hsat ? 90 : 180}
                />
              </SliderContainer>
            </Flex>
          )}
        </SliderWrapper>
        {(isSolarPlant(formState) || isWindPlant(formState) || enterManually || coords.latitude !== undefined) && (
          <Flex direction="column" flexGrow="1">
            {!isCluster(formState) && (isGenerator(formState) || enterManually) && (
              <>
                <Flex alignItems="baseline">
                  <LocationSearchContainer>
                    <Autocomplete onLoad={handleAutocompleteLoaded} onPlaceChanged={handleAutocompletePlaceChanged}>
                      <GoogleSearchFlex direction="row" justifyContent="flex-start">
                        <GoogleSearchInput
                          type="text"
                          autocomplete="off"
                          autofill="off"
                          placeholder={t`Location search`}
                          disabled={isCluster(formState) || (isPark(formState) && !enterManually)}
                        />
                      </GoogleSearchFlex>
                    </Autocomplete>
                  </LocationSearchContainer>
                  {/*<Flex>*/}
                  {/*<IconButton*/}
                  {/*  color={showWeatherCoords ? 'primary' : 'default'}*/}
                  {/*  edge="end"*/}
                  {/*  aria-label="Toggle Weather Coordinates"*/}
                  {/*  onClick={onToggleWeatherCoords}*/}
                  {/*>*/}
                  {/*  <FontAwesomeIcon size="xs" icon="cloud" />*/}
                  {/*</IconButton>*/}
                  {/*</Flex>*/}
                </Flex>
                <Box ml={0.5} mt={0.5}>
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={synchronizeEnabled} onChange={handleToggleSynchronized} />
                    }
                    label={t`Synchronize map marker and address fields.`}
                    title={t`If enabled, changes to map marker coordinates are automatically mapped to address fields and vice versa.`}
                  />
                </Box>
                <Grid container direction="row" justify="flex-start" spacing={2}>
                  <Grid item md={6} xl={6}>
                    <AutoCompleteWithoutUnit
                      value={formState?.location?.country || ''}
                      options={countryOptions}
                      onChange={handleCountrySelection}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <MaterialTextField label={t`Country`} {...params} margin="normal" fullWidth />
                      )}
                    />
                    <OnChange name={`${formField}.country`}>
                      {(value: any, previous: any) => handleAddressChangeByUser(value, previous, 'country')}
                    </OnChange>
                  </Grid>
                  <Grid item md={6} xl={6}>
                    <Field name={`${formField}.state`}>
                      {(props) => {
                        return <TextField fullWidth label={t`State`} input={props.input} meta={props.meta} />
                      }}
                    </Field>
                    <OnChange name={`${formField}.state`}>
                      {(value: any, previous: any) => handleAddressChangeByUser(value, previous, 'state')}
                    </OnChange>
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="flex-start" spacing={2}>
                  <Grid item md={3} xl={3}>
                    <Field name={`${formField}.zip`}>
                      {(props) => {
                        return (
                          <NumberField fullWidth label={t`ZIP`} type="number" input={props.input} meta={props.meta} />
                        )
                      }}
                    </Field>
                    <OnChange name={`${formField}.zip`}>
                      {(value: any, previous: any) => handleAddressChangeByUser(value, previous, 'zip')}
                    </OnChange>
                  </Grid>
                  <Grid item md={9} xl={9}>
                    <Field name={`${formField}.city`}>
                      {(props) => {
                        return <TextField fullWidth label={t`City`} input={props.input} meta={props.meta} />
                      }}
                    </Field>
                    <OnChange name={`${formField}.city`}>
                      {(value: any, previous: any) => handleAddressChangeByUser(value, previous, 'city')}
                    </OnChange>
                  </Grid>
                </Grid>
                <StreetAddressField>
                  <Grid item md={12} xl={12}>
                    <Field name={`${formField}.address`}>
                      {(props) => {
                        return <TextField fullWidth label={t`Street address`} input={props.input} meta={props.meta} />
                      }}
                    </Field>
                    <OnChange name={`${formField}.address`}>
                      {(value: any, previous: any) => handleAddressChangeByUser(value, previous, 'address')}
                    </OnChange>
                  </Grid>
                </StreetAddressField>
              </>
            )}
          </Flex>
        )}
      </Flex>
    </ContainerFlex>
  )
}

export default React.memo(SectionLocation)
