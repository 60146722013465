import React, { useCallback, useEffect, useRef, useState, useContext } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import CenteredLoading from 'ui/CenteredLoading'
import styled from 'styled-components'
import BulkMdcRow from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingBody/BulkMdcRow'
import {
  QUERY_KEY_BULK_METER_DATA_CLEANSING,
  useGetAllFromBulkMdc,
} from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.api'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { BulkMdcRowObject } from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.types'
import { calculatePagesCount } from 'utils/meterDataCleansing'
import { Typography } from '@material-ui/core'
import { t } from 'ttag'
import { useQueryClient } from 'react-query'
import { FilterSettingsContext } from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMdc'
import BulkMdcHeaderHelper from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingBody/BulkMeterDataCleansingSelectAll/BulkMdcHeaderHelper'
import { BulkMeterDataCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { QUERY_ASSET, useQueryMatch } from 'utils/query-string'
import { connectFiltersToDefault } from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { Asset } from 'modules/asset/store/asset.types'
import BulkMdcUnSupportedAssetRow from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingBody/BulkMdcUnSupportedAssetRow'
import { User } from 'modules/auth/Auth.types'
import { hasPermissionForPvBulkMeterDataCleansing, hasPermissionForWindBulkMeterDataCleansing } from 'utils/user'

// Styled component
const BulkMdcBodyMainDiv = styled.div`
  height: 96.5%;
  overflow-y: auto;
  padding-top: 0.5%;
`
// To show loading spinner properly inside infinite scroll component we need to set overflow hidden
const CenterLoadingDiv = styled.div`
  overflow: hidden;
`

// General declarations
const sizeOfElementForEachPage = 10

interface BulkMdcBodyInterface {
  linkToDefault: boolean
  assetsForBulkMdc: Asset[]
  allAssets: Asset[]
  user: User
}

const BulkMdcBody: React.FC<BulkMdcBodyInterface> = ({ linkToDefault, assetsForBulkMdc, allAssets, user }) => {
  let fromScratchTimeout: any
  const isAssetDetails = useQueryMatch(QUERY_ASSET)
  const { triggerFilterSettings } = useContext(FilterSettingsContext)
  const { refresh } = useContext(FilterSettingsContext)

  const queryClient = useQueryClient()
  const refetchInterval = useRef()
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const totalPages = calculatePagesCount(sizeOfElementForEachPage, assetsForBulkMdc.length)

  //State of applied filterSettings
  const [appliedFilterSettings, setAppliedFilterSettings] = useState<BulkMeterDataCleansingFilterSettings | null>(null)

  //State of available data
  const [availableData, setAvailableData] = useState<BulkMdcRowObject[]>([])

  const [numberOfPagesPerRequest, setNumberOfPagesPerRequest] = useState<number>(0)
  const getAllFromBulkMdcResult = useGetAllFromBulkMdc({
    page: numberOfPagesPerRequest,
    size: sizeOfElementForEachPage,
    includeImage: true,
    assets: assetsForBulkMdc,
    filterSettings: appliedFilterSettings,
    user: user,
  })

  const { isLoading, isSuccess, isError, refetch, isFetching, data } = getAllFromBulkMdcResult

  const fetchMoreData = () => {
    if (numberOfPagesPerRequest + 1 < totalPages) {
      setNumberOfPagesPerRequest((prevState) => {
        return prevState + 1
      })
    }
  }

  // Since we are using react query with pagination we need to add numberOfPagesPerRequest as condition
  // to load this error only for the first time
  const renderErrorMessage = useCallback(() => {
    if (!isLoading && isError && numberOfPagesPerRequest === 0) {
      return (
        <Typography variant="subtitle1" color="error">
          {t`Something went wrong`}
        </Typography>
      )
    }
    return null
  }, [isLoading, isError, numberOfPagesPerRequest])

  const hasAccessToBulkWindMdc = hasPermissionForWindBulkMeterDataCleansing(user)
  const hasAccessToBulkPvMdc = hasPermissionForPvBulkMeterDataCleansing(user)

  const renderCleansingResult = () => {
    if (availableData.length > 0 || (data && data?.done)) {
      return assetsForBulkMdc.map((asset) => {
        const assetCleansingResult = (availableData || []).find((data) => data.assetId === asset.id)
        if (assetCleansingResult) {
          return <BulkMdcRow key={asset.id} rowData={assetCleansingResult} />
        } else
          return (
            <BulkMdcUnSupportedAssetRow
              assetSelection={assetSelection}
              allAssets={allAssets}
              key={asset.id}
              asset={asset}
              hasAccessToBulkWindMdc={hasAccessToBulkWindMdc}
              hasAccessToBulkPvMdc={hasAccessToBulkPvMdc}
            />
          )
      })
    }
    return null
  }

  const renderHeaderHelper = () => {
    if (availableData.length > 0) {
      return <BulkMdcHeaderHelper />
    }
    return null
  }

  const renderDataInInfiniteScroll = () => {
    return (
      <InfiniteScroll
        dataLength={availableData.length}
        next={fetchMoreData}
        hasMore={numberOfPagesPerRequest + 1 < totalPages && data?.done === true}
        scrollableTarget="scrollableDiv"
        loader={
          <CenterLoadingDiv>
            <CenteredLoading size="3em" />
          </CenterLoadingDiv>
        }
      >
        {<>{renderHeaderHelper()}</>}
        {<>{renderCleansingResult()}</>}
      </InfiniteScroll>
    )
  }

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      if (data.done && appliedFilterSettings) {
        // If link to default is clicked , we need to make the following api call
        if (linkToDefault) {
          connectFiltersToDefault(assetSelection).then((r) => console.log(r))
        }

        setAppliedFilterSettings(null)
      }
      setAvailableData((prevState) => {
        const copyOfPrevState = [...prevState]

        data?.cleansingResult?.forEach((assetFromNewData) => {
          const indexOfAssetWithSameId = prevState.findIndex((asset) => {
            return asset.assetId === assetFromNewData.assetId
          })

          if (indexOfAssetWithSameId > -1) {
            copyOfPrevState[indexOfAssetWithSameId] = assetFromNewData
          } else {
            copyOfPrevState.push(assetFromNewData)
          }
        })

        return copyOfPrevState
      })
    }
  }, [isLoading, isSuccess, data, linkToDefault])

  useEffect(() => {
    if (numberOfPagesPerRequest > 0) {
      refetch().then((r) => console.log('more'))
    }
  }, [numberOfPagesPerRequest])

  useEffect(() => {
    if (!isLoading && isSuccess && data) {
      if (!data.done) {
        refetchInterval.current = setInterval(() => {
          refetch().then((r) => console.log('refetch again'))
        }, 5000)
      } else {
        if (refetchInterval.current) {
          clearInterval(refetchInterval.current)
        }
      }
    }
    return () => {
      if (refetchInterval.current) {
        clearInterval(refetchInterval.current)
      }
    }
  }, [isLoading, isSuccess, data])

  useEffect(() => {
    if (!isAssetDetails) {
      // we need to scroll to top , to prevent recalling all pages from infinite scroll
      document.getElementById('scrollableDiv').scrollTop = 0

      // we need to set this timeout , to wait until scrollTop is 0
      fromScratchTimeout = setTimeout(() => {
        queryClient.removeQueries(QUERY_KEY_BULK_METER_DATA_CLEANSING)
        setNumberOfPagesPerRequest(0)
        setAvailableData([])
      }, 10)
    }

    return () => {
      clearTimeout(fromScratchTimeout)
    }
  }, [assetSelection, triggerFilterSettings, isAssetDetails, refresh])

  useEffect(() => {
    if (triggerFilterSettings) {
      setAppliedFilterSettings(triggerFilterSettings)
    }
  }, [triggerFilterSettings])

  // isFetching && !isLoading = isRefetching
  return (
    <BulkMdcBodyMainDiv id="scrollableDiv">
      {(isLoading || (isFetching && !isLoading && availableData.length === 0)) && (
        <CenteredLoading fullHeight size="3em" />
      )}
      <>{renderErrorMessage()}</>
      <>{renderDataInInfiniteScroll()}</>
    </BulkMdcBodyMainDiv>
  )
}

export default BulkMdcBody
