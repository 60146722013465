import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import {
  DELIVERY_TARGET_DEFAULT,
  DELIVERY_TARGET_TYPE,
  DELIVERY_TARGET_TYPE_CUSTOMER_FTP,
  DELIVERY_TARGET_TYPE_ENERCAST_FTP,
  DELIVERY_TARGET_TYPE_MAIL,
  DeliveryTarget,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { FormApi } from 'final-form'
import { AnyObject, useField } from 'react-final-form'
import Flex from 'ui/styles/Flex'
import { FormContainer } from 'ui/form/form.style'
import SectionHeaderDeliveryTargetForm from 'modules/delivery/deliveryTargets/SectionHeaderDeliveryTargetForm'
import { Box } from '@material-ui/core'
import SectionLocalFTPServerDeliveryTargetForm from 'modules/delivery/deliveryTargets/SectionLocalFTPServerDeliveryTargetForm'
import SectionEmailDeliveryTargetForm from 'modules/delivery/deliveryTargets/SectionEmailDeliveryTargetForm'
import { UseMutationResult } from 'react-query'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import { c } from 'ttag'
import { QUERY_DELIVERY_TARGET } from 'utils/query-string'
import { ROUTE_DATA_DELIVERY } from 'modules/app/Routes'
import { useDebounce } from 'use-debounce'
import { useDeliveryTargetsTableItems } from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import SectionTargetUsedIn from 'modules/delivery/deliveryTargets/SectionTargetUsedIn'
import { createDeliveryTargetSteps } from 'modules/app/tour/appTourSteps'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { AppTours, TourStepCategory } from 'modules/app/tour/appTour.types'
import { AddTourStepsTime } from 'modules/app/tour/appTourUtils'
import { KeyValuePair } from 'ui/form/assetForm.style'
import SectionEnercastFTPDeliveryTargetForm from 'modules/delivery/deliveryTargets/SectionEnercastFTPDeliveryTargetForm'

interface DeliveryTargetFormProps {
  isNew: boolean
  form: FormApi
  siteForecastConfigs: ForecastConfig[]
  saveResult: UseMutationResult<DeliveryTarget, Error>
  isDemoUser: boolean
  onCloseSlider: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
}

const DeliveryTargetForm: React.FC<DeliveryTargetFormProps> = ({
  isNew,
  form,
  onFormSubmit,
  onCloseSlider,
  siteForecastConfigs,
  saveResult,
  isDemoUser,
}) => {
  const { addTourSteps, removeTourSteps, isTourActive } = useContext(AppTourContext)

  const deliveryTargetResult = useDeliveryTargetsTableItems()

  const formDirty = useMemo(() => form.getState().dirty, [form.getState().dirty])
  const typeOfTarget = useField<DELIVERY_TARGET_TYPE>('type').input.value
  const targetName = useField<string>('name').input.value
  const loading = saveResult.isLoading || deliveryTargetResult.isFetching

  const formInvalid = useMemo(() => {
    return isNew ? form.getState().submitFailed && form.getState().invalid : form.getState().invalid
  }, [form.getState().invalid, form.getState().submitFailed, isNew])
  const [formInvalidDebounced] = useDebounce(formInvalid, 200)

  const handleCloseTour = useCallback(() => {
    if (typeof removeTourSteps === 'function' && isTourActive) removeTourSteps(AppTours.ADD_DELIVERY_TARGET)
  }, [removeTourSteps, isTourActive])

  useEffect(() => {
    const addSteps =
      deliveryTargetResult?.isSuccess &&
      deliveryTargetResult?.data?.length == 1 &&
      typeof addTourSteps === 'function' &&
      isTourActive
    if (!addSteps) return
    const steps = createDeliveryTargetSteps().filter((item) => item.stepCategory === TourStepCategory.FORM)
    const addStepsTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (addStepsTimer) clearTimeout(addStepsTimer)
    }
  }, [deliveryTargetResult?.isSuccess, deliveryTargetResult?.data && addTourSteps, isTourActive])

  useEffect(() => {
    if (saveResult?.isSuccess) {
      handleCloseTour()
    }
  }, [saveResult?.isSuccess, handleCloseTour])

  useEffect(() => {
    return () => {
      handleCloseTour()
    }
  }, [])

  return (
    <FormContainer>
      <form onSubmit={onFormSubmit} noValidate>
        <FormBlockNavigation
          blockWithExternalChanges={false}
          navigationDialogText={c('Delivery:Target').t`${targetName} delivery target`}
          form={form}
          currentPageQueries={[QUERY_DELIVERY_TARGET]}
          currentPageRoute={ROUTE_DATA_DELIVERY}
          navigationDialogKey={'deliveryTarget'}
        />
        <Flex direction="column" fullHeight>
          <SectionHeaderDeliveryTargetForm
            form={form}
            isNewDeliveryTarget={isNew}
            enableSaveBtn={formDirty || typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP}
            onCloseSlider={onCloseSlider}
            onFormSubmit={onFormSubmit}
            saveResult={saveResult}
            typeOfTarget={typeOfTarget}
            isDemoUser={isDemoUser}
            loading={loading}
            formInvalid={formInvalidDebounced}
          />

          <KeyValuePair>
            {typeOfTarget === DELIVERY_TARGET_DEFAULT && (
              <Box mt={1} mb={1}>
                <>{c('Delivery:Target')
                  .t`Display in portal is the default delivery target, this cannot be deleted or modified.`}</>
              </Box>
            )}
            {typeOfTarget === DELIVERY_TARGET_TYPE_MAIL && (
              <Box mt={0.5}>
                <SectionEmailDeliveryTargetForm isNew={isNew} form={form} />
              </Box>
            )}
            {typeOfTarget === DELIVERY_TARGET_TYPE_CUSTOMER_FTP && (
              <Box mt={0.5}>
                <SectionLocalFTPServerDeliveryTargetForm form={form} />
              </Box>
            )}
            {typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP && (
              <Box mt={0.5}>
                <SectionEnercastFTPDeliveryTargetForm
                  siteForecastConfigs={siteForecastConfigs}
                  form={form}
                  isNew={isNew}
                />
              </Box>
            )}

            {!isNew && typeOfTarget !== DELIVERY_TARGET_TYPE_ENERCAST_FTP && <SectionTargetUsedIn />}
          </KeyValuePair>
        </Flex>
      </form>
    </FormContainer>
  )
}

export default React.memo(DeliveryTargetForm)
