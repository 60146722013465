import React, { useEffect, useMemo } from 'react'
import { StartTime } from './SectionTimingPreview'
import { ForecastConfig, SiteForecastQualityConfig } from '../dataStreams.types'
import {
  getEvaluationTimesWithoutOverlapForChart,
  createSeriesForTimingOverviewChart,
} from 'utils/siteForecastConfiguration'
import { getColor, paletteTimingPreview } from '../../../themes/theme-light'
import { TimingPreview } from './SiteForecastDetails'
import SectionTimingPreviewChart from 'modules/dataStreams/siteForecast/SectionTimingPreviewChart'
import { c } from 'ttag'
import { addDays, isAfter, isBefore, isEqual } from 'date-fns'

interface SectionTimingPreviewOldDeliveriesProps {
  forecastDeliveries: StartTime[]
  forecastDetails: ForecastConfig
  onUpdateTimingPreview?: (data: TimingPreview) => void
}

const SectionTimingPreviewOldDeliveries: React.FC<SectionTimingPreviewOldDeliveriesProps> = ({
  forecastDeliveries,
  forecastDetails,
  onUpdateTimingPreview,
}) => {
  const forecastTimingSeriesColor = getColor(0, 0, 0, paletteTimingPreview)
  const forecastLengthHours = forecastDetails.horizon?.lengthHours
  const forecastLengthInMilliSeconds = 1000 * 60 * 60 * forecastLengthHours

  const sortedForecastDeliveries = forecastDeliveries.sort((a, b) => {
    const timeA = new Date(a.delivery)
    const timeB = new Date(b.delivery)
    return isBefore(timeA, timeB) ? -1 : 1
  })

  let timingPreviewData: TimingPreview = { forecastDeliveryTiming: [], qualityConfigTiming: [] }
  const historicalDeliveriesSeriesSet = useMemo(() => {
    const forecastTimingSeries: any[] = []
    const qualityConfigTimingSeries: any[] = []
    const qualitySeriesByConfig: any[] = []
    if (forecastDetails?.qualityConfigs && forecastDetails?.qualityConfigs.length) {
      forecastDetails.qualityConfigs.forEach((config: SiteForecastQualityConfig, configIndex) => {
        const seriesByConfig: any[] = []
        const configStartTimes = getEvaluationTimesWithoutOverlapForChart({
          config,
          configIndex,
          forecastLengthInMilliSeconds,
          forecastDeliveries: sortedForecastDeliveries,
          forecastHorizonUntilEndOfDay: forecastDetails?.horizon.untilEndOfDay,
        })

        configStartTimes.forEach(function (st) {
          const configSeriesColor = getColor(configIndex + 1, 0, 0, paletteTimingPreview)
          const seriesData = createSeriesForTimingOverviewChart(st, 2, 5, configSeriesColor, 'quality', configIndex)
          seriesByConfig.push(seriesData)
          qualityConfigTimingSeries.push(seriesData)
        })
        qualitySeriesByConfig[configIndex] = seriesByConfig
      })
    }

    sortedForecastDeliveries.forEach(function (st) {
      forecastTimingSeries.push(createSeriesForTimingOverviewChart(st, 1, 0, forecastTimingSeriesColor, 'forecast'))
    })

    if (onUpdateTimingPreview) {
      timingPreviewData = {
        forecastDeliveryTiming: [...timingPreviewData.forecastDeliveryTiming, ...forecastTimingSeries],
        qualityConfigTiming: [...timingPreviewData.qualityConfigTiming, ...qualitySeriesByConfig],
      }

      // onUpdateTimingPreview(timingPreviewData)
    }
    return [...forecastTimingSeries, ...qualityConfigTimingSeries]
  }, [forecastDetails, sortedForecastDeliveries, forecastLengthInMilliSeconds])

  const recentDeliveriesSeriesSet = useMemo(() => {
    const forecastTimingSeries: any[] = []
    const qualityConfigTimingSeries: any[] = []
    const qualitySeriesByConfig: any[] = []

    if (forecastDetails?.qualityConfigs && forecastDetails?.qualityConfigs.length) {
      forecastDetails.qualityConfigs.forEach((config: SiteForecastQualityConfig, configIndex) => {
        const seriesByConfig: any[] = []
        const configStartTimes = getEvaluationTimesWithoutOverlapForChart({
          config,
          configIndex,
          forecastLengthInMilliSeconds,
          forecastDeliveries: sortedForecastDeliveries,
          forecastHorizonUntilEndOfDay: forecastDetails?.horizon.untilEndOfDay,
          limitEvaluationTimes: 1,
        })

        configStartTimes.forEach(function (st) {
          const configSeriesColor = getColor(configIndex + 1, 0, 0, paletteTimingPreview)
          const seriesData = createSeriesForTimingOverviewChart(st, 2, 5, configSeriesColor, 'quality', configIndex)
          seriesByConfig.push(seriesData)
          qualityConfigTimingSeries.push(seriesData)
        })
        qualitySeriesByConfig[configIndex] = seriesByConfig
      })
    }

    const forecastDeliveriesBeforeOrWithSameDeliveryTime = sortedForecastDeliveries.filter((d) => {
      return qualityConfigTimingSeries.find(
        (qct) =>
          isEqual(new Date(d.delivery), new Date(qct.data[0][1])) ||
          isBefore(new Date(d.delivery), new Date(qct.data[0][1])),
      )
    })

    const deliveries = [...sortedForecastDeliveries]
    // When no quality evaluations are defined just show one forecast delivery
    const forecastDeliveriesToBeDisplayed = forecastDeliveriesBeforeOrWithSameDeliveryTime?.length
      ? forecastDeliveriesBeforeOrWithSameDeliveryTime
      : deliveries.splice(0, 1)

    forecastDeliveriesToBeDisplayed.forEach(function (st) {
      forecastTimingSeries.push(createSeriesForTimingOverviewChart(st, 1, 0, forecastTimingSeriesColor, 'forecast'))
    })

    if (onUpdateTimingPreview) {
      timingPreviewData = {
        forecastDeliveryTiming: [...timingPreviewData.forecastDeliveryTiming, ...forecastTimingSeries],
        qualityConfigTiming: [...timingPreviewData.qualityConfigTiming, ...qualitySeriesByConfig],
      }
      // onUpdateTimingPreview(timingPreviewData)
    }

    // Adding one day to show the deliveries in the next day
    return [...forecastTimingSeries, ...qualityConfigTimingSeries].map((s) => {
      const firstVal = s.data[0]
      const secondVal = s.data[1]
      return {
        ...s,
        data: [
          [addDays(new Date(firstVal[0]), 1).getTime(), addDays(new Date(firstVal[1]), 1).getTime()],
          [addDays(new Date(secondVal[0]), 1).getTime(), addDays(new Date(secondVal[1]), 1).getTime()],
        ],
      }
    })
  }, [forecastDetails, sortedForecastDeliveries, forecastLengthInMilliSeconds])

  const seriesSet = useMemo(() => [...historicalDeliveriesSeriesSet, ...recentDeliveriesSeriesSet], [
    historicalDeliveriesSeriesSet,
    recentDeliveriesSeriesSet,
  ])

  // Validate the quality config time plots
  // Need to implement it
  useEffect(() => {
    if (seriesSet.length && onUpdateTimingPreview && forecastDetails?.qualityConfigs) {
      const qualityConfigSeries = seriesSet?.filter((series) => series?.name === 'quality')
      const qualityConfigByIndex = forecastDetails?.qualityConfigs.map((config, index) => {
        return qualityConfigSeries.filter((series) => series.qualityConfigIndex === index)
      })
      onUpdateTimingPreview({
        forecastDeliveryTiming: seriesSet?.filter((series) => series.name === 'forecast'),
        qualityConfigTiming: qualityConfigByIndex,
      })
    }
  }, [seriesSet, forecastDetails])

  const maxDeliveryStartDateInSeriesSet = useMemo(() => {
    return (seriesSet || []).reduce((maxStartDate, currentSeries) => {
      const currStartDate = currentSeries?.data[0][1]
      return isAfter(currStartDate, maxStartDate) ? currStartDate : maxStartDate
    }, new Date(0))
  }, [seriesSet])

  const maxEndDateInSeriesSet = useMemo(() => {
    return seriesSet.reduce((maxEndDate, currentSeries) => {
      const currEndDate = currentSeries?.data[1][0]
      return isAfter(currEndDate, maxEndDate) ? currEndDate : maxEndDate
    }, new Date(0))
  }, [seriesSet])

  const yAxisMaxValue = useMemo(() => 1000 * 60 * 60 * 1 + maxDeliveryStartDateInSeriesSet, [
    maxDeliveryStartDateInSeriesSet,
  ])

  const daySeries = {
    data: [
      [new Date(0).getTime(), 86400000],
      [new Date(maxEndDateInSeriesSet).getTime(), 86400000],
    ],
    color: 'white',
    lineWidth: 1.5,
    // description:'Test',
    // label:'test',
    // dashStyle: 'Dash',
    shadow: {
      color: 'black',
      width: 0,
      offsetX: 0,
      offsetY: 0,
    },
  }

  // const yAxisMaxValue = 1000 * 60 * 60 * 24 // one day

  return (
    <SectionTimingPreviewChart
      yAxisTitle={c('Site Forecast:Preview').t`Delivery`}
      chartHeight={550}
      chartWidth={450}
      yAxisMaxValue={yAxisMaxValue}
      seriesSet={[daySeries, ...seriesSet]}
    />
  )
}

export default React.memo(SectionTimingPreviewOldDeliveries)
