import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'

import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import PopperTooltip from 'ui/PopperTooltip'

const Error = styled.div`
  color: ${theme.palette.error.main};
`

interface FieldErrorProps {
  error: string
}

const FieldError: React.FC<FieldErrorProps> = ({ error }) => {
  return (
    <Box ml={1}>
      <PopperTooltip
        popperLabel={<FontAwesomeActionIcon icon="exclamation-triangle" color={theme.palette.error.main} size="lg" />}
        popperContent={<Error>{error}</Error>}
      />
    </Box>
  )
}

export default React.memo(FieldError)
