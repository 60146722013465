import { SET_META } from '../actions/metaActions'
import { SET_COORDS } from 'modules/gips/actions/coordsActions'
import { googleFormatter, multipolygonFormatter } from 'modules/gips/helper/GoogleFormatHelper'
import { SET_BOTH } from 'modules/gips/actions/bboxActions'

export const GIPS_API_URL = '/api/gips/v1'

export async function getMetaInfo(dispatch, params) {
  const sort = (items) => {
    return items.sort(function (a, b) {
      let nameA = a['shapeInfo'].name.toUpperCase()
      let nameB = b['shapeInfo'].name.toUpperCase()
      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
  }

  if (params && params['shape']) {
    fetch(GIPS_API_URL + '/shape/filtered?filterRequest=' + params['shape'])
      .then((response) => {
        if (response.ok && response.status !== 204 && response.status !== 400) return response.json()
        else throw new Error(response.status + ' - ' + response.statusText)
      })
      .then((items) => sort(items))
      .then((items) => dispatch({ type: SET_META, meta: items }))
      .catch((error) => {
        console.log('Error while fetching Metadata. ' + error)
        dispatch({ type: SET_META, meta: [] })
      })
  } else {
    fetch(GIPS_API_URL + '/shape/meta')
      .then((response) => {
        if (response.ok && response.status !== 204) return response.json()
        else throw new Error(response.status + ' - ' + response.statusText)
      })
      .then((items) => sort(items))
      .then((items) => dispatch({ type: SET_META, meta: items }))
      .catch((error) => {
        console.log('Error while fetching Metadata. ' + error)
        dispatch({ type: SET_META, meta: [] })
      })
  }
}

export async function checkPointsShape(rBody) {
  return fetch(GIPS_API_URL + '/shape/search/pointsShape', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: rBody,
  })
}

export async function importFiles(formData) {
  return fetch(GIPS_API_URL + '/shape/import', {
    method: 'POST',
    body: formData,
  })
}

export async function getShape(dispatch, uuid) {
  fetch(GIPS_API_URL + '/shape/search/coords/uuid?uuid=' + uuid)
    .then((response) => {
      if (response.ok && response.status !== 204) {
        return response.json()
      } else throw new Error(response.status + ' - ' + response.statusText)
    })
    .then((item) => googleFormatter(item))
    .then((item) => dispatch({ type: SET_COORDS, uuid, coords: item }))
}

export async function getCombinedInfo(dispatch, selectedRow) {
  fetch(GIPS_API_URL + '/shape/search/combined?uuid=' + selectedRow)
    .then((response) => response.json())
    .then((item) => bboxGoogleFormat(item))
    .then((item) =>
      dispatch({
        type: SET_BOTH,
        selectedRow,
        radius: item.radius,
        lat: item.center.y,
        long: item.center.x,
        coords: item.box,
      }),
    )

  const bboxGoogleFormat = (item) => {
    item.box = multipolygonFormatter(item.box['coordinates'])
    return item
  }
}
