import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { useAssetDeleteMutation } from 'modules/asset/api/assets.api'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { msgid, ngettext, t, jt } from 'ttag'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

import ButtonMenu from 'ui/form/ButtonMenu'
import { getAssetQueryObj, QUERY_ASSET, useQueryMatch } from 'utils/query-string'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import { useIsReadOnlyUser } from 'utils/user'
import { useUniqueSelectedAssets } from 'utils/asset'
import AssetLink from 'modules/asset/AssetLink'
import { DisplayItemsToDeleteLimit } from 'utils/dataFormatting'
import Flex from 'ui/styles/Flex'

const AlignedIconMenu = styled(ButtonMenu)`
  margin-right: 0.5em;
`

const AssetsMenu: React.FC = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { onAddQueryString } = useUpdateQueryString()
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const selectedAssets = useUniqueSelectedAssets()

  const isAddAsset = useQueryMatch(QUERY_ASSET, 'new')
  const isAssetDetails = useQueryMatch(QUERY_ASSET) && !isAddAsset
  const isAssetDetailsOrAddAsset = isAssetDetails || isAddAsset
  const hasSelection = assetSelection.length > 0
  const isReadOnlyUser = useIsReadOnlyUser()

  const handleAdd = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onAddQueryString(getAssetQueryObj({}))
      handleClose()
    },
    [],
  )

  const handleDeleteConfirm = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      setIsDeleteDialogOpen(true)
      handleClose()
    },
    [isDeleteDialogOpen],
  )

  const deleteAssetResult = useAssetDeleteMutation()
  const deleteAssetMutation = deleteAssetResult.mutate
  const handleDeleteAssets = useCallback(() => {
    deleteAssetMutation(assetSelection)
    setIsDeleteDialogOpen(false)
  }, [assetSelection])

  const closeDeleteAssets = useCallback(() => {
    setIsDeleteDialogOpen(false)
  }, [])

  const itemsToBeDisplayed = selectedAssets.length >= 10 ? DisplayItemsToDeleteLimit : selectedAssets.length
  const remainingItemsToDisplay = assetSelection?.length - itemsToBeDisplayed
  const singleAssetSelected = selectedAssets.length === 1 ? <AssetLink asset={selectedAssets[0]} /> : false

  return (
    <>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          heading={ngettext(msgid`Delete asset`, `Delete assets`, assetSelection.length)}
          text={''}
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={handleDeleteAssets}
          onCancel={closeDeleteAssets}
          openDialog={isDeleteDialogOpen}
        >
          <>
            {assetSelection.length > 1 ? (
              <>
                {t`Do you want to delete these assets?`}
                <ul style={{ marginBottom: '0' }}>
                  {selectedAssets.slice(0, itemsToBeDisplayed).map((asset) => (
                    <li style={{ margin: '5px 0' }} key={asset.id}>
                      <AssetLink asset={asset} />
                    </li>
                  ))}
                </ul>
                {remainingItemsToDisplay > 0 && (
                  <div style={{ marginLeft: '1.9em' }}>{t`and ${remainingItemsToDisplay} more.`}</div>
                )}
              </>
            ) : (
              <Flex>{jt`Do you want to delete "${singleAssetSelected}" asset?`}</Flex>
            )}
          </>
        </ConfirmationDialog>
      )}
      <AlignedIconMenu
        icon="bars"
        className="appTour-createAsset-contextMenu"
        iconOnly
        renderMenu={(handleClose) => (
          <div>
            <MenuItem disabled={isReadOnlyUser} onClick={handleAdd(handleClose)}>
              <ListItemIcon>
                <FontAwesomeActionIcon icon="plus" />
              </ListItemIcon>
              <ListItemText primary={t`Add Asset`} />
            </MenuItem>

            <MenuItem
              disabled={isReadOnlyUser || isAssetDetailsOrAddAsset || !hasSelection}
              onClick={handleDeleteConfirm(handleClose)}
            >
              <ListItemIcon>
                <FontAwesomeActionIcon icon="trash-alt" />
              </ListItemIcon>
              <ListItemText primary={ngettext(msgid`Delete asset`, `Delete assets`, assetSelection.length)} />
            </MenuItem>
          </div>
        )}
      />
    </>
  )
}

export default React.memo(AssetsMenu)
