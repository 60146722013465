import React from 'react'
import styled from 'styled-components'
import { Box } from '@material-ui/core'
import LayoutTitle from 'ui/LayoutTitle'
import { t } from 'ttag'
import Spacer from 'ui/Spacer'
import { useUserManagementAllUsersTableItems } from 'modules/userManagement/api/userManagement.api'
import UserManagementTable from 'modules/userManagement/UserManagementTable'
import InlineLoading from 'ui/InlineLoading'

const TableWrapper = styled(Box)`
  width: ${(props) => props.width};
  height: calc(100% - 5.2em);
`

const Container = styled.div`
  height: 100%;
  width: 100vw;
  background: white;
`

const UserManagement: React.FC = () => {
  const userManagementUsersResult = useUserManagementAllUsersTableItems()

  return (
    <Container>
      <Box ml={1}>
        <LayoutTitle icon="users">
          {t`User Management`}
          {userManagementUsersResult?.isLoading && <InlineLoading />}
          <Spacer />
        </LayoutTitle>
      </Box>
      <TableWrapper ml={1} mr={1}>
        {userManagementUsersResult.data && <UserManagementTable tableItems={userManagementUsersResult.data} />}
      </TableWrapper>
    </Container>
  )
}

export default React.memo(UserManagement)
