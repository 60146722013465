import React, { useCallback } from 'react'
import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import { QUERY_DATA_STREAM_ID, QUERY_DATA_STREAM_TYPE, QUERY_NEW_SCHEDULE, useQueryString } from 'utils/query-string'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

const PreviewContainer = styled.div`
  width: 100%;
`

interface PreviewContentProps {
  usedFrom?: string
}

const PreviewContent = styled(Box)<PreviewContentProps>`
  height: 100%;
  position: ${(props) => props.usedFrom === TimeSeriesType.WEATHER_DATA && 'fixed'};
`

const PreviewActions = styled(Flex)``
// position: fixed;
// right: 21em;
// margin-top: 2.3em;

interface DataStreamPreviewContainerProps {
  isDataStreamSelected: boolean
  previewHeader: string
  emptyBoxText: string
  useBtnText: string
  onUseDataStream?: () => void
  disableUseBtn?: boolean
  customActionButton?: React.ReactNode
  usedFrom?: string
  children?: React.ReactNode
}

const DataStreamPreviewContainer: React.FC<DataStreamPreviewContainerProps> = ({
  isDataStreamSelected,
  emptyBoxText,
  useBtnText,
  onUseDataStream,
  disableUseBtn = false,
  customActionButton,
  usedFrom,
  children,
}) => {
  const { onDeleteQueryStrings } = useQueryString()

  const handleCloseDataStreamSlider = useCallback(() => {
    const queryStrings = [QUERY_DATA_STREAM_TYPE, QUERY_DATA_STREAM_ID, QUERY_NEW_SCHEDULE]
    onDeleteQueryStrings(queryStrings)
  }, [onDeleteQueryStrings])

  const loadPreviewContent = () => {
    if (children) {
      if (isDataStreamSelected) {
        return <>{children}</>
      } else {
        return (
          <Box width="30em">
            <DetailsNotFound hideIcon={true} message={emptyBoxText} />
          </Box>
        )
      }
    }
    return <></>
  }

  return (
    <PreviewContainer>
      <Box mt={1.1} display="flex" justifyContent="flex-end" alignItems="center">
        {/*<LayoutTitle>*/}
        {/*  <Box>{previewHeader}</Box>*/}
        {/*</LayoutTitle>*/}
        <PreviewActions justifyContent="flex-end" alignItems="center">
          {customActionButton && <>{customActionButton}</>}
          {onUseDataStream && (
            <Button
              className="appTour-createSiteForecast-useTemplate"
              disabled={!isDataStreamSelected || disableUseBtn}
              variant="contained"
              size="small"
              color="primary"
              onClick={onUseDataStream}
            >
              {useBtnText}
            </Button>
          )}

          <SliderCloseButton onCloseSlider={handleCloseDataStreamSlider} />
        </PreviewActions>
      </Box>
      <PreviewContent mt={6.4} usedFrom={usedFrom}>
        {loadPreviewContent()}
      </PreviewContent>
    </PreviewContainer>
  )
}

export default React.memo(DataStreamPreviewContainer)
