import {
  addDays,
  addSeconds,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  formatDistance,
  getHours,
  getMinutes,
  getSeconds,
  isSameDay,
  isSameHour,
  isSameMinute,
  isSameSecond,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { de, enUS } from 'date-fns/locale'
import { Timezone } from 'fixtures/timezones'
import {
  BackCastRangeType,
  ChartDataRange,
  ChartDataRangeType,
  MeterDataCleansingRangeType,
  RangeTypes,
} from 'modules/workspace/store/workspace.types'
import { subQuarters } from 'date-fns/esm'
import { c } from 'ttag'
import { DeliveryTime } from 'modules/dataStreams/dataStreams.types'
import { LanguageKeys } from 'fixtures/header'
import { TimePeriodWithRange } from 'modules/workspace/header/backCast/BackCastCalculationForm'

export type DateRange = [Date | string, Date | string]

export type EnercastDate = {
  date: Date | string
  timezone: Timezone
}

export const MAX_DATE = new Date(8640000000000000)
export const MIN_DATE = new Date(-8640000000000000)

// TODO do we need this format? 'yyyy-MM-dd HH:mm:ssXXX'
export const DATE_FORMAT_DEFAULT = 'yyyy-MM-dd HH:mm'
export const DATE_FORMAT_DEFAULT_SHORT = 'yyyy-MM-dd'
export const DATE_FORMAT_ONLY_YEAR_MONTH = 'yyyy-MM'
export const DATE_FORMAT_ONLY_TIME = 'HH:mm'
export const DATE_FORMAT_INTERNAL_SHORT = 'yyyy-MM-dd-HH-mm'
export const DATE_FORMAT_INTERNAL_LONG = "yyyy-MM-dd'T'HH:mm:ss'Z'"
export const DATE_FORMAT_INTERNAL_LONGER = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
export const DATE_FORMAT_DEFAULT_WITH_SECONDS = 'yyyy-MM-dd HH:mm:ss'

export const getDateFnsLocale = () => {
  if (window.__localeId__ === 'de') {
    return de
  } else {
    return enUS
  }
}

// convert dates from and to different timezones

export const convertLocalDateToUTC = (date: Date | string | number) => {
  const d = new Date(date)
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
  )
}

export const convertUTCToLocalDate = (date: Date | string) => {
  const d = new Date(date)
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()))
}

export const convertZonedTimeToUtc = (date: Date | string | number, timezone: Timezone) => {
  return zonedTimeToUtc(new Date(date), timezone)
  try {
    return zonedTimeToUtc(new Date(date), timezone)
  } catch (e) {
    return new Date(date)
  }
}

export const convertUtcToZonedTime = (date: Date | string | number, timezone: Timezone) => {
  try {
    return utcToZonedTime(new Date(date), timezone)
  } catch (e) {
    return new Date(date)
  }
}

export const convertZonedTimeToAnotherZonedTime = (
  date: Date | string,
  timezoneFrom: Timezone,
  timezoneTo: Timezone,
) => {
  try {
    const zonedTimeFrom = zonedTimeToUtc(new Date(date), timezoneFrom)
    return utcToZonedTime(zonedTimeFrom, timezoneTo)
  } catch (e) {
    return new Date(date)
  }
}

export const convertLocalDateToZonedTime = (date: Date | string, timezone: Timezone) => {
  try {
    const utcTime = convertLocalDateToUTC(date)
    return utcToZonedTime(utcTime, timezone)
  } catch (e) {
    return new Date(date)
  }
}

// formatting dates

export const formatUTCDate = (date: string | Date, dateFormat = DATE_FORMAT_DEFAULT_WITH_SECONDS) => {
  if (!date) return ''
  return format(new Date(date), dateFormat)
}

// TODO make timezone parameter mandatory
export const formatDate = (
  date: string | number | Date,
  timezone?: Timezone | null,
  dateFormat? = DATE_FORMAT_DEFAULT,
) => {
  if (!date) return ''
  const zonedDate = timezone ? utcToZonedTime(new Date(date), timezone) : new Date(date)
  return format(zonedDate, dateFormat, { locale: getDateFnsLocale() })
}

// TODO make timezone parameter mandatory
export const formatDateShort = (date: string | number | Date) => {
  return date ? format(new Date(date), DATE_FORMAT_DEFAULT_SHORT, { locale: getDateFnsLocale() }) : ''
}

export const formatDateYearMonth = (date: string | number | Date) => {
  return date ? format(new Date(date), DATE_FORMAT_ONLY_YEAR_MONTH, { locale: getDateFnsLocale() }) : ''
}

// TODO make timezone parameter mandatory
export const formatDateInternalShort = (date: number | string | Date) => {
  return date ? format(new Date(date), DATE_FORMAT_INTERNAL_SHORT, { locale: getDateFnsLocale() }) : ''
}

// TODO make timezone parameter mandatory
export const formatDateInternalLong = (date: number | string | Date) => {
  return date ? format(convertLocalDateToUTC(date), DATE_FORMAT_INTERNAL_LONG, { locale: getDateFnsLocale() }) : ''
}

// TODO make timezone parameter mandatory
export const formatDateInternalLonger = (date: number | string | Date) => {
  return date ? format(convertLocalDateToUTC(date), DATE_FORMAT_INTERNAL_LONGER, { locale: getDateFnsLocale() }) : ''
}

export const formatDateOnlyTime = (date: Date) => {
  return format(date, DATE_FORMAT_ONLY_TIME)
}

// date distance calculations

export const dateDifferenceMs = (fromDate: string | Date, toDate: string | Date, diffInDay?: boolean) => {
  if (!fromDate || !toDate) return null

  const from = new Date(fromDate)
  const to = new Date(toDate)
  if (diffInDay) {
    return to.getDay() - from.getDay()
  }
  return to.getTime() - from.getTime()
  // return Math.ceil(diff / (1000 * 3600 * 24))
}

export const timeAgo = (date: string | Date) => {
  return formatDistance(new Date(date), new Date(), { addSuffix: true, locale: getDateFnsLocale() })
}

// timezone offset calculations

const locale = 'en-US'
const usUSRegex = /(\d+).(\d+).(\d+),?\s+(\d+).(\d+)(.(\d+))?/

const formatOptions = {
  timeZone: 'UTC',
  hour12: false,
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

const utcFormat = new Intl.DateTimeFormat(locale, formatOptions)

const parseDate = (dateString: string): number[] => {
  dateString = dateString.replace(/[\u200E\u200F]/g, '')
  return [].slice.call(usUSRegex.exec(dateString), 1).map(Math.floor)
}

const diffMinutes = (d1: number[], d2: number[]) => {
  let day = d1[1] - d2[1]
  const hour = d1[3] - d2[3]
  const min = d1[4] - d2[4]

  if (day > 15) day = -1
  if (day < -15) day = 1

  return 60 * (24 * day + hour) + min
}

export const getTimezoneOffset = (timezone: Timezone, date: number | Date | undefined): number => {
  let localFormat
  try {
    formatOptions.timeZone = timezone
    localFormat = new Intl.DateTimeFormat(locale, formatOptions)
  } catch (e) {
    localFormat = utcFormat
  }

  return diffMinutes(parseDate(utcFormat.format(date)), parseDate(localFormat.format(date)))
}

export const isDateValid = (date: string | number | Date) => {
  const newDate = new Date(date)
  return !isNaN(newDate.getTime())
}

export const isStartOfDay = (date: string | number | Date) => {
  const newDate = new Date(date)
  return getHours(newDate) === 0 && getMinutes(newDate) === 0 && getSeconds(newDate) === 0
}

export const isEndOfDay = (date: string | number | Date) => {
  const newDate = new Date(date)
  return getHours(newDate) === 23 && getMinutes(newDate) === 59 && getSeconds(newDate) === 59
}

/**
 * @param date
 * @param dateForCheck
 * @description This function returns true , if dateForCheck(param) is equal to a certain date(param) , otherwise it returns false.
 */
export const isSameDate = (date: Date | string, dateForCheck: Date | string): boolean => {
  return new Date(dateForCheck).getTime() === new Date(date).getTime()
}

/**
 * @param date
 * @param dateForCheck
 * @description This function returns true , if dateForCheck(param) is equal to a certain date(param) , and ignores the milliseconds
 */

export const areDatesEqualIgnoringSecondsMilliseconds = (date1: Date | string, date2: Date | string): boolean => {
  // Ignore milliseconds and seconds by setting them to 0
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  d1.setMilliseconds(0)
  d2.setMilliseconds(0)
  d1.setSeconds(0)
  d2.setSeconds(0)

  // Compare the dates
  return d1.getTime() === d2.getTime()
}

/**
 * @param date
 * @param dateForCheck
 * @description This function returns true , if dateForCheck(param) is equal to a certain date(param) , and ignores the milliseconds
 */

export const areDatesEqualIgnoringMilliseconds = (date1: Date | string, date2: Date | string): boolean => {
  // Ignore milliseconds and seconds by setting them to 0
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  const sameDay = isSameDay(d1, d2)
  const sameMinutes = isSameMinute(d1, d2)
  const sameHour = isSameHour(d1, d2)
  const sameSecond = isSameSecond(d1, d2)

  return sameDay && sameMinutes && sameHour && sameSecond
}

export const isBeforeIgnoringMilliseconds = (date1: Date | string, date2: Date | string): boolean => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  d1.setMilliseconds(0)
  d2.setMilliseconds(0)

  return d1.getTime() < d2.getTime()
}

export const isAfterIgnoringMilliseconds = (date1: Date | string, date2: Date | string): boolean => {
  const d1 = new Date(date1)
  const d2 = new Date(date2)

  d1.setMilliseconds(0)
  d2.setMilliseconds(0)

  return d1.getTime() > d2.getTime()
}

/**
 * @param from
 * @param to
 * @param dateForCheck
 * @param exact
 * @description This function returns true , if dateForCheck(param) is between from(param) and to(param) , otherwise it returns false.
 */
export const isBetweenDates = (
  from: Date | string,
  to: Date | string,
  dateForCheck: Date | string,
  exact = true,
): boolean => {
  return exact ? dateForCheck >= from && dateForCheck <= to : dateForCheck > from && dateForCheck < to
}

/**
 * @param from
 * @param to
 * @param startDateForCheck
 * @param endDateForCheck
 * @description This function returns true , if startDateForCheck(param) is smaller than from(param) and endDateForCheck(param) is greater than to(param)
 * (which means we have a large selected zone that contains some small zones inside), otherwise it returns false.
 */
export const exceedDates = (
  from: Date | string,
  to: Date | string,
  startDateForCheck: Date | string,
  endDateForCheck: Date | string,
): boolean => {
  return startDateForCheck < from && endDateForCheck > to
}

// Always returns dates in local timezone for the presets which are in else block
export const getDateRangeFromChartDataRange = (
  chartDataRange: ChartDataRange,
  timezone?: Timezone | null,
  getDatesInTimezone?: boolean,
): DateRange => {
  const { rangeType, customRange } = chartDataRange
  const { CHART_DATA_RANGE_CUSTOM } = ChartDataRangeType

  if (rangeType === CHART_DATA_RANGE_CUSTOM && customRange) {
    // Custom dates are already converted to UTC
    return [new Date(customRange[0]), new Date(customRange[1])]
  }

  const now = getDatesInTimezone && timezone ? convertUtcToZonedTime(new Date(), timezone) : new Date()

  const startOfToday = startOfDay(now)
  const endOfToday = endOfDay(now)
  const next30Days = addDays(endOfToday, 30)
  const next7Days = addDays(endOfToday, 7)
  const startOfThisWeek = startOfWeek(now, { weekStartsOn: 1 })
  const endOfThisWeek = endOfWeek(now, { weekStartsOn: 1 })
  const startOfThisMonth = startOfMonth(now)
  const endOfThisMonth = endOfMonth(now)
  const startOfThisQuarter = startOfQuarter(now)
  const endOfThisQuarter = endOfQuarter(now)
  const startOfThisYear = startOfYear(now)
  const endOfThisYear = endOfYear(now)
  let start
  let end
  switch (rangeType) {
    case ChartDataRangeType.CHART_DATA_RANGE_YESTERDAY:
      start = subDays(startOfToday, 1)
      end = endOfDay(start)
      break
    case ChartDataRangeType.CHART_DATA_RANGE_TODAY:
      start = startOfToday
      end = endOfToday
      break
    case ChartDataRangeType.CHART_DATA_RANGE_TOMORROW:
      start = addDays(startOfToday, 1)
      end = endOfDay(start)
      break
    case ChartDataRangeType.CHART_DATA_RANGE_NEXT_30_DAYS:
      start = startOfToday
      end = next30Days
      break
    case ChartDataRangeType.CHART_DATA_RANGE_NEXT_7_DAYS:
    default:
      start = startOfToday
      end = next7Days
      break
    case ChartDataRangeType.CHART_DATA_RANGE_PLUS_MINUS_7_DAYS:
      start = subDays(startOfToday, 7)
      end = addDays(endOfToday, 7)
      break
    case ChartDataRangeType.CHART_DATA_RANGE_LAST_7_DAYS:
      start = subDays(startOfToday, 7)
      end = endOfToday
      break
    case ChartDataRangeType.CHART_DATA_RANGE_LAST_30_DAYS:
      start = subDays(startOfToday, 30)
      end = endOfToday
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_WEEK:
      start = startOfThisWeek
      end = endOfThisWeek
      break
    case ChartDataRangeType.CHART_DATA_RANGE_LAST_WEEK:
      start = subWeeks(startOfThisWeek, 1)
      end = subWeeks(endOfThisWeek, 1)
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_MONTH:
      start = startOfThisMonth
      end = endOfThisMonth
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_MONTH_TO_DATE:
      start = startOfThisMonth
      end = endOfToday
      break
    case ChartDataRangeType.CHART_DATA_RANGE_LAST_MONTH:
    case BackCastRangeType.CHART_DATA_RANGE_LAST_MONTH:
      start = subMonths(startOfThisMonth, 1)
      end = endOfMonth(subMonths(now, 1))
      break
    case BackCastRangeType.CHART_DATA_RANGE_LAST_3_MONTHS:
      start = subMonths(startOfThisMonth, 3)
      end = endOfMonth(subMonths(now, 1))
      break
    case BackCastRangeType.CHART_DATA_RANGE_LAST_6_MONTHS:
      start = subMonths(startOfThisMonth, 6)
      end = endOfMonth(subMonths(now, 1))
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_QUARTER:
      start = startOfThisQuarter
      end = endOfThisQuarter // min([endOfThisQuarter, next30Days]) we don't want to show more than 30 days in the future
      // end = min([endOfThisQuarter, next30Days]) // we don't want to show more than 30 days in the future
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_QUARTER_TO_DATE:
      start = startOfThisQuarter
      end = endOfToday
      break
    case ChartDataRangeType.CHART_DATA_RANGE_LAST_QUARTER:
      start = subQuarters(startOfThisQuarter, 1)
      end = subQuarters(endOfThisQuarter, 1)
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_YEAR:
      start = startOfThisYear
      end = endOfThisYear // min([endOfThisYear, next30Days]) we don't want to show more than 30 days in the future
      // end = min([endOfThisYear, next30Days]) // we don't want to show more than 30 days in the future
      break
    case ChartDataRangeType.CHART_DATA_RANGE_THIS_YEAR_TO_DATE:
      start = startOfThisYear
      end = endOfToday
      break
    case ChartDataRangeType.CHART_DATA_RANGE_LAST_YEAR:
    case BackCastRangeType.CHART_DATA_RANGE_LAST_YEAR:
      start = subYears(startOfThisYear, 1)
      end = subYears(endOfThisYear, 1)
      break
    case BackCastRangeType.CHART_DATA_RANGE_LAST_2_YEARS:
      start = subYears(startOfThisYear, 2)
      end = subYears(endOfThisYear, 1)
      break
    case BackCastRangeType.CHART_DATA_RANGE_LAST_3_YEARS:
      start = subYears(startOfThisYear, 3)
      end = subYears(endOfThisYear, 1)
      break
  }

  start = timezone ? convertZonedTimeToUtc(convertLocalDateToUTC(start), timezone) : start
  end = timezone ? convertZonedTimeToUtc(convertLocalDateToUTC(end), timezone) : end
  return [start, end]
}

// end date is including last millisecond
export const getInclusiveDateRangeFromChartDataRange = (
  chartDataRange: ChartDataRange,
  timezone?: Timezone | null,
): DateRange => {
  const dateRange = getDateRangeFromChartDataRange(chartDataRange, timezone, true)
  return [addSeconds(new Date(dateRange[0]), 1), addSeconds(new Date(dateRange[1]), 1)]
}

export const isRangeGreaterThanYear = (start: Date, end: Date): boolean => {
  const rangeLongerThanOneYear = differenceInCalendarDays(end, start) > 366
  return rangeLongerThanOneYear
}

export const isRangeGreaterThanMonth = (start: Date, end: Date): boolean => {
  const rangeIsLongerThanOneMonth = differenceInCalendarDays(end, start) > 31
  return rangeIsLongerThanOneMonth
}

export const limitRangeToOneMonth = (start: Date, end: Date): DateRange => {
  const rangeIsLongerThanOneMonth = isRangeGreaterThanMonth(start, end)
  return rangeIsLongerThanOneMonth ? [start, endOfDay(addDays(start, 30))] : [start, end]
}

export const limitRangeToOneYear = (start: Date, end: Date): DateRange => {
  const rangeIsLongerThanOneYear = isRangeGreaterThanYear(start, end)
  return rangeIsLongerThanOneYear ? [start, endOfDay(addDays(start, 365))] : [start, end]
}

export const getPastYears = (years: number) => {
  const d = new Date()
  const pastYear = d.getFullYear() - years
  d.setFullYear(pastYear)
  return d
}

export const pad = (number: number) => {
  return ('00' + number).slice(-2)
}

export const getFormattedTime = (time: DeliveryTime) => {
  return pad(time.hours) + ':' + pad(time.minutes)
}

export const setHoursAndMinutes = (from: Date | string, to: Date | string) => {
  const event1 = new Date(from)
  const event2 = new Date(to)

  event2.setHours(event1.getHours())
  event2.setMinutes(event1.getMinutes())

  return event2
}

export const calendarRangeOptions: () => { key: ChartDataRangeType; label: string }[] = () => [
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_YESTERDAY,
    label: c('Workbench:Time Range').t`Yesterday`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_TODAY,
    label: c('Workbench:Time Range').t`Today`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_TOMORROW,
    label: c('Workbench:Time Range').t`Tomorrow`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_NEXT_30_DAYS,
    label: c('Workbench:Time Range').t`Next 30 days`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_NEXT_7_DAYS,
    label: c('Workbench:Time Range').t`Next 7 days`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_PLUS_MINUS_7_DAYS,
    label: c('Workbench:Time Range').t`+/- 7 days`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_LAST_7_DAYS,
    label: c('Workbench:Time Range').t`Last 7 days`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_LAST_30_DAYS,
    label: c('Workbench:Time Range').t`Last 30 days`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_WEEK,
    label: c('Workbench:Time Range').t`This week`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_LAST_WEEK,
    label: c('Workbench:Time Range').t`Last week`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_MONTH,
    label: c('Workbench:Time Range').t`This month`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_MONTH_TO_DATE,
    label: c('Workbench:Time Range').t`Month to date`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_LAST_MONTH,
    label: c('Workbench:Time Range').t`Last month`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_QUARTER,
    label: c('Workbench:Time Range').t`This quarter`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_QUARTER_TO_DATE,
    label: c('Workbench:Time Range').t`Quarter to date`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_LAST_QUARTER,
    label: c('Workbench:Time Range').t`Last quarter`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_YEAR,
    label: c('Workbench:Time Range').t`This Year`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_THIS_YEAR_TO_DATE,
    label: c('Workbench:Time Range').t`Year to date`,
  },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_LAST_YEAR,
    label: c('Workbench:Time Range').t`Last Year`,
  },
  // {
  //   key: ChartDataRangeType.CHART_DATA_RANGE_3_YEARS,
  //   label: c('Workbench:Time Range').t`3 Years`,
  // },
  {
    key: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM,
    label: c('Workbench:Time Range').t`Custom`,
  },
]

export const backCastRangeOptions: () => { key: BackCastRangeType; label: string }[] = () => [
  {
    key: BackCastRangeType.CHART_DATA_RANGE_LAST_MONTH,
    label: c('Workbench:Time Range').t`Last month`,
  },
  {
    key: BackCastRangeType.CHART_DATA_RANGE_LAST_3_MONTHS,
    label: c('Workbench:Time Range').t`Last 3 months`,
  },
  {
    key: BackCastRangeType.CHART_DATA_RANGE_LAST_6_MONTHS,
    label: c('Workbench:Time Range').t`Last 6 months`,
  },
  {
    key: BackCastRangeType.CHART_DATA_RANGE_LAST_YEAR,
    label: c('Workbench:Time Range').t`Last Year`,
  },
  {
    key: BackCastRangeType.CHART_DATA_RANGE_LAST_2_YEARS,
    label: c('Workbench:Time Range').t`Last 2 Years`,
  },
  {
    key: BackCastRangeType.CHART_DATA_RANGE_LAST_3_YEARS,
    label: c('Workbench:Time Range').t`Last 3 Years`,
  },
  {
    key: BackCastRangeType.CHART_DATA_RANGE_CUSTOM,
    label: c('Workbench:Time Range').t`Custom`,
  },
]

export const meterDataCleansingRangeOptions: () => { key: MeterDataCleansingRangeType; label: string }[] = () => [
  {
    key: MeterDataCleansingRangeType.AVAILABLE_PERIOD,
    label: c('Meter data cleansing').t`Available period`,
  },
  {
    key: MeterDataCleansingRangeType.CHART_DATA_RANGE_CUSTOM,
    label: c('Meter data cleansing').t`Custom`,
  },
]

/**
 * @param month
 * @description By passing month number , this function returns month name depending on selected language
 */

export const getMonthName = (month: number) => {
  const { english } = LanguageKeys
  const langKey = localStorage.getItem('language') || english
  const randomNumberLessThan29 = 17

  const d = new Date()

  // We don't care about date , we need only month
  // Since new Date() returns today date , February is shorter comparing to the other months so , 17 it's included in every month , even for February.
  d.setDate(randomNumberLessThan29)
  d.setMonth(month - 1)

  return d.toLocaleString(langKey, { month: 'long' })
}

export const getScheduleFormDateRangeOptions = () => {
  return [
    {
      key: ChartDataRangeType.CHART_DATA_RANGE_TODAY,
      label: c('Workbench:Manual Forecast Range').t`Intraday`,
    },
    {
      key: ChartDataRangeType.CHART_DATA_RANGE_TOMORROW,
      label: c('Workbench:Manual Forecast Range').t`Day-Ahead`,
    },
    {
      key: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM,
      label: c('Workbench:Manual Forecast Range').t`Custom`,
    },
  ]
}

interface GetRangeTypeFromTimePeriodProps {
  timePeriod: Partial<TimePeriodWithRange>
  rangeType: RangeTypes
  rangeOptions: { key: ChartDataRangeType | BackCastRangeType; label: string }[]
  timezone?: Timezone | null
}

export const getRangeTypeFromTimePeriod = ({
  timePeriod,
  rangeType,
  rangeOptions,
  timezone,
}: GetRangeTypeFromTimePeriodProps) => {
  let range =
    rangeType === RangeTypes.BACKAST_RANGE
      ? BackCastRangeType.CHART_DATA_RANGE_CUSTOM
      : ChartDataRangeType.CHART_DATA_RANGE_CUSTOM

  const userTimezone = timezone ? timezone : null

  if (timePeriod && rangeOptions?.length) {
    const isTimePeriodMatchingAnyRange = (rangeOptions || []).find((option) => {
      const period = getDateRangeFromChartDataRange({ rangeType: option?.key, customRange: null }, userTimezone, true)
      return (
        areDatesEqualIgnoringSecondsMilliseconds(new Date(period[0]), new Date(timePeriod?.start)) &&
        areDatesEqualIgnoringSecondsMilliseconds(new Date(period[1]), new Date(timePeriod?.end))
      )
    })
    if (isTimePeriodMatchingAnyRange) {
      range = isTimePeriodMatchingAnyRange.key
    }
  }
  return range
}

export const roundToNext15Minutes = (refDate: Date) => {
  // Set seconds and milliseconds to 0
  refDate.setSeconds(0)
  refDate.setMilliseconds(0)

  const coeff = 1000 * 60 * 15 // 15 minutes in milliseconds
  const roundedTimestamp = Math.ceil(refDate.getTime() / coeff) * coeff
  const roundedDate = new Date(roundedTimestamp)

  return roundedDate
}

export const findNearestRightSidedTimestamp = (refDate: Date, intervalInMinutes: number) => {
  // Set seconds and milliseconds to 0
  refDate.setSeconds(0)
  refDate.setMilliseconds(0)

  const coeff = 1000 * 60 * intervalInMinutes
  const roundedTimestamp = Math.ceil(refDate.getTime() / coeff) * coeff
  const roundedDate = new Date(roundedTimestamp)

  return roundedDate
}
