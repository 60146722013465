import React from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import { usePenaltyNotifications } from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'
import PopperTooltip from 'ui/PopperTooltip'
import Number from 'ui/Number'
import { numberCompareHandler } from 'utils/dataFormatting'
import { c } from 'ttag'
import { AppUnits } from 'utils/units'

interface PenaltyAccuracyCellProps {
  asset: Asset
}

const PenaltyAccuracyCell: React.FC<PenaltyAccuracyCellProps> = ({ asset }) => {
  const penaltyNotificationsResult = usePenaltyNotifications()
  const penaltyNotifications = penaltyNotificationsResult?.data || []

  const notificationsWithAccuracyData = penaltyNotifications.filter((pn) => pn.assetId === asset.id && pn.primary)
  if (notificationsWithAccuracyData.length > 0) {
    const penaltyAccuracy =
      notificationsWithAccuracyData.map((data) => data.accuracy).reduce((acc, curr) => acc + curr, 0) /
      notificationsWithAccuracyData.length
    const thresholdValue =
      notificationsWithAccuracyData.map((data) => data.accuracyThreshold).reduce((acc, curr) => acc + curr, 0) /
      notificationsWithAccuracyData.length
    const isAccuracyLessThanThreshold = penaltyAccuracy < thresholdValue

    return isAccuracyLessThanThreshold ? (
      <PopperTooltip
        popperLabel={
          <Number
            unit={AppUnits.PERCENTAGE}
            showFractionalPart={true}
            forceFractionalPart={true}
            limit={3}
            addColor={numberCompareHandler(penaltyAccuracy, thresholdValue)}
            data={penaltyAccuracy}
          />
        }
        popperContent={c('Asset Tree').jt`Penalty Accuracy is less than threshold value ${thresholdValue}`}
      />
    ) : (
      <Number
        unit={AppUnits.PERCENTAGE}
        showFractionalPart={true}
        limit={3}
        addColor={numberCompareHandler(penaltyAccuracy, thresholdValue)}
        data={penaltyAccuracy}
      />
    )
  } else return <></>
}

export default React.memo(PenaltyAccuracyCell)
