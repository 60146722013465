import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

const CloseIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin-right: -12px;
  }
`

interface SliderCloseButtonProps {
  onCloseSlider: () => void
}

const SliderCloseButton: React.FC<SliderCloseButtonProps> = ({ onCloseSlider }) => {
  return (
    <CloseIconButton color="primary" onClick={onCloseSlider}>
      <FontAwesomeIcon icon="times" />
    </CloseIconButton>
  )
}

export default SliderCloseButton
