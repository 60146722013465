import React, { useCallback, useEffect, useMemo } from 'react'
import { AnyObject, Field, FormSpy } from 'react-final-form'
import { FormHeaderContainer } from 'ui/form/form.style'
import DetailsFormHeader from 'ui/form/DetailsFormHeader'
import { c, t } from 'ttag'
import ActionButton from 'ui/form/ActionButton'
import {
  useDeliveryTargetById,
  useDeliveryTargets,
  useDeliveryTargetSaveMainItemKey,
  useDeliveryTargetSaveMainItemKeySaveMutation,
} from 'modules/delivery/deliveryTargets/api/deliveryTargets.api'
import {
  deliveryTargetFormRequiredFields,
  FormSaveOptions,
  getFormSaveMenuItems,
  getInvalidFieldsErrorMessagesForCertainForm,
} from 'utils/form'
import {
  DELIVERY_TARGET_DEFAULT,
  DELIVERY_TARGET_TYPE,
  DELIVERY_TARGET_TYPE_ENERCAST_FTP,
  DELIVERY_TARGET_TYPE_MAIL,
  DeliveryTarget,
  DeliveryTargetSaveMainItem,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import styled from 'styled-components'
import { Box, FormControl, MenuItem } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { Select } from 'final-form-material-ui'
import { deliveryTargetTypesWithIcons, enercastFtpServerId } from 'utils/delivery'
import { UseMutationResult } from 'react-query'
import { getDeliveryTargetQueryObj, QUERY_DELIVERY_TARGET, useQueryParams, useQueryString } from 'utils/query-string'
import { getNextPrevNavigationIndex } from 'utils/route'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_DELIVERY_TARGETS } from 'modules/reTable/reTable.types'
import { theme } from 'themes/theme-light'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EnercastIconSmall from 'ui/icons/EnercastIconSmall'
import { isFree } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { FormApi } from 'final-form'

const SelectFormControl = styled(FormControl)`
  & .MuiMenuItem-root {
    font-size: 20px;
  }
  & .MuiFormControl-root {
    margin-right: 0.5em;
  }

  & label + .MuiInput-formControl {
    margin-top: 0px;
  }
  width: 14em;
  font-size: 1.5em;
`

interface SectionHeaderDeliveryTargetFormProps {
  isNewDeliveryTarget: boolean
  enableSaveBtn: boolean
  isDemoUser: boolean
  formInvalid: boolean
  loading: boolean
  onCloseSlider: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  saveResult: UseMutationResult<DeliveryTarget, Error>
  typeOfTarget: DELIVERY_TARGET_TYPE
  form: FormApi
}

const requiredFieldsForAssetForm = deliveryTargetFormRequiredFields()

const SectionHeaderDeliveryTargetForm: React.FC<SectionHeaderDeliveryTargetFormProps> = ({
  isNewDeliveryTarget,
  enableSaveBtn,
  onCloseSlider,
  onFormSubmit,
  saveResult,
  typeOfTarget,
  formInvalid,
  isDemoUser,
  loading,
  form,
}) => {
  const { queryParams } = useQueryParams()
  const { updateQueryString } = useQueryString()
  const user = useSelector(getUserResultSelector)
  const enercastFtp = useDeliveryTargetById(enercastFtpServerId)

  const deliveryTargetsResult = useDeliveryTargets()
  const filteredIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_DELIVERY_TARGETS)

  const filteredDeliveryTargets = useMemo(() => {
    return (deliveryTargetsResult.data || []).filter((dt) => dt.id && filteredIds.includes(dt.id))
  }, [deliveryTargetsResult.data, filteredIds])

  const handleNextPrevDeliveryTarget = useCallback(
    (nextItem: boolean) => {
      const currentDisplayedId = queryParams[QUERY_DELIVERY_TARGET]
      const newIndex = getNextPrevNavigationIndex(filteredDeliveryTargets, currentDisplayedId, nextItem)
      if (newIndex != undefined) {
        updateQueryString(getDeliveryTargetQueryObj(filteredDeliveryTargets[newIndex]))
      }
    },
    [queryParams, updateQueryString, filteredDeliveryTargets],
  )

  // save handling
  const saveMainItemKey = useDeliveryTargetSaveMainItemKey()
  const {
    mutate: saveMainMutation,
    isLoading: savingMenuItemLoading,
    isSuccess: saveMenuItemSuccess,
    reset,
  } = useDeliveryTargetSaveMainItemKeySaveMutation()

  const saveMenuItems = useMemo(
    () =>
      getFormSaveMenuItems().filter((item) =>
        isNewDeliveryTarget
          ? item.key != FormSaveOptions.SAVE_AND_NEXT && item.key != FormSaveOptions.CREATE_COPY
          : item.key !== FormSaveOptions.SAVE_AND_NEW,
      ),
    [isNewDeliveryTarget],
  )

  const savedMainItem = useMemo(() => {
    const mainItem = isNewDeliveryTarget
      ? saveMainItemKey.data?.createDeliveryTarget
      : saveMainItemKey.data?.updateDeliveryTarget
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNewDeliveryTarget, saveMainItemKey.data])

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      const { SAVE_AND_CLOSE } = FormSaveOptions
      if (savedMainItem !== item.key) {
        const data: DeliveryTargetSaveMainItem = {
          createDeliveryTarget: isNewDeliveryTarget
            ? item.key
            : saveMainItemKey.data?.createDeliveryTarget || SAVE_AND_CLOSE,
          updateDeliveryTarget: !isNewDeliveryTarget
            ? item.key
            : saveMainItemKey.data?.updateDeliveryTarget || SAVE_AND_CLOSE,
        }
        saveMainMutation(data)
      }
    },
    [saveMainItemKey, saveMainMutation, isNewDeliveryTarget, savedMainItem],
  )

  const headerTitle = useMemo(() => {
    return isNewDeliveryTarget
      ? c('Delivery:Target').t`New delivery target`
      : c('Delivery:Target').t`Delivery target details`
  }, [isNewDeliveryTarget])

  const headerPlaceholder = useMemo(() => {
    return typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP
      ? c('Delivery:Target').t`FTP account name`
      : c('Delivery:Target').t`Delivery target name`
  }, [typeOfTarget])

  const handleChangeType = useCallback(
    (value) => {
      if (value === DELIVERY_TARGET_TYPE_ENERCAST_FTP && isNewDeliveryTarget) {
        form.mutators.setValue('name', user?.login)
      } else {
        form.mutators.setValue('name', '')
      }
    },
    [user, isNewDeliveryTarget],
  )

  const deliveryTypeComponent = useMemo(() => {
    const deliveryTargetTypes = deliveryTargetTypesWithIcons()

    // Remove enercast ftp if user has a ROLE_FREE authority OR if already exist a enercast ftp for a certain user
    if (isFree(user) || enercastFtp.data) {
      deliveryTargetTypes.pop()
    }

    return isNewDeliveryTarget ? (
      <Field name="type">
        {(props) => {
          return (
            <SelectFormControl className="appTour-createDeliveryTarget-save">
              <Select input={props.input} meta={props.meta}>
                {deliveryTargetTypes.map((el) => (
                  <MenuItem onClick={() => handleChangeType(el.value)} key={el.value} value={el.value}>
                    <Flex direction="row" alignItems="center">
                      <Box mr={1}>{el.image}</Box>
                      {el.label}
                    </Flex>
                  </MenuItem>
                ))}
              </Select>
            </SelectFormControl>
          )
        }}
      </Field>
    ) : (
      <>
        {typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP || typeOfTarget === DELIVERY_TARGET_DEFAULT ? (
          <Box mt={0.7} mr={1.6}>
            <EnercastIconSmall size="25" />
          </Box>
        ) : (
          <Box mr={1.6} mt={1.2}>
            <FontAwesomeIcon
              size="2x"
              color={theme.palette.primary.main}
              icon={typeOfTarget === DELIVERY_TARGET_TYPE_MAIL ? 'envelope' : 'server'}
            />
          </Box>
        )}
      </>
    )
  }, [isNewDeliveryTarget, typeOfTarget, isDemoUser, user, enercastFtp?.isSuccess])

  // This func handles Button Text if there is only one action.
  const buttonText = useMemo(() => {
    if (typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP) {
      return isNewDeliveryTarget ? t`Create FTP/SFTP account` : t`Close`
    }
    return t`Save` // This 'save' is not used if there is a multiple actions button.
  }, [isNewDeliveryTarget, typeOfTarget])

  // Submit the form, if a new option is selected from the save menu
  // we save in the user settings and after that we submit the form
  useEffect(() => {
    if (saveMenuItemSuccess) {
      onFormSubmit()
      reset()
    }
  }, [saveMenuItemSuccess])

  return (
    <FormHeaderContainer>
      <DetailsFormHeader
        showNextPrevOptions={!isNewDeliveryTarget}
        handleGoToNextPrevItem={handleNextPrevDeliveryTarget}
        totalItems={filteredDeliveryTargets.length}
        onCloseDetailsForm={onCloseSlider}
        fieldName="name"
        placeholder={headerPlaceholder}
        headerTitle={headerTitle}
        useFullWidthForTitle={isNewDeliveryTarget ? 1 : 0}
        customComponent={deliveryTypeComponent}
        disabled={typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP || typeOfTarget === DELIVERY_TARGET_DEFAULT}
        titleFieldClassName="appTour-createDeliveryTarget-name"
        requiredField={true}
        hideTitleInputField={isNewDeliveryTarget && typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP}
      >
        <FormSpy subscription={{ dirty: true, touched: true, modifiedSinceLastSubmit: true }}>
          {(props) => {
            const formTouched = Object.keys(props.touched || {}).some((key) => props.touched && props.touched[key])
            return (
              <ActionButton
                invalidFieldsErrorMessages={getInvalidFieldsErrorMessagesForCertainForm(
                  form as FormApi,
                  requiredFieldsForAssetForm,
                )}
                result={saveResult}
                formInvalid={formInvalid}
                formTouched={formTouched}
                formModifiedSinceLastSubmit={props.modifiedSinceLastSubmit}
                disabled={
                  typeOfTarget === DELIVERY_TARGET_DEFAULT ||
                  loading ||
                  !enableSaveBtn ||
                  savingMenuItemLoading ||
                  saveMainItemKey.isLoading
                }
                menuItems={typeOfTarget !== DELIVERY_TARGET_TYPE_ENERCAST_FTP ? saveMenuItems : undefined}
                mainItemKey={savedMainItem}
                onSelectMenuItem={handleSaveMenuSelect}
                dynamicButtonType={
                  typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP && !isNewDeliveryTarget ? 'button' : undefined
                }
                onCloseDetailsForm={
                  typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP && !isNewDeliveryTarget ? onCloseSlider : undefined
                }
                icon={
                  typeOfTarget === DELIVERY_TARGET_TYPE_ENERCAST_FTP && !isNewDeliveryTarget ? 'times-circle' : 'save'
                }
                variant="contained"
                color="primary"
                size="small"
                saveOptionLoading={savingMenuItemLoading}
              >
                {buttonText}
              </ActionButton>
            )
          }}
        </FormSpy>
      </DetailsFormHeader>
    </FormHeaderContainer>
  )
}

export default React.memo(SectionHeaderDeliveryTargetForm)
