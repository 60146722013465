import { Parameter } from 'modules/quality/quality.types'

export interface ForecastModel {
  uuid: string
  creationDate: Date
  activeFrom?: Date
  assetId: string
  assetType: string
  customer: string
  parameter: Parameter
  latitude: number
  longitude: number
  reason: string
  trainingId: string
  precursors: any[]
  version?: string
  deleted?: any
  hide?: boolean
  name?: string
  modelInstanceVersion?: number
}

export enum ForecastModelCategoryEnum {
  E3 = 'E3',
  META = 'META',
}
