import React, { useMemo } from 'react'
import { Chip, Typography } from '@material-ui/core'
import { t } from 'ttag'

import { Asset } from 'modules/asset/store/asset.types'
import { getActiveModels } from 'utils/forecastModel'
import { ForecastModel } from 'modules/quality/quality.types'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'

interface ActivationStateProps {
  asset: Asset
  forecastModel: ForecastModel
}

const ActivationState: React.FC<ActivationStateProps> = ({ asset, forecastModel }) => {
  const forecastModelsQueryResult = useForecastModelsByAsset(asset)
  const forecastModels = useMemo(() => forecastModelsQueryResult?.data, [forecastModelsQueryResult.data])
  const activeModels = useMemo(() => {
    if (forecastModels) {
      return getActiveModels(forecastModels)
    } else return []
  }, [forecastModels])

  const activeModelForThisForecastModelCategory = useMemo(
    () => activeModels.find((am) => am.category === forecastModel.category),
    [activeModels, forecastModel],
  )

  const isActive = activeModelForThisForecastModelCategory?.uuid === forecastModel?.uuid
  const wasActive = !isActive && forecastModel?.activeFrom

  return (
    <>
      {isActive ? (
        <Typography component="div" color="primary">
          <Chip color="primary" size="small" label={t`Active now`} />
        </Typography>
      ) : wasActive ? (
        <Typography color="primary">{t`Previously Active`}</Typography>
      ) : null}
    </>
  )
}

export default React.memo(ActivationState)
