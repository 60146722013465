import React from 'react'
import styled from 'styled-components'

const Image = styled.img`
  height: 35vh;
  width: 43vw;
`

interface ConvertSvgToImageProps {
  imageUrl: string
}

const ConvertSvgToImage: React.FC<ConvertSvgToImageProps> = ({ imageUrl }) => {
  // 1.Parsing process
  const parser = new DOMParser()
  const parsedResult = parser.parseFromString(imageUrl, 'application/xml')

  // 2.Serializing process
  const serializedData = new XMLSerializer().serializeToString(parsedResult)

  // 3. Preparing a Blob with serialized result
  const svgBlob = new Blob([serializedData], {
    type: 'image/svg+xml;charset=utf-8',
  })

  // 4.Convert the blob object to a dedicated URL
  const url = URL.createObjectURL(svgBlob)

  return <Image src={url} alt="Image" />
}

export default ConvertSvgToImage
