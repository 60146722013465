// Important
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormApi } from 'final-form'
import Flex from 'ui/styles/Flex'
import {
  AssetFormLabel,
  AutoCompleteWithLabel,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  TextFieldWithoutLabelAndUnit,
} from 'ui/form/assetForm.style'
import { c, t } from 'ttag'
import { Field, useField } from 'react-final-form'
import { Checkbox, Select } from 'final-form-material-ui'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import {
  getAuthoritiesLabelHandler,
  geUserManagementLanguages,
  preDefinedAllAuthorities,
  preDefinedPartnerAuthorities,
  Roles,
} from 'utils/userManagement'
import { Box, Button, Chip, MenuItem, TextField as MaterialTextField, Typography } from '@material-ui/core'
import FinalFormAutocompleteField from 'ui/elements/FinalFormAutocompleteField'
import {
  getSiteAssessmentCoordinatesList,
  resetSiteAssessmentCoordinatesList,
  useAllPartners,
} from 'modules/userManagement/api/userManagement.api'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isCompanyAccount } from 'utils/user'
import { formatUTCDate } from 'utils/date'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { OnChange } from 'react-final-form-listeners'
import { QUERY_USER_MANAGEMENT_DETAILS, useQueryParams } from 'utils/query-string'
import { UserManagementType } from 'modules/userManagement/userManagement.types'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import {
  UserDetailsTextFieldWithoutLabel,
  UserDetailsFieldUnit,
  UserDetailsSelectFieldWithoutLabel,
  UserDetailsNumberFieldWithoutLabel,
} from 'ui/form/userDetailsForm.style'
import { RenderInputParams } from '@material-ui/lab'
import { AppEntityNamesForTranslation, tGetEntityName } from 'fixtures/commonTranslations'

const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 0.3em;
`

const StyledButton = styled(Button)`
  max-height: 2em;
  align-self: center;
  margin-right: 1.6em !important;
`

// Styled Component
const CheckboxControl = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    padding-top: 6px;
  }
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }
`

const CheckBoxContainer = styled(Flex)`
  margin-left: 5px;
`

const CustomChip = styled(Chip)`
  &.MuiChip-root > .MuiChip-deleteIcon {
    display: none;
  }
`

//Interface
interface UserManagementDetailsFieldsProps {
  form: FormApi
  isNew: boolean
  allUsers: UserManagementType[]
  selectedUserDetails: UserManagementType
}

const companyAccountRelatedDialogFrom = {
  authoritiesValueChange: 'authoritiesValueChange',
  companyAccountValueChange: 'companyAccountValueChange',
}

const UserManagementDetailsFields: React.FC<UserManagementDetailsFieldsProps> = ({
  form,
  isNew,
  allUsers,
  selectedUserDetails,
}) => {
  // States
  const [numberOfUsedCoordinates, setNumberOfUsedCoordinates] = useState(0)
  const [showClearLocationsDialog, setShowClearLocationsDialog] = useState<boolean>(false)
  const [showReadOnlyConfirmation, setShowReadOnlyConfirmation] = useState<boolean>(false)
  const [showRemovingCompanyAccountConfirmation, setShowRemovingCompanyAccountConfirmation] = useState<boolean>(false)
  const [handleCompanyAccountRelatedDialog, setHandleCompanyAccountRelatedDialog] = useState<{
    show: boolean
    from: string | null
  }>({ show: false, from: null })
  const [previousAuthoritiesSelection, setPreviousAuthoritiesSelection] = useState<string[]>([])

  // get result from url
  const { queryParams } = useQueryParams()

  // Get logged in user
  const loggedInUser = useSelector(getUserResultSelector)
  // Get all partners
  const allPartners = useAllPartners()
  // Get available language
  const availableLanguageValues = useMemo(() => geUserManagementLanguages(), [])
  // Inputs
  const authoritiesInputValue = useField<string[]>('authorities').input.value
  const partnerInputValue = useField<string>('partnerName').input.value
  const companyAccountsInputValue = useField<string>('companyAccounts').input.value
  const subUsersRelatedToCompany = useField<string[]>('subUsers').input.value
  const idInputValue = useField<string>('id').input.value
  const createdDateInputValue = useField<string>('createdDate').input.value
  const lastModifiedDateInputValue = useField<string>('lastModifiedDate').input.value
  const lastModifiedByInputValue = useField<string>('lastModifiedBy').input.value
  const selectedUserIsCompanyAccount = selectedUserDetails.authorities?.includes(Roles.COMPANY_ACCOUNT)

  const clearCoordinatesListHandler = () => {
    setShowClearLocationsDialog(true)
  }

  const handleConfirm = () => {
    const userDetailsId = form.getState().values.login
    resetSiteAssessmentCoordinatesList(userDetailsId)
      .then(() => {
        setNumberOfUsedCoordinates(0)
      })
      .catch((err) => console.log(err))
    // after api request we need to hide dialog
    setShowClearLocationsDialog(false)
  }

  const handleCancel = () => {
    setShowClearLocationsDialog(false)
  }

  useEffect(() => {
    // only when !isNew
    if (!isNew && isAdmin(loggedInUser)) {
      const userDetailsId = form.getState().values.login
      getSiteAssessmentCoordinatesList(userDetailsId)
        .then((res) => {
          setNumberOfUsedCoordinates(res.length)
        })
        .catch(() => setNumberOfUsedCoordinates(0))
    }
  }, [form.getState().values, loggedInUser])

  // 1. Prepared function
  const preDefinedAuthoritiesHandler = (authority: string) => {
    return {
      id: authority,
      name: getAuthoritiesLabelHandler(authority),
    }
  }

  const partnersAndCompanyAccountHandler = (user: any) => {
    return {
      id: user.login,
      name: user.login,
    }
  }

  // 2. Prepared Const
  const preparedPredefinedAuthorities = (isAdmin(loggedInUser)
    ? preDefinedAllAuthorities()
    : preDefinedPartnerAuthorities
  ).map(preDefinedAuthoritiesHandler)

  const preparedPredefinedPartners = allPartners.data
    ?.filter((partner) => partner.login !== queryParams[QUERY_USER_MANAGEMENT_DETAILS])
    .map(partnersAndCompanyAccountHandler)
    .sort((a, b) => a.name.localeCompare(b.name))

  const preparedPredefinedCompanyAccounts = allUsers
    ?.filter((user) => {
      const notSelf = user.id !== loggedInUser?.login
      const notAdmin = !isAdmin(user)
      const activated = user.activated
      return notSelf && notAdmin && activated && isCompanyAccount(user)
    })
    .map(partnersAndCompanyAccountHandler)
    .sort((a, b) => a.name.localeCompare(b.name))

  // Change authorities handler
  const handleAuthoritiesValueChange = useCallback(
    (value: string[], previousValue: string[]) => {
      // Checking for COMPANY ACCOUNT ONLY role
      const companyAccountRoleInPreviousValue = (previousValue || []).find((role) => role === Roles.COMPANY_ACCOUNT)
      const companyAccountRoleInActiveValue = (value || []).find((role) => role === Roles.COMPANY_ACCOUNT)
      if (
        companyAccountRoleInPreviousValue &&
        !companyAccountRoleInActiveValue &&
        subUsersRelatedToCompany.length > 0
      ) {
        setShowRemovingCompanyAccountConfirmation(true)
        setPreviousAuthoritiesSelection(previousValue)
      }

      // Checking for PARTNER role
      const partnerRoleInPreviousValue = (previousValue || []).find((role) => role === Roles.PARTNER)
      const partnerRoleInActiveValue = (value || []).find((role) => role === Roles.PARTNER)
      if (
        !partnerRoleInPreviousValue &&
        partnerRoleInActiveValue &&
        companyAccountsInputValue &&
        companyAccountsInputValue.length > 0
      ) {
        setHandleCompanyAccountRelatedDialog({
          show: true,
          from: companyAccountRelatedDialogFrom.authoritiesValueChange,
        })
        form.mutators.setValue('authorities', previousValue)
      }

      // Checking for READ ONLY role
      const readOnlyRoleInPreviousValue = (previousValue || []).find((role) => role === Roles.READ_ONLY)
      const readOnlyRoleInActiveValue = (value || []).find((role) => role === Roles.READ_ONLY)
      if (readOnlyRoleInPreviousValue && !readOnlyRoleInActiveValue) {
        setShowReadOnlyConfirmation(true)
        setPreviousAuthoritiesSelection(previousValue)
      }
    },
    [companyAccountsInputValue, subUsersRelatedToCompany],
  )

  // Change company account handler
  const handleCompanyAccountValueChange = useCallback(
    (value: any, previousValue: any) => {
      if (
        (!previousValue || previousValue?.length === 0) &&
        value.length > 0 &&
        authoritiesInputValue.includes(Roles.PARTNER)
      ) {
        setHandleCompanyAccountRelatedDialog({
          show: true,
          from: companyAccountRelatedDialogFrom.companyAccountValueChange,
        })
        form.mutators.setValue('companyAccounts', [])
      }
    },
    [authoritiesInputValue],
  )

  const handleConfirmChangingRolePartner = () => {
    setHandleCompanyAccountRelatedDialog({ show: false, from: null })
  }

  const handleCancelChangingRoleReadOnly = () => {
    form.mutators.setValue('authorities', previousAuthoritiesSelection)
    setShowReadOnlyConfirmation(false)
  }

  const handleCloseRemovingCompanyAccountRoleDialog = () => {
    form.mutators.setValue('authorities', previousAuthoritiesSelection)
    setShowRemovingCompanyAccountConfirmation(false)
  }

  const handleConfirmChangingRoleReadOnly = () => {
    setShowReadOnlyConfirmation(false)
  }

  return (
    <Flex direction="column">
      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{c('UserManagement').t`Id`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex alignItems="center">
              <div>{idInputValue}</div>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{c('UserManagement').t`First name`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="firstName" fullWidth>
            {(props) => {
              return <UserDetailsTextFieldWithoutLabel fullWidth type="text" input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{c('UserManagement').t`Last name`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="lastName" fullWidth>
            {(props) => {
              return <UserDetailsTextFieldWithoutLabel fullWidth type="text" input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{c('UserManagement').t`E-mail`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="email" fullWidth>
            {(props) => {
              return <UserDetailsTextFieldWithoutLabel fullWidth type="text" input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{c('UserManagement').t`Language`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="langKey" fullWidth>
            {(props) => {
              return (
                <UserDetailsSelectFieldWithoutLabel>
                  <Select input={props.input} meta={props.meta}>
                    {availableLanguageValues.map((el) => (
                      <MenuItem key={el.value} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </UserDetailsSelectFieldWithoutLabel>
              )
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>{c('UserManagement').t`Role`}:</FormKey>
        <FormValue>
          <Flex direction="row">
            <Flex flexGrow={1}>
              <Field name="authorities">
                {(props) => {
                  return (
                    <FinalFormAutocompleteField
                      availableItems={preparedPredefinedAuthorities || []}
                      selectedIds={authoritiesInputValue || []}
                      multiple={true}
                      input={props.input}
                      meta={props.meta}
                      inputFieldWidth={'100%'}
                    />
                  )
                }}
              </Field>
              <OnChange name={`authorities`}>
                {(value: string[], previousValue: string[]) => {
                  handleAuthoritiesValueChange(value, previousValue)
                }}
              </OnChange>
            </Flex>
            <UserDetailsFieldUnit></UserDetailsFieldUnit>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      <Box mt={2} mb={2}>
        <Typography variant="subtitle2">{c('UserManagement').t`Account hierarchy`}</Typography>

        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{c('UserManagement').t`Partner`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex direction="row">
              <Flex flexGrow={1}>
                <Field name="partnerName" fullWidth>
                  {(props) => {
                    return isAdmin(loggedInUser) ? (
                      <FinalFormAutocompleteField
                        availableItems={preparedPredefinedPartners || []}
                        selectedIds={[...partnerInputValue] || []}
                        multiple={false}
                        input={props.input}
                        meta={props.meta}
                        placeHolder={c('UserManagement').t`Select partner`}
                        inputFieldWidth={'100%'}
                      />
                    ) : (
                      <TextFieldWithoutLabelAndUnit
                        fullWidth
                        disabled={!isAdmin(loggedInUser)}
                        type="text"
                        input={props.input}
                        meta={props.meta}
                      />
                    )
                  }}
                </Field>
              </Flex>
              <UserDetailsFieldUnit>
                <PopperTooltip
                  popperLabel={<TooltipIcon icon="info" size="sm" />}
                  popperContent={
                    <>
                      {t`By linking this account to a partner account the partner account gets access to this account.`}
                    </>
                  }
                />
              </UserDetailsFieldUnit>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>

        {isAdmin(loggedInUser) && !selectedUserIsCompanyAccount ? (
          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>{c('UserManagement').t`Company accounts`}:</AssetFormLabel>
            </FormKey>
            <FormValue>
              <Flex direction="row">
                <Flex flexGrow={1}>
                  <Field name="companyAccounts" fullWidth>
                    {(props) => {
                      return (
                        <FinalFormAutocompleteField
                          availableItems={preparedPredefinedCompanyAccounts || []}
                          selectedIds={[...companyAccountsInputValue] || []}
                          disabled={subUsersRelatedToCompany.length > 0}
                          multiple={true}
                          input={props.input}
                          meta={props.meta}
                          placeHolder={
                            companyAccountsInputValue?.length === 0
                              ? c('UserManagement').t`Select company account`
                              : undefined
                          }
                          inputFieldWidth={'100%'}
                        />
                      )
                    }}
                  </Field>
                  <OnChange name={`companyAccounts`}>
                    {(value: any, previousValue: any) => {
                      handleCompanyAccountValueChange(value, previousValue)
                    }}
                  </OnChange>
                </Flex>

                <UserDetailsFieldUnit>
                  <PopperTooltip
                    popperLabel={<TooltipIcon icon="info" size="sm" />}
                    popperContent={
                      <>
                        {t`After linking this user account to a company account, the user gets access to the company account’s assets and data streams rather than those of his user account.`}
                      </>
                    }
                  />
                </UserDetailsFieldUnit>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>
        ) : (
          <></>
        )}

        {isAdmin(loggedInUser) && selectedUserIsCompanyAccount ? (
          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>{c('UserManagement').t`Authorized users`}:</AssetFormLabel>
            </FormKey>
            <FormValue>
              <Flex direction="row">
                <Flex flexGrow={1}>
                  <Field name="subUsers" fullWidth>
                    {(props) => {
                      const sortedSubUsersRelatedCompany = subUsersRelatedToCompany.length
                        ? subUsersRelatedToCompany.sort()
                        : []

                      return (
                        <AutoCompleteWithLabel
                          size="medium"
                          fullWidth
                          freeSolo
                          multiple
                          options={sortedSubUsersRelatedCompany}
                          value={sortedSubUsersRelatedCompany}
                          disableClearable
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <CustomChip key={index} label={option} {...getTagProps({ index })} />
                            ))
                          }
                          renderInput={(params: RenderInputParams) => <MaterialTextField {...params} fullWidth />}
                        />
                      )
                    }}
                  </Field>
                </Flex>

                <UserDetailsFieldUnit>
                  <PopperTooltip
                    popperLabel={<TooltipIcon icon="info" size="sm" />}
                    popperContent={<>{t`Authorized users get access to this account's assets and data streams.`}</>}
                  />
                </UserDetailsFieldUnit>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>
        ) : (
          <></>
        )}
      </Box>

      {isAdmin(loggedInUser) && (
        <Box mb={2}>
          <Typography variant="subtitle2">{c('UserManagement').t`Site assessment and backcast`}</Typography>

          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>{c('UserManagement').t`Location quota`}:</AssetFormLabel>
            </FormKey>
            <FormValue>
              <Field name="siteAssessmentCoordinateLimit" fullWidth>
                {(props) => {
                  return (
                    <UserDetailsNumberFieldWithoutLabel fullWidth type="number" input={props.input} meta={props.meta} />
                  )
                }}
              </Field>
            </FormValue>
          </FormKeyValueGrid>

          {/* Site assessment coordinates handler */}
          {!isNew && (
            <Flex direction="row" justifyContent="space-between">
              <FormKeyValueGrid>
                <FormKey>
                  <AssetFormLabel>{c('UserManagement').t`Used locations`}:</AssetFormLabel>
                </FormKey>
                <FormValue>
                  <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
                    <div>{numberOfUsedCoordinates}</div>
                    <Box>
                      <PopperTooltip
                        popperLabel={
                          <Box ml={1.2} mt={0.1}>
                            <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
                          </Box>
                        }
                        popperContent={
                          <div>{c('UserManagement')
                            .t`The location quota and used location counter are cumulative. For contracts with an annual or monthly quota, the quota must be increased by the annual or monthly amount on every anniversary of the contract. In case the used location counter is reset, be sure to also adjust the quota (including any carryover) in order to reflect the correct amount of remaining locations.`}</div>
                        }
                      />
                    </Box>
                  </Flex>
                </FormValue>
              </FormKeyValueGrid>

              <StyledButton
                onClick={clearCoordinatesListHandler}
                variant="contained"
                size="small"
                color="default"
                disabled={numberOfUsedCoordinates === 0}
              >
                {c('UserManagement').t`Clear history`}
              </StyledButton>
            </Flex>
          )}

          {showClearLocationsDialog && (
            <ConfirmationDialog
              heading={c('UserManagement').t`CAUTION`}
              text={c('UserManagement')
                .t`This will irrevocably delete the backcasting history. This will cause the user to lose access to all previously created backcast and require him to create new backcasts that are debited from his location quota. DO NOT PROCEED UNLESS YOU UNDERSTAND PRECISELY WHAT YOU ARE DOING.`}
              confirmAction={c('UserManagement').t`Clear history`}
              cancelAction={t`Cancel`}
              onConfirm={handleConfirm}
              onCancel={handleCancel}
              openDialog={showClearLocationsDialog}
            />
          )}
        </Box>
      )}

      {!isNew && (
        <Box>
          <Typography variant="subtitle2">{t`Meter data`}</Typography>

          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>{c('UserManagement').t`Created date`}:</AssetFormLabel>
            </FormKey>
            <FormValue>
              <Flex alignItems="center">
                <div>{formatUTCDate(createdDateInputValue.replace('[UTC]', ''))}</div>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>

          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>{c('UserManagement').t`Last modified date`}:</AssetFormLabel>
            </FormKey>
            <FormValue>
              <Flex alignItems="center">
                <div>{formatUTCDate(lastModifiedDateInputValue.replace('[UTC]', ''))}</div>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>

          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>{c('UserManagement').t`Last modified by`}:</AssetFormLabel>
            </FormKey>
            <FormValue>
              <Flex alignItems="center">
                <div>{lastModifiedByInputValue}</div>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>
        </Box>
      )}

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{c('UserManagement').t`Activated`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <CheckBoxContainer direction="row" alignItems="center" justifyContent="flex-start">
            <CheckboxControl
              label={''}
              disabled={isNew}
              control={<Field name="activated" type="checkbox" component={Checkbox} />}
            />
          </CheckBoxContainer>
        </FormValue>
      </FormKeyValueGrid>

      {showReadOnlyConfirmation && (
        <ConfirmationDialog
          heading={c('UserManagement').t`CAUTION`}
          text={c('UserManagement')
            .t`Are you sure that you want to give this user full write access?  If this user belongs to a company account, please confirm that write access was authorized by the company administrator and documented in Jira.`}
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={handleConfirmChangingRoleReadOnly}
          onCancel={handleCancelChangingRoleReadOnly}
          openDialog={showReadOnlyConfirmation}
        />
      )}

      {showRemovingCompanyAccountConfirmation && subUsersRelatedToCompany.length > 0 && (
        <ConfirmationDialog
          heading={t`Error`}
          headingColor="error"
          text=""
          confirmAction={t`Ok`}
          onConfirm={handleCloseRemovingCompanyAccountRoleDialog}
          openDialog={showRemovingCompanyAccountConfirmation}
        >
          <div>
            {c('UserManagement')
              .t`The role COMPANY ACCOUNT cannot be removed because there are still authorized users assigned to this account.  Please remove all authorized users, then repeat this action.`}

            <Box mt={1}>
              <div>{c('UserManagement').t`Authorized users`}:</div>
              <ul style={{ marginTop: '3px' }}>
                {subUsersRelatedToCompany.sort().map((subUserId) => (
                  <li key={subUserId}>{subUserId}</li>
                ))}
              </ul>
            </Box>
          </div>
        </ConfirmationDialog>
      )}

      {handleCompanyAccountRelatedDialog.show && (
        <ConfirmationDialog
          heading={c('UserManagement').t`CAUTION`}
          text={
            handleCompanyAccountRelatedDialog.from === companyAccountRelatedDialogFrom.companyAccountValueChange
              ? c('UserManagement')
                  .t`You cannot link this user to a company account while it still has the role PARTNER. Please remove the role and try again.`
              : c('UserManagement')
                  .t`You cannot assign the role PARTNER to this user because it is linked to a company account. Please unlink the user from the company account and try again.`
          }
          confirmAction={t`Ok`}
          onConfirm={handleConfirmChangingRolePartner}
          openDialog={handleCompanyAccountRelatedDialog.show}
        />
      )}
    </Flex>
  )
}

export default UserManagementDetailsFields
