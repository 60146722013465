import { GetTrainingInfoAction, TrainingInfoPerAsset } from 'modules/quality/quality.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  result: TrainingInfoPerAsset
  lastSetNetwork: Record<string, Date>
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: {},
  lastSetNetwork: {},
  loading: false,
  error: null,
}

const result = createReducer<State['result'], GetTrainingInfoAction>(
  (state = initialState.result, { type, trainingInfo }) => {
    if (type === actionTypes.GET_TRAINING_INFO_SUCCESS) {
      return { ...state, ...trainingInfo }
    }
    return state
  },
)

const lastSetNetwork = createReducer<State['lastSetNetwork'], GetTrainingInfoAction>(
  (state = initialState.lastSetNetwork, { type, trainingInfo }) => {
    if (type === actionTypes.GET_TRAINING_INFO_SUCCESS) {
      const assetTrainingInfo = Object.keys(trainingInfo).reduce<State['lastSetNetwork']>((previous, assetId) => {
        const lastModifiedDates = trainingInfo[assetId]
          .filter((info) => info.training.uuid) // exclude dummy networks for fresh assets, see: https://jira.enercast.de/browse/PR-3786
          .map((info) => info.lastModified)
        const next = {
          ...previous,
        }
        if (lastModifiedDates.length > 0) {
          next[assetId] = new Date(Math.max(...lastModifiedDates) * 1000)
        }
        return next
      }, {})
      return { ...state, ...assetTrainingInfo }
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetTrainingInfoAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.GET_TRAINING_INFO_SUCCESS:
    case actionTypes.GET_TRAINING_INFO_FAILURE:
      return false
    case actionTypes.GET_TRAINING_INFO_REQUEST:
      return true
  }
  return state
})

const error = createReducer<State['error'], GetTrainingInfoAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.GET_TRAINING_INFO_FAILURE:
      return error
    case actionTypes.GET_TRAINING_INFO_REQUEST:
    case actionTypes.GET_TRAINING_INFO_SUCCESS:
      return null
  }
  return state
})

export const getTrainingInfoReducer = combineReducers({ result, lastSetNetwork, loading, error })

// selectors

export const getTrainingInfoResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.quality.getTrainingInfo.result,
  (result) => result,
)
export const getTrainingInfoLastSetNetworkSelector = createSelector<
  any,
  State['lastSetNetwork'],
  State['lastSetNetwork']
>(
  (state) => state.quality.getTrainingInfo.lastSetNetwork,
  (lastSetNetwork) => lastSetNetwork,
)
export const getTrainingInfoLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.quality.getTrainingInfo.loading,
  (loading) => loading,
)
export const getTrainingInfoErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.quality.getTrainingInfo.error,
  (error) => error,
)
