import React from 'react'
import { IconButton, MenuItem, TextField } from '@material-ui/core'
import { Field, useField } from 'react-final-form'
import { FieldUnit, NumberFieldWithLabel } from 'ui/form/assetForm.style'
import { c, t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { Select } from 'final-form-material-ui'
import { getPenaltyCostTypes, PenaltyBandTypes } from 'utils/penaltyRegulations'
import Spacer from 'ui/Spacer'
import { FormControl } from '@material-ui/core'
import styled from 'styled-components'
import { colors, theme } from 'themes/theme-light'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { OnChange } from 'react-final-form-listeners'
import { AppUnits } from 'utils/units'
import { PenaltyBandCostType } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { HandleToValueChangeProps } from 'modules/data/penalties/PenaltyRegulationNew/PenaltyBandTypeSection'
import { isNumeric } from 'utils/dataFormatting'
import { useIndianTimeseriesList } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'

const SelectFormControl = styled(FormControl)`
  & .MuiFormControl-root {
    margin-top: -8px;
  }
  width: 100%;
`

const BandContainer = styled.div`
  width: 100%;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 10px;
  padding: 0.5em;
  margin: 0.5em 0;
`

interface InputContainerProps {
  width?: string
}
const InputContainer = styled.div<InputContainerProps>`
  & .MuiFormControl-root {
    width: ${(props) => props.width || 'inherit'};
  }
  margin-right: 1em;

  &:last-child {
    margin-right: 0;
  }
`

const SectionHeader = styled.div`
  margin-bottom: 0.2em;
  color: ${colors.primaryMain};
`

interface PenaltyBandWidgetProps {
  fieldArrayIndexKey: string
  fieldArrayKey: string
  index: number
  penaltyBandType: PenaltyBandTypes
  showDeleteAction: boolean
  onDeleteBand: (event: React.MouseEvent<HTMLElement>, index: number, bandType: PenaltyBandTypes) => void
  isFirstBand: boolean
  isLastBand: boolean
  onToValueChange: (props: HandleToValueChangeProps) => void
}

const PenaltyBandWidget: React.FC<PenaltyBandWidgetProps> = ({
  fieldArrayIndexKey,
  index,
  penaltyBandType,
  showDeleteAction,
  onDeleteBand,
  isFirstBand,
  isLastBand,
  onToValueChange,
  fieldArrayKey,
}) => {
  const indianTimeseriesListResult = useIndianTimeseriesList()

  const penaltyBandCostTypes = getPenaltyCostTypes()
  const penaltyCostType = useField(`${fieldArrayIndexKey}.penaltyCostType`).input.value
  const nextBandFromValue = useField(`${fieldArrayKey}[${index + 1}].from`).input.value

  const checkCurrentToValueNotSameAsNextFromValue = (currentValue: number | null) => {
    if (!isLastBand && isNumeric(currentValue) && currentValue !== nextBandFromValue) {
      onToValueChange({ currentBandToValue: currentValue, isLastBand, index })
    }
  }

  // const showDeleteAction = index > 1 && index === lastBandIndex
  return (
    <BandContainer>
      <SectionHeader>
        <Flex justifyContent="space-between">
          {t`Error Band `} {index + 1}
          {showDeleteAction && (
            <IconButton onClick={(event) => onDeleteBand(event, index, penaltyBandType)} size="small">
              <FontAwesomeIcon icon="trash-alt" size="xs" color={theme.palette.primary.main} />
            </IconButton>
          )}
        </Flex>
      </SectionHeader>

      <Flex>
        <InputContainer width="6em">
          <Field name={`${fieldArrayIndexKey}.kpi`}>
            {(props) => {
              return (
                <SelectFormControl>
                  <Select label={t`KPI`} input={props.input} meta={props.meta}>
                    <MenuItem value="deviation">{c('PenaltyRegulation').t`Deviation`}</MenuItem>
                  </Select>
                </SelectFormControl>
              )
            }}
          </Field>
        </InputContainer>

        <InputContainer width="4em">
          <Field name={`${fieldArrayIndexKey}.from`}>
            {(props) => {
              return (
                <NumberFieldWithLabel
                  disabled={!isFirstBand}
                  label={c('PenaltyRegulation').t`From`}
                  type="number"
                  input={props.input}
                  meta={props.meta}
                />
              )
            }}
          </Field>
        </InputContainer>

        <InputContainer width="4em">
          {isLastBand && (
            <div style={{ marginTop: '-8px' }}>
              <TextField value={`∞`} disabled={true} label={c('PenaltyRegulation').t`To`} />
            </div>
          )}
          {!isLastBand && (
            <>
              <Field name={`${fieldArrayIndexKey}.to`}>
                {(props) => {
                  if (isLastBand) {
                    return
                  } else {
                    return (
                      <NumberFieldWithLabel
                        label={c('PenaltyRegulation').t`To`}
                        type="number"
                        input={props.input}
                        meta={props.meta}
                      />
                    )
                  }
                }}
              </Field>

              <OnChange name={`${fieldArrayIndexKey}.to`}>
                {(value: string) => {
                  checkCurrentToValueNotSameAsNextFromValue(value ? parseInt(value) : null)
                }}
              </OnChange>
            </>
          )}
        </InputContainer>

        <InputContainer width={penaltyCostType === PenaltyBandCostType.TIMESERIES_PERCENTAGE ? '10.5em' : '22em'}>
          <Field name={`${fieldArrayIndexKey}.penaltyCostType`}>
            {(props) => {
              return (
                <SelectFormControl>
                  <Select label={c('PenaltyRegulation').t`Penalty type`} input={props.input} meta={props.meta}>
                    {penaltyBandCostTypes.map((costType) => {
                      return (
                        <MenuItem key={costType.id} value={costType.id}>
                          {costType.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </SelectFormControl>
              )
            }}
          </Field>
        </InputContainer>

        {penaltyCostType === PenaltyBandCostType.TIMESERIES_PERCENTAGE && (
          <InputContainer width="10.5em">
            <Field name={`${fieldArrayIndexKey}.timeseriesId`}>
              {(props) => {
                return (
                  <SelectFormControl>
                    <Select label={c('PenaltyRegulation').t`Market price`} input={props.input} meta={props.meta}>
                      {(indianTimeseriesListResult?.data || []).map((timeseries) => {
                        return (
                          <MenuItem key={timeseries} value={timeseries}>
                            {timeseries.replaceAll('_', ' ')}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </SelectFormControl>
                )
              }}
            </Field>
            <Spacer minWidth="1em" />
          </InputContainer>
        )}

        <InputContainer width="5em">
          <Flex>
            <Field name={`${fieldArrayIndexKey}.penaltyCostPerUnit`}>
              {(props) => {
                return (
                  <NumberFieldWithLabel
                    label={c('PenaltyRegulation').t`Penalty amount`}
                    type="number"
                    input={props.input}
                    meta={props.meta}
                  />
                )
              }}
            </Field>
            <FieldUnit>
              <Flex direction="row" alignItems="center">
                {penaltyCostType === PenaltyBandCostType.FIXED_AMOUNT ? (
                  <>{AppUnits.RUPEE_CURRENCY}</>
                ) : (
                  <>{AppUnits.PERCENTAGE}</>
                )}
              </Flex>
            </FieldUnit>
          </Flex>
        </InputContainer>
      </Flex>
    </BandContainer>
  )
}

export default PenaltyBandWidget
