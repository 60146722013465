import { useAllAssets } from 'modules/asset/api/assets.api'
import { CellRenderType, Column } from 'modules/reTable/reTable.types'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import React, { useMemo } from 'react'
import { QueryObserverResult } from 'react-query'
import InlineLoading from 'ui/InlineLoading'
import { usePenaltyCalculationError } from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'
import PenaltyCalculationTableCell from 'modules/data/penalties/penaltyCalculations/PenaltyCalculationTableCell'
import { isJson } from 'utils/dataFormatting'
import Flex from 'ui/styles/Flex'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'
import AssetIcon from 'modules/asset/AssetIcon'
import { PenaltyCalculationResult } from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import { Box } from '@material-ui/core'

interface PenaltyTableRowProps {
  item: QueryObserverResult<PenaltyCalculationResult, PenaltyCalculationResult>
  columns: Column[]
  showNumCapFraction: boolean
}

const PenaltyCalculationTableRow: React.FC<PenaltyTableRowProps> = ({
  item: penaltyResult,
  columns,
  showNumCapFraction,
}) => {
  const responseError = penaltyResult?.error?.message
  const errorData = isJson(responseError)
    ? (JSON.parse(responseError) as PenaltyCalculationResult)?.summaryResult
    : null

  const isFetched = penaltyResult?.isFetched
  const isLoading = penaltyResult?.isLoading

  const penaltySummary = penaltyResult?.data?.summaryResult || errorData

  const allAssets = useAllAssets()
  const selectedAssetsAndModelIds = useSelector(workspaceDraftAssetSelectionSelector)

  const asset = useMemo(
    () => (allAssets.data || []).find((a) => a.id === penaltySummary?.assetId || a.id === errorData?.assetId),
    [allAssets.data, penaltySummary, errorData],
  )

  const isBackCastPenalty = useMemo(() => penaltySummary?.isBackcastRequest || errorData?.isBackcastRequest, [
    penaltySummary,
    errorData,
  ])

  const modelName = useMemo(() => {
    const modelId = penaltySummary?.forecastModelId || errorData?.forecastModelId
    if (modelId) {
      const matchId = selectedAssetsAndModelIds.find((id) => id.includes(modelId))
      return getBackCastModelDataFromId(matchId).name
    } else return ''
  }, [penaltySummary, errorData, selectedAssetsAndModelIds])

  const resultErrorMsg = usePenaltyCalculationError(penaltySummary)

  const getAssetNameCell = () => {
    const cellValue = `${asset ? asset.name : ''} ${modelName ? modelName : ''} ${
      isBackCastPenalty ? ' [Backcast]' : ''
    }`
    return (
      <Flex style={{ width: 'fit-content' }} justifyContent="center" alignItems="center">
        {asset && <AssetIcon selected={false} asset={asset} size={AssetLinkIconSize} />}
        <Box ml={0.5}>
          <FixedWidthTextContainer text={cellValue} width="16em">
            {cellValue}
          </FixedWidthTextContainer>
        </Box>
      </Flex>
    )
  }

  return (
    <>
      {isFetched && !isLoading ? (
        <ReTableRow>
          {columns.map((column, index) => (
            <ReTableCell
              key={column.name}
              colSpan={index === 0 ? 2 : 1}
              align={column.cellRenderType === CellRenderType.NUMERIC ? 'right' : 'left'}
            >
              <div style={{ maxWidth: index === columns.length - 1 ? column.width : undefined }}>
                <PenaltyCalculationTableCell
                  asset={asset}
                  penalty={penaltySummary}
                  column={column}
                  onGetAssetNameCell={getAssetNameCell}
                  showNumCapFraction={showNumCapFraction}
                  resultErrorMsg={resultErrorMsg}
                />
              </div>
            </ReTableCell>
          ))}
        </ReTableRow>
      ) : (
        <ReTableRow>
          <ReTableCell colSpan={columns.length + 1}>
            <InlineLoading />
          </ReTableCell>
        </ReTableRow>
      )}
    </>
  )
}

export default React.memo(PenaltyCalculationTableRow)
