import { combineReducers } from 'redux'
import { takeEvery, takeLatest } from 'redux-saga/effects'

import {
  DELETE_WORKSPACE_CONFIGS_REQUEST,
  deleteWorkspaceConfigSaga,
  deleteWorkspaceConfigsReducer,
} from 'modules/workspace/store/deleteWorkspaceConfigs.state'
import {
  GET_WORKSPACE_CONFIGS_REQUEST,
  getWorkspaceConfigsReducer,
  getWorkspaceConfigsSaga,
} from 'modules/workspace/store/getWorkspaceConfigs.state'
import {
  SAVE_WORKSPACE_CONFIGS_REQUEST,
  saveWorkspaceConfigsReducer,
  saveWorkspaceConfigsSaga,
} from 'modules/workspace/store/saveWorkspaceConfigs.state'
import { viewReducer } from 'modules/workspace/store/view.state'
import { getDraftReducer, getWorkspaceDraftSaga } from 'modules/workspace/store/getWorkspaceDraft.state'
import {
  saveDraftReducer,
  saveWorkspaceDraftSaga,
  saveWorkspaceDraftSelectAssetSaga,
  saveWorkspaceDraftSelectModelSaga,
} from 'modules/workspace/store/saveWorkspaceDraft.state'
import {
  GET_WORKSPACE_DRAFT_REQUEST,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  SAVE_WORKSPACE_DRAFT_SELECT_ASSET,
  SAVE_WORKSPACE_DRAFT_SELECT_MODEL,
} from 'modules/workspace/store/workspace.types'
import { seriesReducer } from 'modules/workspace/store/series.state'

export const workspaceReducer = combineReducers({
  deleteWorkspaceConfigs: deleteWorkspaceConfigsReducer,
  getWorkspaceConfigs: getWorkspaceConfigsReducer,
  saveWorkspaceConfigs: saveWorkspaceConfigsReducer,
  getDraft: getDraftReducer,
  saveDraft: saveDraftReducer,
  series: seriesReducer,
  view: viewReducer,
})

export function* workspaceSaga() {
  yield takeEvery(DELETE_WORKSPACE_CONFIGS_REQUEST, deleteWorkspaceConfigSaga)
  yield takeLatest(GET_WORKSPACE_CONFIGS_REQUEST, getWorkspaceConfigsSaga)
  yield takeEvery(SAVE_WORKSPACE_CONFIGS_REQUEST, saveWorkspaceConfigsSaga)
  yield takeLatest(GET_WORKSPACE_DRAFT_REQUEST, getWorkspaceDraftSaga)
  yield takeEvery(SAVE_WORKSPACE_DRAFT_REQUEST, saveWorkspaceDraftSaga)
  yield takeEvery(SAVE_WORKSPACE_DRAFT_SELECT_ASSET, saveWorkspaceDraftSelectAssetSaga)
  yield takeEvery(SAVE_WORKSPACE_DRAFT_SELECT_MODEL, saveWorkspaceDraftSelectModelSaga)
}
