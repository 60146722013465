import React, { useCallback } from 'react'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { t } from 'ttag'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { Column } from 'modules/reTable/reTable.types'
import TrainingRow from 'modules/quality/TrainingTable/TrainingRow'

interface TrainingTableBodyProps {
  visibleItems: any[]
  columns: Column[]
}

const TrainingTableBody: React.FC<TrainingTableBodyProps> = ({ visibleItems, columns }) => {
  const handleGetCustomRowComponent = useCallback(
    (columns: Column[], item: any) => {
      return <TrainingRow error={null} tableHeaderHasActions={false} columns={columns} itemObject={item} />
    },
    [columns, visibleItems],
  )

  return (
    <ReTableBody>
      {visibleItems.length === 0 && (
        <ReTableRow>
          <ReTableCell colSpan={4}>
            <>{t`There is no ongoing trainings.`}</>
          </ReTableCell>
        </ReTableRow>
      )}

      {visibleItems.map((row: any) => {
        return (
          <ReTableEditableRow
            key={row.id}
            isRowEdit={false}
            rowItem={row}
            columns={columns}
            showEditableRowActions={false}
            getCustomRowComponent={handleGetCustomRowComponent}
            isCustomRow={row.uiLevel === 0}
          />
        )
      })}
    </ReTableBody>
  )
}

export default TrainingTableBody
