import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'

import { seriesSelector } from 'modules/workspace/store/series.state'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { c, jt, t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { convertSeriesToCSVAndStartDownload } from 'utils/csv-export'
import { lcm } from 'utils/math'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { convertUTCToLocalDate } from 'utils/date'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'
import styled from 'styled-components'
import {
  workspaceDraftAssetSelectionSelector,
  workspaceDraftDataStreamSelectionSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { getDataForDownload } from 'modules/workspace/api/lineChart.api'
import { BackcastCsvExport } from 'utils/backcast-csv-export'
import CircularProgress from '@material-ui/core/CircularProgress'
import LoadingButton from 'ui/form/LoadingButton'
import { theme } from 'themes/theme-light'
import { LinkToContactSupport } from 'ui/elements/MailLink'

const ErrorMessage = styled.div`
  margin: 0.5em 0;
  color: ${theme.palette.error.main};
  height: 1.6rem;
  width: 34em;
`

const DatesContainer = styled(Flex)`
  padding-bottom: 1em;
  *.MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
  }
`

// const InputFieldContainer = styled(Flex)`
//   & .MuiInput-formControl {
//     width: 10em;
//     margin-right: 3em;
//   }
// `

interface CSVExportDialogProps {
  onClose: () => void
}

enum downloadingBackcastError {
  SOME = 'some',
  ALL = 'all',
  NONE = '',
}

export enum TimeIntervalsFileTitles {
  MERGE_INTERPOLATION = 'interpolated',
  MERGE_AGGREGATION = 'aggregated',
}

const CSVExportDialog: React.FC<CSVExportDialogProps> = ({ onClose }) => {
  const dataStreamsSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const user = useSelector(getUserResultSelector)
  const series = useSelector(seriesSelector)
  const timezone = useSelector(getUserTimezoneSelector)
  const chartSelectedDateRangeInUTC = useWorkspaceChartSelectedDateRange()
  const chartSelectedDateRange = useMemo(
    () => [
      convertUTCToLocalDate(chartSelectedDateRangeInUTC[0]),
      convertUTCToLocalDate(chartSelectedDateRangeInUTC[1]),
    ],
    [chartSelectedDateRangeInUTC],
  )
  const hasAccessToSiteAssessmentBackcast = useMemo(() => hasPermissionForSiteAssessmentBackcast(user), [user])

  // const rangeOptions = useMemo(() => backCastRangeOptions(), [])

  const selectedSiteForecastDataStreams = dataStreamsSelection?.filter(
    (dataStream) => dataStream.type === TimeSeriesType.SITE_FORECAST,
  )

  // const chartSelectedDateRange = useWorkspaceChartSelectedDateRange()

  // we remove this feature for now, because of:
  // https://jira.enercast.de/browse/PR-5390?focusedCommentId=205902&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-205902
  // const hasAccessToAdvancedExportOptions = isAdmin(user) || isImpersonated(user) || isDemo(user)
  const hasAccessToAdvancedExportOptions = series.length > 1

  enum Mode {
    MERGE_INTERPOLATION = 'MERGE_INTERPOLATION',
    MERGE_AGGREGATION = 'MERGE_AGGREGATION',
    MERGE_RAW = 'MERGE_RAW',
    SINGLE_RAW = 'SINGLE_RAW',
  }
  const [errorDownloadingBackcast, setErrorDownloadingBackcast] = useState<string>(downloadingBackcastError.NONE)
  const [loadingDownloadingBackcast, setLoadingDownloadingBackcast] = useState<boolean>(false)
  // const [staticPeriod, setStaticPeriod] = useState<BackCastRangeType>(BackCastRangeType.CHART_DATA_RANGE_CUSTOM)
  // const [timePeriod, setTimePeriod] = useState<BackCastTimePeriod>({
  //   start: new Date(chartSelectedDateRange[0]),
  //   end: new Date(chartSelectedDateRange[1]),
  // })
  const [mode, setMode] = useState<Mode>(hasAccessToAdvancedExportOptions ? Mode.MERGE_INTERPOLATION : Mode.SINGLE_RAW)
  const availableIntervals = useMemo(() => {
    const allIntervals = series.reduce<number[]>((result, s) => {
      return !s?.custom?.detectedInterval || result.includes(s?.custom?.detectedInterval)
        ? result
        : [...result, s?.custom?.detectedInterval]
    }, [])
    allIntervals.sort((a, b) => (Number(a) < Number(b) ? -1 : 1))
    return allIntervals
  }, [series])

  const smallestInterval = useMemo(() => {
    return availableIntervals.length > 0 ? availableIntervals[0] : 1000 * 60 * 60
  }, [availableIntervals])
  const leastCommonMultipleInterval = useMemo(() => {
    return availableIntervals.length > 0 ? availableIntervals.reduce(lcm) : 1000 * 60 * 60
  }, [availableIntervals])

  const interpolationMinutes = useMemo(() => smallestInterval / 1000 / 60, [smallestInterval])
  const aggregationMinutes = useMemo(() => leastCommonMultipleInterval / 1000 / 60, [leastCommonMultipleInterval])

  const handleChangeMode = useCallback((e) => {
    setMode(e.target.value)
  }, [])

  const handleDownloadSingle = useCallback(
    (s) => {
      convertSeriesToCSVAndStartDownload([s], timezone, chartSelectedDateRange)
    },
    [series, timezone, chartSelectedDateRange, onClose],
  )

  const handleDownload = useCallback(() => {
    convertSeriesToCSVAndStartDownload(series, timezone, chartSelectedDateRange)
  }, [series, timezone, chartSelectedDateRange])

  const handleDownloadWithSmallestInterval = useCallback(() => {
    convertSeriesToCSVAndStartDownload(
      series,
      timezone,
      chartSelectedDateRange,
      smallestInterval,
      TimeIntervalsFileTitles.MERGE_INTERPOLATION,
    )
  }, [series, timezone, chartSelectedDateRange, smallestInterval])

  const handleDownloadWithLeastCommonMultipleInterval = useCallback(() => {
    convertSeriesToCSVAndStartDownload(
      series,
      timezone,
      chartSelectedDateRange,
      leastCommonMultipleInterval,
      TimeIntervalsFileTitles.MERGE_AGGREGATION,
    )
  }, [series, timezone, chartSelectedDateRange, leastCommonMultipleInterval])

  const handleDownloadBackcast = useCallback(() => {
    const promises: any[] = []

    setLoadingDownloadingBackcast(true)

    assetSelection.forEach((assetSelectedId) => {
      selectedSiteForecastDataStreams.forEach((selectedSiteForecast) => {
        const name = series.find((s) => {
          const id = s.custom.result?.data?.id || s.custom.result?.error?.id || ''
          const timeSeriesType = s.custom.result?.data?.type || s.custom.result?.error?.type || ''
          const dataStreamId = s.custom?.datastreamId || ''
          return (
            timeSeriesType === TimeSeriesType.BACK_CAST &&
            id === assetSelectedId &&
            dataStreamId === selectedSiteForecast?.id
          )
        })?.name
        promises.push(getDataForDownload(assetSelectedId, selectedSiteForecast?.id, timezone, name))
      })
    })

    Promise.allSettled(promises)
      .then((res) => {
        let atLeastOneResult
        res.forEach((singleRes) => {
          if (singleRes.status === 'fulfilled') {
            atLeastOneResult = true
            BackcastCsvExport(singleRes.value.csvResult, timezone, singleRes.value.csvName)
          } else {
            setErrorDownloadingBackcast(downloadingBackcastError.SOME)
          }
        })

        if (!atLeastOneResult) {
          setErrorDownloadingBackcast(downloadingBackcastError.ALL)
        }
        setLoadingDownloadingBackcast(false)
      })
      .catch((err) => console.log(err))
  }, [assetSelection, selectedSiteForecastDataStreams, timezone, series])

  // Start and End date Handlers
  // const handleChangeStartDate = (value: Date) => {
  //   setTimePeriod((prev) => {
  //     return { ...prev, start: value }
  //   })
  //   setStaticPeriod(BackCastRangeType.CHART_DATA_RANGE_CUSTOM)
  // }

  // const handleChangeEndDate = (value: Date) => {
  //   setTimePeriod((prev) => {
  //     return { ...prev, end: value }
  //   })
  //   setStaticPeriod(BackCastRangeType.CHART_DATA_RANGE_CUSTOM)
  // }

  // const handleRangeChange = (event: React.ChangeEvent<{ value: BackCastRangeType }>) => {
  //   const rangeType = event.target.value
  //   setStaticPeriod(rangeType)
  //   if (rangeType !== BackCastRangeType.CHART_DATA_RANGE_CUSTOM) {
  //     const period = getDateRangeFromChartDataRange({ rangeType, customRange: null })
  //     setTimePeriod({
  //       start: new Date(period[0]),
  //       end: new Date(period[1]),
  //     })
  //   }
  // }

  // If there is a match between date range and range option , range option should be updated with the correct value.
  // useEffect(() => {
  // const isTimePeriodMatchingAnyRange = (rangeOptions || []).find((option) => {
  //   const period = getDateRangeFromChartDataRange({ rangeType: option?.key, customRange: null })
  //   return (
  //     isSameDay(new Date(period[0]), new Date(timePeriod.start)) &&
  //     isSameDay(new Date(period[1]), new Date(timePeriod.end))
  //   )
  // })
  // if (isTimePeriodMatchingAnyRange) {
  //   setStaticPeriod(isTimePeriodMatchingAnyRange.key)
  // }
  // }, [timePeriod, rangeOptions])

  const handleClose = useCallback(() => {
    setErrorDownloadingBackcast(downloadingBackcastError.NONE)
    onClose()
  }, [onClose])

  return (
    <Dialog
      open={true}
      // disableEscapeKeyDown={true}
      // disableBackdropClick={true}
      onClose={handleClose}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
      maxWidth={'lg'}
    >
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">{t`Export as CSV`}</Typography>
      </DialogTitle>
      <DialogContent>
        <div>
          {hasAccessToAdvancedExportOptions
            ? c('Workbench:CSV Export')
                .t`Download CSV file with currently displayed series. You can choose how it should be formatted below.`
            : c('Workbench:CSV Export').t`Download CSV files with currently displayed series.`}
        </div>
        <Box my={1}>
          {hasAccessToAdvancedExportOptions && (
            <RadioGroup name="mode" value={mode} onChange={handleChangeMode}>
              <Flex direction="column">
                <FormControlLabel
                  value={Mode.MERGE_INTERPOLATION}
                  control={<Radio size="small" />}
                  label={c('Workbench:CSV Export')
                    .t`Interpolated data to fit in smallest time interval (${interpolationMinutes} min)`}
                  title={c('Workbench:CSV Export')
                    .t`Good for calculations and data streams with values of different time intervals.`}
                />
                <FormControlLabel
                  value={Mode.MERGE_AGGREGATION}
                  control={<Radio size="small" />}
                  label={c('Workbench:CSV Export')
                    .t`Aggregated data to fit in least common multiple of all time intervals (${aggregationMinutes} min)`}
                  title={c('Workbench:CSV Export')
                    .t`Good for calculations and data streams with values of different time intervals.`}
                />
                <FormControlLabel
                  value={Mode.MERGE_RAW}
                  control={<Radio size="small" />}
                  label={c('Workbench:CSV Export').t`All data streams merged together`}
                  title={c('Workbench:CSV Export')
                    .t`Good to compute raw data and for data streams with equal time intervals.`}
                />
                <FormControlLabel
                  value={Mode.SINGLE_RAW}
                  control={<Radio size="small" />}
                  label={c('Workbench:CSV Export').t`Each data stream in a separate file`}
                  title={c('Workbench:CSV Export')
                    .t`Good to compute raw data for data streams with values of different time intervals.`}
                />
              </Flex>
            </RadioGroup>
          )}

          <Box mt={2}>
            {mode === Mode.MERGE_INTERPOLATION && (
              <>
                <Button size="small" variant="contained" onClick={handleDownloadWithSmallestInterval}>
                  {c('Workbench:CSV Export').t`Download interpolated CSV (${interpolationMinutes} min)`}
                </Button>
              </>
            )}
            {mode === Mode.MERGE_AGGREGATION && (
              <>
                <Button size="small" variant="contained" onClick={handleDownloadWithLeastCommonMultipleInterval}>
                  {c('Workbench:CSV Export').t`Download aggregated CSV (${aggregationMinutes} min)`}
                </Button>
              </>
            )}
            {mode === Mode.MERGE_RAW && (
              <Button size="small" variant="contained" onClick={handleDownload}>
                {c('Workbench:CSV Export').t`Download merged CSV`}
              </Button>
            )}
            {mode === Mode.SINGLE_RAW && (
              <>
                {series.map((s) => (
                  <Box key={s.name} mb={1}>
                    <Button size="small" variant="contained" onClick={() => handleDownloadSingle(s)}>
                      {c('Workbench:CSV Export').t`Download CSV for "${s.name}"`}
                    </Button>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </Box>

        <Box mt={2}>
          {hasAccessToSiteAssessmentBackcast && selectedSiteForecastDataStreams.length > 0 && (
            <>
              <>{t`Backcast`}</>
              <DatesContainer direction="row" alignItems="center">
                {/*  <InputFieldContainer>*/}
                {/*    <FormControl>*/}
                {/*      <InputLabel id="download-period-select-label">{t`Time range`}</InputLabel>*/}
                {/*      <Select*/}
                {/*        labelId="download-period-select-label"*/}
                {/*        id="download-period-select"*/}
                {/*        value={staticPeriod}*/}
                {/*        onChange={handleRangeChange}*/}
                {/*      >*/}
                {/*        {rangeOptions?.map((item) => (*/}
                {/*          <MenuItem key={item.key} value={item.key}>*/}
                {/*            {item.label}*/}
                {/*          </MenuItem>*/}
                {/*        ))}*/}
                {/*      </Select>*/}
                {/*    </FormControl>*/}
                {/*  </InputFieldContainer>*/}

                {/*  <InputFieldContainer alignItems="center">*/}
                {/*    <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*      <KeyboardDatePicker*/}
                {/*        onChange={handleChangeStartDate}*/}
                {/*        value={timePeriod?.start || null}*/}
                {/*        format={'yyyy-MM-dd'}*/}
                {/*        variant="inline"*/}
                {/*        label={t`Start`}*/}
                {/*        autoOk={true}*/}
                {/*      />*/}
                {/*    </MuiPickersUtilsProvider>*/}
                {/*  </InputFieldContainer>*/}

                {/*  <InputFieldContainer alignItems="center">*/}
                {/*    <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                {/*      <KeyboardDatePicker*/}
                {/*        variant="inline"*/}
                {/*        label={t`End`}*/}
                {/*        onChange={handleChangeEndDate}*/}
                {/*        value={timePeriod?.end || null}*/}
                {/*        format={'yyyy-MM-dd'}*/}
                {/*        autoOk={true}*/}
                {/*        minDate={timePeriod?.start}*/}
                {/*        minDateMessage={t`Should be after start date`}*/}
                {/*      />*/}
                {/*    </MuiPickersUtilsProvider>*/}
                {/*  </InputFieldContainer>*/}
              </DatesContainer>
              <LoadingButton
                variant="contained"
                size="small"
                startIcon={
                  loadingDownloadingBackcast && (
                    <CircularProgress style={{ cursor: 'help' }} size={22} thickness={4.5} />
                  )
                }
                onClick={handleDownloadBackcast}
              >{c('Workbench:CSV Export').t`Download all available backcast`}</LoadingButton>
              {errorDownloadingBackcast.length > 0 && (
                <ErrorMessage>
                  <div>
                    {errorDownloadingBackcast === downloadingBackcastError.ALL
                      ? jt`Could not start backcast downloading. Please contact ${LinkToContactSupport}.`
                      : jt`Could not start backcast downloading for some of the selected Site Forecasts or Assets. Please contact ${LinkToContactSupport}.`}
                  </div>
                </ErrorMessage>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" size="small">
          {t`Close`}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(CSVExportDialog)
