import { ACKNOWLEDGE_SPLASH_SCREEN_REQUEST } from 'modules/app/splashScreen/redux_store/splash.screen.action.types'
import { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'

import { AcknowledgeSplashScreenAction } from 'modules/app/splashScreen/redux_store/state/splashScreen.acknowledge'
import { setShowSplashScreen } from 'modules/app/splashScreen/redux_store/splash.screen.api'

export function* acknowledgeSplashScreenSaga({ value }: AcknowledgeSplashScreenAction): SagaIterator {
  // Here we negate the value to manage displaying splash screen
  const data = !value
  yield call(setShowSplashScreen, data)
}

export function* splashScreenSaga() {
  yield takeLatest(ACKNOWLEDGE_SPLASH_SCREEN_REQUEST, acknowledgeSplashScreenSaga)
}
