import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { ReTableCell } from 'modules/reTable/ReTableCell'
import ReTableHeaderContextMenu from 'modules/reTable/ReTableHeaderContextMenu'
import ReTableHeaderTreeToggle from 'modules/reTable/ReTableHeaderTreeToggle'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'
import { useReTableId, useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import {
  reTableCollapsibleIdsSelector,
  reTableColumnsSelectedSelector,
  reTableSearchSelector,
  reTableSortDefaultSelector,
  reTableSortSelector,
} from 'modules/reTable/redux_store/state/view.state'
import {
  RETABLE_COLLAPSE_SUBTREES,
  RETABLE_COLLAPSE_SUBTREES_SEARCH,
  RETABLE_EXPAND_SUBTREES,
  RETABLE_EXPAND_SUBTREES_SEARCH,
  RETABLE_SET_COLUMNS_SELECTED,
  RETABLE_SET_SORT,
} from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch } from 'react-redux'
import { Column } from 'modules/reTable/reTable.types'

export const TableHeaderActionCellWidth = '3.2em'

const HeaderActionCell = styled(ReTableCell)`
  &.MuiTableCell-root {
    padding-right: 0;
    vertical-align: baseline;
  }
`

interface ReTableHeaderActionsCellProps {
  children?: React.ReactNode
  isHierarchical: boolean
  inline?: boolean
  isFormOpen?: boolean
  onSelectAllLeafs?: () => void
  onSelectEntireBranch?: () => void
  onToggleModelRows?: () => void
  onSelectAll?: () => void
  cellWidth?: string
}

const ReTableHeaderActionsCell: React.FC<ReTableHeaderActionsCellProps> = ({
  children,
  isHierarchical,
  isFormOpen,
  onSelectAllLeafs,
  onSelectEntireBranch,
  onToggleModelRows,
  cellWidth,
  onSelectAll,
}) => {
  const dispatch = useDispatch()
  const [ref, tableId] = useReTableId()
  const collapsibleIds = useReTableSelectorWithId(reTableCollapsibleIdsSelector, tableId)
  const search = useReTableSelectorWithId(reTableSearchSelector, tableId)
  const sort = useReTableSelectorWithId(reTableSortSelector, tableId)
  const sortDefault = useReTableSelectorWithId(reTableSortDefaultSelector, tableId)
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, tableId)
  const columnsSelectedNames = useMemo(() => columnsSelected.map((c) => c.name), [columnsSelected])

  const handleExpandAllRows = useCallback(() => {
    dispatch({
      type: search ? RETABLE_EXPAND_SUBTREES_SEARCH : RETABLE_EXPAND_SUBTREES,
      table: tableId,
      ids: collapsibleIds,
    })
  }, [collapsibleIds, tableId, search])

  const handleCollapseAllRows = useCallback(() => {
    dispatch({
      type: search ? RETABLE_COLLAPSE_SUBTREES_SEARCH : RETABLE_COLLAPSE_SUBTREES,
      table: tableId,
      ids: collapsibleIds,
    })
  }, [collapsibleIds, tableId, search])

  const handleToggleTree = useCallback(() => {
    const newSort = sort.active ? { active: false } : sortDefault
    dispatch({
      type: RETABLE_SET_SORT,
      table: tableId,
      sort: newSort,
    })
  }, [sort.active, sortDefault, tableId])

  const handleColumnSelection = useCallback(
    (column: Column) => {
      const columns = columnsSelectedNames.includes(column.name)
        ? columnsSelectedNames.filter((c) => c !== column.name)
        : [...columnsSelectedNames, column.name]

      dispatch({
        type: RETABLE_SET_COLUMNS_SELECTED,
        table: tableId,
        columnsSelected: columns,
      })
    },
    [columnsSelectedNames, tableId],
  )

  return (
    <HeaderActionCell
      no_border={true}
      width={cellWidth ? cellWidth : isHierarchical ? TableHeaderActionCellWidth : '1.5em'}
    >
      <Flex ref={ref} direction="column">
        {children}
        <Box ml={1}>
          <Flex alignItems="center">
            <ReTableHeaderContextMenu
              disabled={sort.active || isFormOpen}
              isHierarchical={isHierarchical}
              onExpandRows={handleExpandAllRows}
              onCollapseRows={handleCollapseAllRows}
              onSelectAll={onSelectAll}
              onSelectAllLeafs={onSelectAllLeafs}
              onSelectEntireBranch={onSelectEntireBranch}
              onToggleModelRows={onToggleModelRows}
              onColumnSelection={handleColumnSelection}
            />
            {isHierarchical && <ReTableHeaderTreeToggle sort={sort} onToggle={handleToggleTree} />}
          </Flex>
        </Box>
      </Flex>
    </HeaderActionCell>
  )
}

export default React.memo(ReTableHeaderActionsCell)
