import { useAllAssets } from 'modules/asset/api/assets.api'
import { useStage } from 'modules/asset/api/metadata.api'
import { GET_ALL_PLAUSIBILITY_REQUEST } from 'modules/asset/plausability/redux_store/plausability.action.types'
import { GET_METERDATA_SUMMARIES_REQUEST } from 'modules/asset/store/getMeterdataSummaries.state'
import {
  GET_USER_TIMEZONE_REQUEST,
  GET_USERCONFIG_REQUEST,
  LEAVE_IMPERSONATE_REQUEST,
  LOGOUT_REQUEST,
} from 'modules/auth/redux_store/auth.action.types'
import {
  authedSelector,
  getUserInitializedSelector,
  getUserResultSelector,
} from 'modules/auth/redux_store/state/getUser'
import { GET_AREACONFIGS_REQUEST } from 'modules/data/store/getAreaConfigs.state'
import { GET_METADATA_REQUEST } from 'modules/data/store/getMetaData.state'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { GET_PRODUCTS_REQUEST } from 'modules/product/store/getProducts.state'
import {
  GET_QUALITY_OVERVIEW_REQUEST,
  GET_TRAINING_JOBS_REQUEST,
} from 'modules/quality/redux_store/quality.action.types'
import { activateForecastModelIsActivatedSelector } from 'modules/quality/redux_store/state/activateForecastModel'
import { reevaluateForecastModelIsReEvaluatedSelector } from 'modules/quality/redux_store/state/reevaluateForecastModel'
import { useReTableFetchSavedData, useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { GET_WEATHERCONFIGS_REQUEST } from 'modules/weather/store/getWeatherConfig.state'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import {
  QUERY_LEAVE_IMPERSONATE,
  QUERY_LOGOUT,
  QUERY_SWITCH_TO_CLASSIC,
  useQueryMatch,
  useQueryString,
} from 'utils/query-string'
import { switchToClassicUI } from 'utils/request'
import { useRouter, useRouterMatch } from 'utils/route'
import { isImpersonatedAdmin, isImpersonatedPartner } from 'utils/user'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { ROUTE_WORKBENCH_ASSET_TABLE } from 'modules/app/Routes'

const BootstrapContainer = styled(Flex)`
  height: 100%;
`

interface BootstrapProps {
  onInitialized: () => void
  onUninitialized: () => void
}
const Bootstrap: React.FC<BootstrapProps> = ({ onInitialized, onUninitialized, children }) => {
  const dispatch = useDispatch()

  // assetTableColumnsSelected and isAssetTable are used to make meter data summaries request conditionally
  const assetTableColumnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_ASSETS)
  const { isMatch: isAssetTable } = useRouterMatch(ROUTE_WORKBENCH_ASSET_TABLE)

  const allAssets = useAllAssets()
  const stage = useStage()
  const forecastConfigs = useSiteForecastConfigs()
  const forecastModelsActivated = useSelector(activateForecastModelIsActivatedSelector)
  const forecastModelsReEvaluated = useSelector(reevaluateForecastModelIsReEvaluatedSelector)

  const user = useSelector(getUserResultSelector)
  const userInitialized = useSelector(getUserInitializedSelector)
  const authed = useSelector(authedSelector)

  const { dispatchReTableFetchSavedData } = useReTableFetchSavedData()
  const collectionId = 'uiSettings'

  // routing based on query strings
  const { history } = useRouter()
  const isLeaveImpersonate = useQueryMatch(QUERY_LEAVE_IMPERSONATE)
  const isLogout = useQueryMatch(QUERY_LOGOUT)
  const isSwitchToClassic = useQueryMatch(QUERY_SWITCH_TO_CLASSIC)
  const { onDeleteQueryStrings } = useQueryString()

  const debounceLeaveImpersonate = useDebouncedCallback(() => {
    dispatch({ type: LEAVE_IMPERSONATE_REQUEST, history })
  }, 500)

  useEffect(() => {
    if (isLeaveImpersonate && (isImpersonatedAdmin(user, true) || isImpersonatedPartner(user))) {
      onDeleteQueryStrings([QUERY_LEAVE_IMPERSONATE])
      debounceLeaveImpersonate()
    }
  }, [isLeaveImpersonate])

  useEffect(() => {
    if (isLogout) {
      onUninitialized()
      onDeleteQueryStrings([QUERY_LOGOUT])
      dispatch({ type: LOGOUT_REQUEST })
    }
  }, [isLogout, onUninitialized, onDeleteQueryStrings])

  useEffect(() => {
    if (isSwitchToClassic && user) {
      onDeleteQueryStrings([QUERY_SWITCH_TO_CLASSIC])
      switchToClassicUI(user)
    }
  }, [isSwitchToClassic, user, onDeleteQueryStrings])

  // initialization logic
  useEffect(() => {
    if (userInitialized) {
      onInitialized()
    }
  }, [userInitialized, onInitialized])

  useEffect(() => {
    // load initial data
    const currentUser = user?.login || ''
    // TODO this won't be necessary with react-query!
    if (currentUser) {
      // essentials
      dispatch({ type: GET_USER_TIMEZONE_REQUEST })
      dispatch({ type: GET_USERCONFIG_REQUEST, collectionId })

      dispatch({ type: GET_PRODUCTS_REQUEST })

      // forecast related
      dispatch({ type: GET_METADATA_REQUEST })
      dispatch({ type: GET_WEATHERCONFIGS_REQUEST })

      // asset related
      // dispatch({ type: GET_ALL_AVAILABILITY_REQUEST })

      // reTable related
      dispatchReTableFetchSavedData()

      // plausibility related
      dispatch({ type: GET_ALL_PLAUSIBILITY_REQUEST })

      // other
      dispatch({ type: GET_AREACONFIGS_REQUEST })
      dispatch({ type: GET_TRAINING_JOBS_REQUEST })
    } else {
    }
  }, [user?.login])

  useEffect(() => {
    if ((allAssets.data || []).length > 0 && assetTableColumnsSelected.length > 0) {
      const isMeterDataColumnSelected = assetTableColumnsSelected.find((column) => column.name === 'meterdata')

      // load additional data for assets
      // In order to make meter data summaries request this column should be selected and asset table should be totally visible (wide)
      if (isMeterDataColumnSelected && isAssetTable) {
        dispatch({ type: GET_METERDATA_SUMMARIES_REQUEST, assets: allAssets.data })
      }
    }
  }, [allAssets.data, assetTableColumnsSelected, isAssetTable])

  useEffect(() => {
    if ((allAssets.data || []).length > 0 && (forecastConfigs.data || []).length > 0) {
      // load additional data for assets and forecast configs
      // TODO load only needed data
      dispatch({ type: GET_QUALITY_OVERVIEW_REQUEST, assets: allAssets.data, forecastConfigs: forecastConfigs.data })
    }
  }, [allAssets.data, forecastConfigs.data, forecastModelsActivated, forecastModelsReEvaluated])

  useEffect(() => {
    if (!userInitialized || !authed) return

    dispatch({ type: GET_ALL_PLAUSIBILITY_REQUEST })
  }, [stage])

  return <BootstrapContainer direction="column">{children}</BootstrapContainer>
}

export default React.memo(Bootstrap)
