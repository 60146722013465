import styled from 'styled-components'
import { LARGE_SCREEN_MEDIA_QUERY } from '../styles/styleVariables'

interface FlexProps {
  direction?: string
  display?: string
  justifyContent?: string
  alignItems?: string
  flexWrap?: string
  flexBasis?: string
  flexGrow?: number
  flexShrink?: number
  gap?: string
  fullHeight?: boolean
  largeScreenFlexGrow?: string
  largeScreenFlexBasis?: string
}

const Flex = styled.div<FlexProps>`
  display: ${(props) => props.display || 'flex'};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) => props.flexWrap};
  flex-basis: ${(props) => props.flexBasis};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
  gap: ${(props) => props.gap};
  height: ${(props) => (props.fullHeight ? '100%' : 'auto')};
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    flex-grow: ${(props) => props.largeScreenFlexGrow};
    flex-basis: ${(props) => props.largeScreenFlexBasis};
  }
`

export default Flex
