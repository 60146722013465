import React from 'react'
import { colors, theme } from 'themes/theme-light'
import { CellRenderType, Column, ReTableItem } from 'modules/reTable/reTable.types'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import Number from 'ui/Number'
import { isAsset } from 'utils/asset'
import AssetIcon from 'modules/asset/AssetIcon'
import { getPrintTableCellBackgroundColor, getPrintTableCellBorderColor } from 'utils/printView'
import { rgba } from 'polished'

export enum PRINT_VIEW_ROW_TYPE {
  ONLY_VALUE = 'ONLY_VALUE',
}

const PrintTable = styled.table`
  border-collapse: collapse;
`

const PrimaryText = styled.div`
  color: ${theme.palette.primary.main};
`

interface PrintIndentDataProps {
  uiLevel: number | undefined
}
const PrintIndentData = styled.div<PrintIndentDataProps>`
  padding-left: ${(props) => (props.uiLevel ? props.uiLevel / 3 : 0)}em;
`

interface PrintTableThProps {
  width: string | number
}

const PrintTableTh = styled.th<PrintTableThProps>`
  height: 20px;
  background-color: ${colors.markedLight};
  padding: 5px 2px;
  width: ${(props) => props.width || 'inherit'};
  font-weight: normal;
`

interface PrintTableTdProps {
  width: string
  cell_bg_color?: string
  cell_border_bottom_color?: string
}

const PrintTableTd = styled.td<PrintTableTdProps>`
  padding: 5px 2px;
  width: ${(props) => props.width || 'inherit'};
  background-color: ${(props) => props.cell_bg_color || 'inherit'};
  border-bottom: 1px solid ${(props) => props.cell_border_bottom_color || rgba(224, 224, 224, 1)};
`

interface DummyTableProps {
  columns: Column[]
  itemsToRender: ReTableItem[]
}

const PrintViewTable: React.FC<DummyTableProps> = ({ columns, itemsToRender }) => {
  const { CUSTOM_NUMERIC, NUMERIC } = CellRenderType

  return (
    <PrintTable>
      {/*Table Header*/}
      <tr>
        {columns.map((col) => (
          <PrintTableTh width={col.width as string} key={`${col.name + col.label}`}>
            <Flex
              justifyContent={
                col.cellRenderType === NUMERIC || col.cellRenderType === CUSTOM_NUMERIC ? 'right' : 'left'
              }
            >
              {col.label}
            </Flex>
          </PrintTableTh>
        ))}
      </tr>

      {/*Table Body*/}
      {itemsToRender.map((item) => {
        return (
          <tr key={item.id}>
            {item?.rowType === PRINT_VIEW_ROW_TYPE.ONLY_VALUE ? (
              <PrintTableTd width={''} colSpan={columns.length}>
                <PrimaryText>{item?.value || ''}</PrimaryText>
              </PrintTableTd>
            ) : isAsset(item) ? (
              <PrintTableTd style={{ padding: '3px 0px' }} width={''} colSpan={columns.length}>
                <Flex alignItems="center" gap={'3px'}>
                  <AssetIcon asset={item} size={'14'} />
                  <PrimaryText>{item.name}</PrimaryText>
                </Flex>
              </PrintTableTd>
            ) : (
              columns.map((col) => {
                const cellRenderType = col.cellRenderType
                const cellData = item?.[col?.name] ? item?.[col?.name] : null

                return (
                  <PrintTableTd
                    cell_bg_color={getPrintTableCellBackgroundColor({ column: col, rowData: item })}
                    cell_border_bottom_color={getPrintTableCellBorderColor({ column: col, rowData: item })}
                    width={col.width as string}
                    key={col.name}
                  >
                    <PrintIndentData uiLevel={col.firstColumn ? item.uiLevel : 0}>
                      {cellRenderType === NUMERIC || cellRenderType === CUSTOM_NUMERIC ? (
                        <Flex justifyContent={'right'}>
                          {cellData ? <Number data={cellData as number} limit={3} /> : '-'}
                        </Flex>
                      ) : (
                        <>{cellData || '-'}</>
                      )}
                    </PrintIndentData>
                  </PrintTableTd>
                )
              })
            )}
          </tr>
        )
      })}
    </PrintTable>
  )
}

export default PrintViewTable
