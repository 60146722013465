export const GET_FORECAST_MODELS_REQUEST = 'GET_FORECAST_MODELS_REQUEST'
export const GET_FORECAST_MODELS_SUCCESS = 'GET_FORECAST_MODELS_SUCCESS'
export const GET_FORECAST_MODELS_FAILURE = 'GET_FORECAST_MODELS_FAILURE'
export type GET_FORECAST_MODELS_REQUEST = typeof GET_FORECAST_MODELS_REQUEST
export type GET_FORECAST_MODELS_SUCCESS = typeof GET_FORECAST_MODELS_SUCCESS
export type GET_FORECAST_MODELS_FAILURE = typeof GET_FORECAST_MODELS_FAILURE
export type GET_FORECAST_MODELS_ACTION_TYPE =
  | GET_FORECAST_MODELS_REQUEST
  | GET_FORECAST_MODELS_SUCCESS
  | GET_FORECAST_MODELS_FAILURE

export const TRIGGER_WEIGHT_OPTIMIZATION_REQUEST = 'TRIGGER_WEIGHT_OPTIMIZATION_REQUEST'
export const TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS = 'TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS'
export const TRIGGER_WEIGHT_OPTIMIZATION_FAILURE = 'TRIGGER_WEIGHT_OPTIMIZATION_FAILURE'
export type TRIGGER_WEIGHT_OPTIMIZATION_REQUEST = typeof TRIGGER_WEIGHT_OPTIMIZATION_REQUEST
export type TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS = typeof TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS
export type TRIGGER_WEIGHT_OPTIMIZATION_FAILURE = typeof TRIGGER_WEIGHT_OPTIMIZATION_FAILURE
export type TRIGGER_WEIGHT_OPTIMIZATION_ACTION_TYPE =
  | TRIGGER_WEIGHT_OPTIMIZATION_REQUEST
  | TRIGGER_WEIGHT_OPTIMIZATION_SUCCESS
  | TRIGGER_WEIGHT_OPTIMIZATION_FAILURE

export const SAVE_FORECAST_MODEL_REQUEST = 'SAVE_FORECAST_MODEL_REQUEST'
export const SAVE_FORECAST_MODEL_SUCCESS = 'SAVE_FORECAST_MODEL_SUCCESS'
export const SAVE_FORECAST_MODEL_FAILURE = 'SAVE_FORECAST_MODEL_FAILURE'
export type SAVE_FORECAST_MODEL_REQUEST = typeof SAVE_FORECAST_MODEL_REQUEST
export type SAVE_FORECAST_MODEL_SUCCESS = typeof SAVE_FORECAST_MODEL_SUCCESS
export type SAVE_FORECAST_MODEL_FAILURE = typeof SAVE_FORECAST_MODEL_FAILURE
export type SAVE_FORECAST_MODEL_ACTION_TYPE =
  | SAVE_FORECAST_MODEL_REQUEST
  | SAVE_FORECAST_MODEL_SUCCESS
  | SAVE_FORECAST_MODEL_FAILURE

export const HIDE_FORECAST_MODEL_REQUEST = 'HIDE_FORECAST_MODEL_REQUEST'
export const HIDE_FORECAST_MODEL_SUCCESS = 'HIDE_FORECAST_MODEL_SUCCESS'
export const HIDE_FORECAST_MODEL_FAILURE = 'HIDE_FORECAST_MODEL_FAILURE'
export type HIDE_FORECAST_MODEL_REQUEST = typeof HIDE_FORECAST_MODEL_REQUEST
export type HIDE_FORECAST_MODEL_SUCCESS = typeof HIDE_FORECAST_MODEL_SUCCESS
export type HIDE_FORECAST_MODEL_FAILURE = typeof HIDE_FORECAST_MODEL_FAILURE
export type HIDE_FORECAST_MODEL_ACTION_TYPE =
  | HIDE_FORECAST_MODEL_REQUEST
  | HIDE_FORECAST_MODEL_SUCCESS
  | HIDE_FORECAST_MODEL_FAILURE

export const SAVE_FORECAST_MODEL_NAME_REQUEST = 'SAVE_FORECAST_MODEL_NAME_REQUEST'
export const SAVE_FORECAST_MODEL_NAME_SUCCESS = 'SAVE_FORECAST_MODEL_NAME_SUCCESS'
export const SAVE_FORECAST_MODEL_NAME_FAILURE = 'SAVE_FORECAST_MODEL_NAME_FAILURE'
export type SAVE_FORECAST_MODEL_NAME_REQUEST = typeof SAVE_FORECAST_MODEL_NAME_REQUEST
export type SAVE_FORECAST_MODEL_NAME_SUCCESS = typeof SAVE_FORECAST_MODEL_NAME_SUCCESS
export type SAVE_FORECAST_MODEL_NAME_FAILURE = typeof SAVE_FORECAST_MODEL_NAME_FAILURE
export type SAVE_FORECAST_MODEL_NAME_ACTION_TYPE =
  | SAVE_FORECAST_MODEL_NAME_REQUEST
  | SAVE_FORECAST_MODEL_NAME_SUCCESS
  | SAVE_FORECAST_MODEL_NAME_FAILURE

export const REEVALUATE_FORECAST_MODEL_REQUEST = 'REEVALUATE_FORECAST_MODEL_REQUEST'
export const REEVALUATE_FORECAST_MODEL_SUCCESS = 'REEVALUATE_FORECAST_MODEL_SUCCESS'
export const REEVALUATE_FORECAST_MODEL_FAILURE = 'REEVALUATE_FORECAST_MODEL_FAILURE'
export type REEVALUATE_FORECAST_MODEL_REQUEST = typeof REEVALUATE_FORECAST_MODEL_REQUEST
export type REEVALUATE_FORECAST_MODEL_SUCCESS = typeof REEVALUATE_FORECAST_MODEL_SUCCESS
export type REEVALUATE_FORECAST_MODEL_FAILURE = typeof REEVALUATE_FORECAST_MODEL_FAILURE
export type REEVALUATE_FORECAST_MODEL_ACTION_TYPE =
  | REEVALUATE_FORECAST_MODEL_REQUEST
  | REEVALUATE_FORECAST_MODEL_SUCCESS
  | REEVALUATE_FORECAST_MODEL_FAILURE

export const ACTIVATE_FORECAST_MODEL_REQUEST = 'ACTIVATE_FORECAST_MODEL_REQUEST'
export const ACTIVATE_FORECAST_MODEL_SUCCESS = 'ACTIVATE_FORECAST_MODEL_SUCCESS'
export const ACTIVATE_FORECAST_MODEL_FAILURE = 'ACTIVATE_FORECAST_MODEL_FAILURE'
export type ACTIVATE_FORECAST_MODEL_REQUEST = typeof ACTIVATE_FORECAST_MODEL_REQUEST
export type ACTIVATE_FORECAST_MODEL_SUCCESS = typeof ACTIVATE_FORECAST_MODEL_SUCCESS
export type ACTIVATE_FORECAST_MODEL_FAILURE = typeof ACTIVATE_FORECAST_MODEL_FAILURE
export type ACTIVATE_FORECAST_MODEL_ACTION_TYPE =
  | ACTIVATE_FORECAST_MODEL_REQUEST
  | ACTIVATE_FORECAST_MODEL_SUCCESS
  | ACTIVATE_FORECAST_MODEL_FAILURE

export const GET_TRAINING_JOBS_REQUEST = 'GET_TRAINING_JOBS_REQUEST'
export const GET_TRAINING_JOBS_SUCCESS = 'GET_TRAINING_JOBS_SUCCESS'
export const GET_TRAINING_JOBS_FAILURE = 'GET_TRAINING_JOBS_FAILURE'
export type GET_TRAINING_JOBS_REQUEST = typeof GET_TRAINING_JOBS_REQUEST
export type GET_TRAINING_JOBS_SUCCESS = typeof GET_TRAINING_JOBS_SUCCESS
export type GET_TRAINING_JOBS_FAILURE = typeof GET_TRAINING_JOBS_FAILURE
export type GET_TRAINING_JOBS_ACTION_TYPE =
  | GET_TRAINING_JOBS_REQUEST
  | GET_TRAINING_JOBS_SUCCESS
  | GET_TRAINING_JOBS_FAILURE

export const GET_TRAINING_INFO_REQUEST = 'GET_TRAINING_INFO_REQUEST'
export const GET_TRAINING_INFO_SUCCESS = 'GET_TRAINING_INFO_SUCCESS'
export const GET_TRAINING_INFO_FAILURE = 'GET_TRAINING_INFO_FAILURE'
export type GET_TRAINING_INFO_REQUEST = typeof GET_TRAINING_INFO_REQUEST
export type GET_TRAINING_INFO_SUCCESS = typeof GET_TRAINING_INFO_SUCCESS
export type GET_TRAINING_INFO_FAILURE = typeof GET_TRAINING_INFO_FAILURE
export type GET_TRAINING_INFO_ACTION_TYPE =
  | GET_TRAINING_INFO_REQUEST
  | GET_TRAINING_INFO_SUCCESS
  | GET_TRAINING_INFO_FAILURE

export const GET_QUALITY_EVALUATION_REQUEST = 'GET_QUALITY_EVALUATION_REQUEST'
export const GET_QUALITY_EVALUATION_SUCCESS = 'GET_QUALITY_EVALUATION_SUCCESS'
export const GET_QUALITY_EVALUATION_FAILURE = 'GET_QUALITY_EVALUATION_FAILURE'
export type GET_QUALITY_EVALUATION_REQUEST = typeof GET_QUALITY_EVALUATION_REQUEST
export type GET_QUALITY_EVALUATION_SUCCESS = typeof GET_QUALITY_EVALUATION_SUCCESS
export type GET_QUALITY_EVALUATION_FAILURE = typeof GET_QUALITY_EVALUATION_FAILURE
export type GET_QUALITY_EVALUATION_ACTION_TYPE =
  | GET_QUALITY_EVALUATION_REQUEST
  | GET_QUALITY_EVALUATION_SUCCESS
  | GET_QUALITY_EVALUATION_FAILURE

export const GET_QUALITY_OVERVIEW_REQUEST = 'GET_QUALITY_OVERVIEW_REQUEST'
export const GET_QUALITY_OVERVIEW_SUCCESS = 'GET_QUALITY_OVERVIEW_SUCCESS'
export const GET_QUALITY_OVERVIEW_FAILURE = 'GET_QUALITY_OVERVIEW_FAILURE'
export type GET_QUALITY_OVERVIEW_REQUEST = typeof GET_QUALITY_OVERVIEW_REQUEST
export type GET_QUALITY_OVERVIEW_SUCCESS = typeof GET_QUALITY_OVERVIEW_SUCCESS
export type GET_QUALITY_OVERVIEW_FAILURE = typeof GET_QUALITY_OVERVIEW_FAILURE
export type GET_QUALITY_OVERVIEW_ACTION_TYPE =
  | GET_QUALITY_OVERVIEW_REQUEST
  | GET_QUALITY_OVERVIEW_SUCCESS
  | GET_QUALITY_OVERVIEW_FAILURE
