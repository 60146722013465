import React from 'react'
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import queryString from 'query-string'

import { SET_URLPARAMS } from './actions/urlParamsActions'
import ResultTable from './component/ResultTable'
import ShapeOptions from './component/ShapeOptions'
import ShapeMap from './component/ShapeMap'
import { useRouter } from 'utils/route'

import './Gips.css'

function Gips() {
  const dispatch = useDispatch()
  const { location } = useRouter()

  if (location) {
    let params = queryString.parse(location.search)
    if (Object.keys(params).length !== 0) dispatch({ type: SET_URLPARAMS, params })
  }

  return (
    <div className="container">
      <ResultTable />
      <ShapeMap />
      <ShapeOptions />
      <ToastContainer autoClose={3000} hideProgressBar={true} position={'bottom-center'} />
    </div>
  )
}

export default Gips
