import React from 'react'
import TimeSelectionForCleansing from 'modules/asset/assetCrud/meterDataCleansing/TimeSelectionForCleansing'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import styled from 'styled-components'
import { FormApi } from 'final-form'
import { Asset } from 'modules/asset/store/asset.types'
import WeatherGuidedCleansingChart from 'modules/asset/assetCrud/meterDataCleansing/weatherGuided/WeatherGuidedCleansingChart'
import WeatherGuidedConfigurations from 'modules/asset/assetCrud/meterDataCleansing/weatherGuided/WeatherGuidedConfigurations'
import Flex from 'ui/styles/Flex'
import { MeterDataCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { Timezone } from 'fixtures/timezones'
import { isSolarAsset } from 'utils/asset'

const Container = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: wrap;
`

interface MeterDataCleansingFormBodyProps {
  asset: Asset
  defaultFilterSettings?: MeterDataCleansingFilterSettings
  mdcRequestFailed: string
  form: FormApi
  totalTimePeriod: MDCTimePeriod
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  onChangeTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  refreshData: boolean
  onRefresh: () => void
  cleansingData: any
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onCancelEditExcludedTimePeriod: () => void
  onSaveExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteAllExcludedTimePeriods: () => void
  loadingCleansingData: boolean
  userTimezone: Timezone
  formInvalid: boolean
  linkToDefault: boolean
  onLinkToDefaultChange: () => void
}

const MeterDataCleansingFormBody: React.FC<MeterDataCleansingFormBodyProps> = ({
  asset,
  defaultFilterSettings,
  mdcRequestFailed,
  form,
  totalTimePeriod,
  timePeriodToExcludeFromChart,
  onChangeTimePeriod,
  refreshData,
  onRefresh,
  cleansingData,
  timePeriodToEdit,
  onEditExcludedTimePeriod,
  onCancelEditExcludedTimePeriod,
  onSaveExcludedTimePeriod,
  onDeleteExcludedTimePeriod,
  onDeleteAllExcludedTimePeriods,
  loadingCleansingData,
  userTimezone,
  formInvalid,
  linkToDefault,
  onLinkToDefaultChange,
}) => {
  return (
    <Container>
      <TimeSelectionForCleansing
        asset={asset}
        form={form}
        timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
        onChangeTimePeriod={onChangeTimePeriod}
        totalTimePeriod={totalTimePeriod}
        timePeriodToEdit={timePeriodToEdit}
        onEditExcludedTimePeriod={onEditExcludedTimePeriod}
        onCancelEditExcludedTimePeriod={onCancelEditExcludedTimePeriod}
        onSaveExcludedTimePeriod={onSaveExcludedTimePeriod}
        onDeleteExcludedTimePeriod={onDeleteExcludedTimePeriod}
        onDeleteAllExcludedTimePeriods={onDeleteAllExcludedTimePeriods}
        cleansingSummary={cleansingData?.summary}
        userTimezone={userTimezone}
        linkToDefault={linkToDefault}
      />

      <WeatherGuidedCleansingChart
        mdcRequestFailed={mdcRequestFailed}
        refreshData={refreshData}
        scatterChartValues={cleansingData?.data}
        onRefresh={onRefresh}
        loadingCleansingData={loadingCleansingData}
        formInvalid={formInvalid}
        asset={asset}
      />

      <WeatherGuidedConfigurations
        asset={asset}
        defaultFilterSettings={defaultFilterSettings}
        totalTimePeriod={totalTimePeriod}
        timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
        form={form}
        refreshData={refreshData}
        cleansingSummary={cleansingData?.summary}
        userTimezone={userTimezone}
        linkToDefault={linkToDefault}
        onLinkToDefaultChange={onLinkToDefaultChange}
      />
    </Container>
  )
}

export default React.memo(MeterDataCleansingFormBody)
