import {
  DataStream,
  E3Ensemble,
  E3ForecastConfigs,
  E3ForecastModel,
  TimeSeriesResult,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'
import { aggregateTimeSeries } from 'utils/timeseries'
import { c, t } from 'ttag'

export const createDefaultE3Ensemble = (): E3Ensemble => {
  return {
    CMC: 50,
    ECMWF: 50,
    // ECMWF_VAR_EPS_MONTHLY_FORECAST: 50,
    GFS: 50,
    ICON_EU: 50,
    // NAM: 50,
    UKMET_UM: 50,
  }
}

export const getDefaultE3ModelLevelForecastConfigs = (): E3ForecastConfigs => {
  return {
    CMC: {
      name: 'CMC',
      category: 'forecast',
      type: 'SINGLE_MODEL',
      model: 'CMC',
      source: 'enercast',
    },
    ECMWF: {
      name: 'ECMWF',
      category: 'forecast',
      type: 'SINGLE_MODEL',
      model: 'ECMWF',
      source: 'enercast',
    },
    // ECMWF_VAR_EPS_MONTHLY_FORECAST: {
    //   name: 'ECMWF_VAR_EPS_MONTHLY_FORECAST',
    //   category: 'forecast',
    //   type: 'SINGLE_MODEL',
    //   model: 'ECMWF_VAR_EPS_MONTHLY_FORECAST',
    //   source: 'enercast',
    // },
    GFS: {
      name: 'GFS',
      category: 'forecast',
      type: 'SINGLE_MODEL',
      model: 'GFS',
      source: 'enercast',
    },
    ICON_EU: {
      name: 'ICON_EU',
      category: 'forecast',
      type: 'SINGLE_MODEL',
      model: 'ICON_EU',
      source: 'enercast',
    },
    // NAM: {
    //   name: 'NAM',
    //   category: 'forecast',
    //   type: 'SINGLE_MODEL',
    //   model: 'NAM',
    //   source: 'enercast',
    // },
    UKMET_UM: {
      name: 'UKMET_UM',
      category: 'forecast',
      type: 'SINGLE_MODEL',
      model: 'UKMET_UM',
      source: 'enercast',
    },
  }
}

const labels = {
  E3_META_FORECAST: 'Meta Forecast',
  META_FORECAST_META_ENSEMBLE: 'Meta Forecast',
  CMC: 'CMC GDPS',
  ECMWF: 'ECMWF HRES',
  ECMWF_VAR_EPS_MONTHLY_FORECAST: 'ECMWF EPS',
  GFS: 'NOAA GFS',
  ICON_EU: 'DWD ICON EU',
  NAM: 'NOAA NAM',
  UKMET_UM: 'UKMO UM',
}

export const getE3ModelLabel = (model: E3ForecastModel | string) => {
  if (model == 'META_FORECAST_META_ENSEMBLE') {
    return c('Workbench:TimeSeries').t`Interactive meta forecast`
  }
  return labels[model]
}

export const calculateMetaForecastTimeseries = (
  e3TimeSeriesWeatherTracks: TimeSeriesResult[],
  ensemble: E3Ensemble,
) => {
  const selectedE3Models = Object.keys(e3TimeSeriesWeatherTracks)

  const combinedTs = aggregateTimeSeries({
    timeSeriesResults: e3TimeSeriesWeatherTracks,
    chartAggregationMode: ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_WEIGHTED_AVG,
    selectedKeys: selectedE3Models,
    aggregationAverageWeights: ensemble,
  })
  return combinedTs
}

export enum E3WidgetActions {
  SAVE_AS_NEW_MODEL = 'SAVE_AS_NEW_MODEL',
  SAVE_AND_ACTIVATE_NEW_MODEL = 'SAVE_AND_ACTIVATE_NEW_MODEL',
}

export const getE3OptimizationThirdPartyWidgetSaveMenuItems = () => {
  return [
    {
      key: E3WidgetActions.SAVE_AS_NEW_MODEL,
      label: t`Save as new model`,
    },
    {
      key: E3WidgetActions.SAVE_AND_ACTIVATE_NEW_MODEL,
      label: t`Save as new active model`,
    },
  ]
}

export const defaultEnsembleWeightsByDataStreams = (dataStream: DataStream[]) => {
  const modelWeights = dataStream.reduce((ensemble, dataStream) => {
    return {
      ...ensemble,
      [`${dataStream.id}`]: 50,
    }
  }, {})

  return modelWeights
}

interface CreateSlidersInASequenceProps {
  dataStreamsOfType: DataStream[]
  qualityTableDataStreams: DataStream[]
  isBackCastEnabled: boolean
}

interface SlidersToRenderProps {
  showSlider: boolean
  id: string
  name?: string
}

export const createSlidersInASequence = ({
  dataStreamsOfType,
  qualityTableDataStreams,
  isBackCastEnabled,
}: CreateSlidersInASequenceProps) => {
  const dataStreamSlidersToDisplayIds = dataStreamsOfType.map((d) => d.id)
  const sliderDataStreamIds: SlidersToRenderProps[] = []
  qualityTableDataStreams.forEach((dataStream) => {
    // add dataStream ID if it should display
    if (dataStream.id && dataStreamSlidersToDisplayIds.includes(dataStream.id)) {
      sliderDataStreamIds.push({ showSlider: true, id: dataStream.id, name: dataStream?.label || '' })
    } else {
      // Need to show empty space
      sliderDataStreamIds.push({ showSlider: false, id: dataStream.id })
    }

    if (isBackCastEnabled && dataStream.type === TimeSeriesType.SITE_FORECAST) {
      // Need to show empty space
      sliderDataStreamIds.push({ showSlider: false, id: `${dataStream.id}-BACK_CAST` })
    }
  })
  return sliderDataStreamIds
}
