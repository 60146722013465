import axios from 'axios'
import { Asset, FORECAST_MODEL_FOR_BACK_CAST_ID, GenericAssetType } from 'modules/asset/store/asset.types'
import { AssetAndTimeSeries, PenaltyConfigurationMultiple } from 'modules/quality/penalty.types'
import { useMemo } from 'react'
import { QueryObserverResult, useQueries, useQuery } from 'react-query'
import { jt } from 'ttag'
import { useDebounce } from 'use-debounce'
import { getGenericAssetType, useUniqueAllAssets } from 'utils/asset'
import { DateRange } from 'utils/date'
import { apiRequest } from 'utils/request'
import {
  PenaltyBlockResult,
  PenaltyCalculationResult,
  PenaltyCalculationSummary,
  PenaltyNotification,
} from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import { MailAddresses } from 'ui/elements/MailLink'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'
import { getPenaltyErrorMsgByStatus } from 'utils/penaltyCalculations'

// constants

const PENALTY_CALCULATIONS_BASE_URL = '/api/qecs/penalty/v1'

// Query keys for caching data
export const QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE = 'workspace:penalty:byConfigMultiple'
export const QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE = 'workspace:penalty:backCast:byConfigMultiple'
export const QUERY_KEY_PENALTY_NOTIFICATIONS = 'workspace:penalty:notifications'

// Async API requests to fetch and update data

interface CalculatePenaltyByConfigCombinedProps {
  penaltyRegulationId?: string
  asset: Asset
  forecastModelId?: string
  assetAndTimeSeriesList: AssetAndTimeSeries
  dateRange: DateRange
}
const calculatePenaltyByConfigCombined = async ({
  asset,
  assetAndTimeSeriesList,
  dateRange,
}: CalculatePenaltyByConfigCombinedProps) => {
  return apiRequest(() => {
    const assetType = asset?.type ? getGenericAssetType(asset.type) : null
    const generatorType = assetType === GenericAssetType.GENERATOR ? asset?.type : null
    const shapeUUID = asset?.shapeUUID

    const start = dateRange[0]
    const end = dateRange[1]

    return axios.post<PenaltyCalculationResult>(`${PENALTY_CALCULATIONS_BASE_URL}/calculate/by-config/combined`, {
      assetAndTimeSeries: assetAndTimeSeriesList,
      start,
      end,
      generatorType,
      shapeUUID,
    })
  })
}

// this takes an array of asset/site forecast combinations and sums up all values to return a single penalty result
export const useCalculatePenalties = ({
  dateRange,
  assets,
  selectedAssetsAndModelIds,
  penaltyRegulationId,
  siteForecastId,
  actualDataItem,
  availableCapacityItem,
}: PenaltyConfigurationMultiple) => {
  const allAssets = useUniqueAllAssets()
  const chartSettings = useLineChartSettings()
  const user = useSelector(getUserResultSelector)
  const hasAccessToBackCast = hasPermissionForSiteAssessmentBackcast(user)
  // Fetch back cast
  let queryOptionsForBackCast: {
    queryKey: (string | DateRange | undefined)[]
    queryFn: () => Promise<PenaltyCalculationResult>
    enabled: boolean
  }[] = []

  if (hasAccessToBackCast && chartSettings.data.showBackCast && allAssets.length && selectedAssetsAndModelIds?.length) {
    queryOptionsForBackCast = selectedAssetsAndModelIds.map((id) => {
      const modelId = id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID) ? getBackCastModelDataFromId(id).modelId : ''
      const assetId = modelId ? getBackCastModelDataFromId(id).assetId : id
      const asset = allAssets.find((a) => a.id === assetId)

      const assetAndTimeSeriesList: AssetAndTimeSeries = {
        assetId: assetId,
        forecastModelId: modelId || null,
        assetType: getGenericAssetType(asset?.type),
        siteForecastId: siteForecastId as string,
        actualDataClassifier: actualDataItem?.classifier || null,
        availableCapacityClassifier: availableCapacityItem?.classifier,
        isBackcastRequest: true,
      }

      const queryHash = [
        assetId,
        modelId,
        siteForecastId,
        actualDataItem?.type,
        actualDataItem?.subType,
        actualDataItem?.classifier,
        availableCapacityItem?.type,
        availableCapacityItem?.subType,
        availableCapacityItem?.classifier,
        dateRange,
      ]

      return {
        queryKey: [QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE, ...queryHash],
        queryFn: () => {
          return calculatePenaltyByConfigCombined({
            penaltyRegulationId,
            asset,
            assetAndTimeSeriesList,
            dateRange,
          })
        },
        enabled: Boolean(siteForecastId && asset.id),
      }
    })
  }

  const queryOptions = assets.map((asset) => {
    const assetAndTimeSeriesList: AssetAndTimeSeries[] = {
      assetId: asset.id,
      assetType: getGenericAssetType(asset.type),
      siteForecastId: siteForecastId as string,
      actualDataClassifier: actualDataItem?.classifier,
      availableCapacityClassifier: availableCapacityItem?.classifier,
    }

    const queryHash = [
      asset.id,
      siteForecastId,
      actualDataItem?.type,
      actualDataItem?.subType,
      actualDataItem?.classifier,
      availableCapacityItem?.type,
      availableCapacityItem?.subType,
      availableCapacityItem?.classifier,
      dateRange,
    ]

    return {
      queryKey: [QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE, ...queryHash],
      queryFn: () => {
        return calculatePenaltyByConfigCombined({
          penaltyRegulationId,
          asset,
          assetAndTimeSeriesList,
          dateRange,
        })
      },
      enabled: Boolean(siteForecastId && asset.id),
    }
  })

  // TODO remove hard type conversion as soon as react-query supports types for each query
  return useQueries([...queryOptions, ...queryOptionsForBackCast]) as QueryObserverResult<PenaltyCalculationResult>[]
}

// custom hooks
const joinArrayMessage = (message: any) => {
  if (Array.isArray(message)) {
    return message.join('')
  }
  return message
}

export const usePenaltyCalculationError = (penaltySummary: PenaltyCalculationSummary): string | null => {
  let message: string | string[] | null = ''
  if (penaltySummary?.status) {
    message = getPenaltyErrorMsgByStatus(penaltySummary.status)
    return joinArrayMessage(message)
  } else {
    message = jt`Penalty can't be calculated. Please contact ${MailAddresses.support}.`
  }
  return message
}

export const useMaxErrorPenaltyBlock = (penaltyResults: PenaltyCalculationResult[] | undefined) => {
  const [debouncedPenaltyResults] = useDebounce(penaltyResults, 100)

  const mergedBlocks = useMemo(() => {
    return (debouncedPenaltyResults || []).flatMap((penaltyResult) => penaltyResult.penaltyBlockResults)
  }, [debouncedPenaltyResults])

  const maxErrorBlock = useMemo(() => {
    return mergedBlocks.reduce<PenaltyBlockResult | null>((resultBlock, penaltyBlock) => {
      if (!penaltyBlock) return resultBlock
      if (!resultBlock) return penaltyBlock

      return penaltyBlock.normalizedError > resultBlock.normalizedError ? penaltyBlock : resultBlock
    }, null)
  }, [mergedBlocks])

  return maxErrorBlock
}

const getPenaltyNotifications = async () => {
  return await apiRequest<PenaltyNotification[]>(() => axios.get(`${PENALTY_CALCULATIONS_BASE_URL}/notifications/all`))
}

export const usePenaltyNotifications = () => {
  return useQuery<PenaltyNotification[]>(QUERY_KEY_PENALTY_NOTIFICATIONS, getPenaltyNotifications)
}
