import { useAllAssets } from 'modules/asset/api/assets.api'
import AssetIcon from 'modules/asset/AssetIcon'
import { Asset } from 'modules/asset/store/asset.types'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import TimezoneInfo from 'ui/datetime/TimezoneInfo'
import TabBar from 'ui/elements/TabBar'
import { AssetDetailsTitleChip, FormHeaderIconWrapper, TitleContainer } from 'ui/form/DetailsFormHeader'
import Flex from 'ui/styles/Flex'
import {
  getAssetQueryObj,
  getAssetQueryStrings,
  QUERY_ACTIVE_TAB,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import { getNextPrevNavigationIndex } from 'utils/route'
import { TabItem } from 'utils/tabs'

import { FormHeaderContainer } from 'ui/form/form.style'
import ChartSettingsMenu from 'ui/elements/ChartSettingsButton'
import { Box } from '@material-ui/core'
import NextPreviousButtons from 'ui/elements/NextPreviousButtons'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import { t } from 'ttag'
import { orange, red } from '@material-ui/core/colors'
import TextField from '@material-ui/core/TextField'
import { useAssetTabs } from 'utils/hooks/asset/useAssetTabs'

const TabBarWithActions = styled.div`
  display: flex;
  align-items: center;
`

const TimezoneInfoContainer = styled.div`
  margin-left: auto;
`

const DisabledTitleField = styled(TextField)`
  & .MuiInputBase-root.Mui-disabled {
    color: black;
  }
`

const StyledTitleContainer = styled(TitleContainer)`
  &.MuiTypography-h2 {
    height: 1.9em;
    margin-top: 0.1em;
  }
`

interface AssetCrudCommonHeaderProps {
  activeTab: string
  asset: Asset
  actions?: React.ReactNode
  onToggleShowMDCChartTooltip?: () => void
  showMDCTooltip?: boolean
}

const AssetCrudCommonHeader: React.FC<AssetCrudCommonHeaderProps> = ({
  activeTab,
  asset,
  actions,
  onToggleShowMDCChartTooltip,
  showMDCTooltip,
}) => {
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const allAssets = useAllAssets()
  const { queryParams } = useQueryParams()

  const assetTabs = useAssetTabs(asset)
  const isNew = asset && asset?.id === 'new'

  const filteredAssetIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_ASSETS)
  const filteredAssets = useMemo(() => {
    return (allAssets.data || []).filter((asset) => filteredAssetIds.includes(asset.id))
  }, [allAssets.data, filteredAssetIds])

  const handleCloseForm = useCallback(() => {
    onDeleteQueryStrings(getAssetQueryStrings())
  }, [onDeleteQueryStrings])

  const handleNextPrevAsset = useCallback(
    (nextAsset: boolean) => {
      const displayedAssetId = asset?.id || ''
      const newIndex = getNextPrevNavigationIndex(filteredAssets, displayedAssetId, nextAsset)
      if (newIndex !== undefined) {
        onUpdateQueryString(getAssetQueryObj(filteredAssets[newIndex], queryParams))
      }
    },
    [filteredAssets, onUpdateQueryString, queryParams, asset],
  )

  const handleTabChange = useCallback(
    (tab: TabItem) => {
      onUpdateQueryString({ [QUERY_ACTIVE_TAB]: tab.name })
    },
    [onUpdateQueryString],
  )

  return (
    <FormHeaderContainer nopaddingbottom={1}>
      <Flex direction="row" alignItems="center" flexGrow={1}>
        {asset.id && (
          <FormHeaderIconWrapper>
            <AssetIcon asset={asset} size="40" />
          </FormHeaderIconWrapper>
        )}
        <Flex flexGrow={1}>
          <Flex flexGrow={1} justifyContent="space-between">
            <StyledTitleContainer
              use_full_width_for_title={0}
              variant="h2"
              highlight_required={1}
              className={'appTour-createAsset-save'}
            >
              <DisabledTitleField defaultValue={asset?.name} fullWidth disabled={true} />
            </StyledTitleContainer>

            {(asset.changed || asset.deleted) && (
              <Box ml={1}>
                <AssetDetailsTitleChip
                  size="small"
                  label={asset.deleted ? t`Deleted` : t`Changed`}
                  color={asset.deleted ? red[700] : orange[800]}
                />
              </Box>
            )}
          </Flex>
          <Flex alignItems="center">
            <NextPreviousButtons
              showNextPrevOptions={!isNew}
              onNextPrevItem={handleNextPrevAsset}
              totalItems={filteredAssets.length}
            />
            {actions && <>{actions}</>}
            <SliderCloseButton className="appTour-createAsset-close" onCloseSlider={handleCloseForm} />
          </Flex>
        </Flex>
      </Flex>
      <TabBarWithActions>
        <TabBar
          changed={asset.changed}
          isNew={isNew}
          activeTab={activeTab}
          tabs={assetTabs}
          onChange={handleTabChange}
        />
        <ChartSettingsMenu onTooltipChange={onToggleShowMDCChartTooltip} showTooltip={showMDCTooltip} />
        <TimezoneInfoContainer>
          <TimezoneInfo showTime={false} />
        </TimezoneInfoContainer>
      </TabBarWithActions>
    </FormHeaderContainer>
  )
}

export default React.memo(AssetCrudCommonHeader)
