import { takeEvery, takeLatest } from '@redux-saga/core/effects'
import { fileManagerReducer } from 'modules/data/fileManager/redux_store/fileManager.store'
import {
  GET_AREACONFIGS_REQUEST,
  getAreaConfigsReducer,
  getAreaConfigsSaga,
} from 'modules/data/store/getAreaConfigs.state'
import { GET_METADATA_REQUEST, getMetaDataReducer, getMetaDataSaga } from 'modules/data/store/getMetaData.state'
import {
  GET_WEATHER_COORDINATES_REQUEST,
  getWeatherCoordinatesReducer,
  getWeatherCoordinatesSaga,
} from 'modules/data/store/getWeatherCoordinates.state'
import { combineReducers } from 'redux'

export const dataReducer = combineReducers({
  // filter: filterReducer,
  getAreaConfigs: getAreaConfigsReducer,
  getMetaData: getMetaDataReducer,
  fileManager: fileManagerReducer,
  weatherCoordinates: getWeatherCoordinatesReducer,
})

export function* dataSaga() {
  yield takeEvery(GET_AREACONFIGS_REQUEST, getAreaConfigsSaga)
  yield takeEvery(GET_METADATA_REQUEST, getMetaDataSaga)
  yield takeLatest(GET_WEATHER_COORDINATES_REQUEST, getWeatherCoordinatesSaga)
}
