import React, { useEffect } from 'react'
import OldUiIframe from 'ui/elements/OldUiIframe'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useQueryClient } from 'react-query'
import { QUERY_KEY_MASTER_DATA_CUSTOM_FORMATS } from 'modules/masterData/api/masterDataCustomFormats.api'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'

interface MasterDataIframeProps {
  url: string
}

const MasterDataIframe: React.FC<MasterDataIframeProps> = ({ url }) => {
  const user = useSelector(getUserResultSelector)

  // Fetch delivery targets when leaving iframe as user might updated
  const queryClient = useQueryClient()
  useEffect(() => {
    return () => {
      if (user) {
        queryClient.invalidateQueries(QUERY_KEY_MASTER_DATA_CUSTOM_FORMATS)
        setTimeout(() => {
          queryClient.invalidateQueries(QUERY_KEY_ASSETS)
        }, 2000)
      }
    }
  }, [user])
  return <OldUiIframe url={url} />
}

export default MasterDataIframe
