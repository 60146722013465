import React, { useCallback } from 'react'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { theme } from 'themes/theme-light'

export interface DetailsLinkProps {
  lightMode: boolean
  title: string
  onToggle: () => void
}
const DetailsLink: React.FC<DetailsLinkProps> = ({ lightMode, title, onToggle, ...rest }) => {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      onToggle()
    },
    [onToggle],
  )

  return (
    <IconButton size="small" title={title} onClick={handleClick} {...rest}>
      <FontAwesomeIcon icon="eye" color={lightMode ? 'white' : theme.palette.primary.main} />
    </IconButton>
  )
}

export default React.memo(DetailsLink)
