import { c, t } from 'ttag'
import {
  DELIVERY_TARGET_DEFAULT,
  DELIVERY_TARGET_TYPE,
  DELIVERY_TARGET_TYPE_CUSTOMER_FTP,
  DELIVERY_TARGET_TYPE_ENERCAST_FTP,
  DELIVERY_TARGET_TYPE_MAIL,
  DeliveryTarget,
  FtpConfiguration,
  LocalFtpIfFileExist,
  LocalFtpProtocols,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import EnercastIconSmall from 'ui/icons/EnercastIconSmall'
import { theme } from 'themes/theme-light'
import { Box } from '@material-ui/core'
import { deleteUiKeysFromObject } from 'utils/array'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'

export const defaultDeliveryTargetId = 'default-delivery-target'
export const enercastFtpServerId = 'enercast-ftp'

export const getDefaultDeliveryTarget = (): DeliveryTarget => {
  return {
    id: defaultDeliveryTargetId,
    name: t`Display in portal`,
    type: DELIVERY_TARGET_DEFAULT,
    customer: '',
    version: '2015-09-18T07:05:35.755Z',
    ui: {
      fixed: true,
    },
  }
}

export const getDeliveryTargetsColumns: () => Column[] = () => [
  {
    name: 'type',
    label: c('Delivery:Target').t`Type`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.DELIVERY_TARGET_TYPE,
    searchable: false,
    sortable: true,
    width: '4em',
    fieldName: 'type',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'name',
    label: c('Delivery:Target').t`Name`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '15em',
    fieldName: 'name',
    isEditable: true,
    fixed: true,
  },

  {
    name: 'usedInLength',
    label: c('Delivery:Target').t`Used in`,
    cellRenderType: CellRenderType.CUSTOM_NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '8em',
    fieldName: 'usedInLength',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'version',
    label: c('Delivery:Target').t`Last modified`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '14em',
    fieldName: 'version',
    isEditable: false,
    minDate: new Date(1995, 1, 1),
    fixed: true,
  },
]

export const getDeliveryFormatsColumns: () => Column[] = () => [
  {
    name: 'name',
    label: c('Delivery:Format').t`Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '20em',
    fieldName: 'name',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'usedInLength',
    label: c('Delivery:Format').t`Used in`,
    cellRenderType: CellRenderType.CUSTOM_NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '8em',
    fieldName: 'usedInLength',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'version',
    label: c('Delivery:Format').t`Last modified`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '14em',
    fieldName: 'version',
    isEditable: false,
    minDate: new Date(1995, 1, 1),
    fixed: true,
  },
]

export const getDeliveryTargetTypeLabel = (type: DELIVERY_TARGET_TYPE) => {
  switch (type) {
    case DELIVERY_TARGET_TYPE_MAIL:
      return c('Delivery:Target').t`Email delivery`
    case DELIVERY_TARGET_TYPE_CUSTOMER_FTP:
      return c('Delivery:Target').t`Customer FTP server`
    case DELIVERY_TARGET_TYPE_ENERCAST_FTP:
      return c('Delivery:Target').t`enercast FTP/SFTP server`
  }
}

export const deliveryTargetTypesWithIcons = () => {
  return [
    {
      value: DELIVERY_TARGET_TYPE_MAIL,
      label: getDeliveryTargetTypeLabel(DELIVERY_TARGET_TYPE_MAIL),
      image: <FontAwesomeIcon color={theme.palette.primary.main} size="sm" icon="envelope" />,
    },
    {
      value: DELIVERY_TARGET_TYPE_CUSTOMER_FTP,
      label: getDeliveryTargetTypeLabel(DELIVERY_TARGET_TYPE_CUSTOMER_FTP),
      image: <FontAwesomeIcon color={theme.palette.primary.main} size="sm" icon="server" />,
    },
    {
      value: DELIVERY_TARGET_TYPE_ENERCAST_FTP,
      label: getDeliveryTargetTypeLabel(DELIVERY_TARGET_TYPE_ENERCAST_FTP),
      image: (
        <Box mt={0.5}>
          <EnercastIconSmall size="15" />
        </Box>
      ),
    },
  ]
}

export const getLocalFtpProtocolTypes = () => {
  return [
    {
      value: LocalFtpProtocols.FTP,
      label: 'FTP (ftp://)',
    },
    {
      value: LocalFtpProtocols.SFTP,
      label: 'SFTP (sftp://)',
    },
    {
      value: LocalFtpProtocols.FTPS,
      label: 'FTPS (ftps://)',
    },
  ]
}

export const getEnercastFtpTypes = () => {
  return [
    {
      value: LocalFtpProtocols.FTP,
      label: 'FTP (ftp://)',
    },
    {
      value: LocalFtpProtocols.SFTP,
      label: 'SFTP (sftp://)',
    },
  ]
}

export const getLocalFtpIfFileExistTypes = () => {
  return [
    {
      value: LocalFtpIfFileExist.OVERRIDE,
      label: c('Delivery:Target').t`Overwrite`,
    },
    {
      value: LocalFtpIfFileExist.IGNORE,
      label: c('Delivery:Target').t`Skip`,
    },
  ]
}

export const prepareDeliveryTargetBeforeSave = (data: DeliveryTarget) => {
  deleteUiKeysFromObject(data)
  if (data.id === 'new') {
    delete data.id
    delete data.version
    delete data.customer
  }

  if (data.ftpConfiguration?.host) {
    data.ftpConfiguration.host = data.ftpConfiguration.host.replace(/\s+/g, '')
  }

  if (data.type !== DELIVERY_TARGET_TYPE_CUSTOMER_FTP && data.type !== DELIVERY_TARGET_TYPE_ENERCAST_FTP) {
    delete data.ftpConfiguration
  }
  if (data.type !== DELIVERY_TARGET_TYPE_MAIL) {
    delete data.mailConfiguration
  }
  if (data.type === DELIVERY_TARGET_TYPE_MAIL && data?.mailConfiguration) {
    if (!data.mailConfiguration.bccAddresses) {
      data.mailConfiguration.bccAddresses = []
    }
    if (!data.mailConfiguration.toAddresses) {
      data.mailConfiguration.toAddresses = []
    }
    if (!data.mailConfiguration.ccAddresses) {
      data.mailConfiguration.ccAddresses = []
    }
  }

  return data
}

// Transform delivery target
export const transformDeliveryTargetAfterGet = (target: DeliveryTarget, forecastConfigurations: ForecastConfig[]) => {
  const configsUsingThisTarget =
    target.id == defaultDeliveryTargetId
      ? forecastConfigurations
      : forecastConfigurations.filter((config) => config.deliveryEndpoints.includes(target.id))
  const transformedData = {
    ...target,
    usedIn: configsUsingThisTarget,
    usedInLength: configsUsingThisTarget.length,
  }
  if (target.type === DELIVERY_TARGET_TYPE_CUSTOMER_FTP && transformedData.ftpConfiguration) {
    const ftpParameter = transformedData.ftpConfiguration.ftpParameter
    if (typeof ftpParameter.disconnect === 'string') {
      transformedData['ftpConfiguration']['ftpParameter']['disconnect'] = ftpParameter.disconnect === 'true'
    }
    if (typeof ftpParameter.stepwise === 'string') {
      transformedData['ftpConfiguration']['ftpParameter']['stepwise'] = ftpParameter.stepwise === 'true'
    }
  }
  return transformedData
}

export const getNewLocalFTPDeliveryConfigData = (): FtpConfiguration => {
  return {
    host: '',
    port: 21,
    protocol: 'FTP',
    directory: 'forecasts',
    ftpParameter: {
      connectTimeout: '10000',
      fileExist: 'Override',
      disconnect: false,
      stepwise: false,
    },
    username: '',
    password: '',
  }
}

export const getNewDeliveryTargetData = (): DeliveryTarget => {
  return {
    name: '',
    type: DELIVERY_TARGET_TYPE_CUSTOMER_FTP,
    ftpConfiguration: getNewLocalFTPDeliveryConfigData(),
    customer: '',
    id: 'new',
    version: '',
  }
}
