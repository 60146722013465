import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, IconButton, Menu, PropTypes } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'

import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import styled from 'styled-components'

const ButtonDropdown = styled(Button)`
  &.MuiButton-root {
    min-width: auto;
  }
`

const StyledMenu = styled(Menu)`
  & > .MuiPaper-root {
    max-width: 32em;
  }

  & .MuiFormControlLabel-root {
    margin-right: 0;
  }
`

export interface SelectOption {
  key: string
  label: string
}

export interface ButtonMenuProps {
  text?: string | ReactNode
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
  color?: PropTypes.Color
  icon?: IconProp
  iconSize?: SizeProp
  iconColor?: string
  iconOnly?: boolean
  initiallyOpen?: boolean
  disabled?: boolean
  renderMenu: (handleClose: () => void) => ReactNode
}
const ButtonMenu: React.FC<ButtonMenuProps> = ({
  text,
  variant,
  size,
  color = 'default',
  icon,
  iconSize = '1x',
  iconColor = 'inherit',
  iconOnly = false,
  initiallyOpen = false,
  disabled = false,
  renderMenu,
  ...rest
}) => {
  const buttonRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(initiallyOpen ? buttonRef.current : null)
  const open = Boolean(anchorEl)

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  useEffect(() => {
    if (initiallyOpen && buttonRef.current) {
      setAnchorEl(buttonRef.current)
    }
  }, [buttonRef.current])

  const Btn = (
    <>
      {icon && !text && iconOnly ? (
        <IconButton edge="end" disabled={disabled} onClick={handleClick}>
          <FontAwesomeActionIcon icon={icon} size={iconSize} disabled={disabled} fixedWidth />
        </IconButton>
      ) : (
        <ButtonDropdown
          aria-label="menu"
          aria-controls="menu"
          aria-haspopup="true"
          color={color}
          variant={variant}
          size={size}
          disabled={disabled}
          ref={buttonRef}
          onClick={handleClick}
        >
          {icon && <FontAwesomeIcon icon={icon} size={iconSize} color={iconColor} />}
          {text && <Box ml={1}>{text}</Box>}
          {!iconOnly && (
            <Box ml={1}>
              <FontAwesomeIcon icon="caret-down" />
            </Box>
          )}
        </ButtonDropdown>
      )}
    </>
  )

  return (
    <div {...rest}>
      {Btn}
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <div>{renderMenu(handleClose)}</div>
      </StyledMenu>
    </div>
  )
}

export default React.memo(ButtonMenu)
