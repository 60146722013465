import React from 'react'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { c, t } from 'ttag'
import { Field } from 'react-final-form'
import { Checkbox } from 'final-form-material-ui'
import { Box } from '@material-ui/core'

import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Flex from 'ui/styles/Flex'
import { useCustomMuiStyles } from 'themes/theme-light'
import { DisableContainer } from 'modules/dataStreams/siteForecast/SiteForecastForm'

interface SectionFecsProcessingProps {
  disableFecs: boolean
}

const SectionFecsProcessing: React.FC<SectionFecsProcessingProps> = ({ disableFecs }) => {
  //Style
  const classes = useCustomMuiStyles({ important: true })

  return (
    <>
      <div>
        <FormKeyValueGrid>
          <FormKey className={classes.adminComponent}>{c('Delivery').t`FECS forecast enabled:`}</FormKey>
          <FormValue isAdminComponent>
            <Flex>
              <div>{!disableFecs ? t`Yes` : t`No`}</div>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      </div>
      <DisableContainer disable={disableFecs}>
        <FormKeyValueGrid>
          <FormKey className={classes.adminComponent}>{c('Delivery').t`FECS forecast:`}</FormKey>
          <FormValue isAdminComponent>
            <Flex>
              <Box display="flex" flexDirection="row" ml={0.5} mb={0.5}>
                <FormControlLabel
                  label={c('Delivery').t`Disable realtime`}
                  control={<Field name="ui.disableFecsRealTime" type="checkbox" component={Checkbox} />}
                />
              </Box>
              <Box display="flex" flexDirection="row" ml={1} mb={0.5}>
                <FormControlLabel
                  label={c('Delivery').t`Disable post processing`}
                  control={<Field name="ui.disableFecsPostProcessing" type="checkbox" component={Checkbox} />}
                />
              </Box>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      </DisableContainer>
    </>
  )
}

export default React.memo(SectionFecsProcessing)
