import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import MainPage from 'modules/app/MainPage'
import AuthContainer from 'modules/auth/AuthContainer'
import { authedSelector } from 'modules/auth/redux_store/state/getUser'
import CenteredLoading from 'ui/CenteredLoading'
import CheckConnectivity from 'modules/app/CheckConnectivity'
import CheckUserDefaults from 'modules/user/CheckUserDefaults'
import Flex from 'ui/styles/Flex'
import { loginInitializedSelector } from 'modules/auth/redux_store/state/login'
import { logoutInitiatedSelector } from 'modules/auth/redux_store/state/logout'
import { initiateReloadSelector } from 'modules/app/checkConnectivity.state'
import { SessionActions } from 'modules/app/app.types'
import AppTour from 'modules/app/tour/AppTour'
import { LoadScript } from '@react-google-maps/api'
import axios from 'axios'

const googleMapsLibraries = ['places']

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
`

interface MainWrapperProps {
  initialized: boolean
}
const MainWrapper: React.FC<MainWrapperProps> = ({ initialized }) => {
  const dispatch = useDispatch()
  // we check for loginInitialized so that the app is not flickering just before
  // the page refreshes after successful login (see location.reload in login saga)
  const loginInitialized = useSelector(loginInitializedSelector)
  const logoutInitiated = useSelector(logoutInitiatedSelector)
  const reloadInitiated = useSelector(initiateReloadSelector)
  const authed = useSelector(authedSelector)

  // duplicates initiateReload but may help to avoid flickering
  const [reloading, setReloading] = useState(false)
  const [googleMapKey, setGoogleMapKey] = useState<null | string>(null)

  window.addEventListener('beforeunload', () => {
    dispatch({ type: SessionActions.INITIATE_RELOAD, initiateReload: true })
    setReloading(true)
  })

  useEffect(() => {
    axios.get('/api/v1/app/values/gc_api_key').then((res) => {
      const keyWithAsyncLoading = `${res.data}&loading=async`
      setGoogleMapKey(keyWithAsyncLoading)
    })
  }, [])

  return (
    <Wrapper>
      {initialized && !(reloadInitiated || reloading) ? (
        <>
          <CheckUserDefaults />
          <CheckConnectivity />

          <Flex flexBasis="100%" direction="column" fullHeight>
            {(authed || logoutInitiated) && !loginInitialized && googleMapKey ? (
              <AppTour>
                <LoadScript googleMapsApiKey={googleMapKey} libraries={googleMapsLibraries}>
                  <MainPage />
                </LoadScript>
              </AppTour>
            ) : (
              <AuthContainer />
            )}
          </Flex>
        </>
      ) : (
        <LoadingContainer>
          <CenteredLoading fullHeight size="5em" />
        </LoadingContainer>
      )}
    </Wrapper>
  )
}

export default React.memo(MainWrapper)
