import React, { useMemo } from 'react'
import { CellRenderType, Column } from 'modules/reTable/reTable.types'
import ReTableTextCell from 'modules/reTable/ReTableInputFields/ReTableTextCell'
import { t } from 'ttag'
import ReTableDateCell from 'modules/reTable/ReTableInputFields/ReTableDateCell'
import ReTableSelectCell from 'modules/reTable/ReTableInputFields/ReTableSelectCell'
import ReTableCheckboxCell from 'modules/reTable/ReTableInputFields/ReTableCheckboxCell'
import ReTableNumberCell from 'modules/reTable/ReTableInputFields/ReTableNumberCell'

interface ReTableEditableCellProps<ObjectType> {
  column: Column
  isRowEdit: boolean
  fieldAutoFocus: boolean
  item: ObjectType
  query: string
  tableToolbarHasActions?: boolean
}

const ReTableEditableCell: React.FC = <ObjectType,>({
  column,
  isRowEdit,
  fieldAutoFocus,
  item,
  query,
  tableToolbarHasActions = false,
}: ReTableEditableCellProps<ObjectType>) => {
  const isEdit = useMemo(() => Boolean(column.isEditable && isRowEdit), [column, isRowEdit])
  switch (column.cellRenderType) {
    case CellRenderType.TEXT:
      return (
        <ReTableTextCell
          name={column.fieldName}
          query={query}
          isEdit={isEdit}
          value={item[column.fieldName]}
          width={column.width}
          label={column.label}
          tableToolbarHasActions={tableToolbarHasActions}
          fieldAutoFocus={fieldAutoFocus}
        />
      )
    case CellRenderType.NUMERIC:
      return (
        <ReTableNumberCell
          name={column.fieldName}
          query={query}
          isEdit={isEdit}
          value={item[column.fieldName]}
          width={column.width}
          label={column.label}
        />
      )
    case CellRenderType.DATE:
      return (
        <ReTableDateCell
          name={column?.fieldName}
          label={column?.label || t`Enter date`}
          isEdit={isEdit}
          minDate={column.minDate || new Date()}
          query={query}
          dateInUserTimezone={item[column.fieldName] ? new Date(item[column.fieldName]) : ''}
          fieldAutoFocus={fieldAutoFocus}
          timezone={item['timeZone']}
        />
      )
    case CellRenderType.SELECT:
      return (
        <ReTableSelectCell
          name={column.fieldName}
          value={item[column.fieldName]}
          isEdit={isEdit}
          label={column.label}
          selectItems={column.selectItems}
          query={query}
          width={column.width}
        />
      )
    case CellRenderType.CHECKBOX:
      return (
        <ReTableCheckboxCell
          label={column.label}
          value={item[column.fieldName]}
          name={column.fieldName}
          isEdit={isEdit}
        />
      )
    default:
      return <></>
  }
}

export default React.memo(ReTableEditableCell)
