import React from 'react'
import PenaltyCalculations from 'modules/data/penalties/penaltyCalculations/PenaltyCalculations'
import { Asset } from 'modules/asset/store/asset.types'
import { DateRange } from 'utils/date'
import { Box } from '@material-ui/core'

interface AssetPenalties {
  assets: Asset[]
  selectedAssetsAndModelIds: string[] // Penalties for backcast
  loading: boolean
  range: DateRange
  widgetName: string
  showTooltip: boolean
  backCastEnabled: boolean
}

const AssetPenalties: React.FC<AssetPenalties> = ({
  assets,
  loading,
  range,
  selectedAssetsAndModelIds,
  widgetName,
  showTooltip,
  backCastEnabled,
}) => {
  return (
    <Box mt={2}>
      <PenaltyCalculations
        range={range}
        selectedAssetsAndModelIds={selectedAssetsAndModelIds}
        assets={assets}
        loading={loading}
        widgetName={widgetName}
        showTooltip={showTooltip}
        backCastEnabled={backCastEnabled}
      />
    </Box>
  )
}

export default React.memo(AssetPenalties)
