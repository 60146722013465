import { ForecastModel, GetForecastModelsAction } from 'modules/quality/quality.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  result: Record<string, ForecastModel[]>
  loading: Record<string, boolean>
  error: Record<string, string | null>
}

const initialState: State = {
  result: {},
  loading: {},
  error: {},
}

const result = createReducer<State['result'], GetForecastModelsAction>(
  (state = initialState.result, { type, asset, forecastModels }) => {
    if (type === actionTypes.GET_FORECAST_MODELS_SUCCESS) {
      return {
        ...state,
        [asset.id]: forecastModels,
      }
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetForecastModelsAction>(
  (state = initialState.loading, { type, asset }) => {
    switch (type) {
      case actionTypes.GET_FORECAST_MODELS_SUCCESS:
      case actionTypes.GET_FORECAST_MODELS_FAILURE:
        return {
          ...state,
          [asset.id]: false,
        }
      case actionTypes.GET_FORECAST_MODELS_REQUEST:
        return {
          ...state,
          [asset.id]: true,
        }
    }
    return state
  },
)

const error = createReducer<State['error'], GetForecastModelsAction>(
  (state = initialState.error, { type, asset, error }) => {
    switch (type) {
      case actionTypes.GET_FORECAST_MODELS_FAILURE:
        return {
          ...state,
          [asset.id]: error,
        }
      case actionTypes.GET_FORECAST_MODELS_REQUEST:
      case actionTypes.GET_FORECAST_MODELS_SUCCESS:
        return {
          ...state,
          [asset.id]: null,
        }
    }
    return state
  },
)

export const getForecastModelsReducer = combineReducers({ result, loading, error })

export const getForecastModelsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.quality.getForecastModels.result,
  (result) => result,
)
