import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SplitButton from 'ui/form/SplitButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { E3WidgetActions, getE3OptimizationThirdPartyWidgetSaveMenuItems } from 'utils/e3'
import {
  QUERY_KEY_E3_OPTIMIZATION_SAVEMAINITEMKEY,
  useUserSetting,
  useUserSettingSaveMutation,
} from 'modules/auth/api/userSettings.api'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import {
  QUERY_KEY_FORECAST_MODELS_BY_ASSETS,
  QUERY_KEY_MODEL_WEIGHTS_BY_ASSET,
  saveForecastModelUsingDataStream,
  saveForecastModelUsingE3Weights,
} from 'modules/quality/quality.api'
import { useDispatch, useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useQueryClient } from 'react-query'
import { convertPercentToDecimal } from 'utils/math'
import {
  saveForecastModelErrorSelector,
  saveForecastModelLoadingSelector,
} from 'modules/quality/redux_store/state/saveForecastModel'
import { Asset } from 'modules/asset/store/asset.types'
import { MetaForecastWidgetType } from 'modules/workspace/store/workspace.types'
import { useIsReadOnlyUser } from 'utils/user'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { useUniqueAllAssets } from 'utils/asset'
import { c } from 'ttag'
import PopperTooltip from 'ui/PopperTooltip'

interface SaveModelActionProps {
  modelWeights: Record<string, any>
  selectedAssets: Asset[]
  selectedModelIds: string[]
  activeWidgetType: MetaForecastWidgetType
  hasAccessToThirdPartyForecast: boolean
  invalidNumberOfSelection: boolean
  hasMetaForecastTimeSeries: boolean
}

const SaveModelAction: React.FC<SaveModelActionProps> = ({
  modelWeights,
  selectedAssets,
  selectedModelIds,
  activeWidgetType,
  hasAccessToThirdPartyForecast,
  invalidNumberOfSelection,
  hasMetaForecastTimeSeries,
}) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const allAssets = useUniqueAllAssets()
  const assetsFromModelSelection = useMemo(() => {
    if (selectedModelIds.length) {
      const assetIdFromModelId = getBackCastModelDataFromId(selectedModelIds[0]).assetId
      return allAssets.filter((asset) => asset.id === assetIdFromModelId)
    } else return []
  }, [allAssets, selectedModelIds])

  const user = useSelector(getUserResultSelector)
  const saveModelWithWeatherModelsLoading = useSelector(saveForecastModelLoadingSelector)
  const saveModelWithWeatherModelsError = useSelector(saveForecastModelErrorSelector)
  const isReadOnlyUser = useIsReadOnlyUser()

  const [saveErrorMsg, setSaveErrorMsg] = useState<string | null>(null)
  const [saveModelLoading, setSaveModelLoading] = useState(false)

  const { SAVE_AND_ACTIVATE_NEW_MODEL, SAVE_AS_NEW_MODEL } = E3WidgetActions
  const widgetSaveMenuItems = getE3OptimizationThirdPartyWidgetSaveMenuItems()

  const e3SaveMainItemKeyResult = useUserSetting<string>(QUERY_KEY_E3_OPTIMIZATION_SAVEMAINITEMKEY)
  const e3SaveMainItemKey = e3SaveMainItemKeyResult?.data?.value || SAVE_AS_NEW_MODEL
  const { mutate: mutateE3SavedMainItemKey } = useUserSettingSaveMutation<string>(
    QUERY_KEY_E3_OPTIMIZATION_SAVEMAINITEMKEY,
  )

  const weightsInDecimals = convertPercentToDecimal(modelWeights)

  // Save Model with weather model weights
  const handleSaveForecastModelWithWeatherModels = useCallback(
    (activate: boolean) => {
      // save forecast model
      setSaveModelLoading(true)
      let assetIds = selectedAssets.map((asset) => asset.id) || []
      if (assetsFromModelSelection.length) {
        assetIds = assetsFromModelSelection.filter((asset) => asset.id).map((a) => a.id) || []
      }

      saveForecastModelUsingE3Weights({ assetIds, e3Ensemble: weightsInDecimals, activate })
        .then(() => {
          // we are adding this for loop , because type: actionTypes.GET_FORECAST_MODELS_REQUEST , does not support multiple assets
          for (let i = 0; i < selectedAssets.length; i++) {
            dispatch({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset: selectedAssets[i] })
          }

          assetIds.forEach((assetId) => {
            queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, assetId])
          })
          dispatch({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: selectedAssets })
        })
        .catch((error) => {
          const errorData = JSON.parse(error.message)
          setSaveErrorMsg(errorData?.message || '')
        })
        .finally(() => {
          queryClient.removeQueries(QUERY_KEY_MODEL_WEIGHTS_BY_ASSET)
          setSaveModelLoading(false)
        })
    },
    [selectedAssets, assetsFromModelSelection, weightsInDecimals, user],
  )

  // Save Model with Third party model weights
  const handleSaveForecastModelWithThirdPartyForecasts = (activate: boolean) => {
    // save forecast model
    setSaveModelLoading(true)
    let assetIds = selectedAssets.map((asset) => asset.id) || []
    if (assetsFromModelSelection.length) {
      assetIds = assetsFromModelSelection.filter((asset) => asset.id).map((a) => a.id) || []
    }

    saveForecastModelUsingDataStream({ assetIds, e3Ensemble: weightsInDecimals, activate })
      .then(() => {
        assetIds.forEach((assetId) => {
          queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, assetId])
        })
      })
      .catch((error) => {
        const errorData = JSON.parse(error?.message)
        setSaveErrorMsg(errorData?.message || '')
      })
      .finally(() => {
        queryClient.removeQueries(QUERY_KEY_MODEL_WEIGHTS_BY_ASSET)
        setSaveModelLoading(false)
      })
  }

  // Save menu items
  const handleSaveMenuSelect = (item: SelectAsListItem) => {
    mutateE3SavedMainItemKey({ value: item.key })
    const saveAndActivate = item.key === SAVE_AND_ACTIVATE_NEW_MODEL

    if (activeWidgetType === TimeSeriesSubType.E3_WEATHER_TRACK) {
      handleSaveForecastModelWithWeatherModels(saveAndActivate)
    } else if (hasAccessToThirdPartyForecast) {
      handleSaveForecastModelWithThirdPartyForecasts(saveAndActivate)
    }
  }

  useEffect(() => {
    if (saveModelWithWeatherModelsError) {
      setSaveErrorMsg(saveModelWithWeatherModelsError)
    }
  }, [saveModelWithWeatherModelsError])

  useEffect(() => {
    setSaveErrorMsg(null)
  }, [JSON.stringify(modelWeights)])

  const noWeightsDisplayed = !Object.keys(modelWeights || {}).length

  const invalidWeights = Object.keys(modelWeights || {}).every((key) => !modelWeights[key])
  const disableSaveBtn =
    !hasMetaForecastTimeSeries || isReadOnlyUser || invalidWeights || noWeightsDisplayed || invalidNumberOfSelection

  const modelName = activeWidgetType === TimeSeriesSubType.E3_WEATHER_TRACK ? c('e³').t`e³` : c('e³').t`Meta forecast`

  let toolTipMsg: string | string[] = ''
  if (!noWeightsDisplayed && invalidWeights) {
    toolTipMsg = c('e³').jt`Cannot create a ${modelName} model with invalid model weights.`
  }

  return (
    <PopperTooltip
      popperLabel={
        <SplitButton
          items={widgetSaveMenuItems}
          loading={saveModelLoading || saveModelWithWeatherModelsLoading}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<FontAwesomeIcon icon="save" fixedWidth />}
          onSelectItem={handleSaveMenuSelect}
          error={saveErrorMsg}
          mainItemKey={e3SaveMainItemKey}
          disabled={disableSaveBtn}
        />
      }
      popperContent={toolTipMsg}
    />
  )
}

export default SaveModelAction
