import { DataStreamSelectionItem, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import {
  areDatesEqualIgnoringSecondsMilliseconds,
  convertLocalDateToUTC,
  DATE_FORMAT_INTERNAL_LONG,
  findNearestRightSidedTimestamp,
  formatDate,
  isBetweenDates,
  isSameDate,
} from 'utils/date'
import {
  CreateScheduleInputData,
  HighlightedSchedulePeriod,
  ScheduleEditModeChartOptions,
  ScheduleSeriesUpdateModes,
  ScheduleTemplateUploadStatus,
} from 'modules/workspace/schedule/schedule.types'
import { ChartSeriesOptions } from 'modules/workspace/chart/timeSeriesToChartSeries'
import { getDataStreamUnit } from 'utils/dataStream'
import { Asset } from 'modules/asset/store/asset.types'
import { differenceInMilliseconds, isAfter, isBefore } from 'date-fns'
import { SeriesOptions } from 'highcharts/highstock'
import { isNumeric } from 'utils/dataFormatting'
import { findPointOldValue } from 'utils/chart'
import {
  getOffsetIncreased,
  getPercentageIncreased,
  increaseChartPointsLinearly,
  increaseValueByOffset,
  increaseValueByPercentage,
} from 'utils/math'
import { keyboardKeyCode } from 'utils/workspace'
import { jt, t } from 'ttag'

import { MetaForecastColor } from 'themes/theme-light'
import { removeDuplicates } from 'utils/array'
import { SimpleRange } from 'utils/timeseries'

import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import React from 'react'

export const getScheduleSourceDataStreamsTypes = (): TimeSeriesType[] => {
  return [
    TimeSeriesType.SITE_FORECAST,
    TimeSeriesType.E3_META_FORECAST,
    TimeSeriesType.METER_DATA,
    TimeSeriesType.SCHEDULE,
  ]
}

interface checkWhichFieldsChangedProps {
  currentData: CreateScheduleInputData
  previousData: CreateScheduleInputData
}

export const getCreateScheduleFormChangedFields = ({
  currentData,
  previousData,
}: checkWhichFieldsChangedProps): Record<string, any>[] => {
  const changedFields = []
  let changedData = { key: null, newValue: null, oldValue: null }
  let changed = false
  const keysToCheck: (keyof CreateScheduleInputData)[] = [
    'asset',
    'targetScheduleDataStream',
    'sourceDataStream',
    'start',
    'end',
  ]
  for (let i = 0; i < keysToCheck.length; i++) {
    const key = keysToCheck[i]
    if (key === 'start' || key === 'end') {
      changed = !isSameDate(currentData?.[key]?.date, previousData?.[key]?.date)
    }
    if (key === 'asset' || key === 'targetScheduleDataStream' || key === 'sourceDataStream') {
      changed = currentData?.[key]?.id !== previousData?.[key]?.id
    }
    if (changed) {
      changedData = { key, newValue: currentData?.[key], oldValue: previousData?.[key] }
      changedFields.push(changedData)
    }
  }

  return changedFields
}

// export const ScheduleSeriesColor = '#c2111d'
export const ScheduleSeriesColor = MetaForecastColor

interface getScheduleSeriesChartOptionsProps {
  asset: Asset
  inputSource: DataStreamSelectionItem
  targetDataStream: DataStreamSelectionItem
  maxY: number | undefined
  start: Date
  end: Date
}

export const getScheduleSeriesChartOptions = ({
  asset,
  inputSource,
  targetDataStream,
  maxY,
  start,
  end,
}: getScheduleSeriesChartOptionsProps): ChartSeriesOptions => {
  const unit = getDataStreamUnit({
    type: inputSource?.type,
    subType: inputSource?.subType,
    classifier: inputSource?.classifier,
  })
  const name = `${asset?.name} - Schedule - ${inputSource.label}`

  return {
    data: [],
    dataGrouping: {
      approximation: 'range',
      smoothed: false,
      groupPixelWidth: 1,
    },
    connectNulls: false,
    gapSize: 1,
    zIndex: 11,
    color: ScheduleSeriesColor,
    name,
    lineWidth: 2,
    custom: {
      alignment: 'right',
      label: name,
      unit: unit,
      assetId: asset.id,
      assetIds: [],
      dataStreamId: inputSource?.id,
      datastreamType: inputSource?.type,
      datastreamSubType: inputSource?.subType,
      datastreamClassifier: inputSource?.classifier,
      shouldBeFilled: false,
      scheduleDataStreamId: targetDataStream?.id,
      scheduleStartDate: start,
      scheduleEndDate: end,
    },
    dragDrop: {
      draggableY: true,
      dragMaxY: maxY,
      dragMinY: 0,
      dragHandle: {
        pathFormatter: function () {
          return ''
        },
        color: ScheduleSeriesColor,
      },
    },
    dragPrecisionY: 0,
    dragSensitivity: 0,

    // cursor: 'ns-resize',
    // stickyTracking: true,
    marker: {
      symbol: 'diamond',
      enabled: false,
      radius: 4,
    },
    yAxis: unit,
    type: 'arearange',
    step: 'right',
    showInNavigator: true,
  }
}

export enum TypesOfScheduleTimingLines {
  revisionWindow = 'revisionWindow',
  realTime = 'realTime',
  forecastOffset = 'forecastOffset',
}

export enum ScheduleLocalStorageKeys {
  seriesChanged = 'pc8e2ss3s',
  outPutSeries = '8zpiqpzj0',
  pointTimestampClickedToMoveSeriesByArrowKeys = 'mksao6ixe',
}

export const getScheduleSeriesClickedPointFromStorage = () => {
  return parseInt(localStorage.getItem(ScheduleLocalStorageKeys.pointTimestampClickedToMoveSeriesByArrowKeys))
}

export const removeScheduleSeriesClickedPointFromStorage = () => {
  localStorage.removeItem(ScheduleLocalStorageKeys.pointTimestampClickedToMoveSeriesByArrowKeys)
}

// TODO Need to remove this key from local storage
export const localStorageRefScheduleSeriesLatestPointClicked = 'refPointTimestamp'

interface UpdateScheduleSeriesPointsProps {
  sourcePoint: any
  seriesData: SeriesOptions[]
  valueForCalculation?: number
  arrow?: number
  minValue: number | undefined
  maxValue: number | undefined
  selectedTimePeriod: HighlightedSchedulePeriod
  touchedTimestamps: number[]
  seriesChangeMode: ScheduleSeriesUpdateModes
  scheduleSeriesIntervalInMinutes: number
  seriesFirstPointTimestampFromLeftEdge: number
}
export const updateScheduleSeriesPoints = ({
  sourcePoint,
  seriesData,
  valueForCalculation,
  arrow,
  minValue,
  maxValue,
  selectedTimePeriod,
  touchedTimestamps,
  seriesChangeMode,
  scheduleSeriesIntervalInMinutes,
  seriesFirstPointTimestampFromLeftEdge,
}: UpdateScheduleSeriesPointsProps) => {
  const updatedSeriesData = [...seriesData] || []

  let updatedTouchedTimestamps = [...touchedTimestamps]

  const changeSeriesByPoint = seriesChangeMode === ScheduleSeriesUpdateModes.point
  const referencePointTimeStamp = valueForCalculation
    ? getScheduleSeriesClickedPointFromStorage()
    : sourcePoint?.target.category

  const referencePointIndex = seriesData.findIndex((point) => {
    const pointTimestamp = Array.isArray(point) ? point[0] : point?.xAxis || point?.x
    return pointTimestamp === referencePointTimeStamp
  })

  // Consider referencePoint from series if the series is updated by arrow keys else take source point
  const referencePoint = valueForCalculation ? updatedSeriesData[referencePointIndex] : sourcePoint

  const referencePointValue =
    referencePoint && Array.isArray(referencePoint) ? referencePoint[1] : referencePoint?.low || 0
  // UPDATE THE REFERENCE POINT
  if (valueForCalculation) {
    // Update the point when changed using arrow keys
    let referencePointUpdatedValue = referencePointValue
    if (arrow === keyboardKeyCode.arrowUp) {
      // Increase the value
      referencePointUpdatedValue = referencePointUpdatedValue + valueForCalculation
    } else if (arrow === keyboardKeyCode.arrowDown) {
      // Decrease the value
      referencePointUpdatedValue = referencePointUpdatedValue - valueForCalculation
    }

    if (isNumeric(maxValue) && referencePointUpdatedValue > maxValue) {
      referencePointUpdatedValue = maxValue
    }
    if (isNumeric(minValue) && referencePointUpdatedValue < minValue) {
      referencePointUpdatedValue = minValue
    }
    updatedSeriesData[referencePointIndex] = [
      referencePointTimeStamp,
      referencePointUpdatedValue,
      referencePointUpdatedValue,
    ]
  } else {
    updatedSeriesData[referencePointIndex] = [
      referencePointTimeStamp,
      referencePoint?.newPoint?.low,
      referencePoint?.newPoint.high,
    ]
  }

  // Update other points if not its not a point mode
  if (!changeSeriesByPoint) {
    const seriesLastPoint = seriesData[seriesData.length - 1]
    const seriesLastPointTimestamps = Array.isArray(seriesLastPoint)
      ? seriesLastPoint[0]
      : seriesLastPoint?.xAxis || seriesLastPoint?.x
    let refPointIsNotInsideSelectedPeriod = false

    // Add the first and last timestamps
    updatedTouchedTimestamps = updatedTouchedTimestamps.concat([
      seriesFirstPointTimestampFromLeftEdge,
      seriesLastPointTimestamps,
    ])

    // When period is selected we need to consider them as touched points
    if (scheduleSeriesIntervalInMinutes && selectedTimePeriod.start && selectedTimePeriod.end) {
      updatedTouchedTimestamps = updatedTouchedTimestamps.concat([
        findNearestRightSidedTimestamp(selectedTimePeriod.start, scheduleSeriesIntervalInMinutes).getTime(),
        findNearestRightSidedTimestamp(selectedTimePeriod.end, scheduleSeriesIntervalInMinutes).getTime(),
      ])
    }

    updatedTouchedTimestamps = removeDuplicates(updatedTouchedTimestamps)

    let pointBoundary = {
      start: seriesFirstPointTimestampFromLeftEdge,
      end: seriesLastPointTimestamps,
    }

    if (seriesChangeMode === ScheduleSeriesUpdateModes.linearly) {
      pointBoundary = findNearestTouchedBoundary(referencePointTimeStamp, updatedTouchedTimestamps)
    } else {
      if (selectedTimePeriod?.start && selectedTimePeriod?.end) {
        const isPointInsideSelectedPeriod = isBetweenDates(
          selectedTimePeriod?.start,
          selectedTimePeriod?.end,
          referencePointTimeStamp,
        )
        if (isPointInsideSelectedPeriod) {
          pointBoundary = {
            start: selectedTimePeriod.start,
            end: selectedTimePeriod.end,
          }
        } else {
          refPointIsNotInsideSelectedPeriod = true
          pointBoundary = {
            start: Math.min(...updatedTouchedTimestamps),
            end: Math.max(...updatedTouchedTimestamps),
          }
        }
      } else {
        pointBoundary = {
          start: Math.min(...updatedTouchedTimestamps),
          end: Math.max(...updatedTouchedTimestamps),
        }
      }
    }

    // UPDATE THE SELECTED POINTS INSIDE THE BOUNDARY AND MODE SHOULD NOT BE POINT
    if (pointBoundary.start && pointBoundary.end && !changeSeriesByPoint) {
      const isReferencePointInsideBoundary = isBetweenDates(
        pointBoundary.start,
        pointBoundary.end,
        referencePointTimeStamp,
      )

      // Update the points only if the reference point is inside the boundary period
      if (isReferencePointInsideBoundary) {
        const selectedPointIndexes = []
        // Get the indexes of selected points
        for (let p = 0; p < updatedSeriesData.length; p++) {
          const d = Array.isArray(updatedSeriesData[p]) ? updatedSeriesData[p][0] : updatedSeriesData[p].x

          const isDateBetweenSelectedPeriod = isAfter(d, pointBoundary.start) && isBefore(d, pointBoundary.end)
          const isSameStartOrEndDate =
            areDatesEqualIgnoringSecondsMilliseconds(d, pointBoundary.start) ||
            areDatesEqualIgnoringSecondsMilliseconds(d, pointBoundary.end)

          if (isDateBetweenSelectedPeriod || isSameStartOrEndDate) {
            // If there is period selected and point is moved out of the selected period,
            // then we need to ignore the points inside the selected period
            if (refPointIsNotInsideSelectedPeriod && selectedTimePeriod.start && selectedTimePeriod.end) {
              const pointInsideSelectedPeriod = isBetweenDates(selectedTimePeriod.start, selectedTimePeriod.end, d)
              if (!pointInsideSelectedPeriod) {
                selectedPointIndexes.push(p)
              }
            } else {
              selectedPointIndexes.push(p)
            }
          }
        }

        if (seriesChangeMode === ScheduleSeriesUpdateModes.linearly) {
          // ----------- UPDATE SERIES LINEARLY/RUBBER BAND -------------
          const pointsToUpdateLinearly = selectedPointIndexes.map((i) => updatedSeriesData[i])

          let valueIfSeriesMovedByArrow = null
          if (valueForCalculation) {
            valueIfSeriesMovedByArrow =
              arrow === keyboardKeyCode.arrowUp
                ? referencePointValue + valueForCalculation
                : referencePointValue - valueForCalculation
          }

          const linearPoints = increaseChartPointsLinearly({
            points: pointsToUpdateLinearly,
            changedPoint: referencePoint,
            fixedValue: valueIfSeriesMovedByArrow,
          })

          selectedPointIndexes.forEach((index) => {
            if (index !== referencePointIndex) {
              const pointAtIndex = updatedSeriesData[index]
              const pointTimeStamp = Array.isArray(pointAtIndex) ? pointAtIndex[0] : pointAtIndex.x
              const linearPointData = linearPoints.find((linearPoint) => {
                const linearPointTimeStamp = Array.isArray(linearPoint) ? linearPoint[0] : linearPoint.x
                return linearPointTimeStamp === pointTimeStamp
              })
              if (linearPointData) {
                updatedSeriesData[index] = linearPointData
              }
            }
          })
        } else {
          // ------------ UPDATE SERIES By FACTOR/PERCENTAGE OR OFFSET ----------
          // Get the reference point old value
          const refPointOldValue = valueForCalculation ? referencePointValue : findPointOldValue(referencePoint)

          // Get the reference point new value
          const refPointNewValue = valueForCalculation
            ? updatedSeriesData[referencePointIndex][1] || referencePointValue || 0
            : referencePoint?.newPoint?.low

          // After getting the ref point old and new values
          // 1. find the percentage increased
          const percentageIncreased = getPercentageIncreased(refPointOldValue, refPointNewValue)
          // 2. find the offset increased
          const offsetIncreased = getOffsetIncreased(refPointOldValue, refPointNewValue)

          // Increase by percentage/factor if the mode is set to Percentage or if th ref point value  is 0
          let updateValueHandler = refPointOldValue <= 0 ? increaseValueByOffset : increaseValueByPercentage
          let updateValueBy = refPointOldValue <= 0 ? offsetIncreased : percentageIncreased

          // Increase by offset if the mode is set to offset
          if (seriesChangeMode === ScheduleSeriesUpdateModes.offset) {
            updateValueBy = offsetIncreased
            updateValueHandler = increaseValueByOffset
          }

          selectedPointIndexes.forEach((index) => {
            // Do not update the moved/dragged Point
            if (index !== referencePointIndex) {
              const isArrayPoint = Array.isArray(updatedSeriesData[index])
              const date = isArrayPoint ? updatedSeriesData[index][0] : updatedSeriesData[index]?.x
              const oldValue = isArrayPoint ? updatedSeriesData[index][1] : updatedSeriesData[index].low
              let newValue = updateValueHandler(oldValue, updateValueBy)
              // console.log({ index, oldValue, newValue })
              if (newValue > maxValue) {
                newValue = maxValue
              }
              if (newValue < minValue) {
                newValue = minValue
              }
              updatedSeriesData[index] = [date, newValue, newValue]
            }
          })
        }
      }
    }
  }

  return updatedSeriesData
}

export const findNearestTouchedBoundary = (timestamp: number, edges: number[]): { start: Date; end: Date } => {
  const sortedEdges = [...edges].sort()
  // Remove the edge if the timestamp is already present in the list of edges
  const filteredEdges = sortedEdges.filter((e) => e !== timestamp)

  let leftBoundary: Date = new Date(sortedEdges[0])
  let rightBoundary: Date = new Date(sortedEdges[sortedEdges.length - 1])

  let leftDifferenceTemp: number | null = null
  let rightDifferenceTemp: number | null = null

  filteredEdges.forEach((edgeTimestamp) => {
    const isPast = isBefore(edgeTimestamp, timestamp)
    if (isPast) {
      // Check for left edge
      const difference = differenceInMilliseconds(timestamp, edgeTimestamp)
      // If the difference is less than the previous left difference in temp then update it
      if (leftDifferenceTemp === null || difference < leftDifferenceTemp) {
        leftDifferenceTemp = difference
        leftBoundary = edgeTimestamp
      }
    } else {
      // Check for right edge
      const difference = differenceInMilliseconds(edgeTimestamp, timestamp)
      // If the difference is less than the previous right difference in temp then update it
      if (rightDifferenceTemp === null || difference < rightDifferenceTemp) {
        rightDifferenceTemp = difference
        rightBoundary = edgeTimestamp
      }
    }
  })

  if (isSameDate(leftBoundary, rightBoundary)) {
    rightBoundary = new Date(timestamp)
  }

  return {
    start: new Date(leftBoundary),
    end: new Date(rightBoundary),
  }
}

export const getChangeScheduleSeriesModeMenuItems = () => {
  return [
    {
      key: ScheduleSeriesUpdateModes.point,
      label: t`Single`,
    },
    {
      key: ScheduleSeriesUpdateModes.linearly,
      label: t`Rubber band`,
    },
    {
      key: ScheduleSeriesUpdateModes.offset,
      label: t`Offset`,
    },
    {
      key: ScheduleSeriesUpdateModes.percentage,
      label: t`Scale`,
    },
  ]
}

export const getEditModeChartOptionsMenuItem = () => {
  return [
    {
      key: ScheduleEditModeChartOptions.select,
      label: t`Select`,
    },
    {
      key: ScheduleEditModeChartOptions.zoom,
      label: t`Zoom`,
    },
  ]
}

export const ScheduleUploadWarningStatusCodes = [
  ScheduleTemplateUploadStatus.ASSET_CONTAINS_CHILDREN_NOT_INCLUDED_IN_THE_TEMPLATE,
  ScheduleTemplateUploadStatus.ASSET_SCHEDULE_PERIOD_LONGER_THAN_TEMPLATE_SCHEDULE_PERIOD,
]
export const ScheduleUploadNotificationStatusCodes = [
  ScheduleTemplateUploadStatus.TEMPLATE_CONTAINS_MORE_SCHEDULES_THAN_THE_ONE_SELECTED,
]

export const getScheduleTemplateUploadErrorAndWarningMessage = (status: ScheduleTemplateUploadStatus) => {
  const supportLink = <a href="mailto: support@enercast.de">support@enercast.de</a>

  const {
    TEMPLATE_CONTAINS_MORE_SCHEDULES_THAN_THE_ONE_SELECTED,
    ASSET_CONTAINS_CHILDREN_NOT_INCLUDED_IN_THE_TEMPLATE,
    UPLOADED_SCHEDULE_NOT_IN_TEMPLATE_DATE,
    TEMPLATE_CONTRACT_CAPACITIES_ERROR,
    INCOMPATIBLE_TEMPLATE_ERROR,
    TEMPLATE_PROCESSING_ERROR,
    TemplateUploadException,
    ASSET_NOT_FOUND_ERROR,
    ASSET_SCHEDULE_NOT_FOUND_ERROR,
    POS_NAME_NOT_FOUND_IN_ASSET_TREE,
    NO_FILES_SUBMITTED,
    INCOMPLETE_TEMPLATE_INFORMATION,
    TEMPLATE_UPLOAD_ERROR,
    SUCCESS,
    SUCCESS_WITH_WARNINGS,
    MISSING_TEMPLATE_CAPACITY_VALUE,
    MISSING_TEMPLATE_PARENT,
    SELECTED_ASSET_MISSING_POS_NAME_VALUE,
    MISSING_TEMPLATE_SCHEDULING_ENTITY,
    MISSING_TEMPLATE_DATE,
    ASSET_SCHEDULE_PERIOD_LONGER_THAN_TEMPLATE_SCHEDULE_PERIOD,
    ASSET_SCHEDULE_PERIOD_SHORTER_THAN_TEMPLATE_SCHEDULE_PERIOD,
    ASSET_CAPACITY_VALUE_MISMATCH,
    MISSING_ASSET_AC_CAPACITY_VALUE,
    REQUIRED_CHILD_ASSETS_MISSING_IN_ASSET_TREE,
  } = ScheduleTemplateUploadStatus
  switch (status) {
    case SUCCESS:
    case SUCCESS_WITH_WARNINGS:
      return {
        message: null,
        hint: null,
      }
    case NO_FILES_SUBMITTED:
      return {
        message: t`No file has been submitted to upload.`,
        hint: null,
      }
    case MISSING_TEMPLATE_CAPACITY_VALUE:
      return {
        message: t`Error in the schedule template: The capacity of this asset or one of its contracts is missing.`,
        hint: null,
      }
    case MISSING_TEMPLATE_PARENT:
      return {
        message: t`The Scheduling Entity specified in the schedule template cannot be found in the asset tree of the asset you are currently working on. Check the POS Name fields in the schedule template and the plant keys in your asset tree.`,
        hint: null,
      }
    case SELECTED_ASSET_MISSING_POS_NAME_VALUE:
      return {
        message: t`The plant key of the asset you are currently working on is missing or does not match any asset in the schedule template. Check the POS Name fields in the schedule template.`,
        hint: null,
      }
    case MISSING_TEMPLATE_SCHEDULING_ENTITY:
      return {
        message: t`Error in the schedule template: The Scheduling Entity is missing.`,
        hint: null,
      }
    case MISSING_TEMPLATE_DATE:
      return {
        message: t`Error in the schedule template: The Date is missing.`,
        hint: null,
      }
    case INCOMPLETE_TEMPLATE_INFORMATION:
      return {
        message: t`Error in the schedule template: Some data is missing. Please check if all fields in the schedule template you uploaded are correctly filled.`,
        hint: null,
      }
    case POS_NAME_NOT_FOUND_IN_ASSET_TREE:
      return {
        message: t`An asset required by the schedule template cannot be found in the asset tree of the current asset. Check the POS Name fields in the schedule template and the Plant Keys in your asset hierarchy.`,
        hint: null,
      }
    case ASSET_SCHEDULE_NOT_FOUND_ERROR:
      return {
        message: t`This asset does not have a saved schedule or the period of the saved schedule is outside of the schedule period required by the schedule template.`,
        hint: null,
      }
    case TEMPLATE_CONTAINS_MORE_SCHEDULES_THAN_THE_ONE_SELECTED:
      return {
        message: t`When you proceed, you will submit schedules for all assets required by the uploaded schedule template, in addition to the one you are currently working on.`,
        hint: null,
      }
    case ASSET_CONTAINS_CHILDREN_NOT_INCLUDED_IN_THE_TEMPLATE:
      return {
        message: t`When you proceed, you will submit schedules only for the assets required by the uploaded schedule template. Not all children of this asset will be included.`,
        hint: null,
      }
    case TEMPLATE_CONTRACT_CAPACITIES_ERROR:
      return {
        message: t`Error in the schedule template: The asset’s total capacity does not match the sum of the contracted capacities for this asset.`,
        hint: null,
      }
    case INCOMPATIBLE_TEMPLATE_ERROR:
      return {
        message: t`There is missing data in the schedule template. Please check and upload it again.`,
        hint: null,
      }
    case TEMPLATE_PROCESSING_ERROR:
      return {
        message: jt`Error processing the schedule template. Please try again. If the error persists, please contact ${supportLink}`,
        hint: null,
      }
    case TEMPLATE_UPLOAD_ERROR:
    case TemplateUploadException:
      return {
        message: jt`Error uploading the schedule template.  Please try again.  Most likely your browser is unable to access the file you are trying to upload.  If the error persists, please contact ${supportLink}.`,
        hint: null,
      }
    case ASSET_NOT_FOUND_ERROR:
      return {
        message: t`The selected asset id does not match any of the current user's assets.`,
        hint: null,
      }
    case UPLOADED_SCHEDULE_NOT_IN_TEMPLATE_DATE:
      return {
        message: t`The uploaded schedule is of a time frame that is outside of the date in the template.`,
        hint: t`Please update the date in the template to match the schedule period.`,
      }
    case ASSET_SCHEDULE_PERIOD_LONGER_THAN_TEMPLATE_SCHEDULE_PERIOD:
      return {
        message: t`The saved schedule for this asset covers a longer schedule period than required by the schedule template. Only the data for the schedule period required by the schedule template will be submitted.`,
        hint: null,
      }
    case ASSET_SCHEDULE_PERIOD_SHORTER_THAN_TEMPLATE_SCHEDULE_PERIOD:
      return {
        message: t`The saved schedule for this asset does not cover the entire period required by the schedule template.`,
        hint: null,
      }
    case ASSET_CAPACITY_VALUE_MISMATCH:
      return {
        message: t`This asset’s AC capacity does not match the sum of the contracted capacities for this asset as provided in the schedule template.`,
        hint: null,
      }
    case MISSING_ASSET_AC_CAPACITY_VALUE:
      return {
        message: t`The value of the AC capacity is missing from the asset tree.`,
        hint: null,
      }
    case REQUIRED_CHILD_ASSETS_MISSING_IN_ASSET_TREE:
      return {
        message: t`The imported template requires assets not existent in the selected asset's tree.`,
        hint: null,
      }
    default:
      return {
        message: '',
        hint: '',
      }
  }
}

export const convertChartSeriesToValuesMap = (series: SimpleRange[]) => {
  return series?.reduce((prev, current) => {
    const timestamp = Array.isArray(current) ? current[0] : current?.xAxis || current?.x
    const date = formatDate(convertLocalDateToUTC(timestamp), null, DATE_FORMAT_INTERNAL_LONG)
    const value = Array.isArray(current) ? current[1] : current?.low || 0
    return {
      ...prev,
      [date]: value * 1000, // Convert the value to watt
    }
  }, {})
}

export const defaultSelectedColumnsForScheduleTemplateTable = (): string[] => {
  return ['status', 'posName', 'asset', 'contractSplit', 'scheduleSaved']
}

export const getScheduleTemplateDataTableColumns: () => Column[] = () => [
  {
    name: 'status',
    label: t`Status`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '3em',
    align: 'left',
    fixed: true,
  },
  {
    name: 'posName',
    label: t`Pos name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '7.8em',
    align: 'left',
    primaryColumn: true,
    fixed: true,
  },
  {
    name: 'assetName',
    label: t`Asset`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: false,
    width: '7.5em',
    fixed: true,
  },
  {
    name: 'contractSplit',
    label: t`Contract split`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '7.5em',
    align: 'right',
    fixed: true,
  },
  {
    name: 'savedSchedule',
    label: t`Schedule saved`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '7em',
    align: 'right',
    fixed: true,
  },
]
