import React, { useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import { Box, MenuItem } from '@material-ui/core'
import { c, t } from 'ttag'
import { AssetFormLabel, FormKey, FormKeyValueGrid, FormValue, SelectFieldWithoutLabel } from 'ui/form/assetForm.style'
import { FormApi } from 'final-form'
import { Field, useField } from 'react-final-form'
import { Select } from 'final-form-material-ui'
import { getEnercastFtpTypes } from 'utils/delivery'
import PasswordField from 'ui/form/PasswordField'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import SectionTargetUsedIn from 'modules/delivery/deliveryTargets/SectionTargetUsedIn'

interface SectionEnercastFTPDeliveryTargetFormProps {
  form: FormApi
  isNew: boolean
  siteForecastConfigs: ForecastConfig[]
}

const SectionEnercastFTPDeliveryTargetForm: React.FC<SectionEnercastFTPDeliveryTargetFormProps> = ({ isNew }) => {
  // Inputs
  const directory = useField<string>('ftpConfiguration.directory').input.value
  const username = useField<string>('ftpConfiguration.username').input.value
  const port = useField<string>('ftpConfiguration.port').input.value
  const serverAddress = useField<string>('ftpConfiguration.host').input.value
  const protocol = useField<string>('ftpConfiguration.protocol').input.value

  const enercastFtpTypes = useMemo(() => getEnercastFtpTypes(), [])

  const protocolLabel = useMemo(() => {
    return enercastFtpTypes.find((obj) => obj.value === protocol)
  }, [protocol, enercastFtpTypes])

  // Link to support
  const supportLink = (
    <a href="mailto: support@enercast.de" target="_blank" rel="noreferrer">
      support@enercast.de
    </a>
  )

  return (
    <Flex direction="column">
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Protocol`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          {isNew ? (
            <Field name="ftpConfiguration.protocol">
              {(props) => {
                return (
                  <SelectFieldWithoutLabel>
                    <Select input={props.input} meta={props.meta} disabled={!isNew}>
                      {enercastFtpTypes.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFieldWithoutLabel>
                )
              }}
            </Field>
          ) : (
            <Flex alignItems="center">
              <div>{protocolLabel?.label}</div>
            </Flex>
          )}
        </FormValue>
      </FormKeyValueGrid>

      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Server address`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex alignItems="center">
              <div>{serverAddress}</div>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Port`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex alignItems="center">
              <div>{port}</div>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Username`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex alignItems="center">
              <div>{username}</div>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Password`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <PasswordField
              readOnly={true}
              withOutBorders={true}
              formFieldsHasUnits={1}
              formStyleKeyValue={1}
              name="ftpConfiguration.password"
              label=""
            />
          </FormValue>
        </FormKeyValueGrid>
      )}

      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Directory`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex alignItems="center">
              <div>{directory}</div>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}
      {isNew && (
        <>
          <Box mt={0.4} mb={0.4}>{c('Delivery:Target')
            .t`You can use this function to create an account on an enercast FTP server to access your forecasts. Note that only one FTP account can be created for your enercast ID. Please select your preferred protocol (ftp or stfp) as this cannot be changed later.`}</Box>

          <Box mt={0.4} mb={0.4}>{c('Delivery:Target')
            .t`After you proceed to create the FTP account, your credentials and server address will be shown here.`}</Box>
        </>
      )}
      {!isNew && <SectionTargetUsedIn />}
      {!isNew && (
        <Box mt={0.4} mb={0.4}>
          {c('Delivery:Target')
            .jt`You can now assign this Delivery Target to your Forecast Configurations, which will cause your forecasts to be delivered to your FTP account. Forecasts will be available on the FTP server. To stop deliveries to the FTP account, simply remove this Delivery Target from the respective Forecast Configuration. If you need to make any other changes, please contact ${supportLink}`}
        </Box>
      )}
    </Flex>
  )
}

export default React.memo(SectionEnercastFTPDeliveryTargetForm)
