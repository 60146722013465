import React, { useCallback, useMemo } from 'react'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { AvailabilityTableItem } from 'modules/asset/availability/Availability.types'
import { Asset } from 'modules/asset/store/asset.types'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { isAvailability } from 'utils/availabilities'
import { Column, RETABLE_ID_AVAILABILITIES } from 'modules/reTable/reTable.types'
import { FormApi } from 'final-form'
import { AnyObject } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasAnyAuthority, isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import { c } from 'ttag'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { QUERY_ACTIVE_TAB, QUERY_ASSET } from 'utils/query-string'
import AvailabilityTableValueUnitCell from 'modules/asset/availability/AvailabilityTableValueUnitCell'
import AssetRow from 'modules/asset/availability/AssetRow'
import { testIdAvailabilityItem } from 'modules/asset/availability/Availabilities.ids'
import AvailabilityTypeCell from 'modules/asset/availability/AvailabilityTypeCell'

interface AvailabilityTableBodyProps {
  onAddNewItem: (event: React.MouseEvent<HTMLElement>, item: Asset) => void
  onEditItem: (data: AvailabilityTableItem) => void
  onDeleteItem: (data: AvailabilityTableItem) => void
  onSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  visibleItems: (AvailabilityTableItem | Asset)[]
  editedAvailability: AvailabilityTableItem | null
  columns: Column[]
  form: FormApi
  onClose: () => void
  tableHeaderHasActions: boolean
  assets: Asset[]
  rootAsset: Asset
  availabilities: AvailabilityTableItem[]
}

const AvailabilityTableBody: React.FC<AvailabilityTableBodyProps> = ({
  onAddNewItem,
  onEditItem,
  onDeleteItem,
  onSubmit,
  columns,
  visibleItems,
  form,
  onClose,
  editedAvailability,
  tableHeaderHasActions,
  assets,
  rootAsset,
  availabilities,
}) => {
  const user = useSelector(getUserResultSelector)
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_AVAILABILITIES)
  const hasAccess = useMemo(
    () => hasAnyAuthority(user, ['ROLE_MAINTENANCE']) || isAdmin(user) || isImpersonatedAdmin(user),
    [user],
  )
  const activeField = useMemo(() => form.getState().active, [form.getState().active])
  const formDirty = useMemo(() => form.getState().dirty, [form.getState().dirty])
  const isReadOnlyUser = useIsReadOnlyUser()

  const handleGetCustomRowComponent = useCallback(
    (columns: Column[], item: Asset) => {
      return (
        <AssetRow
          asset={item as Asset}
          error={null}
          tableHeaderHasActions={tableHeaderHasActions}
          columns={columns}
          onAddNewAvailability={onAddNewItem}
          rootAsset={rootAsset}
          availabilities={availabilities}
          form={form}
          showCreateButton={hasAccess}
          isReadOnlyUser={isReadOnlyUser}
        />
      )
    },
    [onAddNewItem, columns, tableHeaderHasActions, formDirty, rootAsset, availabilities, hasAccess],
  )

  const handleGetCustomCellComponent = useCallback(
    (column: Column, item: AvailabilityTableItem, query: string, isRowEdit: boolean) => {
      const parentAsset = assets.find((asset) => asset.id === item.siteId)
      if (column.name === 'value') {
        return (
          <AvailabilityTableValueUnitCell
            parentAsset={parentAsset}
            isEdit={isRowEdit}
            availability={item}
            activeField={activeField}
            fieldAutoFocus={false}
          />
        )
      } else if (column.name === 'type') {
        return <AvailabilityTypeCell asset={parentAsset} isEdit={isRowEdit} column={column} availability={item} />
      }
    },
    [activeField, assets],
  )

  return (
    <ReTableBody>
      {visibleItems.map((row: AvailabilityTableItem | Asset) => {
        const isRowEdit = row.id && editedAvailability?.id ? row.id === editedAvailability.id : false
        return (
          <ReTableEditableRow
            dataTestId={testIdAvailabilityItem}
            key={row.id}
            isRowEdit={isRowEdit}
            rowItem={row}
            columns={columns}
            form={form as FormApi}
            onEditRowItem={onEditItem}
            onDeleteRowItem={onDeleteItem}
            onSubmit={onSubmit}
            onClose={onClose}
            getCustomCellComponent={handleGetCustomCellComponent}
            getCustomRowComponent={handleGetCustomRowComponent}
            tableHeaderHasActions={tableHeaderHasActions}
            isCustomRow={!isAvailability(row)}
            showEditableRowActions={hasAccess && isAvailability(row)}
            isShaded={isAvailability(row) && row.uiIsPast}
            dialogText={
              isAvailability(row)
                ? c('Availability').t`${row.uiAssetName} availability`
                : c(`Availability`).t`availability`
            }
            dialogContext={c('Availability').t`availability limitation`}
            pageQueries={[QUERY_ASSET, QUERY_ACTIVE_TAB]}
            query={query}
            navigationDialogKey={'availabilityLimitation'}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(AvailabilityTableBody)
