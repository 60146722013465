export const ACKNOWLEDGE_SPLASH_SCREEN_REQUEST = 'ACKNOWLEDGE_SPLASH_SCREEN_REQUEST'
export const ACKNOWLEDGE_SPLASH_SCREEN_SUCCESS = 'ACKNOWLEDGE_SPLASH_SCREEN_SUCCESS'
export const ACKNOWLEDGE_SPLASH_SCREEN_FAILURE = 'ACKNOWLEDGE_SPLASH_SCREEN_FAILURE'
export const ACKNOWLEDGE_SPLASH_SCREEN_DISMISS = 'ACKNOWLEDGE_SPLASH_SCREEN_DISMISS'
export type ACKNOWLEDGE_SPLASH_SCREEN_REQUEST = typeof ACKNOWLEDGE_SPLASH_SCREEN_REQUEST
export type ACKNOWLEDGE_SPLASH_SCREEN_SUCCESS = typeof ACKNOWLEDGE_SPLASH_SCREEN_SUCCESS
export type ACKNOWLEDGE_SPLASH_SCREEN_FAILURE = typeof ACKNOWLEDGE_SPLASH_SCREEN_FAILURE
export type ACKNOWLEDGE_SPLASH_SCREEN_DISMISS = typeof ACKNOWLEDGE_SPLASH_SCREEN_DISMISS
export type ACKNOWLEDGE_SPLASH_SCREEN_ACTION_TYPE =
  | ACKNOWLEDGE_SPLASH_SCREEN_REQUEST
  | ACKNOWLEDGE_SPLASH_SCREEN_SUCCESS
  | ACKNOWLEDGE_SPLASH_SCREEN_FAILURE
  | ACKNOWLEDGE_SPLASH_SCREEN_DISMISS
