import React from 'react'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'

interface InlineLoadingProps {
  size?: number
  gap?: number
}

interface StyledCircularProgressProps {
  gap: number
}
const StyledCircularProgress = styled(CircularProgress)<StyledCircularProgressProps>`
  margin: 0 ${(props) => theme.spacing(props.gap)}px;
`

const InlineLoading: React.FC<InlineLoadingProps & CircularProgressProps> = ({ size = 16, gap = 1, ...props }) => {
  return <StyledCircularProgress size={size} gap={gap} {...props} />
}

export default React.memo(InlineLoading)
