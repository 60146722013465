import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import {
  QUERY_LANG_CHANGED,
  QUERY_STRING,
  QUERY_USER_SETTINGS,
  QueryParamType,
  useQueryParams,
} from 'utils/query-string'
import { blockCommonQueries, UnsavedChangesContext } from 'ui/elements/BlockNavigationDialog'
import qs from 'query-string'
import { FormApi } from 'final-form'
import { useLocation } from 'react-router-dom'

interface FormBlockNavigationProps {
  blockWithExternalChanges: boolean
  navigationDialogText: React.ReactNode
  form?: FormApi
  currentPageQueries?: QUERY_STRING[] | null
  currentPageRoute?: string | null
  navigationDialogKey: string
  onResetExternalChanges?: () => void
  onCancelUnsavedDialog?: () => void
  resetWithCurrentValues?: boolean
}

const FormBlockNavigation: React.FC<FormBlockNavigationProps> = ({
  blockWithExternalChanges,
  form,
  navigationDialogText,
  currentPageQueries,
  currentPageRoute,
  navigationDialogKey,
  onResetExternalChanges,
  onCancelUnsavedDialog,
  resetWithCurrentValues,
}) => {
  const { addNavigationBlockHandler, removeNavigationBlockHandler } = useContext(UnsavedChangesContext)
  const { queryParams } = useQueryParams()
  const location = useLocation()
  const isUserSettingsPage = (currentPageQueries || [])?.includes(QUERY_USER_SETTINGS)

  const formDirtyFields = useMemo(() => (form ? form.getState().dirtyFields : {}), [form?.getState().dirtyFields])
  const formDirtyFieldsKeys = Object.keys(formDirtyFields)

  const formDirty = useMemo(() => formDirtyFieldsKeys.length > 0, [formDirtyFields, formDirtyFieldsKeys])

  // User settings page
  const onlyLangFieldEdited =
    isUserSettingsPage && formDirtyFieldsKeys.length == 1 && formDirtyFieldsKeys.includes('langKey')

  const blockNavigation = useMemo(() => formDirty || blockWithExternalChanges, [formDirty, blockWithExternalChanges])

  const checkNavigationWillDestroyChanges = useCallback(
    (currentLocation: Location, nextLocation: Location) => {
      const nextParams = qs.parse(nextLocation.search) as QueryParamType
      const currentParams = qs.parse(currentLocation.search) as QueryParamType
      const onlyLangFieldEditedAndCanceled = onlyLangFieldEdited && !nextParams[QUERY_USER_SETTINGS]

      const isBlocking = blockNavigation && (!onlyLangFieldEdited || onlyLangFieldEditedAndCanceled)

      let isDestroying = false
      if (isBlocking) {
        /**
         * Navigate to a Same page with same query params but with different values
         * eg: asset or forecast config so we check the values
         * */
        const navigateAwayWithSameQueryParams = currentPageQueries?.some(
          (query) => nextParams[query] !== currentParams[query],
        )
        /**
         * Navigate away to a different page with different query params and values
         * */
        const navigateAwayWithDifferentQueryParams = currentPageQueries?.some((query) => !nextParams[query])

        /**
         * Navigate away to a different page with different route
         * */
        const navigateToDifferentRoute = currentPageRoute && !nextLocation.pathname.includes(currentPageRoute)

        // If the language is changed from the user settings when other form (asset details, site forecast ...) is displayed
        const languageChanged =
          !Boolean(currentPageQueries?.includes(QUERY_LANG_CHANGED)) && nextParams[QUERY_LANG_CHANGED]

        isDestroying = Boolean(
          navigateAwayWithSameQueryParams ||
            navigateAwayWithDifferentQueryParams ||
            navigateToDifferentRoute ||
            languageChanged ||
            blockCommonQueries.some((query) => nextParams[query]),
        )
      }

      return {
        isBlocking,
        isDestroying,
      }
    },
    [blockNavigation, currentPageQueries, currentPageRoute, onlyLangFieldEdited],
  )

  const abortNavigation = useCallback(() => {
    // setOpenDialog(false)
    if (onCancelUnsavedDialog) {
      onCancelUnsavedDialog()
    }
  }, [])

  const proceedNavigation = useCallback(() => {
    // do something
    if (resetWithCurrentValues) {
      const values = form.getState().values
      form?.reset(values)
    } else {
      form?.reset()
    }
    if (onResetExternalChanges) {
      onResetExternalChanges()
    }
  }, [Boolean(form), resetWithCurrentValues, onResetExternalChanges])

  useEffect(() => {
    if (addNavigationBlockHandler && removeNavigationBlockHandler) {
      const hasCurrentPageQueries = currentPageQueries?.every((query) => Boolean(queryParams[query]))
      const hasCurrentPageRoute = currentPageRoute === location.pathname

      if (hasCurrentPageQueries || hasCurrentPageRoute) {
        addNavigationBlockHandler({
          [navigationDialogKey]: {
            dialogText: navigationDialogText,
            checkNavigationWillDestroyChanges: checkNavigationWillDestroyChanges,
            abortNavigation: abortNavigation,
            proceedNavigation: proceedNavigation,
          },
        })
      } else {
        removeNavigationBlockHandler(navigationDialogKey)
      }
    }
    return () => {
      if (removeNavigationBlockHandler) {
        removeNavigationBlockHandler(navigationDialogKey)
      }
    }
  }, [
    location,
    checkNavigationWillDestroyChanges,
    queryParams,
    abortNavigation,
    proceedNavigation,
    addNavigationBlockHandler,
    removeNavigationBlockHandler,
    currentPageQueries,
    currentPageRoute,
    navigationDialogKey,
    navigationDialogText,
  ])

  return <></>
}

export default React.memo(FormBlockNavigation)
