import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import styled from 'styled-components'
import { Box, Chip, makeStyles, Theme, Toolbar } from '@material-ui/core'
import { jt, t } from 'ttag'

import {
  ROUTE_DATA,
  ROUTE_SITE_ASSESSMENT,
  ROUTE_SUBSCRIPTIONS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_WORKBENCH,
} from 'modules/app/Routes'
import HeaderLink from 'modules/app/header/HeaderLink'
import Logo from 'modules/app/header/Logo'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { toolbarHeight } from 'themes/theme-light'
import {
  isAdmin,
  isImpersonatedAdmin,
  isPartner,
  useIsReadOnlyUser,
  userHasGlobalAccessWithoutDemo,
  useUserHasGlobalAccess,
} from 'utils/user'
import AppBar from '@material-ui/core/AppBar'

import CustomLink from 'ui/elements/CustomLink'
import { hasPermissionForSiteAssessment } from 'utils/user'

import Flex from 'ui/styles/Flex'
import UserMenu from 'modules/user/userMenu/UserMenu'
import HeaderAdminFunctionality from 'modules/app/header/HeaderAdminFunctionality'
import PopperTooltip from 'ui/PopperTooltip'
import { LinkToContactSupportForReadOnly } from 'ui/elements/MailLink'
import UserProfilePic from 'ui/elements/UserProfilePic'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.app.color.main,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
  },
}))

const AppToolBar = styled(Toolbar)`
  &.MuiToolbar-regular {
    min-height: ${toolbarHeight};
  }
  &.MuiToolbar-gutters {
    padding-left: 1em;
    padding-right: 1em;
  }
`

const LogoContainer = styled.div`
  width: 19.5em;
`
//
// const NewUIInfoContainer = styled(Paper)`
//   position: absolute;
//   margin-top: 2.5em;
//   right: 1.4em;
// `

const PopperTooltipDiv = styled.div`
  margin-right: 3px;
`

const Header: React.FC = () => {
  const classes = useStyles()

  const user = useSelector(getUserResultSelector)
  const hasAccessToUserManagement = isAdmin(user) || isPartner(user)

  // const [showSplashScreen, setShowSplashScreen] = React.useState<boolean>(false)
  // const authed = useSelector(authedSelector)
  //
  // const handleCloseSplashScreen = useCallback(() => {
  //   setShowSplashScreen(false)
  // }, [])

  const isReadOnlyUser = useIsReadOnlyUser()

  // useEffect(() => {
  //   checkShowSplashScreen().then((res) => {
  //     const result = res?.response?.data
  //     if (result === true || result === 'true') {
  //       setShowSplashScreen(true)
  //     } else {
  //       setShowSplashScreen(false)
  //     }
  //   })
  // }, [])

  const userHasGlobalAccess = useUserHasGlobalAccess()

  const hasAccessToSiteAssessment = useMemo(() => hasPermissionForSiteAssessment(user), [user])
  return (
    <AppBar className={classes.root} color="default" position="sticky" data-testid="app-header">
      <AppToolBar>
        <LogoContainer>
          <CustomLink route={ROUTE_WORKBENCH}>
            <Logo />
          </CustomLink>
        </LogoContainer>

        <Box display="flex" flexGrow={1}>
          <HeaderLink to={ROUTE_WORKBENCH}>{t`Workbench`}</HeaderLink>
          {(hasAccessToSiteAssessment || isAdmin(user) || isImpersonatedAdmin(user)) && (
            <HeaderLink
              isAdminComponent={(isAdmin(user) || isImpersonatedAdmin(user)) && !hasAccessToSiteAssessment}
              to={ROUTE_SITE_ASSESSMENT}
            >{t`Site Assessment`}</HeaderLink>
          )}
          <div className="appTour-createDeliveryTarget-headerBarButton">
            <HeaderLink to={ROUTE_DATA}>{t`Data`}</HeaderLink>
          </div>
          {userHasGlobalAccess && (
            <HeaderLink
              isAdminComponent={userHasGlobalAccessWithoutDemo(user)}
              to={ROUTE_SUBSCRIPTIONS}
            >{t`Subscriptions`}</HeaderLink>
          )}
          {hasAccessToUserManagement && (
            <HeaderLink isAdminComponent={isAdmin(user)} to={ROUTE_USER_MANAGEMENT}>{t`User management`}</HeaderLink>
          )}
          {isAdmin(user) && <HeaderAdminFunctionality />}
        </Box>

        {/* eslint-disable-next-line react/jsx-no-undef */}
        <Flex alignItems="center">
          {isReadOnlyUser && (
            <PopperTooltipDiv>
              <PopperTooltip
                popperLabel={<Chip size="small" label={t`Read-only`} style={{ cursor: 'help' }} />}
                popperContent={jt`All editing functions are disabled as this account has read-only access only. For write access, please contact ${LinkToContactSupportForReadOnly}.`}
              />
            </PopperTooltipDiv>
          )}

          <UserMenu />
          <UserProfilePic />
        </Flex>
      </AppToolBar>

      {/*{authed && showSplashScreen && !isImpersonatedAdmin(user) && !isAdmin(user) && (*/}
      {/*  <NewUIInfoContainer elevation={3}>*/}
      {/*    <SplashScreenDialog onDialogClose={handleCloseSplashScreen} />*/}
      {/*  </NewUIInfoContainer>*/}
      {/*)}*/}
    </AppBar>
  )
}

// Header.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(Header)
