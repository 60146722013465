import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { UserConfig } from 'modules/auth/Auth.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { useSelector } from 'react-redux'

// helpers

const delimiter = '---'

const getUniqueId = (collectionId: string, configId?: string) => {
  return configId ? `${collectionId}${delimiter}${configId}` : collectionId
}

// state

interface State {
  result: Record<string, Record<string, UserConfig['value']>>
  loading: Record<string, boolean>
  error: Record<string, string | null>
  initialized: Record<string, boolean>
}

const initialState: State = {
  result: {},
  loading: {},
  error: {},
  initialized: {},
}

export interface GetUserConfigAction {
  type:
    | actionTypes.GET_USERCONFIG_ACTION_TYPE
    | actionTypes.SET_USERCONFIG_SUCCESS
    | actionTypes.DELETE_USERCONFIG_SUCCESS
  // REQUEST
  collectionId: string
  configId?: string
  // SUCCESS
  userConfigs: UserConfig[]
  // FAILURE
  error: string | null
}

// reducers

const result = createReducer<State['result'], GetUserConfigAction>(
  (state = initialState.result, { type, collectionId, configId, userConfigs }) => {
    switch (type) {
      case actionTypes.GET_USERCONFIG_SUCCESS:
      case actionTypes.SET_USERCONFIG_SUCCESS:
        userConfigs.map((userConfig) => {
          const { collectionId, configId, value } = userConfig
          if (collectionId && configId) {
            if (!state[collectionId]) state[collectionId] = {}
            state[collectionId][configId] = value
          }
        })
        break
      case actionTypes.DELETE_USERCONFIG_SUCCESS:
        if (configId && state[collectionId]?.[configId]) delete state[collectionId][configId]
        else if (!configId) delete state[collectionId]
        break
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetUserConfigAction>(
  (state = initialState.loading, { type, collectionId, configId }) => {
    switch (type) {
      case actionTypes.GET_USERCONFIG_REQUEST:
        return {
          ...state,
          [getUniqueId(collectionId, configId)]: true,
        }
      case actionTypes.GET_USERCONFIG_SUCCESS:
      case actionTypes.GET_USERCONFIG_FAILURE:
        return {
          ...state,
          [getUniqueId(collectionId, configId)]: false,
        }
    }
    return state
  },
)

const error = createReducer<State['error'], GetUserConfigAction>(
  (state = initialState.error, { type, collectionId, configId, error }) => {
    switch (type) {
      case actionTypes.GET_USERCONFIG_SUCCESS:
      case actionTypes.GET_USERCONFIG_DISMISS:
        return {
          ...state,
          [getUniqueId(collectionId, configId)]: null,
        }
      case actionTypes.GET_USERCONFIG_FAILURE:
        return {
          ...state,
          [getUniqueId(collectionId, configId)]: error,
        }
    }
    return state
  },
)

const initialized = createReducer<State['initialized'], GetUserConfigAction>(
  (state = initialState.initialized, { type, collectionId, configId }) => {
    switch (type) {
      case actionTypes.GET_USERCONFIG_SUCCESS:
      case actionTypes.GET_USERCONFIG_FAILURE:
        return {
          ...state,
          [getUniqueId(collectionId, configId)]: true,
        }
    }
    return state
  },
)

export const getUserConfigReducer = combineReducers({
  result,
  loading,
  error,
  initialized,
})

// selectors

export const getUserConfigResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.auth.getUserConfig.result,
  (result) => result,
)
export const getUserConfigLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.getUserConfig.loading,
  (loading) => loading,
)
export const getUserConfigErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.getUserConfig.error,
  (error) => error,
)
export const getUserConfigInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.auth.getUserConfig.initialized,
  (initialized) => initialized,
)

// hooks

export const useUserConfigResult = (collectionId: string, configId?: string) => {
  const userConfig = useSelector(getUserConfigResultSelector) || {}

  if (configId) {
    return (userConfig[collectionId] || {})[configId]
  } else {
    return userConfig[collectionId]
  }
}

export const useUserConfigLoading = (collectionId: string, configId?: string) => {
  const loading = useSelector(getUserConfigLoadingSelector) || {}
  return loading[collectionId] || loading[getUniqueId(collectionId, configId)] || false
}

export const useUserConfigError = (collectionId: string, configId?: string) => {
  const error = useSelector(getUserConfigErrorSelector) || {}
  return error[collectionId] || error[getUniqueId(collectionId, configId)] || null
}

export const useUserConfigInitialized = (collectionId: string, configId?: string) => {
  const initialized = useSelector(getUserConfigInitializedSelector) || {}
  return initialized[collectionId] || initialized[getUniqueId(collectionId, configId)] || false
}
