import React from 'react'
import { Box, Chip, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { TextField } from 'final-form-material-ui'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { Field } from 'react-final-form'
import { orange, red } from '@material-ui/core/colors'
import NextPreviousButtons from 'ui/elements/NextPreviousButtons'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import { FieldValidator } from 'final-form'
import LayoutTitle from 'ui/LayoutTitle'
import { colors } from 'themes/theme-light'
import { rgba } from 'polished'
import InBuiltEntityIcon from 'ui/elements/InBuiltEntityIcon'

export const FormHeaderIconWrapper = styled.div`
  margin-right: 1em;
  margin-top: 1em;
`

interface TitleContainerProps {
  use_full_width_for_title: number
  highlight_required?: number
}

export const TitleContainer = styled(Typography)<TitleContainerProps>`
  width: ${(props) => (props.use_full_width_for_title ? '100%' : '60%')};
  background-color: ${(props) => (props.highlight_required ? rgba(colors.primaryMain, 0.07) : 'inherit')};
`

const BuiltInIconContainer = styled.div`
  margin-left: 1em;
`

interface TitleChipProps {
  color: string | null
}
// <Chip> does not allow colors other than 'primary' and 'secondary'
//  therefore we don't pass `color` prop to the MUI component
//  otherwise we get an error in console that Chip props are invalid
export const AssetDetailsTitleChip = styled(({ ...rest }) => <Chip {...rest} />)<TitleChipProps>`
  &.MuiChip-root {
    background-color: ${(props) => props.color};
    color: white;
  }
  margin-top: 10px;
`

interface DetailsFormHeaderProps {
  showNextPrevOptions: boolean
  handleGoToNextPrevItem: (nextItem: boolean) => void
  totalItems: number
  onCloseDetailsForm: () => void
  fieldName: string
  placeholder: string
  disabled?: boolean
  changed?: boolean
  deleted?: boolean
  validators?: FieldValidator<any>
  headerTitle?: string
  closeButtonClassName?: string
  useFullWidthForTitle?: number
  children: React.ReactNode
  customComponent?: React.ReactNode
  titleFieldClassName?: string // For app tour,
  requiredField?: boolean
  hideTitleInputField?: boolean
  extraActions?: React.ReactNode
  extraFormComponents?: React.ReactNode
  isInBuiltEntity?: boolean
  inBuiltEntityMsg?: string
}

const DetailsFormHeader: React.FC<DetailsFormHeaderProps> = ({
  showNextPrevOptions,
  handleGoToNextPrevItem,
  totalItems,
  onCloseDetailsForm,
  fieldName,
  placeholder,
  disabled = false,
  changed,
  deleted,
  validators,
  headerTitle,
  closeButtonClassName,
  useFullWidthForTitle = 0,
  children,
  customComponent,
  titleFieldClassName,
  requiredField,
  hideTitleInputField = false,
  extraActions,
  extraFormComponents,
  isInBuiltEntity = false,
  inBuiltEntityMsg,
}) => {
  const headerInputFieldContent = (
    <Flex direction="row" flexGrow={1} alignItems="center">
      <TitleContainer
        use_full_width_for_title={useFullWidthForTitle}
        variant="h2"
        highlight_required={requiredField ? 1 : 0}
        className={titleFieldClassName || 'name'}
      >
        <Field name={fieldName} validate={validators ? validators : undefined}>
          {(props) => {
            return (
              <TextField
                placeholder={placeholder}
                fullWidth
                disabled={disabled}
                input={props.input}
                meta={props.meta}
              />
            )
          }}
        </Field>
      </TitleContainer>

      {isInBuiltEntity && (
        <BuiltInIconContainer>
          <InBuiltEntityIcon size={'lg'} message={inBuiltEntityMsg} />
        </BuiltInIconContainer>
      )}

      {(changed || deleted) && (
        <Box ml={1}>
          <AssetDetailsTitleChip
            size="small"
            label={deleted ? t`Deleted` : t`Changed`}
            color={deleted ? red[700] : orange[800]}
          />
        </Box>
      )}
      {extraFormComponents && <Box ml={1}>{extraFormComponents}</Box>}
    </Flex>
  )

  const headerActions = (
    <Flex alignItems="center">
      <NextPreviousButtons
        showNextPrevOptions={showNextPrevOptions}
        onNextPrevItem={handleGoToNextPrevItem}
        totalItems={totalItems}
      />
      {children}
      <SliderCloseButton className={closeButtonClassName} onCloseSlider={onCloseDetailsForm} />
    </Flex>
  )
  return (
    <>
      <Flex direction="row" justifyContent="space-between" alignItems="center" flexGrow={1}>
        <Flex direction="row" alignItems="center" flexGrow={1}>
          {headerTitle ? <LayoutTitle>{headerTitle}</LayoutTitle> : <>{headerInputFieldContent}</>}{' '}
        </Flex>
        {extraActions && <>{extraActions}</>}
        <>{headerActions}</>
      </Flex>

      {headerTitle && (
        <Flex direction="row" alignItems="center" justifyContent="flex-start">
          {customComponent}
          {hideTitleInputField && isInBuiltEntity && (
            <BuiltInIconContainer>
              <InBuiltEntityIcon size={'lg'} message={inBuiltEntityMsg} />
            </BuiltInIconContainer>
          )}
          {!hideTitleInputField && <>{headerInputFieldContent}</>}{' '}
        </Flex>
      )}
    </>
  )
}

export default React.memo(DetailsFormHeader)
