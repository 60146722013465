import {
  addMinutes,
  addSeconds,
  differenceInCalendarDays,
  differenceInMilliseconds,
  differenceInMinutes,
  getDay,
  getTime,
  isEqual,
  isSameDay,
  isSameHour,
  subSeconds,
} from 'date-fns'

import { Timezone } from 'fixtures/timezones'
import { Axis } from 'highcharts'
import {
  SeriesOptions,
  XAxisOptions,
  XAxisPlotBandsOptions,
  XAxisPlotLinesOptions,
  YAxisOptions,
} from 'highcharts/highstock'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { createAvailabilityPlotbands } from 'modules/workspace/chart/availabilities'
import { alignYAxes, CreateYAxis, getUniqueUnitYAxisParams } from 'modules/workspace/chart/axis'
import {
  basicSchedulePlotBand,
  createDayPlotBands,
  createSchedulePlotBand,
  createScheduleTimingPlotLine,
  createTodayLine,
  getTodayLineValue,
  getWindowStartAndEndTimesTimestamp,
} from 'modules/workspace/chart/days'
import { useChartSeriesSet } from 'modules/workspace/chart/timeSeriesToChartSeries'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import TimeOptions from 'modules/workspace/highstock/TimeOptions'
import TooltipOptions from 'modules/workspace/highstock/TooltipOptions'
import { SET_SERIES } from 'modules/workspace/store/series.state'
import {
  ChartRangeSelectionOffset,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { HighchartsStockChart, Navigator, PlotBand, PlotLine, Series, XAxis, YAxis } from 'react-jsx-highstock'
import { useDispatch, useSelector } from 'react-redux'
import useResizeAware from 'react-resize-aware'
import styled from 'styled-components'
import { c, t } from 'ttag'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { mapAvailabilityToAssets } from 'utils/availabilities'
import { isNumeric } from 'utils/dataFormatting'
import { convertLocalDateToUTC, convertUTCToLocalDate, DateRange, roundToNext15Minutes } from 'utils/date'
import { keyboardKeyCode, useWorkspaceChartSelectedDateRange, useWorkspaceChartWholeDateRange } from 'utils/workspace'
import { DeepPartial } from 'ts-essentials'
import { Availability } from 'modules/asset/availability/Availability.types'
import { useAvailabilitiesByAssets } from 'modules/asset/availability/api/availability.api'
import {
  Asset,
  FORECAST_MODEL_FOR_BACK_CAST_ID,
  TYPE_SOLARPARK,
  TYPE_SOLARPLANT,
  TYPE_WINDPARK,
  TYPE_WINDPLANT,
} from 'modules/asset/store/asset.types'
import { removeDuplicates } from 'utils/array'
import { ArtificialPointSegment, CHART_BOOST_THRESHOLD_VALUE, getEmptyChartYAxisData, getYAxisData } from 'utils/chart'
import {
  addPointForArtificialSegment,
  createTimeSeriesWithNullValues,
  isNotMonthlyMarketPriceTimeseries,
} from 'utils/timeseries'
import ResetZoomButton from 'modules/workspace/highstock/ResetZoomButton'
import {
  workspaceDraftChartWidgetsSelector,
  workspaceDraftLoadingSelector,
  workspaceDraftResultSelector,
  workspaceDraftScheduleSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { scheduleDataMenuName, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useCreateScheduleSeries } from 'utils/hooks/useCreateScheduleSeries'
import {
  useCreateScheduleInput,
  useCreateScheduleInputDataMutation,
  useScheduleSeriesChanged,
  useScheduleSeriesChangedMutation,
} from 'modules/workspace/schedule/schedule.api'
import {
  getScheduleSeriesClickedPointFromStorage,
  removeScheduleSeriesClickedPointFromStorage,
  ScheduleLocalStorageKeys,
  TypesOfScheduleTimingLines,
  updateScheduleSeriesPoints,
} from 'utils/schedule'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionToCreateSchedule } from 'utils/user'
import { getScheduleTiming } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'

import {
  HighlightedSchedulePeriod,
  ScheduleEditModeChartOptions,
  ScheduleSeriesUpdateModes,
} from 'modules/workspace/schedule/schedule.types'
import { scheduleTimingColors } from 'themes/theme-light'

interface ContentProps {
  chartWidgetsSelected: number
}

const Content = styled.div<ContentProps>`
  position: relative;
  height: inherit;
  min-height: 21em;
  max-height: ${(props) => (props.chartWidgetsSelected !== 1 ? `inherit` : `calc(100vh - 12.5em)`)};
`

export interface DataStreamChartProps {
  loading: boolean
  range: DateRange
  isHidden: boolean
  timezone?: Timezone
  showDayIndicators?: boolean
  countOfChartsSelected: number
  selectedAssets: Asset[]
  showE3Widget: boolean
  showTooltip: boolean
  createScheduleActive: boolean
  setRevisionTimingInfo: (value: any) => void
  revisionTimingInfo: any
}
const DataStreamChart: React.FC<DataStreamChartProps> = ({
  loading,
  range,
  isHidden, // this is used in memo equality callback
  timezone = 'UTC',
  showDayIndicators = true,
  selectedAssets,
  showTooltip,
  createScheduleActive,
  setRevisionTimingInfo,
  revisionTimingInfo,
}) => {
  const controller = new AbortController()
  const dispatch = useDispatch()
  const chartWholeDateRange = useWorkspaceChartWholeDateRange()
  const chartSelectedRange = useWorkspaceChartSelectedDateRange()

  const user = useSelector(getUserResultSelector)

  const selectedChartWidgets = useSelector(workspaceDraftChartWidgetsSelector)

  const [currentRange, setCurrentRange] = useState(range)
  const [resizeListener, { width, height }] = useResizeAware()
  const [chart, setChart] = useState<HighchartsStockChart | undefined>()

  const chartSettings = useLineChartSettings().data
  // Actual Timeseries data for all selected data streams and assets
  const availabilityResults = useAvailabilitiesByAssets({ assets: selectedAssets })
  const availabilities = useMemo(() => {
    let result = [] as Availability[]
    if (Array.isArray(availabilityResults) && availabilityResults && availabilityResults?.length) {
      availabilityResults?.forEach((res) => {
        if (res.isFetched && res.isSuccess && res.data) {
          result = [...result, ...res?.data]
        }
      })
    }

    return result
  }, [availabilityResults])

  const availability = useMemo(() => mapAvailabilityToAssets(selectedAssets || [], availabilities), [
    selectedAssets,
    availabilities,
  ])

  // ----------------------- Schedule series code starts here -----------------------
  const workspaceDraftSchedule = useSelector(workspaceDraftScheduleSelector)
  const changeSeriesMode = workspaceDraftSchedule?.changeSeriesMode || ScheduleSeriesUpdateModes.linearly

  // saved period to highlight
  const savedSelectedPeriodToHighlight = workspaceDraftSchedule.hasSelectedSchedulePeriodToHighlight

  const schedulePeriodToHighlightInitialValue = () => {
    if (
      savedSelectedPeriodToHighlight?.start &&
      savedSelectedPeriodToHighlight?.end &&
      savedSelectedPeriodToHighlight?.event
    ) {
      return {
        start: new Date(savedSelectedPeriodToHighlight.start),
        end: new Date(savedSelectedPeriodToHighlight.end),
        event: savedSelectedPeriodToHighlight.event,
      }
    }
    return {}
  }

  const scheduleSeries = useCreateScheduleSeries()
  const [selectedSchedulePeriodToHighlight, setSelectedSchedulePeriodToHighlight] = useState<HighlightedSchedulePeriod>(
    schedulePeriodToHighlightInitialValue(),
  )

  const [scheduleSeriesTouchedTimestamps, setScheduleSeriesTouchedTimestamps] = useState<number[]>([])
  const [chartPlotLines, setChartPlotLines] = useState<XAxisPlotLinesOptions[]>([])

  const { mutate: saveCreateScheduleInputMutation } = useCreateScheduleInputDataMutation()
  const scheduleInputSeriesQueryResult = useCreateScheduleInput()
  const scheduleInputSeriesData = scheduleInputSeriesQueryResult?.data
  const workspaceDraftResult = useSelector(workspaceDraftResultSelector)
  const workspaceDraftLoading = useSelector(workspaceDraftLoadingSelector)

  // edit mode options
  const scheduleEditModeChartOption =
    workspaceDraftSchedule?.scheduleEditModeChartOptions || ScheduleEditModeChartOptions.select
  const scheduleEditModeChartOptionSelect = scheduleEditModeChartOption === ScheduleEditModeChartOptions.select

  const touchedPointsFromWorkspaceDraft = workspaceDraftSchedule?.touchedPoints || []
  const changeSeriesLinearly = changeSeriesMode === ScheduleSeriesUpdateModes.linearly
  const changeSeriesByPoint = changeSeriesMode === ScheduleSeriesUpdateModes.point
  const { mutate: saveScheduleSeriesChangedMutation } = useScheduleSeriesChangedMutation()
  const seriesChangedQueryResult = useScheduleSeriesChanged()
  const seriesChangedData = seriesChangedQueryResult?.data

  const [updatedScheduleSeries, setUpdatedScheduleSeries] = useState<SeriesOptions[]>([])
  const [debouncedScheduleSeriesSet] = useDebounce(scheduleSeries, 30, { leading: true })
  const { arrowUp, arrowDown } = keyboardKeyCode
  const scheduleSeriesInterval = scheduleSeries[0]?.custom?.detectedInterval

  const scheduleSeriesIntervalInMinutes = scheduleSeriesInterval > 0 ? scheduleSeriesInterval / (60 * 1000) : 0

  useEffect(() => {
    if (hasPermissionToCreateSchedule(user)) {
      setUpdatedScheduleSeries(debouncedScheduleSeriesSet)
    }
  }, [JSON.stringify(debouncedScheduleSeriesSet), user])

  useEffect(() => {
    if (scheduleInputSeriesData) {
      getScheduleTiming(scheduleInputSeriesData?.asset?.id || '')
        .then((response: any) => {
          let selectedAssetType = ''
          if (
            scheduleInputSeriesData?.asset?.type === TYPE_SOLARPLANT ||
            scheduleInputSeriesData?.asset?.type === TYPE_SOLARPARK
          ) {
            selectedAssetType = TYPE_SOLARPLANT.toUpperCase()
          } else if (
            scheduleInputSeriesData?.asset?.type === TYPE_WINDPLANT ||
            scheduleInputSeriesData?.asset?.type === TYPE_WINDPARK
          ) {
            selectedAssetType = TYPE_WINDPLANT.toUpperCase()
          }

          const revisionTimingInfoForSelectedAssetType = response?.revisionTimingInfo[selectedAssetType]

          if (
            revisionTimingInfoForSelectedAssetType &&
            Object.keys(revisionTimingInfoForSelectedAssetType).length > 0
          ) {
            setRevisionTimingInfo((prev) => {
              return { ...prev, data: revisionTimingInfoForSelectedAssetType }
            })
          }
        })
        .catch(() => setRevisionTimingInfo({ data: {}, hoveredPlotline: null, locationX: null, locationY: null }))
    }
  }, [scheduleInputSeriesData])

  /**
   * getLeftEdgeTimestamp
   * This function is used to calculate left edge when moving points in schedule.
   * If there is a revision info , it returns the value depending on the timeBlockLength and forecastOffset calculation , otherwise from current time.
   * IMPORTANT: In dependency array , we have added chartPlotLines which is used to dynamically calculate this value when the time passes
   */
  const getLeftEdgeTimestamp = useMemo(() => {
    let date: any
    if (Object.keys(revisionTimingInfo).length > 0) {
      const { timeBlockLength, forecastOffset } = revisionTimingInfo.data
      const dateWithAddedMinutes = addMinutes(new Date(), forecastOffset)
      date = getTodayLineValue(dateWithAddedMinutes, timeBlockLength)
    } else {
      date = roundToNext15Minutes(new Date()).getTime()
    }
    return isNaN(date) ? roundToNext15Minutes(new Date()).getTime() : date
  }, [revisionTimingInfo, chartPlotLines])

  /**
   * Add the First/left most point after checking if there are any points in workspace draft
   */
  const firstPointData =
    debouncedScheduleSeriesSet?.length && debouncedScheduleSeriesSet[0]?.data
      ? debouncedScheduleSeriesSet[0]?.data[0]
      : []
  const firstPointTimestamp = Array.isArray(firstPointData) ? firstPointData[0] || null : firstPointData?.x
  const seriesFirstPointTimestampFromLeftEdge =
    firstPointTimestamp > getLeftEdgeTimestamp ? firstPointTimestamp : getLeftEdgeTimestamp

  /**
   * Update the touched points state
   */
  const handleUpdateScheduleSeriesTouchedTimestamps = (timestamps: number[], reset = false) => {
    if (changeSeriesLinearly || changeSeriesByPoint) {
      setScheduleSeriesTouchedTimestamps((prev) => {
        const newTimestamps = reset ? timestamps : removeDuplicates(prev.concat(timestamps))
        const filteredTimestamps = newTimestamps.filter((t) => t >= getLeftEdgeTimestamp)
        return filteredTimestamps
      })
    }
  }

  /**
   * Update the series and save in local storage because this will be used when submitting the series
   * @param pointData
   * @param valueForCalculation is used when the series is updated with arrow keys
   * @param arrow
   */
  const handleUpdateScheduleSeries = (pointData, valueForCalculation?: number, arrow?: number) => {
    // Save "useSource" as false since user changed the series
    if (scheduleInputSeriesData?.useSource) {
      saveCreateScheduleInputMutation({ ...scheduleInputSeriesData, useSource: false })
    }
    // Save series changed to true
    if (!seriesChangedData?.value) {
      saveScheduleSeriesChangedMutation({ value: true })
    }

    const series = debouncedScheduleSeriesSet[0]

    const updatedSeries = updateScheduleSeriesPoints({
      sourcePoint: pointData,
      seriesData: [...series?.data] || [],
      minValue: series?.dragDrop?.dragMinY,
      maxValue: series?.dragDrop?.dragMaxY,
      arrow,
      valueForCalculation,
      selectedTimePeriod: selectedSchedulePeriodToHighlight,
      touchedTimestamps: scheduleSeriesTouchedTimestamps,
      seriesChangeMode: changeSeriesMode,
      scheduleSeriesIntervalInMinutes: scheduleSeriesIntervalInMinutes,
      seriesFirstPointTimestampFromLeftEdge: seriesFirstPointTimestampFromLeftEdge,
    })

    series['data'] = updatedSeries
    setUpdatedScheduleSeries([series])

    const pointTimestamp = pointData?.target?.category
    // console.log('move point')
    handleUpdateScheduleSeriesTouchedTimestamps([pointTimestamp])

    // Storing data in local storage and then access when submitting
    localStorage.setItem(ScheduleLocalStorageKeys.outPutSeries, JSON.stringify(series))
    localStorage.setItem(ScheduleLocalStorageKeys.seriesChanged, 'true')
  }

  /**
   * Set the initial touched points from workspace draft if there are any points saved in draft
   */
  const checkTouchedPointsInWorkspaceDraft = useRef(false)
  useEffect(() => {
    if (
      changeSeriesLinearly &&
      !checkTouchedPointsInWorkspaceDraft.current &&
      !workspaceDraftLoading &&
      Object.keys(workspaceDraftResult || {}).length &&
      createScheduleActive &&
      getLeftEdgeTimestamp
    ) {
      // console.log('touchedPointsFromWorkspaceDraft =', touchedPointsFromWorkspaceDraft)
      if (touchedPointsFromWorkspaceDraft.length) {
        handleUpdateScheduleSeriesTouchedTimestamps(touchedPointsFromWorkspaceDraft)
      }
      checkTouchedPointsInWorkspaceDraft.current = true
    }
  }, [
    changeSeriesLinearly,
    workspaceDraftResult,
    touchedPointsFromWorkspaceDraft,
    workspaceDraftLoading,
    workspaceDraftResult,
    createScheduleActive,
    getLeftEdgeTimestamp,
  ])

  /**
   * Save the touched points into the workspace draft
   */
  useEffect(() => {
    if (checkTouchedPointsInWorkspaceDraft.current) {
      const draft: DeepPartial<WorkspaceConfig> = {
        schedule: {
          touchedPoints: scheduleSeriesTouchedTimestamps,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    }
  }, [JSON.stringify(scheduleSeriesTouchedTimestamps)])

  /**
   * Saves in the draft , if there is selected period to highlight
   */
  useEffect(() => {
    const draft: DeepPartial<WorkspaceConfig> = {
      schedule: {
        hasSelectedSchedulePeriodToHighlight: {
          start: selectedSchedulePeriodToHighlight.start,
          end: selectedSchedulePeriodToHighlight.end,
          event: {},
        },
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
  }, [selectedSchedulePeriodToHighlight])

  /**
   * @param event
   * @description This function handle zone selection inside a chart.
   * When return false it will disable zoom
   */
  const handleSchedulePeriodSelection = (event) => {
    // Selection must be at least greater than one time block
    if (
      Math.abs(differenceInMilliseconds(new Date(event.xAxis[0].min), new Date(event.xAxis[0].max))) <
      event.xAxis[0].axis.tickInterval / 2
    ) {
      return false
    }

    if (event.xAxis && scheduleEditModeChartOptionSelect && createScheduleActive) {
      const data = {
        start: new Date(event.xAxis[0].min),
        end: new Date(event.xAxis[0].max),
        event: event,
      }

      // If a period is selected there is no use of point mode so we set to Rubber band/linear mode
      if (changeSeriesByPoint) {
        const draft: DeepPartial<WorkspaceConfig> = {
          schedule: {
            changeSeriesMode: ScheduleSeriesUpdateModes.linearly,
          },
        }
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
      setSelectedSchedulePeriodToHighlight(data)
      return false
    }
    return true
  }

  /**
   * When series is reverted to source we need to remove the touched points
   */
  useEffect(() => {
    if (scheduleInputSeriesData?.useSource || scheduleInputSeriesData?.savedInUserSetting) {
      handleUpdateScheduleSeriesTouchedTimestamps([], true)
    }
  }, [scheduleInputSeriesData?.useSource])

  /**
   * Remove the selectedSchedulePeriodToHighlight if schedule is not active
   */
  useEffect(() => {
    if (selectedSchedulePeriodToHighlight?.start && selectedSchedulePeriodToHighlight?.end && !createScheduleActive) {
      setSelectedSchedulePeriodToHighlight({})
    }
  }, [createScheduleActive, selectedSchedulePeriodToHighlight])

  /**
   * Selected/Highlighted schedule period
   * Also add and remove colours for the points which are touched/dragged in linear mode
   */
  const getSchedulePlotBandsHandler = () => {
    if (createScheduleActive) {
      const series = debouncedScheduleSeriesSet[0]
      const scheduleSeriesFromChart = chart?.series.find((chartSeries) => {
        return series?.name === chartSeries?.name
      })

      // Add marker for touched points
      scheduleSeriesFromChart?.points
        ?.filter((point) => scheduleSeriesTouchedTimestamps.includes(point.x) || point?.marker?.enabled)
        .forEach((tp) => {
          if (!scheduleSeriesTouchedTimestamps.includes(tp.x) && tp?.marker?.enabled) {
            tp.update({ marker: { symbol: 'diamond', enabled: false, radius: 5 } })
          } else if (scheduleSeriesTouchedTimestamps.includes(tp.x)) {
            tp.update({
              marker: {
                symbol: 'diamond',
                enabled: true,
                radius: 5,
              },
            })
          }
        })

      if (
        selectedSchedulePeriodToHighlight?.start &&
        selectedSchedulePeriodToHighlight?.end &&
        selectedSchedulePeriodToHighlight?.event
      ) {
        return createSchedulePlotBand({
          startDate: selectedSchedulePeriodToHighlight.start,
          endDate: selectedSchedulePeriodToHighlight.end,
        })
      } else {
        return []
      }
    } else {
      // Remove the Schedule highlight period and also mark all points to Schedule series colour
      const series = debouncedScheduleSeriesSet[0]
      const scheduleSeriesFromChart =
        chart?.series.find((serie) => {
          if (series?.name && serie?.name) {
            return series.name === serie.name
          } else return false
        }) || []

      if (scheduleSeriesFromChart) {
        scheduleSeriesFromChart?.points
          ?.filter((point) => point.marker?.enabled)
          .forEach((tp) => tp.update({ marker: { symbol: 'diamond', enabled: false, radius: 4 } }))
      }
      return []
    }
  }
  // Listener function for EventListener must be useCallback , because otherwise a new listener is created in every render
  const removeScheduleSelectionEventListener = useCallback((event) => {
    if (event.keyCode === keyboardKeyCode.escape) {
      setSelectedSchedulePeriodToHighlight({})
    }
  }, [])

  const removeScheduleSelection = () => {
    setSelectedSchedulePeriodToHighlight({})
  }

  // this function is used to disable point movement/selection if there is a selection , and this point is outside the selection
  const disablePointMovement = (selectedSchedulePeriodToHighlight: HighlightedSchedulePeriod, point: number) => {
    if (Object.keys(selectedSchedulePeriodToHighlight).length > 0 && point) {
      if (
        point < selectedSchedulePeriodToHighlight.start.getTime() ||
        point > selectedSchedulePeriodToHighlight.end.getTime()
      ) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (createScheduleActive) {
      document.addEventListener('keydown', removeScheduleSelectionEventListener, false)
    } else {
      document.removeEventListener('keydown', removeScheduleSelectionEventListener, false)
      const hasClickedPoint = getScheduleSeriesClickedPointFromStorage()
      if (hasClickedPoint) {
        removeScheduleSeriesClickedPointFromStorage()
      }

      // Todo we don't need to update state when you initially load data stream chart
      // following function updates the state
      removeScheduleSelection()
    }
  }, [createScheduleActive])

  // ----------------------- Schedule series code end here -----------------------

  const chartSeriesSet = useChartSeriesSet()

  // actual data that is plotted
  const [debouncedSeriesSet] = useDebounce(chartSeriesSet, 30, { leading: true })
  // if (debouncedSeriesSet.length) {
  //   console.log({ debouncedSeriesSet })
  // }

  useEffect(() => {
    dispatch({ type: SET_SERIES, series: debouncedSeriesSet })
  }, [debouncedSeriesSet])

  const hasData =
    debouncedSeriesSet.length > 0 &&
    debouncedSeriesSet.some((series) => Array.isArray(series.data) && series.data.length > 0)

  const dateRangeInDays = useMemo(() => differenceInCalendarDays(new Date(range[1]), new Date(range[0])), [range])

  // Chart plot lines
  const getPlotLines = () => {
    const plotLines: any[] = []

    if (createScheduleActive && Object.keys(revisionTimingInfo.data).length > 0) {
      const { windowStartTimes, windowLength, timeBlockLength, forecastOffset } = revisionTimingInfo.data

      // 1. Show Start and End Times (Blue lines)
      const windowStartAndEndTimesTimestampArray = getWindowStartAndEndTimesTimestamp(
        windowStartTimes,
        windowLength,
        timezone,
      )

      windowStartAndEndTimesTimestampArray.forEach((timestamp) => {
        plotLines.push(createScheduleTimingPlotLine(timestamp, setRevisionTimingInfo, revisionTimingInfo, showTooltip))
      })

      // 2. Show Today line without rounding
      plotLines.push(createTodayLine(createScheduleActive, setRevisionTimingInfo, revisionTimingInfo, showTooltip))

      // 3. Show Forecast offset line , which is today line , (where minutes are rounded in timeBlockLength) + forecastOffset
      plotLines.push(
        createTodayLine(
          createScheduleActive,
          setRevisionTimingInfo,
          revisionTimingInfo,
          showTooltip,
          timeBlockLength,
          forecastOffset,
        ),
      )
    } else if (showDayIndicators) {
      // If createScheduleActive is false and showDayIndicators is true, push todayPlotLine
      plotLines.push(createTodayLine(createScheduleActive))
    }

    return plotLines
  }

  // This useEffect is used to move dynamically the plot lines
  useEffect(() => {
    const handlerFunction = () => {
      const plotLines = getPlotLines()
      setChartPlotLines(plotLines)
    }

    // Called for initial draw
    handlerFunction()

    let intervalId: NodeJS.Timeout
    let timeoutId: NodeJS.Timeout

    if (createScheduleActive) {
      // now date
      const date = new Date()
      // Here we are handling reDraw every minutes that passes from real time
      timeoutId = setTimeout(function () {
        intervalId = setInterval(handlerFunction, 60000)
        handlerFunction()
      }, (60 - date.getSeconds()) * 1000)
    }

    // Clear timeout and interval when schedule is not visible anymore
    if (!createScheduleActive) {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      if (intervalId) {
        clearInterval(intervalId)
      }
    }

    // Clear timeout and interval on component unmount
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [showDayIndicators, createScheduleActive, revisionTimingInfo])

  const dayPlotBands = useMemo(
    () =>
      showDayIndicators && chartSettings?.showDayPlotBands
        ? createDayPlotBands(new Date(), dateRangeInDays, timezone)
        : [],
    [dateRangeInDays, timezone, getDay(new Date()), chartSettings],
  )

  const availabilityPlotBands = useMemo(
    () => (chartSettings?.showAvailabilityPlotBands ? createAvailabilityPlotbands(availability) : []),
    [availability, chartSettings],
  )

  const excludedScheduleTimingPlotBand = useMemo(() => {
    const excludedPlotBand = []

    if (createScheduleActive) {
      if (Object.keys(revisionTimingInfo.data).length > 0) {
        const { timeBlockLength, forecastOffset } = revisionTimingInfo.data

        const date = addMinutes(new Date(), forecastOffset)

        excludedPlotBand.push(
          basicSchedulePlotBand(chartWholeDateRange[0], getTodayLineValue(date, timeBlockLength), true),
        )
      } else {
        excludedPlotBand.push(basicSchedulePlotBand(chartWholeDateRange[0], getTodayLineValue(new Date()), true))
      }
    }

    return excludedPlotBand
  }, [createScheduleActive, chartWholeDateRange, chartPlotLines, revisionTimingInfo])

  const schedulePlotBands = getSchedulePlotBandsHandler()
  const plotBands: XAxisPlotBandsOptions[] = useMemo(() => {
    return [...dayPlotBands, ...availabilityPlotBands, ...excludedScheduleTimingPlotBand, ...schedulePlotBands]
  }, [showDayIndicators, dayPlotBands, availabilityPlotBands, schedulePlotBands, excludedScheduleTimingPlotBand])

  const togglePlotbandVisibility = useCallback((axis: Axis) => {
    axis.plotLinesAndBands.forEach((plotband) => {
      if (!plotband.label) return

      const { plotLeft, plotWidth } = axis.chart
      const from = Math.max(axis.toPixels(plotband.options.from), plotLeft)
      const to = Math.min(axis.toPixels(plotband.options.to), plotLeft + plotWidth)

      const plotbandWidth = to - from
      const show = plotband.label.getBBox().width < plotbandWidth

      plotband.label.css({ opacity: Number(show) })
    })
  }, [])

  const handleChartMounted = useCallback(
    (c) => {
      setChart(c)
      if (Array.isArray(c.xAxis) && c.xAxis.length > 0) {
        togglePlotbandVisibility(c.xAxis[0])
      }
    },
    [togglePlotbandVisibility],
  )

  const handleRangeSelect = useCallback(
    (range: DateRange) => {
      // Calculate the distance between start and end dates of chart whole range and selected range
      // so that we can add and sub them the same distances for relative dates ex(Last 7 days)
      const selectedRange: ChartRangeSelectionOffset = [
        differenceInMilliseconds(range[0], chartWholeDateRange[0]),
        differenceInMilliseconds(chartWholeDateRange[1], range[1]),
      ]
      const draft: DeepPartial<WorkspaceConfig> = {
        chart: {
          range: selectedRange,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    },
    [chartWholeDateRange],
  )

  // Need to modify the start only if the following condition satisfies and that should be start of chartWholeDateRange
  // When using navigator for zoom and when dragged to the start of the navigator it misses 15min that is why we modify
  useEffect(() => {
    if (chartSelectedRange.length && chartWholeDateRange.length) {
      const modifyStart =
        isSameDay(chartSelectedRange[0], chartWholeDateRange[0]) &&
        isSameHour(chartSelectedRange[0], chartWholeDateRange[0]) &&
        differenceInMinutes(chartSelectedRange[0], chartWholeDateRange[0]) == 15

      if (modifyStart) {
        const range = [chartWholeDateRange[0], chartSelectedRange[1]]
        handleRangeSelect(range)
      }
    }
  }, [chartSelectedRange, chartWholeDateRange])

  // handle range changes
  // Left and right side values of the highlighted area in the navigator below the chart
  const debouncedSetRange = useDebouncedCallback(
    (newRange: DateRange) => {
      // New range is in UTC and we add one second to end date that is why we remove here
      if (
        isEqual(new Date(currentRange[0]), convertUTCToLocalDate(new Date(newRange[0]))) &&
        isEqual(new Date(currentRange[1]), convertUTCToLocalDate(subSeconds(new Date(newRange[1]), 1)))
      )
        return

      // we postpone setting the new range after user is finished with dragging/panning/zooming
      // if we would update on each event, the chart performance would decrease significantly
      handleRangeSelect(newRange)
    },
    100,
    { leading: false },
  )

  // Left and right side values of the whole area in the navigator below the chart
  useEffect(() => {
    // we add a second here to show the expected date range
    // e.g. for selection of a day from 00:00 to 00:00 instead of 23:45 to 23:45
    const localRange: DateRange = [convertUTCToLocalDate(range[0]), convertUTCToLocalDate(range[1])]
    setCurrentRange(localRange)
  }, [range, timezone])

  useEffect(() => {
    if (!chart?.xAxis || chart.xAxis.length === 0) return
    // const currentExtremes = chart.xAxis[0].getExtremes()
    const timeoutExtremes = setTimeout(() => {
      const nextExtremes = [getTime(new Date(currentRange[0])), getTime(addSeconds(new Date(currentRange[1]), 1))]
      if (
        !nextExtremes ||
        !chart?.xAxis ||
        (Math.abs(differenceInMinutes(chart?.xAxis[0]?.min, nextExtremes[0])) <= 1 &&
          Math.abs(differenceInMinutes(chart?.xAxis[0]?.max, nextExtremes[1])) <= 1)
      ) {
        return
      }

      // we need the timeout so that setExtremes is overriding any automatic extremes adjustments by highcharts when new data is loaded
      // see this from 2012 (!!): https://www.highcharts.com/forum/viewtopic.php?t=15091
      if (chart?.xAxis[0]) {
        // reset selection when chart date range changes
        chart.xAxis[0].setExtremes(nextExtremes[0], nextExtremes[1], true, false)
      }
    }, 0)

    return () => {
      if (timeoutExtremes) {
        clearTimeout(timeoutExtremes)
      }
    }
  }, [chart, currentRange])

  // yAxis definition
  const yAxis: YAxisOptions[] = useMemo(() => {
    const createYAxisParams: CreateYAxis[] = []
    const units = removeDuplicates(debouncedSeriesSet?.filter((fs) => fs.custom?.unit).map((ms) => ms.custom?.unit))

    const seriesForEachUnit = (units || []).reduce((prevVal, currentVal) => {
      const currentUnitSeries = debouncedSeriesSet
        ?.filter((fs) => fs?.custom?.unit === currentVal)
        .map((ms) => ms?.data)
      return {
        ...prevVal,
        [currentVal]: currentUnitSeries.reduce((a, b) => a.concat(b), []),
      }
    }, {})

    const yAxisPartialDataForEachUnit = {}
    for (const [key, value] of Object.entries(seriesForEachUnit)) {
      yAxisPartialDataForEachUnit[key] = getYAxisData(value)
    }

    debouncedSeriesSet.forEach((series) => {
      if (
        series?.custom?.datastreamType !== TimeSeriesType.CAPACITY_DATA &&
        series?.custom?.datastreamType !== TimeSeriesType.WEATHER_DATA &&
        // we don't need to apply this logic for monthly price series
        isNotMonthlyMarketPriceTimeseries(series?.custom?.datastreamClassifier)
      ) {
        if (series.data && series.data.length > 0) {
          const artificialSegments: ArtificialPointSegment[] = []
          // since we are using step chart , point after null (null value makes gaps in chart) should be a segment no a single point
          // for that reason we are adding an artificial point after null values (should be only in ui , in this component , not in timeseries)
          // This artificial point is added after every null , if this null it's not the last point , and before null if this null it's last point , that's why we have this if (series.data[index + 1])
          series.data.forEach((point, index) => {
            if (point[1] === null || point[2] === null) {
              if (series.data[index + 1]) {
                const nextPoint = series.data[index + 1]

                // here we're preparing an array with value (points after null) that we need to push in series.data
                artificialSegments.push({
                  artificialPointIndex: index + 1,
                  artificialPoint: addPointForArtificialSegment(nextPoint, series.custom.detectedInterval),
                })
              } else {
                const prevPoint = series.data[index - 1]
                // Is last null
                artificialSegments.push({
                  artificialPointIndex: index,
                  artificialPoint: addPointForArtificialSegment(prevPoint, series.custom.detectedInterval, true),
                })
              }
            }
          })

          // after having this array , we are pushing elements in series.data but in a predefined index that's why is used splice.
          // we are using segment.artificialPointIndex + index as index , because every time we push an element in series.data , length of series.data changes
          if (artificialSegments.length > 0) {
            artificialSegments.forEach((segment, index) => {
              series.data?.splice(segment.artificialPointIndex + index, 0, segment.artificialPoint)
            })
          }

          // here we are adding an artificial point , if first point doesn't have null values , a normal case
          // so that the two logics (above one which checks null , and this one) do not collide with each other , we are checking if low (series.data[0][1]) and high series.data[0][2] of first point is number
          if (isNumeric(series.data[0][1]) || isNumeric(series.data[0][2])) {
            series.data.unshift(addPointForArtificialSegment(series.data[0], series.custom.detectedInterval))
          }
        }
      }

      const paramData = series.custom?.paramData
      const unit = series.custom?.unit
      if (unit) {
        createYAxisParams.push({
          paramData,
          unit,
          yAxisPartialData: yAxisPartialDataForEachUnit[unit],
          hasData: series?.data.length > 0,
        })
      }
    })

    const yAxes = getUniqueUnitYAxisParams(createYAxisParams)
    const transformedYAxes = alignYAxes(yAxes)
    return transformedYAxes
  }, [debouncedSeriesSet])

  // xAxis definition
  const xAxis: XAxisOptions = useMemo(() => {
    return {
      crosshair: true,
      labels: {
        rotation: -45,
      },
      // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
      // Attention: setting min and max values this can lead to fatal errors like this:
      //   "Uncaught TypeError: c.toPrecision is not a function"
      // Reason unknown
      // min: currentRange?.[0],
      // max: currentRange?.[1],
      // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
      minRange: 1000 * 60 * 60 * 2, // 2 hours
      minTickInterval: 1000 * 60 * 15, // 15 minutes
      ordinal: false,
      tickPixelInterval: 50,

      type: 'datetime',
      onSetExtremes: function (e) {
        // visible range of chart
        if (!isNumeric(e.min) || !isNumeric(e.max)) return
        // const min = subMinutes(convertLocalDateToUTC(e.min), 15)
        const min = convertLocalDateToUTC(e.min)
        const max = convertLocalDateToUTC(e.max)
        const newRange: DateRange = [min, max]
        debouncedSetRange(newRange)
      },
    }
  }, [chart, debouncedSetRange])

  // chart and plot options
  /**
   * IMPORTANT: This useEffect is used to update initialChartOption
   * For example: scheduleEditModeChartOptionSelect is used inside handleSchedulePeriodSelection ,
   * and in case you need the updated value of scheduleEditModeChartOptionSelect inside that function ,
   * you must add scheduleEditModeChartOptionSelect , in this useEffect dependency
   */
  useEffect(() => {
    if (chart) {
      chart.update()
    }
  }, [scheduleEditModeChartOptionSelect, createScheduleActive])

  // For advance usage of this function , please check the above useEffect
  const initialChartOptions = useMemo(() => {
    return {
      height: '100%',
      events: {
        selection: handleSchedulePeriodSelection,
      },
    }
  }, [handleSchedulePeriodSelection, changeSeriesLinearly, getLeftEdgeTimestamp])

  const plotOptions = useMemo(() => {
    return {
      area: {
        animation: false,
        lineWidth: 2,
        marker: { enabled: false },
        shadow: false,
      },
      series: {
        stickyTracking: !createScheduleActive,
        point: {
          events: {
            drop: function (e) {
              if (hasPermissionToCreateSchedule(user)) {
                if (!disablePointMovement(selectedSchedulePeriodToHighlight, e.target.category)) {
                  return false
                }
                handleUpdateScheduleSeries(e)
              }
              return false
            },
            drag: function (e) {
              if (hasPermissionToCreateSchedule(user)) {
                if (!disablePointMovement(selectedSchedulePeriodToHighlight, e.target.category)) {
                  return false
                }
              }
            },
            click: function (e) {
              if (e.point.series.name.toLowerCase().includes(scheduleDataMenuName)) {
                // disable point if it's outside the selection
                if (!disablePointMovement(selectedSchedulePeriodToHighlight, e.point.category)) {
                  return false
                }

                // Undo points with ctrl/command+click
                if (e.metaKey || e.ctrlKey) {
                  setScheduleSeriesTouchedTimestamps((prev) => {
                    return prev.filter((value) => value !== e.point.category)
                  })
                } else {
                  localStorage.setItem(
                    ScheduleLocalStorageKeys.pointTimestampClickedToMoveSeriesByArrowKeys,
                    e.point.category,
                  )
                  // Add the clicked point into the touched points
                  if (changeSeriesLinearly || changeSeriesByPoint) {
                    setScheduleSeriesTouchedTimestamps((prev) => {
                      return removeDuplicates([...prev, e.point.category])
                    })
                  }
                }
              }

              const handleManualSeriesWithKeyboardEventListener = (event) => {
                if (!localStorage.getItem(ScheduleLocalStorageKeys.pointTimestampClickedToMoveSeriesByArrowKeys)) {
                  controller.abort()
                  return false
                }
                if (event.keyCode === arrowUp || event.keyCode === arrowDown) {
                  const arrow = event.keyCode === arrowUp ? arrowUp : arrowDown
                  handleUpdateScheduleSeries({}, chart ? chart.yAxis[0].dataMax / 50 : 500, arrow)
                }
              }

              if (createScheduleActive) {
                // timeout after 1 min
                const timeoutSignal = AbortSignal.timeout(10000 * 6)
                const combinedSignal = AbortSignal.any([controller.signal, timeoutSignal])
                document.addEventListener('keydown', handleManualSeriesWithKeyboardEventListener, {
                  signal: combinedSignal,
                })
              }
            },
          },
        },
        animation: {
          duration: 100,
        },
        turboThreshold: 0,
        boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
        connectNulls: false,
        states: {
          inactive: {
            enabled: false, // do not fade out other series if one is hovered
          },
        },
      },
    }
  }, [
    handleUpdateScheduleSeries,
    user,
    createScheduleActive,
    changeSeriesLinearly,
    changeSeriesByPoint,
    getLeftEdgeTimestamp,
    selectedSchedulePeriodToHighlight,
  ])

  // handle size changes
  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }

    // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
    // BEWARE the following lines lead to broken charts with lots of highcharts errors, we disable them for now (see PR-5694)
    // ---
    // NOTE: annoying highcharts bug
    // we need to re-adjust extremes, because chart will be cut off for resize events like removing the meta forecast panel at the bottom
    // fixes PR-5143 and PR-5238
    // const { dataMin, dataMax } = chart.xAxis[0]
    // if (!isNumeric(dataMin) || !isNumeric(dataMax) || dataMin === 0 || dataMin === dataMax) return
    // chart.xAxis[0].setExtremes(dataMin + 1, dataMax + 1, true, false)
    // chart.xAxis[0].setExtremes(dataMin, dataMax, true, false)
    // ☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠☠
  }, [chart, width, height, JSON.stringify(updatedScheduleSeries)])

  const emptySeries = createTimeSeriesWithNullValues(chartSelectedRange)
  const emptyChartYAxis: YAxisOptions = getEmptyChartYAxisData()

  const seriesWithData = useMemo(() => {
    return debouncedSeriesSet.filter((dss) => dss?.data?.length)
  }, [debouncedSeriesSet])

  const infoForHoveredPlotLine = (plotLineType: string) => {
    switch (plotLineType) {
      case TypesOfScheduleTimingLines.revisionWindow:
        return <span style={{ color: scheduleTimingColors.revisionWindow }}>{t`Schedule submission deadline`}</span>
      case TypesOfScheduleTimingLines.realTime:
        return <span style={{ color: scheduleTimingColors.realTime }}>{t`Current time`}</span>
      case TypesOfScheduleTimingLines.forecastOffset:
        return (
          <span
            style={{ color: scheduleTimingColors.forecastOffset }}
          >{t`Earliest permitted start of the next schedule revision`}</span>
        )
      default:
        return null
    }
  }

  return (
    <Content chartWidgetsSelected={selectedChartWidgets.length}>
      {revisionTimingInfo?.locationX && revisionTimingInfo?.locationY && showTooltip && (
        <div
          style={{
            position: 'absolute',
            left: `${revisionTimingInfo.locationX + 20}px`,
            top: `${revisionTimingInfo.locationY}px`,
            zIndex: 9999999,
          }}
        >
          {infoForHoveredPlotLine(revisionTimingInfo.hoveredPlotLine)}
        </div>
      )}

      {resizeListener}
      <HighchartsStockChart chart={initialChartOptions} plotOptions={plotOptions} callback={handleChartMounted}>
        <GenericOptions
          message={!loading && !hasData ? c('Workbench:Quality').t`Current selection provides no data.` : ''}
          showTooltip={showTooltip}
        />
        <ChartOptions />
        <LoadingOptions isLoading={loading} />
        {/*Actually Highcharts expects local dates, even though we get data in UTC from API we need
          to convert them to local dates (see timeSeriesToChartSeries.tsx file createChartSeries()) and then pass the user timezone to the highcharts.
          When timezone is passed Highcharts internally first convert the local dates to UTC and then
          convert to the timezone we pass to it and plots the data */}
        <TimeOptions seriesStartDate={chartSelectedRange[0]} timezone={timezone} />
        {showTooltip && <TooltipOptions timezone={timezone} />}

        <XAxis {...xAxis}>
          <XAxis.Title>{xAxis.title?.text}</XAxis.Title>
          {hasData && (plotBands || []).map((plotBand) => <PlotBand key={`plotband-${plotBand.id}`} {...plotBand} />)}

          {hasData &&
            (chartPlotLines || []).map((plotLine, index) => (
              <PlotLine key={`plotline-${index}-${plotLine.value}`} {...plotLine} />
            ))}
        </XAxis>

        {hasData ? (
          yAxis.map((y) => {
            return (
              <YAxis key={y.id} {...y}>
                <YAxis.Title>{y.title?.text}</YAxis.Title>
                {[...debouncedSeriesSet, ...updatedScheduleSeries]
                  .filter((series) => series.yAxis === y.id)
                  .map((series) => {
                    const dataStreamId =
                      series?.custom?.result?.data?.dataStreamId || series?.custom?.result?.error?.dataStreamId
                    const resultId = series?.custom?.result?.data?.id || series?.custom?.result?.error?.id
                    const seriesIdentifier = resultId?.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)
                      ? `${resultId}${dataStreamId}`
                      : series.name
                    return <Series key={seriesIdentifier} id={seriesIdentifier} {...series} />
                  })}
              </YAxis>
            )
          })
        ) : (
          // For empty chart
          <YAxis {...emptyChartYAxis}>
            <YAxis.Title>{emptyChartYAxis?.title?.text}</YAxis.Title>
            {emptySeries.map((series) => (
              <Series key={series.name} id={series.name} {...series} />
            ))}
          </YAxis>
        )}

        <ResetZoomButton />

        <Navigator>
          {seriesWithData.map((series) => {
            const resultId = series?.custom?.result?.data?.id || series?.custom?.result?.error?.id
            const seriesIdentifier = resultId?.includes(FORECAST_MODEL_FOR_BACK_CAST_ID) ? resultId : series.name
            return <Navigator.Series key={seriesIdentifier} seriesId={seriesIdentifier} />
          })}
        </Navigator>
      </HighchartsStockChart>
    </Content>
  )
}

const areEqual = (prevProps: DataStreamChartProps, nextProps: DataStreamChartProps) => {
  // do not render if we are showing asset details
  return nextProps.isHidden || prevProps === nextProps
}

// Chart.whyDidYouRender = true
export default React.memo(DataStreamChart, areEqual)
