import { LanguageKeys } from 'fixtures/header'
import { t } from 'ttag'

export enum AuthForms {
  login = 'login',
  register = 'register',
  forgotPassword = 'forgotPassword',
  activateUser = 'activateUser',
  resetPassword = 'resetPassword',
  resendConfirmation = 'resendConfirmation',
}

export const getSalutations = (langKey: keyof typeof LanguageKeys) => {
  const salutationEmpty = { id: '-', label: '-' }
  const salutationsEnglish = [
    { id: 'Ms.', label: 'Ms.' },
    { id: 'Mr.', label: 'Mr.' },
  ]
  const salutationsGerman = [
    { id: 'Frau', label: 'Frau' },
    { id: 'Herr', label: 'Herr' },
    { id: 'Dr.', label: 'Dr.' },
    { id: 'Frau Dr.', label: 'Frau Dr.' },
    { id: 'Herr Dr.', label: 'Herr Dr.' },
    { id: 'Prof.', label: 'Prof.' },
    { id: 'Frau Prof.', label: 'Frau Prof.' },
    { id: 'Herr Prof.', label: 'Herr Prof.' },
  ]

  if (langKey === LanguageKeys.english) {
    return [salutationEmpty, ...salutationsEnglish, ...salutationsGerman]
  }
  if (langKey === LanguageKeys.german) {
    return [salutationEmpty, ...salutationsGerman, ...salutationsEnglish]
  }
}

export const LeadSourceIds = {
  USER_CUSTOMER: 'User/Customer',
  FRIEND_COLLEAGUE_OTHER_USER: 'Friend/Colleague/Other user',
  WEB_SEARCH: 'Web search',
  SOCIAL_MEDIA: 'Social media',
  EVENT: 'Event',
  MAGAZINE_ASSOCIATION: 'Magazine/Association',
  OTHER: 'Other',
}

export const getLeadSource = () => {
  const {
    USER_CUSTOMER,
    FRIEND_COLLEAGUE_OTHER_USER,
    WEB_SEARCH,
    SOCIAL_MEDIA,
    EVENT,
    MAGAZINE_ASSOCIATION,
    OTHER,
  } = LeadSourceIds
  return [
    {
      id: USER_CUSTOMER,
      label: t`I am/was a user/customer`,
    },
    {
      id: FRIEND_COLLEAGUE_OTHER_USER,
      label: t`Referral from a friend/colleague/other user`,
    },
    {
      id: WEB_SEARCH,
      label: t`Search engine (e.g., Google)`,
    },
    {
      id: SOCIAL_MEDIA,
      label: t`Social media (e.g., LinkedIn)`,
    },
    {
      id: EVENT,
      label: t`Event (Exhibition, Conference, Webinar)`,
    },
    {
      id: MAGAZINE_ASSOCIATION,
      label: t`Magazine (news, article, advertisement) / Association (member list)`,
    },
    {
      id: OTHER,
      label: t`Other`,
    },
  ]
}

export const getPasswordStrength = (password: string) => {
  let strength = 0

  // Check for length
  if (password.length >= 25) {
    strength += 1
  }

  // Check for presence of a number, special character, and capital letter
  if (/\d/.test(password)) {
    strength += 1
  }
  // Check for presence of special characters
  if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
    strength += 1
  }
  // Check for presence of capital characters
  if (/[A-Z]/.test(password)) {
    strength += 1
  }

  // Check for presence of at least two different special characters and two different numbers
  const specialChars = password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)
  const numbers = password.match(/\d/g)
  if (specialChars && specialChars.length >= 2 && numbers && new Set(numbers).size >= 2) {
    strength += 1
  }

  // Check for duplicate combination of letters, if yes decrease strength
  if (/([a-zA-Z]{2,}).*\1/.test(password)) {
    strength -= 1
  }

  return strength < 0 ? 0 : strength > 4 ? 4 : strength
}

export enum PasswordLengthConstraints {
  min = 8,
  max = 100,
}
export enum basicTextLengthConstraints {
  min = 2,
  max = 50,
}
export enum LoginIdLengthConstraints {
  min = 3,
  max = 50,
}

export const loginAlreadyUsedMsg = 'login already in use'
export const emailAlreadyUsedMsg = 'e-mail address already in use'
