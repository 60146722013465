import {
  useSiteForecastSaveMainItemKey,
  useSiteForecastSaveMainItemKeySaveMutation,
} from 'modules/dataStreams/api/dataStreamsUserSettings.api'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { ForecastConfig, ForecastSaveMainItem, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { RETABLE_ID_DATASTREAMS } from 'modules/reTable/reTable.types'
import React, { useCallback, useEffect, useMemo } from 'react'
import { AnyObject, FormSpy, useField } from 'react-final-form'
import { UseMutationResult } from 'react-query'
import { c, t } from 'ttag'
import ActionButton from 'ui/form/ActionButton'
import DetailsFormHeader from 'ui/form/DetailsFormHeader'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { dataStreamTypeQuery } from 'utils/dataStream'
import {
  getDataStreamDetailsQueryObj,
  getManageDataStreamQueryObj,
  QUERY_DATA_STREAM_ID,
  QUERY_NEW_SCHEDULE,
  QUERY_NEW_SITE_FORECAST,
  QUERY_NEW_SITE_FORECAST_TEMPLATE,
  useQueryMatch,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import { Error } from 'utils/request'
import { getNextPrevNavigationIndex } from 'utils/route'
import { FormHeaderContainer } from 'ui/form/form.style'
import {
  FormSaveOptions,
  getFormSaveMenuItems,
  getInvalidFieldsErrorMessagesForCertainForm,
  siteForecastFormRequiredFields,
} from 'utils/form'
import { FormApi } from 'final-form'
import { SiteForecastFormPrimaryStyledChip } from 'modules/dataStreams/siteForecast/SectionForecastQualityEvaluationHeader'
import { Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'
import { theme } from 'themes/theme-light'

interface SectionHeaderForecastFormProps {
  form: FormApi
  isNewCustomConfig: boolean
  saveResult: UseMutationResult<ForecastConfig, Error>
  onCloseSlider: () => void
  enableSaveBtn: boolean
  isTemplate: boolean
  isDefaultDataStream: boolean
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
}

const requiredFieldsForAssetForm = siteForecastFormRequiredFields()

const SectionHeaderSiteForecast: React.FC<SectionHeaderForecastFormProps> = ({
  form,
  isNewCustomConfig,
  saveResult,
  onCloseSlider,
  enableSaveBtn,
  isTemplate,
  isDefaultDataStream,
  onFormSubmit,
}) => {
  const { [TimeSeriesType.SITE_FORECAST]: siteForecast } = dataStreamTypeQuery
  const { queryParams } = useQueryParams()
  const { updateQueryString } = useQueryString()
  const isConfigPrimary = useField('primary').input.value

  const forecastConfigs = useSiteForecastConfigs()
  const filteredIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_DATASTREAMS)
  const filteredConfigs = useMemo(() => {
    return (forecastConfigs.data || []).filter((config) => config.id && filteredIds.includes(config.id))
  }, [forecastConfigs.data, filteredIds])

  const currentPrimaryForecastConfig = useMemo(() => (forecastConfigs.data || []).find((fc) => fc?.primary), [
    forecastConfigs,
  ])
  const currentPrimaryForecastConfigName = useMemo(
    () => (currentPrimaryForecastConfig ? <b>{currentPrimaryForecastConfig?.name}</b> : <></>),
    [currentPrimaryForecastConfig],
  )

  const isNewForecast = useQueryMatch(QUERY_NEW_SITE_FORECAST)
  const isNewTemplate = useQueryMatch(QUERY_NEW_SITE_FORECAST_TEMPLATE)

  // save handling
  const saveMainItemKey = useSiteForecastSaveMainItemKey()
  const {
    mutate: saveMainMutation,
    isLoading: savingMenuItemLoading,
    isSuccess: saveMenuItemSuccess,
    reset,
  } = useSiteForecastSaveMainItemKeySaveMutation()

  const saveMenuItems = useMemo(
    () =>
      getFormSaveMenuItems().filter((item) => {
        if (isNewForecast) {
          return item.key !== FormSaveOptions.SAVE_AND_NEXT
        } else {
          if (form.getState().values.maximumHorizon) {
            return item.key !== FormSaveOptions.SAVE_AND_NEW && item.key !== FormSaveOptions.CREATE_COPY
          }
          return item.key !== FormSaveOptions.SAVE_AND_NEW
        }
      }),
    [isNewForecast, JSON.stringify(form.getState().values)],
  )

  const savedMainItem = useMemo(() => {
    const mainItem = isNewForecast ? saveMainItemKey.data?.createForecast : saveMainItemKey.data?.updateForecast
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNewForecast, saveMainItemKey.data])

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      const { SAVE_AND_CLOSE } = FormSaveOptions
      if (savedMainItem !== item.key) {
        const data: ForecastSaveMainItem = {
          createForecast: isNewForecast ? item.key : saveMainItemKey.data?.createForecast || SAVE_AND_CLOSE,
          updateForecast: !isNewForecast ? item.key : saveMainItemKey.data?.updateForecast || SAVE_AND_CLOSE,
        }
        saveMainMutation(data)
      }
    },
    [saveMainItemKey, saveMainMutation, isNewForecast, savedMainItem],
  )

  useEffect(() => {
    if (saveMenuItemSuccess) {
      onFormSubmit()
      reset()
    }
  }, [saveMenuItemSuccess])

  // prev, next handling
  const handleNextPrevForecast = useCallback(
    (nextItem: boolean) => {
      const currentDisplayedId = queryParams[QUERY_DATA_STREAM_ID]
      const newIndex = getNextPrevNavigationIndex(filteredConfigs, currentDisplayedId, nextItem)
      if (newIndex != undefined) {
        const queryData = {
          ...getManageDataStreamQueryObj(siteForecast),
          ...getDataStreamDetailsQueryObj(filteredConfigs[newIndex].id),
          [QUERY_NEW_SITE_FORECAST]: undefined,
          [QUERY_NEW_SCHEDULE]: undefined,
        }
        updateQueryString(queryData)
      }
    },
    [queryParams, updateQueryString, filteredConfigs],
  )

  const headerTitle = useMemo(() => {
    if (isTemplate) {
      return isNewTemplate
        ? c('Site Forecast:Details').t`New template for forecast configuration`
        : c('Site Forecast:Details').t`Edit template for forecast configuration`
    } else {
      return isNewForecast
        ? c('Site Forecast:Details').t`New site forecast configuration`
        : c('Site Forecast:Details').t`Site forecast configuration details`
    }
  }, [isNewForecast, isTemplate, isNewTemplate])

  const handleSetSiteForecastConfigAsPrimary = () => {
    form.mutators.setValue('primary', !isConfigPrimary)
  }

  const primaryField = (
    <Box width="8em" display="flex" alignItems="center">
      {isConfigPrimary ? (
        <SiteForecastFormPrimaryStyledChip
          onClick={() => handleSetSiteForecastConfigAsPrimary()}
          label={c('Site Forecast:Quality Evaluation').t`Primary`}
          size="small"
          color="primary"
        />
      ) : (
        <SiteForecastFormPrimaryStyledChip
          onClick={() => handleSetSiteForecastConfigAsPrimary()}
          variant={'outlined'}
          label={c('Site Forecast:Quality Evaluation').t`Set as primary`}
          size="small"
          color="primary"
        />
      )}
      <PopperTooltip
        popperLabel={
          <Box ml={1}>
            <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
          </Box>
        }
        popperContent={
          <div>
            <div>
              {c('Site Forecast:')
                .t`If primary is set, only the evaluations from the primary configuration are considered for automatic training. If no primary is set and there is an ambiguous training result from multiple configurations a model update has to be made manual.`}
            </div>
            {currentPrimaryForecastConfig && (
              <Box mt={1}>{c('Site Forecast:')
                .jt`Current primary configuration: ${currentPrimaryForecastConfigName}`}</Box>
            )}
          </div>
        }
      />
    </Box>
  )

  return (
    <FormHeaderContainer>
      <DetailsFormHeader
        showNextPrevOptions={!isNewForecast && !isNewTemplate}
        handleGoToNextPrevItem={handleNextPrevForecast}
        totalItems={filteredConfigs.length}
        onCloseDetailsForm={onCloseSlider}
        fieldName="name"
        placeholder="Forecast name"
        disabled={Boolean(isNewCustomConfig) || isDefaultDataStream}
        headerTitle={headerTitle}
        titleFieldClassName="appTour-createSiteForecast-name"
        requiredField={true}
        extraFormComponents={primaryField}
      >
        {/*Pass save button as children*/}
        <FormSpy subscription={{ invalid: true, touched: true, modifiedSinceLastSubmit: true }}>
          {(props) => {
            return (
              <ActionButton
                invalidFieldsErrorMessages={getInvalidFieldsErrorMessagesForCertainForm(
                  form as FormApi,
                  requiredFieldsForAssetForm,
                )}
                className="appTour-createSiteForecast-save"
                result={saveResult}
                formInvalid={props.invalid}
                // formTouched={Object.keys(props.touched || {}).some((key) => props.touched && props.touched[key])}
                formModifiedSinceLastSubmit={props.modifiedSinceLastSubmit}
                disabled={!enableSaveBtn || savingMenuItemLoading || saveMainItemKey.isLoading}
                menuItems={saveMenuItems}
                mainItemKey={savedMainItem}
                onSelectMenuItem={handleSaveMenuSelect}
                icon="save"
                variant="contained"
                color="primary"
                size="small"
                saveOptionLoading={savingMenuItemLoading}
              >
                {t`Save`}
              </ActionButton>
            )
          }}
        </FormSpy>
      </DetailsFormHeader>
    </FormHeaderContainer>
  )
}

export default SectionHeaderSiteForecast
