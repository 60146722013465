import axios from 'axios'
import { useQuery } from 'react-query'

import { apiRequest, useOptimisticMutation } from 'utils/request'

/**
 * Default account name for demo data importing
 **/
// 1. DEV environment account username, its already defined
export const MASTER_DEMO_ACCOUNT = 'master-demouser'

// Query keys for caching data

export const QUERY_KEY_USER_SETTINGS = 'auth:userSettings'
export const KEY_ADMIN_SETTINGS = 'adminSettings'
export const KEY_USER_COMPANY_LOGO = 'userCompanyLogo'
export const KEY_SHOW_MODELS_IN_ASSET_TREE = 'showModelsInAssetTree'
export const QUERY_KEY_E3_OPTIMIZATION_SAVEMAINITEMKEY = 'e3Widget:saveMainItemKey'
export const KEY_SHOW_IN_ACTIVE_PENALTIES = 'showInActivePenalties'

// Async API requests to fetch and update data

interface GetUserSettingParams {
  key: string
}
const getUserSetting = async <TResult extends unknown>({ key }: GetUserSettingParams) => {
  return await apiRequest<TResult>(() => axios.get(`api/usersettings/${key}`))
}

interface SaveUserSettingParams<TResult> {
  key: string
  data: TResult
}
const saveUserSetting = async <TResult extends unknown>({ key, data }: SaveUserSettingParams<TResult>) => {
  const config = ['string', 'number'].includes(typeof data) ? { headers: { 'Content-Type': 'text/plain' } } : {}
  return await apiRequest<TResult>(() => axios.post(`api/usersettings/save/${key}`, data, config))
}

// Hooks to fetch and update via react-query

export const useUserSetting = <TResult extends unknown>(key: string) => {
  return useQuery<TResult>([QUERY_KEY_USER_SETTINGS, key], () => getUserSetting<TResult>({ key }))
}

export const useUserSettingSaveMutation = <TResult extends unknown>(key: string) => {
  return useOptimisticMutation<TResult, TResult, TResult>({
    queryCacheKey: [QUERY_KEY_USER_SETTINGS, key],
    apiMutator: (data: TResult) => saveUserSetting({ key, data }),
  })
}

// Used to add demo account setup to a certain user
export const addDemoData = async (masterDemoAccount: string) => {
  return await apiRequest(() => axios.post(`/api/demo/v1/copy/from/${masterDemoAccount}`))
}

// Used to remove demo account setup from a certain user
export const deleteDemoData = async (masterDemoAccount: string) => {
  return await apiRequest(() => axios.delete(`/api/demo/v1/copy/from/${masterDemoAccount}`))
}

// Used to check if we are using demo account setup for a certain user
export const getDemoData = async (masterDemoAccount: string) => {
  return await apiRequest(() => axios.get(`/api/demo/v1/copy/from/${masterDemoAccount}`))
}
