import React, { useCallback, useMemo } from 'react'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Column, RETABLE_ID_USER_MANAGEMENT, ReTableId, ReTableItem } from 'modules/reTable/reTable.types'
import { Box, Button } from '@material-ui/core'
import { c } from 'ttag'
import { math } from 'polished'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import { TableHeaderActionCellWidth } from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableGenericToolbarContainer } from 'ui/styles/table'
import Flex from 'ui/styles/Flex'
import ReTablePagination, { PaginationIndex } from 'utils/ReTablePagination'
import { TableCsvExport } from 'utils/table-csv-export'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isPartner, useIsReadOnlyUser } from 'utils/user'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import styled from 'styled-components'
import {
  DangerRoles,
  getAuthoritiesLabelHandler,
  getRoleFilterItems,
  getUserStatusFilterItems,
} from 'utils/userManagement'
import { RoleChip } from 'modules/userManagement/RoleCell'
import { UserManagementTableFilter } from 'modules/userManagement/UserManagementTable'

const FilterContainer = styled(Flex)`
  margin-top: -1em;
`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface UserManagementTableToolbarProps {
  columns: Column[]
  tableHeaderHasActions: boolean
  onAddUser: () => void
  totalItems: ReTableItem[]
  visibleItems: ReTableItem[]
  onPageChange: (paginationObj: PaginationIndex) => void
  onUpdateFilter: (data: UserManagementTableFilter | null) => void
  filterData: UserManagementTableFilter | null
  tableId: ReTableId
}

const UserManagementTableToolbar: React.FC<UserManagementTableToolbarProps> = ({
  columns,
  tableHeaderHasActions,
  onAddUser,
  totalItems,
  visibleItems,
  onPageChange,
  onUpdateFilter,
  filterData,
  tableId,
}) => {
  const user = useSelector(getUserResultSelector)
  const isReadOnlyUser = useIsReadOnlyUser()

  const columnsWidth = useMemo(() => {
    const width = (columns || []).reduce((sum, column) => {
      return math(`${sum} + ${column.width}`)
    }, '0')
    return math(
      `${visibleItems.length ? columns.length * 0.58 : 0} em + ${width} + ${editableRowActionsCellWidth} + ${
        tableHeaderHasActions ? TableHeaderActionCellWidth : 0
      }`,
    )
  }, [columns, visibleItems])

  const actions = useMemo(() => {
    return (
      <Flex alignItems="center">
        <ReTablePagination
          tableId={tableId}
          visibleItems={visibleItems}
          totalItems={totalItems}
          onPageChange={onPageChange}
        />

        {(isAdmin(user) || isPartner(user)) && (
          <Button onClick={() => TableCsvExport(totalItems, columns)} color="primary" size="small">
            {c('UserManagementTable').t`Export`}
          </Button>
        )}

        <Button onClick={onAddUser} color="primary" size="small" disabled={isReadOnlyUser}>
          {c('UserManagementTable').t`Add new user`}
        </Button>
      </Flex>
    )
  }, [onAddUser, visibleItems, totalItems, tableId, columns, user])

  const handleChangeFilterData = useCallback(
    (event, key) => {
      if (!key) {
        onUpdateFilter(null)
        return
      }

      // equivalent to event.target.value
      const {
        target: { value },
      } = event
      const data = {
        ...filterData,
        [key]: key === 'status' ? value : typeof value === 'string' ? value.split(',') : value,
      }
      const modifiedData = data

      if (key !== 'status') {
        const withRoles = data?.withRole || []
        const withOutRoles = data?.withoutRole || []
        if (key === 'withRole') {
          modifiedData['withoutRole'] = withOutRoles?.filter((role) => !withRoles.includes(role))
        } else if (key === 'withoutRole') {
          modifiedData['withRole'] = withRoles?.filter((role) => !withOutRoles.includes(role))
        }
      }

      onUpdateFilter(modifiedData)
    },

    [filterData],
  )

  const filters = useMemo(() => {
    return (
      <FilterContainer>
        <FormControl variant="standard" style={{ width: '10em' }}>
          <InputLabel id="filter-by-status-label">{c('UserManagement').t`Status`}</InputLabel>
          <Select
            labelId="filter-by-status-label"
            id="filter-by-status"
            value={filterData?.status ? filterData?.status : ''}
            onChange={(event) => handleChangeFilterData(event, 'status')}
            label={c('UserManagement').t`Status`}
          >
            {getUserStatusFilterItems().map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box ml={1}>
          <FormControl style={{ width: '15em' }} variant="standard">
            <InputLabel id="filter-with-role-label">{c('UserManagement').t`With Role`}</InputLabel>
            <Select
              labelId="filter-with-role-label"
              id="filter-with-role"
              multiple
              value={filterData?.withRole || []}
              onChange={(event) => handleChangeFilterData(event, 'withRole')}
              renderValue={(selected) => {
                return (
                  <Flex flexWrap="wrap">
                    {selected.map((item) => {
                      const isDangerRole = DangerRoles.includes(item)
                      return (
                        <RoleChip key={item} danger={isDangerRole ? 1 : 0}>
                          {getAuthoritiesLabelHandler(item)}
                        </RoleChip>
                      )
                    })}
                  </Flex>
                )
              }}
              MenuProps={MenuProps}
            >
              {getRoleFilterItems().map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  <Checkbox checked={(filterData?.withRole || []).indexOf(role?.value) > -1} />
                  <ListItemText primary={role.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box ml={1}>
          <FormControl style={{ width: '15em' }} variant="standard">
            <InputLabel id="filter-without-role-label">{c('UserManagement').t`Without Role`}</InputLabel>
            <Select
              labelId="filter-without-role-label"
              id="filter-without-role"
              multiple
              value={filterData?.withoutRole || []}
              onChange={(event) => handleChangeFilterData(event, 'withoutRole')}
              renderValue={(selected) => {
                return (
                  <Flex flexWrap="wrap">
                    {selected.map((item) => {
                      const isDangerRole = DangerRoles.includes(item)
                      return (
                        <RoleChip key={item} danger={isDangerRole ? 1 : 0}>
                          {getAuthoritiesLabelHandler(item)}
                        </RoleChip>
                      )
                    })}
                  </Flex>
                )
              }}
              MenuProps={MenuProps}
            >
              {getRoleFilterItems().map((role) => (
                <MenuItem key={role.value} value={role.value}>
                  <Checkbox checked={(filterData?.withoutRole || []).indexOf(role?.value) > -1} />
                  <ListItemText primary={role.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </FilterContainer>
    )
  }, [filterData])

  return (
    <ReTableGenericToolbarContainer flexGrow={1} width={columnsWidth}>
      <ReTableToolbar
        showSearchField={true}
        id={RETABLE_ID_USER_MANAGEMENT}
        actions={actions}
        filters={filters}
        showClearSorting={true}
        showClearFiltering={Boolean(filterData)}
        onClearFilter={() => handleChangeFilterData(null, null)}
      />
    </ReTableGenericToolbarContainer>
  )
}

export default React.memo(UserManagementTableToolbar)
