import { t } from 'ttag'

import { Asset } from 'modules/asset/store/asset.types'
import { getNomcap, isCluster } from 'utils/asset'
import {
  AssetMaintenanceDataAttributes,
  Availability,
  AvailabilityTableItem,
  AvailabilityType,
  CalculatedValueType,
} from 'modules/asset/availability/Availability.types'
import { formatNumber, getUnit, limitDecimals, thousandSeparator } from 'utils/dataFormatting'
import { convertUtcToZonedTime, convertZonedTimeToUtc } from 'utils/date'
import { Timezone } from 'fixtures/timezones'
import { FormApi } from 'final-form'
import { isBefore } from 'date-fns'
import { AppUnits } from 'utils/units'
import { AvailabilityTypes } from 'fixtures/assetMaintenance'

// type guards

export const isAvailability = (value: any): value is Availability => {
  return Boolean(value.siteId)
}

// helper methods

type MapAvailabilityToAssets = (assets: Asset[], availabilities: Availability[]) => AssetMaintenanceDataAttributes[]

export const mapAvailabilityToAssets: MapAvailabilityToAssets = (assets, availabilities) => {
  if (availabilities?.length > 0 && assets.length > 0) {
    const result = assets.map((asset) => {
      const data: AssetMaintenanceDataAttributes = {
        asset: asset,
        maintenanceList: availabilities.filter((a) => a.siteId === asset.id),
      }
      return data
    })
    return result
  } else {
    return []
  }
}

const formatCalculatedValue = (value: number) => {
  const formatedValue = limitDecimals(value, 2)
  return thousandSeparator(parseFloat(formatedValue))
}

export const calculateAvailabilityValue = (value: number | string, unit: string, asset: Asset) => {
  let result: CalculatedValueType = {} as CalculatedValueType
  if (!unit || !value) {
    result = { unit: '', data: '0' }
  } else {
    const nomCap = getNomcap(asset)
    result.unit = unit === 'PERCENT' ? AppUnits.KILO_WATT : AppUnits.PERCENTAGE
    switch (unit) {
      case 'WATT':
        result.data = formatCalculatedValue((100 * Number(value)) / (nomCap * 1000))
        break
      case 'KILOWATT':
        result.data = formatCalculatedValue((100 * Number(value)) / nomCap)
        break
      case 'MEGAWATT':
        result.data = formatCalculatedValue((100 * Number(value)) / (nomCap / 1000))
        break
      case 'PERCENT':
      default:
        result.data = formatCalculatedValue((nomCap * Number(value)) / 100)
        break
    }
  }
  return result
}

export const getAvailabilityTypeLabel = (type: AvailabilityType) => {
  switch (type) {
    case 'CAP':
      return t`Capped`
    case 'SCALE':
      return t`Scaled`
  }
}

export const getNewAvailabilityData = (asset: Asset, userTimezone) => {
  const newAvailabilityId = `${asset.id}-new-availability`
  return {
    id: newAvailabilityId,
    siteId: asset.id,
    isNewAvailability: true,
    from: new Date(),
    to: '',
    description: '',
    timeZone: userTimezone,
    uiAncestors: [asset.id],
    uiChildren: [],
    type: isCluster(asset) ? AvailabilityTypes.CAP : '',
  }
}

export const valueWithUnit = (availability: Availability) => {
  const { value, unit } = availability
  if (!value && !unit) return ''
  const availabilityValue = formatNumber({ data: value, showFractionalPart: false })
  const availabilityUnit = getUnit(unit)
  return availabilityValue + availabilityUnit
}

// normalization and transformation of data

export const enrichAvailabilityDataAfterLoad = (
  availability: Availability,
  allAssets: Asset[],
  userTimezone: Timezone,
) => {
  const now = new Date()
  const asset = allAssets.find((asset: Asset) => asset.id == availability.siteId)
  const enrichedAvailability = { ...availability }

  enrichedAvailability.from = userTimezone
    ? convertUtcToZonedTime(enrichedAvailability.from, userTimezone)
    : enrichedAvailability.from

  if (enrichedAvailability.to) {
    enrichedAvailability.to = userTimezone
      ? convertUtcToZonedTime(enrichedAvailability.to, userTimezone)
      : enrichedAvailability.to
  } else {
    enrichedAvailability.to = ''
  }

  enrichedAvailability.uiType = getAvailabilityTypeLabel(enrichedAvailability.type)
  enrichedAvailability.uiAssetName = asset?.name
  enrichedAvailability.uiValueUnit = valueWithUnit(enrichedAvailability)
  enrichedAvailability.uiIsPast = isBefore(new Date(enrichedAvailability.to), now)

  // hierarchy information
  const ancestors = [enrichedAvailability.siteId]
  enrichedAvailability.uiAncestors = ancestors
  enrichedAvailability.uiParents = ancestors
  enrichedAvailability.uiChildren = []
  enrichedAvailability.uiDescendants = []
  enrichedAvailability.uiLevel = 1

  return enrichedAvailability
}

export const sortAvailabilites = (availabilites: Availability[]): Availability[] => {
  const availabilitesToSort = [...availabilites]
  availabilitesToSort.sort((a, b) => {
    return isBefore(new Date(a.from), new Date(b.from)) ? 1 : -1
  })
  return availabilitesToSort
}

export const transformAvailabilitiesForReTable = (
  availabilities: Availability[],
  allAssets: Asset[],
  userTimezone: Timezone,
): AvailabilityTableItem[] => {
  const sortedAvailabilities = sortAvailabilites(availabilities)
  const availabilityTableItems = sortedAvailabilities.map((availability) => {
    return enrichAvailabilityDataAfterLoad(availability, allAssets, userTimezone)
  })
  return availabilityTableItems
}

export const prepareAvailabilityDataBeforeSave = (
  availability: Availability,
  formReference: FormApi<Availability>,
  userTimezone: Timezone,
) => {
  if (availability.isNewAvailability) {
    delete availability.id
    // delete availability.isNewAvailability
  }

  const timezoneToConvertFrom = userTimezone
  availability.timeZone = timezoneToConvertFrom

  if (availability.from) {
    const date = new Date(availability.from)
    date.setSeconds(0o0, 0o00)
    availability.from = convertZonedTimeToUtc(date, timezoneToConvertFrom)
  }
  if (availability.to) {
    const date = new Date(availability.to)
    date.setSeconds(0o0, 0o00)
    availability.to = convertZonedTimeToUtc(date, timezoneToConvertFrom)
  }

  delete availability.uiType
  delete availability.uiAssetName
  delete availability.uiValueUnit

  delete availability.uiAncestors
  delete availability.uiParents
  delete availability.uiChildren
  delete availability.uiDescendants
  delete availability.uiLevel

  return availability
}
