import createReducer from 'utils/createReducer'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import * as actionTypes from 'modules/data/fileManager/redux_store/fileManager.action.types'

interface State {
  result: Record<string, string>
  loading: Record<string, boolean>
  error: Record<string, string | null>
}

const initialState: State = {
  result: {},
  loading: {},
  error: {},
}

export interface DownloadFileAction {
  type: actionTypes.DOWNLOAD_FILE_ACTION_TYPE
  // REQUEST
  fileId: string
  // SUCCESS
  result: string
  // ERROR
  error: string | null
}

const result = createReducer<State['result'], DownloadFileAction>(
  (state = initialState.result, { type, result, fileId }) => {
    if (type === actionTypes.DOWNLOAD_FILE_SUCCESS) {
      state[fileId] = result
    }
    return state
  },
)

const loading = createReducer<State['loading'], DownloadFileAction>(
  (state = initialState.loading, { type, fileId }) => {
    switch (type) {
      case actionTypes.DOWNLOAD_FILE_REQUEST:
        state[fileId] = true
        break
      case actionTypes.DOWNLOAD_FILE_SUCCESS:
      case actionTypes.DOWNLOAD_FILE_FAILURE:
        state[fileId] = false
        break
    }
    return state
  },
)

const error = createReducer<State['error'], DownloadFileAction>(
  (state = initialState.error, { type, error, fileId }) => {
    switch (type) {
      case actionTypes.DOWNLOAD_FILE_SUCCESS:
      case actionTypes.DOWNLOAD_FILE_DISMISS:
        state[fileId] = null
        break
      case actionTypes.DOWNLOAD_FILE_FAILURE:
        state[fileId] = error
        break
    }
    return state
  },
)

export const downloadFileReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors
export const downloadFileResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.data.fileManager.download.result,
  (result) => result,
)

export const downloadFileLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.data.fileManager.download.loading,
  (loading) => loading,
)

export const downloadFileErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.data.fileManager.download.error,
  (error) => error,
)
