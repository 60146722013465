import React, { useCallback, useState } from 'react'
import { Checkbox, IconButton, Menu, MenuItem } from '@material-ui/core'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import { t } from 'ttag'
import { ChartSettings } from 'modules/workspace/store/workspace.types'

interface ChartSettingsMenuProps {
  showTooltip: boolean
  onTooltipChange: () => void
}

const ChartSettingsMenu: React.FC<ChartSettingsMenuProps> = ({ showTooltip, onTooltipChange }) => {
  const [settingsMenuEl, setSettingsMenuEl] = useState<null | HTMLElement>(null)
  const [tooltip]: (keyof ChartSettings)[] = ['showChartTooltip']

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setSettingsMenuEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setSettingsMenuEl(null)
  }, [])

  const onChangeSettings = useCallback(
    (type: keyof ChartSettings) => {
      if (type === tooltip) {
        onTooltipChange()
        handleClose()
      }
    },
    [onTooltipChange, handleClose],
  )

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleMenuClick}>
        <FontAwesomeActionIcon aria-controls="simple-menu" aria-haspopup="true" icon="cog" />
      </IconButton>

      <Menu
        id="line-chart-settings-menu"
        anchorEl={settingsMenuEl}
        keepMounted
        open={Boolean(settingsMenuEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => onChangeSettings(tooltip)}>
          <ListItemIcon>
            <Checkbox checked={showTooltip} color="primary" />
          </ListItemIcon>
          <ListItemText primary={t`Show tooltip`} />
        </MenuItem>
      </Menu>
    </>
  )
}

export default ChartSettingsMenu
