import React, { useMemo } from 'react'
import styled from 'styled-components'
import zxcvbn from 'zxcvbn'

const StrengthBarContainer = styled.div`
  height: 4px;
  margin-top: 2px;
  & .strength-bar {
    position: relative;
    height: 3px;
    background: #ddd;
    border-radius: 2px;
  }
  & .strength-bar:before,
  & .strength-bar:after {
    content: '';
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 6px 0;
    position: absolute;
    width: calc(18% + 6px);
    z-index: 10;
  }
  & .strength-bar:before {
    left: calc(18% - 3px);
  }
  & .strength-bar:after {
    right: calc(18% - 3px);
  }

  & .strength-meter-fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
  }
  & .strength-meter-fill[data-strength='0'] {
    width: 20%;
    background: darkred;
  }
  & .strength-meter-fill[data-strength='1'] {
    width: 40%;
    background: orangered;
  }
  & .strength-meter-fill[data-strength='2'] {
    width: 60%;
    background: orange;
  }
  & .strength-meter-fill[data-strength='3'] {
    width: 80%;
    background: yellowgreen;
  }
  & .strength-meter-fill[data-strength='4'] {
    width: 100%;
    background: green;
  }
`

interface PasswordStrengthBarProps {
  value: string
}

const PasswordStrengthBar: React.FC<PasswordStrengthBarProps> = ({ value }) => {
  const strengthClass = ['strength-bar mt-2', value.length > 0 ? 'visible' : 'invisible'].join(' ').trim()
  const strength = useMemo(() => {
    if (value.length) {
      // Browser hangs if we calculate the strength with more than 128 characters
      // return value.length > 128 ? 4 : getPasswordStrength(value)
      return value.length > 128 ? 4 : zxcvbn(value).score
    } else {
      return 0
    }
  }, [value])

  return (
    <StrengthBarContainer>
      {value && (
        <div className={strengthClass}>
          <div className="strength-meter-fill" data-strength={strength} />
        </div>
      )}
    </StrengthBarContainer>
  )
}

export default PasswordStrengthBar
