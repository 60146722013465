import React, { useRef } from 'react'
import { GoogleMap, GoogleMapProps, Marker } from '@react-google-maps/api'
// import { ClustererOptions } from '@react-google-maps/marker-clusterer'
import { getBounds, getCenter } from 'geolib'
import deepmerge from 'deepmerge'

import { DEFAULT_LAT, DEFAULT_LNG } from 'fixtures/assetForm'
import { Coordinate, LocationAsset } from 'modules/asset/store/asset.types'
import { defaultLocation } from 'utils/asset'

// import { getMarkerIcons } from 'utils/map'

interface GoogleLatLng {
  lat: number
  lng: number
}

const convertCoords = (coordinates: Coordinate): GoogleLatLng => {
  return {
    lat: coordinates.latitude || DEFAULT_LAT,
    lng: coordinates.longitude || DEFAULT_LNG,
  }
}

interface Bounds {
  minLat: number
  maxLat: number
  minLng: number
  maxLng: number
}

const convertBoundsToZoom = (bounds: Bounds) => {
  const GLOBE_WIDTH = 256 // a constant in Google's map projection
  const west = bounds.minLng
  const east = bounds.maxLng
  let angle = east - west
  if (angle < 0) {
    angle += 360
  }
  const pixelWidth = 512
  return Math.round(Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2) - 1
}

// const convertBoundsToZoom = (bounds: Bounds, mapDim) => {
//   const WORLD_DIM = { height: 256, width: 256 }
//   const ZOOM_MAX = 21
//
//   function latRad(lat) {
//     const sin = Math.sin((lat * Math.PI) / 180)
//     const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
//     return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
//   }
//
//   function zoom(mapPx, worldPx, fraction) {
//     return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
//   }
//
//   const ne = bounds.getNorthEast()
//   const sw = bounds.getSouthWest()
//
//   const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI
//
//   const lngDiff = ne.lng() - sw.lng()
//   const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360
//
//   const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
//   const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)
//
//   return Math.min(latZoom, lngZoom, ZOOM_MAX)
// }

export interface WeatherCoordinate {
  position: GoogleLatLng
  provider: string
}

interface MapProps {
  mapLocation?: Coordinate
  mapZoom?: number
  assets?: LocationAsset[]
  onDragMarker?: (args: any) => void
  options: google.maps.MapOptions
  weatherCoordinates?: any[]
  showWeatherCoords?: boolean
  labelColor: string
}

const GoogleMaps: React.FC<MapProps & GoogleMapProps> = ({
  mapLocation,
  mapZoom,
  assets = [],
  onDragMarker,
  options,
  // weatherCoordinates,
  // showWeatherCoords,
  // labelColor,
  ...rest
}) => {
  const refMap = useRef(null)
  let location: GoogleLatLng = { lat: 0, lng: 0 }
  let zoom = mapZoom || 6
  const showMultiple = assets && assets.length > 0

  if (!showMultiple && mapLocation && mapLocation.latitude && mapLocation.longitude) {
    location = convertCoords(mapLocation)
  } else if (showMultiple) {
    const coordinates = assets.map((asset) => asset.location.coordinate)
    const center = getCenter(coordinates)
    const bounds = getBounds(coordinates)
    if (center) {
      location = convertCoords(center)
      // show all items but do not zoom in too much
      zoom = Math.min(16, convertBoundsToZoom(bounds))
    }
  } else if (!location.lat || !location.lng) {
    location = defaultLocation()
  }

  const defaultOptions: google.maps.MapOptions = { gestureHandling: 'cooperative', streetViewControl: false }
  const mergedOptions: google.maps.MapOptions = deepmerge(defaultOptions, options || {})

  // const icons = useMemo(getMarkerIcons, [])
  //
  // const markerClustererOptions: ClustererOptions = {
  //   // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
  //   imageExtension: 'png',
  //   imagePath: '/public/map/clusterer',
  //   imageSizes: [40, 50, 60, 70, 80],
  //   gridSize: 40,
  // }

  return (
    <>
      <GoogleMap
        ref={refMap}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        center={location}
        zoom={zoom}
        tilt={0} // disable AUTOMATICALLY 45° imagery view , that's why is static.
        options={mergedOptions}
        {...rest}
      >
        {/*{showWeatherCoords &&*/}
        {/*  weatherCoordinates &&*/}
        {/*  weatherCoordinates.length > 0 &&*/}
        {/*  (weatherCoordinates || []).map((coord, index) => (*/}
        {/*    <Marker key={index} position={coord.position} title={coord.provider} />*/}
        {/*  ))}*/}

        {showMultiple ? (
          // <MarkerClusterer maxZoom={14} options={markerClustererOptions}>
          //   {(clusterer) =>
          //     assets.map((asset) => (
          //       <Marker
          //         key={asset.id}
          //         icon={icons[asset.type]}
          //         position={convertCoords(asset.location.coordinate)}
          //         title={asset.name}
          //         clusterer={clusterer}
          //       />
          //     ))
          //   }
          // </MarkerClusterer>
          assets.map((asset) => (
            <Marker
              key={asset.id}
              // icon={icons[asset.type]}
              position={convertCoords(asset.location.coordinate)}
              title={asset.name}
            />
          ))
        ) : (
          <Marker
            position={location}
            // icon={icons[assets.length > 0 ? assets[0].type : 'unknown']}
            draggable={Boolean(onDragMarker)}
            onDragEnd={onDragMarker}
          />
        )}
      </GoogleMap>
    </>
  )
}

// GoogleMaps.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
//
export default React.memo(GoogleMaps)
