import { useSelector } from 'react-redux'
import {
  workspaceDraftChartWidgetsSelector,
  workspaceDraftDataStreamSelectionSelector,
  workspaceDraftE3WidgetSettingsSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { ChartWidget } from 'modules/workspace/store/workspace.types'
import {
  hasPermissionForE3ModelLevelForecast,
  hasPermissionForMetaForecast,
  hasPermissionTo3rdPartyForecasts,
} from 'utils/user'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isMetaForecastDataStream } from 'utils/dataStream'

export const useMetaForecastData = () => {
  const user = useSelector(getUserResultSelector)
  const selectedChartWidgets = useSelector(workspaceDraftChartWidgetsSelector)
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)

  const hasAccessToMetaForecast = hasPermissionForMetaForecast(user)
  const hasAccessToE3WeatherModelForecast = hasPermissionForE3ModelLevelForecast(user)
  const hasAccessToThirdPartyForecast = hasPermissionTo3rdPartyForecasts(user)

  const metaForecastWidgetSelected = selectedChartWidgets.includes(ChartWidget.WIDGET_META_FORECAST)
  const metaForecastDataStreamSelected = selectedDataStreams.some((dataStream) => isMetaForecastDataStream(dataStream))

  const metaForecastWidgetData = useSelector(workspaceDraftE3WidgetSettingsSelector)

  return {
    hasAccessToMetaForecast,
    hasAccessToThirdPartyForecast,
    hasAccessToE3WeatherModelForecast,
    showMetaForecastWidget: hasAccessToMetaForecast && metaForecastWidgetSelected,
    metaForecastWidgetSelected,
    metaForecastDataStreamSelected,
    thirdPartySliders: metaForecastWidgetData.thirdPartySliders,
    weatherModelSliders: metaForecastWidgetData.weatherModelSliders,
    activeWidget: metaForecastWidgetData.activeWidget,
  }
}
