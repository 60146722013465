import React, { useCallback, useEffect, useState } from 'react'
import { EnrichedForecastModelItem } from 'utils/forecastModel'
import { hideForecastModel, QUERY_KEY_FORECAST_MODELS_BY_ASSETS } from 'modules/quality/quality.api'
import { useQueryClient } from 'react-query'
import { Asset } from 'modules/asset/store/asset.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { useDispatch } from 'react-redux'
import InlineLoading from 'ui/InlineLoading'
import { theme } from 'themes/theme-light'
import { useIsReadOnlyUser } from 'utils/user'
import { SAVE_WORKSPACE_DRAFT_SELECT_MODEL } from 'modules/workspace/store/workspace.types'

interface HideModelCheckBoxProps {
  forecastModel: EnrichedForecastModelItem
  asset: Asset
}

const HideModelCheckBox: React.FC<HideModelCheckBoxProps> = ({ forecastModel, asset }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const [hideModel, setHideModel] = useState(forecastModel?.hide || false)
  const [loading, setLoading] = useState(false)
  const isReadOnlyUser = useIsReadOnlyUser()

  const handleChangeHide = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      const hideModel = !forecastModel.hide
      setHideModel(hideModel)
      setLoading(true)
      hideForecastModel(forecastModel.id, hideModel)
        .then(() => {
          queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
          dispatch({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: [asset] })
          dispatch({
            type: SAVE_WORKSPACE_DRAFT_SELECT_MODEL,
            model: forecastModel,
            selectionOptions: { ctrlKey: true },
          })
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
        })
    },
    [forecastModel, asset],
  )

  useEffect(() => {
    setLoading(false)
  }, [forecastModel.hide])

  return (
    <>
      {loading ? (
        <InlineLoading />
      ) : (
        <div
          style={{
            color: theme.palette.primary.main,
            cursor: 'pointer',
            pointerEvents: isReadOnlyUser ? 'none' : 'inherit',
          }}
          onClick={handleChangeHide}
        >
          {hideModel ? 'Unhide' : 'Hide'}
        </div>
      )}
      {/*<StyledCheckBox color="primary" checked={hideModel} onChange={handleChangeHide} />*/}
    </>
  )
}

export default HideModelCheckBox
