import React, { useCallback } from 'react'
import { IconButton } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import Flex from 'ui/styles/Flex'
import {
  UserManagementTableActions,
  UserManagementTableContextMenuItem,
  UserManagementType,
  UserStatus,
} from 'modules/userManagement/userManagement.types'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import Highlight from 'ui/Highlight'
import SidebarRowItem from 'ui/elements/SidebarRowItem'
import { Column, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { math } from 'polished'
import { impersonateUser } from 'modules/user/api/user.api'
import ReTableContextMenu from 'modules/reTable/ReTableContextMenu'
import { t } from 'ttag'
import { TOGGLE_ADMIN_COMPONENT, useIsReadOnlyUser } from 'utils/user'

interface EnercastIdCellProps {
  user: UserManagementType
  hovered: boolean
  query: string
  currentlyDisplayed: boolean
  column: Column
  onEditItem: (user: UserManagementType) => void
  onClickMenuItem: (action: ReTableRowContextActions, user: UserManagementType) => void
}

const EnercastIdCell: React.FC<EnercastIdCellProps> = ({
  user,
  hovered,
  query,
  currentlyDisplayed,
  column,
  onEditItem,
  onClickMenuItem,
}) => {
  const isReadOnlyUser = useIsReadOnlyUser()
  const width = math(`${column.width} - 4em`)
  const actionMenuItems: UserManagementTableContextMenuItem[] = [
    {
      itemName: UserManagementTableActions.SYNCHRONIZE,
      itemLabel: t`Synchronize`,
      icon: 'sync',
    },
    {
      itemName: UserManagementTableActions.CURRENT_REVISION_TO_BASE,
      itemLabel: t`Current revision to base`,
      icon: 'level-down-alt',
    },
    {
      itemName: UserManagementTableActions.RESET_REVISION_TO_BASE,
      itemLabel: t`Reset revision to base`,
      icon: 'level-up-alt',
    },
    {
      itemName: UserManagementTableActions.EDIT_USER,
      itemLabel: t`Edit`,
      icon: 'pen',
    },
  ]

  const handleOnMenuItemClick = useCallback(
    (action: ReTableRowContextActions) => {
      onClickMenuItem(action, user)
    },
    [user],
  )

  const handleImpersonate = (user: UserManagementType) => {
    if (user.login) {
      impersonateUser(user.login).finally(() => {
        window.location.assign('/')

        // in every impersonation , we need to switch off the admin toggle
        sessionStorage.setItem(TOGGLE_ADMIN_COMPONENT, 'false')
      })
    }
  }

  return (
    <Flex direction="row" justifyContent="flex-start" alignItems="center">
      <SidebarRowItem
        lightMode={false}
        isVisible={hovered || currentlyDisplayed}
        showIcon={true}
        onToggleDetails={() => onEditItem(user)}
      >
        <FixedWidthTextContainer text={user.login} width={width || 'inherit'}>
          <Highlight text={user.login} query={query} />
        </FixedWidthTextContainer>
      </SidebarRowItem>
      <div style={{ width: '2em' }}>
        {(hovered || currentlyDisplayed) && (
          <IconButton
            disabled={isReadOnlyUser || user.uiUserStatus !== UserStatus.ACTIVATED}
            onClick={() => handleImpersonate(user)}
            size="small"
          >
            <FontAwesomeIcon
              icon="sign-in-alt"
              color={
                isReadOnlyUser
                  ? 'grey'
                  : user.uiUserStatus === UserStatus.ACTIVATED
                  ? theme.palette.primary.main
                  : 'grey'
              }
            />
          </IconButton>
        )}
      </div>

      <ReTableContextMenu menuItems={actionMenuItems} showContextMenu={true} onClickMenuItem={handleOnMenuItemClick} />
    </Flex>
  )
}

export default React.memo(EnercastIdCell)
