import React, { useMemo } from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'
import CenteredLoading from 'ui/CenteredLoading'
import ForecastModelsTable from 'modules/forecastModels/ForecastModelsTable'
// import TrainingResultsComparisonChart from 'modules/quality/TrainingResultsComparisonChart'
// import TrainingResultsHistoryChart from 'modules/quality/TrainingResultsHistoryChart'import { useEnrichedQualityEvaluations } from 'utils/hooks/useEnrichedQualityEvaluations'
import { Typography } from '@material-ui/core'
import { c } from 'ttag'
import Flex from 'ui/styles/Flex'
import { useEnrichedQualityEvaluations } from 'utils/hooks/useEnrichedQualityEvaluations'

interface ForecastModelsProps {
  asset: Asset
}

const ForecastModels: React.FC<ForecastModelsProps> = ({ asset }) => {
  const forecastModelsQueryResult = useForecastModelsByAsset(asset)
  const loading = useMemo(() => forecastModelsQueryResult.isLoading, [forecastModelsQueryResult.isLoading])

  // This is used only for editing the forecast models
  const forecastModelsWithoutTransformation = useMemo(() => forecastModelsQueryResult.data, [
    forecastModelsQueryResult?.data,
  ])

  const modelsAndQualityEvaluations = useEnrichedQualityEvaluations({ asset })

  return (
    <>
      {loading ? (
        <CenteredLoading size="2em" />
      ) : (
        <>
          {modelsAndQualityEvaluations && modelsAndQualityEvaluations.length ? (
            <div>
              <Flex direction="row">
                {/*<TrainingResultsComparisonChart evaluations={modelsAndEvaluations} />*/}
                {/*<TrainingResultsHistoryChart evaluations={modelsAndEvaluations} />*/}
              </Flex>
              <ForecastModelsTable
                forecastModelsWithoutTransformation={forecastModelsWithoutTransformation}
                modelsAndEvaluations={modelsAndQualityEvaluations}
                asset={asset}
              />
            </div>
          ) : (
            <Typography>{c('Training Results:Quality').t`No quality evaluation results available`}</Typography>
          )}
        </>
      )}
    </>
  )
}

export default React.memo(ForecastModels)
