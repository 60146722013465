import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Field, FormSpy } from 'react-final-form'
import { Box, MenuItem } from '@material-ui/core'
import { FormApi } from 'final-form'
import { t } from 'ttag'

import { defaultTrackerValues, getTrackerValues, TrackerNamesEnum } from 'fixtures/assetForm'
import { SolarAttributes, SolarGenerator, SolarPark } from 'modules/asset/store/asset.types'
import Flex from 'ui/styles/Flex'
import {
  AssetFormLabel,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  NumberFieldWithLabel,
  NumberFieldWithoutLabel,
  SelectFieldWithoutLabel,
  SubField,
} from 'ui/form/assetForm.style'
import { isGenerator, isPark, isSolarPark, isSolarPlant } from 'utils/asset'
import { Select } from 'final-form-material-ui'

interface ConfigurationSolarPlantProps {
  asset: SolarGenerator | SolarPark
  form: FormApi<SolarGenerator | SolarPark>
  enterManually: boolean
  formChangedExternally?: boolean
}

const ConfigurationSolarPlant: React.FC<ConfigurationSolarPlantProps> = ({
  asset,
  form,
  enterManually,
  formChangedExternally = false,
}) => {
  const [formState, setFormState] = useState(asset)

  const { none, ttdatAadat, vsat, tsat, hsat } = TrackerNamesEnum

  const trackerValues = useMemo(getTrackerValues, [])

  const degreeFieldUnit = useCallback(() => {
    return <FieldUnit>°</FieldUnit>
  }, [])

  useEffect(() => {
    if (isSolarPark(formState) && enterManually && !formState?.typeSpecificAttributes?.trackerName) {
      form.mutators.setValue('typeSpecificAttributes.tracker', 0)
      form.mutators.setValue('typeSpecificAttributes.trackerName', none)
    }
  }, [formState?.type, enterManually])

  useEffect(() => {
    const trackerName = formState?.typeSpecificAttributes?.trackerName || none
    const trackerValues = defaultTrackerValues(trackerName)

    if (form.getState().dirty || formChangedExternally) {
      formState.typeSpecificAttributes = Object.keys(formState.typeSpecificAttributes).reduce<SolarAttributes>(
        (prev, key) => {
          return key === 'trackerName'
            ? {
                ...prev,
                [key]: formState.typeSpecificAttributes[key],
              }
            : prev
        },
        {},
      )
      Object.keys(trackerValues).forEach((key: string) => {
        form.mutators.setValue(`typeSpecificAttributes.${key}`, trackerValues[key])
      })
    }
  }, [formChangedExternally, formState?.typeSpecificAttributes?.trackerName])

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as SolarGenerator | SolarPark)
        }}
      />

      {isPark(formState) && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Number of plants in park`}: </AssetFormLabel>
          </FormKey>

          <FormValue>
            <Flex direction="row">
              <Flex flexBasis="50%">
                <Field name="numParts">
                  {(props) => {
                    return <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />
                  }}
                </Field>
              </Flex>
              <SubField flexBasis="50%" />
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Plant with tracker`}:</AssetFormLabel>
        </FormKey>

        <FormValue>
          <Flex direction="row">
            <Flex flexBasis="50%">
              <Field name="typeSpecificAttributes.trackerName">
                {(props) => {
                  return (
                    <SelectFieldWithoutLabel>
                      <Select input={props.input} meta={props.meta}>
                        {trackerValues.map((el) => (
                          <MenuItem key={el.name} value={el.name}>
                            {el.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </SelectFieldWithoutLabel>
                  )
                }}
              </Field>
            </Flex>
            <SubField flexBasis="50%" />
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*orientation*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>
            <Flex flexWrap="wrap">
              <div>{t`Orientation `}</div>
              <Box ml={0.5}>{t`[0° = South]`}:</Box>
            </Flex>
          </AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <Flex direction="row">
            <Flex flexBasis="50%" direction="row">
              {(formState?.typeSpecificAttributes?.trackerName === none ||
                formState?.typeSpecificAttributes?.trackerName === hsat) && (
                <Field name={`typeSpecificAttributes.azimuth`}>
                  {(props) => <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />}
                </Field>
              )}

              {(formState?.typeSpecificAttributes?.trackerName === ttdatAadat ||
                formState?.typeSpecificAttributes?.trackerName === vsat ||
                formState?.typeSpecificAttributes?.trackerName === tsat) && (
                <Field name={`typeSpecificAttributes.trackerAzimuthFrom`}>
                  {(props) => (
                    <NumberFieldWithLabel label={t`Start angle`} type="number" input={props.input} meta={props.meta} />
                  )}
                </Field>
              )}

              {degreeFieldUnit()}
            </Flex>
            <SubField flexBasis="50%" direction="row">
              {(isGenerator(formState) || enterManually) &&
                (formState?.typeSpecificAttributes?.trackerName === ttdatAadat ||
                  formState?.typeSpecificAttributes?.trackerName === vsat ||
                  formState?.typeSpecificAttributes?.trackerName === tsat) && (
                  <>
                    <Field name="typeSpecificAttributes.trackerAzimuthTo">
                      {(props) => {
                        return (
                          <NumberFieldWithLabel
                            label={t`End angle`}
                            type="number"
                            input={props.input}
                            meta={props.meta}
                          />
                        )
                      }}
                    </Field>
                    {degreeFieldUnit()}
                  </>
                )}
            </SubField>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      {/*Tilt*/}
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>
            <Flex>
              <div>{t`Tilt`}</div>
              <Box ml={0.5}>{t`[0° = horizontal]`}:</Box>
            </Flex>
          </AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <Flex direction="row">
            <Flex flexBasis="50%" direction="row">
              <Field
                name={`typeSpecificAttributes.${
                  formState?.typeSpecificAttributes?.trackerName === none ||
                  formState?.typeSpecificAttributes?.trackerName === tsat ||
                  formState?.typeSpecificAttributes?.trackerName === vsat
                    ? 'tilt'
                    : 'trackerTiltFrom'
                }`}
              >
                {(props) => {
                  return formState?.typeSpecificAttributes?.trackerName === ttdatAadat ||
                    formState?.typeSpecificAttributes?.trackerName === hsat ? (
                    <NumberFieldWithLabel
                      type="number"
                      input={props.input}
                      meta={props.meta}
                      label={t`Minimum angle`}
                    />
                  ) : (
                    <NumberFieldWithoutLabel
                      disabled={formState?.typeSpecificAttributes?.trackerName === vsat}
                      type="number"
                      input={props.input}
                      meta={props.meta}
                    />
                  )
                }}
              </Field>
              {degreeFieldUnit()}
            </Flex>
            <SubField flexBasis="50%" direction="row">
              {(isGenerator(formState) || enterManually) &&
                (formState?.typeSpecificAttributes?.trackerName === ttdatAadat ||
                  formState?.typeSpecificAttributes?.trackerName === hsat) && (
                  <>
                    <Field name="typeSpecificAttributes.trackerTiltTo">
                      {(props) => {
                        return (
                          <NumberFieldWithLabel
                            type="number"
                            label={t`Maximum angle`}
                            input={props.input}
                            meta={props.meta}
                          />
                        )
                      }}
                    </Field>
                    {degreeFieldUnit()}
                  </>
                )}
            </SubField>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>
      {isSolarPlant(formState) && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Number of panels`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex direction="row">
              <Flex flexBasis="50%" direction="row">
                <Field name="numParts">
                  {(props) => {
                    return (
                      <NumberFieldWithoutLabel
                        // TODO this seems to break `form.getState().active` which is undefined
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        input={props.input}
                        meta={props.meta}
                      />
                    )
                  }}
                </Field>
              </Flex>

              <SubField flexBasis="50%" direction="row" />
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {/*Extra info*/}
    </>
  )
}

export default React.memo(ConfigurationSolarPlant)
