import React, { useEffect, useMemo, useState } from 'react'
import {
  AssetFormLabel,
  AutoCompleteWithLabel,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  SubField,
} from 'ui/form/assetForm.style'
import { isAdmin, isCurrentUserSubscribedTo, isImpersonatedAdmin, isIndianCustomer } from 'utils/user'
import { SubscribedProduct, User } from 'modules/auth/Auth.types'
import { jt, t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { useField } from 'react-final-form'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import PenaltyRegulationBriefInfo from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationBriefInfo'
import { Asset } from 'modules/asset/store/asset.types'
import { FormApi } from 'final-form'
import { useCustomMuiStyles } from 'themes/theme-light'
import { isCluster, isPark } from 'utils/asset'
import axios from 'axios'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { usePenaltyRegulations } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import { PenaltyRegulationNew } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import InlineLoading from 'ui/InlineLoading'
import PopperTooltip from 'ui/PopperTooltip'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { AppUnits } from 'utils/units'
import { Link } from 'react-router-dom'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import { getAssetQueryObj, stringifyQueryParams } from 'utils/query-string'
import { useDraftChangeCounts } from 'modules/asset/api/metadata.api'

const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 0.3em;
`

interface PenaltyFieldProps {
  is_parent_value?: boolean
}
const PenaltyRegulationField = styled(TextField)<PenaltyFieldProps>`
  *.MuiInputBase-root {
    color: ${(props) => (props.is_parent_value ? 'inherit' : 'inherit')};
  }
`

const PenaltyPpaField = styled(TextField)<PenaltyFieldProps>`
  *.MuiInputBase-root {
    color: ${(props) => (props.is_parent_value ? 'inherit' : 'inherit')};
  }
  *.MuiInputBase-input {
    text-align: end;
  }
`

interface SectionPenaltyProps {
  user: User
  asset: Asset
  form: FormApi<Asset>
  assetPhysicalAggregator: Asset
}

const SectionPenalty: React.FC<SectionPenaltyProps> = ({ user, asset, form, assetPhysicalAggregator }) => {
  // console.log(asset)
  const classes = useCustomMuiStyles({ important: true })
  const penaltyRegulations = usePenaltyRegulations()
  const [parentPpaRate, setParentPpaRate] = useState<number | string>('')
  const [parentRegulationId, setParentRegulationId] = useState<string>('')
  const [loadingParentPpaRate, setLoadingParentPpaRate] = useState(false)
  const [loadingParentPenaltyRegulation, setLoadingParentPenaltyRegulation] = useState(false)

  // Getting ppaRate Instance and display parent ppaRate if it's not added
  const assetBelongsToPhysicalAggregator = Boolean(assetPhysicalAggregator)
  const ppaRateFieldInstance = useField('ppaRate')
  const currentPpaRate = ppaRateFieldInstance.input.value
  const ppaInputValue = assetBelongsToPhysicalAggregator ? parentPpaRate : currentPpaRate

  // Getting penaltyRegulationId Instance and display parent penaltyRegulationId if it's not added
  const penaltyRegulationIdInstance = useField('penaltyRegulationId')
  const currentPenaltyRegulation = penaltyRegulationIdInstance.input.value

  const penaltyRegulationInputValue = assetBelongsToPhysicalAggregator ? parentRegulationId : currentPenaltyRegulation
  const { pendingCount } = useDraftChangeCounts()
  const hasModifiedAssets = useMemo(() => pendingCount > 1, [pendingCount])
  // console.log({
  //   asset,
  //   parentRegulationId,
  //   parentPpaRate,
  //   currentPpaRate,
  //   currentPenaltyRegulation,
  // })

  const handlePpaRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.mutators.setValue('ppaRate', event.target.value)
  }

  const handlePenaltyRegulationChange = (regulationId: string) => {
    form.mutators.setValue('penaltyRegulationId', regulationId)
  }

  useEffect(() => {
    if (!isCluster(asset) && assetBelongsToPhysicalAggregator && asset?.id) {
      const type = isPark(asset) ? 'PARK' : 'GENERATOR'
      setLoadingParentPpaRate(true)
      setLoadingParentPenaltyRegulation(true)

      axios
        .get(`/api/qecs/penalty/v1/ppa_rate/assetType/${type}/assetId/${asset.id}`)
        .then((res) => {
          setParentPpaRate(res.data)
        })
        .catch(() => {
          setParentPpaRate('')
        })
        .finally(() => {
          setLoadingParentPpaRate(false)
        })

      axios
        .get(`/api/qecs/penalty/v1/regulation_id/assetType/${type}/assetId/${asset.id}`)
        .then((res) => {
          setParentRegulationId(res.data)
        })
        .catch(() => {
          setParentRegulationId('')
        })
        .finally(() => {
          setLoadingParentPenaltyRegulation(false)
        })
    }
  }, [asset?.ppaRate, hasModifiedAssets, asset?.penaltyRegulationId, asset?.id, assetBelongsToPhysicalAggregator])

  const queryString = assetPhysicalAggregator?.id
    ? stringifyQueryParams(getAssetQueryObj({ id: assetPhysicalAggregator?.id }))
    : ''

  const parentAggregatorLink = assetPhysicalAggregator ? (
    <Link to={`${ROUTE_WORKBENCH}?${queryString}`}>{assetPhysicalAggregator.name}</Link>
  ) : (
    <></>
  )

  return (
    <>
      <FormKeyValueGrid>
        <FormKey
          className={
            (isAdmin(user) || isImpersonatedAdmin(user)) &&
            (!isIndianCustomer(user) ||
              !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
              ? classes.adminComponent
              : 'inherit'
          }
        >
          <AssetFormLabel>
            <Flex alignItems="center">
              {t`PPA rate`}: {loadingParentPpaRate && <InlineLoading />}
            </Flex>
          </AssetFormLabel>
        </FormKey>
        <FormValue
          isAdminComponent={
            (isAdmin(user) || isImpersonatedAdmin(user)) &&
            (!isIndianCustomer(user) ||
              !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
          }
        >
          <Flex direction="row">
            <Flex flexBasis="50%" direction="row">
              <PenaltyPpaField
                fullWidth={true}
                onChange={handlePpaRateChange}
                value={ppaInputValue}
                type="number"
                disabled={assetBelongsToPhysicalAggregator}
              />
              <FieldUnit>
                <Flex direction="row" alignItems="center">
                  {AppUnits.RUPEE_PER_KILO_WATT_HOUR}
                  {assetBelongsToPhysicalAggregator && (
                    <PopperTooltip
                      popperLabel={<TooltipIcon icon="info" size="sm" />}
                      popperContent={
                        <>
                          {jt`Regulation data cannot be modified here because this asset is physically connected to ${parentAggregatorLink} and the regulation data is inherited from that latter.`}
                        </>
                      }
                    />
                  )}
                </Flex>
              </FieldUnit>
            </Flex>

            <SubField flexBasis="50%" direction="row" />
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey
          className={
            (isAdmin(user) || isImpersonatedAdmin(user)) &&
            (!isIndianCustomer(user) ||
              !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
              ? classes.adminComponent
              : 'inherit'
          }
        >
          <AssetFormLabel>
            <Flex alignItems="center">
              {t`Penalty regulation`}:{loadingParentPenaltyRegulation && <InlineLoading />}
            </Flex>
          </AssetFormLabel>
        </FormKey>

        <FormValue
          isAdminComponent={
            (isAdmin(user) || isImpersonatedAdmin(user)) &&
            (!isIndianCustomer(user) ||
              !isCurrentUserSubscribedTo(user, SubscribedProduct.QUALITY_AND_SYSTEM_MONITORING))
          }
        >
          <Flex alignItems="center">
            <AutoCompleteWithLabel
              value={
                (penaltyRegulations.data || []).find((regulation) => regulation.uuid === penaltyRegulationInputValue) ||
                null
              }
              options={penaltyRegulations.data || []}
              getOptionLabel={(option) => `${option?.regulationName}` || ''}
              onChange={(event, option) => handlePenaltyRegulationChange(option?.uuid)}
              disabled={assetBelongsToPhysicalAggregator}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <PenaltyRegulationField {...params} fullWidth disabled={assetBelongsToPhysicalAggregator} />
              )}
              renderOption={(regulation: PenaltyRegulationNew) => (
                <PenaltyRegulationBriefInfo key={regulation?.uuid} regulation={regulation} />
              )}
            />
            {assetBelongsToPhysicalAggregator && (
              <PopperTooltip
                popperLabel={<TooltipIcon icon="info" size="sm" />}
                popperContent={
                  <>
                    {jt`Regulation data cannot be modified here because this asset is physically connected to ${parentAggregatorLink} and the regulation data is inherited from that latter.`}
                  </>
                }
              />
            )}
          </Flex>
        </FormValue>
      </FormKeyValueGrid>
    </>
  )
}

export default SectionPenalty
