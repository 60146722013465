import { combineReducers } from 'redux'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { LOGIN_REQUEST } from 'modules/auth/redux_store/auth.action.types'
import { createSelector } from 'reselect'

// state

interface State {
  loading: boolean
  error: string | null
  initiated: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  initiated: false,
}

interface LogoutAction {
  type: actionTypes.LOGOUT_ACTION_TYPE | LOGIN_REQUEST
}

// reducers

const loading = createReducer<State['loading'], LogoutAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.LOGOUT_REQUEST:
      return true
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGOUT_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], LogoutAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.LOGOUT_SUCCESS:
      case actionTypes.LOGOUT_DISMISS:
        return null
      case actionTypes.LOGOUT_FAILURE:
        return error
    }
    return state
  },
)

const initiated = createReducer<State['initiated'], LogoutAction>((state = initialState.initiated, { type }) => {
  switch (type) {
    case actionTypes.LOGOUT_REQUEST:
    case actionTypes.LOGOUT_SUCCESS:
    case actionTypes.LOGOUT_FAILURE:
      return true
    case LOGIN_REQUEST:
      return false
  }
  return state
})

export const logoutReducer = combineReducers({
  loading,
  error,
  initiated,
})

// selectors
export const logoutLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.logout.loading,
  (loading) => loading,
)
export const logoutErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.error,
  (error) => error,
)
export const logoutInitiatedSelector = createSelector<any, State['initiated'], State['initiated']>(
  (state) => state.auth.logout.initiated,
  (initiated) => initiated,
)
