export const STAGE_PRODUCTIVE = 'PRODUCTIVE'
export const STAGE_DRAFT = 'DRAFT'
export type PRODUCTIVE = typeof STAGE_PRODUCTIVE
export type DRAFT = typeof STAGE_DRAFT
export type STAGE = PRODUCTIVE | DRAFT

export interface Draft {
  id: number
  created: number
  activeFrom?: number
  stage: STAGE
}
