import { t } from 'ttag'
import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'

export const getFileManagerTableColumns: () => Column[] = () => [
  {
    name: 'status',
    label: t`Status`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FILE_UPLOAD_STATUS,
    searchable: false,
    sortable: true,
    width: '6.5em',
    align: 'left',
    fixed: true,
  },
  {
    name: 'originalFileName',
    label: t`File Name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    width: '30em',
    align: 'left',
    fixed: true,
  },

  {
    name: 'contentType',
    label: t`Upload Typ`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '10em',
    align: 'left',
  },
  {
    name: 'uploadTs',
    label: t`Upload Time`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '10em',
    align: 'right',
  },
  {
    name: 'fileSize',
    label: t`File Size`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '9em',
    align: 'right',
  },
]
