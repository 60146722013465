import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReTable from 'modules/reTable/ReTable'
import { RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { table } from 'themes/theme-light'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { getExcludedTimePeriodsColumns } from 'utils/meterDataCleansing'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch } from 'react-redux'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import ExcludedTimePeriodsTableBody from 'modules/asset/assetCrud/meterDataCleansing/ExcludedPeriodsTable/ExcludedTimePeriodsTableBody'
import { TimePeriodToHighlightInterface } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { Timezone } from 'fixtures/timezones'

interface ExcludedTimePeriodsTableProps {
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  onChangeTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onCancelEditExcludedTimePeriod: () => void
  onSaveExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  userTimezone: Timezone
  linkToDefault: boolean
}

const ExcludedTimePeriodsTable: React.FC<ExcludedTimePeriodsTableProps> = ({
  timePeriodToExcludeFromChart,
  onChangeTimePeriod,
  onEditExcludedTimePeriod,
  timePeriodToEdit,
  onCancelEditExcludedTimePeriod,
  onSaveExcludedTimePeriod,
  onDeleteExcludedTimePeriod,
  userTimezone,
  linkToDefault,
}) => {
  const dispatch = useDispatch()
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const [itemsToRender, setItemsToRender] = useState<TimePeriodToHighlightInterface[]>([])

  const columnsSelected = useReTableSelectorWithId(
    reTableColumnsSelectedSelector,
    RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS,
  )

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'start',
    }),
    [],
  )

  const handleItemsToRenderChange = useCallback((items: TimePeriodToHighlightInterface[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  const columnsAvailable = useMemo(() => getExcludedTimePeriodsColumns(), [])
  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS,
      columnsAvailable,
    })
  }, [columnsAvailable])

  const handleEditItem = useCallback(
    (item: TimePeriodToHighlightInterface) => {
      onEditExcludedTimePeriod(item)
    },
    [onEditExcludedTimePeriod],
  )

  const handleDeleteItem = useCallback(
    (item: TimePeriodToHighlightInterface) => {
      onDeleteExcludedTimePeriod(item)
    },
    [onDeleteExcludedTimePeriod],
  )

  const handleCloseEditingTimePeriod = useCallback(() => {
    if (timePeriodToEdit) {
      // onChangeTimePeriod(timePeriodToEdit)
      onCancelEditExcludedTimePeriod()
    }
  }, [timePeriodToEdit, onCancelEditExcludedTimePeriod])

  const handleSaveItem = useCallback(
    (item: TimePeriodToHighlightInterface) => {
      onSaveExcludedTimePeriod(item)
    },
    [onSaveExcludedTimePeriod],
  )

  const tableHeaderHasAction = false

  return (
    <ReTable
      id={RETABLE_ID_METER_DATA_CLEANSING_TIME_PERIODS}
      itemHeight={table.rowHeight}
      itemsPaddingHeader={ITEMS_PADDING_HEADER}
      itemsPaddingFooter={ITEMS_PADDING_FOOTER}
      items={timePeriodToExcludeFromChart as ReTableItem[]}
      renderAheadCount={RENDER_AHEAD_COUNT}
      hasExtendedHeader={tableHeaderHasAction}
      defaultSort={defaultSort}
      onItemsToRenderChange={handleItemsToRenderChange}
    >
      <ReTableGenericHeader tableHeaderHasActions={tableHeaderHasAction} columns={columnsSelected} />
      <ExcludedTimePeriodsTableBody
        onEditItem={handleEditItem}
        onDeleteItem={handleDeleteItem}
        columns={columnsSelected}
        visibleItems={itemsToRender}
        tableHeaderHasActions={tableHeaderHasAction}
        onClose={handleCloseEditingTimePeriod}
        onSaveItem={handleSaveItem}
        onChangeTimePeriod={onChangeTimePeriod}
        timePeriodToEdit={timePeriodToEdit}
        userTimezone={userTimezone}
        linkToDefault={linkToDefault}
      />
    </ReTable>
  )
}

export default React.memo(ExcludedTimePeriodsTable)
