import { Popper } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'
import { PopperTooltipPosition } from 'ui/PopperTooltip'

interface PopperContainerProps {
  hasContent: number
}
const PopperContainer = styled.div<PopperContainerProps>`
  cursor: ${(props) => (props.hasContent ? 'help' : 'inherit')};
`

interface ContentProps {
  backgroundcolor?: string
  contentWidth?: string
}
const Content = styled(Paper)<ContentProps>`
  &.MuiPaper-root {
    background-color: ${(props) => props.backgroundcolor || 'white'};
  }
  max-width: ${(props) => props.contentWidth || '40em'};
  padding: 1em;
`

interface PopperToolTipProps {
  tooltipContent: React.ReactNode
  contentBackgroundColor?: string
  position?: PopperTooltipPosition
  contentWidth?: string
  tooltipRef: React.MutableRefObject<null>
}

const FadablePopperTooltip: React.FC<PopperToolTipProps> = ({
  tooltipContent,
  contentBackgroundColor,
  position = PopperTooltipPosition.BOTTOM_START,
  contentWidth,
  tooltipRef,
}) => {
  const [showPopper, setShowPopper] = useState<boolean>(true)

  const handlePopperContentMouseLeave = useCallback(() => {
    setShowPopper(false)
  }, [])

  const [showPopperDebounced] = useDebounce(showPopper, 50)

  useEffect(() => {
    const timer = setTimeout(() => setShowPopper(false), 5 * 1000) // 5 seconds

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <PopperContainer hasContent={tooltipContent ? 1 : 0}>
      {tooltipContent && (
        <Popper
          open={showPopperDebounced}
          placement={position}
          anchorEl={tooltipRef.current}
          // we need a high z-index so that it is visible even in modals (e.g. UploadModal.tsx)
          style={{ zIndex: 1500 }}
        >
          {tooltipContent && (
            <Content
              elevation={3}
              onMouseLeave={handlePopperContentMouseLeave}
              backgroundcolor={contentBackgroundColor}
              contentWidth={contentWidth}
            >
              {tooltipContent}
            </Content>
          )}
        </Popper>
      )}
    </PopperContainer>
  )
}

export default React.memo(FadablePopperTooltip)
