import { orange, red } from '@material-ui/core/colors'

import { ERROR, WARN } from 'fixtures/plausability'
import PlausabilityToolTip from 'modules/asset/plausability/PlausabilityToolTip'
import { getAllPlausibilityResultSelector } from 'modules/asset/plausability/redux_store/state/plausability.getAll'
import { Asset } from 'modules/asset/store/asset.types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'

interface PlausabilityIconProps {
  status: string
  selected: boolean
}

interface TreePlausabilityDataProps {
  asset: Asset
  selected: boolean
}

const getPlausibilityColor = (props: PlausabilityIconProps) =>
  props.status === WARN
    ? orange[props.selected ? 300 : 800]
    : props.status === ERROR
    ? red[props.selected ? 200 : 600]
    : ''

const PlausabilityIcon = styled(FontAwesomeActionIcon)<PlausabilityIconProps>`
  color: ${(props) => getPlausibilityColor(props)};
`
const PlausibilityCellData = styled.div`
  text-align: center;
`
const PlausibilityText = styled.div<PlausabilityIconProps>`
  margin-right: 0.5em;
  color: ${(props) => getPlausibilityColor(props)};
`

const TreePlausabilityData: React.FC<TreePlausabilityDataProps> = ({ asset, selected }) => {
  const plausibilityData = useSelector(getAllPlausibilityResultSelector)

  return (
    <PlausibilityCellData>
      {plausibilityData[asset.id] &&
        (plausibilityData[asset.id].status === 'ERROR' || plausibilityData[asset.id].status === 'WARN') && (
          <PopperTooltip
            popperLabel={
              <Flex direction="row" alignItems="center">
                <PlausibilityText status={plausibilityData[asset.id].status} selected={selected}>
                  {plausibilityData[asset.id].status === WARN
                    ? t`Warning`
                    : plausibilityData[asset.id].status === ERROR
                    ? t`Error`
                    : ''}
                  {'  '}
                </PlausibilityText>
                <PlausabilityIcon
                  status={plausibilityData[asset.id].status}
                  selected={selected}
                  icon="exclamation-circle"
                  size="sm"
                />
              </Flex>
            }
            popperContent={<PlausabilityToolTip results={plausibilityData[asset.id].results} />}
          />
        )}
    </PlausibilityCellData>
  )
}

export default React.memo(TreePlausabilityData)
