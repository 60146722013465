import { request } from 'utils/request'
import axios from 'axios'
import { FileData } from 'utils/file'
import { UploadResult } from 'modules/data/fileManager/redux_store/state/uploadMeterdataFiles.state'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'

export const getAllFiles = () => {
  return request(() => axios.get('getall'))
}
export const downloadFile = (fileId: string) => {
  return request(() => axios.get(`download/${fileId}`))
}
export const deactivateFile = (fileId: string) => {
  return request(() => axios.put(`deactivateFile/${fileId}`))
}
export const deleteFile = (fileId: string) => {
  return request(() => axios.delete(`delete file/${fileId}`))
}
export const getMeterDataFiles = () => {
  return request(() => axios.get<MeterdataFile[]>('/api/upload/meterdata/v1'))
}
export const uploadMeterDataFiles = (fileDatas: FileData[], selectedAssetName: string) => {
  // TODO response type
  // If we upload a file from Asset Details > Meter Data Tab , we need to automatically change the file name to selected asset.
  const formData = new FormData()
  fileDatas.forEach((fileData) => {
    if (selectedAssetName) {
      const newFileName = `${selectedAssetName}.csv`
      formData.append('file', fileData.file, newFileName)
    } else {
      formData.append('file', fileData.file)
    }
  })

  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  return request(() => axios.post<UploadResult>('/api/upload/meterdata/v1', formData, { headers }))
}
