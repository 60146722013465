import React from 'react'
import styled from 'styled-components'

interface MessageProps {
  color: string
  minHeight: boolean
}

export const Message = styled.div<MessageProps>`
  color: ${(props) => props.color};
  min-height: ${(props) => props.minHeight && '2.5em'};
  font-size: 14px;
`

interface ErrorMessageProps {
  errorMsg?: string | null
  children?: React.ReactNode
  color?: string
  minHeight?: boolean
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorMsg, children, color = 'red', minHeight = true }) => {
  return (
    <Message minHeight={minHeight} color={color}>
      {errorMsg ? <>{errorMsg}</> : children ? <>{children}</> : ''}
    </Message>
  )
}

export default ErrorMessage
