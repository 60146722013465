import React, { useEffect, useMemo, useState } from 'react'
import { c, t } from 'ttag'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { TimePeriodToHighlightInterface } from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { Column, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { convertZonedTimeToUtc, formatDate } from 'utils/date'
import ReTableEditableRowActionsCell from 'modules/reTable/ReTableEditableRowActionsCell'
import { getTableRowContextMenuActions } from 'modules/reTable/reTable.functionality'
import { isAfter, isBefore } from 'date-fns'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import DateAndTimePicker from 'ui/elements/DateAndTimePicker'
import { lighten } from '@material-ui/core'
import { theme } from 'themes/theme-light'
import { Timezone } from 'fixtures/timezones'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'

interface EditableRowProps {
  is_edit: number
  is_selected?: number
}
export const EditableRow = styled(ReTableRow)<EditableRowProps>`
  &.MuiTableRow-root {
    cursor: pointer;
    background-color: ${(props) =>
      props.is_edit || props.is_selected ? lighten(theme.palette.primary.main, 0.8) : 'inherit'};
  }
  *.MuiTableCell-body {
    ${(props) => (props.is_edit ? 'padding-bottom: 17px;' : '')}
  }
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -18px;
  }
`

const InputFieldContainer = styled(Flex)`
  padding-right: 1em;
  padding-bottom: 1em;
  & .MuiInput-formControl {
    width: 12.2em;
  }
`

interface ExcludedTimePeriodsTableBodyProps {
  visibleItems: TimePeriodToHighlightInterface[]
  tableHeaderHasActions: boolean
  columns: Column[]
  onEditItem: (data: TimePeriodToHighlightInterface) => void
  onDeleteItem: (data: TimePeriodToHighlightInterface) => void
  onClose: () => void
  onSaveItem: (data: TimePeriodToHighlightInterface) => void
  onChangeTimePeriod: (data: TimePeriodToHighlightInterface, b: boolean) => void
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  userTimezone: Timezone
  linkToDefault: boolean
}

const ExcludedTimePeriodsTableBody: React.FC<ExcludedTimePeriodsTableBodyProps> = ({
  visibleItems,
  onEditItem,
  onDeleteItem,
  onClose,
  onSaveItem,
  onChangeTimePeriod,
  timePeriodToEdit,
  userTimezone,
  linkToDefault,
}) => {
  const actionMenuItems = useMemo(getTableRowContextMenuActions, [])
  const [timePeriodInputsData, setTimePeriodInputsData] = useState<TimePeriodToHighlightInterface | null>()

  const handleChangeStartDate = (value: Date) => {
    const data = {
      start: {
        date: value,
        timezone: userTimezone,
      },
      end: {
        date: timePeriodInputsData?.end.date,
        timezone: timePeriodInputsData?.end.timezone,
      },
    }
    onChangeTimePeriod(data, true)
    setTimePeriodInputsData(data)
  }

  const handleChangeEndDate = (value: Date) => {
    const data = {
      start: {
        date: timePeriodInputsData?.start.date,
        timezone: timePeriodInputsData?.start.timezone,
      },
      end: {
        date: value,
        timezone: userTimezone,
      },
    }
    onChangeTimePeriod(data, true)
    setTimePeriodInputsData(data)
  }

  const handleCloseForm = () => {
    setTimePeriodInputsData(null)
    onClose()
  }

  const handleSave = () => {
    if (timePeriodInputsData) {
      onSaveItem(timePeriodInputsData)
    }
    setTimePeriodInputsData(null)
  }

  const startDateErrorMsg = useMemo(() => {
    if (
      timePeriodInputsData?.start?.date &&
      timePeriodInputsData?.end?.date &&
      isAfter(timePeriodInputsData.start.date, timePeriodInputsData.end.date)
    ) {
      return 'Start should be less than end'
    } else return ''
  }, [timePeriodInputsData?.start, timePeriodInputsData?.end])

  const endDateErrorMsg = useMemo(() => {
    if (
      timePeriodInputsData?.start?.date &&
      timePeriodInputsData?.end?.date &&
      isBefore(timePeriodInputsData.end.date, timePeriodInputsData.start.date)
    ) {
      return 'End should be greater than start'
    } else return ''
  }, [timePeriodInputsData?.start, timePeriodInputsData?.end])

  const handleClickMenuItem = (action: ReTableRowContextActions, rowItem?: any) => {
    if (action === ReTableRowContextActions.EDIT_ROW) {
      onEditItem(rowItem)
      setTimePeriodInputsData(rowItem)
    } else if (action === ReTableRowContextActions.DELETE_ROW) {
      onDeleteItem(rowItem)
    }
  }

  useEffect(() => {
    if (timePeriodToEdit) {
      setTimePeriodInputsData(timePeriodToEdit)
    }
  }, [timePeriodToEdit])

  return (
    <ReTableBody>
      {visibleItems.length < 1 && (
        <ReTableRow>
          <ReTableCell colSpan={3}>
            <>{c('Meter data cleansing')
              .t`There is no excluded period. You can select a time period from the chart above.`}</>
          </ReTableCell>
        </ReTableRow>
      )}
      {visibleItems.map((row: TimePeriodToHighlightInterface) => {
        const isRowEdit = row.highlight // time period to edit is highlighted

        const startDate = row?.start.date || null
        const startDateTimezone = row?.start.timezone || null
        const startDateInUTC = row ? convertZonedTimeToUtc(startDate, userTimezone) : null

        const endDate = row?.end.date || null
        const endDateTimezone = row?.end.timezone || null
        const endDateInUTC = endDate ? convertZonedTimeToUtc(endDate, userTimezone) : null

        return (
          <EditableRow key={row.id} is_edit={isRowEdit ? 1 : 0}>
            {isRowEdit ? (
              <>
                <ReTableCell>
                  <InputFieldContainer alignItems="center">
                    <DateAndTimePicker
                      onDateChange={handleChangeStartDate}
                      label={t`Start`}
                      value={timePeriodInputsData?.start?.date || null}
                      onlyDate={false}
                      errorMsg={startDateErrorMsg}
                    />
                  </InputFieldContainer>
                </ReTableCell>
                <ReTableCell no_border={true}>
                  <DateAndTimePicker
                    onDateChange={handleChangeEndDate}
                    label={t`End`}
                    value={timePeriodInputsData?.end?.date || null}
                    onlyDate={false}
                    errorMsg={endDateErrorMsg}
                  />
                </ReTableCell>
              </>
            ) : (
              <>
                <ReTableCell>
                  <Flex>
                    <>{formatDate(row?.start?.date)}</>
                    {startDateInUTC && startDateTimezone && startDateTimezone !== userTimezone && (
                      <DateTimezonePopover dateInUTC={startDateInUTC} originalTimezone={startDateTimezone} />
                    )}
                  </Flex>
                </ReTableCell>
                <ReTableCell no_border={true}>
                  <Flex>
                    <>{formatDate(row?.end?.date)}</>
                    {endDateInUTC && endDateTimezone && endDateTimezone !== userTimezone && (
                      <DateTimezonePopover dateInUTC={endDateInUTC} originalTimezone={endDateTimezone} />
                    )}
                  </Flex>
                </ReTableCell>
              </>
            )}

            <ReTableEditableRowActionsCell
              showContextMenu={!linkToDefault}
              onClose={handleCloseForm}
              onSubmit={handleSave}
              actionMenuItems={actionMenuItems}
              onClickMenuItem={handleClickMenuItem}
              dialogContext={''}
              dialogText={''}
              navigationDialogKey={''}
              rowItem={row}
              showTableRowContextMenu={!linkToDefault}
              showFormActions={isRowEdit}
            />
          </EditableRow>
        )
      })}
    </ReTableBody>
  )
}

export default ExcludedTimePeriodsTableBody
