import { spawn } from '@redux-saga/core/effects'
import { splashScreenSaga } from 'modules/app/splashScreen/redux_store/splash.screen.saga'
import { plausibilitySaga } from 'modules/asset/plausability/redux_store/plausibilitySaga'

import { assetSaga } from 'modules/asset/store/asset.store'
import { authSaga } from 'modules/auth/redux_store/auth.saga'
import { fileManagerSaga } from 'modules/data/fileManager/redux_store/fileManager.saga'
import { dataSaga } from 'modules/data/store/data.store'
import { areaForecastsSaga } from 'modules/dataStreams/areaForecast/redux_store/areaForecast.saga'
import { productSaga } from 'modules/product/store/product.store'
import { qualitySaga } from 'modules/quality/redux_store/quality.saga'
import { reTableSaga } from 'modules/reTable/redux_store/reTable.saga'
import { weatherSaga } from 'modules/weather/store/weather.store'
import { workspaceSaga } from 'modules/workspace/store/workspace.store'
import { applyMiddleware, createStore, Middleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createSagaMiddleware from 'redux-saga'

import rootReducer from 'rootReducer'

function* appSaga() {
  yield spawn(assetSaga)
  yield spawn(authSaga)
  yield spawn(dataSaga)
  yield spawn(fileManagerSaga)
  yield spawn(plausibilitySaga)
  yield spawn(productSaga)
  yield spawn(reTableSaga)
  yield spawn(splashScreenSaga)
  yield spawn(qualitySaga)
  yield spawn(weatherSaga)
  yield spawn(workspaceSaga)
  yield spawn(areaForecastsSaga)
}

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  trace: true,
  traceLimit: 25,
})

const sagaMiddleware = createSagaMiddleware()
const middleware: Middleware[] = [sagaMiddleware]

const store = createStore(
  rootReducer,
  /* preloadedState, */
  composeEnhancers(
    applyMiddleware(...middleware),
    // other store enhancers if any
  ),
)

sagaMiddleware.run(appSaga)

// hot reload reducers, inspired by https://gist.github.com/markerikson/dc6cee36b5b6f8d718f2e24a249e0491
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => store.replaceReducer(require('./rootReducer').default))
}

export default store
