import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { t } from 'ttag'

export const getTrainingColumns: () => Column[] = () => [
  {
    name: 'jobName',
    label: t`Job name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '9em',
    fieldName: 'jobName',
    isEditable: false,
    fixed: true,
  },

  {
    name: 'weatherModel',
    label: t`Weather model`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '9em',
    fieldName: 'weatherModel',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'status',
    label: t`Status`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '9em',
    fieldName: 'status',
    isEditable: false,
    fixed: true,
  },
]

export const prepareTrainingJobsTree = (jobsArray: any[]) => {
  const trainingJobsTableValues: {
    id: string
    jobName?: string
    uiLevel: number
    weatherModel?: string
    status?: string
    uiParents?: string[]
    uiAncestors?: string[]
  }[] = []

  if (jobsArray.length === 0) {
    return []
  }

  const filteredResult = jobsArray.filter((job) => {
    return job.trainings.some((traning) => {
      return traning.status !== 'FINISHED' && traning.status !== 'ERROR'
    })
  })

  if (filteredResult.length === 0) {
    return []
  }

  // We are looping backwards in order to show the latest one first.
  for (let index = filteredResult.length - 1; index >= 0; index--) {
    trainingJobsTableValues.push({
      id: filteredResult[index].jobName + filteredResult[index].creationDate,
      jobName: filteredResult[index].jobName,
      uiLevel: 0,
    })
    filteredResult[index].trainings.forEach((training) => {
      trainingJobsTableValues.push({
        id: filteredResult[index].jobName + filteredResult[index].creationDate + training.weatherModel,
        weatherModel: training.weatherModel,
        status: training.status,
        uiLevel: 1,
        uiParents: [...(filteredResult[index].jobName + filteredResult[index].creationDate)],
        uiAncestors: [...(filteredResult[index].jobName + filteredResult[index].creationDate)],
      })
    })
  }

  return trainingJobsTableValues
}
