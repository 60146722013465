import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from 'ui/form/LoadingButton'

import { QUERY_SCHEDULE, useQueryParams, useQueryString } from 'utils/query-string'
import { useDispatch, useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useDataStreams } from 'utils/dataStream'
import { TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { SAVE_WORKSPACE_DRAFT_REQUEST, WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import { DeepPartial } from 'ts-essentials'
import { AppEntityNamesForTranslation, tGetEntityName } from 'fixtures/commonTranslations'

interface CreateScheduleButtonProps {
  isWorkbenchReadyWithSufficientData: boolean
}

const CreateScheduleButton: React.FC<CreateScheduleButtonProps> = ({ isWorkbenchReadyWithSufficientData }) => {
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const allDataStreams = useDataStreams()
  const dispatch = useDispatch()
  const { queryParams } = useQueryParams()
  const createScheduleActive = queryParams[QUERY_SCHEDULE]
  const { onUpdateQueryString } = useQueryString()
  const [loading, setLoading] = useState(false)

  const handleOpenManualForecastForm = () => {
    if (isWorkbenchReadyWithSufficientData) {
      setLoading(true)
      onUpdateQueryString({ [QUERY_SCHEDULE]: true })
    }
  }

  useEffect(() => {
    if (createScheduleActive) {
      const availableCapacityPlanned = allDataStreams.find((dataStream) => {
        return (
          dataStream.type === TimeSeriesType.CAPACITY_DATA &&
          dataStream.subType === TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY
        )
      })

      const isAvailableCapacityPlannedSelected = selectedDataStreams.find((dataStream) => {
        return (
          dataStream.type === TimeSeriesType.CAPACITY_DATA &&
          dataStream.subType === TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY
        )
      })

      if (availableCapacityPlanned && !isAvailableCapacityPlannedSelected) {
        const cloneAvailableCapacityPlanned = { ...availableCapacityPlanned }
        cloneAvailableCapacityPlanned['color'] = selectedDataStreams.length + 1

        const newSelection = selectedDataStreams.concat(cloneAvailableCapacityPlanned)

        const draft: DeepPartial<WorkspaceConfig> = {
          data: {
            selection: newSelection,
          },
        }
        dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
      }
      setLoading(false)
    }
  }, [createScheduleActive, allDataStreams, selectedDataStreams])

  return (
    <>
      <LoadingButton
        loading={loading}
        variant="contained"
        size="small"
        disabled={!isWorkbenchReadyWithSufficientData || createScheduleActive}
        startIcon={<FontAwesomeIcon icon="chalkboard-teacher" fixedWidth />}
        onClick={handleOpenManualForecastForm}
      >
        {tGetEntityName(AppEntityNamesForTranslation.PREPARE_SCHEDULE)}
      </LoadingButton>
    </>
  )
}

export default CreateScheduleButton
