import React, { useState } from 'react'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { t } from 'ttag'
import TrainingTable from 'modules/quality/TrainingTable/TrainingTable'
import LoadingButton from 'ui/form/LoadingButton'
import ErrorMessage from 'ui/form/ErrorMessage'
import { getTrainingJobsById } from 'modules/quality/quality.api'
import { prepareTrainingJobsTree } from 'utils/training'
import { Asset } from 'modules/asset/store/asset.types'
import { isWindAsset } from 'utils/asset'

interface DisplayOnGoingTrainingsProps {
  asset: Asset
}

const DisplayOnGoingTrainings: React.FC<DisplayOnGoingTrainingsProps> = ({ asset }) => {
  const [openTrainingDialog, setOpenTrainingDialog] = useState<boolean>(false)
  // There is also a trainingJobs declaration but response object is not correct since there are some empty keys. Following state is correct.
  const [trainingJobsById, setTrainingJobsById] = useState([])
  const [errorTrainingJobsById, setErrorTrainingJobsById] = useState(false)
  const [loadingTrainingJobsById, setLoadingTrainingJobsById] = useState(false)

  const showTrainingHandler = () => {
    // Add loading
    setLoadingTrainingJobsById(true)

    // Reset error if it's true
    if (errorTrainingJobsById) {
      setErrorTrainingJobsById(false)
    }

    // Get jobs
    getTrainingJobsById(asset.id).then((res) => {
      if (res.isSuccessful) {
        // Prepare response
        setTrainingJobsById(prepareTrainingJobsTree(res.response.data))
        // Open dialogs
        setOpenTrainingDialog(true)
        // Remove loading
        setLoadingTrainingJobsById(false)
      } else {
        // Remove loading
        setLoadingTrainingJobsById(false)
        // Error
        setErrorTrainingJobsById(true)
        // Close dialog
        setOpenTrainingDialog(false)
      }
    })
  }

  return (
    <>
      {openTrainingDialog && (
        <ConfirmationDialog
          heading={t`Information about ongoing trainings`}
          text={t`Required`}
          confirmAction={t`Refresh`}
          cancelAction={t`Close`}
          onConfirm={showTrainingHandler}
          onCancel={() => setOpenTrainingDialog(false)}
          openDialog={openTrainingDialog}
          $customSize={true}
        >
          <TrainingTable trainingTableValues={trainingJobsById} />
        </ConfirmationDialog>
      )}
      {errorTrainingJobsById && <ErrorMessage errorMsg={t`Could not get training jobs.`} minHeight={false} />}

      {isWindAsset(asset) ? (
        <LoadingButton
          onClick={showTrainingHandler}
          color="primary"
          size="small"
          variant="text"
          loading={loadingTrainingJobsById}
          style={{ marginRight: '1em' }}
        >
          {t`Ongoing trainings`}
        </LoadingButton>
      ) : (
        <></>
      )}
    </>
  )
}

export default React.memo(DisplayOnGoingTrainings)
