import React, { useCallback, useMemo } from 'react'
import { Box, Chip } from '@material-ui/core'
import { c } from 'ttag'
import Flex from 'ui/styles/Flex'
import { Field, useField } from 'react-final-form'
import { getSiteForecastQualityMeasurements } from 'utils/siteForecastConfiguration'
import { ForecastConfig, QualityMeasurement } from 'modules/dataStreams/dataStreams.types'
import styled from 'styled-components'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { FormApi } from 'final-form'
import { Checkbox } from 'final-form-material-ui'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import SiteForecastDurationField from 'ui/elements/SiteForecastDurationField'
import FieldError from 'ui/form/FieldError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'
import { theme } from 'themes/theme-light'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isIndianCustomer } from 'utils/user'

const QualityMeasurementChip = styled(Chip)`
  &.MuiChip-root {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`

interface SectionQualityMeasurementProps {
  form: FormApi<ForecastConfig>
  fieldArrayKey: string
}

const SectionQualityMeasurement: React.FC<SectionQualityMeasurementProps> = ({ form, fieldArrayKey }) => {
  const user = useSelector(getUserResultSelector)
  const primaryMeasurement = useField<string>(`${fieldArrayKey}.primaryMeasurement`).input.value
  const penaltyNotificationEnabled = useField<string>(`${fieldArrayKey}.penaltyNotificationEnabled`).input.value
  const availableMeasurements = useMemo(() => getSiteForecastQualityMeasurements(), [])

  const handleSelectPrimaryMeasurement = useCallback((item: QualityMeasurement) => {
    form.mutators.setValue(`${fieldArrayKey}.primaryMeasurement`, item.name)
  }, [])

  const accuracyFieldDurationText = useMemo(() => {
    return (
      <Flex>
        <Box>{c('Site Forecast:Quality Evaluation').jt`% penalty accuracy`}</Box>
        <PopperTooltip
          popperLabel={
            <Box ml={0.7}>
              <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
            </Box>
          }
          popperContent={
            <div>
              {c('Site Forecast:Quality Evaluation')
                .t`Penalty accuracy represents the ratio of time blocks (15 min.) in a given period for which the penalty is zero.  If the penalty accuracy falls below the threshold during a daily calculation, the asset will be flagged.`}
            </div>
          }
        />
      </Flex>
    )
  }, [])

  return (
    <>
      <FormKeyValueGrid>
        <FormKey>{c('Site Forecast:Quality Evaluation').t`Primary measurement:`}</FormKey>
        <FormValue>
          <Flex flexWrap="wrap" alignItems="center">
            <Flex flexWrap="wrap">
              {availableMeasurements.slice(0, 3).map((measurement) => (
                <QualityMeasurementChip
                  color={measurement.name === primaryMeasurement ? 'primary' : 'default'}
                  key={measurement.name}
                  onClick={() => handleSelectPrimaryMeasurement(measurement)}
                  label={measurement.label}
                />
              ))}
            </Flex>
            <Flex flexWrap="wrap">
              {availableMeasurements.slice(3).map((measurement) => (
                <QualityMeasurementChip
                  color={measurement.name === primaryMeasurement ? 'primary' : 'default'}
                  key={measurement.name}
                  onClick={() => handleSelectPrimaryMeasurement(measurement)}
                  label={measurement.label}
                />
              ))}
            </Flex>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>
      {isIndianCustomer(user) && (
        <FormKeyValueGrid>
          <FormKey>{c('Site Forecast:Quality Evaluation').t`Calculate penalties:`}</FormKey>
          <FormValue>
            <Box ml={0.5} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start">
              <FormControlLabel
                label={'Daily'}
                control={
                  <Field name={`${fieldArrayKey}.penaltyNotificationEnabled`} type="checkbox" component={Checkbox} />
                }
              />
              <p>{c('Site Forecast:Quality Evaluation').t`Threshold`}</p>
              <Box ml={1}>
                <Field name={`${fieldArrayKey}.penaltyNotificationThreshold`}>
                  {({ input, meta }) => (
                    <Flex direction="row" alignItems="center">
                      <SiteForecastDurationField
                        step={1}
                        disabled={!penaltyNotificationEnabled}
                        min={0}
                        durationText={accuracyFieldDurationText}
                        input={input}
                      />
                      {meta.error && <FieldError error={meta.error} />}
                    </Flex>
                  )}
                </Field>
              </Box>
            </Box>
          </FormValue>
        </FormKeyValueGrid>
      )}
    </>
  )
}

export default React.memo(SectionQualityMeasurement)
