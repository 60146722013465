import { request } from 'utils/request'
import axios from 'axios'

export const setShowSplashScreen = (value: boolean) => {
  return request(() =>
    axios.post(`api/usersettings/save/showSplashScreen`, value, {
      // TODO add headers
      // headers: {
      //   'Content-Type': 'application/json',
      // },
    }),
  )
}

export const checkShowSplashScreen = () => {
  return request(() => axios.get('api/usersettings/showSplashScreen'))
}
