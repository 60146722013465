import React, { useState } from 'react'
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core'
import { c } from 'ttag'
import Flex from 'ui/styles/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import BulkMdcFilterSettings from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingHeader/BulkMeterDataCleansingFilterSettings/BulkMdcFilterSettings'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import { useRouter } from 'utils/route'
import { isSolarAsset, isWindAsset, useUniqueSelectedAssets } from 'utils/asset'
import { User } from 'modules/auth/Auth.types'
import { hasPermissionForPvBulkMeterDataCleansing, hasPermissionForWindBulkMeterDataCleansing } from 'utils/user'

// Styled component
const BulkMdcHeaderMainDiv = styled(Flex)`
  height: auto;
`

// Filter button
const CustomBulkButton = styled(Button)`
  margin-right: 10px !important;
`

// Close button component
const CloseButton = styled(IconButton)`
  padding-top: 7px !important;
`

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    width: 60em;
    max-width: 60em;
  }
`

const DialogBody = styled(DialogContent)`
  &.MuiDialogContent-root {
    padding-bottom: 20px;
  }
`

interface BulkMdcHeaderInterface {
  linkToDefault: boolean
  handleLinkToDefaultChange: () => void
  user: User | null
}

const BulkMdcHeader: React.FC<BulkMdcHeaderInterface> = ({ linkToDefault, handleLinkToDefaultChange, user }) => {
  const { history } = useRouter()

  const selectedAssets = useUniqueSelectedAssets()
  const solarAssetsSelected =
    selectedAssets?.length > 0 &&
    hasPermissionForPvBulkMeterDataCleansing(user) &&
    selectedAssets.some((a) => isSolarAsset(a))
  const windAssetsSelected =
    selectedAssets?.length > 0 &&
    hasPermissionForWindBulkMeterDataCleansing(user) &&
    selectedAssets.some((a) => isWindAsset(a))

  // State
  const [showFilerSettingsDialog, setShowFilterSettingsDialog] = useState(false)

  // Filter settings handler
  const handleShowFilterSettingsDialog = () => {
    setShowFilterSettingsDialog(true)
  }

  // Close handler
  const closeHandler = () => {
    history.push(ROUTE_WORKBENCH)
  }

  // handle dialog close
  const handleDialogClose = () => {
    setShowFilterSettingsDialog(false)
  }

  return (
    <>
      <BulkMdcHeaderMainDiv justifyContent="space-between">
        <Typography variant="h1">{c('Asset Details').t`Meter data cleansing`}</Typography>

        <Flex alignItems="start">
          <CustomBulkButton
            variant="contained"
            size="small"
            startIcon={<FontAwesomeIcon icon={'filter'} size="sm" />}
            onClick={handleShowFilterSettingsDialog}
          >
            {c('BulkMdc').t`Filter settings`}
          </CustomBulkButton>

          <CloseButton key="close" aria-label="close" color="primary" onClick={closeHandler}>
            <FontAwesomeIcon icon="times" />
          </CloseButton>
        </Flex>
      </BulkMdcHeaderMainDiv>

      {showFilerSettingsDialog && (
        <CustomDialog
          open={true}
          onClose={handleDialogClose}
          onEscapeKeyDown={handleDialogClose}
          onBackdropClick={handleDialogClose}
          fullWidth
        >
          <DialogBody>
            <BulkMdcFilterSettings
              onCloseFilterSettingsDialog={handleDialogClose}
              linkToDefault={linkToDefault}
              handleLinkToDefaultChange={handleLinkToDefaultChange}
              solarAssetsSelected={solarAssetsSelected}
              windAssetsSelected={windAssetsSelected}
            />
          </DialogBody>
        </CustomDialog>
      )}
    </>
  )
}

export default BulkMdcHeader
