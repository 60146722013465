import { useQuery } from 'react-query'

import { apiRequest, useOptimisticMutation } from 'utils/request'
import axios from 'axios'
import { AreaConfig } from 'modules/dataStreams/dataStreams.types'
import { transformAreaConfigs } from 'utils/dataStream'

export const QUERY_KEY_AREA_DATA_ACTIVE_CONFIGS = 'dataStreams:areaData:activeConfigs'

const getActiveAreaForecastConfigs = async () => {
  const data = await apiRequest<AreaConfig[]>(() => axios.get('/api/usersettings/areaconfig'))
  const transformedData = transformAreaConfigs(data)
  return transformedData
}

const saveActiveAreaForecastConfigs = async (data: AreaConfig[]) => {
  return await apiRequest<AreaConfig[]>(() => axios.post('/api/usersettings/save/areaconfig', data))
}

// Hooks to fetch and update via react-query

export const useActiveAreaConfigs = () => {
  // fetch all
  return useQuery<AreaConfig[]>(QUERY_KEY_AREA_DATA_ACTIVE_CONFIGS, getActiveAreaForecastConfigs)
}

export const useAreaForecastConfigSaveMutation = () => {
  return useOptimisticMutation<AreaConfig[], AreaConfig[], AreaConfig[] | undefined>({
    queryCacheKey: QUERY_KEY_AREA_DATA_ACTIVE_CONFIGS,
    apiMutator: saveActiveAreaForecastConfigs,
    cacheUpdater: (newValue, oldData) => {
      return oldData
    },
  })
}

export const useAreaForecastConfigDeleteMutation = () => {
  const activeAreaConfigs = useActiveAreaConfigs()

  const deleteAreaConfigs = (areaIdsToDelete: string[]) => {
    const newAreaConfigs = (activeAreaConfigs.data || []).filter((areaConfig) => {
      return !areaIdsToDelete.includes(areaConfig.productCode)
    }, {})
    return saveActiveAreaForecastConfigs(newAreaConfigs)
  }

  return useOptimisticMutation<AreaConfig[], string[], AreaConfig[] | undefined>({
    queryCacheKey: QUERY_KEY_AREA_DATA_ACTIVE_CONFIGS,
    apiMutator: deleteAreaConfigs,
    cacheUpdater: (newValue, oldData) => {
      return oldData
    },
  })
}
