import axios from 'axios'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'

import { Asset } from 'modules/asset/store/asset.types'
import { STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { queryClient } from 'queryClient'
import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { c } from 'ttag'
import { flattenAndUniquifyAssetIds } from 'utils/asset'
import createReducer from 'utils/createReducer'
import { request } from 'utils/request'

// state

export enum ActivateModelsMode {
  PREVIOUS = 'PREVIOUS',
  CANDIDATE = 'CANDIDATE',
}

export enum ActivateModelsResultType {
  SUCCESS = 'SUCCESS',
  NO_PREVIOUS = 'NO_PREVIOUS',
  NO_CANDIDATE = 'NO_CANDIDATE',
  ACTIVATION_FAILED = 'ACTIVATION_FAILED',
}
export interface ActivateModelsResultForAsset {
  result: ActivateModelsResultType
  forecastModelId: string
}
export type ActivateModelsResult = Record<string, ActivateModelsResultForAsset>

interface State {
  result: Record<ActivateModelsMode, ActivateModelsResult>
  loading: Record<ActivateModelsMode, boolean>
  error: Record<ActivateModelsMode, string | null>
  success: Record<ActivateModelsMode, boolean>
}

const initialState: State = {
  result: {
    [ActivateModelsMode.PREVIOUS]: {},
    [ActivateModelsMode.CANDIDATE]: {},
  },
  loading: {
    [ActivateModelsMode.PREVIOUS]: false,
    [ActivateModelsMode.CANDIDATE]: false,
  },
  error: {
    [ActivateModelsMode.PREVIOUS]: null,
    [ActivateModelsMode.CANDIDATE]: null,
  },
  success: {
    [ActivateModelsMode.PREVIOUS]: false,
    [ActivateModelsMode.CANDIDATE]: false,
  },
}

// types

export const ACTIVATE_MODELS_REQUEST = 'ACTIVATE_MODELS_REQUEST'
export const ACTIVATE_MODELS_SUCCESS = 'ACTIVATE_MODELS_SUCCESS'
export const ACTIVATE_MODELS_FAILURE = 'ACTIVATE_MODELS_FAILURE'
export const ACTIVATE_MODELS_DISMISS = 'ACTIVATE_MODELS_DISMISS'
export const ACTIVATE_MODELS_RESET = 'ACTIVATE_MODELS_RESET'
export type ACTIVATE_MODELS_REQUEST = typeof ACTIVATE_MODELS_REQUEST
export type ACTIVATE_MODELS_SUCCESS = typeof ACTIVATE_MODELS_SUCCESS
export type ACTIVATE_MODELS_FAILURE = typeof ACTIVATE_MODELS_FAILURE
export type ACTIVATE_MODELS_DISMISS = typeof ACTIVATE_MODELS_DISMISS
export type ACTIVATE_MODELS_RESET = typeof ACTIVATE_MODELS_RESET
export type ACTIVATE_MODELS_ACTION_TYPE =
  | ACTIVATE_MODELS_REQUEST
  | ACTIVATE_MODELS_SUCCESS
  | ACTIVATE_MODELS_FAILURE
  | ACTIVATE_MODELS_DISMISS
  | ACTIVATE_MODELS_RESET

interface ActivateModelsAction {
  type: ACTIVATE_MODELS_ACTION_TYPE
  // REQUEST
  assets: Asset[]
  mode: ActivateModelsMode
  // SUCCESS
  result: ActivateModelsResult
  // ERROR
  error: State['error']
}

// reducers

const result = createReducer<State['result'], ActivateModelsAction>(
  (state = initialState.result, { type, mode, result }) => {
    switch (type) {
      case ACTIVATE_MODELS_SUCCESS:
      case ACTIVATE_MODELS_FAILURE:
        return {
          ...state,
          [mode]: result,
        }
    }
    return state
  },
)

const loading = createReducer<State['loading'], ActivateModelsAction>(
  (state = initialState.loading, { type, mode }) => {
    switch (type) {
      case ACTIVATE_MODELS_REQUEST:
        return {
          ...state,
          [mode]: true,
        }
      case ACTIVATE_MODELS_SUCCESS:
      case ACTIVATE_MODELS_FAILURE:
        return {
          ...state,
          [mode]: false,
        }
    }
    return state
  },
)

const error = createReducer<State['error'], ActivateModelsAction>(
  (state = initialState.error, { type, mode, error }) => {
    switch (type) {
      case ACTIVATE_MODELS_SUCCESS:
      case ACTIVATE_MODELS_DISMISS:
      case ACTIVATE_MODELS_RESET:
        return {
          ...state,
          [mode]: null,
        }
      case ACTIVATE_MODELS_FAILURE:
        return {
          ...state,
          [mode]: error,
        }
    }
    return state
  },
)

const success = createReducer<State['success'], ActivateModelsAction>(
  (state = initialState.success, { type, mode }) => {
    switch (type) {
      case ACTIVATE_MODELS_SUCCESS:
        return {
          ...state,
          [mode]: true,
        }
      case ACTIVATE_MODELS_REQUEST:
      case ACTIVATE_MODELS_FAILURE:
      case ACTIVATE_MODELS_RESET:
        return {
          ...state,
          [mode]: false,
        }
    }
    return state
  },
)

export const activateModelsReducer = combineReducers({
  result,
  loading,
  error,
  success,
})

// selectors

export const activateModelsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.asset.activateModels.result,
  (result) => result,
)
export const activateModelsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.asset.activateModels.loading,
  (loading) => loading,
)
export const activateModelsErrorSelector = createSelector<any, State['error'], State['result'], State['error']>(
  (state) => state.asset.activateModels.error,
  activateModelsResultSelector,
  (error, result) => {
    return (Object.keys(result) as ActivateModelsMode[]).reduce((errPrev, mode) => {
      if (error[mode]) {
        return {
          ...errPrev,
          [mode]: error[mode],
        }
      } else {
        return {
          ...errPrev,
          [mode]: Object.keys(result[mode])
            .reduce((prev, assetId) => {
              const resultForAsset = result[mode][assetId]
              if (resultForAsset.result === ActivateModelsResultType.SUCCESS || prev.length > 0) {
                return prev
              } else {
                let error: string
                switch (resultForAsset.result) {
                  case ActivateModelsResultType.ACTIVATION_FAILED:
                    error = c('Training:Forecast Model').t`Activation failed`
                    break
                  case ActivateModelsResultType.NO_CANDIDATE:
                    error = c('Training:Forecast Model').t`No candidate available`
                    break
                  case ActivateModelsResultType.NO_PREVIOUS:
                    error = c('Training:Forecast Model').t`No previous available`
                    break
                }
                return [...prev, error]
              }
            }, [])
            .join(' - '),
        }
      }
    }, {})
  },
)

export const activateModelsSuccessSelector = createSelector<any, State['success'], State['success']>(
  (state) => state.asset.activateModels.success,
  (success) => success,
)

// api
export const activateModels = (assetIds: string[], mode: ActivateModelsMode) => {
  const data = {
    assetIds,
    type: mode,
  }
  return request(() => {
    return axios.post('/api/productconfig/v2/forecast-model/activate-batch', data)
  })
}

// sagas
export function* activateModelsSaga({ assets, mode }: ActivateModelsAction): SagaIterator {
  // TODO to get all assets we can't use hooks in sagas, so we need to find a better way than the following:
  const allAssetsProductive = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE])
  const allAssetsDraft = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT])
  const allAssets = ((allAssetsDraft || []).length > 0 ? allAssetsDraft : allAssetsProductive) || []

  const assetIdsToSet = flattenAndUniquifyAssetIds(assets, allAssets)

  const result = yield call(activateModels, assetIdsToSet, mode)

  if (result.isSuccessful) {
    yield put({ type: ACTIVATE_MODELS_SUCCESS, mode, result: result.getData() })
    queryClient.invalidateQueries(QUERY_KEY_ASSETS)
  } else {
    const error = result.getError()
    yield put({ type: ACTIVATE_MODELS_FAILURE, mode, error, result: result.getData() })
  }
}
