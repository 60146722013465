import { combineReducers } from 'redux'
import createReducer from 'utils/createReducer'
import { User } from '../../Auth.types'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { createSelector } from 'reselect'

// state

interface State {
  tempUserObj: User | null
  isLangChange: boolean
  isSuccess: boolean
  loading: boolean
  error: string | null
}

const initialState: State = {
  tempUserObj: null,
  isLangChange: false,
  isSuccess: false,
  loading: false,
  error: null,
}

export interface SaveUserAction {
  type: actionTypes.SAVE_USER_ACTION_TYPE
  // REQUEST
  user: User
}

// reducers

const tempUserObj = createReducer<State['tempUserObj'], SaveUserAction>(
  (state = initialState.tempUserObj, { type, user }) => {
    switch (type) {
      case actionTypes.SAVE_USER_TEMPORARILY:
        return user
      case actionTypes.SAVE_USER_SUCCESS:
      case actionTypes.CLEAR_SAVE_USER_STATE:
      case actionTypes.SAVE_USER_REQUEST:
      case actionTypes.SAVE_USER_FAILURE:
        return null
    }
    return state
  },
)

const isLangChange = createReducer<State['isLangChange'], SaveUserAction>(
  (state = initialState.isLangChange, { type }) => {
    switch (type) {
      case actionTypes.SAVE_USER_TEMPORARILY:
        return true
      case actionTypes.SAVE_USER_SUCCESS:
      case actionTypes.CLEAR_SAVE_USER_STATE:
      case actionTypes.SAVE_USER_REQUEST:
      case actionTypes.SAVE_USER_FAILURE:
        return false
    }
    return state
  },
)

const isSuccess = createReducer<State['isSuccess'], SaveUserAction>((state = initialState.isSuccess, { type }) => {
  switch (type) {
    case actionTypes.SAVE_USER_SUCCESS:
      return true
    case actionTypes.CLEAR_SAVE_USER_STATE:
    case actionTypes.SAVE_USER_REQUEST:
    case actionTypes.SAVE_USER_FAILURE:
      return false
  }
  return state
})

const loading = createReducer<State['loading'], SaveUserAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.SAVE_USER_REQUEST:
      return true
    case actionTypes.CLEAR_SAVE_USER_STATE:
    case actionTypes.SAVE_USER_SUCCESS:
    case actionTypes.SAVE_USER_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], SaveUserAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.SAVE_USER_SUCCESS:
      case actionTypes.SAVE_USER_DISMISS:
      case actionTypes.CLEAR_SAVE_USER_STATE:
        return null
      case actionTypes.SAVE_USER_FAILURE:
        return error
    }
    return state
  },
)

export const saveUserReducer = combineReducers({
  tempUserObj,
  isLangChange,
  isSuccess,
  loading,
  error,
})

// selectors
export const tempUserObjSelector = createSelector<any, State['tempUserObj'], State['tempUserObj']>(
  (state) => state.auth.saveUser.tempUserObj,
  (tempUserObj) => tempUserObj,
)

export const isLangChangeSelector = createSelector<any, State['isLangChange'], State['isLangChange']>(
  (state) => state.auth.saveUser.tempUserObj,
  (tempUserObj) => Boolean(tempUserObj),
)

export const saveUserIsSuccessSelector = createSelector<any, State['isSuccess'], State['isSuccess']>(
  (state) => state.auth.saveUser.isSuccess,
  (isSuccess) => isSuccess,
)

export const saveUserLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.saveUser.loading,
  (loading) => loading,
)
export const saveUserErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.saveUser.error,
  (error) => error,
)
