import React, { useCallback, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { c } from 'ttag'
import { Box, Typography } from '@material-ui/core'
import ButtonMenu from 'ui/form/ButtonMenu'
import { Asset } from 'modules/asset/store/asset.types'
import AssetLink from 'modules/asset/AssetLink'
import { useCustomMuiStyles } from 'themes/theme-light'
import { hasPermissionForMaintenance, isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

interface GenericAddAvailabilityBtnProps {
  onAddNewAvailability: (event: React.MouseEvent<HTMLElement> | null, asset: Asset) => void
  assets: Asset[]
}

const GenericAddAvailabilityBtn: React.FC<GenericAddAvailabilityBtnProps> = ({ onAddNewAvailability, assets }) => {
  //Style
  const classes = useCustomMuiStyles()
  const user = useSelector(getUserResultSelector)
  const [selectedAsset, setSelectedAsset] = useState<Asset>({} as Asset)
  const topMostLevel = assets?.[0]?.uiLevel || 0
  const isReadOnlyUser = useIsReadOnlyUser()

  const handleSelectAsset = (handleClose: any, asset: Asset) => {
    setSelectedAsset(asset)
    onAddNewAvailability(null, asset)
    handleClose()
  }

  const menuContent = useCallback(
    (handleClose) => (
      <>
        <Box mx={2} my={1}>
          <Typography variant="subtitle2">{c('Availability').t`Select Asset`}</Typography>
        </Box>
        {assets.map((asset) => (
          <MenuItem
            key={asset.id}
            selected={asset === selectedAsset}
            onClick={() => handleSelectAsset(handleClose, asset)}
          >
            <Box ml={2 * (asset.uiLevel - topMostLevel)}>
              <AssetLink asset={asset} />
            </Box>
          </MenuItem>
        ))}
      </>
    ),
    [assets, handleSelectAsset],
  )

  return (
    <>
      <ButtonMenu
        className={
          (isAdmin(user) || isImpersonatedAdmin(user)) && !hasPermissionForMaintenance(user)
            ? classes.adminComponent
            : 'inherit'
        }
        text={c('Availability').t`Add`}
        icon="plus"
        color="primary"
        size="small"
        renderMenu={menuContent}
        disabled={isReadOnlyUser}
      />
    </>
  )
}

export default React.memo(GenericAddAvailabilityBtn)
