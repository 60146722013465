import { t } from 'ttag'

import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'

export enum AvailabilityTypes {
  CAP = 'CAP',
  SCALE = 'SCALE',
}

export const getAvailabilityColumns: () => Column[] = () => [
  // TODO See also PR-4047.
  // -- HSL
  {
    name: 'uiAssetName',
    label: t`Asset`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: true,
    fieldName: 'uiAssetName',
    width: '16em',
    fixed: true,
    isEditable: false,
  },
  {
    name: 'from',
    label: t`Start`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'from',
    isEditable: true,
    width: '12em',
    fixed: true,
  },
  {
    name: 'to',
    label: t`End`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'to',
    isEditable: true,
    width: '12em',
    fixed: true,
  },
  {
    name: 'type',
    label: t`Type`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'type',
    isEditable: true,
    selectItems: getAvailabilityTypes(),
    width: '8.5em',
    fixed: true,
  },
  {
    name: 'value',
    label: t`Value`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: true,
    width: '10em',
    fixed: true,
  },
  {
    name: 'description',
    label: t`Description`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fieldName: 'description',
    isEditable: true,
    width: '24em',
    fixed: true,
  },
]

export const getAvailabilityTypes = () => {
  return [
    {
      id: AvailabilityTypes.CAP,
      label: t`Capped`,
    },
    {
      id: AvailabilityTypes.SCALE,
      label: t`Scaled`,
    },
  ]
}
