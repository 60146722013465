import React from 'react'
import styled from 'styled-components'

export const Message = styled.div`
  color: black;
  min-height: 1.2em;
  font-size: 14px;
`

interface SuccessMessageProps {
  successMsg: string | string[] | null
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ successMsg }) => {
  return <Message>{successMsg && <>{successMsg}</>}</Message>
}

export default SuccessMessage
