import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { ForecastSaveMainItem } from 'modules/dataStreams/dataStreams.types'

// Query keys for caching data

export const QUERY_KEY_SITEFORECAST_SAVEMAINITEMKEY = 'dataStreams:siteForecast:saveMainItemKey'

// Async API requests to fetch and update data

// Hooks to fetch and update via react-query

export const useSiteForecastSaveMainItemKey = () => {
  return useUserSetting<ForecastSaveMainItem>(QUERY_KEY_SITEFORECAST_SAVEMAINITEMKEY)
}

export const useSiteForecastSaveMainItemKeySaveMutation = () => {
  return useUserSettingSaveMutation<ForecastSaveMainItem>(QUERY_KEY_SITEFORECAST_SAVEMAINITEMKEY)
}
