import React, { useCallback, useEffect, useMemo, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import { c, t } from 'ttag'
import Select from '@material-ui/core/Select/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import { filterSelectionByChartType } from 'utils/workspace'
import { DataStream, TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useDataStreams } from 'utils/dataStream'
import { useDispatch, useSelector } from 'react-redux'
import {
  workspaceDraftDataStreamSelectionSelector,
  workspaceDraftPenaltyWidgetSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import PopperTooltip from 'ui/PopperTooltip'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { Box } from '@material-ui/core'
import MeterDataSelectInput from 'ui/meterdata/MeterDataSelectInput'
import { DeepPartial } from 'ts-essentials'
import {
  PenaltyWidgetSelectionModes,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'

export interface PenaltyInputData {
  siteForecastId: string
  actualGenerationId?: string
  actualGeneration?: DataStream
  capacity?: string
}

const PenaltyInputSelector = styled(FormControl)`
  &.MuiFormControl-root {
    margin-top: -1em;
    width: 13em;
  }
`

interface PenaltyCalculationInputProps {
  onUpdatePenaltyInput: (data: PenaltyInputData) => void
}

const PenaltyCalculationInput: React.FC<PenaltyCalculationInputProps> = ({ onUpdatePenaltyInput }) => {
  const dispatch = useDispatch()
  const PenaltyWidgetInputSelectionInDraft = useSelector(workspaceDraftPenaltyWidgetSelector)

  const [dropDownValue, setDropDownValue] = useState('')

  const [penaltyInputData, setPenaltyInputData] = useState<PenaltyInputData>({
    siteForecastId: '',
    actualGenerationId: '',
  })

  const dataStreams = useDataStreams()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)

  const selectedSiteForecastItems = useMemo(() => {
    const siteForecast = filterSelectionByChartType(dataStreams, dataSelection, TimeSeriesType.SITE_FORECAST)
    const scheduleForecast = filterSelectionByChartType(dataStreams, dataSelection, TimeSeriesType.SCHEDULE)
    const e3SelectedDataStreams = filterSelectionByChartType(
      dataStreams,
      dataSelection,
      TimeSeriesType.E3_META_FORECAST,
    )
    const e3ThirdPartyForecast = (e3SelectedDataStreams || [])?.filter(
      (e3DataStream) => e3DataStream.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST,
    )

    const forecastSelection = [...siteForecast, ...scheduleForecast, ...e3ThirdPartyForecast]

    // This is used to reorder forecast selection (in dataSelection order), since we are now using site forecast and schedule
    const orderedForecastSelection = forecastSelection.sort(
      (a, b) => dataSelection.indexOf(a) - dataSelection.indexOf(b),
    )

    return orderedForecastSelection.length > 0
      ? orderedForecastSelection.map((selectedItem) => {
          const item = dataStreams.find((ds) => ds.id === selectedItem.id)
          return { ...selectedItem, name: item.name }
        })
      : undefined
  }, [dataStreams, dataSelection])

  const selectedActualGenerationItems = useMemo(() => {
    const ActualGenerationSelection = dataStreams?.filter((item) => item.type === TimeSeriesType.METER_DATA)
    return ActualGenerationSelection
  }, [dataStreams, dataSelection])

  const handlePenaltyInputData = useCallback((event) => {
    const value = event?.target?.value

    // update draft with the latest selection
    const draft: DeepPartial<WorkspaceConfig> = {
      penaltyWidget: {
        selectionMode: PenaltyWidgetSelectionModes.DROPDOWN,
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })

    setDropDownValue(value)
  }, [])

  const handleChangeMeterDataSelection = (id: string) => {
    setPenaltyInputData((prevState) => {
      return { ...prevState, ['actualGenerationId']: id }
    })
  }

  // This useEffect is used to handle selection from data stream selection
  useEffect(() => {
    const siteForecastId =
      selectedSiteForecastItems && selectedSiteForecastItems?.length > 0
        ? selectedSiteForecastItems[selectedSiteForecastItems?.length - 1]?.id
        : undefined

    if (siteForecastId && PenaltyWidgetInputSelectionInDraft.selectionMode === PenaltyWidgetSelectionModes.SELECTION) {
      setPenaltyInputData((prevState) => {
        return { ...prevState, siteForecastId }
      })
    }
  }, [JSON.stringify(selectedSiteForecastItems), PenaltyWidgetInputSelectionInDraft])

  // This useEffect is used to handle selection from input field
  useEffect(() => {
    const savedSiteForecastId = PenaltyWidgetInputSelectionInDraft.siteForecastId
      ? PenaltyWidgetInputSelectionInDraft.siteForecastId
      : undefined

    const lastSelectedSiteForecastId =
      selectedSiteForecastItems && selectedSiteForecastItems?.length > 0
        ? selectedSiteForecastItems[selectedSiteForecastItems?.length - 1]?.id
        : undefined

    const isSavedSiteForecastIdPartOfSelection = selectedSiteForecastItems?.find((el) => el.id === savedSiteForecastId)

    if (PenaltyWidgetInputSelectionInDraft.selectionMode === PenaltyWidgetSelectionModes.DROPDOWN) {
      setPenaltyInputData((prevState) => {
        return {
          ...prevState,
          siteForecastId:
            dropDownValue || (isSavedSiteForecastIdPartOfSelection ? savedSiteForecastId : lastSelectedSiteForecastId),
        }
      })
    }
  }, [PenaltyWidgetInputSelectionInDraft, dropDownValue, JSON.stringify(selectedSiteForecastItems)])

  // This useEffect is used to handle the scenario when there is no selectionMode saved
  useEffect(() => {
    const siteForecastId =
      selectedSiteForecastItems && selectedSiteForecastItems?.length > 0
        ? selectedSiteForecastItems[selectedSiteForecastItems?.length - 1]?.id
        : undefined

    if (siteForecastId && !PenaltyWidgetInputSelectionInDraft.selectionMode) {
      setPenaltyInputData((prevState) => {
        return { ...prevState, siteForecastId }
      })
    }
  }, [JSON.stringify(selectedSiteForecastItems), PenaltyWidgetInputSelectionInDraft])

  // This useEffect is used to save the selected siteForecastId , to use after reload
  useEffect(() => {
    if (penaltyInputData.siteForecastId) {
      // update draft with the latest selection
      const draft: DeepPartial<WorkspaceConfig> = {
        penaltyWidget: {
          siteForecastId: penaltyInputData.siteForecastId,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    }
  }, [penaltyInputData])

  useEffect(() => {
    if (penaltyInputData?.siteForecastId || penaltyInputData?.actualGenerationId) {
      let actualGeneration
      if (penaltyInputData?.actualGenerationId) {
        actualGeneration = selectedActualGenerationItems?.find(
          (item) => item.id === penaltyInputData?.actualGenerationId,
        )
      }
      onUpdatePenaltyInput({ ...penaltyInputData, actualGeneration })
    }
  }, [penaltyInputData, selectedActualGenerationItems])

  return (
    <>
      <PenaltyInputSelector
        variant="standard"
        fullWidth
        disabled={!(selectedSiteForecastItems && selectedSiteForecastItems.length > 0)}
      >
        <InputLabel id="site-forecast-label">{c('Data Stream').t`Site Forecast`}</InputLabel>
        <Select
          labelId="site-forecast-label"
          id="site-forecast"
          value={
            selectedSiteForecastItems && selectedSiteForecastItems?.length > 0 ? penaltyInputData?.siteForecastId : ''
          }
          onChange={(event) => handlePenaltyInputData(event)}
          label={c('Data Stream').t`Site Forecast`}
        >
          {(selectedSiteForecastItems || []).map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </PenaltyInputSelector>
      <Box ml={1} mr={1}>
        {'vs.'}
      </Box>
      <MeterDataSelectInput
        disabled={true}
        showSelectedItems={false}
        onChangeMeterDataSelection={handleChangeMeterDataSelection}
      />

      <PopperTooltip
        popperLabel={
          <Box ml={0.5} mt={0.3}>
            <FontAwesomeActionIcon icon="info" size="sm" />
          </Box>
        }
        popperContent={t`Please select one each of the available Site Forecast and Actual Generation data streams which data shall be used for calculating the deviation.  At this time, penalty calculations always use the latest available meter data information (live SCADA data for final meter data, as available). Selection of another meter data stream is therefore not possible.`}
      />
    </>
  )
}

export default React.memo(PenaltyCalculationInput)
