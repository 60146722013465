import createReducer from 'utils/createReducer'
import { RegistrationAction } from 'modules/auth/Auth.types'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  loading: boolean
  error: string | null
  result: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  result: false,
}

const loading = createReducer<State['loading'], RegistrationAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.REGISTRATION_REQUEST:
      return true
    case actionTypes.REGISTRATION_SUCCESS:
    case actionTypes.REGISTRATION_FAILURE:
    case actionTypes.CLEAR_REGISTRATION_STATE:
      return false
  }
  return state
})

const error = createReducer<State['error'], RegistrationAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.REGISTRATION_SUCCESS:
      case actionTypes.REGISTRATION_DISMISS:
      case actionTypes.CLEAR_REGISTRATION_STATE:
        return null
      case actionTypes.REGISTRATION_FAILURE:
        return error
    }
    return state
  },
)

const result = createReducer<State['result'], RegistrationAction>((state = initialState.result, { type }) => {
  switch (type) {
    case actionTypes.REGISTRATION_SUCCESS:
      return true
    case actionTypes.REGISTRATION_FAILURE:
    case actionTypes.CLEAR_REGISTRATION_STATE:
      return false
  }
  return state
})

export const registrationReducer = combineReducers({ loading, error, result })

// Selectors
export const registrationSuccessSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.auth.registration.result,
  (result) => result,
)
export const registrationLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.registration.loading,
  (loading) => loading,
)
export const registrationErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.registration.error,
  (error) => error,
)
