import React from 'react'
import { Column } from 'modules/reTable/reTable.types'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'

interface PenaltyRegulationsTableHeaderProps {
  columns: Column[]
  isFormOpen?: boolean
  isHierarchical?: boolean
  tableHeaderHasActions?: boolean
  tableRowsHasCrudActions?: boolean
  autoWidthForHeaderWithSort?: boolean
  inheritWidthForHeaderWithSort?: boolean
}

const ReTableGenericHeader: React.FC<PenaltyRegulationsTableHeaderProps> = ({
  columns,
  isFormOpen = false,
  isHierarchical = false,
  tableHeaderHasActions = true,
  tableRowsHasCrudActions = true,
  autoWidthForHeaderWithSort = false,
  inheritWidthForHeaderWithSort = false,
}) => {
  return (
    <ReTableHeader>
      <ReTableRow>
        {tableHeaderHasActions && <ReTableHeaderActionsCell isHierarchical={isHierarchical} isFormOpen={isFormOpen} />}

        {columns.map((column, index) => (
          <ReTableCell
            key={index}
            width={column.customDynamicWidth ? column.customDynamicWidth : column.width}
            no_border={index === columns.length - 1}
          >
            <ReTableHeaderWithSort
              column={column}
              disableSort={isFormOpen}
              autoWidthForHeaderWithSort={autoWidthForHeaderWithSort}
              widthInheritanceForHeaderWithSort={inheritWidthForHeaderWithSort ? column.width : undefined}
            />
          </ReTableCell>
        ))}

        {tableRowsHasCrudActions && <ReTableCell width={editableRowActionsCellWidth}></ReTableCell>}
      </ReTableRow>
    </ReTableHeader>
  )
}

export default React.memo(ReTableGenericHeader)
