import { combineReducers } from 'redux'
import { getUserReducer } from './state/getUser'
import { saveUserReducer } from './state/saveUser'
import { getUserConfigReducer } from './state/getUserConfig'
import { setUserConfigReducer } from './state/setUserConfig'
import { deleteUserConfigReducer } from './state/deleteUserConfig'
import { loginReducer } from './state/login'
import { logoutReducer } from './state/logout'
import { registrationReducer } from 'modules/auth/redux_store/state/registration'
import { resetPasswordReducer } from 'modules/auth/redux_store/state/resetPassword'
import { activateUserReducer } from 'modules/auth/redux_store/state/activateUser'
import { changePasswordReducer } from 'modules/auth/redux_store/state/changePassword'
import { forgotPasswordReducer } from 'modules/auth/redux_store/state/forgotPassword'
import { resendConfirmationReducer } from 'modules/auth/redux_store/state/resendConfirmation'

export const authReducer = combineReducers({
  registration: registrationReducer,
  activateUser: activateUserReducer,
  login: loginReducer,
  getUser: getUserReducer,
  saveUser: saveUserReducer,
  getUserConfig: getUserConfigReducer,
  setUserConfig: setUserConfigReducer,
  deleteUserConfig: deleteUserConfigReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,
  logout: logoutReducer,
  resendConfirmation: resendConfirmationReducer,
})
