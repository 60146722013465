import React, { useCallback } from 'react'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import { getAssetQueryObj, getAssetQueryStrings, useGetFullPath, useQueryString } from 'utils/query-string'
import { Link } from 'react-router-dom'
import { c } from 'ttag'

const AssetNotFound: React.FC = () => {
  const { onDeleteQueryStrings } = useQueryString()

  const handleCloseSlider = useCallback(() => {
    onDeleteQueryStrings(getAssetQueryStrings())
  }, [onDeleteQueryStrings])

  // These are shown in details not found page
  const routeToNewAsset = useGetFullPath(getAssetQueryObj())
  const createANewAssetLink = <Link to={routeToNewAsset}>{c('Asset Details:Not found').t`new asset`}</Link>
  const detailsNotFoundMessage = c('Asset Details:Not found')
    .jt`Asset not found. Please select an asset from the list or create a ${createANewAssetLink}.`

  return <DetailsNotFound message={detailsNotFoundMessage} onClose={handleCloseSlider} />
}

export default AssetNotFound
