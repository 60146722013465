import React, { useCallback } from 'react'
import { IconButton } from '@material-ui/core'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

import { QUERY_KEY_BACK_CAST_TIMESERIES, QUERY_KEY_TIMESERIES } from 'modules/dataStreams/api/timeseries.api'
import {
  QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE,
  QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE,
} from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'
import { useQueryClient } from 'react-query'
import { QUERY_KEY_ALL_EVALUATION_DATA } from 'modules/workspace/advancedChartWidgets/evaluation/api/evaluation.api'

const RefreshSeriesButton: React.FC = () => {
  const queryClient = useQueryClient()
  // const isTimeSeriesFetching = useIsFetching({ queryKey: QUERY_KEY_TIMESERIES }) > 0
  // const isBackCastSeriesFetching = useIsFetching({ queryKey: QUERY_KEY_BACK_CAST_TIMESERIES }) > 0

  // const loading = isTimeSeriesFetching || isBackCastSeriesFetching

  const handleRefresh = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    // re-fetch all time series
    queryClient.invalidateQueries(QUERY_KEY_TIMESERIES)
    queryClient.invalidateQueries(QUERY_KEY_BACK_CAST_TIMESERIES)
    // re-fetch evaluation data (table view widget)
    queryClient.invalidateQueries(QUERY_KEY_ALL_EVALUATION_DATA)
    // re-calculate penalty
    queryClient.invalidateQueries(QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE)
    queryClient.invalidateQueries(QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE)
  }, [])

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleRefresh}>
        <FontAwesomeActionIcon aria-controls="simple-menu" aria-haspopup="true" icon="sync-alt" />
      </IconButton>
    </>
  )
}

export default RefreshSeriesButton
