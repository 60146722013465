import { Box, FormControl, MenuItem } from '@material-ui/core'
import { Select } from 'final-form-material-ui'
import { useAllAssets } from 'modules/asset/api/assets.api'
import AssetIcon from 'modules/asset/AssetIcon'
import { Asset, TYPE_CHP, UiAssetType, UiClusterTypes } from 'modules/asset/store/asset.types'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AnyObject, Field, FormSpy } from 'react-final-form'
import { UseMutationResult } from 'react-query'
import styled from 'styled-components'
import { t } from 'ttag'
import TimezoneInfo from 'ui/datetime/TimezoneInfo'
import TabBar from 'ui/elements/TabBar'
import ActionButton from 'ui/form/ActionButton'
import DetailsFormHeader, { FormHeaderIconWrapper } from 'ui/form/DetailsFormHeader'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import Flex from 'ui/styles/Flex'
import { AssetSaveMainItem, assetTypesWithIcons, getAssetTypeText, isCHP, isPark } from 'utils/asset'
import {
  getAssetQueryObj,
  getAssetQueryStrings,
  QUERY_ACTIVE_TAB,
  QUERY_ASSET,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import { Error } from 'utils/request'
import { getNextPrevNavigationIndex } from 'utils/route'
import { TabItem } from 'utils/tabs'

import { useAssetSaveMainItemKey, useAssetSaveMainItemKeySaveMutation } from 'modules/asset/api/assetsUserSettings.api'
import { FormHeaderContainer } from 'ui/form/form.style'
import {
  assetFormRequiredFields,
  FormSaveOptions,
  getFormSaveMenuItems,
  getInvalidFieldsErrorMessagesForCertainForm,
} from 'utils/form'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isChpRole } from 'utils/user'
import ChartSettingsMenu from 'ui/elements/ChartSettingsButton'
import { FormApi } from 'final-form'
import { assetTabNames, ClusterTypesEnum } from 'fixtures/assetForm'
import PopperTooltip from 'ui/PopperTooltip'
import { useAssetTabs } from 'utils/hooks/asset/useAssetTabs'

const SelectFormControl = styled(FormControl)`
  & .MuiMenuItem-root {
    font-size: 20px;
  }
  width: 10em;
  font-size: 1.5em;
  height: 3.2em;
`

const AssetTypeFieldContainer = styled.div`
  margin-right: 1em;
`

const TabBarWithActions = styled.div`
  display: flex;
  align-items: center;
`

const TimezoneInfoContainer = styled.div`
  margin-left: auto;
`

interface SectionHeaderProps {
  form?: FormApi
  activeTab: string
  asset: Asset
  loading: boolean
  isNew: boolean
  formChanged: boolean
  formInvalid: boolean
  enterManually: boolean
  saveResult: UseMutationResult<Asset, Error, Asset, Asset[]>
  showMDCChartTooltip: boolean
  onToggleShowMDCChartTooltip: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  extraActions?: React.ReactNode
  disableTitleInputField?: boolean
  disableSaveExternally?: boolean
}

const requiredFieldsForAssetForm = assetFormRequiredFields()

const SectionHeader: React.FC<SectionHeaderProps> = ({
  form,
  activeTab,
  asset,
  loading,
  isNew,
  formChanged,
  formInvalid,
  enterManually,
  saveResult,
  showMDCChartTooltip,
  onToggleShowMDCChartTooltip,
  onFormSubmit,
  extraActions,
  disableTitleInputField,
  disableSaveExternally,
}) => {
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const assetTabs = useAssetTabs(asset)
  const { queryParams } = useQueryParams()
  // Get logged in user data
  const [assetTypeWithIcon, setAssetTypeWithIcon] = useState([])
  const user = useSelector(getUserResultSelector)
  // Get all Asset Type with Icons. Used in dropdown values.
  let availableAssetTypesWithIcons = assetTypesWithIcons()

  const saveMainItemKey = useAssetSaveMainItemKey()
  const {
    mutate: saveMainMutation,
    isSuccess: saveMenuItemSuccess,
    reset,
    isLoading: assetSaveOptionLoading,
  } = useAssetSaveMainItemKeySaveMutation()

  const saveMenuItems = useMemo(() => {
    const items = getFormSaveMenuItems().filter((item) =>
      isNew ? item.key != FormSaveOptions.SAVE_AND_NEXT : item.key !== FormSaveOptions.SAVE_AND_NEW,
    )
    // Remove copy for new asset and park with turbines inside it
    return isNew || (isPark(asset) && !enterManually) || activeTab === assetTabNames.meterDataCleansing
      ? items.filter((item) => item.key != FormSaveOptions.CREATE_COPY)
      : items
  }, [isNew, asset, enterManually, activeTab])

  const savedMainItem = useMemo(() => {
    const mainItem = isNew ? saveMainItemKey.data?.createAsset : saveMainItemKey.data?.updateAsset
    if (isPark(asset) && !enterManually && mainItem === FormSaveOptions.CREATE_COPY) {
      return FormSaveOptions.SAVE_AND_CLOSE
    }
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNew, saveMainItemKey.data, enterManually, asset])

  const allAssets = useAllAssets()
  const filteredAssetIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_ASSETS)
  const filteredAssets = useMemo(() => {
    return (allAssets.data || []).filter((asset) => filteredAssetIds.includes(asset.id))
  }, [allAssets.data, filteredAssetIds])

  const handleCloseForm = useCallback(() => {
    onDeleteQueryStrings(getAssetQueryStrings())
  }, [onDeleteQueryStrings])

  const handleNextPrevAsset = useCallback(
    (nextAsset: boolean) => {
      const displayedAssetId = queryParams[QUERY_ASSET]
      const newIndex = getNextPrevNavigationIndex(filteredAssets, displayedAssetId, nextAsset)
      if (newIndex !== undefined) {
        onUpdateQueryString(getAssetQueryObj(filteredAssets[newIndex], queryParams))
      }
    },
    [filteredAssets, onUpdateQueryString, queryParams],
  )

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      const { SAVE_AND_CLOSE } = FormSaveOptions
      if (savedMainItem !== item.key) {
        const data: AssetSaveMainItem = {
          createAsset: isNew ? item.key : saveMainItemKey.data?.createAsset || SAVE_AND_CLOSE,
          updateAsset: !isNew ? item.key : saveMainItemKey.data?.updateAsset || SAVE_AND_CLOSE,
        }
        saveMainMutation(data)
      }
    },
    [isNew, saveMainMutation, saveMainItemKey.data, savedMainItem],
  )

  const handleTabChange = useCallback(
    (tab: TabItem) => {
      onUpdateQueryString({ [QUERY_ACTIVE_TAB]: tab.name })
    },
    [onUpdateQueryString],
  )

  const handleUpdateUiAssetType = (value: UiAssetType) => {
    const { CLUSTER, CLUSTER_HYBRID, CLUSTER_POOL } = UiClusterTypes
    form?.mutators?.setValue('uiAssetType', value)

    if (value === CLUSTER || value === CLUSTER_HYBRID || value === CLUSTER_POOL) {
      const { HYBRID, POOL, COLLECTION } = ClusterTypesEnum
      const clusterType = value === CLUSTER_HYBRID ? HYBRID : value === CLUSTER_POOL ? POOL : COLLECTION
      form?.mutators?.setValue('clusterType', clusterType)
    } else {
      // TODO Check this it can be removed
      form?.mutators?.setValue('clusterType', '')
    }
  }

  useEffect(() => {
    if (!isChpRole(user)) {
      availableAssetTypesWithIcons = availableAssetTypesWithIcons.filter(
        (singleAssetTypeWithIcon) => singleAssetTypeWithIcon.value !== TYPE_CHP,
      )
    }
    setAssetTypeWithIcon(availableAssetTypesWithIcons)
  }, [user])

  useEffect(() => {
    if (saveMenuItemSuccess) {
      onFormSubmit()
      reset()
    }
  }, [saveMenuItemSuccess])

  return (
    <FormHeaderContainer nopaddingbottom={1}>
      <Flex direction="row" justifyContent="center" alignItems="center" flexGrow={1}>
        {asset.id ? (
          <FormHeaderIconWrapper>
            <PopperTooltip
              popperLabel={<AssetIcon asset={asset} size="40" />}
              popperContent={!isCHP(asset) && <>{getAssetTypeText(asset.uiAssetType)}</>}
            />
          </FormHeaderIconWrapper>
        ) : (
          <AssetTypeFieldContainer>
            <Field name="type">
              {(props) => {
                return (
                  <SelectFormControl className="appTour-createAsset-type">
                    <Select input={props.input} meta={props.meta}>
                      {assetTypeWithIcon.map((el) => (
                        <MenuItem key={el.value} onClick={() => handleUpdateUiAssetType(el.value)} value={el.value}>
                          <Flex direction="row" alignItems="center">
                            <Box mr={1}>{el.image}</Box>
                            {el.label}
                          </Flex>
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFormControl>
                )
              }}
            </Field>
          </AssetTypeFieldContainer>
        )}
        <DetailsFormHeader
          showNextPrevOptions={!isNew}
          handleGoToNextPrevItem={handleNextPrevAsset}
          totalItems={filteredAssets.length}
          onCloseDetailsForm={handleCloseForm}
          fieldName="name"
          placeholder="Asset name"
          changed={asset.changed}
          deleted={asset.deleted}
          closeButtonClassName="appTour-createAsset-close"
          titleFieldClassName="appTour-createAsset-save"
          requiredField={true}
          extraActions={extraActions}
          disabled={disableTitleInputField}
        >
          {/*Pass save button as children*/}
          <FormSpy
            subscription={{
              dirty: true,
              touched: true,
              modifiedSinceLastSubmit: true,
            }}
          >
            {(props) => {
              const formTouched = Object.keys(props.touched || {}).some((key) => props.touched && props.touched[key])
              return (
                <ActionButton
                  className="save"
                  invalidFieldsErrorMessages={getInvalidFieldsErrorMessagesForCertainForm(
                    form as FormApi,
                    requiredFieldsForAssetForm,
                  )}
                  result={saveResult}
                  formInvalid={formInvalid}
                  formTouched={formTouched || formChanged}
                  formModifiedSinceLastSubmit={props.modifiedSinceLastSubmit}
                  disabled={loading || assetSaveOptionLoading || disableSaveExternally || !(formChanged || props.dirty)} // TODO  || assetSaveMenuItems[0].isLoading
                  menuItems={saveMenuItems}
                  mainItemKey={savedMainItem}
                  onSelectMenuItem={handleSaveMenuSelect}
                  icon="save"
                  variant="contained"
                  color="primary"
                  size="small"
                  saveOptionLoading={assetSaveOptionLoading}
                >
                  {/*default option*/}

                  {t`Save`}
                </ActionButton>
              )
            }}
          </FormSpy>
        </DetailsFormHeader>
      </Flex>

      <TabBarWithActions>
        <TabBar
          changed={asset.changed}
          isNew={isNew}
          activeTab={activeTab}
          tabs={assetTabs}
          onChange={handleTabChange}
        />
        <div>
          <ChartSettingsMenu onTooltipChange={onToggleShowMDCChartTooltip} showTooltip={showMDCChartTooltip} />
        </div>
        <TimezoneInfoContainer>
          <TimezoneInfo showTime={false} />
        </TimezoneInfoContainer>
      </TabBarWithActions>
    </FormHeaderContainer>
  )
}

export default React.memo(SectionHeader)
