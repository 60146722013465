import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'

import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { useMetaDataHierarchy, useMetaDataParams } from 'modules/dataStreams/api/metaData.api'
import { ReTableItem } from 'modules/reTable/reTable.types'
import { UniqueCatalogLabel, WeatherCatalog, WeatherConfig, WeatherDetails } from 'modules/weather/store/weather.types'
import { convertWeatherDataToTreeHierarchy } from 'utils/weather'
import { apiRequest, queryOptionsForStaticData, useOptimisticMutation } from 'utils/request'
import { LanguageKey } from 'fixtures/header'
import { useQuery } from 'react-query'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { getSavedLanguage } from 'utils/user'

// Query keys for caching data

export const QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS = 'dataStreams:weatherData:activeConfigs'
export const QUERY_KEY_WEATHER_DATA_CATALOG = 'dataStreams:weatherData:catalog'
export const QUERY_KEY_WEATHER_DATA_SELECTED = 'dataStreams:weatherData:selected'

// Async API requests to fetch and update data

const getWeatherDataCatalog = async (lang: LanguageKey) => {
  return await apiRequest<WeatherCatalog>(() =>
    axios.get(`/api/weather-data-catalog/v1/all?lang=${lang.toUpperCase()}`),
  )
}

const getWeatherDataCatalogItem = async (id: keyof UniqueCatalogLabel) => {
  return await apiRequest<WeatherDetails>(() => axios.get(`/api/weather-data-catalog/v1/products/${id}`))
}

const getActiveWeatherConfigs = async () => {
  return await apiRequest<WeatherConfig[]>(() => axios.get('/api/usersettings/weatherconfig'))
}

const saveActiveWeatherConfigs = async (data: WeatherConfig[]) => {
  return await apiRequest<WeatherConfig[]>(() => axios.post('/api/usersettings/save/weatherconfig', data))
}

// Hooks to fetch and update via react-query

export const useActiveWeatherConfigs = () => {
  // fetch all
  return useQuery<WeatherConfig[]>(QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS, getActiveWeatherConfigs)
}

export const useWeatherConfigSaveMutation = () => {
  return useOptimisticMutation<WeatherConfig[], WeatherConfig[], WeatherConfig[] | undefined>({
    queryCacheKey: QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS,
    apiMutator: saveActiveWeatherConfigs,
    cacheUpdater: (newValue, oldData) => {
      return oldData
    },
  })
}

export const useWeatherConfigDeleteMutation = () => {
  const activeWeatherConfigs = useActiveWeatherConfigs()

  const deleteWeatherConfigs = (weatherIdsToDelete: string[]) => {
    const newWeatherConfigs = (activeWeatherConfigs.data || []).filter((weatherConfig) => {
      return !weatherIdsToDelete.includes(weatherConfig.id)
    }, {})
    return saveActiveWeatherConfigs(newWeatherConfigs)
  }

  return useOptimisticMutation<WeatherConfig[], string[], WeatherConfig[] | undefined>({
    queryCacheKey: QUERY_KEY_WEATHER_DATA_ACTIVE_CONFIGS,
    apiMutator: deleteWeatherConfigs,
    cacheUpdater: (newValue, oldData) => {
      return oldData
    },
  })
}

export const useWeatherCatalog = () => {
  const user = useSelector(getUserResultSelector)
  const lang = getSavedLanguage(user) || 'en'
  return useQuery<WeatherCatalog>(
    [QUERY_KEY_WEATHER_DATA_CATALOG, lang],
    () => getWeatherDataCatalog(lang),
    queryOptionsForStaticData,
  )
}

export const useWeatherCatalogItem = (id: keyof UniqueCatalogLabel) => {
  return useQuery<WeatherDetails>([QUERY_KEY_WEATHER_DATA_CATALOG, id], () => getWeatherDataCatalogItem(id), {
    ...queryOptionsForStaticData,
    enabled: Boolean(id),
  })
}

export const useSelectedWeatherData = () => {
  return useUserSetting<string>(QUERY_KEY_WEATHER_DATA_SELECTED)
}

export const useSelectedWeatherDataSaveMutation = () => {
  return useUserSettingSaveMutation<string>(QUERY_KEY_WEATHER_DATA_SELECTED)
}

export const useWeatherDataTreeItems = () => {
  const catalog = useWeatherCatalog()
  const params = useMetaDataParams()
  const hierarchy = useMetaDataHierarchy()

  const [items, setItems] = useState<ReTableItem[]>([])

  useEffect(() => {
    if (
      catalog.isLoading ||
      params.isLoading ||
      hierarchy.isLoading ||
      !catalog.data ||
      !params.data ||
      !hierarchy.data
    ) {
      return
    }

    const weatherHierarchy = hierarchy.data.filter((item) => {
      // filter out everything that looks like this (because it's not related to weather):
      //   structure: [
      //     {
      //       type: "root",
      //       value: "alt"
      //     },
      //   ]
      return item.structure[0].value !== 'alt'
    })

    const weatherCatalog = catalog.data
    const weatherParams = params.data

    const treeItems = convertWeatherDataToTreeHierarchy({ weatherCatalog, weatherParams, weatherHierarchy })
    setItems(treeItems)
  }, [catalog.isLoading, params.isLoading, hierarchy.isLoading, catalog.data, params.data, hierarchy.data])

  return items
}
