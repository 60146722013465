import { createStory } from 'test/utils'

// stories
export const storyCategory = 'Modules/Workspace/WorkspaceHeader'
export const storyNew = createStory(storyCategory, 'new')
export const storySaved = createStory(storyCategory, 'saved')
export const storyUnsaved = createStory(storyCategory, 'unsaved')

// tests
const testIdPrefix = 'workspaceHeader'
export const testIdWorkspaceHeaderRoot = `${testIdPrefix}-root`
export const testIdWorkspaceHeaderMenu = `${testIdPrefix}-menu`
export const testIdWorkspaceHeaderMenuContent = `${testIdPrefix}-menuContent`
export const testIdWorkspaceHeaderToolbar = `${testIdPrefix}-toolbar`
export const testIdWorkspaceHeaderToolbarExportButton = `${testIdPrefix}-toolbar-export-button`
export const testIdWorkspaceHeaderToolbarRefreshButton = `${testIdPrefix}-toolbar-refresh-button`
export const testIdWorkspaceHeaderToolbarSaveButton = `${testIdPrefix}-toolbar-save-button`
export const testIdWorkspaceHeaderChartName = `${testIdPrefix}-chartName`
export const testIdWorkspaceHeaderEdited = `${testIdPrefix}-edited`
