import React from 'react'
import { Column, ReTableItem } from 'modules/reTable/reTable.types'
import { Asset } from 'modules/asset/store/asset.types'
import { isAsset } from 'utils/asset'
import SeasonalForecastAssetRow from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastAssetRow'
import SeasonalForecastDataRow from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastDataRow'
import styled from 'styled-components'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { table, theme } from 'themes/theme-light'
import { MonthlyViewModeIds } from 'utils/seasonalForecast'

const SeasonalForecastTableRow = styled(ReTableRow)`
  &.MuiTableRow-root:hover {
    background: none;
  }
`

export const IndentationValueForSeasonalTableRow = '0.6em'

const CategoryRow = styled.div`
  font-size: 11px;
  color: ${theme.palette.primary.main};
  margin-left: ${IndentationValueForSeasonalTableRow};
  text-transform: uppercase;
`

interface SeasonalForecastCategoryAndDataRowsProps {
  category: string
  assetSpecificItems: ReTableItem[]
  asset: Asset
  columns: Column[]
  tableHeaderHasActions: boolean
  setAllResultsLoading: (value: boolean) => void
  collapsedIds: string[]
  monthlyViewMode: MonthlyViewModeIds
}

const SeasonalForecastCategoryAndDataRows: React.FC<SeasonalForecastCategoryAndDataRowsProps> = ({
  category,
  assetSpecificItems,
  asset,
  columns,
  tableHeaderHasActions,
  setAllResultsLoading,
  collapsedIds,
  monthlyViewMode,
}) => {
  const forecastItems = assetSpecificItems
  const assetCollapsed = collapsedIds?.includes(asset.id)

  return (
    <>
      {assetSpecificItems.length > 0 && !assetCollapsed && monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW && (
        <SeasonalForecastTableRow key={`${asset.id}-${category}`}>
          <ReTableCell
            style={{ height: `${table.narrowRowHeight}px` }}
            colSpan={columns.length + (tableHeaderHasActions ? 1 : 0)}
          >
            <CategoryRow>{category}</CategoryRow>
          </ReTableCell>
        </SeasonalForecastTableRow>
      )}

      {forecastItems.map((row: ReTableItem | Asset) => {
        return (
          <SeasonalForecastTableRow key={row.id}>
            {isAsset(row) ? (
              <SeasonalForecastAssetRow
                key={`asset-${category}-${row.id}`}
                asset={row}
                tableHeaderHasActions={tableHeaderHasActions}
                columns={columns}
                setAllResultsLoading={setAllResultsLoading}
              />
            ) : (
              <SeasonalForecastDataRow asset={asset} columns={columns} seasonalForecastData={row} />
            )}
          </SeasonalForecastTableRow>
        )
      })}
    </>
  )
}

export default SeasonalForecastCategoryAndDataRows
