import { FormApi } from 'final-form'
import { getTableRowContextMenuActions } from 'modules/reTable/reTable.functionality'
import { CellRenderType, Column, ReTableRowContextActions } from 'modules/reTable/reTable.types'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import ReTableEditableCell from 'modules/reTable/ReTableEditableCell'
import ReTableEditableRowActionsCell from 'modules/reTable/ReTableEditableRowActionsCell'
import React, { useCallback, useMemo, useState } from 'react'
import { AnyObject } from 'react-final-form'
import { EditableRow } from 'ui/form/reactFinalFormFields.style'
import { QUERY_STRING } from 'utils/query-string'

interface ReTableEditableRowProps<ObjectType> {
  isRowEdit: boolean
  isSelected?: boolean
  rowItem: ObjectType
  columns: Column[]
  form?: FormApi
  onEditRowItem: (item: ObjectType) => void
  onDeleteRowItem: (item: ObjectType) => void
  onClickRowItem?: (item: ObjectType) => void
  selectedColumnsWidth: string
  onSubmit?: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  onClose?: () => void
  getCustomCellComponent?: (column: Column, row: ObjectType, query: string, isRowEdit: boolean) => React.ReactNode
  getCustomRowComponent?: (column: Column[], row: ObjectType, query: string, isRowEdit: boolean) => React.ReactNode
  tableHeaderHasActions: boolean
  isCustomRow?: boolean
  isShaded?: boolean
  showEditableRowActions?: boolean
  dialogText: string
  dialogContext: string
  query: string
  pageRoute?: string
  pageQueries?: QUERY_STRING[] | null
  navigationDialogKey: string
  dataTestId: string
  tableToolbarHasActions?: boolean
  customEditableRowActionsCell?: React.ReactNode
}

const ReTableEditableRow: React.FC = <ObjectType,>({
  isRowEdit,
  isSelected = false,
  rowItem,
  columns,
  form,
  onEditRowItem,
  onDeleteRowItem,
  onClickRowItem,
  onSubmit,
  onClose,
  getCustomCellComponent,
  getCustomRowComponent,
  tableHeaderHasActions,
  isCustomRow,
  isShaded = false,
  showEditableRowActions = true,
  dialogText,
  dialogContext,
  query,
  pageRoute,
  pageQueries,
  navigationDialogKey,
  dataTestId,
  tableToolbarHasActions = false,
  customEditableRowActionsCell,
}: ReTableEditableRowProps<ObjectType>) => {
  const { CUSTOM, CUSTOM_NUMERIC, NUMERIC } = CellRenderType
  const [hovered, setHovered] = useState<boolean>(false)
  const actionMenuItems = useMemo(getTableRowContextMenuActions, [])
  const handleMouseLeave = useCallback(() => {
    setHovered(false)
  }, [])

  const handleMouseOver = useCallback(() => {
    setHovered(true)
  }, [])

  const handleMenuItemClick = useCallback(
    (action: ReTableRowContextActions) => {
      const { EDIT_ROW, DELETE_ROW } = ReTableRowContextActions
      if (action === EDIT_ROW) {
        onEditRowItem(rowItem)
      }
      if (action === DELETE_ROW) {
        onDeleteRowItem(rowItem)
      }
    },
    [rowItem],
  )

  const handleRowClick = useCallback(() => {
    if (onClickRowItem) {
      onClickRowItem(rowItem)
    }
  }, [onClickRowItem, rowItem])

  return (
    <EditableRow
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleRowClick}
      is_selected={isSelected ? 1 : 0}
      is_edit={isRowEdit ? 1 : 0}
      is_shaded={isShaded ? 1 : 0}
      data-testid={isCustomRow ? '' : dataTestId}
    >
      {isCustomRow && getCustomRowComponent ? (
        // Custom row, define this method in the parent and return the custom row
        getCustomRowComponent(columns, rowItem, '', isRowEdit)
      ) : (
        <>
          {tableHeaderHasActions && <ReTableCell no_border={true}></ReTableCell>}
          {columns.map((column, index) => (
            <ReTableCell
              key={column.name}
              align={column.cellRenderType === NUMERIC || column.cellRenderType === CUSTOM_NUMERIC ? 'right' : 'left'}
              no_border={index === columns.length - 1}
            >
              {(column.cellRenderType === CUSTOM || column.cellRenderType === CUSTOM_NUMERIC) &&
              getCustomCellComponent ? (
                // Custom cell, define this method in the parent and return the custom cell
                getCustomCellComponent(column, rowItem, query, isRowEdit, hovered)
              ) : (
                <ReTableEditableCell
                  query={query}
                  column={column}
                  isRowEdit={isRowEdit}
                  item={rowItem}
                  fieldAutoFocus={false}
                  tableToolbarHasActions={tableToolbarHasActions}
                />
              )}
            </ReTableCell>
          ))}
        </>
      )}

      {customEditableRowActionsCell && hovered ? (
        customEditableRowActionsCell
      ) : (
        <ReTableEditableRowActionsCell
          showContextMenu={showEditableRowActions}
          showFormActions={isRowEdit}
          actionMenuItems={actionMenuItems}
          onClickMenuItem={handleMenuItemClick}
          onSubmit={onSubmit}
          onClose={onClose}
          form={form}
          dialogText={dialogText}
          dialogContext={dialogContext}
          pageRoute={pageRoute}
          pageQueries={pageQueries}
          navigationDialogKey={navigationDialogKey}
          showAddActions={false}
          showTableRowContextMenu={true}
        />
      )}
    </EditableRow>
  )
}

export default React.memo(ReTableEditableRow)
