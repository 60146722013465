import React from 'react'
import { Grid } from '@material-ui/core'
import { msgid, ngettext, t } from 'ttag'

import { MeterdataSummary } from 'modules/asset/store/asset.types'
import { formatDate } from 'utils/date'
import { useSelector } from 'react-redux'
import { getMeterdataFilesLoadingSelector } from 'modules/data/fileManager/redux_store/state/getMeterdataFiles.state'
import InlineLoading from 'ui/InlineLoading'
import styled from 'styled-components'

interface AssetMeterdataDescriptionProps {
  summary: MeterdataSummary
  toolTip: boolean
}

const LoadingContainer = styled.span`
  height: 1.3em;
  width: 1.3em;
`

const AssetMeterdataDescription: React.FC<AssetMeterdataDescriptionProps> = ({ summary, toolTip }) => {
  const loading = useSelector(getMeterdataFilesLoadingSelector)

  const dayCount = summary?.availabilityDays ? summary.availabilityDays : 0
  const daysText = ngettext(msgid`${dayCount} day`, `${dayCount} days`, dayCount)
  const hint =
    dayCount < 180
      ? t`Possibly insufficient amount of power data for forecast model training`
      : t`Enough power data available for forecast model training`

  // const lastUpload = (
  //   <>
  //     <Key>{t`Latest file uploaded at`}:</Key>
  //     <Value>
  //       {summary ? <strong>{formatDate(summary.lastImport)}</strong> : <em>{t`No file uploaded`}</em>}
  //     </Value>
  //   </>
  // )

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          {t`Amount`}: <strong>{daysText}</strong> {toolTip && <span>({hint})</span>}
          {!toolTip && dayCount > 0 && (
            <>
              ({t`Latest timestamp (UTC)`}: <strong>{formatDate(summary.lastTimeseriesTimestamp, 'UTC')}</strong>)
            </>
          )}
          <LoadingContainer>{loading && <InlineLoading />}</LoadingContainer>
        </Grid>
        {toolTip && (
          <Grid item>
            {t`Latest timestamp (UTC)`}:{' '}
            {summary ? (
              <strong>{formatDate(summary.lastTimeseriesTimestamp, 'UTC')}</strong>
            ) : (
              <em>{t`Timestamp not available`}</em>
            )}
          </Grid>
        )}
      </Grid>
      {/*<KeyValueGrid>{daysAvailable}</KeyValueGrid>*/}
      {/*<KeyValueGrid>{lastTimestamp} </KeyValueGrid>*/}
      {/*<KeyValueGrid> {lastUpload}</KeyValueGrid>*/}
    </>
  )
}

export default React.memo(AssetMeterdataDescription)
