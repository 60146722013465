import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnSeries, HighchartsChart, PlotBand, XAxis, YAxis } from 'react-jsx-highcharts'
import styled from 'styled-components'
import { CHART_BOOST_THRESHOLD_VALUE } from 'utils/chart'
import useResizeAware from 'react-resize-aware'
import TooltipOptions from 'modules/workspace/highstock/TooltipOptions'
import { c, t } from 'ttag'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import {
  getEmptySeasonalForecastChartColumnSeries,
  getSeasonalForecastChartPlotBand,
  getSeasonalForecastSummaryResults,
  getSeasonalForecastYearAndMonths,
  MonthlyViewModeIds,
  SeasonalForecastChartColor,
} from 'utils/seasonalForecast'
import { ReTableItem } from 'modules/reTable/reTable.types'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import Highcharts, { XAxisOptions } from 'highcharts/highstock'
import { AppUnits } from 'utils/units'
import { formatNumber } from 'utils/dataFormatting'
import { getDataStreamName, getDataStreamTypeQueryLabel } from 'utils/dataStream'
import { TimeSeriesClassifier, TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { PrintViewFontSize } from 'modules/printView/PrintView'

export const ChartHeightInPrintView = 575

interface ContentProps {
  chartWidth?: any
  clipChartWidth?: any
  isPrintView?: boolean
}

// 12.5em is width of the first column of seasonal forecast , and is used only for width and margin-left
// makes chart and table in the same line
const Content = styled.div<ContentProps>`
  position: relative;
  height: ${(props) => (props.isPrintView ? `100mm` : `inherit`)};
  min-height: ${(props) => (!props.isPrintView ? '21em' : ``)};
  max-height: ${(props) => (!props.isPrintView ? `calc(100vh - 12.5em)` : ``)};
  margin-top: ${(props) => (props.isPrintView ? `2em` : ``)};
  ${(props) =>
    props.chartWidth &&
    props.clipChartWidth &&
    `width: calc(100% - (12.5em - ${props.chartWidth - props.clipChartWidth}px));`}
  ${(props) =>
    props.chartWidth &&
    props.clipChartWidth &&
    `margin-left: calc(12.5em - ${props.chartWidth - props.clipChartWidth}px);`}
`

interface SeasonalForecastChartProps {
  seasonalForecastItems: ReTableItem[]
  allResultsLoading: boolean
  showTableData: boolean
  isPrintView?: boolean
  monthlyViewMode: MonthlyViewModeIds
}

const SeasonalForecastChart: React.FC<SeasonalForecastChartProps> = ({
  seasonalForecastItems,
  allResultsLoading,
  showTableData,
  isPrintView = false,
  monthlyViewMode,
}) => {
  const [chartWidth, setChartWidth] = useState()
  const [clipChartWidth, setClipChartWidth] = useState()
  const generationLabel = getDataStreamName({
    type: TimeSeriesType.CLIMATOLOGY,
    subType: TimeSeriesSubType.CLIMATOLOGY,
    classifier: TimeSeriesClassifier.CLIMATOLOGY_GENERATION,
  })

  const additionalInfo = t`Generation - Total`
  const climatologyDataStreamName =
    getDataStreamTypeQueryLabel(TimeSeriesSubType.CLIMATOLOGY)?.label + ` - ${additionalInfo}` || ''
  const seasonalForecastDataStreamName =
    getDataStreamTypeQueryLabel(TimeSeriesSubType.SEASONAL_FORECAST)?.label + ` - ${additionalInfo}` || ''

  const seasonalForecastDynamicTooltipValue = (monthIndex: number, monthlyViewMode: MonthlyViewModeIds) => {
    let toolTipValue = ''
    if (monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW) {
      if (monthIndex <= 5) {
        toolTipValue = t`Meter data` + ` - ${additionalInfo}`
      } else if (monthIndex === 6) {
        toolTipValue = t`Intramonth Forecast` + ` - ${additionalInfo}`
      } else {
        toolTipValue = seasonalForecastDataStreamName
      }
    } else {
      if (monthIndex === 0) {
        toolTipValue = t`Intramonth Forecast` + ` - ${additionalInfo}`
      } else {
        toolTipValue = seasonalForecastDataStreamName
      }
    }

    return toolTipValue
  }

  const [resizeListener, { width, height }] = useResizeAware()
  const [chart, setChart] = useState<any>()

  // Getting seasonal forecast summary
  const seasonalForecastSummaryResults = getSeasonalForecastSummaryResults(seasonalForecastItems, monthlyViewMode)

  // Getting array of years and months
  const seasonalForecastMonths = getSeasonalForecastYearAndMonths(monthlyViewMode)

  // Getting plot band
  const seasonalForecastPlotBand = getSeasonalForecastChartPlotBand(monthlyViewMode)

  // Chart plot additional options
  const plotOptions = useMemo(() => {
    return {
      column: {
        dataGrouping: {
          enabled: true, // NOTE: disable data grouping so that we don't skip intermediate column bars
        },
        stacking: undefined,
        turboThreshold: 0, // NOTE: disable turbo threshold to avoid disappearing column bars
      },
      series: {
        pointWidth: 35,
        marker: {
          enabled: false,
          states: {
            hover: { enabled: false },
          },
        },
        grouping: true,
        animation: false,
        turboThreshold: 0,
        boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
        connectNulls: false,
        states: {
          inactive: {
            enabled: true, // do not fade out other series if one is hovered
          },
        },
      },
    }
  }, [])

  // Tooltip additional options
  // We are using this formatter because we have used type column range but for this type we need to show only max value.
  const tooltipOptions = useMemo(
    () => ({
      formatter: function () {
        if (!this.points) return false
        const { x, y } = this
        const pointObject = this.points.find((p: Highcharts.Point) => {
          return p.x === x && p.y === y
        })
        if (!pointObject) return false

        const thisPoint: Highcharts.Point = pointObject.point

        const thisSeries = thisPoint.series
        const chart = thisSeries.chart
        const allSeries = chart.series

        const tooltipContent = []

        allSeries.forEach((series) => {
          if (series.points[thisPoint.index].y) {
            const value = formatNumber({
              data: series.points[thisPoint.index].y,
              showFractionalPart: true,
              forceFractionalPart: true,
              separator: true,
              limit: 3,
            })

            tooltipContent.push(
              `<span style="font-size: 16px; color: ${series.color}">\u25CF</span> ${
                series.points[thisPoint.index].name ? series.points[thisPoint.index].name : series.name
              }: <strong>${value} ${AppUnits.GIGA_WATT_HOUR}</strong><br/>`,
            )
          }
        })

        return tooltipContent.length > 0 ? tooltipContent : false
      },
      distance: 20,
    }),
    [],
  )

  // Chart additional options
  const chartOptions = { zoomType: undefined }

  const handleChartMounted = useCallback((c) => {
    setChart(c)
  }, [])

  const initialChartOptions = useMemo(
    () => ({
      height: '100%',
      width: '100%',
      spacingRight: 0,
    }),
    [],
  )

  const xAxis: XAxisOptions = useMemo(() => {
    return {
      labels: {
        style: {
          fontSize: isPrintView ? PrintViewFontSize : '11px',
        },
      },
    }
  }, [isPrintView])

  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }
  }, [chart, width, height])

  useEffect(() => {
    if (chart) {
      setChartWidth(chart.chartWidth)
      setClipChartWidth(chart.clipBox.width)
    }
  }, [chart])

  const solarAssetsSeasonalGenerationData: Record<string, number> =
    seasonalForecastSummaryResults.solarEnergyYieldSummary
  const windAssetsSeasonalGeneration: Record<string, number> = seasonalForecastSummaryResults.windEnergyYieldSummary
  const hasSeasonalGeneration =
    Object.values(solarAssetsSeasonalGenerationData).length || Object.values(windAssetsSeasonalGeneration).length

  const allAssetsSeasonalGeneration = useMemo(() => {
    if (hasSeasonalGeneration) {
      const seasonalGeneration = seasonalForecastMonths.map((month, index) => {
        const solarAssetValue = solarAssetsSeasonalGenerationData?.[month] || 0
        const windAssetValue = windAssetsSeasonalGeneration?.[month] || 0
        return {
          name: seasonalForecastDynamicTooltipValue(index, monthlyViewMode),
          x: index,
          y: solarAssetValue + windAssetValue,
        }
      })
      return seasonalGeneration
    } else return getEmptySeasonalForecastChartColumnSeries()
  }, [
    hasSeasonalGeneration,
    solarAssetsSeasonalGenerationData,
    windAssetsSeasonalGeneration,
    seasonalForecastMonths,
    monthlyViewMode,
  ])

  const allAssetsClimatologyGeneration =
    Object.values(seasonalForecastSummaryResults.climatologySummary).length > 0
      ? Object.values(seasonalForecastSummaryResults.climatologySummary)
      : []

  const hasData = hasSeasonalGeneration || allAssetsClimatologyGeneration.length

  return (
    <Content chartWidth={chartWidth} clipChartWidth={clipChartWidth} isPrintView={isPrintView}>
      {resizeListener}
      <HighchartsChart chart={initialChartOptions} plotOptions={plotOptions} callback={handleChartMounted}>
        <GenericOptions
          message={
            (!hasData && !allResultsLoading) || !showTableData
              ? c('Workbench:Quality').t`Current selection provides no data.`
              : ''
          }
        />
        <ChartOptions options={chartOptions} />
        <LoadingOptions isLoading={allResultsLoading && showTableData} />
        <TooltipOptions options={tooltipOptions} />
        <XAxis {...xAxis} categories={seasonalForecastMonths}>
          {hasData && seasonalForecastPlotBand.map((plotBand, index) => <PlotBand key={index} {...plotBand} />)}
        </XAxis>
        <YAxis gridLineColor="white" gridLineWidth={0.5}>
          <YAxis.Title>{`${generationLabel} ${AppUnits.GIGA_WATT_HOUR}`}</YAxis.Title>

          {allAssetsClimatologyGeneration.length > 0 && (
            <ColumnSeries
              name={climatologyDataStreamName}
              data={allAssetsClimatologyGeneration}
              borderColor="white"
              color={SeasonalForecastChartColor.CLIMATOLOGY_GENERATION}
              pointPlacement={0.09}
              zIndex={1}
              index={1}
            />
          )}

          {allAssetsSeasonalGeneration.length > 0 && (
            <ColumnSeries
              name={seasonalForecastDataStreamName}
              data={allAssetsSeasonalGeneration}
              borderColor="white"
              color={SeasonalForecastChartColor.SEASONAL_GENERATION}
              pointPlacement={allAssetsClimatologyGeneration.length === 0 ? 0 : -0.09}
              zIndex={2}
              index={2}
            />
          )}

          {/*<ColumnSeries*/}
          {/*  name={t`Climatology`}*/}
          {/*  data={allAssetsClimatologyGeneration}*/}
          {/*  borderColor="transparent"*/}
          {/*  color={SeasonalForecastDataColors.CLIMATOLOGY}*/}
          {/*/>*/}

          {/*/!*This is a trick to show only a line in column series , highchart doesn't support such a thing*!/*/}
          {/*{allAssetsClimatologyGeneration.length > 0 && (*/}
          {/*  <Series*/}
          {/*    data={allAssetsClimatologyGeneration}*/}
          {/*    name={t`Climatology`}*/}
          {/*    color={SeasonalForecastDataColors.CLIMATOLOGY}*/}
          {/*    borderColor="transparent"*/}
          {/*    type="columnrange"*/}
          {/*    minPointLength={3}*/}
          {/*  />*/}
          {/*)}*/}
        </YAxis>
      </HighchartsChart>
    </Content>
  )
}

export default SeasonalForecastChart
