import { Step } from 'react-joyride'

export interface AppTourSettings {
  startOnBoardingTour?: boolean
  endOnBoardingTour?: boolean
  enableTour: boolean
}

export enum AppTours {
  ADD_ASSET = 'ADD_ASSET',
  ADD_DELIVERY_TARGET = 'ADD_DELIVERY_TARGET',
  ADD_SITE_FORECAST = 'ADD_SITE_FORECAST',
  ACTIVATE_DRAFT = 'ACTIVATE_DRAFT',
  ADD_DELIVERY_TARGET_TO_SITE_FORECAST = 'ADD_DELIVERY_TARGET_TO_SITE_FORECAST',
}

export enum TourStepCategory {
  FORM = 'FORM',
  TABLE_OR_PAGE = 'TABLE_OR_PAGE',
  WORKBENCH = 'WORKBENCH',
}

export interface AppTourStep extends Step {
  stepCategory: TourStepCategory
  tourContext: AppTours
}

export interface StepModel {
  name?: string
  active?: boolean
  done?: boolean
}
