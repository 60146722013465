import React, { useCallback, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { c, t } from 'ttag'
import {
  ForecastConfig,
  SiteForecastQualityConfig,
  SiteForecastUpdateTimes,
} from 'modules/dataStreams/dataStreams.types'
import { FormApi } from 'final-form'
import { FieldArray } from 'react-final-form-arrays'
import SectionForecastQualityEvaluationDetails from 'modules/dataStreams/siteForecast/SectionForecastQualityEvaluationDetails'
import Flex from 'ui/styles/Flex'
import { getNewQualityConfigData, getIndividualTimes } from 'utils/siteForecastConfiguration'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { isNumeric } from 'utils/dataFormatting'
import { useField } from 'react-final-form'

interface SectionForecastQualityEvaluationProps {
  onFormChange: () => void
  form: FormApi<ForecastConfig>
}

const SectionForecastQualityEvaluations: React.FC<SectionForecastQualityEvaluationProps> = ({ onFormChange, form }) => {
  const updateTimesObj = useField<SiteForecastUpdateTimes>('updateTimes').input.value
  const qualityConfigs = useField<SiteForecastUpdateTimes>('qualityConfigs').input.value

  const handleSetPrimaryQualityConfig = useCallback(
    (index) => {
      const currentPrimaryConfigIndex = form.getState().values.qualityConfigs.findIndex((config) => config.primary)
      form.mutators.setValue(`qualityConfigs[${currentPrimaryConfigIndex}].primary`, false)
      form.mutators.setValue(`qualityConfigs[${index}].primary`, true)
    },
    [form.getState().values],
  )

  // delete functionality
  interface DeleteDialogProps {
    open: boolean
    index?: number
    qualityConfig?: SiteForecastQualityConfig
  }
  const [deleteDialog, setDeleteDialog] = useState<DeleteDialogProps>({
    open: false,
  })

  const handleRemoveQualityConfigConfirm = useCallback((index: number, qualityConfig: SiteForecastQualityConfig) => {
    setDeleteDialog({
      open: true,
      index,
      qualityConfig,
    })
  }, [])

  const handleRemoveQualityConfigCancel = useCallback(() => {
    setDeleteDialog({
      open: false,
    })
  }, [])

  const handleRemoveQualityConfig = useCallback(
    (index: number, qualityConfig: SiteForecastQualityConfig) => {
      if (qualityConfig.primary) {
        handleSetPrimaryQualityConfig(0)
      }
      form.mutators.remove('qualityConfigs', index)
      setDeleteDialog({
        open: false,
      })
    },
    [Boolean(form)],
  )

  const handleAddQualityConfig = useCallback(() => {
    const deliveryTimes = getIndividualTimes(updateTimesObj)
    const newQualityConfig = getNewQualityConfigData()
    newQualityConfig.deliveryTimes = deliveryTimes
    form.mutators.push('qualityConfigs', newQualityConfig)
  }, [updateTimesObj])

  return (
    <>
      {deleteDialog.open && isNumeric(deleteDialog.index) && deleteDialog.qualityConfig && (
        <ConfirmationDialog
          heading={c('Site Forecast:Quality Evaluation').t`Delete Quality Evaluation`}
          text={c('Site Forecast:Quality Evaluation').t`Do you want to delete this quality evaluation?`}
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={() => handleRemoveQualityConfig(deleteDialog.index, deleteDialog.qualityConfig)}
          onCancel={handleRemoveQualityConfigCancel}
          openDialog={deleteDialog.open}
        />
      )}

      <Typography variant="subtitle2">{c('Site Forecast:Quality Evaluation').t`Quality Evaluation`}</Typography>
      <FieldArray name="qualityConfigs">
        {({ fields }) => {
          return fields.map((name, index) => (
            <SectionForecastQualityEvaluationDetails
              key={name}
              fieldArrayKey={name}
              form={form}
              index={index}
              onFormChange={onFormChange}
              onSetPrimaryQualityConfig={handleSetPrimaryQualityConfig}
              onRemoveQualityConfig={handleRemoveQualityConfigConfirm}
            />
          ))
        }}
      </FieldArray>
      <Flex justifyContent="flex-start">
        <Box mt={1}>
          <Button onClick={handleAddQualityConfig} color="primary" variant="contained" size="small">
            {!qualityConfigs || (Array.isArray(qualityConfigs) && qualityConfigs.length === 0)
              ? c('Site Forecast:Quality Evaluation').t`Add first quality evaluation`
              : c('Site Forecast:Quality Evaluation').t`Add another quality evaluation`}
          </Button>
        </Box>
      </Flex>
    </>
  )
}

export default React.memo(SectionForecastQualityEvaluations)
