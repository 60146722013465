import { Plausibility } from 'modules/asset/plausability/Plausability.types'
import * as actionTypes from 'modules/asset/plausability/redux_store/plausability.action.types'
import createReducer from 'utils/createReducer'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  result: Plausibility[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
}

interface GetAllPlausibilityAction {
  type: actionTypes.GET_ALL_PLAUSIBILITY_ACTION_TYPE
  // SUCCESS
  plausibilityData: Plausibility[]
  // ERROR
  error: State['error']
}

const result = createReducer<State['result'], GetAllPlausibilityAction>(
  (state = initialState.result, { type, plausibilityData }) => {
    if (type === actionTypes.GET_ALL_PLAUSIBILITY_SUCCESS) {
      return plausibilityData
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetAllPlausibilityAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.GET_ALL_PLAUSIBILITY_REQUEST:
      return true
    case actionTypes.GET_ALL_PLAUSIBILITY_SUCCESS:
    case actionTypes.GET_ALL_PLAUSIBILITY_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], GetAllPlausibilityAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.GET_ALL_PLAUSIBILITY_SUCCESS:
    case actionTypes.GET_ALL_PLAUSIBILITY_DISMISS:
      return null
    case actionTypes.GET_ALL_PLAUSIBILITY_FAILURE:
      return error
  }
  return state
})

export const getAllPlausibilityReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors
export const getAllPlausibilityResultSelector = createSelector<any, Plausibility[], Plausibility[]>(
  (state) => state.asset.plausibility.getAllPlausibility.result,
  (result) => result,
)

export const getAllPlausibilityLoadingSelector = createSelector<any, boolean, boolean>(
  (state) => state.asset.plausibility.getAllPlausibility.loading,
  (loading) => loading,
)

export const getAllPlausibilityErrorSelector = createSelector<any, string | null, string | null>(
  (state) => state.asset.plausibility.getAllPlausibility.error,
  (error) => error,
)
