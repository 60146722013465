import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { ResetPasswordType } from 'modules/auth/Auth.types'
import { AuthActionButtonContainer, AuthFormContainer, AuthFormField, AuthFormTitle } from 'ui/form/authForm.style'
import Flex from 'ui/styles/Flex'
import LoadingButton from 'ui/form/LoadingButton'

import * as actionTypes from './redux_store/auth.action.types'
import {
  resetPasswordErrorSelector,
  resetPasswordLoadingSelector,
  resetPasswordResultSelector,
} from 'modules/auth/redux_store/state/resetPassword'
import { Box, Button } from '@material-ui/core'
import { t } from 'ttag'
import ErrorMessage from 'ui/form/ErrorMessage'
import { AuthForms } from 'fixtures/auth'
import { validatePasswordForm } from 'utils/formValidations'
import SuccessMessage from 'ui/form/SuccessMessage'
import PasswordStrengthBar from 'ui/form/PasswordStrengthBar'
import { QUERY_AUTH_FORM, QUERY_KEY, stringifyQueryParams, useQueryParams } from 'utils/query-string'
import PasswordField from 'ui/form/PasswordField'
import { useRouter } from 'utils/route'
import AuthHelpText, { HelpTextContainer } from 'ui/elements/AuthHelpText'

const ResetPasswordForm: React.FC = () => {
  const { login } = AuthForms
  const { history } = useRouter()

  const dispatch = useDispatch()
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false)
  const success = useSelector(resetPasswordResultSelector)
  const loading = useSelector(resetPasswordLoadingSelector)
  const error = useSelector(resetPasswordErrorSelector)
  const { queryParams } = useQueryParams()
  const resetPwdKey = useMemo(() => queryParams[QUERY_KEY], [queryParams])

  const resetPwdData: Partial<ResetPasswordType> = useMemo(() => (resetPwdKey ? { key: resetPwdKey } : {}), [
    resetPwdKey,
  ])

  const handleFormSubmit = useCallback((resetPasswordData: ResetPasswordType) => {
    dispatch({ type: actionTypes.RESET_PASSWORD_REQUEST, resetPasswordData })
  }, [])

  const handleNavigateToLogin = useCallback(() => {
    const queryParamsString = stringifyQueryParams({ [QUERY_AUTH_FORM]: login })
    history.push(`/?${queryParamsString}`)
  }, [])

  useEffect(() => {
    if (success) {
      setResetPasswordSuccess(success)
    }
    dispatch({ type: actionTypes.CLEAR_RESET_PASSWORD_STATE })
  }, [success])

  return (
    <AuthFormContainer>
      <AuthFormTitle variant="h1">{t`Reset your password`}</AuthFormTitle>

      {resetPasswordSuccess && (
        <Flex direction="column">
          <Box mb={2}>
            <SuccessMessage successMsg={t`Password reset successful.`} />
          </Box>
          <div>
            <Button color="primary" onClick={handleNavigateToLogin} variant="contained">
              {t`Back to login`}
            </Button>
          </div>
        </Flex>
      )}
      {!resetPasswordSuccess && (
        <Form
          onSubmit={handleFormSubmit}
          initialValues={resetPwdData}
          validate={validatePasswordForm}
          render={({ handleSubmit, form, values }) => {
            const activeField = form.getState().active

            return (
              <form onSubmit={handleSubmit} autoComplete="off">
                <HelpTextContainer maxWidthMarginTop="28em">
                  {activeField && <AuthHelpText activeField={activeField} />}
                </HelpTextContainer>

                <ErrorMessage errorMsg={!form.getState().modifiedSinceLastSubmit && error ? error : ''} />

                <p>{t`Enter new password.`}</p>
                <AuthFormField>
                  <PasswordField name="newPassword" label={t`New password`} />

                  <PasswordStrengthBar value={values.newPassword || ''} />
                </AuthFormField>
                <AuthFormField>
                  <PasswordField name="confirmPassword" label={t`Confirm new password`} />
                </AuthFormField>
                <Flex direction="column">
                  <AuthFormField>
                    <AuthActionButtonContainer mt={2} mb={1}>
                      <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
                        {t`Reset password`}
                      </LoadingButton>
                    </AuthActionButtonContainer>
                  </AuthFormField>
                  <AuthFormField>
                    <a onClick={handleNavigateToLogin}>{t`Back to login`}</a>
                  </AuthFormField>
                </Flex>
              </form>
            )
          }}
        />
      )}
    </AuthFormContainer>
  )
}

export default React.memo(ResetPasswordForm)
