import React, { useCallback, useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import {
  AssetFormLabel,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  NumberFieldWithoutLabel,
  SelectFieldWithoutLabel,
  TextFieldWithoutLabel,
} from 'ui/form/assetForm.style'
import { c, t } from 'ttag'
import { Field } from 'react-final-form'
import { Box, MenuItem, Typography } from '@material-ui/core'
import { Checkbox, Select } from 'final-form-material-ui'
import { CheckboxLabel } from 'ui/form/authForm.style'
import styled from 'styled-components'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import { FormApi } from 'final-form'
import { getLocalFtpIfFileExistTypes, getLocalFtpProtocolTypes } from 'utils/delivery'
import PasswordField from 'ui/form/PasswordField'
import { LocalFtpProtocols } from 'modules/delivery/deliveryTargets/deliveryTargets.types'

const CheckboxControl = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    padding-top: 6px;
  }
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }
`

const CheckBoxContainer = styled(Flex)`
  margin-left: 5px;
`

interface SectionLocalFTPServerDeliveryTargetFormProps {
  form: FormApi
}

const SectionLocalFTPServerDeliveryTargetForm: React.FC<SectionLocalFTPServerDeliveryTargetFormProps> = ({ form }) => {
  const localFtpProtocolValues = useMemo(() => getLocalFtpProtocolTypes(), [])
  const localFtpIfFileExistValues = useMemo(() => getLocalFtpIfFileExistTypes(), [])

  const handleChangeProtocol = useCallback((protocolType) => {
    if (protocolType === LocalFtpProtocols.SFTP) {
      form.mutators.setValue('ftpConfiguration.port', 22)
    } else {
      form.mutators.setValue('ftpConfiguration.port', 21)
    }
  }, [])

  // const redirectToIpWhiteList = useMemo(() => {
  //   return (
  //     <CustomLink route={ROUTE_DATA_FLOW}>
  //       <a>{c('IpWhiteList').t`whitelisting instructions`}</a>
  //     </CustomLink>
  //   )
  // }, [])

  return (
    <Flex direction="column">
      <Box mt={0.4} mb={0.4}>{c('Delivery:Target')
        .t`The forecasts are delivered to an FTP server set up by the customer.`}</Box>
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Protocol`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="ftpConfiguration.protocol">
            {(props) => {
              return (
                <SelectFieldWithoutLabel>
                  <Select input={props.input} meta={props.meta}>
                    {localFtpProtocolValues.map((el) => (
                      <MenuItem onClick={() => handleChangeProtocol(el.value)} key={el.value} value={el.value}>
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                </SelectFieldWithoutLabel>
              )
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Server address`}:</AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <Field name="ftpConfiguration.host">
            {(props) => {
              return <TextFieldWithoutLabel type="text" fullWidth input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Port`}:</AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <Field name="ftpConfiguration.port">
            {(props) => {
              return <NumberFieldWithoutLabel type="number" fullWidth input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Username`}:</AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <Field name="ftpConfiguration.username">
            {(props) => {
              return <TextFieldWithoutLabel type="text" fullWidth input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Password`}:</AssetFormLabel>
        </FormKey>
        <FormValue highlightRequired>
          <PasswordField formFieldsHasUnits={1} formStyleKeyValue={1} name="ftpConfiguration.password" label="" />
        </FormValue>
      </FormKeyValueGrid>
      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Directory`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="ftpConfiguration.directory">
            {(props) => {
              return <TextFieldWithoutLabel type="text" fullWidth input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      <Box mt={0.4} mb={0.4}>
        <Typography variant="subtitle2">{c('Delivery:Target').t`Advanced settings`}</Typography>
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`FTP connection timeout`}:</AssetFormLabel>
          </FormKey>
          <FormValue highlightRequired>
            <Flex>
              <Field name="ftpConfiguration.ftpParameter.connectTimeout">
                {(props) => {
                  return <NumberFieldWithoutLabel type="number" fullWidth input={props.input} meta={props.meta} />
                }}
              </Field>
              <FieldUnit>ms</FieldUnit>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Strategy when file exist`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Field name="ftpConfiguration.ftpParameter.fileExist">
              {(props) => {
                return (
                  <SelectFieldWithoutLabel>
                    <Select input={props.input} meta={props.meta}>
                      {localFtpIfFileExistValues.map((el) => (
                        <MenuItem key={el.value} value={el.value}>
                          {el.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </SelectFieldWithoutLabel>
                )
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>

        <CheckBoxContainer direction="row" alignItems="center" justifyContent="flex-start">
          <CheckboxControl
            label={''}
            control={<Field name="ftpConfiguration.ftpParameter.disconnect" type="checkbox" component={Checkbox} />}
          />
          <CheckboxLabel>{c('Delivery:Target').t`Disconnect after operation`}</CheckboxLabel>
        </CheckBoxContainer>

        <CheckBoxContainer direction="row" alignItems="center" justifyContent="flex-start">
          <CheckboxControl
            label={''}
            control={<Field name="ftpConfiguration.ftpParameter.stepwise" type="checkbox" component={Checkbox} />}
          />
          <CheckboxLabel>{c('Delivery:Target')
            .t`Stepwise (only applicable if target server disallows direct path navigation)`}</CheckboxLabel>
        </CheckBoxContainer>
      </Box>

      {/*{!isFree(user) && (*/}
      {/*  <Box mt={0.4} mb={0.4}>*/}
      {/*    <span>*/}
      {/*      {c('IpWhiteList')*/}
      {/*        .jt`If you want to connect with enercast via your (s)FTP server and your infrastructure requires whitelisting*/}
      {/*      for incoming connections, please refer to our ${redirectToIpWhiteList}.`}*/}
      {/*    </span>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </Flex>
  )
}

export default React.memo(SectionLocalFTPServerDeliveryTargetForm)
