import React, { ReactElement, ReactEventHandler, useCallback } from 'react'
import styled from 'styled-components'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

interface ContainerProps {
  wrap: 'wrap' | 'nowrap'
}
export const LayoutTitleContainer = styled.div<ContainerProps>`
  & .MuiListItem-root {
    padding: 0 0.65em 0 0;
    min-height: 4em;
  }

  /* for icons that are an action (they have implicit margins because of ripple effect) */
  & .MuiListItemIcon-root {
    margin-right: 0 !important;
  }

  & .MuiListItemIcon-root .MuiIconButton-label {
    width: 1em;
    height: 1em;
  }

  /* for icons that are not an action (they have no margin, so we add it) */
  & .MuiListItemIcon-root > svg {
    margin-right: 0.5em;
  }

  & .MuiListItemText-root .MuiTypography-root {
    display: flex;
    align-items: center;
    flex-wrap: ${(props) => props.wrap};
  }
`

interface LayoutTitleProps {
  wrap?: boolean
  icon?: IconProp | ReactElement
  onToggle?: () => void
  expanded?: boolean
  children: React.ReactNode
}

const LayoutTitle: React.FC<LayoutTitleProps> = ({ wrap = true, icon, onToggle, expanded, children, ...props }) => {
  const expandable = typeof onToggle === 'function'

  const handleToggle: ReactEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.stopPropagation()
      if (onToggle) {
        onToggle()
      }
    },
    [onToggle],
  )

  return (
    <LayoutTitleContainer wrap={wrap ? 'wrap' : 'nowrap'} {...props}>
      <List>
        <ListItem disableGutters={true}>
          {icon && (
            <ListItemIcon>
              {typeof icon === 'string' ? <FontAwesomeIcon icon={icon} size="lg" /> : (icon as ReactElement)}
            </ListItemIcon>
          )}
          {expandable && (
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="expand" onClick={handleToggle}>
                <FontAwesomeActionIcon icon={expanded ? 'angle-double-left' : 'angle-double-right'} />
              </IconButton>
            </ListItemSecondaryAction>
          )}
          <ListItemText>
            <Typography variant="h1">{children}</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </LayoutTitleContainer>
  )
}

export default React.memo(LayoutTitle)
