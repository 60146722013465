export enum SessionActions {
  SET_SESSION_TYPE = 'SET_SESSION_TYPE',
  SET_SESSION_RECOVERING = 'SET_SESSION_RECOVERING',
  ADD_APP_BANNER = 'ADD_APP_BANNER',
  REMOVE_APP_BANNER = 'REMOVE_APP_BANNER',
  INITIATE_RELOAD = 'INITIATE_RELOAD',
  SET_ACTION_FAILED = 'SET_ACTION_FAILED',
  SET_SESSION_LOADING = 'SET_SESSION_LOADING',
}

export enum AppBanner {
  TIMEZONE = 'TIMEZONE',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  SESSION_OFFLINE = 'SESSION_OFFLINE',
  ACTION_FAILED = 'ACTION_FAILED',
}

export enum SessionStatus {
  AUTHED = 'AUTHED', // user is authenticated
  EXPIRED = 'EXPIRED', // user session has expired or was never logged in
  OFFLINE = 'OFFLINE', // server or internet is not reachable
}
