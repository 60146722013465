import React from 'react'
import { Column, RETABLE_ID_EVALUATION, ReTableItem } from 'modules/reTable/reTable.types'
import { formatDateShort, getMonthName } from 'utils/date'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { TDIndented } from 'ui/styles/table'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import Number from 'ui/Number'
import styled from 'styled-components'
import { isNumeric } from 'utils/dataFormatting'
import { dataStreamWithoutAssetIdCombination } from 'utils/evaluation'

export interface EvaluationTableCellProps {
  item: ReTableItem
  columns: Column[]
}

interface StyledReTableCellProps {
  hasBoldText: boolean
}

const StyledReTableCell = styled(ReTableCell)<StyledReTableCellProps>`
  font-weight: ${(props) => (props.hasBoldText ? 'bold !important' : 'inherit')};
  -webkit-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
`

const EvaluationTableCell: React.FC<EvaluationTableCellProps> = ({ item, columns }) => {
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_EVALUATION)

  const periodName = () => {
    if (item.uiLevel === 2) {
      return getMonthName(+item?.name)
    }
    return item.name
  }

  const valueFromDynamicColumn = (column: Column) => {
    return (
      <StyledReTableCell hasBoldText={item.uiLevel === 1} align="right">
        <div style={{ width: column.width }}>
          {isNumeric(item[column.name]) ? (
            <Number
              showFractionalPart={true}
              forceFractionalPart={true}
              data={item[column.name]}
              limit={2}
              unit={column.unit}
              separator={false}
            />
          ) : (
            <span>-</span>
          )}
        </div>
      </StyledReTableCell>
    )
  }

  const handleLevel = () => {
    if (sort.active) {
      return 0
    } else {
      if (item.identifier === dataStreamWithoutAssetIdCombination) {
        return item.uiLevel - 1
      } else {
        return item.uiLevel
      }
    }
  }

  return (
    <>
      {columns.map((column) =>
        column.name === 'period' ? (
          <StyledReTableCell colSpan={2} hasBoldText={item.uiLevel === 1}>
            <TDIndented level={handleLevel()}>
              {(item.uiLevel === 0 || item.uiLevel === 1) && !sort.active && <ReTableExpandToggle item={item} />}
              {item.name ? periodName() : '-'}
            </TDIndented>
          </StyledReTableCell>
        ) : column.name === 'from' ? (
          <StyledReTableCell hasBoldText={item.uiLevel === 1}>
            {item.tsFrom ? formatDateShort(item.tsFrom) : '-'}
          </StyledReTableCell>
        ) : column.name === 'to' ? (
          <StyledReTableCell hasBoldText={item.uiLevel === 1}>
            {item.tsTo ? formatDateShort(item.tsTo) : '-'}
          </StyledReTableCell>
        ) : (
          valueFromDynamicColumn(column)
        ),
      )}
    </>
  )
}

export default EvaluationTableCell
