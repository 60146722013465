import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Flex from 'ui/styles/Flex'
import { Box, Button, Typography } from '@material-ui/core'
import { c, t } from 'ttag'
import IconButton from '@material-ui/core/IconButton'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import {
  getEndTourDialogBodyContent,
  getOnBoardingStartTourDialogBodyContent,
  getTourName,
} from 'modules/app/tour/appTourUtils'
import TourProgressStep from 'modules/app/tour/TourProgressStep'
import styled from 'styled-components'
import { useAppTourSettingsSaveMutation } from 'modules/app/tour/appTour.api'
import { AppTours } from 'modules/app/tour/appTour.types'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { Asset } from 'modules/asset/store/asset.types'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { DeliveryTarget } from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import { theme } from 'themes/theme-light'
import { useDraftChangeCounts, useHasDraft } from 'modules/asset/api/metadata.api'
import { rgb } from 'polished'
import { useRouter } from 'utils/route'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'

interface FloatingDialogProps {
  minimizeDialog: boolean
  isTourPaused?: boolean
  tourStart?: boolean
}

interface FadeContainerProps {
  fadeOutContainer?: boolean
}

// colors
export const appTourColors = {
  lightBlue: rgb(187, 209, 255),
  darkBlue: '#0038a8',
}

// Styled Elements
const StyledButton = styled(Button)`
  background-color: ${appTourColors.darkBlue} !important;
  color: white !important;
`

const StyledFontAwesomeActionIcon = styled(FontAwesomeActionIcon)`
  color: ${appTourColors.darkBlue} !important;
`

const StyledTypography = styled(Typography)`
  color: ${appTourColors.darkBlue} !important;
`

const FadeContainer = styled.div<FadeContainerProps>`
  z-index: 1000;
  opacity: ${(props) => (props.fadeOutContainer ? '0' : '1')};
  transition: opacity 1s;
`

const FloatingDialog = styled.div<FloatingDialogProps>`
  border: 1px solid ${appTourColors.lightBlue};
  padding: ${(props) => (props.minimizeDialog ? '0.5em' : '2em')};
  ${(props) => (props.minimizeDialog ? 'height:3.7em;' : '')}
  z-index: 1000;
  position: absolute;
  ${(props) =>
    !props.minimizeDialog
      ? 'left: 50%;\n  top: 50%; \n  transform: translate(-50%, -50%);'
      : 'bottom: 1em; \n right: 1em;'}
  transition:  transitionFor(['margin-right', 'right'],  800);
  background: ${appTourColors.lightBlue};
  width: ${(props) =>
    props.minimizeDialog && !props.tourStart
      ? 'auto'
      : props.minimizeDialog && props.isTourPaused
      ? 'auto'
      : props.minimizeDialog && !props.isTourPaused
      ? '39em'
      : '46em'};
  transition-property: ${(props) => props.minimizeDialog && 'width'};
  transition-duration: ${(props) => props.minimizeDialog && '200ms'};
  box-shadow: ${theme.shadows[6]};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface OnBoardingTourDialogProps {
  tourContext: AppTours | undefined
  assets: Asset[]
  siteForecasts: ForecastConfig[]
  deliveryTargets: DeliveryTarget[]
  onTourStart: () => void
}

export const OnBoardingTourDialog: React.FC<OnBoardingTourDialogProps> = ({
  tourContext,
  assets,
  siteForecasts,
  deliveryTargets,
  onTourStart,
}) => {
  const { isTourPaused, resumeTour, removeTourSteps } = useContext(AppTourContext)
  const hasDraft = useHasDraft()
  const { pendingCount } = useDraftChangeCounts()
  const { history } = useRouter()

  const {
    ADD_ASSET,
    ADD_SITE_FORECAST,
    ADD_DELIVERY_TARGET,
    ACTIVATE_DRAFT,
    ADD_DELIVERY_TARGET_TO_SITE_FORECAST,
  } = AppTours

  const [tourStart, setTourStart] = useState<boolean>(false)
  // After tour is over.
  const showFloatingDialog = true
  const hideFadeContainer = false
  // const [showFloatingDialog, setShowFloatingDialog] = useState<boolean>(true)
  // const [hideFadeContainer, setHideFadeContainer] = useState<boolean>(false)

  const [minimizeDialog, setMinimizeDialog] = useState<boolean>(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false)

  const saveAppTourSettingsResult = useAppTourSettingsSaveMutation()
  const saveAppTourSettingsMutation = saveAppTourSettingsResult.mutate

  // Check if delivery target is added to siteforecast config
  const atLeastOneDeliveryTargetIsAssignedToSiteForecast = useMemo(
    () =>
      siteForecasts?.length > 1 &&
      deliveryTargets?.length > 1 &&
      deliveryTargets?.some((delivery) =>
        siteForecasts?.some((config) => config.deliveryEndpoints.includes(delivery.id)),
      ),
    [deliveryTargets, siteForecasts],
  )

  const userPerformedInitialSteps = useMemo(
    () =>
      !hasDraft.data &&
      !pendingCount &&
      assets?.length &&
      siteForecasts?.length > 1 &&
      deliveryTargets?.length > 1 &&
      atLeastOneDeliveryTargetIsAssignedToSiteForecast,
    [
      hasDraft,
      pendingCount,
      assets,
      deliveryTargets,
      siteForecasts,
      pendingCount,
      atLeastOneDeliveryTargetIsAssignedToSiteForecast,
    ],
  )

  // Start, Resume and End onBoarding tour handlers
  const handleStartTour = useCallback(() => {
    saveAppTourSettingsMutation({ startOnBoardingTour: true, enableTour: true })
    setMinimizeDialog(true)
    setTourStart(true)
    onTourStart()
  }, [onTourStart, tourContext])

  const handleResumeTour = useCallback(() => {
    saveAppTourSettingsMutation({ startOnBoardingTour: true, enableTour: true })
    setMinimizeDialog(true)
    resumeTour()
    onTourStart()
  }, [onTourStart, resumeTour])

  const handleEndTour = useCallback(() => {
    saveAppTourSettingsMutation({ startOnBoardingTour: false, endOnBoardingTour: true, enableTour: false })
  }, [])

  const handleCloseTour = useCallback(() => {
    if (userPerformedInitialSteps) {
      handleEndTour()
    } else {
      setOpenConfirmDialog(true)
    }
  }, [userPerformedInitialSteps])

  // Confirmation dialog handlers for closing onBoarding tour abruptly
  const handleProceedClosingTour = useCallback(() => {
    setOpenConfirmDialog(false)
    handleEndTour()
  }, [])

  const handleCancelClosingTour = useCallback(() => {
    setOpenConfirmDialog(false)
    setMinimizeDialog(true)
    resumeTour()
    saveAppTourSettingsMutation({ startOnBoardingTour: true, enableTour: false })
  }, [resumeTour])

  // Progress bar steps
  const progressStep = useMemo(() => {
    return [
      {
        name: getTourName(ADD_ASSET),
        active: tourContext === ADD_ASSET || tourContext === ACTIVATE_DRAFT,
        done: assets?.length > 0 && !pendingCount,
      },
      {
        name: getTourName(ADD_SITE_FORECAST),
        active: tourContext === ADD_SITE_FORECAST,
        done: siteForecasts?.length > 1,
      },
      {
        name: getTourName(ADD_DELIVERY_TARGET),
        active: tourContext === ADD_DELIVERY_TARGET || tourContext === ADD_DELIVERY_TARGET_TO_SITE_FORECAST,
        done: deliveryTargets?.length > 1 && atLeastOneDeliveryTargetIsAssignedToSiteForecast,
      },
    ]
  }, [tourContext, assets, siteForecasts, deliveryTargets, atLeastOneDeliveryTargetIsAssignedToSiteForecast])

  const ExitTourActionIcon = useMemo(() => {
    return (
      <Box display="flex" flexDirection="row">
        <IconButton aria-label="close" onClick={handleCloseTour}>
          <StyledFontAwesomeActionIcon icon="times" />
        </IconButton>
      </Box>
    )
  }, [minimizeDialog])

  const startTourActionButton = useMemo(() => {
    return (
      <StyledButton onClick={handleStartTour} variant="contained" size="small" color="default">
        {c('AppTour').t`Start tour`}
      </StyledButton>
    )
  }, [handleStartTour])

  const endTourActionButton = useMemo(() => {
    return (
      <StyledButton onClick={handleEndTour} variant="contained" size="small" color="default">
        {c('AppTour').t`End tour`}
      </StyledButton>
    )
  }, [])

  // Do not fade

  // useEffect(() => {
  //   if (!userPerformedInitialSteps) return
  //   const floatingDialogTimer = setTimeout(() => setShowFloatingDialog(false), 4000)
  //   const fadeContainerTimer = setTimeout(() => setHideFadeContainer(true), 3000)
  //   // this will clear Timeout
  //   return () => {
  //     clearTimeout(floatingDialogTimer)
  //     clearTimeout(fadeContainerTimer)
  //   }
  // }, [userPerformedInitialSteps])

  // Do not end tour after finishing and fading
  // useEffect(() => {
  //   if (!hideFadeContainer && !userPerformedInitialSteps) return
  //   const endTourTimer = setTimeout(() => handleEndTour(), 3000)
  //   return () => {
  //     clearTimeout(endTourTimer)
  //   }
  // }, [userPerformedInitialSteps, hideFadeContainer])

  useEffect(() => {
    if (!userPerformedInitialSteps) return
    const NavigateToWorkbenchTimer = setTimeout(() => {
      setMinimizeDialog(false)
      removeTourSteps(AppTours.ADD_DELIVERY_TARGET_TO_SITE_FORECAST)
      history.push(ROUTE_WORKBENCH)
    }, 3000)
    return () => {
      if (NavigateToWorkbenchTimer) clearTimeout(NavigateToWorkbenchTimer)
    }
  }, [userPerformedInitialSteps])

  return (
    <>
      {openConfirmDialog && (
        <ConfirmationDialog
          heading={c('AppTour').t`Exit tour`}
          text={c('AppTour').t`Do you want to exit the tour?`}
          confirmAction={t`Exit`}
          cancelAction={t`Continue later`}
          onConfirm={handleProceedClosingTour}
          onCancel={handleCancelClosingTour}
          openDialog={openConfirmDialog}
        />
      )}
      {showFloatingDialog && (
        <FadeContainer fadeOutContainer={hideFadeContainer}>
          <FloatingDialog minimizeDialog={minimizeDialog} isTourPaused={isTourPaused} tourStart={tourStart}>
            {!minimizeDialog ? (
              <Flex direction="column">
                <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                  <StyledTypography variant="h2">
                    {userPerformedInitialSteps ? (
                      <>{c('AppTour').t`You did it!`}</>
                    ) : (
                      <>{c('AppTour').t`Welcome to enercast!`}</>
                    )}
                  </StyledTypography>
                  {ExitTourActionIcon}
                </Box>

                <Box mb={1}>
                  <Typography>
                    {userPerformedInitialSteps
                      ? getEndTourDialogBodyContent()
                      : getOnBoardingStartTourDialogBodyContent()}
                  </Typography>
                </Box>

                {userPerformedInitialSteps ? <Box>{endTourActionButton}</Box> : <Box>{startTourActionButton}</Box>}
              </Flex>
            ) : (
              <Box width="100%">
                {!tourStart && startTourActionButton}
                {tourStart && (
                  <Box>
                    {isTourPaused ? (
                      <Flex alignItems="center">
                        <Box mr={1}>
                          <StyledButton onClick={handleResumeTour} variant="contained" size="small" color="default">
                            {c('AppTour').t`Resume tour`}
                          </StyledButton>
                        </Box>
                        {endTourActionButton}
                      </Flex>
                    ) : (
                      <TourProgressStep steps={progressStep} />
                    )}
                  </Box>
                )}
              </Box>
            )}
          </FloatingDialog>
        </FadeContainer>
      )}
    </>
  )
}

export default React.memo(OnBoardingTourDialog)
