import { YAxisOptions } from 'highcharts'
import { ParamDto } from 'modules/dataStreams/dataStreams.types'
import { t } from 'ttag'

import { thousandSeparator } from 'utils/dataFormatting'
import { DEFAULT_POWER_UNIT, getSoftMaximumForUnit, getSoftMinimumForUnit } from 'utils/units'
import { removeDuplicates } from 'utils/array'

export interface CreateYAxis {
  paramData?: ParamDto
  unit?: string
  tickPositions?: number[]
  yAxisPartialData?: any
  hasData?: boolean
}
export const createYAxis = ({ paramData, unit = DEFAULT_POWER_UNIT, yAxisPartialData, hasData }: CreateYAxis) => {
  const isMainAxis = unit === DEFAULT_POWER_UNIT
  // const isMainAxis = false
  // const yAxisTickPositions = tickPositions
  // dynamic attributes
  const gridLineWidth = isMainAxis ? 1 : 0

  const align = isMainAxis ? undefined : 'left'
  const opposite = !isMainAxis
  // TODO softMin/softMax is hacky frontend solution until backend provides proper limit data
  const softMin = !isMainAxis && paramData ? getSoftMinimumForUnit(paramData) : undefined
  const softMax = !isMainAxis && paramData ? getSoftMaximumForUnit(paramData) : undefined
  let text = isMainAxis ? `(${unit})` : unit

  if (unit === DEFAULT_POWER_UNIT) {
    text = `${t`Power`} ${text}`
  }

  const newYAxis: YAxisOptions = {
    id: unit,
    alignTicks: true,
    crosshair: true,
    gridLineWidth,
    labels: {
      align,
      formatter: function () {
        return thousandSeparator(this.value)
      },
    },
    lineWidth: 1,
    minPadding: 0,
    softMin,
    softMax,
    opposite,
    showEmpty: false,
    //tickPositions: yAxisTickPositions,
    title: {
      text,
    },
    ...yAxisPartialData,
    // height: '100%',
    // top: 0,
    hasData,
  }

  return newYAxis
}

export const alignYAxes = (yAxes: YAxisOptions[]) => {
  const transformedYAxes: YAxisOptions[] = []
  // // Power axis is the main axis
  // const powerAxisWithData = yAxes.find((axis) => axis.id === DEFAULT_POWER_UNIT && axis?.hasData)
  // const axisWithOutPowerWithData = yAxes.find((axis) => axis.id !== DEFAULT_POWER_UNIT && axis?.hasData)
  // // Since Power axis is the main axis it should be on left side therefore we check for that first
  // const leftAxis = powerAxisWithData || axisWithOutPowerWithData

  const leftAxis = yAxes.find((axis) => axis?.hasData)

  if (leftAxis) {
    leftAxis['opposite'] = false
    leftAxis['labels'] = { ...leftAxis.labels, align: undefined }
    leftAxis['gridLineWidth'] = 1
    transformedYAxes.push(leftAxis)
  }

  const rightAxes = yAxes.filter((axis) => axis.id !== leftAxis?.id)
  if (rightAxes.length) {
    rightAxes.forEach((rightAxis) => {
      transformedYAxes.push({
        ...rightAxis,
        opposite: true,
        labels: { ...rightAxis.labels, align: 'left' },
        gridLineWidth: 0,
      })
    })
  }

  // const hasLeftAxisWithData = yAxes.some((axis) => !axis?.opposite && axis?.hasData)
  //
  // console.log({ yAxes, hasLeftAxisWithData })
  // // If there are no left axis then we should display atleast one axis to the left.
  // if (yAxes.length && !hasLeftAxisWithData) {
  //   const leftAxis = {
  //     ...yAxes[0],
  //     gridLineWidth: 1,
  //     labels: { ...yAxes[0].labels, align: undefined },
  //     opposite: false,
  //   }
  //   transformedYAxes.push(leftAxis)
  //   for (let i = 1; i < yAxes.length; i++) {
  //     const nextRightAxis = {
  //       ...yAxes[i],
  //       gridLineWidth: 0,
  //       labels: { ...yAxes[i].labels, align: 'left' }, // alignment of label
  //       opposite: true,
  //     }
  //     transformedYAxes.push(nextRightAxis)
  //   }
  // } else {
  //   transformedYAxes = [...yAxes]
  // }

  return transformedYAxes
}

export const getUniqueUnitYAxisParams = (createYAxisParams: CreateYAxis[]): CreateYAxis[] => {
  const axisUnits = removeDuplicates(createYAxisParams.map((axisParam) => axisParam.unit))
  // console.log({ createYAxisParams, axisUnits })
  const yAxisParamWithoutDuplicateUnits = axisUnits.map((unit) => {
    const axisWithData = createYAxisParams.find((axisParam) => axisParam.unit === unit && axisParam.hasData)
    // Always consider a param with matching unit and which has Data as high priority
    if (axisWithData) {
      return axisWithData
    } else {
      // If the axis doesn't has data, return param which matches unit
      return createYAxisParams.find((axisParam) => axisParam.unit === unit)
    }
  })

  const yAxes = yAxisParamWithoutDuplicateUnits.map((params) => createYAxis(params))
  return yAxes
}
