import { isAfter } from 'date-fns'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { ForecastModel, QualityEvaluation } from 'modules/quality/quality.types'
import { formatDate, MAX_DATE } from 'utils/date'
import { c, t } from 'ttag'
import {
  CellRenderType,
  Column,
  ColumnSortType,
  ReTableContextMenuItem,
  ReTableItem,
  ReTableRowContextActions,
  Status,
} from 'modules/reTable/reTable.types'
import {
  hasPermissionForE3ModelLevelForecast,
  hasPermissionTo3rdPartyForecasts,
  isAdmin,
  isImpersonatedAdmin,
} from 'utils/user'
import { User } from 'modules/auth/Auth.types'
import { ForecastModelCategoryEnum } from 'modules/forecastModels/forecastModels.types'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { labelsForForecastModelCategory } from 'utils/siteForecastConfiguration'

/**
 * Each asset can have single (e3 or Meta) active model or multiple active models one from e3 and other from Meta
 * @param forecastModels
 * returns array of active models
 */
export const getActiveModels = (forecastModels: ForecastModel[]) => {
  const activeModels: ForecastModel[] = []
  const e3Models = forecastModels?.filter((model) => model.category === ForecastModelCategoryEnum.E3)
  const metaModels = forecastModels?.filter((model) => model.category === ForecastModelCategoryEnum.META)

  const allActiveE3Models = (e3Models || [])
    .filter((model) => model.activeFrom)
    .sort((a, b) => {
      return isAfter(a.activeFrom ? new Date(a.activeFrom) : MAX_DATE, b.activeFrom ? new Date(b.activeFrom) : MAX_DATE)
        ? -1
        : 1
    })

  const allActiveMetaModels = (metaModels || [])
    .filter((model) => model.activeFrom)
    .sort((a, b) => {
      return isAfter(a.activeFrom ? new Date(a.activeFrom) : MAX_DATE, b.activeFrom ? new Date(b.activeFrom) : MAX_DATE)
        ? -1
        : 1
    })
  if (allActiveE3Models?.length) {
    const latestE3ActiveModel = allActiveE3Models[0]
    activeModels.push(latestE3ActiveModel)
  }
  if (allActiveMetaModels?.length) {
    const latestE3ActiveModel = allActiveMetaModels[0]
    activeModels.push(latestE3ActiveModel)
  }
  return activeModels
}

/**
 * Handler to create an Id for backcast model which will be saved in asset selection asset tree
 */
export const getBackCastModelIdForAssetTree = (forecastModel: ForecastModel) => {
  const id = `${forecastModel.assetId}${FORECAST_MODEL_FOR_BACK_CAST_ID}${
    forecastModel?.uuid
  }${FORECAST_MODEL_FOR_BACK_CAST_ID}${getForecastModelName(forecastModel)}`
  return id
}

export const getForecastModelName = (forecastModel: ForecastModel) => {
  return forecastModel?.name ? forecastModel?.name : `[${formatDate(forecastModel?.creationDate)}]`
}

export const isMetaForecastModel = (forecastModel: ForecastModel) => {
  return forecastModel && forecastModel.category === ForecastModelCategoryEnum.META
}

export interface BackCastModelDataFromId {
  assetId: string
  modelId: string
  name: string
}
export const getBackCastModelDataFromId = (id: string): BackCastModelDataFromId => {
  const ids = id.split(FORECAST_MODEL_FOR_BACK_CAST_ID)

  const assetId = ids[0] || ''
  const modelId = ids[1] || ''
  const name = ids[2] || ''

  return {
    assetId,
    modelId,
    name,
  }
}

export enum EvaluationImprovement {
  POSITIVE,
  NEGATIVE,
  NEUTRAL,
}

export interface EnrichedForecastModelItem extends ReTableItem, ForecastModel {
  id: string
}

export interface EnrichedForecastConfigItemForForecastModel extends ReTableItem {
  id: string
  name: string
  isForecastConfig: boolean
}

export interface EnrichedQualityEvaluationItem extends ReTableItem, QualityEvaluation {
  comparisonType: string
  evaluationStatus: Status
  forecastConfigName: string
  forecastConfigId: string
  forecastModelType: string
  forecastModelName: string
  forecastModel: ForecastModel | undefined
  measurementErrorTag: string
  measurementErrorMessage: string
  measurementHasError: boolean
  nRmse: number | undefined
  nMae: number | undefined
  absoluteDeviation: number | undefined
  nbias: number | undefined
  correlationCoefficient: number | undefined
  primaryEvaluationResult: number | undefined
  nRmseChangePercentage: number | undefined
  nMaeChangePercentage: number | undefined
  absoluteDeviationChangePercentage: number | undefined
  nbiasChangePercentage: number | undefined
  correlationCoefficientChangePercentage: number | undefined
  nRmseHasImproved: EvaluationImprovement
  nMaeHasImproved: EvaluationImprovement
  absoluteDeviationHasImproved: EvaluationImprovement
  nbiasHasImproved: EvaluationImprovement
  correlationCoefficientHasImproved: EvaluationImprovement
  isPrimaryNRMSE: boolean
  isPrimaryNMAE: boolean
  isPrimaryABSDEV: boolean
  isPrimaryNBIAS: boolean
  isPrimaryCORCO: boolean
  qualityConfigName: string
  isQualityEvaluation: boolean
  weatherModels: {
    name: string
    weight: number
  }[]
}

// const hasPermissionToWeatherEnsemble = (user: User | null) => {
//   return isAdmin(user) || isImpersonatedAdmin(user)
// }

export type ForecastModelTableItem =
  | EnrichedForecastModelItem
  | EnrichedForecastConfigItemForForecastModel
  | EnrichedQualityEvaluationItem

export const getForecastModelColumns: () => Column[] = () => [
  {
    name: 'forecastModelName',
    label: c('Training Results:Quality').t`Forecast model`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '11em',
  },
  {
    name: 'correctionModel',
    label: c('Training Results:Quality').t`Correction`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '8em',
  },
  {
    name: 'weatherModel',
    label: c('Training Results:Quality').t`Weather ensemble`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    fixed: true,
    width: '11em',
  },
  {
    name: 'activationStatus',
    label: c('Training Results:Quality').t`Activation state`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '9em',
  },

  {
    name: 'forecastConfigName',
    label: c('Training Results:Quality').t`Forecast config`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '12em',
  },
  {
    name: 'reason',
    label: c('Training Results:Quality').t`Reason`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
  },
  {
    name: 'qualityConfigName',
    label: c('Training Results:Quality').t`Quality config`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '11em',
  },
  {
    name: 'forecastModelId',
    label: c('Training Results:Quality').t`Forecast model ID`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '11em',
  },
  {
    name: 'nRmse',
    label: getTranslationsTrainingResults().nRmse,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '5.5em',
  },
  {
    name: 'nMae',
    label: getTranslationsTrainingResults().nMae,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '5em',
  },
  {
    name: 'absoluteDeviation',
    label: getTranslationsTrainingResults().absoluteDeviation,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '5.5em',
  },
  {
    name: 'nbias',
    label: getTranslationsTrainingResults().nbias,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '5em',
  },
  {
    name: 'correlationCoefficient',
    label: getTranslationsTrainingResults().correlationCoefficient,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    fixed: true,
    width: '6em',
  },

  {
    name: 'activeFrom',
    label: c('Training Results:Quality').t`Activation date`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '9em',
  },
  {
    name: 'creationDate',
    label: c('Training Results:Quality').t`Evaluation timestamp`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '12em',
  },
  {
    name: 'evaluationPeriodStart',
    label: c('Training Results:Quality').t`Evaluation start`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '9em',
  },
  {
    name: 'evaluationPeriodEnd',
    label: c('Training Results:Quality').t`Evaluation end`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '9em',
  },
  {
    name: 'deliveryTime',
    label: c('Training Results:Quality').t`Delivery time`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '8em',
  },
  {
    name: 'trainingPeriodStart',
    label: c('Training Results:Quality').t`Training start`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '8em',
  },
  {
    name: 'trainingPeriodEnd',
    label: c('Training Results:Quality').t`Training end`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '8em',
  },
]

export const getTranslationsTrainingResults = () => {
  return {
    nRmse: c('Training Results:Quality').t`nRMSE`,
    nMae: c('Training Results:Quality').t`nMAE`,
    absoluteDeviation: c('Training Results:Quality').t`AbsDev`,
    nbias: c('Training Results:Quality').t`nBIAS`,
    correlationCoefficient: c('Training Results:Quality').t`CorrCo`,
    rmse: c('Training Results:Quality').t`RMSE`,
    mae: c('Training Results:Quality').t`MAE`,
    bias: c('Training Results:Quality').t`BIAS`,
  }
}

export const hasPermissionForForecastModelTableActions = (user: User | null) => {
  return isAdmin(user) || isImpersonatedAdmin(user)
}

export const forecastModelsTableActionMenuItems: (user: User) => ReTableContextMenuItem[] = () => [
  {
    itemName: ReTableRowContextActions.ACTIVATE,
    itemLabel: t`Activate`,
    icon: '',
    disabled: false,
  },
  {
    itemName: ReTableRowContextActions.EVALUATE_ALL,
    itemLabel: t`Evaluate all`,
    icon: '',
    disabled: false,
    userHasPermission: hasPermissionForForecastModelTableActions,
  },
  {
    itemName: ReTableRowContextActions.EDIT_ROW,
    itemLabel: t`Edit`,
    icon: '',
    disabled: false,
    userHasPermission: hasPermissionForForecastModelTableActions,
  },
  {
    itemName: ReTableRowContextActions.START_WEIGHT_OPTIMIZATION,
    itemLabel: t`Start weight optimization`,
    icon: '',
    disabled: false,
    userHasPermission: hasPermissionForForecastModelTableActions,
  },
  {
    itemName: ReTableRowContextActions.START_TRAINING_NOW,
    itemLabel: t`Start training now`, // used only for wind plant
    icon: '',
    disabled: false,
    userHasPermission: hasPermissionForForecastModelTableActions,
  },
  {
    itemName: ReTableRowContextActions.START_SOLAR_CALIBRATION,
    itemLabel: t`Start training now`, // = Start solar calibration and weight optimization , used only for solar plant
    icon: '',
    disabled: false,
    userHasPermission: hasPermissionForForecastModelTableActions,
  },
]

export const convertSerializedForecastModelData = (forecastModel: ForecastModel) => {
  const {
    forcePhysical,
    anns,
    capacitiesSources,
    algorithmChain,
    weatherModelConfiguration,
    weatherPoints,
    windCorrections,
  } = forecastModel?.parameter
  // const convertedData = { ...forecastModel }

  forecastModel.parameter.forcePhysical = ((forcePhysical as unknown) as string) === 'true'
  if (anns) {
    delete forecastModel.parameter.anns
  }
  if (capacitiesSources && typeof capacitiesSources === 'string') {
    forecastModel.parameter.capacitiesSources = JSON.parse((capacitiesSources as unknown) as string)
  }
  if (algorithmChain && typeof algorithmChain === 'string') {
    forecastModel.parameter.algorithmChain = JSON.parse((algorithmChain as unknown) as string)
  }
  if (weatherModelConfiguration && typeof weatherModelConfiguration === 'string') {
    forecastModel.parameter.weatherModelConfiguration = JSON.parse((weatherModelConfiguration as unknown) as string)
  }
  if (weatherPoints && typeof weatherPoints === 'string') {
    forecastModel.parameter['weatherPoints'] = JSON.parse((weatherPoints as unknown) as string)
  }
  if (windCorrections && typeof windCorrections === 'string') {
    forecastModel.parameter.windCorrections = JSON.parse((windCorrections as unknown) as string)
  }
  const sortedParameter = Object.keys(forecastModel?.parameter)
    .sort()
    .reduce((obj, key) => {
      obj[key] = forecastModel.parameter[key]
      return obj
    }, {})

  return { ...forecastModel, parameter: sortedParameter }
}

export const getForecastModelTableItemsBasedOnHideFilter = (
  models: ForecastModelTableItem[],
  getHiddenModels: boolean,
) => {
  let filteredItems = [...models]

  // If getHiddenModels is true do nothing else hide the models and evaluations
  if (!getHiddenModels) {
    const hiddenModelIds = filteredItems?.filter((item) => item?.hide).map((i) => i.uuid)
    // do not show hidden models and its quality evaluations
    filteredItems = filteredItems.filter((item) => {
      if (item?.isQualityEvaluation) {
        return !hiddenModelIds.includes(item.forecastModelId)
      } else {
        return !hiddenModelIds.includes(item.uuid)
      }
    })
    return filteredItems
  } else return filteredItems
}

export const getMixerWidgetMenuItems = (user: User) => {
  const menuItems = []
  const e3MenuItem = {
    label: labelsForForecastModelCategory(ForecastModelCategoryEnum.E3),
    value: TimeSeriesSubType.E3_WEATHER_TRACK,
  }
  const metForecastMenuItem = {
    label: labelsForForecastModelCategory(ForecastModelCategoryEnum.META),
    value: TimeSeriesSubType.E3_THIRD_PARTY_FORECAST,
  }
  if (hasPermissionForE3ModelLevelForecast(user)) {
    menuItems.push(e3MenuItem)
  }
  if (hasPermissionTo3rdPartyForecasts(user)) {
    menuItems.push(metForecastMenuItem)
  }
  return menuItems
}
