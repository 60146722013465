import { SET_META, SET_TYPES } from '../actions/metaActions'

const initialState = { meta: [], types: [] }

export default function metaReducer(state = initialState, action) {
  switch (action.type) {
    case SET_META:
      return { ...state, meta: action.meta }
    case SET_TYPES:
      return { ...state, types: action.types }
    default:
      return state
  }
}
