import { useAllAssets } from 'modules/asset/api/assets.api'
import { Asset } from 'modules/asset/store/asset.types'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { SAVE_WORKSPACE_DRAFT_REQUEST, WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeepPartial } from 'ts-essentials'
import { getDescendants, hasChildren, isGenerator, isParkWithNoPlants, useUniqueAllAssets } from 'utils/asset'
import { RETABLE_COLLAPSE_SUBTREES } from 'modules/reTable/redux_store/reTable.action.types'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import {
  KEY_SHOW_MODELS_IN_ASSET_TREE,
  useUserSetting,
  useUserSettingSaveMutation,
} from 'modules/auth/api/userSettings.api'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableCollapsedSelector } from 'modules/reTable/redux_store/state/view.state'
import { HeaderTitle } from 'modules/asset/tree/AssetTreeHead'

const AssetTreeHeadActions: React.FC = () => {
  const dispatch = useDispatch()
  const uniqueAssets = useUniqueAllAssets()
  const allAssets = useAllAssets()
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const userShowModelRowsResult = useUserSetting<any>(KEY_SHOW_MODELS_IN_ASSET_TREE)
  const { mutate: mutateShowModelsInTree } = useUserSettingSaveMutation<boolean>(KEY_SHOW_MODELS_IN_ASSET_TREE)
  const collapsedIds = useReTableSelectorWithId(reTableCollapsedSelector, RETABLE_ID_ASSETS)

  const selectedAssets = useMemo(() => (allAssets.data || []).filter((a) => assetSelection.includes(a.id)), [
    allAssets.data,
    assetSelection,
  ])

  const selectedAssetsAndDescendants = useMemo<Asset[]>(() => {
    // select all descendants of current selection (in other words: whole hierarchy)
    return selectedAssets.reduce<Asset[]>((previous, selectedAsset) => {
      const descendants = getDescendants(selectedAsset, allAssets.data || [])
      return [...previous, ...descendants]
    }, selectedAssets)
  }, [selectedAssets, allAssets.data])

  // const hasColumnWithSubItems = useMemo(() => columnsWide.some(column => column.subItems), [columnsWide])

  const handleSelectAllLeafs = useCallback(() => {
    // return !assetSelection.length
    // filter out duplicates and only select leafs (assets without children)
    if (assetSelection.length) {
      const selection = Array.from(
        selectedAssetsAndDescendants
          .filter((asset) => !hasChildren(asset, allAssets.data || []))
          .map((asset) => asset.id),
      )
      const draft: DeepPartial<WorkspaceConfig> = {
        asset: {
          selection,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    } else {
      return
    }
  }, [selectedAssetsAndDescendants, assetSelection, allAssets.data])

  const handleSelectEntireBranch = useCallback(() => {
    // return !assetSelection.length
    if (assetSelection.length) {
      // filter out duplicates
      const selection = Array.from(selectedAssetsAndDescendants.map((asset) => asset.id))
      const draft: DeepPartial<WorkspaceConfig> = {
        asset: {
          selection,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    } else {
      return
    }
  }, [selectedAssetsAndDescendants, assetSelection])

  const handleToggleModelRows = useCallback(() => {
    const showModels = !(userShowModelRowsResult?.data?.value || false)
    let newCollapsibleIds: string[] = []
    const assetsIdsWithNoChildren =
      allAssets?.data?.filter((asset) => isGenerator(asset) || isParkWithNoPlants(asset))?.map((a) => a.id) || []
    // Add generators and parks without plants and which are not selected along with previous collapsed ids
    if (showModels) {
      const assetIdsNotSelected = assetsIdsWithNoChildren?.filter((id) => !assetSelection.includes(id))
      newCollapsibleIds = [...collapsedIds, ...assetIdsNotSelected]
    } // Remove generators and parks without plants from previous collapsed ids
    else {
      newCollapsibleIds = collapsedIds.filter((id) => !assetsIdsWithNoChildren.includes(id))
    }
    dispatch({
      type: RETABLE_COLLAPSE_SUBTREES,
      table: RETABLE_ID_ASSETS,
      ids: newCollapsibleIds,
    })

    mutateShowModelsInTree({ value: showModels })
  }, [userShowModelRowsResult?.data, collapsedIds, allAssets?.data, assetSelection])

  const handleSelectAll = () => {
    if (uniqueAssets.length) {
      const selection = uniqueAssets.map((a) => a.id)
      const draft: DeepPartial<WorkspaceConfig> = {
        asset: {
          selection,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    } else return
  }

  // Initially if show models is not set in user settings, we set the show models to false by default
  useEffect(() => {
    if (userShowModelRowsResult?.isFetched && !userShowModelRowsResult?.data) {
      mutateShowModelsInTree({ value: false })
    }
  }, [userShowModelRowsResult?.data, userShowModelRowsResult?.isFetched])

  return (
    <ReTableHeaderActionsCell
      isHierarchical={true}
      onSelectAllLeafs={handleSelectAllLeafs}
      onSelectEntireBranch={handleSelectEntireBranch}
      onToggleModelRows={handleToggleModelRows}
      onSelectAll={handleSelectAll}
    >
      <HeaderTitle />
    </ReTableHeaderActionsCell>
  )
}

export default React.memo(AssetTreeHeadActions)
