import React, { useMemo } from 'react'
import { t } from 'ttag'
import { getNomcap, isParkWithNoPlants, isSolarPlant, isWindPlant } from 'utils/asset'
import { AppUnits } from 'utils/units'
import Flex from 'ui/styles/Flex'
import PopperTooltip from 'ui/PopperTooltip'
import { Box, lighten } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme, bulkMeterDataCleansingCorrelationCoefficient } from 'themes/theme-light'
import { Asset, TYPE_SOLARPLANT, TYPE_WINDPLANT } from 'modules/asset/store/asset.types'
import styled from 'styled-components'
import { BulkMDCMeterDataRaw } from 'modules/meterDataCleansing/BulkMeterDataCleansing/api/BulkMeterDataCleansing.types'
import { isValidAssetForMDC } from 'utils/meterDataCleansing'
import AssetLink from 'modules/asset/AssetLink'

const CORRELATION_COEFFICIENT_COLOR = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
}

interface InfoBodyElementProps {
  border?: boolean
  withBackground?: null | string
}

export const BulkMdcInfoBodyElement = styled(Flex)<InfoBodyElementProps>`
  height: 30px;
  border-bottom: ${(props) => props.border && '1px solid rgb(224, 224, 224)'};
  background-color: ${(props) =>
    props.withBackground && lighten(bulkMeterDataCleansingCorrelationCoefficient[props.withBackground], 0.7)};
`

export const BulkMdcInfoHeaderElement = styled(Flex)`
  background-color: rgb(239, 239, 239);
  height: 30px;
  cursor: pointer;
`

const TooltipDiv = styled.div`
  margin-left: 5px;
`

interface BulkMDCRowStatisticsProps {
  asset: Asset
  meterDataRaw: BulkMDCMeterDataRaw
}

const BulkMDCRowStatistics: React.FC<BulkMDCRowStatisticsProps> = ({ asset, meterDataRaw }) => {
  // Highlight correlation coefficient
  // This function is used to handle background of correlation coefficient
  const correlationCoefficientHandler = useMemo(() => {
    if (meterDataRaw?.summary && meterDataRaw?.summary['chosenGPscore']) {
      const minCoefficient = 0.6
      const maxCoefficient = 0.8
      if (meterDataRaw?.summary['chosenGPscore'] < minCoefficient) {
        return CORRELATION_COEFFICIENT_COLOR.RED
      } else if (
        meterDataRaw?.summary['chosenGPscore'] >= minCoefficient &&
        meterDataRaw?.summary['chosenGPscore'] <= maxCoefficient
      ) {
        return CORRELATION_COEFFICIENT_COLOR.ORANGE
      } else if (meterDataRaw?.summary['chosenGPscore'] > maxCoefficient) {
        return CORRELATION_COEFFICIENT_COLOR.GREEN
      }
    }
    return null
  }, [meterDataRaw])

  return (
    <>
      <BulkMdcInfoHeaderElement justifyContent="space-between" alignItems="center">
        {asset && asset.name && (
          <p>
            <b>
              <AssetLink asset={asset} />
            </b>
          </p>
        )}
      </BulkMdcInfoHeaderElement>

      {/*asset?nomCap is used only for wind plant, while asset?.currentNomCap is used for wind parks */}
      <BulkMdcInfoBodyElement justifyContent="space-between" border={true}>
        <p>{t`Nameplate capacity`}:</p>
        <p>
          {asset ? (
            <>
              {getNomcap(asset)} {AppUnits.KILO_WATT}
            </>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>

      <BulkMdcInfoBodyElement justifyContent="space-between" border={true}>
        <p>{t`Meter data completeness`}:</p>
        <p>
          {meterDataRaw?.summary &&
          meterDataRaw?.summary['meterCompleteness(%)'] &&
          asset &&
          isValidAssetForMDC(asset) ? (
            <>
              {meterDataRaw.summary['meterCompleteness(%)']} {AppUnits.PERCENTAGE}
            </>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>

      <BulkMdcInfoBodyElement justifyContent="space-between" border={true}>
        <p>{t`Count of timesteps (Original)`}:</p>
        <p>
          {meterDataRaw?.summary &&
          meterDataRaw?.summary['observedTimeSteps(count)'] &&
          asset &&
          isValidAssetForMDC(asset) ? (
            <>{meterDataRaw.summary['observedTimeSteps(count)']}</>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>

      <BulkMdcInfoBodyElement justifyContent="space-between" border={true}>
        <p>{t`Count of timesteps (Filtered)`}:</p>
        <p>
          {meterDataRaw?.summary &&
          meterDataRaw?.summary['qualifiedTimeSteps(count)'] &&
          asset &&
          isValidAssetForMDC(asset) ? (
            <>{meterDataRaw.summary['qualifiedTimeSteps(count)']}</>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>

      <BulkMdcInfoBodyElement justifyContent="space-between" border={true}>
        <p>{t`Rate of qualified data`}:</p>
        <p>
          {meterDataRaw?.summary &&
          meterDataRaw?.summary['qualifiedTimeSteps(%)'] &&
          asset &&
          isValidAssetForMDC(asset) ? (
            <>
              {meterDataRaw.summary['qualifiedTimeSteps(%)']} {AppUnits.PERCENTAGE}
            </>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>

      <BulkMdcInfoBodyElement justifyContent="space-between" border={true}>
        <p>{t`Weather model`}:</p>
        <p>
          {meterDataRaw?.summary && meterDataRaw?.summary['weatherModel'] && isValidAssetForMDC(asset) ? (
            <>{meterDataRaw.summary['weatherModel']}</>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>

      <BulkMdcInfoBodyElement
        justifyContent="space-between"
        withBackground={asset && isValidAssetForMDC(asset) && correlationCoefficientHandler}
      >
        <Flex alignItems="center">
          <p>{t`Correlation coefficient`}:</p>
          <TooltipDiv>
            <PopperTooltip
              popperLabel={
                <Box ml={0.3}>
                  <FontAwesomeIcon color={theme.palette.primary.main} icon="info" size="sm" />
                </Box>
              }
              popperContent={
                <div>{t`Correlation coefficient between the asset data and the wind speed from the weather model. Colors indicate that the value is within (green), below (orange) or much below (red) the expected range of an ideal scenario.`}</div>
              }
            />
          </TooltipDiv>
        </Flex>

        <p>
          {meterDataRaw?.summary && meterDataRaw?.summary['chosenGPscore'] && asset && isValidAssetForMDC(asset) ? (
            <>{meterDataRaw.summary['chosenGPscore']}</>
          ) : (
            <>-</>
          )}
        </p>
      </BulkMdcInfoBodyElement>
    </>
  )
}

export default BulkMDCRowStatistics
