import { ReTableItem } from 'modules/reTable/reTable.types'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'

export interface PenaltyFormSaveMainItem {
  update: string
  create: string
}

export interface PenaltyRegulationNew {
  uuid: string
  created: Date | null
  updated: Date | null
  regulationName: string
  gridType: PenaltyRegulationGridType
  userId: string
  shared: boolean
  shapeId: string
  country: string
  state: string
  regulationVersions?: PenaltyRegulationVersionNew[]
  typeOfRow?: PenaltyTableTypeOfRow
  deleted: Date | null
}

export interface PenaltyRegulationVersionNew {
  uuid: string
  regulationVersionName: string
  penaltyRegulationUuid: string
  activeSince: string
  effectiveNow: boolean
  userId: string
  created: Date | null
  updated: Date | null
  deleted: Date | null
  penaltyRegulationRuleSets?: PenaltyRegulationRuleSet[]
  typeOfRow?: PenaltyTableTypeOfRow
}

export interface PenaltyRegulationRuleSet {
  uuid: string
  generatorTypes: PenaltyGeneratorType[]
  overInjectionPenaltyBands: PenaltyBandNew[]
  underInjectionPenaltyBands: PenaltyBandNew[]
  typeOfRow?: PenaltyTableTypeOfRow
  effectiveNow: boolean
  created: Date | null
  updated: Date | null
  deleted: Date | null
}

export enum PenaltyRegulationGridType {
  STATE = 'STATE',
  REGIONAL = 'REGIONAL',
}

export enum PenaltyGeneratorType {
  WIND = 'WIND',
  PV = 'PV',
  HYBRID = 'HYBRID',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum PenaltyBandCostType {
  TIMESERIES_PERCENTAGE = 'TIMESERIES_PERCENTAGE',
  REVENUE_PERCENTAGE = 'REVENUE_PERCENTAGE',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export interface PenaltyBandNew {
  index: number
  from: number | string
  to: number | string
  penaltyCostPerUnit: number
  penaltyCostType: string
  timeseriesId?: string
  deviation?: string
}

export interface RegulationTableItem extends ReTableItem {
  id: string
  regulationName: string
  gridType: PenaltyRegulationGridType
  userId: string
  shared: boolean
  shapeId: string
  country: string
  state: string
  created: Date | null
  deleted: Date | null
  updated: Date | null
  typeOfRow: PenaltyTableTypeOfRow
  effectiveNow: boolean
}

export interface VersionTableItem extends ReTableItem {
  id: string
  regulationId: string
  regulationVersionName: string
  penaltyRegulationUuid: string
  activeSince: Date | string | null
  userId: string
  created: Date | null
  updated: Date | null
  deleted: Date | null
  typeOfRow: PenaltyTableTypeOfRow
  shared: boolean
  effectiveNow: boolean
  penaltyRegulationRuleSets: PenaltyRegulationRuleSet[]
}

export interface RuleSetTableItem extends ReTableItem {
  generatorTypes: string[]
  versionId: string
  regulationId: string
  shared: boolean
  created: Date | null
  deleted: Date | null
  updated: Date | null
  typeOfRow: PenaltyTableTypeOfRow
  effectiveNow: boolean
}

export type PenaltyRegulationTableItemNew = RegulationTableItem | VersionTableItem | RuleSetTableItem
