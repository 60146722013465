import React from 'react'
import { FormApi } from 'final-form'
import { AnyObject } from 'react-final-form'
import FilterSettings from 'modules/asset/assetCrud/meterDataCleansing/FilterSettings'
import { c, jt, t } from 'ttag'
import { Box, Button } from '@material-ui/core'
import styled from 'styled-components'
import {
  BulkMeterDataCleansingFilterSettings,
  MDCAssetType,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import Flex from 'ui/styles/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import ErrorMessage from 'ui/form/ErrorMessage'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'

// Style component
const FilterSettingsButtonsDiv = styled(Flex)`
  margin-top: 1em;
`

const CancelButton = styled(Button)`
  margin-right: 8px !important;
`

const TooltipDiv = styled.div`
  margin-left: 5px;
`

const SolarConfigurationFilters = {
  RANGE: 'solar.range',
  OUTLIER_LEFT: 'solar.outlierLeft',
  OUTLIER_RIGHT: 'solar.outlierRight',
  GHI_THRESHOLD_NO_POWER: 'solar.ghiThresholdNoPower',
  REJECT_NIGHT_VALUES: 'solar.filterNightValues',
  MULTIPLE_GRID_POINTS: 'solar.multipleGridPoints',
}

const WindConfigurationFilters = {
  RANGE: 'wind.range',
  REPETITIVE: 'wind.repetitive',
  OUTLIER_LEFT: 'wind.outlierLeft',
  OUTLIER_RIGHT: 'wind.outlierRight',
  GHI_THRESHOLD_NO_POWER: 'wind.ghiThresholdNoPower',
  MULTIPLE_GRID_POINTS: 'wind.multipleGridPoints',
}

export interface BulkMdcFilterSettingsFormProps {
  form: FormApi
  defaultFilterSettings?: BulkMeterDataCleansingFilterSettings
  showConfirmation: boolean
  onCloseFilterSettingsDialog: () => void
  linkToDefault: boolean
  handleLinkToDefaultChange: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  windAssetsSelected: boolean
  solarAssetsSelected: boolean
}

const BulkMdcFilterSettingsForm: React.FC<BulkMdcFilterSettingsFormProps> = ({
  form,
  defaultFilterSettings,
  showConfirmation,
  onCloseFilterSettingsDialog,
  linkToDefault,
  handleLinkToDefaultChange,
  onFormSubmit,
  windAssetsSelected,
  solarAssetsSelected,
}) => {
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const bothFiltersDisplayed = solarAssetsSelected && windAssetsSelected

  return (
    <form onSubmit={onFormSubmit} noValidate>
      <div style={bothFiltersDisplayed ? { display: 'flex', flexDirection: 'row', gap: '10px' } : {}}>
        {solarAssetsSelected && (
          <FilterSettings
            form={form}
            defaultFilterSettings={(defaultFilterSettings?.solar || {}) as MeterDataCleansingFilterSettings}
            disabled={showConfirmation}
            hasLinkToDefault={true}
            linkToDefault={linkToDefault}
            onLinkToDefaultChange={handleLinkToDefaultChange}
            showAdditionalFiltersForSolar={true}
            showAdditionalFiltersForWind={false}
            assetTypeBulkFilterKeys={SolarConfigurationFilters}
            bulkFilterSettingsHeading={c('Bulk Meter data cleansing').t`Solar filter settings`}
          />
        )}

        {windAssetsSelected && (
          <FilterSettings
            form={form}
            defaultFilterSettings={(defaultFilterSettings?.wind || {}) as MeterDataCleansingFilterSettings}
            disabled={showConfirmation}
            hasLinkToDefault={true}
            linkToDefault={linkToDefault}
            onLinkToDefaultChange={handleLinkToDefaultChange}
            showAdditionalFiltersForSolar={false}
            showAdditionalFiltersForWind={true}
            assetTypeBulkFilterKeys={WindConfigurationFilters}
            bulkFilterSettingsHeading={c('Bulk Meter data cleansing').t`Wind filter settings`}
          />
        )}
      </div>

      <FilterSettingsButtonsDiv justifyContent={showConfirmation ? 'space-between' : 'end'} alignItems="center">
        {showConfirmation ? (
          <Flex>
            <ErrorMessage
              minHeight={false}
              color="orange"
              errorMsg={jt`Warning: Do you want to apply settings to ${assetSelection.length} assets?`}
            />
            <TooltipDiv>
              <PopperTooltip
                popperLabel={
                  <Box ml={0.3}>
                    <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
                  </Box>
                }
                popperContent={
                  <div>{t`These filter settings will be applied to all selected assets and overwrite any asset-specific filter settings.`}</div>
                }
              />
            </TooltipDiv>
          </Flex>
        ) : (
          <></>
        )}

        <div>
          <CancelButton color="primary" size="small" onClick={onCloseFilterSettingsDialog}>
            {showConfirmation ? t`No` : t`Cancel`}
          </CancelButton>
          <Button type="submit" color="primary" size="small" variant="contained">
            {showConfirmation ? t`Yes` : c('BulkMdc').t`Apply settings`}
          </Button>
        </div>
      </FilterSettingsButtonsDiv>
    </form>
  )
}

export default BulkMdcFilterSettingsForm
