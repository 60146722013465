import React from 'react'
import { Column } from 'modules/reTable/reTable.types'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import ReTableEmptyHeaderActionCell from 'modules/reTable/ReTableEmptyHeaderActionCell'

interface AvailabilityTableHeaderRQProps {
  columns: Column[]
  isFormOpen: boolean
}

const AvailabilityTableHeader: React.FC<AvailabilityTableHeaderRQProps> = ({ columns, isFormOpen }) => {
  return (
    <ReTableHeader>
      <ReTableRow>
        <ReTableHeaderActionsCell isHierarchical={true} isFormOpen={isFormOpen} />
        {columns.map((column, index) => (
          <ReTableCell no_border={index === columns.length - 1} key={column.label} width={column.width}>
            <ReTableHeaderWithSort column={column} disableSort={isFormOpen} />
          </ReTableCell>
        ))}
        <ReTableEmptyHeaderActionCell />
      </ReTableRow>
    </ReTableHeader>
  )
}

export default React.memo(AvailabilityTableHeader)
