import { Box, IconButton } from '@material-ui/core'
import { ROUTE_WORKBENCH, ROUTE_WORKBENCH_ASSET_TABLE } from 'modules/app/Routes'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'
import AssetsMenu from 'modules/asset/portfolio/header/AssetsMenu'
import AssetsToolbar from 'modules/asset/portfolio/header/AssetsToolbar'
import React, { useCallback } from 'react'
import { useIsFetching } from 'react-query'
import styled from 'styled-components'
import { t } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import InlineLoading from 'ui/InlineLoading'
import LayoutTitle from 'ui/LayoutTitle'
import Spacer from 'ui/Spacer'
import Flex from 'ui/styles/Flex'
import { useRouter, useRouterMatch } from 'utils/route'
import {
  getAssetMapQueryStrings,
  QUERY_ASSET_MAP,
  stringifyQueryParams,
  useQueryMatch,
  useQueryParams,
} from 'utils/query-string'

const MainFlex = styled(Flex)`
  width: 11em;
  min-width: 11em;
  max-width: 11em;
`

const Title = styled.span`
  margin-right: 0.5em;
`

const AssetsHeader: React.FC = () => {
  const { history } = useRouter()
  const { queryParams } = useQueryParams()
  const { isMatch: isAssetTable } = useRouterMatch(ROUTE_WORKBENCH_ASSET_TABLE)
  const isAssetMapDisplayed = useQueryMatch(QUERY_ASSET_MAP)
  const loading = useIsFetching({ queryKey: QUERY_KEY_ASSETS }) > 0

  const handleToggleAssetTable = useCallback(
    (event) => {
      event.stopPropagation()
      const queryParamsString = stringifyQueryParams(queryParams)
      // history.push(
      //   `${isAssetTable ? ROUTE_WORKBENCH : ROUTE_WORKBENCH_ASSET_TABLE}${
      //     queryParamsString ? `?${queryParamsString}` : ''
      //   }`,
      // )
      const path = isAssetTable
        ? `${ROUTE_WORKBENCH}${queryParamsString ? `?${queryParamsString}` : ''}`
        : ROUTE_WORKBENCH_ASSET_TABLE
      history.push(path)
    },
    [isAssetTable, history, queryParams],
  )

  const handleToggleAssetMap = useCallback(() => {
    const queryParamsString = stringifyQueryParams(getAssetMapQueryStrings())
    history.push(`${ROUTE_WORKBENCH}${isAssetMapDisplayed ? `` : `?${queryParamsString}`}`)
  }, [isAssetMapDisplayed, history])

  return (
    <LayoutTitle wrap={false}>
      <MainFlex alignItems="center">
        <AssetsMenu />
        <Title>{t`Assets`}</Title>
        {loading && <InlineLoading />}
        <Spacer />
        <Box>
          <IconButton edge="end" aria-label="map" onClick={handleToggleAssetMap}>
            <FontAwesomeActionIcon icon={isAssetMapDisplayed ? 'chart-line' : 'map-marked-alt'} />
          </IconButton>
        </Box>
        <Box>
          <IconButton edge="end" aria-label="expand" onClick={handleToggleAssetTable}>
            <FontAwesomeActionIcon icon={isAssetTable ? 'angle-double-left' : 'angle-double-right'} />
          </IconButton>
        </Box>
      </MainFlex>
      {isAssetTable && (
        <Box ml={1}>
          <AssetsToolbar />
        </Box>
      )}
    </LayoutTitle>
  )
}

export default React.memo(AssetsHeader)
