import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import createReducer from 'utils/createReducer'
import { request } from 'utils/request'
import { Product } from './product.types'
import { createSelector } from 'reselect'

// state

interface State {
  result: Product[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
}

// types

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE'
export const GET_PRODUCTS_DISMISS = 'GET_PRODUCTS_DISMISS'
export type GET_PRODUCTS_REQUEST = typeof GET_PRODUCTS_REQUEST
export type GET_PRODUCTS_SUCCESS = typeof GET_PRODUCTS_SUCCESS
export type GET_PRODUCTS_FAILURE = typeof GET_PRODUCTS_FAILURE
export type GET_PRODUCTS_DISMISS = typeof GET_PRODUCTS_DISMISS
export type GET_PRODUCTS_ACTION_TYPE =
  | GET_PRODUCTS_REQUEST
  | GET_PRODUCTS_SUCCESS
  | GET_PRODUCTS_FAILURE
  | GET_PRODUCTS_DISMISS

interface GetProductsAction {
  type: GET_PRODUCTS_ACTION_TYPE
}

// reducers

const result = createReducer<State['result'], GetProductsAction & { products: State['result'] }>(
  (state = initialState.result, { type, products }) => {
    if (type === GET_PRODUCTS_SUCCESS) {
      return products
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetProductsAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case GET_PRODUCTS_REQUEST:
      return true
    case GET_PRODUCTS_SUCCESS:
    case GET_PRODUCTS_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], GetProductsAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case GET_PRODUCTS_SUCCESS:
      case GET_PRODUCTS_DISMISS:
        return null
      case GET_PRODUCTS_FAILURE:
        return error
    }
    return state
  },
)

export const getProductsReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors

export const getProductsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.product.getProducts.result,
  (result) => result,
)
export const getProductsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.product.getProducts.loading,
  (loading) => loading,
)
export const getProductsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.product.getProducts.error,
  (error) => error,
)

// api
export const getProductsState = () => {
  return request(() => axios.get<Product[]>('/api/v1/products'))
}

// sagas
export function* getProductsSaga(): SagaIterator {
  const result = yield call(getProductsState)
  if (result.isSuccessful) {
    const products = result.getData()
    yield put({ type: GET_PRODUCTS_SUCCESS, products })
  } else {
    const error = result.getError()
    yield put({ type: GET_PRODUCTS_FAILURE, error })
  }
}
