import { availabilityUnits } from 'utils/units'

export const DisplayItemsToDeleteLimit = 9

export const limitDecimals = (data: number, fractionDigits = 3, forceFractionalPart = false) => {
  if (typeof data !== 'number') return ''
  const stringData = data.toString()
  const decimals = stringData.split('.')[1]

  if (forceFractionalPart || (decimals && decimals.length > fractionDigits)) {
    return data.toFixed(fractionDigits)
  } else {
    return data
  }
}

export const thousandSeparator = (data: number) => {
  // toLocaleString by default round the value to 3 fractions
  return data.toLocaleString('en-US')
}

export const getUnit = (data: string) => {
  const unit = availabilityUnits.filter((u) => u.name === data)
  return unit[0].value
}

export const isNumeric = (value: unknown): value is number => {
  return !isNaN(parseFloat(value as string)) && isFinite(value as number)
}

export const isJson = (item: unknown) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item

  try {
    item = JSON.parse(item as string)
  } catch (e) {
    return false
  }

  return typeof item === 'object' && item !== null
}

export const isNegative = (value: number) => {
  return value < 0
}

interface FormatNumberParams {
  data?: number
  showFractionalPart?: boolean
  forceFractionalPart?: boolean
  separator?: boolean
  limit?: number
}

export const formatNumber = ({
  data,
  showFractionalPart,
  forceFractionalPart,
  separator,
  limit,
}: FormatNumberParams) => {
  // we need toFixed() here so that scientific notations like "2.37344741638168e-8" work (fixes PR-5745)
  let number: string | number = Number(`${data}`).toFixed(limit)

  if (!showFractionalPart) {
    number = Math.round(Number(number))
  }

  if (limit) {
    number = limitDecimals(Number(number), limit, forceFractionalPart)
  }

  if (separator) {
    const [wholeNum, fractionalNum] = number.toString().split('.')

    if (showFractionalPart && limit && fractionalNum) {
      // Save updated fractional value, which was changed when limit is applied
      // we apply thousand separator only for whole number if the showFractionalPart is true
      const fractionalNum = number.toString().split('.')[1]

      // Add the updated fractional value if limit and showFractionalPart is applied
      number = `${thousandSeparator(Number(wholeNum))}.${fractionalNum}`
    } else {
      number = thousandSeparator(Number(number))
    }
  }

  return number
}

export const formatIndianCurrency = (value: number) => {
  return value.toLocaleString('en-IN', {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'INR',
  })
}

/**
 * This function compares two numbers and returns a boolean.
 * numberCompareHandler
 * @return boolean
 * @param firstNumber
 * @param secondNumber
 */
export const numberCompareHandler = (firstNumber: number, secondNumber: number): boolean => {
  return Math.round(firstNumber) < Math.round(secondNumber)
}

export const capitalizeString = (data: string) => {
  const str = data.toLowerCase()

  //split the above string into an array of strings
  //whenever a blank space is encountered
  const arr = str.split(' ')
  //loop through each element of the array and capitalize the first letter.
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  //Join all the elements of the array back into a string
  //using a blankspace as a separator
  const capitalizedString = arr.join(' ')

  return capitalizedString
}

export const objectHasData = (obj: Record<string, any>) => {
  if (obj) {
    return Object.keys(obj).length > 0
  }
  return false
}

export const isMaxNumber = (number: number | string) => {
  if (number == 1.7976931348623157e308) {
    return true
  }
  if (number?.toString().includes('e')) {
    return true
  } else return false
}
