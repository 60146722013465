import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'
import ReTable from 'modules/reTable/ReTable'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import Flex from 'ui/styles/Flex'
import { Box, lighten } from '@material-ui/core'
import {
  Column,
  ReTableContextMenuItem,
  ReTableContextMenuItemName,
  ReTableId,
  ReTableItem,
  Sort,
} from 'modules/reTable/reTable.types'
import { GetItemHeight } from 'ui/scroll/VirtualScroll'
import { AreaProduct, ConfigureDataStreamTableCellWidth } from 'utils/dataStream'
import styled from 'styled-components'
import { miscellaneousColors, theme } from 'themes/theme-light'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import Highlight from 'ui/Highlight'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector, reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { WeatherConfig } from 'modules/weather/store/weather.types'

import ReTableContextMenu from 'modules/reTable/ReTableContextMenu'
import { RETABLE_SET_SORT } from 'modules/reTable/redux_store/reTable.action.types'
import { useDispatch } from 'react-redux'
import { TDIndented } from 'ui/styles/table'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { math } from 'polished'

const ReTableForecasts = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

interface RowProps {
  selected: boolean
  anyDetailsVisible: boolean
  disabled: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TableRow = styled(({ anyDetailsVisible, ...rest }) => <ReTableRow {...rest} />)<RowProps>`
  &.MuiTableRow-root.Mui-selected {
    background-color: ${(props) => lighten(theme.palette.primary.main, props.anyDetailsVisible ? 0.8 : 0)};
  }
  &.MuiTableRow-root.Mui-selected:hover {
    background-color: ${(props) => lighten(theme.palette.primary.main, props.anyDetailsVisible ? 0.85 : 0.1)};
  }
  &.MuiTableRow-root.Mui-selected .MuiTableCell-body {
    color: ${(props) => (props.anyDetailsVisible ? 'inherit' : 'white')};
  }
`

const TogglePlaceholder = styled.div`
  width: 1.4em;
`

interface NameProps {
  disabled: boolean
}
const Name = styled(({ ...rest }) => <span {...rest} />)<NameProps>`
  color: ${(props) => (props.disabled ? miscellaneousColors.mutedText : 'inherit')};
`

export type ForecastType = (AreaProduct | ForecastConfig | WeatherConfig) & ReTableItem

interface ReTableForecastsManagementProps {
  tableId: ReTableId
  itemHeight: number | GetItemHeight
  items: ForecastType[]
  itemsToRender: ForecastType[]
  selectedItem?: ForecastType
  onItemsToRenderChange: (itemsToRender: ForecastType[]) => void
  columns: Column[]
  onSelectItem?: (item: ForecastType) => void
  contextMenuItems?: ReTableContextMenuItem[]
  onClickMenuItem?: (itemName: ReTableContextMenuItemName, item: ForecastType) => void
  activeForecastIds?: string[]
  actions?: ReactNode
  hasAuthority: boolean
}

const ReTableForecastsManagement: React.FC<ReTableForecastsManagementProps> = ({
  tableId,
  itemHeight,
  columns,
  items,
  selectedItem,
  itemsToRender,
  onItemsToRenderChange,
  onSelectItem,
  contextMenuItems,
  onClickMenuItem,
  activeForecastIds,
  actions,
  hasAuthority,
}) => {
  const dispatch = useDispatch()
  const search = useReTableSelectorWithId(reTableSearchSelector, tableId)
  const sort = useReTableSelectorWithId(reTableSortSelector, tableId)

  const itemsWithChildren = useMemo(() => {
    return items.filter((item) => ((item as ReTableItem)?.uiChildren || []).length > 0)
  }, [items])

  const collapsibleIds = useMemo(() => {
    return itemsWithChildren.map((item) => item.id)
  }, [itemsWithChildren])

  const defaultCollapsed = useMemo(() => {
    return itemsWithChildren.filter((item) => item.uiLevel && item.uiLevel > 1).map((item) => item.id)
  }, [itemsWithChildren])

  const isTree = useMemo(() => {
    return collapsibleIds.length > 0
  }, [collapsibleIds])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'name',
    }),
    [],
  )

  useEffect(() => {
    // initially show the tree hierarchy, therefore sort is not active
    dispatch({ type: RETABLE_SET_SORT, table: tableId, sort: { active: false } })
  }, [tableId])

  const handleSelectItem = useCallback(
    (item: ForecastType) => {
      if (onSelectItem) {
        onSelectItem(item)
      }
    },
    [onSelectItem],
  )

  return (
    <>
      <ReTableToolbar id={tableId} actions={actions} showClearSorting={false} />
      <ReTableForecasts
        id={tableId}
        itemHeight={itemHeight}
        tableHeight="auto"
        items={items || []}
        hasExtendedHeader={false}
        itemsPaddingHeader={1}
        itemsPaddingFooter={1}
        collapsibleIds={collapsibleIds}
        defaultCollapsed={defaultCollapsed}
        defaultSort={defaultSort}
        onItemsToRenderChange={onItemsToRenderChange}
      >
        <ReTableHeader>
          <ReTableRow>
            {isTree && <ReTableHeaderActionsCell isHierarchical={true} />}
            {columns.map((col) => (
              <ReTableCell key={col.label} width={col.width}>
                <ReTableHeaderWithSort column={col} />
              </ReTableCell>
            ))}
          </ReTableRow>
        </ReTableHeader>
        <ReTableBody>
          {itemsToRender &&
            itemsToRender.map((item) => {
              // const disabled = Boolean(
              //   !hasAuthority || (disableSelectedItems && selectedItems.find((si) => si.id === item.id)),
              // )
              const disabled = (activeForecastIds || []).includes(item.id)
              return (
                <TableRow
                  anyDetailsVisible={true}
                  selected={item.id === selectedItem?.id}
                  onClick={() => handleSelectItem(item)}
                  key={item.id}
                  disabled={disabled}
                >
                  <ReTableCell colSpan={isTree ? 2 : 1}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <TDIndented level={isTree && !sort.active ? item.uiLevel : 0}>
                        <Flex>
                          <Flex>
                            {isTree && !sort.active ? (
                              (item.uiChildren || []).length > 0 ? (
                                <ReTableExpandToggle item={item} />
                              ) : (
                                <TogglePlaceholder />
                              )
                            ) : null}
                            <Flex alignItems="center">
                              <Name disabled={disabled}>
                                <FixedWidthTextContainer
                                  text={item?.name}
                                  width={math(
                                    `${ConfigureDataStreamTableCellWidth} - ${
                                      isTree && !sort.active ? item.uiLevel : 0
                                    }em`,
                                  )}
                                >
                                  <Highlight text={item?.name} query={search} />
                                </FixedWidthTextContainer>
                              </Name>
                            </Flex>
                          </Flex>
                          {hasAuthority && contextMenuItems && onClickMenuItem && contextMenuItems.length > 0 && (
                            <ReTableContextMenu
                              menuItems={contextMenuItems}
                              onClickMenuItem={(itemName) => onClickMenuItem(itemName, item)}
                              showContextMenu={true}
                            />
                          )}
                        </Flex>
                      </TDIndented>
                    </Box>
                  </ReTableCell>
                </TableRow>
              )
            })}
        </ReTableBody>
      </ReTableForecasts>
    </>
  )
}

export default React.memo(ReTableForecastsManagement)
