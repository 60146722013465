import React, { useCallback, useEffect, useMemo } from 'react'
import { AuthFormContainer, AuthFormTitle } from 'ui/form/authForm.style'
import { t } from 'ttag'
import ErrorMessage from 'ui/form/ErrorMessage'
import { AuthForms } from 'fixtures/auth'
import { QUERY_AUTH_FORM, QUERY_KEY, stringifyQueryParams, useQueryParams } from 'utils/query-string'
import { useDispatch, useSelector } from 'react-redux'
import { activateUserErrorSelector, activateUserLoadingSelector } from 'modules/auth/redux_store/state/activateUser'
import SuccessMessage from 'ui/form/SuccessMessage'
import { Box, Button } from '@material-ui/core'
import { ACTIVATE_USER_REQUEST } from 'modules/auth/redux_store/auth.action.types'
import { useRouter } from 'utils/route'

const ActivateUser: React.FC = () => {
  const dispatch = useDispatch()
  const { history } = useRouter()
  const activateUserError = useSelector(activateUserErrorSelector)
  const activateUserLoading = useSelector(activateUserLoadingSelector)
  const { register, login } = AuthForms
  const { queryParams } = useQueryParams()

  const activationKey = useMemo(() => queryParams[QUERY_KEY], [queryParams])

  const handleNavigateToLogin = useCallback(() => {
    const queryParamsString = stringifyQueryParams({ [QUERY_AUTH_FORM]: login })
    history.push(`/?${queryParamsString}`)
  }, [])

  const handleNavigateToRegister = useCallback(() => {
    const queryParamsString = stringifyQueryParams({ [QUERY_AUTH_FORM]: register })
    history.push(`/?${queryParamsString}`)
  }, [])

  useEffect(() => {
    if (activationKey) {
      dispatch({ type: ACTIVATE_USER_REQUEST, activationKey: activationKey })
    }
  }, [queryParams, activationKey])

  return (
    <AuthFormContainer>
      <AuthFormTitle variant="h1">{t`Activation`}</AuthFormTitle>
      {(!activationKey || (activateUserError && !activateUserLoading)) && (
        <>
          <ErrorMessage errorMsg={t`Your enercast ID could not be activated.`} />
          <Box mt={1}>{t`Please use the registration form to sign up.`} </Box>
          <Box mt={2}>
            <Button color="primary" variant="contained" onClick={handleNavigateToRegister}>{t`Register`}</Button>
          </Box>
        </>
      )}
      {activationKey && !activateUserError && !activateUserLoading && (
        <>
          <SuccessMessage successMsg={t`Your enercast ID has been activated.`} />
          <Box mt={2}>
            <Button color="primary" variant="contained" onClick={handleNavigateToLogin}>{t`Login`}</Button>
          </Box>
        </>
      )}
    </AuthFormContainer>
  )
}

export default React.memo(ActivateUser)
