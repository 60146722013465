import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import useResizeAware from 'react-resize-aware'

import { useScroll } from 'utils/scroll'
import { transitionFor } from 'themes/theme-light'

export type GetItemHeight = (index: number, start: number, end: number) => number

interface WrapperProps {
  minWidth: string
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  flex: 1 1 auto; /* use exactly the remaining screen space available */
  /* min-width: ${(props) => props.minWidth};*/
  /*height: 0; use full height via flex, not manually */
`

interface ScrollContainerProps {
  isNarrow: boolean
  height?: string
}
const ScrollContainer = styled.div<ScrollContainerProps>`
  position: relative;
  height: ${(props) => (props.height ? props.height : '100%')};
  /* contain: strict for 100%, inherit for dynamic height */
  contain: ${(props) => (!props.height || props.height === '100%' ? 'strict' : 'inherit')};
  overflow-x: ${(props) => (props.isNarrow ? 'hidden' : 'auto')};
  overflow-y: auto;
  transform: translateZ(0);
  will-change: scroll-position;
`
interface VirtualWrapperProps {
  height: number
  marginTop: number
}
const VirtualWrapper = styled.div<VirtualWrapperProps>`
  height: ${(props) => props.height}px;
  transform: translateY(${(props) => (props.marginTop ? props.marginTop : 0)}px);
  transition: ${transitionFor(['transform'])} !important;
`

export interface VirtualRange {
  renderedItemCount: number
  start: number
  end: number
  startGap: number
  endGap: number
}

interface VirtualScrollProps {
  itemHeight: number | GetItemHeight
  itemCount: number
  itemsPaddingHeader?: number
  itemsPaddingFooter?: number
  renderAheadCount?: number
  marginTop?: number
  minWidth?: string
  height?: string
  isNarrow?: boolean
  currentPaginationItems?: number
  onVirtualRangeChange: (range: VirtualRange) => void
}
const VirtualScroll: React.FC<VirtualScrollProps> = ({
  itemHeight,
  itemCount,
  itemsPaddingHeader = 0,
  itemsPaddingFooter = 0,
  renderAheadCount = 25,
  marginTop = 0,
  minWidth = '0',
  height,
  isNarrow = false,
  currentPaginationItems,
  onVirtualRangeChange,
  children,
  ...rest
}) => {
  const [resizeListener, { height: containerHeight }] = useResizeAware()
  const { ref, scrollTop } = useScroll()

  const virtualHeight = ((currentPaginationItems || itemCount) + itemsPaddingHeader + itemsPaddingFooter) * itemHeight

  const [itemsRange, setItemsRange] = useState<VirtualRange>({
    renderedItemCount: 0,
    start: 0,
    end: 0,
    startGap: 0,
    endGap: 0,
  })

  useEffect(() => {
    const renderedItemCount = Math.max(0, Math.ceil(containerHeight / itemHeight))
    const totalItemCount = itemCount + itemsPaddingHeader + itemsPaddingFooter
    const start = Math.max(
      0,
      Math.min(totalItemCount - renderedItemCount, Math.floor(scrollTop / itemHeight)) - renderAheadCount,
    )
    const end = Math.max(0, Math.min(start + renderedItemCount + renderAheadCount * 2, totalItemCount) - 1)
    const startGap = Math.max(0, start) * itemHeight
    const endGap = virtualHeight - startGap - (end - start + 1) * itemHeight

    if (
      itemsRange.renderedItemCount !== renderedItemCount ||
      itemsRange.start !== start ||
      itemsRange.end !== end ||
      itemsRange.startGap !== startGap ||
      itemsRange.endGap !== endGap
    ) {
      setItemsRange({ renderedItemCount, start, end, startGap, endGap })
    }
  }, [virtualHeight, scrollTop, containerHeight, itemHeight, itemCount, itemsPaddingHeader, itemsPaddingFooter])

  useEffect(() => {
    onVirtualRangeChange(itemsRange)
  }, [itemsRange])

  return (
    <Wrapper {...rest} minWidth={minWidth}>
      <ScrollContainer ref={ref} isNarrow={isNarrow} height={height}>
        {resizeListener}
        <VirtualWrapper height={virtualHeight} marginTop={marginTop}>
          {children}
        </VirtualWrapper>
      </ScrollContainer>
    </Wrapper>
  )
}

export default React.memo(VirtualScroll)
