import React, { useMemo } from 'react'
import { c, t } from 'ttag'
import Flex from 'ui/styles/Flex'
import { Box, Table, TableBody, TableHead, Typography } from '@material-ui/core'
import { formatDateShort, isDateValid } from 'utils/date'
import { differenceInHours } from 'date-fns'
import { DetailsKey, DetailsKeyValueGrid, DetailsValue } from 'ui/form/assetForm.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'
import { table, theme } from 'themes/theme-light'
import { useField } from 'react-final-form'
import { Asset } from 'modules/asset/store/asset.types'
import styled from 'styled-components'
import { useMeterDataCleansingChartSeriesSet } from 'utils/hooks/useMeterDataCleansingChartSeries'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { sumOfAvailableSeriesForFullTime, sumOfAvailableSeriesForSelectedTime } from 'utils/meterDataCleansing'
import { AppUnits } from 'utils/units'
import Number from 'ui/Number'
import { Timezone } from 'fixtures/timezones'
import TableRow from '@material-ui/core/TableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'

const MeterDataStatisticDiv = styled(Flex)`
  flex-direction: column;
  flex: 1 1 0;
  margin-top: 1em;
`

const ValueAsParagraph = styled.p`
  text-align: end;
  flex: 1 1 0;
`

const DetailsKeyWithFlex = styled(DetailsKey)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const StyledTableCell = styled(ReTableCell)`
  &.MuiTableCell-root {
    height: ${table.rowHeight}px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 0.3em;
  }
`

const FirstColumnWidth = '19em'
const SecondColumnWidth = '12em'

enum DURATION_STATISTIC {
  TOTAL_TIME_PERIOD = 'totalTimePeriod',
  SELECTED_TIME_PERIOD = 'selectedTimePeriod',
}
// there are 24 time steps in a day if the temporal resolution is 60 minutes
const TIME_STEPS = 24
const DIVIDE_VALUE_FROM_MILLISECONDS_TO_HOURS = 3600000

interface MeterDataStatisticsProps {
  asset: Asset
  totalTimePeriod: MDCTimePeriod
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  cleansingSummary: any
  refreshData: boolean
  userTimezone: Timezone
}

const MeterDataStatistics: React.FC<MeterDataStatisticsProps> = ({
  asset,
  totalTimePeriod,
  timePeriodToExcludeFromChart,
  cleansingSummary,
  refreshData,
  userTimezone,
}) => {
  const { TOTAL_TIME_PERIOD, SELECTED_TIME_PERIOD } = DURATION_STATISTIC
  const selectedTimePeriodForTraining = useField<MDCTimePeriod>('timePeriodForTraining').input.value

  const totalTimeStartDate = totalTimePeriod?.start.date as Date
  const totalTimeEndDate = totalTimePeriod?.end.date as Date

  const selectedTimeStartDate =
    Object.keys(selectedTimePeriodForTraining).length > 0
      ? (selectedTimePeriodForTraining?.start.date as Date)
      : totalTimeStartDate
  const selectedTimeEndDate =
    Object.keys(selectedTimePeriodForTraining).length > 0
      ? (selectedTimePeriodForTraining?.end.date as Date)
      : totalTimeEndDate

  const totalTimeChartSeriesSet = useMeterDataCleansingChartSeriesSet(asset, [totalTimeStartDate, totalTimeEndDate])
  // TODO Here we are getting also availability (for statistic purpose), maybe we need only meter data results
  const selectedTimeChartSeriesSet = useMeterDataCleansingChartSeriesSet(asset, [
    selectedTimeStartDate,
    selectedTimeEndDate,
  ])

  // Convert detected interval (which is in milliseconds) to hours
  const getDetectedIntervalInHours = () => {
    const detectedIntervalInMilliseconds = selectedTimeChartSeriesSet[0]?.custom?.detectedInterval
    return detectedIntervalInMilliseconds / DIVIDE_VALUE_FROM_MILLISECONDS_TO_HOURS
  }

  const totalTimePeriodInHours = useMemo(() => {
    if (totalTimeStartDate && totalTimeEndDate) {
      return differenceInHours(totalTimeEndDate, totalTimeStartDate)
    } else return 0
  }, [totalTimeStartDate, totalTimeEndDate])

  const selectedTimePeriodInHours = useMemo(() => {
    if (selectedTimeStartDate && selectedTimeEndDate) {
      return differenceInHours(selectedTimeEndDate, selectedTimeStartDate)
    } else return 0
  }, [selectedTimeStartDate, selectedTimeEndDate])

  // This function returns how many hours are left after cleansing
  // We need to add a small convert (in days) while using this function
  const remainingDataAfterCleansing = () => {
    if (cleansingSummary) {
      return cleansingSummary?.['qualifiedTimeSteps(count)'] * getDetectedIntervalInHours()
    }
    return 0
  }

  // Completeness statistic handler
  const completenessStatisticHandler = (type: DURATION_STATISTIC) => {
    if (type === TOTAL_TIME_PERIOD && totalTimeChartSeriesSet.length > 0) {
      const countOfSeries = totalTimeChartSeriesSet[0]?.data?.length || 0
      const roundedValue = (countOfSeries / (totalTimePeriodInHours * (1 / getDetectedIntervalInHours()))) * 100
      // console.log({
      //   totalTimeStartDate,
      //   totalTimeEndDate,
      //   countOfSeries,
      //   totalTimePeriodInHours,
      //   detectedInterval: getDetectedIntervalInHours(),
      // })
      return roundedValue
    } else if (type === SELECTED_TIME_PERIOD && selectedTimeChartSeriesSet.length > 0) {
      if (selectedTimePeriodInHours === 0) return 0 // we are adding this condition because , in lines above , division with 0 doesn't make sense
      const countOfSeries = selectedTimeChartSeriesSet[0]?.data?.length || 0
      const roundedValue = (countOfSeries / (selectedTimePeriodInHours * (1 / getDetectedIntervalInHours()))) * 100
      return roundedValue
    }
    return null
  }

  // Data loss (Manual) handler
  const dataLossManualHandler = (type: DURATION_STATISTIC) => {
    if (
      timePeriodToExcludeFromChart.length > 0 &&
      totalTimeChartSeriesSet.length &&
      selectedTimeChartSeriesSet.length
    ) {
      const seriesData =
        type === TOTAL_TIME_PERIOD ? totalTimeChartSeriesSet[0]?.data : selectedTimeChartSeriesSet[0]?.data
      const countOfSeries = seriesData?.length || 0
      if (countOfSeries == 0) return 0
      if (type === TOTAL_TIME_PERIOD) {
        const sumOfSeriesData = sumOfAvailableSeriesForFullTime(timePeriodToExcludeFromChart, seriesData, userTimezone)
        return (sumOfSeriesData / countOfSeries) * 100
      } else if (
        type === SELECTED_TIME_PERIOD &&
        isDateValid(selectedTimeStartDate) &&
        isDateValid(selectedTimeEndDate)
      ) {
        const sumOfSeriesData = sumOfAvailableSeriesForSelectedTime(
          timePeriodToExcludeFromChart,
          selectedTimePeriodForTraining,
          seriesData,
          userTimezone,
        )
        return (sumOfSeriesData / countOfSeries) * 100
      }
    } else return 0
  }

  const qualifiedDataHandler = (type: DURATION_STATISTIC) => {
    const duration = type === TOTAL_TIME_PERIOD ? totalTimePeriodInHours : selectedTimePeriodInHours
    const completenessDays = (duration * completenessStatisticHandler(type)) / 100
    const dataLossDays = (completenessDays * dataLossManualHandler(type)) / 100
    // return Math.floor(completenessDays - dataLossDays)
    return completenessDays - dataLossDays
  }

  return (
    <MeterDataStatisticDiv>
      <Typography variant="subtitle1">{c('Meter data cleansing').t`Meter data statistics`}</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>{t`Type`}</StyledTableCell>
            <StyledTableCell align="right">{c('Meter data cleansing').t`Available`}</StyledTableCell>
            <StyledTableCell align="right">{c('Meter data cleansing').t`Selected`}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>{t`Start date`}:</StyledTableCell>
            <StyledTableCell align="right">
              {isDateValid(totalTimeStartDate) ? formatDateShort(totalTimeStartDate) : '-'}
            </StyledTableCell>
            <StyledTableCell align="right">
              {isDateValid(selectedTimeStartDate) ? formatDateShort(selectedTimeStartDate) : '-'}
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell>{t`End date`}:</StyledTableCell>
            <StyledTableCell align="right">
              {isDateValid(totalTimeEndDate) ? formatDateShort(totalTimeEndDate) : '-'}
            </StyledTableCell>
            <StyledTableCell align="right">
              {isDateValid(selectedTimeEndDate) ? formatDateShort(selectedTimeEndDate) : '-'}
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell>{t`Period length`}:</StyledTableCell>
            <StyledTableCell align="right">
              <Number
                showFractionalPart={false}
                data={Math.floor(totalTimePeriodInHours / TIME_STEPS)}
                unit={t`days`}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Number
                showFractionalPart={false}
                data={Math.floor(selectedTimePeriodInHours / TIME_STEPS)}
                unit={t`days`}
              />
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell>
              <Flex>
                {t`Data completeness`}:{' '}
                <PopperTooltip
                  popperLabel={
                    <Box ml={0.7}>
                      <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
                    </Box>
                  }
                  popperContent={
                    <div>{c('Meter data cleansing')
                      .t`Percentage of the amount of existing data against the amount of expected data within a given period. Data completeness is 100% when there are no gaps in a dataset.`}</div>
                  }
                />
              </Flex>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Number
                data={Math.round(completenessStatisticHandler(TOTAL_TIME_PERIOD) || 0)}
                showFractionalPart={false}
                unit={AppUnits.PERCENTAGE}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Number
                data={Math.round(completenessStatisticHandler(SELECTED_TIME_PERIOD) || 0)}
                showFractionalPart={false}
                unit={AppUnits.PERCENTAGE}
              />
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell>{t`Remaining data after time period selection`}:</StyledTableCell>
            <StyledTableCell align="right">
              <Number
                showFractionalPart={false}
                data={
                  qualifiedDataHandler(TOTAL_TIME_PERIOD)
                    ? Math.floor(qualifiedDataHandler(TOTAL_TIME_PERIOD) / TIME_STEPS)
                    : 0
                }
                unit={t`days`}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Number
                showFractionalPart={false}
                data={
                  qualifiedDataHandler(SELECTED_TIME_PERIOD)
                    ? Math.floor(qualifiedDataHandler(SELECTED_TIME_PERIOD) / TIME_STEPS)
                    : 0
                }
                unit={t`days`}
              />
            </StyledTableCell>
          </TableRow>

          <TableRow>
            <StyledTableCell>{t`Remaining data after filtering`}:</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>
              <Flex flexWrap="nowrap" justifyContent="flex-end" alignItems="flex-end">
                {!refreshData ? (
                  <Number
                    showFractionalPart={false}
                    data={Math.floor(remainingDataAfterCleansing() / TIME_STEPS)}
                    unit={t`days`}
                  />
                ) : (
                  <>-</>
                )}
              </Flex>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </MeterDataStatisticDiv>
  )
}

export default React.memo(MeterDataStatistics)
