import { apiRequest } from 'utils/request'
import axios from 'axios'
import { User } from 'modules/auth/Auth.types'
import { useQuery } from 'react-query'

export const QUERY_KEY_USER_AUTHORIZED_ACCOUNTS = 'user:AuthorizedAccounts'

const getAuthorizedAccounts = async () => {
  return await apiRequest<User[]>(() => axios.get(`api/users?page=0&size=200000`))
}

export const impersonateUser = async (login: string) => {
  return await apiRequest(() => axios.get(`api/login/impersonate?username=${login}`))
}

export const getMeterDataStatus = async () => {
  return await apiRequest(() => axios.get(`/api/usersettings/meterDataStatus`))
}

export const useAuthorizedAccounts = () => {
  return useQuery<User[]>(QUERY_KEY_USER_AUTHORIZED_ACCOUNTS, getAuthorizedAccounts)
}
