import React from 'react'

import { Box, MenuItem, TextField as MaterialTextField } from '@material-ui/core'
import {
  AutoCompleteWithoutUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  KeyValuePair,
  SelectFieldWithoutLabelAndUnit,
  TextFieldWithoutLabelAndUnit,
} from 'ui/form/assetForm.style'
import { Field } from 'react-final-form'
import { Checkbox, Select } from 'final-form-material-ui'
import { getPenaltyGridTypes } from 'utils/penaltyRegulations'
import { RenderInputParams } from '@material-ui/lab'
import { c } from 'ttag'
import { CheckboxControl } from 'ui/form/authForm.style'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useIndianStates } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import { useCustomMuiStyles } from 'themes/theme-light'

const RegulationForm: React.FC = () => {
  const indianStates = useIndianStates()?.data || []
  const user = useSelector(getUserResultSelector)
  const gridTypes = getPenaltyGridTypes()
  const classes = useCustomMuiStyles({ important: true })

  return (
    <Box mt={1}>
      <KeyValuePair>
        <FormKeyValueGrid>
          <FormKey>{c('PenaltyRegulation').t`Grid type`}:</FormKey>
          <FormValue>
            <Field name="gridType">
              {(props) => {
                return (
                  <SelectFieldWithoutLabelAndUnit>
                    <Select input={props.input} meta={props.meta}>
                      {gridTypes.map((grid) => {
                        return (
                          <MenuItem key={grid.id} value={grid.id}>
                            {grid.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </SelectFieldWithoutLabelAndUnit>
                )
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>

        {(isAdmin(user) || isImpersonatedAdmin(user)) && (
          <FormKeyValueGrid>
            <FormKey className={classes.adminComponent}>{c('PenaltyRegulation').t`Shape`}:</FormKey>
            <FormValue isAdminComponent>
              <Field name="shapeId">
                {(props) => {
                  return (
                    <TextFieldWithoutLabelAndUnit
                      rowsMax={6}
                      multiline
                      type="text"
                      fullWidth
                      input={props.input}
                      meta={props.meta}
                    />
                  )
                }}
              </Field>
            </FormValue>
          </FormKeyValueGrid>
        )}

        <FormKeyValueGrid>
          <FormKey>{c('PenaltyRegulation').t`Country`}:</FormKey>
          <FormValue>
            <Field name="country">
              {(props) => {
                return (
                  <SelectFieldWithoutLabelAndUnit>
                    <Select input={props.input} meta={props.meta}>
                      <MenuItem value="India">India</MenuItem>
                    </Select>
                  </SelectFieldWithoutLabelAndUnit>
                )
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>

        <FormKeyValueGrid>
          <FormKey>{c('PenaltyRegulation').t`State`}:</FormKey>
          <FormValue>
            <Field name="state">
              {({ input, meta }) => {
                return (
                  <>
                    <AutoCompleteWithoutUnit
                      value={input.value}
                      options={indianStates
                        .filter((is) => is.toLowerCase().includes((input?.value || '').toLowerCase()))
                        .map((el) => el)}
                      onChange={(a, b) => input.onChange(b)}
                      renderInput={(params: RenderInputParams) => (
                        <MaterialTextField
                          value={input.value}
                          {...params}
                          margin="normal"
                          fullWidth
                          error={meta.error && (meta.dirty || meta.submitFailed)}
                        />
                      )}
                    />
                    <>
                      {meta.error && (meta.dirty || meta.submitFailed) && (
                        <div style={{ color: 'red', fontSize: '12px' }}>{meta.error}</div>
                      )}
                    </>
                  </>
                )
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>

        {(isAdmin(user) || isImpersonatedAdmin(user)) && (
          <FormKeyValueGrid>
            <FormKey className={classes.adminComponent}>{c('PenaltyRegulation').t`System-wide`}:</FormKey>
            <FormValue isAdminComponent>
              <Box ml={0.7} mt={1} display="flex" flexDirection="row" alignItems="center">
                <CheckboxControl label={''} control={<Field name="shared" type="checkbox" component={Checkbox} />} />
                <div style={{ marginBottom: '5px' }}>{c('PenaltyRegulation').t`System-wide penalty regulation`}</div>
              </Box>
            </FormValue>
          </FormKeyValueGrid>
        )}
      </KeyValuePair>
    </Box>
  )
}

export default RegulationForm
