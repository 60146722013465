import produce, { Draft } from 'immer'

const createReducer = <State, Action>(
  // this reducer will be called with an immutable immer draft
  reducerWithDraft: (state: Draft<State | undefined>, action: Action) => State | undefined,
): // this is the defined signature of any redux reducer
((state: State | undefined, action: Action) => State | undefined) => (
  state: State | undefined,
  action: Action,
): State | undefined =>
  // create immer store
  produce<State | undefined>(state, (draft) => reducerWithDraft(draft, action))

export default createReducer
