import * as React from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import styled from 'styled-components'

import { PlausibilityData } from 'modules/asset/plausability/Plausability.types'
import { ERROR, WARN } from 'fixtures/plausability'
import { colors } from 'themes/theme-light'

interface PlausibilityToolTipProps {
  results: PlausibilityData[]
}

interface PlausibilityListItem {
  status: string
}

const PlausibilityListItem = styled(ListItem)<PlausibilityListItem>`
  background-color: ${(props) => (props.status === WARN ? '#f5d9a7' : props.status === ERROR ? '#f9cbcb' : '')};
  border-bottom: 1px solid ${colors.borderLight};
  &:last-child {
    border-bottom: none;
  }
`

const PlausabilityToolTip: React.FC<PlausibilityToolTipProps> = ({ results }) => {
  return (
    <List>
      {results
        .filter((a) => a.status === ERROR || a.status === WARN)
        .map((data, index) => {
          return (
            <PlausibilityListItem status={data.status} key={index} alignItems="flex-start">
              <ListItemText primary={data.type} secondary={<React.Fragment>{data.message}</React.Fragment>} />
            </PlausibilityListItem>
          )
        })}
    </List>
  )
}

export default React.memo(PlausabilityToolTip)
