import React, { useEffect, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { Field, useField } from 'react-final-form'
import FormControl from '@material-ui/core/FormControl'
import { Select } from 'final-form-material-ui'
import MenuItem from '@material-ui/core/MenuItem'
import DateTimePickerFinalForm from 'ui/elements/DateTimePickerFinalForm'
import { t } from 'ttag'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'
import Flex from 'ui/styles/Flex'
import ScheduleShowInChartButton from 'modules/workspace/schedule/ScheduleShowInChartButton'
import { ScheduleFormDateContainer } from 'modules/workspace/schedule/ScheduleFormFields'
import { ChartDataRangeType, RangeTypes } from 'modules/workspace/store/workspace.types'
import {
  convertZonedTimeToAnotherZonedTime,
  convertZonedTimeToUtc,
  getDateRangeFromChartDataRange,
  getRangeTypeFromTimePeriod,
  getScheduleFormDateRangeOptions,
  isDateValid,
} from 'utils/date'
import { addSeconds, subSeconds } from 'date-fns'
import { ScheduleFormFieldsFirstColumnContainer } from 'modules/workspace/schedule/ScheduleForm'
import styled from 'styled-components'
import { Timezone } from 'fixtures/timezones'
import { FormApi } from 'final-form'
import { CreateScheduleInputData } from 'modules/workspace/schedule/schedule.types'

const DatesContainer = styled(Flex)`
  margin-top: 5px;
  *.MuiFormHelperText-root {
    position: absolute;
    bottom: -20px;
  }
`

interface SchedulePeriodInputProps {
  userTimezone: Timezone
  form: FormApi
  formValues: CreateScheduleInputData
  onSetDatesChangedDueToTimezoneUpdate: (value: boolean) => void
  onCheckAndChangeMainChartDateRange: (currentValues: CreateScheduleInputData) => void
}

const SchedulePeriodInput: React.FC<SchedulePeriodInputProps> = ({
  userTimezone,
  form,
  formValues,
  onSetDatesChangedDueToTimezoneUpdate,
  onCheckAndChangeMainChartDateRange,
}) => {
  // Range Options
  const scheduleRanges = getScheduleFormDateRangeOptions()

  const currentStartDate = useField('start.date').input.value
  const currentStartDateTimezone = useField('start.timezone').input.value || userTimezone
  const currentEndDate = useField('end.date').input.value
  const currentEndDateTimezone = useField('end.timezone').input.value || userTimezone
  const currentRangeType = useField('range').input.value

  const startDateInUTC = isDateValid(currentStartDate) ? convertZonedTimeToUtc(currentStartDate, userTimezone) : null
  const endDateInUTC = isDateValid(currentEndDate) ? convertZonedTimeToUtc(currentEndDate, userTimezone) : null

  const timezoneWhenFormInitialized = formValues.timezoneWhenFormInitialized
  /**
   * Update dates when timezone changed from user settings
   */
  useEffect(() => {
    if (userTimezone !== timezoneWhenFormInitialized) {
      const startDateInNewTimezone = convertZonedTimeToAnotherZonedTime(
        currentStartDate,
        timezoneWhenFormInitialized,
        userTimezone,
      )
      const EndDateInNewTimezone = convertZonedTimeToAnotherZonedTime(
        currentEndDate,
        timezoneWhenFormInitialized,
        userTimezone,
      )
      form.mutators.setValue('start.date', startDateInNewTimezone)
      form.mutators.setValue('end.date', EndDateInNewTimezone)
      form.mutators.setValue('timezoneWhenFormInitialized', userTimezone)
      onSetDatesChangedDueToTimezoneUpdate(true)
    }
  }, [userTimezone, currentStartDate, currentEndDate, timezoneWhenFormInitialized])

  /**
   * Update the timezones when period is changed
   * To avoid the cyclic update we reset the field state
   */
  const startDateModified = useField('start.date')?.meta.modified
  const endDateModified = useField('end.date')?.meta.modified
  const rangeModified = useField('range')?.meta.modified
  useEffect(() => {
    if (startDateModified || endDateModified || rangeModified) {
      form.resetFieldState('start.date')
      form.resetFieldState('end.date')
      form.resetFieldState('range')
      form.mutators.setValue('start.timezone', userTimezone)
      form.mutators.setValue('end.timezone', userTimezone)
      form.mutators.setValue('timezoneWhenFormInitialized', userTimezone)
      onSetDatesChangedDueToTimezoneUpdate(false)
    }
  }, [startDateModified, endDateModified, rangeModified, userTimezone])

  /**
   * Update the range selector based on dates
   */
  useEffect(() => {
    if (currentStartDate && currentEndDate && currentRangeType) {
      const currentStartDateInUTC = convertZonedTimeToAnotherZonedTime(currentStartDate, userTimezone, 'UTC')
      const currentEndDateInUTC = convertZonedTimeToAnotherZonedTime(currentEndDate, userTimezone, 'UTC')
      const rangeFromTimePeriod = getRangeTypeFromTimePeriod({
        timePeriod: {
          start: currentStartDateInUTC,
          end: subSeconds(currentEndDateInUTC, 1),
        },
        rangeOptions: scheduleRanges,
        rangeType: RangeTypes.MAIN_CHART_RANGE,
        timezone: userTimezone,
      })
      if (currentRangeType !== rangeFromTimePeriod && currentRangeType !== ChartDataRangeType.CHART_DATA_RANGE_CUSTOM) {
        form.mutators.setValue('range', rangeFromTimePeriod)
      }
    }
  }, [currentStartDate, currentEndDate, scheduleRanges, currentRangeType, userTimezone])

  const handleRangeChange = (rangeType: ChartDataRangeType) => {
    if (rangeType !== ChartDataRangeType.CHART_DATA_RANGE_CUSTOM) {
      const period = getDateRangeFromChartDataRange({ rangeType, customRange: null }, userTimezone, true)
      const periodInUserTimezone = [
        convertZonedTimeToAnotherZonedTime(period[0], 'UTC', userTimezone),
        convertZonedTimeToAnotherZonedTime(period[1], 'UTC', userTimezone),
      ]

      form.mutators.setValues([
        {
          field: 'start.date',
          value: periodInUserTimezone[0],
        },
        {
          field: 'start.timezone',
          value: userTimezone,
        },
        {
          field: 'end.date',
          value: addSeconds(periodInUserTimezone[1], 1),
        },
        {
          field: 'end.timezone',
          value: userTimezone,
        },
      ])
    }
  }

  return (
    <DatesContainer direction="row" alignItems="center" gap="20px">
      <ScheduleFormFieldsFirstColumnContainer>
        <Box mt={1.7}>{t`Schedule period:`}</Box>
      </ScheduleFormFieldsFirstColumnContainer>
      <Flex gap="13px">
        <Box width="9em">
          <Field name="range">
            {(props) => {
              return (
                <FormControl style={{ width: '100%' }}>
                  <Select label="" input={props.input} meta={props.meta}>
                    {scheduleRanges?.map((item) => (
                      <MenuItem onClick={() => handleRangeChange(item.key)} key={item.key} value={item.key}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            }}
          </Field>
        </Box>

        <ScheduleFormDateContainer alignItems="center">
          <Field name="start.date" fullWidth>
            {({ input, meta }) => {
              return (
                <DateTimePickerFinalForm
                  label={t`Start`}
                  input={input}
                  meta={meta}
                  maxDate={currentEndDate ? currentEndDate : null}
                />
              )
            }}
          </Field>
          <Box mt={1.5} mr={1.5}>
            {startDateInUTC && currentStartDateTimezone && currentStartDateTimezone !== userTimezone && (
              <DateTimezonePopover dateInUTC={startDateInUTC} originalTimezone={currentStartDateTimezone} />
            )}
          </Box>
        </ScheduleFormDateContainer>

        <ScheduleFormDateContainer alignItems="center">
          <Field name="end.date" fullWidth>
            {({ input, meta }) => {
              return (
                <DateTimePickerFinalForm
                  label={t`End`}
                  input={input}
                  minDate={currentStartDate ? currentStartDate : null}
                  meta={meta}
                />
              )
            }}
          </Field>

          <Box mt={1.5} mr={1.5}>
            {endDateInUTC && currentEndDateTimezone && currentEndDateTimezone !== userTimezone && (
              <DateTimezonePopover dateInUTC={endDateInUTC} originalTimezone={currentEndDateTimezone} />
            )}
          </Box>
        </ScheduleFormDateContainer>

        <Flex alignItems="end">
          <ScheduleShowInChartButton
            onCheckAndChangeMainChartDateRange={onCheckAndChangeMainChartDateRange}
            formValues={formValues}
          />
        </Flex>
      </Flex>
    </DatesContainer>
  )
}

export default SchedulePeriodInput
