import React from 'react'
import EvaluationTable from 'modules/workspace/advancedChartWidgets/evaluation/table/EvaluationTable'
import styled from 'styled-components'
import { useEvaluationTableItems } from 'utils/evaluation'

const Content = styled.div`
  position: relative;
`

const EvaluationContent = () => {
  const tableItems = useEvaluationTableItems()

  const { evaluationTableItemsForReTable, isSuccess, isLoading } = tableItems

  // Getting dates from selection
  // const timezone = useSelector(getUserTimezoneSelector)
  // const chartSelectedDateRangeInUTC = useWorkspaceChartSelectedDateRange()
  // const chartSelectedDataRangeInSelectedTimezone = useMemo(
  //   () => [
  //     convertUtcToZonedTime(convertUTCToLocalDate(chartSelectedDateRangeInUTC[0]), timezone),
  //     convertUtcToZonedTime(convertUTCToLocalDate(chartSelectedDateRangeInUTC[1]), timezone),
  //   ],
  //   [chartSelectedDateRangeInUTC],
  // )

  // Formatted dates
  // const formattedSelectedDatesWithTimezone = [
  //   formatDate(chartSelectedDataRangeInSelectedTimezone[0], null, DATE_FORMAT_DEFAULT_SHORT),
  //   formatDate(chartSelectedDataRangeInSelectedTimezone[1], null, DATE_FORMAT_DEFAULT_SHORT),
  // ]

  // const { yearAndMonth } = getYearAndMonths(formattedSelectedDatesWithTimezone)

  // const [statePeriod, setStatePeriod] = useState({
  //   yearAndMonth: yearAndMonth,
  // })

  // const updatedTableItems = useMemo(() => {
  //   // Highlight rows that are part of selected dates
  //   if (statePeriod.yearAndMonth.length > 0) {
  //     tableItems.forEach((item) => {
  //       if (item.uiLevel === 1) {
  //         const findIndexOfYear = statePeriod.yearAndMonth.findIndex((el) => {
  //           return moment(el).year().toString() === item.year
  //         })
  //
  //         item.highlight = findIndexOfYear > -1
  //       } else if (item.uiLevel === 2) {
  //         item.highlight = statePeriod.yearAndMonth.includes(item.yearAndMonth)
  //       }
  //     })
  //   }
  //   return tableItems
  // }, [statePeriod, tableItems])

  // const onSelect = (event: React.MouseEvent<HTMLDivElement>, item: ReTableItem) => {
  //   // Handle month selection (which means year and month (like 2020-01))
  //   setStatePeriod((prevState) => {
  //     const nextStateForYearsAndMonth = [...prevState.yearAndMonth]
  //
  //     // Get index of selected month in yearsAndMonth array
  //     const foundMonthValueIndex = nextStateForYearsAndMonth.findIndex((el) => el === item.yearAndMonth)
  //
  //     if (foundMonthValueIndex > -1) {
  //       // remove selected year-month if it exists
  //       nextStateForYearsAndMonth.splice(foundMonthValueIndex, 1)
  //     } else {
  //       // add selected year-month if it doesn't exist
  //       nextStateForYearsAndMonth.push(item.yearAndMonth as string)
  //     }
  //     return { yearAndMonth: nextStateForYearsAndMonth }
  //   })
  // }

  const showTableData = isSuccess && !isLoading && evaluationTableItemsForReTable.length > 0

  return (
    <>
      <Content>
        <EvaluationTable
          tableItems={evaluationTableItemsForReTable}
          showTableData={showTableData}
          isLoading={isLoading}
        />
      </Content>
    </>
  )
}

export default EvaluationContent
