import React, { useCallback } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { c } from 'ttag'
import { FieldArray } from 'react-final-form-arrays'
import PenaltyBandWidget from 'modules/data/penalties/PenaltyRegulationNew/PenaltyBandWidget'
import { PenaltyBandTypes } from 'utils/penaltyRegulations'
import {
  PenaltyBandNew,
  PenaltyRegulationTableItemNew,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { FormApi } from 'final-form'
import { isMaxNumber } from 'utils/dataFormatting'
import { useField } from 'react-final-form'

export interface HandleToValueChangeProps {
  currentBandToValue: number | string
  isLastBand: boolean
  index: number
}

interface PenaltyBandTypeSectionProps {
  form: FormApi<PenaltyRegulationTableItemNew>
  injectionBands: PenaltyBandNew[]
  fieldArrayKey: string
  penaltyBandType: PenaltyBandTypes
  onAddBand: (event: React.MouseEvent<HTMLElement>, bandType: PenaltyBandTypes) => void
  onDeleteBand: (event: React.MouseEvent<HTMLElement>, index: number, bandType: PenaltyBandTypes) => void
  sameBands: boolean
}

const PenaltyBandTypeSection: React.FC<PenaltyBandTypeSectionProps> = ({
  form,
  injectionBands,
  fieldArrayKey,
  penaltyBandType,
  onAddBand,
  onDeleteBand,
  sameBands,
}) => {
  const { UNDER_INJECTION_BANDS } = PenaltyBandTypes
  const bandIndexes = (injectionBands || [])?.map((band) => band.index)
  const lastBandIndex = Math.max(...bandIndexes)
  const lastBandToValue = useField(`${fieldArrayKey}[${lastBandIndex}].to`).input.value

  // Update next band 'from' value when the current band 'to' value is changed
  const handleToValueChange = useCallback(
    ({ currentBandToValue, index, isLastBand }: HandleToValueChangeProps) => {
      // If the current band is last, we don't have to change the to value for the next band
      if (!isLastBand) {
        // Update the next band 'To' value
        form.mutators.setValue(`${fieldArrayKey}[${index + 1}].from`, currentBandToValue)
      }
    },
    [fieldArrayKey],
  )

  const updateToValueAndAddBand = (event: React.MouseEvent<HTMLElement>, bandType: PenaltyBandTypes) => {
    if (isMaxNumber(lastBandToValue)) {
      form.mutators.setValue(`${fieldArrayKey}[${lastBandIndex}].to`, '')
    }

    onAddBand(event, bandType)
  }

  return (
    <Box>
      <Typography variant="subtitle1">
        <Box display="flex" justifyContent="space-between">
          {sameBands ? (
            <>{c('PenaltyRegulation').t`Over/Under Injection`}</>
          ) : penaltyBandType === UNDER_INJECTION_BANDS ? (
            <>{c('PenaltyRegulation').t`Under Injection`}</>
          ) : (
            <>{c('PenaltyRegulation').t`Over Injection`}</>
          )}

          <Button onClick={(event) => updateToValueAndAddBand(event, penaltyBandType)} color="primary" size="small">
            {c('PenaltyRegulation').t`Add new band`}
          </Button>
        </Box>
      </Typography>

      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <FieldArray name={fieldArrayKey}>
          {({ fields }) => {
            return fields.map((name, index) => {
              const isLastBand = lastBandIndex === index
              const isFirstBand = index === 0
              return (
                <PenaltyBandWidget
                  key={name}
                  fieldArrayIndexKey={name}
                  index={index}
                  penaltyBandType={penaltyBandType}
                  onDeleteBand={onDeleteBand}
                  showDeleteAction={injectionBands.length > 1 && isLastBand}
                  isFirstBand={isFirstBand}
                  isLastBand={isLastBand}
                  onToValueChange={handleToValueChange}
                  fieldArrayKey={fieldArrayKey}
                />
              )
            })
          }}
        </FieldArray>
      </Box>
    </Box>
  )
}

export default PenaltyBandTypeSection
