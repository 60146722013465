import { Asset } from 'modules/asset/store/asset.types'
import { useSelector } from 'react-redux'
import { getQualityEvaluationsResultSelector } from 'modules/quality/redux_store/state/getQualityEvaluation'
import { useMemo } from 'react'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import {
  EnrichedForecastConfigItemForForecastModel,
  EnrichedForecastModelItem,
  EnrichedQualityEvaluationItem,
  EvaluationImprovement,
  getForecastModelName,
} from 'utils/forecastModel'
import { c, t } from 'ttag'
import {
  FORECAST_MODEL_TYPE_ANN,
  FORECAST_MODEL_TYPE_POWERCURVE,
  QualityEvaluation,
} from 'modules/quality/quality.types'
import { getQualityConfigName } from 'utils/siteForecastConfiguration'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { theme } from 'themes/theme-light'
import { Status, StatusType } from 'modules/reTable/reTable.types'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'
import { isAfter } from 'date-fns'

export const measurementsInPercentage = ['nRmse', 'nMae', 'absoluteDeviation', 'nbias', 'correlationCoefficient']

type BestEvaluationPerTrainingAndQC = Record<
  string,
  { oldNetwork?: number; newNetwork?: number; oldEvaluationId?: string; newEvaluationId?: string }
>

interface EnrichedQualityEvaluationsProps {
  asset: Asset
}
export const useEnrichedQualityEvaluations = ({ asset }: EnrichedQualityEvaluationsProps) => {
  const qualityEvaluations = useSelector(getQualityEvaluationsResultSelector)
  const qualityEvaluationsForAsset = useMemo(() => {
    return (qualityEvaluations || []).filter((evaluation) => evaluation.assetId === asset.id)
  }, [qualityEvaluations, asset])

  const forecastConfigs = useSiteForecastConfigs()
  const forecastModelsQueryResult = useForecastModelsByAsset(asset)
  // const forecastModels = useMemo(() => forecastModelsQueryResult?.data, [forecastModelsQueryResult.data])

  const forecastModels = useMemo(() => {
    return (forecastModelsQueryResult?.data || []).sort((a, b) => {
      return isAfter(new Date(a.creationDate), new Date(b.creationDate)) ? -1 : 1
    })
  }, [forecastModelsQueryResult?.data])

  const enrichedForecastModels: EnrichedForecastModelItem[] = useMemo(() => {
    return forecastModels?.map((fm) => {
      return { ...fm, id: fm.uuid, uiLevel: 0, uiAncestors: [], uiChildren: [], uiDescendants: [], uiParents: [] }
    })
  }, [forecastModels])

  const bestEvaluationPerTrainingAndQC: BestEvaluationPerTrainingAndQC = {}

  const enrichedQualityEvaluations = useMemo<EnrichedQualityEvaluationItem[]>(() => {
    // console.log({ qualityEvaluationsForAsset, enrichedForecastModels, forecastConfigs: forecastConfigs?.data })
    const enrichedEvaluationsForAsset = qualityEvaluationsForAsset.map((evaluation) => {
      const forecastConfig = (forecastConfigs.data || []).find((f) => f.id === evaluation.productConfigId)
      const forecastConfigName = forecastConfig?.name || t`[deleted]`
      const forecastConfigId = forecastConfig?.id

      const qualityConfigIndex = (forecastConfig?.qualityConfigs || []).findIndex(
        (forecastQualityConfig) => forecastQualityConfig.uuid === evaluation.id,
      )
      const qualityConfigName = evaluation.qualityConfig?.name
        ? evaluation.qualityConfig.name
        : evaluation.qualityConfig?.autoName
        ? evaluation.qualityConfig.autoName
        : qualityConfigIndex >= 0
        ? getQualityConfigName(evaluation.qualityConfig, qualityConfigIndex)
        : ''

      const combinedName = `${forecastConfigName}-${qualityConfigName}`

      // const measurementKeys = getErrorMeasurements(evaluation)
      const measurementValues = formatErrorMeasurementValues(evaluation)
      const { errorTag: measurementErrorTag, errorMessage: measurementErrorMessage } = measurementValues
      const measurementHasError = measurementErrorTag !== 'OK'

      const nRmse = typeof measurementValues.nRmse === 'number' ? measurementValues.nRmse : undefined
      const nMae = typeof measurementValues.nMae === 'number' ? measurementValues.nMae : undefined
      const absoluteDeviation =
        typeof measurementValues.absoluteDeviation === 'number' ? measurementValues.absoluteDeviation : undefined
      const nbias = typeof measurementValues.nbias === 'number' ? measurementValues.nbias : undefined
      const correlationCoefficient =
        typeof measurementValues.correlationCoefficient === 'number'
          ? measurementValues.correlationCoefficient
          : undefined

      const isPrimaryNRMSE = evaluation.qualityConfig.primaryMeasurement === 'NRMSE'
      const isPrimaryNMAE = evaluation.qualityConfig.primaryMeasurement === 'NMAE'
      const isPrimaryABSDEV = evaluation.qualityConfig.primaryMeasurement === 'ABSOLUTE_DEVIATION'
      const isPrimaryNBIAS = evaluation.qualityConfig.primaryMeasurement === 'NBIAS'
      const isPrimaryCORCO = evaluation.qualityConfig.primaryMeasurement === 'CORRELATION_COEFFICIENT'

      const primaryEvaluationResult = isPrimaryNRMSE
        ? nRmse
        : isPrimaryNMAE
        ? nMae
        : isPrimaryABSDEV
        ? absoluteDeviation
        : isPrimaryNBIAS
        ? nbias
        : isPrimaryCORCO
        ? correlationCoefficient
        : undefined

      const forecastModel = enrichedForecastModels.find((model) => model.uuid === evaluation.forecastModelId)
      const forecastModelType = forecastModel?.parameter?.forcePhysical
        ? FORECAST_MODEL_TYPE_POWERCURVE
        : FORECAST_MODEL_TYPE_ANN
      const forecastModelName = forecastModel ? getForecastModelName(forecastModel) : ''
      // console.log({ forecastModel })

      let weatherModelConfiguration =
        forecastModel?.parameter?.weatherModelConfiguration || forecastModel?.parameter?.weatherPoints
      if (weatherModelConfiguration && typeof weatherModelConfiguration === 'string') {
        weatherModelConfiguration = JSON.parse(weatherModelConfiguration)
      }

      const weatherModels = (weatherModelConfiguration || [])
        .filter((config) => config.weight)
        .map((config) => ({
          name: config.model || config.weatherModel,
          weight: config.weight,
        }))

      if (forecastModel) {
        // we prepare filtering losing evaluations to only include the best candidate
        const trainingQCId = `${forecastModel?.trainingId}_${evaluation.productConfigId}_${evaluation.qualityConfig.uuid}`
        if (!bestEvaluationPerTrainingAndQC[trainingQCId]) {
          bestEvaluationPerTrainingAndQC[trainingQCId] = {}
        }

        const isCurrent = evaluation.label === 'CURRENT'
        if (isCurrent) {
          bestEvaluationPerTrainingAndQC[trainingQCId].oldNetwork = primaryEvaluationResult
          bestEvaluationPerTrainingAndQC[trainingQCId].oldEvaluationId = evaluation.id
        } else {
          if (!bestEvaluationPerTrainingAndQC[trainingQCId].newNetwork) {
            bestEvaluationPerTrainingAndQC[trainingQCId].newNetwork = primaryEvaluationResult
            bestEvaluationPerTrainingAndQC[trainingQCId].newEvaluationId = evaluation.id
          } else {
            if (
              ((isPrimaryNRMSE || isPrimaryNMAE || isPrimaryABSDEV) && // best are small values (the smaller the better)
                primaryEvaluationResult < bestEvaluationPerTrainingAndQC[trainingQCId].newNetwork) ||
              (isPrimaryNBIAS && // best bias is near to 0
                Math.abs(primaryEvaluationResult) <
                  Math.abs(bestEvaluationPerTrainingAndQC[trainingQCId].newNetwork)) ||
              (isPrimaryCORCO && // Best correlation coefficient is near to 100 (the bigger the better)
                primaryEvaluationResult > bestEvaluationPerTrainingAndQC[trainingQCId].newNetwork)
            ) {
              bestEvaluationPerTrainingAndQC[trainingQCId].newNetwork = primaryEvaluationResult
              bestEvaluationPerTrainingAndQC[trainingQCId].newEvaluationId = evaluation.id
            }
          }
        }
      }

      const enriched = {
        ...evaluation,
        comparisonType: evaluation.label,
        forecastConfigName,
        forecastConfigId,
        forecastModelType,
        forecastModelName,
        forecastModel,
        measurementErrorTag,
        measurementErrorMessage,
        measurementHasError,
        nRmse,
        nMae,
        absoluteDeviation,
        nbias,
        correlationCoefficient,
        primaryEvaluationResult,
        isPrimaryNRMSE,
        isPrimaryNMAE,
        isPrimaryABSDEV,
        isPrimaryNBIAS,
        isPrimaryCORCO,
        name: combinedName,
        qualityConfigName,
        weatherModels,
        uiParents: [],
        uiAncestors: [],
        uiLevel: 0,
      }

      const evaluationStatus = parseEvaluationStatus(enriched)
      return {
        ...enriched,
        evaluationStatus,
      }
    })

    return (
      enrichedEvaluationsForAsset
        // .filter((evaluation) => {
        //   // only keep one evaluation with best result per training
        //   const trainingQCId = `${evaluation.forecastModel?.trainingId}_${evaluation.productConfigId}_${evaluation.qualityConfig.uuid}`
        //   const bestEvaluation = bestEvaluationPerTrainingAndQC[trainingQCId]
        //   return bestEvaluation && bestEvaluation?.newEvaluationId === evaluation.id
        // })
        .map((evaluation) => {
          const trainingQCId = `${evaluation.forecastModel?.trainingId}_${evaluation.productConfigId}_${evaluation.qualityConfig.uuid}`

          // add changed percentages from comparing old and new forecast model
          const bestEvaluation = bestEvaluationPerTrainingAndQC[trainingQCId]
          const oldEvaluation = enrichedEvaluationsForAsset.find((e) => e.id === bestEvaluation?.oldEvaluationId)

          const nRmseChangePercentage =
            evaluation.nRmse && oldEvaluation?.nRmse ? -(1 - evaluation.nRmse / oldEvaluation.nRmse) * 100 : null
          const nMaeChangePercentage =
            evaluation.nMae && oldEvaluation?.nMae ? -(1 - evaluation.nMae / oldEvaluation.nMae) * 100 : null
          const absoluteDeviationChangePercentage =
            evaluation.absoluteDeviation && oldEvaluation?.absoluteDeviation
              ? -(1 - evaluation.absoluteDeviation / oldEvaluation.absoluteDeviation) * 100
              : null
          const nbiasChangePercentage =
            evaluation.nbias && oldEvaluation?.nbias ? -(1 - evaluation.nbias / oldEvaluation.nbias) * 100 : null
          const correlationCoefficientChangePercentage =
            evaluation.correlationCoefficient && oldEvaluation?.correlationCoefficient
              ? -(1 - evaluation.correlationCoefficient / oldEvaluation.correlationCoefficient) * 100
              : null

          const nRmseHasImproved = nRmseChangePercentage
            ? nRmseChangePercentage < 0
              ? EvaluationImprovement.POSITIVE
              : nRmseChangePercentage > 0
              ? EvaluationImprovement.NEGATIVE
              : EvaluationImprovement.NEUTRAL
            : null
          const nMaeHasImproved = nMaeChangePercentage
            ? nMaeChangePercentage < 0
              ? EvaluationImprovement.POSITIVE
              : nMaeChangePercentage > 0
              ? EvaluationImprovement.NEGATIVE
              : EvaluationImprovement.NEUTRAL
            : null
          const absoluteDeviationHasImproved = absoluteDeviationChangePercentage
            ? absoluteDeviationChangePercentage < 0
              ? EvaluationImprovement.POSITIVE
              : absoluteDeviationChangePercentage > 0
              ? EvaluationImprovement.NEGATIVE
              : EvaluationImprovement.NEUTRAL
            : null
          const nbiasHasImproved =
            evaluation?.nbias && oldEvaluation?.nbias
              ? Math.abs(evaluation.nbias) < Math.abs(oldEvaluation.nbias)
                ? EvaluationImprovement.POSITIVE
                : Math.abs(evaluation.nbias) > Math.abs(oldEvaluation.nbias)
                ? EvaluationImprovement.NEGATIVE
                : EvaluationImprovement.NEUTRAL
              : null
          const correlationCoefficientHasImproved = correlationCoefficientChangePercentage
            ? correlationCoefficientChangePercentage > 0
              ? EvaluationImprovement.POSITIVE
              : correlationCoefficientChangePercentage < 0
              ? EvaluationImprovement.NEGATIVE
              : EvaluationImprovement.NEUTRAL
            : null

          return {
            ...evaluation,
            nRmseChangePercentage,
            nMaeChangePercentage,
            absoluteDeviationChangePercentage,
            nbiasChangePercentage,
            correlationCoefficientChangePercentage,
            nRmseHasImproved,
            nMaeHasImproved,
            absoluteDeviationHasImproved,
            nbiasHasImproved,
            correlationCoefficientHasImproved,
            isQualityEvaluation: true,
          }
        })
    )
  }, [qualityEvaluationsForAsset, forecastConfigs.data, enrichedForecastModels])

  // Append the evaluations to the forecast models
  const tableItems:
    | EnrichedQualityEvaluationItem[]
    | EnrichedForecastModelItem[]
    | EnrichedForecastConfigItemForForecastModel[] = []

  if (enrichedForecastModels?.length) {
    enrichedForecastModels?.forEach((efm) => {
      let fmTableItems:
        | EnrichedQualityEvaluationItem[]
        | EnrichedForecastModelItem[]
        | EnrichedForecastConfigItemForForecastModel[] = []

      const evaluations = (enrichedQualityEvaluations || []).filter(
        (evaluation) => evaluation.forecastModelId === efm.uuid && evaluation.assetId === asset.id,
      )
      const evaluationIds = (evaluations || []).map((e) => e.id)

      const modelTableItem = { ...efm, uiChildren: evaluationIds }

      // Push forecast Model
      fmTableItems.push(modelTableItem)

      evaluations?.forEach((currentEvaluation) => {
        const evaluationTableItem = {
          ...currentEvaluation,
          uiLevel: 1,
          uiParents: [modelTableItem.id],
          uiAncestors: [modelTableItem.id],
        }

        fmTableItems.push(evaluationTableItem)

        // Check if there are items with same forecastConfigId
        const itemsWithSameForecastConfig = fmTableItems.filter(
          (t) => t.isQualityEvaluation && t.forecastConfigId === currentEvaluation.forecastConfigId,
        )
        if (itemsWithSameForecastConfig.length > 1) {
          const primaryEvaluations = itemsWithSameForecastConfig.filter((iwsf) => iwsf?.qualityConfig?.primary)
          const latestPrimaryEvaluation = primaryEvaluations.length
            ? primaryEvaluations?.reduce((a, b) => {
                return new Date(a.created) > new Date(b.created) ? a : b
              }, {})
            : null

          // If the array has primaryEvaluation then extract the recent one add it as parent to the remaining evaluations
          if (latestPrimaryEvaluation) {
            const remainingEvaluations = itemsWithSameForecastConfig.filter(
              (iwsf) => iwsf.id !== latestPrimaryEvaluation.id,
            )
            const remainingEvaluationsIds = (remainingEvaluations || []).map(
              (enp: EnrichedQualityEvaluationItem) => enp.id,
            )
            // Add primaryEvaluation as parent to remainingEvaluations
            remainingEvaluations.forEach((enp) => {
              enp['uiAncestors'] = [modelTableItem.id, latestPrimaryEvaluation?.id]
              enp['uiParents'] = [latestPrimaryEvaluation?.id]
              enp['uiLevel'] = 2
            })

            const itemsWithSameForecastConfigIds = itemsWithSameForecastConfig?.map((iwsfc) => iwsfc.id)

            // Remove itemsWithSameForecastConfig from main array and add them in the next statement below parent
            fmTableItems = fmTableItems?.filter((fti) => !itemsWithSameForecastConfigIds.includes(fti.id))

            // Add the itemsWithSameForecastConfig and remainingEvaluations after the parent in the array
            latestPrimaryEvaluation['uiChildren'] = remainingEvaluationsIds
            // { ...latestPrimaryEvaluation, uiChildren: remainingEvaluationsIds }
            // console.log({ fmTableItems, latestPrimaryEvaluation, remainingEvaluations })
            fmTableItems = [...fmTableItems, latestPrimaryEvaluation, ...remainingEvaluations]
          }
        }
      })

      tableItems.push(...fmTableItems)
    })
  }
  return tableItems
}

export const formatErrorMeasurementValues = (evaluation: QualityEvaluation) => {
  const measurementValues = Object.assign({}, evaluation.errorMeasurements)
  measurementsInPercentage.forEach((key) => {
    measurementValues[key] = measurementValues[key] * 100
  })
  return measurementValues
}

const parseEvaluationStatus = (evaluationItem: EnrichedQualityEvaluationItem): Status => {
  let label: string
  let description: string
  let icon: IconProp
  let color: string
  let type: StatusType
  const messages: string[] = []

  if (evaluationItem.measurementHasError) {
    label = evaluationItem.measurementErrorTag
    description = evaluationItem.measurementErrorMessage
    icon = 'exclamation-circle'
    type = StatusType.error
  } else {
    switch (evaluationItem.status) {
      case 'FINISHED':
        label = c('Training Results:Quality').t`Successfully evaluated`
        description = c('Training Results:Quality').t`Quality evaluation was successfully calculated`
        icon = 'check'
        type = StatusType.success
        break
      case 'IN_PROGRESS':
        label = c('Training Results:Quality').t`In Progress`
        description = c('Training Results:Quality').t`Quality evaluation is currently in progress`
        icon = 'laptop-code'
        type = StatusType.progress
        break
      case 'QUEUED':
        label = c('Training Results:Quality').t`Queued`
        description = c('Training Results:Quality').t`Quality evaluation is currently waiting in queue`
        icon = 'pause'
        type = StatusType.progress
        break
    }
  }

  switch (type) {
    case StatusType.success:
      color = theme.palette.primary.main
      break
    case StatusType.error:
      color = theme.palette.error.main
      break
    default:
      color = theme.palette.secondary.main
      break
  }

  return {
    label,
    description,
    icon,
    color,
    type,
    messages,
  }
}
