import React, { useState } from 'react'

import ConfigurationSolarPark from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationSolarPark'
import ConfigurationSolarPlant from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationSolarPlant'
import ConfigurationWindPark from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationWindPark'
import ConfigurationWindPlant from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationWindPlant'
import { Asset, chpGenerator, SolarGenerator, SolarPark, WindGenerator } from 'modules/asset/store/asset.types'
import { isCHP, isSolarPark, isSolarPlant, isWindPark, isWindPlant } from 'utils/asset'
import { FormApi } from 'final-form'
import { FormSpy } from 'react-final-form'
import ConfigurationCHP from 'modules/asset/assetCrud/assetDetails/configuration/ConfigurationCHP'

// styled components

// exported component

interface SectionConfigurationProps {
  asset: Asset
  form: FormApi<Asset>
  enterManually: boolean
  onFormChangeExternally: () => void
  isNew: boolean
}

const SectionConfiguration: React.FC<SectionConfigurationProps> = ({
  asset,
  form,
  enterManually,
  onFormChangeExternally,
  isNew,
}) => {
  const [formState, setFormState] = useState(asset)

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as Asset)
        }}
      />
      {isSolarPark(formState) && (
        <ConfigurationSolarPark form={form} enterManually={enterManually} asset={asset as SolarPark} />
      )}
      {isWindPark(formState) && (
        <ConfigurationWindPark
          onFormChangeExternally={onFormChangeExternally}
          form={form}
          enterManually={enterManually}
        />
      )}
      {isSolarPlant(formState) && (
        <ConfigurationSolarPlant asset={asset as SolarGenerator} form={form} enterManually={enterManually} />
      )}
      {isWindPlant(formState) && (
        <ConfigurationWindPlant
          asset={asset as WindGenerator}
          form={form}
          onFormChangeExternally={onFormChangeExternally}
        />
      )}
      {isCHP(formState) && (
        <ConfigurationCHP
          isNew={isNew}
          asset={asset as chpGenerator}
          form={form}
          onFormChangeExternally={onFormChangeExternally}
        />
      )}
    </>
  )
}

export default React.memo(SectionConfiguration)
