import axios from 'axios'
import { FormApi } from 'final-form'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useSiteForecastSaveMainItemKey } from 'modules/dataStreams/api/dataStreamsUserSettings.api'
import { useSiteForecastConfigTemplates } from 'modules/dataStreams/api/siteForecastConfigTemplates.api'

import { CustomConfigurationFileResponse, ForecastConfig, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { RETABLE_ID_DATASTREAMS } from 'modules/reTable/reTable.types'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { SAVE_WORKSPACE_DRAFT_REQUEST, WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { DeepPartial } from 'ts-essentials'
import { dataStreamTypeQuery, useDataStreams } from 'utils/dataStream'
import {
  transformSiteForecastDataBeforeSubmit,
  transformSiteForecastDataForForm,
} from 'utils/siteForecastConfiguration'
import {
  getDataStreamDetailsQueryObj,
  getDataStreamStrings,
  getManageDataStreamQueryObj,
  QUERY_DATA_STREAM_ID,
  QUERY_NEW_SCHEDULE,
  QUERY_NEW_SITE_FORECAST,
  useQueryMatch,
  useQueryString,
} from 'utils/query-string'
import { apiRequest, Error, useOptimisticMutation } from 'utils/request'
import { filterSelectionByChartType } from 'utils/workspace'
import { FormSaveOptions } from 'utils/form'

// Query keys for caching data

export const QUERY_KEY_SITE_FORECAST_CONFIGS = 'dataStreams:siteForecastConfigs'

// Async API requests to fetch and update data

const getSiteForecastConfigs = async () => {
  return await apiRequest<ForecastConfig[]>(() => axios.get('/api/productconfig/v2'))
}

export const validateSiteForecastCustomConfig = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return await apiRequest<CustomConfigurationFileResponse>(() =>
    axios.post('/api/customized-export/v1/validate', formData),
  )
}

const saveSiteForecastConfig = async (data: ForecastConfig | File, isCustom: boolean) => {
  if (isCustom) {
    // upload custom configuration file and create new forecast configuration
    const configFile = data as File
    const formData = new FormData()
    formData.append('file', configFile)
    return await apiRequest<CustomConfigurationFileResponse>(() =>
      axios.post('/api/customized-export/v1/import', formData),
    ).then((data) => data.productConfigDTO)
  } else {
    const forecastConfig = transformSiteForecastDataBeforeSubmit(data) as ForecastConfig
    if (forecastConfig.customer) {
      // update forecast configuration
      return await apiRequest<ForecastConfig>(() =>
        axios.put(`/api/productconfig/v2/${forecastConfig.id}`, forecastConfig),
      )
    } else {
      // create new forecast configuration
      return await apiRequest<ForecastConfig>(() => axios.post('/api/productconfig/v2', forecastConfig))
    }
  }
}

const deleteSiteForecastConfigs = async (forecastConfigIds: string[]) => {
  return forecastConfigIds.map(async (forecastConfigId) => {
    return await apiRequest<ForecastConfig>(() => axios.delete(`/api/productconfig/v2/${forecastConfigId}`))
  })
}

// Hooks to fetch and update via react-query

export const useSiteForecastConfigs = () => {
  // fetch all
  return useQuery<ForecastConfig[]>(QUERY_KEY_SITE_FORECAST_CONFIGS, getSiteForecastConfigs)
}

export const useSiteForecastConfigSaveMutation = (
  isCustom: boolean,
  formReference: FormApi<ForecastConfig> | null,
  isCreatedFromDetailsForm = true,
) => {
  // create or update one

  // save and close/next functionality
  const saveMainItemKey = useSiteForecastSaveMainItemKey()
  const { SAVE_AND_NEW, SAVE, SAVE_AND_NEXT, CREATE_COPY, SAVE_AND_CLOSE } = FormSaveOptions

  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const isNewSiteForecast = useQueryMatch(QUERY_NEW_SITE_FORECAST)
  const forecastConfigs = useSiteForecastConfigs()
  const user = useSelector(getUserResultSelector)
  const forecastTemplates = useSiteForecastConfigTemplates()
  const forecastConfigIds = (forecastConfigs.data || []).map((config) => config.id)
  const filteredIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_DATASTREAMS).filter((id) =>
    forecastConfigIds.includes(id),
  )

  const queryClient = useQueryClient()
  return useOptimisticMutation<ForecastConfig, ForecastConfig | File, ForecastConfig[] | undefined>({
    queryCacheKey: QUERY_KEY_SITE_FORECAST_CONFIGS,
    apiMutator: (variables) => saveSiteForecastConfig(variables, isCustom),
    cacheUpdater: (newValue, oldData) => {
      if (newValue instanceof File) {
        // custom config is set
        return oldData
      } else if (newValue?.customer) {
        // forecast config is edited
        return (oldData || []).map((config) => (config.id === newValue.id ? newValue : config))
      } else {
        // new forecast config is created (customer is set by backend)
        // we don't want to optimistically update ui because forecast config would have no id
        return oldData
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(QUERY_KEY_ASSETS)
      if (forecastConfigs.isSuccess && forecastTemplates.isSuccess) {
        const forecastFormData = transformSiteForecastDataForForm(data, false, false, user)
        if (formReference) {
          formReference.reset(forecastFormData)
        }
        // If it is created from details form then perform the necessary action based on the save option
        // else close the slider
        if (isCreatedFromDetailsForm) {
          let mainItemKey = isNewSiteForecast
            ? saveMainItemKey?.data?.createForecast
            : saveMainItemKey?.data?.updateForecast

          if (!mainItemKey) {
            mainItemKey = SAVE_AND_CLOSE
          }
          if (mainItemKey === SAVE_AND_CLOSE) {
            onDeleteQueryStrings(getDataStreamStrings())
          }
          if (mainItemKey === SAVE || mainItemKey === CREATE_COPY) {
            const queryData = {
              ...getManageDataStreamQueryObj(dataStreamTypeQuery[TimeSeriesType.SITE_FORECAST]),
              ...getDataStreamDetailsQueryObj(data.id),
              [QUERY_NEW_SITE_FORECAST]: undefined,
              [QUERY_NEW_SCHEDULE]: undefined,
            }
            onUpdateQueryString(queryData)
          } else if (mainItemKey === SAVE_AND_NEXT) {
            const currentId = data.id
            const oldIndex = filteredIds.findIndex((id) => id === currentId)
            const newIndex = oldIndex < filteredIds.length - 1 ? oldIndex + 1 : 0

            const queryData = {
              ...getManageDataStreamQueryObj(dataStreamTypeQuery[TimeSeriesType.SITE_FORECAST]),
              ...getDataStreamDetailsQueryObj(filteredIds[newIndex]),
              [QUERY_NEW_SITE_FORECAST]: undefined,
              [QUERY_NEW_SCHEDULE]: undefined,
            }
            onUpdateQueryString(queryData)
          } else if (mainItemKey === SAVE_AND_NEW) {
            onDeleteQueryStrings([QUERY_NEW_SITE_FORECAST, QUERY_DATA_STREAM_ID])
          } else {
            // Default option to save and close the form
            onDeleteQueryStrings(getDataStreamStrings())
          }
        } else {
          // Close the slider if site forecast is created from template page
          onDeleteQueryStrings(getDataStreamStrings())
        }
      }
    },
  })
}

export const useSiteForecastConfigDeleteMutation = () => {
  const dataStreams = useDataStreams()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const selectedSiteForecasts = filterSelectionByChartType(dataStreams, dataSelection, TimeSeriesType.SITE_FORECAST)
  const selectedSchedule = filterSelectionByChartType(dataStreams, dataSelection, TimeSeriesType.SCHEDULE)

  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  return useMutation<Promise<ForecastConfig>[], Error, string[]>(deleteSiteForecastConfigs, {
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEY_SITE_FORECAST_CONFIGS)
    },
    // Failure or Success
    onSettled: async (data) => {
      const successfullyDeletedIds: string[] = []
      await Promise.all(data || []).then((results) =>
        results.forEach((result) => {
          if (result?.id) {
            successfullyDeletedIds.push(result.id)
          }
        }),
      )

      // Since schedule is considered a site forecast , we are merging both of them
      const mergedSiteForecast = [...selectedSiteForecasts, ...selectedSchedule]
      const newSelection = mergedSiteForecast.filter((selected) => !successfullyDeletedIds.includes(selected.name))
      const draft: DeepPartial<WorkspaceConfig> = {
        data: {
          selection: newSelection,
        },
      }
      dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
    },
  })
}

// This api call returns the forecast model category for a certain user
export const getForecastModelCategoryForUser = async () => {
  return await apiRequest<string[]>(() => axios.get(`/api/productconfig/v2/forecast-model/categories`))
}
