import React, { useCallback, useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { t } from 'ttag'

interface CopyToClipboardProps {
  textToCopy: string
  title?: string
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ textToCopy, title = '' }) => {
  const [successMsg, setSuccessMsg] = useState('')

  const handleCopyToClipboard = useCallback(() => {
    const ta = document.createElement('textarea')
    ta.innerText = textToCopy
    document.body.appendChild(ta)
    ta.select()
    document.execCommand('copy')
    ta.remove()
    setSuccessMsg(t`Copied`)
  }, [textToCopy])

  useEffect(() => {
    if (!successMsg) return
    const removeSuccessMsg = setTimeout(() => {
      setSuccessMsg('')
    }, 1000)

    return () => {
      clearTimeout(removeSuccessMsg)
    }
  }, [successMsg])

  return (
    <>
      <IconButton title={title} aria-label="Copy asset id" onClick={handleCopyToClipboard}>
        <FontAwesomeActionIcon size="xs" icon="copy" />
      </IconButton>
      {successMsg}
    </>
  )
}

export default CopyToClipboard
