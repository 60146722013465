import { apiRequest } from 'utils/request'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import axios from 'axios'
import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { useSiteForecastConfigTemplates } from 'modules/dataStreams/api/siteForecastConfigTemplates.api'

// Query keys for caching data

export const QUERY_KEY_DELIVERY_FORMAT_TEMPLATES = 'delivery:formatTemplates'

const transformDeliveryFormatsAfterGet = (
  formatTemplates: DeliveryFormat[],
  forecastConfigTemplates: ForecastConfig[],
) => {
  const transformedItems = (formatTemplates || []).map((format: DeliveryFormat) => {
    const configsUsingThisTarget = forecastConfigTemplates.filter((config) => config.exportFormatConfigId === format.id)
    return {
      ...format,
      usedIn: configsUsingThisTarget,
      usedInLength: configsUsingThisTarget.length,
    }
  })
  return transformedItems
}

const getDeliveryFormatTemplates = async () => {
  return await apiRequest<DeliveryFormat[]>(() => axios.get('/api/exportformat/v2/templates'))
}

// Hooks to fetch and update via react-query

export const useDeliveryFormatTemplates = () => {
  return useQuery<DeliveryFormat[]>(QUERY_KEY_DELIVERY_FORMAT_TEMPLATES, getDeliveryFormatTemplates)
}

export const useDeliveryFormatTemplatesTableItems = () => {
  const deliveryFormatTemplates = useDeliveryFormatTemplates()
  const forecastConfigTemplates = useSiteForecastConfigTemplates()
  const transformedDeliveryFormats: QueryObserverResult<DeliveryFormat[]> = {
    ...deliveryFormatTemplates,
    data: undefined,
  }
  if (deliveryFormatTemplates.data && forecastConfigTemplates.data) {
    transformedDeliveryFormats.data = transformDeliveryFormatsAfterGet(
      deliveryFormatTemplates.data,
      forecastConfigTemplates.data,
    )
  }
  return transformedDeliveryFormats
}

const deleteDeliveryFormatTemplate = async (data: DeliveryFormat) => {
  return await apiRequest<DeliveryFormat>(() => {
    return axios.delete(`/api/exportformat/v2/templates/${data.id}`)
  })
}

export const useDeliveryFormatTemplatesDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteDeliveryFormatTemplate, {
    onSettled: async () => {
      queryClient.invalidateQueries(QUERY_KEY_DELIVERY_FORMAT_TEMPLATES)
    },
  })
}
