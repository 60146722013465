import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import {
  DataStreamOrder,
  DataStreamSelectionItem,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { DEFAULT_FORECAST_CONFIG_ID, useDataStreams } from 'utils/dataStream'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'

//TODO: This function can be updated with this function sortArrayBasedOnAnotherArray
// Sorted dataStreams by categorically and alphabetically
// Check if every selected item is present in the dataStream list
export const useSortedSelectedDataStreams = () => {
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const siteForecastConfigs = useSiteForecastConfigs().data || []
  const allDataStreams = useDataStreams()

  const maximumHorizonDataStream = siteForecastConfigs?.find((dataStream) => dataStream.maximumHorizon)

  const allDataStreamIds = allDataStreams.map((dataStream) => dataStream.id)
  // Remove the selected items if they are not present in the list from the selection
  const filteredDataStreams = selectedDataStreams.filter((selectedDatastream) =>
    allDataStreamIds.includes(selectedDatastream?.id),
  )

  const sortedDataStreams: DataStreamSelectionItem[] = []

  DataStreamOrder.forEach((type) => {
    const dataStreamsOfType = filteredDataStreams.filter((ds) => ds.type === type)
    if (type === TimeSeriesType.E3_META_FORECAST) {
      // Need to sort the E3 and Meta forecast data streams separately as they have same type
      // Display E3 Model data streams first
      const e3WeatherModelDataStreams = dataStreamsOfType.filter(
        (ds) => ds.subType === TimeSeriesSubType.E3_WEATHER_TRACK,
      )
      if (e3WeatherModelDataStreams.length) {
        e3WeatherModelDataStreams.forEach((ewmdst) => sortedDataStreams.push(ewmdst))
      }
      //  Display Third party next to  E3 Model data streams
      const thirdPartyDataStreams = dataStreamsOfType.filter(
        (ds) => ds.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST,
      )
      if (thirdPartyDataStreams.length) {
        thirdPartyDataStreams.forEach((tpdst) => sortedDataStreams.push(tpdst))
      }
    } else {
      dataStreamsOfType.forEach((dst) => sortedDataStreams.push(dst))
    }

    if (type === TimeSeriesType.SITE_FORECAST) {
      // Add the Maximum horizon item at the beginning
      const maximumHorizonDataStreamIndex = sortedDataStreams.findIndex((ds) => ds.id === maximumHorizonDataStream?.id)
      if (maximumHorizonDataStreamIndex > 0) {
        const maximumHorizonDataStream = sortedDataStreams.splice(maximumHorizonDataStreamIndex, 1)
        sortedDataStreams.unshift(maximumHorizonDataStream[0])
      }
      // Add the Default forecast item at the beginning
      const defaultDataStreamIndex = sortedDataStreams.findIndex((ds) => ds.id === DEFAULT_FORECAST_CONFIG_ID)
      if (defaultDataStreamIndex > 0) {
        const defaultDataStream = sortedDataStreams.splice(defaultDataStreamIndex, 1)
        sortedDataStreams.unshift(defaultDataStream[0])
      }
      // Add the Meta forecast item at the end
      const metaForecastDataStream = selectedDataStreams.find((ds) => ds.type === TimeSeriesType.META_FORECAST)
      if (metaForecastDataStream) {
        sortedDataStreams.push(metaForecastDataStream)
      }
    }
  })

  return sortedDataStreams
}
