import React from 'react'
import EmptyBox from 'ui/EmptyBox'
import { Box, Typography } from '@material-ui/core'
import { jt, t } from 'ttag'
import { getAssetQueryObj } from 'utils/query-string'
import { useAllAssets } from 'modules/asset/api/assets.api'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'

const SelectOrCreateAssetInfo: React.FC = () => {
  const allAssets = useAllAssets()
  const { onAddQueryString } = useUpdateQueryString()

  const handleAddNewAsset = () => {
    onAddQueryString(getAssetQueryObj({}))
  }

  const createANewAssetLink = <a href="#" onClick={handleAddNewAsset}>{t`create a new asset`}</a>
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%" mt={15}>
      <EmptyBox icon="chart-line">
        <Typography>
          {(allAssets.data || []).length > 0 ? (
            <>{t`Please select both an asset and data stream to visualize data`}</>
          ) : (
            <>{jt`No assets have yet been defined. Please ${createANewAssetLink} first.`}</>
          )}
        </Typography>
      </EmptyBox>
    </Box>
  )
}

export default SelectOrCreateAssetInfo
