import React, { useCallback } from 'react'
import Flex from 'ui/styles/Flex'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { c } from 'ttag'
import { Field, FormSpy, useField } from 'react-final-form'
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel'
import Radio from '@material-ui/core/Radio/Radio'
import {
  EvaluationDurationType,
  ForecastConfig,
  OffsetType,
  QualityHorizon,
} from 'modules/dataStreams/dataStreams.types'
import { FormApi } from 'final-form'
import { Typography } from '@material-ui/core'
import ForecastAndEvaluationStart from 'modules/dataStreams/siteForecast/ForecastAndEvaluationStart'
import DurationWithArrows from 'ui/datetime/DurationWithArrows'

interface SectionEvaluationProps {
  form: FormApi<ForecastConfig>
  fieldArrayKey: string
  index: number
}

const SectionEvaluation: React.FC<SectionEvaluationProps> = ({ form, fieldArrayKey, index }) => {
  const { AUTOMATIC, CUSTOM } = EvaluationDurationType
  const { START_OF_DAY, START_OF_DELIVERY } = OffsetType

  const durationType = useField<EvaluationDurationType>(`${fieldArrayKey}.durationType`).input.value
  const offsetType = useField<OffsetType>(`${fieldArrayKey}.offsetType`).input.value

  const horizonTimes = useField<QualityHorizon>(`${fieldArrayKey}.horizon`).input.value

  const updateHorizonTimes = useCallback(() => {
    const { lengthDays, lengthHours, lengthMinutes } = horizonTimes
    if (!lengthDays && !lengthHours && !lengthMinutes) {
      form.mutators.setValue(`${fieldArrayKey}.horizon.lengthDays`, 1)
    }
  }, [horizonTimes])

  return (
    <Flex direction="column">
      <Typography variant="subtitle2">{c('Site Forecast:Config').t`Evaluation window`}</Typography>
      <FormKeyValueGrid>
        <FormKey>{c('Site Forecast:Quality Evaluation').t`Start:`}</FormKey>
        <FormValue>
          <ForecastAndEvaluationStart
            daysInputName={`${fieldArrayKey}.horizon.offsetDays`}
            hoursInputName={`${fieldArrayKey}.horizon.offsetHours`}
            minutesInputName={`${fieldArrayKey}.horizon.offsetMinutes`}
            maxMinutes={45}
            radioButtonName={`${fieldArrayKey}.offsetType`}
            radioButtonValue={offsetType}
            firstRadioButtonOptionValue={START_OF_DELIVERY}
            secondRadioButtonOptionValue={START_OF_DAY}
          />
        </FormValue>
      </FormKeyValueGrid>
      {
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            return (
              <>
                <FormKeyValueGrid>
                  <FormKey>{c('Site Forecast:Quality Evaluation').t`Length`}:</FormKey>
                  <FormValue>
                    <Flex>
                      <Field name={`${fieldArrayKey}.durationType`} type="radio">
                        {({ input }) => {
                          return (
                            <RadioGroup
                              name={`${fieldArrayKey}.durationType`}
                              value={durationType}
                              onChange={input.onChange}
                            >
                              <Flex direction="column">
                                <FormControlLabel
                                  value={AUTOMATIC}
                                  control={<Radio size="small" />}
                                  label={c('Site Forecast:Quality Evaluation').t`Until start of next evaluation window`}
                                  title={c('Site Forecast:Quality Evaluation').t`Until start of next evaluation window`}
                                />
                                <Flex>
                                  <FormControlLabel
                                    onClick={updateHorizonTimes}
                                    value={CUSTOM}
                                    control={<Radio size="small" />}
                                    label={c('Site Forecast:Quality Evaluation').t`Limited to`}
                                    title={c('Site Forecast:Quality Evaluation').t`Limited to`}
                                  />
                                  <DurationWithArrows
                                    daysInputName={`${fieldArrayKey}.horizon.lengthDays`}
                                    repeatDays={false}
                                    hoursInputName={`${fieldArrayKey}.horizon.lengthHours`}
                                    minutesInputName={`${fieldArrayKey}.horizon.lengthMinutes`}
                                    disableInputs={values.qualityConfigs[index].durationType !== CUSTOM}
                                  />
                                </Flex>
                              </Flex>
                            </RadioGroup>
                          )
                        }}
                      </Field>
                    </Flex>
                  </FormValue>
                </FormKeyValueGrid>
              </>
            )
          }}
        </FormSpy>
      }
    </Flex>
  )
}

export default React.memo(SectionEvaluation)
