import cloneDeep from 'clone-deep'
import { addMilliseconds, differenceInMilliseconds, isAfter, isBefore, subMilliseconds } from 'date-fns'
import deepmerge from 'deepmerge'

import { User } from 'modules/auth/Auth.types'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import {
  DATA_SELECTION_MODE_SINGLE,
  DataStream,
  DataStreamSelection,
  DataStreamSelectionItem,
  ForecastConfig,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { WeatherConfig } from 'modules/weather/store/weather.types'
import {
  workspaceDraftChartDataRangeSelector,
  workspaceDraftChartRangeSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import {
  ASSET_SELECTION_MODE_SINGLE,
  CHART_TYPE_LINE,
  ChartAggregationMode,
  ChartConfig,
  ChartDataRangeType,
  ChartRangeSelectionOffset,
  ChartWidget,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { c, t } from 'ttag'
import { isObject, overwriteMerge } from 'utils/array'
import { isNegative, isNumeric } from 'utils/dataFormatting'
import { getDataStreamId, getDataStreamName } from 'utils/dataStream'
import { convertZonedTimeToAnotherZonedTime, DateRange, getDateRangeFromChartDataRange } from 'utils/date'
import { hasPermissionForLongRangeForecast, hasPermissionForMetaForecast, isAuthorizedToPenalties } from 'utils/user'
import { AppEntityNamesForTranslation, tGetEntityName } from 'fixtures/commonTranslations'

export const createDefaultDateRange: () => ChartRangeSelectionOffset = () => {
  return [0, 0]
}

export const getChartWidgetNames = () => {
  return {
    [ChartWidget.WIDGET_LINE_CHART]: c('Workbench:Widget').t`Line Chart`,
    [ChartWidget.WIDGET_FORECAST_ERROR_CHART]: c('Workbench:Widget').t`Forecast Error`,
    [ChartWidget.WIDGET_DAILY_MEAN_CHART]: c('Workbench:Widget').t`Mean Diurnal Profile`,
    [ChartWidget.WIDGET_QUALITY_TABLE]: c('Workbench:Widget').t`Quality`,
    [ChartWidget.WIDGET_PENALTY_TABLE]: c('Workbench:Widget').t`Penalty`,
    [ChartWidget.WIDGET_SEASONAL_TABLE]: c('Workbench:Widget').t`Monthly View`,
    [ChartWidget.WIDGET_META_FORECAST]: c('Workbench:Widget').t`Mixer`,
    [ChartWidget.WIDGET_EVALUATION_TABLE]: c('Workbench:Widget').t`Table view`,
    [ChartWidget.WIDGET_SCHEDULE]: tGetEntityName(AppEntityNamesForTranslation.PREPARE_SCHEDULE),
  }
}

export const getChartWidgetMenuItems = (user: User) => {
  const chartWidgetNames = getChartWidgetNames()

  return [
    {
      key: ChartWidget.WIDGET_LINE_CHART,
      label: chartWidgetNames[ChartWidget.WIDGET_LINE_CHART],
    },
    {
      key: ChartWidget.WIDGET_EVALUATION_TABLE,
      label: chartWidgetNames[ChartWidget.WIDGET_EVALUATION_TABLE],
    },
    {
      key: ChartWidget.WIDGET_FORECAST_ERROR_CHART,
      label: chartWidgetNames[ChartWidget.WIDGET_FORECAST_ERROR_CHART],
    },
    {
      key: ChartWidget.WIDGET_DAILY_MEAN_CHART,
      label: chartWidgetNames[ChartWidget.WIDGET_DAILY_MEAN_CHART],
    },
    ...(hasPermissionForLongRangeForecast(user)
      ? [
          {
            key: ChartWidget.WIDGET_SEASONAL_TABLE,
            label: chartWidgetNames[ChartWidget.WIDGET_SEASONAL_TABLE],
          },
        ]
      : []),
    ...(hasPermissionForMetaForecast(user)
      ? [
          {
            key: ChartWidget.WIDGET_META_FORECAST,
            label: chartWidgetNames[ChartWidget.WIDGET_META_FORECAST],
          },
        ]
      : []),
    {
      key: ChartWidget.WIDGET_QUALITY_TABLE,
      label: chartWidgetNames[ChartWidget.WIDGET_QUALITY_TABLE],
    },

    ...(isAuthorizedToPenalties(user)
      ? [
          {
            key: ChartWidget.WIDGET_PENALTY_TABLE,
            label: chartWidgetNames[ChartWidget.WIDGET_PENALTY_TABLE],
          },
        ]
      : []),
  ]
}

export const getDefaultChartName = () => t`My Chart`

export const createDefaultChartConfig: () => ChartConfig = () => {
  const range = createDefaultDateRange()
  return {
    name: getDefaultChartName(),
    aggregationMode: ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET,
    range,
    dataRange: {
      rangeType: ChartDataRangeType.CHART_DATA_RANGE_PLUS_MINUS_7_DAYS,
      customRange: null,
    },
    widgets: [ChartWidget.WIDGET_LINE_CHART, ChartWidget.WIDGET_FORECAST_ERROR_CHART, ChartWidget.WIDGET_QUALITY_TABLE],
    type: CHART_TYPE_LINE,
  }
}

export const createDefaultWorkspaceConfig: () => WorkspaceConfig = () => {
  const defaultConfig: WorkspaceConfig = {
    asset: {
      selection: [],
      selectionMode: ASSET_SELECTION_MODE_SINGLE,
    },
    chart: createDefaultChartConfig(),
    data: {
      selection: [],
      selectionMode: DATA_SELECTION_MODE_SINGLE,
    },
    default: true,
    e3WidgetSettings: {
      thirdPartySliders: {},
      weatherModelSliders: {},
      activeWidget: '',
    },
  }

  return defaultConfig
}

export const populateWorkspaceConfig = (workspaceConfig: WorkspaceConfig): WorkspaceConfig => {
  // if saved configs are missing required fields, fill missing holes with default config
  const mergedConfig: WorkspaceConfig = deepmerge(createDefaultWorkspaceConfig(), workspaceConfig, {
    arrayMerge: overwriteMerge,
  })

  // mergedConfig.chart.range = [new Date(mergedConfig.chart.range[0]), new Date(mergedConfig.chart.range[1])]

  // if (mergedConfig.asset.selectionMode === ASSET_SELECTION_MODE_MULTIPLE && !mergedConfig.chart.aggregationMode) {
  //   // multi selection needs a default aggregation mode
  //   mergedConfig.chart.aggregationMode = ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET
  // }

  // as soon as we populate a config, it was customized by user
  // and is no longer the default config that is shown initially
  delete mergedConfig.default
  return mergedConfig
}

/**
 * Initial and legacy format conversion when we had complicated and inflexible nested section structure
 */
const migrateWorkspaceConfigFromV1ToV2 = (workspaceConfig: WorkspaceConfig) => {
  if (isObject(workspaceConfig.data.selection)) {
    // backwards compatibility with simplified selection format
    //  BEFORE: selection was an object with different SECTIONS containing arrays with selection
    //  AFTER:  selection is flat array with selection
    workspaceConfig.data.selection = Object.keys(workspaceConfig.data.selection).reduce((previous, section) => {
      return Array.isArray(workspaceConfig.data.selection[section])
        ? [...previous, ...workspaceConfig.data.selection[section]]
        : previous
    }, [])
  }
}

/**
 * Legacy format conversion when we introduced react-query for all time series
 * and found a better more generic of defining data streams
 */
const migrateWorkspaceConfigFromV2ToV3 = (
  workspaceConfig: WorkspaceConfig,
  forecastConfigs: ForecastConfig[],
  weatherConfigs: WeatherConfig[],
) => {
  workspaceConfig.data.selection = workspaceConfig?.data?.selection?.map((item) => {
    // backwards compatibility with old structure where we had Asset Data category for meter data and capacities
    if (!item.id) {
      item.id = item.name
      delete item.name
    }

    if (item.id === 'ABSOLUTE_POWER') {
      item.type = TimeSeriesType.METER_DATA
    }

    if (item.type === 'ASSET_DATA') {
      // since we covered meter data above, this must by capacity data
      item.type = TimeSeriesType.CAPACITY_DATA
      item.subType =
        item.subType === TimeSeriesSubType.MAX_AVAILABLE_CAPACITY
          ? TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY // planned capacity is the data stream which is currently using the capacity service
          : (item.id as TimeSeriesSubType) // all others are using the time series service
    }

    if (!item.type) {
      // add missing type field
      const capacitySubTypes = [
        TimeSeriesSubType.INSTALLED_CAPACITY,
        TimeSeriesSubType.MAX_AVAILABLE_CAPACITY,
        TimeSeriesSubType.CAPPED_CAPACITY,
        TimeSeriesSubType.SCALED_CAPACITY,
      ]

      if (item.id === TimeSeriesType.METER_DATA) {
        item.type = TimeSeriesType.METER_DATA
      } else if (capacitySubTypes.includes(item.id as TimeSeriesSubType)) {
        // capacity
        item.type = TimeSeriesType.CAPACITY_DATA
        item.subType = item.id as TimeSeriesSubType
      } else if (weatherConfigs.some((weatherConfig) => weatherConfig.id == item.id)) {
        item.type = TimeSeriesType.WEATHER_DATA
      } else if (forecastConfigs.some((forecastConfig) => forecastConfig.id === item.id)) {
        item.type = TimeSeriesType.SITE_FORECAST
      }
    }

    if ([TimeSeriesType.METER_DATA, TimeSeriesType.CAPACITY_DATA].includes(item.type)) {
      delete item.id
    }

    item.id = getDataStreamId(item)
    item.name = getDataStreamName({ ...item, forecastConfigs, weatherConfigs })
    return item
  })
}

export const normalizeWorkspaceConfig = (
  workspaceConfig: WorkspaceConfig,
  user: User,
  forecastConfigs: ForecastConfig[],
  weatherConfigs: WeatherConfig[],
): void => {
  // Range is the selected chart area, we do not normalize the dates because now range is an array of numbers
  // which are nothing but the distance between start and end dates of chart whole range and selected range
  // if (workspaceConfig.chart.range) {
  //   // normalize dates
  //   workspaceConfig.chart.range = [new Date(workspaceConfig.chart.range[0]), new Date(workspaceConfig.chart.range[1])]
  // }

  if (!workspaceConfig.chart?.dataRange) {
    // add old default data range for legacy workspace configs
    workspaceConfig.chart.dataRange = {
      rangeType: ChartDataRangeType.CHART_DATA_RANGE_THIS_QUARTER,
      customRange: null,
    }
  }

  // V1 FORMAT upgrade
  migrateWorkspaceConfigFromV1ToV2(workspaceConfig)

  // V2 FORMAT upgrade
  migrateWorkspaceConfigFromV2ToV3(workspaceConfig, forecastConfigs, weatherConfigs)
}

export const prepareWorkspaceConfigForEqualityComparison = (
  workspaceConfig: Partial<WorkspaceConfig>,
  ignoreChartName = false,
): Partial<WorkspaceConfig> => {
  const clonedConfig = cloneDeep(workspaceConfig)

  if (ignoreChartName) {
    // we save a clone and compare everything except the chart name because chart name
    // is always immediately saved in user settings. if we compare the chart name, a "modified"
    // text would be shown for the duration of the save.
    delete clonedConfig.chart?.name
  }

  // we don't compare last updated timestamps since they are not subject of the config data
  delete clonedConfig.lastUpdated

  return clonedConfig
}

interface WorkspaceConfigsAreEqual {
  (a: Partial<WorkspaceConfig> | undefined, b: Partial<WorkspaceConfig> | undefined, ignoreChartName: boolean): boolean
}
export const workspaceConfigsAreEqual: WorkspaceConfigsAreEqual = (a = {}, b = {}, ignoreChartName = false) => {
  const aCloned = prepareWorkspaceConfigForEqualityComparison(a, ignoreChartName)
  const bCloned = prepareWorkspaceConfigForEqualityComparison(b, ignoreChartName)
  return JSON.stringify(aCloned) === JSON.stringify(bCloned)
}

const getDataStreamOfSelectionItem = (dataStreams: DataStream[], item: DataStreamSelectionItem) => {
  return dataStreams.find((d) => d.id === item.name)
}

export const filterSelectionByChartType = (
  dataStreams: DataStream[],
  selectedData: DataStreamSelection,
  type: TimeSeriesType,
): DataStreamSelection => {
  return selectedData.filter((item) => getDataStreamOfSelectionItem(dataStreams, item)?.type === type)
}

export const filterSelectionByAllChartTypes = (
  dataStreams: DataStream[],
  selectedData: DataStreamSelection,
): Record<string, DataStreamSelection> => {
  const selectedSiteForecasts = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.SITE_FORECAST)
  const selectedSchedule = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.SCHEDULE)
  const selectedE3Forecasts = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.E3_META_FORECAST)
  const selectedMeterdata = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.METER_DATA)
  const selectedCapacityData = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.CAPACITY_DATA)
  const selectedWeatherData = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.WEATHER_DATA)
  const selectedAreaForecasts = filterSelectionByChartType(dataStreams, selectedData, TimeSeriesType.AREA_FORECAST)
  return {
    selectedAreaForecasts,
    selectedCapacityData,
    selectedE3Forecasts,
    selectedMeterdata,
    selectedSiteForecasts,
    selectedSchedule,
    selectedWeatherData,
  }
}

export const useWorkspaceChartWholeDateRange = () => {
  const timezone = useSelector(getUserTimezoneSelector)
  const chartRangeType = useSelector(workspaceDraftChartDataRangeSelector)
  const dateRange = useMemo(() => {
    const range = getDateRangeFromChartDataRange(chartRangeType, timezone, true)
    // return [convertUTCToLocalDate(range[0]), convertUTCToLocalDate(range[1])]
    return range
  }, [chartRangeType, timezone])
  return dateRange
}

export const useWorkspaceChartSelectedDateRange = () => {
  const chartWholeDateRange = useWorkspaceChartWholeDateRange()
  const chartWholeStartDate = new Date(chartWholeDateRange[0])
  const chartWholeEndDate = new Date(chartWholeDateRange[1])

  // SelectedDateRange is the distance between start and end dates of chart whole range and selected range in milliseconds
  // We remove that distance with the whole date ranges if the chart whole dates change
  const chartSelectedDateRange = useSelector(workspaceDraftChartRangeSelector)

  let selectionStartInMilliSec = isNumeric(chartSelectedDateRange[0])
    ? parseInt(chartSelectedDateRange[0])
    : differenceInMilliseconds(
        isBefore(new Date(chartSelectedDateRange[0]), chartWholeStartDate)
          ? chartWholeStartDate
          : new Date(chartSelectedDateRange[0]),
        chartWholeStartDate,
      )

  let selectionEndInMilliSec = isNumeric(chartSelectedDateRange[1])
    ? parseInt(chartSelectedDateRange[1])
    : differenceInMilliseconds(
        isBefore(new Date(chartSelectedDateRange[1]), chartWholeEndDate)
          ? new Date(chartSelectedDateRange[1])
          : chartWholeEndDate,
        chartWholeEndDate,
      )

  const selectedRangeIsOutOfBounds =
    isBefore(chartWholeEndDate, addMilliseconds(chartWholeStartDate, selectionStartInMilliSec)) ||
    isAfter(chartWholeStartDate, subMilliseconds(chartWholeEndDate, selectionEndInMilliSec))

  if (selectedRangeIsOutOfBounds) {
    selectionStartInMilliSec = 0
    selectionEndInMilliSec = 0
  }

  const dateRange = useMemo<DateRange>(() => {
    const startMS = addMilliseconds(
      chartWholeStartDate,
      isNegative(selectionStartInMilliSec) ? 0 : selectionStartInMilliSec,
    )
    // const startMS = min([chartWholeDateRange[1], addMilliseconds(chartWholeDateRange[0], selectionStartInMilliSec)]),
    const endMS = subMilliseconds(chartWholeEndDate, isNegative(selectionEndInMilliSec) ? 0 : selectionEndInMilliSec)
    // const endMS = max([chartWholeDateRange[0], subMilliseconds(chartWholeDateRange[1], selectionEndInMilliSec)]),

    return isAfter(startMS, endMS) ? [endMS, startMS] : [startMS, endMS]
  }, [chartWholeDateRange, selectionStartInMilliSec])

  return dateRange
}

export const useWorkspaceChartSelectedDateRangeInUserTimezone = () => {
  const selectedChartDateRange = useWorkspaceChartSelectedDateRange()
  const userTimezone = useSelector(getUserTimezoneSelector)

  // selectedChartDateRange returns dates in UTC
  const selectedRangeInUserTimezone = useMemo(() => {
    const startDate = convertZonedTimeToAnotherZonedTime(selectedChartDateRange[0], 'UTC', userTimezone)
    const endDate = convertZonedTimeToAnotherZonedTime(selectedChartDateRange[1], 'UTC', userTimezone)

    return [startDate, endDate]
  }, [selectedChartDateRange, userTimezone])

  return selectedRangeInUserTimezone
}

export const getAggregationLabel = (aggregationMode: ChartAggregationMode) => {
  switch (aggregationMode) {
    case ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET:
      return c('Workbench:Aggregation').t`None`
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_SUM:
      return c('Workbench:Aggregation').t`Sum`
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_AVG:
      return c('Workbench:Aggregation').t`Average`
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MIN:
      return c('Workbench:Aggregation').t`Min`
    case ChartAggregationMode.CHART_AGGREGATION_MODE_AGGREGATE_MAX:
      return c('Workbench:Aggregation').t`Max`
  }
}

export enum InBuiltEntities {
  PENALTY = 'PENALTY',
}

export const getInBuiltEntityMessage = (entity: InBuiltEntities) => {
  const { PENALTY } = InBuiltEntities
  switch (entity) {
    case PENALTY:
      return t`This is a System-wide penalty regulation and cannot be modified`
    default:
      return t`It is enercast entity`
  }
}

export const keyboardKeyCode = {
  escape: 27,
  arrowUp: 38,
  arrowDown: 40,
}
