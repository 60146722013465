import styled from 'styled-components'
import { table } from 'themes/theme-light'
import Flex from 'ui/styles/Flex'

interface DynamicTableHeightContainerProps {
  numberOfRows: number
  narrowRows?: number
}

const headerRowHeight = table.rowHeight

export const DynamicTableHeightContainer = styled(Flex)<DynamicTableHeightContainerProps>`
  width: 100%;
  height: ${(props) =>
    (props.numberOfRows + 0.7) * table.rowHeight +
    headerRowHeight +
    props.numberOfRows +
    (props.narrowRows || 0) * table.narrowRowHeight}px;
  overflow: auto;
`

interface TDIndentedProps {
  isWide?: boolean
  level: number
}

export const TDIndented = styled.div<TDIndentedProps>`
  position: relative;
  padding-left: ${(props) => (props.level ? props.level : 0)}em !important;
`

interface ToolbarContainerProps {
  width?: string
}

export const ReTableGenericToolbarContainer = styled(Flex)<ToolbarContainerProps>`
  width: ${(props) => props.width || 'inherit'};
  max-width: 100%;
`
