import { Divider, ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useAreaForecastConfigDeleteMutation } from 'modules/dataStreams/api/areaForecastConfigs.api'
import {
  useSiteForecastConfigDeleteMutation,
  useSiteForecastConfigs,
} from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useWeatherConfigDeleteMutation } from 'modules/dataStreams/api/weatherData.api'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { jt, msgid, ngettext, t } from 'ttag'
import ConfirmationDialog from 'ui/elements/ConfirmationDialog'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'

import ButtonMenu from 'ui/form/ButtonMenu'
import {
  dataStreamTypeQuery,
  DEFAULT_FORECAST_CONFIG_ID,
  getDataStreamMenuItems,
  useDataStreams,
} from 'utils/dataStream'
import { QUERY_DATA_STREAM_ID, QUERY_DATA_STREAM_TYPE, QUERY_NEW_SCHEDULE } from 'utils/query-string'
import { filterSelectionByAllChartTypes } from 'utils/workspace'
import { useUpdateQueryString } from 'utils/hooks/useUpdateQueryString'
import { isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import { useCustomMuiStyles } from 'themes/theme-light'
import ErrorMessage from 'ui/form/ErrorMessage'
import { scheduleDataMenuName, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import AssetLink from 'modules/asset/AssetLink'
import { DisplayItemsToDeleteLimit } from 'utils/dataFormatting'
import Flex from 'ui/styles/Flex'

const AlignedIconMenu = styled(ButtonMenu)`
  margin-right: 0.5em;
`

const DataMenu: React.FC = ({ ...rest }) => {
  //Style
  const classes = useCustomMuiStyles()
  const { onAddQueryString } = useUpdateQueryString()
  const user = useSelector(getUserResultSelector)
  const dataStreamMenuItems = useMemo(
    () =>
      getDataStreamMenuItems().filter((item) => {
        if (item?.userHasPermission) {
          if (item.userHasPermission(user)) {
            return item.userHasPermission(user)
          } else {
            if (item?.isAdminComponent) {
              return isAdmin(user) || isImpersonatedAdmin(user)
            }
            return false
          }
        }
        return true
      }),
    [user],
  )
  const isReadOnlyUser = useIsReadOnlyUser()

  // selection
  const dataStreams = useDataStreams()
  const forecastConfigs = useSiteForecastConfigs().data || []

  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const dataSelectionWithoutDefault =
    dataSelection.length > 0 ? dataSelection.filter((s) => s.id !== DEFAULT_FORECAST_CONFIG_ID) : []

  const { selectedAreaForecasts, selectedSiteForecasts, selectedWeatherData, selectedSchedule } = useMemo(() => {
    return filterSelectionByAllChartTypes(dataStreams, dataSelectionWithoutDefault)
  }, [dataStreams, dataSelectionWithoutDefault])

  // add handling
  const handleAddDataStream = useCallback(
    (handleClose, item) => (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()

      onAddQueryString({
        [QUERY_DATA_STREAM_TYPE]:
          item.name === scheduleDataMenuName ? dataStreamTypeQuery[TimeSeriesType.SITE_FORECAST] : item.name,
        [QUERY_DATA_STREAM_ID]: undefined,
      })

      if (item.name === scheduleDataMenuName) {
        onAddQueryString({ [QUERY_NEW_SCHEDULE]: true })
      } else {
        onAddQueryString({ [QUERY_NEW_SCHEDULE]: undefined })
      }

      handleClose()
    },
    [onAddQueryString],
  )

  // delete handling
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const { mutate: deleteSiteForecastConfigMutation } = useSiteForecastConfigDeleteMutation()
  const { mutate: deleteWeatherConfigMutation } = useWeatherConfigDeleteMutation()
  const { mutate: deleteAreaForecastConfigMutation } = useAreaForecastConfigDeleteMutation()

  const handleDeleteConfirm = useCallback(
    (handleClose) => (event: React.MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      setIsDeleteDialogOpen(true)
      handleClose()
    },
    [isDeleteDialogOpen],
  )

  const isMaximumHorizonSelected = useMemo(() => {
    for (const i in dataSelection) {
      for (const j in forecastConfigs) {
        if (dataSelection[i].id === forecastConfigs[j].id && forecastConfigs[j].maximumHorizon) {
          return forecastConfigs[j]
        }
      }
    }
  }, [dataSelection, forecastConfigs])

  const handleDeleteDataStreams = useCallback(() => {
    // Since schedule is considered a site forecast , we are merging both of them
    const mergedSelectedSiteForecast = [...selectedSiteForecasts, ...selectedSchedule]

    deleteSiteForecastConfigMutation(
      mergedSelectedSiteForecast
        .filter((selected) => selected.id !== isMaximumHorizonSelected?.id)
        .map((selected) => selected.name),
    )

    deleteWeatherConfigMutation(selectedWeatherData.map((selected) => selected.name))
    deleteAreaForecastConfigMutation(selectedAreaForecasts.map((selected) => selected.name))
    setIsDeleteDialogOpen(false)
  }, [selectedSiteForecasts, selectedSchedule, selectedWeatherData, selectedAreaForecasts, isMaximumHorizonSelected])

  const closeDeleteDataStreams = useCallback(() => {
    setIsDeleteDialogOpen(false)
  }, [])

  const menuContent = useCallback(
    (handleClose) => (
      <div>
        {dataStreamMenuItems.map((item) => {
          return (
            <MenuItem
              className={item.isAdminComponent && !item.userHasPermission(user) ? classes.adminComponent : 'inherit'}
              disabled={isReadOnlyUser}
              key={item.name}
              onClick={handleAddDataStream(handleClose, item)}
            >
              <ListItemIcon>
                <FontAwesomeActionIcon icon="plus" />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </MenuItem>
          )
        })}
        <Divider />
        <MenuItem
          disabled={isReadOnlyUser || !(dataSelectionWithoutDefault.length > 0)}
          onClick={handleDeleteConfirm(handleClose)}
        >
          <ListItemIcon>
            <FontAwesomeActionIcon icon="trash-alt" />
          </ListItemIcon>
          <ListItemText
            primary={ngettext(msgid`Delete data stream`, `Delete data streams`, dataSelectionWithoutDefault.length)}
          />
        </MenuItem>
      </div>
    ),
    [dataSelectionWithoutDefault, dataStreamMenuItems, handleAddDataStream],
  )

  const itemsToBeDisplayed =
    dataSelectionWithoutDefault.length >= 10 ? DisplayItemsToDeleteLimit : dataSelectionWithoutDefault.length
  const remainingItemsToDisplay = dataSelectionWithoutDefault?.length - itemsToBeDisplayed
  const singleDataStreamSelected =
    dataSelectionWithoutDefault.length === 1 ? dataSelectionWithoutDefault[0]?.label || dataSelection[0]?.name : ''

  return (
    <>
      {isDeleteDialogOpen && (
        <ConfirmationDialog
          heading={ngettext(msgid`Delete data stream`, `Delete data streams`, dataSelectionWithoutDefault.length)}
          text={''}
          confirmAction={t`Yes`}
          cancelAction={t`No`}
          onConfirm={handleDeleteDataStreams}
          onCancel={closeDeleteDataStreams}
          openDialog={isDeleteDialogOpen}
        >
          <div>
            {dataSelectionWithoutDefault.length > 1 ? (
              <>
                {t`Do you want to delete these data streams?`}
                <ul style={{ marginBottom: '0' }}>
                  {dataSelectionWithoutDefault.slice(0, itemsToBeDisplayed).map((dataStream) => (
                    <li style={{ margin: '5px 0' }} key={dataStream.id}>
                      {dataStream?.label || dataStream?.label}
                    </li>
                  ))}
                </ul>
                {remainingItemsToDisplay > 0 && (
                  <div style={{ marginLeft: '1.9em' }}>{t`and ${remainingItemsToDisplay} more.`}</div>
                )}
              </>
            ) : (
              <Flex> {jt`Do you want to delete "${singleDataStreamSelected}" data stream?`}</Flex>
            )}

            {isMaximumHorizonSelected && (
              <ErrorMessage
                minHeight={false}
              >{jt`Attention: You have selected ${isMaximumHorizonSelected.name}. This Site Forecast will not be deleted.`}</ErrorMessage>
            )}
          </div>
        </ConfirmationDialog>
      )}
      <AlignedIconMenu
        className="appTour-createSiteForecast-contextMenu"
        icon="bars"
        iconOnly
        renderMenu={menuContent}
        {...rest}
      />
    </>
  )
}

export default React.memo(DataMenu)
