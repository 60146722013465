import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'

import { testIdMainHeader, testIdMainPage } from 'modules/app/MainPage.ids'
import Routes from 'modules/app/Routes'
import Header from 'modules/app/header/Header'
import OverlayContainer from 'ui/OverlayContainer'
import Flex from 'ui/styles/Flex'
import { QUERY_AUTH_FORM, QUERY_PRINT_VIEW, useQueryParams, useQueryString } from 'utils/query-string'
import OnBoardingTour from 'modules/app/tour/OnBoardingTour'
import { useAppTourSettings } from 'modules/app/tour/appTour.api'
import PrintView from 'modules/printView/PrintView'

// styled components
const MainGrid = styled(Flex)`
  height: 100%;
`

const HeaderLayout = styled.div`
  width: 100%;
  z-index: 10;
`

const MainLayout = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  /*overflow: auto; /* needed for visual bug where workbench asset sidebar shadow overlaps footer bar */
`

// exported component
const MainPage = () => {
  const { onDeleteQueryStrings } = useQueryString()
  const { queryParams } = useQueryParams()

  const appTourSettings = useAppTourSettings()
  const showOnBoardingDialog = useMemo(() => appTourSettings?.data?.startOnBoardingTour, [appTourSettings?.data])
  const isTourByEndUser = useMemo(() => appTourSettings?.data?.endOnBoardingTour, [appTourSettings?.data])

  const isPrintView = useMemo(() => {
    if (queryParams[QUERY_PRINT_VIEW]) {
      return true
    }
    return false
  }, [queryParams])

  useEffect(() => {
    setTimeout(() => {
      // we need to use a timeout since immediately changing the path conflicts with redirects (e.g. from / to /workbench)
      if (queryParams[QUERY_AUTH_FORM]) {
        onDeleteQueryStrings([QUERY_AUTH_FORM])
      }
    }, 0)
  }, [onDeleteQueryStrings])

  // Add this to support the print view and hide the dom elements added necessary
  useEffect(() => {
    document.body.style.overflow = 'hidden'
  }, [])

  return (
    <>
      {isPrintView ? (
        <MainGrid direction="column" flexGrow={1}>
          <PrintView />
        </MainGrid>
      ) : (
        <MainGrid direction="column" flexGrow={1} data-testid={testIdMainPage} className="not-printable">
          {appTourSettings?.isSuccess && !isTourByEndUser && showOnBoardingDialog && <OnBoardingTour />}

          <>
            <HeaderLayout data-testid={testIdMainHeader} className="not-printable">
              <Header />
            </HeaderLayout>
            <MainLayout className="not-printable">
              <Routes />
              <OverlayContainer />
            </MainLayout>
          </>
        </MainGrid>
      )}
    </>
  )
}

export default React.memo(MainPage)
