import React, { useCallback, useMemo, useState } from 'react'

import { Asset, TreeDataStreamsSelectionOptions } from 'modules/asset/store/asset.types'
import TreeControls from 'modules/asset/tree/TreeControls'
import TreeCell from 'modules/asset/tree/TreeCell'
import SidebarRowItem from 'ui/elements/SidebarRowItem'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { CellRenderType, Column, RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { TDIndented } from 'ui/styles/table'
import { CoordinateCachedData } from 'modules/asset/assetCrud/assetDetails/LocationCoordinates'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableCollapsedSelector } from 'modules/reTable/redux_store/state/view.state'
import ForecastModelRows from 'modules/asset/tree/ForecastModelRows'
import { isGenerator, isParkWithNoPlants } from 'utils/asset'
import { KEY_SHOW_MODELS_IN_ASSET_TREE, useUserSetting } from 'modules/auth/api/userSettings.api'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'
import { FindAllCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import { StyledReTableRow } from 'ui/elements/StyledRetableRow'

const AssetNameWidth = 14.5 // em

// types
export interface TreeRowProps {
  asset: Asset
  level: number
  isSelected: boolean
  isWide: boolean
  showNumCapFraction: boolean
  columns: Column[]
  lightMode: boolean
  detailsVisible: boolean
  onSelect: (asset: Asset, selectionOptions: TreeDataStreamsSelectionOptions) => void
  onToggleDetails: (asset: Asset) => void
  operationalData: CoordinateCachedData
  backcastStatusPerAsset: any
  filterSettingsPerAsset: FindAllCleansingFilterSettings
  index: number
  mouseDown: (index: number, event: any) => void
  mouseEnter: (index: number, event: any) => void
  mouseUp: (index: number, event: any) => void
}

// exported component
const TreeRow: React.FC<TreeRowProps> = ({
  asset,
  level,
  isSelected,
  isWide,
  showNumCapFraction,
  columns,
  lightMode,
  detailsVisible,
  onSelect,
  onToggleDetails,
  operationalData,
  backcastStatusPerAsset,
  filterSettingsPerAsset,
  index,
  mouseDown,
  mouseUp,
  mouseEnter,
  ...rest
}) => {
  const user = useSelector(getUserResultSelector)
  const [hovered, setHovered] = useState<boolean>(false)

  const collapsedIds = useReTableSelectorWithId(reTableCollapsedSelector, RETABLE_ID_ASSETS)
  const expanded = useMemo(() => !collapsedIds.includes(asset.id), [collapsedIds, asset])
  const userShowModelRowsResult = useUserSetting<any>(KEY_SHOW_MODELS_IN_ASSET_TREE)

  const showForecastModelRows = useMemo(
    () =>
      userShowModelRowsResult?.data?.value &&
      expanded &&
      (isGenerator(asset) || isParkWithNoPlants(asset)) &&
      hasPermissionForSiteAssessmentBackcast(user),
    [expanded, asset, userShowModelRowsResult?.data, user],
  )

  const handleMouseOver = useCallback(
    (event) => {
      mouseEnter(index, event)
      event.stopPropagation()
      setHovered(true)
    },
    [index],
  )

  const handleMouseDown = (event) => {
    mouseDown(index, event)
    event.stopPropagation()
  }

  const handleMouseUp = (event) => {
    mouseUp(index, event)
    event.stopPropagation()
  }

  const handleMouseLeave = useCallback((event) => {
    event.stopPropagation()
    setHovered(false)
  }, [])

  const handleSelect = useCallback(
    (selectionOptions) => {
      onSelect(asset, selectionOptions)
    },
    [onSelect, asset],
  )

  const handleToggleDetails = useCallback(() => {
    onToggleDetails(asset)
  }, [onToggleDetails, asset])

  return (
    <>
      <StyledReTableRow
        selected={isSelected}
        light_mode={lightMode}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        {...rest}
      >
        <ReTableCell no_border={!isWide} colSpan={2}>
          <TDIndented level={level} isWide={isWide}>
            <SidebarRowItem
              showIcon={true}
              onToggleDetails={handleToggleDetails}
              lightMode={isSelected && !detailsVisible}
              isVisible={hovered || detailsVisible}
            >
              <TreeControls
                asset={asset}
                selected={isSelected}
                // checked={checked}
                // indeterminate={indeterminate}
                // multiSelect={multiSelect}
                // detailsVisible={detailsVisible}
                showExpandIconForModels={
                  (userShowModelRowsResult?.data?.value && hasPermissionForSiteAssessmentBackcast(user)) || false
                }
                anyDetailsVisible={lightMode}
                onSelect={handleSelect}
                assetNameWidth={isWide ? 'inherit' : `${AssetNameWidth - level}em`}
              />
            </SidebarRowItem>
          </TDIndented>
        </ReTableCell>
        {isWide && (
          <>
            {columns.map((column) => (
              <ReTableCell
                align={column.cellRenderType === CellRenderType.NUMERIC ? 'right' : 'left'}
                key={column.name}
              >
                <TreeCell
                  showNumCapFraction={showNumCapFraction}
                  column={column}
                  asset={asset}
                  selected={isSelected}
                  operationalData={operationalData}
                  backcastStatusPerAsset={backcastStatusPerAsset}
                  filterSettingsPerAsset={filterSettingsPerAsset}
                  anyDetailsVisible={lightMode}
                />
              </ReTableCell>
            ))}
          </>
        )}
      </StyledReTableRow>
      {showForecastModelRows && (
        <ForecastModelRows
          asset={asset}
          onSelect={onSelect}
          isWide={isWide}
          level={level}
          backcastStatusPerAsset={backcastStatusPerAsset}
        />
      )}
    </>
  )
}

// TreeRow.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(TreeRow)
