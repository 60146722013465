// (1) polyfill ES6 features
// import 'core-js/stable' // provided by react-app-polyfill
// import 'regenerator-runtime/runtime' // provided by react-app-polyfill
import 'react-app-polyfill/ie11' // (1)
import 'react-app-polyfill/stable' // (1)
import { enableES5 } from 'immer' // (1)
import 'typeface-roboto' // TODO do we still need this?
import 'typeface-lato'
import './iconLibrary'
import './index.css'

import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsMore from 'highcharts/highcharts-more'

import { addLocale } from 'ttag'
import { LanguageKeys } from 'fixtures/header'

enableES5() // (1)

HighchartsMore(Highcharts)

// i18n
const i18nInit = () => {
  const { english, german } = LanguageKeys
  const supportedLanguages = [english, german]
  supportedLanguages.forEach(async (language) => {
    const data = await import(`locales/${language}/ui.po.json`)
    addLocale(language, data)
  })
}
i18nInit()

// polyfill for ResizeObserver
// TODO not needed anymore
const polyfillResizeObserver = async () => {
  if (!('ResizeObserver' in window)) {
    // Loads polyfill asynchronously, only if required.
    const module = await import('@juggle/resize-observer')
    window.ResizeObserver = module.ResizeObserver
  }
}
polyfillResizeObserver()

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render') // eslint-disable-line @typescript-eslint/no-var-requires
  whyDidYouRender(React)

  // const observer = new PerformanceObserver(list => {
  //   for (const entry of list.getEntries()) {
  //     console.log('long task:', entry?.attribution[0]?.containerType, entry)
  //   }
  // })
  // observer.observe({ entryTypes: ['longtask'] })
}
