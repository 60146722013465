import cloneDeep from 'clone-deep'
import { FormApi } from 'final-form'
import { ClusterTypesEnum, DEFAULT_LAT, DEFAULT_LNG, getTrackerValues, TrackerNamesEnum } from 'fixtures/assetForm'
import { Countries } from 'fixtures/countries'
import { Timezone } from 'fixtures/timezones'
import { getCenter } from 'geolib'
import { useAllAssets } from 'modules/asset/api/assets.api'
import {
  Asset,
  AssetLocation,
  BaseGenerator,
  chpGenerator,
  Cluster,
  Coordinate,
  Generator,
  GenericAssetType,
  LocationAsset,
  Nomcap,
  Park,
  RELATION_TYPE,
  RELATION_TYPE_CLUSTER,
  RELATION_TYPE_NONE,
  RELATION_TYPE_PARK,
  RELATION_TYPE_PLANT,
  SolarGenerator,
  SolarPark,
  TYPE_ASSET,
  TYPE_CHP,
  TYPE_CLUSTER,
  TYPE_SOLARPARK,
  TYPE_SOLARPLANT,
  TYPE_WINDPARK,
  TYPE_WINDPLANT,
  UiAssetType,
  UiClusterTypes,
  WindGenerator,
  WindPark,
} from 'modules/asset/store/asset.types'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { ShapeInfoWrapper } from 'modules/gips/helper/Gips.types'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { c } from 'ttag'
import ClusterPoolIcon from 'ui/icons/ClusterPoolIcon'
import SolarParkIcon from 'ui/icons/SolarParkIcon'
import SolarPlantIcon from 'ui/icons/SolarPlantIcon'
import WindParkIcon from 'ui/icons/WindParkIcon'
import WindPlantIcon from 'ui/icons/WindPlantIcon'
import { isNumeric } from 'utils/dataFormatting'

import { convertUtcToZonedTime, convertZonedTimeToUtc, formatDateShort } from 'utils/date'
import { FormSaveOptions } from 'utils/form'
import CHPIcon from 'ui/icons/CHPIcon'

import ClusterHybridIcon from 'ui/icons/HybridClusterIcon'
import ClusterIcon from 'ui/icons/ClusterIcon'
import { User } from 'modules/auth/Auth.types'
import {
  hasPermissionForPvMeterDataCleansing,
  hasPermissionForWindMeterDataCleansing,
  isAdmin,
  isImpersonatedAdmin,
} from 'utils/user'

// type guards that automatically casts an asset if condition is true

export const isCluster = (asset: Partial<Asset> | undefined): asset is Cluster => {
  const { CLUSTER, CLUSTER_HYBRID, CLUSTER_POOL } = UiClusterTypes
  if (asset?.uiAssetType === CLUSTER || asset?.uiAssetType === CLUSTER_HYBRID || asset?.uiAssetType === CLUSTER_POOL) {
    return true
  }
  return asset?.type === TYPE_CLUSTER
}

export const isClusterCollection = (asset: Partial<Asset> | undefined): asset is Cluster => {
  return asset?.uiAssetType === UiClusterTypes.CLUSTER
}

export const isClusterHybridPark = (asset: Partial<Asset> | undefined): asset is Cluster => {
  return asset?.uiAssetType === UiClusterTypes.CLUSTER_HYBRID
}

export const isWindPark = (asset: Partial<Asset> | undefined): asset is WindPark => {
  return asset?.type === TYPE_WINDPARK
}

export const isSolarPark = (asset: Partial<Asset> | undefined): asset is SolarPark => {
  return asset?.type === TYPE_SOLARPARK
}

export const isWindPlant = (asset: Partial<Asset> | undefined): asset is WindGenerator => {
  return asset?.type === TYPE_WINDPLANT
}

export const isSolarPlant = (asset: Partial<Asset> | undefined): asset is SolarGenerator => {
  return asset?.type === TYPE_SOLARPLANT
}

export const isCHP = (asset: Partial<Asset> | undefined): asset is chpGenerator => {
  return asset?.type === TYPE_CHP
}

export const isPark = (asset: Partial<Asset> | undefined): asset is Park => {
  return [TYPE_WINDPARK, TYPE_SOLARPARK].includes(asset?.type || '')
}

export const isGenerator = (asset: Partial<Asset> | undefined): asset is Generator => {
  return [TYPE_WINDPLANT, TYPE_SOLARPLANT, TYPE_CHP].includes(asset?.type || '')
}

export const isLocationAsset = (asset: Partial<Asset> | undefined): asset is LocationAsset => {
  return isParkWithNoPlants(asset) || isGenerator(asset)
}

export const isAsset = (asset: Partial<Asset> | undefined): asset is Asset => {
  return isLocationAsset(asset) || isCluster(asset) || isPark(asset)
}

export const isParkWithNoPlants = (asset: Partial<Asset> | undefined, preferredType?: string): asset is Park => {
  const hasNoGeneratorIds = Boolean(!asset?.generatorIds)
  const hasEmptyGeneratorIds = Boolean(asset?.generatorIds && !asset?.generatorIds.length)
  // Solar park only , TYPE_SOLARPLANT is just reference
  if (preferredType && preferredType === TYPE_SOLARPLANT) {
    return isSolarPark(asset) && (hasNoGeneratorIds || hasEmptyGeneratorIds)
  }
  // Wind park only , TYPE_WINDPLANT is just reference
  if (preferredType && preferredType === TYPE_WINDPLANT) {
    return isWindPark(asset) && (hasNoGeneratorIds || hasEmptyGeneratorIds)
  }
  // For both
  return isPark(asset) && (hasNoGeneratorIds || hasEmptyGeneratorIds)
}

export const isWindAsset = (asset: Partial<Asset> | undefined): boolean => {
  return isWindPark(asset) || isWindPlant(asset)
}

export const isSolarAsset = (asset: Partial<Asset> | undefined): boolean => {
  return isSolarPark(asset) || isSolarPlant(asset)
}

export const assetTypesForMDC = [TYPE_SOLARPARK, TYPE_SOLARPLANT, TYPE_WINDPARK, TYPE_WINDPLANT]

// getters for asset attributes
export const getGenericAssetType = (type: TYPE_ASSET | UiAssetType): GenericAssetType => {
  switch (type) {
    case TYPE_CLUSTER:
    case UiClusterTypes.CLUSTER:
    case UiClusterTypes.CLUSTER_POOL:
    case UiClusterTypes.CLUSTER_HYBRID:
      return GenericAssetType.CLUSTER
    case TYPE_WINDPARK:
    case TYPE_SOLARPARK:
      return GenericAssetType.PARK
    case TYPE_WINDPLANT:
    case TYPE_SOLARPLANT:
    case TYPE_CHP:
      return GenericAssetType.GENERATOR
  }
}

export const getTypeLabel = (type: TYPE_ASSET | UiAssetType) => {
  switch (type) {
    case TYPE_CLUSTER:
    case UiClusterTypes.CLUSTER:
      return c('Asset:Type').t`Cluster`
    case UiClusterTypes.CLUSTER_POOL:
      return c('Asset:Type').t`Pool`
    case UiClusterTypes.CLUSTER_HYBRID:
      return c('Asset:Type').t`Hybrid Park`
    case TYPE_WINDPARK:
      return c('Asset:Type').t`Wind Park`
    case TYPE_WINDPLANT:
      return c('Asset:Type').t`Wind Plant`
    case TYPE_SOLARPARK:
      return c('Asset:Type').t`Solar Park`
    case TYPE_SOLARPLANT:
      return c('Asset:Type').t`Solar Plant`
    case TYPE_CHP:
      return c('Asset:Type').t`CHP Plant`
  }
}

export const getAssetTypeText = (type: TYPE_ASSET | UiAssetType) => {
  switch (type) {
    case TYPE_CLUSTER:
    case UiClusterTypes.CLUSTER:
      return c('Asset details')
        .t`A cluster is a collection of any number of assets of any type that are grouped together for administrative purposes.  An asset can be a member in multiple clusters.  Note that adding hybrid parks or pools to clusters is currently not supported.`
    case UiClusterTypes.CLUSTER_POOL:
      return c('Asset details')
        .t`A pool is a group of multiple assets of any type that are physically connected to a single metering point, e. g. a substation.  A member of a pool cannot be a member of another pool or park.  Note that adding hybrid parks to pools is currently not supported.`
    case UiClusterTypes.CLUSTER_HYBRID:
      return c('Asset details')
        .t`A hybrid park is a combination of solar and wind assets (plants or parks) that are physically connected to a single metering point and usually controlled by a hybrid park controller that optimizes wind and solar generation for a stable output.`
    case TYPE_WINDPARK:
      return c('Asset details')
        .t`A wind park is a group of multiple wind turbines that are physically connected to a single metering point.  It can be created either as a list of of individual wind plants, or – if all turbines in the park share the same technical data – by entering the details at the park level.`
    case TYPE_WINDPLANT:
      return c('Asset details').t`A wind plant is an individual wind turbine.`
    case TYPE_SOLARPARK:
      return c('Asset details')
        .t`A solar park is a group of multiple solar plants that are physically connected to a single metering point.  It can be created either as a list of of individual solar plants, or – if all members of the park share the same technical data – by entering the details at the park level.`
    case TYPE_SOLARPLANT:
      return c('Asset details').t`A solar plant is an individual PV site with uniform technical data.`
    case TYPE_CHP:
      return ''
    default:
      return ''
  }
}

export const getRelationTypeLabel = (relation: RELATION_TYPE) => {
  switch (relation) {
    case RELATION_TYPE_CLUSTER:
      return c('Asset:RelationType').t`Cluster`
    case RELATION_TYPE_PARK:
      return c('Asset:RelationType').t`Park`
    case RELATION_TYPE_PLANT:
      return c('Asset:RelationType').t`Plant`
    case RELATION_TYPE_NONE:
      return c('Asset:RelationType').t`No parent`
  }
}

export const getNomcap = (asset: Partial<Asset>) => {
  // conversion from Watt to Kilowatt

  if (isGenerator(asset)) {
    return asset.nomCap
  } else if (isPark(asset) || isCluster(asset)) {
    return asset.currentNomCap
  }
  return 0
}

type GetAddress = (asset: Partial<Asset>) => Partial<AssetLocation>
export const getAddress: GetAddress = (asset) => {
  if (isGenerator(asset) || isParkWithNoPlants(asset)) {
    const { address, zip, city, country } = asset.location
    return {
      address,
      zip,
      city,
      country,
    }
  } else {
    return {}
  }
}

export const defaultLocation = () => {
  const defaultCoords = {
    lat: DEFAULT_LAT,
    lng: DEFAULT_LNG,
  }
  return defaultCoords
}

export const getCoords = (asset: Partial<Asset>) => {
  let coords: Coordinate = {
    latitude: undefined,
    longitude: undefined,
  }
  if (isGenerator(asset) || isParkWithNoPlants(asset)) {
    if (asset.location.coordinate.latitude && asset.location.coordinate.longitude) {
      coords = asset.location.coordinate
    }
  }
  return coords
}

export const sortAlphabetically = (a: Asset, b: Asset) => {
  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
}

interface GetParents {
  (asset: Asset, allAssets: Asset[]): Asset[]
}
export const getParents: GetParents = (asset, allAssets) => {
  const parentIds = [
    ...((asset as Park).clusterIds || []),
    ...((asset as BaseGenerator).parkId ? [(asset as BaseGenerator).parkId] : []),
  ]
  const parents = [...allAssets.filter((a) => parentIds.includes(a.id))]
  parents.sort(sortAlphabetically)
  return parents
}

interface GetChildren {
  (asset: Asset, allAssets: Asset[]): Asset[]
}
export const getChildren: GetChildren = (asset, allAssets) => {
  const children = asset?.id
    ? [
        // some assets have an inline array of assets
        ...(asset.assets || []),
        // clusters and parks also have an array of generator ids
        ...(asset.generatorIds || []).map((id) => allAssets.find((a) => a.id === id) as Asset),
        // clusters also have an array of park ids
        ...((asset as Cluster).parkIds || []).map((id) => allAssets.find((a) => a.id === id) as Asset),
      ]
        // filter out undefined if allAssets is not containing children ids
        .filter((a) => a)
    : []

  children.sort(sortAlphabetically)
  return children
}

interface HasParents {
  (asset: Asset, allAssets: Asset[]): boolean
}
export const hasParents: HasParents = (asset, allAssets) => {
  const parents = getParents(asset, allAssets)
  return parents.length > 0
}

interface HasChildren {
  (asset: Asset, allAssets: Asset[]): boolean
}
export const hasChildren: HasChildren = (asset, allAssets) => {
  const children = getChildren(asset, allAssets)
  return children.length > 0
}

interface GetAncestors {
  (asset: Asset, allAssets: Asset[]): Asset[]
}
export const getAncestors: GetAncestors = (asset, allAssets) => {
  const parents = getParents(asset, allAssets)
  const grandParents = parents.map((parent) => getAncestors(parent, allAssets))
  const ancestors = parents.concat(grandParents.flat())
  ancestors.sort(sortAlphabetically)
  return ancestors
}

interface GetDescendants {
  (asset: Asset, allAssets: Asset[]): Asset[]
}
export const getDescendants: GetDescendants = (asset, allAssets) => {
  const children = getChildren(asset, allAssets)
  const grandChildren = children.map((child) => getDescendants(child, allAssets))
  const descendants = children.concat(grandChildren.flat())
  descendants.sort(sortAlphabetically)
  return descendants
}

export const combineAssetWithDescendants = ({
  asset,
  selectedAssets,
  allAssets,
}: {
  asset: Asset
  selectedAssets: Asset[]
  allAssets: Asset[]
}): Asset[] => {
  const selectedAssetIds = selectedAssets.map((asset) => asset.id)
  const descendants = getDescendants(asset, allAssets)
  const assetAndDescendants = descendants.concat([asset])
  const assetAndDescendantIds = assetAndDescendants.map((asset) => asset.id)

  let combinedAssets = []
  const alreadySelected = selectedAssets.map((selectedAsset) => selectedAsset.id).includes(asset.id)
  if (alreadySelected) {
    combinedAssets = [
      ...selectedAssets.filter((selectedAsset) => {
        return !assetAndDescendantIds.includes(selectedAsset.id)
      }),
    ]
  } else {
    combinedAssets = [...selectedAssets, ...assetAndDescendants.filter((asset) => !selectedAssetIds.includes(asset.id))]
  }
  return combinedAssets
}

export const combineAssetWithAncestorsAndDescendants = ({
  asset,
  selectedAssets,
  allAssets,
}: {
  asset: Asset
  selectedAssets: Asset[]
  allAssets: Asset[]
}): Asset[] => {
  const selectedAssetIds = selectedAssets.map((asset) => asset.id)
  const ancestors = getAncestors(asset, allAssets)
  const descendants = getDescendants(asset, allAssets)
  const relatives = ancestors.concat(descendants)
  const assetAndRelatives = relatives.concat([asset])
  const assetAndRelativeIds = assetAndRelatives.map((asset) => asset.id)

  let combinedAssets = []
  const alreadySelected = selectedAssets.map((selectedAsset) => selectedAsset.id).includes(asset.id)
  if (alreadySelected) {
    combinedAssets = [
      ...selectedAssets.filter((selectedAsset) => {
        return !assetAndRelativeIds.includes(selectedAsset.id)
      }),
    ]
  } else {
    combinedAssets = [...selectedAssets, ...assetAndRelatives.filter((asset) => !selectedAssetIds.includes(asset.id))]
  }
  return combinedAssets
}

export const combineAssetsWithAncestorsAndDescendants = ({
  assets,
  selectedAssets,
  allAssets,
}: {
  assets: Asset[]
  selectedAssets: Asset[]
  allAssets: Asset[]
}): Asset[] => {
  const combinedAssetSet: Asset[][] = assets.map((asset) =>
    combineAssetWithAncestorsAndDescendants({ asset, selectedAssets, allAssets }),
  )
  return combinedAssetSet.flat()
}

export const combineAssetsWithDescendants = ({
  assets,
  selectedAssets,
  allAssets,
}: {
  assets: Asset[]
  selectedAssets: Asset[]
  allAssets: Asset[]
}): Asset[] => {
  const combinedAssetSet: Asset[][] = assets.map((asset) =>
    combineAssetWithDescendants({ asset, selectedAssets, allAssets }),
  )
  return combinedAssetSet.flat()
}

// export const filterByField = (asset: Asset, filter: Filter) => {
//   const filterValue = filter.value || []
//   return filterValue.length === 0 ? true : filterValue.includes(asset.type)
// }

export const getTrackerName = (trackerId: number) => {
  if (trackerId === undefined) return ''
  return getTrackerValues().filter((tracker) => tracker.id === trackerId)[0].name
}

export const plantsWithoutParent = (allAssets: Asset[], plantType: string) => {
  return allAssets.filter((asset: Asset) => {
    return isGenerator(asset) && asset.type === plantType && !asset.parkId && !asset.clusterIds.length && !asset.deleted
  })
}

const MenuItemAssetIconSize = '20'

export const assetTypesWithIcons = () => {
  return [
    {
      value: TYPE_SOLARPLANT,
      label: getTypeLabel(TYPE_SOLARPLANT),
      image: <SolarPlantIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: TYPE_WINDPLANT,
      label: getTypeLabel(TYPE_WINDPLANT),
      image: <WindPlantIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: TYPE_CHP,
      label: getTypeLabel(TYPE_CHP),
      image: <CHPIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: TYPE_SOLARPARK,
      label: getTypeLabel(TYPE_SOLARPARK),
      image: <SolarParkIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: TYPE_WINDPARK,
      label: getTypeLabel(TYPE_WINDPARK),
      image: <WindParkIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: UiClusterTypes.CLUSTER_HYBRID,
      label: getTypeLabel(UiClusterTypes.CLUSTER_HYBRID),
      image: <ClusterHybridIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: UiClusterTypes.CLUSTER_POOL,
      label: getTypeLabel(UiClusterTypes.CLUSTER_POOL),
      image: <ClusterPoolIcon size={MenuItemAssetIconSize} />,
    },
    {
      value: UiClusterTypes.CLUSTER,
      label: getTypeLabel(UiClusterTypes.CLUSTER),
      image: <ClusterIcon size={MenuItemAssetIconSize} />,
    },
  ]
}

export const getDescendantCoordinates = (asset: Asset, allAssets: Asset[]): Coordinate | undefined => {
  if (allAssets && allAssets.length) {
    const descendantsWithCoords: LocationAsset[] = getDescendants(asset, allAssets).filter(
      (asset) => isLocationAsset(asset) && asset?.location?.coordinate?.latitude,
    ) as LocationAsset[]

    const coordinates = descendantsWithCoords.map((asset) => asset.location.coordinate)
    return getCenter(coordinates)
  }
}

export interface AssetSaveMainItem {
  createAsset: FormSaveOptions
  updateAsset: FormSaveOptions
}

// process lists

export const flattenAndUniquifyAssetIds = (assets: Asset[], allAssets: Asset[], preferredType?: string): string[] => {
  let flattenedAssetIds: string[] = []

  assets.forEach((asset) => {
    const descendants = getDescendants(asset, allAssets)
    const flatAssets = descendants.concat([asset])
    const assetIds = flatAssets
      .filter((asset) => {
        if (preferredType && preferredType === TYPE_SOLARPLANT) {
          return isSolarPlant(asset) || isParkWithNoPlants(asset, preferredType)
        }
        return isGenerator(asset) || isParkWithNoPlants(asset)
      })
      .filter((asset) => !flattenedAssetIds.includes(asset.id))
      .map((asset) => asset.id)
    flattenedAssetIds = flattenedAssetIds.concat(assetIds)
  })

  return flattenedAssetIds
}

// normalization and transformation of data

export const enrichAssetDataAfterLoad = (assets: Asset[]): Asset[] => {
  // normalize data
  const allAssets = assets.map((a) => {
    const asset = cloneDeep(a)
    // Add asset uitype
    asset.uiAssetType = getAssetUiType(asset)
    if (isSolarPlant(asset) || (isSolarPark(asset) && isParkWithNoPlants(asset))) {
      if (asset.typeSpecificAttributes && asset.typeSpecificAttributes.tracker === undefined) {
        asset.typeSpecificAttributes.tracker = 0
      }
      if (asset.typeSpecificAttributes.tracker == 2 && asset?.typeSpecificAttributes?.tilt < 90) {
        asset.typeSpecificAttributes.trackerName = TrackerNamesEnum.tsat
      } else {
        asset.typeSpecificAttributes.trackerName = getTrackerName(asset.typeSpecificAttributes.tracker)
      }
    }

    if (isGenerator(asset)) {
      if (isCHP(asset)) {
        if (asset.typeSpecificAttributes.absoluteCapacityHeat) {
          asset.typeSpecificAttributes.absoluteCapacityHeat = asset?.typeSpecificAttributes?.absoluteCapacityHeat / 1000
        }

        if (asset.typeSpecificAttributes.namePlateConsumption) {
          asset.typeSpecificAttributes.namePlateConsumption = asset?.typeSpecificAttributes?.namePlateConsumption / 1000
        }

        if (asset.typeSpecificAttributes.periodEnergySum) {
          asset.typeSpecificAttributes.periodEnergySum = asset?.typeSpecificAttributes?.periodEnergySum / 1000
        }
      }
      asset.nomCap = asset.nomCap / 1000
    } else if (isPark(asset) || isCluster(asset)) {
      asset.currentNomCap = asset.currentNomCap / 1000
    }
    return asset
  })

  allAssets.sort(sortAlphabetically)

  // append hierarchy information
  const sortedAssets: Asset[] = []

  const assetsWithHierarchyData = allAssets.map((asset) => {
    const ancestors = getAncestors(asset, allAssets)
    ancestors.sort(sortAlphabetically)
    asset.uiAncestors = ancestors.map((a) => a.id) || []

    const parents = getParents(asset, allAssets)
    parents.sort(sortAlphabetically)
    asset.uiParents = parents.map((a) => a.id) || []

    const children = getChildren(asset, allAssets)
    children.sort(sortAlphabetically)
    asset.uiChildren = children.map((a) => a.id) || []

    const descendants = getDescendants(asset, allAssets)
    descendants.sort(sortAlphabetically)
    asset.uiDescendants = descendants.map((a) => a.id) || []

    asset.uiLevel = 0
    return asset
  })

  const addSortedChildren = (children: string[], level: number, parents: string[], ancestors: string[]): void => {
    children.forEach((childId) => {
      const child = cloneDeep(allAssets.find((asset) => asset.id === childId))
      if (child) {
        child.uiLevel = level
        child.uiParents = child.uiParents.filter((uiParent) => parents.includes(uiParent))
        child.uiAncestors = child.uiAncestors.filter((uiAncestor) => ancestors.includes(uiAncestor))
        sortedAssets.push(child)
        if (child.uiChildren) {
          addSortedChildren(child.uiChildren, level + 1, [child.id], [...ancestors, child.id])
        }
      }
    })
  }

  assetsWithHierarchyData
    .filter((asset) => asset.uiParents.length === 0)
    .forEach((rootAsset) => {
      sortedAssets.push(rootAsset)
      addSortedChildren(rootAsset.uiChildren || [], 1, [rootAsset.id], [rootAsset.id])
    })

  return sortedAssets
}

export const enrichAssetDataAfterLoadWithShapes = (assets: Asset[], shapes: ShapeInfoWrapper[]): Asset[] => {
  if (Array.isArray(shapes)) {
    assets.forEach((asset) => {
      shapes.forEach((shape) => {
        if (shape.shapeInfo.assetID === asset.id) {
          asset.shape = JSON.stringify(shape.multiPolygon, null, 2)
          asset.shapeUUID = shape.uuid
        }
      })
    })
  }
  return assets
}

export const getAssetUiType = (asset: Asset): UiAssetType => {
  let uiType: UiAssetType = UiClusterTypes.CLUSTER
  if (isCluster(asset)) {
    if (asset.clusterType === ClusterTypesEnum.POOL) {
      uiType = UiClusterTypes.CLUSTER_POOL
    } else if (asset.clusterType === ClusterTypesEnum.HYBRID) {
      uiType = UiClusterTypes.CLUSTER_HYBRID
    } else {
      uiType = UiClusterTypes.CLUSTER
    }
  } else {
    uiType = asset.type
  }
  return uiType
}

export const transformAssetDataForForm = (asset: Asset, userTimezone?: Timezone): Asset => {
  const transformedAsset = { ...asset }
  transformedAsset.uiAssetType = getAssetUiType(asset)
  if (isCluster(transformedAsset)) {
    transformedAsset.parkIds = transformedAsset?.parkIds.length
      ? transformedAsset.parkIds.filter(
          (selectedId, index, list) => list.findIndex((id) => id === selectedId) === index,
        )
      : []
    transformedAsset.generatorIds = transformedAsset?.generatorIds.length
      ? transformedAsset.generatorIds.filter(
          (selectedId, index, list) => list.findIndex((id) => id === selectedId) === index,
        )
      : []
  } else {
    const uniqueClusterIds = transformedAsset?.clusterIds?.length
      ? transformedAsset.clusterIds.filter(
          (selectedId, index, list) => list.findIndex((id) => id === selectedId) === index,
        )
      : []
    transformedAsset.since =
      userTimezone && transformedAsset.since
        ? convertUtcToZonedTime(new Date(transformedAsset.since), userTimezone).getTime()
        : transformedAsset.since
    transformedAsset.to =
      userTimezone && transformedAsset.to
        ? convertUtcToZonedTime(new Date(transformedAsset.to), userTimezone).getTime()
        : transformedAsset.to

    if (isGenerator(transformedAsset)) {
      transformedAsset.nomCapTimeZone = transformedAsset?.nomCapTimeZone || null
      transformedAsset.clusterIds = uniqueClusterIds
      transformedAsset.uiParkIds = transformedAsset.parkId ? [transformedAsset.parkId] : []
    }
    if (isPark(transformedAsset) && !isNumeric(transformedAsset?.currentNomCap) && transformedAsset?.nomcaps?.length) {
      transformedAsset.currentNomCap = transformedAsset.nomcaps[transformedAsset?.nomcaps.length - 1].nomcap / 1000
      transformedAsset.clusterIds = uniqueClusterIds
      transformedAsset.generatorIds = transformedAsset?.generatorIds.length
        ? transformedAsset.generatorIds.filter(
            (selectedId, index, list) => list.findIndex((id) => id === selectedId) === index,
          )
        : []
    }
    if (
      isWindPlant(transformedAsset) ||
      isSolarPlant(transformedAsset) ||
      isWindPark(transformedAsset) ||
      isSolarPark(transformedAsset)
    ) {
      const typeSpecificAttributes = { ...transformedAsset.typeSpecificAttributes }
      const acCapacityInWatt = isNumeric(typeSpecificAttributes.acCapacity)
        ? typeSpecificAttributes.acCapacity / 1000
        : null
      transformedAsset['typeSpecificAttributes'] = {
        ...typeSpecificAttributes,
        acCapacity: acCapacityInWatt,
      }
    }

    transformedAsset.sinceTimeZone = transformedAsset?.sinceTimeZone || null
    transformedAsset.toTimeZone = transformedAsset?.toTimeZone || null
  }

  // Inverted value 'forecastEnabled' should be added to “Not operational” checkbox to the asset details
  // because the final form checkbox doesn't allow to manipulate the checkbox value manually
  transformedAsset.uiNotOperational = !transformedAsset.forecastEnabled

  return transformedAsset
}

export const prepareAssetDataBeforeSave = (
  assetData: Asset,
  enterManually: boolean,
  formReference: FormApi<Asset>,
  userTimezone: Timezone,
) => {
  const dirtyFields = formReference.getState().dirtyFields
  // update the timezones only when fields values are changed
  // Convert dates to UTC
  if (!isCluster(assetData)) {
    delete assetData.clusterType
    // Capacity
    if (isGenerator(assetData) && dirtyFields?.nomCap) {
      assetData.nomCapTimeZone = userTimezone
    }
    // Since and to
    const timezoneToConvertFrom = userTimezone
    if (assetData.since) {
      assetData.since = convertZonedTimeToUtc(new Date(assetData.since), timezoneToConvertFrom).getTime()
      if (dirtyFields?.since) {
        assetData.sinceTimeZone = userTimezone
      }
    }
    if (assetData.to) {
      assetData.to = convertZonedTimeToUtc(new Date(assetData.to), timezoneToConvertFrom).getTime()
      if (dirtyFields?.to) {
        assetData.toTimeZone = userTimezone
      }
    }
  }

  // normalize asset data
  if (isCluster(assetData)) {
    assetData.type = TYPE_CLUSTER
    delete assetData.location
    delete assetData.typeSpecificAttributes
  }

  if (isPark(assetData) && enterManually && assetData.generatorIds) {
    assetData.generatorIds = []
  }
  if (isGenerator(assetData)) {
    assetData.parkId = assetData?.uiParkIds?.length ? assetData?.uiParkIds[0] : null
    if (isCHP(assetData)) {
      if (assetData.typeSpecificAttributes.absoluteCapacityHeat) {
        assetData.typeSpecificAttributes.absoluteCapacityHeat =
          assetData?.typeSpecificAttributes?.absoluteCapacityHeat * 1000
      }

      if (assetData.typeSpecificAttributes.namePlateConsumption) {
        assetData.typeSpecificAttributes.namePlateConsumption =
          assetData?.typeSpecificAttributes?.namePlateConsumption * 1000
      }

      if (assetData.typeSpecificAttributes.periodEnergySum) {
        assetData.typeSpecificAttributes.periodEnergySum = assetData?.typeSpecificAttributes?.periodEnergySum * 1000
      }

      if (assetData.typeSpecificAttributes.periodStartDay) {
        assetData.typeSpecificAttributes.periodStartDay = formatDateShort(
          assetData.typeSpecificAttributes.periodStartDay,
        )
      }

      if (assetData.typeSpecificAttributes.periodEndDay) {
        assetData.typeSpecificAttributes.periodEndDay = formatDateShort(assetData.typeSpecificAttributes.periodEndDay)
      }
    }

    assetData.nomCap = assetData.nomCap * 1000
  } else if (enterManually || assetData.currentNomCap) {
    assetData.currentNomCap = assetData.currentNomCap * 1000
  }

  if (isSolarPlant(assetData) || isWindPlant(assetData) || isSolarPark(assetData) || isWindPark(assetData)) {
    const acCapacityInKW = assetData?.typeSpecificAttributes?.acCapacity
    const acCapacityInWatt = acCapacityInKW !== undefined && isNumeric(acCapacityInKW) ? acCapacityInKW * 1000 : null

    assetData['typeSpecificAttributes'] = {
      ...assetData.typeSpecificAttributes,
      acCapacity: acCapacityInWatt,
    }
  }

  if (isCluster(assetData) || (!enterManually && isPark(assetData))) {
    if (assetData?.typeSpecificAttributes) {
      const typeSpecificAttributes = Object.keys(assetData.typeSpecificAttributes || {}) || []
      if (typeSpecificAttributes.length > 1) {
        typeSpecificAttributes.forEach((attribute) => {
          if (attribute !== 'acCapacity') {
            delete assetData.typeSpecificAttributes[attribute]
          }
        })
      }

      if (isCluster(assetData)) {
        assetData.typeSpecificAttributes = {}
      }
    }

    delete assetData.parkId
  }

  if (isPark(assetData)) {
    if (!assetData.generatorIds.length && !assetData?.nomcaps?.length) {
      assetData.nomcaps = [
        {
          since: new Date(0).toISOString(),
          nomcap: assetData.currentNomCap,
          sinceTimeZone: userTimezone,
        },
      ]
    } else if (dirtyFields?.currentNomCap) {
      const startIndex = 0
      const endIndex = assetData.nomcaps.length - 1
      const allCapacities: Nomcap[] = assetData.nomcaps
      const updatedCapacity = {
        since: new Date(allCapacities[endIndex].since).toISOString(),
        nomcap: assetData.currentNomCap,
        sinceTimeZone: userTimezone,
      }
      assetData.nomcaps = [...assetData.nomcaps.slice(startIndex, endIndex), updatedCapacity]
    }
  }

  if (isWindPlant(assetData) || (isWindPark(assetData) && enterManually)) {
    if (!assetData.manufacturer && !assetData.model) {
      assetData.manufacturer = 'Default'
      assetData.model = 'Lowland'
    }
  }
  // Assign the inverted "Not Operational" value to forecastEnabled flag
  assetData.forecastEnabled = !assetData.uiNotOperational

  if (isCHP(assetData)) {
    delete assetData.typeSpecificAttributes.azimuth
    delete assetData.typeSpecificAttributes.tilt
    delete assetData.typeSpecificAttributes.trackerName
  }

  if (isSolarPlant(assetData) || isCHP(assetData) || (isSolarPark(assetData) && enterManually)) {
    delete assetData.manufacturer
    delete assetData.model
  }

  // delete fields that were added in and for UI only
  delete assetData.enterManually
  delete assetData.plantBelongsTo

  delete assetData.uiAncestors
  delete assetData.uiParents
  delete assetData.uiChildren
  delete assetData.uiDescendants
  delete assetData.uiLevel
  delete assetData.uiNotOperational
  delete assetData.uiParkIds
  delete assetData.uiAssetType

  return assetData
}

// custom hooks for transforming asset data
export const useUniqueSelectedAssets = () => {
  const allAssets = useAllAssets()
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const selectedAssets = useMemo(() => {
    // allAssets and assetSelection can contain duplicates (e.g. generator with multiple clusters as parent)
    // therefore we need to make sure they are used only once for further processing

    const selectionWithoutDuplicates: Asset[] = []
    ;(allAssets.data || []).forEach((asset) => {
      if (!assetSelection.includes(asset.id)) return // asset is not selected

      const selectionIds = selectionWithoutDuplicates.map((a) => a.id)
      const entryAlreadyExists = selectionIds.includes(asset.id)
      if (entryAlreadyExists) return // asset is a duplicate

      selectionWithoutDuplicates.push(asset)
    })

    return selectionWithoutDuplicates
  }, [allAssets.data, assetSelection])

  return selectedAssets
}

export const useUniqueAllAssets = () => {
  const allAssets = useAllAssets()
  const uniqueAssets = useMemo(() => {
    return (allAssets.data || []).filter((asset, index, assetList) => {
      const valid = assetList.findIndex((a) => a.id === asset.id) === index
      return valid
    })
  }, [allAssets.data])
  return uniqueAssets
}

export const getAssetSiteForecasts = (asset: Asset, siteForecasts: ForecastConfig[]) => {
  return siteForecasts.filter((config) => config.id && asset.productConfigIds?.includes(config.id))
}

export const getAssetCountryCode = (asset: Asset) => {
  if (!isLocationAsset(asset) || !asset.location) return null
  const countryObj = Countries.find((c) => c.name == asset.location.country)
  return countryObj ? countryObj?.code : null
}

export const removeDuplicateAssets = (assets: Asset[]): Asset[] => {
  return assets.filter((asset, index, duplicateAssets) => {
    const valid = duplicateAssets.findIndex((a) => a.id === asset.id) === index
    return valid
  })
}

interface ShowMDCForThisAssetAndUserProps {
  asset: Asset
  user: User
}
export const showMDCForThisAssetAndUser = ({ asset, user }: ShowMDCForThisAssetAndUserProps): boolean => {
  const assetType = asset?.type || ''

  // MDC is available only for plants and parks without generators
  const isWindAssetForMDC =
    assetType === TYPE_WINDPLANT || (assetType === TYPE_WINDPARK && asset.generatorIds.length === 0)
  const isSolarAssetForMDC =
    assetType === TYPE_SOLARPLANT || (assetType === TYPE_SOLARPARK && asset.generatorIds.length === 0)

  const isProperAsset = isWindAssetForMDC || isSolarAssetForMDC
  const hasPermission = isWindAssetForMDC
    ? hasPermissionForWindMeterDataCleansing(user)
    : isSolarAssetForMDC
    ? hasPermissionForPvMeterDataCleansing(user)
    : false

  return (hasPermission || isAdmin(user) || isImpersonatedAdmin(user)) && isProperAsset
}
