import { combineReducers } from 'redux'
import createReducer from 'utils/createReducer'
import { createSelector } from 'reselect'
import * as actionTypes from 'modules/data/fileManager/redux_store/fileManager.action.types'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'

// state
interface State {
  result: MeterdataFile[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
}

// types

interface GetMeterdataFilesAction {
  type: actionTypes.GET_METERDATAFILES_ACTION_TYPE
}

// reducers

const result = createReducer<
  State['result'],
  GetMeterdataFilesAction & {
    files: State['result']
  }
>((state = initialState.result, { type, files }) => {
  if (type === actionTypes.GET_METERDATAFILES_SUCCESS) {
    return files
  }
  return state
})

const loading = createReducer<State['loading'], GetMeterdataFilesAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.GET_METERDATAFILES_REQUEST:
      return true
    case actionTypes.GET_METERDATAFILES_SUCCESS:
    case actionTypes.GET_METERDATAFILES_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], GetMeterdataFilesAction & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.GET_METERDATAFILES_SUCCESS:
      case actionTypes.GET_METERDATAFILES_DISMISS:
        return null
      case actionTypes.GET_METERDATAFILES_FAILURE:
        return error
    }
    return state
  },
)

export const getMeterdataFilesReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors

export const getMeterdataFilesResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.data.fileManager.getMeterdataFiles.result,
  (result) => result,
)
export const getMeterdataFilesLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.data.fileManager.getMeterdataFiles.loading,
  (loading) => loading,
)
export const getMeterdataFilesErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.data.fileManager.getMeterdataFiles.error,
  (error) => error,
)
