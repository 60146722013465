import React, { useCallback, useMemo } from 'react'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Column, RETABLE_ID_FORECAST_MODELS } from 'modules/reTable/reTable.types'
import styled from 'styled-components'
import { Box, Button } from '@material-ui/core'
import { t } from 'ttag'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import Flex from 'ui/styles/Flex'
import { useDispatch, useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { createDefaultForecastModel } from 'modules/quality/quality.api'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import DisplayOnGoingTrainings from 'modules/forecastModels/DisplayOnGoingTrainings'
import { Asset } from 'modules/asset/store/asset.types'
import { useCustomMuiStyles } from 'themes/theme-light'

interface ToolbarContainerProps {
  width: string
}

const ToolbarContainer = styled(Box)<ToolbarContainerProps>`
  width: ${(props) => props.width};
  max-width: 100%;
`

interface ForecastModelsTableToolbarProps {
  columns: Column[]
  tableHeaderHasActions: boolean
  onToggleShowHiddenModels: () => void
  showHiddenModels: boolean
  asset: Asset
}

const ForecastModelsTableToolbar: React.FC<ForecastModelsTableToolbarProps> = ({
  onToggleShowHiddenModels,
  showHiddenModels,
  asset,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserResultSelector)

  const classes = useCustomMuiStyles()

  // maybe used later
  // const columnsWidth = useMemo(() => {
  //   const width = (columns || []).reduce((sum, column) => {
  //     return math(`${sum} + ${column.width}`)
  //   }, '0')
  //   return math(`${width} + ${tableHeaderHasActions ? TableHeaderActionCellWidth : 0}`)
  // }, [columns])

  const handleCreateDefaultModel = useCallback(() => {
    createDefaultForecastModel(asset.id)
      .then((res) => {
        if (res.isSuccessful) {
          dispatch({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [asset])

  const actions = useMemo(() => {
    return (
      <Flex direction="row" justifyContent="flex-end" alignItems="center">
        <DisplayOnGoingTrainings asset={asset} />

        {(isAdmin(user) || isImpersonatedAdmin(user)) && (
          <Button color="default" size="small" onClick={handleCreateDefaultModel} className={classes.adminComponent}>
            {t`Create a new default forecast model`}
          </Button>
        )}

        <Button color="primary" size="small" onClick={onToggleShowHiddenModels}>
          {showHiddenModels ? t`Hide hidden models` : t`Show hidden models`}
        </Button>
      </Flex>
    )
  }, [showHiddenModels, user])

  return (
    <ToolbarContainer flexGrow={1} width={'auto'}>
      <ReTableToolbar
        id={RETABLE_ID_FORECAST_MODELS}
        actions={actions}
        showClearSorting={false}
        showSearchField={false}
      />
    </ToolbarContainer>
  )
}

export default React.memo(ForecastModelsTableToolbar)
