import React, { useCallback, useMemo } from 'react'
import {
  DELIVERY_TARGET_TYPE_ENERCAST_FTP,
  DeliveryTarget,
} from 'modules/delivery/deliveryTargets/deliveryTargets.types'
import { Column, RETABLE_ID_DELIVERY_TARGETS } from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { ReTableBody } from 'modules/reTable/ReTableBody'

import { ROUTE_DATA_DELIVERY } from 'modules/app/Routes'
import DeliveryTargetTypeCell from 'modules/delivery/deliveryTargets/DeliveryTargetTypeCell'
import PopperTooltipContentSiteForecasts from 'ui/elements/PopperTooltipContentSiteForecasts'
import PopperTooltip from 'ui/PopperTooltip'
import Highlight from 'ui/Highlight'
import { defaultDeliveryTargetId } from 'utils/delivery'
import { c } from 'ttag'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import SidebarRowItem from 'ui/elements/SidebarRowItem'
import { QUERY_DELIVERY_TARGET, useQueryParams } from 'utils/query-string'

interface DeliveryTargetsTableBodyProps {
  onEditItem: (data: DeliveryTarget) => void
  onDeleteItem: (data: DeliveryTarget) => void
  columns: Column[]
  visibleItems: DeliveryTarget[]
  tableHeaderHasActions: boolean
}

const DeliveryTargetsTableBody: React.FC<DeliveryTargetsTableBodyProps> = ({
  onEditItem,
  onDeleteItem,
  columns,
  visibleItems,
  tableHeaderHasActions,
}) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_DELIVERY_TARGETS)
  const { queryParams } = useQueryParams()
  const currentlyDisplayedTargetId = useMemo(() => {
    let targetId = ''
    if (queryParams[QUERY_DELIVERY_TARGET]) {
      targetId = queryParams[QUERY_DELIVERY_TARGET]
    }
    return targetId
  }, [queryParams[QUERY_DELIVERY_TARGET]])

  const handleOnClickRowItem = useCallback(
    (item: DeliveryTarget) => {
      if (currentlyDisplayedTargetId) {
        onEditItem(item)
      }
    },
    [currentlyDisplayedTargetId, onEditItem],
  )

  const handleGetCustomCellComponent = useCallback(
    (column: Column, item: DeliveryTarget, searchQuery, isRowEdit, hovered) => {
      const currentlyDisplayed = currentlyDisplayedTargetId === item.id
      if (column.name === 'type') {
        return <DeliveryTargetTypeCell deliveryTarget={item} />
      } else if (column.name === 'usedInLength') {
        return (
          <PopperTooltip
            popperLabel={<Highlight text={`${item.usedInLength}`} query={query} />}
            popperContent={<PopperTooltipContentSiteForecasts siteForecasts={item.usedIn || null} />}
          />
        )
      } else if (column.name === 'name') {
        return (
          <SidebarRowItem
            lightMode={false}
            isVisible={hovered || currentlyDisplayed}
            showIcon={true}
            onToggleDetails={() => onEditItem(item)}
          >
            <FixedWidthTextContainer text={item.name} width={column.width || 'inherit'}>
              <Highlight text={item.name} query={query} />
            </FixedWidthTextContainer>
          </SidebarRowItem>
        )
      }
    },
    [query, currentlyDisplayedTargetId],
  )

  return (
    <ReTableBody>
      {visibleItems.map((row: DeliveryTarget) => {
        return (
          <ReTableEditableRow
            onClickRowItem={handleOnClickRowItem}
            key={row.id}
            isRowEdit={false}
            isSelected={currentlyDisplayedTargetId === row.id}
            rowItem={row}
            columns={columns}
            onEditRowItem={onEditItem}
            onDeleteRowItem={onDeleteItem}
            dialogText={row.name}
            dialogContext={c('Delivery:Target').t`Delivery targets`}
            getCustomCellComponent={handleGetCustomCellComponent}
            tableHeaderHasActions={tableHeaderHasActions}
            isCustomRow={false}
            showEditableRowActions={row.id != defaultDeliveryTargetId && row.type != DELIVERY_TARGET_TYPE_ENERCAST_FTP}
            isShaded={false}
            query={query}
            pageRoute={ROUTE_DATA_DELIVERY}
            navigationDialogKey={'deliveryTargets'}
            tableToolbarHasActions={true}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(DeliveryTargetsTableBody)
