import styled from 'styled-components'

interface KeyValueGridProps {
  compact?: boolean
}
export const KeyValueGrid = styled.dl<KeyValueGridProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.compact ? `minmax(0, 1fr) minmax(0, 1fr)` : `minmax(0, 1fr) minmax(10ch, 2fr)`};
  gap: ${(props) => (props.compact ? '0' : '0.5')}em 1em;
`

export const Key = styled.dt`
  text-align: left;
  color: #4c4a4a;
  padding: 10px;
`

export const Value = styled.dd`
  align-self: center;
`
