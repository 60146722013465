import React, { useEffect, useState } from 'react'
import { Field, useField } from 'react-final-form'
import { c, t } from 'ttag'

import {
  AssetFormLabel,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  NumberFieldWithLabel,
  SubField,
} from 'ui/form/assetForm.style'
import Flex from 'ui/styles/Flex'
import { Asset } from 'modules/asset/store/asset.types'
import { isPark } from 'utils/asset'
import { COORDINATE_LATITUDE } from 'utils/units'
import { getIsDataCachedByCoordinates } from 'modules/asset/api/assets.api'
import { Box } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PopperTooltip from 'ui/PopperTooltip'
import { useDebounce } from 'use-debounce'
import { theme } from 'themes/theme-light'

export interface CoordinateCachedData {
  latitude: number
  longitude: number
  cached: boolean
}

interface LocationCoordinatesProps {
  formState?: Asset
  activeField: string | undefined
}

const LocationCoordinates: React.FC<LocationCoordinatesProps> = ({ formState, activeField }) => {
  const latitude = useField<string>('location.coordinate.latitude').input.value
  const longitude = useField<string>('location.coordinate.longitude').input.value
  // const assetChanged = formState?.changed
  const [coordinatesCached, setCoordinatesCached] = useState<boolean>(false)
  const [coordinatesChanged, setCoordinatesChanged] = useState<boolean>(false)
  const [debouncedLatitude] = useDebounce(latitude, 900)
  const [debouncedLongitude] = useDebounce(longitude, 900)

  useEffect(() => {
    if (debouncedLatitude && debouncedLongitude) {
      getIsDataCachedByCoordinates(debouncedLatitude, debouncedLongitude).then((response) => {
        if (response?.length) {
          setCoordinatesCached(response[0].cached)
        }
      })
    }
  }, [debouncedLatitude, debouncedLongitude])

  useEffect(() => {
    if (activeField === 'location.coordinate.latitude' || activeField === 'location.coordinate.longitude') {
      if (debouncedLatitude && debouncedLongitude) setCoordinatesChanged(true)
    }
  }, [activeField, debouncedLongitude, debouncedLatitude])

  return (
    <FormKeyValueGrid className="appTour-createAsset-coordinates">
      <FormKey>
        <Flex>
          <AssetFormLabel>
            <Flex flexWrap="wrap">
              <div>{t`Location`}</div>
              <div>{isPark(formState) && <>&nbsp;{t`(center of park)`}</>}:</div>
            </Flex>
          </AssetFormLabel>
          {coordinatesChanged && !coordinatesCached && (
            <PopperTooltip
              popperLabel={
                <Box ml={0.7}>
                  <FontAwesomeIcon color={theme.palette.primary.main} icon="info" />
                </Box>
              }
              popperContent={<div>{t`Coordinates are outside of the IAD, forecast may take some time`}</div>}
            />
          )}
        </Flex>
      </FormKey>
      <FormValue highlightRequired>
        <Flex direction="row">
          <Flex flexBasis="50%" direction="row">
            <Field name={`location.coordinate.latitude`} fullWidth>
              {(props) => {
                return <NumberFieldWithLabel label={t`Latitude`} type="number" input={props.input} meta={props.meta} />
              }}
            </Field>
            <FieldUnit>{COORDINATE_LATITUDE}</FieldUnit>
          </Flex>

          <SubField flexBasis="50%" direction="row">
            <Field name={`location.coordinate.longitude`}>
              {(props) => {
                return <NumberFieldWithLabel label={t`Longitude`} type="number" input={props.input} meta={props.meta} />
              }}
            </Field>
            {/*refers to COORDINATE_LONGITUDE*/}
            <FieldUnit>{c('Units').t`°E`}</FieldUnit>
          </SubField>
        </Flex>
      </FormValue>
    </FormKeyValueGrid>
  )
}

export default React.memo(LocationCoordinates)
