import { ROUTE_DEMO_RESOURCES, ROUTE_GIPS, ROUTE_LOGS, ROUTE_RUNNING_BACKCASTS } from 'modules/app/Routes'
import { getUserMenuItemData, UserMenuItemKeys } from 'utils/user'

export const LanguageKeys = {
  english: 'en',
  german: 'de',
} as const

export type LanguageKey = typeof LanguageKeys[keyof typeof LanguageKeys]

export const DocumentLinksEnum = {
  imprintEnglish: 'https://www.enercast.de/imprint/',
  imprintGerman: 'https://www.enercast.de/de/impressum/',
  termsOfUseEnglish: 'https://www.enercast.de/terms-and-conditions/',
  termsOfUseGerman: 'https://www.enercast.de/de/allgemeine-geschaeftsbedingungen/',
  privacyPolicyEnglish: 'https://www.enercast.de/privacy-policy/',
  privacyPolicyGerman: 'https://www.enercast.de/de/datenschutzerklaerung/',
}

export const getDocumentLinks = (langKey: string) => {
  const { english } = LanguageKeys
  const {
    imprintEnglish,
    imprintGerman,
    termsOfUseEnglish,
    termsOfUseGerman,
    privacyPolicyEnglish,
    privacyPolicyGerman,
  } = DocumentLinksEnum

  const links = {
    imprint: langKey === english ? imprintEnglish : imprintGerman,
    privacyPolicy: langKey === english ? privacyPolicyEnglish : privacyPolicyGerman,
    termsOfUse: langKey === english ? termsOfUseEnglish : termsOfUseGerman,
    releaseNotes: 'https://www.enercast.de/release-notes/',
  }

  return links
}

export const getDocumentMenuItems = (langKey: string) => {
  const links = getDocumentLinks(langKey)
  return [
    {
      icon: getUserMenuItemData(UserMenuItemKeys.RELEASE_NOTES).icon,
      label: getUserMenuItemData(UserMenuItemKeys.RELEASE_NOTES).label,
      key: UserMenuItemKeys.RELEASE_NOTES,
      link: links.releaseNotes,
    },
    {
      icon: getUserMenuItemData(UserMenuItemKeys.TERMS_AND_CONDITIONS).icon,
      label: getUserMenuItemData(UserMenuItemKeys.TERMS_AND_CONDITIONS).label,
      key: UserMenuItemKeys.TERMS_AND_CONDITIONS,
      link: links.termsOfUse,
    },
    {
      icon: getUserMenuItemData(UserMenuItemKeys.PRIVACY_POLICY).icon,
      label: getUserMenuItemData(UserMenuItemKeys.PRIVACY_POLICY).label,
      key: UserMenuItemKeys.PRIVACY_POLICY,
      link: links.privacyPolicy,
    },
    {
      icon: getUserMenuItemData(UserMenuItemKeys.IMPRINT).icon,
      label: getUserMenuItemData(UserMenuItemKeys.IMPRINT).label,
      key: UserMenuItemKeys.IMPRINT,
      link: links.imprint,
    },
  ]
}

export const getAdminHeaderMenuItems = () => {
  return [
    // {
    //   icon: getUserMenuItemData(UserMenuItemKeys.METRICS).icon,
    //   label: getUserMenuItemData(UserMenuItemKeys.METRICS).label,
    //   key: UserMenuItemKeys.METRICS,
    //   link: ROUTE_METRICS,
    // },
    // {
    //   icon: getUserMenuItemData(UserMenuItemKeys.HEALTH).icon,
    //   label: getUserMenuItemData(UserMenuItemKeys.HEALTH).label,
    //   key: UserMenuItemKeys.HEALTH,
    //   link: ROUTE_HEALTH,
    // },
    // {
    //   icon: getUserMenuItemData(UserMenuItemKeys.CONFIGURATION).icon,
    //   label: getUserMenuItemData(UserMenuItemKeys.CONFIGURATION).label,
    //   key: UserMenuItemKeys.CONFIGURATION,
    //   link: ROUTE_CONFIGURATION,
    // },
    // {
    // Audits is temporarily hidden. Route is also disabled.
    //   icon: getUserMenuItemData(UserMenuItemKeys.AUDITS).icon,
    //   label: getUserMenuItemData(UserMenuItemKeys.AUDITS).label,
    //   key: UserMenuItemKeys.AUDITS,
    //   link: ROUTE_AUDITS,
    // },
    {
      icon: getUserMenuItemData(UserMenuItemKeys.LOGS).icon,
      label: getUserMenuItemData(UserMenuItemKeys.LOGS).label,
      key: UserMenuItemKeys.LOGS,
      link: ROUTE_LOGS,
    },
    // {
    //   icon: getUserMenuItemData(UserMenuItemKeys.TRAINING).icon,
    //   label: getUserMenuItemData(UserMenuItemKeys.TRAINING).label,
    //   key: UserMenuItemKeys.TRAINING,
    //   link: ROUTE_TRAINING,
    // },
    {
      icon: getUserMenuItemData(UserMenuItemKeys.GIPS).icon,
      label: getUserMenuItemData(UserMenuItemKeys.GIPS).label,
      key: UserMenuItemKeys.GIPS,
      link: ROUTE_GIPS,
    },
    {
      icon: getUserMenuItemData(UserMenuItemKeys.RUNNING_BACKCASTS).icon,
      label: getUserMenuItemData(UserMenuItemKeys.RUNNING_BACKCASTS).label,
      key: UserMenuItemKeys.RUNNING_BACKCASTS,
      link: ROUTE_RUNNING_BACKCASTS,
    },
  ]
}

export const getDemoUserMenuItems = () => {
  return [
    {
      icon: getUserMenuItemData(UserMenuItemKeys.DEMO_RESOURCES).icon,
      label: getUserMenuItemData(UserMenuItemKeys.DEMO_RESOURCES).label,
      key: UserMenuItemKeys.DEMO_RESOURCES,
      link: ROUTE_DEMO_RESOURCES,
    },
  ]
}
