import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSelected, getSelectedPoint, getSelectedPoints, getSelectedRow } from '../selector/selectedSelector'
import { getCoords } from '../selector/coordsSelector'
import { getBbox } from '../selector/bboxSelector'
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api'
import Paper from '@material-ui/core/Paper'
import { getPoints } from '../selector/pointsSelector'
import { SET_SELECTED_POINT } from '../actions/selectedActions'
import { getBBox, plotShape } from 'utils/map'

const options = {
  center: {
    lat: 50,
    lng: 10,
  },
  zoom: 1,
}

export default function ShapeMap() {
  const selectedShapes = useSelector(getSelected)
  const selectedRow = useSelector(getSelectedRow)
  const pointList = useSelector(getPoints)
  const selectedPoints = useSelector(getSelectedPoints)
  const selectedPoint = useSelector(getSelectedPoint)
  const coords = useSelector(getCoords)
  const bounds = useSelector(getBbox)
  const dispatch = useDispatch()

  const setSelectedPoint = (event, key) => {
    if (event.target.tagName === key) {
      let point = '-1'
      if (selectedPoint !== key) point = key
      dispatch({ type: SET_SELECTED_POINT, point })
    }
  }

  const plotPoint = (key, clusterer) => {
    return (
      <Marker
        onClick={(event) => setSelectedPoint(event, key)}
        key={key}
        position={{ lat: pointList[key][0], lng: pointList[key][1] }}
        title={key + ': [' + pointList[key][0] + ':' + pointList[key][1] + ']'}
        clusterer={clusterer}
      />
    )
  }

  const getFocus = () => {
    return selectedPoint !== '-1'
      ? {
          lat: pointList[selectedPoint][0],
          lng: pointList[selectedPoint][1],
        }
      : options.center
  }

  const getZoom = () => {
    return selectedPoint !== '-1' ? 6 : options.zoom
  }

  const tempMarker = () => {
    if (selectedPoint !== '-1' && selectedPoints && !selectedPoints.includes(selectedPoint))
      return (
        <Marker
          key="temp"
          position={{ lat: pointList[selectedPoint][0], lng: pointList[selectedPoint][1] }}
          title={'Point [' + pointList[selectedPoint][0] + ':' + pointList[selectedPoint][1] + ']'}
        />
      )
  }

  return (
    <Paper className={'googleMap'}>
      {/*{console.log("center: {" + options.center.lat + ", " + options.center.lng + "}")}*/}
      <GoogleMap id="map" mapContainerStyle={{ height: '100%', width: '100%' }} zoom={getZoom()} center={getFocus()}>
        {tempMarker()}
        {selectedShapes
          .filter((uuid) => coords[uuid])
          .map((uuid) => {
            return plotShape(coords[uuid], selectedRow)
          })}
        {selectedRow !== '-1' && bounds[selectedRow] && getBBox(bounds[selectedRow].coords)}

        {selectedRow !== '-1' && bounds[selectedRow] && bounds[selectedRow].center && (
          <Marker
            position={{ lat: bounds[selectedRow].center.lat, lng: bounds[selectedRow].center.lng }}
            title={'Center [' + bounds[selectedRow].center.lat + ':' + bounds[selectedRow].center.lng + ']'}
          />
        )}
        <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
          {(clusterer) =>
            selectedPoints
              .filter((key) => pointList[key])
              .map((key) => {
                return plotPoint(key, clusterer)
              })
          }
        </MarkerClusterer>
      </GoogleMap>
    </Paper>
  )
}
