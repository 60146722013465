import { AnyAction, combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { put, race, take } from 'redux-saga/effects'

import { WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import createReducer from 'utils/createReducer'
import { createSelector } from 'reselect'
import {
  DELETE_USERCONFIG_FAILURE,
  DELETE_USERCONFIG_REQUEST,
  DELETE_USERCONFIG_SUCCESS,
} from 'modules/auth/redux_store/auth.action.types'
import { GET_WORKSPACE_CONFIGS_REQUEST } from 'modules/workspace/store/getWorkspaceConfigs.state'

// state

interface State {
  configs: WorkspaceConfig[]
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  configs: [],
  loading: false,
  error: null,
  initialized: false,
}

// types

export const DELETE_WORKSPACE_CONFIGS_REQUEST = 'DELETE_WORKSPACE_CONFIGS_REQUEST'
export const DELETE_WORKSPACE_CONFIGS_SUCCESS = 'DELETE_WORKSPACE_CONFIGS_SUCCESS'
export const DELETE_WORKSPACE_CONFIGS_FAILURE = 'DELETE_WORKSPACE_CONFIGS_FAILURE'
export const DELETE_WORKSPACE_CONFIGS_DISMISS = 'DELETE_WORKSPACE_CONFIGS_DISMISS'
export type DELETE_WORKSPACE_CONFIGS_REQUEST = typeof DELETE_WORKSPACE_CONFIGS_REQUEST
export type DELETE_WORKSPACE_CONFIGS_SUCCESS = typeof DELETE_WORKSPACE_CONFIGS_SUCCESS
export type DELETE_WORKSPACE_CONFIGS_FAILURE = typeof DELETE_WORKSPACE_CONFIGS_FAILURE
export type DELETE_WORKSPACE_CONFIGS_DISMISS = typeof DELETE_WORKSPACE_CONFIGS_DISMISS
export type DELETE_WORKSPACE_CONFIGS_ACTION_TYPE =
  | DELETE_WORKSPACE_CONFIGS_REQUEST
  | DELETE_WORKSPACE_CONFIGS_SUCCESS
  | DELETE_WORKSPACE_CONFIGS_FAILURE
  | DELETE_WORKSPACE_CONFIGS_DISMISS

interface ConfigAction {
  type: DELETE_WORKSPACE_CONFIGS_ACTION_TYPE
  // REQUEST
  config: WorkspaceConfig
  // ERROR
  error: State['error']
}

// reducers

const loading = createReducer<State['loading'], ConfigAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case DELETE_WORKSPACE_CONFIGS_REQUEST:
      return true
    case DELETE_WORKSPACE_CONFIGS_SUCCESS:
    case DELETE_WORKSPACE_CONFIGS_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], ConfigAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case DELETE_WORKSPACE_CONFIGS_SUCCESS:
    case DELETE_WORKSPACE_CONFIGS_DISMISS:
      return null
    case DELETE_WORKSPACE_CONFIGS_FAILURE:
      return error
  }
  return state
})

const initialized = createReducer<State['initialized'], ConfigAction>((state = initialState.initialized, { type }) => {
  switch (type) {
    case DELETE_WORKSPACE_CONFIGS_SUCCESS:
    case DELETE_WORKSPACE_CONFIGS_FAILURE:
      return true
  }
  return state
})

export const deleteWorkspaceConfigsReducer = combineReducers({
  loading,
  error,
  initialized,
})

// selectors

export const deleteWorkspaceConfigsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.workspace.deleteWorkspaceConfigs.loading,
  (loading) => loading,
)
export const deleteWorkspaceConfigsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.workspace.deleteWorkspaceConfigs.error,
  (error) => error,
)
export const deleteWorkspaceConfigsInitializedSelector = createSelector<
  any,
  State['initialized'],
  State['initialized']
>(
  (state) => state.workspace.deleteWorkspaceConfigs.initialized,
  (initialized) => initialized,
)

// api
// -> handled in auth / userConfig

// sagas
const collectionId = 'workspaceConfigs'

export function* deleteWorkspaceConfigSaga({ config }: ConfigAction): SagaIterator {
  if (!config.id) return

  yield put({
    type: DELETE_USERCONFIG_REQUEST,
    collectionId,
    configId: config.id,
  })

  const { success, failure } = yield race({
    success: take(
      (action: AnyAction) =>
        action.type === DELETE_USERCONFIG_SUCCESS &&
        action.collectionId === collectionId &&
        action.configId === config.id,
    ),
    failure: take(
      (action: AnyAction) =>
        action.type === DELETE_USERCONFIG_FAILURE &&
        action.collectionId === collectionId &&
        action.configId === config.id,
    ),
  })

  if (success) {
    yield put({ type: DELETE_WORKSPACE_CONFIGS_SUCCESS })
    yield put({ type: GET_WORKSPACE_CONFIGS_REQUEST })
  } else if (failure) {
    yield put({
      type: DELETE_WORKSPACE_CONFIGS_FAILURE,
      error: failure.error,
    })
  }
}
