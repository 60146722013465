import React, { ReactNode } from 'react'

interface ConditionalWrapperProps {
  condition: boolean
  wrapper: (children: ReactNode) => ReactNode
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

export default ConditionalWrapper
