import React, { useMemo } from 'react'
import { Box, Chip } from '@material-ui/core'
import ActivationState from 'modules/forecastModels/ActivationState'
import { Asset } from 'modules/asset/store/asset.types'
import WeatherModelEnsembleBar from 'modules/forecastModels/WeatherModelEnsembleBar'
import Flex from 'ui/styles/Flex'
import { ForecastModel } from 'modules/quality/quality.types'
import { User } from 'modules/auth/Auth.types'
import { t } from 'ttag'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { ForecastModelCategoryEnum } from 'modules/forecastModels/forecastModels.types'

interface ModelMetaDataProps {
  forecastModel: ForecastModel
  asset: Partial<Asset>
  user: User
}

const ForecastModelRowMetaData: React.FC<ModelMetaDataProps> = ({ forecastModel, asset }) => {
  const forecastConfigs = useSiteForecastConfigs()

  let weatherModelConfiguration =
    forecastModel.category === ForecastModelCategoryEnum.META
      ? forecastModel?.parameter?.StaticWeightsMetaForecastAlgorithm
      : forecastModel?.parameter?.weatherModelConfiguration || forecastModel?.parameter?.weatherPoints

  if (weatherModelConfiguration && typeof weatherModelConfiguration === 'string') {
    weatherModelConfiguration = JSON.parse(weatherModelConfiguration)
  }

  const weatherModels = useMemo(() => {
    if (forecastModel.category === ForecastModelCategoryEnum.META) {
      weatherModelConfiguration = JSON.parse(weatherModelConfiguration.inputWeights) || []
    }

    const handleSourceIdName = (sourceId: string) => {
      if (sourceId && forecastConfigs.data && forecastConfigs.data.length > 0) {
        return forecastConfigs.data.find((confg) => {
          return confg.id === sourceId
        })?.name
      }
      return ''
    }

    const dynamicWeatherModels =
      weatherModelConfiguration.length > 0
        ? weatherModelConfiguration
            .filter((config) => config.weight)
            .map((config) => ({
              name: config?.model || config?.weatherModel || handleSourceIdName(config?.sourceId),
              weight: config?.weight,
            }))
        : []
    return dynamicWeatherModels
  }, [weatherModelConfiguration, forecastConfigs.data])

  return (
    <Flex alignItems="center">
      <Box width="12em">{<WeatherModelEnsembleBar weatherModels={weatherModels} />}</Box>

      {forecastModel && (
        <Box width={'11em'}>
          <ActivationState asset={asset as Asset} forecastModel={forecastModel} />
          {forecastModel.version === 'V2' && forecastModel?.parameter?.realtime === 'true' && (
            <Chip size="small" label={t`Real time`} />
          )}
        </Box>
      )}
    </Flex>
  )
}

export default ForecastModelRowMetaData
