import axios from 'axios'
import { diff } from 'deep-diff'
import { QUERY_KEY_ASSETS, useAllAssetsOfStage } from 'modules/asset/api/assets.api'
import { ManufacturerModel } from 'modules/asset/assetCrud/asset.crud.types'
import { Asset, Coordinate } from 'modules/asset/store/asset.types'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { GIPS_API_URL } from 'modules/gips/helper/FetchCalls'
import { Operations, Requirements, ShapeCollectionType, ShapeInfoWrapper } from 'modules/gips/helper/Gips.types'
import { selectedStageSelector } from 'pages/workbench/store/revision.state'
import { REVISION_SET_STAGE } from 'pages/workbench/store/workbench.action.types'
import { Draft, STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { useMemo } from 'react'
import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'

import { apiRequest, ErrorData, Result } from 'utils/request'
import { QUERY_KEY_FORECAST_MODELS_BY_ASSETS } from 'modules/quality/quality.api'
import {
  QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE,
  QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE,
} from 'modules/data/penalties/penaltyCalculations/api/penaltyCalculation.api'
import { QUERY_KEY_BACK_CAST_TIMESERIES } from 'modules/dataStreams/api/timeseries.api'
import {
  workspaceDraftChartDataRangeSelector,
  workspaceDraftDataStreamSelectionSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { getInclusiveDateRangeFromChartDataRange } from 'utils/date'

// Query keys for caching data

export const QUERY_KEY_ASSETS_MANUFACTURERS = 'assets:manufacturers'
export const QUERY_KEY_ASSETS_DRAFT = 'assets:draft'
export const QUERY_KEY_ASSETS_SHAPES = 'assets:shapes'

// types

export enum DraftRevisionAction {
  ACTIVATE = 'ACTIVATE',
  DISCARD = 'DISCARD',
}

// API: asset additional data

export const getManufacturerModels = async () => {
  return await apiRequest<ManufacturerModel[]>(() => axios.get('/api/turbineinfo'))
}

export const getAssetWeatherCoordinates = async (coords: Coordinate) => {
  return await apiRequest(() =>
    axios.get(`api/weather-data-catalog/v1/utils/NWPGridPoints/${coords.latitude}/${coords.longitude}`),
  )
}

// API: drafts and stages

export const getHasDraft = async () => {
  return await apiRequest<boolean>(() =>
    axios.get(`/api/v1/revision/hasdraftrevision`, {
      transformResponse: (data) => {
        return data.toLowerCase() === 'true'
      },
    }),
  )
}

export const getDraft = async () => {
  return await apiRequest<Draft>(() => axios.get(`/api/v1/revision/draftrevision`))
}

export const createDraft = async () => {
  return await apiRequest<Draft>(() => axios.post(`/api/v1/revision/createdraft`, {}))
}

export const deleteDraft = async (revisionId?: number) => {
  if (!revisionId) return new Promise<Draft>((resolve) => resolve())
  return await apiRequest<Draft>(() =>
    axios.delete(`/api/v1/revision/deletedraft?revisionId=${revisionId.toString()}`, {}),
  )
}

export const activateDraft = async (forceActivation?: boolean, draft?: Draft) => {
  if (!draft) return new Promise<Draft>((resolve) => resolve())
  const url = `/api/v1/revision/activate${forceActivation ? `?force=true` : ''}`
  return await apiRequest<Draft>(() => axios.put(url, draft))
}

// API: shapes

export const getShapes = async (username?: string) => {
  const shapeInfo = {
    customerID: username,
    type: ShapeCollectionType[ShapeCollectionType.ASSET],
  }
  return await apiRequest<ShapeInfoWrapper[]>(() => axios.post(GIPS_API_URL + '/shape/search/list', shapeInfo))
}

export const updateAssetShape = async (req: Requirements) => {
  if (req.ops === Operations.update)
    return await apiRequest<ShapeInfoWrapper>(() => axios.post(`/api/gips/v1/shape/create/JSON`, req.gjson))
  else if (req.ops === Operations.delete)
    return await apiRequest<ShapeInfoWrapper>(() => axios.get(`/api/gips/v1/shape/delete?uuid=${req.uuid}`))
  return new Promise<Result<ShapeInfoWrapper>>((resolve) => resolve())
}

// Hooks to fetch and update via react-query

export const useManufacturerModels = () => {
  return useQuery<ManufacturerModel[]>(QUERY_KEY_ASSETS_MANUFACTURERS, getManufacturerModels)
}

export const useManufacturers = () => {
  const manufacturerModels = useManufacturerModels()

  const manufacturersData = useMemo(() => {
    let manufacturers: string[] = []
    ;(manufacturerModels.data || []).map((model) => {
      // Check for duplicate entries and then push the value
      if (!manufacturers.includes(model.manufacturer)) {
        manufacturers = [...manufacturers, model.manufacturer]
      }
    })
    manufacturers.sort()
    return manufacturers
  }, [manufacturerModels.data])

  const manufacturers = { ...manufacturerModels } as QueryObserverResult<string[]>
  manufacturers.data = manufacturersData
  return manufacturers
}

export const useHasDraft = () => {
  return useQuery<boolean>([QUERY_KEY_ASSETS_DRAFT, 'has-draft'], getHasDraft)
}

export const useGetDraft = () => {
  return useQuery<Draft>([QUERY_KEY_ASSETS_DRAFT, 'revision'], getDraft)
}

export const useCreateDraftMutation = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  return useMutation<Draft, ErrorData>(createDraft, {
    onMutate: () => {
      dispatch({ type: REVISION_SET_STAGE, stage: STAGE_DRAFT })
    },
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_DRAFT)
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_SHAPES)
      queryClient.invalidateQueries(QUERY_KEY_ASSETS)
    },
  })
}

export const useActivateDraftMutation = () => {
  // const dispatch = useDispatch()
  const draftAssets = useAllAssetsOfStage(STAGE_DRAFT)
  const queryClient = useQueryClient()
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const draft = useGetDraft()
  const chartDataRange = useSelector(workspaceDraftChartDataRangeSelector)
  const timezone = useSelector(getUserTimezoneSelector)
  const range = getInclusiveDateRangeFromChartDataRange(chartDataRange, timezone)

  return useMutation<Draft, ErrorData>((forceActivation) => activateDraft(forceActivation, draft.data), {
    onMutate: () => {
      queryClient.setQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE], () => draftAssets.data || [])
    },
    onSettled: () => {
      const modifiedAssets = (draftAssets.data || [])?.filter((asset) => asset.changed)

      ;(modifiedAssets || []).forEach((asset) => {
        queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
        // Remove the Backcast timeseries for Assets which are changed
        selectedDataStreams.forEach((dataStream) => {
          queryClient.setQueryData(
            [
              QUERY_KEY_BACK_CAST_TIMESERIES,
              dataStream.type,
              dataStream.subType,
              dataStream.classifier,
              dataStream.id,
              asset.id,
              null,
              range,
            ],
            () => {
              return {}
            },
          )
        })
      })
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_DRAFT)
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_SHAPES)
      queryClient.invalidateQueries(QUERY_KEY_ASSETS)
      // We are removing the following queries because we need to clear the cache to get the latest calculation when an asset is updated.
      queryClient.removeQueries(QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE)
      queryClient.removeQueries(QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE)
    },
  })
}

export const useDeleteDraftMutation = () => {
  const queryClient = useQueryClient()
  const draft = useGetDraft()
  const revisionId = draft.data?.id
  return useMutation<Draft, ErrorData>(() => deleteDraft(revisionId), {
    onSettled: () => {
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_DRAFT)
      queryClient.invalidateQueries(QUERY_KEY_ASSETS_SHAPES)
      queryClient.invalidateQueries(QUERY_KEY_ASSETS)
    },
  })
}

export const useDraftChangeCounts = () => {
  const draftAssets = useAllAssetsOfStage(STAGE_DRAFT)
  const modifiedAssets = (draftAssets.data || []).filter((asset) => asset.changed || asset.deleted)

  return useMemo(() => {
    const deletedCount = modifiedAssets.filter((asset) => asset.deleted).length
    const changedCount = modifiedAssets.length - deletedCount
    const pendingCount = changedCount + deletedCount

    return {
      deletedCount,
      changedCount,
      pendingCount,
    }
  }, [modifiedAssets])
}

export const useDraftChanges = () => {
  // TODO this is proposal for a new hook that shows the ACTUAL changes,
  //  not only the count of changed and deleted assets.
  //  that would be great so that we can show in UI what has changes
  //  1. in asset details, highlight form fields that are different,
  //     maybe with tooltip of what the previous value is
  //  2. in asset revision chooser provide a dialog that shows all changes

  const draftAssets = useAllAssetsOfStage(STAGE_DRAFT) // "modified"
  const productiveAssets = useAllAssetsOfStage(STAGE_PRODUCTIVE) // "active"

  const modifiedAssets = (draftAssets.data || []).filter((asset) => asset.changed || asset.deleted)

  return useMemo(() => {
    return modifiedAssets.map((modifiedAsset) => {
      const id = modifiedAsset.id
      const productiveAsset = (productiveAssets.data || []).find((asset) => asset.id === id)
      return { [id]: diff(productiveAsset, modifiedAsset) }
    })
  }, [modifiedAssets, productiveAssets.data])
}

export const useStage = () => {
  // fetch draft stage
  // if there is a draft, we need to fetch draft data
  // if there is none, we need to fetch productive data
  const hasDraft = useHasDraft()
  const automaticStage = hasDraft.data ? STAGE_DRAFT : STAGE_PRODUCTIVE

  // manually selected stage via asset revision chooser
  const selectedStage = useSelector(selectedStageSelector)
  const noSelectedStage = selectedStage === undefined || selectedStage === null

  // if user chose stage manually we use that
  // otherwise we show automatically chosen stage if it is available
  const stage = noSelectedStage || !hasDraft.data ? automaticStage : selectedStage
  return stage || STAGE_PRODUCTIVE
}

export const useShapes = () => {
  // fetch shapes
  const user = useSelector(getUserResultSelector)
  return useQuery<ShapeInfoWrapper[]>(QUERY_KEY_ASSETS_SHAPES, () => getShapes(user?.login))
}
