import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { AppTourSettings } from 'modules/app/tour/appTour.types'
import { useMemo } from 'react'

export const QUERY_KEY_APP_TOUR_SETTINGS = 'appTour:settings'

export const useAppTourSettings = () => {
  const userSettings = useUserSetting<AppTourSettings>(QUERY_KEY_APP_TOUR_SETTINGS)
  return useMemo(() => {
    const data: AppTourSettings = {
      // default settings if not set by user
      enableTour: false,
      // user settings
      ...(userSettings.data || {}),
    }
    return {
      ...userSettings,
      data,
    }
  }, [userSettings])
}

export const useAppTourSettingsSaveMutation = () => {
  return useUserSettingSaveMutation<AppTourSettings>(QUERY_KEY_APP_TOUR_SETTINGS)
}
