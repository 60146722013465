import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { c, jt, msgid, ngettext, t } from 'ttag'
import {
  PenaltyBlockIssue,
  PenaltyResultStatus,
} from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import { MailAddresses } from 'ui/elements/MailLink'
import { thousandSeparator } from 'utils/dataFormatting'

export enum PenaltyCalculationColumnType {
  UNDER_INJECTION = 'underInjectionColumn',
  OVER_INJECTION = 'overInjectionColumn',
  TOTAL_PENALTY_COST = 'totalPenaltyCost',
}

export const getPenaltyCalculationsTableColumns: () => Column[] = () => [
  {
    name: 'status',
    label: t`Status`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: false,
    width: '4em',
    align: 'left',
  },
  {
    name: 'assetId',
    label: c('Workbench:Penalty').t`Asset`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: true, // only one column can be primary column
    searchable: true,
    sortable: false,
    width: '16em',
    fixed: true,
  },
  {
    name: 'nameplateCapacity',
    label: c('Workbench:Penalty').t`Nameplate Capacity (kW)`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    primaryColumn: false,
    searchable: true,
    sortable: false,
    width: '12em',
  },
  {
    name: 'actualGeneration',
    label: t`Generation (kWh)`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '9em',
  },
  {
    name: 'overInjectionPenaltyCost',
    label: t`DSM Penalty` + ' (OI)',
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '9em',
    helperValue: PenaltyCalculationColumnType.OVER_INJECTION,
  },
  // Even though it has same label `DSM Penalty` as above these will be appended with OI or UI in the components it was used

  {
    name: 'underInjectionPenaltyCost',
    label: t`DSM Penalty` + ' (UI)',
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '9em',
    helperValue: PenaltyCalculationColumnType.UNDER_INJECTION,
  },

  {
    name: 'totalPenaltyCost',
    label: t`Total DSM Penalty`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '9.5em',
    helperValue: PenaltyCalculationColumnType.TOTAL_PENALTY_COST,
  },
  {
    name: 'overInjectionPenaltyCostNormalized',
    label: t`Paise/kWh` + ' (OI)',
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '7.5em',
    helperValue: PenaltyCalculationColumnType.OVER_INJECTION,
  },
  // Even though it has same label `Paise/kWh` as above these will be appended with OI or UI in the components it was used
  {
    name: 'underInjectionPenaltyCostNormalized',
    label: t`Paise/kWh` + ' (UI)',
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '7.5em',
    helperValue: PenaltyCalculationColumnType.UNDER_INJECTION,
  },

  {
    name: 'totalPenaltyCostNormalized',
    label: t`Total Paise/kWh`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '8em',
    helperValue: PenaltyCalculationColumnType.TOTAL_PENALTY_COST,
  },
  {
    name: 'revenue',
    label: t`Revenue`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '10em',
  },
  {
    name: 'revenueImpact',
    label: t`Impact`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    sortable: false,
    width: '8em',
  },
  {
    name: 'realtimeDataCompleteness',
    label: t`Completeness`,
    cellRenderType: CellRenderType.NUMERIC,
    columnSortType: ColumnSortType.VALUE_WITH_UNIT,
    searchable: true,
    fixed: true,
    sortable: false,
    width: '9em',
  },
]

export const defaultSelectedColumnForPenaltyCalculationTable = (): string[] => {
  return ['status', 'assetId', 'actualGeneration', 'totalPenaltyCost', 'totalPenaltyCostNormalized', 'revenueImpact']
}

export const getPenaltyErrorMsgByStatus = (status: PenaltyResultStatus) => {
  switch (status) {
    case PenaltyResultStatus.ASSET_INCONSISTENCY:
      return c('Workbench:Quality').t`Penalty can't be calculated. Assets have conflicting penalty regulations.`
    case PenaltyResultStatus.ASSET_NOT_FOUND:
      return c('Workbench:Quality').t`Penalty can't be calculated. Asset not found.`
    case PenaltyResultStatus.ASSET_TYPE_NOT_SUPPORTED:
      return c('Workbench:Quality').t`Penalty can't be calculated. Asset type not supported.`
    case PenaltyResultStatus.CAPACITY_TIMESERIES_EMPTY:
      return c('Workbench:Quality').t`Penalty can't be calculated. No capacity data within the selected time range.`
    case PenaltyResultStatus.ACTUAL_DATA_TIMESERIES_EMPTY:
      return c('Workbench:Quality')
        .t`Penalty can't be calculated. No actual generation data within the selected time range.`
    case PenaltyResultStatus.FORECAST_NOT_FOUND:
      return c('Workbench:Quality').t`Penalty can't be calculated. Site Forecast configuration not found.`
    case PenaltyResultStatus.FORECAST_TIMESERIES_EMPTY:
      return c('Workbench:Quality').t`Penalty can't be calculated. No forecast data within the selected time range.`
    case PenaltyResultStatus.BACKCAST_TIMESERIES_EMPTY:
      return c('Workbench:Quality').t`Penalty can't be calculated. No backcast data within the selected time range.`
    case PenaltyResultStatus.INTERNAL_ERROR:
      return jt`Penalty can't be calculated. Please contact ${MailAddresses.support}.`
    case PenaltyResultStatus.NO_PENALTY_REGULATION_FOUND:
      return c('Workbench:Quality')
        .t`No penalty regulation found for asset or time range, select an appropriate regulation in asset details.`
    case PenaltyResultStatus.PARAM_MISSING:
      return c('Workbench:Quality').t`Penalty can't be calculated. Parameter is missing.`
    case PenaltyResultStatus.PPA_RATE_MISSING:
      return c('Workbench:Quality').t`Penalty can't be calculated. PPA Rate is missing.`
    case PenaltyResultStatus.SYNC_FAILED:
      return c('Workbench:Quality')
        .t`Required data streams are either not selected or empty. Please select Site Forecast, Actual Generation and available capacity.`
    case PenaltyResultStatus.PENALTY_TIMESERIES_VALUE_MISSING:
      return c('Workbench:Quality')
        .jt`The price timeseries data is currently delayed, please try again later. If the issue persists please contact ${MailAddresses.support}.`
    case PenaltyResultStatus.NO_PENALTY_REGULATION_VERSION_FOUND:
      return c('Workbench:Quality').t`No penalty regulation version found.`
    case PenaltyResultStatus.NO_PENALTY_REGULATION_RULE_SET_FOUND:
      return c('Workbench:Quality').t`No penalty regulation rule set found.`
    case PenaltyResultStatus.ASSET_GENERATOR_TYPE_INCONSISTENCY:
      return c('Workbench:Quality').t`The selected cluster does not have a type.`
    case PenaltyResultStatus.MISSING_DATA_FOR_CHILD_ASSET:
      return c('Workbench:Quality').t`One of the assets in this cluster has no data for the selected time range.`
    case PenaltyResultStatus.PENALTY_TIMESERIES_ID_MISSING:
      return c('Workbench:Quality')
        .t`The price data specified within the penalty regulation is not available. Please review the regulation and the price data stream referenced by it.`
    case PenaltyResultStatus.NO_PENALTY_REGULATION_FOUND_FOR_CHILD_ASSET:
      return c('Workbench:Quality').t`At least one of the cluster's assets doesn't have a penalty regulation.`
    case PenaltyResultStatus.DATA_RESOLUTION_DOES_NOT_MATCH_REGULATION:
      return c('Workbench:Quality')
        .t`The resolution of the processed data does not match the resolution specified in the regulation.`
    case PenaltyResultStatus.OK:
      return null
    default:
      return null
  }
}

export const getPenaltyBlockIssueErrorMsg = (issue: PenaltyBlockIssue, count: number) => {
  const countFormatted = thousandSeparator(count)
  switch (issue) {
    // Block issues
    case PenaltyBlockIssue.PENALTIES_TIMESERIES_NOT_AVAILABLE:
      return ngettext(
        msgid`The price data specified within the penalty regulation is currently not available for ${countFormatted} block.`,
        `The price data specified within the penalty regulation is currently not available for ${countFormatted} blocks.`,
        count,
      )
    case PenaltyBlockIssue.FORECAST_DATA_NOT_AVAILABLE:
      return ngettext(
        msgid`Forecast data not available for ${countFormatted} block.`,
        `Forecast data not available for ${countFormatted} blocks.`,
        count,
      )
    case PenaltyBlockIssue.METER_DATA_NOT_AVAILABLE:
      return ngettext(
        msgid`Meter data not available for ${countFormatted} block.`,
        `Meter data not available for ${countFormatted} blocks.`,
        count,
      )
    case PenaltyBlockIssue.CAPACITY_DATA_NOT_AVAILABLE:
      return ngettext(
        msgid`Capacity data not available for ${countFormatted} block.`,
        `Capacity data not available for ${countFormatted} blocks.`,
        count,
      )
    default:
      return null
  }
}

export const getPenaltyBlockSingleIssueErrorMsg = (issue: PenaltyBlockIssue) => {
  switch (issue) {
    // Block issues
    case PenaltyBlockIssue.PENALTIES_TIMESERIES_NOT_AVAILABLE:
      return c('Workbench:Quality')
        .t`The price data specified within the penalty regulation is currently not available.`
    case PenaltyBlockIssue.FORECAST_DATA_NOT_AVAILABLE:
      return c('Workbench:Quality').t`Forecast data not available.`
    case PenaltyBlockIssue.METER_DATA_NOT_AVAILABLE:
      return c('Workbench:Quality').t`Meter data not available.`
    case PenaltyBlockIssue.CAPACITY_DATA_NOT_AVAILABLE:
      return c('Workbench:Quality').t`Capacity data not available.`
    default:
      return null
  }
}
