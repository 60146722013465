import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { sessionStatusChangedDateSelector } from 'modules/app/checkConnectivity.state'
import Alert from '@material-ui/lab/Alert'
import { tConnectivityAndBanners } from 'fixtures/commonTranslations'
import { Button } from '@material-ui/core'
import TimeAgo from 'ui/datetime/TimeAgo'
import { getReloadWindowQueryObj, useQueryString } from 'utils/query-string'

const SessionExpiredBanner: React.FC = () => {
  const { onUpdateQueryString } = useQueryString()

  const sessionStatusChangedDate = useSelector(sessionStatusChangedDateSelector)
  const sessionTimeAgo = <TimeAgo key="timeago" date={sessionStatusChangedDate} />
  const translations = useMemo(() => tConnectivityAndBanners(sessionTimeAgo), [sessionStatusChangedDate])

  const handleReload = useCallback(() => {
    onUpdateQueryString(getReloadWindowQueryObj())
  }, [onUpdateQueryString])

  return (
    <Alert
      action={
        <Button onClick={handleReload} size="small">
          {translations.actionReload}
        </Button>
      }
      severity="warning"
      variant="filled"
    >
      <div>{translations.textExpired}</div>
    </Alert>
  )
}

export default React.memo(SessionExpiredBanner)
