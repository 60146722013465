import { Box } from '@material-ui/core'
import { useAllAssets } from 'modules/asset/api/assets.api'

import AssetIcon from 'modules/asset/AssetIcon'
import { Asset } from 'modules/asset/store/asset.types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import LabelWithoutWrap from 'ui/LabelWithoutWrap'
import PopperTooltip, { PopperTooltipPosition } from 'ui/PopperTooltip'

import Flex from 'ui/styles/Flex'
import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'
import { getAncestors } from 'utils/asset'

const AssetName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

interface AssetHierarchyPopoverProps {
  asset: Asset
}

const AssetHierarchyPopover: React.FC<AssetHierarchyPopoverProps> = ({ asset, children }) => {
  const allAssets = useAllAssets()
  const ancestors = useMemo(() => {
    // TODO would be better to use uiAncestors but we can't since we set them to [] for availabilities so that asset rows do not collapse
    //  see: <SectionAvailability>
    const ancestorIds = getAncestors(asset, allAssets.data || []).map((a) => a.id)
    return (allAssets.data || []).filter((a) => ancestorIds.includes(a.id))
  }, [allAssets.data, asset])

  return ancestors.length > 0 ? (
    <PopperTooltip
      popperLabel={children}
      popperContent={
        <Flex direction="column">
          {ancestors.concat([asset]).map((a, index) => (
            <Box key={a.id} ml={a.uiLevel * 2} mb={index === ancestors.length ? 0 : 1}>
              <Flex direction="row">
                <Box mr={1}>
                  <AssetIcon asset={asset} size={AssetLinkIconSize} />
                </Box>
                <AssetName>
                  <LabelWithoutWrap>{a.name}</LabelWithoutWrap>
                </AssetName>
              </Flex>
            </Box>
          ))}
        </Flex>
      }
      position={PopperTooltipPosition.TOP_START}
    />
  ) : (
    children
  )
}

export default React.memo(AssetHierarchyPopover)
