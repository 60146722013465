import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import {
  testIdWorkspaceHeaderToolbar,
  testIdWorkspaceHeaderToolbarExportButton,
} from 'modules/workspace/header/WorkspaceHeader.ids'
import { seriesSelector } from 'modules/workspace/store/series.state'
import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { t } from 'ttag'
import CSVExportDialog from 'ui/elements/CSVExportDialog'
import LoadingButton from 'ui/form/LoadingButton'
import CreateBackCastCalculation from 'modules/workspace/header/backCast/CreateBackCastCalculation'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasPermissionForSiteAssessmentBackcast, hasPermissionToCreateSchedule } from 'utils/user'
import CreateScheduleButton from 'modules/workspace/schedule/CreateScheduleButton'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0.25em;
  flex-wrap: wrap;
  gap: 8px;
`

interface WorkspaceToolbarInterface {
  setTriggeredBackcastCreation: (value: boolean) => void
  isWorkbenchReadyWithSufficientData: boolean
}

const WorkspaceToolbar: React.FC<WorkspaceToolbarInterface> = ({
  setTriggeredBackcastCreation,
  isWorkbenchReadyWithSufficientData,
}) => {
  // const error = useSelector(saveWorkspaceConfigsErrorSelector)
  const user = useSelector(getUserResultSelector)
  const series = useSelector(seriesSelector)

  // console.log({ draft, unsavedChanges })

  // refresh
  // const queryClient = useQueryClient()
  // const isTimeSeriesFetching = useIsFetching({ queryKey: QUERY_KEY_TIMESERIES }) > 0
  // const isBackCastSeriesFetching = useIsFetching({ queryKey: QUERY_KEY_BACK_CAST_TIMESERIES }) > 0
  // const handleRefresh = useCallback((event: React.MouseEvent<HTMLElement>) => {
  //   event.stopPropagation()
  //   // re-fetch all time series
  //   queryClient.invalidateQueries(QUERY_KEY_TIMESERIES)
  //   queryClient.invalidateQueries(QUERY_KEY_BACK_CAST_TIMESERIES)
  //   // re-calculate penalty
  //   queryClient.invalidateQueries(QUERY_KEY_PENALTY_BYCONFIG_MULTIPLE)
  //   queryClient.invalidateQueries(QUERY_KEY_PENALTY_BACK_CAST_BYCONFIG_MULTIPLE)
  // }, [])

  // export actions
  const handleExportCSV = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setShowCSVExportDialog(true)
  }, [])

  // export dialog
  const [showCSVExportDialog, setShowCSVExportDialog] = useState(false)
  const handleCloseCSVExportDialog = useCallback(() => {
    setShowCSVExportDialog(false)
  }, [])

  const hasAccessToSiteAssessmentBackcast = useMemo(() => hasPermissionForSiteAssessmentBackcast(user), [user])
  const hasAccessToCreateScheduler = useMemo(() => hasPermissionToCreateSchedule(user), [user])

  return (
    <Container data-testid={testIdWorkspaceHeaderToolbar}>
      {hasAccessToCreateScheduler && (
        <CreateScheduleButton isWorkbenchReadyWithSufficientData={isWorkbenchReadyWithSufficientData} />
      )}

      {hasAccessToSiteAssessmentBackcast && (
        <CreateBackCastCalculation setTriggeredBackcastCreation={(value) => setTriggeredBackcastCreation(value)} />
      )}
      {/*<Box>*/}
      {/*  <LoadingButton*/}
      {/*    data-testid={testIdWorkspaceHeaderToolbarRefreshButton}*/}
      {/*    disabled={series.length === 0}*/}
      {/*    loading={isTimeSeriesFetching || isBackCastSeriesFetching}*/}
      {/*    variant="contained"*/}
      {/*    size="small"*/}
      {/*    startIcon={<FontAwesomeIcon icon="sync-alt" fixedWidth />}*/}
      {/*    onClick={handleRefresh}*/}
      {/*  >{t`Refresh`}</LoadingButton>*/}
      {/*</Box>*/}
      <Box mr={1}>
        <LoadingButton
          data-testid={testIdWorkspaceHeaderToolbarExportButton}
          disabled={series.length === 0}
          loading={false}
          variant="contained"
          size="small"
          startIcon={<FontAwesomeIcon icon="download" fixedWidth />}
          onClick={handleExportCSV}
        >{t`Export as CSV`}</LoadingButton>
      </Box>

      {showCSVExportDialog && <CSVExportDialog onClose={handleCloseCSVExportDialog} />}
    </Container>
  )
}

export default React.memo(WorkspaceToolbar)
