import React, { useCallback, useMemo } from 'react'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { UserManagementTableActions, UserManagementType, UserStatus } from 'modules/userManagement/userManagement.types'
import { Column, RETABLE_ID_USER_MANAGEMENT } from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import EnercastIdCell from 'modules/userManagement/enercastIdCell'
import { QUERY_USER_MANAGEMENT_DETAILS, useQueryParams } from 'utils/query-string'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import {
  currentRevisionToBase,
  resetRevisionToBase,
  synchronizeUser,
} from 'modules/userManagement/api/userManagement.api'
import RoleCell from 'modules/userManagement/RoleCell'
import Flex from 'ui/styles/Flex'
import Highlight from 'ui/Highlight'

interface StatusLabelProps {
  status: UserStatus
}

// width: 6em;
// text-align: center;
// font-size: small;
// border-radius: 7px;

const StatusLabel = styled.div<StatusLabelProps>`
  color: ${(props) => {
    if (props.status === UserStatus.DEACTIVATED || props.status === UserStatus.EXPIRED) {
      return 'grey'
    }
    return props.status === UserStatus.ACTIVATED ? theme.palette.primary.main : 'red'
  }};
`

interface UserManagementTableBodyProps {
  visibleItems: UserManagementType[]
  onEditItem: (data: UserManagementType) => void
  onDeleteItem: (data: UserManagementType) => void
  columns: Column[]
  tableHeaderHasActions: boolean
}

const UserManagementTableBody: React.FC<UserManagementTableBodyProps> = ({
  visibleItems,
  onEditItem,
  onDeleteItem,
  columns,
  tableHeaderHasActions,
}) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_USER_MANAGEMENT)
  const { queryParams } = useQueryParams()

  const currentlyDisplayedLoginId = useMemo(() => {
    let userId = ''
    if (queryParams[QUERY_USER_MANAGEMENT_DETAILS]) {
      userId = queryParams[QUERY_USER_MANAGEMENT_DETAILS]
    }
    return userId
  }, [queryParams[QUERY_USER_MANAGEMENT_DETAILS]])

  const handleOnClickRowItem = useCallback(
    (item: UserManagementType) => {
      if (currentlyDisplayedLoginId) {
        onEditItem(item)
      }
    },
    [currentlyDisplayedLoginId, onEditItem],
  )

  const handleClickMenuItem = useCallback((action, user: UserManagementType) => {
    const { SYNCHRONIZE, EDIT_USER, RESET_REVISION_TO_BASE, CURRENT_REVISION_TO_BASE } = UserManagementTableActions
    switch (action) {
      case SYNCHRONIZE:
        handleSynchronizeUser(user)
        break
      case EDIT_USER:
        onEditItem(user)
        break
      case RESET_REVISION_TO_BASE:
        handleResetRevisionToBase(user)
        break
      case CURRENT_REVISION_TO_BASE:
        handleCurrentRevisionToBase(user)
        break
    }
  }, [])

  const handleGetCustomCellComponent = useCallback(
    (column: Column, item: UserManagementType, query: string, isRowEdit: boolean, hovered) => {
      const currentlyDisplayed = currentlyDisplayedLoginId === item.login
      if (column.name === 'authorities') {
        return <RoleCell query={query} column={column} user={item} />
      } else if (column.name === 'uiUserStatusLabel') {
        return (
          <StatusLabel status={item.uiUserStatus}>
            <Highlight text={item.uiUserStatusLabel} query={query} />
          </StatusLabel>
        )
      } else if (column.name === 'id') {
        return (
          <Flex justifyContent="flex-end">
            <Highlight text={`${item.id}`} query={query} />
          </Flex>
        )
      } else if (column.name === 'login') {
        return (
          <EnercastIdCell
            user={item}
            query={query}
            hovered={hovered}
            currentlyDisplayed={currentlyDisplayed}
            column={column}
            onEditItem={onEditItem}
            onClickMenuItem={handleClickMenuItem}
          />
        )
      }
    },
    [currentlyDisplayedLoginId, onEditItem],
  )

  const handleSynchronizeUser = (rowItem: UserManagementType) => {
    synchronizeUser(rowItem)
  }

  const handleResetRevisionToBase = (rowItem: UserManagementType) => {
    if (confirm('Are you sure you want to reset this user to base revision?')) {
      resetRevisionToBase(rowItem)
        .then(() => {
          alert('ok')
        })
        .catch(() => {
          alert('failed')
        })
    }
  }

  const handleCurrentRevisionToBase = (rowItem: UserManagementType) => {
    if (confirm('Are you sure you want to set current revision to base revision?')) {
      currentRevisionToBase(rowItem)
        .then(() => {
          alert('ok')
        })
        .catch(() => {
          alert('failed')
        })
    }
  }

  return (
    <ReTableBody>
      {visibleItems.map((row: UserManagementType) => {
        return (
          <ReTableEditableRow
            onClickRowItem={handleOnClickRowItem}
            key={row.id}
            isRowEdit={false}
            isSelected={currentlyDisplayedLoginId === row.login}
            rowItem={row}
            columns={columns}
            onEditRowItem={onEditItem}
            onDeleteRowItem={onDeleteItem}
            getCustomCellComponent={handleGetCustomCellComponent}
            tableHeaderHasActions={tableHeaderHasActions}
            isShaded={row?.uiUserStatus === UserStatus.DEACTIVATED || row?.uiUserStatus === UserStatus.EXPIRED}
            query={query}
            showEditableRowActions={false}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(UserManagementTableBody)
