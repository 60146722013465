import React, { useEffect, useState } from 'react'
import { t } from 'ttag'
import { Typography } from '@material-ui/core'
import LayoutTitle from 'ui/LayoutTitle'
import { getListOfDemoResources } from 'modules/user/demoResources/api/DemoResources.api'
import CenteredLoading from 'ui/CenteredLoading'
import styled from 'styled-components'
import DetailsNotFound from 'ui/elements/DetailsNotFound'
import { getResourceDownloadLink } from 'utils/other'
import { useHistory } from 'react-router-dom'

const DemoResourceDiv = styled.div`
  padding-left: 1em;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
`

export interface DemoResourcesObject {
  [key: number]: string
}

const FOLDER_NAME = 'demo'

const DemoResources = () => {
  const [listOfDemoResources, setListOfDemoResources] = useState<DemoResourcesObject>({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const history = useHistory()

  useEffect(() => {
    setLoading(true)

    getListOfDemoResources()
      .then((response) => {
        setListOfDemoResources(response)
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false))
  }, [])

  const handleContent = () => {
    if (error) {
      return <DetailsNotFound message={t`Cannot load demo resources.`} onClose={() => history.goBack()} />
    } else {
      return (
        <DemoResourceDiv>
          <LayoutTitle icon="download">{t`Demo resources`}</LayoutTitle>
          <Typography>{t`List of demo resources:`}</Typography>
          <ul>
            {Object.keys(listOfDemoResources).map((resourceKey) => {
              return (
                <li key={resourceKey}>
                  <a href={getResourceDownloadLink(FOLDER_NAME, resourceKey)} download>
                    {listOfDemoResources[+resourceKey]}
                  </a>
                </li>
              )
            })}
          </ul>
        </DemoResourceDiv>
      )
    }
  }

  return <>{!loading ? handleContent() : <CenteredLoading size="5em" fullHeight />}</>
}

export default DemoResources
