export const GET_SUBSCRIBED_PRODUCT_CODES_REQUEST = 'GET_SUBSCRIBED_PRODUCT_CODES_REQUEST'
export const GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS = 'GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS'
export const GET_SUBSCRIBED_PRODUCT_CODES_FAILURE = 'GET_SUBSCRIBED_PRODUCT_CODES_FAILURE'
export const GET_SUBSCRIBED_PRODUCT_CODES_DISMISS = 'GET_SUBSCRIBED_PRODUCT_CODES_DISMISS'
export type GET_SUBSCRIBED_PRODUCT_CODES_REQUEST = typeof GET_SUBSCRIBED_PRODUCT_CODES_REQUEST
export type GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS = typeof GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS
export type GET_SUBSCRIBED_PRODUCT_CODES_FAILURE = typeof GET_SUBSCRIBED_PRODUCT_CODES_FAILURE
export type GET_SUBSCRIBED_PRODUCT_CODES_DISMISS = typeof GET_SUBSCRIBED_PRODUCT_CODES_DISMISS
export type GET_SUBSCRIBED_PRODUCT_CODES_ACTION_TYPE =
  | GET_SUBSCRIBED_PRODUCT_CODES_REQUEST
  | GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS
  | GET_SUBSCRIBED_PRODUCT_CODES_FAILURE
  | GET_SUBSCRIBED_PRODUCT_CODES_DISMISS
