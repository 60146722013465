import { Column, RETABLE_ID_LONG_RANGE_FORECAST, ReTableItem } from 'modules/reTable/reTable.types'
import { getSeasonalTableCellBackgroundColor, getSeasonalTableCellBorderColor } from 'utils/seasonalForecast'

interface GetPrintTableCellStyleProps {
  column: Column
  rowData: ReTableItem
}

export const getPrintTableCellBackgroundColor = ({ column, rowData }: GetPrintTableCellStyleProps): string => {
  const tableId = column.tableId
  let bgColor = ''

  switch (tableId) {
    case RETABLE_ID_LONG_RANGE_FORECAST:
      bgColor = getSeasonalTableCellBackgroundColor({ column, seasonalData: rowData })
      break
    default:
      bgColor = 'inherit'
  }

  return bgColor
}

export const getPrintTableCellBorderColor = ({ column, rowData }: GetPrintTableCellStyleProps): string => {
  const tableId = column.tableId
  let cellBorder = ''

  switch (tableId) {
    case RETABLE_ID_LONG_RANGE_FORECAST:
      cellBorder = getSeasonalTableCellBorderColor({ column, seasonalData: rowData })
      break
    default:
      cellBorder = ''
  }

  return cellBorder
}
