import React, { useCallback, useMemo, useRef } from 'react'
import { GoogleMap, GoogleMapProps, InfoWindow, Marker } from '@react-google-maps/api'
import { getBounds, getCenter } from 'geolib'
import deepmerge from 'deepmerge'

import { DEFAULT_LAT, DEFAULT_LNG } from 'fixtures/assetForm'
import { Asset, Coordinate, LocationAsset } from 'modules/asset/store/asset.types'
import {
  defaultLocation,
  getDescendants,
  getNomcap,
  isLocationAsset,
  useUniqueAllAssets,
  useUniqueSelectedAssets,
} from 'utils/asset'
import { getMarkerIcons, plotShape } from 'utils/map'
import AssetLink from 'modules/asset/AssetLink'
import {
  getAssetQueryObj,
  getAssetQueryStrings,
  QUERY_ASSET,
  useQueryMatch,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import { colors } from 'themes/theme-light'
import { GeolibInputCoordinates } from 'geolib/es/types'
import { googleFormatter } from 'modules/gips/helper/GoogleFormatHelper'
import { AppUnits } from 'utils/units'
import { Box } from '@material-ui/core'

interface GoogleLatLng {
  lat: number
  lng: number
}

const convertCoords = (coordinates: Coordinate): GoogleLatLng => {
  return {
    lat: coordinates.latitude || DEFAULT_LAT,
    lng: coordinates.longitude || DEFAULT_LNG,
  }
}

interface Bounds {
  minLat: number
  maxLat: number
  minLng: number
  maxLng: number
}

// const convertBoundsToZoom = (bounds: Bounds) => {
//   const GLOBE_WIDTH = 256 // a constant in Google's map projection
//   const west = bounds.minLng
//   const east = bounds.maxLng
//   let angle = east - west
//   if (angle < 0) {
//     angle += 360
//   }
//   const pixelWidth = 512
//   return Math.round(Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2) - 1
// }

//https://stackoverflow.com/questions/59158138/cant-use-fitbounds-on-react-google-map

const convertBoundsToZoom = (bounds: Bounds) => {
  const WORLD_DIM = { height: 256, width: 256 }
  const ZOOM_MAX = 21
  const mapDim = { height: 512, width: 512 }

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180)
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }

  const latFraction = (latRad(bounds.maxLat) - latRad(bounds.minLat)) / Math.PI

  const lngDiff = bounds.maxLng - bounds.minLng
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export interface WeatherCoordinate {
  position: GoogleLatLng
  provider: string
}

interface MapProps {
  mapLocation?: Coordinate
  mapZoom?: number
  assets?: LocationAsset[]
  onDragMarker?: (args: any) => void
  options: google.maps.MapOptions
  weatherCoordinates?: any[]
  showWeatherCoords?: boolean
  labelColor: string
}

const AssetGoogleMaps: React.FC<MapProps & GoogleMapProps> = ({
  mapLocation,
  mapZoom,
  assets = [],
  onDragMarker,
  options,
  // weatherCoordinates,
  // showWeatherCoords,
  // labelColor,
  ...rest
}) => {
  const refMap = useRef(null)
  let location: GoogleLatLng = { lat: 0, lng: 0 }
  let zoom = mapZoom || 6
  const allAssets = useUniqueAllAssets()
  const showMultiple = assets && assets.length > 0
  const selectedAssets = useUniqueSelectedAssets()
  const showSelected = selectedAssets && selectedAssets.length > 0

  if (!showSelected && !showMultiple && mapLocation && mapLocation.latitude && mapLocation.longitude) {
    location = convertCoords(mapLocation)
  } else if (showSelected || showMultiple) {
    let coordinates: Coordinate[]
    if (showSelected) {
      let allSelected = selectedAssets as Asset[]
      const nonLocationAssets = selectedAssets.filter((asset) => !isLocationAsset(asset))
      nonLocationAssets.forEach((asset) => {
        allSelected = allSelected.concat(getDescendants(asset, allAssets))
      })
      const locationAssets = allSelected.filter((asset) => isLocationAsset(asset)) as LocationAsset[]
      coordinates = locationAssets.map((asset) => asset.location.coordinate)
    } else {
      coordinates = assets.map((asset) => asset.location.coordinate)
    }
    const center = getCenter(coordinates as GeolibInputCoordinates[])
    const bounds = getBounds(coordinates)
    if (center) {
      location = convertCoords(center)
      zoom = convertBoundsToZoom(bounds)
    }
  } else if (!location.lat || !location.lng) {
    location = defaultLocation()
  }

  /*
        Weather Grip Points call
        api/weather-data-catalog/v1/utils/NWPGridPoints/${location.latitude}/${location.longitude}
        
    {showWeatherCoords &&
     weatherCoordinates &&
     weatherCoordinates.length > 0 &&
     (weatherCoordinates || []).map((coord, index) => (
    <Marker key={index} position={coord.position} title={coord.provider} />
    ))}
    */

  const defaultOptions: google.maps.MapOptions = { gestureHandling: 'greedy', streetViewControl: true }
  const mergedOptions: google.maps.MapOptions = deepmerge(defaultOptions, options || {})

  const icons = useMemo(getMarkerIcons, [])

  const [popupShown, setPopupShown] = React.useState<string[]>([])

  const toggleShown = (id: string) => {
    let newPopupShown
    if (popupShown.includes(id)) {
      newPopupShown = popupShown.filter((testId) => testId !== id)
    } else {
      // console.log('Popup with id ' + popupShown + ' hidden.')
      newPopupShown = [...popupShown, id]
    }
    setPopupShown(newPopupShown)
  }

  const { queryParams } = useQueryParams()
  const detailsOnScreen = useMemo(() => (isAssetDetails ? [queryParams[QUERY_ASSET]] : []), [queryParams])
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const isAddAsset = useQueryMatch(QUERY_ASSET, 'new')
  const isAssetDetails = useQueryMatch(QUERY_ASSET) && !isAddAsset

  const getAssetType = (assetType: string) => {
    if (assetType === 'pv')
      return (
        <div>
          <b>Type:</b> {'Solar'}
        </div>
      )
    else if (assetType === 'wind')
      return (
        <div>
          <b>Type:</b> {'Wind'}
        </div>
      )
    else
      return (
        <div>
          <b>Type:</b> {'Unknown'}
        </div>
      )
  }

  const handleToggleDetails = useCallback(
    (asset: Asset) => {
      if (detailsOnScreen.includes(asset.id)) {
        onDeleteQueryStrings(getAssetQueryStrings())
        // history.push(ROUTE_WORKBENCH)
      } else {
        // TODO support multiple assets
        onUpdateQueryString(getAssetQueryObj(asset, queryParams))
      }
    },
    [queryParams, detailsOnScreen, onDeleteQueryStrings, onUpdateQueryString],
  )

  // Rounding that way, because .round alone can be incorrect in some cases
  const getRoundedAssetCoordinates = (asset: LocationAsset) => {
    let lat = asset.location.coordinate.latitude
    let lng = asset.location.coordinate.longitude
    if (lat && lng) {
      lat = +(Math.round(Number(asset.location.coordinate.latitude + 'e+6')) + 'e-6')
      lng = +(Math.round(Number(asset.location.coordinate.longitude + 'e+6')) + 'e-6')
    }
    return lat + ',' + lng
  }

  const getAssetsWithSimilarCoordinates = useCallback(
    (asset: Asset) => {
      return assets.filter(
        (a) =>
          asset?.location?.coordinate?.latitude === a?.location?.coordinate?.latitude &&
          asset?.location?.coordinate?.longitude === a?.location?.coordinate?.longitude,
      )
    },
    [assets],
  )

  const getInfoWindowContent = useCallback(
    (asset: Asset) => {
      const assetsWithSimilarCoordinates = getAssetsWithSimilarCoordinates(asset)
      return (
        <>
          {assetsWithSimilarCoordinates.map((asset) => {
            return (
              <Box key={asset.id} mb={0.5}>
                <AssetLink asset={asset} color={colors.secondaryMain} onSelect={() => handleToggleDetails(asset)} />
                {getAssetType(asset.type)}
                <div>
                  <b>Location:</b> {getRoundedAssetCoordinates(asset)}
                </div>
                <div>
                  <b>Capacity:</b> {getNomcap(asset)}
                  <b>{AppUnits.KILO_WATT}</b>
                </div>
              </Box>
            )
          })}
        </>
      )
    },
    [getAssetsWithSimilarCoordinates],
  )

  const plot = (asset: LocationAsset) => {
    const assetPlot = []
    assetPlot.push(
      <Marker
        key={asset.id}
        icon={icons[asset.type]}
        position={convertCoords(asset.location.coordinate)}
        title={getAssetsWithSimilarCoordinates(asset)
          .map((a) => a.name)
          .join(', ')}
        onClick={() => toggleShown(asset.id)}
      >
        {popupShown.includes(asset.id) && (
          <InfoWindow onCloseClick={() => toggleShown(asset.id)}>{getInfoWindowContent(asset)}</InfoWindow>
        )}
      </Marker>,
    )

    if (asset.shape) {
      const shapeObj = { multiPolygon: JSON.parse(asset.shape) }
      const googleFormattedShape = googleFormatter(shapeObj)
      assetPlot.push(plotShape(googleFormattedShape, asset.id))
    }

    return assetPlot
  }

  return (
    <>
      <GoogleMap
        ref={refMap}
        mapContainerStyle={{ height: '100%', width: '100%' }}
        center={location}
        zoom={zoom}
        options={mergedOptions}
        {...rest}
      >
        {showMultiple ? (
          assets.map((asset) => {
            return plot(asset)
          })
        ) : (
          <Marker
            position={location}
            // icon={icons[assets.length > 0 ? assets[0].type : 'unknown']}
            draggable={Boolean(onDragMarker)}
            onDragEnd={onDragMarker}
          />
        )}
      </GoogleMap>
    </>
  )
}

export default React.memo(AssetGoogleMaps)
