import React from 'react'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { c } from 'ttag'
import styled from 'styled-components'
import { appTourColors } from 'modules/app/tour/OnBoardingTourDialog'
import { Column } from 'modules/reTable/reTable.types'
import { isAsset } from 'utils/asset'
import { Box } from '@material-ui/core'
import AssetLink from 'modules/asset/AssetLink'

import { Asset } from 'modules/asset/store/asset.types'

import { ReTableBody } from 'modules/reTable/ReTableBody'
import { QualityPreviewRowItem } from 'modules/workspace/advancedChartWidgets/quality/QualityPreviewTable'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import QualityPreviewTableRow from 'modules/workspace/advancedChartWidgets/quality/QualityPreviewTableRow'
import { theme } from 'themes/theme-light'

interface ErrorMessageProps {
  isMixerSelected?: boolean
}
// 21 is first column width + action button
const ErrorMessage = styled.div<ErrorMessageProps>`
  color: ${appTourColors.darkBlue};
  margin-left: ${(props) => (props.isMixerSelected ? '21em' : '1.7em')};
`

interface QualityPreviewTableBodyProps {
  hasForecastData: boolean
  hasMeterData: boolean
  columns: Column[]
  itemsToRender: QualityPreviewRowItem[]
  allAssets: Asset[]
  loading: boolean
  showMetaForecastWidget: boolean
}

const QualityPreviewTableBody: React.FC<QualityPreviewTableBodyProps> = ({
  hasForecastData,
  hasMeterData,
  columns,
  itemsToRender,
  showMetaForecastWidget,
}) => {
  const showTableRows = (hasMeterData || hasForecastData || showMetaForecastWidget) && itemsToRender.length > 0
  const forecastConfigs = useSiteForecastConfigs()

  const errorMessageText = (isMixerSelected: boolean) => {
    return (
      <>
        {!hasMeterData ? (
          <ErrorMessage isMixerSelected={isMixerSelected}>
            {c('Workbench:Quality')
              .t`Quality can't be calculated. No actual generation data within the selected time range.`}
          </ErrorMessage>
        ) : !hasForecastData ? (
          <ErrorMessage isMixerSelected={isMixerSelected}>
            {c('Workbench:Quality').t`Quality can't be calculated. No forecast data within the selected time range.`}
          </ErrorMessage>
        ) : null}
      </>
    )
  }

  const DataIsMissing: React.FC = () => (
    <ReTableRow>
      <ReTableCell colSpan={columns.length + 1}>{errorMessageText(false)}</ReTableCell>
    </ReTableRow>
  )

  return (
    <ReTableBody>
      {showTableRows ? (
        itemsToRender
          .filter((it) => it)
          .map((item) => {
            if (isAsset(item)) {
              // --------- Asset Row ---------
              return (
                <ReTableRow key={item.id}>
                  <ReTableCell colSpan={columns.length + 1}>
                    <Box ml={1} display="flex" flexDirection="row">
                      <AssetLink color={theme.palette.primary.main} asset={item} />
                      {errorMessageText(true)}
                    </Box>
                  </ReTableCell>
                </ReTableRow>
              )
            } else if (item !== null) {
              const key = JSON.stringify(item)
              return (
                <QualityPreviewTableRow
                  key={key}
                  qualityValues={item}
                  columns={columns}
                  forecastConfigs={forecastConfigs}
                />
              )
            }
          })
      ) : (
        <DataIsMissing />
      )}
    </ReTableBody>
  )
}

export default QualityPreviewTableBody
