import React from 'react'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface SaveButtonWithNextPrevOptionsProps {
  showNextPrevOptions: boolean
  onNextPrevItem: (nextItem: boolean) => void
  totalItems: number
}

const NextPreviousButtons: React.FC<SaveButtonWithNextPrevOptionsProps> = ({
  showNextPrevOptions,
  onNextPrevItem,
  totalItems,
}) => {
  return (
    <Flex direction="row" alignItems="center">
      {showNextPrevOptions && (
        <Box>
          <IconButton onClick={() => onNextPrevItem(false)} color="primary" disabled={totalItems < 2}>
            <FontAwesomeIcon icon="chevron-left" />
          </IconButton>
          <IconButton onClick={() => onNextPrevItem(true)} color="primary" disabled={totalItems < 2}>
            <FontAwesomeIcon icon="chevron-right" />
          </IconButton>
        </Box>
      )}
    </Flex>
  )
}

export default NextPreviousButtons
