import React from 'react'
import { AssetFormLabel, FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import { t } from 'ttag'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { useField } from 'react-final-form'
import styled from 'styled-components'
import { Box, Chip } from '@material-ui/core'

const ConfigChip = styled(Chip)`
  margin-right: 0.4em;
  margin-bottom: 0.2em;
  margin-top: 0.2em;
`

const SectionTargetUsedIn: React.FC = () => {
  const targetUsedIn = useField<ForecastConfig[]>('usedIn').input.value

  return (
    <FormKeyValueGrid>
      <FormKey>
        <AssetFormLabel>{t`Target used in`}:</AssetFormLabel>
      </FormKey>
      <FormValue>
        <Box>
          {(targetUsedIn || []).map((config) => (
            <ConfigChip color="primary" key={config.id} label={config?.name} />
          ))}
        </Box>
        {targetUsedIn.length < 1 && <>{t`This delivery target is not used.`}</>}
      </FormValue>
    </FormKeyValueGrid>
  )
}

export default React.memo(SectionTargetUsedIn)
