import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@material-ui/core'
import { t } from 'ttag'

import { getFileManagerTableColumns } from 'fixtures/fileManagerTable'
import { getMeterdataFilesResultSelector } from 'modules/data/fileManager/redux_store/state/getMeterdataFiles.state'
import { RETABLE_ID_FILEMANAGER, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import FileManagerTableRow from 'modules/data/fileManager/FileManagerTableRow'
import { GET_METERDATAFILES_REQUEST } from 'modules/data/fileManager/redux_store/fileManager.action.types'
import ReTable from 'modules/reTable/ReTable'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { table } from 'themes/theme-light'
import Flex from 'ui/styles/Flex'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { RETABLE_SET_COLUMNS_AVAILABLE, RETABLE_SET_SORT } from 'modules/reTable/redux_store/reTable.action.types'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import styled from 'styled-components'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import { MeterdataFile } from 'modules/dataStreams/dataStreams.types'
import FileManagerTableToolbar from 'modules/data/fileManager/FileManagerTableToolbar'
import { Asset, MeterdataSummary } from 'modules/asset/store/asset.types'
import { GET_METERDATA_SUMMARIES_REQUEST } from 'modules/asset/store/getMeterdataSummaries.state'

const FileManagerTableWrapper = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

interface FileManagerTableProps {
  assetsToBeDisplayed?: Asset[]
  summary?: MeterdataSummary
  toolTip?: boolean
  showSearchField?: boolean
}

const FileManagerTable: React.FC<FileManagerTableProps> = ({
  assetsToBeDisplayed,
  summary,
  toolTip,
  showSearchField,
}) => {
  const dispatch = useDispatch()
  const assetNames = (assetsToBeDisplayed || []).map((asset) => asset?.name)

  // row items
  const files = useSelector(getMeterdataFilesResultSelector)
  const [itemsToRender, setItemsToRender] = useState<MeterdataFile[]>([])

  const handleItemsToRenderChange = useCallback(
    (items: MeterdataFile[]) => {
      // if assetsToBeDisplayed is passed display Asset specific files or display all files
      const filteredItems =
        assetNames && assetNames?.length > 0
          ? items.filter((item) => assetNames.includes(item?.originalFileName.replace('.csv', '')))
          : items
      requestAnimationFrame(() => {
        setItemsToRender(filteredItems)
      })
    },
    [JSON.stringify(assetNames)],
  )

  // table functions

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: false,
      column: 'uploadTs',
    }),
    [],
  )

  const columnsAvailable = useMemo(getFileManagerTableColumns, [])
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_FILEMANAGER)

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_FILEMANAGER, columnsAvailable })
  }, [columnsAvailable])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_SORT,
      table: RETABLE_ID_FILEMANAGER,
      sort: {
        active: true,
        column: 'uploadTs',
        ascending: false,
      },
    })
  }, [])

  useEffect(() => {
    dispatch({ type: GET_METERDATAFILES_REQUEST })
  }, [])

  const totalFiles = useMemo(() => {
    return assetNames?.length
      ? files.filter((item) => assetNames.includes(item?.originalFileName.replace('.csv', '')))
      : files
  }, [files, JSON.stringify(assetNames)])

  useEffect(() => {
    if (itemsToRender?.length && assetsToBeDisplayed && assetsToBeDisplayed.length) {
      dispatch({
        type: GET_METERDATA_SUMMARIES_REQUEST,
        assets: assetsToBeDisplayed,
      })
    }
  }, [itemsToRender.length, JSON.stringify(assetsToBeDisplayed)])

  return (
    <Flex direction="column" fullHeight>
      {columnsSelected.length > 0 && (
        <FileManagerTableToolbar
          selectedAssetName={assetsToBeDisplayed?.[0].name}
          summary={summary}
          toolTip={toolTip}
          showSearchField={showSearchField}
        />
      )}

      <FileManagerTableWrapper
        id={RETABLE_ID_FILEMANAGER}
        itemHeight={table.rowHeight}
        items={totalFiles as ReTableItem[]}
        hasExtendedHeader={false}
        itemsPaddingHeader={1}
        itemsPaddingFooter={1}
        defaultSort={defaultSort}
        onItemsToRenderChange={handleItemsToRenderChange}
      >
        <ReTableHeader>
          <ReTableRow>
            {columnsSelected.map((col, index) => (
              <ReTableCell key={col.label} width={col.width}>
                <ConditionalWrapper
                  condition={index === 0}
                  wrapper={(children) => (
                    <Flex direction="row">
                      <ReTableHeaderActionsCell isHierarchical={false} inline={true} />
                      {children}
                    </Flex>
                  )}
                >
                  <ReTableHeaderWithSort column={col} />
                </ConditionalWrapper>
              </ReTableCell>
            ))}
          </ReTableRow>
        </ReTableHeader>
        <ReTableBody>
          {itemsToRender.length === 0 && (
            <ReTableRow>
              <ReTableCell colSpan={columnsSelected.length}>
                <Box mt={1}>{t`No files found.`}</Box>
              </ReTableCell>
            </ReTableRow>
          )}

          {itemsToRender.map((file) => {
            return <FileManagerTableRow columns={columnsSelected} key={file.uuid} file={file} />
          })}
        </ReTableBody>
      </FileManagerTableWrapper>
    </Flex>
  )
}

export default React.memo(FileManagerTable)
