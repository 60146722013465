import { E3Ensemble } from 'modules/dataStreams/dataStreams.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import createReducer from 'utils/createReducer'
import { createDefaultE3Ensemble } from 'utils/e3'

import { Asset } from './asset.types'

// state

interface State {
  e3Ensemble: E3Ensemble
  e3EnsembleByAsset: Record<string, E3Ensemble>
}

const initialState: State = {
  e3Ensemble: createDefaultE3Ensemble(),
  e3EnsembleByAsset: {},
}

// types

export const SET_E3_ENSEMBLE = 'SET_E3_ENSEMBLE'
export type SET_E3_ENSEMBLE = typeof SET_E3_ENSEMBLE
export type GET_E3_FORECASTS_ACTION_TYPE = SET_E3_ENSEMBLE

interface GetE3ForecastsAction {
  type: GET_E3_FORECASTS_ACTION_TYPE
  // REQUEST
  assets: Asset[]
  e3Ensemble: E3Ensemble
}

// reducers

const e3Ensemble = createReducer<State['e3Ensemble'], GetE3ForecastsAction>(
  (state = initialState.e3Ensemble, { type, e3Ensemble }) => {
    if (type === SET_E3_ENSEMBLE) {
      state = e3Ensemble
    }
    return state
  },
)

const e3EnsembleByAsset = createReducer<State['e3EnsembleByAsset'], GetE3ForecastsAction>(
  (state = initialState.e3EnsembleByAsset, { type, e3Ensemble, assets }) => {
    if (type === SET_E3_ENSEMBLE) {
      state = {
        ...state,
      }
      assets.forEach((asset) => {
        state[asset.id] = { ...e3Ensemble }
      })
    }
    return state
  },
)

export const e3MetaForecastReducer = combineReducers({
  e3Ensemble,
  e3EnsembleByAsset,
})

// selectors

export const e3MetaForecastEnsembleSelector = createSelector<any, State['e3Ensemble'], State['e3Ensemble']>(
  (state) => state.asset.e3MetaForecast.e3Ensemble,
  (e3Ensemble) => e3Ensemble,
)

export const e3MetaForecastEnsembleByAssetSelector = createSelector<
  any,
  State['e3EnsembleByAsset'],
  State['e3EnsembleByAsset']
>(
  (state) => state.asset.e3MetaForecast.e3EnsembleByAsset,
  (e3EnsembleByAsset) => e3EnsembleByAsset,
)

// api

// sagas
