import React, { useCallback, useMemo, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { FormApi, Mutator } from 'final-form'
import {
  BulkMeterDataCleansingFilterSettings,
  MDCAssetType,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import {
  BULK_FILTER_SETTINGS_SOLAR,
  BULK_FILTER_SETTINGS_WIND,
  getDefaultValuesForBulkMdcFilterSettings,
} from 'utils/meterDataCleansing'
import { genericFormSubscription } from 'utils/form'
import BulkMdcFilterSettingsForm from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMeterDataCleansingHeader/BulkMeterDataCleansingFilterSettings/BulkMdcFilterSettingsForm'
import {
  QUERY_KEY_PERSIST_ALL_CONFIGURATIONS,
  useDefaultMDCFilterSettings,
  useEnercastMDCFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { FilterSettingsContext } from 'modules/meterDataCleansing/BulkMeterDataCleansing/BulkMdc'
import { useQueryClient } from 'react-query'

let formReference: FormApi<BulkMeterDataCleansingFilterSettings>

interface BulkMdcFilterSettingsProps {
  onCloseFilterSettingsDialog: () => void
  linkToDefault: boolean
  handleLinkToDefaultChange: () => void
  windAssetsSelected: boolean
  solarAssetsSelected: boolean
}

const BulkMdcFilterSettings: React.FC<BulkMdcFilterSettingsProps> = ({
  onCloseFilterSettingsDialog,
  linkToDefault,
  handleLinkToDefaultChange,
  windAssetsSelected,
  solarAssetsSelected,
}) => {
  const queryClient = useQueryClient()
  const { triggerFilterSettings, setTriggerFilterSettings } = useContext(FilterSettingsContext)
  const [showConfirmation, setShowConfirmation] = useState(false)

  // Getting wind filters
  const defaultFilterSettingsWind = useDefaultMDCFilterSettings(MDCAssetType.WIND)
  const enercastFilterSettingsWind = useEnercastMDCFilterSettings(MDCAssetType.WIND)

  // Managing result from filters
  const {
    isLoading: windDefaultFilterSettingsIsLoading,
    isSuccess: windDefaultFilterSettingsIsSuccess,
    data: windDefaultFilterSettingsData,
  } = defaultFilterSettingsWind

  const {
    isLoading: windEnercastFilterSettingsIsLoading,
    isSuccess: windEnercastFilterSettingsIsSuccess,
    data: windEnercastFilterSettingsData,
  } = enercastFilterSettingsWind

  // Getting solar filters
  const defaultFilterSettingsSolar = useDefaultMDCFilterSettings(MDCAssetType.SOLAR)
  const enercastFilterSettingsSolar = useEnercastMDCFilterSettings(MDCAssetType.SOLAR)

  // Managing result from filters
  const {
    isLoading: solarDefaultFilterSettingsIsLoading,
    isSuccess: solarDefaultFilterSettingsIsSuccess,
    data: solarDefaultFilterSettingsData,
  } = defaultFilterSettingsSolar

  const {
    isLoading: solarEnercastFilterSettingsIsLoading,
    isSuccess: solarEnercastFilterSettingsIsSuccess,
    data: solarEnercastFilterSettingsData,
  } = enercastFilterSettingsSolar

  // Form subscription
  const formSubscription = useMemo(() => genericFormSubscription(), [])

  // 1. Form mutators
  const formMutators = useMemo<{
    [key: string]: Mutator<BulkMeterDataCleansingFilterSettings, Partial<BulkMeterDataCleansingFilterSettings>>
  }>(() => {
    return {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value)
      },
    }
  }, [])

  // 2. Handle form submit
  const handleFormSubmit = () => {
    if (!showConfirmation) {
      setShowConfirmation(true)
    } else {
      // close filter settings dialog
      onCloseFilterSettingsDialog()
      // we are passing formReference , to use it inside bulk
      if (formReference.getState().values.timePeriodForTraining) {
        delete formReference.getState().values['timePeriodForTraining']
      }
      queryClient.removeQueries(QUERY_KEY_PERSIST_ALL_CONFIGURATIONS)
      // Save filter settings in session storage
      const values = formReference.getState().values
      sessionStorage.setItem(BULK_FILTER_SETTINGS_WIND, JSON.stringify(values?.wind || {}))
      sessionStorage.setItem(BULK_FILTER_SETTINGS_SOLAR, JSON.stringify(values?.solar || {}))

      // Save filter settings in state , to trigger additional action
      setTriggerFilterSettings(formReference.getState().values)

      setShowConfirmation(false)
    }
  }

  // 3. Initial values
  // Priority is: defaultFilterSettings (part of user) , enercast, all 0
  // In case a filter is applied in bulk , than on this state we need to see trigger filters
  const initialMeterDataCleansingConfig: BulkMeterDataCleansingFilterSettings = useMemo(() => {
    let windFilterSettings = {} as MeterDataCleansingFilterSettings
    let solarFilterSettings = {} as MeterDataCleansingFilterSettings
    let initialConfig = { wind: windFilterSettings, solar: solarFilterSettings } as BulkMeterDataCleansingFilterSettings

    if (triggerFilterSettings && !linkToDefault) {
      initialConfig = triggerFilterSettings
    } else {
      // Wind Filter settings
      if (sessionStorage.getItem(BULK_FILTER_SETTINGS_WIND) && !linkToDefault) {
        // if the link to default is clicked we don't have to use this values from session
        windFilterSettings = JSON.parse(sessionStorage.getItem(BULK_FILTER_SETTINGS_WIND))
      } else {
        if (
          !windDefaultFilterSettingsIsLoading &&
          windDefaultFilterSettingsIsSuccess &&
          windDefaultFilterSettingsData
        ) {
          windFilterSettings = windDefaultFilterSettingsData
        } else if (
          !windEnercastFilterSettingsIsLoading &&
          windEnercastFilterSettingsIsSuccess &&
          windEnercastFilterSettingsData
        ) {
          windFilterSettings = windEnercastFilterSettingsData
        } else {
          windFilterSettings = getDefaultValuesForBulkMdcFilterSettings()
        }
      }

      // Solar Filter settings
      if (sessionStorage.getItem(BULK_FILTER_SETTINGS_SOLAR) && !linkToDefault) {
        // if the link to default is clicked we don't have to use this values from session
        solarFilterSettings = JSON.parse(sessionStorage.getItem(BULK_FILTER_SETTINGS_SOLAR))
      } else {
        if (
          !solarDefaultFilterSettingsIsLoading &&
          solarDefaultFilterSettingsIsSuccess &&
          solarDefaultFilterSettingsData
        ) {
          solarFilterSettings = solarDefaultFilterSettingsData
        } else if (
          !solarEnercastFilterSettingsIsLoading &&
          solarEnercastFilterSettingsIsSuccess &&
          solarEnercastFilterSettingsData
        ) {
          solarFilterSettings = solarEnercastFilterSettingsData
        } else {
          solarFilterSettings = getDefaultValuesForBulkMdcFilterSettings()
        }
      }
      initialConfig['solar'] = solarFilterSettings
      initialConfig['wind'] = windFilterSettings
    }

    return initialConfig
  }, [
    defaultFilterSettingsWind,
    enercastFilterSettingsWind,
    defaultFilterSettingsSolar,
    enercastFilterSettingsSolar,
    triggerFilterSettings,
    linkToDefault,
  ])

  // 5. Form render
  const formRender = useCallback(
    ({ form, handleSubmit }) => {
      formReference = form
      return (
        <BulkMdcFilterSettingsForm
          form={form}
          onFormSubmit={handleSubmit}
          defaultFilterSettings={initialMeterDataCleansingConfig}
          onCloseFilterSettingsDialog={onCloseFilterSettingsDialog}
          showConfirmation={showConfirmation}
          linkToDefault={linkToDefault}
          handleLinkToDefaultChange={handleLinkToDefaultChange}
          solarAssetsSelected={solarAssetsSelected}
          windAssetsSelected={windAssetsSelected}
        />
      )
    },
    [
      initialMeterDataCleansingConfig,
      onCloseFilterSettingsDialog,
      showConfirmation,
      linkToDefault,
      handleLinkToDefaultChange,
      windAssetsSelected,
      solarAssetsSelected,
    ],
  )

  return (
    <Form
      mutators={formMutators}
      onSubmit={handleFormSubmit}
      initialValues={initialMeterDataCleansingConfig}
      subscription={formSubscription}
      render={formRender}
    />
  )
}

export default BulkMdcFilterSettings
