import React from 'react'
import { Field } from 'react-final-form'

import DateWithTimezone from 'ui/elements/DateWithTimezone'
import { Timezone } from 'fixtures/timezones'
import { useSelector } from 'react-redux'
import { convertZonedTimeToUtc } from 'utils/date'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import styled from 'styled-components'
import { ColumnWidth } from 'modules/reTable/reTable.types'
import DateTimePickerFinalForm from 'ui/elements/DateTimePickerFinalForm'

interface DateContainerProps {
  width: ColumnWidth
}

const DateContainer = styled.div<DateContainerProps>`
  & .MuiIconButton-root {
    padding: 0;
  }
  width: 11.5em;
`

interface ReTableDateCellProps {
  isEdit: boolean
  label: string
  name?: string
  minDate: Date
  query: string
  dateInUserTimezone: string | Date
  timezone: Timezone | null
  fieldAutoFocus?: boolean
}

const ReTableDateCell: React.FC<ReTableDateCellProps> = ({
  isEdit,
  label = '',
  name = '',
  minDate,
  query,
  dateInUserTimezone,
  timezone,
  fieldAutoFocus,
}) => {
  const userTimezone = useSelector(getUserTimezoneSelector)

  const dateInUTC = dateInUserTimezone ? convertZonedTimeToUtc(dateInUserTimezone, userTimezone) : undefined
  return (
    <DateContainer>
      {isEdit ? (
        <Field
          name={name}
          defaultValue={dateInUserTimezone}
          component={DateTimePickerFinalForm}
          label={label}
          minDate={minDate}
          autoFocus={fieldAutoFocus}
        />
      ) : (
        <>{dateInUTC && <DateWithTimezone dateInUTC={dateInUTC} originalTimezone={timezone} query={query} />}</>
      )}
    </DateContainer>
  )
}

export default React.memo(ReTableDateCell)
