import { SET_URLPARAMS } from '../actions/urlParamsActions'

const initialState = { params: '' }

export default function urlParamsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_URLPARAMS:
      return action.params
    default:
      return state
  }
}
