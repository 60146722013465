import { Box } from '@material-ui/core'

import { QualityEvaluation, QualityOverviewItem } from 'modules/quality/quality.types'
import React from 'react'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { jt, t } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { Key, KeyValueGrid, Value } from 'ui/KeyValueGrid'
import Number from 'ui/Number'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'
import { formatDateShort, timeAgo } from 'utils/date'
import {
  formatErrorMeasurementValues,
  getErrorMeasurements,
  getPrimaryMeasurement,
  getPrimaryQualityValue,
  measurementsInPercentage,
} from 'utils/quality'

const ValueContainer = styled(Value)`
  text-align: right;
  margin-right: 6em;
`

// this belongs in an own file
interface EvaluationTooltipProps {
  evaluation: QualityEvaluation
}
const EvaluationTooltip: React.FC<EvaluationTooltipProps> = ({ evaluation }) => {
  const primary = getPrimaryMeasurement(evaluation)
  const fromDateText = <strong>{formatDateShort(evaluation.evaluationTimeRangeStart)}</strong>
  const toDateText = <strong>{formatDateShort(evaluation.evaluationTimeRangeEnd)}</strong>
  const measurementKeys = getErrorMeasurements(evaluation)
  const measurementValues = formatErrorMeasurementValues(evaluation)

  return (
    <>
      <Box mb={0.5}>
        {t`Calculated`} <strong>{timeAgo(evaluation.created)}</strong>
      </Box>
      <Box mb={1}>{jt`Evaluation date range from ${fromDateText} to ${toDateText}`}:</Box>
      <KeyValueGrid compact>
        {measurementKeys.map((errorMeasurement) => {
          return (
            <React.Fragment key={errorMeasurement}>
              <Key>{errorMeasurement === primary ? <strong>{errorMeasurement}</strong> : errorMeasurement}</Key>
              <ValueContainer>
                {errorMeasurement === primary ? (
                  <strong>
                    <Number
                      showFractionalPart={true}
                      unit={measurementsInPercentage.includes(errorMeasurement) ? '%' : '  '}
                      data={measurementValues[errorMeasurement]}
                      limit={3}
                    />
                  </strong>
                ) : (
                  <Number
                    unit={measurementsInPercentage.includes(errorMeasurement) ? '%' : '  '}
                    data={measurementValues[errorMeasurement]}
                    limit={3}
                  />
                  // limitDecimals(measurementValues[errorMeasurement])
                )}
              </ValueContainer>
            </React.Fragment>
          )
        })}
      </KeyValueGrid>
    </>
  )
}

const Result = styled.div`
  flex: 1;
`

interface EvaluationDataProps {
  overviewItem: QualityOverviewItem
  selected?: boolean
  highlight?: string
}

const EvaluationData: React.FC<EvaluationDataProps> = ({ overviewItem, selected = false, highlight = '', ...rest }) => {
  const last = overviewItem.last
  const active = overviewItem.active
  const candidate = overviewItem.candidate
  const valLast = getPrimaryQualityValue(last)
  const valActive = getPrimaryQualityValue(active)
  const valCandidate = getPrimaryQualityValue(candidate)

  return (
    <Flex direction="row" justifyContent="space-evenly" {...rest}>
      <Result>
        {last && valLast && (
          <PopperTooltip
            popperLabel={
              <Flex justifyContent="flex-end" alignItems="center">
                <Number showFractionalPart={true} unit="%" limit={3} highlight={highlight} data={parseFloat(valLast)} />
                <FontAwesomeActionIcon
                  style={{ marginLeft: '2px' }}
                  icon="info"
                  color={selected ? 'white' : theme.palette.primary.main}
                  size="sm"
                />
              </Flex>
            }
            popperContent={<EvaluationTooltip evaluation={last} />}
          />
        )}
      </Result>
      <Result>
        {active && valActive && (
          <PopperTooltip
            popperLabel={
              <Flex justifyContent="flex-end" alignItems="center">
                <Number
                  showFractionalPart={true}
                  unit="%"
                  limit={3}
                  highlight={highlight}
                  data={parseFloat(valActive)}
                />
                <FontAwesomeActionIcon
                  style={{ marginLeft: '2px' }}
                  icon="info"
                  color={selected ? 'white' : theme.palette.primary.main}
                  size="sm"
                />
              </Flex>
            }
            popperContent={<EvaluationTooltip evaluation={active} />}
          />
        )}
      </Result>
      <Result>
        {candidate && valCandidate && (
          <PopperTooltip
            popperLabel={
              <Flex justifyContent="flex-end" alignItems="center">
                <Number
                  showFractionalPart={true}
                  unit="%"
                  limit={3}
                  highlight={highlight}
                  data={parseFloat(valCandidate)}
                />
                <FontAwesomeActionIcon
                  style={{ marginLeft: '2px' }}
                  icon="info"
                  color={selected ? 'white' : theme.palette.primary.main}
                  size="sm"
                />
              </Flex>
            }
            popperContent={<EvaluationTooltip evaluation={candidate} />}
          />
        )}
      </Result>
    </Flex>
  )
}

export default React.memo(EvaluationData)
