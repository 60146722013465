import { combineReducers } from 'redux'

import { getForecastModelsReducer } from 'modules/quality/redux_store/state/getForecastModels'
import { getQualityEvaluationsReducer } from 'modules/quality/redux_store/state/getQualityEvaluation'
import { getTrainingInfoReducer } from 'modules/quality/redux_store/state/getTrainingInfo'
import { getTrainingJobsReducer } from 'modules/quality/redux_store/state/getTrainingJobs'
import { getQualityOverviewReducer } from 'modules/quality/redux_store/state/getQualityOverview'
import { activateForecastModelReducer } from 'modules/quality/redux_store/state/activateForecastModel'
import { reevaluateForecastModelReducer } from 'modules/quality/redux_store/state/reevaluateForecastModel'
import { saveForecastModelReducer } from 'modules/quality/redux_store/state/saveForecastModel'
import { triggerWeightOptimizationReducer } from 'modules/quality/redux_store/state/triggerWeightOptimization'
import { hideForecastModelReducer } from 'modules/quality/redux_store/state/hideForecastModel'
import { saveForecastModelNameReducer } from 'modules/quality/redux_store/state/saveForecastModelName'

export const qualityReducer = combineReducers({
  activateForecastModel: activateForecastModelReducer,
  getForecastModels: getForecastModelsReducer,
  // getForecastModelsFromReactQuery: getForecastModelsFromReactQueryReducer,
  getQualityEvaluations: getQualityEvaluationsReducer,
  getQualityOverview: getQualityOverviewReducer,
  getTrainingInfo: getTrainingInfoReducer,
  getTrainingJobs: getTrainingJobsReducer,
  reevaluateForecastModel: reevaluateForecastModelReducer,
  saveForecastModel: saveForecastModelReducer,
  hideForecastModel: hideForecastModelReducer,
  triggerWeightOptimization: triggerWeightOptimizationReducer,
  saveForecastModelName: saveForecastModelNameReducer,
})
