import React, { useMemo } from 'react'
import { getPrimaryQualityValue } from 'utils/quality'
import { QualityOverview } from 'modules/quality/quality.types'
import Flex from 'ui/styles/Flex'
import { Asset } from 'modules/asset/store/asset.types'
import { SummaryAggregation, SummaryType } from 'modules/asset/tree/AssetTreeSummary'
import Number from 'ui/Number'
import { AppUnits } from 'utils/units'
import styled from 'styled-components'

const Result = styled.div`
  flex: 1;
  margin-right: 0.5em;
`

interface QualitySummaryCellProps {
  activeAggregation: SummaryAggregation
  activeSummary: SummaryType
  qualityOverview: QualityOverview | null
  forecastConfigId: string
  selectedAssets: Asset[]
}

const QualitySummaryCell: React.FC<QualitySummaryCellProps> = ({
  qualityOverview,
  forecastConfigId,
  activeAggregation,
  activeSummary,
  selectedAssets,
}) => {
  const calculateAssetsQualitySummary = (assets?: Asset[]) => {
    let assetsQualityOverview: QualityOverview | null = qualityOverview
    // If assets passed, filter out the quality values of those assets if not use all quality values
    if (assets) {
      assetsQualityOverview = selectedAssets.reduce((prev, currentAsset) => {
        if (qualityOverview?.[currentAsset.id]) {
          return {
            ...prev,
            [currentAsset.id]: qualityOverview[currentAsset.id],
          }
        } else return { ...prev }
      }, {})
    }

    let QualityValuesByForecastIdResult = {}
    if (assetsQualityOverview) {
      Object.keys(assetsQualityOverview).forEach((assetId) => {
        // If quality is available for asset
        if (assetsQualityOverview?.[assetId]) {
          Object.keys(assetsQualityOverview[assetId]).forEach((forecastId) => {
            const qualityDataByForecastIdPrevAsset = QualityValuesByForecastIdResult[forecastId]
            const qualityDataByForecastIdCurrAsset = ((assetsQualityOverview || {})[assetId] || {})[forecastId] || null
            QualityValuesByForecastIdResult = {
              ...QualityValuesByForecastIdResult,
              [forecastId]: qualityDataByForecastIdPrevAsset
                ? [...qualityDataByForecastIdPrevAsset, qualityDataByForecastIdCurrAsset]
                : [qualityDataByForecastIdCurrAsset],
            }
          })
        }
      })
    }

    let QualitySummaryByForecastIdResult = {}
    Object.keys(QualityValuesByForecastIdResult).forEach((forecastId) => {
      const QualityByForecastId = QualityValuesByForecastIdResult?.[forecastId] || []
      const activeValues = QualityByForecastId.map((quality) => getPrimaryQualityValue(quality.active))?.filter((val) =>
        Boolean(val),
      )
      const lastValues = QualityByForecastId.map((quality) => getPrimaryQualityValue(quality.last))?.filter((val) =>
        Boolean(val),
      )
      const candidateValues = QualityByForecastId.map((quality) =>
        getPrimaryQualityValue(quality.candidate),
      )?.filter((val) => Boolean(val))

      QualitySummaryByForecastIdResult = {
        ...QualitySummaryByForecastIdResult,
        [forecastId]: {
          active: {
            sum: null,
            max: Math.max(...activeValues) || null,
            min: Math.min(...activeValues) || null,
            avg: null,
          },
          last: {
            sum: null,
            max: Math.max(...lastValues) || null,
            min: Math.min(...lastValues) || null,
            avg: null,
          },
          candidate: {
            sum: null,
            max: Math.max(...candidateValues) || null,
            min: Math.min(...candidateValues) || null,
            avg: null,
          },
        },
      }
    })

    return QualitySummaryByForecastIdResult
  }

  const totalAssetsQualitySummary = useMemo(() => {
    return calculateAssetsQualitySummary()
  }, [qualityOverview])

  const selectedAssetsQualitySummary = useMemo(() => {
    return calculateAssetsQualitySummary(selectedAssets)
  }, [selectedAssets, qualityOverview])

  const getQualitySummaryInfoComponent = (
    summaryData: Record<string, any>,
    qualityValueType: string,
    forecastId: string,
  ) => {
    const value = summaryData?.[forecastId]?.[qualityValueType]?.[activeAggregation]

    return (
      <Flex justifyContent="flex-end" alignItems="center">
        <Number showFractionalPart={true} unit={AppUnits.PERCENTAGE} limit={3} data={parseFloat(value)} />
      </Flex>
    )
  }

  return (
    <Flex direction="row" justifyContent="space-evenly" flexGrow={1}>
      <Result>
        {getQualitySummaryInfoComponent(
          activeSummary === SummaryType.total ? totalAssetsQualitySummary : selectedAssetsQualitySummary,
          'last',
          forecastConfigId,
        )}
      </Result>
      <Result>
        {getQualitySummaryInfoComponent(
          activeSummary === SummaryType.total ? totalAssetsQualitySummary : selectedAssetsQualitySummary,
          'active',
          forecastConfigId,
        )}
      </Result>
      <Result>
        {getQualitySummaryInfoComponent(
          activeSummary === SummaryType.total ? totalAssetsQualitySummary : selectedAssetsQualitySummary,
          'candidate',
          forecastConfigId,
        )}
      </Result>
    </Flex>
  )
}

export default React.memo(QualitySummaryCell)
