import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Asset, TreeDataStreamsSelectionOptions } from 'modules/asset/store/asset.types'
import { RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import AssetLink from 'modules/asset/AssetLink'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector, reTableSortSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableExpandToggle from 'modules/reTable/ReTableExpandToggle'
import { isGenerator, isParkWithNoPlants } from 'utils/asset'

const flexStyles = `
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`

const Container = styled.div`
  ${flexStyles};
  max-width: 100%;
  height: 1.6em;
`
// padding-left: calc(0.5rem - 0.15em);

// (1) fix to avoid flex item to exceed its container (which would cause text ellipsis to be not shown)
const SelectionContainer = styled.div`
  ${flexStyles};
  width: 0; /* (1) */
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
`

const IconPlaceholder = styled.div`
  width: 1.4em;
  min-width: 1.4em;
  max-width: 1.4em;
`

interface TreeControlsProps {
  asset: Asset
  selected: boolean
  // multiSelect: boolean
  // checked: boolean
  // indeterminate: boolean
  // detailsVisible: boolean
  anyDetailsVisible: boolean
  onSelect: (selectionOptions: TreeDataStreamsSelectionOptions) => void
  assetNameWidth: string
  showExpandIconForModels?: boolean
}

const TreeControls: React.FC<TreeControlsProps> = ({
  asset,
  selected,
  // multiSelect,
  // checked,
  // indeterminate,
  // detailsVisible,
  anyDetailsVisible,
  onSelect,
  assetNameWidth,
  showExpandIconForModels,
}) => {
  const search = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_ASSETS)
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_ASSETS)

  // Icon should be visible only if asset have children
  // When models are shown icon should be visible for generators and also for park with no plants
  const showIcon =
    !sort.active &&
    (showExpandIconForModels
      ? isGenerator(asset) || isParkWithNoPlants(asset) || asset?.uiChildren?.length > 0
      : asset?.uiChildren?.length > 0)

  const handleClickContainer = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      event.preventDefault()
      onSelect({ ctrlKey: event.ctrlKey || event.metaKey, shiftKey: event.shiftKey })
    },
    [onSelect],
  )

  return (
    <Container onClick={handleClickContainer}>
      {showIcon ? (
        <ReTableExpandToggle item={asset} color={selected && !anyDetailsVisible ? 'white' : 'inherit'} />
      ) : (
        <IconPlaceholder />
      )}
      {/*{multiSelect && <Checkbox checked={checked} indeterminate={indeterminate} value={asset.id} color="default" />}*/}
      <SelectionContainer>
        <AssetLink
          assetNameWidth={assetNameWidth}
          asset={asset}
          selected={selected && !anyDetailsVisible}
          query={search}
        />
      </SelectionContainer>
    </Container>
  )
}

// TreeControls.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(TreeControls)
