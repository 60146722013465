import { combineReducers } from 'redux'
import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import createReducer from 'utils/createReducer'
import { request } from 'utils/request'
import { createSelector } from 'reselect'
import { MetaData } from 'modules/dataStreams/dataStreams.types'

// state

interface State {
  result: MetaData | null
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: null,
  loading: false,
  error: null,
}

// types

export const GET_METADATA_REQUEST = 'GET_METADATA_REQUEST'
export const GET_METADATA_SUCCESS = 'GET_METADATA_SUCCESS'
export const GET_METADATA_FAILURE = 'GET_METADATA_FAILURE'
export const GET_METADATA_DISMISS = 'GET_METADATA_DISMISS'
export type GET_METADATA_REQUEST = typeof GET_METADATA_REQUEST
export type GET_METADATA_SUCCESS = typeof GET_METADATA_SUCCESS
export type GET_METADATA_FAILURE = typeof GET_METADATA_FAILURE
export type GET_METADATA_DISMISS = typeof GET_METADATA_DISMISS
export type GET_METADATA_ACTION_TYPE =
  | GET_METADATA_REQUEST
  | GET_METADATA_SUCCESS
  | GET_METADATA_FAILURE
  | GET_METADATA_DISMISS

interface GetMetaDataAction {
  type: GET_METADATA_ACTION_TYPE
  // SUCCESS
  metaData: State['result']
  // ERROR
  error: State['error']
}

// reducers

const result = createReducer<State['result'] | undefined, GetMetaDataAction>(
  (state = initialState.result, { type, metaData }) => {
    if (type === GET_METADATA_SUCCESS) {
      state = metaData
    }
    return state
  },
)

const loading = createReducer<State['loading'] | undefined, GetMetaDataAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case GET_METADATA_REQUEST:
        return true
      case GET_METADATA_SUCCESS:
      case GET_METADATA_FAILURE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'] | undefined, GetMetaDataAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case GET_METADATA_SUCCESS:
      case GET_METADATA_DISMISS:
        return null
      case GET_METADATA_FAILURE:
        return error
    }
    return state
  },
)

export const getMetaDataReducer = combineReducers({
  result,
  loading,
  error,
})

// selectors

export const getMetaDataResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.data.getMetaData.result,
  (result) => result,
)
export const getMetaDataLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.data.getMetaData.loading,
  (loading) => loading,
)
export const getMetaDataErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.data.getMetaData.error,
  (error) => error,
)

// api
export const getMetaData = () => {
  return request(() => axios.get<MetaData>('/api/availdecisionparams'))
}

// sagas
export function* getMetaDataSaga() {
  const metaDataResult = yield call(getMetaData)
  if (metaDataResult.isSuccessful) {
    const metaData = metaDataResult.getData()
    yield put({ type: GET_METADATA_SUCCESS, metaData })
  } else {
    const error = metaDataResult.getError()
    yield put({ type: GET_METADATA_FAILURE, error })
  }
}
