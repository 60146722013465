import React, { useCallback } from 'react'
import { Divider } from '@material-ui/core'
import { getChangeScheduleSeriesModeMenuItems, getEditModeChartOptionsMenuItem } from 'utils/schedule'
import { t } from 'ttag'
import { useDispatch, useSelector } from 'react-redux'
import { ScheduleEditModeChartOptions, ScheduleSeriesUpdateModes } from 'modules/workspace/schedule/schedule.types'
import { DeepPartial } from 'ts-essentials'
import { SAVE_WORKSPACE_DRAFT_REQUEST, WorkspaceConfig } from 'modules/workspace/store/workspace.types'
import { workspaceDraftScheduleSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import ButtonMenu from 'ui/form/ButtonMenu'
import CheckmarkRadio from 'ui/form/CheckmarkRadio'

interface UpdateScheduleSeriesModeProps {
  disablePointMode: boolean
  hideLabel?: boolean
}

const UpdateScheduleSeriesMode: React.FC<UpdateScheduleSeriesModeProps> = ({ disablePointMode, hideLabel }) => {
  const dispatch = useDispatch()
  const workspaceDraftSchedule = useSelector(workspaceDraftScheduleSelector)
  const selectedMode = workspaceDraftSchedule?.changeSeriesMode || ScheduleSeriesUpdateModes.linearly
  const scheduleEditModeChartOptions =
    workspaceDraftSchedule?.scheduleEditModeChartOptions || ScheduleEditModeChartOptions.select

  const handleSelectMode = (handleClose: () => void) => (mode: ScheduleSeriesUpdateModes) => {
    const draft: DeepPartial<WorkspaceConfig> = {
      schedule: {
        changeSeriesMode: mode.key,
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })

    // close the dropdown
    handleClose()
  }

  const handleSelectModeChartOptions = (handleClose: () => void) => (mode: ScheduleEditModeChartOptions) => {
    const draft: DeepPartial<WorkspaceConfig> = {
      schedule: {
        scheduleEditModeChartOptions: mode.key,
      },
    }
    dispatch({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })

    // close the dropdown
    handleClose()
  }

  const modeMenuItems = getChangeScheduleSeriesModeMenuItems()

  const optionsMenuItems = getEditModeChartOptionsMenuItem()

  const menuContent = useCallback(
    (handleClose) => {
      return (
        <>
          <CheckmarkRadio
            options={modeMenuItems}
            selected={[selectedMode]}
            onChange={handleSelectMode(handleClose)}
            disableItem={disablePointMode ? ScheduleSeriesUpdateModes.point : ''}
          />
          <Divider />
          <CheckmarkRadio
            options={optionsMenuItems}
            selected={[scheduleEditModeChartOptions]}
            onChange={handleSelectModeChartOptions(handleClose)}
          />
        </>
      )
    },

    [selectedMode, scheduleEditModeChartOptions, disablePointMode],
  )

  return (
    <ButtonMenu
      icon="arrows-alt-v"
      size="small"
      color="primary"
      renderMenu={menuContent}
      text={hideLabel ? '' : t`Edit mode`}
    />
  )
}

export default UpdateScheduleSeriesMode
