import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { c } from 'ttag'

import { ChartDataRange, ChartDataRangeType, RangeTypes } from 'modules/workspace/store/workspace.types'
import { useSelector } from 'react-redux'
import {
  workspaceDraftChartDataRangeSelector,
  workspaceDraftDataStreamSelectionSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import {
  calendarRangeOptions,
  convertUTCToLocalDate,
  convertUtcToZonedTime,
  DateRange,
  formatDateYearMonth,
  getDateRangeFromChartDataRange,
  getRangeTypeFromTimePeriod,
  isRangeGreaterThanYear,
  limitRangeToOneYear,
} from 'utils/date'
import DateTimeRangeSelector, { DateTimeRange } from 'ui/datetime/DateTimeRangeSelector'
import { useWorkspaceChartWholeDateRange } from 'utils/workspace'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { useUniqueSelectedAssets } from 'utils/asset'
import { getMeterDataStatus } from 'modules/user/api/user.api'
import { differenceInDays, endOfDay, isAfter, isBefore } from 'date-fns'

export interface ChartDataRangeSelectorProps {
  onChangeRange: (dataRange: ChartDataRange) => void
  onChangeChartInfoMsg: (msg: string) => void
  hideLabel: boolean
}

const ChartDataRangeSelector: React.FC<ChartDataRangeSelectorProps> = ({
  onChangeRange,
  onChangeChartInfoMsg,
  hideLabel,
}) => {
  // Meter Data State
  const [meterDataStatus, setMeterDataStatus] = useState([])

  const selectedAssets = useUniqueSelectedAssets()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const chartDataRange = useSelector(workspaceDraftChartDataRangeSelector)
  const [dateTimeRangeLimitMsg, setDateTimeRangeLimitMsg] = useState<string>('')
  const chartWholeDateRange = useWorkspaceChartWholeDateRange()
  const user = useSelector(getUserResultSelector)
  const timezone = useSelector(getUserTimezoneSelector)

  useEffect(() => {
    getMeterDataStatus().then((res) => setMeterDataStatus(res))
  }, [])

  const matchedAssetsWithFileHandler = useMemo(() => {
    // 1. Declare empty array for matched assets
    const matchedAssets: any[] = []

    // 2. Find all matches
    if (selectedAssets?.length > 0 && meterDataStatus?.length > 0) {
      for (const assetFromUI of selectedAssets) {
        for (const assetFromFile of meterDataStatus) {
          if (assetFromUI.id === assetFromFile.identifiers) {
            matchedAssets.push(assetFromFile)
            break
          }
        }
      }
    }

    return matchedAssets
  }, [selectedAssets, meterDataStatus])

  const checkKeysHandler = useCallback(
    (type: ChartDataRangeType, customRange?: DateTimeRange) => {
      let startDate, endDate

      if (type !== ChartDataRangeType.CHART_DATA_RANGE_CUSTOM) {
        startDate = getDateRangeFromChartDataRange({ rangeType: type, customRange: null }, timezone)[0]
        endDate = getDateRangeFromChartDataRange({ rangeType: type, customRange: null }, timezone)[1]
      } else {
        startDate = customRange ? new Date(customRange?.startDate) : chartWholeDateRange[0]
        endDate = customRange ? new Date(customRange?.endDate) : chartWholeDateRange[0]
      }

      if (startDate && endDate) {
        // Get and convert date from UI
        const convertedStartDate = convertUtcToZonedTime(convertUTCToLocalDate(startDate), timezone)
        const convertedEndDate = convertUtcToZonedTime(convertUTCToLocalDate(endDate), timezone)
        const convertedDateFromUI = [formatDateYearMonth(convertedStartDate), formatDateYearMonth(convertedEndDate)]

        // console.log({ matchedAssetsWithFileHandler, convertedDateFromUI, convertedStartDate, convertedEndDate })

        for (const singleAsset of matchedAssetsWithFileHandler) {
          const keys = Object.keys(singleAsset)
          for (const key of keys) {
            if (
              key === convertedDateFromUI[0] ||
              key === convertedDateFromUI[1] ||
              (isAfter(new Date(key), convertedStartDate) && isBefore(new Date(key), convertedEndDate))
            )
              return true
          }
        }
      }

      return false
    },
    [timezone, chartWholeDateRange, matchedAssetsWithFileHandler, chartDataRange],
  )

  const isMeterDataLive = useMemo(() => {
    return dataSelection.find((el) => el.id == 'METER_DATA_SCADA')
  }, [JSON.stringify(dataSelection)])

  const rangeTypeOptions = useMemo(() => calendarRangeOptions(), [])

  const oneDayForMeterDataLiveMsg = c('Workbench:Time Range')
    .t`Maximum time range is one day when meter data (live) datastream is active for the selected assets.`

  const handleChange = useCallback(
    (rangeType: ChartDataRangeType, customRange?: DateTimeRange) => {
      let dateRange: DateRange | null = null
      let limitMsg = ''
      const originalRangeType = rangeType

      if (isMeterDataLive && checkKeysHandler(originalRangeType, customRange)) {
        rangeType = ChartDataRangeType.CHART_DATA_RANGE_CUSTOM
        let daysDiff = 0

        if (originalRangeType !== ChartDataRangeType.CHART_DATA_RANGE_CUSTOM) {
          const staticDateRange = getDateRangeFromChartDataRange(
            { rangeType: originalRangeType, customRange: null },
            timezone,
          )
          daysDiff = differenceInDays(new Date(staticDateRange[1]), new Date(staticDateRange[0]))
          if (daysDiff > 0) {
            limitMsg = oneDayForMeterDataLiveMsg
          }
          const convertedStartDate = convertUtcToZonedTime(convertUTCToLocalDate(staticDateRange[0]), timezone)
          dateRange = [convertedStartDate, endOfDay(convertedStartDate)]
        } else {
          daysDiff = differenceInDays(new Date(customRange?.endDate), new Date(customRange?.startDate))
          if (daysDiff > 0) {
            limitMsg = oneDayForMeterDataLiveMsg
          }
          const start = customRange?.startDate
          dateRange = [start, endOfDay(start)]
        }
      } else {
        if (rangeType === ChartDataRangeType.CHART_DATA_RANGE_CUSTOM && customRange) {
          const start = customRange.startDate
          const end = customRange.endDate
          const oneYearLimitMsg = c('Workbench:Time Range').t`Maximum time range is one year`

          if (isRangeGreaterThanYear(start, end)) {
            limitMsg = oneYearLimitMsg
            dateRange = limitRangeToOneYear(start, end)
          } else {
            dateRange = [start, end]
          }
        }
      }
      setDateTimeRangeLimitMsg(limitMsg)

      onChangeRange({
        rangeType: rangeType,
        customRange: dateRange,
      })
    },
    [onChangeRange, chartWholeDateRange, user, isMeterDataLive, checkKeysHandler, timezone],
  )

  // when meter data is selected restrict the time period to one day for assets whose meter data is messed up
  // setting error message when meter data selected or assets changed
  useEffect(() => {
    if (isMeterDataLive) {
      const daysDiff = differenceInDays(new Date(chartWholeDateRange[1]), new Date(chartWholeDateRange[0]))
      const startDate = convertUtcToZonedTime(convertUTCToLocalDate(new Date(chartWholeDateRange[0])), timezone)
      const endDate = convertUtcToZonedTime(convertUTCToLocalDate(new Date(chartWholeDateRange[1])), timezone)
      const chartDataRange = {
        startDate,
        endDate,
        rangeType: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM,
        key: 'selection',
      }

      if (checkKeysHandler(ChartDataRangeType.CHART_DATA_RANGE_CUSTOM, chartDataRange)) {
        if (daysDiff > 0) {
          handleChange(ChartDataRangeType.CHART_DATA_RANGE_CUSTOM, chartDataRange)
          setDateTimeRangeLimitMsg(oneDayForMeterDataLiveMsg)
        }
      }
    }
  }, [isMeterDataLive, handleChange, chartWholeDateRange, checkKeysHandler, timezone, selectedAssets])

  const selectedDateTimeRange = useMemo(() => {
    // const period = {
    //   start: convertUtcToZonedTime(convertUTCToLocalDate(chartWholeDateRange[0]), timezone),
    //   end: convertUtcToZonedTime(convertUTCToLocalDate(chartWholeDateRange[1]), timezone),
    // }
    const period = {
      start: chartWholeDateRange[0],
      end: chartWholeDateRange[1],
    }

    const rangeType = getRangeTypeFromTimePeriod({
      timePeriod: period,
      rangeType: RangeTypes.MAIN_CHART_RANGE,
      rangeOptions: rangeTypeOptions,
      timezone: timezone,
    })

    return {
      startDate: convertUtcToZonedTime(convertUTCToLocalDate(chartWholeDateRange[0]), timezone),
      endDate: convertUtcToZonedTime(convertUTCToLocalDate(chartWholeDateRange[1]), timezone),
      rangeType: rangeType || ChartDataRangeType.CHART_DATA_RANGE_PLUS_MINUS_7_DAYS,
    }
  }, [chartDataRange, chartWholeDateRange, timezone, rangeTypeOptions])

  const activeType = rangeTypeOptions.find((typeOption) => typeOption.key === selectedDateTimeRange.rangeType)

  const handleCloseSelector = useCallback(() => {
    const diff = differenceInDays(new Date(chartWholeDateRange[1]), new Date(chartWholeDateRange[0]))
    if (isMeterDataLive && diff === 0 && dateTimeRangeLimitMsg === oneDayForMeterDataLiveMsg) {
      return
    }
    setDateTimeRangeLimitMsg((prevMsg) => (prevMsg === oneDayForMeterDataLiveMsg ? prevMsg : ''))
  }, [isMeterDataLive, chartWholeDateRange])

  useEffect(() => {
    let message = dateTimeRangeLimitMsg
    if (message) {
      if (message === oneDayForMeterDataLiveMsg) {
        message = isMeterDataLive ? dateTimeRangeLimitMsg : ''
      }
      setDateTimeRangeLimitMsg(message)
    }
    onChangeChartInfoMsg(message)
  }, [dateTimeRangeLimitMsg, isMeterDataLive])

  return (
    <DateTimeRangeSelector
      selectedDateTimeRange={selectedDateTimeRange}
      rangeItems={rangeTypeOptions}
      onRangeChange={handleChange}
      title={activeType ? activeType.label : ''}
      label={hideLabel ? '' : activeType ? activeType.label : ''}
      info={dateTimeRangeLimitMsg}
      onClose={handleCloseSelector}
    />
  )
}

export default React.memo(ChartDataRangeSelector)
