import React, { useMemo } from 'react'
import { DataIndicationType, DataStream, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import PopperTooltip from 'ui/PopperTooltip'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { Box } from '@material-ui/core'
import { useForecastSeriesDataIndication } from 'utils/chart'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { c } from 'ttag'
import AssetList from 'modules/asset/AssetList'

const DataIndicatorContainer = styled(Box)`
  width: 0.5em;
  margin-left: -0.1em;
`

interface DataIndicationBarProps {
  indicatorType: string
}
const DataIndicationBar = styled.div<DataIndicationBarProps>`
  width: 0.4em;
  height: 29px;
  background: ${(props) =>
    props.indicatorType == DataIndicationType.ALL_SITES
      ? theme.palette.primary.main
      : props.indicatorType == DataIndicationType.PARTIAL_SITES
      ? '#9ed09e'
      : props.indicatorType == DataIndicationType.NO_SITES
      ? '#D5D5D5'
      : 'white'};
  border-right: 1px solid #e0e0e0;
`

interface DataStreamDataIndicatorProps {
  dataStream: DataStream
}

const DataStreamDataIndicator: React.FC<DataStreamDataIndicatorProps> = ({ dataStream }) => {
  const { ALL_SITES, PARTIAL_SITES, NO_SITES } = DataIndicationType

  const forecastConfigs = useSiteForecastConfigs()
  const forecastConfig = forecastConfigs.data?.find((config) => config.id === dataStream.id)
  const dataIndication = useForecastSeriesDataIndication(forecastConfig)
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const dataIndicationTooltipContent = useMemo(() => {
    switch (dataIndication.type) {
      case ALL_SITES:
        return <>{c('Data Stream Sidenav:Data indicator').t`Data is available for all selected assets`}</>
      case PARTIAL_SITES:
      case NO_SITES:
        return (
          <>
            <div>
              {dataIndication.type === PARTIAL_SITES
                ? c('Data Stream Sidenav:Data indicator').t`Data is not available for the following selected assets:`
                : c('Data Stream Sidenav:Data indicator').t`Data is not available for all selected assets:`}
            </div>
            <AssetList assets={dataIndication.missingAssets} />
          </>
        )
    }
  }, [dataIndication])

  return (
    <DataIndicatorContainer>
      {assetSelection.length > 0 && dataIndication && dataStream.type === TimeSeriesType.SITE_FORECAST && (
        <PopperTooltip
          popperLabel={<DataIndicationBar indicatorType={dataIndication?.type} />}
          popperContent={dataIndicationTooltipContent}
        />
      )}
    </DataIndicatorContainer>
  )
}

export default React.memo(DataStreamDataIndicator)
