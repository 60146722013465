import styled from 'styled-components'
import { FormControl } from '@material-ui/core'
import { TextField } from 'final-form-material-ui'

export const ValueField = styled(TextField)`
  width: 100%;
  margin: 0 1em;
  *.MuiInputBase-input {
    text-align: end;
  }
`

export const UnitFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin-right: 0.5em;
  }
`

export const ValueFormControl = styled(FormControl)`
  &.MuiFormControl-root {
    width: 100%;
    margin-right: 0.5em;
  }
`
export const AvailabilityValue = styled.div`
  height: 2.3em;
  min-width: 4em;
  width: fit-content;
  background-color: #eee;
  border-radius: 0.3em;
  padding: 4px 8px;
  text-align: right;
  position: absolute;
  bottom: 3em;
  z-index: 2;
  color: black;
  margin-right: 13px;
  border: 1px solid grey;

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-top: 0;
    border-bottom: 1px solid grey;
    border-left: 0;
    border-right: 1px solid grey;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    transform: rotate(45deg);
    margin-top: -8px;
    background: #eee;
  }
`
