import { useQueries } from 'react-query'
import { Asset } from 'modules/asset/store/asset.types'
import { apiRequest } from 'utils/request'
import axios from 'axios'

import {
  extractSeasonalForecastMonthlyData,
  extractSeasonalMeterDataMonthly,
  getClimatologyTypes,
  getSeasonalCategoriesBasedOnAsset,
  getSeasonalDataStaticUnits,
  getSeasonalForecastYearAndMonths,
  getSeasonalTypes,
  getYearAndMonthForSeasonalForecast,
  MonthlyViewModeIds,
  transformSeasonalForecastDataForReTable,
} from 'utils/seasonalForecast'
import { ReTableItem } from 'modules/reTable/reTable.types'
import { SeasonalForecastData } from 'modules/workspace/advancedChartWidgets/seasonalForecast/seasonalForecast.types'
import { TimeSeriesClassifier, TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { Timezone } from 'fixtures/timezones'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { convertLocalDateToUTC } from 'utils/date'
import { addMonths } from 'date-fns'
import { isNumeric } from 'utils/dataFormatting'
import { AppUnits } from 'utils/units'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { hasPermissionToClimatology } from 'utils/user'
import { User } from 'modules/auth/Auth.types'

export const QUERY_KEY_LONG_RANGE_FORECAST_CLIMATOLOGY_BY_ASSETS = 'LongRangeForecast:climatologyByAssets'
export const QUERY_KEY_LONG_RANGE_FORECAST_MONTHLY_BY_ASSETS = 'LongRangeForecast:MonthlyByAssets'
export const QUERY_KEY_LONG_RANGE_FORECAST_INTRA_MONTH_BY_ASSETS = 'LongRangeForecast:IntraMonthByAssets'
export const QUERY_KEY_LONG_RANGE_FORECAST_MONTHLY_METER_DATA_BY_ASSETS = 'LongRangeForecast:MonthlyMeterDataByAssets'

interface SeasonalDataByAssets {
  assets: Asset[]
  monthlyViewMode?: MonthlyViewModeIds
}

// Seasonal Climatology hooks
const getSeasonalClimatologyByAssetId = async (assetId: string) => {
  return await apiRequest<SeasonalForecastData[]>(() =>
    axios.get(`/api/monthly-aggregation/v1/climatology?assetUuid=${assetId}`, {
      transformResponse: (data) => {
        const transformedRes = JSON.parse(data)
        return { ...transformedRes, assetUuid: assetId }
      },
    }),
  )
}

export const useSeasonalClimatologyByAssets = ({ assets }: SeasonalDataByAssets) => {
  if (!assets?.length) return {}

  const user = useSelector(getUserResultSelector)
  const dataStreamSelection = useSelector(workspaceDraftDataStreamSelectionSelector)

  const isSeasonalForecastClimatologySelected = dataStreamSelection.some(
    (dss) => dss.subType === TimeSeriesSubType.CLIMATOLOGY,
  )
  const queryOptions = assets?.map((asset) => {
    return {
      queryKey: [QUERY_KEY_LONG_RANGE_FORECAST_CLIMATOLOGY_BY_ASSETS, asset.id],
      queryFn: () => {
        return getSeasonalClimatologyByAssetId(asset.id)
      },
      enabled: isSeasonalForecastClimatologySelected && hasPermissionToClimatology(user),
    }
  })

  return useQueries(queryOptions)
}

export const useSeasonalClimatologyByAssetsTableItems = (
  assets: Asset[],
  user: User,
  monthlyViewMode: MonthlyViewModeIds,
) => {
  const tableItems: ReTableItem[] = []
  const dataStreamSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const isSeasonalForecastClimatologySelected = dataStreamSelection.some(
    (dss) => dss.subType === TimeSeriesSubType.CLIMATOLOGY,
  )

  // Return empty if the climatology is not selected
  if (!isSeasonalForecastClimatologySelected || !assets.length || !hasPermissionToClimatology(user)) {
    return []
  }

  const seasonalForecastMonths = getSeasonalForecastYearAndMonths(monthlyViewMode)

  const climatologyResult = useSeasonalClimatologyByAssets({ assets })

  assets?.forEach((asset) => {
    const assetClimatologyResult = climatologyResult?.find(
      (result: Record<string, any>) => asset.id === result?.data?.assetUuid,
    )
    const dataCategories: (keyof SeasonalForecastData)[] = getSeasonalCategoriesBasedOnAsset(asset)

    dataCategories.forEach((category: keyof SeasonalForecastData) => {
      const unitKey = `${category}Unit`
      // Use MUS(GIGA_WATT_HOUR) for "energyYield" because these are converted into MUS(GIGA_WATT_HOUR) units
      const unit = category === 'energyYield' ? AppUnits.GIGA_WATT_HOUR : assetClimatologyResult?.data?.[unitKey]
      const climatologyType = getClimatologyTypes(category)

      const climatologyDataByCategory = {
        assetId: asset.id,
        assetName: asset?.name,
        unit: unit || getSeasonalDataStaticUnits(category),
        assetType: asset?.type,
      }

      seasonalForecastMonths.forEach((yearAndMonth) => {
        const month = yearAndMonth.split('-')[1]
        let climatologyValue = assetClimatologyResult?.data?.[category]?.mean[month] || ''
        if (category === 'energyYield') {
          climatologyValue = isNumeric(climatologyValue) ? climatologyValue / 1000 : '' // convert energy values so divide by 1000 to display in MUs (GIGA_WATT_HOUR)
        }
        climatologyDataByCategory[yearAndMonth] = climatologyValue
      })

      const transformedDataForReTable = transformSeasonalForecastDataForReTable(
        { ...climatologyDataByCategory },
        climatologyType,
      )
      tableItems.push(transformedDataForReTable)
    })
  })

  // climatologyResult?.forEach((assetClimatologyResult) => {
  //   const asset = assets.find((a: Asset) => a.id === assetClimatologyResult.data?.assetUuid)
  //   const dataCategories: (keyof SeasonalForecastData)[] = getSeasonalCategoriesBasedOnAsset(asset)
  //
  //   dataCategories.forEach((category: keyof SeasonalForecastData) => {
  //     const unitKey = `${category}Unit`
  //     // Use MUS (GIGA_WATT_HOUR) for "energyYield" because these are converted into MUS (GIGA_WATT_HOUR) units
  //     const unit = category === 'energyYield' ? AppUnits.MUS : assetClimatologyResult.data?.[unitKey]
  //     const asset = assets.find((a: Asset) => a.id === assetClimatologyResult.data?.assetUuid)
  //     const climatologyType = getClimatologyTypes(category)
  //
  //     const climatologyDataByCategory = {
  //       assetId: assetClimatologyResult.data?.assetUuid,
  //       assetName: asset?.name,
  //       unit: unit || getSeasonalDataStaticUnits(category),
  //       assetType: asset?.type,
  //     }
  //
  //     seasonalForecastMonths.forEach((yearAndMonth) => {
  //       const month = yearAndMonth.split('-')[1]
  //       let climatologyValue = assetClimatologyResult?.data?.[category]?.mean[month] || ''
  //       if (category === 'energyYield') {
  //         climatologyValue = isNumeric(climatologyValue) ? climatologyValue / 1000 : '' // convert energy values so divide by 1000 to display in MUs (GIGA_WATT_HOUR)
  //       }
  //       climatologyDataByCategory[yearAndMonth] = climatologyValue
  //     })
  //
  //     const transformedDataForReTable = transformSeasonalForecastDataForReTable(
  //       { ...climatologyDataByCategory },
  //       climatologyType,
  //     )
  //     tableItems.push(transformedDataForReTable)
  //   })
  // })

  const selectedClimatologyDataStreamClassifier = dataStreamSelection
    .filter((ds) => ds.subType === TimeSeriesSubType.CLIMATOLOGY)
    .map((seasonalDataStream) => seasonalDataStream.classifier)

  const filteredTableItems = tableItems.filter((item) => selectedClimatologyDataStreamClassifier.includes(item.type))

  return filteredTableItems
}

// Seasonal Monthly Meter data and forecast data hooks
const getSeasonalMonthlyMeterDataByAssetId = async (assetId: string) => {
  return await apiRequest<SeasonalForecastData[]>(() =>
    axios.get(`/api/monthly-aggregation/v1/finalized-meter?assetUuid=${assetId}`, {
      transformResponse: (data) => {
        const transformedRes = JSON.parse(data)
        return { ...transformedRes, assetUuid: assetId }
      },
    }),
  )
}

const getSeasonalMonthlyForecastByAssetId = async (
  assetId: string,
  monthlyViewMode: MonthlyViewModeIds,
  userTimezone: Timezone,
) => {
  const url =
    monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW
      ? `/api/monthly-aggregation/v1/seasonal-forecast?assetUuid=${assetId}`
      : `/api/monthly-aggregation/v1/seasonal-forecast-extended?assetUuid=${assetId}&timeZone=${userTimezone}`
  return await apiRequest<SeasonalForecastData[]>(() =>
    axios.get(url, {
      transformResponse: (data) => {
        // transformResponse get data as raw staring so we need to parse it.
        const transformedRes = JSON.parse(data)
        return { ...transformedRes, assetUuid: assetId }
      },
    }),
  )
}

const getIntraMonthSeasonalForecastByAssetId = async (
  assetId: string,
  userId: string,
  userTimezone: Timezone,
  startYearAndMonth: string,
  endYearAndMonth: string,
  maximumHorizonSiteForecastId: string,
) => {
  return await apiRequest(() =>
    axios.get(
      `/api/monthly-aggregation/v1/hybrid-intra-month?assetUuid=${assetId}&accountName=${userId}&timeZone=${userTimezone}&startMonth=${startYearAndMonth}&endMonth=${endYearAndMonth}&siteForecastUuid=${maximumHorizonSiteForecastId}`,
      {
        transformResponse: (data) => {
          // transformResponse get data as raw staring so we need to parse it.
          const transformedRes = JSON.parse(data)
          return { ...transformedRes, assetUuid: assetId }
        },
      },
    ),
  )
}

export const useSeasonalMonthlyMeterDataByAssets = ({ assets, monthlyViewMode }: SeasonalDataByAssets) => {
  if (!assets?.length) return {}

  const dataStreamSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const isSeasonalForecastMonthlySelected = dataStreamSelection.some(
    (dss) => dss.subType === TimeSeriesSubType.SEASONAL_FORECAST,
  )
  const queryOptions = assets?.map((asset) => {
    return {
      queryKey: [QUERY_KEY_LONG_RANGE_FORECAST_MONTHLY_METER_DATA_BY_ASSETS, asset.id],
      queryFn: () => {
        return getSeasonalMonthlyMeterDataByAssetId(asset.id)
      },
      enabled: isSeasonalForecastMonthlySelected && monthlyViewMode === MonthlyViewModeIds.HALF_FUTURE_VIEW,
    }
  })

  return useQueries(queryOptions)
}

export const useSeasonalMonthlyForecastByAssets = ({ assets, monthlyViewMode }: SeasonalDataByAssets) => {
  const dataStreamSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const userTimezone = useSelector(getUserTimezoneSelector)
  const isSeasonalForecastMonthlySelected = dataStreamSelection.some(
    (dss) => dss.subType === TimeSeriesSubType.SEASONAL_FORECAST,
  )

  if (!assets?.length || !isSeasonalForecastMonthlySelected || !monthlyViewMode) return []

  const queryOptions = assets?.map((asset) => {
    return {
      queryKey: [QUERY_KEY_LONG_RANGE_FORECAST_MONTHLY_BY_ASSETS, asset.id, monthlyViewMode],
      queryFn: () => {
        return getSeasonalMonthlyForecastByAssetId(asset.id, monthlyViewMode, userTimezone)
      },
      enabled: isSeasonalForecastMonthlySelected,
    }
  })

  return useQueries(queryOptions)
}

export const useSeasonalIntraMonthForecastByAssets = ({ assets }: SeasonalDataByAssets) => {
  const dataStreamSelection = useSelector(workspaceDraftDataStreamSelectionSelector)

  const siteForecastConfigs = useSiteForecastConfigs().data || []

  const maximumHorizonSiteForecast = siteForecastConfigs.find((siteForecast) => siteForecast.maximumHorizon)

  const isSeasonalForecastMonthlySelected = dataStreamSelection.some(
    (dss) => dss.classifier === TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION,
  )

  if (!assets?.length || !isSeasonalForecastMonthlySelected || !maximumHorizonSiteForecast) return []

  const userId = useSelector(getUserResultSelector)?.login || ''
  const userTimezone = useSelector(getUserTimezoneSelector)
  const startDate = convertLocalDateToUTC(new Date())
  const endDate = addMonths(startDate, 1)

  const startYearAndMonth = getYearAndMonthForSeasonalForecast(startDate)
  const endYearAndMonth = getYearAndMonthForSeasonalForecast(endDate)

  const queryOptions = assets?.map((asset) => {
    return {
      queryKey: [QUERY_KEY_LONG_RANGE_FORECAST_INTRA_MONTH_BY_ASSETS, startYearAndMonth, endYearAndMonth, asset.id],
      queryFn: () => {
        return getIntraMonthSeasonalForecastByAssetId(
          asset.id,
          userId,
          userTimezone,
          startYearAndMonth,
          endYearAndMonth,
          maximumHorizonSiteForecast?.id || '',
        )
      },
      enabled: isSeasonalForecastMonthlySelected,
    }
  })

  return useQueries(queryOptions)
}

// Seasonal data monthly consists of data from monthly forecast and monthly meter data
// Therefore we need to fetch the forecast and meter data for each asset
// Then create data for one year ( 6months - current month + 5months) from forecast and meter data
// First 6months data is always meter data and next 6months is always forecast
export const useSeasonalDataMonthlyByAssets = (assets: Asset[], monthlyViewMode: MonthlyViewModeIds) => {
  const forecastMonthlyResult = useSeasonalMonthlyForecastByAssets({ assets, monthlyViewMode })
  const meterDataMonthlyResult = useSeasonalMonthlyMeterDataByAssets({ assets, monthlyViewMode })

  const seasonalForecastMonths = getSeasonalForecastYearAndMonths()
  const currentYearAndMonth = getYearAndMonthForSeasonalForecast(new Date())
  const currentYearAndMonthIndex = seasonalForecastMonths.findIndex((sfm) => sfm === currentYearAndMonth)
  const currentPastYearAndMonths = seasonalForecastMonths.slice(0, currentYearAndMonthIndex + 1).reverse()

  const dataCategories: (keyof SeasonalForecastData)[] = getSeasonalCategoriesBasedOnAsset(null)
  // FORECAST DATA: Extract energyYield and if asset is solar extract poaIrradiation else wind speed
  const extractedForecastDataMonthly = forecastMonthlyResult?.map((res) => {
    let extractedData: Record<string, any> = {}
    if (res.data) {
      extractedData = extractSeasonalForecastMonthlyData(res.data, dataCategories, currentPastYearAndMonths)
    }
    return extractedData
  })

  // METER DATA: Extract energyYield and if asset is solar extract poaIrradiation else wind speed
  const extractedMeterDataMonthly = meterDataMonthlyResult?.map((res) => {
    let extractedData: Record<string, any> = {}
    if (res.data) {
      const pastYearAndMonths = currentPastYearAndMonths.filter((cpym) => cpym !== currentYearAndMonth)
      extractedData = extractSeasonalMeterDataMonthly(res.data, dataCategories, pastYearAndMonths)
    }
    return extractedData
  })

  return { monthlyForecast: extractedForecastDataMonthly, monthlyMeterData: extractedMeterDataMonthly }
}

export const useSeasonalDataMonthlyByAssetsTableItems = (assets: Asset[], monthlyViewMode: MonthlyViewModeIds) => {
  const tableItems: ReTableItem[] = []
  const dataStreamSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const { SEASONAL_FORECAST } = TimeSeriesSubType
  const isSeasonalForecastMonthlySelected = dataStreamSelection.some((dss) => dss.subType === SEASONAL_FORECAST)

  // Return empty if the monthly is not selected
  if (!isSeasonalForecastMonthlySelected || !assets.length) {
    return []
  }

  const { monthlyForecast, monthlyMeterData } = useSeasonalDataMonthlyByAssets(assets, monthlyViewMode)

  assets.forEach((asset) => {
    const assetMonthlyForecast = monthlyForecast.find((mf) => mf.assetId === asset.id)
    const assetMonthlyMeterData = monthlyMeterData.find((mf) => mf.assetId === asset.id)

    const dataCategories: (keyof SeasonalForecastData)[] = getSeasonalCategoriesBasedOnAsset(asset)

    dataCategories.forEach((category: keyof SeasonalForecastData) => {
      const forecastDataByCategory = assetMonthlyForecast?.[category] || {}
      const MeterDataByCategory = assetMonthlyMeterData?.[category] || {}
      const unitKey = `${category}Unit`
      // Use MUS (GIGA_WATT_HOUR) for "energyYield" because these are converted into MUS (GIGA_WATT_HOUR) units
      const unit =
        category === 'energyYield'
          ? AppUnits.GIGA_WATT_HOUR
          : assetMonthlyForecast?.[unitKey] || assetMonthlyMeterData?.[unitKey] || ''
      const seasonalType = getSeasonalTypes(category)

      const seasonalDataByCategory = {
        assetId: asset.id,
        assetName: asset?.name,
        unit: unit || getSeasonalDataStaticUnits(category),
        assetType: asset?.type,
        ...forecastDataByCategory,
        ...MeterDataByCategory,
      }

      const transformedDataForReTable = transformSeasonalForecastDataForReTable(
        { ...seasonalDataByCategory },
        seasonalType,
      )
      tableItems.push(transformedDataForReTable)
    })
  })

  const selectedSeasonalDataStreamClassifier = dataStreamSelection
    .filter((ds) => ds.subType === SEASONAL_FORECAST)
    .map((seasonalDataStream) => seasonalDataStream.classifier)

  const filteredTableItems = tableItems.filter((item) => selectedSeasonalDataStreamClassifier.includes(item.type))

  return filteredTableItems
}
