export interface StoryDescriptor {
  category: string
  name: string
}

export const createStory = (category: string, name: string): StoryDescriptor => {
  return {
    category,
    name,
  }
}

export const mockUrl = (path = '') => {
  return new RegExp(path.replace(/:\w+/g, '[^/]+'))
}

export const pathToStoryForTesting = (story: StoryDescriptor) => {
  const category = story.category.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
  const name = story.name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
  const id = `${category}--${name}`
  return `http://localhost:${Cypress.env('STORYBOOK_PORT')}/iframe.html?id=${id}`
}
