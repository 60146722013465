import React, { useCallback, useEffect, useState } from 'react'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { isNumeric } from 'utils/dataFormatting'

const Container = styled.div`
  width: 2.7em;
`
const InputField = styled.input`
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
  width: 100%;
  padding: 3px 5px;
  text-align: center;
`

const ArrowIcon = styled(FontAwesomeActionIcon)`
  font-size: 15px;
  cursor: pointer;
`

enum ArrowTypes {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
}

interface SiteForecastFormNumberFieldProps {
  value: number
  placeholder?: string
  onChange: (val: number, changedWithArrow: boolean) => void
  min?: number
  max?: number
  step?: number
  repeatNumbers?: boolean
  disabled?: boolean
  minMaxValuesHandle?: boolean
  timeInput?: boolean
}

const NumberFieldWithArrows: React.FC<SiteForecastFormNumberFieldProps> = ({
  value,
  placeholder = '',
  onChange,
  min = 0,
  max = 365,
  step = 1,
  repeatNumbers = true,
  disabled = false,
  minMaxValuesHandle = true,
  timeInput = false,
}) => {
  const { INCREMENT, DECREMENT } = ArrowTypes
  const [inputValue, setInputValue] = useState<number>((typeof value === 'string' ? parseInt(value) : value) || 0)

  const checkMinMax = useCallback(
    (type: ArrowTypes | null, onChangeVal: number | null) => {
      let value = onChangeVal || 0
      if (type) {
        value = type === INCREMENT ? inputValue + step : inputValue - step
      }
      if (!repeatNumbers && (value > max || value < min)) {
        value = min || 0
      } else if (minMaxValuesHandle) {
        if (min != undefined && max && value > max) {
          value = min
        }
        if (max && min != undefined && value < min) {
          value = max
        }
      }

      return value
    },
    [onChange, inputValue, step, min, max, repeatNumbers],
  )

  const handleChangeWithArrows = useCallback(
    (type) => {
      if (disabled) return
      const value = checkMinMax(type, null)
      setInputValue(value)
      onChange(value, true)
    },
    [checkMinMax, disabled],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = isNumeric(parseInt(event.target.value)) ? parseInt(event.target.value) : min || 0
      const value = checkMinMax(null, inputValue)
      setInputValue(value)
      onChange(value, false)
    },
    [onChange],
  )

  // TODO Fix page scrolling on value change
  // const handleWheelScroll = (event: React.WheelEvent<HTMLElement>) => {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   if (event.deltaY > 0) {
  //     setInputValue(inputValue - 1)
  //   } else {
  //     setInputValue(inputValue + 1)
  //   }
  // }

  useEffect(() => {
    if (isNumeric(value) && value != inputValue) {
      setInputValue(value)
    } else if (!value && value != inputValue) {
      setInputValue(0)
    }

    // setInputValue(value)
  }, [value, inputValue])

  return (
    <Container>
      <Flex alignItems="center" direction="column">
        <ArrowIcon onClick={() => handleChangeWithArrows(INCREMENT)} icon="angle-up" />
        <InputField
          // onWheel={handleWheelScroll}
          type="number"
          placeholder={placeholder}
          value={inputValue?.toString().length === 1 && timeInput ? `0${inputValue}` : inputValue || 0}
          onChange={handleChange}
          step={step}
          disabled={disabled}
        />
        <ArrowIcon onClick={() => handleChangeWithArrows(DECREMENT)} icon="angle-down" />
      </Flex>
    </Container>
  )
}

export default React.memo(NumberFieldWithArrows)
