import { Timezone } from 'fixtures/timezones'
import { XAxisOptions, YAxisOptions, YAxisPlotBandsOptions } from 'highcharts'
import Highcharts from 'highcharts/highstock'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import TimeOptions from 'modules/workspace/highstock/TimeOptions'
import TooltipOptions from 'modules/workspace/highstock/TooltipOptions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ColumnSeries, HighchartsStockChart, PlotBand, Series, XAxis, YAxis } from 'react-jsx-highstock'
import useResizeAware from 'react-resize-aware'
import styled from 'styled-components'
import { c } from 'ttag'
import { CHART_BOOST_THRESHOLD_VALUE, WorkspaceChartWidgetHeight } from 'utils/chart'
import { formatIndianCurrency, formatNumber, thousandSeparator } from 'utils/dataFormatting'
import {
  convertUTCToLocalDate,
  convertUtcToZonedTime,
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_ONLY_TIME,
  DateRange,
  formatDate,
} from 'utils/date'
import { AppUnits } from 'utils/units'
import {
  PenaltyBlockResult,
  PenaltyCalculationResult,
} from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import { createTimeSeriesWithNullValues } from 'utils/timeseries'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { fade } from '@material-ui/core/styles'
import {
  addMilliseconds,
  addSeconds,
  differenceInMilliseconds,
  differenceInMinutes,
  getTime,
  isSameDay,
} from 'date-fns'

const Content = styled.div`
  position: relative;
  height: ${WorkspaceChartWidgetHeight};
  min-height: ${WorkspaceChartWidgetHeight};
  max-height: ${WorkspaceChartWidgetHeight};
`

interface BandData {
  x: number
  y: number
  color: string
  custom: {
    blockNumber: number
    start: Date
    end: Date
    overInjectionPenaltyCost: string
    underInjectionPenaltyCost: string
    normalizedError: string | number
    forecast: string | number
    meterData: string | number
    availableCapacity: string | number
  }
}

interface PenaltyDeviationChartProps {
  penaltyCalculations: PenaltyCalculationResult
  maxErrorBlock: PenaltyBlockResult | null
  loading?: boolean
  timezone?: Timezone
  range: DateRange
  title?: string
  message?: string | null
  showTooltip: boolean
  backCastEnabled: boolean
}

const PenaltyDeviationChart: React.FC<PenaltyDeviationChartProps> = ({
  penaltyCalculations,
  maxErrorBlock,
  loading = false,
  timezone = 'UTC',
  range,
  title = '',
  message = '',
  showTooltip,
  backCastEnabled,
}) => {
  const chartSelectedRange = useWorkspaceChartSelectedDateRange()

  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const [resizeListener, { width, height }] = useResizeAware()
  const [chart, setChart] = useState<HighchartsStockChart | undefined>()
  const [currentRange, setCurrentRange] = useState(range)
  const [series, setSeries] = useState<BandData[]>([])

  const penaltyBandColors = [
    'rgba(255, 255, 255, 0)',
    'rgba(232, 206, 64, 0.12)',
    'rgba(231,133,14,0.15)',
    'rgba(232,43,23,0.18)',
  ]

  const initialChartOptions = useMemo(
    () => ({
      height: '100%',
    }),
    [],
  )

  const chartOptions = useMemo(
    () => ({
      zoomType: undefined,
      // height: AdvancedChartHeight,
    }),
    [],
  )

  const plotOptions = useMemo(() => {
    return {
      column: {
        dataGrouping: {
          enabled: false, // NOTE: disable data grouping so that we don't skip intermediate column bars
        },
        groupPadding: 0,
        pointPadding: 0,
        stacking: 'normal',
        turboThreshold: 0, // NOTE: disable turbo threshold to avoid disappearing column bars
      },
      series: {
        animation: {
          enabled: false,
        },
        boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
        connectNulls: false,
        states: {
          inactive: {
            enabled: false, // do not fade out other series if one is hovered
          },
        },
      },
    }
  }, [])

  const finalBandSeries = useMemo(
    () => (penaltyCalculations?.penaltyBlockResults || []).filter((penalty) => penalty.finalBand),
    [penaltyCalculations, assetSelection],
  )

  // This function returns all penalty block results which are not final.
  const bandSeriesWithoutFinal = useMemo(
    () => (penaltyCalculations?.penaltyBlockResults || []).filter((penalty) => !penalty.finalBand),
    [penaltyCalculations, assetSelection],
  )

  // axis definitions

  const xAxis: XAxisOptions = useMemo(() => {
    // const categories = finalBandSeries.map((result, index) => index + 1)
    return {
      // categories: categories,
      crosshair: true,
      labels: {
        rotation: -45,
      },
      minRange: 1000 * 60 * 60 * 2, // 2 hours
      minTickInterval: 1000 * 60 * 15, // 15 minutes
      ordinal: false,
      tickPixelInterval: 50,
      type: 'datetime',

      // title: {
      //   text: `${c('Workbench:Quality').t`Block number`}`,
      // },
    }
  }, [finalBandSeries])

  const unit = '%'
  const yAxis: YAxisOptions = useMemo(() => {
    const maxValue = maxErrorBlock ? Math.round((maxErrorBlock.normalizedError + 4) / 5) * 5 : undefined

    return {
      alignTicks: true,
      crosshair: true,
      gridLineWidth: 0,
      gridLineColor: 'transparent',
      labels: {
        align: 'left',
        formatter: function () {
          return thousandSeparator(this.value)
        },
        reserveSpace: true,
      },
      lineWidth: 1,
      minPadding: 0,
      max: maxValue,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      endOnTick: false,
      tickPositions: [-25, -12, 0, 12, 25],
      title: {
        // offset: 48,
        text: `${c('Workbench:Quality').t`Deviation`} (${unit})`,
      },
    }
  }, [maxErrorBlock, unit])

  const handleChartMounted = useCallback((c) => {
    setChart(c)
  }, [])

  useEffect(() => {
    if (range && range.length) {
      const localRange: DateRange = [convertUTCToLocalDate(range[0]), convertUTCToLocalDate(range[1])]
      setCurrentRange(localRange)
    }
  }, [range, timezone])

  useEffect(() => {
    if (!chart?.xAxis || chart.xAxis.length === 0 || !currentRange || !currentRange.length) return
    const timeoutExtremes = setTimeout(() => {
      const nextExtremes = [getTime(new Date(currentRange[0])), getTime(addSeconds(new Date(currentRange[1]), 1))]
      if (
        !nextExtremes ||
        (Math.abs(differenceInMinutes(chart.xAxis[0]?.min, nextExtremes[0])) <= 1 &&
          Math.abs(differenceInMinutes(chart.xAxis[0]?.max, nextExtremes[1])) <= 1)
      ) {
        return
      }

      // we need the timeout so that setExtremes is overriding any automatic extremes adjustments by highcharts when new data is loaded
      // see this from 2012 (!!): https://www.highcharts.com/forum/viewtopic.php?t=15091
      if (chart.xAxis[0]) {
        // reset selection when chart date range changes
        chart.xAxis[0].setExtremes(nextExtremes[0], nextExtremes[1], true, false)
      }
    }, 0)

    return () => {
      if (timeoutExtremes) {
        clearTimeout(timeoutExtremes)
      }
    }
  }, [chart, currentRange])

  const tooltipOptions = useMemo(
    () => ({
      formatter: function () {
        if (!this.points) return false
        const { x, y } = this
        const pointObject = this.points.find((p: Highcharts.Point) => {
          return p.x === x && p.y === y
        })
        if (!pointObject) return false
        const thisPoint: Highcharts.Point = pointObject.point

        const dateStart = convertUtcToZonedTime(thisPoint.custom.start, timezone)
        const dateEnd = convertUtcToZonedTime(thisPoint.custom.end, timezone)
        const sameDay = isSameDay(dateStart, dateEnd)
        const dateStartFormatted = formatDate(dateStart)
        const dateEndFormatted = formatDate(dateEnd, null, sameDay ? DATE_FORMAT_ONLY_TIME : DATE_FORMAT_DEFAULT)

        const tooltipContent = []
        tooltipContent.push(`<strong>${dateStartFormatted} .. ${dateEndFormatted}</strong><br>`)
        tooltipContent.push(
          `<span>${c('Workbench:Quality').t`Block number`}: <strong>${
            thisPoint.custom.blockNumber + 1
          }</strong></span><br>`,
        )
        tooltipContent.push(
          `<span>${c('Workbench:Quality').t`Deviation`}: <strong>${
            thisPoint.custom.normalizedError
          } %</strong></span><br>`,
        )
        tooltipContent.push(
          `<span>${c('Workbench:Quality').t`DSM Penalty`}: <strong>${
            thisPoint.custom.normalizedError < 0
              ? thisPoint.custom.underInjectionPenaltyCost
              : thisPoint.custom.overInjectionPenaltyCost
          }</strong></span><br>`,
        )
        tooltipContent.push(`<br>`)
        tooltipContent.push(
          `<span>${c('Workbench:Quality').t`Forecast`}: <strong>${thisPoint.custom.forecast} ${
            AppUnits.KILO_WATT
          }</strong></span><br>`,
        )
        tooltipContent.push(
          `<span>${c('Workbench:Quality').t`Meter data`}: <strong>${thisPoint.custom.meterData}  ${
            AppUnits.KILO_WATT
          }</strong></span><br>`,
        )
        tooltipContent.push(
          `<span>${c('Workbench:Quality').t`Available capacity`}: <strong>${thisPoint.custom.availableCapacity} ${
            AppUnits.KILO_WATT
          }</strong></span><br>`,
        )
        return tooltipContent
      },
      shared: true,
    }),
    [timezone],
  )

  // actual data

  useEffect(() => {
    if (finalBandSeries && finalBandSeries.length) {
      const bandSeriesSet: BandData[] = []

      finalBandSeries.forEach((penaltyBlockResult, index) => {
        const otherBandsPenaltyCost = bandSeriesWithoutFinal
          .filter((blockResult) => {
            return blockResult.time.end === penaltyBlockResult.time.end
          })
          .map((blockResultsWithSameTimestamp) => {
            return {
              overInjectionPenaltyCost: blockResultsWithSameTimestamp.overInjectionPenaltyCost,
              underInjectionPenaltyCost: blockResultsWithSameTimestamp.underInjectionPenaltyCost,
            }
          })

        // Over Injection Penalty Cost Handler
        const finalBandOverInjectionPenaltyCost = penaltyBlockResult.overInjectionPenaltyCost
        const otherBandOverInjectionPenaltyCost = otherBandsPenaltyCost
          .map((cost) => cost.overInjectionPenaltyCost)
          .reduce((a, b) => a + b, 0)
        const totalOverInjectionPenaltyCost = finalBandOverInjectionPenaltyCost + otherBandOverInjectionPenaltyCost

        // Under Injection Penalty Cost Handler
        const finalBandUnderInjectionPenaltyCost = penaltyBlockResult.underInjectionPenaltyCost
        const otherBandUnderInjectionPenaltyCost = otherBandsPenaltyCost
          .map((cost) => cost.underInjectionPenaltyCost)
          .reduce((a, b) => a + b, 0)
        const totalUnderInjectionPenaltyCost = finalBandUnderInjectionPenaltyCost + otherBandUnderInjectionPenaltyCost
        const value = penaltyBlockResult.normalizedError

        // To position the start of bar at the start of interval
        const gapInMilliSeconds = differenceInMilliseconds(
          new Date(penaltyBlockResult.time.end),
          new Date(penaltyBlockResult.time.start),
        )
        const xPosition = getTime(addMilliseconds(new Date(penaltyBlockResult.time.start), gapInMilliSeconds / 2))

        bandSeriesSet.push({
          x: xPosition,
          y: value,
          color: 'rgb(150, 150, 160)',
          custom: {
            blockNumber: index,
            start: penaltyBlockResult.time.start,
            end: penaltyBlockResult.time.end,
            overInjectionPenaltyCost: formatIndianCurrency(totalOverInjectionPenaltyCost || 0),
            underInjectionPenaltyCost: formatIndianCurrency(totalUnderInjectionPenaltyCost || 0),
            normalizedError: formatNumber({
              data: penaltyBlockResult.normalizedError,
              limit: 2,
              showFractionalPart: true,
            }),
            forecast: formatNumber({
              data: penaltyBlockResult.forecastValue / 1000,
              limit: 3,
              showFractionalPart: true,
              separator: true,
            }),
            meterData: formatNumber({
              data: penaltyBlockResult.actualValue / 1000,
              limit: 3,
              showFractionalPart: true,
              separator: true,
            }),
            availableCapacity: formatNumber({
              data: penaltyBlockResult.availableCapacityValue / 1000,
              limit: 3,
              showFractionalPart: true,
              separator: true,
            }),
          },
        })
      })
      setSeries(bandSeriesSet)
    }
    if (!finalBandSeries.length) {
      setSeries([])
    }
  }, [finalBandSeries, bandSeriesWithoutFinal, backCastEnabled])

  const plotBands: YAxisPlotBandsOptions[] = useMemo(() => {
    let overInjectionBands: YAxisPlotBandsOptions[] = []
    if (penaltyCalculations?.overInjectionPenaltyBands && penaltyCalculations?.overInjectionPenaltyBands.length) {
      overInjectionBands = (penaltyCalculations?.overInjectionPenaltyBands || [])
        .sort((a, b) => a.index - b.index)
        .map((penaltyBand, index) => ({
          id: penaltyBand.index.toString(),
          from: penaltyBand.from,
          to: Math.min(penaltyBand.to, 1000),
          color: penaltyBandColors[index],
          borderColor: fade(penaltyBandColors[penaltyBand.index], 0.15),
          borderWidth: 1,
        }))
    }

    let underInjectionBands: YAxisPlotBandsOptions[] = []
    if (penaltyCalculations?.underInjectionPenaltyBands && penaltyCalculations?.underInjectionPenaltyBands.length) {
      underInjectionBands = (penaltyCalculations?.underInjectionPenaltyBands || [])
        .sort((a, b) => a.index - b.index)
        .map((penaltyBand, index) => {
          return {
            id: `under-injection-${penaltyBand.index.toString()}`,
            from: penaltyBand.from === 0 ? penaltyBand.from : -1 * penaltyBand.from,
            to: Math.min(penaltyBand.to, 1000) * -1,
            color: penaltyBandColors[index],
            borderColor: fade(penaltyBandColors[index], 0.15),
            borderWidth: 1,
          }
        })
    }

    const bandAtZero: YAxisPlotBandsOptions[] = [
      {
        id: `band-at-zero`,
        from: 0,
        to: 0,
        color: '#bbbfbc',
        borderWidth: 0.5,
      },
    ]

    return [...overInjectionBands, ...bandAtZero, ...underInjectionBands]
  }, [penaltyCalculations])

  // handle size changes

  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }
  }, [chart, width, height, showTooltip])

  const emptyYAxis: YAxisOptions = useMemo(() => {
    return {
      alignTicks: true,
      crosshair: true,
      gridLineWidth: 1,
      labels: {
        align: 'left',
        formatter: function () {
          return thousandSeparator(this.value)
        },
        reserveSpace: true,
      },
      lineWidth: 1,
      minPadding: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      endOnTick: false,
      title: {
        // offset: 48,
        text: `${c('Workbench:Quality').t`Deviation`} (${unit})`,
      },
    }
  }, [unit])

  const emptySeries = createTimeSeriesWithNullValues(currentRange)

  return (
    <Content>
      {resizeListener}
      <HighchartsStockChart chart={initialChartOptions} plotOptions={plotOptions} callback={handleChartMounted}>
        <GenericOptions title={title} message={!loading ? message : ''} showTooltip={showTooltip} />
        <ChartOptions options={chartOptions} />
        <LoadingOptions isLoading={loading} />
        <TimeOptions seriesStartDate={chartSelectedRange[0]} timezone={timezone} />
        {showTooltip && <TooltipOptions options={tooltipOptions} />}

        <XAxis {...xAxis}>
          <XAxis.Title>{xAxis.title?.text}</XAxis.Title>
        </XAxis>

        {series.length > 0 ? (
          <YAxis {...yAxis}>
            <YAxis.Title>{yAxis.title?.text}</YAxis.Title>
            <ColumnSeries id="band0" name="Band0" data={series} />
            {(plotBands || []).map((plotBand) => (
              <PlotBand key={`plotband-${plotBand.id}`} {...plotBand} />
            ))}
          </YAxis>
        ) : (
          <YAxis {...emptyYAxis}>
            <YAxis.Title>{emptyYAxis.title?.text}</YAxis.Title>

            {emptySeries.map((series) => (
              <Series key={series.name} id={series.name} {...series} />
            ))}
          </YAxis>
        )}
      </HighchartsStockChart>
    </Content>
  )
}

export default React.memo(PenaltyDeviationChart)
