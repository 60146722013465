import React, { useMemo } from 'react'
// import { ChartOptions } from 'highcharts/highstock'
import { Chart } from 'react-jsx-highstock'

interface ChartOptionsProps {
  // options?: ChartOptions
  options?: Record<string, unknown>
}
const ChartOptions: React.FC<ChartOptionsProps> = ({ options = {} }) => {
  const chartOptions = useMemo(() => {
    return {
      animation: false,
      alignTicks: true,
      // height,
      marginLeft: 80,
      // panning: true,
      // panKey: 'shift',
      shadow: false,
      style: {
        fontFamily: 'Lato',
      },
      // type: 'scatter',
      zoomType: 'x',
      ...options,
    }
  }, [options])

  return <Chart {...chartOptions} />
}

export default React.memo(ChartOptions)
