import { useTimeSeriesQueryResults } from 'modules/dataStreams/api/timeseries.api'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'

import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useDebounce } from 'use-debounce'
import { getNomcap, useUniqueSelectedAssets } from 'utils/asset'
import { calculateQualityForPreview, createCorrelations, QualityTableDataStreamsTypes } from 'utils/quality'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import QualityPreviewTable from 'modules/workspace/advancedChartWidgets/quality/QualityPreviewTable'
import { Box, Typography } from '@material-ui/core'
import MeterDataSelectInput from 'ui/meterdata/MeterDataSelectInput'
import InlineLoading from 'ui/InlineLoading'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { MeterDataWithValueContext } from 'modules/workspace/advancedChartWidgets/QualityTableAndMetaForecastWidgets'

const Content = styled.div`
  position: relative;
  width: 100%;
`

interface QualityResultTableProps {
  loading: boolean
  widgetName: string
}
const QualityPreview: React.FC<QualityResultTableProps> = ({ loading, widgetName }) => {
  const { meterDataWithValue, setMeterDataWithValue } = useContext(MeterDataWithValueContext)
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const [meterDataToUse, setMeterDataToUse] = useState('')
  const [debouncedAssetSelection] = useDebounce(assetSelection, 100)
  const selectedAssets = useUniqueSelectedAssets()
  const chartSettingsResult = useLineChartSettings()
  const isBackCastEnabled = useMemo(() => chartSettingsResult?.data?.showBackCast, [chartSettingsResult?.data])

  const { SITE_FORECAST, METER_DATA, SCHEDULE, BACK_CAST, E3_META_FORECAST, META_FORECAST } = TimeSeriesType

  const chartSelectedDateRange = useWorkspaceChartSelectedDateRange()
  const [debouncedChartRange] = useDebounce(chartSelectedDateRange, 100)
  const nomcapsWatt = useMemo(() => {
    return selectedAssets.reduce((result, asset) => ({ ...result, [asset.id]: getNomcap(asset) }), {})
  }, [selectedAssets])

  const timeSeriesQueryResults = useTimeSeriesQueryResults({
    filterTypes: [METER_DATA, SITE_FORECAST, SCHEDULE, E3_META_FORECAST, META_FORECAST],
  })

  const forecastAndBackcastQueryResults = useMemo(() => {
    const siteForecastQueryResults = timeSeriesQueryResults.filter((queryResult) => {
      const forecastSeriesType = queryResult.data?.type as TimeSeriesType
      return QualityTableDataStreamsTypes.includes(forecastSeriesType)
    })
    const backCastQueryResults = timeSeriesQueryResults.filter((queryResult) => queryResult.data?.type === BACK_CAST)

    return [...siteForecastQueryResults, ...backCastQueryResults]
  }, [timeSeriesQueryResults])

  const correlationSet = useMemo(() => {
    const meterDataQueryResults = timeSeriesQueryResults.filter(
      (queryResult) => queryResult.data?.type === METER_DATA && queryResult?.data?.dataStreamId === meterDataToUse,
    )

    const meterDataQueryResultsForEachItem = forecastAndBackcastQueryResults
      .map((fbqr) => {
        const meterData = meterDataQueryResults?.find((mr) => mr?.data?.assetId === fbqr?.data?.assetId)
        if (!meterData) return null
        return {
          ...meterData,
          data: {
            ...meterData?.data,
            id: `${fbqr?.data.id}${fbqr?.data?.type === BACK_CAST ? 'BACK_CAST' : ''}`,
            meterDataFor: fbqr.data?.type,
          },
        }
      })
      .filter((result) => result !== null)

    const transformedForecastAndBackCastQueryResults = [...forecastAndBackcastQueryResults].map((fbr) => {
      return {
        ...fbr,
        data: {
          ...fbr.data,
          id: `${fbr?.data.id}${fbr?.data?.type === BACK_CAST ? 'BACK_CAST' : ''}`,
        },
      }
    })
    return createCorrelations(meterDataQueryResultsForEachItem, transformedForecastAndBackCastQueryResults)
  }, [timeSeriesQueryResults, forecastAndBackcastQueryResults, meterDataToUse])

  // Check id in asset selection and id in correlation set
  const qualityPreviewResult = useMemo(() => {
    return calculateQualityForPreview(correlationSet, nomcapsWatt, debouncedChartRange, debouncedAssetSelection)
  }, [correlationSet, nomcapsWatt, debouncedChartRange, debouncedAssetSelection])

  const handleSelectMeterData = (meterDataId: string) => {
    setMeterDataToUse(meterDataId)
  }

  const hasMeterData = useMemo(() => {
    if (timeSeriesQueryResults) {
      return timeSeriesQueryResults
        .filter(
          (queryResult) => queryResult.data?.type === METER_DATA && queryResult?.data?.dataStreamId === meterDataToUse,
        )
        .some((md) => Object.keys(md?.data?.data || {}).length > 0)
    } else return false
  }, [timeSeriesQueryResults, meterDataToUse])

  const hasForecastData = useMemo(() => {
    if (forecastAndBackcastQueryResults) {
      return forecastAndBackcastQueryResults?.some((sf) => Object.keys(sf?.data?.data || {}).length > 0)
    } else return false
  }, [forecastAndBackcastQueryResults])

  useEffect(() => {
    setMeterDataWithValue(hasMeterData)
  }, [hasMeterData])

  return (
    <Content>
      <Box display="flex" flexDirection="column" width="100%">
        <Box mb={0.5} display="flex" flexDirection="row" alignItems="center">
          <Typography variant="subtitle1">{widgetName}</Typography>
          <Box width="2em">{loading && <InlineLoading />}</Box>
          <MeterDataSelectInput
            disabled={!meterDataToUse}
            showSelectedItems={true}
            onChangeMeterDataSelection={handleSelectMeterData}
          />
        </Box>

        <QualityPreviewTable
          hasMeterData={hasMeterData}
          hasForecastData={hasForecastData}
          result={qualityPreviewResult}
          loading={loading}
          forecastAndBackcastQueryResults={forecastAndBackcastQueryResults}
          isBackCastEnabled={isBackCastEnabled}
        />
      </Box>
    </Content>
  )
}

export default QualityPreview
