import React from 'react'
import Flex from 'ui/styles/Flex'
import TimePeriodAnaAvailabilityAnalysis from 'modules/asset/assetCrud/meterDataCleansing/TimePeriodAndAvailabilityAnalysis'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import ExcludedTimePeriodsFromChart from 'modules/asset/assetCrud/meterDataCleansing/ExcludedTimePeriodsFromChart'
import { FormApi } from 'final-form'
import { Asset } from 'modules/asset/store/asset.types'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import { DetailsKey, DetailsKeyValueGrid, DetailsValue } from 'ui/form/assetForm.style'
import { c } from 'ttag'
import { isAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { Timezone } from 'fixtures/timezones'
import { useCustomMuiStyles } from 'themes/theme-light'

const TimeSelectionForCleansingDiv = styled(Flex)`
  flex: 1 1 0;
`

const AdminSectionInfo = styled(Flex)`
  flex-direction: column;
  flex: 1 1 0;
`

interface TimeSelectionForCleansingProps {
  asset: Asset
  form: FormApi
  totalTimePeriod: MDCTimePeriod
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  onChangeTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  timePeriodToEdit: TimePeriodToHighlightInterface | null
  onEditExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onCancelEditExcludedTimePeriod: () => void
  onSaveExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteExcludedTimePeriod: (timePeriod: TimePeriodToHighlightInterface) => void
  onDeleteAllExcludedTimePeriods: () => void
  cleansingSummary: any
  userTimezone: Timezone
  linkToDefault: boolean
}

const TimeSelectionForCleansing: React.FC<TimeSelectionForCleansingProps> = ({
  asset,
  form,
  totalTimePeriod,
  timePeriodToExcludeFromChart,
  onChangeTimePeriod,
  timePeriodToEdit,
  onEditExcludedTimePeriod,
  onCancelEditExcludedTimePeriod,
  onSaveExcludedTimePeriod,
  onDeleteExcludedTimePeriod,
  onDeleteAllExcludedTimePeriods,
  cleansingSummary,
  userTimezone,
  linkToDefault,
}) => {
  const user = useSelector(getUserResultSelector)
  const classes = useCustomMuiStyles()

  return (
    <TimeSelectionForCleansingDiv direction="column">
      <TimePeriodAnaAvailabilityAnalysis
        form={form}
        totalTimePeriod={totalTimePeriod}
        asset={asset}
        userTimezone={userTimezone}
        linkToDefault={linkToDefault}
      />

      <ExcludedTimePeriodsFromChart
        timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
        onChangeTimePeriod={onChangeTimePeriod}
        timePeriodToEdit={timePeriodToEdit}
        onEditExcludedTimePeriod={onEditExcludedTimePeriod}
        onCancelEditExcludedTimePeriod={onCancelEditExcludedTimePeriod}
        onSaveExcludedTimePeriod={onSaveExcludedTimePeriod}
        onDeleteExcludedTimePeriod={onDeleteExcludedTimePeriod}
        onDeleteAllExcludedTimePeriods={onDeleteAllExcludedTimePeriods}
        userTimezone={userTimezone}
        linkToDefault={linkToDefault}
      />
      {isAdmin(user) && cleansingSummary?.['chosenGP'] !== cleansingSummary?.['closestGP'] && (
        <AdminSectionInfo className={classes.adminComponent}>
          <Box mt={1}>
            <Typography variant="subtitle2">{c('Meter data cleansing')
              .t`Hint: One of the NWP model's grid points around the power plant has a better correlation with the meter data than the nearest grid point. Hence this grid point which is found to be the best match has been chosen as the reference in the data cleansing process. And, there is a potential for improvement of ANN training quality by relocating this power plant towards “chosen grid point” location (see below).`}</Typography>

            <DetailsKeyValueGrid>
              <DetailsKey style={{ width: 'max-content' }}>
                {c('Meter data cleansing').t`Nearest grid point coordinates (LAT/LONG)`}:
              </DetailsKey>
              <DetailsValue fullWidth={true}>
                <Flex justifyContent="right">
                  {cleansingSummary?.['closestGP'] ? cleansingSummary?.['closestGP'] : null}
                </Flex>
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey style={{ width: 'max-content' }}>
                {c('Meter data cleansing').t`Nearest grid point correlation score`}:
              </DetailsKey>
              <DetailsValue fullWidth={true}>
                <Flex justifyContent="right">
                  {cleansingSummary?.['closestGPscore'] ? cleansingSummary?.['closestGPscore'] : null}
                </Flex>
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey style={{ width: 'max-content' }}>
                {c('Meter data cleansing').t`Chosen grid point coordinates (LAT/LONG)`}:
              </DetailsKey>
              <DetailsValue fullWidth={true}>
                <Flex justifyContent="right">
                  {cleansingSummary?.['chosenGP'] ? cleansingSummary?.['chosenGP'] : null}
                </Flex>
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey style={{ width: 'max-content' }}>
                {c('Meter data cleansing').t`Chosen grid point correlation score`}:
              </DetailsKey>
              <DetailsValue fullWidth={true}>
                <Flex justifyContent="right">
                  {cleansingSummary?.['chosenGPscore'] ? cleansingSummary?.['chosenGPscore'] : null}
                </Flex>
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Meter data cleansing').t`Weather model`}:</DetailsKey>
              <DetailsValue fullWidth={true}>
                <Flex justifyContent="right">
                  {cleansingSummary?.['weatherModel'] ? cleansingSummary?.['weatherModel'] : null}
                </Flex>
              </DetailsValue>
            </DetailsKeyValueGrid>
          </Box>
        </AdminSectionInfo>
      )}
    </TimeSelectionForCleansingDiv>
  )
}

export default React.memo(TimeSelectionForCleansing)
