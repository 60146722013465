import { useCreateScheduleInput, useSavedSchedules } from 'modules/workspace/schedule/schedule.api'
import { useDebounce } from 'use-debounce'
import { useEffect, useMemo, useState } from 'react'

import { useSelector } from 'react-redux'
import { seriesSelector } from 'modules/workspace/store/series.state'
import { SeriesArearangeOptions, SeriesOptions } from 'highcharts/highstock'
import { QUERY_SCHEDULE, useQueryMatch } from 'utils/query-string'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import {
  areDatesEqualIgnoringMilliseconds,
  areDatesEqualIgnoringSecondsMilliseconds,
  convertUTCToLocalDate,
  convertZonedTimeToUtc,
} from 'utils/date'

import {
  getScheduleSeriesChartOptions,
  getScheduleSourceDataStreamsTypes,
  ScheduleLocalStorageKeys,
} from 'utils/schedule'
import { Asset } from 'modules/asset/store/asset.types'
import { DataStreamSelectionItem, TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { cropSeries } from 'utils/timeseries'
import { useWorkspaceChartWholeDateRange } from 'utils/workspace'
import { addSeconds } from 'date-fns'

const DefaultScheduleInterval = 900000

export const useCreateScheduleSeries = () => {
  const isCreateScheduleActive = useQueryMatch(QUERY_SCHEDULE)
  const sourceTypes = getScheduleSourceDataStreamsTypes()
  const [scheduleSeries, setScheduleSeries] = useState<SeriesOptions[]>([])

  const createScheduleInputQueryResult = useCreateScheduleInput()
  const createScheduleInputFetched = createScheduleInputQueryResult?.isFetched
  const createScheduleInputData = createScheduleInputQueryResult?.data
  const userTimezone = useSelector(getUserTimezoneSelector)

  const savedSchedulesQueryResult = useSavedSchedules()
  const savedSchedulesFetched = savedSchedulesQueryResult?.isFetched
  const savedSchedulesData = savedSchedulesQueryResult?.data

  const chartWholePeriodInUTC = useWorkspaceChartWholeDateRange()

  const series = useSelector(seriesSelector)

  const [debouncedInput] = useDebounce(createScheduleInputData, 30)

  // console.log({ debouncedInput })

  useEffect(() => {
    if (
      isCreateScheduleActive &&
      savedSchedulesFetched &&
      createScheduleInputFetched &&
      debouncedInput?.asset?.id &&
      debouncedInput?.sourceDataStream?.id &&
      debouncedInput?.targetScheduleDataStream?.id
    ) {
      const inputAsset = debouncedInput?.asset as Asset
      const inputSource = debouncedInput?.sourceDataStream as DataStreamSelectionItem
      const inputTargetSchedule = debouncedInput?.targetScheduleDataStream as DataStreamSelectionItem
      const inputStartDate = debouncedInput.start?.date || debouncedInput.start
      const inputEndDate = debouncedInput.end?.date || debouncedInput.end

      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

      const schedulePeriod = [
        convertZonedTimeToUtc(inputStartDate, localTimezone),
        convertZonedTimeToUtc(inputEndDate, localTimezone),
      ]

      const findAvailableCapacityPlanned = series.find((serie) => {
        return (
          serie.custom?.datastreamType === TimeSeriesType.CAPACITY_DATA &&
          serie.custom?.datastreamSubType === TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY
        )
      })

      const getDragMaximum = () => {
        if (findAvailableCapacityPlanned && findAvailableCapacityPlanned.data?.length > 0) {
          const resultLength = findAvailableCapacityPlanned.data.length
          return (
            findAvailableCapacityPlanned.data[resultLength - 1].y ||
            findAvailableCapacityPlanned.data[resultLength - 1][1]
          )
        }
        return undefined
      }

      const maxY = getDragMaximum()

      const scheduleSeriesOptions = getScheduleSeriesChartOptions({
        asset: inputAsset,
        inputSource,
        targetDataStream: inputTargetSchedule,
        maxY: maxY,
        start: inputStartDate,
        end: inputEndDate,
      })

      // Check if there is a changed version in localstorage
      const dataInLocal = JSON.parse(localStorage.getItem(ScheduleLocalStorageKeys.outPutSeries))

      const dataInLocalSamePeriod = dataInLocal?.custom
        ? areDatesEqualIgnoringSecondsMilliseconds(inputStartDate, dataInLocal?.custom?.scheduleStartDate) &&
          areDatesEqualIgnoringSecondsMilliseconds(inputEndDate, dataInLocal?.custom?.scheduleEndDate)
        : false

      if (
        dataInLocal &&
        dataInLocal?.custom?.assetId === inputAsset.id &&
        dataInLocal?.custom?.dataStreamId === inputSource.id &&
        dataInLocal?.custom?.scheduleDataStreamId === inputTargetSchedule?.id &&
        dataInLocalSamePeriod &&
        !debouncedInput?.useSource
      ) {
        // console.log('Data from local storage')
        // Data from local storage
        const cropped = cropSeries([{ ...scheduleSeriesOptions, data: dataInLocal.data }], schedulePeriod)
        const transformedCropped = cropped?.map((cs) => {
          return {
            ...cs,
            custom: {
              ...cs.custom,
              detectedInterval: dataInLocal.custom?.detectedInterval || DefaultScheduleInterval,
            },
          }
        })
        setScheduleSeries(transformedCropped)
      } else {
        localStorage.setItem(ScheduleLocalStorageKeys.seriesChanged, 'false')
        localStorage.setItem(ScheduleLocalStorageKeys.outPutSeries, JSON.stringify({ custom: undefined }))

        // Find if there is a saved forecast series for Asset and Schedule datastream
        const savedConfig =
          savedSchedulesData && savedSchedulesData.length
            ? savedSchedulesData?.find((smf) => {
                return (
                  smf?.asset?.id === inputAsset.id &&
                  smf?.targetScheduleDataStream?.id === inputTargetSchedule?.id &&
                  smf?.sourceDataStream?.id === inputSource?.id
                )
              })
            : undefined

        let datesInSavedSamePeriod = false
        if (savedConfig) {
          const savedStartDate = savedConfig?.start?.date || savedConfig?.start
          const savedEndDate = savedConfig?.end?.date || savedConfig?.end
          datesInSavedSamePeriod =
            areDatesEqualIgnoringMilliseconds(savedStartDate, inputStartDate) &&
            areDatesEqualIgnoringMilliseconds(savedEndDate, inputEndDate)
        }

        if (savedConfig && !debouncedInput?.useSource && savedConfig?.savedSeries && datesInSavedSamePeriod) {
          // console.log('saved config')
          // If there is a saved config use that series
          scheduleSeriesOptions.data = savedConfig?.savedSeries
          const cropped = cropSeries([scheduleSeriesOptions], schedulePeriod)
          const transformedCropped = cropped?.map((cs) => {
            return {
              ...cs,
              custom: {
                ...cs.custom,
                detectedInterval: savedConfig?.detectedInterval || DefaultScheduleInterval,
              },
            }
          })
          setScheduleSeries(transformedCropped)
        } else {
          // console.log('from series')
          // If there is no saved series find it from the main chart series
          const filteredSeries = series?.find((s) => {
            return (
              sourceTypes.includes(s?.custom.datastreamType) &&
              s?.custom.assetId == inputAsset.id &&
              s?.custom.datastreamId === inputSource.id
            )
          })

          if (filteredSeries) {
            const transformedSeries = {
              ...filteredSeries,
              ...scheduleSeriesOptions,
              data: filteredSeries?.data,
              custom: {
                ...scheduleSeriesOptions.custom,
                result: filteredSeries?.result,
                detectedInterval: filteredSeries?.custom?.detectedInterval || DefaultScheduleInterval,
              },
            }

            const cropped = cropSeries([transformedSeries], schedulePeriod) as SeriesArearangeOptions[]
            setScheduleSeries(cropped)
          } else {
            setScheduleSeries([scheduleSeriesOptions])
          }
        }
      }
    } else {
      setScheduleSeries([])
    }
  }, [
    debouncedInput?.useSource,
    JSON.stringify(debouncedInput),
    series,
    JSON.stringify(savedSchedulesData),
    createScheduleInputFetched,
    savedSchedulesFetched,
    isCreateScheduleActive,
    userTimezone,
    JSON.stringify(sourceTypes),
    JSON.stringify(chartWholePeriodInUTC),
  ])

  /**
   * Crop schedule series by main chart period.
   * Because, schedule series is created using the schedule period
   * and this period may not be part of main chart period
   */
  const cropSeriesWithMainChartRange = useMemo(() => {
    if (scheduleSeries.length) {
      const chartWholePeriodInLocal = [
        convertUTCToLocalDate(chartWholePeriodInUTC[0]),
        addSeconds(convertUTCToLocalDate(chartWholePeriodInUTC[1]), 1),
      ]
      return cropSeries(scheduleSeries, chartWholePeriodInLocal)
    } else return []
  }, [JSON.stringify(chartWholePeriodInUTC), scheduleSeries])

  return cropSeriesWithMainChartRange
}
