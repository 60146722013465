import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import { Asset, MeterdataSummaries } from 'modules/asset/store/asset.types'
import createReducer from 'utils/createReducer'
import { request } from 'utils/request'
import { createSelector } from 'reselect'

// state

interface State {
  result: MeterdataSummaries
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: {},
  loading: false,
  error: null,
}

// types

export const GET_METERDATA_SUMMARIES_REQUEST = 'GET_METERDATA_SUMMARIES_REQUEST'
export const GET_METERDATA_SUMMARIES_SUCCESS = 'GET_METERDATA_SUMMARIES_SUCCESS'
export const GET_METERDATA_SUMMARIES_FAILURE = 'GET_METERDATA_SUMMARIES_FAILURE'
export const GET_METERDATA_SUMMARIES_DISMISS = 'GET_METERDATA_SUMMARIES_DISMISS'
export type GET_METERDATA_SUMMARIES_REQUEST = typeof GET_METERDATA_SUMMARIES_REQUEST
export type GET_METERDATA_SUMMARIES_SUCCESS = typeof GET_METERDATA_SUMMARIES_SUCCESS
export type GET_METERDATA_SUMMARIES_FAILURE = typeof GET_METERDATA_SUMMARIES_FAILURE
export type GET_METERDATA_SUMMARIES_DISMISS = typeof GET_METERDATA_SUMMARIES_DISMISS
export type GET_METERDATA_SUMMARIES_ACTION_TYPE =
  | GET_METERDATA_SUMMARIES_REQUEST
  | GET_METERDATA_SUMMARIES_SUCCESS
  | GET_METERDATA_SUMMARIES_FAILURE
  | GET_METERDATA_SUMMARIES_DISMISS

interface GetMeterdataSummariesAction {
  type: GET_METERDATA_SUMMARIES_ACTION_TYPE
  // SUCCESS
  summaries: MeterdataSummaries
  // ERROR
  error: State['error']
  // REQUEST
  assets: Asset[]
}

// reducers

const result = createReducer<State['result'], GetMeterdataSummariesAction>(
  (state = initialState.result, { type, summaries }) => {
    if (type === GET_METERDATA_SUMMARIES_SUCCESS) {
      return { ...state, ...summaries }
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetMeterdataSummariesAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case GET_METERDATA_SUMMARIES_REQUEST:
        return true
      case GET_METERDATA_SUMMARIES_SUCCESS:
      case GET_METERDATA_SUMMARIES_FAILURE:
        return false
    }
    return state
  },
)

const error = createReducer<State['error'], GetMeterdataSummariesAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case GET_METERDATA_SUMMARIES_SUCCESS:
      case GET_METERDATA_SUMMARIES_DISMISS:
        return null
      case GET_METERDATA_SUMMARIES_FAILURE:
        return error
    }
    return state
  },
)

export const getMeterdataSummariesReducer = combineReducers({
  result,
  loading,
  error,
})

export const getMeterdataSummariesResultSelector = createSelector<any, MeterdataSummaries, MeterdataSummaries>(
  (state) => state.asset.getMeterdataSummaries.result,
  (result) => result,
)

export const getMeterdataSummariesLoadingSelector = createSelector<any, boolean, boolean>(
  (state) => state.asset.getMeterdataSummaries.loading,
  (loading) => loading,
)

export const getMeterdataSummariesErrorSelector = createSelector<any, string | null, string | null>(
  (state) => state.asset.getMeterdataSummaries.error,
  (error) => error,
)

// api
export const getMeterdataSummaries = (assets: Asset[], fromMeterDataCleansing?: boolean) => {
  return request(() => {
    const requestUrl = fromMeterDataCleansing
      ? 'api/upload/meterdata/v2/summaries'
      : 'api/upload/meterdata/v1/summaries'
    return axios.post<MeterdataSummaries>(
      requestUrl,
      assets.map((asset) => asset.id),
    )
  })
}

// sagas
export function* getMeterdataSummariesSaga({ assets }: GetMeterdataSummariesAction): SagaIterator {
  const result = yield call(getMeterdataSummaries, assets)

  if (result.isSuccessful) {
    const summaries = result.getData()
    yield put({
      type: GET_METERDATA_SUMMARIES_SUCCESS,
      summaries,
    })
  } else {
    const error = result.getError()
    yield put({ type: GET_METERDATA_SUMMARIES_FAILURE, error })
  }
}
