import React from 'react'
import PopperTooltip from 'ui/PopperTooltip'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { c } from 'ttag'
import { SizeProp } from '@fortawesome/fontawesome-svg-core'

interface InBuiltEntityIconProps {
  message?: string
  size?: SizeProp
}

const InBuiltEntityIcon: React.FC<InBuiltEntityIconProps> = ({ message, size }) => {
  return (
    <PopperTooltip
      popperLabel={<FontAwesomeActionIcon color={'#a9a8a8'} size={size || 'sm'} icon={'lock'} />}
      popperContent={message ? <>{message}</> : <>{c('EnercastEntity').t`This is an in-built penalty.`}</>}
    />
  )
}

export default React.memo(InBuiltEntityIcon)
