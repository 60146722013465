import React, { useMemo } from 'react'
import { Button } from '@material-ui/core'
import { ReTableGenericToolbarContainer } from 'ui/styles/table'
import ReTableToolbar from 'modules/reTable/ReTableToolbar'
import { Column, ReTableId } from 'modules/reTable/reTable.types'
import { math } from 'polished'
import { editableRowActionsCellWidth } from 'modules/reTable/reTable.functionality'
import { TableHeaderActionCellWidth } from 'modules/reTable/ReTableHeaderActionsCell'
import { useIsReadOnlyUser } from 'utils/user'

interface DeliveryTargetsTableToolbarProps {
  onAddNewItem: (event: React.MouseEvent<HTMLElement>) => void
  columns: Column[]
  tableHeaderHasActions: boolean
  tableId: ReTableId
  addItemButtonLabel: string
  addBtnClassName?: string
  isTargetTable?: string
}

const DeliveryTablesGenericToolbar: React.FC<DeliveryTargetsTableToolbarProps> = ({
  onAddNewItem,
  tableHeaderHasActions,
  columns,
  tableId,
  addItemButtonLabel,
  addBtnClassName = 'add-new-item',
  isTargetTable,
}) => {
  const columnsWidth = useMemo(() => {
    const width = (columns || []).reduce((sum, column) => {
      return math(`${sum} + ${column.width}`)
    }, '0')
    return math(
      `${width} + ${editableRowActionsCellWidth} + ${tableHeaderHasActions ? TableHeaderActionCellWidth : 0} + ${
        isTargetTable ? '0.8em' : '0.1em'
      }`,
    )
  }, [columns, isTargetTable])
  const isReadOnlyUser = useIsReadOnlyUser()

  const actions = useMemo(() => {
    return (
      <Button
        disabled={isReadOnlyUser}
        className={addBtnClassName}
        onClick={(e) => onAddNewItem(e)}
        color="primary"
        size="small"
      >
        {addItemButtonLabel}
      </Button>
    )
  }, [onAddNewItem, isReadOnlyUser, addBtnClassName])

  return (
    <ReTableGenericToolbarContainer width={columnsWidth}>
      <ReTableToolbar
        showSearchField={true}
        id={tableId}
        actions={actions}
        showClearSorting={true}
        width={columnsWidth}
      />
    </ReTableGenericToolbarContainer>
  )
}

export default React.memo(DeliveryTablesGenericToolbar)
