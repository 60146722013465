import React, { useCallback } from 'react'
import {
  Column,
  RETABLE_ID_DELIVERY_TARGETS,
  ReTableContextMenuItem,
  ReTableRowContextActions,
} from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { ReTableBody } from 'modules/reTable/ReTableBody'

import { ROUTE_DATA_FLOW } from 'modules/app/Routes'

import { defaultDeliveryTargetId } from 'utils/delivery'
import { MasterDataConfig } from 'modules/masterData/masterData.types'
import ReTableEditableRowActionsCell from 'modules/reTable/ReTableEditableRowActionsCell'
import { c, t } from 'ttag'

interface MasterDataCustomFormatsTableBodyProps {
  onCopyItem: (event: React.MouseEvent<HTMLElement>, item: MasterDataConfig) => void
  onEditItem: (data: MasterDataConfig) => void
  onDeleteItem: (data: MasterDataConfig) => void
  columns: Column[]
  visibleItems: MasterDataConfig[]
  tableHeaderHasActions: boolean
}

const MasterDataCustomFormatsTableBody: React.FC<MasterDataCustomFormatsTableBodyProps> = ({
  onEditItem,
  onDeleteItem,
  columns,
  visibleItems,
  tableHeaderHasActions,
}) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_DELIVERY_TARGETS)

  const actionMenuItems: ReTableContextMenuItem[] = [
    {
      itemName: ReTableRowContextActions.EDIT_ROW,
      itemLabel: t`Edit and upload`,
      icon: 'pen',
    },
    {
      itemName: ReTableRowContextActions.DELETE_ROW,
      itemLabel: t`Delete`,
      icon: 'trash-alt',
    },
  ]

  const handleMenuItemClick = useCallback((action: ReTableRowContextActions, rowItem: MasterDataConfig) => {
    const { EDIT_ROW, DELETE_ROW } = ReTableRowContextActions
    if (action === EDIT_ROW) {
      onEditItem(rowItem)
    }
    if (action === DELETE_ROW) {
      onDeleteItem(rowItem)
    }
  }, [])

  const getCustomEditableRowActionsCell = useCallback((row: MasterDataConfig) => {
    return (
      <ReTableEditableRowActionsCell
        showContextMenu={true}
        showFormActions={false}
        actionMenuItems={actionMenuItems}
        onClickMenuItem={handleMenuItemClick}
        dialogText={row.displayName}
        dialogContext={c('Master data').t`Master data custom format`}
        pageRoute={''}
        navigationDialogKey={'masterDataCustomFormat'}
        showTableRowContextMenu={true}
        rowItem={row}
      />
    )
  }, [])

  return (
    <ReTableBody>
      {visibleItems.map((row: MasterDataConfig) => {
        return (
          <ReTableEditableRow
            key={row.id}
            isRowEdit={false}
            rowItem={row}
            columns={columns}
            onEditRowItem={onEditItem}
            onDeleteRowItem={onDeleteItem}
            dialogText={row.displayName}
            dialogContext={c('Master data').t`Master data custom format`}
            tableHeaderHasActions={tableHeaderHasActions}
            isCustomRow={false}
            showEditableRowActions={row.id != defaultDeliveryTargetId}
            isShaded={false}
            query={query}
            pageRoute={ROUTE_DATA_FLOW}
            navigationDialogKey={'masterDataCustomFormat'}
            tableToolbarHasActions={true}
            customEditableRowActionsCell={getCustomEditableRowActionsCell(row)}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(MasterDataCustomFormatsTableBody)
