import React from 'react'
import { Box } from '@material-ui/core'
import { getPenaltyGeneratorTypes, getPenaltyGridTypes } from 'utils/penaltyRegulations'
import PenaltyRegulationSharedIcon from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationSharedIcon'
import { PenaltyRegulationNew } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'

interface PenaltyRegulationNameProps {
  regulation: PenaltyRegulationNew
}

const PenaltyRegulationBriefInfo: React.FC<PenaltyRegulationNameProps> = ({ regulation }) => {
  const grid = getPenaltyGridTypes().find((g) => g.id === regulation.gridType)
  const generator = getPenaltyGeneratorTypes().find((g) => g.id === regulation.generatorType)
  // const state = (regulation.state || '').replace('_', ' ')

  return (
    <>
      <Box ml={0.5} mr={0.8}>
        <PenaltyRegulationSharedIcon shared={regulation.shared} />
      </Box>
      {regulation.regulationName} ({grid?.label} grid for {generator?.label} plants)
      {/*With state information*/}
      {/*{regulation.name} ({grid?.label} grid for {generator?.label} plants in {state})*/}
    </>
  )
}

export default React.memo(PenaltyRegulationBriefInfo)
