import React, { useCallback, useMemo } from 'react'
import Alert from '@material-ui/lab/Alert'
import { tConnectivityAndBanners } from 'fixtures/commonTranslations'
import TimeAgo from 'ui/datetime/TimeAgo'
import { useSelector } from 'react-redux'
import { sessionStatusChangedDateSelector, sessionStatusSelector } from 'modules/app/checkConnectivity.state'
import { Button } from '@material-ui/core'
import { getReloadWindowQueryObj, useQueryString } from 'utils/query-string'
import { SessionStatus } from 'modules/app/app.types'

const ActionFailedBanner: React.FC = () => {
  const { onUpdateQueryString } = useQueryString()
  const sessionStatusChangedDate = useSelector(sessionStatusChangedDateSelector)
  const sessionStatus = useSelector(sessionStatusSelector)

  const sessionTimeAgo = <TimeAgo key="timeago" date={sessionStatusChangedDate} />
  const translations = useMemo(() => tConnectivityAndBanners(sessionTimeAgo), [sessionStatusChangedDate])

  const handleReload = useCallback(() => {
    onUpdateQueryString(getReloadWindowQueryObj())
  }, [onUpdateQueryString])

  return (
    <Alert
      severity="warning"
      variant="filled"
      action={
        (sessionStatus === SessionStatus.AUTHED || sessionStatus === SessionStatus.EXPIRED) && (
          <Button onClick={handleReload} size="small">
            {translations.actionReload}
          </Button>
        )
      }
    >
      <div>
        {sessionStatus === SessionStatus.AUTHED
          ? translations.textActionFailedOnline
          : sessionStatus === SessionStatus.EXPIRED
          ? translations.textActionFailedExpired
          : translations.textActionFailedOffline}
      </div>
    </Alert>
  )
}

export default ActionFailedBanner
