import React from 'react'
import { Button, ButtonGroup, ButtonGroupProps, CircularProgress } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { green } from '@material-ui/core/colors'
import styled from 'styled-components'
import { useCustomMuiStyles } from 'themes/theme-light'

const LoadingWrapper = styled.div`
  position: relative;
`
const LoadingProgress = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    color: ${green[500]};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }
`

export interface SubmitButtonProps {
  loading?: boolean
  disabled?: boolean
  group?: boolean
  isAdminComponent?: boolean
}

const LoadingButton: React.FC<SubmitButtonProps & (ButtonProps | ButtonGroupProps)> = ({
  loading = false,
  disabled = false,
  group = false,
  isAdminComponent,
  children,
  className,
  ...rest
}) => {
  const ButtonComponent = group ? ButtonGroup : Button
  //Style
  const classes = useCustomMuiStyles({ important: true })
  return (
    <LoadingWrapper className={className}>
      <ButtonComponent
        disabled={loading || disabled}
        {...rest}
        className={isAdminComponent ? classes.adminComponent : 'inherit'}
      >
        {children}
      </ButtonComponent>
      {loading && <LoadingProgress size={24} />}
    </LoadingWrapper>
  )
}

export default React.memo(LoadingButton)
