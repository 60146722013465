import { createSelector } from 'reselect'

import createReducer from 'utils/createReducer'
import { SeriesOptions } from 'highcharts/highstock'

// state

interface State {
  series: SeriesOptions[]
}

const initialState: State = {
  series: [],
}

// types

export const SET_SERIES = 'SET_SERIES'
export type SET_SERIES = typeof SET_SERIES
export type SERIES_ACTION_TYPE = SET_SERIES

interface SeriesAction {
  type: SERIES_ACTION_TYPE
  series: State['series']
}

// reducers

export const seriesReducer = createReducer<State['series'], SeriesAction>(
  (state = initialState.series, { type, series }) => {
    if (type === SET_SERIES) {
      return series
    }
    return state
  },
)

// selectors
export const seriesSelector = createSelector<any, State['series'], State['series']>(
  (state) => state.workspace.series,
  (series) => series,
)

// api

// sagas
