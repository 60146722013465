import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import axios from 'axios'

import { request } from 'utils/request'
import createReducer from 'utils/createReducer'
import { AreaConfig } from '../../dataStreams/dataStreams.types'
import { Area, TYPE_AREA } from 'modules/asset/store/asset.types'
import { getAreaIds } from 'fixtures/areaLabels'
import { createSelector } from 'reselect'

// state

interface State {
  result: AreaConfig[]
  areas: Area[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  areas: [],
  loading: false,
  error: null,
}

// types

export const GET_AREACONFIGS_REQUEST = 'GET_AREACONFIGS_REQUEST'
export const GET_AREACONFIGS_SUCCESS = 'GET_AREACONFIGS_SUCCESS'
export const GET_AREACONFIGS_FAILURE = 'GET_AREACONFIGS_FAILURE'
export const GET_AREACONFIGS_DISMISS = 'GET_AREACONFIGS_DISMISS'
export type GET_AREACONFIGS_REQUEST = typeof GET_AREACONFIGS_REQUEST
export type GET_AREACONFIGS_SUCCESS = typeof GET_AREACONFIGS_SUCCESS
export type GET_AREACONFIGS_FAILURE = typeof GET_AREACONFIGS_FAILURE
export type GET_AREACONFIGS_DISMISS = typeof GET_AREACONFIGS_DISMISS
export type GET_AREACONFIGS_ACTION_TYPE =
  | GET_AREACONFIGS_REQUEST
  | GET_AREACONFIGS_SUCCESS
  | GET_AREACONFIGS_FAILURE
  | GET_AREACONFIGS_DISMISS

export const GET_AREA_FORECAST_REQUEST = 'GET_AREA_FORECAST_REQUEST'
export const GET_AREA_FORECAST_SUCCESS = 'GET_AREA_FORECAST_SUCCESS'
export const GET_AREA_FORECAST_FAILURE = 'GET_AREA_FORECAST_FAILURE'
export const GET_AREA_FORECAST_DISMISS = 'GET_AREA_FORECAST_DISMISS'
export type GET_AREA_FORECAST_REQUEST = typeof GET_AREA_FORECAST_REQUEST
export type GET_AREA_FORECAST_SUCCESS = typeof GET_AREA_FORECAST_SUCCESS
export type GET_AREA_FORECAST_FAILURE = typeof GET_AREA_FORECAST_FAILURE
export type GET_AREA_FORECAST_DISMISS = typeof GET_AREA_FORECAST_DISMISS
export type GET_AREA_FORECAST_ACTION_TYPE =
  | GET_AREA_FORECAST_REQUEST
  | GET_AREA_FORECAST_SUCCESS
  | GET_AREA_FORECAST_FAILURE
  | GET_AREA_FORECAST_DISMISS

interface GetAreaConfigAction {
  type: GET_AREACONFIGS_ACTION_TYPE
  // SUCCESS
  areaConfigs: State['result']
  // ERROR
  error: State['error']
}

// reducers

const result = createReducer<State['result'], GetAreaConfigAction>(
  (state = initialState.result, { type, areaConfigs }) => {
    if (type === GET_AREACONFIGS_SUCCESS) {
      return areaConfigs
    }
    return state
  },
)

const areas = createReducer<State['areas'], GetAreaConfigAction>(
  (state = initialState.areas, { type, areaConfigs }) => {
    if (type === GET_AREACONFIGS_SUCCESS) {
      const areaIds = getAreaIds()
      return areaConfigs.map<Area>((areaConfig) => ({
        type: TYPE_AREA,
        id: areaConfig.id,
        name: areaIds[areaConfig.id] || 'unknown area',
        productCode: areaConfig.productCode,
      }))
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetAreaConfigAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case GET_AREACONFIGS_REQUEST:
      return true
    case GET_AREACONFIGS_SUCCESS:
    case GET_AREACONFIGS_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], GetAreaConfigAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case GET_AREACONFIGS_SUCCESS:
    case GET_AREACONFIGS_DISMISS:
      return null
    case GET_AREACONFIGS_FAILURE:
      return error
  }
  return state
})

export const getAreaConfigsReducer = combineReducers({
  result,
  areas,
  loading,
  error,
})

export const getAreaConfigsResultSelector = createSelector<any, AreaConfig[], AreaConfig[]>(
  (state) => state.data.getAreaConfigs.result,
  (result) => result,
)
export const getAreaConfigsAreasSelector = createSelector<any, Area[], Area[]>(
  (state) => state.data.getAreaConfigs.areas,
  (areas) => areas,
)
export const getAreaConfigsLoadingSelector = createSelector<any, boolean, boolean>(
  (state) => state.data.getAreaConfigs.loading,
  (loading) => loading,
)
export const getAreaConfigsErrorSelector = createSelector<any, string | null, string | null>(
  (state) => state.data.getAreaConfigs.error,
  (error) => error,
)

// api
export const getAreaConfigs = () => {
  return request(() => axios.get<AreaConfig[]>('/api/usersettings/areaconfig'))
}

// sagas
export function* getAreaConfigsSaga(): SagaIterator {
  const result = yield call(getAreaConfigs)
  if (result.isSuccessful) {
    // result may be empty
    const areaConfigs = result.getData() || []
    yield put({ type: GET_AREACONFIGS_SUCCESS, areaConfigs })
  } else {
    const error = result.getError()
    yield put({ type: GET_AREACONFIGS_FAILURE, error })
  }
}
