import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Table } from '@material-ui/core'
import styled from 'styled-components'
import VirtualScroll, { GetItemHeight, VirtualRange } from 'ui/scroll/VirtualScroll'
import { useDispatch } from 'react-redux'
import {
  reTableCollapsedSearchSelector,
  reTableCollapsedSelector,
  reTableColumnsAvailableSelector,
  reTableColumnsSelectedSelector,
  reTableFiltersSelector,
  reTableSearchSelector,
  reTableSortSelector,
  reTableVirtualRangeSelector,
} from 'modules/reTable/redux_store/state/view.state'
import { ReTableId, ReTableItem, ReTableSortData, Sort } from 'modules/reTable/reTable.types'
import { reTableFilter, reTableSort } from 'modules/reTable/reTable.functionality'
import {
  RETABLE_COLLAPSE_SUBTREES_SEARCH,
  RETABLE_EXPAND_SUBTREES_SEARCH,
  RETABLE_INIT_COLUMNS_SELECTED,
  RETABLE_INIT_TREE,
  RETABLE_SET_COLLAPSIBLE_IDS,
  RETABLE_SET_COLUMNS_SELECTED,
  RETABLE_SET_DEFAULT_SORT,
  RETABLE_SET_FILTERED_ITEMS,
  RETABLE_SET_SELECTED_IDS,
  RETABLE_SET_VIRTUAL_RANGE,
  RETABLE_TOGGLE_SUBTREE,
  RETABLE_TOGGLE_SUBTREE_SEARCH,
} from 'modules/reTable/redux_store/reTable.action.types'
import {
  useReTableSelectorWithId,
  useSavedCollapsedInitialized,
  useSavedCollapsedResult,
  useSavedColumnNamesInitialized,
  useSavedColumnNamesResult,
} from 'modules/reTable/reTable.hooks'
import { math } from 'polished'
import { usePrevious } from 'utils/state'
import { useDebounce, useDebouncedCallback } from 'use-debounce'

interface TableWithStickyHeaderProps {
  min_width: string
  item_height: number
}

const TableWithStickyHeader = styled(Table)<TableWithStickyHeaderProps>`
  &.MuiTable-root {
    table-layout: fixed;
    min-width: ${(props) => props.min_width};
  }

  .MuiTableHead-root .MuiTableCell-root {
    position: sticky;
    top: 0;
    z-index: 10;
  }

  .MuiTableCell-head {
    height: ${(props) => props.item_height}px;
  }

  .MuiTableCell-body {
    height: ${(props) => props.item_height}px;
  }
`

export interface ReTableProps {
  id: ReTableId
  items: ReTableItem[]
  itemHeight: number | GetItemHeight
  hasExtendedHeader: boolean
  itemsPaddingHeader: number
  itemsPaddingFooter: number
  renderAheadCount?: number
  isNarrow?: boolean
  collapsibleIds?: string[]
  selectedIds?: string[]
  defaultCollapsed?: string[]
  defaultColumnsSelected?: string[]
  defaultSort?: Sort
  sortData: ReTableSortData
  onItemsToRenderChange: (itemsToRender: ReTableItem[], sort: Sort, virtualRange: VirtualRange) => void
  currentPaginationItems?: number
  filterData?: any
}

const ReTable: React.FC<ReTableProps> = ({
  id,
  items,
  itemHeight,
  tableHeight,
  hasExtendedHeader,
  itemsPaddingHeader,
  itemsPaddingFooter,
  renderAheadCount = 25,
  isNarrow = false,
  collapsibleIds,
  selectedIds,
  defaultCollapsed,
  defaultColumnsSelected,
  defaultSort,
  sortData,
  onItemsToRenderChange,
  currentPaginationItems,
  filterData,
  children,
  ...rest
}) => {
  const dispatch = useDispatch()

  // current state
  const collapsed = useReTableSelectorWithId(reTableCollapsedSelector, id)
  const collapsedSearch = useReTableSelectorWithId(reTableCollapsedSearchSelector, id)
  const columnsAvailable = useReTableSelectorWithId(reTableColumnsAvailableSelector, id)
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, id)
  const filters = useReTableSelectorWithId(reTableFiltersSelector, id)
  const search = useReTableSelectorWithId(reTableSearchSelector, id)
  const sort = useReTableSelectorWithId(reTableSortSelector, id)
  const virtualRange = useReTableSelectorWithId(reTableVirtualRangeSelector, id)

  // for handling collapsed state while search is active
  const previousSearch = usePrevious(search)

  // defaults and external changes
  useEffect(() => {
    if (!defaultSort) return
    dispatch({ type: RETABLE_SET_DEFAULT_SORT, table: id, sort: defaultSort })
  }, [defaultSort])

  useEffect(() => {
    if (!collapsibleIds) return
    dispatch({ type: RETABLE_SET_COLLAPSIBLE_IDS, table: id, collapsibleIds: collapsibleIds })
  }, [collapsibleIds])

  useEffect(() => {
    if (!selectedIds) return
    dispatch({ type: RETABLE_SET_SELECTED_IDS, table: id, selectedIds: selectedIds })
  }, [selectedIds])

  // user saved state

  const savedCollapsed = useSavedCollapsedResult(id)
  const savedCollapsedInitialized = useSavedCollapsedInitialized(id)

  const [collapseInitialized, setCollapseInitialized] = useState(false)
  useEffect(() => {
    if (!savedCollapsedInitialized || collapseInitialized) return

    if (Array.isArray(savedCollapsed)) {
      // we got user saved values, no need to save them again
      dispatch({ type: RETABLE_INIT_TREE, table: id, ids: savedCollapsed })
    } else {
      // user never saved values, so we save them now
      const collapsedIds = defaultCollapsed || collapsibleIds || []
      dispatch({ type: search ? RETABLE_TOGGLE_SUBTREE_SEARCH : RETABLE_TOGGLE_SUBTREE, table: id, ids: collapsedIds })
    }
    setCollapseInitialized(true)
  }, [collapseInitialized, savedCollapsedInitialized, savedCollapsed, search, id, defaultCollapsed, collapsibleIds])

  const savedColumnNames = useSavedColumnNamesResult(id)
  const savedColumnNamesInitialized = useSavedColumnNamesInitialized(id)

  const [columnsSelectedInitialized, setColumnsSelectedInitialized] = useState(false)
  useEffect(() => {
    if (!savedColumnNamesInitialized || columnsSelectedInitialized || columnsAvailable.length === 0) return

    const columnsAvailableNames = columnsAvailable.map((column) => column.name)
    const defaultColumnNames = defaultColumnsSelected || columnsAvailableNames || []
    if (Array.isArray(savedColumnNames)) {
      // only use columns that actually exist
      const selectedColumnNames = columnsAvailable.reduce<string[]>((result, defaultColumn) => {
        let isSelected = false
        if (savedColumnNames.length > 0) {
          isSelected = defaultColumn.fixed || savedColumnNames.find((c) => c === defaultColumn.name)
        } else if (defaultColumnNames.includes(defaultColumn.name)) {
          isSelected = true
        }
        return isSelected ? [...result, defaultColumn.name] : result
      }, [])

      // we got user saved values, no need to save them again
      dispatch({ type: RETABLE_INIT_COLUMNS_SELECTED, table: id, columnsSelected: selectedColumnNames })
    } else {
      // user never saved values, so we save them now
      dispatch({ type: RETABLE_SET_COLUMNS_SELECTED, table: id, columnsSelected: defaultColumnNames })
    }
    setColumnsSelectedInitialized(true)
  }, [
    columnsSelectedInitialized,
    savedColumnNamesInitialized,
    savedColumnNames,
    defaultColumnsSelected,
    columnsAvailable,
    id,
  ])

  const [userDataIsLoading] = useDebounce(!(collapseInitialized && columnsSelectedInitialized), 100)

  // virtual table

  const minWidth = useMemo(() => {
    return isNarrow
      ? undefined
      : columnsSelected.reduce((sum, column) => {
          return math(`${sum} + ${column.width}`)
        }, '0')
  }, [columnsSelected, isNarrow])

  const handleVirtualRangeChange = useCallback(
    (updatedVirtualRange: VirtualRange) => {
      dispatch({ type: RETABLE_SET_VIRTUAL_RANGE, table: id, virtualRange: updatedVirtualRange })
    },
    [id],
  )

  const debouncedSetFilteredItems = useDebouncedCallback((filteredItems: ReTableItem[]) => {
    const itemIds = filteredItems.map((item) => item.id)
    dispatch({ type: RETABLE_SET_FILTERED_ITEMS, table: id, itemIds })
  }, 1000)

  const itemsToRender = useMemo(() => {
    const filteredItems = userDataIsLoading
      ? []
      : reTableFilter({
          items,
          collapsed,
          collapsedSearch,
          columns: columnsSelected,
          filters,
          search,
          selected: selectedIds || [],
          sort,
        })

    const sortedItems = reTableSort({
      items: filteredItems,
      columns: columnsSelected,
      sort,
      data: sortData,
    })
    return sortedItems
  }, [
    userDataIsLoading,
    items,
    collapsed,
    collapsedSearch,
    savedCollapsed,
    savedCollapsedInitialized,
    collapsibleIds,
    columnsSelected,
    filters,
    search,
    selectedIds,
    sort,
    sortData,
    virtualRange,
  ])

  const handleItemToRenderChange = useCallback(
    (items: ReTableItem[]) => {
      if (!onItemsToRenderChange) return
      onItemsToRenderChange(items, sort, virtualRange)
    },
    [onItemsToRenderChange, sort, virtualRange],
  )

  useEffect(() => {
    handleItemToRenderChange(itemsToRender)
  }, [sort, virtualRange, filterData])

  const previousItemsToRender = usePrevious(itemsToRender)
  useEffect(() => {
    if (JSON.stringify(previousItemsToRender) !== JSON.stringify(itemsToRender)) {
      debouncedSetFilteredItems(itemsToRender)
      handleItemToRenderChange(itemsToRender)
    }
  }, [JSON.stringify(previousItemsToRender), JSON.stringify(itemsToRender)])

  // search defaults

  useEffect(() => {
    if (search === previousSearch) return

    if (search) {
      const itemsToExpandIds = items.map((item) => item.id)
      dispatch({
        type: RETABLE_EXPAND_SUBTREES_SEARCH,
        table: id,
        ids: itemsToExpandIds,
      })
      // initialize collapsed state for search results
      // everything is expanded which:
      //  - has a matching descendants
      const itemsToCollapse = itemsToRender.filter((item) => {
        return !item.uiHasMatchingDescendants
      })
      const itemsToCollapseIds = itemsToCollapse.map((item) => item.id)
      dispatch({
        type: RETABLE_COLLAPSE_SUBTREES_SEARCH,
        table: id,
        ids: itemsToCollapseIds,
      })
    }
  }, [items, itemsToRender, search, previousSearch])

  return (
    <VirtualScroll
      data-retable-id={id}
      itemCount={currentPaginationItems || itemsToRender.length}
      itemsPaddingHeader={itemsPaddingHeader}
      itemsPaddingFooter={itemsPaddingFooter}
      itemHeight={itemHeight}
      renderAheadCount={renderAheadCount}
      marginTop={hasExtendedHeader ? itemHeight : 0}
      isNarrow={isNarrow}
      minWidth={minWidth}
      height={tableHeight}
      onVirtualRangeChange={handleVirtualRangeChange}
      currentPaginationItems={currentPaginationItems}
      {...rest}
    >
      <TableWithStickyHeader item_height={itemHeight} min_width={minWidth}>
        {children}
      </TableWithStickyHeader>
    </VirtualScroll>
  )
}

// ReTable.whyDidYouRender = {
//   logOnDifferentValues: true,
// }
export default React.memo(ReTable)
