import { CellRenderType, Column, ColumnSortType } from 'modules/reTable/reTable.types'
import { UserManagementType, UserStatus } from 'modules/userManagement/userManagement.types'
import { c } from 'ttag'

export const getUserManagementTableColumns: () => Column[] = () => [
  {
    name: 'id',
    label: c('UserManagement').t`Id`,
    cellRenderType: CellRenderType.CUSTOM_NUMERIC,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '4em',
    fieldName: 'id',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'login',
    label: c('UserManagement').t`enercast ID`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '18em',
    fieldName: 'login',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'email',
    label: c('UserManagement').t`E-mail`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '17em',
    fieldName: 'email',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'uiUserStatusLabel',
    label: c('UserManagement').t`Status`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '7em',
    fieldName: 'uiUserStatusLabel',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'authorities',
    label: c('UserManagement').t`Role`,
    cellRenderType: CellRenderType.CUSTOM,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: false,
    width: '26em',
    fieldName: 'authorities',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'partnerName',
    label: c('UserManagement').t`Partner`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'partnerName',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'companyAccounts',
    label: c('UserManagement').t`Company accounts`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '11em',
    fieldName: 'companyAccounts',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'firstName',
    label: c('UserManagement').t`First name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'firstName',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'lastName',
    label: c('UserManagement').t`Last name`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'lastName',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'country',
    label: c('UserManagement').t`Country`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '6em',
    fieldName: 'country',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'timezone',
    label: c('UserManagement').t`Timezone`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'timezone',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'langKey',
    label: c('UserManagement').t`Language`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '6em',
    fieldName: 'langKey',
    isEditable: false,
    fixed: false,
  },
  // {
  //   name: 'createdBy',
  //   label: c('UserManagement').t`Created by`,
  //   cellRenderType: CellRenderType.TEXT,
  //   columnSortType: ColumnSortType.FIELD,
  //   searchable: true,
  //   sortable: true,
  //   width: '12em',
  //   fieldName: 'createdBy',
  //   isEditable: false,
  //   fixed: true,
  // },
  {
    name: 'createdDate',
    label: c('UserManagement').t`Created`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '10em',
    fieldName: 'createdDate',
    isEditable: false,
    minDate: new Date(1995, 1, 1),
    fixed: true,
  },
  {
    name: 'lastModifiedBy',
    label: c('UserManagement').t`Modified by`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '12em',
    fieldName: 'lastModifiedBy',
    isEditable: false,
    fixed: false,
  },
  {
    name: 'lastModifiedDate',
    label: c('UserManagement').t`Last modified`,
    cellRenderType: CellRenderType.DATE,
    columnSortType: ColumnSortType.FIELD,
    searchable: false,
    sortable: true,
    width: '10em',
    fieldName: 'lastModifiedDate',
    isEditable: false,
    minDate: new Date(1995, 1, 1),
    fixed: true,
  },
  {
    name: 'orderNumber',
    label: c('UserManagement').t`Order number`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '10em',
    fieldName: 'orderNumber',
    isEditable: false,
    fixed: true,
  },
  {
    name: 'leadSource',
    label: c('UserManagement').t`Lead source`,
    cellRenderType: CellRenderType.TEXT,
    columnSortType: ColumnSortType.FIELD,
    searchable: true,
    sortable: true,
    width: '26em',
    fieldName: 'leadSource',
    isEditable: false,
    fixed: false,
  },
]

export enum Roles {
  ADMIN = 'ROLE_ADMIN',
  PARTNER = 'ROLE_PARTNER',
  COMPANY_ACCOUNT = 'ROLE_COMPANY_ACCOUNT',
  DEMO = 'ROLE_DEMO',
  DEV = 'ROLE_DEV',
  ENERCAST = 'ROLE_ENERCAST',
  VPP = 'ROLE_VPP',
  USER = 'ROLE_USER',
  FREE = 'ROLE_FREE',
  SLA_EXPORT_ALERT = 'ROLE_SLA_EXPORT_ALERT',
  SITE_FORECAST = 'ROLE_SITE_FORECAST',
  SITE_ASSESSMENT_PV = 'ROLE_SITE_ASSESSMENT_PV',
  SITE_ASSESSMENT_WIND = 'ROLE_SITE_ASSESSMENT_WIND',
  SITE_ASSESSMENT_PACKAGE = 'ROLE_SITE_ASSESSMENT_PACKAGE',
  SITE_ASSESSMENT_TRIAL = 'ROLE_SITE_ASSESSMENT_TRIAL',
  SITE_ASSESSMENT_BACKCAST = 'ROLE_SITE_ASSESSMENT_BACKCAST',
  MAINTENANCE = 'ROLE_MAINTENANCE',
  AREA_FORECAST = 'ROLE_AREA_FORECAST',
  AREA_FORECAST_TRIAL = 'ROLE_AREA_FORECAST_TRIAL',
  WDA = 'ROLE_WDA',
  WEATHER = 'ROLE_WEATHER',
  WEATHER_PRO = 'ROLE_WEATHER_PRO',
  INDIA = 'ROLE_INDIA',
  ENERGY = 'ROLE_ENERGY',
  EC_CUSTOMER = 'ROLE_EC_CUSTOMER',
  DEVELOPER = 'ROLE_DEVELOPER',
  PROFESSIONAL = 'ROLE_PROFESSIONAL',
  ENTERPRISE = 'ROLE_ENTERPRISE',
  CONSTRUCTION = 'ROLE_CONSTRUCTION',
  METER_DATA_CLEANSING = 'ROLE_METER_DATA_CLEANSING',
  METER_DATA_CLEANSING_PV = 'ROLE_METER_DATA_CLEANSING_PV',
  METER_DATA_CLEANSING_WIND = 'ROLE_METER_DATA_CLEANSING_WIND',
  METER_DATA_CLEANSING_BULK_PV = 'ROLE_METER_DATA_CLEANSING_BULK_PV',
  METER_DATA_CLEANSING_BULK_WIND = 'ROLE_METER_DATA_CLEANSING_BULK_WIND',
  CHP = 'ROLE_CHP',
  E3_FORECAST = 'ROLE_E3_FORECAST',
  E3_METAFORECAST = 'ROLE_E3_METAFORECAST',
  READ_ONLY = 'ROLE_READ_ONLY',
  SEASONAL_FORECAST = 'ROLE_SEASONAL_FORECAST',
  TEST = 'ROLE_TEST',
  E3_3RD_PARTY_FORECAST = 'ROLE_E3_3RD_PARTY_FORECAST',
  CLIMATOLOGY = 'ROLE_CLIMATOLOGY',
  CREATE_SCHEDULE = 'ROLE_CREATE_SCHEDULE',
}

// This roles are listed exactly at the same order as Portal Roles in confluence
// Should be listed this way because so is the priority
export const preDefinedAllAuthorities = (): Roles[] => {
  const {
    ADMIN,
    PARTNER,
    COMPANY_ACCOUNT,
    DEMO,
    DEV,
    ENERCAST,
    VPP,
    USER,
    FREE,
    SLA_EXPORT_ALERT,
    SITE_FORECAST,
    SITE_ASSESSMENT_PV,
    SITE_ASSESSMENT_WIND,
    SITE_ASSESSMENT_PACKAGE,
    SITE_ASSESSMENT_TRIAL,
    SITE_ASSESSMENT_BACKCAST,
    MAINTENANCE,
    AREA_FORECAST,
    AREA_FORECAST_TRIAL,
    WDA,
    WEATHER,
    WEATHER_PRO,
    INDIA,
    ENERGY,
    EC_CUSTOMER,
    DEVELOPER,
    PROFESSIONAL,
    ENTERPRISE,
    CONSTRUCTION,
    METER_DATA_CLEANSING,
    METER_DATA_CLEANSING_PV,
    METER_DATA_CLEANSING_WIND,
    METER_DATA_CLEANSING_BULK_PV,
    METER_DATA_CLEANSING_BULK_WIND,
    CHP,
    E3_FORECAST,
    E3_METAFORECAST,
    READ_ONLY,
    SEASONAL_FORECAST,
    TEST,
    E3_3RD_PARTY_FORECAST,
    CLIMATOLOGY,
    CREATE_SCHEDULE,
  } = Roles
  return [
    ADMIN,
    PARTNER,
    COMPANY_ACCOUNT,
    DEMO,
    DEV,
    ENERCAST,
    VPP,
    USER,
    FREE,
    SLA_EXPORT_ALERT,
    SITE_FORECAST,
    SITE_ASSESSMENT_PV,
    SITE_ASSESSMENT_WIND,
    SITE_ASSESSMENT_PACKAGE,
    SITE_ASSESSMENT_TRIAL,
    SITE_ASSESSMENT_BACKCAST,
    MAINTENANCE,
    AREA_FORECAST,
    AREA_FORECAST_TRIAL,
    WDA,
    WEATHER,
    WEATHER_PRO,
    INDIA,
    ENERGY,
    EC_CUSTOMER,
    DEVELOPER,
    PROFESSIONAL,
    ENTERPRISE,
    CONSTRUCTION,
    METER_DATA_CLEANSING_PV,
    METER_DATA_CLEANSING_WIND,
    METER_DATA_CLEANSING_BULK_PV,
    METER_DATA_CLEANSING_BULK_WIND,
    CHP,
    E3_FORECAST,
    E3_METAFORECAST,
    READ_ONLY,
    SEASONAL_FORECAST,
    TEST,
    E3_3RD_PARTY_FORECAST,
    CLIMATOLOGY,
    CREATE_SCHEDULE,
  ]
}

export const preDefinedPartnerAuthorities = [
  Roles.VPP,
  Roles.USER,
  Roles.FREE,
  Roles.SITE_FORECAST,
  Roles.SITE_ASSESSMENT_PV,
  Roles.SITE_ASSESSMENT_WIND,
  Roles.SITE_ASSESSMENT_PACKAGE,
  Roles.SITE_ASSESSMENT_TRIAL,
  Roles.MAINTENANCE,
  Roles.WDA,
  Roles.INDIA,
]

export const DangerRoles = [Roles.ADMIN, Roles.PARTNER, Roles.DEMO, Roles.DEV, Roles.ENERCAST, Roles.VPP]

export const getNewUserManagementUserData = (): UserManagementType => {
  return {
    id: undefined,
    login: undefined,
    email: undefined,
    firstName: undefined,
    lastName: undefined,
    salutation: undefined,
    country: undefined,
    timezone: undefined,
    partnerName: undefined,
    activated: true,
    langKey: undefined,
    ackTermsAndConditions: false,
    authorities: undefined,
    createdDate: undefined,
    lastModifiedDate: undefined,
    lastModifiedBy: undefined,
    leadSource: undefined,
  }
}

export const geUserManagementLanguages = () => {
  return [
    {
      value: 'en',
      label: c('UserManagement').t`English`,
    },
    {
      value: 'de',
      label: c('UserManagement').t`German`,
    },
    {
      value: 'it',
      label: c('UserManagement').t`Italian`,
    },
  ]
}

export const getUserStatusLabel = (status: UserStatus | undefined) => {
  const { ACTIVATED, DEACTIVATED } = UserStatus
  return status === ACTIVATED
    ? c('UserManagement').t`Active`
    : status === DEACTIVATED
    ? c('UserManagement').t`Deactivated`
    : c('UserManagement').t`Expired`
}

export const getAuthoritiesLabelHandler = (label: string) => {
  return label.replace('ROLE_', '').replace(/_/g, ' ')
}

export const getUserStatusFilterItems = () => {
  return [
    {
      value: UserStatus.ACTIVATED,
      label: getUserStatusLabel(UserStatus.ACTIVATED),
    },
    {
      value: UserStatus.DEACTIVATED,
      label: getUserStatusLabel(UserStatus.DEACTIVATED),
    },
    {
      value: UserStatus.EXPIRED,
      label: getUserStatusLabel(UserStatus.EXPIRED),
    },
  ]
}

export const getRoleFilterItems = () => {
  const roles = preDefinedAllAuthorities()
  const items = roles.map((role) => {
    return {
      value: role,
      label: getAuthoritiesLabelHandler(role),
    }
  })
  return items.sort((a, b) => {
    const valueA = a.value.toUpperCase()
    const valueB = b.value.toUpperCase()
    if (valueA < valueB) {
      return -1
    }
    if (valueA > valueB) {
      return 1
    }
    return 0
  })
}

// from backend, we are getting company account field as string[] , but we need to convert it in a string (in our case separated by comma)
export const getCompanyAccountAsString = (value: string[]) => {
  let valueSeperatedByComma = ''
  value.forEach((valueElement, index) => {
    valueSeperatedByComma += `${valueElement}${index < value.length - 1 ? ', ' : ''}`
  })

  return valueSeperatedByComma
}
