import { Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui'
import { Autocomplete } from '@material-ui/lab'
import { FormControl } from '@material-ui/core'
import styled from 'styled-components'
import { LARGE_SCREEN_MEDIA_QUERY } from '../styles/styleVariables'
import { colors, theme } from 'themes/theme-light'

import Flex from 'ui/styles/Flex'
import { rgba } from 'polished'

const formKeyValueHeight = '3.3em'
const maxWidthForLargeScreens = '31em'
export const capacityUnitWidth = '2.5em'
export const formLabelHeight = '16px'

export interface FormValueRequired {
  highlightRequired?: boolean
  isAdminComponent?: boolean
}

export const ErrorMsg = styled.small`
  color: red;
`

export const FieldUnit = styled.div`
  margin-left: 0.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${capacityUnitWidth};
`

export const KeyValuePair = styled.div`
  width: 100%;
  dt:nth-child(odd),
  dd:nth-child(odd) {
    &.MuiIconButton-label {
      background: transparent !important;
      height: 2em !important;
    }
    background: ${theme.app.color.main};
    *.MuiGrid-spacing-xs-4 > .MuiGrid-item {
      padding: 0 16px !important;
    }
  }
`

export const FormKeyValueGrid = styled.dl`
  display: grid;
  grid-template-columns: 13.2em minmax(14em, 1fr);
  align-items: flex-start;
  gap: 0.5em 1em;
  margin-top: 2px;
`

export const FormKey = styled.dt`
  text-align: left;
  color: #4c4a4a;
  height: ${formKeyValueHeight};
  padding: 0px 13px 0 13px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const FormValue = styled.dd<FormValueRequired>`
  min-height: ${formKeyValueHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => (props.isAdminComponent ? colors.adminFunctionalityColor.light : 'inherit')};
  *.MuiInputBase-root {
    background-color: ${(props) => (props.highlightRequired ? rgba(colors.primaryMain, 0.07) : 'inherit')};
  }
  *.MuiInputLabel-shrink {
    z-index: ${(props) => props.highlightRequired && 9} !important;
  }
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    max-width: ${maxWidthForLargeScreens};
  }
`

export const AssetTextContainer = styled.div`
  width: calc(100% - ${capacityUnitWidth});
  margin-bottom: 7px;
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    max-width: 45em;
  }
`

export const AssetAutocompleteContainer = styled.div`
  width: calc(100% - 2.28em);
  margin-bottom: 7px;
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    max-width: 45em;
  }
`

// 	& .MuiSelect-icon {
// 	top: calc(50% - 5px);
// }

interface DetailsKeyValueGridProps {
  keyGridWidth?: string
  valueGridWidth?: string
  withBorder?: boolean
  withBackground?: boolean
}

export const DetailsKeyValueGrid = styled(FormKeyValueGrid)<DetailsKeyValueGridProps>`
  ${(props) =>
    props.keyGridWidth && props.valueGridWidth
      ? `grid-template-columns:${props.keyGridWidth} minmax(${props.valueGridWidth}, 1fr);`
      : ''}
  gap: 0.5em 1em;
  margin-top: 2px;
  border-bottom: ${(props) => (props.withBorder ? '1px solid rgba(224, 224, 224, 1)' : '')};
  background-color: ${(props) => (props.withBackground ? 'rgb(239, 239, 239)' : '')};
  padding-left: ${(props) => (props.withBackground ? '4px' : '')};
`
// ${(props) => (props.firstColumnWidth ? `grid-template-columns:${props.firstColumnWidth} minmax(14em, 1fr)` : '')}

export const DetailsKey = styled(FormKey)`
  height: 2em;
  padding: 0;
`

interface DetailsValueProps {
  fullWidth?: boolean
  maxHeight?: string
}
export const DetailsValue = styled(FormValue)<DetailsValueProps>`
  min-height: 2em;
  max-width: ${(props) => props.fullWidth && '100% !important'};
  max-height: ${(props) => props.maxHeight && `${props.maxHeight} !important`};
`

export const AssetFormLabel = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

// 	*.MuiInputBase-input {
export const AutoCompleteWithLabel = styled(Autocomplete)`
  *.MuiFormControl-marginNormal {
    margin-top: -${formLabelHeight};
    margin-bottom: 0;
  }
  width: calc(100% - ${capacityUnitWidth});
`

export const AutoCompleteWithLabelAndUnit = styled(Autocomplete)`
  *.MuiFormControl-marginNormal {
    margin-top: -${formLabelHeight};
    margin-bottom: 0;
  }
  width: 100%;
`

export const AutoCompleteWithoutUnit = styled(Autocomplete)`
  *.MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 0;
  }
  width: 100%;
`
export const NumberField = styled(TextField)`
  display: flex;
  justify-content: center;
  *.MuiInputBase-input {
    text-align: end;
  }
  &.MuiFormControl-root {
    width: calc(100% - 2px);
  }
  *.MuiInputLabel-shrink {
    z-index: 0;
  }
`
// 	margin-top: ${marginTopNoLabels};
// 	*.MuiInputBase-input {

// }
interface NumberFieldWithoutLabelProps {
  color?: string
}
export const NumberFieldWithoutLabel = styled(NumberField)<NumberFieldWithoutLabelProps>`
  &.MuiFormControl-root {
    width: calc(100% - ${capacityUnitWidth});
  }

  *.MuiInputBase-root {
    color: ${(props) => props.color || 'inherit'};
  }
`
export const NumberFieldWithoutLabelAndUnit = styled(TextField)`
  *.MuiInputBase-root {
    width: 100%;
  }
`

export const NumberFieldWithLabel = styled(NumberField)`
  position: relative;
  top: -8px;
`

export const SelectFieldWithoutLabel = styled(FormControl)`
  & .MuiInput-root {
    margin-top: 0;
  }
  width: calc(100% - ${capacityUnitWidth});
`

export const SelectFieldWithoutLabelAndUnit = styled(FormControl)`
  & .MuiInput-root {
    margin-top: 0;
  }
  width: 100%;
`

export const SiteForecastSelectField = styled(FormControl)`
  & .MuiInput-root {
    margin-top: 0;
  }
`

export const DateField = styled(Field)`
  &.MuiFormControl-root {
    width: calc(100% - ${capacityUnitWidth});
    top: -8px;
  }
`
// 	margin-top: -8px;

// }
export const TextFieldWithoutLabel = styled(TextField)`
  *.MuiInputBase-root {
    width: calc(100% - ${capacityUnitWidth});
  }
`

export const TextFieldWithoutLabelAndUnit = styled(TextField)`
  *.MuiInputBase-root {
    width: 100%;
  }
`

export const TextFieldWithLabel = styled(TextField)`
  *.MuiInputBase-root {
    width: calc(100% - ${capacityUnitWidth});
  }
  position: relative;
  top: -8px;
`

export const SubField = styled(Flex)`
  margin-left: 1.5em;
`
