import React from 'react'
import styled from 'styled-components'
import { StepModel } from 'modules/app/tour/appTour.types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DoneIcon = styled(FontAwesomeIcon)`
  margin-left: 3px;
`

interface stepProps {
  active?: boolean
  done?: boolean
}

const Progress = styled.ul`
  display: inline-flex;
  padding: 0;
  list-style-type: none;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;
`

const ModuleStep = styled.li<stepProps>`
  float: left;
  padding: 5px 21px 5px 27px;
  background: #282c34;
  color: #fff;
  position: relative;
  min-width: 11.7em;
  margin-right: 10px;

  &:before {
    content: '';
    border-left: 20px solid rgb(187, 209, 255);
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    position: absolute;
    top: 0;
    left: -1px;
  }

  &:after {
    content: '';
    border-left: 20px solid #282c34;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    position: absolute;
    top: 0;
    left: 99.8%;
    z-index: 20;
  }

  &.actionColor {
    background: ${(props) => (props.active || props.done ? '#0038a8' : '#838990')};
  }

  &.actionColor:after {
    border-left-color: ${(props) => (props.active || props.done ? '#0038a8' : '#838990')};
  }
`

const TourProgressStep = (props: any) => {
  return (
    <Progress>
      {props.steps?.map(({ name, active, done }: StepModel) => (
        <ModuleStep className="actionColor" active={active} done={done} key={name}>
          {name}
          {done && <DoneIcon size="sm" icon="check" />}
        </ModuleStep>
      ))}
    </Progress>
  )
}

export default TourProgressStep
