import { apiRequest, request } from 'utils/request'
import axios from 'axios'
import {
  ForecastModel,
  QualityEvaluation,
  QualityOverview,
  TrainingInfoPerAsset,
  TrainingJob,
  WeightOptimizationJobMap,
} from 'modules/quality/quality.types'
import { Weights } from 'utils/math'
import { useQuery } from 'react-query'
import { TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'

export const QUERY_KEY_MODEL_WEIGHTS_BY_ASSET = 'e3:modelWeightsByAsset'
export const QUERY_KEY_WEIGHTS_BY_MODEL = 'e3:modelWeightsByModel'

export const getForecastModels = (assetId: string) => {
  return request(() => axios.get<ForecastModel[]>(`/api/productconfig/v2/forecast-model/asset/${assetId}?category=all`))
}

export const triggerWeightOptimization = (assetIds: string[]) => {
  // API documentation: https://jira.enercast.de/browse/PR-4660
  return request(() => axios.post<WeightOptimizationJobMap>(`/api/qecs/weightopt/v0/start`, assetIds))
}

export const saveForecastModel = (data: ForecastModel, activate: boolean) => {
  return request(() =>
    axios.post(`/api/productconfig/v2/forecast-model/weather-model-weights?activate=${activate}`, data),
  )
}

/** Save the forecast model using the weights from selected dataStreams
 *  NOTE: For now only third party dataStreams are supported
 */
interface SaveForecastModelProps {
  assetIds: string[]
  e3Ensemble: Weights
  activate: boolean
}
export const saveForecastModelUsingE3Weights = async ({ assetIds, e3Ensemble, activate }: SaveForecastModelProps) => {
  const data = {
    assetIds: assetIds,
    weights: e3Ensemble
      ? Object.keys(e3Ensemble).map((dataStreamId) => {
          return {
            weatherModel: dataStreamId,
            weight: e3Ensemble[dataStreamId],
          }
        })
      : [],
  }

  return apiRequest(() =>
    axios.post(`/api/productconfig/v2/forecast-model/weather-model-weights?activate=${activate}`, data),
  )
}

/** Save the forecast model using the weights from selected dataStreams
 *  NOTE: For now only third party dataStreams are supported
 */

export const saveForecastModelUsingDataStream = async ({ assetIds, e3Ensemble, activate }: SaveForecastModelProps) => {
  const data = {
    assetIds: assetIds,
    weights: e3Ensemble
      ? Object.keys(e3Ensemble).map((dataStreamId) => {
          return {
            sourceId: dataStreamId,
            weight: e3Ensemble[dataStreamId],
          }
        })
      : [],
  }
  return apiRequest(() => axios.post(`/api/productconfig/v2/forecast-model/meta-weights?activate=${activate}`, data))
}

interface getMetaForecastModelWeightsByAssetOrModelProps {
  assetId?: string
  forecastModelId?: string
  typeOfModel?: TimeSeriesSubType
}
const getModelWeights = async ({
  assetId,
  forecastModelId,
  typeOfModel,
}: getMetaForecastModelWeightsByAssetOrModelProps) => {
  let url = ''
  const isMetaModel = typeOfModel === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST
  if (isMetaModel) {
    if (assetId) {
      url = `/api/productconfig/v2/asset/${assetId}/meta-weights`
    } else if (forecastModelId) {
      url = `/api/productconfig/v2/forecast-model/${forecastModelId}/meta-weights`
    }
  } else {
    if (assetId) {
      url = `/api/productconfig/v2/asset/${assetId}/weather-model-weights`
    } else if (forecastModelId) {
      url = `/api/productconfig/v2/forecast-model/${forecastModelId}/weather-model-weights`
    }
  }

  return await apiRequest(() =>
    axios.get(url, {
      transformResponse: (data) => {
        const staticWeight = 'ECMWF_VAR_EPS_MONTHLY_FORECAST'

        const transformedData = (JSON.parse(data) || [])?.filter(
          (modelAndWeight) => modelAndWeight.weatherModel !== staticWeight,
        )

        return transformedData.reduce((prev, curr) => {
          return {
            ...prev,
            [`${isMetaModel ? curr.sourceId : curr.weatherModel}`]: curr.weight * 1 * 100, // These are in decimals
          }
        }, {})
      },
    }),
  )
}

interface GetForecastModelWeightsByAssetProps {
  assetId: string
  typeOfModel: TimeSeriesSubType
}

export const useForecastModelWeightsByAsset = ({ assetId, typeOfModel }: GetForecastModelWeightsByAssetProps) => {
  return useQuery<Record<string, any>>(
    [QUERY_KEY_MODEL_WEIGHTS_BY_ASSET, assetId, typeOfModel],
    () => getModelWeights({ assetId, typeOfModel }),
    {
      enabled: Boolean(assetId),
    },
  )
}

interface GetForecastModelWeightsByModelProps {
  forecastModelId: string
  typeOfModel: TimeSeriesSubType
}

export const useForecastModelWeightsByForecastModel = ({
  forecastModelId,
  typeOfModel,
}: GetForecastModelWeightsByModelProps) => {
  return useQuery<Record<string, any>>(
    [QUERY_KEY_WEIGHTS_BY_MODEL, forecastModelId, typeOfModel],
    () => getModelWeights({ forecastModelId, typeOfModel }),
    { enabled: Boolean(forecastModelId) },
  )
}

export const hideForecastModel = (forecastModelId: string, hide: boolean) => {
  return request(() => axios.put<any>(`/api/productconfig/v2/forecast-model/${forecastModelId}/hide/${hide}`))
}

export const saveForecastModelName = (forecastModelId: string, name: string) => {
  return request(() =>
    axios.put<any>(`/api/productconfig/v2/forecast-model/${forecastModelId}/rename`, {
      name: name,
    }),
  )
}

export const reevaluateForecastModel = (assetId: string, trainingJobId: string) => {
  return request(() =>
    axios.get<ForecastModel[]>(`/api/v1/train/training/${trainingJobId}/asset/${assetId}/reevaluate/complete`),
  )
}

export const activateForecastModel = (forecastModelId: string) => {
  return request(() => axios.get<ForecastModel[]>(`/api/productconfig/v2/forecast-model/activate/${forecastModelId}`))
}

export const migrateForecastModelsToV2 = (assetIds: string[]) => {
  return request(() => axios.post(`/api/productconfig/v2/forecast-model/fmv2-migration`, assetIds))
}

export const editForecastModel = (forecastModel: ForecastModel, originalAssetId: string) => {
  return request(() =>
    axios.post<ForecastModel>(`api/productconfig/v2/forecast-model/asset/${originalAssetId}`, forecastModel),
  )
}

export const toggleEnableRealTime = (modelId: string, realTime: boolean, assetType: string) => {
  return request(() => axios.post(`api/productconfig/v2/realtime/${realTime}`, { [modelId]: assetType }))
}

export const startWeightOptimization = (forecastModelId: string, trainingId: string) => {
  return request(() =>
    axios.post(`/api/qecs/weightopt/v0/start/forecast-model-id/${forecastModelId}/trainingId/${trainingId}`),
  )
}

export const startSolarCalibration = (forecastModelId: string) => {
  return request(() => axios.post(`/api/qecs/solarcalibration/v0/start/forecast-model-id/${forecastModelId}`))
}

export const createDefaultForecastModel = (assetId: string) => {
  return request(() => axios.post(`/api/masterdata/asset/${assetId}/forecast-model/default/create`))
}

export const getTrainingJobsById = (assetId: string) => {
  return request(() => axios.get<any>(`/api/v1/train/jobs/asset/${assetId}`))
}

export const getTrainingJobs = () => {
  return request(() => axios.get<TrainingJob[]>('/api/v1/train/jobs'))
}

export const getTrainingInfo = (assetIds: string[]) => {
  return request(() => axios.post<TrainingInfoPerAsset>('/api/v1/train/info', assetIds))
}

export const getQualityEvaluation = (assetIds: string[]) => {
  return request(() => axios.post<QualityEvaluation[]>('/api/qecs/v1/qm/assets/reason/all', assetIds))
}

export const getQualityOverview = (assetIds: string[], forecastIds: string[]) => {
  return request(() =>
    axios.post<QualityOverview>('/api/qecs/v1/qm/asset/forecast-model/candidates', {
      assetIds,
      productConfigIds: forecastIds,
    }),
  )
}

export const QUERY_KEY_FORECAST_MODELS_BY_ASSETS = 'assets:forecastModelsByAssets'
