import React, { useCallback, useState } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { ForecastModel } from 'modules/quality/quality.types'
import { editForecastModel, QUERY_KEY_FORECAST_MODELS_BY_ASSETS } from 'modules/quality/quality.api'
import { Asset } from 'modules/asset/store/asset.types'
import { useDispatch } from 'react-redux'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { t } from 'ttag'
import Flex from 'ui/styles/Flex'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import ErrorMessage from 'ui/form/ErrorMessage'
import { useQueryClient } from 'react-query'

interface ForecastModelDetailsDialogProps {
  forecastModel: ForecastModel
  onCloseDialog: () => void
  asset: Asset
}

const DialogContainer = styled(Dialog)`
  & .MuiDialog-paperWidthSm {
    width: 80vh;
    max-width: 80vh;
  }
`

const DialogBody = styled(DialogContent)`
  &.MuiDialogContent-root {
    height: 70vh;
  }
`

const TextAreaInput = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 20px;
  background-color: #232323;
  color: white;
`

const ERROR = {
  EMPTY: 'empty',
  FORECAST_MODEL_OBJECT: 'forecastModelObject',
  WEATHER_MODEL_CONFIGURATION_OBJECT: 'weatherModelConfigurationObject',
  WEATHER_POINTS_OBJECT: 'weatherPointsObject',
  UNDEFINED: 'undefined',
}

const ForecastModelDetailsDialog: React.FC<ForecastModelDetailsDialogProps> = ({
  forecastModel,
  onCloseDialog,
  asset,
}) => {
  // const foreCastModelJsonField = useField<string>('forecastModelJson').input.value
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const openDialog = Boolean(forecastModel)
  // const data = useRef(forecastModel)
  const [editWithError, setEditWithError] = useState<string>(ERROR.EMPTY)
  const [forecastModelData, setForecastModelData] = useState(JSON.stringify(forecastModel))

  const handleCancel = useCallback(() => {
    onCloseDialog()
    if (editWithError) {
      setEditWithError(ERROR.EMPTY)
    }
  }, [editWithError])

  const handleSave = useCallback(() => {
    if (editWithError !== ERROR.EMPTY) {
      setEditWithError(ERROR.EMPTY)
    }

    let updatedModel
    try {
      JSON.parse(forecastModelData)
      updatedModel = JSON.parse(forecastModelData)
    } catch (e) {
      console.log(e)
      setEditWithError(ERROR.FORECAST_MODEL_OBJECT)
      return false
    }

    if (updatedModel && updatedModel.parameter.weatherModelConfiguration) {
      try {
        JSON.parse(updatedModel.parameter.weatherModelConfiguration)
      } catch (e) {
        console.log(e)
        setEditWithError(ERROR.WEATHER_MODEL_CONFIGURATION_OBJECT)
        return false
      }
    }

    if (updatedModel && updatedModel.parameter.weatherPoints) {
      try {
        JSON.parse(updatedModel.parameter.weatherPoints)
      } catch (e) {
        console.log(e)
        setEditWithError(ERROR.WEATHER_POINTS_OBJECT)
        return false
      }
    }

    editForecastModel(updatedModel, asset.id)
      .then((res) => {
        // handling 404
        if (res.hasError) {
          setEditWithError(ERROR.UNDEFINED)
        } else {
          dispatch({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
          queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
          dispatch({ type: actionTypes.GET_QUALITY_EVALUATION_REQUEST, assets: [asset] })
          handleCancel()
        }
      })
      .catch((error) => {
        setEditWithError(ERROR.UNDEFINED)
        console.log(error)
      })
  }, [forecastModelData, asset])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForecastModelData(event.target.value)
  }, [])

  return (
    <>
      {openDialog && (
        <div id="unsaved-changes">
          <DialogContainer
            data-testid="confirmation-dialog"
            id="confirmation-dialog"
            open={openDialog}
            onClose={handleCancel}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            // onEscapeKeyDown={handleCancel}
            // onBackdropClick={handleCancel}
          >
            <DialogTitle disableTypography>
              <Flex alignItems="center" justifyContent="space-between">
                <div>
                  <Typography variant="subtitle1">{t`Edit forecast model`}</Typography>
                  {editWithError === ERROR.UNDEFINED && (
                    <ErrorMessage errorMsg={t`Action returned an error. Please try again.`} />
                  )}
                  {editWithError === ERROR.FORECAST_MODEL_OBJECT && (
                    <ErrorMessage errorMsg={t`Problem parsing forecast model object.`} />
                  )}
                  {editWithError === ERROR.WEATHER_MODEL_CONFIGURATION_OBJECT && (
                    <ErrorMessage errorMsg={t`Problem parsing weather model configuration object.`} />
                  )}
                  {editWithError === ERROR.WEATHER_POINTS_OBJECT && (
                    <ErrorMessage errorMsg={t`Problem parsing weather points object.`} />
                  )}
                </div>

                <div>
                  <Button onClick={handleSave} variant="contained" color="primary" size="small">{t`Save`}</Button>
                  <IconButton key="close" aria-label="close" color="primary" onClick={onCloseDialog}>
                    <FontAwesomeActionIcon icon="times" color="primary" />
                  </IconButton>
                </div>
              </Flex>
            </DialogTitle>
            <DialogBody>
              <TextAreaInput rows={30} value={forecastModelData} onChange={handleChange} />
              {/*<Box mb={2}>*/}
              {/*<JSONInput*/}
              {/*  id="json-editor"*/}
              {/*  style={{ body: { fontSize: '13px' } }}*/}
              {/*  placeholder={forecastModel}*/}
              {/*  onChange={handleChange}*/}
              {/*  width="100%"*/}
              {/*  height="100%"*/}
              {/*/>*/}
              {/*</Box>*/}
            </DialogBody>
          </DialogContainer>
        </div>
      )}
    </>
  )
}

export default React.memo(ForecastModelDetailsDialog)
