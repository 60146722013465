import React, { useCallback, useEffect, useState } from 'react'
import { Field, FormSpy, useField } from 'react-final-form'
import { FormApi } from 'final-form'
import { Select, TextField } from 'final-form-material-ui'
import { Box, Chip, MenuItem, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import { c, t } from 'ttag'

import {
  ForecastConfig,
  IndividualTime,
  SiteForecastQualityConfig,
  SiteForecastUpdateTimes,
  SiteForecastUpdateTimesFrequency,
} from 'modules/dataStreams/dataStreams.types'
import SiteForecastDurationField from 'ui/elements/SiteForecastDurationField'
import { FormKey, FormKeyValueGrid, FormValue, SiteForecastSelectField } from 'ui/form/assetForm.style'
import Flex from 'ui/styles/Flex'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { getSiteForecastUpdateTimesFrequency, getIndividualTimes } from 'utils/siteForecastConfiguration'
import TimePicker from 'ui/form/TimePicker'
import SiteForecastTimeChips from 'ui/SiteForecastTimeChips'
import ErrorMessage from 'ui/form/ErrorMessage'
import { TimezoneSelectorWrapper } from 'ui/datetime/TimezoneSelector'

const DeliveryNameContainer = styled.div`
  max-width: 20em;
`

const DeliveryTimeContainer = styled.div`
  width: 100%;
`

export const TimeChip = styled(Chip)`
  margin-bottom: 5px;
  margin-right: 5px;
`

interface SectionForecast {
  form: FormApi<ForecastConfig>
  onFormChange: () => void
  disableForm: boolean
}

const SectionDelivery: React.FC<SectionForecast> = ({ form, onFormChange, disableForm }) => {
  const { INDIVIDUAL, QUARTER_HOURLY } = SiteForecastUpdateTimesFrequency
  const [deliveryTimes, setDeliveryTimes] = useState<IndividualTime[]>([])
  const [customHours, setCustomHours] = useState('00')
  const [customMinutes, setCustomMinutes] = useState('00')

  const updateTimesObj = useField<SiteForecastUpdateTimes>('updateTimes').input.value
  const customName = useField<string>('ui.deliveryCustomName').input.value
  const customTime = useField<IndividualTime>('ui.deliveryFrequencyCustom').input.value
  const qualityConfigs = useField<SiteForecastQualityConfig[]>('qualityConfigs').input.value || []

  const updateDeliveries = useCallback(
    (deliveries: IndividualTime[]) => {
      form.mutators.setValue('updateTimes.individualTimes', deliveries)
    },
    [qualityConfigs],
  )

  const handleAddCustomDeliveryTimes = useCallback(() => {
    const time = customTime || { hours: '00', minutes: '00' }
    const newTimeObj: IndividualTime = {
      ...time,
      name: customName || '',
    }
    if (newTimeObj.hours || newTimeObj.minutes) {
      const alreadyExists = deliveryTimes.some(
        (time) => newTimeObj.hours == time.hours && newTimeObj.minutes == time.minutes,
      )

      if (!alreadyExists) {
        const customDeliveryTimes: IndividualTime[] = [...deliveryTimes]
        customDeliveryTimes.push(newTimeObj)
        updateDeliveries(customDeliveryTimes)
        setCustomHours('00')
        setCustomMinutes('00')
        form.mutators.setValue('ui.deliveryCustomName', '')
        onFormChange()
      }
    }
  }, [customName, customTime, deliveryTimes, onFormChange])

  const handleDeleteCustomDeliveryTime = useCallback(
    (item: IndividualTime) => {
      const itemTime = `${item.hours}${item.minutes}`

      const deliveries = deliveryTimes.filter((cd: IndividualTime) => {
        const cdTime = `${cd.hours}${cd.minutes}`
        return itemTime != cdTime
      })
      updateDeliveries(deliveries)
    },
    [deliveryTimes],
  )

  const handleFrequencyChange = useCallback(() => {
    form.mutators.setValue('ui.deliveryFrequencyChanged', true)
  }, [])

  const deliveryFrequencies = getSiteForecastUpdateTimesFrequency()

  useEffect(() => {
    if (updateTimesObj?.frequency) {
      const deliveryTimes = getIndividualTimes(updateTimesObj)
      setDeliveryTimes(deliveryTimes)
    }
  }, [updateTimesObj?.frequency, updateTimesObj?.offsetMinutes, updateTimesObj?.individualTimes])

  return (
    <>
      <Typography variant="subtitle2">{c('Delivery').t`Delivery frequency`}</Typography>
      <Flex direction="column">
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`Frequency:`}</FormKey>
          <FormValue>
            <SiteForecastSelectField>
              <Field name="updateTimes.frequency" component={Select} fullWidth>
                {deliveryFrequencies.map((frequency) => (
                  <MenuItem onClick={handleFrequencyChange} key={frequency.name} value={frequency.name}>
                    {frequency.label}
                  </MenuItem>
                ))}
              </Field>
            </SiteForecastSelectField>
          </FormValue>
        </FormKeyValueGrid>

        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            return (
              <>
                {values.updateTimes.frequency === INDIVIDUAL ? (
                  <FormKeyValueGrid>
                    <FormKey>{c('Delivery').t`Delivery time:`}</FormKey>
                    <FormValue>
                      <DeliveryTimeContainer>
                        <Flex alignItems="center">
                          <DeliveryNameContainer>
                            <Field name="ui.deliveryCustomName" fullWidth>
                              {({ input, meta }) => {
                                return (
                                  <Flex direction="row" alignItems="center">
                                    <Box mb={1}>
                                      <TextField label={c('Delivery').t`Name`} fullWidth input={input} meta={meta} />
                                    </Box>
                                    <Box mx={1}>-</Box>
                                  </Flex>
                                )
                              }}
                            </Field>
                          </DeliveryNameContainer>
                          <Field name="ui.deliveryFrequencyCustom">
                            {({ input }) => (
                              <TimePicker
                                initialHours={customHours}
                                initialMinutes={customMinutes}
                                onChange={input.onChange}
                              />
                            )}
                          </Field>

                          <IconButton key="add" aria-label="add" onClick={handleAddCustomDeliveryTimes} color="inherit">
                            <FontAwesomeActionIcon size="xs" icon="plus" />
                          </IconButton>
                        </Flex>
                      </DeliveryTimeContainer>
                    </FormValue>
                  </FormKeyValueGrid>
                ) : (
                  <FormKeyValueGrid>
                    <FormKey>{c('Delivery').t`Offset`}:</FormKey>
                    <FormValue>
                      <Field name="updateTimes.offsetMinutes">
                        {({ input }) => (
                          <div>
                            <SiteForecastDurationField
                              durationText={t`minutes`}
                              max={values.updateTimes.frequency === QUARTER_HOURLY ? 10 : 55}
                              input={input}
                            />
                            {/*{meta.touched && meta.error && <span>{meta.error}</span>}*/}
                          </div>
                        )}
                      </Field>
                    </FormValue>
                  </FormKeyValueGrid>
                )}
              </>
            )
          }}
        </FormSpy>
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`Delivery times:`}</FormKey>
          <FormValue>
            <Box>
              <Field name="updateTimes.individualTimes">
                {({ meta }) => <>{meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}</>}
              </Field>
              <>
                {updateTimesObj.frequency === INDIVIDUAL ? (
                  <SiteForecastTimeChips
                    times={deliveryTimes || []}
                    initialCount={disableForm ? deliveryTimes.length : 5}
                    onDeleteTime={handleDeleteCustomDeliveryTime}
                  />
                ) : (
                  <SiteForecastTimeChips
                    times={deliveryTimes || []}
                    initialCount={disableForm ? deliveryTimes.length : 5}
                  />
                )}
              </>
            </Box>
          </FormValue>
        </FormKeyValueGrid>
        <FormKeyValueGrid>
          <FormKey>{c('Delivery').t`Time zone:`}</FormKey>
          <FormValue>
            <Field name="ui.timeZone" showLabel={false} component={TimezoneSelectorWrapper} required />
          </FormValue>
        </FormKeyValueGrid>
      </Flex>
    </>
  )
}

export default React.memo(SectionDelivery)
