import { Asset } from 'modules/asset/store/asset.types'
import { useTimeSeriesQueryResults } from 'modules/dataStreams/api/timeseries.api'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import {
  DataStreamSelectionItem,
  DAY_AHEAD_DATA_STREAM_SEARCH_KEY,
  GERMANY_REVENUE_SEARCH_KEY,
  INTRA_DAY_DATA_STREAM_SEARCH_KEY,
  PRICE_DATA_INTERVAL,
  TimeSeries,
  TimeSeriesClassifier,
  TimeSeriesResult,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { QueryObserverResult, useQueries } from 'react-query'
import { objectHasData } from 'utils/dataFormatting'
import { calculateRevenueEquations, SimpleRange } from 'utils/timeseries'
import { getTime } from 'date-fns'
import { convertLocalDateToUTC, DATE_FORMAT_INTERNAL_LONG, formatDate } from 'utils/date'
import { createSeriesDataWithCustomInterval, detectTimeSeriesInterval } from 'utils/chart'
import { useWorkspaceChartWholeDateRange } from 'utils/workspace'

export const QUERY_KEY_REVENUE_TIMESERIES = 'revenueTimeseries'

const checkAndCreateSeriesDataWithCustomInterval = (timeSeries: TimeSeries, baseInterval: number) => {
  if (!objectHasData(timeSeries)) {
    return {}
  }
  const timeSeriesInRangeFormat: SimpleRange[] = Object.keys(timeSeries).map((x) => {
    // TODO check the values after calculating with timezone
    const time = getTime(convertLocalDateToUTC(new Date(x)))
    const value = timeSeries[x]

    return [time, value, value]
  })
  const timeSeriesInterval = detectTimeSeriesInterval(timeSeriesInRangeFormat)

  if (timeSeriesInterval > baseInterval) {
    return {}
  }

  const baseIntervalInMin = baseInterval / 1000 / 60
  const timeSeriesIntervalInMin = timeSeriesInterval / 1000 / 60
  // Meter data for calculation
  const timeSeriesWithCustomInterval = createSeriesDataWithCustomInterval(
    timeSeriesInRangeFormat as [number, number, number][],
    timeSeriesIntervalInMin,
    baseIntervalInMin,
  )

  const timeSeriesDataInCustomInterval = timeSeriesWithCustomInterval?.reduce((prev, curr) => {
    const timeStampKey = formatDate(curr[0], null, DATE_FORMAT_INTERNAL_LONG)
    return {
      ...prev,
      [timeStampKey]: curr[1],
    }
  }, {})

  return timeSeriesDataInCustomInterval
}

interface createRevenueTimeseriesProps {
  asset: Asset
  revenueDataStream: DataStreamSelectionItem
  firstSimulationTimeSeries: TimeSeries
  secondSimulationTimeSeries: TimeSeries
  reBapTimeSeriesResult: QueryObserverResult<TimeSeriesResult, TimeSeriesResult> | undefined
  meterDataTimeSeriesResult: QueryObserverResult<TimeSeriesResult, TimeSeriesResult> | undefined
  epexSpotDayAheadTimeSeriesResult: QueryObserverResult<TimeSeriesResult, TimeSeriesResult> | undefined
  epexSpotIntraDayContinuousAverageTimeSeriesResult: QueryObserverResult<TimeSeriesResult, TimeSeriesResult> | undefined
}

const createRevenueTimeseries = async ({
  asset,
  revenueDataStream,
  firstSimulationTimeSeries,
  secondSimulationTimeSeries,
  reBapTimeSeriesResult,
  meterDataTimeSeriesResult,
  epexSpotDayAheadTimeSeriesResult,
  epexSpotIntraDayContinuousAverageTimeSeriesResult,
}: createRevenueTimeseriesProps) => {
  const {
    GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
    GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE,
  } = TimeSeriesClassifier

  return new Promise((resolve) => {
    const rebapIntervalInMin = PRICE_DATA_INTERVAL.GERMANY_REBAP / 1000 / 60
    const epexDayAheadIntervalInMin = PRICE_DATA_INTERVAL.GERMANY_EPEX_DAY_AHEAD_AUCTION / 1000 / 60

    const meterDataTimeSeriesInCustomInterval = checkAndCreateSeriesDataWithCustomInterval(
      meterDataTimeSeriesResult?.data?.data || {},
      PRICE_DATA_INTERVAL.GERMANY_REBAP,
    )

    // simulation data needs to be converted in custom interval for THIRD and FOURTH EQUATIONS
    const firstSimulationTimeSeriesCustomInterval = checkAndCreateSeriesDataWithCustomInterval(
      firstSimulationTimeSeries,
      PRICE_DATA_INTERVAL.GERMANY_EPEX_DAY_AHEAD_AUCTION,
    )

    const secondSimulationTimeSeriesCustomInterval = checkAndCreateSeriesDataWithCustomInterval(
      secondSimulationTimeSeries,
      PRICE_DATA_INTERVAL.GERMANY_EPEX_DAY_AHEAD_AUCTION,
    )

    const epexSpotIntraDayContinuousAverageTimeSeriesCustomInterval = checkAndCreateSeriesDataWithCustomInterval(
      epexSpotIntraDayContinuousAverageTimeSeriesResult?.data?.data,
      PRICE_DATA_INTERVAL.GERMANY_EPEX_DAY_AHEAD_AUCTION,
    )

    let revenueTimeseries = {}
    const classifier = revenueDataStream.classifier
    /** FIRST AND SECOND EQUATIONS CALCULATION */
    if (
      (classifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST ||
        classifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST) &&
      objectHasData(reBapTimeSeriesResult?.data || {}) &&
      objectHasData(firstSimulationTimeSeries) &&
      objectHasData(meterDataTimeSeriesInCustomInterval || {})
    ) {
      revenueTimeseries = calculateRevenueEquations({
        equationType: revenueDataStream.classifier as TimeSeriesClassifier,
        simulation: firstSimulationTimeSeries,
        measuredData: meterDataTimeSeriesInCustomInterval,
        reBapData: reBapTimeSeriesResult?.data?.data,
        equationIntervalInMinutes: rebapIntervalInMin,
      })
    } else if (
      classifier === GERMANY_REVENUE_DAY_AHEAD_REVENUE &&
      firstSimulationTimeSeriesCustomInterval &&
      objectHasData(firstSimulationTimeSeriesCustomInterval) &&
      objectHasData(epexSpotDayAheadTimeSeriesResult?.data || {})
    ) {
      revenueTimeseries = calculateRevenueEquations({
        equationType: revenueDataStream.classifier as TimeSeriesClassifier,
        simulation: firstSimulationTimeSeriesCustomInterval,
        epexSpotDayAhead: epexSpotDayAheadTimeSeriesResult?.data?.data,
        equationIntervalInMinutes: epexDayAheadIntervalInMin,
      })
    } else if (
      classifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE &&
      firstSimulationTimeSeriesCustomInterval &&
      objectHasData(firstSimulationTimeSeriesCustomInterval) &&
      secondSimulationTimeSeriesCustomInterval &&
      objectHasData(secondSimulationTimeSeriesCustomInterval) &&
      objectHasData(epexSpotDayAheadTimeSeriesResult?.data || {}) &&
      objectHasData(epexSpotIntraDayContinuousAverageTimeSeriesCustomInterval || {})
    ) {
      revenueTimeseries = calculateRevenueEquations({
        equationType: revenueDataStream.classifier as TimeSeriesClassifier,
        simulation: firstSimulationTimeSeriesCustomInterval,
        secondSimulation: secondSimulationTimeSeriesCustomInterval,
        epexSpotDayAhead: epexSpotDayAheadTimeSeriesResult?.data?.data,
        epexSpotIntraDayContinuousAverage: epexSpotIntraDayContinuousAverageTimeSeriesCustomInterval,
        equationIntervalInMinutes: epexDayAheadIntervalInMin,
      })
    }

    const dayAheadRevenueTimeseries = {
      aggregation: 'CHART_AGGREGATION_MODE_GROUP_BY_ASSET',
      assetId: asset.id,
      classifier: revenueDataStream.classifier,
      data: revenueTimeseries,
      dataStreamId: revenueDataStream.id,
      subType: revenueDataStream.subType,
      type: revenueDataStream.type,
    }

    resolve(dayAheadRevenueTimeseries)
  })
}

export const useRevenueTimeSeries = (assets: Asset[]) => {
  if (!assets?.length) return {}
  const {
    GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST,
    GERMANY_REVENUE_INTRA_DAY_BALANCING_COST,
    GERMANY_REVENUE_DAY_AHEAD_REVENUE,
    GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE,
  } = TimeSeriesClassifier
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const chartWholeDateRange = useWorkspaceChartWholeDateRange()

  // Fetch selected revenue datastreams
  const selectedRevenueDataStreams = dataSelection.filter((ds) => ds.classifier?.includes(GERMANY_REVENUE_SEARCH_KEY))
  const selectedMeterDataLatest = dataSelection.find((ds) => ds.type === TimeSeriesType.METER_DATA && !ds.subType)
  const selectedReBapDataStream = dataSelection.find((ds) => ds.classifier === TimeSeriesClassifier.GERMANY_REBAP)
  const selectedEpexDayAheadDataStream = dataSelection.find(
    (ds) => ds.classifier === TimeSeriesClassifier.GERMANY_EPEX_DAY_AHEAD_AUCTION,
  )
  const selectedEpexIntraDayContinuousAverageDataStream = dataSelection.find(
    (ds) => ds.classifier === TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
  )

  const queryOptions = []

  // FIRST EQUATION: find selected Day-ahead datastreams
  const selectedDayAheadDataStreams = dataSelection.filter(
    (ds) =>
      ds.type === TimeSeriesType.SITE_FORECAST &&
      (ds.name.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY) ||
        ds?.label?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY)),
  )

  // SECOND EQUATION: find selected Intra-day datastreams
  const selectedIntraDayDataStreams = dataSelection.filter(
    (ds) =>
      ds.type === TimeSeriesType.SITE_FORECAST &&
      (ds.name.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY) ||
        ds?.label?.toLowerCase().includes(INTRA_DAY_DATA_STREAM_SEARCH_KEY)),
  )

  const timeSeriesQueryResults = useTimeSeriesQueryResults()
  const allTimeSeriesFetched = timeSeriesQueryResults.every((tqr) => tqr.isFetched)

  if (allTimeSeriesFetched) {
    selectedRevenueDataStreams?.forEach((revenueDataStream) => {
      const equationDataStreamClassifier = revenueDataStream.classifier
      assets.forEach((asset) => {
        let firstSimulationTimeSeries = {}
        let firstSimulationDataStream = { id: '' } // Site forecast id (Backcast)

        let secondSimulationTimeSeries = {}
        let secondSimulationDataStream = { id: '' } // Site forecast id (Backcast)

        /** Rebap and Meter data is used for FIRST and SECOND equations */
        // ReBap timeseries
        const reBapTimeSeriesResult = timeSeriesQueryResults?.find(
          (queryResult) =>
            queryResult?.isSuccess && queryResult?.data?.classifier === TimeSeriesClassifier.GERMANY_REBAP,
        )

        // Meterdata latest timeseries
        const meterDataTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
          return (
            tqr?.data?.type === TimeSeriesType.METER_DATA &&
            !tqr?.data?.classifier &&
            tqr?.isSuccess &&
            tqr?.data?.assetId === asset.id
          )
        })

        /** FIRST EQUATION, THIRD EQUATION, FOURTH EQUATION: Day-ahead forecast for "DayAhead Balancing cost" & "Day ahead revenue"*/
        if (
          equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST ||
          equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_REVENUE ||
          equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE
        ) {
          firstSimulationDataStream = selectedDayAheadDataStreams[0]
          // Find the dayahead forecast timeseries
          const dayAheadForecastTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
            const dataStreamId = tqr?.data?.dataStreamId || ''
            return (
              firstSimulationDataStream?.id === dataStreamId &&
              tqr?.data?.type === TimeSeriesType.SITE_FORECAST &&
              tqr?.data?.data &&
              tqr?.data?.assetId === asset.id
            )
          })

          // Find the dayahead backcast timeseries
          const dayAheadBackcastTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
            const dataStreamId = tqr?.data?.dataStreamId || ''
            return (
              firstSimulationDataStream?.id === dataStreamId &&
              tqr?.data?.type === TimeSeriesType.BACK_CAST &&
              tqr?.data?.data &&
              tqr?.data?.assetId === asset.id
            )
          })

          firstSimulationTimeSeries = objectHasData(dayAheadForecastTimeSeriesResult?.data?.data)
            ? dayAheadForecastTimeSeriesResult?.data?.data
            : objectHasData(dayAheadBackcastTimeSeriesResult?.data?.data)
            ? dayAheadBackcastTimeSeriesResult?.data?.data
            : {}
        }

        if (
          equationDataStreamClassifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST ||
          equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE
        ) {
          /** SECOND EQUATION and FOURTH EQUATION: Intra-day forecast for "Intraday Balancing cost"*/

          if (equationDataStreamClassifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST) {
            firstSimulationDataStream = selectedIntraDayDataStreams[0]
            // Find the intra-day forecast timeseries
            const intraDayForecastTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
              const dataStreamId = tqr?.data?.dataStreamId || ''
              return (
                firstSimulationDataStream?.id === dataStreamId &&
                tqr?.data?.type === TimeSeriesType.SITE_FORECAST &&
                tqr?.data?.data &&
                tqr?.data?.assetId === asset.id
              )
            })

            // Find the intra-day backcast timeseries
            const intraDayBackcastTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
              const dataStreamId = tqr?.data?.dataStreamId || ''
              return (
                firstSimulationDataStream?.id === dataStreamId &&
                tqr?.data?.type === TimeSeriesType.BACK_CAST &&
                tqr?.data?.data &&
                tqr?.data?.assetId === asset.id
              )
            })

            firstSimulationTimeSeries = objectHasData(intraDayForecastTimeSeriesResult?.data?.data)
              ? intraDayForecastTimeSeriesResult?.data?.data
              : objectHasData(intraDayBackcastTimeSeriesResult?.data?.data)
              ? intraDayBackcastTimeSeriesResult?.data?.data
              : {}
          } else {
            secondSimulationDataStream = selectedIntraDayDataStreams[0]
            // Find the intra-day forecast timeseries
            const intraDayForecastTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
              const dataStreamId = tqr?.data?.dataStreamId || ''
              return (
                secondSimulationDataStream?.id === dataStreamId &&
                tqr?.data?.type === TimeSeriesType.SITE_FORECAST &&
                tqr?.data?.data &&
                tqr?.data?.assetId === asset.id
              )
            })

            // Find the intra-day backcast timeseries
            const intraDayBackcastTimeSeriesResult = timeSeriesQueryResults.find((tqr) => {
              const dataStreamId = tqr?.data?.dataStreamId || ''
              return (
                secondSimulationDataStream?.id === dataStreamId &&
                tqr?.data?.type === TimeSeriesType.BACK_CAST &&
                tqr?.data?.data &&
                tqr?.data?.assetId === asset.id
              )
            })

            secondSimulationTimeSeries = objectHasData(intraDayForecastTimeSeriesResult?.data?.data)
              ? intraDayForecastTimeSeriesResult?.data?.data
              : objectHasData(intraDayBackcastTimeSeriesResult?.data?.data)
              ? intraDayBackcastTimeSeriesResult?.data?.data
              : {}
          }
        }

        /** THIRD and FOURTH EQUATION data*/
        const epexSpotDayAheadTimeSeriesResult = timeSeriesQueryResults?.find(
          (queryResult) => queryResult?.data?.classifier === TimeSeriesClassifier.GERMANY_EPEX_DAY_AHEAD_AUCTION,
        )

        /** FOURTH EQUATION data*/
        const epexSpotIntraDayContinuousAverageTimeSeriesResult = timeSeriesQueryResults?.find(
          (queryResult) =>
            queryResult?.data?.classifier === TimeSeriesClassifier.GERMANY_EPEX_INTRA_DAY_CONTINUOUS_AVERAGE,
        )

        let queryKey = [
          QUERY_KEY_REVENUE_TIMESERIES,
          asset.name,
          asset.id,
          revenueDataStream.id,
          firstSimulationDataStream?.id,
          chartWholeDateRange,
        ]

        if (
          equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_BALANCING_COST ||
          equationDataStreamClassifier === GERMANY_REVENUE_INTRA_DAY_BALANCING_COST
        ) {
          queryKey = [...queryKey, selectedMeterDataLatest?.id, selectedReBapDataStream?.id]
        } else if (equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_REVENUE) {
          queryKey = [...queryKey, selectedEpexDayAheadDataStream?.id]
        } else if (equationDataStreamClassifier === GERMANY_REVENUE_DAY_AHEAD_AND_INTRA_DAY_REVENUE) {
          queryKey = [
            ...queryKey,
            selectedEpexDayAheadDataStream?.id,
            selectedEpexIntraDayContinuousAverageDataStream?.id,
            secondSimulationDataStream?.id,
          ]
        }

        if (
          objectHasData(firstSimulationTimeSeries) ||
          objectHasData(secondSimulationTimeSeries) ||
          reBapTimeSeriesResult ||
          meterDataTimeSeriesResult ||
          epexSpotDayAheadTimeSeriesResult ||
          epexSpotIntraDayContinuousAverageTimeSeriesResult
        ) {
          const queryOption = {
            queryKey: queryKey,
            queryFn: () => {
              return createRevenueTimeseries({
                revenueDataStream,
                reBapTimeSeriesResult,
                meterDataTimeSeriesResult,
                firstSimulationTimeSeries,
                secondSimulationTimeSeries,
                epexSpotDayAheadTimeSeriesResult,
                epexSpotIntraDayContinuousAverageTimeSeriesResult,
                asset,
              })
            },
          }
          queryOptions.push(queryOption)
        }
      })
    })
  }

  return useQueries(queryOptions)
}
