import React, { useEffect, useMemo } from 'react'
import { Field } from 'react-final-form'
import { t } from 'ttag'

import styled from 'styled-components'
import Flex from 'ui/styles/Flex'

import { AutoCompleteWithoutUnit } from 'ui/form/assetForm.style'
import { RenderInputParams } from '@material-ui/lab'
import { Box, TextField as MaterialTextField } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from 'ui/form/LoadingButton'
import {
  ScheduleFormAutocompleteErrorMsg,
  ScheduleFormFieldsFirstColumnContainer,
  ScheduleFormFieldsSecondColumnContainer,
} from 'modules/workspace/schedule/ScheduleForm'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { FormApi } from 'final-form'
import { getScheduleSourceDataStreamsTypes } from 'utils/schedule'
import { useDataStreams } from 'utils/dataStream'
import { sortArrayBasedOnAnotherArray } from 'utils/array'
import { LanguageKey } from 'fixtures/header'
import { CreateScheduleInputData, SubmitScheduleSeriesProps } from 'modules/workspace/schedule/schedule.types'

import { Timezone } from 'fixtures/timezones'
import SchedulePeriodInput from 'modules/workspace/schedule/uiHelperElements/SchedulePeriodInput'
import ScheduleFormActions from 'modules/workspace/schedule/ScheduleFormActions'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useUniqueSelectedAssets } from 'utils/asset'

export const ScheduleFormDateContainer = styled(Flex)`
  & .MuiInput-formControl {
    width: 12.5em;
  }
`

interface ScheduleFormBodyProps {
  form: FormApi
  onSubmitScheduleSeries: (data: SubmitScheduleSeriesProps) => void
  disableSubmitButton: boolean
  submitInProgress: boolean
  seriesChanged: boolean
  onRevertToSource: () => void
  langKey: LanguageKey
  onCheckAndChangeMainChartDateRange: (currentValues: CreateScheduleInputData) => void
  userTimezone: Timezone
  onSetDatesChangedDueToTimezoneUpdate: (value: boolean) => void
}

const ScheduleFormFields: React.FC<ScheduleFormBodyProps> = ({
  form,
  onSubmitScheduleSeries,
  disableSubmitButton,
  onRevertToSource,
  submitInProgress,
  seriesChanged,
  langKey,
  onCheckAndChangeMainChartDateRange,
  userTimezone,
  onSetDatesChangedDueToTimezoneUpdate,
}) => {
  const formValues = form.getState().values
  const currentSourceDataStream = formValues?.sourceDataStream
  const currentTargetDataStream = formValues?.targetScheduleDataStream
  const currentAsset = formValues?.asset

  const selectedAssets = useUniqueSelectedAssets()
  const dataStreams = useDataStreams()
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const sortedSelectedDataStreams = sortArrayBasedOnAnotherArray(selectedDataStreams, dataStreams, 'id')
  const sourceTypes = getScheduleSourceDataStreamsTypes()

  const sourceDataStreams = useMemo(() => {
    return sortedSelectedDataStreams.filter((s) => sourceTypes.includes(s.type))
  }, [sortedSelectedDataStreams])

  const scheduleDataStreams = useMemo(() => {
    const selectedSchedules = sortedSelectedDataStreams?.filter((s) => s.type == TimeSeriesType.SCHEDULE)
    if (selectedSchedules.length) {
      return selectedSchedules
    } else {
      return dataStreams.filter((s) => s.type == TimeSeriesType.SCHEDULE)
    }
  }, [sortedSelectedDataStreams, dataStreams])

  /**
   * Set the Asset only if it is selected if not set it to empty string
   */
  useEffect(() => {
    const currentAssetSelected = selectedAssets?.some((a) => a.id === currentAsset?.id)
    if (currentAsset?.id && !currentAssetSelected) {
      // If something was chosen but it is removed from the side nav selection
      form.mutators.setValue('asset', selectedAssets[0] || '')
    } else if (!currentAsset && selectedAssets.length) {
      // If nothing is chosen and then user selected some data streams from side nav
      form.mutators.setValue('asset', selectedAssets[0])
    }
  }, [JSON.stringify(selectedAssets), currentAsset])

  /**
   * Set the site forecast only if it is selected if not set it to empty string
   */
  useEffect(() => {
    const currentSourceDataStreamSelected = sourceDataStreams?.some((s) => s.id === currentSourceDataStream?.id)
    if (currentSourceDataStream && !currentSourceDataStreamSelected) {
      // If something was chosen but it is removed from the side nav selection
      form.mutators.setValue('sourceDataStream', sourceDataStreams[0] || '')
    } else if (!currentSourceDataStream && sourceDataStreams.length) {
      // If nothing is chosen and then user selected some data streams from side nav
      form.mutators.setValue('sourceDataStream', sourceDataStreams[0])
    }
  }, [JSON.stringify(sourceDataStreams), currentSourceDataStream])

  /**
   * Set the target datastream only if it is selected if not set it to empty string
   */
  useEffect(() => {
    const currentTargetDataStreamSelected = scheduleDataStreams?.some((s) => s.id === currentTargetDataStream?.id)
    if (currentTargetDataStream?.id && !currentTargetDataStreamSelected) {
      // If something was chosen but it is removed from the side nav selection
      form.mutators.setValue('targetScheduleDataStream', scheduleDataStreams[0] || '')
    } else if (!currentTargetDataStream && scheduleDataStreams.length) {
      // If nothing is chosen and then user selected some data streams from side nav
      form.mutators.setValue('targetScheduleDataStream', scheduleDataStreams[0])
    }
  }, [JSON.stringify(scheduleDataStreams), currentTargetDataStream])

  return (
    <Box>
      <Flex gap="20px">
        <ScheduleFormFieldsFirstColumnContainer direction="column">
          <Field name="asset">
            {(props) => {
              return (
                <>
                  <AutoCompleteWithoutUnit
                    value={props.input?.value}
                    options={selectedAssets}
                    getOptionLabel={(option) => option.name || ''}
                    onChange={(event, option) => props.input.onChange(option)}
                    disableClearable
                    renderInput={(params: RenderInputParams) => (
                      <MaterialTextField label={t`Asset`} {...params} margin="normal" fullWidth />
                    )}
                  />
                  <ScheduleFormAutocompleteErrorMsg>
                    {props.meta.error && (props.meta.dirty || props.meta.submitFailed) && (
                      <div style={{ color: 'red', fontSize: '12px' }}>{props.meta.error}</div>
                    )}
                  </ScheduleFormAutocompleteErrorMsg>
                </>
              )
            }}
          </Field>

          <Box mt={3.5}>{t`Create schedule from:`}</Box>
        </ScheduleFormFieldsFirstColumnContainer>

        {/*------------------------Second Column--------------------------*/}
        <ScheduleFormFieldsSecondColumnContainer flexGrow={1} direction="column">
          <Field name="targetScheduleDataStream">
            {(props) => {
              return (
                <>
                  <AutoCompleteWithoutUnit
                    value={props.input?.value}
                    options={scheduleDataStreams}
                    getOptionLabel={(option) => option?.label || option?.name || ''}
                    onChange={(event, option) => props.input.onChange(option)}
                    disableClearable
                    renderInput={(params: RenderInputParams) => (
                      <MaterialTextField label={t`Destination Schedule`} {...params} margin="normal" fullWidth />
                    )}
                  />
                  <ScheduleFormAutocompleteErrorMsg>
                    {props.meta.error && (props.meta.dirty || props.meta.submitFailed) && (
                      <div style={{ color: 'red', fontSize: '12px' }}>{props.meta.error}</div>
                    )}
                  </ScheduleFormAutocompleteErrorMsg>
                </>
              )
            }}
          </Field>
          <Box mt={0.8}>
            <Field name="sourceDataStream">
              {(props) => {
                return (
                  <>
                    <AutoCompleteWithoutUnit
                      value={props.input?.value}
                      options={sourceDataStreams}
                      getOptionLabel={(option) => option.label || ''}
                      onChange={(event, option) => props.input.onChange(option)}
                      disableClearable
                      renderInput={(params: RenderInputParams) => (
                        <MaterialTextField label={t`Source Data Stream`} {...params} margin="normal" fullWidth />
                      )}
                      noOptionsText={t`Please select at least one data stream of the following types: Site Forecast, e³ Forecast, Meta Forecast, Schedule, Actual Generation`}
                    />
                    <ScheduleFormAutocompleteErrorMsg>
                      {props.meta.error && (props.meta.dirty || props.meta.submitFailed) && (
                        <div style={{ color: 'red', fontSize: '12px' }}>{props.meta.error}</div>
                      )}
                    </ScheduleFormAutocompleteErrorMsg>
                  </>
                )
              }}
            </Field>
          </Box>
        </ScheduleFormFieldsSecondColumnContainer>

        <Flex direction="column">
          <Box mt={1.7}>
            <ScheduleFormActions
              form={form}
              onSubmitScheduleSeries={onSubmitScheduleSeries}
              disableSubmitButton={disableSubmitButton}
              langKey={langKey}
              submitInProgress={submitInProgress}
              formValues={formValues}
              seriesChanged={seriesChanged}
            />
          </Box>

          <Box mt={2.6}>
            <LoadingButton
              loading={false}
              variant="contained"
              size="small"
              startIcon={<FontAwesomeIcon icon="undo" fixedWidth />}
              onClick={onRevertToSource}
            >{t`Revert to source`}</LoadingButton>
          </Box>
        </Flex>
      </Flex>

      <SchedulePeriodInput
        userTimezone={userTimezone}
        form={form}
        formValues={formValues}
        onSetDatesChangedDueToTimezoneUpdate={onSetDatesChangedDueToTimezoneUpdate}
        onCheckAndChangeMainChartDateRange={onCheckAndChangeMainChartDateRange}
      />
    </Box>
  )
}

export default ScheduleFormFields
