import { QueryClient } from 'react-query'

// const OneHourInMs = 1000 * 60 * 60 * 1
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // hold data this long in cache
      // note: even if we have a cache hit, react-query still fetches fresh data in the background
      // see also: https://react-query.tanstack.com/guides/caching
      cacheTime: 1000 * 60 * 60 * 1,
      // do not refetch all queries on window focus
      refetchOnWindowFocus: false,
      // do not refetch when new instances of the query mount
      refetchOnMount: false,
      // do not lose time with the same calls over and over again
      retry: false,
      // consider data "fresh" for this period of time
      // see also: https://react-query.tanstack.com/guides/important-defaults
      // staleTime: OneHourInMs,
    },
  },
})
