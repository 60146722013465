import React from 'react'
import { t } from 'ttag'

import Highlight from 'ui/Highlight'
import { formatDate } from 'utils/date'
import Flex from 'ui/styles/Flex'
import { Box } from '@material-ui/core'

interface DateRangeProps {
  from: Date
  to: Date
  selected?: boolean
  highlight?: string
}

const DateRange: React.FC<DateRangeProps> = ({ from, to, highlight = '', ...rest }) => {
  if (!from && !to) return <></>

  const formattedDateFrom = from ? formatDate(from) : ''
  const formattedDateTo = to ? formatDate(to) : ''
  // const formattedRange = `${formattedDateFrom} - ${formattedDateTo}`

  return (
    <Flex {...rest}>
      <div>
        <Highlight text={formattedDateFrom} query={highlight} />
      </div>
      <Box ml={0.5} mr={0.5}>{t`to`}</Box>
      <div>
        <Highlight text={formattedDateTo} query={highlight} />
      </div>
    </Flex>
  )
}

export default React.memo(DateRange)
