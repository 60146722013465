import React, { useCallback } from 'react'
import { Column, RETABLE_ID_DELIVERY_FORMATS } from 'modules/reTable/reTable.types'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import ReTableEditableRow from 'modules/reTable/ReTableEditableRow'
import { ReTableBody } from 'modules/reTable/ReTableBody'

import { ROUTE_DATA_DELIVERY } from 'modules/app/Routes'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import PopperTooltip from 'ui/PopperTooltip'
import PopperTooltipContentSiteForecasts from 'ui/elements/PopperTooltipContentSiteForecasts'
import Highlight from 'ui/Highlight'
import { c } from 'ttag'

interface DeliveryTargetsTableBodyProps {
  onEditItem: (data: DeliveryFormat) => void
  onDeleteItem: (data: DeliveryFormat) => void
  columns: Column[]
  visibleItems: DeliveryFormat[]
  tableHeaderHasActions: boolean
  isTemplateTable: boolean
}

const DeliveryFormatsTableBody: React.FC<DeliveryTargetsTableBodyProps> = ({
  onEditItem,
  onDeleteItem,
  columns,
  visibleItems,
  tableHeaderHasActions,
  isTemplateTable,
}) => {
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_DELIVERY_FORMATS)

  const handleGetCustomCellComponent = useCallback(
    (column: Column, item: DeliveryFormat) => {
      if (column.name === 'usedInLength') {
        return (
          <PopperTooltip
            popperLabel={<Highlight text={`${item.usedInLength}`} query={query} />}
            popperContent={<PopperTooltipContentSiteForecasts siteForecasts={item.usedIn || null} />}
          />
        )
      }
    },
    [query],
  )

  return (
    <ReTableBody>
      {visibleItems.map((row: DeliveryFormat) => {
        return (
          <ReTableEditableRow
            key={row.id}
            isRowEdit={false}
            rowItem={row}
            columns={columns}
            onEditRowItem={onEditItem}
            onDeleteRowItem={onDeleteItem}
            dialogText={row.name}
            dialogContext={isTemplateTable ? c('Delivery').t`File Format Templates` : c('Delivery').t`File Formats`}
            getCustomCellComponent={handleGetCustomCellComponent}
            tableHeaderHasActions={tableHeaderHasActions}
            isCustomRow={false}
            showEditableRowActions={true}
            isShaded={false}
            query={query}
            pageRoute={ROUTE_DATA_DELIVERY}
            navigationDialogKey={isTemplateTable ? 'deliveryFormatTemplate' : 'deliveryFormats'}
            tableToolbarHasActions={true}
          />
        )
      })}
    </ReTableBody>
  )
}

export default React.memo(DeliveryFormatsTableBody)
