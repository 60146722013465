import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Asset } from 'modules/asset/store/asset.types'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import { useSelector } from 'react-redux'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { checkIfTimePeriodIsToAddOrExtendOrReduceOrPrevent, TimePeriodAction } from 'utils/meterDataCleansing'
import { convertUtcToZonedTime, formatDateInternalShort, formatDateShort, isDateValid, isSameDate } from 'utils/date'
import MeterDataCleansingDetails from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingDetails'
import { getMeterdataSummaries } from 'modules/asset/store/getMeterdataSummaries.state'
import CenteredLoading from 'ui/CenteredLoading'
import { t } from 'ttag'
import { isNumeric } from 'utils/dataFormatting'
import AssetCrudCommonHeader from 'modules/asset/assetCrud/AssetCrudCommonHeader'
import { FormContainer } from 'ui/form/form.style'
import { Timezone } from 'fixtures/timezones'
import { assetTabNames } from 'fixtures/assetForm'

interface SectionMeterDataCleansingProps {
  asset: Asset
}

const AssetMeterDataCleansing: React.FC<SectionMeterDataCleansingProps> = ({ asset }) => {
  const timezone = useSelector(getUserTimezoneSelector)
  const [timePeriodsToExcludeFromChart, setTimePeriodsToExcludeFromChart] = useState<TimePeriodToHighlightInterface[]>(
    [],
  )
  const [timePeriodsToExcludedFromTraining, setTimePeriodsToExcludedFromTraining] = useState<
    TimePeriodToHighlightInterface[]
  >([])
  const [totalTimePeriod, setTotalTimePeriod] = useState<MDCTimePeriod>()
  const [timePeriodToEdit, setTimePeriodToEdit] = useState<TimePeriodToHighlightInterface | null>(null)
  const [timePeriodToEditStartTemp, setTimePeriodToEditStartTemp] = useState<Date | string | null>(null)
  const [timePeriodToEditEndTemp, setTimePeriodToEditEndTemp] = useState<Date | string | null>(null)
  const [formChangedExternally, setFormChangedExternally] = useState(false)
  const [linkToDefault, setLinkToDefault] = useState(false)
  const [summaryLoading, setSummaryLoading] = useState<boolean>(false)
  // Used to disable the actions if the time period is invalid when editing in the table
  const [excludedTimePeriodFromChartIsInvalid, setExcludedTimePeriodFromChartIsInvalid] = useState(false)

  const handleLinkToDefaultChange = (value?: boolean) => {
    if (value) {
      setLinkToDefault(value)
    } else {
      setLinkToDefault((prevState) => !prevState)
    }
  }

  const handleSetFormChangedExternally = (value: boolean) => {
    setFormChangedExternally(value)
  }

  const addTimePeriodToExcludeFromChart = useCallback(
    (timePeriod: TimePeriodToHighlightInterface, fromTable?: boolean) => {
      if (isDateValid(timePeriod?.start?.date) && isDateValid(timePeriod?.end?.date)) {
        setExcludedTimePeriodFromChartIsInvalid(false)
        handleSetFormChangedExternally(true)
        const { start: nextStart, end: nextEnd } = timePeriod
        setTimePeriodsToExcludeFromChart((prevTimePeriods) => {
          const id = `${formatDateInternalShort(nextStart.date)}-${formatDateInternalShort(nextEnd.date)}`
          const name = `${formatDateShort(nextStart.date)}-${formatDateShort(nextEnd.date)}`
          const timePeriodToCheck = { ...timePeriod }
          const data = checkIfTimePeriodIsToAddOrExtendOrReduceOrPrevent(timePeriodToCheck, prevTimePeriods, fromTable)
          const { action, timePeriod: modifiedTimePeriod, index } = data
          if (modifiedTimePeriod && action) {
            if (isNumeric(index) && action === TimePeriodAction.EXTEND_OR_REDUCE) {
              const newState = [...prevTimePeriods]
              newState[index] = modifiedTimePeriod
              return newState
            } else if (action == TimePeriodAction.ADD) {
              const newTimePeriod = {
                id,
                name,
                start: nextStart,
                end: nextEnd,
                highlight: false,
              }
              return [...prevTimePeriods, newTimePeriod]
            } else {
              return [...prevTimePeriods]
            }
          } else return [...prevTimePeriods]
        })
      } else {
        setExcludedTimePeriodFromChartIsInvalid(true)
      }
    },
    [],
  )

  // This handler updates the excluded timePeriods array when user performs extend/reduce/editing/cancel and saving a timePeriod
  const updateExcludedTimePeriods = useCallback((timePeriod: TimePeriodToHighlightInterface | null) => {
    const updatedObj = { ...timePeriod }
    setTimePeriodsToExcludeFromChart((prevState: TimePeriodToHighlightInterface[]) => {
      const updatedTimePeriods = prevState?.map((period) => {
        if (updatedObj && updatedObj.id === period.id) {
          return updatedObj // return the highlighted time period
        } else return { ...period, highlight: false }
      })
      return updatedTimePeriods
    })
  }, [])

  const clearTimePeriodToEdit = () => {
    setTimePeriodToEdit(null)
    setTimePeriodToEditStartTemp(null)
    setTimePeriodToEditEndTemp(null)
  }

  // Save excluded timePeriod, save is nothing but update the timePeriod list with new values
  const handleSaveExcludedTimePeriod = useCallback((editedTimePeriod: TimePeriodToHighlightInterface) => {
    const timePeriod = { ...editedTimePeriod, highlight: false }
    updateExcludedTimePeriods(timePeriod)
    clearTimePeriodToEdit()
    setExcludedTimePeriodFromChartIsInvalid(false)
  }, [])

  // Edit excluded timePeriod
  const handleEditExcludedTimePeriod = useCallback((timePeriod: TimePeriodToHighlightInterface) => {
    const deepCopyOfTimePeriod = Object.assign({}, timePeriod) // {...obj} will not copy nested objects
    const deepCopyOfTimePeriod1 = Object.assign({}, timePeriod) // {...obj} will not copy nested objects
    const modifyTimePeriodToEdit = { ...deepCopyOfTimePeriod, highlight: !deepCopyOfTimePeriod.highlight } // toggle highlight to edited time period

    setTimePeriodToEdit(
      (prevTimePeriod) => (prevTimePeriod?.id === modifyTimePeriodToEdit.id ? null : modifyTimePeriodToEdit), // if same time period is clicked remove it
    )

    const startDate = deepCopyOfTimePeriod1.start.date
    const endDate = deepCopyOfTimePeriod1.end.date
    setTimePeriodToEditStartTemp(startDate)
    setTimePeriodToEditEndTemp(endDate)

    updateExcludedTimePeriods(modifyTimePeriodToEdit)
  }, [])

  // Cancel editing excluded timePeriod
  const handleCancelEditExcludedTimePeriod = useCallback(() => {
    // reset the old time period after editing is cancelled
    if (timePeriodToEdit && (timePeriodToEditEndTemp || timePeriodToEditStartTemp)) {
      const timePeriod = {
        start: {
          date: timePeriodToEditStartTemp as Date,
          timezone: timePeriodToEdit.start.timezone as Timezone,
        },
        end: {
          date: timePeriodToEditEndTemp as Date,
          timezone: timePeriodToEdit.end.timezone as Timezone,
        },
        id: timePeriodToEdit.id,
        name: timePeriodToEdit.name,
        highlight: false,
      } // toggle highlight to edited time period
      updateExcludedTimePeriods(timePeriod)
    }
    clearTimePeriodToEdit()
    setFormChangedExternally(false)
    setExcludedTimePeriodFromChartIsInvalid(false)
  }, [timePeriodToEditStartTemp, timePeriodToEditEndTemp])

  // Delete excluded timePeriod
  const handleDeleteExcludedTimePeriod = useCallback(
    (periodToDelete: TimePeriodToHighlightInterface) => {
      const timePeriodsAfterDelete = timePeriodsToExcludeFromChart?.filter(
        (timePeriod) =>
          !isSameDate(timePeriod.start.date, periodToDelete.start.date) &&
          !isSameDate(timePeriod.end.date, periodToDelete.end.date),
      )
      setTimePeriodsToExcludeFromChart(timePeriodsAfterDelete)
    },
    [timePeriodsToExcludeFromChart],
  )

  // Delete all excluded timePeriods
  const handleDeleteAllExcludedTimePeriods = useCallback(() => {
    setTimePeriodsToExcludeFromChart([])
    setFormChangedExternally(true)
  }, [])

  useEffect(() => {
    setSummaryLoading(true)
    if (asset) {
      getMeterdataSummaries([asset], true)
        .then((response) => {
          const summaries: Record<string, any> | undefined = response?.response?.data
          const meterDataSummary = summaries ? summaries[asset.id] : null
          if (meterDataSummary) {
            const data = {
              start: {
                date: convertUtcToZonedTime(meterDataSummary.firstTimeseriesTimestamp, timezone),
                timezone,
              },
              end: {
                date: convertUtcToZonedTime(meterDataSummary.lastTimeseriesTimestamp, timezone),
                // date: addDays(convertUtcToZonedTime(meterDataSummary.firstTimeseriesTimestamp, timezone), 10),
                timezone,
              },
            }
            setTotalTimePeriod(data) // is used as total AVAILABLE time , and is used everywhere.
          }
        })
        .finally(() => setSummaryLoading(false))
    }
  }, [timezone, asset])

  const handleChangeTimePeriodForTraining = useCallback(
    (period: MDCTimePeriod) => {
      if (isDateValid(period?.start.date) && isDateValid(period?.end.date) && totalTimePeriod) {
        if (
          isSameDate(totalTimePeriod.start.date, period?.start.date) &&
          isSameDate(period?.end.date, totalTimePeriod.end.date)
        ) {
          setTimePeriodsToExcludedFromTraining([])
          return
        }
        const timePeriodsExcludedFromTrainingPeriod = [
          [totalTimePeriod.start.date, period?.start.date],
          [period?.end.date, totalTimePeriod.end.date],
        ]

        const data = timePeriodsExcludedFromTrainingPeriod.map((range) => {
          return {
            id: `${formatDateShort(range[0])}-${formatDateShort(range[1])}`,
            name: `${formatDateShort(range[0])}-${formatDateShort(range[1])}`,
            start: { date: range[0], timezone },
            end: { date: range[1], timezone },
          }
        })

        setTimePeriodsToExcludedFromTraining(data)
      }
    },
    [totalTimePeriod, timezone],
  )

  // Add initial excluded timePeriods into the Chart
  const handleSetExcludedTimePeriodsOnLoadAndAfterSave = (timePeriods: TimePeriodToHighlightInterface[]) => {
    setTimePeriodsToExcludeFromChart(timePeriods)
  }

  const currentlyEditedTimePeriod = useMemo(() => {
    const data = Object.assign({}, timePeriodToEdit)
    return data
  }, [timePeriodToEdit])

  // Remove the selected time periods from chart when linkto Defaul is checked
  useEffect(() => {
    if (linkToDefault) {
      sessionStorage.setItem('timePeriodsToExcludeFromChart', JSON.stringify(timePeriodsToExcludeFromChart))
      sessionStorage.setItem('timePeriodsToExcludedFromTraining', JSON.stringify(timePeriodsToExcludedFromTraining))

      setTimePeriodsToExcludeFromChart([])
      setTimePeriodsToExcludedFromTraining([])
    }
  }, [linkToDefault])

  useEffect(() => {
    if (!linkToDefault) {
      if (sessionStorage.getItem('timePeriodsToExcludeFromChart')) {
        const timePeriodsToExcludeFromChartFromSession = JSON.parse(
          sessionStorage.getItem('timePeriodsToExcludeFromChart'),
        )
        setTimePeriodsToExcludeFromChart(timePeriodsToExcludeFromChartFromSession)
      }

      if (sessionStorage.getItem('timePeriodsToExcludedFromTraining')) {
        const timePeriodsToExcludedFromTrainingFromSession = JSON.parse(
          sessionStorage.getItem('timePeriodsToExcludedFromTraining'),
        )
        setTimePeriodsToExcludedFromTraining(timePeriodsToExcludedFromTrainingFromSession)
      }
    }
  }, [linkToDefault])

  useEffect(() => {
    return () => {
      if (sessionStorage.getItem('timePeriodsToExcludeFromChart')) {
        sessionStorage.removeItem('timePeriodsToExcludeFromChart')
      }

      if (sessionStorage.getItem('timePeriodsToExcludedFromTraining')) {
        sessionStorage.removeItem('timePeriodsToExcludedFromTraining')
      }
    }
  }, [])

  return (
    <FormContainer>
      {/*This header is to show empty page with a message when there is no meter data to display */}
      {(summaryLoading || !totalTimePeriod) && (
        <AssetCrudCommonHeader asset={asset} activeTab={assetTabNames.meterDataCleansing} />
      )}

      {summaryLoading ? (
        <CenteredLoading fullHeight size="3em" />
      ) : totalTimePeriod ? (
        <MeterDataCleansingDetails
          asset={asset}
          timePeriodToExcludeFromChart={linkToDefault ? [] : timePeriodsToExcludeFromChart}
          timePeriodsToExcludedFromTraining={timePeriodsToExcludedFromTraining}
          onChangeTimePeriod={addTimePeriodToExcludeFromChart}
          totalTimePeriod={totalTimePeriod}
          onChangeTimePeriodForTraining={handleChangeTimePeriodForTraining}
          timePeriodToEdit={currentlyEditedTimePeriod}
          onEditExcludedTimePeriod={handleEditExcludedTimePeriod}
          onCancelEditExcludedTimePeriod={handleCancelEditExcludedTimePeriod}
          onSaveExcludedTimePeriod={handleSaveExcludedTimePeriod}
          onDeleteExcludedTimePeriod={handleDeleteExcludedTimePeriod}
          onDeleteAllExcludedTimePeriods={handleDeleteAllExcludedTimePeriods}
          onSetExcludedTimePeriodsOnLoadAndAfterSave={handleSetExcludedTimePeriodsOnLoadAndAfterSave}
          formChangedExternally={formChangedExternally}
          onSetFormChangedExternally={handleSetFormChangedExternally}
          handleLinkToDefaultChange={handleLinkToDefaultChange}
          linkToDefault={linkToDefault}
          timezone={timezone}
          excludedTimePeriodFromChartIsInvalid={excludedTimePeriodFromChartIsInvalid}
        />
      ) : (
        <>{t`No Meter data available for training`}</>
      )}
    </FormContainer>
  )
}

export default React.memo(AssetMeterDataCleansing)
