import React, { useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import PopperTooltip from 'ui/PopperTooltip'
import { theme } from 'themes/theme-light'
import { jt, msgid, ngettext, t } from 'ttag'
import { LinkToContactSupport } from 'ui/elements/MailLink'
import { BackCastCreationResponse } from 'modules/workspace/header/backCast/backCast.api'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { DataStreamSelectionItem, ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { useUniqueAllAssets } from 'utils/asset'
import { useDataStreams } from 'utils/dataStream'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'

const ErrorMessageTitle = styled.div`
  color: ${theme.palette.error.main};
  padding-left: 4px;
`

const ErrorMessageContainer = styled.div`
  max-height: 5em;
  overflow-y: auto;
  width: 34em;
  background: #f4f1f1;
  margin-bottom: 10px;
  padding: 4px;
`

const ErrorMessage = styled(Box)`
  color: ${theme.palette.error.main};
`

interface BackCastCalculationErrorMsgProps {
  selectedForecastsWithNoQualityConfig: ForecastConfig[]
  creationResponse: BackCastCreationResponse[] | undefined
  selectedSiteForecastDataStreams: DataStreamSelectionItem[]
}

const BackCastCalculationErrorMsg: React.FC<BackCastCalculationErrorMsgProps> = ({
  selectedForecastsWithNoQualityConfig,
  creationResponse,
  selectedSiteForecastDataStreams,
}) => {
  const allAssets = useUniqueAllAssets()
  const dataStreams = useDataStreams()
  const assetsAndModelsSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const successBackCasts = useMemo(() => {
    if (creationResponse && creationResponse?.length) {
      return creationResponse?.filter((res: BackCastCreationResponse) => res.status == 'SUCCESS')
    }
  }, [creationResponse])

  const failedBackCasts = useMemo(() => {
    if (creationResponse && creationResponse?.length) {
      return creationResponse?.filter((res: BackCastCreationResponse) => res.status == 'ERROR')
    }
  }, [creationResponse])

  const isMultipleBackcast = selectedSiteForecastDataStreams?.length > 1 || assetsAndModelsSelection.length > 1

  const getTooltipLabel = () => {
    if (!selectedForecastsWithNoQualityConfig.length) return null

    if (isMultipleBackcast) {
      return (
        <Flex>
          {ngettext(
            msgid`The requested backcasts cannot be calculated because the selected site forecast does not have a valid quality configuration. You can add one by opening the site forecast configuration from the side bar on the right.`,
            `The requested backcasts cannot be calculated because the selected site forecasts do not have a valid quality configuration. You can add one by opening the site forecast configuration from the side bar on the right.`,
            selectedForecastsWithNoQualityConfig.length,
          )}
        </Flex>
      )
    } else {
      return (
        <Flex>
          {ngettext(
            msgid`The requested backcast cannot be calculated because the selected site forecast does not have a valid quality configuration. You can add one by opening the site forecast configuration from the side bar on the right.`,
            `The requested backcast cannot be calculated because the selected site forecasts do not have a valid quality configuration. You can add one by opening the site forecast configuration from the side bar on the right.`,
            selectedForecastsWithNoQualityConfig.length,
          )}
        </Flex>
      )
    }
  }

  const getErrorToolTipContent = (data: BackCastCreationResponse) => {
    const isAsset = Boolean(data.request?.assetId)
    let labelPrefix = ''
    if (isAsset) {
      const asset = allAssets?.find((asset) => asset.id === data.request.assetId)
      labelPrefix = asset ? `${asset?.name} - ` || '' : ''
    } else {
      const matchedId = assetsAndModelsSelection?.find((id) => id.includes(data.request.forecastModelId))
      const modelName = matchedId ? getBackCastModelDataFromId(matchedId).name : ''
      const asset = allAssets?.find((asset) => asset.id === getBackCastModelDataFromId(matchedId).assetId)
      const assetName = asset?.name || ''
      labelPrefix = matchedId ? `${assetName} ${modelName} - ` : ''
    }
    return (
      <>
        <b>
          {labelPrefix}
          {dataStreams ? dataStreams?.find((dataStream) => dataStream.id === data.request.forecastConfigId).name : ''}
        </b>

        <>: {data.message}</>
      </>
    )
  }

  return (
    <>
      {!failedBackCasts && selectedForecastsWithNoQualityConfig.length > 0 && (
        <ErrorMessage mb={1} width="30em">
          <PopperTooltip
            popperLabel={getTooltipLabel()}
            popperContent={
              <ul>
                {selectedForecastsWithNoQualityConfig?.map((config) => (
                  <li key={JSON.stringify(config)}>
                    <Box mb={0.4}>{config.name}</Box>
                  </li>
                ))}
              </ul>
            }
          />
        </ErrorMessage>
      )}

      {failedBackCasts && failedBackCasts?.length > 0 && (
        <ErrorMessageContainer>
          <ErrorMessageTitle>
            {successBackCasts && successBackCasts?.length > 0 && failedBackCasts?.length > 0 ? (
              <> {t`Could not start backcast calculation for some of the selected Site Forecasts.`}</>
            ) : (
              <>{t`Could not start backcast calculation.`}</>
            )}
          </ErrorMessageTitle>
          <ul>
            {failedBackCasts?.map((fbc) => (
              <li key={JSON.stringify(fbc)}>{getErrorToolTipContent(fbc)}</li>
            ))}
          </ul>
          <ErrorMessageTitle>{jt`Please contact ${LinkToContactSupport}.`}</ErrorMessageTitle>
        </ErrorMessageContainer>
      )}
    </>
  )
}

export default React.memo(BackCastCalculationErrorMsg)
