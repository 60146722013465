import React from 'react'
import Flex from 'ui/styles/Flex'
import { FormApi } from 'final-form'
import MeterDataStatistics from 'modules/asset/assetCrud/meterDataCleansing/MeterDataStatistics'
import { Asset } from 'modules/asset/store/asset.types'
import {
  MDCTimePeriod,
  TimePeriodToHighlightInterface,
} from 'modules/asset/assetCrud/meterDataCleansing/MeterDataCleansingChart'
import styled from 'styled-components'
import { MeterDataCleansingFilterSettings } from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import FilterSettings from 'modules/asset/assetCrud/meterDataCleansing/FilterSettings'
import { Timezone } from 'fixtures/timezones'
import { isSolarAsset, isWindAsset } from 'utils/asset'

const WeatherGuidedConfigurationDiv = styled(Flex)`
  flex-direction: column;
  flex: 1 1 0;
  padding-bottom: 1em;
`

interface WeatherGuidedConfigurationsProps {
  asset: Asset
  defaultFilterSettings?: MeterDataCleansingFilterSettings
  totalTimePeriod: MDCTimePeriod
  timePeriodToExcludeFromChart: TimePeriodToHighlightInterface[]
  form: FormApi
  refreshData: boolean
  cleansingSummary: any
  userTimezone: Timezone
  linkToDefault: boolean
  onLinkToDefaultChange: () => void
}

const WeatherGuidedConfigurations: React.FC<WeatherGuidedConfigurationsProps> = ({
  asset,
  defaultFilterSettings,
  totalTimePeriod,
  timePeriodToExcludeFromChart,
  form,
  refreshData,
  cleansingSummary,
  userTimezone,
  linkToDefault,
  onLinkToDefaultChange,
}) => {
  return (
    <WeatherGuidedConfigurationDiv>
      <FilterSettings
        form={form}
        defaultFilterSettings={defaultFilterSettings}
        hasLinkToDefault={true}
        linkToDefault={linkToDefault}
        onLinkToDefaultChange={onLinkToDefaultChange}
        showAdditionalFiltersForSolar={isSolarAsset(asset)}
        showAdditionalFiltersForWind={isWindAsset(asset)}
      />

      <MeterDataStatistics
        totalTimePeriod={totalTimePeriod}
        asset={asset}
        timePeriodToExcludeFromChart={timePeriodToExcludeFromChart}
        cleansingSummary={cleansingSummary}
        refreshData={refreshData}
        userTimezone={userTimezone}
      />
    </WeatherGuidedConfigurationDiv>
  )
}

export default React.memo(WeatherGuidedConfigurations)
