import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ListItemIcon, ListItemText, MenuItem } from '@material-ui/core'
import { green, red } from '@material-ui/core/colors'
import React from 'react'
import styled from 'styled-components'

import InlineLoading from 'ui/InlineLoading'
import PopperTooltip from 'ui/PopperTooltip'
import { useCustomMuiStyles } from 'themes/theme-light'

const Error = styled.div`
  color: ${red[500]};
`

interface ActionMenuItemProps {
  text: string
  icon: IconProp
  loading: boolean
  error: string | null
  success: boolean
  onClick: () => void
  isAdminComponent?: boolean
}

const ActionMenuItem: React.FC<ActionMenuItemProps> = ({
  text,
  icon,
  loading,
  error,
  success,
  onClick,
  isAdminComponent = false,
}) => {
  //Style
  const classes = useCustomMuiStyles()

  let iconToDisplay = icon
  let iconColor: string = green[500]
  let enabled = true
  if (error) {
    iconToDisplay = 'times'
    iconColor = red[500]
    enabled = true
  } else if (success) {
    iconToDisplay = 'check'
    iconColor = green[500]
    enabled = false
  } else if (loading) {
    enabled = false
  }

  return (
    <PopperTooltip
      popperLabel={
        <MenuItem
          disabled={!enabled}
          onClick={onClick}
          className={isAdminComponent ? classes.adminComponent : 'inherit'}
        >
          <ListItemIcon>
            {loading ? (
              <InlineLoading gap={0} />
            ) : (
              <FontAwesomeIcon icon={iconToDisplay} fixedWidth color={iconColor} />
            )}
          </ListItemIcon>
          <ListItemText primary={text} />
        </MenuItem>
      }
      popperContent={error ? <Error>{error}</Error> : null}
    />
  )
}

export default ActionMenuItem
