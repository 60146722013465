import { GetQualityEvaluationAction, QualityEvaluation } from 'modules/quality/quality.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  result: QualityEvaluation[]
  loading: boolean
  error: string | null
}

const initialState: State = {
  result: [],
  loading: false,
  error: null,
}

const result = createReducer<State['result'], GetQualityEvaluationAction>(
  (state = initialState.result, { type, qualityEvaluations /*, trainingInfo*/ }) => {
    if (type === actionTypes.GET_QUALITY_EVALUATION_SUCCESS) {
      return qualityEvaluations
    }
    return state
  },
)

const loading = createReducer<State['loading'], GetQualityEvaluationAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.GET_QUALITY_EVALUATION_SUCCESS:
      case actionTypes.GET_QUALITY_EVALUATION_FAILURE:
        return false
      case actionTypes.GET_QUALITY_EVALUATION_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<State['error'], GetQualityEvaluationAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.GET_QUALITY_EVALUATION_FAILURE:
        return error
      case actionTypes.GET_QUALITY_EVALUATION_REQUEST:
      case actionTypes.GET_QUALITY_EVALUATION_SUCCESS:
        return null
    }
    return state
  },
)

export const getQualityEvaluationsReducer = combineReducers({ result, loading, error })

// selector

export const getQualityEvaluationsResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.quality.getQualityEvaluations.result,
  (result) => result,
)
export const getQualityEvaluationsLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.quality.getQualityEvaluations.loading,
  (loading) => loading,
)
export const getQualityEvaluationsErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.quality.getQualityEvaluations.error,
  (error) => error,
)
