import React, { useCallback, useMemo, useState } from 'react'
import { c } from 'ttag'
import { Box, Typography } from '@material-ui/core'
import { CheckboxControl, CheckboxLabel } from 'ui/form/authForm.style'
import { Field, useField } from 'react-final-form'
import { Checkbox, TextField } from 'final-form-material-ui'
import Flex from 'ui/styles/Flex'
import { FormKey, FormKeyValueGrid, FormValue } from 'ui/form/assetForm.style'
import FinalFormTextAreaField from 'ui/elements/FinalFormTextAreaField'
import { DisableContainer } from 'modules/dataStreams/siteForecast/SiteForecastForm'
import ErrorMessage from 'ui/form/ErrorMessage'
import styled from 'styled-components'
import { theme } from 'themes/theme-light'
import { ForecastConfig } from 'modules/dataStreams/dataStreams.types'
import { isEnercast, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const FileInput = styled.input`
  display: none;
`

const ConfigFileUpload = styled.label`
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background: ${theme.palette.primary.main};
  color: white;
  width: fit-content;
`

interface SectionCustomConfigurationProps {
  onValidateCustomConfig: (file: File) => void
  forecastDetails: ForecastConfig
  isDefaultDataStream: boolean
  uploadWarnings: string[] | null
  uploadErrors: string[] | null
}

const SectionCustomConfiguration: React.FC<SectionCustomConfigurationProps> = ({
  onValidateCustomConfig,
  forecastDetails,
  isDefaultDataStream,
  uploadWarnings,
  uploadErrors,
}) => {
  const user = useSelector(getUserResultSelector)
  const hasValidCustomConfigFile = useField<boolean>('ui.hasValidCustomConfigFile').input.value
  const isCustomConfig = useField<boolean>('customConfig').input.value
  const customUnRemovable = useMemo(() => (forecastDetails.id && isCustomConfig) || hasValidCustomConfigFile, [
    forecastDetails,
    isCustomConfig,
    hasValidCustomConfigFile,
  ])
  const [uploadedFile, setUploadedFile] = useState<File>()

  const showCustomConfig = useMemo(
    () =>
      !isDefaultDataStream &&
      (isImpersonatedAdmin(user) || isEnercast(user)) &&
      (!forecastDetails.id || forecastDetails.customConfig),
    [user, isDefaultDataStream, forecastDetails],
  )

  const showDryRunOption = useMemo(() => !isDefaultDataStream && (isImpersonatedAdmin(user) || isEnercast(user)), [
    user,
    isDefaultDataStream,
  ])

  const handleFileSelected = useCallback((files: FileList) => {
    setUploadedFile(files[0])
    onValidateCustomConfig(files[0])
  }, [])

  return (
    <>
      {(showCustomConfig || showDryRunOption) && (
        <Box mb={1.5}>
          <Typography variant="subtitle2">{c('Site Forecast:Custom Config').t`Custom configuration`}</Typography>
          {showDryRunOption && (
            <FormKeyValueGrid>
              <FormKey>{c('Site Forecast:Custom Config').t`Redirect delivery:`}</FormKey>
              <FormValue>
                <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
                  <CheckboxControl
                    label={''}
                    control={<Field name="inDryRunMode" type="checkbox" component={Checkbox} />}
                  />
                  <CheckboxLabel>{c('Site Forecast:Custom Config')
                    .t`Redirect delivery to dryrun@enercast.de`}</CheckboxLabel>
                </Flex>
              </FormValue>
            </FormKeyValueGrid>
          )}

          {showCustomConfig && (
            <>
              <FormKeyValueGrid>
                <FormKey>{c('Site Forecast:Custom Config').t`Custom solution:`}</FormKey>
                <FormValue>
                  <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
                    <CheckboxControl
                      label={''}
                      control={
                        <Field name="customConfig" type="checkbox" disabled={customUnRemovable} component={Checkbox} />
                      }
                    />
                    <CheckboxLabel>{c('Site Forecast:Custom Config')
                      .t`The configuration is implemented as custom solution and not administrable in the platform`}</CheckboxLabel>
                  </Flex>
                </FormValue>
              </FormKeyValueGrid>

              {isCustomConfig && !forecastDetails?.id && (
                <FormKeyValueGrid>
                  <FormKey>{c('Site Forecast:Custom Config').t`Custom config file:`}</FormKey>
                  <FormValue>
                    <Flex alignItems="center">
                      <ConfigFileUpload>
                        {c('Site Forecast:Custom Config').t`Choose file`}
                        <FileInput
                          type="file"
                          accept=".xml,.XML"
                          onChange={(e) => handleFileSelected(e.target.files)}
                        />
                      </ConfigFileUpload>
                      {uploadedFile && <Box ml={0.5}>{uploadedFile.name}</Box>}
                    </Flex>
                  </FormValue>
                </FormKeyValueGrid>
              )}

              {uploadErrors &&
                uploadErrors.length > 0 &&
                uploadErrors.map((error: string) => <ErrorMessage key={error} errorMsg={error} />)}
              {uploadWarnings &&
                uploadWarnings.length > 0 &&
                uploadWarnings.map((warning: string) => (
                  <ErrorMessage color="orange" key={warning} errorMsg={warning} />
                ))}

              {isCustomConfig && (
                <DisableContainer disable={isDefaultDataStream || hasValidCustomConfigFile}>
                  <FormKeyValueGrid>
                    <FormKey>{c('Site Forecast:Custom Config').t`Name of Export Configuration:`}</FormKey>
                    <FormValue>
                      <Field name="customConfigName">
                        {(props) => {
                          return <TextField fullWidth input={props.input} meta={props.meta} />
                        }}
                      </Field>
                    </FormValue>
                  </FormKeyValueGrid>
                  <FormKeyValueGrid>
                    <FormKey>{c('Site Forecast:Custom Config').t`Delivery XML snippet:`}</FormKey>
                    <FormValue>
                      <Field name="customConfigOptional" rows={2} rowsMax={10} component={FinalFormTextAreaField} />
                    </FormValue>
                  </FormKeyValueGrid>
                  <FormKeyValueGrid>
                    <FormKey>{c('Site Forecast:Custom Config').t`Default ensemble algorithm configs:`}</FormKey>
                    <FormValue>
                      <Field name="defaultUnpaps" rows={2} rowsMax={10} component={FinalFormTextAreaField} />
                    </FormValue>
                  </FormKeyValueGrid>
                </DisableContainer>
              )}
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default SectionCustomConfiguration
