import React from 'react'
import { IconSelectedColor, SvgIconPath } from 'ui/styles/SvgIconPath'
import { AssetIconProps } from 'ui/icons/ClusterPoolIcon'
import { getTypeLabel } from 'utils/asset'
import { TYPE_SOLARPARK } from 'modules/asset/store/asset.types'

const SolarParkIcon: React.FC<AssetIconProps> = ({ iconSelected, size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      viewBox="100 -150 400 650"
      width={size}
      height={size}
      preserveAspectRatio="xMidYMid meet"
    >
      <rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none" />
      <metadata>Created by potrace 1.15, written by Peter Selinger 2001-2017</metadata>

      <g className="currentLayer">
        <title>{getTypeLabel(TYPE_SOLARPARK)}</title>
        <g transform="translate(0,550) scale(0.1,-0.1) ">
          <SvgIconPath
            d="M2104 6395 c-25 -25 -25 -27 -22 -171 3 -139 4 -147 27 -165 30 -24 82 -24 112 0 23 19 24 24 24 171 0 147 -1 152 -24 171 -34 27 -86 25 -117 -6z"
            id="svg_2"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M570 5960 c-13 -13 -20 -33 -20 -59 0 -36 8 -48 128 -170 119 -123 129 -131 165 -131 49 0 87 35 87 82 0 29 -16 49 -133 166 -122 122 -135 132 -170 132 -24 0 -44 -7 -57 -20z"
            id="svg_3"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M3528 5851 c-118 -118 -128 -131 -128 -165 0 -48 36 -86 81 -86 28 0 50 18 166 132 124 123 133 135 133 170 0 52 -27 78 -82 78 -41 0 -47 -4 -170 -129z"
            id="svg_4"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M1930 5746 c-165 -30 -295 -75 -437 -152 -354 -192 -607 -532 -699 -939 -25 -112 -30 -391 -9 -517 20 -119 59 -250 79 -267 28 -24 79 -24 109 -1 34 27 35 67 7 160 -35 114 -53 273 -46 395 34 576 444 1043 1016 1156 100 20 348 17 450 -5 244 -52 451 -163 627 -336 75 -73 86 -80 122 -80 51 0 81 29 81 79 0 32 -10 46 -102 136 -188 183 -408 301 -673 361 -96 22 -423 28 -525 10z"
            id="svg_5"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M153 4440 c-45 -19 -58 -90 -24 -131 19 -23 24 -24 171 -24 147 0 152 1 171 24 24 30 24 82 0 112 -18 23 -26 24 -158 26 -76 1 -148 -2 -160 -7z"
            id="svg_6"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M1671 4423 c-14 -16 -273 -472 -574 -1015 -581 -1048 -573 -1032 -527 -1078 20 -20 33 -20 990 -20 l970 0 0 -490 0 -490 -319 0 -320 0 -20 -26 c-28 -36 -27 -79 4 -109 l24 -25 1006 0 c625 0 1013 4 1024 10 10 5 24 23 31 40 15 35 0 84 -31 100 -10 6 -136 10 -293 10 l-276 0 0 490 0 490 370 0 370 0 0 -265 0 -265 -144 0 c-130 0 -146 -2 -170 -21 -32 -25 -35 -75 -7 -110 l19 -24 577 0 577 0 19 24 c26 32 25 82 -4 109 -22 21 -33 22 -175 22 l-152 0 0 265 0 265 319 0 320 0 20 26 c12 15 21 38 21 53 -1 39 -1111 2033 -1141 2048 -19 10 -297 13 -1252 13 l-1229 0 -27 -27z m715 -160 c-3 -10 -26 -110 -50 -223 -25 -113 -48 -215 -51 -227 l-5 -23 -385 0 c-212 0 -385 3 -385 6 0 4 60 114 132 245 l133 239 308 0 c289 0 308 -1 303 -17z m972 -224 c30 -132 52 -243 49 -245 -2 -2 -218 -3 -479 -2 l-475 3 34 150 c18 83 42 192 54 243 l21 92 371 0 372 0 53 -241z m826 71 c51 -91 111 -200 135 -242 l42 -78 -388 0 -389 0 -52 238 c-29 130 -52 240 -52 245 0 4 138 6 306 5 l306 -3 92 -165z m-1949 -522 c-4 -18 -28 -127 -54 -243 l-47 -210 -497 -3 c-273 -1 -497 0 -497 2 0 4 236 430 256 464 l14 22 416 0 415 0 -6 -32z m1224 -5 c5 -21 28 -123 51 -228 23 -104 44 -198 46 -207 5 -17 -29 -18 -620 -18 -344 0 -626 3 -626 8 0 4 23 114 52 245 l52 237 518 0 517 0 10 -37z m1133 -208 l136 -245 -499 0 c-274 0 -499 2 -499 3 0 4 -95 433 -104 470 -4 16 21 17 413 17 l417 0 136 -245z m-2507 -462 c-7 -32 -32 -145 -56 -250 l-42 -193 -609 0 -609 0 41 73 c22 39 84 152 138 249 l97 178 527 0 526 0 -13 -57z m1566 -193 l56 -250 -774 0 c-425 0 -773 2 -773 3 0 4 94 427 105 475 l5 22 663 0 663 0 55 -250z m1303 5 c75 -135 136 -248 136 -251 0 -2 -273 -3 -607 -2 l-606 3 -53 235 c-29 129 -53 241 -53 248 -1 9 110 12 523 12 l524 0 136 -245z m-464 -680 l0 -265 -120 0 -120 0 0 265 0 265 120 0 120 0 0 -265z m-1300 -240 l0 -465 -245 0 -245 0 0 465 0 465 245 0 245 0 0 -465z"
            id="svg_7"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
          <SvgIconPath
            d="M4532 4183 c-22 -9 -44 -63 -37 -94 12 -55 29 -59 256 -59 l206 0 357 -585 c196 -322 356 -587 356 -590 0 -3 -81 -5 -179 -5 -99 0 -187 -3 -196 -6 -25 -10 -47 -62 -40 -95 13 -57 22 -59 331 -59 l283 0 21 27 c15 20 20 37 17 58 -6 35 -817 1372 -848 1398 -19 15 -47 17 -268 16 -135 0 -252 -3 -259 -6z"
            id="svg_8"
            strokeWidth="90"
            iconColor={iconSelected ? IconSelectedColor : ''}
            strokeOpacity="1"
          />
        </g>
      </g>
    </svg>
  )
}

export default SolarParkIcon
