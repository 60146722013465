import React, { useCallback, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { t } from 'ttag'
import LoadingButton from 'ui/form/LoadingButton'

const DialogInput = styled.div`
  height: 5em;
`

interface TextFieldDialogProps {
  heading: string
  text: string
  label: string
  defaultValue?: string
  loading?: boolean
  validateOnInit?: boolean
  submitLabel?: string
  onSubmit: (name: string) => void
  onValidate: (name: string) => string | undefined
  onCancel?: () => void
}

const TextFieldDialog: React.FC<TextFieldDialogProps> = ({
  heading,
  text,
  label,
  defaultValue = '',
  loading = false,
  validateOnInit = false,
  submitLabel,
  onSubmit,
  onValidate,
  onCancel,
}) => {
  const [open, setOpen] = React.useState(true)
  const [value, setValue] = React.useState(defaultValue)
  const [error, setError] = React.useState<string | undefined>(undefined)

  const handleSave = useCallback(() => {
    if (error) return
    onSubmit(value)
    setOpen(false)
  }, [error, value, onSubmit])

  const handleClose = useCallback(() => {
    if (onCancel) onCancel()
    setOpen(false)
  }, [onCancel])

  const handleValidate = useCallback(
    (name: string) => {
      if (onValidate) {
        const errorMessage = onValidate(name)
        setError(errorMessage)
      }
    },
    [onValidate],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = event.currentTarget.value
      setValue(name)
      handleValidate(name)
    },
    [handleValidate],
  )

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        handleSave()
      }
    },
    [handleSave],
  )

  useEffect(() => {
    if (!validateOnInit) return
    handleValidate(defaultValue)
  }, [handleValidate, validateOnInit, defaultValue])

  return (
    <Dialog
      open={open}
      // disableEscapeKeyDown={true}
      // disableBackdropClick={true}
      onClose={handleClose}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
    >
      <DialogTitle disableTypography>
        <Typography variant="subtitle1">{heading}</Typography>
      </DialogTitle>
      <DialogContent>
        <div>{text}</div>
        <DialogInput>
          <TextField
            label={label}
            defaultValue={defaultValue}
            error={Boolean(error)}
            helperText={error}
            margin="dense"
            autoFocus
            fullWidth
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        </DialogInput>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" size="small">
          {t`Cancel`}
        </Button>
        <LoadingButton
          onClick={handleSave}
          color="primary"
          size="small"
          variant="contained"
          loading={loading}
          disabled={Boolean(error)}
        >
          {submitLabel ? submitLabel : t`Save`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(TextFieldDialog)
