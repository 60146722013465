import styled from 'styled-components'
import { Box, FormControlLabel, Typography } from '@material-ui/core'

export const AuthFormContainer = styled.div`
  margin: 2em;
  & .MuiFormHelperText-root {
    position: absolute;
    bottom: -23px;
  }
`

export const AuthFormField = styled.div`
  margin-bottom: 1.2em;
  width: 100%;
`

export const AuthFormTitle = styled(Typography)``

//     &.MuiTypography-root {
//     margin-bottom: 0.5em;
// }

export const AuthActionButtonContainer = styled(Box)`
  width: fit-content;
`

export const CheckboxControl = styled(FormControlLabel)`
  & .MuiCheckbox-root {
    padding-top: 0;
  }
  &.MuiFormControlLabel-root {
    margin-right: 0;
  }
`

export const CheckboxLabel = styled.div`
  margin-top: 3px;
`
