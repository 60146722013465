import { getTime } from 'date-fns'
import { SeriesLineOptions } from 'highcharts'
import { SeriesArearangeOptions } from 'highcharts/highstock'
import { Area, Asset, FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { getAreaConfigsAreasSelector } from 'modules/data/store/getAreaConfigs.state'
import { getMetaDataResultSelector } from 'modules/data/store/getMetaData.state'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useTimeSeriesQueryResults } from 'modules/dataStreams/api/timeseries.api'

import {
  DataStreamSelection,
  ForecastConfig,
  GERMANY_REVENUE_SEARCH_KEY,
  MetaData,
  ParamDto,
  TimeSeries,
  TimeSeriesClassifier,
  TimeSeriesResult,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { getWeatherConfigsResultSelector } from 'modules/weather/store/getWeatherConfig.state'
import { WeatherConfig } from 'modules/weather/store/weather.types'
import {
  workspaceDraftChartAggregationModeSelector,
  workspaceDraftDataStreamSelectionSelector,
  workspaceDraftSelectedModelsSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'
import { useMemo } from 'react'
import { QueryObserverResult } from 'react-query'
import { useSelector } from 'react-redux'
import { getColor } from 'themes/theme-light'
import { useDebounce } from 'use-debounce'
import { useUniqueAllAssets, useUniqueSelectedAssets } from 'utils/asset'
import { detectTimeSeriesInterval } from 'utils/chart'
import { getDataStreamName, getDataStreamUnit } from 'utils/dataStream'
import { getDataForParam } from 'utils/metaData'
import { mergeQueryResults } from 'utils/request'
import {
  isAustriaMarketTimeSeries,
  isFranceM0TimeSeries,
  isMarktwertTimeSeries,
  mergeAndAggregateTimeSeriesResults,
  SimplePoint,
  SimpleRange,
} from 'utils/timeseries'
import { getAggregationLabel } from 'utils/workspace'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import { useRevenueTimeSeries } from 'modules/workspace/chart/equationsTimeseries'
import { ForecastModel } from 'modules/forecastModels/forecastModels.types'
import { formatDate } from 'utils/date'

export type ChartSeriesOptions = SeriesArearangeOptions | SeriesLineOptions

export interface SeriesCustomOptions {
  custom: {
    alignment: 'right' | 'left'
    label: string
    unit: string
    assetId?: string
    // assetIds?: string[]
    datastreamId?: string
    datastreamType?: TimeSeriesType
    datastreamSubType?: TimeSeriesSubType
    datastreamClassifier?: TimeSeriesClassifier
    paramData: ParamDto
    result: QueryObserverResult
  }
}

interface GetChartSeriesName {
  asset?: Asset
  assets?: Asset[]
  forecastConfigs?: ForecastConfig[]
  weatherConfigs?: WeatherConfig[]
  areas?: Area[]
  id?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
  chartAggregationMode: ChartAggregationMode
  queryResultId?: string
  selectedModels?: ForecastModel[]
  modelId?: string
}
export const getChartSeriesName = ({
  asset,
  forecastConfigs,
  weatherConfigs,
  areas,
  id,
  type,
  subType,
  classifier,
  chartAggregationMode,
  queryResultId,
  selectedModels,
  modelId,
}: GetChartSeriesName) => {
  const prefix =
    type === TimeSeriesType.MARKET_PRICE_DATA && !classifier?.includes(GERMANY_REVENUE_SEARCH_KEY)
      ? getDataStreamName({ type, subType })
      : chartAggregationMode === ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET
      ? asset?.name
        ? `${asset.name}` // if there is no aggregation, we show the asset name in front of the data stream name
        : undefined
      : getAggregationLabel(chartAggregationMode) // if there is aggregation, we show the aggregation mode instead of asset names
  const dataStreamName = getDataStreamName({
    id,
    type,
    subType,
    classifier,
    forecastConfigs,
    weatherConfigs,
    areas,
  })
  let subName = ''
  const isModelBackCastTimeSeries = queryResultId && queryResultId.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)
  if (isModelBackCastTimeSeries && queryResultId) {
    subName = getBackCastModelDataFromId(queryResultId).name
  }
  if (modelId) {
    const model = selectedModels?.find((model) => model.uuid === modelId)
    subName = model ? `[${formatDate(model?.creationDate)}]` : ''
  }

  return prefix
    ? `${prefix} ${subName} - ${dataStreamName} ${type === TimeSeriesType.BACK_CAST ? ' [Backcast]' : ''}`
    : dataStreamName
}

/**
 * create a single highcharts compatible chart series
 * Additional: Please check Important comments.
 */
interface CreateChartSeries {
  result: QueryObserverResult
  timeSeries?: TimeSeries
  asset?: Asset
  assets?: Asset[]
  id?: string
  type: TimeSeriesType
  subType?: TimeSeriesSubType
  classifier?: TimeSeriesClassifier
  chartAggregationMode: ChartAggregationMode
  // selectedDateRange: DateRange
  selectedDataStreams: DataStreamSelection
  forecastConfigs?: ForecastConfig[]
  weatherConfigs?: WeatherConfig[]
  metaData?: MetaData
  areas?: Area[]
  index?: number
  queryResultId?: string
  modelId?: string
  selectedModels?: ForecastModel[]
}
export const createChartSeries = ({
  result,
  timeSeries = {},
  asset,
  assets = [],
  id,
  type,
  subType,
  classifier,
  chartAggregationMode,
  selectedDataStreams,
  forecastConfigs,
  weatherConfigs,
  metaData,
  areas,
  index = 0,
  queryResultId,
  modelId,
  selectedModels,
}: CreateChartSeries): ChartSeriesOptions => {
  const { CAPACITY_DATA, BACK_CAST, SITE_FORECAST } = TimeSeriesType
  // build series data array
  const isSimpleLine = type === CAPACITY_DATA
  const data: SimplePoint[] | SimpleRange[] = Object.keys(timeSeries).map((x, index) => {
    // Actually Highcharts expects local dates, even though we get data in UTC from API we need
    // to convert them to local dates and then pass user timezone while displaying
    const time = getTime(new Date(x))
    const value = timeSeries[x]

    /** Important **/
    // For CAPACITY_DATA , we need to hide last point in the chart.
    // We are just hiding it , if you want to see it , comment following if block
    if (isSimpleLine && index === Object.keys(timeSeries).length - 1) {
      return {
        x: time,
        y: value,
        noToolTip: true,
        marker: {
          states: {
            hover: { enabled: false },
          },
        },
      }
    }

    return isSimpleLine ? [time, value] : [time, value, value]
  }) // highcharts expects local dates !!!

  // For capacity data we don't need to sort data
  if (!isSimpleLine) {
    data.sort((a, b) => (a[0] < b[0] ? -1 : 1))
  }

  // set series properties
  const paramData = getDataForParam({ metaData, param: id })
  const unit = getDataStreamUnit({ id, type, subType, paramData, classifier })

  const name = getChartSeriesName({
    asset,
    assets,
    forecastConfigs,
    weatherConfigs,
    areas,
    id,
    type,
    subType,
    classifier,
    chartAggregationMode,
    queryResultId,
    selectedModels,
    modelId,
  })

  const selected = selectedDataStreams.find(
    (selection) =>
      selection.id === id &&
      (selection.type === type || (selection.type === SITE_FORECAST && type === BACK_CAST)) &&
      selection.subType === subType &&
      selection.classifier === classifier,
  )

  const isMarktWert = isMarktwertTimeSeries(classifier)
  const isFranceM0 = isFranceM0TimeSeries(classifier)
  const isAustriaTimeSeries = isAustriaMarketTimeSeries(classifier)

  const color = selected ? getColor(selected.color, 0, index) : 'transparent'
  const dataGrouping =
    type === CAPACITY_DATA
      ? undefined
      : {
          approximation: 'range',
          smoothed: false,
          groupPixelWidth: 1,
        }
  const isInteractiveMetaForecast = type === TimeSeriesType.META_FORECAST && subType === TimeSeriesSubType.META_ENSEMBLE
  const lineWidth = isInteractiveMetaForecast ? 1.5 : 1 // 0 lets the line disappear when zoomed in, even for area range charts
  const step = isMarktWert || isFranceM0 || isAustriaTimeSeries || type === CAPACITY_DATA ? 'left' : 'right' // undefined seems to avoid random transformation of series in step charts
  const seriesType = type === CAPACITY_DATA ? 'line' : 'arearange'

  const detectedInterval = type === CAPACITY_DATA ? undefined : detectTimeSeriesInterval(data)
  const shouldBeFilled = type === CAPACITY_DATA
  const alignment = isMarktWert || isFranceM0 || isAustriaTimeSeries ? 'left' : 'right'

  // finally create chart series options
  const series: ChartSeriesOptions & SeriesCustomOptions = {
    color,
    connectNulls: false,
    data: data,
    dataGrouping,
    gapSize: isMarktWert || isFranceM0 || isAustriaTimeSeries || type === CAPACITY_DATA ? 0 : 1, // 1. WARNING: might cause the series to disappear when zoomed in 2. we are making marktwert check because we are getting data on a monthly basis for marktwert, and highchart treat it as gape size
    lineWidth,
    zIndex: isInteractiveMetaForecast ? 10 : 1,
    custom: {
      alignment,
      label: name,
      unit,
      assetId: asset?.id,
      assetIds: assets.map((a) => a.id),
      datastreamId: selected?.name,
      datastreamType: type,
      datastreamSubType: subType,
      datastreamClassifier: classifier,
      paramData,
      detectedInterval,
      result,
      shouldBeFilled,
    },
    name: name,
    pointStart: data.length > 0 && data[0][0] ? data[0][0] : undefined,
    // pointInterval,
    showInNavigator: true,
    step,
    type: seriesType,
    yAxis: unit,
  }

  if (type === BACK_CAST) {
    series['dashStyle'] = 'ShortDash'
  }
  // console.log({ series, type })

  return series
}

/**
 * aggregate time series results
 * we need to aggregate the time series data as well as the react-query result objects
 */
interface MergeQueryResultsAndAggregateTimeSeries {
  timeSeriesQueryResults: QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[]
  chartAggregationMode: ChartAggregationMode // TODO turn this into enum
}
const mergeQueryResultsAndAggregateTimeSeries = ({
  timeSeriesQueryResults,
  chartAggregationMode,
}: MergeQueryResultsAndAggregateTimeSeries) => {
  if (chartAggregationMode === ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET) {
    return timeSeriesQueryResults
  }

  // all data stream ids to group by
  const dataStreamIds = timeSeriesQueryResults
    .filter((timeSeriesQueryResult, index, results) => {
      const isNotDuplicate =
        results.findIndex((r) => r.data?.dataStreamId === timeSeriesQueryResult.data?.dataStreamId) === index
      return timeSeriesQueryResult.data?.dataStreamId && isNotDuplicate
    })
    .map((timeSeriesQueryResult) => {
      return timeSeriesQueryResult.data?.dataStreamId
    })

  const mergedQueryResults = dataStreamIds.reduce<QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[]>(
    (mergedResults, dataStreamId) => {
      const timeSeriesQueryResultsWithData = timeSeriesQueryResults.filter((timeSeries) => {
        return (
          typeof timeSeries.data !== 'undefined' &&
          timeSeries.data.dataStreamId === dataStreamId &&
          timeSeries?.data?.type !== TimeSeriesType.BACK_CAST
        )
      })

      const timeSeriesResultsWithData = timeSeriesQueryResultsWithData.map((timeSeries) => {
        return timeSeries.data as TimeSeriesResult
      })

      const mergedTimeSeriesResults = mergeAndAggregateTimeSeriesResults({
        timeSeriesResults: timeSeriesResultsWithData,
        chartAggregationMode,
      })

      const mergedQueryResult = mergeQueryResults(timeSeriesQueryResults, mergedTimeSeriesResults)
      return [...mergedResults, mergedQueryResult]
    },
    [],
  )

  // this is same as above but only for back cast
  const mergedBackCastQueryResults = dataStreamIds.reduce<QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[]>(
    (mergedResults, dataStreamId) => {
      const timeSeriesQueryResultsWithData = timeSeriesQueryResults.filter((timeSeries) => {
        return (
          typeof timeSeries.data !== 'undefined' &&
          timeSeries.data.dataStreamId === dataStreamId &&
          timeSeries?.data?.type === TimeSeriesType.BACK_CAST
        )
      })

      const timeSeriesResultsWithData = timeSeriesQueryResultsWithData.map((timeSeries) => {
        return timeSeries.data as TimeSeriesResult
      })

      const mergedTimeSeriesResults = mergeAndAggregateTimeSeriesResults({
        timeSeriesResults: timeSeriesResultsWithData,
        chartAggregationMode,
      })

      const mergedQueryResult = mergeQueryResults(timeSeriesQueryResults, mergedTimeSeriesResults)
      return [...mergedResults, mergedQueryResult]
    },
    [],
  )

  return [...mergedQueryResults, ...mergedBackCastQueryResults]
}

// hooks

/**
 * converts time series data into highcharts compatible chart series
 * takes aggregation settings into account to aggregate time series of the same type across all selected assets
 */
export const useChartSeriesSet = () => {
  // collect required data
  const allAssets = useUniqueAllAssets()
  const selectedAssets = useUniqueSelectedAssets()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const chartAggregationMode = useSelector(workspaceDraftChartAggregationModeSelector)
  const selectedModels = useSelector(workspaceDraftSelectedModelsSelector)

  // const fetchedModelsByAsset = useSelector(getForecastModelsFromReactQuerySelector)
  // let allModels: ForecastModelForChart[] = []
  // Object.keys(fetchedModelsByAsset).forEach((assetId) => {
  //   allModels = [...allModels, ...fetchedModelsByAsset[assetId]]
  // })

  // additional data for various types
  const forecastConfigs = useSiteForecastConfigs()
  const areas = useSelector(getAreaConfigsAreasSelector)
  const weatherConfigs = useSelector(getWeatherConfigsResultSelector)
  const metaData = useSelector(getMetaDataResultSelector) || undefined

  // time series data
  const mainTimeSeriesResult = useTimeSeriesQueryResults()

  const revenueTimeSeriesResult = useRevenueTimeSeries(selectedAssets) || []

  const timeSeriesQueryResults = [...mainTimeSeriesResult, ...revenueTimeSeriesResult]

  // console.log({ timeSeriesQueryResults })

  // aggregate per data stream
  // for each data stream we aggregate all selected assets and combine their data into one new series
  const aggregatedTimeSeriesQueryResults = useMemo(() => {
    return mergeQueryResultsAndAggregateTimeSeries({
      timeSeriesQueryResults,
      chartAggregationMode,
    })
  }, [
    JSON.stringify(timeSeriesQueryResults), // TODO this is changing infinitely, should be fixed
    chartAggregationMode,
  ])

  // if (aggregatedTimeSeriesQueryResults) {
  //   console.log({ aggregatedTimeSeriesQueryResults })
  // }

  // create chart series
  const chartSeriesSet = useMemo(() => {
    const indexes: Record<string, number> = {}
    // console.log({ aggregatedTimeSeriesQueryResults })
    const set = aggregatedTimeSeriesQueryResults.reduce<ChartSeriesOptions[]>((acc, timeSeriesQueryResult) => {
      // console.log({ acc, timeSeriesQueryResult })
      const timeSeriesResult = timeSeriesQueryResult.data || timeSeriesQueryResult.error
      if (timeSeriesResult) {
        const asset = allAssets.find((asset) => asset.id === timeSeriesResult.assetId)
        const assets = allAssets.filter((asset) => timeSeriesResult.assetIds?.includes(asset.id))

        if (timeSeriesResult.dataStreamId) {
          // we keep track how many times a data stream has been drawn so that
          // we can show their colors in slight variations of the original one
          indexes[timeSeriesResult.dataStreamId] = (indexes[timeSeriesResult.dataStreamId] || 0) + 1
        }

        return [
          ...acc,
          // add another chart series for this data stream
          createChartSeries({
            result: timeSeriesQueryResult,
            asset,
            assets,
            timeSeries: timeSeriesResult.data,
            id: timeSeriesResult.dataStreamId,
            queryResultId: timeSeriesResult.id,
            type: timeSeriesResult.type,
            subType: timeSeriesResult.subType,
            classifier: timeSeriesResult.classifier,
            chartAggregationMode,
            selectedDataStreams: dataSelection,
            forecastConfigs: forecastConfigs.data || [],
            weatherConfigs,
            metaData,
            areas,
            modelId: timeSeriesResult?.modelId || undefined,
            selectedModels,
            index: timeSeriesResult.dataStreamId ? indexes[timeSeriesResult.dataStreamId] : undefined,
          }),
        ]
      } else {
        return acc
      }
    }, [])

    return set
  }, [aggregatedTimeSeriesQueryResults, allAssets, dataSelection, forecastConfigs.data])

  // we use debounced series in order to avoid too many costly re-renders in highcharts
  const [debouncedChartSeriesSet] = useDebounce(chartSeriesSet, 30, { leading: true })
  // if (debouncedChartSeriesSet) {
  //   console.log({ debouncedChartSeriesSet })
  // }
  return debouncedChartSeriesSet
}
