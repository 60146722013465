import { call, put, takeLatest } from 'redux-saga/effects'

import * as actionTypes from 'modules/dataStreams/areaForecast/redux_store/areaForecast.action.types'
import { Result } from 'utils/request'
import { getSubscribedProductCodes } from 'modules/dataStreams/areaForecast/redux_store/areaForecast.api'

export function* getSubscribedProductCodesSaga() {
  const result: Result<string[]> = yield call(getSubscribedProductCodes)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.GET_SUBSCRIBED_PRODUCT_CODES_SUCCESS, codes: result.getData() })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_SUBSCRIBED_PRODUCT_CODES_FAILURE, error })
  }
}

export function* areaForecastsSaga() {
  yield takeLatest(actionTypes.GET_SUBSCRIBED_PRODUCT_CODES_REQUEST, getSubscribedProductCodesSaga)
}
