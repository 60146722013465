import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form } from 'react-final-form'
import { Checkbox, TextField } from 'final-form-material-ui'
import { Box, Button, Divider, MenuItem } from '@material-ui/core'
import { jt, msgid, ngettext, t } from 'ttag'
import Select from '@material-ui/core/Select'

import {
  testIdButtonSubmit,
  testIdError,
  testIdInputPassword,
  testIdInputUsername,
  testIdLoginForm,
} from 'modules/auth/AuthenticationForm.ids'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { CLEAR_LOGIN_STATE } from 'modules/auth/redux_store/auth.action.types'
import LoadingButton from 'ui/form/LoadingButton'
import { LoginType } from 'modules/auth/Auth.types'
import Flex from 'ui/styles/Flex'
import {
  AuthActionButtonContainer,
  AuthFormContainer,
  AuthFormField,
  AuthFormTitle,
  CheckboxControl,
  CheckboxLabel,
} from 'ui/form/authForm.style'
import {
  loginErrorSelector,
  loginInitializedSelector,
  loginLoadingSelector,
} from 'modules/auth/redux_store/state/login'
import { authedSelector, getUserLoadingSelector } from 'modules/auth/redux_store/state/getUser'
import { AuthForms } from 'fixtures/auth'
import ErrorMessage from 'ui/form/ErrorMessage'
import PasswordField from 'ui/form/PasswordField'
import styled from 'styled-components'
import { LinkToContactSales } from 'ui/elements/MailLink'
import { ACCOUNT_EXPIRED_ERR_MSG } from 'utils/request'
import { AuthContext } from 'modules/auth/AuthContainer'
import { transformLoginDataBeforeSubmit } from 'utils/auth'
import { impersonateUser } from 'modules/user/api/user.api'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { getCompaniesForSubUser } from 'modules/userManagement/api/userManagement.api'
import { logoutInitiatedSelector } from 'modules/auth/redux_store/state/logout'

const CheckboxContainer = styled(Flex)`
  margin-top: 1.5em;
`

interface LoginFormProps {
  username?: string
  password?: string
  onChangeActiveForm?: (e: any, formType: AuthForms) => void
  onUpdateContextData?: (data: LoginType) => void
}

const LoginForm: React.FC<LoginFormProps> = ({
  username = '',
  password = '',
  onChangeActiveForm,
  onUpdateContextData,
}) => {
  const initialData: LoginType = {
    username: username,
    password: password,
    rememberMe: true,
  }

  const dispatch = useDispatch()
  const { register, forgotPassword } = AuthForms
  const loading = useSelector(loginLoadingSelector)
  const error = useSelector(loginErrorSelector)
  const userLoading = useSelector(getUserLoadingSelector)
  const loginInitialized = useSelector(loginInitializedSelector)
  const authed = useSelector(authedSelector)
  const logoutInitiated = useSelector(logoutInitiatedSelector)
  const { authData } = useContext(AuthContext)
  const [loginData, setLoginData] = useState<LoginType>(initialData.username ? initialData : (authData as LoginType))

  // States for dealing with company selection
  const [showCompanySelectionUI, setShowCompanySelectionUI] = useState(false)
  const [companiesList, setCompaniesList] = useState<string[]>([])
  const [selectedCompany, setSelectedCompany] = useState<string>('')
  // const dismissError = useCallback(() => dispatch({ type: actionTypes.LOGIN_DISMISS }), [])

  const validateLoginForm = useCallback((formValues: LoginType) => {
    const errors: LoginType = {} as LoginType
    if (!formValues.username) {
      errors.username = t`Required`
    }
    if (!formValues.password) {
      errors.password = t`Required`
    }
    return errors
  }, [])

  const handleFormSubmit = useCallback((loginData: LoginType) => {
    const data = transformLoginDataBeforeSubmit(loginData)
    dispatch({ type: actionTypes.LOGIN_REQUEST, loginData: data })
  }, [])

  const handleChangeActiveForm = useCallback(
    (event: React.MouseEvent<HTMLElement>, formType) => {
      if (!onChangeActiveForm) return
      onChangeActiveForm(event, formType)
    },
    [onChangeActiveForm],
  )

  const linkSignUp = <a key="registerLink" onClick={(e) => handleChangeActiveForm(e, register)}>{t`Register here`}</a>

  useEffect(() => {
    dispatch({ type: CLEAR_LOGIN_STATE })
  }, [])

  useEffect(() => {
    const data = initialData.username ? initialData : authData
    setLoginData(data)
  }, [authData, initialData])

  const handleFormValuesChange = useCallback(
    (values) => {
      if (JSON.stringify(values) !== JSON.stringify(authData) && onUpdateContextData) {
        onUpdateContextData(values)
      }
    },
    [authData, onUpdateContextData],
  )

  useEffect(() => {
    // !logoutInitiated is used to prevent company selection ui from flickering while logout
    // setTimeout is used to prevent company selection ui from flickering while login
    if (authed && loginInitialized && !logoutInitiated) {
      setTimeout(() => {
        setShowCompanySelectionUI(true)
      }, 100)
    }
  }, [authed, loginInitialized, logoutInitiated])

  useEffect(() => {
    if (showCompanySelectionUI) {
      getCompaniesForSubUser(false).then((res) => {
        if (res?.length > 0) {
          if (loginData.username) {
            res.push(loginData.username)
          }
          setCompaniesList(res)
        }
      })
    }
  }, [showCompanySelectionUI, loginData])

  const handleCompanySelectionChange = (event: any) => {
    setSelectedCompany(event.target.value as string)
  }

  const handleButtonClick = () => {
    if (selectedCompany === loginData?.username) {
      window.location.reload()
    } else {
      impersonateUser(selectedCompany).then(() => {
        window.location.reload()
      })
    }
  }

  const valueForPluralText = useMemo(() => {
    return companiesList.length - 1
  }, [companiesList])

  return (
    <>
      <AuthFormContainer data-testid={testIdLoginForm}>
        <AuthFormTitle variant="h1">
          {showCompanySelectionUI ? t`Select account` : t`Sign in to your enercast account`}
        </AuthFormTitle>
        {showCompanySelectionUI && (
          <div style={{ marginTop: '0.2em' }}>
            {ngettext(
              msgid`You have access to the following company account in addition to your personal account. Please
            choose the account into which you would like to log in.`,
              `You have access to the following company accounts in addition to your personal account. Please
            choose the account into which you would like to log in.`,
              valueForPluralText,
            )}
          </div>
        )}
        {!showCompanySelectionUI ? (
          <Form
            onSubmit={handleFormSubmit}
            initialValues={loginData}
            validate={validateLoginForm}
            render={({ handleSubmit, values, form }) => {
              return (
                <form
                  onKeyUp={() => handleFormValuesChange(values)}
                  onClick={() => handleFormValuesChange(values)}
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <ErrorMessage data-testid={testIdError}>
                    {!loading && !form.getState().modifiedSinceLastSubmit && error ? (
                      <>
                        {error === ACCOUNT_EXPIRED_ERR_MSG
                          ? jt`Your free trial has expired. Please contact ${LinkToContactSales} to reactivate your enercast ID.`
                          : error}
                      </>
                    ) : (
                      <></>
                    )}
                  </ErrorMessage>

                  <AuthFormField data-testid={testIdInputUsername}>
                    <Field fullWidth name="username" component={TextField} label={t`enercast ID`} />
                  </AuthFormField>

                  <AuthFormField data-testid={testIdInputPassword}>
                    <PasswordField name="password" label={t`Password`} />
                  </AuthFormField>
                  <AuthFormField>
                    <Box ml={0.5}>
                      <CheckboxContainer direction="row" alignItems="flex-start" justifyContent="flex-start">
                        <CheckboxControl
                          label={''}
                          control={<Field name="rememberMe" type="checkbox" component={Checkbox} />}
                        />
                        <CheckboxLabel>{t`Keep me signed in on this computer`}</CheckboxLabel>
                      </CheckboxContainer>
                    </Box>
                  </AuthFormField>
                  <Flex direction="column">
                    <AuthFormField>
                      <AuthActionButtonContainer mb={1}>
                        <LoadingButton
                          loading={loading || userLoading || loginInitialized}
                          variant="contained"
                          color="primary"
                          type="submit"
                          data-testid={testIdButtonSubmit}
                        >
                          {t`Log in`}
                        </LoadingButton>
                      </AuthActionButtonContainer>
                    </AuthFormField>
                    <Box mb={1}>
                      <AuthFormField>
                        <a onClick={(e) => handleChangeActiveForm(e, forgotPassword)}>{t`Forgot Password?`}</a>
                      </AuthFormField>
                    </Box>
                    <AuthFormField>{jt`Do not have an account? ${linkSignUp}`}</AuthFormField>
                  </Flex>
                </form>
              )
            }}
          />
        ) : (
          <div>
            <FormControl fullWidth style={{ marginBottom: '1.2em' }}>
              <InputLabel id="select-account-label-id">{t`Account`}</InputLabel>
              <Select
                labelId="select-account-label-id"
                id="account"
                label={t`Account`}
                onChange={handleCompanySelectionChange}
                value={selectedCompany}
              >
                {companiesList.map((company, index) => (
                  <MenuItem
                    key={index}
                    value={company}
                    style={{ borderTop: index === companiesList.length - 1 ? '1px solid rgba(0, 0, 0, 0.12)' : 'none' }}
                  >
                    {company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              disabled={selectedCompany.length === 0}
              variant="contained"
              color="primary"
              onClick={() => handleButtonClick()}
            >
              {t`Proceed`}
            </Button>
          </div>
        )}
      </AuthFormContainer>
    </>
  )
}

export default React.memo(LoginForm)
