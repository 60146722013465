import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Card, CardContent } from '@material-ui/core'

import LoginForm from 'modules/auth/LoginForm'
import RegistrationForm from 'modules/auth/RegistrationForm'
import Flex from 'ui/styles/Flex'
import { jt, t, useLocale } from 'ttag'
import Logo from 'modules/app/header/Logo'

import authBanner from 'media/auth-banner.jpg'
import { QUERY_AUTH_FORM, useQueryParams, useQueryString } from 'utils/query-string'
import { LanguageKeys } from 'fixtures/header'
import { AuthForms } from 'fixtures/auth'
import ResetPasswordForm from 'modules/auth/ResetPasswordForm'
import { useRouterMatch } from 'utils/route'
import { ROUTE_ACTIVATE_USER, ROUTE_RESET_PASSWORD } from 'modules/app/Routes'
import ActivateUser from 'modules/auth/ActivateUser'
import ForgotPasswordForm from 'modules/auth/ForgotPasswordForm'
import MailLink, { MailAddresses } from 'ui/elements/MailLink'
import { LoginType, RegistrationType } from 'modules/auth/Auth.types'
import { DeepPartial } from 'ts-essentials'
import { useSelector } from 'react-redux'
import { authedSelector } from 'modules/auth/redux_store/state/getUser'

const authContainerWidth = 37
const authContainerMarginTop = 12

const AuthWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  background-color: white;
`

const Banner = styled.div`
  height: 350px;
  background: url(${authBanner});
  background-size: cover;
  background-repeat: no-repeat;
`

const HeaderContainer = styled(Flex)`
  padding: 2.5em;
`
const BodyContainer = styled(Flex)`
  margin-top: -${authContainerMarginTop}em;
`
const HeaderText = styled.div`
  font-size: 3em;
  font-weight: 300;
  margin-right: 8px;
  margin-top: -7px;
  color: white;
`

const AuthFormWrapper = styled.div`
  width: ${authContainerWidth}em;
  position: relative;
`

const AuthFormContainer = styled(Card)`
  & .MuiCard-root {
    min-height: 500px;
    width: ${authContainerWidth}em;
    margin-bottom: 1em;
  }
`

const SubHeading = styled.div`
  height: 3em;
  font-size: 2em;
  color: white;
  margin-top: 0.25em;
  & a {
    color: initial;
    text-decoration: initial;
  }
`

export const ContactUsText = styled.div`
  color: white;
  margin-left: 5px;
  text-decoration: none;
  :hover {
    border-bottom: 1px solid white;
  }
`

const LanguageContainer = styled(Flex)`
  color: white;
  font-weight: bold;
`

const LanguageLink = styled.div`
  cursor: pointer;
  margin: 5px 0px;
  &:hover {
    text-decoration: underline;
  }
`

const LanguageSeparator = styled.div`
  margin: 0 2px;
`

export type AuthContextDataType = DeepPartial<LoginType> | DeepPartial<RegistrationType>

interface AuthContextProps {
  authData: AuthContextDataType
}

export const AuthContext = React.createContext<AuthContextProps>({
  authData: {} as AuthContextDataType,
})

const initialAuthContextData = {
  ackTermsAndConditions: false,
  langKey: LanguageKeys.english,
  rememberMe: true,
}

const AuthContainer: React.FC = () => {
  const { english, german } = LanguageKeys
  const { login, register, forgotPassword, activateUser, resetPassword } = AuthForms
  const { onUpdateQueryString, onDeleteQueryStrings } = useQueryString()
  const { queryParams, queryParamsInitialized } = useQueryParams()
  const authed = useSelector(authedSelector)
  const freeTrailPeriod = <b key="trailPeriod">{t`30 day trial`}</b>
  const { routeMatch: isActivateUser } = useRouterMatch(ROUTE_ACTIVATE_USER)
  const { routeMatch: isResetPassword } = useRouterMatch(ROUTE_RESET_PASSWORD)
  const [contextData, setContextData] = useState<AuthContextProps>({ authData: initialAuthContextData })

  const langKey = localStorage.getItem('language') || english
  window.__localeId__ = langKey
  useLocale(langKey)

  const [activeForm, setActiveForm] = useState<string | AuthForms>()

  const handleChangeActiveForm = useCallback(
    (event: React.ChangeEvent, formType: AuthForms) => {
      onUpdateQueryString({ [QUERY_AUTH_FORM]: formType })
    },
    [onUpdateQueryString],
  )

  const handleSetLanguageTo = useCallback(
    (langKey: string) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      event.stopPropagation()
      window.__localeId__ = langKey
      localStorage.setItem('language', langKey)
      location.reload()
    },
    [],
  )

  const handleUpdateContextData = useCallback(
    (data: LoginType | RegistrationType) => {
      const userId = (activeForm === register ? data?.login : data?.username) || ''
      const syncData = { ...data, username: userId, login: userId }
      setContextData({ authData: syncData })
    },
    [activeForm],
  )

  useEffect(() => {
    return () => {
      onDeleteQueryStrings([QUERY_AUTH_FORM])
    }
  }, [])

  useEffect(() => {
    if (!queryParamsInitialized) return
    let formName
    if (isActivateUser || isResetPassword) {
      formName = isActivateUser ? activateUser : resetPassword
    } else {
      formName = queryParams[QUERY_AUTH_FORM] ? queryParams[QUERY_AUTH_FORM] : login
    }
    setActiveForm(formName)
  }, [queryParams, queryParamsInitialized, isActivateUser])

  return (
    <Flex justifyContent="center" alignItems="center">
      <AuthWrapper>
        <Banner>
          <HeaderContainer direction="column">
            <Flex justifyContent="space-between" alignItems="flex-start">
              <HeaderText>{t`Welcome to enercast!`} </HeaderText>
              <Logo height="3em" />
            </Flex>

            <SubHeading>
              {isActivateUser && <>{t`Thank you for joining the enercast community!`}</>}
              {activeForm === register && (
                <div>
                  <Box
                    key="subHeading"
                    mb={0.7}
                  >{jt`Create your free enercast ID to start your ${freeTrailPeriod}.`}</Box>
                  <Flex>
                    {t`If you need any help, feel free to `}
                    <MailLink mailingAddress={MailAddresses.support}>
                      <ContactUsText>{t`contact us`}</ContactUsText>
                    </MailLink>
                    {'.'}
                  </Flex>
                </div>
              )}
            </SubHeading>
          </HeaderContainer>
        </Banner>
        {queryParamsInitialized && (
          <BodyContainer direction="column" alignItems="center" justifyContent="center">
            <AuthFormWrapper>
              {!authed && (
                <LanguageContainer justifyContent="flex-end" alignItems="center">
                  <LanguageLink onClick={handleSetLanguageTo(english)}>{t`EN`}</LanguageLink>
                  <LanguageSeparator>|</LanguageSeparator>
                  <LanguageLink onClick={handleSetLanguageTo(german)}>{t`DE`}</LanguageLink>
                </LanguageContainer>
              )}

              <AuthFormContainer>
                <AuthContext.Provider value={contextData}>
                  <CardContent>
                    {activeForm === login && (
                      <LoginForm
                        onUpdateContextData={handleUpdateContextData}
                        onChangeActiveForm={handleChangeActiveForm}
                      />
                    )}
                    {activeForm === register && (
                      <RegistrationForm
                        onUpdateContextData={handleUpdateContextData}
                        onChangeActiveForm={handleChangeActiveForm}
                      />
                    )}
                    {activeForm === forgotPassword && (
                      <ForgotPasswordForm
                        onUpdateContextData={handleUpdateContextData}
                        onChangeActiveForm={handleChangeActiveForm}
                      />
                    )}
                    {activeForm === activateUser && <ActivateUser />}
                    {activeForm === resetPassword && <ResetPasswordForm />}
                  </CardContent>
                </AuthContext.Provider>
              </AuthFormContainer>
            </AuthFormWrapper>
          </BodyContainer>
        )}
      </AuthWrapper>
    </Flex>
  )
}

export default React.memo(AuthContainer)
