import React, { useCallback, useMemo } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { c } from 'ttag'

import timezones, { Timezone } from 'fixtures/timezones'
import { testIdTimezoneEntry, testIdTimezoneSelector } from 'ui/datetime/TimezoneSelector.ids'

const groupByCategory = (timezone: Timezone) =>
  timezone.includes('/') ? timezone.split('/')[0] : c('Time zone').t`Other`

interface TimezoneSelectorProps {
  defaultValue: Timezone
  onChange?: (value: Timezone) => void
  showLabel?: boolean
}
const TimezoneSelector: React.FC<TimezoneSelectorProps & AutocompleteProps<Timezone>> = ({
  defaultValue,
  onChange,
  showLabel = true,
  ...rest
}) => {
  const options = useMemo(() => [...timezones], [])

  const getOptionLabel = useCallback(
    (option) => {
      if (typeof option === 'number') {
        return null
      } else {
        return option
      }
    },
    [options],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent, timezone: Timezone | null) => {
      if (!timezone || !onChange) return
      event.stopPropagation()
      onChange(timezone)
    },
    [onChange],
  )

  return (
    <div data-testid={testIdTimezoneSelector}>
      <Autocomplete
        options={options}
        groupBy={groupByCategory}
        getOptionLabel={getOptionLabel}
        multiple={false}
        renderInput={(params) => (
          <TextField {...params} label={showLabel ? c('Time zone').t`Time zone` : ''} variant="standard" />
        )}
        renderOption={(option) => <option data-testid={testIdTimezoneEntry}>{option}</option>}
        defaultValue={defaultValue}
        onChange={handleChange}
        {...rest}
      />
    </div>
  )
}

export const TimezoneSelectorWrapper: React.FC<FieldRenderProps<Timezone>> = ({
  input: { defaultValue, onChange, ...restInput },
  meta,
  ...rest
}) => {
  // const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  return <TimezoneSelector showLabel={rest.showLabel} defaultValue={defaultValue} onChange={onChange} {...restInput} />
}

export default React.memo(TimezoneSelector)
