import createReducer from 'utils/createReducer'
import { ResetPasswordActionType } from 'modules/auth/Auth.types'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

interface State {
  loading: boolean
  error: string | null
  result: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  result: false,
}

const loading = createReducer<State['loading'], ResetPasswordActionType>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.RESET_PASSWORD_REQUEST:
      return true
    case actionTypes.RESET_PASSWORD_SUCCESS:
    case actionTypes.RESET_PASSWORD_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], ResetPasswordActionType & { error: State['error'] }>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.RESET_PASSWORD_SUCCESS:
      case actionTypes.RESET_PASSWORD_DISMISS:
        return null
      case actionTypes.RESET_PASSWORD_FAILURE:
        return error
    }
    return state
  },
)

const result = createReducer<State['result'], ResetPasswordActionType>((state = initialState.result, { type }) => {
  switch (type) {
    case actionTypes.RESET_PASSWORD_SUCCESS:
      return true
    case actionTypes.RESET_PASSWORD_FAILURE:
    case actionTypes.CLEAR_RESET_PASSWORD_STATE:
      return false
  }
  return state
})

export const resetPasswordReducer = combineReducers({ loading, error, result })

// Selectors
export const resetPasswordResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.auth.resetPassword.result,
  (result) => result,
)
export const resetPasswordLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.resetPassword.loading,
  (loading) => loading,
)
export const resetPasswordErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.resetPassword.error,
  (error) => error,
)
