import cloneDeep from 'clone-deep'

import { MetaData, MetaDataHierarchy, Structure } from 'modules/dataStreams/dataStreams.types'
import { ReTableItem } from 'modules/reTable/reTable.types'
import { UniqueCatalogLabel, WeatherCatalog } from 'modules/weather/store/weather.types'
import { c } from 'ttag'

const getWeatherCatalogTranslations = () => {
  return {
    weatherdata: c('Weather Data:Catalog').t`Historical Weather Data`,
    weatherforecast: c('Weather Data:Catalog').t`Historical Weather Forecast`,
    simple: c('Weather Data:Catalog').t`Simple Data`,
    expert: c('Weather Data:Catalog').t`Expert Data`,
    ecmwfh: c('Weather Data:Catalog').t`ECMWF HRES`,
    ecmwfm: c('Weather Data:Catalog').t`ECMWF Monthly`,
    gfsn: c('Weather Data:Catalog').t`NOAA GFS`,
    namn: c('Weather Data:Catalog').t`NOAA NAM`,
    ukmet4: c('Weather Data:Catalog').t`UK Met Office`,
    iconw: c('Weather Data:Catalog').t`DWD ICON WORLD`,
    icone: c('Weather Data:Catalog').t`DWD ICON EU`,
    cosmod: c('Weather Data:Catalog').t`DWD COSMO DE`,
    cosmoe: c('Weather Data:Catalog').t`DWD COSMO EU`,
    cmcg: c('Weather Data:Catalog').t`CMC GDPS`,
    dwd: c('Weather Data:Catalog').t`DWD weather station`,
    'weatherforecast rec': c('Weather Data:Catalog').t`Recent Weather Forecast`,
    'simple rec': c('Weather Data:Catalog').t`Simple Data`,
    'expert rec': c('Weather Data:Catalog').t`Expert Data`,
    'ecmwfh rec': c('Weather Data:Catalog').t`ECMWF HRES`,
    'ecmwfm rec': c('Weather Data:Catalog').t`ECMWF Monthly`,
    'gfsn rec': c('Weather Data:Catalog').t`NOAA GFS`,
    'namn rec': c('Weather Data:Catalog').t`NOAA NAM`,
    'ukmet4 rec': c('Weather Data:Catalog').t`UK Met Office`,
    'iconw rec': c('Weather Data:Catalog').t`DWD ICON WORLD`,
    'icone rec': c('Weather Data:Catalog').t`DWD ICON EU`,
    'cosmod rec': c('Weather Data:Catalog').t`DWD COSMO DE`,
    'cosmoe rec': c('Weather Data:Catalog').t`DWD COSMO EU`,
    'cmcg rec': c('Weather Data:Catalog').t`CMC GDPS`,
    'dwd rec': c('Weather Data:Catalog').t`DWD weather station`,
  }
}

export const sortAlphabetically = (a: ReTableItem | undefined, b: ReTableItem | undefined) => {
  if (!a || !b) return 0
  return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
}

interface ConvertWeatherDataToTreeHierarchyProps {
  weatherCatalog: WeatherCatalog
  weatherParams: MetaData
  weatherHierarchy: MetaDataHierarchy
}
export const convertWeatherDataToTreeHierarchy = ({
  weatherCatalog,
  weatherParams,
  weatherHierarchy,
}: ConvertWeatherDataToTreeHierarchyProps): ReTableItem[] => {
  const catalogTranslations = getWeatherCatalogTranslations()
  // normalize data
  const weatherItems: ReTableItem[] = weatherHierarchy.reduce<ReTableItem[]>((items, hierarchyItem) => {
    const param = weatherParams.paramsDTO.find((p) => p.name === hierarchyItem.id)
    if (!param) return items

    const name = weatherCatalog.UniqueCatalogLabel[param.name as keyof UniqueCatalogLabel] || param.name

    const newItem: ReTableItem = { ...param, id: param.name, name, structure: hierarchyItem.structure }
    return [...items, newItem]
  }, [])

  // build tree nodes
  const treeNodeItems = weatherItems.reduce<ReTableItem[]>((nodeItems, item) => {
    const rootNode = (item.structure as Structure[]).find((s) => s.type === 'root')
    const categoryNode = (item.structure as Structure[]).find((s) => s.type === 'category')
    const modelNode = (item.structure as Structure[]).find((s) => s.type === 'model')

    const updatedNodeItems = [...nodeItems]

    const addIfNew = (node?: Structure, level?: number, ancestors: (ReTableItem | undefined)[] = []) => {
      if (!node) return

      const existingNodeItem = nodeItems.find((nodeItem) => nodeItem.id === node.value)
      if (existingNodeItem) return existingNodeItem

      const validAncestors = ancestors.filter((i) => Boolean(i)) as ReTableItem[]
      const newNodeItem: ReTableItem = {
        id: node.value,
        name: catalogTranslations[node.value as keyof typeof catalogTranslations],
        uiAncestors: validAncestors.map((i) => i.id),
        uiParents: validAncestors.length > 0 ? [validAncestors.reverse()[0].id] : [],
        uiChildren: [],
        uiDescendants: [],
        uiLevel: level,
      }

      updatedNodeItems.push(newNodeItem)
      return newNodeItem
    }

    const addDescendants = (nodeItem?: ReTableItem, descendants: (ReTableItem | undefined)[] = []) => {
      if (!nodeItem) return

      const validDescendants = descendants.filter((i) => Boolean(i)) as ReTableItem[]
      validDescendants.forEach((descendant, index) => {
        if (index === 0) {
          if (!Array.isArray(nodeItem.uiChildren)) nodeItem.uiChildren = []
          if (!nodeItem.uiChildren.includes(descendant.id)) {
            nodeItem.uiChildren.push(descendant.id)
          }
        }
        if (!Array.isArray(nodeItem.uiDescendants)) nodeItem.uiDescendants = []
        if (!nodeItem.uiDescendants.includes(descendant.id)) {
          nodeItem.uiDescendants.push(descendant.id)
        }
      })
    }

    const rootItem = addIfNew(rootNode, 0)
    const categoryItem = addIfNew(categoryNode, 1, [rootItem])
    const modelItem = addIfNew(modelNode, 2, [rootItem, categoryItem])

    addDescendants(rootItem, [categoryItem, modelItem])
    addDescendants(categoryItem, [modelItem])

    return updatedNodeItems
  }, [])

  // append hierarchy information
  weatherItems.forEach((weatherItem) => {
    const rootNode = (weatherItem.structure as Structure[]).find((s) => s.type === 'root')
    const categoryNode = (weatherItem.structure as Structure[]).find((s) => s.type === 'category')
    const modelNode = (weatherItem.structure as Structure[]).find((s) => s.type === 'model')
    const ancestorNodes = [rootNode, categoryNode, modelNode]
    const validAncestorNodes = ancestorNodes.filter((i) => Boolean(i)) as Structure[]
    const ancestors = validAncestorNodes.map((i) =>
      treeNodeItems.find((item) => {
        return item.id === i.value
      }),
    ) as ReTableItem[]
    const parents: ReTableItem[] = ancestors.length > 0 ? [ancestors.reverse()[0]] : []

    // add hierarchy info for tree node items
    ancestors.forEach((ancestor) => {
      ancestor.uiDescendants = [...(ancestor.uiDescendants || []), weatherItem.id]
    })
    parents.forEach((parent) => {
      parent.uiChildren = [...(parent.uiChildren || []), weatherItem.id]
    })

    // add hierarchy info for weather items
    ancestors.sort(sortAlphabetically)
    weatherItem.uiAncestors = ancestors.map((i) => i.id)
    weatherItem.uiParents = parents.map((i) => i.id)
    weatherItem.uiChildren = []
    weatherItem.uiDescendants = []
    weatherItem.uiLevel = ancestors.length - 1
  })

  // concat all items
  const allItems = [...weatherItems, ...treeNodeItems]
  allItems.sort(sortAlphabetically)

  // build final tree structure
  const sortedItems: ReTableItem[] = []
  const addSortedChildren = (children: string[], level: number, parents: string[], ancestors: string[]): void => {
    children.forEach((childId) => {
      const child = cloneDeep(allItems.find((item) => item.id === childId))
      if (child) {
        child.uiLevel = level
        child.uiParents = (child.uiParents || []).filter((uiParent) => parents.includes(uiParent))
        child.uiAncestors = (child.uiAncestors || []).filter((uiAncestor) => ancestors.includes(uiAncestor))
        sortedItems.push(child)
        if (child.uiChildren) {
          addSortedChildren(child.uiChildren, level + 1, [child.id], [...ancestors, child.id])
        }
      }
    })
  }

  allItems
    .filter((items) => (items.uiParents || []).length === 0)
    .forEach((rootAsset) => {
      sortedItems.push(rootAsset)
      addSortedChildren(rootAsset.uiChildren || [], 1, [rootAsset.id], [rootAsset.id])
    })

  return sortedItems
}
