import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { c, t } from 'ttag'

import { EnrichedQualityEvaluationItem, getActiveModels, getTranslationsTrainingResults } from 'utils/forecastModel'
import Number from 'ui/Number'
import StatusIcon from 'ui/elements/StatusIcon'
import { ErrorMeasurementType, ForecastModel } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import { useDispatch, useSelector } from 'react-redux'
import { Asset } from 'modules/asset/store/asset.types'
import Flex from 'ui/styles/Flex'
import DateRange from 'ui/DateRange'
import { getTrainingJobsResultSelector } from 'modules/quality/redux_store/state/getTrainingJobs'
import Spacer from 'ui/Spacer'
import ActivationState from 'modules/forecastModels/ActivationState'
import WeatherModelEnsembleBar from 'modules/forecastModels/WeatherModelEnsembleBar'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'
import NextPreviousButtons from 'ui/elements/NextPreviousButtons'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import { DATE_FORMAT_DEFAULT_SHORT, formatDate } from 'utils/date'
import { DetailsKey, DetailsKeyValueGrid, DetailsValue } from 'ui/form/assetForm.style'
import CopyToClipboard from 'ui/elements/CopyToClipboard'

const DetailsPaper = styled(Paper)`
  padding: 1em;
  overflow: auto;
`

const DetailsSection = styled.div`
  margin-bottom: 1em;
`

interface EvaluationValueProps {
  isPrimary: boolean
}
const EvaluationValue = styled(({ ...rest }) => <DetailsValue {...rest} />)<EvaluationValueProps>`
  font-weight: ${(props) => (props.isPrimary ? 'bold' : 'normal')};
`

const ActionButton = styled(Button)`
  &.MuiButton-root {
    margin-right: 0.5em;
  }
`

interface EvaluationProps {
  evaluation: EnrichedQualityEvaluationItem
  measurement: ErrorMeasurementType
}
const Evaluation: React.FC<EvaluationProps> = ({ evaluation, measurement }) => {
  const isPrimary = evaluation[`isPrimary${measurement.toUpperCase()}`]
  // const changePercentage = evaluation[`${measurement}ChangePercentage`]
  // const hasImproved = evaluation[`${measurement}HasImproved`]
  // const color =
  //   hasImproved === EvaluationImprovement.POSITIVE
  //     ? 'green'
  //     : hasImproved === EvaluationImprovement.NEGATIVE
  //     ? 'red'
  //     : undefined

  return (
    <DetailsKeyValueGrid>
      <DetailsKey>{getTranslationsTrainingResults()[measurement]}</DetailsKey>
      <EvaluationValue isPrimary={isPrimary}>
        {evaluation[measurement] && (
          <Flex direction="row" alignItems="center">
            <Number data={evaluation[measurement]} showFractionalPart={true} limit={3} unit="%" />
            {isPrimary && (
              <Typography color="primary" component="span">
                ({c('Site Forecast:Quality Evaluation').t`Primary`})
              </Typography>
            )}
            {/*<Spacer />*/}
            {/*{changePercentage && (*/}
            {/*  <>*/}
            {/*    <Number*/}
            {/*      data={changePercentage}*/}
            {/*      showFractionalPart={true}*/}
            {/*      limit={3}*/}
            {/*      changePrefix*/}
            {/*      unit="%"*/}
            {/*      color={color}*/}
            {/*    />*/}
            {/*  </>*/}
            {/*)}*/}
          </Flex>
        )}
      </EvaluationValue>
    </DetailsKeyValueGrid>
  )
}

interface ForecastModelDetailsProps {
  asset: Asset
  evaluation: EnrichedQualityEvaluationItem
  onCloseModelDetails: () => void
  totalItemsCount: number
  onGoToNextPrevItem: (nextItem: boolean) => void
}

const ForecastModelDetails: React.FC<ForecastModelDetailsProps> = ({
  asset,
  evaluation,
  onCloseModelDetails,
  totalItemsCount,
  onGoToNextPrevItem,
}) => {
  const dispatch = useDispatch()

  // forecast model
  const forecastModelsQueryResult = useForecastModelsByAsset(asset)
  const forecastModels = useMemo(() => forecastModelsQueryResult?.data, [forecastModelsQueryResult.data])

  const activeModels = useMemo(() => {
    if (forecastModels) {
      return getActiveModels(forecastModels)
    } else return []
  }, [forecastModels])

  const activeModelForThisForecastModelCategory = useMemo(
    () => activeModels.find((am) => am.category === evaluation.forecastModel?.category),
    [activeModels, evaluation],
  )

  const isActive = activeModelForThisForecastModelCategory?.uuid === evaluation.forecastModel?.uuid

  const [evaluationRunning, setEvaluationRunning] = useState<Record<string, boolean>>({})
  const evaluationInProgress = evaluation.status !== 'FINISHED'
  const running =
    (evaluation.forecastModel?.uuid && evaluationRunning[evaluation.forecastModel?.uuid]) || evaluationInProgress
  const disabled = !evaluation.forecastModel?.trainingId || running

  // training pipeline
  const trainingJobs = useSelector(getTrainingJobsResultSelector)
  const trainingJob = trainingJobs.find((t) => t.uuid === evaluation.meta)
  const trainingName = trainingJob?.jobName
  const trainingFrom = trainingJob?.trainingStart ? new Date(trainingJob.trainingStart) : null
  const trainingTo = trainingJob?.trainingEnd ? new Date(trainingJob.trainingEnd) : null

  let cs = undefined
  if (evaluation?.forecastModel?.parameter?.windCorrections) {
    cs =
      typeof evaluation?.forecastModel?.parameter?.windCorrections === 'string'
        ? JSON.parse(evaluation?.forecastModel?.parameter?.windCorrections)
        : evaluation?.forecastModel?.parameter?.windCorrections
  }

  const correction = cs?.name || ''

  const handleReevaluate = useCallback(
    (event, forecastModel: ForecastModel) => {
      event.stopPropagation()
      setEvaluationRunning((running) => {
        return {
          ...running,
          [forecastModel.uuid]: true,
        }
      })
      dispatch({ type: actionTypes.REEVALUATE_FORECAST_MODEL_REQUEST, asset, forecastModel })
    },
    [asset],
  )

  const handleActivate = useCallback(
    (event, forecastModel: ForecastModel) => {
      event.stopPropagation()
      dispatch({ type: actionTypes.ACTIVATE_FORECAST_MODEL_REQUEST, asset, forecastModel })
    },
    [asset],
  )

  const headerActions = (
    <Flex alignItems="center">
      <NextPreviousButtons
        showNextPrevOptions={true}
        onNextPrevItem={onGoToNextPrevItem}
        totalItems={totalItemsCount}
      />
      <SliderCloseButton onCloseSlider={onCloseModelDetails} />
    </Flex>
  )

  return (
    <DetailsPaper elevation={1}>
      <DetailsSection style={{ marginTop: '6.7em' }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{c('Training Results:Quality').t`Training Result`}</Typography>
          {headerActions}
        </Flex>
      </DetailsSection>

      <DetailsSection>
        <Flex direction="row">
          <Typography variant="subtitle1">{c('Training Results:Quality').t`Forecast Model`}</Typography>
          <Spacer />
          {evaluation.forecastModel && (
            <ActivationState asset={asset as Asset} forecastModel={evaluation.forecastModel} />
          )}
        </Flex>
        {evaluation.forecastModel ? (
          <>
            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Name:`}</DetailsKey>
              <DetailsValue>{evaluation.forecastModelName}</DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Forecast model ID:`}</DetailsKey>
              <DetailsValue>
                <Flex alignItems="center">
                  <div>{evaluation.forecastModelId}</div>
                  <CopyToClipboard title={t`Copy model UUID`} textToCopy={evaluation.forecastModelId} />
                </Flex>
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Quality config:`}</DetailsKey>
              <DetailsValue>{evaluation.qualityConfigName}</DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Model UUID:`}</DetailsKey>
              <DetailsValue>{evaluation.qualityConfigName}</DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Activation date:`}</DetailsKey>
              <DetailsValue>
                {evaluation.forecastModel?.activeFrom && (
                  <>{formatDate(evaluation.forecastModel?.activeFrom, null, DATE_FORMAT_DEFAULT_SHORT)}</>
                )}
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Weather ensemble:`}</DetailsKey>
              <DetailsValue>
                <Box width="18em">
                  <WeatherModelEnsembleBar weatherModels={evaluation.weatherModels} />
                </Box>
              </DetailsValue>
            </DetailsKeyValueGrid>

            <DetailsKeyValueGrid>
              <DetailsKey>{c('Training Results:Quality').t`Correction:`}</DetailsKey>
              <DetailsValue>{correction}</DetailsValue>
            </DetailsKeyValueGrid>

            <Box mt={1}>
              <Flex direction="row">
                <Spacer />
                <ActionButton
                  disabled={disabled}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => handleReevaluate(e, evaluation.forecastModel)}
                >
                  {t`Evaluate all`}
                </ActionButton>
                <ActionButton
                  disabled={isActive}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={(e) => handleActivate(e, evaluation.forecastModel)}
                >
                  {t`Activate`}
                </ActionButton>
              </Flex>
            </Box>
          </>
        ) : (
          c('Training Results:Quality').t`Warning: Associated Forecast Model not found`
        )}
      </DetailsSection>

      {/* -------------------- Evaluation details ------------------ */}
      <DetailsSection>
        <Typography variant="subtitle1">{c('Training Results:Quality').t`Quality Evaluation`}</Typography>

        <DetailsKeyValueGrid>
          <DetailsKey>{c('Training Results:Quality').t`Evaluation date:`}</DetailsKey>
          <DetailsValue>{formatDate(evaluation.created, null, DATE_FORMAT_DEFAULT_SHORT)}</DetailsValue>
        </DetailsKeyValueGrid>

        {/*<DetailsKeyValueGrid>*/}
        {/*  <DetailsKey>{c('Training Results:Quality').t`Status:`}</DetailsKey>*/}
        {/*  <DetailsValue>*/}
        {/*    <StatusIcon status={evaluation.evaluationStatus} />*/}
        {/*  </DetailsValue>*/}
        {/*</DetailsKeyValueGrid>*/}

        <Evaluation evaluation={evaluation} measurement="nRmse" />

        <Evaluation evaluation={evaluation} measurement="nMae" />

        <Evaluation evaluation={evaluation} measurement="absoluteDeviation" />

        <Evaluation evaluation={evaluation} measurement="nbias" />

        <Evaluation evaluation={evaluation} measurement="correlationCoefficient" />

        <DetailsKeyValueGrid>
          <DetailsKey>{c('Training Results:Quality').t`Evaluation period:`}</DetailsKey>
          <DetailsValue>
            {formatDate(evaluation.evaluationTimeRangeStart, null, DATE_FORMAT_DEFAULT_SHORT)} -
            {formatDate(evaluation.evaluationTimeRangeEnd, null, DATE_FORMAT_DEFAULT_SHORT)}
          </DetailsValue>
        </DetailsKeyValueGrid>
      </DetailsSection>

      <DetailsSection>
        <Flex direction="row">
          <Typography variant="subtitle1">{c('Training Results:Quality').t`Training Pipeline`}</Typography>
        </Flex>

        <DetailsKeyValueGrid>
          <DetailsKey>{c('Training Results:Quality').t`Name:`}</DetailsKey>
          <DetailsValue>
            <StatusIcon status={trainingName} />
          </DetailsValue>
        </DetailsKeyValueGrid>

        <DetailsKeyValueGrid>
          <DetailsKey>{c('Training Results:Quality').t`Training period:`}</DetailsKey>
          <DetailsValue>{trainingFrom && trainingTo && <DateRange from={trainingFrom} to={trainingTo} />}</DetailsValue>
        </DetailsKeyValueGrid>
      </DetailsSection>
    </DetailsPaper>
  )
}

export default React.memo(ForecastModelDetails)
