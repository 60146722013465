import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { RegistrationType } from 'modules/auth/Auth.types'
import { TextField } from 'final-form-material-ui'
import { AuthActionButtonContainer, AuthFormContainer, AuthFormField, AuthFormTitle } from 'ui/form/authForm.style'
import Flex from 'ui/styles/Flex'
import LoadingButton from 'ui/form/LoadingButton'

import * as actionTypes from './redux_store/auth.action.types'
import {
  forgotPasswordErrorSelector,
  forgotPasswordLoadingSelector,
  forgotPasswordResultSelector,
  ForgotPasswordType,
} from 'modules/auth/redux_store/state/forgotPassword'
import { Box, Button } from '@material-ui/core'
import { t } from 'ttag'
import ErrorMessage from 'ui/form/ErrorMessage'
import { AuthForms } from 'fixtures/auth'
import { validate } from 'utils/formValidations'
import SuccessMessage from 'ui/form/SuccessMessage'
import { transformForgotPasswordDataBeforeSubmit } from 'utils/auth'
import { AuthContext } from 'modules/auth/AuthContainer'

interface ForgotPasswordFormType {
  onChangeActiveForm: (e: any, formType: AuthForms) => void
  onUpdateContextData: (data: ForgotPasswordType) => void
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormType> = ({ onChangeActiveForm, onUpdateContextData }) => {
  const { login } = AuthForms
  const dispatch = useDispatch()
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false)
  const success = useSelector(forgotPasswordResultSelector)
  const loading = useSelector(forgotPasswordLoadingSelector)
  const error = useSelector(forgotPasswordErrorSelector)
  const { authData } = useContext(AuthContext)
  const [forgotPasswordData, setForgotPasswordData] = useState<ForgotPasswordType>(authData as ForgotPasswordType)

  // const dismissError = useCallback(() => dispatch({ type: actionTypes.RESET_PASSWORD_DISMISS }), [])

  const validateForgotPasswordForm = useCallback((formValues: ForgotPasswordType) => {
    const errors: RegistrationType = {} as RegistrationType
    if (!formValues.email) {
      errors.email = t`Required`
    }
    if (formValues.email && !validate.email(formValues.email)) {
      errors.email = t`Enter a valid e-mail address.`
    }
    return errors
  }, [])

  const handleFormSubmit = useCallback((forgotPasswordData: ForgotPasswordType) => {
    const data = transformForgotPasswordDataBeforeSubmit(forgotPasswordData)
    dispatch({ type: actionTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordData: data })
  }, [])

  const handleFormValuesChange = useCallback(
    (values) => {
      if (JSON.stringify(values) !== JSON.stringify(authData)) {
        onUpdateContextData(values)
      }
    },
    [authData, onUpdateContextData],
  )

  useEffect(() => {
    if (success) {
      setForgotPasswordSuccess(success)
    }
    dispatch({ type: actionTypes.CLEAR_FORGOT_PASSWORD_STATE })
  }, [success])

  useEffect(() => {
    setForgotPasswordData(authData)
  }, [authData])

  return (
    <AuthFormContainer>
      <AuthFormTitle variant="h1">{t`Forgot password`}</AuthFormTitle>

      {forgotPasswordSuccess && (
        <Flex direction="column">
          <Box mb={2}>
            <SuccessMessage successMsg={t`Check your e-mails for details on how to reset your password.`} />
          </Box>
          <div>
            <Button color="primary" onClick={(e) => onChangeActiveForm(e, login)} variant="contained">
              {t`Back to login`}
            </Button>
          </div>
        </Flex>
      )}
      {!forgotPasswordSuccess && (
        <Form
          onSubmit={handleFormSubmit}
          initialValues={forgotPasswordData}
          validate={validateForgotPasswordForm}
          render={({ handleSubmit, values, form }) => {
            return (
              <form
                onKeyUp={() => handleFormValuesChange(values)}
                onClick={() => handleFormValuesChange(values)}
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <ErrorMessage errorMsg={!loading && !form.getState().modifiedSinceLastSubmit && error ? error : ''} />
                <p>{t`Enter the e-mail address you used to register.`}</p>
                <AuthFormField>
                  <Field fullWidth name="email" type="email" component={TextField} label={t`Email`} />
                </AuthFormField>
                <Flex direction="column">
                  <AuthFormField>
                    <AuthActionButtonContainer mt={2} mb={1}>
                      <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
                        {t`Send`}
                      </LoadingButton>
                    </AuthActionButtonContainer>
                  </AuthFormField>
                  <AuthFormField>
                    <a onClick={(e) => onChangeActiveForm(e, login)}>{t`Back to login`}</a>
                  </AuthFormField>
                </Flex>
              </form>
            )
          }}
        />
      )}
    </AuthFormContainer>
  )
}

export default React.memo(ForgotPasswordForm)
