import styled from 'styled-components'
import { sideNavWidth, sidePanelBgColor, theme } from 'themes/theme-light'

interface SideNavContainerProps {
  zIndex?: string
  containerWidth?: string
}

export const SideNavContainer = styled.div<SideNavContainerProps>`
  position: absolute;
  background-color: ${sidePanelBgColor};
  box-shadow: ${theme.shadows[6]};
  height: 100%;
  overflow: hidden;
  z-index: ${(props) => props.zIndex || 'inherit'};
  min-width: ${sideNavWidth};
  width: ${(props) => props.containerWidth || sideNavWidth};
`
