// finds closest parent
export const closest = (element: HTMLElement, selector: string): HTMLElement | null => {
  let matchesFn = 'matches'

  // find vendor prefix
  ;['matches', 'msMatchesSelector'].some(function (fn) {
    if (typeof document.body[fn] == 'function') {
      matchesFn = fn
      return true
    }
    return false
  })

  let node: HTMLElement | null = element
  let parent

  // traverse parents
  while (node) {
    parent = node.parentElement
    if (parent && parent[matchesFn](selector)) {
      return parent
    }
    node = parent
  }

  return null
}

export const hasAncestor = (element: HTMLElement, ancestor: HTMLElement | null): boolean => {
  if (ancestor) return false

  let node: HTMLElement | null = element
  let parent

  // traverse parents
  while (node) {
    parent = node.parentElement
    if (parent && parent === ancestor) {
      return true
    }
    node = parent
  }

  return false
}

export const hasAncestorOrSelf = (element: HTMLElement, ancestor: HTMLElement | null): boolean => {
  return element === ancestor || hasAncestor(element, ancestor)
}
