import React from 'react'
import Flex from 'ui/styles/Flex'
import { Box, Typography } from '@material-ui/core'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import EmptyBox from 'ui/EmptyBox'
import styled from 'styled-components'

export const EmptyBoxContainer = styled.div`
  padding: 0 1rem;
`

interface DetailsNotFoundProps {
  message: string | string[]
  onClose?: () => void
  hideIcon?: boolean
}

const DetailsNotFound: React.FC<DetailsNotFoundProps> = ({ message, onClose, hideIcon = false }) => {
  return (
    <EmptyBoxContainer>
      {onClose && (
        <Flex justifyContent="flex-end">
          <Box mt={1}>
            <SliderCloseButton onCloseSlider={onClose} />
          </Box>
        </Flex>
      )}

      <Box mt={5}>
        <Flex justifyContent="center" alignItems="center">
          <EmptyBox hideIcon={hideIcon} icon="exclamation">
            <Typography>
              <>{message}</>
            </Typography>
          </EmptyBox>
        </Flex>
      </Box>
    </EmptyBoxContainer>
  )
}

export default React.memo(DetailsNotFound)
