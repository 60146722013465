import React from 'react'
import { Box, IconButton } from '@material-ui/core'
import Flex from 'ui/styles/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import {
  getDataStreamDetailsQueryObj,
  getManageDataStreamQueryObj,
  QUERY_NEW_SCHEDULE,
  QUERY_NEW_SITE_FORECAST,
  stringifyQueryParams,
} from 'utils/query-string'
import { ROUTE_WORKBENCH } from 'modules/app/Routes'
import { ForecastConfig, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { dataStreamTypeQuery } from 'utils/dataStream'

const DetailsLink = styled(Link)`
  margin-left: 0.5em;
`

interface PopperTooltipContentSiteForecastsProps {
  siteForecasts: ForecastConfig[] | null
}

const PopperTooltipContentSiteForecasts: React.FC<PopperTooltipContentSiteForecastsProps> = ({ siteForecasts }) => {
  const { [TimeSeriesType.SITE_FORECAST]: siteForecast } = dataStreamTypeQuery

  return (
    <>
      {siteForecasts.map((config) => {
        const queryData = {
          ...getManageDataStreamQueryObj(siteForecast),
          ...getDataStreamDetailsQueryObj(config.id),
          [QUERY_NEW_SITE_FORECAST]: undefined,
          [QUERY_NEW_SCHEDULE]: undefined,
        }
        const queryString = stringifyQueryParams(queryData)
        const detailsURL = `${ROUTE_WORKBENCH}?${queryString}`
        return (
          <Box mb={0.5} key={config.id}>
            <Flex alignItems="center" justifyContent="space-between">
              <>{config.name}</>
              <DetailsLink className="details-link" to={detailsURL}>
                <IconButton size="small">
                  <FontAwesomeIcon icon="eye" color={theme.palette.primary.main} />
                </IconButton>
              </DetailsLink>
            </Flex>
          </Box>
        )
      })}
    </>
  )
}

export default React.memo(PopperTooltipContentSiteForecasts)
