import React, { useCallback, useEffect, useMemo } from 'react'
import { FormHeaderContainer } from 'ui/form/form.style'
import DetailsFormHeader from 'ui/form/DetailsFormHeader'
import { getPenaltyDetailsQueryObj, QUERY_PENALTY_DETAILS, useQueryParams, useQueryString } from 'utils/query-string'
import { getNextPrevNavigationIndex } from 'utils/route'
import {
  PenaltyFormData,
  PenaltyTableTypeOfRow,
  usePenaltyFormSaveMainItemKey,
  usePenaltyFormSaveMainItemKeySaveMutation,
} from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import {
  PenaltyFormSaveMainItem,
  PenaltyRegulationTableItemNew,
} from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { FormApi } from 'final-form'
import { AnyObject, Field, FormSpy, useField } from 'react-final-form'
import ActionButton from 'ui/form/ActionButton'
import { t } from 'ttag'
import {
  FormSaveOptions,
  getFormSaveMenuItems,
  getInvalidFieldsErrorMessagesForCertainForm,
  penaltyFormRequiredFields,
} from 'utils/form'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { UseMutationResult } from 'react-query'
import FinalFormAutocompleteField from 'ui/elements/FinalFormAutocompleteField'
import {
  getPenaltyFormHeaderTitle,
  getPenaltyGeneratorTypes,
  usePenaltyRegulationTableItems,
} from 'utils/penaltyRegulations'
import { Box } from '@material-ui/core'
import { DisableContainer } from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationDetails'
import { getInBuiltEntityMessage, InBuiltEntities } from 'utils/workspace'

interface SectionPenaltyFormHeaderProps {
  isNewItem: boolean
  item: PenaltyRegulationTableItemNew
  form: FormApi<PenaltyRegulationTableItemNew>
  enableSaveBtn: boolean
  saveResult: UseMutationResult<PenaltyFormData, Error>
  loading: boolean
  detailsType: PenaltyTableTypeOfRow
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  onClose: () => void
  disableEdit: boolean
  formInvalid: boolean
}

const SectionPenaltyFormHeader: React.FC<SectionPenaltyFormHeaderProps> = ({
  isNewItem,
  item,
  form,
  enableSaveBtn,
  saveResult,
  loading,
  detailsType,
  onFormSubmit,
  onClose,
  disableEdit,
  formInvalid,
}) => {
  const { queryParams } = useQueryParams()
  const { updateQueryString } = useQueryString()
  const { REGULATION, VERSION, RULESET } = PenaltyTableTypeOfRow
  const { SAVE_AND_NEXT, SAVE_AND_CLOSE, SAVE_AND_NEW, CREATE_COPY } = FormSaveOptions
  const itemType = detailsType || item?.typeOfRow
  const requiredFieldsInTheForm = itemType ? penaltyFormRequiredFields(itemType) : []
  const penaltyItems = usePenaltyRegulationTableItems()

  // Save button key mutations
  const saveMainItemKey = usePenaltyFormSaveMainItemKey()
  const {
    mutate: saveMainMutation,
    isLoading: savingMenuItemLoading,
    isSuccess: saveMenuItemSuccess,
    reset,
  } = usePenaltyFormSaveMainItemKeySaveMutation()

  const saveMenuItems = useMemo(
    () =>
      getFormSaveMenuItems().filter(
        (item) => item.key !== CREATE_COPY && (isNewItem ? item.key !== SAVE_AND_NEXT : item.key !== SAVE_AND_NEW),
      ),
    [isNewItem],
  )

  const savedMainItem = useMemo(() => {
    const mainItem = isNewItem ? saveMainItemKey.data?.create : saveMainItemKey.data?.update
    return mainItem || SAVE_AND_CLOSE
  }, [isNewItem, saveMainItemKey.data])

  const handleNextPrevForecast = useCallback(
    (goToNextItem: boolean) => {
      const currentDisplayedId = queryParams[QUERY_PENALTY_DETAILS]
      const newIndex = getNextPrevNavigationIndex(penaltyItems, currentDisplayedId, goToNextItem)
      if (newIndex != undefined) {
        const nextItem = penaltyItems[newIndex]
        const queryData = {
          ...getPenaltyDetailsQueryObj(nextItem.id),
        }
        updateQueryString(queryData)
      }
    },
    [queryParams, updateQueryString, penaltyItems],
  )

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      const { SAVE_AND_CLOSE } = FormSaveOptions
      if (savedMainItem !== item.key) {
        const data: PenaltyFormSaveMainItem = {
          create: isNewItem ? item.key : saveMainItemKey.data?.create || SAVE_AND_CLOSE,
          update: !isNewItem ? item.key : saveMainItemKey.data?.update || SAVE_AND_CLOSE,
        }
        saveMainMutation(data)
      }
    },
    [saveMainItemKey, saveMainMutation, isNewItem, savedMainItem],
  )

  const headerTitle = getPenaltyFormHeaderTitle(itemType)

  useEffect(() => {
    if (saveMenuItemSuccess) {
      onFormSubmit()
      reset()
    }
  }, [saveMenuItemSuccess])

  const fieldName =
    itemType === REGULATION ? 'regulationName' : itemType === VERSION ? 'regulationVersionName' : 'generatorTypes'
  const placeholder =
    itemType === REGULATION ? t`Regulation name` : itemType === VERSION ? t`Version name` : t`Generator type`

  const generatorTypeInputValue = useField<string[]>('generatorTypes').input.value
  const generatorTypes = getPenaltyGeneratorTypes()
  const customComponent =
    itemType === RULESET ? (
      <Box width="60%">
        <DisableContainer disable={disableEdit}>
          <Field name={fieldName}>
            {(props) => {
              return (
                <FinalFormAutocompleteField
                  availableItems={generatorTypes || []}
                  selectedIds={generatorTypeInputValue || []}
                  multiple={true}
                  input={props.input}
                  meta={props.meta}
                  placeHolder={placeholder}
                  required={true}
                  formDirty={form.getState().dirty}
                  touched={props.touched}
                />
              )
            }}
          </Field>
        </DisableContainer>
      </Box>
    ) : undefined

  return (
    <FormHeaderContainer>
      <DetailsFormHeader
        showNextPrevOptions={!isNewItem}
        handleGoToNextPrevItem={handleNextPrevForecast}
        totalItems={(penaltyItems || []).length}
        onCloseDetailsForm={onClose}
        fieldName={fieldName}
        placeholder={placeholder}
        disabled={disableEdit}
        headerTitle={headerTitle}
        requiredField={true}
        customComponent={customComponent}
        hideTitleInputField={itemType === RULESET}
        isInBuiltEntity={item.shared && itemType === REGULATION}
        inBuiltEntityMsg={getInBuiltEntityMessage(InBuiltEntities.PENALTY)}
      >
        <FormSpy subscription={{ dirty: true, touched: true, modifiedSinceLastSubmit: true }}>
          {(props) => {
            return (
              <ActionButton
                invalidFieldsErrorMessages={getInvalidFieldsErrorMessagesForCertainForm(
                  form as FormApi,
                  requiredFieldsInTheForm,
                )}
                result={saveResult}
                formInvalid={itemType === RULESET ? false : formInvalid}
                // formTouched={{Object.keys(props.touched || {}).some((key) => props.touched && props.touched[key])}}
                formModifiedSinceLastSubmit={props.modifiedSinceLastSubmit}
                disabled={loading || !enableSaveBtn || savingMenuItemLoading || saveMainItemKey.isLoading}
                menuItems={saveMenuItems}
                mainItemKey={savedMainItem}
                onSelectMenuItem={handleSaveMenuSelect}
                icon="save"
                variant="contained"
                color="primary"
                size="small"
                saveOptionLoading={savingMenuItemLoading}
              >
                {t`Save`}
              </ActionButton>
            )
          }}
        </FormSpy>
      </DetailsFormHeader>
    </FormHeaderContainer>
  )
}

export default SectionPenaltyFormHeader
