import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  addDataStreamsToTheirCategories,
  getDataStreamColumns,
  isMetaForecastDataStream,
  useDataStreams,
} from 'utils/dataStream'
import { DataStream, DataStreamOrder, DataStreamRowItem } from 'modules/dataStreams/dataStreams.types'
import ReTable from 'modules/reTable/ReTable'
import { RETABLE_ID_DATASTREAMS, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { table } from 'themes/theme-light'
import { useDispatch, useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import DataStreamTreeHeader from 'modules/dataStreams/tree/DataStreamTreeHeader'
import { VirtualRange } from 'ui/scroll/VirtualScroll'
import { reTableCrop } from 'modules/reTable/reTable.functionality'
import DataStreamTreeBody from 'modules/dataStreams/tree/DataStreamTreeBody'
import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import { useMetaForecastData } from 'utils/hooks/useMetaForecastData'

const columnsAvailable = getDataStreamColumns()
const columnsSelected = columnsAvailable.map((c) => c.name)
const defaultSort = {
  active: true,
  ascending: true,
  column: 'name',
}
const itemsPaddingHeader = 1
const itemsPaddingFooter = 0
const renderAheadCount = 15

const collapsibleIds = DataStreamOrder
const defaultCollapsed: string[] = []
interface DataStreamTreeProps {
  isDataStreamDetails: boolean
  siteForecastDetailsId: string
}

const DataStreamTree: React.FC<DataStreamTreeProps> = ({ isDataStreamDetails, siteForecastDetailsId }) => {
  const dispatch = useDispatch()
  const dataStreams = useDataStreams()
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const [itemsToRender, setItemsToRender] = useState([])

  // Meta forecast dataStream and widget
  const metaForecastData = useMetaForecastData()
  const isMetaForecastWidgetSelected = metaForecastData.metaForecastWidgetSelected
  const isMetaForecastDataStreamSelected = metaForecastData.metaForecastDataStreamSelected
  const hasAccessToMetaForecast = metaForecastData.hasAccessToMetaForecast
  const metaForecastDataStream = dataStreams.find((dataStream) => isMetaForecastDataStream(dataStream))

  const handleItemsToRenderChange = useCallback((items: DataStream[], sort: Sort, virtualRange: VirtualRange) => {
    let rowItems: DataStreamRowItem[]
    if (sort.active) {
      rowItems = addDataStreamsToTheirCategories(items)
    } else {
      rowItems = items
    }

    const croppedItems = reTableCrop({
      items: rowItems as ReTableItem[],
      virtualRange,
    })

    requestAnimationFrame(() => {
      setItemsToRender(croppedItems)
    })
  }, [])

  const dataStreamsWithCategory = useMemo(() => {
    const filteredDataStreams = isMetaForecastWidgetSelected
      ? dataStreams
      : dataStreams.filter((dataStream) => !isMetaForecastDataStream(dataStream))
    return addDataStreamsToTheirCategories(filteredDataStreams)
  }, [dataStreams, isMetaForecastWidgetSelected])

  const selectedDataStreamsIds = useMemo(() => selectedDataStreams.map((d) => d.name), [selectedDataStreams])

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_DATASTREAMS, columnsAvailable })
  }, [JSON.stringify(columnsAvailable)])

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_SELECTED, table: RETABLE_ID_DATASTREAMS, columnsSelected })
  }, [JSON.stringify(columnsSelected)])

  return (
    <ReTable
      id={RETABLE_ID_DATASTREAMS}
      itemHeight={table.rowHeight}
      // itemHeight={getItemHeight}
      items={dataStreamsWithCategory}
      hasExtendedHeader={false}
      itemsPaddingHeader={itemsPaddingHeader}
      itemsPaddingFooter={itemsPaddingFooter}
      renderAheadCount={renderAheadCount}
      isNarrow={true}
      collapsibleIds={collapsibleIds}
      selectedIds={selectedDataStreamsIds}
      defaultCollapsed={defaultCollapsed}
      defaultSort={defaultSort}
      onItemsToRenderChange={handleItemsToRenderChange}
    >
      <DataStreamTreeHeader />
      <DataStreamTreeBody
        isDataStreamDetails={isDataStreamDetails}
        itemsToRender={itemsToRender}
        selectedDataStreams={selectedDataStreams}
        siteForecastDetailsId={siteForecastDetailsId}
        dataStreamsWithCategory={dataStreamsWithCategory}
        // Meta forecast dataStream related props
        isMetaForecastWidgetSelected={isMetaForecastWidgetSelected}
        isMetaForecastDataStreamSelected={isMetaForecastDataStreamSelected}
        hasAccessToMetaForecast={hasAccessToMetaForecast}
        metaForecastDataStream={metaForecastDataStream}
      />
    </ReTable>
  )
}

export default React.memo(DataStreamTree)
