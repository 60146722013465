import axios from 'axios'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'

import { Asset } from 'modules/asset/store/asset.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { queryClient } from 'queryClient'
import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { flattenAndUniquifyAssetIds } from 'utils/asset'
import createReducer from 'utils/createReducer'
import { request } from 'utils/request'

// state

interface State {
  loading: boolean
  error: string | null
  success: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  success: false,
}

// types

export const START_TRAINING_REQUEST = 'START_TRAINING_REQUEST'
export const START_TRAINING_SUCCESS = 'START_TRAINING_SUCCESS'
export const START_TRAINING_FAILURE = 'START_TRAINING_FAILURE'
export const START_TRAINING_DISMISS = 'START_TRAINING_DISMISS'
export const START_TRAINING_RESET = 'START_TRAINING_RESET'
export type START_TRAINING_REQUEST = typeof START_TRAINING_REQUEST
export type START_TRAINING_SUCCESS = typeof START_TRAINING_SUCCESS
export type START_TRAINING_FAILURE = typeof START_TRAINING_FAILURE
export type START_TRAINING_DISMISS = typeof START_TRAINING_DISMISS
export type START_TRAINING_RESET = typeof START_TRAINING_RESET
export type START_TRAINING_ACTION_TYPE =
  | START_TRAINING_REQUEST
  | START_TRAINING_SUCCESS
  | START_TRAINING_FAILURE
  | START_TRAINING_DISMISS
  | START_TRAINING_RESET

interface StartTrainingAction {
  type: START_TRAINING_ACTION_TYPE
  // ERROR
  error: State['error']
  // REQUEST
  assets: Asset[]
}

// reducers

const loading = createReducer<State['loading'], StartTrainingAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case START_TRAINING_REQUEST:
      return true
    case START_TRAINING_SUCCESS:
    case START_TRAINING_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], StartTrainingAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case START_TRAINING_SUCCESS:
    case START_TRAINING_DISMISS:
    case START_TRAINING_RESET:
      return null
    case START_TRAINING_FAILURE:
      return error
  }
  return state
})

const success = createReducer<State['success'], StartTrainingAction>((state = initialState.success, { type }) => {
  switch (type) {
    case START_TRAINING_SUCCESS:
      return true
    case START_TRAINING_REQUEST:
    case START_TRAINING_FAILURE:
    case START_TRAINING_RESET:
      return false
  }
  return state
})

export const startTrainingReducer = combineReducers({
  loading,
  error,
  success,
})

export const setStartTrainingLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.asset.startTraining.loading,
  (loading) => loading,
)

export const setStartTrainingErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.asset.startTraining.error,
  (error) => error,
)

export const setStartTrainingSuccessSelector = createSelector<any, State['success'], State['success']>(
  (state) => state.asset.startTraining.success,
  (success) => success,
)

// api
export const startTraining = (assetIds: string[], customer: string) => {
  return request(() => {
    return axios.post(`api/v1/train/customer/${customer}/training`, assetIds)
  })
}

// sagas
export function* startTrainingSaga({ assets }: StartTrainingAction): SagaIterator {
  // TODO to get all assets we can't use hooks in sagas, so we need to find a better way than the following:
  const allAssetsProductive = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE])
  const allAssetsDraft = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT])
  const allAssets = ((allAssetsDraft || []).length > 0 ? allAssetsDraft : allAssetsProductive) || []

  const assetIdsToSet = flattenAndUniquifyAssetIds(assets, allAssets)

  const user = yield select(getUserResultSelector)
  const result = yield call(startTraining, assetIdsToSet, user.login)

  if (result.isSuccessful) {
    yield put({ type: START_TRAINING_SUCCESS })
    queryClient.invalidateQueries(QUERY_KEY_ASSETS)
  } else {
    const error = result.getError()
    yield put({ type: START_TRAINING_FAILURE, error })
  }
}
