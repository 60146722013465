import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import {
  DATE_FORMAT_DEFAULT_WITH_SECONDS,
  formatDate,
  getFormattedTime,
  getInclusiveDateRangeFromChartDataRange,
} from 'utils/date'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import { useMemo } from 'react'
import {
  DAY_AHEAD_DATA_STREAM_SEARCH_KEY,
  DeliveryTimesType,
  EvaluationDurationType,
  TimeSeriesClassifier,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { format } from 'date-fns'
import { AppUnits, getUnitForDataStreamCategory } from 'utils/units'
import { getTimeSeriesSeriesType, getTimeSeriesValueType } from 'utils/timeseries'
import { DEFAULT_FORECAST_CONFIG_ID, getMarketPriceDataBaseUrl } from 'utils/dataStream'
import { timeseriesDatetimeFormat } from 'modules/dataStreams/api/timeseries.api'
import { useUniqueSelectedAssets } from 'utils/asset'
import { Timezone } from 'fixtures/timezones'
import { useQuery } from 'react-query'
import { apiRequest } from 'utils/request'
import axios from 'axios'
import cloneDeep from 'clone-deep'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { hasPermissionForSiteAssessmentBackcast } from 'utils/user'
import { dataStreamWithoutAssetIdCombination, filteredSelectedDataStreamForEvaluationTable } from 'utils/evaluation'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { ChartDataRange, ChartDataRangeType } from 'modules/workspace/store/workspace.types'

export const QUERY_KEY_ALL_EVALUATION_DATA = 'Evaluation:AllEvalutationData'

const getAllEvaluationData = async (params: EvaluationApiParams) => {
  return await apiRequest<any>(() =>
    axios.post(`/api/v1/site/workBench/assessment`, params, {
      transformResponse: (data) => {
        const updatedResponse: any[] = []
        const response = JSON.parse(data)

        response.forEach((singleObjectFromResponse: any) => {
          const objectAlreadyExists = updatedResponse.find((singleObjectFromUpdatedResponse) => {
            return singleObjectFromUpdatedResponse.identifier === singleObjectFromResponse.identifier
          })

          if (!objectAlreadyExists) {
            updatedResponse.push(singleObjectFromResponse)
          }
        })

        return { originalData: response, transformedData: updatedResponse }
      },
    }),
  )
}

export const useEvaluationData = () => {
  // Here we are getting all required params for api call
  const params = useEvaluationApiParams()
  const user = useSelector(getUserResultSelector)
  // Are needed for backcast
  const lineChartSettings = useLineChartSettings()
  const hasAccessToBackCast = hasPermissionForSiteAssessmentBackcast(user)

  const isBackcastActive = lineChartSettings?.data?.showBackCast && hasAccessToBackCast

  const queryOption = {
    queryKey: [QUERY_KEY_ALL_EVALUATION_DATA, params.start, params.end, params.timezone, params, isBackcastActive],
    queryFn: () => {
      return getAllEvaluationData(params)
    },
    enabled: true,
  }

  return useQuery(queryOption)
}

interface EvaluationApiParams {
  start: string
  end: string
  timezone: Timezone
  dataStreams: any[]
}

export const useEvaluationApiParams = (): EvaluationApiParams => {
  // const chartDataRange = useSelector(workspaceDraftChartDataRangeSelector)
  const timezone = useSelector(getUserTimezoneSelector)
  const user = useSelector(getUserResultSelector)
  const forecastConfigs = useSiteForecastConfigs().data || []
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const selectedAssets = useUniqueSelectedAssets()

  // Here we are getting values from zoom
  const chartSelectedDateRange = useWorkspaceChartSelectedDateRange()
  const customRange = chartSelectedDateRange.map((date) => {
    return formatDate(date, null, DATE_FORMAT_DEFAULT_WITH_SECONDS)
  })

  const chartDataRange = {
    rangeType: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM,
    customRange,
  }

  const range = getInclusiveDateRangeFromChartDataRange(chartDataRange as ChartDataRange, timezone)

  // Are needed for backcast
  const lineChartSettings = useLineChartSettings()
  const hasAccessToBackCast = hasPermissionForSiteAssessmentBackcast(user)

  const filteredColumns = useMemo(() => {
    return filteredSelectedDataStreamForEvaluationTable(selectedDataStreams, user)
  }, [selectedDataStreams, user])

  const dataToPost: any = {}

  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)

  dataToPost['start'] = start
  dataToPost['end'] = end
  dataToPost['timezone'] = timezone
  dataToPost['user'] = user?.login || ''
  dataToPost['dataStreams'] = []

  filteredColumns.forEach((dataStream) => {
    if (dataStream.type === TimeSeriesType.MARKET_PRICE_DATA && selectedAssets.length === 0) {
      const marketPriceDataStreamParams: any = {}
      marketPriceDataStreamParams['streamIdentifier'] = dataStream.id
      marketPriceDataStreamParams['identifier'] = dataStreamWithoutAssetIdCombination
      marketPriceDataStreamParams['category'] = dataStream.type
      marketPriceDataStreamParams['backcast'] = false
      marketPriceDataStreamParams['url'] = getMarketPriceDataBaseUrl(dataStream)
      dataToPost.dataStreams.push(marketPriceDataStreamParams)
    } else {
      selectedAssets.forEach((asset) => {
        const unit = getUnitForDataStreamCategory(dataStream.type, dataStream.subType, dataStream.classifier)
        const assetId = asset.id

        const seriesType = getTimeSeriesSeriesType(dataStream)
        const valueType = getTimeSeriesValueType(dataStream)

        let offset: number | undefined = undefined
        let duration: number | undefined = undefined
        let deliveryTimes: string[] = []
        let offsetType = ''
        let offsetTimeZone = ''

        const matrixParams: any = {}
        if (dataStream.classifier) {
          matrixParams['POWER_DATA_CLASSIFIER'] = dataStream.classifier
        }

        const isCompactedForecast =
          (dataStream.type === TimeSeriesType.SITE_FORECAST && dataStream.id === DEFAULT_FORECAST_CONFIG_ID) ||
          dataStream.type === TimeSeriesType.E3_META_FORECAST

        const isCompactedTimeSeries = dataStream.type === TimeSeriesType.METER_DATA && !dataStream.classifier

        /**
         * The following block is needed because of the lack of proper backend API for site/scheduled/e3Third-party forecasts
         * to fetch time series for a specific quality configuration id
         * instead, we need to put together all puzzle pieces to resemble the required quality config
         * **/

        if (
          dataStream.type === TimeSeriesType.SITE_FORECAST ||
          dataStream.type === TimeSeriesType.SCHEDULE ||
          dataStream.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST
        ) {
          const forecastConfig = forecastConfigs.find((fc) => fc.id === dataStream.id)
          if (forecastConfig && forecastConfig.qualityConfigs && forecastConfig.qualityConfigs.length > 0) {
            offsetTimeZone = forecastConfig?.horizon?.timeZone || forecastConfig?.updateTimes?.timeZone
            const primaryQualityConfig = forecastConfig.qualityConfigs.find((qualityConfig) => qualityConfig.primary)
            offset =
              (primaryQualityConfig?.horizon?.offsetDays || 0) * 24 * 60 +
              (primaryQualityConfig?.horizon?.offsetHours || 0) * 60 +
              (primaryQualityConfig?.horizon?.offsetMinutes || 0)
            duration =
              primaryQualityConfig?.durationType == EvaluationDurationType.CUSTOM
                ? (primaryQualityConfig?.horizon?.lengthDays || 0) * 24 * 60 +
                  (primaryQualityConfig?.horizon?.lengthHours || 0) * 60 +
                  (primaryQualityConfig?.horizon?.lengthMinutes || 0)
                : undefined
            deliveryTimes =
              primaryQualityConfig?.deliveryTimesType == DeliveryTimesType.CUSTOM
                ? primaryQualityConfig.deliveryTimes.map((time) => getFormattedTime(time).replace(':', '-'))
                : []
            offsetType = primaryQualityConfig?.offsetType
          }

          if (deliveryTimes.length > 0) {
            matrixParams['DELIVERY_TIME'] = deliveryTimes.map((time) => time).join(',')
          }

          matrixParams['PRODUCT_CONFIG_ID'] = dataStream.id
        }

        const dataStreamParams: any = {}

        // the following params are included to all of them
        dataStreamParams['streamIdentifier'] = dataStream.id
        dataStreamParams['identifier'] = assetId
        dataStreamParams['forecastConfigurationId'] = dataStream.id
        dataStreamParams['category'] = dataStream.type
        dataStreamParams['backcast'] = false

        if (dataStream.type !== TimeSeriesType.MARKET_PRICE_DATA) {
          dataStreamParams['unit'] = unit
        }

        if (!isCompactedForecast) {
          if (dataStream.type === TimeSeriesType.SITE_FORECAST) {
            dataStreamParams['seriesType'] = seriesType
            dataStreamParams['valueType'] = valueType
            dataStreamParams['version'] = isCompactedTimeSeries ? 'v1' : 'v2'
            dataStreamParams['duration'] = duration
            dataStreamParams['offset'] = offset
            dataStreamParams['offsetType'] = offsetType
            dataStreamParams['offsetTimeZone'] = offsetTimeZone
            dataStreamParams['loadOption'] = 'DISCRETE'
            dataStreamParams['cacheWaitInitialReq'] = false
            dataStreamParams['cacheReq'] = true
            dataStreamParams['matrixParams'] = matrixParams
          } else if (dataStream.type === TimeSeriesType.METER_DATA) {
            dataStreamParams['seriesType'] = seriesType
            dataStreamParams['valueType'] = valueType
            dataStreamParams['loadOption'] = 'DISCRETE'
            dataStreamParams['cacheWaitInitialReq'] = false
            dataStreamParams['cacheReq'] = true
          } else if (dataStream.type === TimeSeriesType.MARKET_PRICE_DATA) {
            if (dataStream.classifier === TimeSeriesClassifier.GERMANY_REVENUE) {
              dataStreamParams['url'] = '/api/v1/ts/eex/germany/spotmarket/price/'
              dataStreamParams['unit'] = AppUnits.KILO_WATT
              const findStaticDayAheadFromFilteredColumn = filteredColumns.find((dataStrean) => {
                return (
                  dataStrean?.name?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY) ||
                  dataStrean?.label?.toLowerCase().includes(DAY_AHEAD_DATA_STREAM_SEARCH_KEY)
                )
              })
              dataStreamParams['forecastConfigurationId'] = findStaticDayAheadFromFilteredColumn?.id || ''
            } else {
              dataStreamParams['url'] = getMarketPriceDataBaseUrl(dataStream)
            }
          }
        }

        // pushing required params
        dataToPost.dataStreams.push(dataStreamParams)

        // For backcast , we need exactly the same object as site forecast, but we need to add backcast true , and forecastConfigurationId
        if (
          lineChartSettings?.data?.showBackCast &&
          hasAccessToBackCast &&
          dataStream.type === TimeSeriesType.SITE_FORECAST
        ) {
          const backcastDataStreamParams = cloneDeep(dataStreamParams)
          backcastDataStreamParams.backcast = true
          backcastDataStreamParams.forecastConfigurationId = dataStream.id

          //pushing required params for backcast
          dataToPost.dataStreams.push(backcastDataStreamParams)
        }
      })
    }
  })

  return dataToPost
}
