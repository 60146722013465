import { FormApi } from 'final-form'
import { assetTabNames, ClusterTypesEnum } from 'fixtures/assetForm'
import { useAllAssets } from 'modules/asset/api/assets.api'
import SectionHeader from 'modules/asset/assetCrud/assetDetails/SectionHeader'
import { Asset } from 'modules/asset/store/asset.types'

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AnyObject } from 'react-final-form'
import { UseMutationResult } from 'react-query'
import styled from 'styled-components'
import { c } from 'ttag'
import Flex from 'ui/styles/Flex'
import { useDebounce } from 'use-debounce'
import { QUERY_ACTIVE_TAB, QUERY_ASSET, useQueryParams } from 'utils/query-string'
import { Error } from 'utils/request'
import { FormContainer } from 'ui/form/form.style'
import { AppTourContext } from 'modules/app/tour/AppTour'
import { createAssetSteps } from 'modules/app/tour/appTourSteps'
import { AppTours, TourStepCategory } from 'modules/app/tour/appTour.types'
import { AddTourStepsTime } from 'modules/app/tour/appTourUtils'
import AssetFormBody from 'modules/asset/assetCrud/assetDetails/AssetFormBody'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import { isCluster, isGenerator } from 'utils/asset'

export const PaddedFlexAssetForm = styled(Flex)`
  flex: 1;
  padding-top: 0.5rem;
`

interface AssetFormProps {
  asset: Asset
  isNew: boolean
  formChanged: boolean
  setFormChanged: (formChanged: boolean) => void
  enterManually: boolean
  setEnterManually: (enterManually: boolean) => void
  form: FormApi
  saveResult: UseMutationResult<Asset, Error, Asset, Asset[]>
  handleSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
}
const AssetForm: React.FC<AssetFormProps> = ({
  asset,
  isNew,

  formChanged,
  setFormChanged,
  enterManually,
  setEnterManually,
  // be cautious with form.getState()
  // it is only possible to get data that we have subscribed to
  form,
  saveResult,
  handleSubmit,
}) => {
  const { addTourSteps, removeTourSteps, isTourActive } = useContext(AppTourContext)
  const { queryParams } = useQueryParams()

  const allAssets = useAllAssets()
  const allPhysicalClusterAggregators = allAssets.data?.filter(
    (a) => isCluster(a) && a.clusterType !== ClusterTypesEnum.COLLECTION,
  )
  const allPhysicalClusterAggregatorsIds = allPhysicalClusterAggregators?.map((pc) => pc.id)

  const [showMDCChartTooltip, setShowMDCChartTooltip] = useState<boolean>(true)
  const handleToggleShowMDCChartTooltip = () => {
    setShowMDCChartTooltip((prevVal) => !prevVal)
  }

  const loading = allAssets.isFetching || saveResult.isLoading

  const formInvalid = useMemo(() => {
    return isNew ? form.getState().submitFailed && form.getState().invalid : form.getState().invalid
  }, [form.getState().invalid, form.getState().submitFailed, isNew])
  const [formInvalidDebounced] = useDebounce(formInvalid, 200)

  const assetText = useMemo(
    () =>
      asset.name
        ? `"${asset.name}" ${c('Asset details:Confirmation dialog').t`Details`}`
        : c('Asset details:Confirmation dialog').t`New asset`,
    [asset],
  )

  const handleCloseTour = useCallback(() => {
    if (typeof removeTourSteps === 'function' && isTourActive) removeTourSteps(AppTours.ADD_ASSET)
  }, [removeTourSteps, isTourActive])

  useEffect(() => {
    const addSteps = !allAssets?.data?.length && typeof addTourSteps === 'function' && isTourActive && isNew
    if (!addSteps) return
    const steps = createAssetSteps().filter((item) => item.stepCategory === TourStepCategory.FORM)
    const addStepsTimer = setTimeout(() => addTourSteps(steps), AddTourStepsTime)
    return () => {
      if (addStepsTimer) clearTimeout(addStepsTimer)
    }
  }, [addTourSteps, isTourActive, isNew, allAssets?.data])

  useEffect(() => {
    if (saveResult?.isSuccess) {
      handleCloseTour()
    }
  }, [handleCloseTour, saveResult?.isSuccess])

  useEffect(() => {
    return () => {
      handleCloseTour()
    }
  }, [handleCloseTour])

  const handleResetExternalChanges = () => {
    setFormChanged(false)
  }

  const currentAssetValues = form.getState().values

  // Every Generator is always belongs to one Physical Park so we take the first id from the uiParkIds
  const assetPhysicalParkAggregator =
    isGenerator(currentAssetValues) && currentAssetValues?.uiParkIds?.length
      ? allAssets?.data?.find((a) => a.id === currentAssetValues?.uiParkIds[0])
      : undefined

  // Every Generator||Park can belongs to one Physical Cluster or multiple Administrative Clusters
  // So we extract the Physical cluster id from the clusterIds and then find it from allPhysicalClusterAggregators
  const assetPhysicalClusterAggregatorId = currentAssetValues?.clusterIds?.length
    ? currentAssetValues?.clusterIds.find((id) => allPhysicalClusterAggregatorsIds?.includes(id))
    : ''
  const assetPhysicalClusterAggregator =
    !isCluster(currentAssetValues) && assetPhysicalClusterAggregatorId
      ? allPhysicalClusterAggregators?.find((pc) => pc.id === assetPhysicalClusterAggregatorId)
      : undefined

  const assetPhysicalAggregator = assetPhysicalParkAggregator || assetPhysicalClusterAggregator

  return (
    <FormContainer>
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        {/*{console.log('form values =', form.getState())}*/}
        <FormBlockNavigation
          blockWithExternalChanges={formChanged}
          navigationDialogText={assetText}
          form={form}
          currentPageQueries={[QUERY_ASSET, QUERY_ACTIVE_TAB]}
          navigationDialogKey={'assetDetails'}
          onResetExternalChanges={handleResetExternalChanges}
        />
        <SectionHeader
          form={form}
          activeTab={queryParams[QUERY_ACTIVE_TAB] || assetTabNames.details}
          isNew={isNew}
          formChanged={formChanged}
          formInvalid={!loading && formInvalidDebounced}
          loading={loading}
          asset={asset as Asset}
          saveResult={saveResult}
          // onAssetSave={handleAssetSave}
          onFormSubmit={handleSubmit}
          enterManually={enterManually}
          onToggleShowMDCChartTooltip={handleToggleShowMDCChartTooltip}
          showMDCChartTooltip={showMDCChartTooltip}
        />
        {/*Asset details Section*/}

        <AssetFormBody
          enterManually={enterManually}
          asset={asset}
          form={form}
          isNew={isNew}
          setFormChanged={setFormChanged}
          setEnterManually={setEnterManually}
          assetPhysicalAggregator={assetPhysicalAggregator}
        />
      </form>
    </FormContainer>
  )
}

// AssetForm.whyDidYouRender = {
//     logOnDifferentValues: true,
// }
export default React.memo(AssetForm)
