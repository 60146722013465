import { useMeterDataForCleansingQueryResult } from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { useMemo } from 'react'
import { ChartSeriesOptions, createChartSeries } from 'modules/workspace/chart/timeSeriesToChartSeries'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'
import { meterDataCleansingDataSelection } from 'utils/meterDataCleansing'
import { useDebounce } from 'use-debounce'
import { Asset } from 'modules/asset/store/asset.types'
import { convertLocalDateToUTC, convertZonedTimeToUtc, DateRange, isDateValid } from 'utils/date'
import { useSelector } from 'react-redux'
import { getMetaDataResultSelector } from 'modules/data/store/getMetaData.state'
import { getWeatherConfigsResultSelector } from 'modules/weather/store/getWeatherConfig.state'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'

export const useMeterDataCleansingChartSeriesSet = (asset: Asset, timePeriod: DateRange) => {
  let timePeriodInUTC: DateRange | Date[] = []
  const timezone = useSelector(getUserTimezoneSelector)

  // This condition is used to prevent errors from changing date manually
  if (isDateValid(timePeriod[0]) && isDateValid(timePeriod[1])) {
    timePeriodInUTC = [
      convertZonedTimeToUtc(convertLocalDateToUTC(timePeriod[0]), timezone),
      convertZonedTimeToUtc(convertLocalDateToUTC(timePeriod[1]), timezone),
    ]
  }

  const meterDataTimeSeriesQueryResult = useMeterDataForCleansingQueryResult(asset, timePeriodInUTC)
  // create chart series
  const metaData = useSelector(getMetaDataResultSelector) || undefined
  const weatherConfigs = useSelector(getWeatherConfigsResultSelector)

  const chartSeriesSet = useMemo(() => {
    const indexes: Record<string, number> = {}
    // console.log({ aggregatedTimeSeriesQueryResults })
    const set = meterDataTimeSeriesQueryResult.reduce<ChartSeriesOptions[]>((acc, timeSeriesQueryResult) => {
      const timeSeriesResult = timeSeriesQueryResult.data || timeSeriesQueryResult.error
      if (timeSeriesResult) {
        const assets = [asset]

        if (timeSeriesResult.dataStreamId) {
          // we keep track how many times a data stream has been drawn so that
          // we can show their colors in slight variations of the original one
          indexes[timeSeriesResult.dataStreamId] = (indexes[timeSeriesResult.dataStreamId] || 0) + 1
        }

        return [
          ...acc,
          // add another chart series for this data stream
          createChartSeries({
            result: timeSeriesQueryResult,
            asset,
            assets,
            timeSeries: timeSeriesResult.data,
            id: timeSeriesResult.dataStreamId,
            queryResultId: timeSeriesResult.id,
            type: timeSeriesResult.type,
            subType: timeSeriesResult.subType,
            classifier: timeSeriesResult.classifier,
            chartAggregationMode: ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET,
            selectedDataStreams: meterDataCleansingDataSelection,
            metaData,
            weatherConfigs,
            index: timeSeriesResult.dataStreamId ? indexes[timeSeriesResult.dataStreamId] : undefined,
          }),
        ]
      } else {
        return acc
      }
    }, [])

    return set
  }, [JSON.stringify(meterDataTimeSeriesQueryResult)]) // TODO this is changing infinitely, should be fixed

  // return meterDataTimeSeriesQueryResult

  // we use debounced series in order to avoid too many costly re-renders in highcharts
  const [debouncedChartSeriesSet] = useDebounce(chartSeriesSet, 30, { leading: true })
  return debouncedChartSeriesSet
}
