export interface singleRunningBackcastType {
  id?: string | undefined
  customer: string | undefined
  assetId: string | undefined
  forecastModelId: string | undefined
  forecastConfigId: string | undefined
  qualityConfigurationId: string | undefined
  forecastConfigVersion: string | undefined
  start: Date | string | undefined
  end: Date | string | undefined
  timeSeriesCompaction: string | undefined
  ignorePeriodOfOperation: boolean | undefined
  callbackUrl: any | undefined
  reason: string | undefined
  status?: string | undefined
  uiHasMatchingDescendants?: boolean | undefined
  uiHasMatchingParents?: boolean | undefined
  uiIsMatching?: boolean | undefined
}

export interface RunningBackcastsType {
  IN_PROGRESS: singleRunningBackcastType[]
  QUEUED: singleRunningBackcastType[]
}

export enum RunningBackcastsStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  QUEUED = 'QUEUED',
}
