import { apiRequest, request, useOptimisticMutation } from 'utils/request'
import { UserManagementSaveMainItem, UserManagementType, UserStatus } from 'modules/userManagement/userManagement.types'
import axios from 'axios'
import { QueryObserverResult, useMutation, useQuery, useQueryClient } from 'react-query'
import { useUserSetting, useUserSettingSaveMutation } from 'modules/auth/api/userSettings.api'
import { FormApi } from 'final-form'
import { getCompanyAccountAsString, getNewUserManagementUserData, getUserStatusLabel } from 'utils/userManagement'
import { differenceInDays, isBefore } from 'date-fns'

const NEW = 'new'
const USER_MANAGEMENT_BASE_URL = '/api/users'
const USER_SETTINGS_BASE_URL = 'api/usersettings'

// Query keys for caching data
export const QUERY_KEY_USER_MANAGEMENT = 'userManagement'
export const QUERY_KEY_USER_MANAGEMENT_SAVEMAINITEMKEY = 'userManagement:saveMainItemKey'
export const QUERY_KEY_DEFAULT_AUTHORITIES = 'defaultAuthorities'
export const QUERY_KEY_ALL_PARTNERS = 'allPartners'
export const QUERY_KEY_SITE_ASSESSMENT_COORDINATE_LIMIT = 'siteAssessmentCoordinateLimit'

const transformUserItemsForTable = (items: UserManagementType[]) => {
  const tableItems = items.map((i) => {
    const createdDate = i?.createdDate ? new Date(i.createdDate.toString().replace('[UTC]', '')) : ''
    const lastModifiedDate = i?.lastModifiedDate ? new Date(i.lastModifiedDate.toString().replace('[UTC]', '')) : ''
    const isFreeUser = i?.authorities?.includes('ROLE_FREE')
    const noOfDaysFromCreationDate = differenceInDays(new Date(), createdDate)
    const userStatus =
      isFreeUser && noOfDaysFromCreationDate > 30
        ? UserStatus.EXPIRED
        : i.activated
        ? UserStatus.ACTIVATED
        : UserStatus.DEACTIVATED

    const updatedTableItems = {
      ...i,
      createdDate: createdDate,
      lastModifiedDate: lastModifiedDate || createdDate,
      lastModifiedBy: i.lastModifiedBy || i.createdBy,
      uiUserStatus: userStatus,
      uiUserStatusLabel: getUserStatusLabel(userStatus),
    }

    if (i.companyAccounts) {
      updatedTableItems.companyAccounts = getCompanyAccountAsString(i.companyAccounts)
    }

    return updatedTableItems
  })

  const sortedTableItems = tableItems.sort((a, b) => {
    const valueA = a.createdDate
    const valueB = b.createdDate
    const dateA = new Date(valueA)
    const dateB = new Date(valueB)

    if (dateA && dateB) {
      return isBefore(dateA, dateB) ? 1 : -1
    } else if (dateA) {
      return -1
    } else if (dateB) {
      return 1
    } else {
      return 0
    }
  })
  return sortedTableItems
}

export const getCompaniesForSubUser = async (useOriginalUser: boolean) => {
  return await apiRequest<string[] | []>(() =>
    axios.get(`/api/account/company-accounts?useOriginalUser=${useOriginalUser}`),
  )
}

export const getSubUsersForCompany = async (useOriginalUser: boolean) => {
  return await apiRequest<string[] | []>(() =>
    axios.get(`/api/account/company-sub-users?useOriginalUser=${useOriginalUser}`),
  )
}

// Get used coordinates list for a certain user
export const getSiteAssessmentCoordinatesList = async (id: string) => {
  return await apiRequest(() => axios.get(`${USER_SETTINGS_BASE_URL}/${id}/siteAssessmentCoordinates`))
}
// Reset used coordinates list for a certain user
export const resetSiteAssessmentCoordinatesList = async (id: string) => {
  return await apiRequest(() => axios.delete(`${USER_SETTINGS_BASE_URL}/${id}/siteAssessmentCoordinates`))
}

export const synchronizeUser = async (user: UserManagementType) => {
  return await apiRequest(() => axios.get(`/api/v1/sync/${user.login}`))
}

export const currentRevisionToBase = async (user: UserManagementType) => {
  return await apiRequest(() => axios.get(`api/v1/baserevision/set/${user.login}`))
}

export const resetRevisionToBase = async (user: UserManagementType) => {
  return await apiRequest(() => axios.get(`/api/v1/baserevision/reset/${user.login}`))
}

// Site assessment coordinate limit
export const getSiteAssessmentCoordinateLimitById = async (id: string) => {
  return await apiRequest<number>(() => axios.get(`${USER_SETTINGS_BASE_URL}/${id}/siteAssessmentCoordinatesLimit`))
}

export const useSiteAssessmentCoordinateLimitById = (id: string) => {
  return useQuery<number>([QUERY_KEY_SITE_ASSESSMENT_COORDINATE_LIMIT, id], () =>
    getSiteAssessmentCoordinateLimitById(id),
  )
}

export const postSiteAssessmentCoordinateLimit = (userId: string | undefined, value: number | undefined) => {
  return request(() =>
    axios.post(`${USER_SETTINGS_BASE_URL}/save/${userId}/siteAssessmentCoordinatesLimit`, value, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    }),
  )
}

export const useSiteAssessmentCoordinateLimitSaveMutation = (id: string) => {
  return useOptimisticMutation<any, any>({
    queryCacheKey: [QUERY_KEY_SITE_ASSESSMENT_COORDINATE_LIMIT, id],
    apiMutator: (value) => postSiteAssessmentCoordinateLimit(id, value),
    cacheUpdater: (updatedSiteAssessmentCoordinateLimit) => {
      return updatedSiteAssessmentCoordinateLimit
    },
  })
}

// Get default authorities (or profiles or roles)
const getDefaultAuthorities = async () => {
  return await apiRequest<string[]>(() => axios.get(`${USER_MANAGEMENT_BASE_URL}/defaultroles`))
}
export const useDefaultAuthorities = () => {
  return useQuery<string[]>(QUERY_KEY_DEFAULT_AUTHORITIES, getDefaultAuthorities)
}

//Get Partners
const getAllPartners = async () => {
  return await apiRequest<UserManagementType[]>(() => axios.get(`${USER_MANAGEMENT_BASE_URL}/partner`))
}
export const useAllPartners = () => {
  return useQuery<UserManagementType[]>(QUERY_KEY_ALL_PARTNERS, getAllPartners)
}

// Get all users
const getAllUsers = async () => {
  return await apiRequest<UserManagementType[]>(() => axios.get(`${USER_MANAGEMENT_BASE_URL}?page=0&size=200000`))
}
export const useUserManagementAllUsers = () => {
  return useQuery<UserManagementType[]>(QUERY_KEY_USER_MANAGEMENT, getAllUsers)
}

export const useUserManagementAllUsersTableItems = () => {
  const usersResult = useUserManagementAllUsers()
  const tableItemsResult: QueryObserverResult<UserManagementType[]> = { ...usersResult, data: undefined }
  if (usersResult.data) {
    tableItemsResult.data = transformUserItemsForTable(usersResult.data)
  }
  return tableItemsResult
}

// Get User Management User by ID
const getUserManagementUserById = async (id: string) => {
  let data: UserManagementType
  if (id === NEW) {
    data = getNewUserManagementUserData()
  } else {
    data = await apiRequest<UserManagementType>(() => axios.get(`${USER_MANAGEMENT_BASE_URL}/${id}`))
  }
  return data
}

export const useUserManagementUserById = (id: string) => {
  return useQuery<UserManagementType>([QUERY_KEY_USER_MANAGEMENT, id], () => getUserManagementUserById(id))
}

// Delete User Management User
const deleteUserManagementUser = async (data: UserManagementType) => {
  return await apiRequest<UserManagementType>(() => {
    return axios.delete(`${USER_MANAGEMENT_BASE_URL}/${data.id}`)
  })
}

export const useUserManagementUserDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteUserManagementUser, {
    onSettled: async () => {
      await queryClient.invalidateQueries(QUERY_KEY_USER_MANAGEMENT)
    },
  })
}

// Create and Update User Management User
const saveUserManagementUser = async (data: UserManagementType, isNew: boolean) => {
  if (isNew) {
    return await apiRequest<UserManagementType>(() => axios.post(`${USER_MANAGEMENT_BASE_URL}`, data))
  } else {
    return await apiRequest<UserManagementType>(() => axios.put(`${USER_MANAGEMENT_BASE_URL}`, data))
  }
}

// Interface
export interface useUserManagementUserSaveMutationProps {
  isNew: boolean
  userId: string
  // REACT FINAL FORM REF
  formReference: FormApi
}

export const useUserManagementUserSaveMutation = (isNew: boolean) => {
  const queryClient = useQueryClient()
  return useOptimisticMutation<UserManagementType, UserManagementType, UserManagementType[] | undefined>({
    queryCacheKey: QUERY_KEY_USER_MANAGEMENT,
    apiMutator: (variables) => saveUserManagementUser(variables, isNew),
    cacheUpdater: (updatedUserManagementUser, oldUserManagementUser) => {
      if (isNew) {
        // if it is new do not update the cache
        return oldUserManagementUser
      } else {
        // if it is an old user we need to update the cache with updated values
        return (oldUserManagementUser || []).map((UserManagementUser) =>
          UserManagementUser.id === updatedUserManagementUser.id ? updatedUserManagementUser : UserManagementUser,
        )
      }
    },
    onSuccess: () => {
      // TODO for some reason getting undefined for formReference so added navigation logic in userManagementDetails
      queryClient.invalidateQueries(QUERY_KEY_USER_MANAGEMENT, { refetchInactive: true })
    },
  })
}

// User settings for save button in user Management page
export const useUserManagementSaveMainItemKey = () => {
  return useUserSetting<UserManagementSaveMainItem>(QUERY_KEY_USER_MANAGEMENT_SAVEMAINITEMKEY)
}
export const useUserManagementSaveMainItemKeySaveMutation = () => {
  return useUserSettingSaveMutation<UserManagementSaveMainItem>(QUERY_KEY_USER_MANAGEMENT_SAVEMAINITEMKEY)
}
