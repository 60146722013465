import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core'
import { t } from 'ttag'
import FileUploadZone from 'ui/FileUploadZone'
import { convertRejectionErrors, FILE_RENAMED_OR_DELETED, FileData, FileError } from 'utils/file'
import FileUploadPreview from 'ui/FileUploadPreview'
import LoadingButton from 'ui/form/LoadingButton'
import { FileRejection } from 'react-dropzone'
import {
  submitScheduleWithTemplate,
  uploadScheduleTemplateFiles,
  useCreateScheduleInputDataMutation,
} from 'modules/workspace/schedule/schedule.api'
import {
  CreateScheduleInputData,
  SavedScheduleConfig,
  ScheduleTemplateUploadStatus,
} from 'modules/workspace/schedule/schedule.types'
import UploadedScheduleTemplateData from 'modules/workspace/schedule/uiHelperElements/UploadedScheduleTemplateData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import DownloadScheduleTemplate from 'modules/workspace/schedule/uiHelperElements/DownloadScheduleTemplate'
import Flex from 'ui/styles/Flex'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import { Timezone } from 'fixtures/timezones'
import { convertUtcToZonedTime, convertZonedTimeToAnotherZonedTime, convertZonedTimeToUtc } from 'utils/date'

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    min-width: 47em;
    max-width: 47em;
  }
`

interface UploadScheduleTemplateDialogProps {
  onCloseDialog: (value: boolean) => void
  scheduleInputData: CreateScheduleInputData
  onSetSubmitInProgress: (value: boolean) => void
  submitInProgress: boolean
  downloadSchedule: boolean
  onSetDownloadSchedule: (value: boolean) => void
  onRefreshSeriesAndDeleteSavedSchedule: (configs: SavedScheduleConfig[]) => void
  userTimezone: Timezone
}

const UploadScheduleTemplateDialog: React.FC<UploadScheduleTemplateDialogProps> = ({
  onCloseDialog,
  scheduleInputData,
  onSetSubmitInProgress,
  submitInProgress,
  downloadSchedule,
  onSetDownloadSchedule,
  onRefreshSeriesAndDeleteSavedSchedule,
  userTimezone,
}) => {
  const asset = scheduleInputData.asset
  const targetScheduleDataStream = scheduleInputData.targetScheduleDataStream
  const scheduleSourceDataStream = scheduleInputData.sourceDataStream
  const startInUTC = convertZonedTimeToAnotherZonedTime(
    scheduleInputData?.start.date,
    scheduleInputData?.start?.timezone,
    'UTC',
  )
  const endInUTC = convertZonedTimeToAnotherZonedTime(
    scheduleInputData?.end.date,
    scheduleInputData?.end?.timezone,
    'UTC',
  )

  const { mutate: saveCreateScheduleInputMutation } = useCreateScheduleInputDataMutation()

  const [validTemplateFiles, setValidTemplateFiles] = useState<FileData[]>([])
  const [invalidTemplateFiles, setInvalidTemplateFiles] = useState<FileError[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [templateUploadResponse, setTemplateUploadResponse] = useState<Record<string, string> | null>(null)

  const handleCancelAction = () => {
    onCloseDialog(false)
    setValidTemplateFiles([])
    setInvalidTemplateFiles([])
    onSetSubmitInProgress(false)
    onSetDownloadSchedule(false)
  }

  /**
   * Handler to upload the template and verify it in the backend
   */
  const handleUploadScheduleTemplate = (filesToUpload: FileData[]) => {
    const filesArePresentLocally: Promise<FileData>[] = []
    setLoading(true)
    setTemplateUploadResponse(null)

    filesToUpload.forEach((fileData) => {
      filesArePresentLocally.push(
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => resolve(fileData)
          reader.onerror = () => reject(fileData)
          reader.onabort = () => reject(fileData)
          reader.readAsText(fileData.file, 'UTF-16')
        }),
      )
    })

    if (asset && targetScheduleDataStream) {
      Promise.allSettled(filesArePresentLocally).then((result) => {
        const rejectedFileDatas = result.filter((r) => r.status === 'rejected').map((r) => r.reason)
        if (rejectedFileDatas.length) {
          const rejectedFileErrors: FileError[] = rejectedFileDatas.map((fd) => {
            const fileRejection: FileRejection = {
              file: fd.file,
              errors: [
                {
                  message: 'file renamed or deleted',
                  code: FILE_RENAMED_OR_DELETED,
                },
              ],
            }

            return {
              file: fd.file,
              errors: convertRejectionErrors(fileRejection),
            }
          })

          setValidTemplateFiles((fds) =>
            fds.filter((fd) => !rejectedFileDatas.map((rfd) => rfd.file).includes(fd.file)),
          )
          setInvalidTemplateFiles(rejectedFileErrors)
        } else {
          uploadScheduleTemplateFiles({
            fileList: filesToUpload,
            asset,
            targetScheduleDataStream,
            scheduleSourceDataStream,
            startInUTC,
            endInUTC,
            timezone: userTimezone,
          })
            .then(({ response }) => {
              if (response?.data) {
                setTemplateUploadResponse(response?.data)
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }
      })
    }
  }

  /**
   * Handler to select the files from upload dialog
   * @param validFiles
   * @param invalidFiles
   */
  const handleFileSelect = ({ validFiles, invalidFiles }) => {
    setInvalidTemplateFiles(invalidFiles)
    setValidTemplateFiles(validFiles)
    setTemplateUploadResponse(null)
    onSetDownloadSchedule(false)

    if (!invalidFiles.length) {
      handleUploadScheduleTemplate(validFiles)
    }
    onSetDownloadSchedule(false)
  }

  const handleSubmitScheduleTemplate = () => {
    onSetSubmitInProgress(true)

    submitScheduleWithTemplate({
      templateUploadResponse,
      assetId: asset.id,
      scheduleConfigId: targetScheduleDataStream?.id as string,
      scheduleSourceId: scheduleSourceDataStream?.id as string,
    })
      .then((response) => {
        const savedConfigs: SavedScheduleConfig[] = (templateUploadResponse?.templateAssets || []).map((data) => {
          return {
            assetId: data.assetId,
            sourceDataStreamId: data.scheduleSource,
            targetScheduleDataStreamId: data.scheduleTarget,
          }
        })
        if (savedConfigs.length) {
          onRefreshSeriesAndDeleteSavedSchedule(savedConfigs)
        }
        const transformedData = { ...scheduleInputData }

        // Convert the dates
        const startDate = new Date(transformedData?.start?.date)
        startDate.setSeconds(0o0, 0o00)

        transformedData.start = {
          date: convertZonedTimeToUtc(startDate, userTimezone),
          timezone: userTimezone,
        }

        const endDate = new Date(transformedData?.end?.date)
        endDate.setSeconds(0o0, 0o00)
        transformedData.end = {
          date: convertZonedTimeToUtc(endDate, userTimezone),
          timezone: userTimezone,
        }

        saveCreateScheduleInputMutation({ ...transformedData, useSource: true })
        onSetDownloadSchedule(true)
      })
      .finally(() => {
        onSetSubmitInProgress(false)
      })
  }

  const templateVerifiedSuccessfully =
    templateUploadResponse?.status === ScheduleTemplateUploadStatus.SUCCESS ||
    templateUploadResponse?.status === ScheduleTemplateUploadStatus.SUCCESS_WITH_WARNINGS

  return (
    <StyledDialog
      open={true}
      onClose={handleCancelAction}
      onEscapeKeyDown={handleCancelAction}
      onBackdropClick={handleCancelAction}
    >
      <DialogTitle disableTypography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Typography variant="subtitle1">{t`Submit with template`}</Typography>
            {loading && <CircularProgress style={{ cursor: 'help', marginLeft: '5px' }} size={18} thickness={4.5} />}
          </Flex>

          <IconButton onClick={handleCancelAction}>
            <FontAwesomeIcon color={theme.palette.primary.main} size="sm" icon="times" />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <>
          {/* -------------- File Upload Schedule Template --------------------- */}
          <FileUploadZone
            accept=".csv, text/csv, text/plain, application/vnd.ms-excel"
            compact
            label={t`Drag and drop template here to upload, or`}
            onFileSelect={handleFileSelect}
            multiple={false}
            showHelpIcon={false}
          />
          {invalidTemplateFiles.length > 0 && (
            <FileUploadPreview fileDatas={[]} fileErrors={invalidTemplateFiles} showHeading={false} />
          )}
        </>

        {/* -------------- Display the response from Upload Schedule Template --------------------- */}
        {templateUploadResponse ? (
          <UploadedScheduleTemplateData templateUploadResponse={templateUploadResponse} />
        ) : (
          <></>
        )}
      </DialogContent>
      <br />

      {/* --------------------- Actions Download, Cancel and Submit ------------------ */}
      <DialogActions>
        <Flex justifyContent="space-between" flexGrow={1} alignItems="center">
          <div>
            {downloadSchedule && asset && targetScheduleDataStream && scheduleSourceDataStream && (
              <DownloadScheduleTemplate
                asset={asset}
                targetScheduleDataStream={targetScheduleDataStream}
                scheduleSourceDataStream={scheduleSourceDataStream}
                uploadedTemplateFiles={validTemplateFiles}
              />
            )}
          </div>

          <Flex>
            <Button onClick={handleCancelAction} size="small" variant="contained" disabled={downloadSchedule}>
              {t`Cancel`}
            </Button>
            <LoadingButton
              style={{ marginLeft: '1em' }}
              onClick={handleSubmitScheduleTemplate}
              color="primary"
              size="small"
              variant="contained"
              disabled={!templateVerifiedSuccessfully || downloadSchedule || submitInProgress}
              loading={submitInProgress}
            >
              {t`Submit`}
            </LoadingButton>
          </Flex>
        </Flex>
      </DialogActions>
    </StyledDialog>
  )
}

export default UploadScheduleTemplateDialog
