import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Asset } from 'modules/asset/store/asset.types'
import {
  EnrichedForecastModelItem,
  EnrichedQualityEvaluationItem,
  getActiveModels,
  getForecastModelColumns,
  getForecastModelTableItemsBasedOnHideFilter,
} from 'utils/forecastModel'
import {
  RETABLE_ID_FORECAST_MODELS,
  ReTableContextMenuItemName,
  ReTableRowContextActions,
  Sort,
} from 'modules/reTable/reTable.types'

import {
  RETABLE_SET_COLUMNS_AVAILABLE,
  RETABLE_SET_COLUMNS_SELECTED,
} from 'modules/reTable/redux_store/reTable.action.types'
import ReTable from 'modules/reTable/ReTable'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import {
  reTableColumnsSelectedSelector,
  reTableSearchSelector,
  reTableSortSelector,
} from 'modules/reTable/redux_store/state/view.state'
import Flex from 'ui/styles/Flex'
import ForecastModelsTableToolbar from 'modules/forecastModels/ForecastModelsTableToolbar'
import { ForecastModel } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import ForecastModelDetails from 'modules/forecastModels/ForecastModelDetails'
import {
  migrateForecastModelsToV2,
  QUERY_KEY_MODEL_WEIGHTS_BY_ASSET,
  startSolarCalibration,
  startWeightOptimization,
  toggleEnableRealTime,
} from 'modules/quality/quality.api'
import { SliderComponent } from 'ui/elements/SliderComponent'
import { useDebounce } from 'use-debounce'
import { getNextPrevNavigationIndex } from 'utils/route'
import { table } from 'themes/theme-light'
import ForecastModelsTableBody from 'modules/forecastModels/ForecastModelsTableBody'
import ForecastModelsTableHeader from 'modules/forecastModels/ForecastModelsTableHeader'
import ForecastModelDetailsDialog from 'modules/quality/ForecastModelDetailsDialog'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import styled from 'styled-components'
import InlineLoading from 'ui/InlineLoading'
import { START_TRAINING_REQUEST, START_TRAINING_RESET } from 'modules/asset/store/startTraining.state'
import { useQueryClient } from 'react-query'
import { QUERY_KEY_BACK_CAST_TIMESERIES } from 'modules/dataStreams/api/timeseries.api'
import {
  workspaceDraftChartDataRangeSelector,
  workspaceDraftDataStreamSelectionSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { getInclusiveDateRangeFromChartDataRange } from 'utils/date'
import { isSolarAsset, isWindAsset } from 'utils/asset'

const renderAheadCount = 100

const ErrorMessage = styled.div`
  margin: 0.5em 0;
  color: red;
  height: 0.8rem;
`

const TableWrapper = styled.div`
  height: calc(100vh - 12em);
`

const StyledReTable = styled(ReTable)`
  height: 100%;
`

interface ForecastModelsProps {
  asset: Asset
  modelsAndEvaluations: ForecastModel[]
  forecastModelsWithoutTransformation: ForecastModel[]
}

const ForecastModelsTable: React.FC<ForecastModelsProps> = ({
  asset,
  modelsAndEvaluations,
  forecastModelsWithoutTransformation,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserResultSelector)
  const queryClient = useQueryClient()
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const chartDataRange = useSelector(workspaceDraftChartDataRangeSelector)
  const timezone = useSelector(getUserTimezoneSelector)
  const range = getInclusiveDateRangeFromChartDataRange(chartDataRange, timezone)

  const [showHiddenModels, setShowHiddenModels] = useState(false)
  const [itemsToRender, setItemsToRender] = useState<EnrichedQualityEvaluationItem[]>([])
  const [modelToEdit, setModelToEdit] = useState<ForecastModel | null>(null)
  const [evaluationRunning, setEvaluationRunning] = useState<Record<string, boolean>>({})
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const query = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_FORECAST_MODELS)
  const sort = useReTableSelectorWithId(reTableSortSelector, RETABLE_ID_FORECAST_MODELS)

  // selection and details
  const [selectedItem, setSelectedItem] = useState<EnrichedQualityEvaluationItem>()

  const handleSelect = useCallback((item: EnrichedQualityEvaluationItem) => {
    setSelectedItem((prevEval) => {
      return prevEval?.id === item.id ? undefined : item
    })
  }, [])

  const handleCloseModelDetails = () => {
    setSelectedItem(undefined)
  }

  const handleGoToNextPrevItem = useCallback(
    (goToNextItem: boolean) => {
      const displayedEvaluationId = selectedItem?.id || ''
      const newIndex = getNextPrevNavigationIndex(itemsToRender, displayedEvaluationId, goToNextItem)
      if (newIndex !== undefined) {
        handleSelect(itemsToRender[newIndex])
      }
    },
    [itemsToRender, selectedItem],
  )

  const handleToggleShowHiddenModels = useCallback(() => {
    setShowHiddenModels((prevVal) => !prevVal)
  }, [])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: false,
      column: 'creationDate',
    }),
    [],
  )

  const columnsAvailable = useMemo(() => {
    const columnsToRemoveWhenSortNotActive = [
      'forecastModelName',
      'correctionModel',
      'weatherModel',
      'activationStatus',
    ]
    let columns = !sort.active
      ? getForecastModelColumns().filter((h) => !columnsToRemoveWhenSortNotActive.includes(h.name))
      : getForecastModelColumns()

    columns = columns.filter((c) => (c?.isAuthorized ? c.isAuthorized(user) : true))

    return columns
  }, [sort, user])

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_FORECAST_MODELS)

  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_FORECAST_MODELS, columnsAvailable })
    dispatch({
      type: RETABLE_SET_COLUMNS_SELECTED,
      table: RETABLE_ID_FORECAST_MODELS,
      columnsSelected: columnsAvailable.map((c) => c.name),
    })
  }, [columnsAvailable])

  const handleItemsToRenderChange = useCallback(
    (items: EnrichedQualityEvaluationItem[], sort: Sort) => {
      let newItemsToRender = getForecastModelTableItemsBasedOnHideFilter([...items], showHiddenModels)

      // If sort is active show only quality evaluations
      if (sort.active && newItemsToRender) {
        newItemsToRender = newItemsToRender.filter((item) => item?.isQualityEvaluation)
      }

      requestAnimationFrame(() => {
        setItemsToRender(newItemsToRender)
      })
    },
    [showHiddenModels],
  )

  const handleEvaluateAll = useCallback(
    (forecastModel: ForecastModel) => {
      setEvaluationRunning((running) => {
        return {
          ...running,
          [forecastModel.uuid]: true,
        }
      })
      dispatch({ type: actionTypes.REEVALUATE_FORECAST_MODEL_REQUEST, asset, forecastModel })
      setLoading(false)
    },
    [asset],
  )

  const handleActivateForecastModel = useCallback(
    (forecastModel: ForecastModel, isActive: boolean) => {
      // isActivate means in production
      if (!isActive) {
        dispatch({ type: actionTypes.ACTIVATE_FORECAST_MODEL_REQUEST, asset, forecastModel })
        // Since active forecast model is changed remove the Backcast timeseries for this asset because its based on the active forecast model
        selectedDataStreams.forEach((dataStream) => {
          queryClient.setQueryData(
            [
              QUERY_KEY_BACK_CAST_TIMESERIES,
              dataStream.type,
              dataStream.subType,
              dataStream.classifier,
              dataStream.id,
              asset.id,
              null,
              range,
            ],
            () => {
              return {}
            },
          )
        })
        queryClient.removeQueries(QUERY_KEY_MODEL_WEIGHTS_BY_ASSET)
        queryClient.invalidateQueries([QUERY_KEY_BACK_CAST_TIMESERIES, asset.id])
      }

      setLoading(false)
    },
    [asset],
  )

  const handleMigration = useCallback(() => {
    if (asset?.id) {
      migrateForecastModelsToV2([asset.id]).then((res) => {
        dispatch({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
        if (res?.error?.message) {
          setErrorMsg(res.error.message)
        }
        setLoading(false)
      })
    }
  }, [asset])

  const handleEditForecastModel = useCallback((forecastModel: ForecastModel) => {
    setModelToEdit(forecastModel)
  }, [])

  const handleStartWeightOptimization = useCallback((forecastModel: ForecastModel) => {
    startWeightOptimization(forecastModel.uuid, forecastModel.trainingId).then((response) => {
      if (response?.error?.message) {
        const errorMsg = response?.error?.message || ''
        setErrorMsg(errorMsg)
      }
      setLoading(false)
    })
  }, [])

  const handleStartSolarCalibration = useCallback((forecastModel: ForecastModel) => {
    startSolarCalibration(forecastModel.uuid).then((response) => {
      if (response?.error) {
        setErrorMsg(response.error.message)
      }
      setLoading(false)
    })
  }, [])

  const activeModels = useMemo(() => {
    return getActiveModels(forecastModelsWithoutTransformation)
  }, [forecastModelsWithoutTransformation])

  // This function is used only for wind plant
  const handleStartTraining = useCallback(() => {
    if (asset) {
      const selectedAssets = [asset]
      dispatch({ type: START_TRAINING_REQUEST, assets: selectedAssets })
      setLoading(false)
    }
  }, [asset])

  function handleEnableDisableRealTime(forecastModel: EnrichedForecastModelItem) {
    const realTimeEnabled = !Boolean(forecastModel?.parameter?.realtime === 'true')
    const assetType = isSolarAsset(asset) ? 'PV' : isWindAsset(asset) ? 'WIND' : ''
    toggleEnableRealTime(forecastModel.id, realTimeEnabled, assetType)
      .then(() => {
        dispatch({ type: actionTypes.GET_FORECAST_MODELS_REQUEST, asset })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleClickModelRowMenuAction = useCallback(
    (menuItem: ReTableContextMenuItemName, forecastModel: EnrichedForecastModelItem) => {
      setErrorMsg(undefined)

      const {
        EDIT_ROW,
        EVALUATE_ALL,
        ACTIVATE,
        MIGRATE_TO_V2,
        START_WEIGHT_OPTIMIZATION,
        START_SOLAR_CALIBRATION,
        START_TRAINING_NOW,
        ENABLE_DISABLE_REALTIME,
      } = ReTableRowContextActions
      const modelWithOutTransformation = forecastModelsWithoutTransformation?.find(
        (model) => forecastModel?.uuid === model?.uuid,
      )

      if (menuItem !== EDIT_ROW) {
        setLoading(true)
      }

      if (modelWithOutTransformation && forecastModelsWithoutTransformation) {
        const isModelActive =
          activeModels?.length && activeModels.some((am) => am.uuid === modelWithOutTransformation?.uuid)
        switch (menuItem) {
          case EDIT_ROW:
            handleEditForecastModel(modelWithOutTransformation)
            break
          case EVALUATE_ALL:
            handleEvaluateAll(modelWithOutTransformation)
            break
          case ACTIVATE:
            handleActivateForecastModel(modelWithOutTransformation, isModelActive)
            break
          case MIGRATE_TO_V2:
            handleMigration()
            break
          case START_WEIGHT_OPTIMIZATION:
            handleStartWeightOptimization(modelWithOutTransformation)
            break
          case START_SOLAR_CALIBRATION:
            handleStartSolarCalibration(modelWithOutTransformation)
            break
          case START_TRAINING_NOW:
            handleStartTraining()
            break
          case ENABLE_DISABLE_REALTIME:
            handleEnableDisableRealTime(forecastModel)
        }
      }
    },
    [handleMigration, handleStartTraining, forecastModelsWithoutTransformation, activeModels],
  )

  const collapsibleIds = useMemo(
    () => modelsAndEvaluations.filter((item) => item && item?.uiChildren?.length > 0).map((fm) => fm.id),
    [modelsAndEvaluations],
  )

  const defaultCollapsedIds = useMemo(() => modelsAndEvaluations.map((item) => item.id), [modelsAndEvaluations])

  const [debouncedSelectedItem] = useDebounce(selectedItem, 100)

  const [showModelDetails, animateShowModelDetails] = useMemo(
    () => (selectedItem ? [selectedItem, debouncedSelectedItem] : [debouncedSelectedItem, selectedItem]),
    [selectedItem, debouncedSelectedItem],
  )

  // Used for only table height
  const itemsForTable = useMemo(() => {
    if (!modelsAndEvaluations.length) return []
    return getForecastModelTableItemsBasedOnHideFilter(modelsAndEvaluations, showHiddenModels)
  }, [modelsAndEvaluations, showHiddenModels])

  useEffect(() => {
    return () => {
      setErrorMsg(undefined)
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: START_TRAINING_RESET })
    }
  }, [])

  // console.log({ visibleItems, modelsAndEvaluations, itemsToRender, sort })

  return (
    <TableWrapper direction="column">
      {modelToEdit && (
        <ForecastModelDetailsDialog
          forecastModel={modelToEdit}
          onCloseDialog={() => {
            setModelToEdit(null)
          }}
          asset={asset}
        />
      )}

      <Flex direction="column" fullHeight>
        <ErrorMessage>{errorMsg && <> {errorMsg}</>}</ErrorMessage>
        <Flex direction="row">
          {loading && <InlineLoading />}
          <ForecastModelsTableToolbar
            onToggleShowHiddenModels={handleToggleShowHiddenModels}
            columns={columnsSelected}
            tableHeaderHasActions={true}
            showHiddenModels={showHiddenModels}
            asset={asset}
          />
        </Flex>

        <StyledReTable
          id={RETABLE_ID_FORECAST_MODELS}
          itemHeight={table.rowHeight}
          items={itemsForTable}
          hasExtendedHeader={false}
          itemsPaddingHeader={table.itemsPaddingHeader}
          itemsPaddingFooter={table.itemsPaddingFooter}
          renderAheadCount={renderAheadCount}
          isNarrow={false}
          defaultSort={defaultSort}
          collapsibleIds={collapsibleIds}
          // selectedIds={dataSelectionIds}
          defaultCollapsed={defaultCollapsedIds}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ForecastModelsTableHeader columnsSelected={columnsSelected} />

          <ForecastModelsTableBody
            asset={asset}
            activeModels={activeModels}
            evaluationRunning={evaluationRunning}
            columnsSelected={columnsSelected}
            onClickModelRowMenuAction={handleClickModelRowMenuAction}
            onSelect={handleSelect}
            query={query}
            visibleItems={itemsToRender}
            selectedItem={selectedItem}
            sort={sort}
          />
        </StyledReTable>

        {showModelDetails && selectedItem && (
          <SliderComponent marginRight={animateShowModelDetails ? '0' : '-41em'} width="41em" right="0" zIndex={7}>
            <ForecastModelDetails
              asset={asset}
              evaluation={selectedItem}
              onCloseModelDetails={handleCloseModelDetails}
              totalItemsCount={itemsToRender?.length || 0}
              onGoToNextPrevItem={handleGoToNextPrevItem}
            />
          </SliderComponent>
        )}
      </Flex>
    </TableWrapper>
  )
}

// ForecastModelsTable.whyDidYouRender = {
//   logOnDifferentValues: true,
// }

export default React.memo(ForecastModelsTable)
