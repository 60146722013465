import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Asset, TreeDataStreamsSelectionOptions } from 'modules/asset/store/asset.types'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { TDIndented } from 'ui/styles/table'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { useSelector } from 'react-redux'
import { workspaceDraftAssetSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { isAfter } from 'date-fns'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { Column, RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import { t } from 'ttag'
import { getActiveModels, getBackCastModelIdForAssetTree } from 'utils/forecastModel'
import { BACKCAST, backcastStatusValue } from 'fixtures/assetTree'
import ForecastModelRow from 'modules/asset/tree/ForecastModelRow'
import { QUERY_ASSET, useQueryMatch } from 'utils/query-string'
import { useForecastModelsByAsset } from 'modules/forecastModels/api/forecastModels.api'
import { ForecastModel } from 'modules/quality/quality.types'

interface ForecastModelRowsProps {
  asset: Asset
  isWide: boolean
  onSelect: (asset: Asset, selectionOptions: TreeDataStreamsSelectionOptions) => void
  level: number
  backcastStatusPerAsset: any
}

const ForecastModelRows: React.FC<ForecastModelRowsProps> = ({
  asset,
  isWide,
  onSelect,
  level,
  backcastStatusPerAsset,
}) => {
  const isAssetDetails = useQueryMatch(QUERY_ASSET)

  const forecastModelsResult = useForecastModelsByAsset(asset)
  const [activeModels, setActiveModels] = useState<ForecastModel[]>([])
  const [backcastColumn, setBackcastColum] = useState<Column | undefined>(undefined)

  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_ASSETS)
  const forecastModels = useMemo(() => {
    return (forecastModelsResult?.data || []).sort((a, b) => {
      return isAfter(new Date(a.creationDate), new Date(b.creationDate)) ? -1 : 1
    })
  }, [forecastModelsResult?.data])
  const assetSelection = useSelector(workspaceDraftAssetSelectionSelector)

  const handleForecastModelSelect = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, forecastModel) => {
      event.stopPropagation()
      event.preventDefault()
      onSelect(forecastModel, { ctrlKey: event.ctrlKey || event.metaKey })
    },
    [onSelect],
  )

  const lightMode = useMemo(() => isAssetDetails, [isAssetDetails])

  useEffect(() => {
    const activeModels = getActiveModels(forecastModels)
    if (activeModels.length) {
      setActiveModels(activeModels)
    } else setActiveModels([])
  }, [forecastModels])

  useEffect(() => {
    const backcastIsSelected = columnsSelected.find((column) => column.name === BACKCAST)
    setBackcastColum(backcastIsSelected)
  }, [columnsSelected])

  return (
    <>
      {(forecastModels || [])
        ?.filter((m) => !m?.hide)
        .map((model) => {
          const key = model.uuid
          const treeLevel = level + 3
          const transformModel = {
            ...model,
            id: getBackCastModelIdForAssetTree(model),
            backcastStatus: backcastStatusPerAsset?.[model.uuid]
              ? backcastStatusPerAsset?.[model.uuid]
              : backcastStatusValue.NOTHING,
          }
          const isSelected = assetSelection.includes(transformModel.id)
          return (
            <ForecastModelRow
              key={key}
              forecastModel={transformModel}
              treeLevel={treeLevel}
              isWide={isWide}
              lightMode={lightMode}
              activeModels={activeModels}
              isSelected={isSelected}
              onForecastModelSelect={handleForecastModelSelect}
              backcastColumn={backcastColumn}
              columnsSelected={columnsSelected}
            />
          )
        })}
      {forecastModels && forecastModels?.length === 0 && (
        <ReTableRow>
          <ReTableCell colSpan={isWide ? columnsSelected.length : 2}>
            <TDIndented level={level + 3}>
              {forecastModelsResult?.isLoading ? t`Loading...` : t`No models found`}
            </TDIndented>
          </ReTableCell>
        </ReTableRow>
      )}
    </>
  )
}

export default React.memo(ForecastModelRows)
