import AssetMeterdata from 'modules/asset/AssetMeterdata'
import TreePlausabilityData from 'modules/asset/plausability/TreePlausabilityData'
import { Asset } from 'modules/asset/store/asset.types'
import { getMeterdataSummariesResultSelector } from 'modules/asset/store/getMeterdataSummaries.state'
import { SET_AUTODEPLOY_REQUEST } from 'modules/asset/store/setAutoDeploy.state'
import SiteForecastCell from 'modules/asset/tree/SiteForecastCell'
import EvaluationData from 'modules/quality/EvaluationData'
import { QualityOverviewItem } from 'modules/quality/quality.types'
import { getQualityOverviewResultSelector } from 'modules/quality/redux_store/state/getQualityOverview'
import { getTrainingInfoLastSetNetworkSelector } from 'modules/quality/redux_store/state/getTrainingInfo'
import { reTableSearchSelector } from 'modules/reTable/redux_store/state/view.state'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { Column, RETABLE_ID_ASSETS } from 'modules/reTable/reTable.types'
import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DateSingle from 'ui/DateSingle'
import BooleanSwitch from 'ui/form/BooleanSwitch'
import Number from 'ui/Number'
import {
  getAssetCountryCode,
  getCoords,
  getNomcap,
  isCluster,
  isGenerator,
  isParkWithNoPlants,
  isSolarAsset,
  isWindPark,
  isWindPlant,
} from 'utils/asset'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import { backcastStatusValue, PLANT_KEY_AND_EEG_ID_WIDTH } from 'fixtures/assetTree'

import PenaltyAccuracyCell from 'modules/asset/tree/PenaltyAccuracyCell'
import { CoordinateCachedData } from 'modules/asset/assetCrud/assetDetails/LocationCoordinates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { theme } from 'themes/theme-light'
import PopperTooltip from 'ui/PopperTooltip'
import { c, t } from 'ttag'
import styled from 'styled-components'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import Flex from 'ui/styles/Flex'
import { useIsReadOnlyUser } from 'utils/user'

import {
  FindAllCleansingFilterSettings,
  MDCAssetType,
  MeterDataCleansingFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/meterDataCleansingTypes'
import {
  useDefaultMDCFilterSettings,
  useEnercastMDCFilterSettings,
} from 'modules/asset/assetCrud/meterDataCleansing/api/meterDataCleansing.api'
import { getDefaultValuesForBulkMdcFilterSettings } from 'utils/meterDataCleansing'
import { AppUnits } from 'utils/units'

const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 0.5em;
`

const SimpleText = styled.p`
  margin: 0;
`

interface TreeCellProps {
  column: Column
  asset: Asset
  showNumCapFraction: boolean
  selected: boolean | undefined
  operationalData: CoordinateCachedData
  backcastStatusPerAsset: any
  filterSettingsPerAsset: FindAllCleansingFilterSettings
  anyDetailsVisible: boolean
}

const TreeCell: React.FC<TreeCellProps> = ({
  column,
  asset,
  showNumCapFraction,
  selected,
  operationalData,
  backcastStatusPerAsset,
  filterSettingsPerAsset,
  anyDetailsVisible,
}) => {
  const dispatch = useDispatch()
  const summaries = useSelector(getMeterdataSummariesResultSelector)
  const lastSetNetwork = useSelector(getTrainingInfoLastSetNetworkSelector)
  const qualityOverview = useSelector(getQualityOverviewResultSelector)
  const mdcAssetType = isSolarAsset(asset) ? MDCAssetType.SOLAR : MDCAssetType.WIND
  const defaultFilterSettings = useDefaultMDCFilterSettings(mdcAssetType)?.data
  const enercastFilterSettings = useEnercastMDCFilterSettings(mdcAssetType)?.data
  const nomcap = getNomcap(asset)
  const meterdataSummary = summaries[asset.id]
  const coords = getCoords(asset)
  const isReadOnlyUser = useIsReadOnlyUser()

  const search = useReTableSelectorWithId(reTableSearchSelector, RETABLE_ID_ASSETS)

  const getDefaultFilterSettings: MeterDataCleansingFilterSettings = useMemo(() => {
    return defaultFilterSettings || enercastFilterSettings || getDefaultValuesForBulkMdcFilterSettings()
  }, [defaultFilterSettings, enercastFilterSettings])

  const handleToggleAutoDeploy = useCallback(
    (checked: boolean) => {
      dispatch({ type: SET_AUTODEPLOY_REQUEST, assets: [asset], enabled: checked })
    },
    [selected, asset],
  )

  const parts = column.name.split('.')
  switch (parts[0]) {
    case 'backcast':
      let arrayOfBackcastStatusValue, ready, running, total
      if (backcastStatusPerAsset) {
        arrayOfBackcastStatusValue = Object.values(backcastStatusPerAsset)
      }

      if (arrayOfBackcastStatusValue) {
        ready = arrayOfBackcastStatusValue.filter((value) => value === backcastStatusValue.READY).length
        running = arrayOfBackcastStatusValue.filter((value) => value === backcastStatusValue.RUNNING).length
        total = ready + running
      }

      return (
        <Flex direction="row">
          {backcastStatusPerAsset && arrayOfBackcastStatusValue ? `${ready} / ${total}` : ''}
          {backcastStatusPerAsset && arrayOfBackcastStatusValue && (
            <PopperTooltip
              popperLabel={
                <TooltipIcon
                  icon="info"
                  color={selected && !anyDetailsVisible ? 'white' : theme.palette.primary.main}
                  size="sm"
                />
              }
              popperContent={t`Ready / Total`}
            />
          )}
        </Flex>
      )
    case 'nomcap':
      return <Number showFractionalPart={showNumCapFraction} limit={3} highlight={search} data={nomcap} />
    case 'plausibility':
      return <TreePlausabilityData asset={asset} selected={selected} />
    case 'key':
      return (
        <>
          {!isCluster(asset) && asset.key && (
            <FixedWidthTextContainer text={asset.key} width={PLANT_KEY_AND_EEG_ID_WIDTH}>
              {asset.key}
            </FixedWidthTextContainer>
          )}
        </>
      )
    case 'eegId':
      return (
        <>
          {!isCluster(asset) && asset.eegId && (
            <FixedWidthTextContainer text={asset.eegId} width={PLANT_KEY_AND_EEG_ID_WIDTH}>
              {asset.eegId}
            </FixedWidthTextContainer>
          )}
        </>
      )
    case 'siteForecast':
      return <SiteForecastCell asset={asset} />
    case 'country':
      return <>{getAssetCountryCode(asset)}</>
    case 'meterdata':
      return meterdataSummary ? <AssetMeterdata summary={meterdataSummary} selected={selected} /> : <></>
    case 'longitude':
      return coords && coords.longitude ? <Number limit={6} data={coords.longitude} /> : <></>
    case 'latitude':
      return coords && coords.latitude ? <Number limit={6} data={coords.latitude} /> : <></>
    case 'lastTraining':
      return lastSetNetwork[asset.id] ? <DateSingle date={lastSetNetwork[asset.id]} selected={selected} /> : <></>
    case 'autoDeploymentEnabled':
      const hasAutoDeployment = isGenerator(asset) || isParkWithNoPlants(asset)
      return hasAutoDeployment ? (
        <BooleanSwitch
          disabled={isReadOnlyUser}
          enabled={asset.autoDeploymentEnabled}
          selected={selected}
          onToggle={handleToggleAutoDeploy}
        />
      ) : (
        <></>
      )
    case 'orderNumber':
      return <>{asset?.orderNumber}</>
    case 'quality':
      const forecastConfigId = parts[1]
      const overviewItem: QualityOverviewItem | null =
        ((qualityOverview || {})[asset.id] || {})[forecastConfigId] || null
      return overviewItem?.active ? <EvaluationData overviewItem={overviewItem} selected={selected} /> : <></>

    case 'penaltyAccuracy':
      return <PenaltyAccuracyCell asset={asset} />

    case 'operational':
      return (
        <div>
          {operationalData ? (
            <FontAwesomeIcon
              color={selected ? 'white' : theme.palette.primary.main}
              size="1x"
              icon={operationalData?.cached ? 'check' : 'circle-notch'}
            />
          ) : (
            <></>
          )}
        </div>
      )

    case 'assetSpecificFilterSetup':
      return (
        <Flex alignItems="center">
          <SimpleText>
            {isWindPlant(asset) || isWindPark(asset)
              ? filterSettingsPerAsset && filterSettingsPerAsset.specificFilterSetings
                ? t`Asset-specific`
                : t`Default`
              : '-'}
          </SimpleText>

          {(isWindPlant(asset) || isWindPark(asset)) && (
            <div>
              <PopperTooltip
                popperLabel={
                  <TooltipIcon
                    icon="info"
                    color={selected && !anyDetailsVisible ? 'white' : theme.palette.primary.main}
                    size="sm"
                  />
                }
                popperContent={
                  <div>
                    <SimpleText>
                      {c('Meter data cleansing').t`Range`}:{' '}
                      {filterSettingsPerAsset && filterSettingsPerAsset?.specificFilterSetings
                        ? filterSettingsPerAsset?.cleansingParams?.range
                        : getDefaultFilterSettings.range}
                      {''}
                      {AppUnits.PERCENTAGE}
                    </SimpleText>
                    <SimpleText>
                      {c('Meter data cleansing').t`Repetitive`}:{' '}
                      {filterSettingsPerAsset && filterSettingsPerAsset?.specificFilterSetings
                        ? filterSettingsPerAsset?.cleansingParams?.repetitive
                        : getDefaultFilterSettings.repetitive}
                      {''}
                      {AppUnits.PERCENTAGE}
                    </SimpleText>
                    <SimpleText>
                      {c('Meter data cleansing').t`Outlier left`}:{' '}
                      {filterSettingsPerAsset && filterSettingsPerAsset?.specificFilterSetings
                        ? filterSettingsPerAsset?.cleansingParams?.outlierLeft
                        : getDefaultFilterSettings.outlierLeft}
                      {''}
                      {AppUnits.PERCENTAGE}
                    </SimpleText>
                    <SimpleText>
                      {c('Meter data cleansing').t`Outlier right`}:{' '}
                      {filterSettingsPerAsset && filterSettingsPerAsset?.specificFilterSetings
                        ? filterSettingsPerAsset?.cleansingParams?.outlierRight
                        : getDefaultFilterSettings.outlierRight}
                      {''}
                      {AppUnits.PERCENTAGE}
                    </SimpleText>
                  </div>
                }
              />
            </div>
          )}
        </Flex>
      )

    default:
      return <></>
  }
}

// TreeCell.whyDidYouRender = true
export default React.memo(TreeCell)
