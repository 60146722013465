import axios from 'axios'
import { format, getTime, parseISO, subMinutes } from 'date-fns'
import { Asset, Coordinate, FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'

import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import { useSiteForecastConfigs } from 'modules/dataStreams/api/siteForecastConfigs.api'
import {
  DataStreamSelection,
  DataStreamSelectionItem,
  DeliveryTimesType,
  E3Ensemble,
  EvaluationDurationType,
  ForecastConfig,
  GERMANY_REVENUE_SEARCH_KEY,
  TimeSeries,
  TimeSeriesClassifier,
  TimeSeriesResult,
  TimeSeriesSubType,
  TimeSeriesType,
} from 'modules/dataStreams/dataStreams.types'
import { WeatherDataResponse } from 'modules/weather/store/weather.types'
import {
  workspaceDraftAssetSelectionSelector,
  workspaceDraftChartDataRangeSelector,
  workspaceDraftDataStreamSelectionSelector,
  workspaceDraftE3WidgetSettingsSelector,
  workspaceDraftSelectedModelsSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'
import { useMemo } from 'react'
import { QueryKey, QueryObserverOptions, QueryObserverResult, useQueries } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useSelector } from 'react-redux'
import {
  getDescendantCoordinates,
  isGenerator,
  isParkWithNoPlants,
  useUniqueAllAssets,
  useUniqueSelectedAssets,
} from 'utils/asset'
import {
  DEFAULT_FORECAST_CONFIG_ID,
  getDataStreamId,
  getMarketPriceDataBaseUrl,
  isMetaForecastDataStream,
  useDataStreams,
} from 'utils/dataStream'
import { hasPermissionForSiteAssessmentBackcast, hasPermissionTo3rdPartyForecasts } from 'utils/user'
import {
  convertLocalDateToUTC,
  DATE_FORMAT_INTERNAL_LONG,
  DateRange,
  getFormattedTime,
  getInclusiveDateRangeFromChartDataRange,
} from 'utils/date'
import { getBackCastModelDataFromId, isMetaForecastModel } from 'utils/forecastModel'
import { apiRequest } from 'utils/request'
import {
  convertWattTimeseries,
  enrichTimeseriesAfterLoad,
  EnrichTimeseriesFillContent,
  EnrichTimeseriesFillMode,
  getTimeSeriesSeriesType,
  getTimeSeriesValueType,
} from 'utils/timeseries'
import { useLineChartSettings } from 'modules/workspace/api/lineChart.api'
import { AppUnits } from 'utils/units'
import { ChartAggregationMode } from 'modules/workspace/store/workspace.types'
import { Timezone } from 'fixtures/timezones'

// constants
export const timeseriesDatetimeFormat = 'yyyy-MM-dd-HH-mm'

/**
 * default options for querying data streams
 * we don't want time series to be re-fetched too often
 */
const timeSeriesDefaultQueryOptions: UseQueryOptions<TimeSeriesResult, TimeSeriesResult> = {
  cacheTime: 1000 * 60 * 30,
  staleTime: 1000 * 60,
  retry: (failureCount, error: TimeSeriesResult) => {
    const retryNecessary = Boolean(error.cacheIsStillBeingBuilt)
    return retryNecessary
  },
}

// Query keys for caching data

export const QUERY_KEY_TIMESERIES = 'dataStreams:timeSeries'
export const QUERY_KEY_BACK_CAST_TIMESERIES = 'dataStreams:backCastTimeSeries'

// Async API requests to fetch and update data

/**
 * API response transform method for choosing the correct sub result
 */
interface TransformChooseSubResult {
  dataStream: DataStreamSelectionItem
}
const transformChooseSubResult = ({ dataStream }: TransformChooseSubResult) => (
  data: Record<TimeSeriesSubType, TimeSeries>,
) => {
  let dataWithTimeSeriesType: any
  let { subType } = dataStream
  if (subType === TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY) {
    if (dataStream?.timeSeriesResultType) {
      const maxAvailableData = data[TimeSeriesSubType.MAX_AVAILABLE_CAPACITY]
      const firstTimeStamp = Object.keys(maxAvailableData)[0]
      dataWithTimeSeriesType = data[dataStream?.timeSeriesResultType]
        ? data[dataStream?.timeSeriesResultType]
        : { [firstTimeStamp]: null }
    } else {
      subType = TimeSeriesSubType.MAX_AVAILABLE_CAPACITY
    }
    // TODO: this is a workaround for dealing with different sources of capacity data
    //  PLANNED_AVAILABLE_CAPACITY is used for capacity service and will be renamed to MAX_AVAILABLE_CAPACITY
    //  all others are used for time series service
  }
  return dataWithTimeSeriesType ? dataWithTimeSeriesType : subType && data[subType] ? data[subType] : data
}

/**
 * API response transform method for adding a value at the end
 */
interface TransformAddValueAtEnd {
  dataStream: DataStreamSelectionItem
  range: DateRange
}
const transformAddValueAtEnd = ({ range }: TransformAddValueAtEnd) => (data: TimeSeries) => {
  const timestamps = Object.keys(data)
  const formattedEnd = format(subMinutes(new Date(range[1]), 15), DATE_FORMAT_INTERNAL_LONG)
  return {
    ...data,
    [formattedEnd]: data[timestamps[timestamps.length - 1]],
  }
}

/**
 * API response transform method to convert weather response to common format
 */
interface TransformWeatherResponse {
  dataStream: DataStreamSelectionItem
}
const transformWeatherResponse = ({ dataStream }: TransformWeatherResponse) => (data: WeatherDataResponse) => {
  const timeSeries = data.forecastParams.reduce<TimeSeries>((ts, forecastParam) => {
    // we don't get UTC from the server here, so we need to convert the date
    const time = format(convertLocalDateToUTC(parseISO(forecastParam.Timestamp)), "yyyy-MM-dd'T'HH:mm:ss") + 'Z'
    const value = forecastParam[dataStream.id || '']
    return { ...ts, [time]: value }
  }, {})
  return timeSeries
}

/**
 * API response transform method for converting W to kW
 */
interface TransformConvertUnit {
  dataStream: DataStreamSelectionItem
}
const transformConvertUnit = ({ dataStream }: TransformConvertUnit) => (data: TimeSeries) => {
  return convertWattTimeseries(data || {}, dataStream)
}

/**
 * API response transform method for filling holes
 */
interface TransformEnrichData {
  dataStream: DataStreamSelectionItem
  range?: DateRange
  timezone?: Timezone
}
const transformEnrichData = ({ dataStream, range, timezone }: TransformEnrichData) => (data: TimeSeries) => {
  const fillMode = EnrichTimeseriesFillMode.EQUiDISTANT
  const fillContent =
    dataStream.type === TimeSeriesType.CAPACITY_DATA
      ? EnrichTimeseriesFillContent.INTERVAL_PREVIOUS
      : EnrichTimeseriesFillContent.HOLE_CENTER_NULL
  const fillIntervalMinutes = dataStream.type === TimeSeriesType.CAPACITY_DATA ? 60 * 24 : undefined
  const enrichedTimeseries = enrichTimeseriesAfterLoad(
    data || {},
    range,
    {
      fillMode,
      fillContent,
      fillIntervalMinutes,
    },
    dataStream,
    timezone,
  )
  return enrichedTimeseries
}

/**
 * API response transform method for wrapping time series in envelope of additional data
 * so that can reference for which asset / data stream combination this data is for
 */
interface TransformToTimeSeriesResultWithMetaData {
  dataStream: DataStreamSelectionItem
  assetId?: string
  id?: string
  timeSeriesType?: TimeSeriesType
  range?: DateRange
  modelId?: string
}
export const transformToTimeSeriesResultWithMetaData = ({
  dataStream,
  assetId,
  id,
  timeSeriesType,
  modelId,
}: TransformToTimeSeriesResultWithMetaData) => (timeSeriesData: TimeSeries) => {
  const { type, subType, classifier } = dataStream
  const dataStreamId = getDataStreamId(dataStream)
  const timeSeriesResult: TimeSeriesResult = {
    data: timeSeriesData,
    assetId,
    dataStreamId,
    type: timeSeriesType || type,
    subType,
    classifier,
    id: id || assetId,
    modelId: modelId || null,
    aggregation: ChartAggregationMode.CHART_AGGREGATION_MODE_GROUP_BY_ASSET,
  }

  return timeSeriesResult
}

/**
 * Fake API method to compute e³ meta ensemble since we don't have a backend implementation for that
 */
interface GetE3MetaEnsembleData {
  dataStream: DataStreamSelectionItem
  asset: Asset
  range: DateRange
  forecastConfigs: ForecastConfig[]
  e3Ensemble: E3Ensemble
  modelId?: string
}
const getMetaForecastTimeseriesUsingEnsembleWeights = async ({
  dataStream,
  asset,
  e3Ensemble,
  range,
  modelId,
}: GetE3MetaEnsembleData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const assetId = asset.id

  // console.log({ e3Ensemble })

  const dataStreamIds = Object.keys(e3Ensemble)
  const weightAndDataStreams = dataStreamIds.map((id) => ({
    category: 'FORECAST',
    dataStreamId: id,
    weight: e3Ensemble[id] || 0,
  }))
  // const payLoad = {}

  return apiRequest(() => {
    return axios.post<TimeSeriesResult>(
      `api/qecs/meta_forecast/v1/calculate`,
      { assetId, weightAndDataStreams, start, end, unit: 'kW' },
      {
        transformResponse: (Array.isArray(axios.defaults.transformResponse)
          ? axios.defaults.transformResponse // default transform of axios parses JSON
          : []
        ).concat(
          transformEnrichData({ dataStream }), // we make sure to fill holes
          transformToTimeSeriesResultWithMetaData({ dataStream, assetId, modelId }), // wrap time series in envelope with meta data
        ),
      },
    )
  })
}

/**
 * API method to fetch time series data from backend
 */
interface GetTimeSeriesData {
  dataStream: DataStreamSelectionItem
  asset: Asset
  range: DateRange
  forecastConfigs: ForecastConfig[]
  modelId?: string
}
const getTimeSeriesData = async ({ dataStream, asset, range, forecastConfigs, modelId }: GetTimeSeriesData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const unit = AppUnits.KILO_WATT
  const assetId = asset.id
  const seriesType = getTimeSeriesSeriesType(dataStream)
  const valueType = getTimeSeriesValueType(dataStream)

  let offset: number | undefined = undefined
  let duration: number | undefined = undefined
  let deliveryTimes: string[] = []
  let offsetType = ''
  let offsetTimeZone = ''

  const matrixParams: string[] = []
  if (dataStream.classifier) {
    matrixParams.push(`;POWER_DATA_CLASSIFIER=${dataStream.classifier}`)
  }

  const isCompactedForecast =
    (dataStream.type === TimeSeriesType.SITE_FORECAST && dataStream.id === DEFAULT_FORECAST_CONFIG_ID) ||
    dataStream.type === TimeSeriesType.E3_META_FORECAST

  const isCompactedTimeSeries = dataStream.type === TimeSeriesType.METER_DATA && !dataStream.classifier

  /**
   * The following block is needed because of the lack of proper backend API for site/scheduled/e3Third-party forecasts
   * to fetch time series for a specific quality configuration id
   * instead, we need to put together all puzzle pieces to resemble the required quality config
   * **/

  if (
    dataStream.type === TimeSeriesType.SITE_FORECAST ||
    dataStream.type === TimeSeriesType.SCHEDULE ||
    dataStream.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST
  ) {
    const forecastConfig = forecastConfigs.find((fc) => fc.id === dataStream.id)
    if (forecastConfig && forecastConfig.qualityConfigs && forecastConfig.qualityConfigs.length > 0) {
      offsetTimeZone = forecastConfig?.horizon?.timeZone || forecastConfig?.updateTimes?.timeZone
      const primaryQualityConfig = forecastConfig.qualityConfigs.find((qualityConfig) => qualityConfig.primary)
      offset =
        (primaryQualityConfig?.horizon?.offsetDays || 0) * 24 * 60 +
        (primaryQualityConfig?.horizon?.offsetHours || 0) * 60 +
        (primaryQualityConfig?.horizon?.offsetMinutes || 0)
      duration =
        primaryQualityConfig?.durationType == EvaluationDurationType.CUSTOM
          ? (primaryQualityConfig?.horizon?.lengthDays || 0) * 24 * 60 +
            (primaryQualityConfig?.horizon?.lengthHours || 0) * 60 +
            (primaryQualityConfig?.horizon?.lengthMinutes || 0)
          : undefined
      deliveryTimes =
        primaryQualityConfig?.deliveryTimesType == DeliveryTimesType.CUSTOM
          ? primaryQualityConfig.deliveryTimes.map((time) => getFormattedTime(time).replace(':', '-'))
          : []
      offsetType = primaryQualityConfig?.offsetType
    }

    if (deliveryTimes.length > 0) {
      matrixParams.push(`;${deliveryTimes.map((time) => `DELIVERY_TIME=${time}`).join(';')}`)
    }

    matrixParams.push(`;PRODUCT_CONFIG_ID=${dataStream.id}`)
  }

  // perform actual api request with proper transformations
  return apiRequest(() => {
    const transformResponse = (Array.isArray(axios.defaults.transformResponse)
      ? axios.defaults.transformResponse // default transform of axios parses JSON
      : []
    ).concat(
      transformEnrichData({ dataStream, range }), // we make sure to fill holes
      transformToTimeSeriesResultWithMetaData({ dataStream, assetId, modelId }), // wrap time series in envelope with meta data
    )

    return isCompactedForecast
      ? axios.get<TimeSeriesResult>(`/api/v1/ts/${assetId}/forecasts/${dataStream.id}/compacted`, {
          params: {
            start,
            end,
            unit,
          },
          transformResponse,
        })
      : axios
          .get<TimeSeriesResult>(
            `/api/${isCompactedTimeSeries ? 'v1' : 'v2'}/ts/${assetId}/${seriesType}/${valueType}/${start}/${end}${
              isCompactedTimeSeries ? '/compacted' : ''
            }${matrixParams.join('')}`,
            {
              params: {
                offset,
                duration,
                offsetType,
                offsetTimeZone,
                // there is DISCRETE and BLEND
                // DISCRETE takes only the last time series track and might miss data at the start
                // BLEND takes all time series in the specified range to not miss data but is much slower
                loadOption: 'DISCRETE',
                // new way of getting fast time series results
                // replaces old "compacted" and is able to be used
                // for arbitrary combination of params
                cacheReq: true,
                // initial cache request: do not wait for response
                // instead return 202 (Accepted) immediately similar
                // to subsequent calls
                cacheWaitInitialReq: false,
                unit,
              },
              transformResponse,
            },
          )
          .then((response) => {
            // in case of 202 we want to retry the call after some time
            if (response.status === 202) {
              // TODO 202
              // if (response.status === 202 || dataStream.type === TimeSeriesType.SITE_FORECAST) {
              response.data.cacheIsStillBeingBuilt = true
            }
            return response
          })
  })
    .catch((error) => {
      throw transformToTimeSeriesResultWithMetaData({ dataStream, assetId })(error)
    })
    .then((timeSeriesResult) => {
      // in case of 202 we want to retry the call after some time
      const promise = new Promise<TimeSeriesResult>((resolve, reject) => {
        if (timeSeriesResult.cacheIsStillBeingBuilt) {
          reject(timeSeriesResult)
        } else {
          resolve(timeSeriesResult)
        }
      })
      return promise
    })
}

/**
 * API method to fetch Schedule series data from backend
 */
const getScheduleTimeSeriesData = async ({ dataStream, asset, range, forecastConfigs, modelId }: GetTimeSeriesData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const unit = AppUnits.KILO_WATT
  const assetId = asset.id
  const seriesType = getTimeSeriesSeriesType(dataStream)
  const valueType = getTimeSeriesValueType(dataStream)

  const matrixParams: string[] = []
  matrixParams.push(`;PRODUCT_CONFIG_ID=${dataStream.id}`)

  // perform actual api request with proper transformations
  return apiRequest(() => {
    const transformResponse = (Array.isArray(axios.defaults.transformResponse)
      ? axios.defaults.transformResponse // default transform of axios parses JSON
      : []
    ).concat(
      transformEnrichData({ dataStream, range }), // we make sure to fill holes
      transformToTimeSeriesResultWithMetaData({ dataStream, assetId, modelId }), // wrap time series in envelope with meta data
    )

    return axios
      .get<TimeSeriesResult>(
        `/api/v2/ts/${assetId}/${seriesType}/${valueType}/${start}/${end}${matrixParams.join('')}`,
        {
          params: {
            // // there is DISCRETE and BLEND
            // // DISCRETE takes only the last time series track and might miss data at the start
            // // BLEND takes all time series in the specified range to not miss data but is much slower
            // loadOption: 'DISCRETE',
            // // new way of getting fast time series results
            // // replaces old "compacted" and is able to be used
            // // for arbitrary combination of params
            // cacheReq: true,
            // // initial cache request: do not wait for response
            // // instead return 202 (Accepted) immediately similar
            // // to subsequent calls
            // cacheWaitInitialReq: false,
            unit,
          },
          transformResponse,
        },
      )
      .then((response) => {
        // in case of 202 we want to retry the call after some time
        if (response.status === 202) {
          // TODO 202
          // if (response.status === 202 || dataStream.type === TimeSeriesType.SITE_FORECAST) {
          response.data.cacheIsStillBeingBuilt = true
        }
        return response
      })
  })
    .catch((error) => {
      throw transformToTimeSeriesResultWithMetaData({ dataStream, assetId })(error)
    })
    .then((timeSeriesResult) => {
      // in case of 202 we want to retry the call after some time
      const promise = new Promise<TimeSeriesResult>((resolve, reject) => {
        if (timeSeriesResult.cacheIsStillBeingBuilt) {
          reject(timeSeriesResult)
        } else {
          resolve(timeSeriesResult)
        }
      })
      return promise
    })
}

/**
 * API method to fetch capacity series data from backend
 */
interface GetCapacitySeriesData {
  dataStream: DataStreamSelectionItem
  asset: Asset
  range: DateRange
}
const getCapacitySeriesData = async ({ dataStream, asset, range }: GetCapacitySeriesData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const assetId = asset.id

  return apiRequest(() => {
    return axios.get<TimeSeriesResult>(`/api/v1/capacities/${assetId}/${start}/${end}`, {
      transformResponse: (Array.isArray(axios.defaults.transformResponse)
        ? axios.defaults.transformResponse // default transform of axios parses JSON
        : []
      ).concat(
        transformChooseSubResult({ dataStream }), // capacity streams need end value to draw a chart line
        transformAddValueAtEnd({ dataStream, range }), // capacity streams need end value to draw a chart line
        transformConvertUnit({ dataStream }), // we need to convert W to kW
        transformEnrichData({ dataStream }), // we make sure to fill holes
        transformToTimeSeriesResultWithMetaData({ dataStream, assetId }), // wrap time series in envelope with meta data
      ),
    })
  }).catch((error) => {
    throw transformToTimeSeriesResultWithMetaData({ dataStream, assetId })(error)
  })
}

/**
 * API method to fetch capacity series data from backend
 */
interface GetWeatherSeriesData {
  dataStream: DataStreamSelectionItem
  asset: Asset
  allAssets: Asset[]
  range: DateRange
}
const getWeatherSeriesData = async ({ dataStream, asset, allAssets, range }: GetWeatherSeriesData) => {
  const start = new Date(range[0])
  const end = new Date(range[1])
  const startMillisUtc = getTime(start)
  const endMillisUtc = getTime(end)

  const coordinates: Coordinate | undefined =
    isGenerator(asset) || isParkWithNoPlants(asset)
      ? asset.location.coordinate
      : getDescendantCoordinates(asset, allAssets)

  return apiRequest(() => {
    return axios.post<TimeSeriesResult>(
      `/api/weather/byCoordinates`,
      {
        coordinates: [coordinates],
        // note: it's also possible to fetch multiple weather ids at once
        // but that would not be very practical with react-query
        params: [
          {
            param: dataStream.id,
          },
        ],
        startMillisUtc,
        endMillisUtc,
        // TODO dynamic timezone
        timeZone: 'Europe/Berlin',
      },
      {
        transformResponse: (Array.isArray(axios.defaults.transformResponse)
          ? axios.defaults.transformResponse // default transform of axios parses JSON
          : []
        ).concat(
          transformWeatherResponse({ dataStream }), // weather endpoint has completely different result format
          transformEnrichData({ dataStream }), // we make sure to fill holes
          transformToTimeSeriesResultWithMetaData({ dataStream }), // wrap time series in envelope with meta data
        ),
      },
    )
  }).catch((error) => {
    throw transformToTimeSeriesResultWithMetaData({ dataStream })(error)
  })
}

/**
 * API method to fetch capacity series data from backend
 */
interface GetAreaSeriesData {
  dataStream: DataStreamSelectionItem
  range: DateRange
}
const getAreaSeriesData = async ({ dataStream, range }: GetAreaSeriesData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const unit = AppUnits.MEGA_WATT
  return apiRequest(() => {
    return axios.get<TimeSeriesResult>(`/api/v1/pfa/${dataStream.id}/start/${start}/end/${end}/compacted`, {
      params: {
        loadOption: 'DISCRETE',
        unit,
      },
      transformResponse: (Array.isArray(axios.defaults.transformResponse)
        ? axios.defaults.transformResponse // default transform of axios parses JSON
        : []
      ).concat(
        transformEnrichData({ dataStream }), // we make sure to fill holes
        transformToTimeSeriesResultWithMetaData({ dataStream }), // wrap time series in envelope with meta data
      ),
    })
  }).catch((error) => {
    throw transformToTimeSeriesResultWithMetaData({ dataStream })(error)
  })
}

/**
 * API method to fetch capacity series data from backend
 */
interface GetPriceSeriesData {
  dataStream: DataStreamSelectionItem
  range: DateRange
  timezone?: Timezone
}

const getPriceTimeseriesData = async ({ dataStream, range, timezone }: GetPriceSeriesData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const getBaseUrl = getMarketPriceDataBaseUrl(dataStream)
  const priceUrl = `${getBaseUrl}${start}/${end}`

  const loadOptions =
    dataStream?.classifier === TimeSeriesClassifier.GERMANY_REBAP ||
    dataStream?.classifier === TimeSeriesClassifier.GERMANY_ESTIMATED_REBAP
      ? {}
      : {
          loadOption: 'DISCRETE',
        }

  return apiRequest(() => {
    return axios.get<TimeSeriesResult>(priceUrl, {
      params: loadOptions,
      transformResponse: (Array.isArray(axios.defaults.transformResponse)
        ? axios.defaults.transformResponse // default transform of axios parses JSON
        : []
      ).concat(
        transformEnrichData({ dataStream, range, timezone }), // we make sure to fill holes
        transformToTimeSeriesResultWithMetaData({ dataStream }), // wrap time series in envelope with meta data
      ),
    })
  }).catch((error) => {
    throw transformToTimeSeriesResultWithMetaData({ dataStream })(error)
  })
}

interface GetBackCastSeriesData {
  dataStream: DataStreamSelectionItem
  range: DateRange
  assetId: string
  id: string
  modelId: string | null
}

const getBackCastTimeSeriesData = async ({ dataStream, range, assetId, id, modelId }: GetBackCastSeriesData) => {
  const start = format(new Date(range[0]), timeseriesDatetimeFormat)
  const end = format(new Date(range[1]), timeseriesDatetimeFormat)
  const unit = AppUnits.KILO_WATT
  let url = ''
  if (modelId) {
    url = `/api/backcast/v1/load/forecast-model/${modelId}/forecast-configuration/${dataStream?.id}/start/${start}/end/${end}`
  } else {
    url = `/api/backcast/v1/load/asset/${assetId}/forecast-configuration/${dataStream?.id}/start/${start}/end/${end}`
  }

  return apiRequest(() => {
    return axios.get<TimeSeriesResult>(url, {
      params: {
        unit,
      },
      transformResponse: (Array.isArray(axios.defaults.transformResponse)
        ? axios.defaults.transformResponse // default transform of axios parses JSON
        : []
      ).concat(
        transformEnrichData({ dataStream }), // we make sure to fill holes
        transformToTimeSeriesResultWithMetaData({
          dataStream,
          timeSeriesType: TimeSeriesType.BACK_CAST,
          assetId,
          id,
          range,
        }), // wrap time series in envelope with meta data
      ),
    })
  }).catch((error) => {
    throw transformToTimeSeriesResultWithMetaData({
      dataStream,
      timeSeriesType: TimeSeriesType.BACK_CAST,
      assetId,
      id,
      range,
    })(error)
  })
}

/**
 * query options for react-query to fetch one specific time series
 */
interface GetBackCastQueryOptions {
  dataStream: DataStreamSelectionItem
  assetId: string
  id: string
  range: DateRange
  modelId: string | null
}
const getBackCastTimeSeriesQueryOptions = ({ dataStream, range, assetId, id, modelId }: GetBackCastQueryOptions) => {
  if (!dataStream) return {}
  const queryFn = () => getBackCastTimeSeriesData({ dataStream, range, assetId, id, modelId })

  const modifiedTimeSeriesDefaultQueryOptions = timeSeriesDefaultQueryOptions
  // Do not cache the Backcast timeseries for assets
  if (assetId && !modelId) {
    modifiedTimeSeriesDefaultQueryOptions['cacheTime'] = 0
    modifiedTimeSeriesDefaultQueryOptions['staleTime'] = 0
  }

  const queryOptions: UseQueryOptions<TimeSeriesResult, TimeSeriesResult> = {
    queryKey: [
      QUERY_KEY_BACK_CAST_TIMESERIES,
      dataStream.type,
      dataStream.subType,
      dataStream.classifier,
      dataStream.id,
      assetId,
      modelId,
      range,
    ],
    queryFn,
    ...modifiedTimeSeriesDefaultQueryOptions,
  }

  return queryOptions
}

// Hooks to fetch and update via react-query

interface GetApiQueryMethod {
  dataStream: DataStreamSelectionItem
}

export const getApiQueryMethod = ({ dataStream }: GetApiQueryMethod) => {
  switch (dataStream.type) {
    case TimeSeriesType.SITE_FORECAST:
    case TimeSeriesType.METER_DATA:
      return getTimeSeriesData
    case TimeSeriesType.SCHEDULE:
      return getScheduleTimeSeriesData
    case TimeSeriesType.META_FORECAST:
      if (dataStream.subType === TimeSeriesSubType.META_ENSEMBLE) {
        return getMetaForecastTimeseriesUsingEnsembleWeights
      } else {
        return getTimeSeriesData
      }
    case TimeSeriesType.E3_META_FORECAST:
      return getTimeSeriesData
    case TimeSeriesType.CAPACITY_DATA:
      if (
        dataStream.subType === TimeSeriesSubType.PLANNED_AVAILABLE_CAPACITY ||
        dataStream.subType === TimeSeriesSubType.INSTALLED_CAPACITY
      ) {
        return getCapacitySeriesData
      } else {
        return getTimeSeriesData
      }
    case TimeSeriesType.WEATHER_DATA:
      return getWeatherSeriesData
    case TimeSeriesType.AREA_FORECAST:
      return getAreaSeriesData
    case TimeSeriesType.MARKET_PRICE_DATA:
      return getPriceTimeseriesData
    default:
      // TODO this is a fallback for wrong configurations that returns nothing
      //  this should never be called
      return () => new Promise<TimeSeriesResult>((resolve) => resolve())
  }
}

/**
 * query options for react-query to fetch one specific time series
 */
interface GetQueryOptions {
  dataStream: DataStreamSelectionItem
  asset?: Asset
  allAssets: Asset[]
  range: DateRange
  forecastConfigs: ForecastConfig[]
  modelId?: string
  e3Ensemble?: E3Ensemble
  timezone?: Timezone
}
const getTimeSeriesQueryOptions = ({
  dataStream,
  asset,
  allAssets,
  range,
  forecastConfigs,
  modelId,
  e3Ensemble = {},
  timezone,
}: GetQueryOptions) => {
  if (!forecastConfigs.length) return {}
  const apiQueryMethod = getApiQueryMethod({ dataStream })

  const queryFn = () =>
    apiQueryMethod({
      dataStream,
      asset,
      allAssets,
      range,
      forecastConfigs,
      e3Ensemble,
      timezone,
      modelId,
    })

  const queryOptions: UseQueryOptions<TimeSeriesResult, TimeSeriesResult> = {
    queryKey: [
      QUERY_KEY_TIMESERIES,
      asset?.id,
      dataStream.type,
      dataStream.subType,
      dataStream.classifier,
      dataStream.id,
      range,
      modelId,
      isMetaForecastDataStream(dataStream) ? e3Ensemble : null,
    ],
    queryFn,
    ...timeSeriesDefaultQueryOptions,
  }

  // e3 meta forecast is calculated in frontend,
  // therefore we need to wait for all required to be ready
  // before starting to process the data
  if (dataStream.type === TimeSeriesType.E3_META_FORECAST && dataStream.subType == TimeSeriesSubType.META_ENSEMBLE) {
    const e3DataIsComplete = Object.keys(e3Ensemble).every((e3Model) => {
      const e3TimeSeriesWeatherTrack = e3TimeSeriesWeatherTracks.find((weatherTrack) => {
        return weatherTrack.dataStreamId === e3Model
      })

      const existsAsSliderControl = Boolean(e3TimeSeriesWeatherTrack)
      const hasData = Object.keys(e3TimeSeriesWeatherTrack?.data || {}).length > 0
      return !existsAsSliderControl || hasData
    })

    // tell react-query to start "fetching" meta data stream as soon as we have the source data
    queryOptions.enabled = e3DataIsComplete
  }

  return queryOptions
}

/**
 * hook to fetch time series data for specified data streams
 */
interface UseTimeSeriesQueryResultsForSelection {
  dataSelection: DataStreamSelection
}
export const useTimeSeriesQueryResultsForSelection = ({ dataSelection }: UseTimeSeriesQueryResultsForSelection) => {
  const chartDataRange = useSelector(workspaceDraftChartDataRangeSelector)
  const timezone = useSelector(getUserTimezoneSelector)
  const range = getInclusiveDateRangeFromChartDataRange(chartDataRange, timezone)
  const forecastConfigs = useSiteForecastConfigs().data || []
  const allAssets = useUniqueAllAssets()
  const selectedAssets = useUniqueSelectedAssets()
  const selectedModels = useSelector(workspaceDraftSelectedModelsSelector)
  const lineChartSettings = useLineChartSettings()
  const user = useSelector(getUserResultSelector)
  const hasAccessToBackCast = hasPermissionForSiteAssessmentBackcast(user)
  const hasAccessToThirdParty = hasPermissionTo3rdPartyForecasts(user)

  // For back cast
  const assetAndModelSelection = useSelector(workspaceDraftAssetSelectionSelector)
  const selectedAssetAndModelIds = assetAndModelSelection?.filter((id) => Boolean(id))

  // Ensembles from third party datastreams
  const metaForecastWidgetDataInDraft = useSelector(workspaceDraftE3WidgetSettingsSelector)
  const activeMetaWidget = metaForecastWidgetDataInDraft.activeWidget

  const e3Ensembles =
    activeMetaWidget === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST && hasPermissionTo3rdPartyForecasts(user)
      ? metaForecastWidgetDataInDraft.thirdPartySliders
      : metaForecastWidgetDataInDraft.weatherModelSliders

  return useQueries(
    dataSelection.flatMap((dataStream) => {
      const { AREA_FORECAST, MARKET_PRICE_DATA } = TimeSeriesType
      if (dataStream.type === AREA_FORECAST || dataStream.type === MARKET_PRICE_DATA) {
        return [
          getTimeSeriesQueryOptions({
            dataStream,
            allAssets,
            range,
            forecastConfigs,
            timezone,
          }),
        ]
      } else {
        // Fetch back cast
        let queryOptionsForBackCast: (UseQueryOptions<
          TimeSeriesResult,
          TimeSeriesResult,
          TimeSeriesResult,
          QueryKey
        > | null)[] = []
        if (
          lineChartSettings?.data?.showBackCast &&
          hasAccessToBackCast &&
          dataStream.type === TimeSeriesType.SITE_FORECAST
        ) {
          queryOptionsForBackCast = selectedAssetAndModelIds
            ?.map((id: string) => {
              if (id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)) {
                // Backcast for models
                const assetId = getBackCastModelDataFromId(id).assetId
                const modelId = getBackCastModelDataFromId(id).modelId
                return getBackCastTimeSeriesQueryOptions({ dataStream, range, id, assetId, modelId })
              } else {
                // Asset should fetch backcast only for site forecasts because for others it will use the below function
                if (dataStream.type !== TimeSeriesType.SITE_FORECAST) {
                  return null
                } else {
                  // Backcast for assets
                  return getBackCastTimeSeriesQueryOptions({
                    dataStream,
                    range,
                    id,
                    assetId: id,
                    modelId: null,
                  })
                }
              }
            })
            .filter((queryOption) => Boolean(queryOption))
        }

        // console.log({ userModifiedE3EnsembleWeights, e3EnsembleWeights, chartSavedThirdPartyEnsembles })

        // Fetch forecast
        const queryOptionsForAssets = selectedAssets.map((asset) => {
          return getTimeSeriesQueryOptions({
            dataStream,
            asset,
            allAssets,
            range,
            forecastConfigs,
            e3Ensemble: e3Ensembles,
          })
        })

        // Fetch Interactive Meta forecast for Third party Models (Meta models)
        if (
          hasAccessToThirdParty &&
          (dataStream.subType === TimeSeriesSubType.META_ENSEMBLE ||
            dataStream.subType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST)
        ) {
          // console.log('selectedModels =', selectedModels)
          const selectedModelIds = selectedAssetAndModelIds.filter((id) => id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID))
          selectedModelIds.forEach((id) => {
            const assetId = getBackCastModelDataFromId(id).assetId
            const asset = allAssets.find((a) => a.id === assetId)
            const modelId = getBackCastModelDataFromId(id).modelId
            const thirdPartyModel = selectedModels.find((model) => modelId === model.uuid && isMetaForecastModel(model))

            if (thirdPartyModel) {
              queryOptionsForAssets.push(
                getTimeSeriesQueryOptions({
                  dataStream,
                  asset,
                  allAssets,
                  range,
                  forecastConfigs,
                  e3Ensemble: e3Ensembles,
                  modelId,
                }),
              )
            }
          })
        }

        return [...queryOptionsForAssets, ...queryOptionsForBackCast].filter(
          (queryOption) => Boolean(queryOption) && Object.keys(queryOption).length > 0,
        ) as QueryObserverOptions<unknown>
      }
    }),
  ) as QueryObserverResult<TimeSeriesResult, TimeSeriesResult>[]
}

/**
 * hook to fetch time series data for all selected assets and data streams
 */
interface UseTimeSeriesQueryResults {
  filterTypes?: TimeSeriesType[]
  filterSubTypes?: TimeSeriesSubType[]
  filterClassifiers?: TimeSeriesClassifier[]
}
export const useTimeSeriesQueryResults = ({
  filterTypes,
  filterSubTypes,
  filterClassifiers,
}: UseTimeSeriesQueryResults = {}) => {
  // data streams
  const dataStreams = useDataStreams()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  // if filters are specified, only fetch data that we want to have
  const filteredDataSelection = useMemo(() => {
    return dataSelection.filter((dataStream) => {
      const isNotGermanyRevenue = !dataStream.classifier?.includes(GERMANY_REVENUE_SEARCH_KEY)
      const isNotSeasonalForecast = dataStream.type !== TimeSeriesType.SEASONAL_FORECAST
      const isNotClimatology = dataStream.type !== TimeSeriesType.CLIMATOLOGY
      const isItemPresentInDataStreams = dataStreams.some((d) => d.id === dataStream?.id)
      const isTypeMatch = !filterTypes || filterTypes.includes(dataStream.type)
      const isSubTypeMatch = !filterSubTypes || !dataStream.subType || filterSubTypes.includes(dataStream.subType)
      const isClassifierMatch =
        !filterClassifiers || !dataStream.classifier || filterClassifiers.includes(dataStream.classifier)

      return (
        isTypeMatch &&
        isSubTypeMatch &&
        isClassifierMatch &&
        isItemPresentInDataStreams &&
        isNotClimatology &&
        isNotSeasonalForecast &&
        isNotGermanyRevenue
      )
    })
  }, [dataSelection, dataStreams])

  // console.log({ filteredDataSelection, dataSelection })

  // get results for all selected data streams
  return useTimeSeriesQueryResultsForSelection({ dataSelection: filteredDataSelection })
}
