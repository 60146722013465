import { request } from 'utils/request'
import axios from 'axios'
import { AreaConfig } from 'modules/dataStreams/dataStreams.types'

export const getSubscribedProductCodes = () => {
  return request(() => axios.get<string[]>('/api/v1/subscription/codes'))
}

export const saveAreaForecasts = (areaForecasts: AreaConfig[]) => {
  return request(() => axios.post<AreaConfig[]>('/api/usersettings/save/areaconfig', areaForecasts))
}
