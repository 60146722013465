import createReducer from 'utils/createReducer'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { AppBanner, SessionActions, SessionStatus } from 'modules/app/app.types'

export const AppBannerOrder = [AppBanner.SESSION_OFFLINE, AppBanner.SESSION_EXPIRED, AppBanner.TIMEZONE]

interface State {
  appBanners: AppBanner[]
  sessionStatus: SessionStatus
  sessionStatusChangedDate: Date
  sessionRecovering: boolean
  initiateReload: boolean
  loading: boolean
  // This variable only used to show the Action failed dialog.
  // Scenario: User is offline/session expired, banner did not shown yet and
  // he tried call an API or Navigate to different page which will fail because of no connection.
  // Then we show this action failed dialog.
  actionFailed: boolean
}

const initialState: State = {
  appBanners: [],
  sessionStatus: SessionStatus.EXPIRED,
  sessionStatusChangedDate: new Date(),
  sessionRecovering: false,
  initiateReload: false,
  actionFailed: false,
  loading: false,
}

export interface AppBannerAction {
  type: typeof SessionActions.ADD_APP_BANNER | typeof SessionActions.REMOVE_APP_BANNER
  appBanner: AppBanner
}

const appBanners = createReducer<State['appBanners'], AppBannerAction>(
  (state = initialState.appBanners, { type, appBanner }) => {
    if (type === SessionActions.ADD_APP_BANNER) {
      return state.includes(appBanner) ? state : [...state, appBanner]
    }
    if (type === SessionActions.REMOVE_APP_BANNER) {
      return state.filter((banner) => banner !== appBanner)
    }
    return state
  },
)

export interface SessionStatusAction {
  type: typeof SessionActions.SET_SESSION_TYPE
  sessionStatus: SessionStatus
}

const sessionStatus = createReducer<State['sessionStatus'], SessionStatusAction>(
  (state = initialState.sessionStatus, { type, sessionStatus }) => {
    if (type === SessionActions.SET_SESSION_TYPE) {
      return sessionStatus
    }
    return state
  },
)

const sessionStatusChangedDate = createReducer<State['sessionStatusChangedDate'], SessionStatusAction>(
  (state = initialState.sessionStatusChangedDate, { type }) => {
    if (type === SessionActions.SET_SESSION_TYPE) {
      return new Date()
    }
    return state
  },
)

export interface SessionRecoveringAction {
  type: typeof SessionActions.SET_SESSION_TYPE | typeof SessionActions.SET_SESSION_RECOVERING
  sessionRecovering: boolean
}

const sessionRecovering = createReducer<State['sessionRecovering'], SessionRecoveringAction>(
  (state = initialState.sessionRecovering, { type, sessionRecovering }) => {
    if (type === SessionActions.SET_SESSION_RECOVERING) {
      return sessionRecovering
    }
    return state
  },
)

export interface InitiateReloadAction {
  type: typeof SessionActions.INITIATE_RELOAD
  initiateReload: boolean
}

const initiateReload = createReducer<State['initiateReload'], InitiateReloadAction>(
  (state = initialState.initiateReload, { type, initiateReload }) => {
    if (type === SessionActions.INITIATE_RELOAD) {
      return initiateReload
    }
    return state
  },
)

export interface actionFailedAction {
  type: typeof SessionActions.SET_ACTION_FAILED
  actionFailed: boolean
}

const actionFailed = createReducer<State['actionFailed'], actionFailedAction>(
  (state = initialState.actionFailed, { type, actionFailed }) => {
    if (type === SessionActions.SET_ACTION_FAILED) {
      return actionFailed
    }
    return state
  },
)

export interface sessionLoadingAction {
  type: typeof SessionActions.SET_SESSION_LOADING
  loading: boolean
}

const loading = createReducer<State['loading'], sessionLoadingAction>(
  (state = initialState.loading, { type, loading }) => {
    if (type === SessionActions.SET_SESSION_LOADING) {
      return loading
    }
    return state
  },
)

export const sessionReducer = combineReducers({
  appBanners,
  sessionStatus,
  sessionStatusChangedDate,
  sessionRecovering,
  initiateReload,
  actionFailed,
  loading,
})

export const appBannersSelector = createSelector<any, State['appBanners'], State['appBanners']>(
  (state) => state.session.appBanners,
  (appBanners) => appBanners,
)

export const appBannerSelector = createSelector<any, State['appBanners'], AppBanner | undefined>(
  (state) => state.session.appBanners,
  (appBanners) => {
    return AppBannerOrder.find((appBanner) => appBanners.includes(appBanner))
  },
)

export const sessionStatusSelector = createSelector<any, State['sessionStatus'], State['sessionStatus']>(
  (state) => state.session.sessionStatus,
  (sessionStatus) => sessionStatus,
)

export const sessionStatusChangedDateSelector = createSelector<
  any,
  State['sessionStatusChangedDate'],
  State['sessionStatusChangedDate']
>(
  (state) => state.session.sessionStatusChangedDate,
  (sessionStatusChangedDate) => sessionStatusChangedDate,
)

export const sessionRecoveringSelector = createSelector<any, State['sessionRecovering'], State['sessionRecovering']>(
  (state) => state.session.sessionRecovering,
  (sessionRecovering) => sessionRecovering,
)

export const initiateReloadSelector = createSelector<any, State['initiateReload'], State['initiateReload']>(
  (state) => state.session.initiateReload,
  (initiateReload) => initiateReload,
)

export const actionFailedSelector = createSelector<any, State['actionFailed'], State['actionFailed']>(
  (state) => state.session.actionFailed,
  (actionFailed) => actionFailed,
)

export const sessionLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.session.loading,
  (loading) => loading,
)
