import React from 'react'
import styled from 'styled-components'
import { formatNumber, isNumeric } from 'utils/dataFormatting'
import Highlight from 'ui/Highlight'
import Flex from 'ui/styles/Flex'

const WholeNumber = styled.div`
  width: auto;
`

interface FractionalNumberProps {
  limit?: number
}
const FractionalNumber = styled.div<FractionalNumberProps>`
  /* we need to add 0.5ch width for decimal point */
  width: ${(props) => (props.limit ? `${props.limit + 0.5}ch` : '4.5ch')};
  text-align: left;
`
interface UnitProps {
  width?: number
  unit?: string
}
const Unit = styled.div<UnitProps>`
  width: ${(props) => (props.width ? `${props.width}px` : 'fit-content')};
  margin-left: ${(props) => (props.unit == '%' ? 0 : '2px')};
  text-align: left;
`

interface NumberProps {
  data: number | null
  showFractionalPart?: boolean
  forceFractionalPart?: boolean
  unit?: string
  separator?: boolean
  limit?: number
  highlight?: string
  unitWidth?: number
  addColor?: boolean
}

interface ContainerProps {
  addColor?: boolean
}
const Container = styled.div<ContainerProps>`
  display: inline-block;
  color: ${(props) => props.addColor && 'red'};
`

const Number: React.FC<NumberProps> = ({
  data,
  showFractionalPart = true,
  forceFractionalPart = false,
  unit = '',
  separator = true,
  limit,
  highlight = '',
  unitWidth,
  ...rest
}) => {
  const alignNumber = () => {
    if (isNumeric(data)) {
      const number = formatNumber({ data, showFractionalPart, forceFractionalPart, separator, limit })

      const splitNumberByDecimal = number ? number.toString().split('.') : []
      const splitHighlightByDecimal = highlight ? highlight.split('.') : []

      const wholeNumPart = splitNumberByDecimal.length ? splitNumberByDecimal[0] : number

      let wholeHighlightPart = splitHighlightByDecimal.length ? splitHighlightByDecimal[0] : highlight

      let fractionalNumPart = null
      let fractionalHighlightPart = null
      let unitHighlightPart = highlight

      if (splitNumberByDecimal.length > 1 && isNumeric(parseInt(splitNumberByDecimal[1]))) {
        fractionalNumPart = `.${splitNumberByDecimal[1]}`
      }
      if (splitHighlightByDecimal.length > 1 && isNumeric(parseInt(splitHighlightByDecimal[1]))) {
        const parsedFractionVal = parseInt(splitHighlightByDecimal[1])
        fractionalHighlightPart = `.${parsedFractionVal === 0 ? '00' : parsedFractionVal}`
        unitHighlightPart = highlight.replace(`${wholeNumPart}${fractionalNumPart}`, '')
      }

      // When there is no decimal point in the highlight
      if (splitHighlightByDecimal.length === 1 && highlight) {
        const extractNum = highlight.replace(/[^0-9]/gi, '')
        wholeHighlightPart = extractNum
        fractionalHighlightPart = extractNum
        unitHighlightPart = extractNum ? highlight.replace(wholeHighlightPart, '') : highlight
      }

      return (
        <Flex justifyContent="flex-end">
          <WholeNumber>
            {wholeNumPart && isNumeric(parseInt(wholeNumPart.toString())) && (
              <Highlight text={wholeNumPart.toString()} query={wholeHighlightPart} hasSeparator={separator} />
            )}
          </WholeNumber>
          {showFractionalPart && (
            <FractionalNumber limit={limit}>
              {fractionalNumPart && <Highlight text={fractionalNumPart} query={fractionalHighlightPart || ''} />}
            </FractionalNumber>
          )}
          {unit && (
            <Unit unit={unit} width={unitWidth}>
              <Highlight text={unit} query={unitHighlightPart} />
            </Unit>
          )}
        </Flex>
      )
    } else {
      return <></>
    }
  }

  return <Container {...rest}>{alignNumber()}</Container>
}

export default React.memo(Number)
