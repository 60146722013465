import React from 'react'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import ReTableTextCell from 'modules/reTable/ReTableInputFields/ReTableTextCell'
import Flex from 'ui/styles/Flex'
import { isNumeric } from 'utils/dataFormatting'
import Number from 'ui/Number'
import { AppUnits } from 'utils/units'
import { Column } from 'modules/reTable/reTable.types'
import { getE3ModelLabel } from 'utils/e3'
import { getPrimaryQualityLabel, QualityPreviewErrors } from 'utils/quality'
import { ForecastConfig, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { FORECAST_MODEL_FOR_BACK_CAST_ID } from 'modules/asset/store/asset.types'
import { getBackCastModelDataFromId } from 'utils/forecastModel'
import styled from 'styled-components'

interface IntentedProps {
  level: number
}
const Indented = styled.div<IntentedProps>`
  margin-left: ${(props) => props.level * 1.4}em;
`

interface QualityPreviewTableRowProps {
  qualityValues: QualityPreviewErrors
  columns: Column[]
  forecastConfigs: ForecastConfig[]
  loading: boolean
}

const QualityPreviewTableRow: React.FC<QualityPreviewTableRowProps> = ({
  qualityValues,
  columns,
  forecastConfigs,
  loading,
}) => {
  const key = `${qualityValues.assetId}_${qualityValues.model}-${qualityValues?.correlationSetType}`

  const label =
    getE3ModelLabel(qualityValues.model) ||
    getPrimaryQualityLabel(qualityValues.model, forecastConfigs.data || [], false) ||
    qualityValues.model

  const isBackCastItem = qualityValues?.correlationSetType === TimeSeriesType.BACK_CAST

  let labelPrefix = ''
  if (isBackCastItem) {
    if (qualityValues?.assetId?.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)) {
      const backCastModelId = qualityValues.assetId.replace('BACK_CAST', '')
      labelPrefix = `${getBackCastModelDataFromId(backCastModelId).name} - `
    }
  }

  const getCellContent = (col: Column) => {
    const unNormalizedCells = ['RMSE', 'MAE']
    const multiplier = unNormalizedCells.includes(col.name) ? 1 : 100
    const unit = unNormalizedCells.includes(col.name) ? AppUnits.KILO_WATT : AppUnits.PERCENTAGE
    return (
      <Flex justifyContent="flex-end" alignItems="flex-end" flexWrap="nowrap">
        {isNumeric(qualityValues[col.name]) && !loading ? (
          <Number data={qualityValues[col.name] * multiplier} unit={unit} limit={3} showFractionalPart />
        ) : (
          <span>-</span>
        )}
      </Flex>
    )
  }

  return (
    <ReTableRow key={key}>
      {columns.map((col, index) => {
        if (col.name === 'siteForecast') {
          return (
            <ReTableCell key={col.name} colSpan={2}>
              <Indented level={1}>
                <ReTableTextCell value={`${labelPrefix + label} ${isBackCastItem ? ' [Backcast]' : ''}`} />
              </Indented>
            </ReTableCell>
          )
        } else {
          return (
            <ReTableCell key={col.name}>
              <div style={{ maxWidth: index === columns.length - 1 ? col.width : undefined }}>
                {getCellContent(col)}
              </div>
            </ReTableCell>
          )
        }
      })}
    </ReTableRow>
  )
}

export default QualityPreviewTableRow
