import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { User } from 'modules/auth/Auth.types'
import createReducer from 'utils/createReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import { Timezone } from 'fixtures/timezones'
import { isCompanySubAccount } from 'utils/user'

// state

interface State {
  result: User | null
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  result: null,
  loading: false,
  error: null,
  initialized: false,
}

interface GetUserAction {
  type: actionTypes.GET_USER_ACTION_TYPE | actionTypes.SAVE_USER_ACTION_TYPE
  // SUCCESS
  user: State['result']
  // FAILURE
  error: State['error']
}

// reducers

const result = createReducer<State['result'], GetUserAction>((state = initialState.result, { type, user }) => {
  switch (type) {
    case actionTypes.GET_USER_SUCCESS:
    case actionTypes.SAVE_USER_SUCCESS:
      return user
    case actionTypes.GET_USER_FAILURE:
    case actionTypes.LOGOUT_SUCCESS:
      return null
  }
  return state
})

const loading = createReducer<State['loading'], GetUserAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case actionTypes.GET_USER_REQUEST:
      return true
    case actionTypes.GET_USER_SUCCESS:
    case actionTypes.GET_USER_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], GetUserAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case actionTypes.GET_USER_SUCCESS:
    case actionTypes.GET_USER_DISMISS:
      return null
    case actionTypes.GET_USER_FAILURE:
      return error
  }
  return state
})

const initialized = createReducer<State['initialized'], GetUserAction>((state = initialState.initialized, { type }) => {
  switch (type) {
    case actionTypes.GET_USER_SUCCESS:
    case actionTypes.GET_USER_FAILURE:
      return true
  }
  return state
})

export const getUserReducer = combineReducers({
  result,
  loading,
  error,
  initialized,
})

// selectors

export const getUserResultSelector = createSelector<any, State['result'], State['result']>(
  (state) => state.auth.getUser.result,
  (result) => result,
)
export const getUserTimezoneSelector = createSelector<any, Timezone, Timezone>(
  (state) => {
    const isSubCompanyAccount = isCompanySubAccount(state.auth.getUser.result)
    return isSubCompanyAccount ? state.auth.getUser.result?.originalUser?.timezone : state.auth.getUser.result?.timezone
  },
  (timezone) => timezone || 'UTC',
)
export const getUserHasTimezoneSelector = createSelector<any, State['result'], boolean>(getUserResultSelector, (user) =>
  Boolean(user?.timezone),
)
export const getUserLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.auth.getUser.loading,
  (loading) => loading,
)
export const getUserErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.auth.getUser.error,
  (error) => error,
)
export const getUserInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.auth.getUser.initialized,
  (initialized) => initialized,
)

interface AuthedSelector {
  (state: any): boolean
}
export const authedSelector: AuthedSelector = createSelector(
  (state) => state.auth.getUser.result,
  (result: User) => Boolean(result && result.login),
)
