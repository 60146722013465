// import React from 'react'
// import { TableHead } from '@material-ui/core'
//
// const ReTableHeader: React.FC = ({ children }) => {
//   return <TableHead>{children}</TableHead>
// }
//
// export default ReTableHeader

import { TableHead } from '@material-ui/core'
import styled from 'styled-components'

export const ReTableHeader = styled(TableHead)``
