import React, { useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import Flex from 'ui/styles/Flex'
import styled from 'styled-components'
import { ReTableContextMenuItem, ReTableContextMenuItemName } from 'modules/reTable/reTable.types'
import { isAdmin, isImpersonatedAdmin, useIsReadOnlyUser } from 'utils/user'
import { useCustomMuiStyles } from 'themes/theme-light'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

const MenuIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    font-size: 1em;
    padding: 5px 9px;
  }
`

interface ReTableContextMenuProps {
  showContextMenu: boolean
  menuItems: ReTableContextMenuItem[]
  onClickMenuItem: (name: ReTableContextMenuItemName | string) => void
}

const ReTableContextMenu: React.FC<ReTableContextMenuProps> = ({ showContextMenu, menuItems, onClickMenuItem }) => {
  const user = useSelector(getUserResultSelector)
  const classes = useCustomMuiStyles()
  const isReadOnlyUser = useIsReadOnlyUser()

  const [menuEl, setMenuEl] = React.useState<null | HTMLElement>(null)

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setMenuEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setMenuEl(null)
  }, [])

  const handleMenuItemClick = useCallback(
    (itemName: ReTableContextMenuItemName) => {
      onClickMenuItem(itemName)
      handleClose()
    },
    [onClickMenuItem],
  )

  return (
    <Flex direction="column" alignItems="center">
      {showContextMenu && (
        <>
          <MenuIconButton size="small" aria-haspopup="true" onClick={handleMenuClick}>
            <FontAwesomeIcon aria-controls="simple-menu" aria-haspopup="true" icon="ellipsis-v" />
          </MenuIconButton>
          <Menu id="simple-menu" anchorEl={menuEl} open={Boolean(menuEl)} onClose={handleClose}>
            {menuItems.map((item) => (
              <MenuItem
                disabled={isReadOnlyUser || item?.disabled}
                key={item.itemName}
                onClick={() => handleMenuItemClick(item.itemName)}
                className={
                  item.userHasPermission
                    ? (isAdmin(user) || isImpersonatedAdmin(user)) && item.userHasPermission(user)
                      ? classes.adminComponent
                      : 'inherit'
                    : 'inherit'
                }
              >
                {item.icon && (
                  <ListItemIcon>
                    <FontAwesomeIcon icon={item.icon} />
                  </ListItemIcon>
                )}

                <ListItemText>{item.itemLabel}</ListItemText>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Flex>
  )
}

export default React.memo(ReTableContextMenu)
