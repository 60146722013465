import { Asset } from 'modules/asset/store/asset.types'
import { Column } from 'modules/reTable/reTable.types'
import React from 'react'
import Number from 'ui/Number'
import Flex from 'ui/styles/Flex'
import { getNomcap } from 'utils/asset'
import { formatIndianCurrency, formatNumber, isNumeric } from 'utils/dataFormatting'
import styled from 'styled-components'
import { PenaltyCalculationSummary } from 'modules/data/penalties/penaltyCalculations/penaltyCalculation.types'
import PenaltyCalculationsStatusCell from 'modules/data/penalties/penaltyCalculations/PenaltyCalculationsStatusCell'

interface PenaltyNumberCellProps {
  error?: number
}

const PenaltyNumberCell = styled(Flex)<PenaltyNumberCellProps>`
  color: ${(props) => (props?.error ? '#EB4D00' : 'inherit')};
`

interface PenaltyTableCellProps {
  penalty: PenaltyCalculationSummary
  column: Column
  asset: Asset
  onGetAssetNameCell: () => React.ReactNode
  showNumCapFraction: boolean
  resultErrorMsg: string | null
}

const PenaltyCalculationTableCell: React.FC<PenaltyTableCellProps> = ({
  penalty,
  column,
  asset,
  onGetAssetNameCell,
  showNumCapFraction,
  resultErrorMsg,
}) => {
  // OI and UI columns are shown if at least one asset from selected assets has different OI and UI bands
  // and do not display the values in OI and UI columns when the penalty has same bands
  const hasSymmetricalInjectionBands = penalty?.underInjectionPenaltyBands?.length === 0

  const {
    actualGeneration,
    overInjectionPenaltyCostNormalized,
    underInjectionPenaltyCostNormalized,
    overInjectionPenaltyCost,
    underInjectionPenaltyCost,
    realtimeDataCompleteness,
    revenueImpact,
    revenue,
  } = penalty

  switch (column.name) {
    case 'status':
      return <PenaltyCalculationsStatusCell resultErrorMsg={resultErrorMsg} penalty={penalty} />

    case 'assetId':
      return <Flex>{onGetAssetNameCell()} </Flex>

    case 'nameplateCapacity':
      return (
        <Flex justifyContent="flex-end" alignItems="flex-end">
          <Number showFractionalPart={showNumCapFraction} limit={3} separator={true} data={getNomcap(asset)} />
        </Flex>
      )

    case 'actualGeneration':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {isNumeric(actualGeneration) ? <Number showFractionalPart={false} data={actualGeneration} /> : <>-</>}
        </PenaltyNumberCell>
      )

    case 'overInjectionPenaltyCost':
      return (
        <PenaltyNumberCell
          // error={blockResultsWithIssues.length > 0 ? 1 : 0}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {hasSymmetricalInjectionBands ? (
            <>-</>
          ) : (
            isNumeric(overInjectionPenaltyCost) && <>{formatIndianCurrency(overInjectionPenaltyCost)}</>
          )}
        </PenaltyNumberCell>
      )

    case 'underInjectionPenaltyCost':
      return (
        <PenaltyNumberCell
          // error={blockResultsWithIssues.length > 0 ? 1 : 0}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {hasSymmetricalInjectionBands ? (
            <>-</>
          ) : (
            isNumeric(underInjectionPenaltyCost) && <>{formatIndianCurrency(underInjectionPenaltyCost)}</>
          )}
        </PenaltyNumberCell>
      )

    case 'totalPenaltyCost':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {isNumeric(overInjectionPenaltyCost) && isNumeric(underInjectionPenaltyCost) ? (
            <>{formatIndianCurrency(overInjectionPenaltyCost + underInjectionPenaltyCost)}</>
          ) : (
            <>-</>
          )}
        </PenaltyNumberCell>
      )

    case 'overInjectionPenaltyCostNormalized':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {hasSymmetricalInjectionBands ? (
            <>-</>
          ) : (
            isNumeric(overInjectionPenaltyCostNormalized) && (
              <Number limit={2} data={overInjectionPenaltyCostNormalized} />
            )
          )}
        </PenaltyNumberCell>
      )

    case 'underInjectionPenaltyCostNormalized':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {hasSymmetricalInjectionBands ? (
            <>-</>
          ) : (
            isNumeric(underInjectionPenaltyCostNormalized) && (
              <Number limit={2} data={underInjectionPenaltyCostNormalized} />
            )
          )}
        </PenaltyNumberCell>
      )

    case 'totalPenaltyCostNormalized':
      const totalDSMPenalty = overInjectionPenaltyCost + underInjectionPenaltyCost
      const totalPenaltyCostNormalized = (totalDSMPenalty * 100) / (actualGeneration || 0)
      const bothNotNull = overInjectionPenaltyCost !== null && underInjectionPenaltyCost !== null
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {bothNotNull ? <Number limit={2} data={totalPenaltyCostNormalized || 0} /> : <>-</>}
        </PenaltyNumberCell>
      )

    case 'revenue':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {isNumeric(revenue) ? <>{formatIndianCurrency(revenue)}</> : <>-</>}
        </PenaltyNumberCell>
      )

    case 'revenueImpact':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {isNumeric(revenueImpact) ? (
            <>{`${formatNumber({
              data: revenueImpact,
              showFractionalPart: true,
              forceFractionalPart: false,
              separator: true,
              limit: 2,
            })}%`}</>
          ) : (
            <>-</>
          )}
        </PenaltyNumberCell>
      )

    case 'realtimeDataCompleteness':
      return (
        <PenaltyNumberCell justifyContent="flex-end" alignItems="flex-end">
          {isNumeric(realtimeDataCompleteness) ? (
            <>{`${formatNumber({
              data: realtimeDataCompleteness,
              showFractionalPart: true,
              forceFractionalPart: true,
              separator: true,
              limit: 2,
            })}%`}</>
          ) : (
            <>-</>
          )}
        </PenaltyNumberCell>
      )
    default:
      return <></>
  }
}

export default React.memo(PenaltyCalculationTableCell)
