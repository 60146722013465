import React from 'react'
import { IconSelectedColor, SvgIconPath } from 'ui/styles/SvgIconPath'
import { AssetIconProps } from 'ui/icons/ClusterPoolIcon'
import { getTypeLabel } from 'utils/asset'
import { TYPE_WINDPLANT } from 'modules/asset/store/asset.types'

const WindPlantIcon: React.FC<AssetIconProps> = ({ iconSelected, size }) => {
  return (
    <svg viewBox="15 -500 10 1100" xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <title>{getTypeLabel(TYPE_WINDPLANT)}</title>
      <SvgIconPath
        transform="rotate(-180 253 230)"
        d="M868.503 292.257c-31.855 7.828-74.728 20.627-125.817 37.27-9.551 3.111-19.331 6.339-29.315 9.671-36.654
          12.235-74.463 25.349-111.288 38.463-12.889 4.59-15.651 4.963-26.183 8.787v-28.543c2.719-0.905 8.393-2.894
          17.022-5.968 36.984-13.171 74.96-26.343 111.804-38.641 10.045-3.353 19.888-6.601 29.503-9.733 138.393-45.083
          195.304-59.069 205.885-35.573 10.394 23.080-38.46 56.319-171.036 133.732-7.75 4.525-15.693 9.132-23.817
          13.814-41.542 23.943-86.034 48.85-130.522 73.265-15.569 8.544-38.56 20.445-51.537 27.465v-33.096c15.254-6.527
          28.054-12.63 38.4-18.308 44.334-24.33 88.665-49.148 130.024-72.985 8.079-4.657 15.978-9.238 23.683-13.737
          49.148-28.698 89.156-53.317 117.421-72.548 11.302-7.689 20.592-14.431 27.698-20.107-8.625 1.403-19.332
          3.677-31.923 6.771zM154.881 292.257c31.855 7.828 74.728 20.627 125.817 37.27 9.551 3.111 19.331 6.339
          29.315 9.671 36.654 12.235 74.463 25.349 111.288 38.463 12.889 4.59 15.651 4.963 26.183
          8.787v-28.543c-2.719-0.905-8.393-2.894-17.022-5.968-36.984-13.171-74.96-26.343-111.804-38.641-10.045-3.353-19.888-6.601-29.503-9.733-138.393-45.083-195.304-59.069-205.885-35.573-10.394
          23.080 38.46 56.319 171.036 133.732 7.75 4.525 15.693 9.132 23.817 13.814 41.542 23.943 86.034 48.85 130.522
          73.265 15.569 8.544 38.56 20.445 51.537 27.465v-33.096c-15.254-6.527-28.054-12.63-38.4-18.308-44.334-24.33-88.665-49.148-130.024-72.985-8.079-4.657-15.978-9.238-23.683-13.737-49.148-28.698-89.156-53.317-117.421-72.548-11.302-7.689-20.592-14.431-27.698-20.107
          8.625 1.403 19.332 3.677 31.923 6.771zM505.045 850.451c-6.076-35.692-12.060-84.748-17.846-143.868-0.873-8.924-1.738-18.038-2.594-27.323-4.059-44.062-7.752-90.149-11.006-135.477-1.139-15.866-3.1-36.627-3.958-49.687-0.343-5.22-9.634-11.017-27.874-17.392
          0.182 2.985 3.452 53.111 4.595 69.034 3.266 45.499 6.973 91.763 11.051 136.027 0.86 9.336 1.73 18.501 2.608 27.478
          15.529 158.678 26.378 222.412 53.038 222.412 26.187 0 36.667-60.666 51.808-213.832 0.837-8.463 1.666-17.111 2.488-25.929
          4.414-47.369 8.44-97.694 11.996-147.746 1.245-17.516 2.701-52.901 3.641-67.444-18.188 8.216-27.391 14.013-27.609 17.392-0.936
          14.487-2.030 30.661-3.27 48.117-3.544 49.867-7.553 100-11.947 147.148-0.817 8.769-1.642 17.365-2.473 25.777-5.66 57.261-11.49
          104.47-17.39 138.626-2.463 14.259-4.912 26.063-7.306 35.173-2.606-9.813-5.272-22.739-7.953-38.485zM953.6-14.080c7.541 0 13.653-6.113
          13.653-13.653s-6.113-13.653-13.653-13.653h-883.2c-7.541 0-13.653 6.113-13.653 13.653s6.113 13.653 13.653 13.653h883.2zM555.947
          468.917c0 24.255-19.692 43.947-43.947 43.947-24.278 0-43.947-19.677-43.947-43.947v-483.012h87.893v483.012zM512 540.17c39.336 0
          71.253-31.918 71.253-71.253v-496.666c0-7.541-6.113-13.653-13.653-13.653h-115.2c-7.541 0-13.653 6.113-13.653
          13.653v496.666c0 39.348 31.891 71.253 71.253 71.253zM512 449.707c8.481 0 15.36 6.878 15.36 15.36s-6.879
          15.36-15.36 15.36c-8.484 0-15.36-6.876-15.36-15.36s6.876-15.36 15.36-15.36zM512 429.227c-19.795 0-35.84 16.046-35.84 35.84s16.045 35.84
          35.84 35.84c19.792 0 35.84-16.047 35.84-35.84s-16.048-35.84-35.84-35.84z"
        iconColor={iconSelected ? IconSelectedColor : ''}
        strokeWidth="25"
      />
    </svg>
  )
}

export default WindPlantIcon
