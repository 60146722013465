import React from 'react'
import { IconSelectedColor, SvgIconPath } from 'ui/styles/SvgIconPath'
import { UiClusterTypes } from 'modules/asset/store/asset.types'
import { getTypeLabel } from 'utils/asset'

export interface AssetIconProps {
  iconSelected?: boolean
  size?: string
}

const ClusterPoolIcon: React.FC<AssetIconProps> = ({ iconSelected, size }) => {
  return (
    <svg width={size} height={size} viewBox="200 23 100 490" xmlns="http://www.w3.org/2000/svg">
      <title>{getTypeLabel(UiClusterTypes.CLUSTER_POOL)}</title>
      <SvgIconPath
        transform="rotate(-180 253 230)"
        iconColor={iconSelected ? IconSelectedColor : ''}
        d="M419.84 407.04c28.2627 0 51.2002 -22.9375 51.2002 -51.2002v-327.68c0 -28.2627 -22.9375 -51.2002 -51.2002 -51.2002h-327.68c-28.2627 0 -51.2002 22.9375 -51.2002 51.2002v327.68c0 28.2627 22.9375 51.2002 51.2002 51.2002h327.68zM388.403 193.536
c16.0771 5.22266 24.8828 22.5283 19.6611 38.707c-5.22266 16.0771 -22.5283 24.8828 -38.708 19.6611c-13.0049 -4.19824 -21.0938 -16.3848 -21.0938 -29.3887l-57.7539 -18.7393c-5.5293 8.60156 -14.1309 14.8477 -24.2686 17.5098v64.7168
c11.8779 4.30078 20.4795 15.5654 20.4795 28.877c0 16.998 -13.7217 30.7197 -30.7197 30.7197s-30.7197 -13.7217 -30.7197 -30.7197c0 -13.3115 8.60156 -24.5762 20.4795 -28.877v-64.7168c-10.1377 -2.66211 -18.7393 -8.9082 -24.2686 -17.5098l-57.6514 18.7393
c0.102539 13.0039 -8.19141 25.1904 -21.1963 29.3887c-16.1797 5.22168 -33.4854 -3.58398 -38.708 -19.6611c-5.22168 -16.1787 3.58398 -33.4844 19.6611 -38.707c12.3906 -3.99414 25.498 0.205078 33.1777 9.72754l58.4707 -19.0459
c0 -0.819336 -0.205078 -1.63867 -0.205078 -2.45801c0 -9.93262 3.68652 -18.8418 9.52344 -25.9072l-41.7793 -57.4463c-12.083 3.68652 -25.7021 -0.40918 -33.5869 -11.2637c-9.93359 -13.7217 -6.86133 -32.8701 6.86035 -42.9053
c13.7217 -9.93359 32.8701 -6.86133 42.9053 6.86035c7.7832 10.752 7.47559 24.8828 0.410156 35.3281l41.6768 57.3438c4.60742 -1.84277 9.625 -2.96973 14.9502 -2.96973s10.3428 1.12695 14.9502 2.96973l41.6768 -57.3438
c-7.06543 -10.4453 -7.37305 -24.5762 0.410156 -35.3281c10.0352 -13.7217 29.1836 -16.7939 42.9053 -6.86035c13.7217 10.0352 16.7939 29.1836 6.86035 42.9053c-7.88477 10.8545 -21.5039 14.9502 -33.5869 11.2637l-41.7793 57.4463
c5.83691 7.06543 9.52344 15.9746 9.52344 25.9072c0 0.819336 -0.205078 1.63867 -0.205078 2.45801l58.4707 19.0459c7.78223 -9.52246 20.7871 -13.7217 33.1777 -9.72754z"
      />
    </svg>
  )
}

export default ClusterPoolIcon
