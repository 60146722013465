import { SET_BBOX, SET_BOTH, SET_RAD_CENT } from '../actions/bboxActions'

const initialState = []

export default function bboxReducer(state = initialState, action) {
  switch (action.type) {
    case SET_BBOX:
      return {
        ...state,
        [action.selectedRow]: { ...state[action.selectedRow], coords: action.coords },
      }
    case SET_RAD_CENT:
      return {
        ...state,
        [action.selectedRow]: {
          radius: action.radius,
          center: {
            lng: action.long,
            lat: action.lat,
          },
          ...state[action.selectedRow],
        },
      }
    case SET_BOTH:
      return {
        ...state,
        [action.selectedRow]: {
          radius: action.radius,
          center: {
            lng: action.long,
            lat: action.lat,
          },
          coords: action.coords,
        },
      }
    default:
      return state
  }
}
