import React from 'react'
import ReTableHeaderActionsCell from 'modules/reTable/ReTableHeaderActionsCell'
import { ReTableCell } from 'modules/reTable/ReTableCell'
import { ReTableHeaderWithSort } from 'modules/reTable/ReTableHeaderWithSort'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { ReTableHeader } from 'modules/reTable/ReTableHeader'
import { getDataStreamColumns } from 'utils/dataStream'

const columnsAvailable = getDataStreamColumns()
const DataStreamTreeHeader: React.FC = () => {
  return (
    <ReTableHeader>
      <ReTableRow>
        <ReTableHeaderActionsCell isHierarchical={true} />
        {columnsAvailable.map((column) => (
          <ReTableCell no_border={true} key={column.label} width={column.width}>
            <ReTableHeaderWithSort column={column} />
          </ReTableCell>
        ))}
      </ReTableRow>
    </ReTableHeader>
  )
}

export default React.memo(DataStreamTreeHeader)
