// TODO: Classes

export type GjsonImport = {
  shapeInfo?: ShapeInfo
  gjson?: string
}

export type ShapeInfoWrapper = {
  _id?: string
  uuid: string
  shapeInfo: ShapeInfo
  version?: string
  deleted?: string
  multiPolygon?: string
  multiPoint?: string
}

export type ShapeInfo = {
  name?: string
  note?: string
  description?: string
  label?: string
  createdBy?: string
  source?: string
  iso2?: string
  iso3?: string
  country?: string
  state?: string
  district?: string
  zipCode?: string
  city?: string
  continent?: string
  customerID?: string
  assetID?: string
  translation?: Map<string, string>
  licence?: Licence
  data?: Data
  type?: ShapeCollectionType
}

export type Licence = {
  type?: string
  data?: string
}

export type Data = {
  sid?: string
  availability?: string
  system?: string
}

export type Requirements = {
  gjson?: GjsonImport
  ops: Operations
  uuid?: string
}

export enum ShapeCollectionType {
  ASSET,
  CITY,
  CLIMATE_ZONE,
  CONTINENT,
  COUNTRY,
  CUSTOM,
  DISTRICT,
  ENERCAST,
  GEOGRAPHICAL_REGION,
  GRID_ZONE,
  IAD,
  NOTFOUND,
  SPACIAL_DATA_COVERAGE,
  STATE,
  TSO,
  PUMPAREA,
  WEATHERMODEL,
  ZIP_3,
  ZIP_5,
}

export enum Operations {
  update,
  delete,
  nothing,
}
