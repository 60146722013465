export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'
export const REGISTRATION_DISMISS = 'REGISTRATION_DISMISS'
export const CLEAR_REGISTRATION_STATE = 'CLEAR_REGISTRATION_STATE'
export type REGISTRATION_REQUEST = typeof REGISTRATION_REQUEST
export type REGISTRATION_SUCCESS = typeof REGISTRATION_SUCCESS
export type REGISTRATION_FAILURE = typeof REGISTRATION_FAILURE
export type REGISTRATION_DISMISS = typeof REGISTRATION_DISMISS
export type CLEAR_REGISTRATION_STATE = typeof CLEAR_REGISTRATION_STATE
export type REGISTRATION_ACTION_TYPE =
  | REGISTRATION_REQUEST
  | REGISTRATION_SUCCESS
  | REGISTRATION_FAILURE
  | REGISTRATION_DISMISS
  | CLEAR_REGISTRATION_STATE

export const ACTIVATE_USER_REQUEST = 'ACTIVATE_USER_REQUEST'
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS'
export const ACTIVATE_USER_FAILURE = 'ACTIVATE_USER_FAILURE'
export const ACTIVATE_USER_DISMISS = 'ACTIVATE_USER_DISMISS'
export type ACTIVATE_USER_REQUEST = typeof ACTIVATE_USER_REQUEST
export type ACTIVATE_USER_SUCCESS = typeof ACTIVATE_USER_SUCCESS
export type ACTIVATE_USER_FAILURE = typeof ACTIVATE_USER_FAILURE
export type ACTIVATE_USER_DISMISS = typeof ACTIVATE_USER_DISMISS
export type ACTIVATE_USER_TYPE =
  | ACTIVATE_USER_REQUEST
  | ACTIVATE_USER_SUCCESS
  | ACTIVATE_USER_FAILURE
  | ACTIVATE_USER_DISMISS

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const CHANGE_PASSWORD_DISMISS = 'CHANGE_PASSWORD_DISMISS'
export const CLEAR_CHANGE_PASSWORD_STATE = 'CLEAR_CHANGE_PASSWORD_STATE'
export type CHANGE_PASSWORD_REQUEST = typeof CHANGE_PASSWORD_REQUEST
export type CHANGE_PASSWORD_SUCCESS = typeof CHANGE_PASSWORD_SUCCESS
export type CHANGE_PASSWORD_FAILURE = typeof CHANGE_PASSWORD_FAILURE
export type CHANGE_PASSWORD_DISMISS = typeof CHANGE_PASSWORD_DISMISS
export type CLEAR_CHANGE_PASSWORD_STATE = typeof CLEAR_CHANGE_PASSWORD_STATE
export type CHANGE_PASSWORD_TYPE =
  | CHANGE_PASSWORD_REQUEST
  | CHANGE_PASSWORD_SUCCESS
  | CHANGE_PASSWORD_FAILURE
  | CHANGE_PASSWORD_DISMISS
  | CLEAR_CHANGE_PASSWORD_STATE

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGIN_DISMISS = 'LOGIN_DISMISS'
export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE'
export type LOGIN_REQUEST = typeof LOGIN_REQUEST
export type LOGIN_SUCCESS = typeof LOGIN_SUCCESS
export type LOGIN_FAILURE = typeof LOGIN_FAILURE
export type LOGIN_DISMISS = typeof LOGIN_DISMISS
export type CLEAR_LOGIN_STATE = typeof CLEAR_LOGIN_STATE
export type LOGIN_ACTION_TYPE = LOGIN_REQUEST | LOGIN_SUCCESS | LOGIN_FAILURE | LOGIN_DISMISS | CLEAR_LOGIN_STATE

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'
export const LOGOUT_DISMISS = 'LOGOUT_DISMISS'
export type LOGOUT_REQUEST = typeof LOGOUT_REQUEST
export type LOGOUT_SUCCESS = typeof LOGOUT_SUCCESS
export type LOGOUT_FAILURE = typeof LOGOUT_FAILURE
export type LOGOUT_DISMISS = typeof LOGOUT_DISMISS
export type LOGOUT_ACTION_TYPE = LOGOUT_REQUEST | LOGOUT_SUCCESS | LOGOUT_FAILURE | LOGOUT_DISMISS

export const LEAVE_IMPERSONATE_REQUEST = 'LEAVE_IMPERSONATE_REQUEST'
export const LEAVE_IMPERSONATE_SUCCESS = 'LEAVE_IMPERSONATE_SUCCESS'
export const LEAVE_IMPERSONATE_FAILURE = 'LEAVE_IMPERSONATE_FAILURE'
export const LEAVE_IMPERSONATE_DISMISS = 'LEAVE_IMPERSONATE_DISMISS'
export type LEAVE_IMPERSONATE_REQUEST = typeof LEAVE_IMPERSONATE_REQUEST
export type LEAVE_IMPERSONATE_SUCCESS = typeof LEAVE_IMPERSONATE_SUCCESS
export type LEAVE_IMPERSONATE_FAILURE = typeof LEAVE_IMPERSONATE_FAILURE
export type LEAVE_IMPERSONATE_DISMISS = typeof LEAVE_IMPERSONATE_DISMISS
export type LEAVE_IMPERSONATE_ACTION_TYPE =
  | LEAVE_IMPERSONATE_REQUEST
  | LEAVE_IMPERSONATE_SUCCESS
  | LEAVE_IMPERSONATE_FAILURE
  | LEAVE_IMPERSONATE_DISMISS

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'
export const GET_USER_DISMISS = 'GET_USER_DISMISS'
export type GET_USER_REQUEST = typeof GET_USER_REQUEST
export type GET_USER_SUCCESS = typeof GET_USER_SUCCESS
export type GET_USER_FAILURE = typeof GET_USER_FAILURE
export type GET_USER_DISMISS = typeof GET_USER_DISMISS
export type GET_USER_ACTION_TYPE =
  | GET_USER_REQUEST
  | GET_USER_SUCCESS
  | GET_USER_FAILURE
  | GET_USER_DISMISS
  | LOGOUT_SUCCESS

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST'
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE'
export const SAVE_USER_DISMISS = 'SAVE_USER_DISMISS'
export const SAVE_USER_TEMPORARILY = 'SAVE_USER_TEMPORARILY'
export const CLEAR_SAVE_USER_STATE = 'CLEAR_SAVE_USER_STATE'
export type SAVE_USER_REQUEST = typeof SAVE_USER_REQUEST
export type SAVE_USER_SUCCESS = typeof SAVE_USER_SUCCESS
export type SAVE_USER_FAILURE = typeof SAVE_USER_FAILURE
export type SAVE_USER_DISMISS = typeof SAVE_USER_DISMISS
export type SAVE_USER_TEMPORARILY = typeof SAVE_USER_TEMPORARILY
export type CLEAR_SAVE_USER_STATE = typeof CLEAR_SAVE_USER_STATE
export type SAVE_USER_ACTION_TYPE =
  | SAVE_USER_REQUEST
  | SAVE_USER_SUCCESS
  | SAVE_USER_FAILURE
  | SAVE_USER_DISMISS
  | SAVE_USER_TEMPORARILY
  | CLEAR_SAVE_USER_STATE

export const RESEND_CONFIRMATION_REQUEST = 'RESEND_CONFIRMATION_REQUEST'
export const RESEND_CONFIRMATION_SUCCESS = 'RESEND_CONFIRMATION_SUCCESS'
export const RESEND_CONFIRMATION_FAILURE = 'RESEND_CONFIRMATION_FAILURE'
export const RESEND_CONFIRMATION_DISMISS = 'RESEND_CONFIRMATION_DISMISS'
export const CLEAR_RESEND_CONFIRMATION_STATE = 'CLEAR_RESEND_CONFIRMATION_STATE'
export type RESEND_CONFIRMATION_REQUEST = typeof RESEND_CONFIRMATION_REQUEST
export type RESEND_CONFIRMATION_SUCCESS = typeof RESEND_CONFIRMATION_SUCCESS
export type RESEND_CONFIRMATION_FAILURE = typeof RESEND_CONFIRMATION_FAILURE
export type RESEND_CONFIRMATION_DISMISS = typeof RESEND_CONFIRMATION_DISMISS
export type CLEAR_RESEND_CONFIRMATION_STATE = typeof CLEAR_RESEND_CONFIRMATION_STATE
export type RESEND_CONFIRMATION_ACTION_TYPE =
  | RESEND_CONFIRMATION_REQUEST
  | RESEND_CONFIRMATION_SUCCESS
  | RESEND_CONFIRMATION_FAILURE
  | RESEND_CONFIRMATION_DISMISS
  | CLEAR_RESEND_CONFIRMATION_STATE

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'
export const FORGOT_PASSWORD_DISMISS = 'FORGOT_PASSWORD_DISMISS'
export const CLEAR_FORGOT_PASSWORD_STATE = 'CLEAR_FORGOT_PASSWORD_STATE'
export type FORGOT_PASSWORD_REQUEST = typeof FORGOT_PASSWORD_REQUEST
export type FORGOT_PASSWORD_SUCCESS = typeof FORGOT_PASSWORD_SUCCESS
export type FORGOT_PASSWORD_FAILURE = typeof FORGOT_PASSWORD_FAILURE
export type FORGOT_PASSWORD_DISMISS = typeof FORGOT_PASSWORD_DISMISS
export type CLEAR_FORGOT_PASSWORD_STATE = typeof CLEAR_FORGOT_PASSWORD_STATE
export type FORGOT_PASSWORD_ACTION_TYPE =
  | FORGOT_PASSWORD_REQUEST
  | FORGOT_PASSWORD_SUCCESS
  | FORGOT_PASSWORD_FAILURE
  | FORGOT_PASSWORD_DISMISS
  | CLEAR_FORGOT_PASSWORD_STATE

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'
export const RESET_PASSWORD_DISMISS = 'RESET_PASSWORD_DISMISS'
export const CLEAR_RESET_PASSWORD_STATE = 'CLEAR_RESET_PASSWORD_STATE'
export type RESET_PASSWORD_REQUEST = typeof RESET_PASSWORD_REQUEST
export type RESET_PASSWORD_SUCCESS = typeof RESET_PASSWORD_SUCCESS
export type RESET_PASSWORD_FAILURE = typeof RESET_PASSWORD_FAILURE
export type RESET_PASSWORD_DISMISS = typeof RESET_PASSWORD_DISMISS
export type CLEAR_RESET_PASSWORD_STATE = typeof CLEAR_RESET_PASSWORD_STATE
export type RESET_PASSWORD_ACTION_TYPE =
  | RESET_PASSWORD_REQUEST
  | RESET_PASSWORD_SUCCESS
  | RESET_PASSWORD_FAILURE
  | RESET_PASSWORD_DISMISS
  | CLEAR_RESET_PASSWORD_STATE

export const GET_USER_TIMEZONE_REQUEST = 'GET_USER_TIMEZONE_REQUEST'
export const GET_USER_TIMEZONE_SUCCESS = 'GET_USER_TIMEZONE_SUCCESS'
export const GET_USER_TIMEZONE_FAILURE = 'GET_USER_TIMEZONE_FAILURE'
export const GET_USER_TIMEZONE_DISMISS = 'GET_USER_TIMEZONE_DISMISS'
export type GET_USER_TIMEZONE_REQUEST = typeof GET_USER_TIMEZONE_REQUEST
export type GET_USER_TIMEZONE_SUCCESS = typeof GET_USER_TIMEZONE_SUCCESS
export type GET_USER_TIMEZONE_FAILURE = typeof GET_USER_TIMEZONE_FAILURE
export type GET_USER_TIMEZONE_DISMISS = typeof GET_USER_TIMEZONE_DISMISS
export type GET_USER_TIMEZONE_ACTION_TYPE =
  | GET_USER_TIMEZONE_REQUEST
  | GET_USER_TIMEZONE_SUCCESS
  | GET_USER_TIMEZONE_FAILURE
  | GET_USER_TIMEZONE_DISMISS

export const SAVE_USER_TIMEZONE_REQUEST = 'SAVE_USER_TIMEZONE_REQUEST'
export const SAVE_USER_TIMEZONE_SUCCESS = 'SAVE_USER_TIMEZONE_SUCCESS'
export const SAVE_USER_TIMEZONE_FAILURE = 'SAVE_USER_TIMEZONE_FAILURE'
export const SAVE_USER_TIMEZONE_DISMISS = 'SAVE_USER_TIMEZONE_DISMISS'
export type SAVE_USER_TIMEZONE_REQUEST = typeof SAVE_USER_TIMEZONE_REQUEST
export type SAVE_USER_TIMEZONE_SUCCESS = typeof SAVE_USER_TIMEZONE_SUCCESS
export type SAVE_USER_TIMEZONE_FAILURE = typeof SAVE_USER_TIMEZONE_FAILURE
export type SAVE_USER_TIMEZONE_DISMISS = typeof SAVE_USER_TIMEZONE_DISMISS
export type SAVE_USER_TIMEZONE_ACTION_TYPE =
  | SAVE_USER_TIMEZONE_REQUEST
  | SAVE_USER_TIMEZONE_SUCCESS
  | SAVE_USER_TIMEZONE_FAILURE
  | SAVE_USER_TIMEZONE_DISMISS

export const GET_USERCONFIG_REQUEST = 'GET_USERCONFIG_REQUEST'
export const GET_USERCONFIG_SUCCESS = 'GET_USERCONFIG_SUCCESS'
export const GET_USERCONFIG_FAILURE = 'GET_USERCONFIG_FAILURE'
export const GET_USERCONFIG_DISMISS = 'GET_USERCONFIG_DISMISS'
export type GET_USERCONFIG_REQUEST = typeof GET_USERCONFIG_REQUEST
export type GET_USERCONFIG_SUCCESS = typeof GET_USERCONFIG_SUCCESS
export type GET_USERCONFIG_FAILURE = typeof GET_USERCONFIG_FAILURE
export type GET_USERCONFIG_DISMISS = typeof GET_USERCONFIG_DISMISS
export type GET_USERCONFIG_ACTION_TYPE =
  | GET_USERCONFIG_REQUEST
  | GET_USERCONFIG_SUCCESS
  | GET_USERCONFIG_FAILURE
  | GET_USERCONFIG_DISMISS

export const SET_USERCONFIG_REQUEST = 'SET_USERCONFIG_REQUEST'
export const SET_USERCONFIG_SUCCESS = 'SET_USERCONFIG_SUCCESS'
export const SET_USERCONFIG_FAILURE = 'SET_USERCONFIG_FAILURE'
export const SET_USERCONFIG_DISMISS = 'SET_USERCONFIG_DISMISS'
export type SET_USERCONFIG_REQUEST = typeof SET_USERCONFIG_REQUEST
export type SET_USERCONFIG_SUCCESS = typeof SET_USERCONFIG_SUCCESS
export type SET_USERCONFIG_FAILURE = typeof SET_USERCONFIG_FAILURE
export type SET_USERCONFIG_DISMISS = typeof SET_USERCONFIG_DISMISS
export type SET_USERCONFIG_ACTION_TYPE =
  | SET_USERCONFIG_REQUEST
  | SET_USERCONFIG_SUCCESS
  | SET_USERCONFIG_FAILURE
  | SET_USERCONFIG_DISMISS

export const DELETE_USERCONFIG_REQUEST = 'DELETE_USERCONFIG_REQUEST'
export const DELETE_USERCONFIG_SUCCESS = 'DELETE_USERCONFIG_SUCCESS'
export const DELETE_USERCONFIG_FAILURE = 'DELETE_USERCONFIG_FAILURE'
export const DELETE_USERCONFIG_DISMISS = 'DELETE_USERCONFIG_DISMISS'
export type DELETE_USERCONFIG_REQUEST = typeof DELETE_USERCONFIG_REQUEST
export type DELETE_USERCONFIG_SUCCESS = typeof DELETE_USERCONFIG_SUCCESS
export type DELETE_USERCONFIG_FAILURE = typeof DELETE_USERCONFIG_FAILURE
export type DELETE_USERCONFIG_DISMISS = typeof DELETE_USERCONFIG_DISMISS
export type DELETE_USERCONFIG_ACTION_TYPE =
  | DELETE_USERCONFIG_REQUEST
  | DELETE_USERCONFIG_SUCCESS
  | DELETE_USERCONFIG_FAILURE
  | DELETE_USERCONFIG_DISMISS
