import React, { useEffect } from 'react'
import { ChartWidget } from 'modules/workspace/store/workspace.types'
import { PRINT_VIEW_RELOAD } from 'utils/user'
import SeasonalForecast from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecast'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { useUniqueSelectedAssets } from 'utils/asset'
import { getChartWidgetNames } from 'utils/workspace'
import styled from 'styled-components'
import { QUERY_PRINT_VIEW, useQueryString } from 'utils/query-string'
import PrintViewHeader from 'modules/printView/PrintViewHeader'
import PrintViewFooter from 'modules/printView/PrintViewFooter'

export const PrintViewHeadingsFontSize = '11px'
export const PrintViewFontSize = '10px'

export enum A4Dimensions {
  height = '297mm',
  width = '192mm',
}

const PrintViewContainer = styled.div`
  background: white;
  font-size: ${PrintViewFontSize};
  padding: 1em;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  width: ${A4Dimensions.width};
`

const PrintView: React.FC = () => {
  const selectedAssets = useUniqueSelectedAssets()
  const dataSelection = useSelector(workspaceDraftDataStreamSelectionSelector)
  const { onDeleteQueryStrings } = useQueryString()
  const chartWidgetNames = getChartWidgetNames()

  useEffect(() => {
    if (!localStorage.getItem(PRINT_VIEW_RELOAD)) {
      onDeleteQueryStrings([QUERY_PRINT_VIEW])
      localStorage.removeItem(PRINT_VIEW_RELOAD)
    }

    return () => {
      if (localStorage.getItem(PRINT_VIEW_RELOAD)) {
        localStorage.removeItem(PRINT_VIEW_RELOAD)
      }
    }
  }, [])

  // Remove unnecessary dom elements added in print view
  const workbenchPages = document.getElementsByClassName('workbench-page')
  useEffect(() => {
    if (workbenchPages.length) {
      for (let index = 0; index <= workbenchPages.length; index++) {
        if (workbenchPages[index]) {
          workbenchPages[index].remove()
        }
      }
    }
  }, [workbenchPages])

  window.addEventListener('beforeprint', () => {
    const previewContainer = document.getElementById('printView-page')
    if (previewContainer) {
      previewContainer.style.overflow = 'inherit'
    }
  })

  window.addEventListener('afterprint', () => {
    const previewContainer = document.getElementById('printView-page')
    if (previewContainer) {
      previewContainer.style.overflow = 'auto'
    }
  })

  return (
    <PrintViewContainer id="printView-page">
      <table className="report-container">
        <thead className="printView-header-container">
          <tr>
            <th>
              <PrintViewHeader />
            </th>
          </tr>
        </thead>
        <tfoot className="printView-footer-container">
          <tr>
            <td>
              <PrintViewFooter />
            </td>
          </tr>
        </tfoot>
        <tbody className="printView-body-container">
          <tr>
            <td>
              <Container>
                <SeasonalForecast
                  dataStreamSelection={dataSelection}
                  assets={selectedAssets}
                  loading={false}
                  widgetName={chartWidgetNames[ChartWidget.WIDGET_SEASONAL_TABLE]}
                  showPrintButton={false}
                />
              </Container>
            </td>
          </tr>
        </tbody>
      </table>
    </PrintViewContainer>
  )
}

export default PrintView
