import React, { useMemo } from 'react'
import Flex from 'ui/styles/Flex'
import Chip from '@material-ui/core/Chip'

import { c } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import IconButton from '@material-ui/core/IconButton'
import styled from 'styled-components'
import { colors, theme } from 'themes/theme-light'
import { ForecastConfig, SiteForecastQualityConfig } from 'modules/dataStreams/dataStreams.types'
import { getQualityConfigName } from 'utils/siteForecastConfiguration'

export const SiteForecastFormPrimaryStyledChip = styled(Chip)`
  &.MuiChip-outlinedPrimary {
    cursor: pointer;
  }
`

const QualityTitle = styled.div`
  font-size: 16px;
  color: ${theme.palette.primary.main};
  cursor: pointer;
`

interface HeaderContainerProps {
  hasError: number
}

const HeaderContainer = styled(Flex)<HeaderContainerProps>`
  background: #f5f5f5;
  padding: 10px 3px;
  margin-bottom: 3px;
  border: ${(props) => (props.hasError ? '1px solid red' : 'none')};
`

interface SectionForecastQualityEvaluationHeaderProps {
  index: number
  forecastDetails: ForecastConfig
  showDetails: boolean
  onShowDetails: () => void
  onSetPrimaryQualityConfig: (index: number) => void
  onRemoveQualityConfig: (index: number, qualityConfig: SiteForecastQualityConfig) => void
  hasError: boolean
}

const SectionForecastQualityEvaluationHeader: React.FC<SectionForecastQualityEvaluationHeaderProps> = ({
  index,
  forecastDetails,
  showDetails,
  onShowDetails,
  onSetPrimaryQualityConfig,
  onRemoveQualityConfig,
  hasError,
}) => {
  const qualityConfig = forecastDetails.qualityConfigs[index] as SiteForecastQualityConfig
  const qualityConfigName = useMemo(() => getQualityConfigName(qualityConfig, index), [qualityConfig, index])
  const deleteDisabled = qualityConfig.primary

  return (
    <HeaderContainer hasError={hasError ? 1 : 0} justifyContent="space-between" alignItems="center">
      <div>
        <Flex onClick={onShowDetails}>
          <IconButton size="small">
            <FontAwesomeActionIcon size="sm" icon={showDetails ? 'angle-down' : 'angle-right'} />
          </IconButton>
          <QualityTitle>{qualityConfigName}</QualityTitle>
        </Flex>
      </div>
      {/*<Typography>{qualityConfig.name}</Typography>*/}
      <Flex alignItems="center">
        {qualityConfig.primary ? (
          <SiteForecastFormPrimaryStyledChip
            label={c('Site Forecast:Quality Evaluation').t`Primary`}
            size="small"
            color="primary"
          />
        ) : (
          <SiteForecastFormPrimaryStyledChip
            onClick={() => onSetPrimaryQualityConfig(index)}
            variant="outlined"
            label={c('Site Forecast:Quality Evaluation').t`Set as primary`}
            size="small"
            color="primary"
          />
        )}

        <IconButton key="close" disabled={deleteDisabled} onClick={() => onRemoveQualityConfig(index, qualityConfig)}>
          <FontAwesomeActionIcon
            icon="times"
            size="sm"
            color={deleteDisabled ? colors.marked : theme.palette.primary.main}
          />
        </IconButton>
      </Flex>
    </HeaderContainer>
  )
}

export default React.memo(SectionForecastQualityEvaluationHeader)
