import React, { FormEvent, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { Box, IconButton, InputBase, Paper } from '@material-ui/core'
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from 'ttag'
import { theme } from 'themes/theme-light'

const StyledPaper = styled(Paper)`
  border-radius: 3px;
  &.MuiPaper-root {
    padding: 0 0.25em;
    display: inline-flex;
    align-items: center;
  }
  width: 100%;
`

const StyledIconButton = styled(IconButton)`
  padding: 0.25em 0.5em;
`

const StyledInputBase = styled(InputBase)`
  padding: 0.5em;
  width: 95%;
  & .MuiInputBase-input {
    padding: 0;
    font-size: 14px; /* px instead of em so that it doesnt appear huge when used in h1 or similar */
  }
`

interface SearchInputProps {
  onClearSearch: () => void
}

const SearchInput: React.FC<SearchInputProps & InputBaseComponentProps> = ({ onClearSearch, className, ...rest }) => {
  const ref = useRef<HTMLInputElement>(null)
  const [hasValue, setHasValue] = useState(false)

  const { onChange, ...inputProps } = rest

  const handleChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setHasValue(Boolean(ref.current?.value))
      if (onChange) {
        onChange(event)
      }
    },
    [rest.onChange],
  )

  const handleClearSearch = useCallback(() => {
    if (!ref.current) return

    ref.current.value = ''
    setHasValue(false)

    onClearSearch()
  }, [onClearSearch])

  return (
    <StyledPaper className={className}>
      {/*<FontAwesomeIcon icon="search" size="sm" color={theme.app.color.border} />*/}
      <StyledInputBase
        placeholder={t`Search...`}
        type="text"
        inputProps={{ ref, onChange: handleChange, ...inputProps }}
      />
      {hasValue && (
        <Box mr={0.5}>
          <IconButton size="small" onClick={handleClearSearch}>
            <FontAwesomeIcon icon="times" size="xs" color={theme.palette.primary.main} />
          </IconButton>
        </Box>
      )}
      <StyledIconButton size="small" type="submit" aria-label="search">
        <FontAwesomeIcon icon="search" />
      </StyledIconButton>
      {/*<ButtonDivider orientation="vertical" />*/}
      {/*<StyledIconButton color="primary" size="small" aria-label="filter">*/}
      {/*  <FontAwesomeIcon icon="filter" />*/}
      {/*</StyledIconButton>*/}
    </StyledPaper>
  )
}

export default React.memo(SearchInput)
