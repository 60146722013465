// Important
import React, { useCallback, useEffect, useMemo } from 'react'
import { AnyObject, FormSpy } from 'react-final-form'
import { UseMutationResult } from 'react-query'
import { UserManagementSaveMainItem, UserManagementType } from 'modules/userManagement/userManagement.types'
import {
  getUserManagementUserQueryObj,
  QUERY_USER_MANAGEMENT_DETAILS,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import {
  useUserManagementAllUsers,
  useUserManagementSaveMainItemKey,
  useUserManagementSaveMainItemKeySaveMutation,
} from 'modules/userManagement/api/userManagement.api'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableFilteredItemsSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_USER_MANAGEMENT } from 'modules/reTable/reTable.types'
import { getNextPrevNavigationIndex } from 'utils/route'
import {
  FormSaveOptions,
  getFormSaveMenuItems,
  getInvalidFieldsErrorMessagesForCertainForm,
  userManagementFormRequiredFields,
} from 'utils/form'
import { SelectAsListItem } from 'ui/form/SelectAsList'
import { c, t } from 'ttag'
import { FormHeaderContainer } from 'ui/form/form.style'
import DetailsFormHeader from 'ui/form/DetailsFormHeader'
import ActionButton from 'ui/form/ActionButton'
import { FormApi } from 'final-form'

//Interface
interface UserManagementSectionHeaderProps {
  form: FormApi
  isNewUserManagementUser: boolean
  enableSaveBtn: boolean
  formInvalid: boolean
  loading: boolean
  onCloseSlider: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  saveResult: UseMutationResult<UserManagementType, Error>
}

const requiredFieldsForAssetForm = userManagementFormRequiredFields()

const UserManagementSectionHeader: React.FC<UserManagementSectionHeaderProps> = ({
  form,
  isNewUserManagementUser,
  enableSaveBtn,
  onCloseSlider,
  onFormSubmit,
  saveResult,
  formInvalid,
  loading,
}) => {
  const { queryParams } = useQueryParams()
  const { updateQueryString } = useQueryString()

  const allUserManagementUsersResult = useUserManagementAllUsers()
  const filteredIds = useReTableSelectorWithId(reTableFilteredItemsSelector, RETABLE_ID_USER_MANAGEMENT)

  const filteredUserManagementUsers = useMemo(() => {
    return filteredIds.map((id) => (allUserManagementUsersResult.data || []).find((user) => user.id === id))
  }, [allUserManagementUsersResult.data, filteredIds])

  const handleNextPrevUserManagementUser = useCallback(
    (nextItem: boolean) => {
      const enercastIdFromUrl = queryParams[QUERY_USER_MANAGEMENT_DETAILS]

      const currentDisplayId = (filteredUserManagementUsers || []).filter(
        (item: UserManagementType) => item.login === enercastIdFromUrl,
      )

      const newIndex = getNextPrevNavigationIndex(filteredUserManagementUsers, currentDisplayId[0].id, nextItem)

      if (newIndex != undefined) {
        updateQueryString(getUserManagementUserQueryObj(filteredUserManagementUsers[newIndex]))
      }
    },
    [queryParams, updateQueryString, filteredUserManagementUsers],
  )

  //Save Handler
  const saveMainItemKey = useUserManagementSaveMainItemKey()
  const {
    mutate: saveMainMutation,
    isLoading: savingMenuItemLoading,
    isSuccess: saveMenuItemSuccess,
    reset,
  } = useUserManagementSaveMainItemKeySaveMutation()

  const saveMenuItems = useMemo(
    () =>
      getFormSaveMenuItems().filter((item) =>
        isNewUserManagementUser
          ? item.key !== FormSaveOptions.SAVE_AND_NEXT
          : item.key !== FormSaveOptions.SAVE_AND_NEW,
      ),
    [isNewUserManagementUser],
  )

  const savedMainItem = useMemo(() => {
    const mainItem = isNewUserManagementUser
      ? saveMainItemKey.data?.createUserManagement
      : saveMainItemKey.data?.updateUserManagement
    return mainItem || FormSaveOptions.SAVE_AND_CLOSE
  }, [isNewUserManagementUser, saveMainItemKey.data])

  const handleSaveMenuSelect = useCallback(
    (item: SelectAsListItem) => {
      const { SAVE_AND_CLOSE } = FormSaveOptions
      if (savedMainItem !== item.key) {
        const data: UserManagementSaveMainItem = {
          createUserManagement: isNewUserManagementUser
            ? item.key
            : saveMainItemKey.data?.createUserManagement || SAVE_AND_CLOSE,
          updateUserManagement: !isNewUserManagementUser
            ? item.key
            : saveMainItemKey.data?.updateUserManagement || SAVE_AND_CLOSE,
        }
        saveMainMutation(data)
      }
    },
    [saveMainItemKey, saveMainMutation, isNewUserManagementUser, savedMainItem],
  )

  // Header Title
  const headerTitle = useMemo(() => {
    return isNewUserManagementUser ? c('UserManagement').t`New user` : c('UserManagement').t`User details`
  }, [isNewUserManagementUser])

  // Submit the form, if a new option is selected from the save menu
  // we save in the user settings and after that we submit the form
  useEffect(() => {
    if (saveMenuItemSuccess) {
      onFormSubmit()
      reset()
    }
  }, [saveMenuItemSuccess])

  return (
    <FormHeaderContainer>
      <DetailsFormHeader
        showNextPrevOptions={!isNewUserManagementUser}
        handleGoToNextPrevItem={handleNextPrevUserManagementUser}
        totalItems={filteredUserManagementUsers.length}
        onCloseDetailsForm={onCloseSlider}
        fieldName="login"
        placeholder={c('UserManagement').t`enercast ID`}
        headerTitle={headerTitle}
        useFullWidthForTitle={1}
        disabled={!isNewUserManagementUser}
        requiredField={true}
      >
        <FormSpy subscription={{ dirty: true, touched: true, modifiedSinceLastSubmit: true }}>
          {(props) => {
            const formTouched = Object.keys(props.touched || {}).some((key) => props.touched && props.touched[key])
            return (
              <ActionButton
                invalidFieldsErrorMessages={getInvalidFieldsErrorMessagesForCertainForm(
                  form as FormApi,
                  requiredFieldsForAssetForm,
                )}
                result={saveResult}
                formInvalid={formInvalid}
                formTouched={formTouched}
                formModifiedSinceLastSubmit={props.modifiedSinceLastSubmit}
                disabled={loading || !enableSaveBtn || savingMenuItemLoading || saveMainItemKey.isLoading}
                menuItems={saveMenuItems}
                mainItemKey={savedMainItem}
                onSelectMenuItem={handleSaveMenuSelect}
                icon="save"
                variant="contained"
                color="primary"
                size="small"
                saveOptionLoading={savingMenuItemLoading}
              >
                {t`Save`}
              </ActionButton>
            )
          }}
        </FormSpy>
      </DetailsFormHeader>
    </FormHeaderContainer>
  )
}

export default React.memo(UserManagementSectionHeader)
