import { Timezone } from 'fixtures/timezones'
import { SeriesArearangeOptions, XAxisOptions, YAxisOptions } from 'highcharts/highstock'
import { TimeSeriesSubType, TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import ChartOptions from 'modules/workspace/highstock/ChartOptions'
import GenericOptions from 'modules/workspace/highstock/GenericOptions'
import LoadingOptions from 'modules/workspace/highstock/LoadingOptions'
import TimeOptions from 'modules/workspace/highstock/TimeOptions'
import TooltipOptions from 'modules/workspace/highstock/TooltipOptions'
import { seriesSelector } from 'modules/workspace/store/series.state'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { HighchartsStockChart, Series, XAxis, YAxis } from 'react-jsx-highstock'
import { useSelector } from 'react-redux'
import useResizeAware from 'react-resize-aware'
import styled from 'styled-components'
import { c, t } from 'ttag'
import { useDebounce } from 'use-debounce'
import { CHART_BOOST_THRESHOLD_VALUE, WorkspaceChartWidgetHeight } from 'utils/chart'
import { thousandSeparator } from 'utils/dataFormatting'
import { createForecastErrorTimeSeries, createTimeSeriesWithNullValues, cropSeries } from 'utils/timeseries'
import { useWorkspaceChartSelectedDateRange } from 'utils/workspace'
import { convertUTCToLocalDate, DateRange } from 'utils/date'
import { AppUnits } from 'utils/units'
import { addSeconds, differenceInMinutes, getTime } from 'date-fns'

const Content = styled.div`
  position: relative;
  height: ${WorkspaceChartWidgetHeight};
  min-height: ${WorkspaceChartWidgetHeight};
  max-height: ${WorkspaceChartWidgetHeight};
`

export interface ForecastErrorChartProps {
  loading: boolean
  isHidden: boolean
  timezone?: Timezone
  range: DateRange
  widgetName: string
  showTooltip: boolean
}

const ForecastErrorChart: React.FC<ForecastErrorChartProps> = ({
  loading,
  isHidden, // this is used in memo equality callback
  timezone = 'UTC',
  range,
  widgetName,
  showTooltip,
}) => {
  const [resizeListener, { width, height }] = useResizeAware()
  const [chart, setChart] = useState<HighchartsStockChart | undefined>()
  const [currentRange, setCurrentRange] = useState(range)
  const chartSelectedDateRangeInUTC = useWorkspaceChartSelectedDateRange()
  const selectedChartDateRange = useMemo(
    () => [
      convertUTCToLocalDate(chartSelectedDateRangeInUTC[0]),
      convertUTCToLocalDate(chartSelectedDateRangeInUTC[1]),
    ],
    [chartSelectedDateRangeInUTC],
  )
  const [debouncedChartRange] = useDebounce(selectedChartDateRange, 100)

  // actual data that is plotted
  const series = useSelector(seriesSelector)

  const seriesMeterdata = useMemo(() => {
    const filteredSeries = series.filter(
      (s) => s.custom.datastreamType === TimeSeriesType.METER_DATA,
    ) as SeriesArearangeOptions[]
    return cropSeries(filteredSeries, debouncedChartRange) as SeriesArearangeOptions[]
  }, [series, debouncedChartRange])

  const seriesForecast = useMemo(() => {
    const filteredSeries = series.filter(
      (s) =>
        s.custom.datastreamType === TimeSeriesType.SITE_FORECAST ||
        s.custom.datastreamType === TimeSeriesType.SCHEDULE ||
        s.custom.datastreamSubType === TimeSeriesSubType.E3_THIRD_PARTY_FORECAST ||
        s.custom.datastreamType === TimeSeriesType.BACK_CAST,
    ) as SeriesArearangeOptions[]
    return cropSeries(filteredSeries, debouncedChartRange) as SeriesArearangeOptions[]
  }, [series, debouncedChartRange])

  const seriesSet = useMemo(() => {
    return [...createForecastErrorTimeSeries({ seriesMeterdata, seriesSiteForecasts: seriesForecast })]
  }, [seriesMeterdata, seriesForecast])

  const hasData =
    seriesSet.length > 0 &&
    seriesSet.some((series) => Array.isArray(series.data) && series.data.length > 0) &&
    seriesMeterdata.length > 0 &&
    seriesMeterdata.some((series) => Array.isArray(series.data) && series.data.length > 0) &&
    seriesForecast.length > 0 &&
    seriesForecast.some((series) => Array.isArray(series.data) && series.data.length > 0)

  const handleChartMounted = useCallback((c) => {
    setChart(c)
  }, [])

  useEffect(() => {
    // we add a second here to show the expected date range
    // e.g. for selection of a day from 00:00 to 00:00 instead of 23:45 to 23:45
    const localRange: DateRange = [convertUTCToLocalDate(range[0]), convertUTCToLocalDate(range[1])]
    setCurrentRange(localRange)
  }, [range, timezone])

  useEffect(() => {
    if (!chart?.xAxis || chart.xAxis.length === 0 || !currentRange.length) return

    const timeoutExtremes = setTimeout(() => {
      const nextExtremes = [getTime(new Date(currentRange[0])), getTime(addSeconds(new Date(currentRange[1]), 1))]
      if (
        !nextExtremes ||
        (Math.abs(differenceInMinutes(chart.xAxis[0]?.min, nextExtremes[0])) <= 1 &&
          Math.abs(differenceInMinutes(chart.xAxis[0]?.max, nextExtremes[1])) <= 1)
      ) {
        return
      }

      // we need the timeout so that setExtremes is overriding any automatic extremes adjustments by highcharts when new data is loaded
      // see this from 2012 (!!): https://www.highcharts.com/forum/viewtopic.php?t=15091
      if (chart.xAxis[0]) {
        // reset selection when chart date range changes
        chart.xAxis[0].setExtremes(nextExtremes[0], nextExtremes[1], true, false)
      }
    }, 0)

    return () => {
      if (timeoutExtremes) {
        clearTimeout(timeoutExtremes)
      }
    }
  }, [chart, currentRange])

  // axis definitions

  const xAxis: XAxisOptions = useMemo(() => {
    return {
      crosshair: true,
      labels: {
        rotation: -45,
      },
      minRange: 1000 * 60 * 60 * 2, // 2 hours
      minTickInterval: 1000 * 60 * 15, // 15 minutes
      ordinal: false,
      tickPixelInterval: 50,
      type: 'datetime',
    }
  }, [])

  const unit = AppUnits.KILO_WATT
  const yAxis: YAxisOptions = useMemo(() => {
    return {
      alignTicks: true,
      crosshair: true,
      gridLineWidth: 1,
      labels: {
        align: 'left',
        formatter: function () {
          return thousandSeparator(this.value)
        },
        reserveSpace: true,
      },
      lineWidth: 1,
      minPadding: 0,
      opposite: false,
      showEmpty: false,
      startOnTick: false,
      endOnTick: false,
      title: {
        // offset: 48,
        text: `${t`Power`} (${unit})`,
      },
    }
  }, [unit])

  // chart and plot options

  const initialChartOptions = useMemo(
    () => ({
      height: '100%',
    }),
    [],
  )

  const chartOptions = useMemo(
    () => ({
      zoomType: undefined,
      // height: AdvancedChartHeight,
    }),
    [],
  )

  const plotOptions = useMemo(() => {
    return {
      area: {
        animation: false,
        lineWidth: 2,
        marker: { enabled: false },
        shadow: false,
      },
      series: {
        animation: {
          duration: 100,
        },
        turboThreshold: 0,
        boostThreshold: CHART_BOOST_THRESHOLD_VALUE, // number of points in one series, when reaching this number, boost.js module will be used but not working, do not change it
        connectNulls: false,
        states: {
          inactive: {
            enabled: false, // do not fade out other series if one is hovered
          },
        },
      },
    }
  }, [])

  // handle size changes

  useEffect(() => {
    if (!chart) return
    if (!width || !height) return

    try {
      // we need to catch errors since we might get weird highcharts errors:
      // "Uncaught TypeError: c.toPrecision is not a function"
      chart.update({ chart: { width, height } })
      chart.reflow()
    } catch (e) {
      // nothing to do here
    }
  }, [chart, width, height, showTooltip])

  const emptySeries = createTimeSeriesWithNullValues(chartSelectedDateRangeInUTC)

  // console.log({ seriesSet, timezone, chartSelectedDateRangeInUTC, emptySeries })

  return (
    <Content>
      {resizeListener}
      <HighchartsStockChart chart={initialChartOptions} plotOptions={plotOptions} callback={handleChartMounted}>
        <GenericOptions
          title={widgetName}
          message={
            !loading && !hasData
              ? c('Workbench:Quality')
                  .t`Forecast error chart can only be shown if there is forecast and actual generation within the selected time range.`
              : ''
          }
          showTooltip={showTooltip}
        />
        <ChartOptions options={chartOptions} />
        <LoadingOptions isLoading={loading} />
        <TimeOptions seriesStartDate={chartSelectedDateRangeInUTC[0]} timezone={timezone} />
        {showTooltip && <TooltipOptions timezone={timezone} />}
        <XAxis {...xAxis}>
          <XAxis.Title>{xAxis.title?.text}</XAxis.Title>
        </XAxis>
        <YAxis {...yAxis}>
          <YAxis.Title>{yAxis.title?.text}</YAxis.Title>
          {(hasData ? seriesSet : emptySeries).map((series) => (
            <Series key={series.name} id={series.name} {...series} />
          ))}
        </YAxis>
      </HighchartsStockChart>
    </Content>
  )
}

const areEqual = (prevProps: ForecastErrorChartProps, nextProps: ForecastErrorChartProps) => {
  // do not render if we are showing asset details
  return nextProps.isHidden || prevProps === nextProps
}

// Chart.whyDidYouRender = true
export default React.memo(ForecastErrorChart, areEqual)
