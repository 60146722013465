import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import { orange, red } from '@material-ui/core/colors'
import { useAllAssets } from 'modules/asset/api/assets.api'
import AssetHierarchyPopover from 'modules/asset/AssetHierarchyPopover'

import AssetIcon from 'modules/asset/AssetIcon'
import { Asset } from 'modules/asset/store/asset.types'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import Highlight from 'ui/Highlight'
import LabelWithoutWrap from 'ui/LabelWithoutWrap'
import FixedWidthTextContainer from 'ui/styles/FixedWidthTextContainer'
import Flex from 'ui/styles/Flex'
import { AssetLinkIconSize } from 'ui/styles/SvgIconPath'
import ConditionalWrapper from 'ui/utility/ConditionalWrapper'
import { getParents } from 'utils/asset'

const AssetLinkContainer = styled(Flex)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-start;
`

const AssetName = styled(Box)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`

const ArrowFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 0 0.25em;
`

export enum ShowPath {
  INLINE,
  TOOLTIP,
}

interface AssetLinkProps {
  asset: Asset
  rootAsset?: Asset
  showPath?: ShowPath
  selected?: boolean
  color?: string
  query?: string
  isParent?: boolean
  onSelect?: (asset: Asset) => void
  assetNameWidth?: string
}

const AssetLink: React.FC<AssetLinkProps> = ({
  asset,
  rootAsset,
  showPath,
  selected,
  color,
  query = '',
  isParent = false,
  onSelect,
  assetNameWidth,
}) => {
  const allAssets = useAllAssets()
  const showParent = useMemo(() => rootAsset && asset.id !== rootAsset.id && showPath === ShowPath.INLINE, [
    rootAsset,
    showPath,
  ])
  const parentAsset = useMemo(() => {
    if (showParent) {
      const parents = getParents(asset, allAssets.data || [])
      if (parents.length > 0) {
        return (allAssets.data || []).find((a) => a.id === parents[0].id)
      }
    }
  }, [allAssets.data, showParent])
  const parentVisible = showParent && parentAsset

  const handleSelect = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!onSelect) return
      event.stopPropagation()
      onSelect(asset)
    },
    [asset, onSelect],
  )

  const linkColor = isParent
    ? color
    : asset.deleted
    ? red[selected ? 900 : 700]
    : asset.changed
    ? orange[selected ? 200 : 800]
    : color

  return (
    <AssetLinkContainer justifyContent="center" alignItems="center" onClick={handleSelect}>
      {parentVisible && (
        <>
          <AssetLink asset={parentAsset} showPath={ShowPath.INLINE} rootAsset={rootAsset} isParent={true} />
          <ArrowFontAwesomeIcon icon="long-arrow-alt-right" color="grey" />
        </>
      )}

      <ConditionalWrapper
        condition={showPath === ShowPath.TOOLTIP}
        wrapper={(children) => <AssetHierarchyPopover asset={asset}>{children}</AssetHierarchyPopover>}
      >
        <AssetLinkContainer justifyContent="center" alignItems="center">
          {!isParent && <AssetIcon selected={selected} asset={asset} size={AssetLinkIconSize} />}
          <AssetName ml={0.5}>
            <LabelWithoutWrap color={linkColor}>
              <FixedWidthTextContainer text={asset.name} width={assetNameWidth || 'inherit'}>
                {asset.deleted ? (
                  <del>
                    <Highlight disableUserSelect={true} text={asset.name} query={query} />
                  </del>
                ) : (
                  <Highlight disableUserSelect={true} text={asset.name} query={query} />
                )}
              </FixedWidthTextContainer>
            </LabelWithoutWrap>
          </AssetName>
        </AssetLinkContainer>
      </ConditionalWrapper>
    </AssetLinkContainer>
  )
}

export default React.memo(AssetLink)
