import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Box, Typography } from '@material-ui/core'
import { c } from 'ttag'

import { GET_SUBSCRIBED_PRODUCT_CODES_REQUEST } from 'modules/dataStreams/areaForecast/redux_store/areaForecast.action.types'
import { getSubscribedProductCodesResultSelector } from 'modules/dataStreams/areaForecast/redux_store/state/areaForecast.products'
import {
  AreaProduct,
  getAllAreaForecastsColumns,
  getAreaProductsData,
  prepareAreaConfigBeforeSaving,
} from 'utils/dataStream'
import Flex from 'ui/styles/Flex'
import { RETABLE_ID_AVAILABLE_AREA_FORECASTS } from 'modules/reTable/reTable.types'
import ReTableForecastsManagement from 'modules/reTable/ReTableForecastsManagement'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { getAreaIds } from 'fixtures/areaLabels'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { hasAnyAuthority } from 'utils/user'
import { table } from 'themes/theme-light'
import areaForecastMapImg from 'media/map/area-forecast.jpg'
import {
  useActiveAreaConfigs,
  useAreaForecastConfigSaveMutation,
} from 'modules/dataStreams/api/areaForecastConfigs.api'
import { DetailsKey, DetailsKeyValueGrid, DetailsValue } from 'ui/form/assetForm.style'
import LayoutTitle from 'ui/LayoutTitle'
import DataStreamPreviewContainer from 'modules/dataStreams/DataStreamPreviewContainer'
import { DataStreamListContainer } from 'modules/dataStreams/ManageDataStreams'

const MapImage = styled.img`
  width: 35em;
  height: 28em;
`

const AreaForecasts: React.FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUserResultSelector)
  const [selectedAreaForecast, setSelectedAreaForecast] = useState<AreaProduct>()
  const [availableAreaItemsToRender, setAvailableAreaItemsToRender] = useState<AreaProduct[]>([])
  const subscribedProductCodes = useSelector(getSubscribedProductCodesResultSelector)

  // hook to get active area forecasts
  const activeAreaForecasts = useActiveAreaConfigs()

  const activeAreaForecastIds = useMemo(() => activeAreaForecasts?.data?.map((item) => item.id), [
    activeAreaForecasts.data,
  ])
  const areaProductsData = useMemo(() => getAreaProductsData(), [])
  const availableAreaItemsColumns = useMemo(() => getAllAreaForecastsColumns(), [])
  const areaLabels = useMemo(() => getAreaIds(), [])

  const handleAvailableAreaItemsToRenderChange = useCallback((items: AreaProduct[]) => {
    requestAnimationFrame(() => {
      setAvailableAreaItemsToRender(items)
    })
  }, [])

  const allAreaForecasts = useMemo(() => {
    const codes = Object.keys(areaProductsData)
    const areaProducts: AreaProduct[] = []
    codes.forEach((code) => {
      if (subscribedProductCodes.includes(code)) {
        const product = areaProductsData[code]
        product.name = areaLabels[product.id]
        areaProducts.push(product)
      }
    })
    return areaProducts
  }, [subscribedProductCodes, areaLabels])

  const hasAuthority = useMemo(() => {
    return hasAnyAuthority(user, ['ROLE_AREA_FORECAST', 'ROLE_AREA_FORECAST_TRAIL'])
  }, [user])

  const disableAddBtn = useMemo(() => (activeAreaForecastIds || []).some((id) => selectedAreaForecast?.id === id), [
    selectedAreaForecast,
    activeAreaForecastIds,
  ])

  const { mutate: saveMutation } = useAreaForecastConfigSaveMutation()
  const handleAddForecast = useCallback(() => {
    const activeForecasts = activeAreaForecasts.data || []
    const areaForecasts = [...activeForecasts, selectedAreaForecast]
    const data = prepareAreaConfigBeforeSaving(areaForecasts)
    saveMutation(data)
  }, [activeAreaForecasts.data, activeAreaForecasts, saveMutation, selectedAreaForecast])

  const handleSelectAreaForecast = useCallback((areaForecast: AreaProduct) => {
    setSelectedAreaForecast(areaForecast)
  }, [])

  useEffect(() => {
    dispatch({ type: GET_SUBSCRIBED_PRODUCT_CODES_REQUEST })
  }, [])

  useEffect(() => {
    dispatch({
      type: RETABLE_SET_COLUMNS_AVAILABLE,
      table: RETABLE_ID_AVAILABLE_AREA_FORECASTS,
      columnsAvailable: availableAreaItemsColumns,
    })
  }, [availableAreaItemsColumns])

  {
    /*TODO This is Angular code from the classic UI, not used for the current Area forecasts*/
  }
  {
    /*area.html*/
  }
  {
    /*<div ng-if="p.args" className="col-sm-5">*/
  }
  {
    /*  <div ng-repeat="a in p.args track by $index" ng-switch="a.type">*/
  }
  {
    /*    <label*/
  }
  {
    /*        ng-class="{'text-muted': (!p.permit&&!(role.isAreaTrial || role.isArea))}">{{"ecuiarea.products.args."+a.name | translate}}</label>*/
  }
  {
    /*    <input ng-disabled="(!p.permit&&!(role.isAreaTrial || role.isArea))" ng-switch-when="string"*/
  }
  {
    /*           ng-model="p.args[$index].value" maxLength="3" size="3">*/
  }
  {
    /*  </div>*/
  }
  {
    /*</div>*/
  }

  return (
    <Flex direction="row">
      <DataStreamListContainer direction="column">
        <LayoutTitle>
          <Box>{c('Area Forecast').t`Add Area Forecast`}</Box>
        </LayoutTitle>
        <ReTableForecastsManagement
          tableId={RETABLE_ID_AVAILABLE_AREA_FORECASTS}
          itemHeight={table.rowHeight}
          items={allAreaForecasts}
          itemsToRender={availableAreaItemsToRender}
          selectedItem={selectedAreaForecast}
          disableSelectedItems={true}
          onItemsToRenderChange={handleAvailableAreaItemsToRenderChange}
          columns={availableAreaItemsColumns}
          onSelectItem={handleSelectAreaForecast}
          hasAuthority={hasAuthority}
          activeForecastIds={activeAreaForecastIds}
          icon="plus"
        />
      </DataStreamListContainer>

      <DataStreamPreviewContainer
        isDataStreamSelected={Boolean(selectedAreaForecast)}
        previewHeader={c('Area Forecast:Preview').t`Area preview`}
        emptyBoxText={c('Area Forecast:Preview').t`Select an area on the left to obtain more information about it.`}
        useBtnText={c('Area Forecast:Preview').t`Add`}
        onUseDataStream={handleAddForecast}
        disableUseBtn={disableAddBtn}
      >
        {selectedAreaForecast && (
          <>
            <MapImage src={areaForecastMapImg} />
            <Box>
              <Typography variant="subtitle2">{c('Site Forecast:Preview').t`Details`}</Typography>
              <DetailsKeyValueGrid>
                <DetailsKey>{c('Area Forecast:Preview').t`Name:`}</DetailsKey>
                <DetailsValue>{selectedAreaForecast.name}</DetailsValue>
              </DetailsKeyValueGrid>
              <DetailsKeyValueGrid>
                <DetailsKey>{c('Area Forecast:Preview').t`Type:`}</DetailsKey>
                <DetailsValue>{selectedAreaForecast.type}</DetailsValue>
              </DetailsKeyValueGrid>
            </Box>
          </>
        )}
      </DataStreamPreviewContainer>
    </Flex>
  )
}

export default React.memo(AreaForecasts)
