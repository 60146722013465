import React from 'react'
import { AssetFormLabel, FormKey, FormKeyValueGrid, FormValue, TextFieldWithoutLabel } from 'ui/form/assetForm.style'
import { t } from 'ttag'
import { Field } from 'react-final-form'
import { useCustomMuiStyles } from 'themes/theme-light'

const LocationShape: React.FC = () => {
  const classes = useCustomMuiStyles({ important: true })

  return (
    <FormKeyValueGrid>
      <FormKey className={classes.adminComponent}>
        <AssetFormLabel>{t`Shape`}:</AssetFormLabel>
      </FormKey>
      <FormValue isAdminComponent>
        <Field name="shape">
          {(props) => {
            return (
              <TextFieldWithoutLabel
                rowsMax={6}
                multiline
                type="text"
                fullWidth
                input={props.input}
                meta={props.meta}
              />
            )
          }}
        </Field>
      </FormValue>
    </FormKeyValueGrid>
  )
}

export default React.memo(LocationShape)
