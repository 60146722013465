import LocationCoordinates from 'modules/asset/assetCrud/assetDetails/LocationCoordinates'

import { Asset } from 'modules/asset/store/asset.types'
import React, { useState } from 'react'
import { Field, FormSpy } from 'react-final-form'
import styled from 'styled-components'
import { t } from 'ttag'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import {
  AssetFormLabel,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  NumberFieldWithLabel,
  NumberFieldWithoutLabel,
  SubField,
} from 'ui/form/assetForm.style'
import PopperTooltip from 'ui/PopperTooltip'
import Flex from 'ui/styles/Flex'
import { isCHP, isGenerator, isPark, isSolarPark, isSolarPlant, isWindPark, isWindPlant } from 'utils/asset'
import LocationShape from 'modules/asset/assetCrud/assetDetails/LocationShape'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import { AppUnits } from 'utils/units'
import { RequiredFieldContainer } from 'ui/form/form.style'

// styled components

const TooltipIcon = styled(FontAwesomeActionIcon)`
  margin-left: 0.3em;
`

// exported component

interface SectionRequiredProps {
  asset: Asset
  enterManually: boolean
  activeField: string | undefined
}

const SectionRequired: React.FC<SectionRequiredProps> = ({ asset, enterManually, activeField }) => {
  const [formState, setFormState] = useState(asset)
  const user = useSelector(getUserResultSelector)
  const isSolarAsset = isSolarPark(formState) || isSolarPlant(formState)
  const isChpAsset = isCHP(formState)
  const isGeneratorAsset = isGenerator(formState)
  const isSolarOrWindPark = isSolarPark(formState) || isWindPark(formState)

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as Asset)
        }}
      />

      {/*For CHP this field should be present as first one , that's why located here*/}
      {isGeneratorAsset && isChpAsset && <LocationCoordinates activeField={activeField} formState={formState} />}

      {(isGeneratorAsset || isSolarOrWindPark) && (
        <div>
          <FormKeyValueGrid>
            <FormKey>
              <AssetFormLabel>
                {isSolarOrWindPark ? (
                  <Flex flexWrap="wrap">
                    <div>{t`Nameplate capacity`}</div>
                    <div> {t`(total of park)`}</div>
                  </Flex>
                ) : isChpAsset ? (
                  <>{t`Electrical output`}:</>
                ) : (
                  <>{t`Nameplate capacity`}:</>
                )}
              </AssetFormLabel>
            </FormKey>

            <FormValue>
              <Flex direction="row">
                {(isGeneratorAsset || (isSolarOrWindPark && enterManually)) && (
                  <RequiredFieldContainer className="appTour-createAsset-capacity" flexBasis="50%" direction="row">
                    <Field name={isSolarOrWindPark || enterManually ? 'currentNomCap' : 'nomCap'}>
                      {(props) => {
                        return (
                          <>
                            {isChpAsset ? (
                              <NumberFieldWithoutLabel type="number" input={props.input} meta={props.meta} />
                            ) : (
                              <NumberFieldWithLabel
                                label={isSolarAsset ? 'DC' : t`Rated power`}
                                type="number"
                                input={props.input}
                                meta={props.meta}
                              />
                            )}
                          </>
                        )
                      }}
                    </Field>

                    <FieldUnit>
                      <Flex direction="row" alignItems="center">
                        {AppUnits.KILO_WATT}
                        {!isChpAsset && (
                          <PopperTooltip
                            popperLabel={<TooltipIcon icon="info" size="sm" />}
                            popperContent={
                              <>
                                {isSolarAsset
                                  ? t`Aggregated nameplate capacity of panels (kW peak) according to standard test conditions.  This capacity is the basis for daily accounting and billing.`
                                  : t`Quantity of power assigned for a specified operating condition of a wind turbine or wind park. This capacity is the basis for daily accounting and billing.`}
                              </>
                            }
                          />
                        )}
                      </Flex>
                    </FieldUnit>
                  </RequiredFieldContainer>
                )}

                <SubField
                  style={{ marginLeft: isSolarOrWindPark && !enterManually ? '0' : '1.5em' }}
                  flexBasis="50%"
                  direction="row"
                >
                  {!isChpAsset && (
                    <>
                      <Field name={'typeSpecificAttributes.acCapacity'}>
                        {(props) => {
                          return (
                            <NumberFieldWithLabel
                              label={isSolarAsset ? 'AC' : t`Net active electrical power`}
                              type="number"
                              input={props.input}
                              meta={props.meta}
                              InputLabelProps={{ shrink: isSolarAsset ? false : true }}
                            />
                          )
                        }}
                      </Field>
                      <FieldUnit>
                        <Flex direction="row" alignItems="center">
                          {AppUnits.KILO_WATT}

                          <PopperTooltip
                            popperLabel={<TooltipIcon icon="info" size="sm" />}
                            popperContent={
                              <>{t`This is currently for information only and not processed in forecasting.`}</>
                            }
                          />
                        </Flex>
                      </FieldUnit>
                    </>
                  )}
                </SubField>
              </Flex>
            </FormValue>
          </FormKeyValueGrid>
        </div>
      )}

      {((isGeneratorAsset && !isChpAsset) || (enterManually && isPark(formState))) && (
        <LocationCoordinates activeField={activeField} formState={formState} />
      )}
      {((isGeneratorAsset && !isChpAsset) || (enterManually && isPark(formState))) &&
        (isAdmin(user) || isImpersonatedAdmin(user)) && <LocationShape />}
    </>
  )
}

export default React.memo(SectionRequired)
