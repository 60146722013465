import React from 'react'
import { useSelector } from 'react-redux'

import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isAdmin, isDemo, isImpersonatedAdmin } from 'utils/user'

import { ROUTE_PRODUCTS, ROUTE_SUBSCRIPTIONS, ROUTE_WORKBENCH } from 'modules/app/Routes'
import { Redirect, Route, Switch } from 'react-router-dom'

import SubscriptionPage from 'pages/SubscriptionPage'

const Products: React.FC = () => {
  const user = useSelector(getUserResultSelector)

  const hasGlobalAccess = isAdmin(user) || isImpersonatedAdmin(user) || isDemo(user)
  // TODO this should be defined only once (see Header.tsx)
  const hasAccessToSubscriptions = hasGlobalAccess

  return (
    <Switch>
      <Route exact path={ROUTE_PRODUCTS}>
        <Redirect to={ROUTE_SUBSCRIPTIONS} />
      </Route>
      {hasAccessToSubscriptions && <Route path={ROUTE_SUBSCRIPTIONS} exact component={SubscriptionPage} />}

      <Route path="*">
        <Redirect to={ROUTE_WORKBENCH} />
      </Route>
    </Switch>
  )
}

export default React.memo(Products)
