import React, { useCallback } from 'react'
import { ReTableBody } from 'modules/reTable/ReTableBody'
import { ReTableRow } from 'modules/reTable/ReTableRow'
import { Column, RETABLE_ID_EVALUATION, ReTableItem } from 'modules/reTable/reTable.types'
import EvaluationTableCell from 'modules/workspace/advancedChartWidgets/evaluation/table/EvaluationTableCell'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableCollapsedSelector } from 'modules/reTable/redux_store/state/view.state'
import EvaluationTableAssetCell from 'modules/workspace/advancedChartWidgets/evaluation/table/EvaluationTableAssetCell'

export interface EvaluationTableBodyProps {
  items: ReTableItem[]
  columns: Column[]
  tableHeaderHasActions: boolean
}

const EvaluationTableBody: React.FC<EvaluationTableBodyProps> = ({ items, columns, tableHeaderHasActions }) => {
  const collapsed = useReTableSelectorWithId(reTableCollapsedSelector, RETABLE_ID_EVALUATION)

  const isCollapsed = (item: ReTableItem) => {
    if (item.uiLevel === 1) {
      return collapsed.includes(item.id as string)
    }
    return true
  }

  const handleRowSelect = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
    // if ((event.ctrlKey || event.metaKey) && item.uiLevel === 2) {
    //   onSelect(event, item)
    // }
  }, [])

  return (
    <ReTableBody>
      {items.map((item) => {
        return (
          <ReTableRow
            onClick={(event) => handleRowSelect(event)}
            key={item.id}
            style={{
              backgroundColor: item.highlight && isCollapsed(item) ? 'rgba(102,133,194,0.3)' : 'inherit',
            }}
          >
            {item.uiLevel === 0 ? (
              <EvaluationTableAssetCell item={item} columns={columns} tableHeaderHasActions={tableHeaderHasActions} />
            ) : (
              <EvaluationTableCell item={item} columns={columns} />
            )}
          </ReTableRow>
        )
      })}
    </ReTableBody>
  )
}

export default EvaluationTableBody
