import React, { useCallback, useMemo } from 'react'
import { QUERY_ACTIVE_TAB, useQueryParams } from 'utils/query-string'
import {
  Asset,
  TYPE_ASSET,
  TYPE_CHP,
  TYPE_SOLARPARK,
  TYPE_SOLARPLANT,
  TYPE_WINDPARK,
  TYPE_WINDPLANT,
  UiAssetType,
  UiClusterTypes,
} from 'modules/asset/store/asset.types'
import ManageTechnicalData from 'modules/asset/assetCrud/assetDetails/ManageTechnicalData'
import { Box } from '@material-ui/core'
import SectionRequired from 'modules/asset/assetCrud/assetDetails/SectionRequired'
import SectionConfiguration from 'modules/asset/assetCrud/assetDetails/SectionConfiguration'
import SectionInformation from 'modules/asset/assetCrud/assetDetails/SectionInformation'
import SectionRelation from 'modules/asset/assetCrud/assetDetails/SectionRelation'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import SectionAdmin from 'modules/asset/assetCrud/assetDetails/SectionAdmin'
import SectionLocation from 'modules/asset/assetCrud/assetDetails/SectionLocation'
import { PaddedFlexAssetForm } from 'modules/asset/assetCrud/assetDetails/AssetForm'
import styled from 'styled-components'
import Flex from 'ui/styles/Flex'
import { LARGE_SCREEN_MEDIA_QUERY } from 'ui/styles/styleVariables'
import { useField } from 'react-final-form'
import { assetTabNames, PARK_DATA_MODE_MANUAL } from 'fixtures/assetForm'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { FormApi } from 'final-form'
import { AssetTextContainer, KeyValuePair } from 'ui/form/assetForm.style'
import { getAssetTypeText } from 'utils/asset'

const MapSection = styled(Flex)`
  width: 100%;
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    flex-basis: 35%;
  }
  @media (min-width: 1920px) {
    flex-basis: 40% !important;
  }
  margin-left: 5px;
`

const DetailsSection = styled(Flex)`
  @media (min-width: ${LARGE_SCREEN_MEDIA_QUERY}) {
    flex-basis: 34%;
  }
  @media (min-width: 1920px) {
    flex-basis: 18% !important;
  }
`

interface AssetFormBodyProps {
  enterManually: boolean
  asset: Asset
  form: FormApi<Asset>
  isNew: boolean
  setFormChanged: (formChanged: boolean) => void
  setEnterManually: (enterManually: boolean) => void
  assetPhysicalAggregator: Asset
}

const AssetFormBody: React.FC<AssetFormBodyProps> = ({
  enterManually,
  asset,
  form,
  isNew,
  setFormChanged,
  setEnterManually,
  assetPhysicalAggregator,
}) => {
  const { queryParams } = useQueryParams()
  const user = useSelector(getUserResultSelector)

  const assetType = useField<string>('type').input.value
  const isPlant = useMemo(
    () => assetType === TYPE_SOLARPLANT || assetType === TYPE_WINDPLANT || assetType === TYPE_CHP,
    [assetType],
  )
  const isPark = useMemo(() => assetType === TYPE_SOLARPARK || assetType === TYPE_WINDPARK, [assetType])
  const activeField = useMemo(() => form.getState().active, [form.getState().active])

  const handleFormChanged = useCallback(() => {
    setFormChanged(true)
  }, [])

  const handleEnterDataManually = useCallback((val: string) => {
    setEnterManually(val === PARK_DATA_MODE_MANUAL)
    setFormChanged(true)
  }, [])

  const isCluster = useMemo(
    () =>
      assetType === UiClusterTypes.CLUSTER_POOL ||
      assetType === UiClusterTypes.CLUSTER ||
      assetType === UiClusterTypes.CLUSTER_HYBRID,
    [assetType],
  )

  return (
    <>
      {(queryParams[QUERY_ACTIVE_TAB] === assetTabNames.details || !queryParams[QUERY_ACTIVE_TAB]) && (
        <PaddedFlexAssetForm direction="row" flexWrap="wrap">
          <DetailsSection flexBasis="64%" direction="column" flexGrow={1}>
            {isNew && (
              <AssetTextContainer>{getAssetTypeText(assetType as TYPE_ASSET | UiAssetType)}</AssetTextContainer>
            )}
            {/* Manage Entering data manually */}
            {(assetType === TYPE_SOLARPARK || assetType === TYPE_WINDPARK) && (
              <ManageTechnicalData
                asset={asset}
                form={form}
                enterManually={enterManually}
                onChange={handleEnterDataManually}
              />
            )}

            <KeyValuePair>
              {!isCluster && (
                <Box mb={1.5}>
                  <SectionRequired activeField={activeField} asset={asset} enterManually={enterManually} />
                </Box>
              )}

              {/*/!*Configuration section *!/*/}
              {((isPark && enterManually) || isPlant) && (
                <Box mb={1.5}>
                  <SectionConfiguration
                    asset={asset}
                    isNew={isNew}
                    form={form}
                    enterManually={enterManually}
                    onFormChangeExternally={handleFormChanged}
                  />
                </Box>
              )}

              {/*/!*Information section*!/*/}
              {assetType !== TYPE_CHP && (
                <Box mb={2}>
                  <SectionInformation
                    form={form}
                    isNew={isNew}
                    asset={asset}
                    enterManually={enterManually}
                    assetPhysicalAggregator={assetPhysicalAggregator}
                  />
                </Box>
              )}
            </KeyValuePair>

            {/*Relation section*/}
            <Box mb={2}>
              <SectionRelation
                onRelationChange={handleFormChanged}
                enterManually={enterManually}
                asset={asset as Asset}
                form={form}
              />
            </Box>

            {/*Admin section*/}
            {(isAdmin(user) || isImpersonatedAdmin(user)) && (
              <KeyValuePair>
                <Box mb={2}>
                  <SectionAdmin form={form} />
                </Box>
              </KeyValuePair>
            )}
          </DetailsSection>

          {/*Maps section*/}
          <MapSection flexBasis="32%" flexShrink={0} flexGrow={1}>
            <SectionLocation
              asset={asset as Asset}
              form={form}
              formField="location"
              enterManually={enterManually}
              onFormChangeExternally={handleFormChanged}
            />
          </MapSection>
        </PaddedFlexAssetForm>
      )}
    </>
  )
}

export default React.memo(AssetFormBody)
