import React, { useMemo } from 'react'
import { FormApi } from 'final-form'
import { PenaltyRegulationTableItemNew } from 'modules/data/penalties/PenaltyRegulationNew/penaltyRegulations.types'
import { AnyObject } from 'react-final-form'
import { UseMutationResult } from 'react-query'
import { useDebounce } from 'use-debounce/esm'
import { FormContainer } from 'ui/form/form.style'
import FormBlockNavigation from 'ui/FormBlockNavigation'
import { getPenaltyFormHeaderTitle } from 'utils/penaltyRegulations'
import { QUERY_PENALTY_DETAILS, QUERY_PENALTY_NEW_ENTITY_TYPE } from 'utils/query-string'
import { PenaltyTableTypeOfRow } from 'modules/data/penalties/PenaltyRegulationNew/api/penaltyRegulations.api'
import Flex from 'ui/styles/Flex'
import SectionPenaltyFormHeader from 'modules/data/penalties/PenaltyRegulationNew/SectionPenaltyFormHeader'
import RegulationForm from 'modules/data/penalties/PenaltyRegulationNew/RegulationForm'
import VersionForm from 'modules/data/penalties/PenaltyRegulationNew/VersionForm'
import RuleSetForm from 'modules/data/penalties/PenaltyRegulationNew/RuleSetForm'
import { DisableContainer } from 'modules/data/penalties/PenaltyRegulationNew/PenaltyRegulationDetails'
import { isAdmin, isImpersonatedAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'

interface PenaltyFormProps {
  form: FormApi<PenaltyRegulationTableItemNew>
  saveResult: UseMutationResult<PenaltyRegulationTableItemNew, Error>
  onCloseSlider: () => void
  onFormSubmit: (
    event?: Partial<Pick<React.SyntheticEvent, 'preventDefault' | 'stopPropagation'>>,
  ) => Promise<AnyObject | undefined> | undefined
  isNew: boolean
  detailsType: PenaltyTableTypeOfRow
}

const PenaltyForm: React.FC<PenaltyFormProps> = ({
  form,
  saveResult,
  onCloseSlider,
  onFormSubmit,
  isNew,
  detailsType,
}) => {
  const user = useSelector(getUserResultSelector)

  const formDirty = useMemo(() => form.getState().dirty, [form.getState().dirty])
  const formInvalid = useMemo(() => {
    return isNew ? form.getState().submitFailed && form.getState().invalid : form.getState().invalid
  }, [form.getState().invalid, form.getState().submitFailed, isNew])
  const [formInvalidDebounced] = useDebounce(formInvalid, 200)
  const detailsToShowInForm = form.getState().values

  const disableEdit = useMemo(() => {
    if (user && detailsToShowInForm) {
      if (isAdmin(user) || isImpersonatedAdmin(user)) {
        return false
      } else return detailsToShowInForm.shared
    }
  }, [user, detailsToShowInForm])

  return (
    <FormContainer>
      <form onSubmit={onFormSubmit} noValidate>
        <FormBlockNavigation
          blockWithExternalChanges={false}
          navigationDialogText={getPenaltyFormHeaderTitle(detailsType)}
          form={form}
          currentPageQueries={isNew ? [QUERY_PENALTY_DETAILS, QUERY_PENALTY_NEW_ENTITY_TYPE] : [QUERY_PENALTY_DETAILS]}
          currentPageRoute={null}
          navigationDialogKey={'penaltyRegulation'}
        />
        <Flex direction="column" fullHeight>
          <SectionPenaltyFormHeader
            isNewItem={isNew}
            item={detailsToShowInForm}
            form={form}
            enableSaveBtn={formDirty}
            saveResult={saveResult}
            onFormSubmit={onFormSubmit}
            detailsType={detailsType}
            onClose={onCloseSlider}
            disableEdit={disableEdit}
            formInvalid={formInvalidDebounced}
          />
          <DisableContainer disable={Boolean(disableEdit)}>
            {detailsType === PenaltyTableTypeOfRow.REGULATION && <RegulationForm />}
            {detailsType === PenaltyTableTypeOfRow.VERSION && <VersionForm />}
            {detailsType === PenaltyTableTypeOfRow.RULESET && <RuleSetForm isNew={isNew} form={form} />}
          </DisableContainer>
        </Flex>
      </form>
    </FormContainer>
  )
}

export default PenaltyForm
