import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DeliveryFormat } from 'modules/delivery/deliveryFormats/deliveryFormats.types'
import { useDispatch } from 'react-redux'
import { ReTableGenericAttributes } from 'modules/reTable/reTable.functionality'
import { RETABLE_SET_COLUMNS_AVAILABLE } from 'modules/reTable/redux_store/reTable.action.types'
import { RETABLE_ID_DELIVERY_FORMATS, ReTableItem, Sort } from 'modules/reTable/reTable.types'
import { getDeliveryFormatsColumns } from 'utils/delivery'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { getDeliveryFormatQueryObj, getDeliveryFormatTemplateQueryObj, useQueryString } from 'utils/query-string'
import { useDeliveryFormatsDeleteMutation } from 'modules/delivery/deliveryFormats/api/deliveryFormats.api'
import Flex from 'ui/styles/Flex'
import DeliveryTablesGenericToolbar from 'modules/delivery/DeliveryTablesGenericToolbar'
import ReTable from 'modules/reTable/ReTable'
import { table } from 'themes/theme-light'
import ReTableGenericHeader from 'modules/reTable/ReTableGenericHeader'
import styled from 'styled-components'
import { DynamicTableHeightContainer } from 'ui/styles/table'
import DeliveryFormatsTableBody from 'modules/delivery/deliveryFormats/DeliveryFormatsBody'
import { c } from 'ttag'
import { useDeliveryFormatTemplatesDeleteMutation } from 'modules/delivery/deliveryFormats/api/deliveryFormatTemplates.api'

const FormatsTable = styled(ReTable)`
  & .MuiTable-root {
    width: inherit;
  }
`

interface DeliveryFormatsTableProps {
  deliveryFormats: DeliveryFormat[]
  isTemplateTable: boolean
}

const DeliveryFormatsTable: React.FC<DeliveryFormatsTableProps> = ({ deliveryFormats, isTemplateTable }) => {
  const dispatch = useDispatch()
  const { ITEMS_PADDING_HEADER, ITEMS_PADDING_FOOTER, RENDER_AHEAD_COUNT } = ReTableGenericAttributes
  const columnsSelected = useReTableSelectorWithId(reTableColumnsSelectedSelector, RETABLE_ID_DELIVERY_FORMATS)
  const tableHeaderHasAction = false
  const [itemsToRender, setItemsToRender] = useState<DeliveryFormat[]>([])
  const { onUpdateQueryString } = useQueryString()

  const { mutate: deleteDeliveryFormat } = isTemplateTable
    ? useDeliveryFormatTemplatesDeleteMutation()
    : useDeliveryFormatsDeleteMutation()

  const handleItemsToRenderChange = useCallback((items: DeliveryFormat[]) => {
    requestAnimationFrame(() => {
      setItemsToRender(items)
    })
  }, [])

  const handleAddNewItem = useCallback(() => {
    const queryObj = isTemplateTable ? getDeliveryFormatTemplateQueryObj() : getDeliveryFormatQueryObj()
    onUpdateQueryString(queryObj)
  }, [onUpdateQueryString, isTemplateTable])

  const handleEditItem = useCallback(
    (data: DeliveryFormat) => {
      const queryObj = isTemplateTable ? getDeliveryFormatTemplateQueryObj(data) : getDeliveryFormatQueryObj(data)
      onUpdateQueryString(queryObj)
    },
    [onUpdateQueryString, isTemplateTable],
  )

  const handleDeleteItem = useCallback((data: DeliveryFormat) => {
    deleteDeliveryFormat(data)
  }, [])

  const defaultSort = useMemo<Sort>(
    () => ({
      active: true,
      ascending: true,
      column: 'name',
    }),
    [],
  )

  // Manage columns
  const columnsAvailable = useMemo(() => getDeliveryFormatsColumns(), [])
  useEffect(() => {
    dispatch({ type: RETABLE_SET_COLUMNS_AVAILABLE, table: RETABLE_ID_DELIVERY_FORMATS, columnsAvailable })
  }, [columnsAvailable])

  return (
    <Flex direction="column" fullHeight>
      <DeliveryTablesGenericToolbar
        tableHeaderHasActions={tableHeaderHasAction}
        columns={columnsSelected}
        onAddNewItem={handleAddNewItem}
        tableId={RETABLE_ID_DELIVERY_FORMATS}
        addItemButtonLabel={
          isTemplateTable
            ? c('Delivery:Target').t`Add new file format template`
            : c('Delivery:Target').t`Add new file format`
        }
      />
      <DynamicTableHeightContainer numberOfRows={isTemplateTable ? itemsToRender.length + 1 : itemsToRender.length}>
        <FormatsTable
          id={RETABLE_ID_DELIVERY_FORMATS}
          itemHeight={table.rowHeight}
          items={deliveryFormats as ReTableItem[]}
          hasExtendedHeader={false}
          itemsPaddingHeader={ITEMS_PADDING_HEADER}
          itemsPaddingFooter={ITEMS_PADDING_FOOTER}
          renderAheadCount={RENDER_AHEAD_COUNT}
          defaultSort={defaultSort}
          onItemsToRenderChange={handleItemsToRenderChange}
        >
          <ReTableGenericHeader tableHeaderHasActions={tableHeaderHasAction} columns={columnsSelected} />
          <DeliveryFormatsTableBody
            onEditItem={handleEditItem}
            onDeleteItem={handleDeleteItem}
            columns={columnsSelected}
            visibleItems={itemsToRender}
            tableHeaderHasActions={tableHeaderHasAction}
            isTemplateTable={isTemplateTable}
          />
        </FormatsTable>
      </DynamicTableHeightContainer>
    </Flex>
  )
}

export default React.memo(DeliveryFormatsTable)
