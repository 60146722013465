import { combineReducers } from 'redux'
import { createSelector } from 'reselect'

import { SaveForecastModelAction, SaveForecastModelState } from 'modules/quality/quality.types'
import * as actionTypes from 'modules/quality/redux_store/quality.action.types'
import createReducer from 'utils/createReducer'

const initialState: SaveForecastModelState = {
  loading: false,
  error: null,
  success: false,
}

const loading = createReducer<SaveForecastModelState['loading'], SaveForecastModelAction>(
  (state = initialState.loading, { type }) => {
    switch (type) {
      case actionTypes.SAVE_FORECAST_MODEL_SUCCESS:
      case actionTypes.SAVE_FORECAST_MODEL_FAILURE:
        return false
      case actionTypes.SAVE_FORECAST_MODEL_REQUEST:
        return true
    }
    return state
  },
)

const error = createReducer<SaveForecastModelState['error'], SaveForecastModelAction>(
  (state = initialState.error, { type, error }) => {
    switch (type) {
      case actionTypes.SAVE_FORECAST_MODEL_FAILURE:
        return error
      case actionTypes.SAVE_FORECAST_MODEL_REQUEST:
      case actionTypes.SAVE_FORECAST_MODEL_SUCCESS:
        return null
    }
    return state
  },
)
// maybe used later
// const success = createReducer<SaveForecastModelState['success'], SaveForecastModelAction>(
//   (state = initialState.loading, { type }) => {
//     switch (type) {
//       case actionTypes.SAVE_FORECAST_MODEL_SUCCESS:
//         return true
//       case actionTypes.SAVE_FORECAST_MODEL_FAILURE:
//       case actionTypes.SAVE_FORECAST_MODEL_REQUEST:
//         return false
//     }
//     return state
//   },
// )

export const saveForecastModelReducer = combineReducers({ loading, error })

// selectors
export const saveForecastModelLoadingSelector = createSelector<
  any,
  SaveForecastModelState['loading'],
  SaveForecastModelState['loading']
>(
  (state) => state.quality.saveForecastModel.loading,
  (loading) => loading,
)
export const saveForecastModelErrorSelector = createSelector<
  any,
  SaveForecastModelState['error'],
  SaveForecastModelState['error']
>(
  (state) => state.quality.saveForecastModel.error,
  (error) => error,
)

export const saveForecastModelSuccessSelector = createSelector<
  any,
  SaveForecastModelState['success'],
  SaveForecastModelState['success']
>(
  (state) => state.quality.saveForecastModel.success,
  (success) => success,
)
