import deepmerge from 'deepmerge'
import { QUERY_KEY_ASSETS } from 'modules/asset/api/assets.api'
import {
  Asset,
  FORECAST_MODEL_FOR_BACK_CAST_ID,
  TreeDataStreamsSelectionOptions,
} from 'modules/asset/store/asset.types'
import { User } from 'modules/auth/Auth.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { DATA_SELECTION_MODE_SINGLE } from 'modules/dataStreams/dataStreams.types'
import { selectedConfigSelector } from 'modules/workspace/store/getWorkspaceConfigs.state'
import {
  workspaceDraftAssetSelectionModeSelector,
  workspaceDraftAssetSelectionSelector,
  workspaceDraftDataStreamSelectionModeSelector,
  workspaceDraftResultSelector,
  workspaceDraftSelectedModelsSelector,
} from 'modules/workspace/store/getWorkspaceDraft.state'

import {
  ASSET_SELECTION_MODE_HIERARCHY,
  ASSET_SELECTION_MODE_MULTIPLE,
  ASSET_SELECTION_MODE_SINGLE,
  SAVE_WORKSPACE_DRAFT_ACTION_TYPE,
  SAVE_WORKSPACE_DRAFT_DISMISS,
  SAVE_WORKSPACE_DRAFT_FAILURE,
  SAVE_WORKSPACE_DRAFT_REQUEST,
  SAVE_WORKSPACE_DRAFT_SUCCESS,
  WorkspaceConfig,
} from 'modules/workspace/store/workspace.types'
import { STAGE_DRAFT, STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { queryClient } from 'queryClient'
import { combineReducers } from 'redux'
import { SagaIterator } from 'redux-saga'
import { call, put, select } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { overwriteMerge } from 'utils/array'
import { combineAssetWithDescendants } from 'utils/asset'
import createReducer from 'utils/createReducer'
import { formatDate } from 'utils/date'
import { Result } from 'utils/request'
import { populateWorkspaceConfig, workspaceConfigsAreEqual } from 'utils/workspace'
import { ForecastModel } from 'modules/quality/quality.types'
import { getBackCastModelIdForAssetTree } from 'utils/forecastModel'

// state

interface State {
  loading: boolean
  error: string | null
  initialized: boolean
}

const initialState: State = {
  loading: false,
  error: null,
  initialized: false,
}

// types

interface DraftAction {
  type: SAVE_WORKSPACE_DRAFT_ACTION_TYPE
  // REQUEST
  draft: WorkspaceConfig
  isNew: boolean
  // SELECT ASSET
  asset: Asset
  // MULTIPLE ASSETS
  selectMultipleAssets?: Asset[]
  unSelectMultipleAssets?: Asset[]
  model?: ForecastModel
  // SELECTION OPTION
  selectionOptions: TreeDataStreamsSelectionOptions
  // ERROR
  error: State['error']
}

// reducers

const loading = createReducer<State['loading'], DraftAction>((state = initialState.loading, { type }) => {
  switch (type) {
    case SAVE_WORKSPACE_DRAFT_REQUEST:
      return true
    case SAVE_WORKSPACE_DRAFT_SUCCESS:
    case SAVE_WORKSPACE_DRAFT_FAILURE:
      return false
  }
  return state
})

const error = createReducer<State['error'], DraftAction>((state = initialState.error, { type, error }) => {
  switch (type) {
    case SAVE_WORKSPACE_DRAFT_SUCCESS:
    case SAVE_WORKSPACE_DRAFT_DISMISS:
      return null
    case SAVE_WORKSPACE_DRAFT_FAILURE:
      return error
  }
  return state
})

const initialized = createReducer<State['initialized'], DraftAction>((state = initialState.initialized, { type }) => {
  switch (type) {
    case SAVE_WORKSPACE_DRAFT_SUCCESS:
    case SAVE_WORKSPACE_DRAFT_FAILURE:
      return true
  }
  return state
})

export const saveDraftReducer = combineReducers({
  loading,
  error,
  initialized,
})

// selectors

export const saveWorkspaceDraftLoadingSelector = createSelector<any, State['loading'], State['loading']>(
  (state) => state.workspace.saveDraft.loading,
  (loading) => loading,
)
export const saveWorkspaceDraftErrorSelector = createSelector<any, State['error'], State['error']>(
  (state) => state.workspace.saveDraft.error,
  (error) => error,
)
export const saveWorkspaceDraftInitializedSelector = createSelector<any, State['initialized'], State['initialized']>(
  (state) => state.workspace.saveDraft.initialized,
  (initialized) => initialized,
)

// api
export const saveWorkspaceDraft = (config: WorkspaceConfig, selected: WorkspaceConfig | null, user: User) => {
  // return request(() => axios.get<ForecastConfig[]>('/api/productconfig/v2'))
  const workspaceDraft: Record<string, WorkspaceConfig> =
    JSON.parse(localStorage.getItem('workspaceDraft') || '{}') || {}
  const selectedConfig: Record<string, string | null> =
    JSON.parse(localStorage.getItem('workspaceSelectedConfig') || '{}') || {}
  workspaceDraft[user.login] = config
  selectedConfig[user.login] = selected?.id || null
  localStorage.setItem('workspaceDraft', JSON.stringify(workspaceDraft))
  localStorage.setItem('workspaceSelectedConfig', JSON.stringify(selectedConfig))

  const result: Result<WorkspaceConfig> = {
    hasError: false,
    hasAuthError: false,
    isSuccessful: true,
    getData: () => config,
    getError: () => '',
  }
  return result
}

// sagas
export function* saveWorkspaceDraftSaga({ draft, isNew }: DraftAction): SagaIterator {
  const user = yield select(getUserResultSelector)
  const selectedConfig = yield select(selectedConfigSelector)
  const workspaceDraft = yield select(workspaceDraftResultSelector)
  const mergedWorkspaceDraft = deepmerge(workspaceDraft, draft, { arrayMerge: overwriteMerge })
  const configToSave = populateWorkspaceConfig(mergedWorkspaceDraft)

  // previous values for comparison
  // const assetSelection = yield select(workspaceDraftAssetSelectionSelector)
  // const dataSelection = yield select(workspaceDraftDataStreamSelectionSelector)
  // const chartDataRange = yield select(workspaceDraftChartDataRangeSelector)

  if (isNew) {
    delete configToSave.id
    delete configToSave.penaltyWidget
  }

  // Do not get the slider values from previous draft when creating new chart
  if (draft.e3WidgetSettings) {
    configToSave.e3WidgetSettings = draft.e3WidgetSettings
  }

  configToSave.lastUpdated = formatDate(new Date())

  // make sure that selection is handled properly
  if (draft?.data?.selection) {
    const dataSelectionMode = yield select(workspaceDraftDataStreamSelectionModeSelector)
    if (dataSelectionMode === DATA_SELECTION_MODE_SINGLE) {
      // overwrite data selection
      configToSave.data.selection = draft.data.selection
    } else {
      // merge data selection
      configToSave.data.selection = deepmerge(workspaceDraft.data.selection, draft.data.selection, {
        arrayMerge: overwriteMerge,
      })
    }
  }

  const configDidNotChange = workspaceConfigsAreEqual(workspaceDraft, configToSave, false)
  if (configDidNotChange) return
  const result = yield call(saveWorkspaceDraft, configToSave, selectedConfig, user)
  if (result.isSuccessful) {
    const resultDraft = result.getData()
    yield put({ type: SAVE_WORKSPACE_DRAFT_SUCCESS, draft: resultDraft })
  } else {
    const error = result.getError()
    yield put({ type: SAVE_WORKSPACE_DRAFT_FAILURE, error })
  }
}

export function* saveWorkspaceDraftSelectAssetSaga({
  asset,
  selectMultipleAssets,
  unSelectMultipleAssets,
  selectionOptions,
}: DraftAction): SagaIterator {
  const assetSelectionMode =
    selectionOptions.ctrlKey ||
    (selectionOptions.shiftKey && (selectMultipleAssets?.length || unSelectMultipleAssets?.length))
      ? ASSET_SELECTION_MODE_MULTIPLE
      : yield select(workspaceDraftAssetSelectionModeSelector)
  const assetSelection = yield select(workspaceDraftAssetSelectionSelector)
  const selectedModels = yield select(workspaceDraftSelectedModelsSelector)

  // TODO to get all assets we can't use hooks in sagas, so we need to find a better way than the following:
  const allAssetsProductive = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_PRODUCTIVE])
  const allAssetsDraft = queryClient.getQueryData<Asset[]>([QUERY_KEY_ASSETS, STAGE_DRAFT])
  const allAssets = ((allAssetsDraft || []).length > 0 ? allAssetsDraft : allAssetsProductive) || []

  const allAssetIds = allAssets.map((asset) => asset.id)

  const selectedAssets: Asset[] = allAssets.filter((a: Asset) => assetSelection.includes(a.id))
  const selectedForecastModels =
    assetSelectionMode === ASSET_SELECTION_MODE_MULTIPLE
      ? assetSelection?.filter((id: string) => id.includes(FORECAST_MODEL_FOR_BACK_CAST_ID))
      : []

  let assets: Asset[]
  switch (assetSelectionMode) {
    case ASSET_SELECTION_MODE_SINGLE:
      assets = [asset]
      break
    case ASSET_SELECTION_MODE_MULTIPLE:
      const selectedAssetIds = selectedAssets.map((a) => a.id)
      if (selectionOptions.shiftKey && selectMultipleAssets && selectMultipleAssets?.length) {
        // Select Multiple Assets
        assets = [...selectMultipleAssets, ...selectedAssets]
      } else if (selectionOptions.shiftKey && unSelectMultipleAssets && unSelectMultipleAssets?.length) {
        // UnSelect Multiple Assets
        const assetsIdsToUnSelect = unSelectMultipleAssets?.map((a) => a.id)
        assets = selectedAssets.filter((a) => !assetsIdsToUnSelect.includes(a.id))
      } else {
        // Select/UnSelect one asset to the existing assets
        assets = selectedAssetIds.includes(asset.id)
          ? selectedAssets.filter((a) => a.id !== asset.id)
          : [asset, ...selectedAssets]
      }

      break
    case ASSET_SELECTION_MODE_HIERARCHY:
    default:
      assets = combineAssetWithDescendants({
        asset,
        selectedAssets,
        allAssets,
      })
      break
  }

  const selection = (assets || []).map((a) => a.id).concat(...selectedForecastModels)

  const sortedSelection = selection.sort(function (a, b) {
    return allAssetIds.indexOf(a) - allAssetIds.indexOf(b)
  })

  const draft = {
    asset: {
      selection: sortedSelection,
    },
    selectedModels: selectionOptions.ctrlKey ? selectedModels : [],
  }
  yield put({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
}

export function* saveWorkspaceDraftSelectModelSaga({ model, selectionOptions }: DraftAction): SagaIterator {
  const assetAndModelSelectionIds = yield select(workspaceDraftAssetSelectionSelector)
  const selectedModels = yield select(workspaceDraftSelectedModelsSelector)

  let newSelectedModels = [...selectedModels]
  // When a forecast model is selected
  let newSelectionIds = [...assetAndModelSelectionIds]
  const modelId = model?.id?.includes(FORECAST_MODEL_FOR_BACK_CAST_ID)
    ? model.id
    : getBackCastModelIdForAssetTree(model)
  if ((newSelectionIds || []).includes(modelId) && selectionOptions.ctrlKey) {
    // Deselect an item in the table
    newSelectedModels = newSelectedModels?.filter((sm) => sm.uuid != model.uuid)
    newSelectionIds = newSelectionIds?.filter((id) => model.id !== id)
  } else {
    // select an item
    newSelectedModels = selectionOptions.ctrlKey ? [...newSelectedModels, model] : [model]
    newSelectionIds = selectionOptions.ctrlKey ? [...newSelectionIds, model.id] : [model.id]
  }

  const draft = {
    asset: {
      selection: newSelectionIds,
    },
    selectedModels: newSelectedModels,
  }
  yield put({ type: SAVE_WORKSPACE_DRAFT_REQUEST, draft })
}
