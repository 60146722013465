import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { t } from 'ttag'

import { RESET_APP } from 'rootReducer'
import * as actionTypes from 'modules/auth/redux_store/auth.action.types'
import {
  activateUser,
  changePassword,
  deleteUserConfig,
  forgotPassword,
  getCompanyAccounts,
  getUser,
  getUserConfig,
  leaveImpersonate,
  login,
  logout,
  register,
  resendConfirmation,
  resetPassword,
  saveUser,
  setUserConfig,
} from 'modules/auth/redux_store/auth.api'
import { LoginAction, RegistrationAction, ResetPasswordActionType } from 'modules/auth/Auth.types'
import { SaveUserAction } from 'modules/auth/redux_store/state/saveUser'
import { GetUserConfigAction } from 'modules/auth/redux_store/state/getUserConfig'
import { SetUserConfigAction } from 'modules/auth/redux_store/state/setUserConfig'
import { DeleteUserConfigAction } from 'modules/auth/redux_store/state/deleteUserConfig'
import { ROUTE_USER_MANAGEMENT } from 'modules/app/Routes'
import { LeaveImpersonateAction } from 'modules/auth/redux_store/state/leaveImpersonate'
import { ActivateUserAction } from 'modules/auth/redux_store/state/activateUser'
import { ChangePasswordAction } from 'modules/auth/redux_store/state/changePassword'
import { ForgotPasswordActionType } from 'modules/auth/redux_store/state/forgotPassword'
import { emailAlreadyUsedMsg, loginAlreadyUsedMsg } from 'fixtures/auth'
import { authCommonErrorMsgs } from 'utils/formValidations'
import { BAD_GATEWAY, UNAUTHORIZED } from 'utils/request'
import { SessionActions, SessionStatus } from 'modules/app/app.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isImpersonatedPartner } from 'utils/user'
import { ResendConfirmationActionType } from 'modules/auth/redux_store/state/resendConfirmation'

export function* loginSaga({ loginData }: LoginAction): SagaIterator {
  const result = yield call(login, loginData)
  // const resultJWT = yield call(loginJWT, loginData)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.LOGIN_SUCCESS })
    yield put({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.AUTHED })

    // Check for company/sub accounts if the user has any, then impersonate into that account
    const companyAccountsResult = yield call(getCompanyAccounts)
    if (companyAccountsResult.isSuccessful && companyAccountsResult?.response?.data?.length) {
      // This condition must be here , to prevent login with reload in for sub-users
      // Following code might be used later
      // const companyAccount = companyAccountsResult.response.data[0]
      // yield call(impersonateUser, companyAccount)
      // if (result.isSuccessful) {
      // We had to reload the page because of translation
      // window.location.reload()
      // }
    } else {
      // We had to reload the page because of translation
      window.location.reload()
    }

    // window.location.reload()
  } else {
    const error = !result.response
      ? t`Login failed because we could not reach the server.`
      : result.response.status === UNAUTHORIZED
      ? t`We don't recognize this user ID or password. Please check your credentials and try again.`
      : result.response.status === BAD_GATEWAY
      ? t`Login failed because we could not reach the server.`
      : result.getError()
    yield put({ type: actionTypes.LOGIN_FAILURE, error })
  }
}

export function* registrationSaga({ regData }: RegistrationAction): SagaIterator {
  const result = yield call(register, regData)
  const errorMsgs = authCommonErrorMsgs()
  if (result.isSuccessful) {
    yield put({ type: actionTypes.REGISTRATION_SUCCESS })

    // google tag manager: send event for successful registration
    // see https://jira.enercast.de/browse/PR-5682
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'RegistrationClick',
    })
  } else {
    let error = result.getError()
    if (error.includes(loginAlreadyUsedMsg)) {
      error = errorMsgs.loginExist
    }
    if (error.includes(emailAlreadyUsedMsg)) {
      error = errorMsgs.emailExist
    }
    yield put({ type: actionTypes.REGISTRATION_FAILURE, error })
  }
}

export function* getUserSaga(): SagaIterator {
  const result = yield call(getUser)
  if (result.isSuccessful) {
    const user = result.getData()
    yield put({ type: actionTypes.GET_USER_SUCCESS, user })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_USER_FAILURE, error })
  }
}

export function* saveUserSaga({ user }: SaveUserAction): SagaIterator {
  const result = yield call(saveUser, user)
  const errorMsgs = authCommonErrorMsgs()

  if (result.isSuccessful) {
    yield put({ type: actionTypes.SAVE_USER_SUCCESS, user })
    yield put({ type: actionTypes.GET_USER_REQUEST })
  } else {
    let error = result.getError()
    // this part of code needs some backend changes.
    // if (error.includes(emailAlreadyUsedMsg)) {
    //   error = errorMsgs.emailExist
    // }
    if (result.response.status === 400 && result.error.message.length === 0) {
      error = errorMsgs.emailExist
    }
    yield put({ type: actionTypes.SAVE_USER_FAILURE, error })
  }
}

export function* getUserConfigSaga({ collectionId, configId }: GetUserConfigAction): SagaIterator {
  const result = yield call(getUserConfig, collectionId, configId)
  if (result.isSuccessful) {
    const resultData = result.getData()
    const userConfigs = Array.isArray(resultData) ? resultData : [resultData]
    yield put({ type: actionTypes.GET_USERCONFIG_SUCCESS, collectionId, configId, userConfigs })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.GET_USERCONFIG_FAILURE, collectionId, configId, error })
  }
}

export function* setUserConfigSaga({ collectionId, configId, value }: SetUserConfigAction): SagaIterator {
  const result = yield call(setUserConfig, collectionId, configId, value)
  if (result.isSuccessful) {
    const userConfig = result.getData()
    const userConfigs = [userConfig]
    yield put({ type: actionTypes.SET_USERCONFIG_SUCCESS, collectionId, configId, userConfigs })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.SET_USERCONFIG_FAILURE, collectionId, configId, error })
  }
}

export function* deleteUserConfigSaga({ collectionId, configId }: DeleteUserConfigAction): SagaIterator {
  const result = yield call(deleteUserConfig, collectionId, configId)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.DELETE_USERCONFIG_SUCCESS, collectionId, configId })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.DELETE_USERCONFIG_FAILURE, error })
  }
}

export function* logoutSaga(): SagaIterator {
  const result = yield call(logout)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.LOGOUT_SUCCESS })
    yield put({ type: SessionActions.SET_SESSION_TYPE, sessionStatus: SessionStatus.EXPIRED })
    yield put({ type: SessionActions.SET_ACTION_FAILED, actionFailed: false })
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.LOGOUT_FAILURE, error })
  }
  yield put({ type: RESET_APP })
}

export function* leaveImpersonateSaga({ history }: LeaveImpersonateAction): SagaIterator {
  const result = yield call(leaveImpersonate)
  const user = yield select(getUserResultSelector)
  const isPrevUserPartner = isImpersonatedPartner(user)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.LEAVE_IMPERSONATE_SUCCESS })
    yield put({ type: actionTypes.GET_USER_REQUEST })
    if (isPrevUserPartner) {
      // history.push('/')
      window.location.assign('/')
    } else {
      history.push(ROUTE_USER_MANAGEMENT)
      location.reload()
    }
  } else {
    const error = result.getError()
    yield put({ type: actionTypes.LEAVE_IMPERSONATE_FAILURE, error })
  }
}

export function* forgotPasswordSaga({ forgotPasswordData }: ForgotPasswordActionType): SagaIterator {
  const result = yield call(forgotPassword, forgotPasswordData)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.FORGOT_PASSWORD_SUCCESS })
  } else {
    const error = t`Email is not registered`
    yield put({ type: actionTypes.FORGOT_PASSWORD_FAILURE, error })
  }
}

export function* activateUserSaga({ activationKey }: ActivateUserAction): SagaIterator {
  const result = yield call(activateUser, activationKey)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.ACTIVATE_USER_SUCCESS })
  } else {
    const error = result.getError() || t`User activation failed`
    yield put({ type: actionTypes.ACTIVATE_USER_FAILURE, error })
  }
}

export function* changePasswordSaga({ data }: ChangePasswordAction): SagaIterator {
  const result = yield call(changePassword, data.newPassword)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.CHANGE_PASSWORD_SUCCESS })
  } else {
    const error = result.getError() || t`Change password failed`
    yield put({ type: actionTypes.CHANGE_PASSWORD_FAILURE, error })
  }
}

export function* resetPasswordSaga({ resetPasswordData }: ResetPasswordActionType): SagaIterator {
  const data = { key: resetPasswordData.key, newPassword: resetPasswordData.newPassword }
  const result = yield call(resetPassword, data)

  if (result.isSuccessful) {
    yield put({ type: actionTypes.RESET_PASSWORD_SUCCESS })
  } else {
    const error = result.getError() || t`Reset password failed`
    yield put({ type: actionTypes.RESET_PASSWORD_FAILURE, error })
  }
}

export function* resendConfirmationSaga({ resendConfirmationData }: ResendConfirmationActionType): SagaIterator {
  const result = yield call(resendConfirmation, resendConfirmationData)
  if (result.isSuccessful) {
    yield put({ type: actionTypes.RESEND_CONFIRMATION_SUCCESS })
  } else {
    const error = t`Resend Confirmation failed`
    yield put({ type: actionTypes.RESEND_CONFIRMATION_FAILURE, error })
  }
}

export function* authSaga() {
  yield takeLatest(actionTypes.GET_USER_REQUEST, getUserSaga)
  yield takeLatest(actionTypes.SAVE_USER_REQUEST, saveUserSaga)
  yield takeEvery(actionTypes.GET_USERCONFIG_REQUEST, getUserConfigSaga)
  yield takeEvery(actionTypes.SET_USERCONFIG_REQUEST, setUserConfigSaga)
  yield takeEvery(actionTypes.DELETE_USERCONFIG_REQUEST, deleteUserConfigSaga)
  yield takeLatest(actionTypes.LOGIN_SUCCESS, getUserSaga)
  yield takeLatest(actionTypes.LOGIN_REQUEST, loginSaga)
  yield takeLatest(actionTypes.LOGOUT_REQUEST, logoutSaga)
  yield takeLatest(actionTypes.LEAVE_IMPERSONATE_REQUEST, leaveImpersonateSaga)
  yield takeLatest(actionTypes.REGISTRATION_REQUEST, registrationSaga)
  yield takeLatest(actionTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga)
  yield takeLatest(actionTypes.ACTIVATE_USER_REQUEST, activateUserSaga)
  yield takeLatest(actionTypes.CHANGE_PASSWORD_REQUEST, changePasswordSaga)
  yield takeLatest(actionTypes.RESET_PASSWORD_REQUEST, resetPasswordSaga)
  yield takeLatest(actionTypes.RESEND_CONFIRMATION_REQUEST, resendConfirmationSaga)
}
