import React, { useEffect, useMemo, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import SeasonalForecastTable from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastTable'
import styled from 'styled-components'
import {
  useSeasonalClimatologyByAssetsTableItems,
  useSeasonalDataMonthlyByAssetsTableItems,
  useSeasonalIntraMonthForecastByAssets,
} from 'modules/workspace/advancedChartWidgets/seasonalForecast/seasonalForecast.api'
import { Asset } from 'modules/asset/store/asset.types'
import { DataStreamSelection, TimeSeriesClassifier, TimeSeriesSubType } from 'modules/dataStreams/dataStreams.types'
import { isNumeric } from 'utils/dataFormatting'
import SeasonalForecastChart from 'modules/workspace/advancedChartWidgets/seasonalForecast/chart/SeasonalForecastChart'
import SeasonalForecastWidgetInfo from 'modules/workspace/advancedChartWidgets/seasonalForecast/SeasonalForecastWidgetInfo'
import PrintViewButton, { StyledMonthlyViewButton } from 'modules/printView/PrintViewButton'
import { ChartWidget } from 'modules/workspace/store/workspace.types'

import { QUERY_PRINT_VIEW, useQueryParams } from 'utils/query-string'
import { PrintViewHeadingsFontSize } from 'modules/printView/PrintView'
import { c } from 'ttag'
import { useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import { reTableColumnsSelectedSelector } from 'modules/reTable/redux_store/state/view.state'
import { RETABLE_ID_LONG_RANGE_FORECAST } from 'modules/reTable/reTable.types'
import { exportMonthlyViewCsv, MonthlyViewModeIds } from 'utils/seasonalForecast'
import { useSelector } from 'react-redux'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import Flex from 'ui/styles/Flex'
import { hasPermissionToClimatology } from 'utils/user'

const Content = styled.div`
  position: relative;
  top: 1em;
`

interface SeasonalDataProps {
  assets: Asset[]
  dataStreamSelection: DataStreamSelection
  widgetName: string
  showPrintButton?: boolean
}

const SeasonalForecast: React.FC<SeasonalDataProps> = ({
  assets,
  dataStreamSelection,
  widgetName,
  showPrintButton = true,
}) => {
  const [allResultsLoading, setAllResultsLoading] = useState(true)
  const [monthlyViewMode, setMonthlyViewMode] = useState(MonthlyViewModeIds.FULL_FUTURE_VIEW)
  const { queryParams } = useQueryParams()

  const user = useSelector(getUserResultSelector)
  const timezone = useSelector(getUserTimezoneSelector)
  const monthlyViewTablecolumnsSelected = useReTableSelectorWithId(
    reTableColumnsSelectedSelector,
    RETABLE_ID_LONG_RANGE_FORECAST,
  )

  const climatologySelected = dataStreamSelection.some((ds) => ds.subType === TimeSeriesSubType.CLIMATOLOGY)
  const forecastSelected = dataStreamSelection.some((ds) => ds.subType === TimeSeriesSubType.SEASONAL_FORECAST)

  const generationSelected = dataStreamSelection.some(
    (ds) =>
      ds.classifier === TimeSeriesClassifier.CLIMATOLOGY_GENERATION ||
      ds.classifier === TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION,
  )

  const longRangeForecastSelected = climatologySelected || forecastSelected

  const showTableData = longRangeForecastSelected && Boolean(assets?.length)

  // Climatology rows
  const climatologyTableItems = useSeasonalClimatologyByAssetsTableItems(assets, user, monthlyViewMode)

  // Monthly rows
  const monthlyForecastTableItems = useSeasonalDataMonthlyByAssetsTableItems(assets, monthlyViewMode)

  // Intra month rows and we replace the intra month value incase if this has values
  const intraMonthForecastResult = useSeasonalIntraMonthForecastByAssets({ assets })
  const extractIntraMonthData = useMemo(() => {
    const extractedData: Record<string, any>[] = []

    ;(intraMonthForecastResult || []).forEach((intraMonthForecast) => {
      if (intraMonthForecast?.data) {
        const intraMonthData = intraMonthForecast?.data
        const data = {}
        const keys = Object.keys(intraMonthForecast?.data)

        keys.forEach((key) => {
          if (key === 'assetUuid') {
            data['assetId'] = intraMonthData[key]
          } else {
            const intraMonthValue = intraMonthData[key]?.['scadaForecastHybrid']?.['energyYieldMWhExtrapolated']
            // Divide by 1000 to convert MWh values in to MUs (GIGA_WATT_HOUR)
            data[key] = isNumeric(intraMonthValue) ? intraMonthValue / 1000 : ''
          }
        })

        extractedData.push(data)
      }
    })

    return extractedData
  }, [intraMonthForecastResult])

  const transformedMonthlyForecastTableItems = useMemo(() => {
    const transformedData = monthlyForecastTableItems.map((monthlyForecast) => {
      const assetId = monthlyForecast.assetId
      const assetIntraMonth = extractIntraMonthData.find((intraMonth) => intraMonth.assetId === assetId)
      if (assetIntraMonth && monthlyForecast?.type === TimeSeriesClassifier.SEASONAL_FORECAST_GENERATION) {
        return {
          ...monthlyForecast,
          ...assetIntraMonth, // Override the monthly forecast values with intra month values
        }
      } else return monthlyForecast
    })
    return transformedData
  }, [monthlyForecastTableItems, JSON.stringify(extractIntraMonthData)])

  const seasonalForecastItems = useMemo(() => {
    const climatologyItems = climatologyTableItems?.length ? climatologyTableItems : []
    const monthlyItems = transformedMonthlyForecastTableItems?.length ? transformedMonthlyForecastTableItems : []

    return [...monthlyItems, ...climatologyItems]
  }, [JSON.stringify(climatologyTableItems), JSON.stringify(transformedMonthlyForecastTableItems)])

  const isPrintView = useMemo(() => {
    if (queryParams[QUERY_PRINT_VIEW]) {
      return true
    }
    return false
  }, [queryParams])

  const isButtonDisable = !showTableData || allResultsLoading

  useEffect(() => {
    if (user) {
      if (hasPermissionToClimatology(user)) {
        setMonthlyViewMode(MonthlyViewModeIds.HALF_FUTURE_VIEW)
      }
    }
  }, [user])

  return (
    <>
      <Content>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Flex direction="row" alignItems="center">
              <Typography variant="subtitle1">
                <div style={{ fontSize: isPrintView ? PrintViewHeadingsFontSize : 'inherit' }}>{widgetName}</div>
              </Typography>
            </Flex>

            <Box display="flex" flexDirection="row">
              <SeasonalForecastWidgetInfo
                showClimatologyInfo={climatologyTableItems.length > 0}
                showMonthlyForecastInfo={monthlyForecastTableItems.length > 0}
                monthlyViewMode={monthlyViewMode}
              />
              {showPrintButton && (
                <PrintViewButton
                  disabled={isButtonDisable}
                  widgetName={ChartWidget.WIDGET_SEASONAL_TABLE}
                ></PrintViewButton>
              )}
              {showPrintButton && (
                <StyledMonthlyViewButton
                  disabled={isButtonDisable}
                  onClick={() =>
                    isButtonDisable
                      ? false
                      : exportMonthlyViewCsv(
                          assets,
                          monthlyViewTablecolumnsSelected,
                          seasonalForecastItems,
                          dataStreamSelection,
                          timezone,
                          user,
                        )
                  }
                >{c('UserManagementTable').t`Export`}</StyledMonthlyViewButton>
              )}
            </Box>
          </Box>

          <SeasonalForecastTable
            showTableData={showTableData}
            assets={assets}
            seasonalForecastItems={seasonalForecastItems}
            setAllResultsLoading={(value: boolean) => setAllResultsLoading(value)}
            climatologySelected={climatologySelected}
            forecastSelected={forecastSelected}
            isPrintView={isPrintView}
            monthlyViewMode={monthlyViewMode}
          />
        </Box>
      </Content>

      {generationSelected && (
        <SeasonalForecastChart
          seasonalForecastItems={seasonalForecastItems}
          allResultsLoading={allResultsLoading}
          showTableData={showTableData}
          isPrintView={isPrintView}
          monthlyViewMode={monthlyViewMode}
        />
      )}
    </>
  )
}

export default SeasonalForecast
