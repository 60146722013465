import React, { useCallback, useMemo } from 'react'
import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { ReTableItem } from 'modules/reTable/reTable.types'
import { RETABLE_TOGGLE_SUBTREE, RETABLE_TOGGLE_SUBTREE_SEARCH } from 'modules/reTable/redux_store/reTable.action.types'
import { useReTableId, useReTableSelectorWithId } from 'modules/reTable/reTable.hooks'
import {
  reTableCollapsedSearchSelector,
  reTableCollapsedSelector,
  reTableSearchSelector,
} from 'modules/reTable/redux_store/state/view.state'
import { useDispatch } from 'react-redux'

//const HierarchyIcon = styled(IconButton)`
// &.MuiIconButton-sizeSmall {
//   padding-left: 0;
// }
// `

const IconToggle = styled(FontAwesomeActionIcon)`
  width: 1em;
  min-width: 1em;
  max-width: 1em;
`

interface ReTableExpandToggleProps {
  item: ReTableItem
  color?: string
}
const ReTableExpandToggle: React.FC<ReTableExpandToggleProps> = ({ item, color = 'inherit' }) => {
  const dispatch = useDispatch()
  const [ref, tableId] = useReTableId()
  const search = useReTableSelectorWithId(reTableSearchSelector, tableId)
  const collapsed = useReTableSelectorWithId(reTableCollapsedSelector, tableId)
  const collapsedSearch = useReTableSelectorWithId(reTableCollapsedSearchSelector, tableId)

  const expanded = useMemo(() => {
    const collapsedActive = search ? collapsedSearch : collapsed
    return !collapsedActive.includes(item.id)
  }, [item, search, collapsed, collapsedSearch])

  const handleToggle = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      const ids = [item.id]
      dispatch({ type: search ? RETABLE_TOGGLE_SUBTREE_SEARCH : RETABLE_TOGGLE_SUBTREE, table: tableId, ids })
    },
    [item, tableId, search],
  )

  return (
    <IconButton ref={ref} size="small" onClick={handleToggle}>
      <IconToggle size="xs" color={color} icon={expanded ? 'chevron-down' : 'chevron-right'} />
    </IconButton>
  )
}

export default React.memo(ReTableExpandToggle)
