import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import { t } from 'ttag'
import GermanCountryList from '@umpirsky/country-list/data/de/country.json'
import EnglishCountryList from '@umpirsky/country-list/data/en/country.json'

import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { LanguageKeys } from 'fixtures/header'
import { useSelector } from 'react-redux'
import { getSavedLanguage } from 'utils/user'

export interface Country {
  name: string
  code: string
}

interface CountrySelectorProps {
  defaultValue: string
  onChange?: (value: string) => void
}
const CountrySelector: React.FC<CountrySelectorProps & AutocompleteProps<string>> = ({
  defaultValue,
  onChange,
  ...rest
}) => {
  const { english } = LanguageKeys
  const user = useSelector(getUserResultSelector)
  const [selectedCountry, setSelectedCountry] = useState<Country>({ code: '', name: '' })

  const countries = useMemo(() => {
    const countryList = []
    const language = getSavedLanguage(user)
    const langSpecificList: Record<string, string> = language === english ? EnglishCountryList : GermanCountryList
    for (const key in langSpecificList) {
      countryList.push({ code: key, name: langSpecificList[key] })
    }
    return countryList
  }, [user?.langKey, user?.originalUser?.langKey])

  const getOptionLabel = useCallback(
    (option) => {
      if (!option) {
        return selectedCountry?.name
      } else {
        return option.name
      }
    },
    [countries, selectedCountry],
  )

  const handleChange = useCallback(
    (event: React.ChangeEvent, country: Country) => {
      if (!country || !onChange) return
      event.stopPropagation()
      onChange(country)
      setSelectedCountry(country)
    },
    [onChange],
  )

  useEffect(() => {
    if (rest.value && countries.length) {
      const country = countries.find((a) => a.code === rest.value)
      setSelectedCountry(country)
    }
  }, [countries])

  const selectionValid = typeof selectedCountry !== 'undefined' || typeof defaultValue === 'undefined'
  return (
    <div>
      {countries.length > 0 && selectionValid && (
        <Autocomplete
          options={countries}
          value={selectedCountry}
          getOptionLabel={getOptionLabel}
          multiple={false}
          renderInput={(params) => <TextField {...params} label={t`Country`} variant="standard" />}
          getOptionSelected={(option: Country, value: Country) => option?.name === value?.name}
          onChange={handleChange}
        />
      )}
    </div>
  )
}

export const CountrySelectorWrapper: React.FC<FieldRenderProps<string>> = ({
  input: { defaultValue, onChange, ...restInput },
}) => {
  // const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched

  return <CountrySelector defaultValue={defaultValue} onChange={onChange} {...restInput} />
}

export default React.memo(CountrySelector)
