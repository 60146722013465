import React, { useEffect, useMemo, useRef, useState } from 'react'
import { QUERY_ACTIVE_TAB, QUERY_ASSET, useQueryParams, useQueryString } from 'utils/query-string'
import { assetTabNames, defaultAssetFormData } from 'fixtures/assetForm'
import FullHeight from 'ui/FullHeight'
import { Asset } from 'modules/asset/store/asset.types'
import { useDebouncedCallback } from 'use-debounce'
import { Box } from '@material-ui/core'
import CenteredLoading from 'ui/CenteredLoading'
import { getDescendants, isGenerator, showMDCForThisAssetAndUser, transformAssetDataForForm } from 'utils/asset'
import { useAllAssets } from 'modules/asset/api/assets.api'
import { GET_METERDATA_SUMMARIES_REQUEST } from 'modules/asset/store/getMeterdataSummaries.state'
import { useDispatch, useSelector } from 'react-redux'
import {
  GET_FORECAST_MODELS_REQUEST,
  GET_QUALITY_EVALUATION_REQUEST,
} from 'modules/quality/redux_store/quality.action.types'
import AssetNotFound from 'modules/asset/assetCrud/AssetNotFound'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import AssetDetails from 'modules/asset/assetCrud/assetDetails/AssetDetails'
import AssetMeterData from 'modules/asset/assetCrud/assetMeterData/AssetMeterData'
import AssetMeterDataCleansing from 'modules/asset/assetCrud/meterDataCleansing/AssetMeterDataCleansing'
import Availabilities from 'modules/asset/availability/Availabilities'
import AssetQuality from 'modules/asset/assetCrud/AssetQuality'
import AssetForecastModels from 'modules/asset/assetCrud/AssetForecastModels'
import { QUERY_KEY_FORECAST_MODELS_BY_ASSETS } from 'modules/quality/quality.api'
import { useQueryClient } from 'react-query'

interface AssetCrudProps {
  isNew: boolean
}

const AssetCrud: React.FC<AssetCrudProps> = ({ isNew }) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const { queryParams } = useQueryParams()
  const { onUpdateQueryString } = useQueryString()
  const user = useSelector(getUserResultSelector)
  const userTimezone = useSelector(getUserTimezoneSelector)

  const { details, meterData, meterDataCleansing, availability, quality, forecastModels } = assetTabNames
  const allAssets = useAllAssets()

  // const [debouncedAllAssets] = useDebounce(allAssets, 500)
  const [asset, setAsset] = useState<Partial<Asset> | undefined>()
  const [id, setId] = useState<string>('')

  const [viewLoading, setViewLoading] = useState(true)
  const setDebouncedViewLoading = useDebouncedCallback(setViewLoading, 100)

  const assetDescendants = useMemo(() => {
    const assetWithDescendants = isGenerator(asset) ? [asset] : [asset, ...getDescendants(asset, allAssets.data || [])]
    assetWithDescendants.sort((a, b) => {
      const indexA = (allAssets.data || []).indexOf(a)
      const indexB = (allAssets.data || []).indexOf(b)
      return indexA < indexB ? -1 : 1
    })
    return assetWithDescendants.map((asset) => {
      const assetCopy = { ...asset }
      assetCopy.uiAncestors = []
      assetCopy.uiParents = []
      assetCopy.uiDescendants = []
      assetCopy.uiChildren = []
      return assetCopy
    })
  }, [asset, allAssets.data])

  // NEW ASSET: Set default asset data into the form
  useEffect(() => {
    if (isNew && id === 'new') {
      const defaultAssetData = defaultAssetFormData()
      setAsset(defaultAssetData as Partial<Asset>)
      setDebouncedViewLoading(false)
    }
  }, [id, isNew, userTimezone])

  // Used ref to clear the timeout outside of useeffect if necessary
  const debouncedViewLoadingTimer: { current: NodeJS.Timeout | null } = useRef(null)
  // ASSET DETAILS: Set asset data from the list of assets
  useEffect(() => {
    if (id && id.length > 4 && id !== 'new') {
      if (!allAssets.isFetching) {
        const assetToEdit = (allAssets.data || []).find((asset) => asset.id === id)
        setAsset(assetToEdit ? transformAssetDataForForm(assetToEdit, userTimezone) : undefined)

        // Need this timeout to show the loaders in the tabs while switching between assets
        debouncedViewLoadingTimer.current = setTimeout(() => {
          setDebouncedViewLoading(false)
        }, 550)
      }
    }
    return () => {
      clearTimeout(debouncedViewLoadingTimer.current)
    }
  }, [allAssets.isFetching, allAssets.data, id, userTimezone])

  useEffect(() => {
    if (queryParams) {
      setViewLoading(true)
      setId(queryParams[QUERY_ASSET])
    }
  }, [queryParams[QUERY_ASSET]])

  // fetch additional data to speed up rendering other tabs
  useEffect(() => {
    if (asset && asset?.id) {
      dispatch({
        type: GET_METERDATA_SUMMARIES_REQUEST,
        assets: [asset],
      })
    }
  }, [asset])

  // Fetch models and evaluations
  useEffect(() => {
    if (asset && asset?.id) {
      queryClient.invalidateQueries([QUERY_KEY_FORECAST_MODELS_BY_ASSETS, asset.id])
      dispatch({ type: GET_FORECAST_MODELS_REQUEST, asset: asset })
      dispatch({ type: GET_QUALITY_EVALUATION_REQUEST, assets: [asset] })
    }
  }, [asset])

  const activeTab = queryParams[QUERY_ACTIVE_TAB]
  const getActiveTabContent = () => {
    switch (activeTab) {
      case details:
        return <AssetDetails id={id} asset={asset} isNew={isNew} />
      case meterData:
        return <AssetMeterData asset={asset} />
      case meterDataCleansing:
        return handleMeterDataCleansingLoad()
      case availability:
        return <Availabilities asset={asset} assetDescendants={assetDescendants} />
      case quality:
        return <AssetQuality asset={asset} />
      case forecastModels:
        return <AssetForecastModels asset={asset} />
      default:
        return <AssetDetails id={id} asset={asset} isNew={isNew} />
    }
  }

  const handleMeterDataCleansingLoad = () => {
    if (user && asset && showMDCForThisAssetAndUser({ asset, user })) {
      return <AssetMeterDataCleansing asset={asset} />
    }
    onUpdateQueryString({ [QUERY_ACTIVE_TAB]: details })
  }

  return (
    <FullHeight>
      {viewLoading ? (
        <Box mt={3}>
          <CenteredLoading size="2em" />
        </Box>
      ) : asset ? (
        <>{getActiveTabContent()}</>
      ) : (
        <AssetNotFound />
      )}
    </FullHeight>
  )
}

export default React.memo(AssetCrud)
