import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@material-ui/core'
import LayoutTitle from 'ui/LayoutTitle'
import { c } from 'ttag'
import DeliveryFormats from 'modules/delivery/deliveryFormats/DeliveryFormats'
import DeliveryTargets from 'modules/delivery/deliveryTargets/DeliveryTargets'
import styled from 'styled-components'
import DeliveryIframe from 'modules/delivery/DeliveryIframe'
import {
  QUERY_DELIVERY_FORMAT,
  QUERY_DELIVERY_FORMAT_TEMPLATE,
  useQueryMatch,
  useQueryParams,
  useQueryString,
} from 'utils/query-string'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { useSelector } from 'react-redux'
import { hasAnyAuthority, isAdmin, userHasGlobalAccessWithoutDemo, useUserHasGlobalAccess } from 'utils/user'
import { toolbarHeight, useCustomMuiStyles } from 'themes/theme-light'

const DeliveryContainer = styled(Box)`
  height: calc(100vh - ${toolbarHeight});
`

const Delivery: React.FC = () => {
  //Style
  const classes = useCustomMuiStyles()

  const [oldUiIframeUrl, setOldUiIframeUrl] = useState<string>('')
  const isDeliveryFormatDetails = useQueryMatch(QUERY_DELIVERY_FORMAT)

  const { queryParams } = useQueryParams()
  const { onDeleteQueryStrings } = useQueryString()

  const user = useSelector(getUserResultSelector)
  const hasGlobalAccess = useUserHasGlobalAccess()
  const hasAccessToFormats = hasGlobalAccess || hasAnyAuthority(user, ['ROLE_SITE_FORECAST'])
  const hasAccessToTemplates = useMemo(() => isAdmin(user), [user])
  const hasRoleSiteForecast = useMemo(() => hasAnyAuthority(user, ['ROLE_SITE_FORECAST']), [user])

  useEffect(() => {
    if (!isDeliveryFormatDetails) {
      setOldUiIframeUrl('')
    } else {
      const urlContext = 'ecexportformat'
      const id = queryParams[QUERY_DELIVERY_FORMAT]
      const iframeUrl = `/iframe/#/${urlContext}/${id === 'new' ? '' : `${id}`}?IFRAME`

      setOldUiIframeUrl(iframeUrl)
    }
  }, [isDeliveryFormatDetails, queryParams])

  useEffect(() => {
    if (!hasAccessToTemplates && isDeliveryFormatDetails) {
      onDeleteQueryStrings([QUERY_DELIVERY_FORMAT_TEMPLATE])
    }
    if (!hasAccessToFormats && isDeliveryFormatDetails) {
      onDeleteQueryStrings([QUERY_DELIVERY_FORMAT])
    }
  }, [hasAccessToFormats, hasAccessToTemplates, onDeleteQueryStrings, isDeliveryFormatDetails])

  return (
    <>
      {oldUiIframeUrl ? (
        <DeliveryIframe url={oldUiIframeUrl} />
      ) : (
        <DeliveryContainer ml={1}>
          <LayoutTitle icon="truck">{c('Delivery').t`Delivery`}</LayoutTitle>
          <Box mb={1} mr={1}>
            <DeliveryTargets />
          </Box>

          {hasAccessToFormats && (
            <Box
              mb={1}
              mr={1}
              className={
                hasRoleSiteForecast
                  ? 'inherit'
                  : userHasGlobalAccessWithoutDemo(user)
                  ? classes.adminComponent
                  : 'inherit'
              }
            >
              <DeliveryFormats isTemplateTable={false} />
            </Box>
          )}

          {hasAccessToTemplates && (
            <Box mb={4} mr={1} className={classes.adminComponent}>
              <DeliveryFormats isTemplateTable={true} />
            </Box>
          )}
        </DeliveryContainer>
      )}
    </>
  )
}

export default React.memo(Delivery)
