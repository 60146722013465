import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import CreateSchedule from 'modules/workspace/schedule/CreateSchedule'
import styled from 'styled-components'
import {
  useCreateScheduleInput,
  useSavedSchedules,
  useScheduleSeriesChangedMutation,
} from 'modules/workspace/schedule/schedule.api'
import { CreateScheduleInputData } from 'modules/workspace/schedule/schedule.types'
import { useSelector } from 'react-redux'
import { workspaceDraftDataStreamSelectionSelector } from 'modules/workspace/store/getWorkspaceDraft.state'
import { getScheduleSourceDataStreamsTypes, ScheduleLocalStorageKeys } from 'utils/schedule'
import { TimeSeriesType } from 'modules/dataStreams/dataStreams.types'
import { useUniqueSelectedAssets } from 'utils/asset'
import { useWorkspaceChartWholeDateRange } from 'utils/workspace'
import { getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import {
  convertLocalDateToUTC,
  convertUtcToZonedTime,
  convertZonedTimeToAnotherZonedTime,
  getInclusiveDateRangeFromChartDataRange,
  getRangeTypeFromTimePeriod,
  getScheduleFormDateRangeOptions,
} from 'utils/date'

import { ChartDataRangeType, RangeTypes } from 'modules/workspace/store/workspace.types'
import { sortArrayBasedOnAnotherArray } from 'utils/array'
import { useDataStreams } from 'utils/dataStream'
import { subSeconds } from 'date-fns'
import { Box, Typography } from '@material-ui/core'
import SliderCloseButton from 'ui/form/SliderCloseButton'
import { QUERY_SCHEDULE, useQueryString } from 'utils/query-string'
import Flex from 'ui/styles/Flex'
import RevisionCountdownTimer from 'modules/workspace/schedule/uiHelperElements/RevisionCountdownTimer'

export const ManualForecastFormHeight = '15em'

interface ManualForecastContainerProps {
  show_form: number
}
const ManualForecastContainer = styled.div<ManualForecastContainerProps>`
  position: absolute;
  bottom: 0;
  width: calc(100% - 1.4em);
  left: 0.7em;
  right: 0.7em;
  z-index: 1;
  height: ${(props) => (props.show_form ? ManualForecastFormHeight : 0)};
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14),
    0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  transition: ${(props) => (props.show_form ? 'height 0.5s' : null)};
  padding: ${(props) => (props.show_form ? '0em 1.5em 0.5em' : 0)};
  background-color: white;
`

interface ScheduleProps {
  showCreateScheduleForm: boolean
  setNotificationState: any
  widgetName: string
  revisionTimingInfo: any
}
const Schedule: React.FC<ScheduleProps> = ({
  showCreateScheduleForm,
  setNotificationState,
  widgetName,
  revisionTimingInfo,
}) => {
  const [initialScheduleData, setInitialScheduleData] = useState<CreateScheduleInputData>({})
  const setInitialScheduleDataRef = useRef(true)
  const scheduleRanges = useMemo(() => getScheduleFormDateRangeOptions(), [])

  const scheduleInputSeriesQueryResult = useCreateScheduleInput()
  const scheduleInputSeriesData = scheduleInputSeriesQueryResult?.data
  const scheduleInputDataFetched = scheduleInputSeriesQueryResult?.isFetched

  const { onDeleteQueryStrings } = useQueryString()

  const selectedAssets = useUniqueSelectedAssets()
  const dataStreams = useDataStreams()
  const selectedDataStreams = useSelector(workspaceDraftDataStreamSelectionSelector)
  const sortedSelectedDataStreams = useMemo(() => {
    return sortArrayBasedOnAnotherArray(selectedDataStreams, dataStreams, 'id')
  }, [selectedDataStreams, dataStreams])

  const scheduleSourceDataStreamTypes = getScheduleSourceDataStreamsTypes()
  const sourceDataStreams = useMemo(
    () => sortedSelectedDataStreams.filter((s) => scheduleSourceDataStreamTypes.includes(s.type)),
    [sortedSelectedDataStreams, scheduleSourceDataStreamTypes],
  )

  const targetScheduleDataStreams = useMemo(() => {
    const selectedScheduleDataStreams = sortedSelectedDataStreams.filter((s) => s.type === TimeSeriesType.SCHEDULE)
    if (selectedScheduleDataStreams.length) {
      return selectedScheduleDataStreams
    } else {
      // return available schedule dataStreams
      return dataStreams.filter((s) => s.type === TimeSeriesType.SCHEDULE)
    }
  }, [sortedSelectedDataStreams, dataStreams])
  const chartWholeRange = useWorkspaceChartWholeDateRange()
  const timezone = useSelector(getUserTimezoneSelector)

  const savedSchedulesQueryResult = useSavedSchedules()
  const savedSchedulesFetched = savedSchedulesQueryResult?.isFetched
  const savedSchedulesData = savedSchedulesQueryResult?.data

  // Mutation to save the series changed
  const { mutate: saveScheduleSeriesChangedMutation } = useScheduleSeriesChangedMutation()

  const handleCloseForm = useCallback(() => {
    onDeleteQueryStrings([QUERY_SCHEDULE])
  }, [onDeleteQueryStrings])

  /**
   * Set the data initially to the form based on available sources, targets and time period
   * Once the data is set this hook will set again as we disable by "setInitialScheduleDataRef"
   */
  useEffect(() => {
    if (
      savedSchedulesFetched &&
      setInitialScheduleDataRef.current &&
      chartWholeRange.length &&
      showCreateScheduleForm &&
      scheduleInputDataFetched
    ) {
      const asset = selectedAssets[0] || null
      const sourceDataStream = sourceDataStreams[0] || null
      const targetScheduleDataStream = targetScheduleDataStreams[0] || null

      const chartDateRange = getInclusiveDateRangeFromChartDataRange(
        { rangeType: ChartDataRangeType.CHART_DATA_RANGE_CUSTOM, customRange: chartWholeRange },
        timezone,
      )

      const startDate = convertZonedTimeToAnotherZonedTime(chartDateRange[0], 'UTC', timezone)
      const endDate = convertZonedTimeToAnotherZonedTime(chartDateRange[1], 'UTC', timezone)

      const rangeType = getRangeTypeFromTimePeriod({
        timePeriod: {
          start: chartDateRange[0],
          end: subSeconds(chartDateRange[1], 1),
        },
        rangeOptions: scheduleRanges,
        rangeType: RangeTypes.MAIN_CHART_RANGE,
        timezone: timezone,
      })

      const initialValues = {
        asset,
        sourceDataStream,
        targetScheduleDataStream,
        start: {
          date: startDate,
          timezone: timezone,
        },
        end: {
          date: endDate,
          timezone: timezone,
        },
        range: rangeType,
        timezoneWhenFormInitialized: timezone,
      }

      if (savedSchedulesData && savedSchedulesData?.length) {
        const savedForecastForAssetAndSchedule = savedSchedulesData.find((smf) => {
          return (
            smf?.asset?.id === asset.id &&
            smf?.targetScheduleDataStream?.id === targetScheduleDataStream?.id &&
            smf?.sourceDataStream?.id === sourceDataStream?.id
          )
        })

        // TODO remove this block after checking
        // If there is a saved schedule config for the asset, source and target datastream
        if (savedForecastForAssetAndSchedule) {
          const savedStartDateInUTC =
            savedForecastForAssetAndSchedule?.start?.date || savedForecastForAssetAndSchedule?.start
          const savedEndDateInUTC = savedForecastForAssetAndSchedule?.end?.date || savedForecastForAssetAndSchedule?.end

          const savedStartDate = convertUtcToZonedTime(savedStartDateInUTC, timezone)
          const savedEndDate = convertUtcToZonedTime(savedEndDateInUTC, timezone)

          const savedStartTimezone = savedForecastForAssetAndSchedule?.start?.timezone || timezone
          const savedEndTimezone = savedForecastForAssetAndSchedule?.end?.timezone || timezone
          // If the timezone is same for both start and end date then check for range if not set to Custom
          const rangeType =
            savedStartTimezone === savedEndTimezone
              ? getRangeTypeFromTimePeriod({
                  timePeriod: {
                    start: new Date(savedStartDate as Date),
                    end: subSeconds(new Date(savedEndDate as Date), 1),
                  },
                  rangeOptions: scheduleRanges,
                  rangeType: RangeTypes.MAIN_CHART_RANGE,
                  timezone: timezone,
                })
              : ChartDataRangeType.CHART_DATA_RANGE_CUSTOM

          initialValues['start'] = {
            date: savedStartDate,
            timezone: savedStartTimezone,
          }
          initialValues['end'] = {
            date: savedEndDate,
            timezone: savedEndTimezone,
          }
          initialValues['range'] = rangeType
        }
      }

      // If there is a draft input for the asset, source and target datastream
      if (scheduleInputSeriesData) {
        const draftAssetSelected = selectedAssets?.some((sa) => sa.id === scheduleInputSeriesData?.asset?.id)
        const draftSourceSelected = sourceDataStreams?.some(
          (sa) => sa.id === scheduleInputSeriesData?.sourceDataStream?.id,
        )
        const draftTargetSelected = targetScheduleDataStreams?.some(
          (sa) => sa.id === scheduleInputSeriesData?.targetScheduleDataStream?.id,
        )

        if (
          draftAssetSelected &&
          draftSourceSelected &&
          draftTargetSelected &&
          scheduleInputSeriesData?.start?.date &&
          scheduleInputSeriesData?.end?.date &&
          scheduleInputSeriesData.asset
        ) {
          const draftStartDateInUTC = (scheduleInputSeriesData?.start?.date || scheduleInputSeriesData?.start) as Date
          const draftStartTimezone = scheduleInputSeriesData?.start?.timezone || timezone
          const draftEndDateInUTC = (scheduleInputSeriesData?.end?.date || scheduleInputSeriesData?.end) as Date
          const draftEndTimezone = scheduleInputSeriesData?.end?.timezone || timezone

          const draftStartDate = convertUtcToZonedTime(draftStartDateInUTC, timezone)
          const draftEndDate = convertUtcToZonedTime(draftEndDateInUTC, timezone)

          const draftRangeType =
            draftStartTimezone === draftEndTimezone
              ? getRangeTypeFromTimePeriod({
                  timePeriod: {
                    start: convertLocalDateToUTC(new Date(scheduleInputSeriesData?.start?.date)),
                    end: subSeconds(convertLocalDateToUTC(new Date(scheduleInputSeriesData?.end?.date)), 1),
                  },
                  rangeOptions: scheduleRanges,
                  rangeType: RangeTypes.MAIN_CHART_RANGE,
                  timezone: timezone,
                })
              : ChartDataRangeType.CHART_DATA_RANGE_CUSTOM

          initialValues['asset'] = scheduleInputSeriesData.asset
          initialValues['sourceDataStream'] = scheduleInputSeriesData.sourceDataStream
          initialValues['targetScheduleDataStream'] = scheduleInputSeriesData.targetScheduleDataStream
          initialValues['start'] = {
            date: draftStartDate,
            timezone: draftStartTimezone,
          }
          initialValues['end'] = {
            date: draftEndDate,
            timezone: draftEndTimezone,
          }
          initialValues['range'] = draftRangeType
        }
      }
      setInitialScheduleDataRef.current = false
      setInitialScheduleData(initialValues)
    }
  }, [
    JSON.stringify(savedSchedulesData),
    savedSchedulesFetched,
    JSON.stringify(sourceDataStreams),
    JSON.stringify(targetScheduleDataStreams),
    JSON.stringify(selectedAssets),
    JSON.stringify(chartWholeRange),
    showCreateScheduleForm,
    timezone,
    scheduleRanges,
    scheduleInputSeriesData,
    scheduleInputDataFetched,
  ])

  /**
   * Clear the input data when form is not shown
   */
  useEffect(() => {
    if (!showCreateScheduleForm) {
      setInitialScheduleDataRef.current = true
    }
  }, [showCreateScheduleForm])

  // Whenever we open the schedule editor series changed should be false
  // we track the value for every session
  useEffect(() => {
    saveScheduleSeriesChangedMutation({ value: false })
    localStorage.setItem(ScheduleLocalStorageKeys.seriesChanged, 'false')
  }, [])

  // console.log('initialScheduleData =', initialScheduleData)

  return (
    <ManualForecastContainer show_form={showCreateScheduleForm ? 1 : 0}>
      {showCreateScheduleForm ? (
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h1">{widgetName}</Typography>
            <Flex alignItems="center">
              {Object.keys(revisionTimingInfo.data).length > 0 && (
                <RevisionCountdownTimer revisionTimingInfo={revisionTimingInfo.data} />
              )}
              <SliderCloseButton onCloseSlider={handleCloseForm} />
            </Flex>
          </Box>

          <CreateSchedule
            initialScheduleData={initialScheduleData}
            savedSchedulesData={savedSchedulesData}
            setNotificationState={setNotificationState}
          />
        </Box>
      ) : (
        <></>
      )}
    </ManualForecastContainer>
  )
}

export default Schedule
