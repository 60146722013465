import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Field, FormSpy, useField } from 'react-final-form'
import { t } from 'ttag'

import { Asset } from 'modules/asset/store/asset.types'
import {
  isCHP,
  isCluster,
  isClusterCollection,
  isClusterHybridPark,
  isGenerator,
  isLocationAsset,
  isPark,
} from 'utils/asset'
import {
  AssetFormLabel,
  DateField,
  FieldUnit,
  FormKey,
  FormKeyValueGrid,
  FormValue,
  SubField,
  TextFieldWithoutLabel,
} from 'ui/form/assetForm.style'
import Flex from 'ui/styles/Flex'
import FontAwesomeActionIcon from 'ui/FontAwesomeActionIcon'
import { convertZonedTimeToUtc, isDateValid } from 'utils/date'
import { theme } from 'themes/theme-light'
import DateTimezonePopover from 'ui/elements/DateTimezonePopover'
import { useSelector } from 'react-redux'
import { getUserResultSelector, getUserTimezoneSelector } from 'modules/auth/redux_store/state/getUser'
import CopyToClipboard from 'ui/elements/CopyToClipboard'
import { Box } from '@material-ui/core'
import { isAuthorizedToPenalties } from 'utils/user'
import DateTimePickerFinalForm from 'ui/elements/DateTimePickerFinalForm'
import PopperTooltip from 'ui/PopperTooltip'
import { useAllAssetsOfStage } from 'modules/asset/api/assets.api'
import { STAGE_PRODUCTIVE } from 'pages/workbench/store/workbench.types'
import { isBefore } from 'date-fns'
import { Checkbox } from 'final-form-material-ui'
import { FormApi } from 'final-form'
import SectionPenalty from 'modules/asset/assetCrud/assetDetails/SectionPenalty'
import { CheckboxControl } from 'ui/form/authForm.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// exported component

const InfoIcon = styled(FontAwesomeActionIcon)`
  color: ${theme.palette.primary.main};
  cursor: help;
`

interface SectionInformationProps {
  form: FormApi<Asset>
  asset: Asset
  enterManually: boolean
  isNew: boolean
  assetPhysicalAggregator: Asset
}

const SectionInformation: React.FC<SectionInformationProps> = ({
  form,
  asset,
  enterManually,
  isNew,
  assetPhysicalAggregator,
}) => {
  const userTimezone = useSelector(getUserTimezoneSelector)
  const user = useSelector(getUserResultSelector)
  const [formState, setFormState] = useState(asset)
  const alreadyActivatedAssetsResult = useAllAssetsOfStage(STAGE_PRODUCTIVE)
  // const clusterTypes = getClusterTypes()
  const assetIsaPark = isPark(formState) || isClusterHybridPark(formState)

  const fromField = useField('since')
  const toField = useField('to')

  // Checking if asset is activated at least once (Not a virgin Asset)
  const isAssetAlreadyActivated = useMemo(() => {
    if (alreadyActivatedAssetsResult?.data) {
      const alreadyActivatedAssets = alreadyActivatedAssetsResult?.data
      const isAssetActivated = alreadyActivatedAssets.some((a) => a.id === asset.id)
      return isAssetActivated
    } else return false
  }, [alreadyActivatedAssetsResult.data, asset])

  const sinceDateInUTC = useMemo(
    () =>
      !isCluster(formState) && isDateValid(formState.since)
        ? convertZonedTimeToUtc(formState.since, userTimezone)
        : undefined,
    [userTimezone, formState?.since],
  )
  const toDateInUTC = useMemo(
    () =>
      !isCluster(formState) && isDateValid(formState.to)
        ? convertZonedTimeToUtc(formState.to, userTimezone)
        : undefined,
    [userTimezone, formState?.to],
  )

  /* Forecast active fields
   * --------------------------------------
   * value     From field     To field
   * --------------------------------------
   * Blank      Disable         -
   * In past    Disable       Disable
   * New asset   -            -
   * */

  // For disabling from and to date fields
  const isFromDateInPast =
    formState?.since && new Date(formState?.since).getTime() ? isBefore(new Date(formState?.since), new Date()) : true
  const isFromDateChanged = fromField.meta.dirty
  const disableFromDateField = isAssetAlreadyActivated && isFromDateInPast && !isFromDateChanged

  const isToDateInPast = formState?.to ? isBefore(new Date(formState?.to), new Date()) : false
  const toMinDate = formState?.since && new Date(formState?.since).getTime() ? new Date(formState?.since) : new Date()
  const isToDateChanged = toField.meta.dirty
  const disableToDateField = isAssetAlreadyActivated && isToDateInPast && !isToDateChanged
  const NotOperationalLabelForClusterOrPark = assetIsaPark ? t`park` : t`cluster`
  const showSecondaryNotOperationalMessageForClusterOrPark =
    isCluster(formState) || (isPark(formState) && !formState?.enterManually)

  return (
    <>
      <FormSpy
        subscription={{ values: true }}
        onChange={({ values }) => {
          setFormState(values as Asset)
        }}
      />

      {(isLocationAsset(formState) || isPark(formState)) && (
        <FormKeyValueGrid>
          <FormKey>{t`EEG Id`}:</FormKey>
          <FormValue>
            <Field fullWidth name="eegId">
              {(props) => {
                return <TextFieldWithoutLabel fullWidth input={props.input} meta={props.meta} />
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {!isCHP(formState) && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Plant key`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Field name="key">
              {(props) => {
                return <TextFieldWithoutLabel fullWidth input={props.input} meta={props.meta} />
              }}
            </Field>
          </FormValue>
        </FormKeyValueGrid>
      )}

      {/*Penalty Section PPA Rate and Penalty regulation */}
      {isAuthorizedToPenalties(user) && !isClusterCollection(formState) && (
        <SectionPenalty user={user} asset={formState} form={form} assetPhysicalAggregator={assetPhysicalAggregator} />
      )}

      {!isCluster(formState) && (isGenerator(formState) || enterManually) && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Forecast active`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex direction="row">
              <Flex flexBasis="50%" alignItems="center">
                <DateField
                  name="since"
                  disabled={disableFromDateField}
                  label={t`From`}
                  minDate={new Date()}
                  component={DateTimePickerFinalForm}
                />
                {sinceDateInUTC && formState?.sinceTimeZone && formState?.sinceTimeZone !== userTimezone && (
                  <DateTimezonePopover dateInUTC={sinceDateInUTC} originalTimezone={formState?.sinceTimeZone} />
                )}
              </Flex>

              <SubField flexBasis="50%" alignItems="center">
                <DateField
                  name="to"
                  disabled={disableToDateField}
                  label={t`To`}
                  minDate={toMinDate}
                  component={DateTimePickerFinalForm}
                />
                {toDateInUTC && formState?.toTimeZone && formState?.toTimeZone !== userTimezone && (
                  <DateTimezonePopover dateInUTC={toDateInUTC} originalTimezone={formState?.toTimeZone} />
                )}
                {/*{endDateWarning && (*/}
                <FieldUnit>
                  <PopperTooltip
                    popperLabel={<InfoIcon icon="info" size="sm" />}
                    popperContent={
                      <>
                        {t`If one or both dates are provided, a forecast will only be calculated and a license will only be needed within the period specified.  Outside that period, the forecast will show zero.  If left blank, a forecast will be calculated for as long as the asset exists.`}
                      </>
                    }
                  />
                </FieldUnit>
                {/*)}*/}
              </SubField>
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}

      <FormKeyValueGrid>
        <FormKey>{t`Not operational:`}</FormKey>
        <FormValue>
          <Flex direction="row" alignItems="flex-start" justifyContent="flex-start">
            <CheckboxControl
              label={''}
              control={<Field name="uiNotOperational" type="checkbox" component={Checkbox} />}
            />
            <Box>
              <PopperTooltip
                popperLabel={
                  <Box ml={0.7} mt={0.3}>
                    <FontAwesomeIcon color={theme.palette.primary.main} icon="info" size="sm" />
                  </Box>
                }
                popperContent={
                  <div>
                    {t`Checking "not operational" will stop the recurring calculation of live forecasts for this asset.  You can still view the earlier forecasts or calculate backcasts on demand.`}
                    {showSecondaryNotOperationalMessageForClusterOrPark && (
                      <>{t` The forecast calculation for assets in the ${NotOperationalLabelForClusterOrPark} is not affected.`}</>
                    )}
                  </div>
                }
              />
            </Box>
          </Flex>
        </FormValue>
      </FormKeyValueGrid>

      <FormKeyValueGrid>
        <FormKey>
          <AssetFormLabel>{t`Description`}:</AssetFormLabel>
        </FormKey>
        <FormValue>
          <Field name="description">
            {(props) => {
              return <TextFieldWithoutLabel type="text" fullWidth input={props.input} meta={props.meta} />
            }}
          </Field>
        </FormValue>
      </FormKeyValueGrid>

      {!isNew && (
        <FormKeyValueGrid>
          <FormKey>
            <AssetFormLabel>{t`Asset Id`}:</AssetFormLabel>
          </FormKey>
          <FormValue>
            <Flex alignItems="center">
              <div>{asset.id}</div>
              <CopyToClipboard title={t`Copy asset id`} textToCopy={asset.id} />
            </Flex>
          </FormValue>
        </FormKeyValueGrid>
      )}
    </>
  )
}

export default React.memo(SectionInformation)
