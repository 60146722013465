export enum OverlayZIndex {
  forecastConfigIndex = 0,
  dataStreamsIndex = 3,
  assetDetailsIndex = 6,
  userSettingsIndex = 8,
  changePasswordIndex = 9,
  deliveryIndex = 6,
  penaltyRegulationDetailsIndex = 6,
  userManagementDetailsIndex = 7,
}

export enum AppSliders {
  assetDetails,
  forecastConfig,
  userSettings,
  changePassword,
  dataStreams,
}
