import { SagaIterator } from 'redux-saga'
import { call, debounce, put, select } from 'redux-saga/effects'

import {
  RETABLE_COLLAPSE_SUBTREES,
  RETABLE_EXPAND_SUBTREES,
  RETABLE_SAVE_ACTION,
  RETABLE_SET_COLUMNS_SELECTED,
  RETABLE_SET_VIRTUAL_RANGE,
  RETABLE_TOGGLE_SUBTREE,
} from 'modules/reTable/redux_store/reTable.action.types'
import { GET_USERCONFIG_REQUEST, SET_USERCONFIG_REQUEST } from 'modules/auth/redux_store/auth.action.types'
import {
  CollapseAction,
  ColumnsSelectedAction,
  reTableCollapsedSelector,
  reTableColumnsSelectedSelector,
  VirtualRangeAction,
} from 'modules/reTable/redux_store/state/view.state'
import { ReTableId } from 'modules/reTable/reTable.types'
import { getUserResultSelector } from 'modules/auth/redux_store/state/getUser'
import { isImpersonatedAdmin } from 'utils/user'

export const USERCONFIG_COLLECTIONID_RETABLE_PREFIX = 'reTable'
export enum RETABLE_SAVE_ACTION_USERCONFIG {
  SELECTED_COLUMNS = 'columnsSelected',
  COLLAPSED_IDS = 'collapsed',
  VIRTUAL_RANGE = 'virtualRange',
}

export const buildReTableCollectionId = (saveId: RETABLE_SAVE_ACTION_USERCONFIG) => {
  return `${USERCONFIG_COLLECTIONID_RETABLE_PREFIX}-${saveId}`
}

export const getReTableCollectionId = (type: RETABLE_SAVE_ACTION) => {
  let saveId
  switch (type) {
    case RETABLE_SET_COLUMNS_SELECTED:
      saveId = RETABLE_SAVE_ACTION_USERCONFIG.SELECTED_COLUMNS
      break
    case RETABLE_TOGGLE_SUBTREE:
    case RETABLE_EXPAND_SUBTREES:
    case RETABLE_COLLAPSE_SUBTREES:
      saveId = RETABLE_SAVE_ACTION_USERCONFIG.COLLAPSED_IDS
      break
    case RETABLE_SET_VIRTUAL_RANGE:
      saveId = RETABLE_SAVE_ACTION_USERCONFIG.VIRTUAL_RANGE
      break
  }
  return buildReTableCollectionId(saveId)
}

export function* retableGetSavedDataSaga(type: RETABLE_SAVE_ACTION): SagaIterator {
  // const userConfigs = yield select(getUserConfigResultSelector)
  // const columnsPrevious = userConfigs?.[collectionId]?.[configId] || []

  yield put({
    type: GET_USERCONFIG_REQUEST,
    collectionId: getReTableCollectionId(type),
  })

  // const { success, failure } = yield race({
  //   success: take(
  //     (action: AnyAction) =>
  //       action.type === GET_USERCONFIG_SUCCESS &&
  //       action.collectionId === USERCONFIG_COLLECTIONID_RETABLE_SELECTEDCOLUMNS,
  //   ),
  //   failure: take(
  //     (action: AnyAction) =>
  //       action.type === GET_USERCONFIG_FAILURE &&
  //       action.collectionId === USERCONFIG_COLLECTIONID_RETABLE_SELECTEDCOLUMNS,
  //   ),
  // })
}

export interface ReTableSetSavedDataAction {
  type: RETABLE_SAVE_ACTION
  table: ReTableId
  data: Record<
    string,
    ColumnsSelectedAction['columnsSelected'] | CollapseAction['ids'] | VirtualRangeAction['virtualRange']
  >
}
export function* retableSetSavedDataSaga({ type, table, data }: ReTableSetSavedDataAction): SagaIterator {
  // we don't want to override the users settings as impersonated user
  const user = yield select(getUserResultSelector)
  if (isImpersonatedAdmin(user)) return

  const configId = table
  // const userConfigs = yield select(getUserConfigResultSelector)
  // const columnsPrevious = userConfigs?.[collectionId]?.[configId] || []

  yield put({
    type: SET_USERCONFIG_REQUEST,
    collectionId: getReTableCollectionId(type),
    configId,
    value: data,
  })

  // const { success, failure } = yield race({
  //   success: take(
  //     (action: AnyAction) =>
  //       action.type === SET_USERCONFIG_SUCCESS &&
  //       action.collectionId === USERCONFIG_COLLECTIONID_RETABLE_SELECTEDCOLUMNS &&
  //       action.configId === configId,
  //   ),
  //   failure: take(
  //     (action: AnyAction) =>
  //       action.type === SET_USERCONFIG_FAILURE &&
  //       action.collectionId === USERCONFIG_COLLECTIONID_RETABLE_SELECTEDCOLUMNS &&
  //       action.configId === configId,
  //   ),
  // })
}

export function* reTableSaveSelectedColumnsSaga({ type, table }: ColumnsSelectedAction): SagaIterator {
  const columnsSelected = yield select(reTableColumnsSelectedSelector)
  const data = (columnsSelected[table] || []).map((c) => c.name)
  yield call(retableSetSavedDataSaga, {
    type,
    table,
    data,
  })
}

export function* reTableSaveCollapsedSaga({ type, table }: CollapseAction): SagaIterator {
  const collapsed = yield select(reTableCollapsedSelector)
  const data = collapsed[table] || []
  yield call(retableSetSavedDataSaga, {
    type,
    table,
    data,
  })
}

export function* reTableSaga() {
  yield debounce(1000, RETABLE_SET_COLUMNS_SELECTED, reTableSaveSelectedColumnsSaga)
  yield debounce(
    1000,
    [RETABLE_TOGGLE_SUBTREE, RETABLE_EXPAND_SUBTREES, RETABLE_COLLAPSE_SUBTREES],
    reTableSaveCollapsedSaga,
  )
}
