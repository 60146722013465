import React from 'react'

import { Asset } from 'modules/asset/store/asset.types'
import ForecastModels from 'modules/forecastModels/ForecastModels'
import { FormContainer } from 'ui/form/form.style'
import AssetCrudCommonHeader from 'modules/asset/assetCrud/AssetCrudCommonHeader'
import { assetTabNames } from 'fixtures/assetForm'

interface SectionForecastModelsTableProps {
  asset: Asset
}

const AssetForecastModels: React.FC<SectionForecastModelsTableProps> = ({ asset }) => {
  return (
    <FormContainer>
      <AssetCrudCommonHeader activeTab={assetTabNames.forecastModels} asset={asset} />
      <ForecastModels asset={asset} />
    </FormContainer>
  )
}

export default React.memo(AssetForecastModels)
