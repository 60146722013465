import React from 'react'
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress'

import Centered from './Centered'

interface CenteredLoadingProps {
  fullHeight?: boolean
}

const CenteredLoading: React.FC<CenteredLoadingProps & CircularProgressProps> = ({ fullHeight = false, ...props }) => (
  <Centered fullHeight={fullHeight}>
    <CircularProgress {...props} />
  </Centered>
)

export default React.memo(CenteredLoading)
